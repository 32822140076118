import React, { FC, useCallback, useRef, useMemo } from 'react';
import {
  required,
  useTranslate,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Dialog, makeStyles, createStyles } from '@material-ui/core';
import { ReactElementLike } from 'prop-types';
import {
  CustomForm,
  NormalButton,
  DoneButton,
  TextInput,
  SelectInput,
} from '../../../..';
import { styles } from '../../../../../theme';
import {
  CompactionWorkAreaData,
  UnitLength,
} from '../../../../../dataProvider';
import { blockSize, apiErrorHandler } from '../../../../../utils';
import { SaveIcon, ReturnIcon } from '../../../../../assets';
import { useUnitLength } from '../../../../../hooks';

const dialogWidth = 790;

const useStyles = makeStyles(() =>
  createStyles({
    description: {
      ...styles.multilineText,
    },
    header: {
      fontSize: 18,
      margin: 0,
      padding: 24,
    },
    content: {
      fontSize: 18,
      width: dialogWidth, // 名称のバリデーションの関係で、規定の横幅だと収まらないので調整している
      height: 380 - 80, // 80: header
    },
    toolbarButton: {
      position: 'relative',
      bottom: -26,
    },
    alignRight: {
      ...styles.selectInputRightAligned,
    },
  }),
);

interface FormProps {
  disabled: boolean;
  record: CompactionWorkAreaData;
  form: any;
}

const SaveButton: FC<
  {
    resource: string;
    label: string;
    onSaved: () => void;
  } & Partial<FormProps>
> = ({ disabled, form, resource, record, label, onSaved }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleSave = useCallback(() => {
    if (!record) return;
    // API呼び出し処理
    const fn = async () => {
      try {
        const data = {
          name: form.getFieldState('name').value,
          blockSize: form.getFieldState('blockSize').value,
          description: form.getFieldState('description').value,
        };
        await dataProvider.update(resource, {
          id: record.id,
          data: {
            ...record,
            ...data,
          },
          previousData: record,
        });
        notify('admin.message.success', 'info');
        refresh();
      } catch (error) {
        notify(apiErrorHandler(error), 'warning');
      } finally {
        onSaved();
      }
    };
    fn();
  }, [form, resource, record, onSaved, dataProvider, notify, refresh]);
  return (
    <DoneButton label={label} disabled={disabled} onClick={handleSave}>
      <SaveIcon />
    </DoneButton>
  );
};

// CustomForm の props を伝達するコンポーネント
// また、Button の位置調整は 本コンポーネントで行う
const ToolbarButton: FC<{ children?: ReactElementLike }> = ({
  children,
  ...props
}) => {
  const classes = useStyles();
  const button = React.isValidElement(children)
    ? React.cloneElement(children, { ...props })
    : null;
  return <div className={classes.toolbarButton}>{button}</div>;
};

interface Props {
  open: boolean;
  record: CompactionWorkAreaData | undefined;
  onClose: () => void;
  unitLength: UnitLength;
}

const CompactionWorkAreaEdit: FC<Props> = ({
  open,
  record,
  onClose,
  unitLength,
}) => {
  const resource = 'compactionWorkAreas';
  const translate = useTranslate();
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  const { toDisplayUnitLength, meterToUnitLengthRoundUp } = useUnitLength();

  const handleDialogEnter = useCallback(() => {
    const dialogElement = ref?.current?.parentElement?.parentElement;
    if (!dialogElement) return;
    dialogElement.style.minWidth = `${dialogWidth}px`;
  }, [ref]);

  const blockSizeLabel = translate(
    'admin.label.compactionWorkAreas.blockSize',
    {
      unit: toDisplayUnitLength(unitLength),
    },
  );

  const blockSizeChoices = useMemo(
    () =>
      blockSize.map(({ id, name }) => ({
        id,
        name: meterToUnitLengthRoundUp(Number(name), unitLength),
      })),
    [unitLength, meterToUnitLengthRoundUp],
  );

  return (
    <Dialog open={open} onClose={onClose} onEnter={handleDialogEnter}>
      <MuiDialogTitle>
        {translate('admin.dialog.compactionWorkAreas.update.message')}
      </MuiDialogTitle>
      <MuiDialogContent className={classes.content}>
        <div ref={ref}>
          {open ? (
            <CustomForm
              resource={resource}
              record={record}
              bottomToolbar={true}
              deleteButton={false}
              cancelButton={
                <ToolbarButton>
                  <NormalButton onClick={onClose} label="admin.actions.cancel">
                    <ReturnIcon />
                  </NormalButton>
                </ToolbarButton>
              }
              saveButton={
                <ToolbarButton>
                  <SaveButton
                    resource={resource}
                    onSaved={onClose}
                    label="admin.actions.save"
                  />
                </ToolbarButton>
              }
            >
              <TextInput
                resource={resource}
                source="name"
                validate={[required()]}
              />
              <SelectInput
                resource={resource}
                source="blockSize"
                choices={blockSizeChoices}
                validate={[required()]}
                label={blockSizeLabel}
                className={classes.alignRight}
              />
              <TextInput
                resource={resource}
                source="description"
                className={classes.description}
                multiline
                rows={3}
              />
            </CustomForm>
          ) : null}
        </div>
      </MuiDialogContent>
    </Dialog>
  );
};

CompactionWorkAreaEdit.displayName = 'CompactionWorkAreaEdit';
export default CompactionWorkAreaEdit;
