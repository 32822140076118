import { AccountData, getDataProvider } from '../dataProvider';

let account: AccountData;

export default async () => {
  if (!account) {
    const dataProvider = getDataProvider();
    const { data } = await dataProvider.getOne('accounts', { id: 1 });
    account = data as AccountData;
  }
  return account;
};
