import React from 'react';

const CustomSidebar: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  if (!React.isValidElement(children)) return null;
  return (
    <div className={className}>
      {React.cloneElement(React.Children.only(children))}
    </div>
  );
};

export default CustomSidebar;
