import { Record, RecordMap } from 'react-admin';

/**
 * object to List
 *
 * NOTE:
 *   - useGetList
 *   - useGetMany
 *   - useGetManyReference
 *   で取得した data オブジェクトをリスト形式に変換する
 */
const objectToList = (data: RecordMap<Record>): Record[] => {
  return Object.entries(data).map(idWithRecord => {
    const [, record] = idWithRecord;
    return record;
  });
};

export default objectToList;
