import React from 'react';
import { required } from 'react-admin';
import { omit } from 'lodash';

import { SelectInput, TextField } from '../..';
import { bucketType } from '../../../utils';
import { BucketData } from '../../../dataProvider/types';

interface Props {
  addLabel: boolean;
  isRequired: boolean;
  label: string;
  record: BucketData;
}

const BucketTypeField: React.FC<Partial<Props>> = props => {
  const { record } = props;
  if (!record) return null;
  const { uploadedBy } = record;
  const readOnly = uploadedBy === 'Tablet';
  const rest = omit(props, 'isRequired');
  return readOnly ? (
    <TextField {...rest} resource="buckets" source="bucketType" />
  ) : (
    <SelectInput
      {...rest}
      label=""
      resource="buckets"
      source="bucketType"
      choices={bucketType}
      validate={required()}
      optionText="name"
    />
  );
};
BucketTypeField.displayName = 'BucketTypeField';
BucketTypeField.defaultProps = {
  addLabel: true,
};

export default BucketTypeField;
