import React, { useCallback } from 'react';
import { DialogOKCancel } from '..';

export interface ConfirmMessage {
  title: string;
  content: string;
}

interface Props {
  resource: string;
  selectedIds: string[];
  open: boolean;
  message: ConfirmMessage;
  onClose: () => void;
  onBulkAction: (resource: string, selectedIds: string[]) => void;
  isDelete?: boolean;
}

const BulkActionConfirm: React.FC<Props> = ({
  resource,
  selectedIds,
  open,
  message,
  onClose,
  onBulkAction,
  isDelete = false,
}) => {
  const handleBulkAction = useCallback(() => {
    onBulkAction(resource, selectedIds);
  }, [onBulkAction, resource, selectedIds]);

  return (
    <DialogOKCancel
      open={open}
      onClose={onClose}
      onOKClick={handleBulkAction}
      title=""
      message={message.content}
      nonTranslateMessage={true}
      isDelete={isDelete}
    />
  );
};

BulkActionConfirm.displayName = 'BulkActionConfirm';
export default BulkActionConfirm;
