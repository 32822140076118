import React, { FC } from 'react';
import {
  CustomForm,
  BreadcrumbsFiled,
  TextField,
  Show,
  CustomDateField,
  FunctionField,
} from '../../../..';
import { useResource } from '../../../../../hooks';
import { RetrofitData } from '../../../../../dataProvider';
import MachineTypeField from './MachineTypeField';

const RentalShow: FC<{
  props: any;
  retrofitData: RetrofitData;
}> = ({ props, retrofitData }) => {
  const resource = useResource('retrofitRentals');
  const retrofits = useResource('retrofits');

  if (!retrofitData) return <></>;

  return (
    <Show {...props}>
      <CustomForm
        title="admin.pages.retrofitRentalEdit"
        saveButton={false}
        deleteButton={false}
        cancelButton={false}
      >
        <BreadcrumbsFiled
          breadcrumbs={[
            'resources.retrofits.name',
            'admin.breadcrumbs.retrofitEdit',
          ]}
          label="admin.pages.retrofitRentalEdit"
        />
        <FunctionField
          resource={retrofits}
          label="resources.retrofits.fields.machineInfoMachineType"
          record={retrofitData}
          render={record => <MachineTypeField record={record} />}
        />
        <TextField
          record={retrofitData}
          resource={retrofits}
          source="machineInfoMachineId"
          label="resources.retrofits.fields.machineInfoMachineId"
        />
        <TextField
          record={retrofitData}
          resource={retrofits}
          source="machineInfoMachineName"
          label="resources.retrofits.fields.machineInfoMachineName"
        />
        <TextField
          record={retrofitData}
          resource={retrofits}
          source="machineInfoCompanyName"
          label="resources.retrofits.fields.machineInfoCompanyName"
        />
        <TextField resource={resource} source="toCorporationName" />
        <TextField resource={resource} source="email" />

        <CustomDateField resource={resource} source="planStartDate" />
        <CustomDateField resource={resource} source="planEndDate" />
        <CustomDateField resource={resource} source="actualStartDate" />
        <CustomDateField resource={resource} source="actualEndDate" />
      </CustomForm>
    </Show>
  );
};

RentalShow.displayName = 'RentalShow';
export default RentalShow;
