import React, { SyntheticEvent } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles, createStyles, Dialog } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Button, NormalButton } from '../..';
import { colors } from '../../../theme';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../../theme/define';
import { DeleteIcon, OkIcon, ReturnIcon } from '../../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      fontSize: 18,
      margin: 0,
      padding: 24,
    },
    content: {
      fontSize: 18,
      width: 600,
      minHeight: 260 - (80 + 84), // 80: header, 84: footer
      maxHeight: 150,
    },
    iconRoot: {
      display: 'flex',
    },
    icon: {
      marginTop: 12,
      marginRight: 12,
      width: '10%',
    },
    footer: {
      margin: 0,
      padding: 24,
    },
    button: {
      background: colors.button.save,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.saveHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.saveDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
      '& span': {
        padding: 0,
      },
    },
    delete: {
      background: colors.button.delete,
      color: colors.text.primaryLight,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        background: colors.button.deleteHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.deleteDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
      '& span': {
        padding: 0,
      },
    },
  }),
);

const DialogOKCancel: React.FC<{
  open: boolean;
  onClose: (e: SyntheticEvent) => void;
  onOKClick: (e: SyntheticEvent) => void;
  title: string;
  message: string;
  okLabel?: string;
  cancelLabel?: string;
  nonTranslateMessage?: boolean;
  icon?: React.ReactElement;
  isDelete?: boolean;
}> = ({
  open,
  onClose,
  onOKClick,
  title,
  message,
  okLabel,
  cancelLabel,
  nonTranslateMessage = false,
  icon = undefined,
  isDelete = false,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const dialogMessage = nonTranslateMessage ? message : translate(message);
  const withIconMessage = (
    <div className={classes.iconRoot}>
      <div className={classes.icon}>{icon}</div>
      {dialogMessage}
    </div>
  );
  const buttonClass = isDelete ? classes.delete : classes.button;
  const iconComponent = isDelete ? <DeleteIcon /> : <OkIcon />;

  return (
    <Dialog open={open} onClose={onClose}>
      <MuiDialogTitle className={classes.header}>
        {translate(title)}
      </MuiDialogTitle>
      <MuiDialogContent className={classes.content}>
        {icon === undefined ? dialogMessage : withIconMessage}
      </MuiDialogContent>
      <MuiDialogActions className={classes.footer}>
        <NormalButton onClick={onClose} label={cancelLabel}>
          <ReturnIcon />
        </NormalButton>
        <Button
          className={buttonClass}
          onClick={(e: SyntheticEvent) => {
            onOKClick(e);
            onClose(e);
          }}
          data-testid={okLabel}
        >
          {iconComponent}
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};

DialogOKCancel.defaultProps = {
  okLabel: 'admin.actions.ok',
  cancelLabel: 'admin.actions.cancel',
};
DialogOKCancel.displayName = 'DialogOKCancel';
export default DialogOKCancel;
