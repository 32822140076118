import React from 'react';
import { useParams } from 'react-router-dom';
import {
  useUpdateMany,
  useUnselectAll,
  useNotify,
  Identifier,
} from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { BulkActionButtonProps } from '../bases/list/List';
import { Button } from '../bases';
import { useJumpTo } from '../../hooks';
import { colors } from '../../theme';
import { AddIcon } from '../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: colors.button.cancel,
      minWidth: 160,
      minHeight: 36,
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
      },
    },
  }),
);

const useId = () => {
  const { siteId } = useParams<{ siteId: string }>();
  return siteId;
};

type BucketAddToSiteActionButtonProps =
  React.ComponentProps<BulkActionButtonProps>;
const BucketAddToSiteActionButton: React.FC<
  BucketAddToSiteActionButtonProps & {
    disabled?: boolean;
  }
> = props => {
  const { selectedIds, disabled, undoable } = props;
  const ids = selectedIds as Identifier[];
  const unselectAll = useUnselectAll('buckets');
  const notify = useNotify();
  const id = useId();
  const classes = useStyles();
  const { gotoShowTab } = useJumpTo();
  // NOTE: ※ 送信を未然に防ぐ処理の一例。
  //          デメリットはチェックをつけるたびにリクエストが発生すること
  // const { data } = useGetMany('buckets', selectedIds as number[]);
  // NOTE: ※ updateMany にこちらを渡す
  // const selectedIdsWithoutDuplication = data
  //   .filter(
  //     (item: { [k: string]: unknown }) =>
  //       item && isBlank(item.siteId as string | null),
  //   )
  //   .map(item => item.id);
  // NOTE: ※ 別のアプローチのアイデアとして、チェックボックスの状態変化時に
  //          State に selectedIds を蓄積していく方法や、
  //          チェック時に上のバリデーションを走らせる方法などが思いつく。
  const [updateMany, { loading }] = useUpdateMany(
    'buckets',
    ids,
    // selectedIdsWithoutDuplication as [React.ReactText],
    {
      siteId: id,
    },
    {
      undoable,
      onSuccess: () => {
        // TODO: べた書きを直す
        gotoShowTab('/sites', id, 'buckets');
        unselectAll();
        notify(
          'admin.message.success',
          'info',
          {
            smart_count: ids.length,
          },
          undoable,
        );
      },
      onFailure: (error: Error) => notify(`Error: ${error.message}`, 'warning'),
    },
  );
  return (
    <Button
      data-testid="admin.actions.add"
      onClick={updateMany}
      disabled={loading || disabled}
      classes={classes}
    >
      <AddIcon />
    </Button>
  );
};

BucketAddToSiteActionButton.displayName = 'BucketAddToSiteActionButton';
export default BucketAddToSiteActionButton;
