import { AdminApiConverter } from '..';

const converter: AdminApiConverter<'bucketShares', 'bucketShares'> = {
  resource: 'bucketShares',
  defaultSort: { field: 'fromBucketId', order: 'DESC' },
  filterToApi: filter => filter,
  sortFieldToApi: () => 'id',
  recordsFromApi: async records =>
    records.map(record => ({
      id: record.fromBucketId,
      fromBucketId: record.fromBucketId,
      corporationId: record.corporationId,
      name: record.name,
    })),
  recordToApi: record => ({
    fromBucketId: record.fromBucketId,
    name: record.name,
    corporationId: record.corporationId,
  }),
};

export default converter;
