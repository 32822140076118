import {
  AdminApiConverter,
  ApiFilter,
  ApiRecord,
  ViewRecord,
  getRestDataProvider,
  File as FileApi,
} from '..';

const fileStatus = (
  record: ApiRecord<'buckets'>,
): ViewRecord<'buckets'>['fileStatus'] => {
  const { bk3File, gltfFile } = record;
  if (bk3File && bk3File.blobFileStatus === 'WaitForCreate') {
    return 'Converting';
  }
  if (gltfFile) {
    if (gltfFile.blobFileStatus === 'WaitForCreate') {
      return 'Converting';
    }
    if (gltfFile.blobFileStatus === 'Created') {
      return 'Completed';
    }
  }
  return 'Error';
};

const getFile = async (url: string) => {
  const response = await fetch(url);
  return response.text();
};

const converter: AdminApiConverter<'buckets', 'buckets'> = {
  resource: 'buckets',
  defaultSort: { field: 'lastUpdated', order: 'DESC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'buckets'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('corporationId' in filter)
      apiFilter.corporationId = filter.corporationId;
    if ('name' in filter) apiFilter.name = filter.name;
    if ('siteId' in filter) apiFilter.siteId = filter.siteId;
    if ('bucketType' in filter) apiFilter.bucketType = filter.bucketType;
    if ('uploadedBy' in filter) apiFilter.updateUser = filter.uploadedBy;
    if ('lastUpdated' in filter) apiFilter.updateDate = filter.lastUpdated;
    if ('fileStatus' in filter) {
      console.error('FileStatus is not supoorted for fileter of Bucket');
    }
    return apiFilter;
  },
  sortFieldToApi: field => {
    if (field === 'fileStatus') return 'id';
    if (field === 'lastUpdated') return 'updateDate';
    if (field === 'updatedBy') return 'updateUser';
    return field;
  },
  prepareParam: async record => {
    const { bk3File, gltfFile } = record;
    if (bk3File) {
      const { id, rawFile, title, src: url } = bk3File;
      if (id) return record;
      if (rawFile || url) {
        // BK3ファイルの新規追加、またはコピー
        const dataProvider = getRestDataProvider();
        const data: FileApi = {
          blobFileType: 'BucketBk3',
          name: title,
        };
        const result = await dataProvider.create('files', { data });
        const apiData = result.data as FileApi;
        return {
          ...record,
          bk3File: {
            ...bk3File,
            id: apiData.id || '',
            src: apiData.url || '',
            rawFile: rawFile || new File([await getFile(url)], title),
          },
          gltfFile: undefined,
        };
      }
    }

    if (gltfFile) {
      const { id, title, src: url } = gltfFile;
      if (id) return record;
      // glTFファイルをコピー
      const content = await getFile(url);
      const dataProvider = getRestDataProvider();
      const data: FileApi = {
        blobFileType: 'BucketGltf',
        name: title,
      };
      const result = await dataProvider.create('files', { data });
      const fileData = result.data as FileApi;
      if (!fileData.url) throw new Error("Can't get upload URL");
      if (!fileData.id) throw new Error("Can't get file ID");
      await fetch(fileData.url, {
        method: 'PUT',
        body: content,
        headers: { 'Content-Type': 'application/octet-stream' },
      });
      // File APIのUpdateを呼んでステータスを更新させる
      await dataProvider.update('files', {
        id: fileData.id,
        data,
        previousData: { ...data, id: fileData.id },
      });
      return {
        ...record,
        gltfFile: { ...gltfFile, id: fileData.id },
      };
    }

    return record;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(record => ({
        id: record.id || 0,
        corporationId: record.corporationId || '',
        name: record.name,
        bucketType: record.bucketType,
        bk3File: {
          id: record.bk3FileId || '',
          src: record.bk3File?.url || '',
          title: record.bk3File?.name || '',
        },
        gltfFile: {
          id: record.gltfFileId || '',
          src: record.gltfFile?.url || '',
          title: record.gltfFile?.name || '',
        },
        siteId: record.siteId === '' ? undefined : record.siteId,
        uploadedBy: record.uploadedBy,
        fileStatus: fileStatus(record),
        lastUpdated: record.updateDate || '',
        updatedBy: (record.updateUser && decodeURI(record.updateUser)) || '',
      })),
    ),
  recordToApi: record => ({
    name: record.name,
    corporationId: record.corporationId,
    siteId: record.siteId,
    bk3FileId: record.bk3File?.id,
    gltfFileId: record.gltfFile?.id,
    bucketType: record.bucketType,
    uploadedBy: record.uploadedBy,
  }),
  postResult: async ({ request, response }) => {
    if (!request.bk3File) return response;
    const { rawFile, src: url } = request.bk3File;
    if (!rawFile) return response;
    if (!url) return response;
    await fetch(url, {
      method: 'PUT',
      body: rawFile,
      headers: { 'Content-Type': 'application/octet-stream' },
    });
    return response;
  },
  embed: ['bk3File', 'gltfFile'],
};

export default converter;
