import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { Typography } from '../../..';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: 14,
      fontWeight: 'bold',
    },
  }),
);

interface Props {
  label: string;
}

const Message: FC<Props> = ({ label }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const text = translate(label);
  return <Typography className={classes.root}>{text}</Typography>;
};

Message.displayName = 'Message';
export default Message;
