import { AdminApiConverter, ApiFilter } from '..';

const converter: AdminApiConverter<'geofences', 'geofences'> = {
  resource: 'geofences',
  defaultSort: { field: 'lastUpdated', order: 'DESC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'geofences'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('name' in filter) apiFilter.name = filter.name;
    if ('siteId' in filter) apiFilter.siteId = filter.siteId;
    if ('geofenceId' in filter) apiFilter.geofenceId = filter.geofenceId;
    if ('type' in filter) apiFilter.type = filter.type;
    if ('dimension' in filter) apiFilter.dimension = filter.dimension;
    if ('valid' in filter) apiFilter.valid = filter.valid;
    if ('alertType' in filter) apiFilter.alertType = filter.alertType;
    if ('points' in filter) {
      console.error('Points is not supported for filter of Geofences');
    }
    if ('lastUpdated' in filter) apiFilter.updateDate = filter.lastUpdated;
    return apiFilter;
  },
  sortFieldToApi: field => {
    if (field === 'lastUpdated') return 'updateDate';
    if (field === 'points') throw new Error('Unsupported sort field: points');
    if (field === 'latLons') throw new Error('Unsupported sort field: latLons');
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(record => ({
        id: record.id,
        corporationId: record.corporationId,
        siteId: record.siteId,
        geofenceId: record.geofenceId,
        type: record.type,
        name: record.name,
        dimension: record.dimension,
        trigger: record.trigger,
        valid: record.valid,
        alertType: record.alertType,
        lastUpdated: record.updateDate || '',
        startDate: record.startDate,
        endDate: record.endDate,
        transparency: record.transparency,
        rgbRed: record.rgbRed,
        rgbGreen: record.rgbGreen,
        rgbBlue: record.rgbBlue,
        elevation: record.elevation,
        height: record.height,
        thickness: record.thickness,
        radius: record.radius,
        geofenceFile: {
          id: record.geofenceFileId || '',
          src: record.geofenceFile?.url || '',
          title: record.geofenceFile?.name || '',
        },
        latLons: record.latLons,
        points: record.points,
        uploadedBy: record.uploadedBy,
      })),
    ),
  recordToApi: record => ({
    corporationId: record.corporationId,
    siteId: record.siteId,
    type: record.type,
    name: record.name,
    dimension: record.dimension,
    trigger: record.trigger,
    valid: record.valid,
    alertType: record.alertType,
    startDate: record.startDate,
    endDate: record.endDate,
    transparency: record.transparency,
    rgbRed: record.rgbRed,
    rgbGreen: record.rgbGreen,
    rgbBlue: record.rgbBlue,
    elevation: record.elevation,
    height: record.height,
    thickness: record.thickness,
    radius: record.radius,
    points: record.points,
    uploadedBy: record.uploadedBy,
  }),
  embed: ['geofenceFile'],
};

export default converter;
