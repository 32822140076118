import React, { FC } from 'react';
import {
  EditContextProvider,
  ResourceContextProvider,
  useCheckMinimumRequiredProps,
  useEditController,
  EditView,
} from 'react-admin';
import { pick } from 'lodash';
import { Record } from '../..';

interface Props {
  resource?: string;
  // Form に流し込む前に Record の加工を行い場合に利用する変換メソッド
  transform?: (record: Record<any>) => Record<any>;
}

const CustomEdit: FC<Props> = ({ transform, ...props }) => {
  // 基本実装は React-Admin の <Edit> コンポーネントを参考
  //   - https://github.com/marmelab/react-admin/blob/43c4fafc8bcedebc386c7d3dc3b63cfd56420a17/packages/ra-ui-materialui/src/detail/Edit.tsx
  useCheckMinimumRequiredProps('Edit', ['children'], props);
  const controllerProps = useEditController(props);
  const newControllerProps =
    transform && controllerProps.record
      ? {
          ...controllerProps,
          record: transform(controllerProps.record),
        }
      : controllerProps;
  const body = (
    <EditContextProvider value={newControllerProps}>
      <EditView
        {...pick(props, ['children', 'id', 'basePath', 'resource', 'location'])}
        {...newControllerProps}
      />
    </EditContextProvider>
  );
  return props.resource ? (
    <ResourceContextProvider value={props.resource}>
      {body}
    </ResourceContextProvider>
  ) : (
    body
  );
};

CustomEdit.displayName = 'CustomEdit';
export default CustomEdit;
