import { createTheme } from '@material-ui/core/styles';
import { colors } from '.';

const muiCommonTheme = () =>
  createTheme({
    palette: {
      // MEMO: 公式 Material-UI では、アイコンの色が個別で変えられなくなっている（おそらくバグ）ので、
      //       type: dark を使用することで、アイコンを白くしている。
      // MEMO: https://marmelab.com/react-admin/Theming.html#using-a-custom-menu
      //       左メニューのカスタマイズをしたい場合は独自のメニューコンポーネントを作る必要がある
      type: 'dark',
      // dark を以下の通りに上書き
      background: {
        paper: colors.backgroud,
        default: colors.backgroud,
      },
      primary: {
        main: colors.header,
        contrastText: 'rgba(255, 255, 255, 1)',
      },
      secondary: {
        main: colors.menu,
        contrastText: colors.text.primaryLight,
      },
      error: {
        main: '#f44336',
        contrastText: colors.text.primaryLight,
      },

      text: {
        primary: 'rgba(255, 255, 255, 0.87)',
        secondary: 'rgba(197, 197, 197, 1)',
        disabled: 'rgba(122, 122, 122, 1)',
        hint: 'rgba(255, 255, 255, 0.38)',
        // icon: "rgba(255, 255, 255)"
      },
      action: {
        hover: colors.selectedMenu,
      },
    },
    typography: {
      fontFamily: 'Noto Sans CJK JP',
      // Content Headerで利用
      h2: {
        fontSize: 18,
        fontWeight: 'normal',
        letterSpacing: 0,
      },
      // お知らせタイトルで利用
      h6: {
        fontSize: 16,
        fontWeight: 'bold',
      },
      subtitle2: {
        fontSize: 12,
        fontWeight: 'bold',
      },
    },
  });

export default muiCommonTheme;
