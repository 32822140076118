import React, { FC, useCallback, useEffect, useState } from 'react';
import Overlay from 'ol/Overlay';
import { usePermissions } from 'react-admin';
import { MapProps } from '../../../../hooks';
import GeofenceAlertMapField, {
  GeofenceMapFieldProps,
} from './GeofenceAlertMapField';
import { createGeometryFromFeature } from '../../../../utils';
import { geofenceStore } from '../../geofences/utils/index';
import { ProjectsData } from '../../../../dataProvider';
import {
  GeofenceListProjectInput,
  GeofenceListProjectLayerInput,
} from '../../geofences/view';

const COORDINATE_INPUT_ID = 'coordinate-input';

type Props = GeofenceMapFieldProps;
type AddProps = {
  source: string;
  siteId: string;
  geofenceId: number;
  form?: any;
};

const GeofenceAlertMapShow: FC<Props & AddProps> = props => {
  const {
    geofenceId,
    mapController,
    source: paramName,
    siteId,
    form,
    mapBackgroundColor,
  } = props;
  const featureId = geofenceId;
  const { permissions } = usePermissions();
  const [projectsData, setProjectsData] = useState<ProjectsData>();
  const [projectLayerId, setProjectLayerId] = useState<number>();

  const handleChangeProject = useCallback(
    pd => {
      setProjectsData(pd);
      form.change('layerId', null);
      // ローカルストレージに選択プロジェクトを記録
      const storedGeofence = geofenceStore.get();
      const projectSourceLayerId =
        storedGeofence?.projectId === pd.id
          ? storedGeofence?.projectSourceLayerId
          : undefined;
      setProjectLayerId(projectSourceLayerId);
      geofenceStore.set({
        siteId,
        projectId: pd.id,
        projectSourceLayerId,
      });
    },
    [form, siteId],
  );
  const handleChangeLayer = useCallback(
    ({ mapServerProjectSourceLayerId }) => {
      setProjectLayerId(mapServerProjectSourceLayerId);
      // ローカルストレージに選択レイヤを記録
      geofenceStore.set({
        siteId,
        projectId: projectsData?.id,
        projectSourceLayerId: mapServerProjectSourceLayerId,
      });
      // 選択したレイヤーにあわせたboundary再設定
      const projectLayers = projectsData?.projectSourceLayers;
      if (projectLayers) {
        // projectSourceLayersには全レイヤー情報が含まれているため、選択されたレイヤーの情報を選択
        const targetLayers = projectLayers.filter(
          layer =>
            layer.mapServerProjectSourceLayerId ===
            mapServerProjectSourceLayerId,
        );

        if (targetLayers && targetLayers.length > 0) {
          const targetBoundary = targetLayers[0].projectBoundary;
          if (!targetBoundary) return;

          // projectBoundary内の各座標データをBoundary調整できる形式に変換
          const boundaryLonLat = [
            {
              lon: targetBoundary.northEastLon,
              lat: targetBoundary.northEastLat,
            },
            {
              lon: targetBoundary.northWestLon,
              lat: targetBoundary.northWestLat,
            },
            {
              lon: targetBoundary.southWestLon,
              lat: targetBoundary.southWestLat,
            },
            {
              lon: targetBoundary.southEastLon,
              lat: targetBoundary.southEastLat,
            },
          ];
          mapController.setBoundary(boundaryLonLat);
        }
        // Homeボタン押下時と同じ動作を実施
        mapController.navigateToHome(0);
      }
    },
    [siteId, mapController, projectsData],
  );

  useEffect(() => {
    mapController.setAddOnEventEnd(event => {
      switch (event) {
        case 'loaded':
        case 'draw':
        case 'translate':
        case 'modify':
        case 'delete':
          {
            const { sourceVector } = mapController.getMapProps();
            const feature = sourceVector.getFeatureById(`${featureId}`);
            if (!feature) return;
            const geometry = createGeometryFromFeature(feature, 'Point');
            if (!geometry) return;

            mapController.setSelectFeature(featureId);
          }
          break;
        default:
      }
    });
  }, [mapController, featureId]);

  const handleConfigre = useCallback((_mapProps: MapProps) => {
    const { map } = _mapProps;
    const mapOverlay = new Overlay({
      element: document.getElementById(COORDINATE_INPUT_ID)!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
    });
    map.addOverlay(mapOverlay);

    const removeTarget = () => {
      mapOverlay.setPosition(undefined);
    };

    map.on('pointerdrag', () => removeTarget());
  }, []);

  useEffect(() => {
    mapController.removeProjectSourceLayer();
    if (!permissions) return;
    if (!projectsData?.latestProjectVersionId) return;
    if (!projectLayerId) return;
    const { token } = permissions;
    const projectVersionId = projectsData?.latestProjectVersionId;
    const projectLineWorkIds = projectsData?.projectSourceLineWorks.map(
      x => x.mapServerProjectSourceLineWorkId,
    );
    mapController.setProjectSourceLayer(
      projectLayerId,
      projectVersionId,
      token,
    );
    mapController.setProjectSourceLayerLabelPoint(projectVersionId, token);
    mapController.setProjectSourceLayerPointLabel(projectVersionId, token);
    mapController.setProjectSourceLocationPoint(projectVersionId, token);
    mapController.setProjectSourceLocationLabel(projectVersionId, token);
    if (!projectLineWorkIds) return;
    projectLineWorkIds.forEach(x => {
      mapController.setProjectSourceLineWork(x, projectVersionId, token);
    });
  }, [permissions, projectsData, projectLayerId, mapController]);

  return (
    <div>
      <div>
        {siteId && (
          <GeofenceListProjectInput
            siteId={siteId}
            onChangeProject={handleChangeProject}
          />
        )}
        {siteId && projectsData && (
          <GeofenceListProjectLayerInput
            projectsData={projectsData}
            onChangeLayer={handleChangeLayer}
          />
        )}
      </div>
      <div className={`${paramName}Imple`} style={{ display: 'flex' }}>
        <GeofenceAlertMapField
          {...props}
          onConfigure={handleConfigre}
          height={405}
          width={960}
          featureId={featureId}
          mapBackgroundColor={mapBackgroundColor}
        />
      </div>
    </div>
  );
};

GeofenceAlertMapShow.defaultProps = {
  label: 'resources.geofences.fields.points',
  addLabel: true,
  isRequired: true,
};
GeofenceAlertMapShow.displayName = 'GeofenceAlertMapShow';
export default GeofenceAlertMapShow;
