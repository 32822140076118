import React from 'react';
import ReactDOM from 'react-dom';
import { Control } from 'ol/control';
import { MuiThemeProvider } from '@material-ui/core';
import { getDefaultTheme } from '../../theme';

type OverlayOption = {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
};

const defaultOption: OverlayOption = {
  left: undefined,
  right: undefined,
  top: undefined,
  bottom: undefined,
};

let isRender = false;
const setOverlayRender = (flag: boolean) => {
  isRender = flag;
};

const crearteOverlayControl = (
  elementId: string,
  jsxElement: React.ReactElement,
  option: OverlayOption = defaultOption,
): Control => {
  const ret = new Control({});
  const elements = document.createElement('div');
  elements.id = elementId;
  elements.style.display = 'inline';
  elements.style.width = 'auto';
  elements.style.height = 'auto';
  if (option.left) {
    elements.style.left = `${option.left}px`;
  }
  if (option.top) {
    elements.style.top = `${option.top}px`;
  }
  if (option.right) {
    elements.style.right = `${option.right}px`;
  }
  if (option.bottom) {
    elements.style.bottom = `${option.bottom}px`;
  }
  elements.style.position = 'absolute';

  Control.call(ret, {
    element: elements,
    target: undefined,
    render: () => {
      if (!isRender) return;
      ReactDOM.render(
        <MuiThemeProvider theme={getDefaultTheme()}>
          <>{jsxElement}</>
        </MuiThemeProvider>,
        document.getElementById(elementId),
      );
    },
  });
  return ret;
};

export default crearteOverlayControl;
export { setOverlayRender };
