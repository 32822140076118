import { transform } from 'ol/proj';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';

// 補足:
//   - 地理座標系(EPSG:4326) ... 一般的なGPS等で得られた緯度経度の座標系
//   - メルカトル図法(EPSG:3857) ... OpenLayersが扱うデフォルトの座標系

/**
 * 地理座標系(EPSG:4326) → メルカトル図法(EPSG:3857) へ変換
 */
export const geographicToMercator = (src: number[]): number[] => {
  return transform(src, 'EPSG:4326', 'EPSG:3857');
};

/**
 * メルカトル図法(EPSG:3857) → 地理座標系(EPSG:4326) へ変換
 */
export const mercatorToGeographic = (src: number[]): number[] => {
  return transform(src, 'EPSG:3857', 'EPSG:4326');
};

/**
 * Feature → Coordinate へ変換 (From Polygon)
 */
export const featureToCoordinateFromPolygon = (
  feature: Feature,
): number[][][] => {
  const { coordinates } = new GeoJSON().writeFeatureObject(feature)
    .geometry as {
    coordinates: number[][][];
  };
  return coordinates;
};

/**
 * Feature → Coordinate へ変換 (From Line)
 */
export const featureToCoordinateFromLine = (feature: Feature): number[][] => {
  const { coordinates } = new GeoJSON().writeFeatureObject(feature)
    .geometry as {
    coordinates: number[][];
  };
  return coordinates;
};

/**
 * Feature → Coordinate へ変換 (From Point)
 */
export const featureToCoordinateFromPoint = (feature: Feature): number[] => {
  const { coordinates } = new GeoJSON().writeFeatureObject(feature)
    .geometry as {
    coordinates: number[];
  };
  return coordinates;
};
