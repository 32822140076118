import { CorporationData } from '../../types';

const corporations: CorporationData[] = [
  {
    id: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '株式会社クオリカ建築　テスト事業部',
    countryCode: 'JP',
  },
  {
    id: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    name: 'クオリカ',
    countryCode: 'JP',
  },
  {
    id: 'ef12300e-822d-4e8e-b820-2864288d0051',
    name: 'Komatsu Ltd.',
    countryCode: 'JP',
  },
  {
    id: '0da5298e-0081-08af-c9dd-3c8ae99fa742',
    name: 'クオリカその２',
    countryCode: 'JP',
  },
  {
    id: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    name: 'LANDLOG',
    countryCode: 'JP',
  },
  {
    id: '8715c009-aada-9c22-20e3-a63305b46db9',
    name: '会社名５０６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    countryCode: 'JP',
  },
];
export default corporations;
