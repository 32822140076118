import React, { FC, useState, useCallback } from 'react';
import { useTranslate, useDataProvider } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  LinkButton,
  CustomList,
  Datagrid,
  TextField,
  IconField,
  ReferenceField,
  Button,
  BulkActionConfirm,
  DialogOKOnly,
  UnitLengthField,
} from '../../../..';
import { colors } from '../../../../../theme';
import {
  useUpdateManyFn,
  useCreateMessage,
  useUnitLength,
} from '../../../../../hooks';
import { AddIcon, DeleteIcon } from '../../../../../assets';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../../../../theme/define';
import { UnitLength } from '../../../../../dataProvider';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingLeft: '16px',
            paddingRight: '15px',
            width: 16,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingLeft: '15px',
            paddingRight: '15px',
            width: 306,
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingLeft: '15px',
            paddingRight: '15px',
            width: 120,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingLeft: '15px',
            paddingRight: '15px',
            width: 120,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingLeft: '15px',
            paddingRight: '15px',
            width: 120,
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingLeft: '15px',
            paddingRight: '16px',
            width: 96,
            textAlign: 'center',
          },
        },
      },
    },
  }),
);

const useDeleteButtonStyles = makeStyles(() =>
  createStyles({
    button: {
      background: colors.button.delete,
      color: colors.text.primaryLight,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        background: colors.button.deleteHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.deleteDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
  }),
);

const Actions: FC<{
  basePath?: string;
}> = ({ basePath }) => (
  <LinkButton path={`${basePath}/create`} label="admin.actions.add">
    <AddIcon />
  </LinkButton>
);

interface RaBulkActionProps {
  realResource: string;
  resource: string;
  disabled: boolean;
  selectedIds: string[];
}

type DeleteButtonProps = Partial<RaBulkActionProps>;

const DeleteButton: FC<DeleteButtonProps> = ({
  realResource,
  resource,
  disabled,
  selectedIds,
}) => {
  if (!resource) throw Error('Invalid resource');
  if (!realResource) throw Error('Invalid realResource');
  if (!selectedIds) throw Error('Invalid selectedIds');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const deleteButtonClasses = useDeleteButtonStyles();
  const { createMessage } = useCreateMessage();
  const { updateMany } = useUpdateManyFn('ra.notification.deleted');
  const dataProvider = useDataProvider();

  const handleDelete = useCallback(() => {
    const fn = async () => {
      // リストで選択した compactionLayers リソースを取得する
      const { data } = await dataProvider.getMany(realResource, {
        ids: selectedIds,
      });
      const inUsed = data.filter(it => it.inUse).length > 0;
      if (inUsed) {
        setDialogOpen(true); // 転圧レイヤ 使用中: 削除不可
        return;
      }
      setConfirmOpen(true); // 転圧レイヤ 未使用: 削除可能
    };
    fn();
  }, [dataProvider, realResource, selectedIds, setDialogOpen, setConfirmOpen]);

  return (
    <>
      <DialogOKOnly
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title=""
        message="admin.dialog.compactionLayers.undeletable.message"
      />
      <BulkActionConfirm
        resource={resource}
        selectedIds={selectedIds}
        open={confirmOpen}
        message={createMessage(realResource)}
        onClose={() => setConfirmOpen(false)}
        onBulkAction={() =>
          updateMany(resource, selectedIds, { enable: false })
        }
        isDelete={true}
      />
      <Button
        data-testid="ra.action.delete"
        onClick={handleDelete}
        disabled={disabled}
        className={deleteButtonClasses.button}
      >
        <DeleteIcon />
      </Button>
    </>
  );
};

interface Props {
  basePath: string;
  compactionWorkAreaId: number;
  unitLength: UnitLength;
}

const CompactionLayerList: FC<Props> = ({
  basePath,
  compactionWorkAreaId,
  unitLength,
}) => {
  const resource = 'compactionLayers';
  const classes = useStyles();
  const translate = useTranslate();
  const { toDisplayUnitLength } = useUnitLength();

  const thicknessLabel = translate('admin.label.compactionLayers.thickness', {
    unit: toDisplayUnitLength(unitLength),
  });
  const roundUpDigits = 3; // 小数第四位で四捨五入し小数第三位で扱う

  return (
    <CustomList
      controllerResource={`${resource}_${compactionWorkAreaId}`}
      className={classes.root}
      breadcrumbsVisible={false}
      basePath={basePath}
      resource={resource}
      title="admin.pages.CompactionLayerList"
      actions={<Actions />}
      filter={{ compactionWorkAreaId: [compactionWorkAreaId], enable: [true] }}
      bulkActionButtons={<DeleteButton />}
    >
      <Datagrid rowClick="edit">
        <TextField resource={resource} source="name" />
        <UnitLengthField
          resource={resource}
          source="thickness"
          label={thicknessLabel}
          unitLength={unitLength}
          roundUpDigits={roundUpDigits}
        />
        <TextField resource={resource} source="passCount" />
        <ReferenceField
          resource={resource}
          source="compactionMaterialId"
          reference="compactionMaterials"
          link=""
        >
          <TextField resource="compactionMaterials" source="name" />
        </ReferenceField>
        <IconField
          resource={resource}
          source="inUse"
          positionLastColumn={true}
        />
      </Datagrid>
    </CustomList>
  );
};

CompactionLayerList.displayName = 'CompactionLayerList';
export default CompactionLayerList;
