import { AdminApiConverter, ApiFilter } from '..';

const converter: AdminApiConverter<'tasks', 'tasks'> = {
  resource: 'tasks',
  defaultSort: { field: 'id', order: 'ASC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'tasks'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('corporationId' in filter)
      apiFilter.corporationId = filter.corporationId;
    if ('siteId' in filter) apiFilter.siteId = filter.siteId;
    if ('projectId' in filter) apiFilter.projectId = filter.projectId;
    if ('projectVersionId' in filter)
      apiFilter.projectVersionId = filter.projectVersionId;
    if ('projectSourceLayerId' in filter)
      apiFilter.projectSourceLayerId = filter.projectSourceLayerId;
    if ('name' in filter) apiFilter.name = filter.name;
    if ('status' in filter) apiFilter.status = filter.status;

    return apiFilter;
  },
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(record => {
        if (!record.id) throw new Error("Can't get id from API");
        if (!record.status) throw new Error("Can't get status from API");
        if (record.rectangle.type !== 'Polygon')
          throw new Error('Rectangle from API type mismatch');
        return {
          id: record.id,
          corporationId: record.corporationId || '',
          siteId: record.siteId || '',
          projectId: record.projectId,
          projectVersionId: record.projectVersionId,
          projectSourceLayerId: record.projectSourceLayerId,
          name: record.name,
          description: record.description,
          rectangle: {
            type: 'Polygon',
            coordinates: record.rectangle.coordinates,
          },
          retrofitIds: record.retrofitIds || [],
          status: record.status,
          errorInfo: record.errorInfo || [],
          projectZipFileId: record.projectZipFileId,
          projectZipFile: {
            id: record.projectZipFileId || '',
            src: record.projectZipFile?.url || '',
            title: record.projectZipFile?.name || '',
          },
          expiredDate: record.expiredDate || '',
          lastUpdated: record.updateDate,
        };
      }),
    ),
  recordToApi: record => ({
    corporationId: record.corporationId,
    siteId: record.siteId,
    projectId: record.projectId,
    projectVersionId: record.projectVersionId,
    projectSourceLayerId: record.projectSourceLayerId,
    name: record.name,
    rectangle: record.rectangle,
    description: record.description,
    retrofitIds: record.retrofitIds || [],
  }),
  embed: ['ProjectZipFile'],
};

export default converter;
