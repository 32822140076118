const KEY_AGREEMENT_CACHE = 'agreement_cache';

const agreementCacheStore = {
  get: () => {
    const flag = localStorage.getItem(KEY_AGREEMENT_CACHE);
    if (flag === undefined) return false;
    return flag === 'true';
  },
  set: (flag: boolean) => {
    localStorage.setItem(KEY_AGREEMENT_CACHE, flag ? 'true' : 'false');
  },
};

export default agreementCacheStore;
