import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CustomList,
  Datagrid,
  CustomDateField,
  Filter,
  LinkButton,
  Loading,
  SiteSearchInput,
  TextField,
  ExtensionarmAddToSiteActionButton,
  ExtensionarmTypeField,
} from '../../..';
import { useAddInSite, useSpacerStyles } from '../../../../hooks';
import { ReturnIcon } from '../../../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 16,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 80,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 'auto',
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 200,
          },
        },
      },
    },
  }),
);

const ExtensionarmAddToSiteListFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

const BulkActionButtons: React.FC<{ basePath?: string }> = props => {
  const { basePath = '' } = props;
  const classes = useSpacerStyles();
  return (
    <>
      <LinkButton
        className={classes.button}
        path={basePath}
        data-testid="ra.action.cancel"
      >
        <ReturnIcon />
      </LinkButton>
      <span className={classes.root} />
      <ExtensionarmAddToSiteActionButton {...props} />
    </>
  );
};

const SiteExtensionarmCreate: FC = () => {
  const { props, site } = useAddInSite('extensionarms');
  const classes = useStyles();
  if (!site || !props) return <Loading />;
  const { name: siteName, corporationId } = site;
  return (
    <CustomList
      {...props}
      title="admin.pages.siteExtensionarmCreate"
      breadcrumbs={[
        'resources.sites.name',
        '',
        siteName,
        'resources.extensionarms.name',
      ]}
      breadcrumbsTitle="admin.actions.add"
      bulkActionButtons={<BulkActionButtons />}
      filters={<ExtensionarmAddToSiteListFilter />}
      filter={{ corporationId, siteId: '' }}
      sort={{ field: 'siteId', order: 'ASC' }}
    >
      <Datagrid className={classes.root}>
        <ExtensionarmTypeField
          resource="extensionarms"
          source="extensionarmType"
        />
        <TextField resource="extensionarms" source="name" />
        <CustomDateField
          resource="extensionarms"
          source="lastUpdated"
          showTime
        />
      </Datagrid>
    </CustomList>
  );
};

SiteExtensionarmCreate.displayName = 'SiteExtensionarmCreate';
export default SiteExtensionarmCreate;
