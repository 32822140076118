import { GeofenceAlertsData } from '../../types';

const data: GeofenceAlertsData[] = [
  {
    id: 1,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    retrofitId: 1,
    geofenceId: 'aa722951-5301-4092-a46b-5f20a0b3199c',
    geofenceName: 'テスト-polygon',
    alertType: 'Notice',
    collisionPoint: 'Body',
    longitude: 1.23,
    latitude: 4.56,
    height: 5,
    x: 15554.203322826104,
    y: -6071.6214769686508,
    z: 3.3,
    timeStamp: '2020-01-01T01:01:01.000Z',

    retrofit: {
      machineInfoMachineName: 'PC200-10-0000016789012345678901234567890',
      basicInfoManufacturer: 'Komatsu Ltd.',
      basicInfoModel: 'RF001-01',
      basicInfoProductNumber: 'PROD001',
      basicInfoSerialNumber: 'SERIAL_1',
    },
  },
  {
    id: 5,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    retrofitId: 1,
    geofenceId: 'bb722951-5301-4092-a46b-5f20a0b3199d',
    geofenceName: 'テスト-circle',
    alertType: 'Attention', // "Notice" | "Attention" | "Caution" | "Warning" | "Danger"
    collisionPoint: 'Body',
    longitude: 1.23,
    latitude: 4.56,
    height: 5,
    x: 15554.203322826104,
    y: -6071.6214769686508,
    z: 3.3,
    timeStamp: '2020-01-01T01:01:01.000Z',

    retrofit: {
      machineInfoMachineName: 'PC200-10-0000016789012345678901234567890',
      basicInfoManufacturer: 'Komatsu Ltd.',
      basicInfoModel: 'RF001-01',
      basicInfoProductNumber: 'PROD001',
      basicInfoSerialNumber: 'SERIAL_1',
    },
  },
  {
    id: 8,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    retrofitId: 2,
    geofenceId: 'da685121-6f26-4836-ad11-b282b7f63ba0',
    geofenceName: 'ジオフェンスデータなし',
    alertType: 'Danger', // "Notice" | "Attention" | "Caution" | "Warning" | "Danger"
    collisionPoint: 'Body',
    longitude: 1.23,
    latitude: 4.56,
    height: 5,
    x: 15555.203322826104,
    y: -6070.6214769686508,
    z: 3.2,
    timeStamp: '2020-01-01T01:01:01.000Z',

    retrofit: {
      machineInfoMachineName: 'PC200-10-000002',
      basicInfoManufacturer: 'Komatsu Ltd.',
      basicInfoModel: 'RF001-01',
      basicInfoProductNumber: 'PROD002',
      basicInfoSerialNumber: 'SERIAL002',
    },
  },
];
export default data;
