import { UnknownObject } from './types';

/**
 * コンポーネントの props の型を指定のコンポーネント向けに最適化する
 * @param component propsを適用するコンポーネント
 * @param props 型を適用するコンポーネントのプロパティ
 */
const propsOf = (component: React.ComponentType, props: UnknownObject) =>
  props as React.ComponentProps<typeof component>;

export default propsOf;
