import { encode, ParsedUrlQueryInput } from 'querystring';
import { config } from '../../utils';
import { callbackUri, Token, verifyJwt } from '.';

const getExpireDate = (expiresIn: number): number =>
  Date.now() + expiresIn * 1000;
const { apiUri, clientId, clientSecret } = config.auth.landlog;
const uri = `${apiUri}/connect/token`;
const fetchOptions = (request: ParsedUrlQueryInput) => ({
  method: 'POST',
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  body: encode(request),
});

export default {
  get: async (code: string): Promise<Token> => {
    const request = {
      grant_type: 'authorization_code',
      code,
      redirect_uri: callbackUri(),
      client_id: clientId,
      client_secret: clientSecret,
    };

    const response = await fetch(uri, fetchOptions(request));
    if (!response.ok) return Promise.reject();
    const json = await response.json();
    const token: Token = {
      accessToken: json.access_token,
      refreshToken: json.refresh_token,
      tokenType: json.token_type,
      expireDate: getExpireDate(json.expires_in),
      idToken: json.id_token,
      scope: json.scope,
    };
    await verifyJwt(token.idToken);
    return token;
  },

  refresh: async (old: Token) => {
    const request = {
      grant_type: 'refresh_token',
      refresh_token: old.refreshToken,
      client_id: clientId,
      client_secret: clientSecret,
    };

    const response = await fetch(uri, fetchOptions(request));
    if (!response.ok) return Promise.reject();
    const json = await response.json();
    return {
      ...old,
      accessToken: json.access_token,
      tokenType: json.token_type,
      expireDate: getExpireDate(json.expires_in),
      scope: json.scope,
    };
  },
};
