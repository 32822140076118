import React from 'react';
import { makeStyles, createStyles, Dialog } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { ReactElementLike } from 'prop-types';
import { Button } from '../..';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../../theme/define';
import { colors } from '../../../theme';
import { OkIcon } from '../../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      fontSize: 18,
      margin: 0,
      padding: 24,
    },
    content: {
      padding: 0,
    },
    footer: {
      margin: 0,
      padding: 24,
    },
    dialog: {
      '& > div > div': {
        maxWidth: 750, // style.tsのMultiLineErrorInfoより大きめにする
        minHeight: 400,
        maxHeight: 550,
      },
    },
    button: {
      background: colors.button.save,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.saveHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.saveDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
      '& span': {
        padding: 0,
      },
    },
  }),
);

interface Props {
  open: boolean;
  onCloseButton?: () => void;
  onCloseDialog?: () => void;
  title?: string;
  okLabel?: string;
  children?: ReactElementLike;
  testId?: string;
}

const DialogOKOnlyMulitiLine: React.FC<Props> = ({
  open,
  onCloseButton,
  onCloseDialog,
  okLabel,
  children,
  testId,
}) => {
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={open}
        onClose={onCloseDialog}
        data-testid={testId}
        className={classes.dialog}
      >
        {open ? (
          <>
            <MuiDialogContent className={classes.content}>
              {children}
            </MuiDialogContent>
            <MuiDialogActions className={classes.footer}>
              <Button
                className={classes.button}
                data-testid={okLabel}
                onClick={onCloseButton}
                label=""
              >
                <OkIcon />
              </Button>
            </MuiDialogActions>
          </>
        ) : null}
      </Dialog>
    </>
  );
};

DialogOKOnlyMulitiLine.displayName = 'DialogOKOnlyMulitiLine';
DialogOKOnlyMulitiLine.defaultProps = {
  title: '',
  okLabel: '',
};
export default DialogOKOnlyMulitiLine;
