import { lineIntersect, lineString } from '@turf/turf';
import { mapWithNext } from '../../../../utils';

/**
 * ポリゴンの交差を検証するメソッド
 *
 * @param coordinates 座標配列
 *
 * @returns true: 交差している / false 交差していない
 */
const checkIntersectByArray = (coordinates: number[][]): boolean => {
  const coordinateLines = mapWithNext(coordinates, (cur, next) => [
    // 2点の緯度経度から線を構成する
    cur,
    next,
  ]);

  // 交差チェックは総当りでチェックする必要がある
  for (let i = 0; i < coordinateLines.length; i += 1) {
    for (let j = i + 1; j < coordinateLines.length; j += 1) {
      const a = coordinateLines[i];
      const b = coordinateLines[j];
      // 隣接している線を判定対象から除外する条件
      if (
        JSON.stringify(a[0]) !== JSON.stringify(b[0]) &&
        JSON.stringify(a[0]) !== JSON.stringify(b[1]) &&
        JSON.stringify(a[1]) !== JSON.stringify(b[0]) &&
        JSON.stringify(a[1]) !== JSON.stringify(b[1])
      ) {
        // 隣接していない線のみ交差判定を行う
        const result = lineIntersect(lineString(a), lineString(b));
        if (result.features.length > 0) {
          return true; // 交差している
        }
      }
    }
  }
  return false;
};

export default checkIntersectByArray;
