const KEY = 'before_url';
// 各種レトロフィット情報画面のURLパス※追加や変更がある場合は要編集
const URL_RETROFIT_INFOS = [
  'config',
  'kitinfo',
  'accuracies',
  'calibs',
  'basic_settings',
  'errors',
  'toposurveys',
];

const manageUrlStore = {
  getCurrent: () => {
    const rawUrl = window.location.href;
    return rawUrl.replace(window.location.origin, '');
  },
  getBefore: () => {
    return localStorage.getItem(KEY) || '';
  },
  set: () => {
    const rawUrl = window.location.href;
    const path = rawUrl.replace(window.location.origin, '');
    localStorage.setItem(KEY, path);
  },
  invalidAccess: (currentPaths: string[], beforePaths: string[]): boolean => {
    if (
      currentPaths.length === beforePaths.length &&
      currentPaths[currentPaths.length - 1] !==
        beforePaths[beforePaths.length - 1]
    ) {
      // ********************
      // 末尾が異なるURL
      // ********************
      // ■ グランドメニューやグランドメニュー直下のタブ、メニューからの画面は除外
      // _/#/[sites]
      // _/#/[retrofits]
      // _/#/[userNotices]
      if (currentPaths.length === 3) return false;

      // ■ 現場詳細のタブ表示は除外
      if (
        currentPaths.length >= 5 &&
        currentPaths[2] === 'sites' &&
        currentPaths[4] === 'show'
      ) {
        // 後続の除外判定と一致していても、同一タイミングで現場IDが書き換わっていたらNG
        //  以下、URL例の[]で囲んだ部分の変更チェック
        // _/#/sites/[65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4]/show/projects
        // _/#/sites/[65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4]/show/extensionarms
        if (currentPaths[3] !== beforePaths[3]) return true;

        // 現場詳細のタブ表示は除外
        //  以下、URL例の[]で囲んだ部分の変更を除外する
        // _/#/sites/65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4/show/[projects]
        // _/#/sites/65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4/show/[extensionarms]
        if (currentPaths.length === 6) return false;

        // 現場マスタ設定のタブ表示は除外
        //  以下、URL例の[]で囲んだ部分の変更を除外する
        // _/#/sites/65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4/show/master/[compactionMaterials]
        // _/#/sites/65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4/show/master/[operators]
        if (currentPaths.length === 7 && currentPaths[5] === 'master')
          return false;
      }

      // ■ Retrofit管理(管理者/一般)のタブ表示は除外
      //  以下、URL例の[]で囲んだ部分の変更を除外する
      // _/#/controllerRegistration/[photo]
      // _/#/controllerRegistrationAdmin/[bulkAction]
      if (
        currentPaths.length >= 4 &&
        (currentPaths[2] === 'controllerRegistration' ||
          currentPaths[2] === 'controllerRegistrationAdmin')
      ) {
        return false;
      }

      // ■ バケットの詳細画面にて、変更前が createのものは除外(作成画面から詳細画面へ遷移するパターンを許容)
      //  以下のような動作の際に、URL例の[]で囲んだ部分の変更を除外する
      // 遷移元： _/#/buckets/[create]
      // 遷移先： _/#/buckets/[245]
      //  ※遷移先のIDはcreateにより新規採番されたIDの値
      if (
        currentPaths.length === 4 &&
        currentPaths[2] === 'buckets' &&
        beforePaths[beforePaths.length - 1] === 'create'
      ) {
        // 末尾以外はすべて一致する必要がある（同一機能の作成画面から詳細画面に遷移してきたかのチェック）
        const unMatchUrl = currentPaths.map((value, index) => {
          // 末尾の差分は除外 ※固定でfalse返却
          if (index === beforePaths.length - 1) return false;
          // 末尾以外で一致していないパスが存在するとtrueが返却される
          return value !== beforePaths[index];
        });
        // 比較結果にtrueが含まれていない => 末尾以外のURLは一致しているので、作成画面を経由して表示された詳細画面と判断できるため除外
        if (!unMatchUrl.includes(true)) {
          // MEMO: このままだとbucketsの作成画面は全ての詳細画面へ不正遷移できてしまう抜け穴になるので対策を要検討
          return false;
        }
      }

      // 上記例外にあてはまらなかったものはNG
      //  以下、URL例の[]で囲んだ部分の変更チェックを意味する
      // _/#/buckets/[4]
      // _/#/sites/14b2149c-9d45-f3cb-bc93-7916ecc3778d/show/retrofits/[73]
      return true;
    }

    // ■ 現在のrootとなるURLとは異なる子画面を開くのはＮＧ
    //  以下のような動作の際に、URL例の[]で囲んだ部分の変更チェック
    // 遷移元： _/#/[buckets]/create
    // 遷移先： _/#/[sites]/245
    if (currentPaths.length >= 4 && beforePaths.length >= 3) {
      if (currentPaths[2] !== beforePaths[2]) {
        // ■ 現在の表示ページがサポートモード画面の場合は除外
        if (currentPaths.length === 4 && currentPaths[2] === 'supportMode')
          return false;

        // ■遷移元が検索した状態の場合あり
        if (beforePaths[2].includes('?')) {
          const pathInfo = beforePaths[2].split('?');
          // ■ 検索条件を除去した値が一致するのであればNG対象から除外
          if (currentPaths[2] === pathInfo[0]) return false;
        }

        return true;
      }
    }

    // ********************
    // 末尾以外のURL内のIDが異なる場合はNG
    // ********************
    // ■ 現場詳細関連
    if (
      currentPaths.length >= 5 &&
      beforePaths.length >= 4 &&
      currentPaths[2] === 'sites' &&
      currentPaths[4] === 'show'
    ) {
      // 現場詳細のタブ表示(URLの現場ID)
      //  以下、URL例の[]で囲んだ部分の変更チェック
      // _/#/sites/[65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4]/show
      // _/#/sites/[65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4]/show/buckets
      if (currentPaths[3] !== beforePaths[3]) return true;

      // 〇現場建機詳細の各レトロフィット情報画面（URLのレトロフィットID）
      //  以下、URL例の[]で囲んだ部分の変更チェック
      // _/#/sites/65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4/show/retrofits/[5]/basic_settings
      if (
        currentPaths.length === 8 &&
        beforePaths.length >= 7 &&
        currentPaths[5] === 'retrofits' &&
        URL_RETROFIT_INFOS.includes(currentPaths[7])
      ) {
        if (currentPaths[6] !== beforePaths[6]) return true;
      }

      // 〇タスク作成・詳細、転圧施工領域作成・詳細（URLのプロジェクトID）
      //  以下、URL例の[]で囲んだ部分の変更チェック
      // _/#/sites/14b2149c-9d45-f3cb-bc93-7916ecc3778d/show/projects/[2]/tasks/create
      // _/#/sites/14b2149c-9d45-f3cb-bc93-7916ecc3778d/show/projects/[2]/compactionWorkAreas/24
      if (
        currentPaths.length >= 9 &&
        beforePaths.length >= 7 &&
        currentPaths[5] === 'projects'
      ) {
        if (currentPaths[6] !== beforePaths[6]) return true;

        // 〇転圧レイヤー作成詳細（URLの転圧施工領域ID）
        //  以下、URL例の[]で囲んだ部分の変更チェック
        // _/#/sites/65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4/show/projects/11/compactionWorkAreas/[1]/1
        if (
          currentPaths.length >= 10 &&
          beforePaths.length >= 9 &&
          currentPaths[8] !== beforePaths[8]
        )
          return true;
      }
    }

    // ■ 建機関連の子画面(URLのレトロフィットID)
    if (
      currentPaths.length >= 5 &&
      beforePaths.length >= 4 &&
      currentPaths[2] === 'retrofits' &&
      currentPaths[3] !== beforePaths[3]
    ) {
      // 〇各種レトロフィット情報画面:length=5
      //  以下、URL例の[]で囲んだ部分の変更チェック
      // _/#/retrofits/[1]/toposurveys
      if (
        currentPaths.length === 5 &&
        URL_RETROFIT_INFOS.includes(currentPaths[4])
      ) {
        return true;
      }
      // 〇貸与タブ:length=5
      //  以下、URL例の[]で囲んだ部分の変更チェック
      // _/#/retrofits/[2]/1
      // 〇貸与詳細:length=6
      //  以下、URL例の[]で囲んだ部分の変更チェック
      // _/#/retrofits/[2]/1/20
      if (currentPaths[4] === '1') {
        return true;
      }
    }

    // ■ バケット共有(URLのバケットID)
    //  以下、URL例の[]で囲んだ部分の変更チェック
    // _/#/buckets/[6]/create
    if (
      currentPaths.length === 5 &&
      currentPaths[2] === 'buckets' &&
      currentPaths[4] === 'create' &&
      currentPaths[3] !== beforePaths[3]
    )
      return true;

    // ■ 上記チェック結果がいずれもOKな場合は、正常な遷移
    return false;
  },
};

export default manageUrlStore;
