import { GetOneParams, GetOneResult } from 'react-admin';
import { config } from '../../utils';
import { callApiRemoteStart, RemoteStartRemote } from '.';

const uri = `${config.api.remote.apiUri}/operators/support?remote_session_id=`;

export default async (params: GetOneParams): Promise<GetOneResult> => {
  const { id } = params;
  const inMac = id as string;

  const remotestart = await callApiRemoteStart(inMac.split('-').join(''));
  const data: RemoteStartRemote = {
    id: inMac,
    remoteSessionId: remotestart.remote_session_id,
    remoteSupportUrl: uri + remotestart.remote_session_id,
  };
  return { data };
};
