import React from 'react';
import { required, useTranslate } from 'react-admin';
import {
  Create,
  CustomForm,
  BreadcrumbsFiled,
  FileInput,
  FileField,
  LoadingView,
} from '../..';
import { useResource, usePageProps } from '../../../hooks';

const RetrofitBulkActionCreate: React.FC = () => {
  const translate = useTranslate();
  const props = usePageProps('retrofitBulkActions');
  const resource = useResource('retrofitBulkActions');

  return (
    <LoadingView>
      <Create {...props}>
        <CustomForm
          title="admin.pages.retrofitBulkActionCreate"
          resource={resource}
          initialValues={{}}
        >
          <BreadcrumbsFiled
            breadcrumbs={['resources.retrofitBulkActions.name']}
            label="ra.action.create"
          />
          <FileInput
            resource={resource}
            source="uploadFile"
            validate={[required()]}
            accept=".csv"
            placeholder={
              <p>
                {translate('admin.label.fileInput.plaseholder', {
                  fileTypes: translate('admin.label.fileInput.csv'),
                })}
              </p>
            }
          >
            <FileField source="src" title="title" />
          </FileInput>
        </CustomForm>
      </Create>
    </LoadingView>
  );
};

RetrofitBulkActionCreate.displayName = 'RetrofitBulkActionCreate';
export default RetrofitBulkActionCreate;
