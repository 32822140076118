import React, { FC, ComponentProps } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  useUpdateMany,
  useUnselectAll,
  useNotify,
  Identifier,
} from 'react-admin';
import { BulkActionButtonProps } from '../bases/list/List';
import { AddIcon } from '../../assets';
import { Button } from '..';
import { colors } from '../../theme';
import { useJumpTo } from '../../hooks';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: colors.button.cancel,
      minWidth: 160,
      minHeight: 36,
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
      },
    },
  }),
);

type Props = {
  disabled?: boolean;
} & ComponentProps<BulkActionButtonProps>;

const ExtensionarmAddToSiteActionButton: FC<Props> = ({
  selectedIds,
  undoable,
  disabled,
}) => {
  const classes = useStyles();
  const ids = selectedIds as Identifier[];
  const { siteId } = useParams<{ siteId: string }>();
  const unselectAll = useUnselectAll('extensionarms');
  const notify = useNotify();
  const { gotoShowTab } = useJumpTo();
  const [updateMany, { loading }] = useUpdateMany(
    'extensionarms',
    ids,
    {
      siteId,
    },
    {
      undoable,
      onSuccess: () => {
        gotoShowTab('/sites', siteId, 'extensionarms');
        unselectAll();
        notify(
          'admin.message.success',
          'info',
          {
            smart_count: ids.length,
          },
          undoable,
        );
      },
      onFailure: (error: Error) => notify(`Error: ${error.message}`, 'warning'),
    },
  );
  return (
    <Button
      data-testid="admin.actions.add"
      onClick={updateMany}
      disabled={loading || disabled}
      classes={classes}
    >
      <AddIcon />
    </Button>
  );
};

ExtensionarmAddToSiteActionButton.displayName =
  'ExtensionarmAddToSiteActionButton';
export default ExtensionarmAddToSiteActionButton;
