import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CustomList,
  Datagrid,
  CustomDateField,
  IconField,
  FunctionField,
  TextField,
  ListDownloadButton,
  Button,
  Filter,
  SiteSearchInput,
} from '../..';
import { useResource, useJumpTo } from '../../../hooks';
import { colors } from '../../../theme';
import { buttonWidth, buttonHeight } from '../../../theme/define';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 228,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 130,
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 130,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 80,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            // TODO: デザインナーのデザインだと100pxだが、ボタンが崩れてしまうため、暫定的に110pxで対応
            width: 110,
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
        },
      },
    },
    create: {
      backgroundColor: colors.button.cancel,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      color: colors.text.primaryLight,
      fontSize: 14,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
      },
      '& span': {
        padding: 0,
      },
    },
  }),
);

const SerchFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

const RetrofitBulkActionList: React.FC = props => {
  const classes = useStyles();
  const resource = useResource('retrofitBulkActions');
  const { gotoRetrofitBundleCreate } = useJumpTo();

  return (
    <CustomList
      {...props}
      resource={resource}
      title="admin.pages.retrofitBulkActionList"
      bulkActionButtons={false}
      filters={<SerchFilter />}
      actions={
        <Button
          className={classes.create}
          onClick={() => {
            gotoRetrofitBundleCreate('retrofitBulkActions');
          }}
          label="admin.actions.retrofitBundleCreate"
          data-testid="admin.actions.retrofitBundleCreate"
        />
      }
    >
      <Datagrid className={classes.root}>
        <TextField resource={resource} source="uploadFileName" />
        <CustomDateField resource={resource} source="execStartDate" showTime />
        <CustomDateField resource={resource} source="execEndDate" showTime />
        <IconField resource={resource} source="execStatus" />
        <FunctionField
          resource={resource}
          source="uploadFile"
          sortable={false}
          render={record => {
            const uploadFile = record?.uploadFile;
            const { src: url } = uploadFile || { src: '', title: '' };
            return (
              <ListDownloadButton
                label="admin.label.retrofitBulkActions.uploadFileDownload"
                url={url}
              />
            );
          }}
        />
        <FunctionField
          resource={resource}
          source="resultFile"
          sortable={false}
          render={record => {
            const resultFile = record?.resultFile;
            const { src: url } = resultFile || { src: '', title: '' };
            return (
              <ListDownloadButton
                label="admin.label.retrofitBulkActions.resultFileDownload"
                url={url}
              />
            );
          }}
        />
      </Datagrid>
    </CustomList>
  );
};

RetrofitBulkActionList.displayName = 'RetrofitBulkActionList';
export default RetrofitBulkActionList;
