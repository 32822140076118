import React, { FC, ChangeEvent } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { SelectInput, SimpleForm, ReferenceInput } from 'react-admin';
import Message from './Message';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginBottom: 32,
      '& label': {
        display: 'none',
      },
      '& .MuiSelect-root': {
        minHeight: 34,
      },
      '& > div:first-child': {
        paddingTop: 6,
      },
    },
    input: {
      marginLeft: 30,
    },
  }),
);

interface Props {
  onChange?: (id?: number) => void;
}

const RegionSelect: FC<Props> = ({ onChange }) => {
  const classes = useStyles();

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const id = target?.value ? Number(target.value) : undefined;
    if (onChange) onChange(id);
  };

  return (
    <>
      <Message label="admin.label.terms.select" />
      <SimpleForm toolbar={<></>} className={classes.root}>
        <ReferenceInput
          source="region"
          reference="termsConditions"
          sort={{ field: 'regionName', order: 'ASC' }}
          onChange={handleChange}
          className={classes.input}
        >
          <SelectInput optionText="regionName" />
        </ReferenceInput>
      </SimpleForm>
    </>
  );
};

RegionSelect.displayName = 'RegionSelect';
export default RegionSelect;
