import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useCustomGetOne } from '.';
import { SiteData, Resources } from '../dataProvider';

const useEditInSite = <T extends keyof Resources>(resource: T) => {
  // Editコンポーネントに渡すpropsを設定
  const location = useLocation();
  const { pathname } = location;
  const lastPathIndex = pathname.lastIndexOf('/');
  const basePath = location.pathname.slice(0, lastPathIndex);
  const { siteId, id } = useParams<{
    siteId: string;
    id: string;
  }>();
  const props = { location, basePath, resource, id, siteId };

  // Siteの情報
  const [site, setSite] = useState<SiteData>();
  const { data } = useCustomGetOne('sites', siteId);
  useEffect(() => {
    if (!data) return;
    setSite(data as SiteData);
  }, [data]);

  /* ここを以下のやり方に変えるとユニットテストでデータが取得できないので注意
  useGetOne('sites', siteId, {
    onSuccess: ({ data }: { data: SiteData }) => {
      setSite(data);
    },
  });
  */

  return { props, site };
};

export default useEditInSite;
