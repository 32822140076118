import React, { useCallback } from 'react';
import { useTranslate } from 'react-admin';

const useTranslateMultiLine = () => {
  const translate = useTranslate();
  return useCallback(
    (words: string) =>
      translate(words)
        .split('\n')
        .map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item}
              <br />
            </React.Fragment>
          );
        }),
    [translate],
  );
};

export default useTranslateMultiLine;
