import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { RetrofitRentalData } from '../dataProvider';

interface InParams {
  retrofitIds: number[];
}

interface OutParams {
  data: {
    isLending: boolean;
    rentalRetrofitIds: number[];
  };
}

const useRetrofitLending = () => {
  const dataProvider = useDataProvider();
  return useCallback(
    async ({ retrofitIds }: InParams): Promise<OutParams> => {
      const { data: rentals } = await dataProvider.getList<RetrofitRentalData>(
        'retrofitRentals',
        {
          pagination: {
            page: 1,
            perPage: 100,
          },
          sort: {
            field: 'id',
            order: 'DESC',
          },
          filter: {
            retrofitId: retrofitIds,
            isReturned: false,
          },
        },
      );

      return {
        data: {
          isLending: rentals.length > 0,
          rentalRetrofitIds: rentals.map(({ retrofitId }) => retrofitId),
        },
      };
    },
    [dataProvider],
  );
};

export default useRetrofitLending;
