import { withStyles, createStyles } from '@material-ui/core';
import SearchInput from '../../bases/input/SearchInput';
import { colors } from '../../../theme';

const styles = createStyles({
  input: {
    borderRadius: 3,
    '& .MuiSvgIcon-colorDisabled': {
      color: colors.text.primaryLight,
    },
  },
});

const SiteSearchInput = withStyles(styles)(SearchInput);
SiteSearchInput.displayName = 'SiteSearchInput';

export default SiteSearchInput;
