/* eslint-disable camelcase */
import { fetchUtils } from 'react-admin';
import { config } from '../../utils';
import { RemotePointAvailabilityApi } from '.';

const uri = `${config.api.remote.apiUri}/operators/start_point_availability`;

export default async (
  remoteSessionId: string,
): Promise<RemotePointAvailabilityApi> => {
  // JSONをkeyにてJSON変数名[kye名]で取得するためのinterface
  interface ParamKey {
    [key: string]: string | number;
    remote_session_id: string;
    point_availability: string;
  }
  const setParam: ParamKey = {
    // ■サポート接続セッションID
    remote_session_id: remoteSessionId,
    // ■指さしオンオフ制御
    // 0:無効,1:有効
    point_availability: '1',
  };
  const setbody = Object.keys(setParam)
    .map(key => `${key}=${encodeURIComponent(setParam[key])}`)
    .join('&');

  const { json } = await fetchUtils.fetchJson(uri, {
    method: 'POST',
    mode: 'no-cors',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
    body: setbody,
  });
  return json;
};
