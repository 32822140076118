export default {
  backgroud: '#29292f', // 全体背景色
  paper: '#565664', // カード、テーブル行の背景
  header: '#00011D',
  menu: '#31323A',
  line: '#F5F5FF33',
  selectedMenu: '#000344',
  text: {
    primaryLight: '#FFF',
    secondaryLight: '#FFFFFFD9',
  },
  supportMode: '#253FC8',
  adminMode: '#057F00',
  status: {
    waiting: '#C4C4C4',
    working: '#FFA121',
    completed: '#21FF32',
  },
  onlineStatus: {
    online: '#21FF32',
    offline: '#C4C4C4',
  },
  projectFileStatus: {
    waitForConvert: '#C4C4C4',
  },
  button: {
    save: '#1B89E2',
    saveHover: '#1462A2',
    saveDisabled: 'rgba(27, 137, 226, 0.7)',
    delete: '#E10009',
    deleteHover: '#A60007',
    deleteDisabled: 'rgba(225, 0, 9, 0.7)',
    cancel: '#717284',
    cancelHover: '#565664',
    cancelDisabled: 'rgba(113, 114, 132, 0.7)',
  },
  filterField: {
    color: '#17171A',
  },
  autoComplete: {
    suggestionList: '#31323a',
  },
  checkBox: {
    backgroundColor: '#294380',
  },
};
