import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useDataProvider, useTranslate, Record, Loading } from 'react-admin';
import {
  Edit,
  CustomForm,
  BreadcrumbsFiled,
  TextField,
  IconFieldPaddingZeroStyle,
  PropsConsumer,
  SelectInput,
} from '../..';
import { useResource } from '../../../hooks';
import { NtripSaveButton, NtripStatusField } from './views';

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      ...IconFieldPaddingZeroStyle,
    },
  }),
);

const NtripEdit: React.FC = () => {
  const classes = useStyles();
  const resource = useResource('ntrips');
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const base = 'resources.ntrips.ntrip';
  const { id: ntripId } = useParams<{ id: string }>();
  const props = {
    id: ntripId,
    basePath: '/ntrips',
    resource,
  };

  // TODO: URL遷移時の判定機能を追加する際に不要になるので削除する
  const [ntripRecord, setNtripRecord] = useState<Record>();
  useEffect(() => {
    if (!ntripId) return;
    const fn = async () => {
      const { data: ntripData } = await dataProvider.getOne('ntrips', {
        id: ntripId,
      });
      setNtripRecord(ntripData);
    };
    fn();
  }, [ntripId, dataProvider]);
  if (!ntripRecord) return <Loading />;
  const { ntrip } = ntripRecord;

  /* // TODO: データチェック処理の実装
  const getAccount = useAccount();
  const getSelefDetailData = useGetSelefDetailData();
  const redirectTo = useRedirect();

  const [apiState, setApiState] = useState<{
    fetched: boolean;
    loading: boolean;
    ntripId: string | undefined;
    data?: { isOwnData?: boolean; corporationId: string };
  }>({
    fetched: false,
    loading: true,
    ntripId: undefined,
    data: undefined,
  });
  */

  /* // TODO: データチェック処理の実装
  // 自社のntripか判定
  useEffect(() => {
    if (apiState.fetched && apiState.ntripId === ntripId) return;
    setApiState({ fetched: true, loading: true, ntripId });

    const api = async () => {
      // accounts
      const {
        data: { corporationId },
      } = await getAccount();
      const filter = { corporationId };
      const bucket = await getSelefDetailData(resource, filter, ntripId);
      return {
        isOwnData: !!bucket,
        corporationId,
      };
    };
    api().then(data => {
      setApiState({ fetched: true, loading: false, ntripId, data });
    });
  }, [
    apiState.fetched,
    apiState.ntripId,
    getAccount,
    getSelefDetailData,
    ntripId,
    redirectTo,
    resource,
  ]);

  if (apiState.loading || !apiState.data) return <Loading />;

  const { isOwnData } = apiState.data;
  if (!isOwnData) redirectTo('/');
  */

  return (
    <Edit {...props}>
      <CustomForm
        title="admin.pages.ntripEdit"
        deleteButton={false}
        className={classes.form}
        saveButton={<NtripSaveButton />}
      >
        <BreadcrumbsFiled
          breadcrumbs={['resources.ntrips.name']}
          label="ra.action.edit"
        />
        <TextField resource={resource} source="name" />
        {ntrip === null || ntrip === '削除(=返却済)' ? (
          <NtripStatusField label="resources.ntrips.fields.ntrip" />
        ) : (
          <PropsConsumer>
            {formProps => {
              const { record } = formProps;
              let ntripType = [{ id: record.ntrip, name: record.ntrip }];
              if (record.ntrip === '払出済') {
                // 払出済の場合は、停止のみ可能
                ntripType = [
                  { id: '払出済', name: translate(`${base}.publish`) },
                  { id: '停止', name: translate(`${base}.stop`) },
                ];
              } else if (record.ntrip === '停止') {
                // 停止の場合のみ、削除可能
                ntripType = [
                  { id: '停止', name: translate(`${base}.stop`) },
                  { id: '削除(=返却済)', name: translate(`${base}.delete`) },
                ];
              }
              return (
                <SelectInput
                  resource={resource}
                  source="ntrip"
                  choices={ntripType}
                  optionText="name"
                  resettable={false}
                />
              );
            }}
          </PropsConsumer>
        )}
        <TextField resource={resource} source="ntripPass" />
        <TextField resource={resource} source="customerCorporationName" />
        <TextField resource={resource} source="customerCorporationId" />
        <TextField resource={resource} source="serial" />
        <TextField resource={resource} source="numberOfExchanges" />
      </CustomForm>
    </Edit>
  );
};

NtripEdit.displayName = 'NtripEdit';
export default NtripEdit;
