import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Button } from '../../..';
import { mapStyle } from '../../../../theme';
import { MapLineOn, MapLineOff } from '../../../../assets/images';

const onUseStyle = makeStyles(() =>
  createStyles({
    root: {
      ...mapStyle.button,
      backgroundColor: '#000344',
    },
  }),
);

const offUseStyle = makeStyles(() =>
  createStyles({
    root: {
      ...mapStyle.button,
    },
  }),
);

const MapLineworkButton: React.FC<{
  defaultShowLineWork: boolean;
  onClick?: (showLineWork?: boolean) => void;
}> = ({ defaultShowLineWork, onClick }) => {
  const [showLineWork, setShowLineWork] =
    useState<boolean>(defaultShowLineWork);
  const onStyle = onUseStyle();
  const offStyle = offUseStyle();
  return (
    <Button
      onClick={() => {
        if (onClick) onClick(!showLineWork);
        setShowLineWork(!showLineWork);
      }}
      className={showLineWork ? onStyle.root : offStyle.root}
    >
      {showLineWork ? <MapLineOn /> : <MapLineOff />}
    </Button>
  );
};

MapLineworkButton.displayName = 'MapLineworkButton';
export default MapLineworkButton;
