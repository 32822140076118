import { Resources } from '../dataProvider';
import { useEditInSite } from '.';

const useCreateInSite = <T extends keyof Resources>(resource: T) => {
  const { props: editProps, site } = useEditInSite(resource);
  const props = site && {
    location: editProps.location,
    basePath: editProps.basePath,
    resource,
    controllerResource: `${resource}_${site.id}_add`,
    filter: { corporationId: site.corporationId },
  };

  return { props, site };
};

export default useCreateInSite;
