import { Feature } from 'ol';
import {
  MapController,
  Event,
  EventType,
  BindMapParams,
  LabelInfoList,
  ColorInfoRGB,
  LabelInfoInput,
  GeofenceTypeDictionary,
} from './types';
import { MapProps, useUnitLength } from '../../../../../hooks';
import {
  notNull,
  LonLat,
  Geometry,
  geographicToMercator,
  mapViewFitByFeature,
  createFeatureFromGeometry,
  createGeometryFromFeature,
} from '../../../../../utils';
import {
  addAlertTypeControl,
  addColorControl,
  addDeleteControl,
  addDetailInfoControl,
  addDetailInputControl,
  addDimensionControl,
  addEditControl,
  addHomeControl,
  addListDeleteControl,
  addListEditControl,
  addTransparencyControl,
  addTypeControl,
  addZoomControl,
  addProjectSourceLayer,
  addProjectSourceLayerLabelPoint,
  addProjectSourceLayerPointLabel,
  addProjectSourceLocationPoint,
  addProjectSourceLocationLabel,
  addProjectSourceLineWork,
  removeProjectSourceLayer,
  addLineworkButtonControl,
} from '../ui';
import {
  createInteractor,
  setupInteraction,
  Interactor,
  InteractionState,
  ButtonState,
  PrimitiveType,
  removeFeatureBySelectedItem,
} from '../interaction';
import define from '../../../../organisms/taskmap/define'; // TODO: 共通化の課題
import {
  GeofenceAlertType,
  Dimension,
  GeofenceTrigger,
  GeofenceType,
  UnitLength,
} from '../../../../../dataProvider';
import { PopupData } from '../../view/GeofenceDetailInput';
import addValidControl from '../ui/addValidControl';
import { existEditFeature } from '../interaction/utils';

const fireEvent = (events: Event[], type: EventType, args?: any) => {
  events.forEach((event: Event) => {
    event(type, args);
  });
};

const MapControllerImpl = (): MapController => {
  let mapProps: MapProps;
  let bindEnd = false;
  let targetFeatureId: number;
  let defaultBoundary: LonLat[] = [];
  let defaultListLabelInfo: LabelInfoList | undefined;
  let defaultInputLabelInfo: LabelInfoInput | undefined;
  let selectedType: GeofenceType;
  let selectedDimension: Dimension;
  let selectedAlertType: GeofenceAlertType;
  let selectedColor: ColorInfoRGB;
  let selectedValid: boolean;
  let selectedTransparency: boolean;
  let inputObjectData: PopupData;
  let siteUnitLength: UnitLength;
  let settingElevation: number | undefined;
  let settingHeight: number | undefined;
  let settingRadius: number | undefined;
  let settingThickness: number | undefined;
  let settingTrigger: GeofenceTrigger | undefined;
  let eventOnClickListEdit: ((editRecord: any) => void) | undefined;
  let eventOnClickListDelete: ((deleteRecord: any) => void) | undefined;
  let eventOnModify: (() => void) | undefined;
  let showLinework = true;
  let disableSaveButton = true;

  const geofenceTypeList: GeofenceTypeDictionary = new Map<number, string>();
  const events: Event[] = [];
  const interactor: Interactor = createInteractor();
  const { meterToUnitLengthRoundUp, feetToUnitLength } = useUnitLength();
  const defaultInteractionState: InteractionState = 'draw';
  const defaultButtonState: ButtonState = 'draw';
  const onChangeElevation = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    settingElevation = inputValue
      ? feetToUnitLength(Number(inputValue), siteUnitLength)
      : undefined;
    if (eventOnModify) eventOnModify();
  };
  const onChangeHeight = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue =
      Number(event.target.value) < 0
        ? Math.abs(Number(event.target.value))
        : Number(event.target.value);
    settingHeight =
      inputValue > 0 ? feetToUnitLength(inputValue, siteUnitLength) : undefined;
    if (eventOnModify) eventOnModify();
  };
  const onChangeRadius = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue =
      Number(event.target.value) < 0
        ? Math.abs(Number(event.target.value))
        : Number(event.target.value);
    settingRadius =
      inputValue > 0
        ? feetToUnitLength(Number(inputValue), siteUnitLength)
        : undefined;
    if (eventOnModify) eventOnModify();
  };
  const onChangeThickness = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue =
      Number(event.target.value) < 0
        ? Math.abs(Number(event.target.value))
        : Number(event.target.value);
    settingThickness =
      inputValue > 0
        ? feetToUnitLength(Number(inputValue), siteUnitLength)
        : undefined;
    if (eventOnModify) eventOnModify();
  };
  const onChangeTrigger = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue === 'In' || inputValue === 'Out' || inputValue === '')
      settingTrigger = inputValue === '' ? undefined : inputValue;
    if (eventOnModify) eventOnModify();
  };
  const onChangeShowLinework = ({ map }: MapProps, visible: boolean) => {
    map
      .getLayers()
      .getArray()
      .filter(
        layer =>
          layer.getClassName() === 'mapserverLineworkLayer' ||
          layer.getClassName() === 'mapserverLabelPointkLayer' ||
          layer.getClassName() === 'mapserverPointLabelkLayer' ||
          layer.getClassName() === 'mapserverLocationPointLayer' ||
          layer.getClassName() === 'mapserverLocationLabelLayer',
      )
      .forEach(layer => {
        layer.setVisible(visible);
      });
    showLinework = visible;
  };

  const removeOverlay = (target: string) => {
    const ids = mapProps.map
      .getControls()
      .getArray()
      .map((it: any, index) => {
        if (it.element.id === target) return index;
        return null;
      })
      .filter(v => v);
    if (ids.length > 0) {
      const num = ids[0];
      if (num === null) return;
      mapProps.map.getControls().removeAt(num);
    }
  };

  const self = {
    bindMap: ({
      mapProps: props,
      featureId,
      readOnly,
      labelInfoList,
      labelInfoInput,
      record,
      onClickListEdit,
      onClickListDelete,
      onModify,
      onShowInput,
    }: BindMapParams) => {
      mapProps = props;
      targetFeatureId = featureId;
      // インタラクション (MAP上での操作機能) を初期化する
      const interactions = setupInteraction(props, featureId, {
        onDrawEnd: () => {
          interactor.changeState('modify');
          setTimeout(() => fireEvent(events, 'draw'), 1);
        },
        onModifyEnd: () => {
          fireEvent(events, 'modify');
          if (onModify) onModify();
        },
        onTranslateEnd: () => {
          fireEvent(events, 'translate');
          if (onModify) onModify();
        },
        onSelectEnd: (features: Feature[]) => {
          let selecteId: number | undefined;
          if (features.length > 0) {
            const getId: string | number | undefined = features[0].getId();
            selecteId = getId ? Number(getId) : undefined;
          }
          fireEvent(events, 'select', { id: selecteId });
        },
      });
      // 実際にインタラクションを操作するモジュールへインタラクションをバインドする
      interactor.setup(interactions, props.map);
      // ホーム位置を表示する
      self.navigateToHome();
      // オーバーレイUIを初期化する
      if (!readOnly) {
        // ***************************
        // 作成・更新画面の場合
        // ***************************
        // 既にbind済みで呼ばれた場合は新たにControlを設定しない
        if (!bindEnd) {
          eventOnModify = onModify;
          addZoomControl(props);
          addHomeControl(props, () => self.navigateToHome());
          addLineworkButtonControl(props, onChangeShowLinework);
          addEditControl(
            props,
            defaultButtonState,
            () => {
              if (interactor.isDrawed()) {
                interactor.changeState('modify');
              } else {
                interactor.changeState('draw');
                if (onModify) onModify();
              }
            },
            () => interactor.changeState('move'),
            () => {
              const features = mapProps.sourceVector.getFeatures();
              const filteredFeature =
                features.length > 0
                  ? features.filter(it => it.getId() === featureId)
                  : [];
              const feature = filteredFeature[0];
              let geometryCoordinate: LonLat[] = [{ lon: 0, lat: 0 }];
              const geofenceType: GeofenceType = self.getSelectedType();
              if (feature) {
                // Feature情報に座標が含まれていないため、一度geometryに変換してジオフェンス座標を取得
                const geometry = createGeometryFromFeature(
                  feature,
                  geofenceType,
                );
                geometryCoordinate = geometry
                  ? geometry.coordinates
                  : [{ lon: 0, lat: 0 }];
              }
              // マップ上にジオフェンスがある場合のみジオフェンス座標を座標入力欄に表示
              const lonLatArray = feature ? geometryCoordinate : [];
              if (onShowInput) onShowInput(lonLatArray, settingRadius);
            },
          );
          addTypeControl(
            props,
            () => {
              selectedType = 'Polygon';
              interactor.setPrimitiveType('polygon');
              self.renderDimensionControl();
              self.setSettingRadius(undefined);
              self.setSettingThickness(undefined);
              self.renderInputComponent(mapProps);
              if (onModify) onModify();
            },
            () => {
              selectedType = 'Circle';
              interactor.setPrimitiveType('circle');
              self.renderDimensionControl();
              self.setSettingThickness(undefined);
              self.renderInputComponent(mapProps);
              if (onModify) onModify();
            },
            () => {
              selectedType = 'Line';
              interactor.setPrimitiveType('line');
              self.renderDimensionControl();
              self.setSettingHeight(undefined);
              self.setSettingThickness(undefined);
              self.setSettingTrigger(undefined);
              self.setSelectedDimension('ThreeDimension');
              self.renderInputComponent(mapProps);
              if (onModify) onModify();
            },
            () => {
              selectedType = 'Wall';
              interactor.setPrimitiveType('line');
              self.renderDimensionControl();
              self.setSettingRadius(undefined);
              self.setSettingTrigger(undefined);
              self.setSelectedDimension('ThreeDimension');
              self.renderInputComponent(mapProps);
              if (onModify) onModify();
            },
            record?.type,
            existEditFeature(featureId, props.sourceVector),
          );
          addDimensionControl(
            props,
            record?.dimension || 'ThreeDimension',
            record?.type || 'Polygon',
            () => {
              // 今設定しているものとは別の次元を設定する
              selectedDimension =
                selectedDimension === 'TwoDimension'
                  ? 'ThreeDimension'
                  : 'TwoDimension';
              if (selectedDimension === 'TwoDimension') {
                self.setSettingHeight(undefined);
              }
              if (selectedDimension === 'ThreeDimension') {
                self.setSettingTrigger(undefined);
              }
              self.renderInputComponent(mapProps);
              if (onModify) onModify();
            },
          );
          addColorControl(
            props,
            (color: any) => {
              // パレットで選択した色情報を保持
              selectedColor = color.rgb;
              if (onModify) onModify();
            },
            record?.rgbRed,
            record?.rgbGreen,
            record?.rgbBlue,
          );
          addTransparencyControl(props, record?.transparency, () => {
            // 今設定しているものとは別の透過を設定する
            selectedTransparency = !selectedTransparency;
            if (onModify) onModify();
          });
          addValidControl(props, record?.valid, () => {
            selectedValid = !selectedValid;
            if (onModify) onModify();
          });
          addAlertTypeControl(
            props,
            () => {
              selectedAlertType = 'Notice';
              if (onModify) onModify();
            },
            () => {
              selectedAlertType = 'Attention';
              if (onModify) onModify();
            },
            () => {
              selectedAlertType = 'Caution';
              if (onModify) onModify();
            },
            () => {
              selectedAlertType = 'Warning';
              if (onModify) onModify();
            },
            () => {
              selectedAlertType = 'Danger';
              if (onModify) onModify();
            },
            record?.alertType,
          );
          addDeleteControl(props, () => {
            if (
              interactor.getState() === 'move' &&
              removeFeatureBySelectedItem(
                props.map,
                interactions.select,
                props.sourceVector,
                featureId,
              )
            ) {
              interactor.drawClear();
              fireEvent(events, 'delete');
            }
          });
        }

        removeOverlay('map_detailInput');
        const convertedCoordinateData = inputObjectData
          ? {
              id: inputObjectData.id,
              coordinates: inputObjectData.coordinates,
              localCoordinates: inputObjectData.localCoordinates.map(
                localcoodinate => [
                  meterToUnitLengthRoundUp(
                    localcoodinate[0],
                    siteUnitLength,
                    3,
                  ),
                  meterToUnitLengthRoundUp(
                    localcoodinate[1],
                    siteUnitLength,
                    3,
                  ),
                ],
              ),
            }
          : inputObjectData;
        addDetailInputControl(
          mapProps,
          convertedCoordinateData,
          siteUnitLength,
          selectedType,
          selectedDimension,
          onChangeElevation,
          settingElevation
            ? meterToUnitLengthRoundUp(settingElevation, siteUnitLength, 3)
            : settingElevation,
          onChangeHeight,
          settingHeight
            ? meterToUnitLengthRoundUp(settingHeight, siteUnitLength, 3)
            : settingHeight,
          onChangeRadius,
          settingRadius
            ? meterToUnitLengthRoundUp(settingRadius, siteUnitLength, 3)
            : settingRadius,
          onChangeThickness,
          settingThickness
            ? meterToUnitLengthRoundUp(settingThickness, siteUnitLength, 3)
            : settingThickness,
          onChangeTrigger,
          settingTrigger,
          labelInfoInput,
        );
        defaultInputLabelInfo = labelInfoInput;
        if (featureId === 0) {
          // 作成画面時は、描画モード
          interactor.changeState(defaultInteractionState);
        } else {
          // 更新画面時は、編集モード（onDrawEndイベントと同じ処理）
          interactor.changeState('modify');
          setTimeout(() => fireEvent(events, 'draw'), 1);
        }
      } else {
        // ***************************
        // 一覧画面の場合（readOnlyの場合）
        // ***************************
        interactor.changeState('select');
        defaultListLabelInfo = labelInfoList;
        addZoomControl(props);
        addHomeControl(props, () => self.navigateToHome());
        addLineworkButtonControl(props, onChangeShowLinework);

        // 選択中ジオフェンス情報表示・操作コンポートントを追加
        addDetailInfoControl(props, siteUnitLength, labelInfoList);
        addListEditControl(props, () => {
          if (mapProps.record && onClickListEdit) {
            onClickListEdit(mapProps.record);
          }
        });
        eventOnClickListEdit = onClickListEdit;
        addListDeleteControl(
          props,
          () => {
            if (mapProps.record && onClickListDelete) {
              onClickListDelete(mapProps.record);
            }
          },
          labelInfoList?.deleteDialogMessage,
        );
        eventOnClickListDelete = onClickListDelete;
      }
      bindEnd = true;
    },
    isBindMapEnd: (): boolean => bindEnd,
    getGeofenceTypeList: () => geofenceTypeList,
    navigateToHome: (duration: number | undefined = define.homeDuration) => {
      if (defaultBoundary.length <= 0) return;
      const feature = createFeatureFromGeometry({
        type: 'Polygon',
        id: 0,
        name: 'view',
        coordinates: defaultBoundary,
      });
      if (feature) {
        mapViewFitByFeature(mapProps.map, feature, 0, duration);
      }
    },
    navigateToCoordinate: ({ lon, lat }: LonLat) => {
      mapProps.view.setCenter(geographicToMercator([lon, lat]));
    },
    getMapProps: (): MapProps => mapProps,
    getGeometries: (): Geometry[] => {
      const features = mapProps.sourceVector.getFeatures();
      if (features.length <= 0) {
        return [];
      }
      // Feature から各 Geometry に変換
      return features
        .map(feature => {
          const targetId = mapProps.record?.id || 0;
          const id = feature.getId() as number;
          const dictionaryType = geofenceTypeList.get(id);
          const targetType = id === targetId ? selectedType : dictionaryType;
          if (!targetType) return null;
          return createGeometryFromFeature(feature, targetType);
        })
        .filter(notNull);
    },
    setGeometries: (geometries: Geometry[]) => {
      if (mapProps) mapProps.sourceVector.clear();
      if (geometries.length <= 0) return;
      // Geometry から各 Feature に変換
      const features = geometries
        .map(geometry => createFeatureFromGeometry(geometry))
        .filter(notNull);
      // 地図に反映
      features.forEach(feature => mapProps.sourceVector.addFeature(feature));

      fireEvent(events, 'loaded');
    },
    setSelectFeature: (featureId: number) => {
      const features = mapProps.sourceVector.getFeatures();
      if (features.length <= 0) return;
      const filteredFeature = features.filter(it => it.getId() === featureId);
      if (filteredFeature.length <= 0) return;
      interactor.selectFeature(filteredFeature[0]);
    },
    setModifyMode: () => {
      interactor.changeState('modify');
    },
    setBoundary: (boundary: LonLat[]) => {
      defaultBoundary = boundary;
    },
    setAddOnEventEnd: (event: Event) => {
      events.push(event);
    },
    setPrimitiveType: (type: PrimitiveType) => {
      interactor.setPrimitiveType(type);
    },
    setSelectdRecord: (record: any) => {
      const propWithRecord = { ...mapProps, record };
      // 既存の古いコンポーネントは一旦削除
      removeOverlay('map_detail');
      removeOverlay('map_edit');
      removeOverlay('map_delete');
      // 各種コンポーネントの再描画
      addDetailInfoControl(
        propWithRecord,
        siteUnitLength,
        defaultListLabelInfo,
      );
      addListEditControl(propWithRecord, () => {
        if (record && eventOnClickListEdit) {
          eventOnClickListEdit(record);
        }
      });
      addListDeleteControl(
        propWithRecord,
        () => {
          if (record && eventOnClickListDelete) {
            eventOnClickListDelete(record);
          }
        },
        defaultListLabelInfo?.deleteDialogMessage,
      );
    },
    getSelectedType: (): GeofenceType => {
      return selectedType;
    },
    setSelectedType: (type: GeofenceType) => {
      selectedType = type;
    },
    getSelectedDimension: () => {
      return selectedDimension;
    },
    setSelectedDimension: (dimension: Dimension) => {
      selectedDimension = dimension;
    },
    getSelectedAlertType: (): GeofenceAlertType => {
      return selectedAlertType;
    },
    setSelectedAlertype: (alertType: GeofenceAlertType) => {
      selectedAlertType = alertType;
    },
    getSelectedColor: (): ColorInfoRGB => {
      return selectedColor;
    },
    setSelectedColor: (color: ColorInfoRGB) => {
      selectedColor = color;
    },
    getSelectedValid: (): boolean => {
      return selectedValid;
    },
    setSelectedValid: (valid: boolean) => {
      selectedValid = valid;
    },
    getSelectedTransparency: (): boolean => {
      return selectedTransparency;
    },
    setSelectedTransparency: (transparency: boolean) => {
      selectedTransparency = transparency;
    },
    renderTypeControl: (inMapProps: MapProps, disable: boolean) => {
      removeOverlay('map_type');

      addTypeControl(
        inMapProps,
        () => {
          if (disable) return;
          selectedType = 'Polygon';
          interactor.setPrimitiveType('polygon');
          self.renderDimensionControl();
          self.setSettingRadius(undefined);
          self.setSettingThickness(undefined);
          self.renderInputComponent(inMapProps);
          if (eventOnModify) eventOnModify();
        },
        () => {
          if (disable) return;
          selectedType = 'Circle';
          interactor.setPrimitiveType('circle');
          self.renderDimensionControl();
          self.setSettingThickness(undefined);
          self.renderInputComponent(inMapProps);
          if (eventOnModify) eventOnModify();
        },
        () => {
          if (disable) return;
          selectedType = 'Line';
          interactor.setPrimitiveType('line');
          self.renderDimensionControl();
          self.setSettingHeight(undefined);
          self.setSettingThickness(undefined);
          self.setSettingTrigger(undefined);
          self.setSelectedDimension('ThreeDimension');
          self.renderInputComponent(inMapProps);
          if (eventOnModify) eventOnModify();
        },
        () => {
          if (disable) return;
          selectedType = 'Wall';
          interactor.setPrimitiveType('line');
          self.renderDimensionControl();
          self.setSettingRadius(undefined);
          self.setSettingTrigger(undefined);
          self.setSelectedDimension('ThreeDimension');
          self.renderInputComponent(inMapProps);
          if (eventOnModify) eventOnModify();
        },
        selectedType,
        disable,
      );
    },
    renderDimensionControl: () => {
      removeOverlay('map_dimension');
      addDimensionControl(mapProps, selectedDimension, selectedType, () => {
        if (selectedType !== 'Polygon' && selectedType !== 'Circle') return;
        // 今設定しているものとは別の次元を設定する
        selectedDimension =
          selectedDimension === 'TwoDimension'
            ? 'ThreeDimension'
            : 'TwoDimension';
        if (selectedDimension === 'TwoDimension') {
          self.setSettingHeight(undefined);
        }
        if (selectedDimension === 'ThreeDimension') {
          self.setSettingTrigger(undefined);
        }
        self.renderInputComponent(mapProps);
      });
    },
    renderInputComponent: (inMapProps: MapProps) => {
      if (!inMapProps) return;
      removeOverlay('map_detailInput');
      const convertedCoordinateData = inputObjectData
        ? {
            id: inputObjectData.id,
            coordinates: inputObjectData.coordinates,
            localCoordinates: inputObjectData.localCoordinates.map(
              localcoodinate => [
                meterToUnitLengthRoundUp(localcoodinate[0], siteUnitLength, 3),
                meterToUnitLengthRoundUp(localcoodinate[1], siteUnitLength, 3),
              ],
            ),
          }
        : inputObjectData;
      addDetailInputControl(
        mapProps,
        convertedCoordinateData,
        siteUnitLength,
        selectedType,
        selectedDimension,
        onChangeElevation,
        settingElevation
          ? meterToUnitLengthRoundUp(settingElevation, siteUnitLength, 3)
          : settingElevation,
        onChangeHeight,
        settingHeight
          ? meterToUnitLengthRoundUp(settingHeight, siteUnitLength, 3)
          : settingHeight,
        onChangeRadius,
        settingRadius
          ? meterToUnitLengthRoundUp(settingRadius, siteUnitLength, 3)
          : settingRadius,
        onChangeThickness,
        settingThickness
          ? meterToUnitLengthRoundUp(settingThickness, siteUnitLength, 3)
          : settingThickness,
        onChangeTrigger,
        settingTrigger,
        defaultInputLabelInfo,
      );
    },
    setInputObjectData: (data: PopupData) => {
      inputObjectData = data;
    },
    getSiteUnitLength: (): UnitLength => {
      return siteUnitLength;
    },
    setSiteUnitLength: (unitLength: UnitLength) => {
      siteUnitLength = unitLength;
    },
    getDisableSaveButton: (): boolean => {
      // ジオフェンス情報の未入力欄チェック
      switch (selectedType) {
        case 'Polygon':
          disableSaveButton =
            selectedDimension === 'ThreeDimension'
              ? !!(settingElevation === undefined || !settingHeight)
              : !!(settingElevation === undefined || !settingTrigger);
          break;
        case 'Circle':
          disableSaveButton =
            selectedDimension === 'ThreeDimension'
              ? !!(
                  settingElevation === undefined ||
                  !settingHeight ||
                  !settingRadius
                )
              : !!(
                  settingElevation === undefined ||
                  !settingRadius ||
                  !settingTrigger
                );
          break;
        case 'Line':
          disableSaveButton = !!(
            settingElevation === undefined || !settingRadius
          );
          break;
        case 'Wall':
          disableSaveButton = !!(
            settingElevation === undefined ||
            !settingHeight ||
            !settingThickness
          );
          break;
        default:
          console.warn(`Invalid GeofenceType: ${selectedType}`);
      }
      return disableSaveButton;
    },
    getSettingElevation: (): number | undefined => {
      return settingElevation;
    },
    setSettingElevation: (elevation: number | undefined) => {
      settingElevation = elevation;
    },
    getSettingHeight: (): number | undefined => {
      return settingHeight;
    },
    setSettingHeight: (height: number | undefined) => {
      settingHeight = height;
    },
    getSettingRadius: (): number | undefined => {
      return settingRadius;
    },
    setSettingRadius: (radius: number | undefined) => {
      settingRadius = radius;
    },
    getSettingThickness: (): number | undefined => {
      return settingThickness;
    },
    setSettingThickness: (thickness: number | undefined) => {
      settingThickness = thickness;
    },
    getSettingTrigger: (): GeofenceTrigger | undefined => {
      return settingTrigger;
    },
    setSettingTrigger: (trigger: GeofenceTrigger | undefined) => {
      settingTrigger = trigger;
    },
    setProjectSourceLayer: (id: number, versionId: number, token: string) => {
      addProjectSourceLayer(mapProps, id, versionId, token, true);
    },
    setProjectSourceLayerLabelPoint: (versionId: number, token: string) => {
      addProjectSourceLayerLabelPoint(mapProps, versionId, token, showLinework);
    },
    setProjectSourceLayerPointLabel: (versionId: number, token: string) => {
      addProjectSourceLayerPointLabel(mapProps, versionId, token, showLinework);
    },
    setProjectSourceLocationPoint: (versionId: number, token: string) => {
      addProjectSourceLocationPoint(mapProps, versionId, token, showLinework);
    },
    setProjectSourceLocationLabel: (versionId: number, token: string) => {
      addProjectSourceLocationLabel(mapProps, versionId, token, showLinework);
    },
    setProjectSourceLineWork: (
      id: number,
      versionId: number,
      token: string,
    ) => {
      addProjectSourceLineWork(mapProps, id, versionId, token, showLinework);
    },
    removeProjectSourceLayer: () => removeProjectSourceLayer(mapProps),
    /**
     * 経度緯度を元にポリゴンを生成する
     *
     * @param lonlat 経度緯度の配列
     */
    update(lonlat: LonLat[], radius: number | undefined): void {
      if (!mapProps || lonlat.length <= 0) return;
      const { sourceVector } = mapProps;
      const features = mapProps.sourceVector.getFeatures();
      // 更新前のジオフェンス情報
      const filteredFeature =
        features.length > 0
          ? features.filter(it => it.getId() === targetFeatureId)
          : [];
      if (filteredFeature.length !== 0) {
        // 既にマップ上にジオフェンスがある場合、重ならないように更新前のジオフェンスを地図から除外
        sourceVector.removeFeature(filteredFeature[0]);
      }

      // 座標ダイアログで指定された座標・半径情報からFeature, Geometryを作成
      const geofenceType = self.getSelectedType();
      const inputGeofenceType = geofenceType === 'Wall' ? 'Line' : geofenceType;
      const newFeature = createFeatureFromGeometry({
        type: inputGeofenceType,
        id: 0,
        name: 'view',
        coordinates: lonlat,
        radius,
      });
      if (!newFeature) return;
      const newGeometry = createGeometryFromFeature(
        newFeature,
        self.getSelectedType(),
      );
      if (!newGeometry) return;
      newFeature.setId(targetFeatureId);
      // 地図に反映
      sourceVector.addFeature(newFeature);
      const geometry = createGeometryFromFeature(
        newFeature,
        self.getSelectedType(),
      );
      if (!geometry) return;
      self.setInputObjectData({
        id: targetFeatureId,
        coordinates: geometry.coordinates,
        localCoordinates: geometry.coordinates.map(ll => [ll.lon, ll.lat]),
      });
      // 円の場合はRadiusの値を更新しておく
      if (self.getSelectedType() === 'Circle') {
        self.setSettingRadius(radius);
      }
      self.setSelectFeature(targetFeatureId);
      self.setModifyMode();
      // setInputObjectDataで設定したデータを入力コンポーネントに反映する
      self.renderInputComponent(self.getMapProps());

      // 座標登録後は、編集モード（onDrawEndイベントと同じ処理）
      interactor.changeState('modify');
      setTimeout(() => fireEvent(events, 'draw'), 1);
    },
    setModify(): void {
      // 編集モード（onDrawEndイベントと同じ処理）
      interactor.changeState('modify');
      setTimeout(() => fireEvent(events, 'draw'), 1);
    },
  };
  return self;
};

export default MapControllerImpl;
