import React, { FC, useState, useCallback } from 'react';
import { Identifier, useLoading } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import { Button } from '../../..';
import { toSerialNumberText } from '../../../../utils';
import { useRetrofitLending, useRetrofitSerialNumber } from '../../../../hooks';
import { colors } from '../../../../theme';
import { buttonWidth, buttonHeight } from '../../../../theme/define';
import BulkRegistrationDialog from './BulkRegistrationDialog';

const useStyles = makeStyles(() =>
  createStyles({
    confirm: {
      background: colors.button.save,
      color: colors.text.primaryLight,
      fontSize: 14,
      textTransform: 'none',
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.saveHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.saveDisabled,
      },
      '& span': {
        padding: 0,
      },
    },
  }),
);

interface ViewState {
  open: boolean;
  openError: boolean;
  errorMessage: string;
  serialNumbers: string[];
  hasLending: boolean;
  lendingSerialNumberText: string;
}

const initialViewState: ViewState = {
  open: false,
  serialNumbers: [],
  openError: false,
  errorMessage: '',
  hasLending: false,
  lendingSerialNumberText: '',
};

interface Props {
  selectedIds: Identifier[];
  disabled: boolean;
}

const BulkRegistrationButton: FC<Partial<Props>> = ({
  selectedIds: ids = [0],
  disabled,
}) => {
  const classes = useStyles();
  const loading = useLoading();
  const retrofitLending = useRetrofitLending();
  const retrofitSerialNumber = useRetrofitSerialNumber();

  const [viewState, setViewState] = useState<ViewState>(initialViewState);

  const handleClick = useCallback(async () => {
    const retrofitIds = ids.map(id => Number(id));
    // 貸与チェック
    const {
      data: { isLending: hasLending, rentalRetrofitIds },
    } = await retrofitLending({
      retrofitIds,
    });

    // シリアル番号を取得
    const {
      data: { serialNumbers, retrofits },
    } = await retrofitSerialNumber({
      retrofitIds,
    });

    // 貸与したレトロフィットのシリアル番号を抽出
    const lendingSerialNumbers = retrofits
      .filter(({ id }) => rentalRetrofitIds.includes(id))
      .map(({ basicInfoSerialNumber }) => basicInfoSerialNumber);

    // 貸与したレトロフィットのシリアル番号のテキストを生成
    const lendingSerialNumberText = toSerialNumberText(lendingSerialNumbers);

    setViewState({
      ...viewState,
      open: true,
      serialNumbers,
      hasLending,
      lendingSerialNumberText,
    });
  }, [retrofitLending, retrofitSerialNumber, viewState, ids]);

  return (
    <>
      <Button
        className={classes.confirm}
        label="admin.actions.bulkRegistration"
        data-testid="admin.actions.bulkRegistration"
        onClick={handleClick}
        disabled={disabled || loading}
      />
      <BulkRegistrationDialog
        open={viewState.open}
        onClose={() => setViewState({ ...viewState, open: false })}
        ids={ids}
        serialNumbers={viewState.serialNumbers}
        hasLending={viewState.hasLending}
        lendingSerialNumberText={viewState.lendingSerialNumberText}
      />
    </>
  );
};

BulkRegistrationButton.displayName = 'BulkRegistrationButton';
export default BulkRegistrationButton;
