import React, { FC, useState } from 'react';
import {
  Edit,
  Loading,
  required,
  TextInput,
  email as emailValidation,
} from 'react-admin';
import { createStyles, makeStyles } from '@material-ui/core';
import {
  CustomForm,
  Divider,
  CustomerAutoCompleteInput,
  LendCorporationData,
  LoadingView,
  BreadcrumbsFiled,
} from '../../..';
import { useResource, useGetAccount } from '../../../../hooks';
import { styles } from '../../../../theme';
import { inputPasswordWidth } from '../../../../theme/define';
import { RetrofitSsidImage } from '../../../../assets';
import { EditSaveButton as SaveButton } from '.';
import {
  CustomerData,
  RetrofitAlternateRegistData,
} from '../../../../dataProvider/types';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 14,
    },
    divider: {
      marginTop: 8,
      marginBottom: 12,
      backgroundColor: 'white',
    },
    description: {
      ...styles.multilineText,
    },
    corporationId: {
      ...styles.longWidthText,
    },
    icon: {
      marginTop: 24,
    },
    confirm: {
      '& .MuiInputBase-input': {
        minWidth: inputPasswordWidth,
      },
    },
  }),
);

const RetrofitSsidFiled: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.icon}>
      <RetrofitSsidImage />
    </div>
  );
};
// メールアドレスに全角文字がないかチェック
const halfWidthValidation =
  (message = 'ra.validation.email') =>
  (value: any) =>
    typeof value === 'string' &&
    // eslint-disable-next-line no-control-regex
    /([^\x01-\x7E\uFF65-\uFF9F]|[ｦ-ﾟ])+/.test(value)
      ? message
      : undefined;

const AlternateRegistEdit: FC<{
  retrofitAlternateRegistData: RetrofitAlternateRegistData | undefined;
  props: any;
}> = ({ retrofitAlternateRegistData, props }) => {
  const classes = useStyles();
  const [customer, setCustomer] = useState<LendCorporationData | null>(null);
  const resource = useResource('retrofitAlternateRegists');
  const basePath = '/retrofitAlternateRegists';
  const { getAccount } = useGetAccount();
  const accountData = getAccount();
  const alternateRegistId = retrofitAlternateRegistData
    ? retrofitAlternateRegistData.id
    : '';

  const newProps = {
    id: alternateRegistId,
    resource,
    basePath,
    ...props,
  };
  if (!accountData) {
    return <Loading />;
  }
  const { corporationId } = accountData;
  return (
    <>
      <LoadingView>
        <Edit {...newProps}>
          <CustomForm
            title="admin.pages.retrofitAlternateRegistEdit"
            redirect={false}
            deleteButton={false}
            saveButton={
              <SaveButton
                customer={customer}
                retrofitAlternateRegistData={retrofitAlternateRegistData}
              />
            }
          >
            <BreadcrumbsFiled
              breadcrumbs={['resources.retrofitAlternateRegists.name']}
              label="admin.breadcrumbs.retrofitAlternateRegistEdit"
            />
            <CustomerAutoCompleteInput
              label="resources.retrofitAlternateRegists.fields.toCorporationName"
              resource={resource}
              source="toCorporationId"
              fromCorporationId={corporationId}
              onNotifyChange={(value: CustomerData) => setCustomer(value)}
            />
            <TextInput
              label="resources.retrofitAlternateRegists.fields.email"
              data-testid="toMailAddress"
              resource={resource}
              source="toMailAddress"
              validate={[required(), emailValidation(), halfWidthValidation()]}
            />
            <Divider className={classes.divider} />
            <RetrofitSsidFiled />
          </CustomForm>
        </Edit>
      </LoadingView>
    </>
  );
};

AlternateRegistEdit.displayName = 'AlternateRegistEdit';
export default AlternateRegistEdit;
