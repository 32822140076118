import { AdminApiConverter } from '..';

const converter: AdminApiConverter<'landlogapps', 'landlogapps'> = {
  resource: 'landlogapps',
  defaultSort: { field: 'id', order: 'ASC' },
  filterToApi: filter => filter,
  sortFieldToApi: field => field,
  recordsFromApi: records => Promise.resolve(records),
  recordToApi: record => ({ ...record, id: undefined }),
};

export default converter;
