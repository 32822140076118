import { useCallback } from 'react';
import { useGetLatestReturnDate, useGetParentStartDate } from '.';
import { addOneDay } from '../utils';

interface InParams {
  retrofitId: number;
  corporationId: string;
  parentRentalId: number | undefined;
}

interface OutParams {
  data: {
    startDate: Date;
  };
}

const useGetAvailableStartDate = (defaultDate: Date) => {
  const getLatestReturnDate = useGetLatestReturnDate();
  const getParentStartDate = useGetParentStartDate();
  return useCallback(
    async ({
      retrofitId,
      corporationId,
      parentRentalId,
    }: InParams): Promise<OutParams> => {
      // 過去の貸与履歴から返却日を取得する処理
      //   - 貸与履歴がある場合は、+1 した返却日を設定する
      //   - 貸与履歴がない場合は、デフォルト日を設定する
      const latestReturnDate: Date =
        addOneDay(
          (await getLatestReturnDate({ retrofitId, corporationId })).data
            .latestReturnDate,
        ) || defaultDate;

      // Wレンタル時における親の貸与開始日を取得する処理
      //   - 親の貸与開始日がある場合は、親の貸与開始日を設定する
      //   - 親の貸与開始日がない場合は、デフォルト日を設定する
      const parentStateDate: Date =
        (await getParentStartDate({ parentRentalId })).data.parentStartDate ||
        defaultDate;

      // 取得した2つの日付を比較し、未来日を戻り値として設定する
      const startDate =
        latestReturnDate.getTime() >= parentStateDate.getTime()
          ? latestReturnDate
          : parentStateDate;
      return {
        data: {
          startDate,
        },
      };
    },
    [defaultDate, getLatestReturnDate, getParentStartDate],
  );
};

export default useGetAvailableStartDate;
