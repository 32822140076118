import omit from 'lodash/omit';

/**
 * コンポーネントに渡したpropsでMaterial-Uiのコンポーネントに渡したくないpropsがある場合、
 * omitの引数に渡したくないpropsを指定する
 * @param props react-adminで渡ってくるprops
 */
export default (props: object): object =>
  omit(props, [
    // ここに渡したくないpropsを追加していく
    'basePath',
  ]);
