const rateFt = 0.3048;
const rateUsFt = 0.304800609601219;

/**
 * M(メートル)をFt（フィート）に変換する
 * @param m 値
 */
const convertMToFt = (m: number): number => {
  return m / rateFt;
};
/**
 * M(メートル)をUsFt（U.S. Survey Feet）に変換する
 * @param m 値
 */
const convertMToUsFt = (m: number): number => {
  return m / rateUsFt;
};
/**
 * Ft（フィート）をM(メートル)に変換する
 * @param ft 値
 */
const convertFtToM = (ft: number): number => {
  return ft * rateFt;
};
/**
 * Ft（フィート）をM(メートル)に変換する
 * @param usFt 値
 */
const convertUsFtToM = (usFt: number): number => {
  return usFt * rateUsFt;
};

const metreToFeet = (isUsFeet: boolean, metre: number): number => {
  return isUsFeet ? convertMToUsFt(metre) : convertMToFt(metre);
};

const feetToMetre = (isUsFeet: boolean, feet: number): number => {
  return isUsFeet ? convertUsFtToM(feet) : convertFtToM(feet);
};

export { metreToFeet, feetToMetre };
