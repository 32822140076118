import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Identifier } from 'react-admin';
import { useLocation } from 'react-router-dom';
import {
  CustomList,
  Datagrid,
  TextField,
  CustomDateField,
  ReferenceField,
  IconField,
  SiteSearchInput,
  Filter,
  LinkButton,
} from '../..';
import { useResource, useGetAccount, useJumpTo } from '../../../hooks';
import { AddIcon } from '../../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 16,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 102,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 113,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 113,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 113,
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 88,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(7)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 76,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(8)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 99,
          },
        },
      },
    },
  }),
);

const ListFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

const Actions: React.FC<{
  basePath?: string;
}> = ({ basePath }) => (
  <LinkButton path={`${basePath}/create`} label="admin.actions.add">
    <AddIcon />
  </LinkButton>
);

const BucketList: React.FC = props => {
  const resource = useResource('buckets');
  const { getCorporationId } = useGetAccount();
  const corporationId = getCorporationId();
  const classes = useStyles();
  const { gotoListEditRoutingTabbed } = useJumpTo();
  const { pathname: parentPath } = useLocation();
  return (
    <CustomList
      {...props}
      resource={resource}
      title="admin.pages.bucketList"
      filter={{ corporationId }}
      filters={<ListFilter />}
      actions={<Actions />}
    >
      <Datagrid
        rowClick={(id: Identifier) => {
          gotoListEditRoutingTabbed(parentPath, id);
        }}
        className={classes.root}
      >
        <IconField resource={resource} source="bucketType" />
        <TextField resource={resource} source="name" />
        <ReferenceField
          resource={resource}
          source="corporationId"
          reference="corporations"
          link=""
        >
          <TextField resource="corporations" source="name" />
        </ReferenceField>
        <ReferenceField
          resource={resource}
          source="siteId"
          reference="sites"
          link=""
        >
          <TextField resource="sites" source="name" />
        </ReferenceField>
        <IconField resource={resource} source="uploadedBy" />
        <IconField resource={resource} source="fileStatus" />
        <CustomDateField resource={resource} source="lastUpdated" showTime />
      </Datagrid>
    </CustomList>
  );
};

BucketList.displayName = 'BucketList';
export default BucketList;
