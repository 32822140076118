import React from 'react';
import { FormDataConsumer } from 'react-admin';
import { RetrofitData, SiteData } from '../../../../dataProvider';
import { getValidSiteStatus } from '../../../../utils';
import SiteAutoCompleteInput from '../../../molecules/SiteAutoCompleteInput';

interface SiteReferenceInputProps {
  record?: RetrofitData;
  userCorporationId?: string;
  rented?: boolean;
  unapproved?: boolean;
  adminDisabled?: boolean;
  transferd?: boolean;
}

const SiteReferenceInput: React.FC<SiteReferenceInputProps> = ({
  record,
  userCorporationId = '',
  rented = false,
  unapproved = false,
  adminDisabled = false,
  transferd = false,
}) => {
  if (!record) return <></>;
  const { corporationId } = record;
  const unMatchCorporation = corporationId !== userCorporationId;
  // ＜非活性になる条件＞
  //   条件1: 貸与未承認のデータが1件でも存在する場合
  //   条件2: 貸与中のデータが1件でも存在する場合でかつ、
  //         レトロフィットの企業IDが所属している企業IDと一致しない場合
  //   条件3: 所属している企業が該当レトロフィットの管理企業でかつ、
  //         レトロフィットの企業IDが所属している企業IDと一致しない場合
  //   条件4: 譲渡未承認のデータが１件でも存在する場合
  const disabled =
    unapproved || (rented && unMatchCorporation) || adminDisabled || transferd;
  return (
    <FormDataConsumer>
      {({ ...rest }) => {
        return (
          <SiteAutoCompleteInput
            {...rest}
            label="resources.sites.name"
            resource="retrofits"
            source="siteId"
            choiceResources="sites"
            choicesCreate={({ id, name }: SiteData) => ({ id, name })}
            filter={{ corporationId, status: getValidSiteStatus }}
            sort={{ field: 'name', order: 'ASC' }}
            disabled={disabled}
            resettable={true}
            allowEmpty={false}
          />
        );
      }}
    </FormDataConsumer>
  );
};

SiteReferenceInput.displayName = 'SiteReferenceInput';
export default SiteReferenceInput;
