import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useDataProvider, useTranslate } from 'react-admin';
import {
  CustomList,
  Datagrid,
  Filter,
  Loading,
  SiteSearchInput,
  TextField,
  SelectInput,
  Typography,
} from '../..';
import { NtripStatusField } from './views';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '10px',
            paddingLeft: '16px',
            width: 119,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '10px',
            paddingLeft: '10px',
            width: 246,
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '10px',
            paddingLeft: '10px',
            width: 360,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '16px',
            paddingLeft: '10px',
            width: 103,
          },
        },
      },
    },
    columnFilter: {
      marginTop: 0,
      marginBottom: 0,
    },
    labelWarning: {
      '& p': {
        color: 'red',
      },
    },
  }),
);

const useLicenseStatusType = () => {
  const base = 'resources.ntrips.ntrip';
  const translate = useTranslate();
  return [
    { id: '払出済', name: translate(`${base}.publish`) },
    { id: '停止', name: translate(`${base}.stop`) },
    { id: '削除(=返却済)', name: translate(`${base}.delete`) },
  ];
};

const SerchFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

const ColumnFilters: React.FC = props => {
  const classes = useStyles();
  const licenseStatusType = useLicenseStatusType();
  return (
    <Filter {...props}>
      <SelectInput
        className={classes.columnFilter}
        resource="ntrips"
        source="ntrip"
        choices={licenseStatusType}
        alwaysOn
      />
    </Filter>
  );
};

const NtripList: React.FC = props => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: any;
  }>({
    loading: true,
    fetched: false,
    data: undefined,
  });

  useEffect(() => {
    if (apiState.fetched) return;

    const api = async (): Promise<{
      data: {
        unusedTotal: any;
      };
    }> => {
      // 実際はntripsUnusedTotalのgetList内ではpropsの内容を見ていないが、型エラーとなるため第2引数のparamsを設定
      const result = await dataProvider.getList('ntripsUnusedTotal', {
        pagination: {
          page: 1,
          perPage: 1, // 1件しか取る必要がない
        },
        sort: { field: 'id', order: 'ASC' },
        filter: {},
      });

      return {
        data: {
          unusedTotal: result.data[0].unusedTotal,
        },
      };
    };
    api().then(({ data }) => {
      setApiState({ loading: false, fetched: true, data });
    });
  }, [dataProvider, apiState]);

  if (apiState.loading || !apiState.data) return <Loading />;
  const { unusedTotal } = apiState.data;
  const labelUnusedTotal = translate('admin.label.ntripList.unusedTotal');
  const labelClass =
    Number(unusedTotal) < 100 ? classes.labelWarning : undefined;

  return (
    <CustomList
      {...props}
      filters={<SerchFilter />}
      title="admin.pages.ntripList"
      columnfilters={<ColumnFilters />}
      actions={
        <div className={`${labelClass}`}>
          <Typography>{`${labelUnusedTotal}：${unusedTotal}`}</Typography>
        </div>
      }
      bulkActionButtons={false}
      query={{ sort: { field: 'name', order: 'ASC' } }}
    >
      <Datagrid rowClick="edit" className={classes.root}>
        <TextField resource="ntrips" source="name" />
        <TextField resource="ntrips" source="serial" />
        <TextField resource="ntrips" source="customerCorporationName" />
        <NtripStatusField label="resources.ntrips.fields.ntrip" />
      </Datagrid>
    </CustomList>
  );
};
NtripList.displayName = 'NtripList';

export default NtripList;
