import React from 'react';
import { required, useTranslate } from 'react-admin';
import {
  Create,
  CustomForm,
  BreadcrumbsFiled,
  FileInput,
  FileField,
  Loading,
  LoadingView,
} from '../..';
import { useResource, usePageProps, useGetAccount } from '../../../hooks';

const RetrofitManageAdminBulkActionCreate: React.FC = () => {
  const translate = useTranslate();
  const props = usePageProps('retrofitAdminCorporationBulkActions');
  const resource = useResource('retrofitAdminCorporationBulkActions');
  const { getCorporationId, isLoading } = useGetAccount();
  const corporationId = getCorporationId();

  if (isLoading() || corporationId === '') return <Loading />;

  return (
    <LoadingView>
      <Create {...props}>
        <CustomForm
          title="admin.pages.retrofitManageAdminBulkActionCreate"
          resource={resource}
          initialValues={{ corporationId }}
        >
          <BreadcrumbsFiled
            breadcrumbs={['admin.breadcrumbs.retrofitManageAdmin']}
            label="admin.breadcrumbs.retrofitManageAdminBulkActionCreate"
          />
          <FileInput
            resource={resource}
            source="uploadFile"
            validate={[required()]}
            accept=".csv"
            placeholder={
              <p>
                {translate('admin.label.fileInput.plaseholder', {
                  fileTypes: translate('admin.label.fileInput.csv'),
                })}
              </p>
            }
          >
            <FileField source="src" title="title" />
          </FileInput>
        </CustomForm>
      </Create>
    </LoadingView>
  );
};

RetrofitManageAdminBulkActionCreate.displayName =
  'RetrofitManageAdminBulkActionCreate';
export default RetrofitManageAdminBulkActionCreate;
