import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { RetrofitData } from '../dataProvider';
import lastDialogOpenedTimeStore from '../utils/lastDialogOpenedTimeStore';

const useCheckLicensePurchaseDialog = () => {
  const dataProvider = useDataProvider();
  return useCallback(
    async (corporationId): Promise<boolean> => {
      const lastShowedTime = lastDialogOpenedTimeStore.get(); // 前回ダイアログ出力日時
      const oneDay = 24 * 60 * 60 * 1000; // 24時間後

      //* * ログイン時、もしくは前回ダイアログを出力してから24H経過しているか確認 */
      const needRetrofitCheck = lastShowedTime
        ? new Date(lastShowedTime).getTime() + oneDay - Date.now() < 0
        : true;

      if (needRetrofitCheck) {
        // ログインユーザーのライセンスが紐づいていない建機の有無を確認
        const { data: retrofitLicenseUnattached } =
          await dataProvider.getList<RetrofitData>('retrofits', {
            sort: { field: 'id', order: 'ASC' },
            pagination: { page: 1, perPage: 1 },
            filter: {
              ownerCorporationId: corporationId, // 利用中 または 所有中
              isSiteAttached: true,
              hasLicense: false,
            },
          });

        return retrofitLicenseUnattached.length > 0;
      }
      return false;
    },
    [dataProvider],
  );
};

export default useCheckLicensePurchaseDialog;
