import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CustomList,
  Datagrid,
  CustomDateField,
  ListDownloadButton,
  FunctionField,
  Loading,
} from '../../..';
import {
  useAccount,
  useGetSelefDetailData,
  useResource,
} from '../../../../hooks';
import { getRetrofitInfoBreadcrumbs } from './util';
import { useGetSiteName } from './hooks';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 802,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 96,
          },
        },
      },
    },
  }),
);

interface ApiResult {
  isOwnData: boolean;
  isAdminCorporation: boolean;
  siteAttachedData: boolean;
  fetchedRetrofitId: number;
  userCorporationId: string;
  breadcrumbs: string[];
}

const RetrofitKitInfoList: React.FC = () => {
  const resource = useResource('retrofitKitInfos');
  const { retrofitId: strRetrofitId, siteId } =
    useParams<{ retrofitId: string; siteId: string }>();
  const retrofitId = Number(strRetrofitId);
  const classes = useStyles();
  const getAccount = useAccount();
  const getSelefDetailData = useGetSelefDetailData();
  // const redirectTo = useRedirect();
  const getSiteName = useGetSiteName();

  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: ApiResult;
  }>({
    loading: true,
    fetched: false,
    data: undefined,
  });

  useEffect(() => {
    // 既にDB取得済みでも、urlのretrofitIdが変わった場合は再度実施
    if (apiState.fetched && apiState.data?.fetchedRetrofitId === retrofitId) {
      return;
    }

    const api = async (): Promise<{
      data: {
        apiResult: ApiResult;
      };
    }> => {
      // accounts
      const {
        data: { corporationId },
      } = await getAccount();

      // retrofits
      const listFileter = {
        ownerCorporationId: corporationId,
        adminCorporationId: corporationId,
      };
      // 建機一覧から閲覧可能なレトロフィットであるか判定
      const retrofit = await getSelefDetailData(
        'retrofits',
        listFileter,
        retrofitId,
      );

      // ログインユーザーはretrofitの管理者であるか判定
      const isAdminCorporation = retrofit?.adminCorporations
        ? retrofit.adminCorporations.some(
            (it: any) => it.corporationId === corporationId,
          )
        : false;

      // 現場に紐づくレトロフィットであるか判定
      const siteAttachedListFileter = {
        siteId: siteId || '',
      };
      const siteAttachedRetrofit = await getSelefDetailData(
        'retrofits',
        siteAttachedListFileter,
        retrofitId,
      );

      let siteName;
      if (siteId) {
        const result = await getSiteName({ siteId });
        siteName = result.data.siteName;
      }

      // DBの取得結果を使うため、パンくずリストはここで作成
      const breadcrumbs = getRetrofitInfoBreadcrumbs(
        siteAttachedRetrofit?.machineInfoMachineName
          ? siteAttachedRetrofit?.machineInfoMachineName
          : retrofit?.machineInfoMachineName || '',
        siteName,
      );

      return {
        data: {
          apiResult: {
            isOwnData: !!retrofit,
            isAdminCorporation,
            siteAttachedData: !!siteAttachedRetrofit,
            fetchedRetrofitId: retrofitId,
            userCorporationId: corporationId,
            breadcrumbs,
          },
        },
      };
    };
    api().then(({ data: { apiResult } }) => {
      setApiState({ loading: false, fetched: true, data: apiResult });
    });
  }, [
    getSelefDetailData,
    getAccount,
    getSiteName,
    siteId,
    retrofitId,
    apiState,
  ]);

  if (apiState.loading || !apiState.data) return <Loading />;

  // TODO: 閲覧不可なデータは現場一覧に遷移する機能を有効にする場合、コメントアウトを外す
  const { userCorporationId, breadcrumbs, isAdminCorporation } = apiState.data;
  // 建機一覧に存在しないretrofitのコントローラ情報は表示させない
  // または現場詳細画面から遷移する場合、対象現場に紐づかないretrofitのコントローラ情報は表示させない
  // if (siteId ? !siteAttachedData : !isOwnData) redirectTo('/');

  const filter = isAdminCorporation
    ? { retrofitId }
    : { retrofitId, userCorporationId };

  return (
    <CustomList
      breadcrumbs={breadcrumbs}
      resource={resource}
      title="admin.pages.retrofitKitInfoList"
      bulkActionButtons={false}
      filter={filter}
    >
      <Datagrid className={classes.root}>
        <CustomDateField resource={resource} source="timeStamp" showTime />
        <FunctionField
          resource={resource}
          source="url"
          sortable={false}
          label=""
          render={record => {
            if (!record) return <></>;
            const { url } = record;
            const label = 'resources.retrofitKitInfos.fields.url';
            return <ListDownloadButton label={label} url={url} />;
          }}
        />
      </Datagrid>
    </CustomList>
  );
};

RetrofitKitInfoList.displayName = 'RetrofitKitInfoList';
export default RetrofitKitInfoList;
