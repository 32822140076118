import React from 'react';
import { Record } from 'react-admin';
import { ResourceFieldFC, BreadcrumbsPortal } from '..';

interface Props {
  breadcrumbs?: string[];
  customPaths?: string[];
  label?: string;
  source?: string;
  record?: Record;
}

const FC: React.FC<Props> = ({
  breadcrumbs,
  customPaths,
  label,
  source,
  record,
}) => {
  const title = label || (record && source && record[source]);
  return (
    <BreadcrumbsPortal
      breadcrumbs={breadcrumbs}
      customPaths={customPaths}
      title={title}
    />
  );
};

const BreadcrumbsField = FC as ResourceFieldFC<Props>;
BreadcrumbsField.displayName = 'BreadcrumbsField';
export default BreadcrumbsField;
