import { fetchUtils } from 'react-admin';
import { config } from '../../utils';
import { RemotePanelStatusApi } from '.';

const uri = `${config.api.remote.apiUri}/operators/get_panel_status`;

export default async (params: string): Promise<RemotePanelStatusApi> => {
  const setbody = params.split(',').join('&');

  const { json } = await fetchUtils
    .fetchJson(uri, {
      method: 'POST',
      mode: 'no-cors',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: setbody,
    })
    .catch(() => {
      // API内部でエラー発生時はnullで返却
      return { json: null };
    });
  return json;
};
