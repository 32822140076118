import { RetrofitCorporationLicenseData } from '../../../../dataProvider';

export default (
  licenses: RetrofitCorporationLicenseData[],
  permission:
    | 'Temporary'
    | 'All'
    | 'MachineGuidance'
    | 'MachineGuidance2D'
    | 'PayloadMeter'
    | 'Asbuilt'
    | 'FlexAll',
): RetrofitCorporationLicenseData | undefined => {
  const item = licenses.filter(
    ({ licensePermission }) => licensePermission === permission,
  );
  if (item.length <= 0) return undefined;
  const [ret] = item;
  return ret;
};
