import React, { useState } from 'react';
import {
  required,
  useTranslate,
  ArrayInput as RaArrayInput,
  SelectInput as RaSelectInput,
  FormDataConsumer,
} from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  Button,
  Create,
  CustomForm,
  BreadcrumbsFiled,
  FileField,
  LoadingView,
  SelectInput,
  DateTimePickerInput,
  TextInput,
  FileInput,
  Typography,
  CheckboxGroupInput,
  FunctionField,
  ArrayInputDeleteButton,
  SimpleFormIterator,
} from '../..';
import { UserNoticeData } from '../../../dataProvider/types';
import { usePageProps, useResource } from '../../../hooks';
import { styles, colors } from '../../../theme';
import { buttonWidth, buttonHeight } from '../../../theme/define';

const useStyles = makeStyles(() =>
  createStyles({
    description: {
      ...styles.multilineText,
    },
    title: {
      padding: '12px 12px',
      marginTop: '12px',
      borderTop: '1px solid #F5F5FF33',
    },
    root: {
      '& > div': {
        '& > div': {
          '& > div': {
            display: 'flex', // 注意: デフォルトの inline-flex だと width 100% が効かない
          },
        },
      },
    },
    arrayInput: {
      marginTop: 0,
      marginBottom: 0,
      '& > label': {
        // ArrayInput の Label をCSSで非表示にする (TextInput のように addLabel が用意されていない為)
        display: 'none',
      },
      '& > ul': {
        width: '100%',
      },
      '& > ul > li': {
        borderTop: '1px solid rgba(255, 255, 255, 0.12)', // borderButtomに自動設定されるスタイルをborderTopへ
        borderBottom: 0,
      },
      '& > ul > li > p': {
        display: 'none',
      },
    },
  }),
);

const useIteratorAddButtonStyle = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: colors.button.cancel,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      color: colors.text.primaryLight,
      fontSize: 14,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
      },
      '& span': {
        padding: 0,
      },
    },
  }),
);

const useIteratorAddButton = () => {
  const classes = useIteratorAddButtonStyle();
  return (
    <Button
      classes={classes}
      label="admin.actions.addLanguage"
      data-testid="admin.actions.addLanguage"
      onClick={e => e.preventDefault()}
    />
  );
};

const useIteratorDeleteButton = () => {
  return <ArrayInputDeleteButton onClick={e => e.preventDefault()} />;
};

const UserNoticesCreate: React.FC = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const props = usePageProps('userNotices');
  const resource = useResource('userNotices');

  // validation 処理
  const msgStartPeriod = translate(
    'resources.userNotices.fields.publicationStartDate',
  );
  const msgEndPeriod = translate(
    'resources.userNotices.fields.publicationEndDate',
  );

  // 日付判定用funciton
  function nomalFromToDate(start: string, end: string): boolean {
    return new Date(start) <= new Date(end);
  }

  const startPeriodValidation = (unusedValue: any, allValues: any) => {
    const fromDate = allValues.publicationStartDate;
    const toDate = allValues.publicationEndDate;

    if (!fromDate || !toDate) {
      return null;
    }
    if (nomalFromToDate(fromDate, toDate)) {
      return null;
    }
    return {
      message: 'admin.validation.validBeforeDate',
      args: {
        target: msgStartPeriod,
        before: msgEndPeriod,
      },
    };
  };

  const endPeriodValidation = (unusedValue: any, allValues: any) => {
    const fromDate = allValues.publicationStartDate;
    const toDate = allValues.publicationEndDate;

    if (!fromDate || !toDate) {
      return null;
    }
    if (nomalFromToDate(fromDate, toDate)) {
      return null;
    }
    return {
      message: 'admin.validation.validAfterDate',
      args: {
        target: msgEndPeriod,
        after: msgStartPeriod,
      },
    };
  };

  // 設定済み言語をリスト内で非活性に設定
  const languageDisabled = (form: any, own: any, language: string) => {
    // 自身のコントロールで選択済みの言語は選べていい
    if (own && own.language === language) return false;
    if (!form || !form.translations) return false;

    // // 既に他のコントロールで使用済みである場合非活性
    const otherLanguages: string[] = form.translations.map(
      (it: any) => it?.language || '',
    );
    return language ? otherLanguages.includes(language) : false;
  };

  const [disableAddLanguage, setDisableAddLanguage] = useState(false);

  const formValidation = (value: any) => {
    // 言語の追加制限制御
    const formData = value as UserNoticeData;
    if (formData && formData.translations) {
      setDisableAddLanguage(formData.translations?.length >= 4);
    }

    return {};
  };

  const addButton = useIteratorAddButton();
  const deleteButton = useIteratorDeleteButton();
  return (
    <LoadingView>
      <Create {...props}>
        <CustomForm
          title="admin.pages.userNoticeCreate"
          className={classes.root}
          validate={formValidation}
        >
          <BreadcrumbsFiled
            breadcrumbs={['resources.userNotices.name']}
            label="ra.action.create"
          />
          <CheckboxGroupInput
            resource={resource}
            source="noticeTypes"
            choices={[
              {
                id: 'Emergency',
                name: translate('admin.label.userNotices.emergency'),
              },
              {
                id: 'Important',
                name: translate('admin.label.userNotices.important'),
              },
            ]}
          />
          <SelectInput
            resource={resource}
            source="noticeCategory"
            choices={[
              {
                id: 'Information',
                name: translate('admin.label.userNotices.Information'),
              },
              {
                id: 'Maintenance',
                name: translate('admin.label.userNotices.Maintenance'),
              },
            ]}
            validate={[required()]}
            optionText="name"
          />
          <DateTimePickerInput
            resource={resource}
            source="publicationStartDate"
            validate={[
              required('admin.validation.invalidDateTimeFormat'),
              startPeriodValidation,
            ]}
            useUtc={true}
          />
          <DateTimePickerInput
            resource={resource}
            source="publicationEndDate"
            minDate={new Date()}
            validate={[
              required('admin.validation.invalidDateTimeFormat'),
              endPeriodValidation,
            ]}
            useUtc={true}
          />
          <Typography className={classes.title}>
            {translate('admin.label.userNotices.languageDetails')}
          </Typography>
          <FunctionField
            label="admin.label.userNotices.language"
            render={() => {
              return (
                <Typography>
                  {translate('admin.label.userNotices.languageEn')}
                </Typography>
              );
            }}
          />
          <TextInput
            resource={resource}
            source="title"
            validate={[required()]}
          />
          <TextInput
            resource={resource}
            source="content"
            validate={[required()]}
            multiline
            rows={5}
            className={classes.description}
          />
          <FileInput
            multiple
            resource={resource}
            source="file"
            validate={[]}
            accept=".pdf"
            placeholder={
              <p>
                {translate('admin.label.fileInput.plaseholder', {
                  fileTypes: translate('admin.label.fileInput.pdf'),
                })}
              </p>
            }
          >
            <FileField source="src" title="title" />
          </FileInput>
          <RaArrayInput
            resource={resource}
            source="translations"
            label=""
            className={classes.arrayInput}
          >
            <SimpleFormIterator
              disableAdd={disableAddLanguage}
              addButton={addButton}
              removeButton={deleteButton}
            >
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource }) => {
                  return getSource ? (
                    <RaSelectInput
                      source={getSource('language')}
                      label={translate('resources.userNotices.fields.language')}
                      choices={[
                        {
                          id: 'JA',
                          name: translate('admin.label.userNotices.languageJa'),
                          disabled: languageDisabled(
                            formData,
                            scopedFormData,
                            'JA',
                          ),
                        },
                        {
                          id: 'FR',
                          name: translate('admin.label.userNotices.languageFr'),
                          disabled: languageDisabled(
                            formData,
                            scopedFormData,
                            'FR',
                          ),
                        },
                        {
                          id: 'DE',
                          name: translate('admin.label.userNotices.languageDe'),
                          disabled: languageDisabled(
                            formData,
                            scopedFormData,
                            'DE',
                          ),
                        },
                        {
                          id: 'ES',
                          name: translate('admin.label.userNotices.languageEs'),
                          disabled: languageDisabled(
                            formData,
                            scopedFormData,
                            'ES',
                          ),
                        },
                      ]}
                      validate={[required()]}
                      optionText="name"
                    />
                  ) : null;
                }}
              </FormDataConsumer>
              <TextInput
                resource={resource}
                validate={[required()]}
                source="title"
              />
              <TextInput
                resource={resource}
                source="content"
                validate={[required()]}
                multiline
                rows={5}
                className={classes.description}
              />
              <FileInput
                multiple
                resource={resource}
                source="file"
                validate={[]}
                accept=".pdf"
                placeholder={
                  <p>
                    {translate('admin.label.fileInput.plaseholder', {
                      fileTypes: translate('admin.label.fileInput.pdf'),
                    })}
                  </p>
                }
              >
                <FileField source="src" title="title" />
              </FileInput>
            </SimpleFormIterator>
          </RaArrayInput>
        </CustomForm>
      </Create>
    </LoadingView>
  );
};

UserNoticesCreate.displayName = 'UserNoticesCreate';
export default UserNoticesCreate;
