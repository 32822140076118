import React, { useCallback } from 'react';
import {
  fetchRelatedRecords,
  useDataProvider,
  useNotify,
  useListContext,
  useResourceContext,
} from 'ra-core';
import { DownloadIcon } from '../../../assets';
import { Button } from '../../bases';
import { useGetListAllPage } from '../../../hooks';

// packages/ra-ui-materialui/src/button/ExportButton.tsx をコピーしてカスタマイズ
// FIXME: 型エラーが起きるため暫定でany、理想は => // import { ExportButtonProps } from 'ra-ui-materialui/src/button/ExportBUtton';
const CustomExportButton = (props: any) => {
  const {
    onClick,
    label = 'ra.action.export',
    exporter: customExporter,
    sort,
    ...rest
  } = props;
  const {
    filter,
    filterValues,
    exporter: exporterFromContext,
    total,
  } = useListContext(props);
  const resource = useResourceContext(props);
  const exporter = customExporter || exporterFromContext;
  const dataProvider = useDataProvider();
  const getListAllPage = useGetListAllPage();
  const notify = useNotify();
  const handleClick = useCallback(
    event => {
      getListAllPage({
        resource,
        filter: filter ? { ...filterValues, ...filter } : filterValues,
        sort,
      })
        .then(
          ({ data }) =>
            exporter &&
            exporter(
              data,
              fetchRelatedRecords(dataProvider),
              dataProvider,
              resource,
            ),
        )
        .catch(error => {
          console.error(error);
          notify('ra.notification.http_error', { type: 'warning' });
        });
      if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [
      getListAllPage,
      dataProvider,
      exporter,
      filter,
      filterValues,
      notify,
      onClick,
      resource,
      sort,
    ],
  );

  return (
    <Button
      onClick={handleClick}
      label={label}
      disabled={total === 0}
      {...rest}
    >
      <DownloadIcon />
    </Button>
  );
};

CustomExportButton.displayName = 'CustomExportButton';
export default CustomExportButton;
