import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getResources, useTranslate } from 'react-admin';
import {
  withRouter,
  Link,
  LinkProps,
  RouteComponentProps,
} from 'react-router-dom';
import {
  MenuItem,
  ListItemIcon,
  Tooltip,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { ViewList } from '@material-ui/icons';
import { RetrofitManageIcon } from '../../assets';
import { config, countryCodeStore } from '../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 0,
    },
    listItemIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 60,
      height: 60,
    },
  }),
);

const retrofitManage = 'retrofitManage';

interface MenuItemLinkProps {
  resource: any;
  supportMode: boolean;
  myCorporationId: string;
  enableChangeRetrofitOwner: boolean;
  hasControllerRegistRole: boolean;
  hasAlternateRegistRole: boolean;
}

const MenuItemLink: FC<MenuItemLinkProps> = ({
  resource,
  supportMode,
  myCorporationId,
  enableChangeRetrofitOwner,
  hasControllerRegistRole,
  hasAlternateRegistRole,
}) => {
  const classes = useStyles();
  const isRetrofitManage = resource === retrofitManage;
  const to = isRetrofitManage ? `/controllerRegistration` : `/${resource.name}`;
  const translate = useTranslate();
  const title = isRetrofitManage
    ? translate('admin.label.retrofitsManage.menuTitle')
    : translate(`resources.${resource.name}.name`);
  const renderLink = React.useMemo(() => {
    const RenderLink = React.forwardRef<any, Omit<LinkProps, 'to'>>(
      (itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />,
    );
    RenderLink.displayName = 'RenderLink';
    return RenderLink;
  }, [to]);
  let icon = <ViewList />;
  if (resource.icon) {
    icon = <resource.icon />;
  } else if (isRetrofitManage) {
    // configでoffの場合は条件変更 ※REACT_APP_FEATURE_RETROFIT_ALTERNATE_REGIST の削除にて該当変数を削除し、「!hasControllerRegistRole」に置換
    const displayNone = config.feature.retrofitAlternateRegist
      ? !hasControllerRegistRole
      : countryCodeStore.get() === 'JP' || countryCodeStore.get() === '';
    if (supportMode || displayNone) {
      return null;
    }
    icon = <RetrofitManageIcon />;
  }

  // 以下の条件に当てはまる場合、
  // レトロフィット企業ライセンスのグランドメニューを非表示にする
  /**
   *    ログイン中ユーザーの国コードが利用可能国でない場合
   *    かつ、 ログイン中ユーザーの企業IDが利用可能企業でない場合
   *
   *    または、レトロフィット企業ライセンスの機能有効化設定(env)が無効の場合
   */
  const { corporationId: kal } = config.corporation.kal;
  const countryForCorporationLicense = ['JP'];
  const companyForCorporationLicense = [kal];
  const countryNg = !countryForCorporationLicense.includes(
    countryCodeStore.get(),
  );
  const corporationNg = !companyForCorporationLicense.includes(myCorporationId);
  if (
    resource.name === 'retrofitCorporationLicenses' &&
    ((countryNg && corporationNg) || !config.feature.useRetrofitLicense)
  )
    return null;

  // 以下の条件に当てはまる場合、
  // 代理申請のグランドメニューを非表示にする
  /**
   *    サポートモード利用中
   *    または、ユーザーロール権限不足
   *    または、RetrofitOwnerChangeCorporation API にログイン中企業が含まれない
   *    または、代理申請の機能有効化設定(env)が無効の場合
   */
  if (
    resource.name === 'retrofitAlternateRegists' &&
    (supportMode ||
      !hasAlternateRegistRole ||
      !enableChangeRetrofitOwner ||
      !config.feature.retrofitAlternateRegist)
  )
    return null;

  return (
    <MenuItem
      key={resource.name}
      component={renderLink}
      className={classes.root}
    >
      <Tooltip title={title}>
        <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      </Tooltip>
    </MenuItem>
  );
};

interface Props extends RouteComponentProps {
  supportMode: boolean;
  corporationId: string;
  enableChangeRetrofitOwner: boolean;
  hasControllerRegistRole: boolean;
  hasAlternateRegistRole: boolean;
}

const Menu: FC<Props> = ({
  supportMode,
  corporationId,
  enableChangeRetrofitOwner,
  hasControllerRegistRole,
  hasAlternateRegistRole,
}) => {
  const resources = useSelector(getResources);
  const hideMenuResources = ['retrofitBulkActions', 'ntrips', 'extensionarms'];

  return (
    <>
      {resources
        .filter(
          ({ name, hasList }) => hasList && !hideMenuResources.includes(name),
        )
        .map(resource => (
          <MenuItemLink
            key={resource.name}
            resource={resource}
            supportMode={supportMode}
            myCorporationId={corporationId}
            enableChangeRetrofitOwner={enableChangeRetrofitOwner}
            hasControllerRegistRole={hasControllerRegistRole}
            hasAlternateRegistRole={hasAlternateRegistRole}
          />
        ))}
      {config.feature.retrofitManage ? (
        <MenuItemLink
          key={retrofitManage}
          resource={retrofitManage}
          supportMode={supportMode}
          myCorporationId={corporationId}
          enableChangeRetrofitOwner={enableChangeRetrofitOwner}
          hasControllerRegistRole={hasControllerRegistRole}
          hasAlternateRegistRole={hasAlternateRegistRole}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const CustomMenu = withRouter(Menu);
CustomMenu.displayName = 'CustomMenu';

export default CustomMenu;
