import React from 'react';
import {
  MapProps,
  crearteOverlayControl,
  AlertMapProps,
} from '../../../../../hooks';
import { MapLineworkButton } from '../../../..';

const addLineworkButtonControl = (
  mapProps: MapProps | AlertMapProps,
  onClick?: (mapProps: MapProps | AlertMapProps, showLinework: boolean) => void,
) => {
  mapProps.map.addControl(
    crearteOverlayControl(
      'map_linework',
      <MapLineworkButton
        defaultShowLineWork={mapProps.defaultLineWork}
        onClick={(showLinework?: boolean) => {
          if (onClick)
            onClick(mapProps, showLinework === undefined ? true : showLinework);
        }}
      />,
      {
        left: 11,
        top: 111,
      },
    ),
  );
};

export default addLineworkButtonControl;
