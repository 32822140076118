import React, { FC, useState } from 'react';
import { BreadcrumbsFiled, Buttons } from '../..';
import {
  CenterContainer,
  Title,
  RegionSelect,
  Message,
  TermsCondition,
  AcceptButton,
  DenyButton,
} from './views';

const ConsentTerms: FC = () => {
  const [regionId, setRegionId] = useState<number | undefined>();
  const [agreeDisabled, setAgreeDisabled] = useState(true);

  const handleRegionChange = (newRegionId?: number) => {
    setRegionId(newRegionId);
    setAgreeDisabled(true);
  };

  const handleConfirmed = () => {
    setAgreeDisabled(false);
  };

  return (
    <>
      <BreadcrumbsFiled label="admin.breadcrumbs.terms" />
      <CenterContainer>
        <Title />
        <RegionSelect onChange={handleRegionChange} />
        {!!regionId && (
          <>
            <div>
              <Message label="admin.label.terms.confirmMessage1" />
              <Message label="admin.label.terms.confirmMessage2" />
            </div>
            <TermsCondition
              termsConditionId={regionId}
              onConfirmed={handleConfirmed}
            />
            <Buttons>
              <DenyButton />
              <AcceptButton
                termsConditionId={regionId}
                disabled={agreeDisabled}
              />
            </Buttons>
          </>
        )}
      </CenterContainer>
    </>
  );
};

ConsentTerms.displayName = 'ConsentTerms';
export default ConsentTerms;
