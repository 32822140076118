import React from 'react';
import { useLoading } from 'react-admin';

export interface Props {
  isForceBlocking?: boolean; // useLoadingの状態に関わらず強制的にUIブロックさせるフラグ
}

const LoadingView: React.FC<Props> = props => {
  const { children } = props;
  const isForceBlocking = props?.isForceBlocking || false;
  const isBlocking = useLoading() || isForceBlocking;
  const pointerEvents = isBlocking ? 'none' : 'auto';
  return <div style={{ pointerEvents }}>{children}</div>;
};

const LoadingViewEx: React.FC<{
  isForceBlocking?: boolean;
  children?: (isBlocking: boolean) => React.ReactNode;
}> = ({ isForceBlocking = false, children }) => {
  const isBlocking = useLoading() || isForceBlocking;
  const pointerEvents = isBlocking ? 'none' : 'auto';
  const ret = children ? children(isBlocking) : <></>;
  return <div style={{ pointerEvents }}>{ret}</div>;
};

LoadingView.displayName = 'LoadingView';
LoadingViewEx.displayName = 'LoadingViewEx';
export { LoadingView, LoadingViewEx };
