import React from 'react';
import { SwitchListIcon } from '../../../assets';
import Button from '../../bases/button/Button';

export interface Props {
  onClick: () => void;
}

const SiteListIcon = (props: Props) => {
  const { onClick } = props;
  return (
    <Button onClick={onClick}>
      <SwitchListIcon />
    </Button>
  );
};

SiteListIcon.displayName = 'SiteListIcon';

export default SiteListIcon;
