import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  required,
  useDataProvider,
  useGetPermissions,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  Edit,
  CustomForm,
  BreadcrumbsFiled,
  TextInput,
  TextField,
  CustomDeleteButton,
  LinkButton,
  DialogOKOnly,
  Button,
  ReferenceInput,
  SelectInput,
  Loading,
  Typography,
  NormalButton,
  NtripNameField,
} from '../..';
import { useEdit, useResource } from '../../../hooks';
import { styles, colors } from '../../../theme';
import { ReturnIcon, DeleteIcon, TransferIcon } from '../../../assets';
import {
  buttonWidth,
  buttonHeight,
  buttonDisabledOpacity,
} from '../../../theme/define';
import { useGetRetrofitLending } from './hooks';
import { CompareField } from './views';
import { config, hasUserRole } from '../../../utils';
import { RetrofitSendbackData } from '../../../dataProvider';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      padding: '12px 0px',
      borderTop: '1px solid #F5F5FF33',
    },
    description: {
      ...styles.multilineText,
    },
    ownerId: {
      ...styles.longWidthText,
      margin: 0,
    },
    ownerName: {
      margin: 0,
    },
    deleteButton: {
      background: colors.button.delete,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        background: colors.button.deleteHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.deleteDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
    icon: {
      '& > div': {
        marginLeft: -14,
      },
    },
  }),
);

const CancelButton: React.FC<{ basePath: string }> = props => {
  const { basePath } = props;
  return (
    <LinkButton path={basePath} label="ra.action.cancel">
      <ReturnIcon />
    </LinkButton>
  );
};

const DeleteInfoButton: React.FC = () => {
  const [open, setOpen] = useState(false);
  const handleShow = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const handleHide = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const classes = useStyles();

  return (
    <>
      <Button
        label=""
        data-testid="ra.action.delete"
        onClick={handleShow}
        className={classes.deleteButton}
      >
        <DeleteIcon />
      </Button>
      <DialogOKOnly
        open={open}
        onClose={handleHide}
        title=""
        message="admin.dialog.retrofitBasicInfoEdit.undeletable.message"
      />
    </>
  );
};

const ToggleDeleteButton: React.FC = props => {
  const redirect = '/retrofitBasicInfos'; // NOTE: タブがある為、ルート階層に戻るのに必要
  const { record }: any = props;
  if (!record.lastOnlineTime) {
    return (
      <CustomDeleteButton
        {...props}
        redirect={redirect}
        messageTargetItem="admin.dialog.retrofitBasicInfoEdit.deleteConfirm.messageTargetItem"
      >
        <DeleteIcon />
      </CustomDeleteButton>
    );
  }
  return <DeleteInfoButton />;
};

const SendBackButton: React.FC<{
  redirectIcon: any;
  redirectPath: string;
  retrofitId: number;
}> = ({ redirectIcon, redirectPath }) => {
  const redirectTo = useRedirect();

  const handleRedirectClick = useCallback(() => {
    redirectTo(redirectPath);
  }, [redirectTo, redirectPath]);

  return (
    <NormalButton
      label={'admin.label.retrofitSendbacks.retrofitSendbackInfo'}
      onClick={handleRedirectClick}
    >
      {redirectIcon}
    </NormalButton>
  );
};

interface ApiResult {
  hasAuthority: boolean;
  sendbackData: RetrofitSendbackData | undefined;
}

const RetrofitBasicInfoEdit: React.FC = () => {
  const resource = useResource('retrofitBasicInfos');
  const { props } = useEdit(resource, {
    resource,
    key: 'id',
  });
  const { basePath } = props;
  const redirect = '/retrofitBasicInfos'; // NOTE: タブがある為、ルート階層に戻るのに必要
  const classes = useStyles();
  const userRole = hasUserRole();
  const translate = useTranslate();
  const getPermissions = useGetPermissions();
  const dataProvider = useDataProvider();
  const { id } = useParams<{ id: string }>();
  const retrofitId = Number(id);

  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: ApiResult;
  }>({
    loading: true,
    fetched: false,
    data: undefined,
  });

  useEffect(() => {
    if (apiState.fetched) return;
    setApiState({ loading: true, fetched: true });

    const api = async (): Promise<{
      data: {
        apiResult: ApiResult;
      };
    }> => {
      // ユーザーのセンドバック権限を取得
      const permission = await getPermissions();
      const { userRoles } = permission;
      const hasRole = userRole('CreateRetrofit', userRoles);

      // センドバック情報の取得
      const { data: sendback } =
        await dataProvider.getList<RetrofitSendbackData>('retrofitSendbacks', {
          pagination: {
            page: 1,
            perPage: 1, // 1件しか取る必要がない
          },
          sort: { field: 'id', order: 'ASC' },
          filter: {
            fromRetrofitId: retrofitId,
            isComplete: false,
          },
        });
      const sendbackData = sendback ? sendback[0] : undefined;

      return {
        data: {
          apiResult: {
            hasAuthority: !!hasRole,
            sendbackData,
          },
        },
      };
    };
    api().then(({ data: { apiResult } }) => {
      setApiState({ loading: false, fetched: true, data: apiResult });
    });
  }, [apiState.fetched, dataProvider, getPermissions, retrofitId, userRole]);

  const { loading, data } = useGetRetrofitLending({ retrofitId });
  if (loading || !apiState.data) return <Loading />;
  const { isLending } = data;
  const { sendbackData, hasAuthority } = apiState.data;
  const redirectUrl = sendbackData ? sendbackData.id : 'create';
  const redirectPath = `${basePath}/${retrofitId}/sendBack/${redirectUrl}`;
  const sendbackButtonIcon = <TransferIcon />;

  // ロール権限の所持、かつセンドバックボタン本番未公開機能がOFFの場合、センドバック機能利用可能
  const sendbackAvailable = hasAuthority && config.feature.ntrips;

  return (
    <Edit {...props}>
      <CustomForm
        redirect={redirect}
        title="admin.pages.retrofitBasicInfoEdit"
        resource={resource}
        deleteButton={<ToggleDeleteButton />}
        cancelButton={<CancelButton basePath={basePath} />}
      >
        <BreadcrumbsFiled
          breadcrumbs={['resources.retrofitBasicInfos.name']}
          label="ra.action.edit"
        />
        <TextField resource={resource} source="uuid" />
        <ReferenceInput
          resource={resource}
          source="retrofitDistributorId"
          reference="retrofitDistributors"
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          resource={resource}
          source="retrofitRegionId"
          reference="retrofitRegions"
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <CompareField
          resource={resource}
          label="resources.retrofitBasicInfos.fields.ownerId"
          isRequired={true}
          onCompare={({ corporationId, ownerId }) => corporationId === ownerId}
          inputElement={
            <TextInput
              resource={resource}
              source="corporationId"
              validate={[required()]}
              className={classes.ownerId}
              disabled={isLending}
            />
          }
          fieldElement={<TextField resource={resource} source="ownerId" />}
        />
        <CompareField
          resource={resource}
          label="resources.retrofitBasicInfos.fields.ownerName"
          onCompare={({ corporationId, ownerId }) => corporationId === ownerId}
          inputElement={
            <TextInput
              resource={resource}
              source="corporationName"
              className={classes.ownerName}
              disabled={isLending}
            />
          }
          fieldElement={<TextField resource={resource} source="ownerName" />}
        />
        <TextField
          resource={resource}
          source="corporationId"
          label="admin.label.retrofitBasicInfoEdit.corporationId"
        />
        <TextField
          resource={resource}
          source="corporationName"
          label="admin.label.retrofitBasicInfoEdit.corporationName"
        />
        <TextField resource={resource} source="basicInfoManufacturer" />
        <TextField resource={resource} source="basicInfoModel" />
        <TextField resource={resource} source="basicInfoProductNumber" />
        <TextField resource={resource} source="basicInfoSerialNumber" />
        {
          config.feature.ntrips ? (
            <NtripNameField label="resources.ntrips.fields.name" />
          ) : null // REACT_APP_FEATURE_NTRIPSが削除されたら合わせて空タブを削除する
        }
        <TextInput
          resource={resource}
          source="description"
          className={classes.description}
          multiline
          rows={3}
        />
        {sendbackAvailable ? (
          <>
            <Typography className={classes.title}>
              {translate('admin.label.retrofitSendbacks.retrofitSendbackInfo')}
            </Typography>
            <SendBackButton
              redirectIcon={sendbackButtonIcon}
              redirectPath={redirectPath}
              retrofitId={retrofitId}
            />
          </>
        ) : null}
      </CustomForm>
    </Edit>
  );
};

RetrofitBasicInfoEdit.displayName = 'RetrofitBasicInfoEdit';
export default RetrofitBasicInfoEdit;
