import { ReferenceInput } from 'react-admin';
import { ReactElement } from 'react';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import { PaginationPayload, SortPayload, InputProps } from 'ra-core';
import { ReferenceFC } from '..';

interface RaProps {
  allowEmpty?: boolean;
  basePath: string;
  children: ReactElement;
  choices: any[];
  classes?: object;
  className?: string;
  error?: string;
  helperText?: string;
  id: string;
  input: FieldInputProps<any, HTMLElement>;
  isRequired: boolean;
  label: string;
  loading: boolean;
  meta: FieldMetaState<any>;
  reference: string;
  resource: string;
  setFilter: (v: string) => void;
  setPagination: (pagination: PaginationPayload) => void;
  setSort: (sort: SortPayload) => void;
  source: string;
  warning?: string;
  perPage?: number;
}

const { defaultProps } = ReferenceInput;
ReferenceInput.defaultProps = {
  ...defaultProps,
  perPage: 100,
  sort: { field: 'name', order: 'ASC' },
};

ReferenceInput.displayName = 'ReferenceInput';
export default ReferenceInput as ReferenceFC<Partial<InputProps & RaProps>>;
