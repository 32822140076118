// ----------------------------------------------
// ログ出力可能な実行環境
// ----------------------------------------------
const allowPrintEnvs = [
  'test', // yarn test
  'development', // yarn start
];

const logPrint = (text: string, args?: any) => {
  if (!allowPrintEnvs.includes(process.env.NODE_ENV)) {
    return;
  }
  if (args) console.log(text, args);
  else console.log(text);
};

export default logPrint;
