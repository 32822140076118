import { useDataProvider } from 'react-admin';
import { useAccount } from '../../../../hooks';
import { RetrofitManageCorporationData } from '../../../../dataProvider';

export class PermissionError extends Error {}

interface OutParams {
  data: { corporationId: string; corporationName: string };
}

const useAdminInitial = () => {
  const dataProvider = useDataProvider();
  const getAccount = useAccount();
  return async (): Promise<OutParams> => {
    // ログインユーザーに紐づく企業情報を取得
    const {
      data: { corporationId, corporationName },
    } = await getAccount();

    // Admin画面の権限を取得
    const { total } = await dataProvider.getList<RetrofitManageCorporationData>(
      'retrofitManageCorporations',
      {
        pagination: {
          page: 1,
          perPage: 1,
        },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          corporationId,
        },
      },
    );
    if (total <= 0) {
      throw new PermissionError();
    }
    // 正常系は企業情報を返す
    return {
      data: {
        corporationId,
        corporationName,
      },
    };
  };
};

export default useAdminInitial;
