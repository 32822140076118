import { fetchUtils, GetOneResult } from 'react-admin';
import { AccountData, getOption, LandlogAccount } from '..';
import { config } from '../../utils';

const uri = `${config.api.landlog.apiUri}/v2/me`;

export default async (): Promise<GetOneResult> => {
  const { json } = await fetchUtils.fetchJson(uri, await getOption());
  const me = json as LandlogAccount;
  const data: AccountData = {
    id: 1,
    email: me.email,
    name: me.name,
    picture: me.picture,
    corporationId: me.corporation.id,
    corporationName: me.corporation.name,
  };
  return { data };
};
