import React, { useEffect, useState } from 'react';
import { useTranslate, Identifier, useDataProvider } from 'react-admin';
import { createStyles, makeStyles } from '@material-ui/core';
import {
  Divider,
  SimpleShowLayout,
  CustomForm,
  TextField,
  CustomDateField,
  Typography,
  FunctionField,
} from '../..';
import { useResource } from '../../../hooks';
import { RetrofitRentalData } from '../../../dataProvider/types';

const useStyles = makeStyles(() =>
  createStyles({
    topMargin: {
      marginTop: '12px',
    },
    titleFirst: {
      padding: '12px 0px',
    },
    title: {
      padding: '12px 0px',
      borderTop: '1px solid #F5F5FF33',
    },
    rentalInfoTitle: {
      fontSize: 18,
    },
  }),
);

const RentalInfo: React.FC = props => {
  const translate = useTranslate();
  const classes = useStyles();
  const resource = useResource('retrofitRentals');
  return (
    <>
      <Typography className={classes.rentalInfoTitle}>
        {translate('admin.label.retrofitRental.rentalInfo')}
      </Typography>
      <SimpleShowLayout {...props}>
        <TextField source="toCorporationName" resource={resource} />
        <CustomDateField source="planStartDate" resource={resource} />
        <CustomDateField source="planEndDate" resource={resource} />
        <CustomDateField source="actualStartDate" resource={resource} />
        <FunctionField
          resource={resource}
          source="isApproved"
          render={renderRentalData => {
            const status = translate(
              `resources.retrofitRentals.isApproved.${renderRentalData?.isApproved.toString()}`,
            );
            return <div>{status}</div>;
          }}
        />
      </SimpleShowLayout>
    </>
  );
};

interface Props {
  id: Identifier;
  corporationid?: string;
}

const RetrofitRentalDetails: React.FC<Partial<Props>> = props => {
  const { id: retrofitId, corporationid: corporationId = '' } = props as Props;
  const resource = useResource('retrofitRentals');
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [rentalData, setRentalData] = useState<RetrofitRentalData>();

  useEffect(() => {
    // Rental Details（レンタル）の表示条件
    // ＜フィルター条件＞
    // レトロフィットレンタル.レトロフィットID（retrofitRentals.retrofitId）＝ レトロフィット.ID（retrofits.id）が紐づくデータで
    // レトロフィットレンタル.To企業ID = 自分が所属している企業を取得(accounts.corporationId)
    // レトロフィットレンタル.返却済み(retrofitRentals.IsReturnd  = FALSE）※未返却
    if (!corporationId) return;
    dataProvider
      .getList(resource, {
        filter: {
          retrofitId,
          toCorporationId: corporationId,
          isReturned: false, // 貸している状態
        },
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'ASC' },
      })
      .then(result => {
        if (result === undefined) return;
        const { data } = result;
        setRentalData(data[0] as RetrofitRentalData); // 建機単位で表示するので1件しか扱わない
      });
  }, [dataProvider, retrofitId, corporationId, resource]);
  if (!rentalData) return <></>;

  return (
    <div className={classes.topMargin}>
      <CustomForm
        resource={resource}
        record={rentalData}
        redirect={false}
        deleteButton={false}
        saveButton={false}
        cancelButton={false}
      >
        <RentalInfo />
      </CustomForm>
      <div className={classes.topMargin} />
      <Divider />
    </div>
  );
};

RetrofitRentalDetails.displayName = 'RetrofitRentalDetails';
export default RetrofitRentalDetails;
