import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import useResource from './useResource';

interface InParams {
  retrofitIds: number[];
}

interface OutParams {
  data: {
    isPendingAlternateRegisted: boolean;
    pendingRetrofitIds: number[];
  };
}

const usePendingAlternateRegisted = () => {
  const dataProvider = useDataProvider();
  const resource = useResource('retrofitAlternateRegists');

  return useCallback(
    async ({ retrofitIds }: InParams): Promise<OutParams> => {
      const { data: alternateRegisteData } = await dataProvider.getList(
        resource,
        {
          pagination: {
            page: 1,
            perPage: retrofitIds.length, // 登録件数分データを取得
          },
          sort: { field: 'id', order: 'ASC' },
          filter: {
            retrofitId: retrofitIds,
            isApproved: false,
          },
        },
      );

      return {
        data: {
          isPendingAlternateRegisted: alternateRegisteData.length > 0,
          pendingRetrofitIds: alternateRegisteData.map(
            ({ retrofitId }) => retrofitId,
          ),
        },
      };
    },
    [dataProvider, resource],
  );
};

export default usePendingAlternateRegisted;
