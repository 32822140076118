import { GeofenceStoreInfo } from './types';

const SOTRE_KEY = 'geofence';
export default {
  get: () => {
    const store = localStorage.getItem(SOTRE_KEY);
    if (!store) return null;
    return JSON.parse(store) as GeofenceStoreInfo;
  },

  set: (store: GeofenceStoreInfo) => {
    localStorage.setItem(SOTRE_KEY, JSON.stringify(store));
  },

  remove: () => {
    localStorage.removeItem(SOTRE_KEY);
  },
};
