import { useCreate, useDataProvider, useUpdate } from 'react-admin';
import { GnssSettingData } from '../dataProvider';

type Result = {
  onSuccess: () => void;
  onFailure: (error: Error) => void;
};

const useGnssSettingProvider = (basePath: string, record?: GnssSettingData) => {
  const dataProvider = useDataProvider();
  const resource = 'gnssSettings';
  const [create] = useCreate(resource);
  const gnsssettingId = record ? record.id : '';
  const [update] = useUpdate(resource, gnsssettingId, undefined, record);

  return {
    getRecordByRetrofitId: (
      retrofitId: number,
      callback: (gnssSetting: GnssSettingData | undefined) => void,
    ) => {
      const fn = async () => {
        const { data } = await dataProvider.getList(resource, {
          pagination: { perPage: 1, page: 1 },
          sort: { field: 'updateDate', order: 'DESC' },
          filter: { retrofitId },
        });
        const [gnssSetting] = data as GnssSettingData[];
        callback(gnssSetting);
      };
      fn();
    },

    create: (values: GnssSettingData, result: Result) => {
      create(
        {
          payload: {
            data: {
              ...values,
            },
          },
        },
        {
          onSuccess: () => {
            result.onSuccess();
          },
          onFailure: (error: Error) => result.onFailure(error),
        },
      );
    },

    update: (values: GnssSettingData, result: Result) => {
      update(
        {
          payload: {
            data: {
              ...values,
            },
          },
        },
        {
          onSuccess: () => {
            result.onSuccess();
          },
          onFailure: (error: Error) => result.onFailure(error),
        },
      );
    },
  };
};

export default useGnssSettingProvider;
