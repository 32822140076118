const KEY_COUNTRY_CODE = 'country_code';

const countryCodeStore = {
  get: () => {
    return localStorage.getItem(KEY_COUNTRY_CODE) || '';
  },
  set: (countryCode: string) => {
    localStorage.setItem(KEY_COUNTRY_CODE, countryCode);
  },
  clear: () => {
    localStorage.setItem(KEY_COUNTRY_CODE, '');
  },
};

export default countryCodeStore;
