import React, { useState, useEffect, useCallback } from 'react';
import {
  FormDataConsumer,
  Loading,
  required,
  useTranslate,
  useDataProvider,
  useNotify,
  // useRedirect,
} from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  BreadcrumbsFiled,
  CustomForm,
  Edit,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  TextField,
  FileInput,
  FileField,
  RegionSelectInput,
  LoadingView,
  DialogOKCancel,
  ErrorInfoLayout,
  ErrorInfoField,
  CustomDateField,
  IconField,
  FinalFormConsumer,
} from '../../..';
import {
  useEditInSite,
  useNameValidation,
  useFileNameValidation,
  useGetSelefDetailData,
} from '../../../../hooks';
import { propsOf, coordinateType, apiErrorHandler } from '../../../../utils';
import { ProjectsData } from '../../../../dataProvider';
import { SaveButton as FileCheckSaveButton } from './views';

const useStyles = makeStyles(() =>
  createStyles({
    labelLineHeight: {
      '& .MuiFormLabel-root': {
        lineHeight: '1rem',
      },
      '& .MuiInputBase-root': {
        '& .MuiSelect-root': {
          height: '100%',
        },
      },
    },
    icon: {
      '& > div > div': {
        marginLeft: -14,
      },
    },
  }),
);

interface SaveRaProps {
  form: any;
  record: any;
  disabled: boolean;
}

type SaveProps = Partial<SaveRaProps>;

const SaveButton: React.FC<SaveProps> = ({ form, disabled, record }) => {
  const { latestProjectVersionId } = record;
  const [open, setOpen] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const handleSubmit = useCallback(() => {
    form.submit();
  }, [form]);
  const handleHide = useCallback(() => setOpen(false), [setOpen]);
  const handleClick = useCallback(async () => {
    const modifiedFields: string[] = form
      .getRegisteredFields()
      .filter((field: string) => !form.getFieldState(field).pristine);

    if (modifiedFields.length === 1 && modifiedFields.includes('retrofitId')) {
      // 変更内容が 建機に紐づけ変更 のみの場合、
      // latestProjectVersionId が更新されないので確認ダイアログを出さない
      handleSubmit();
      return;
    }
    try {
      const result = await dataProvider.getList('tasks', {
        filter: { projectVersionId: latestProjectVersionId },
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'ASC' },
      });
      if (result.data.length <= 0) {
        handleSubmit();
      } else {
        setOpen(true);
      }
    } catch (error) {
      notify(apiErrorHandler(error), 'warning');
    }
  }, [
    form,
    handleSubmit,
    setOpen,
    notify,
    latestProjectVersionId,
    dataProvider,
  ]);

  return (
    <>
      <DialogOKCancel
        open={open}
        onClose={handleHide}
        onOKClick={handleSubmit}
        okLabel="admin.actions.yes"
        cancelLabel="admin.actions.no"
        title=""
        message="admin.dialog.projectEdit.confirm.message"
      />
      <FileCheckSaveButton
        form={form}
        disabled={disabled}
        onClick={handleClick}
      />
    </>
  );
};

const ProjectStateView: React.FC<{
  record?: ProjectsData;
  onStateChange: (status: string) => void;
}> = ({ record, onStateChange }) => {
  const status = record?.status ?? '';
  useEffect(() => {
    onStateChange(status);
  }, [status, onStateChange]);
  return <></>;
};

const SiteProjectEdit: React.FC = () => {
  const classes = useStyles();
  const { props, site } = useEditInSite('projects');
  const getSelefDetailData = useGetSelefDetailData();
  // const redirectTo = useRedirect();
  const { resource, id: projectId, siteId } = props;
  const nameValidation = useNameValidation(
    'projectNames',
    'admin.validation.duplicateProject',
    { siteId },
    [Number(projectId)],
  );
  const fileNameValidation = useFileNameValidation();
  const translate = useTranslate();
  const [status, setStatus] = useState('');
  const onStateHandle = useCallback(
    newStatus => {
      setStatus(newStatus);
    },
    [setStatus],
  );

  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    projectId: string | undefined;
    data?: {
      isOwnData?: boolean;
    };
  }>({ loading: true, fetched: false, projectId, data: undefined });

  useEffect(() => {
    if (apiState.fetched && apiState.projectId === projectId) return;
    setApiState({ loading: true, fetched: true, projectId });

    const api = async () => {
      const project = await getSelefDetailData(resource, { siteId }, projectId);
      return { isOwnData: !!project };
    };

    api().then(data => {
      setApiState({
        loading: false,
        fetched: true,
        projectId,
        data,
      });
    });
  }, [
    getSelefDetailData,
    projectId,
    apiState.fetched,
    apiState.projectId,
    resource,
    siteId,
  ]);

  if (!site || !apiState.data) return <Loading />;
  const { name: siteName, unitLength } = site;
  const coordinateTypeProjection = 'Projection';
  const handleSaveTransform = (record: ProjectsData) => ({
    ...record,
    unitLength,
  });

  // TODO: 閲覧不可なデータは現場一覧に遷移する機能を有効にする場合、コメントアウトを外す
  // const { isOwnData } = apiState.data;
  // 一覧に存在しないプロジェクトデータは表示させない
  // if (!isOwnData) redirectTo('/');

  return (
    <LoadingView isForceBlocking={status === 'WaitForConvert'}>
      <Edit {...props} transform={handleSaveTransform}>
        <CustomForm
          title="admin.pages.siteProjectEdit"
          redirect={false}
          deleteButton={false}
          saveButton={<SaveButton />}
        >
          <ProjectStateView onStateChange={onStateHandle} />
          <BreadcrumbsFiled
            breadcrumbs={[
              'resources.sites.name',
              '',
              siteName,
              'resources.projects.name',
            ]}
            label="ra.action.edit"
          />
          {status === 'Error' ? (
            <ErrorInfoLayout title="admin.dialog.projectConversionStatus.title">
              <TextField resource={resource} source="version" />
              <CustomDateField resource={resource} source="lastUpdated" />
              <IconField
                resource={resource}
                source="status"
                className={classes.icon}
              />
              <ErrorInfoField resource={resource} source="errorInfo" />
            </ErrorInfoLayout>
          ) : null}
          <TextInput
            resource={resource}
            source="name"
            validate={[required(), fileNameValidation, nameValidation]}
          />
          {/* TODO: 別の画面でも使用するので共通化したい */}
          <SelectInput
            resource={resource}
            source="coordinate"
            choices={coordinateType}
            validate={required()}
          />
          {/* 「region」「projection」「datum」の項目は、「Coordinate Type」がProjectionの場合のみ表示 */}
          <RegionSelectInput
            {...propsOf(RegionSelectInput, {
              coordinateType: coordinateTypeProjection,
            })}
          />

          <FinalFormConsumer>
            {({ form }) => (
              <>
                <FormDataConsumer>
                  {({ formData }) =>
                    formData.coordinate === coordinateTypeProjection && (
                      <ReferenceInput
                        resource={resource}
                        source="projectionId"
                        reference="projections"
                        filter={{ regionId: formData.regionId }}
                        perPage={1000}
                        onChange={() => {
                          form.change('datumId', null);
                        }}
                      >
                        <SelectInput optionText="name" validate={required()} />
                      </ReferenceInput>
                    )
                  }
                </FormDataConsumer>
                <FormDataConsumer>
                  {({ formData }) =>
                    formData.coordinate === coordinateTypeProjection && (
                      <ReferenceInput
                        resource={resource}
                        source="datumId"
                        reference="datums"
                        filter={{ projectionId: formData.projectionId }}
                        perPage={1000}
                      >
                        <SelectInput optionText="name" validate={required()} />
                      </ReferenceInput>
                    )
                  }
                </FormDataConsumer>
                <FormDataConsumer>
                  {({ formData }) =>
                    formData.coordinate === coordinateTypeProjection && (
                      <ReferenceInput
                        resource={resource}
                        source="geoidId"
                        reference="geoids"
                        filter={{ regionId: formData.regionId }}
                        perPage={1000}
                      >
                        <SelectInput optionText="name" />
                      </ReferenceInput>
                    )
                  }
                </FormDataConsumer>
              </>
            )}
          </FinalFormConsumer>
          <FileInput
            multiple
            resource={resource}
            source="file"
            validate={required()}
            accept=".xml,.tp3,.gc3,.dxf,.tn3,.ttm,.svd,.dsz,.cal,.ln3"
            placeholder={
              <p>
                {translate('admin.label.fileInput.plaseholder', {
                  fileTypes: translate('admin.label.fileInput.project'),
                })}
              </p>
            }
          >
            <FileField source="src" title="title" />
          </FileInput>
          <ReferenceArrayInput
            resource={resource}
            source="retrofitId"
            reference="retrofits"
            filter={{ siteId }}
            sort={{ field: 'machineInfoMachineName', order: 'ASC' }}
            className={classes.labelLineHeight}
          >
            <SelectArrayInput optionText="machineInfoMachineName" />
          </ReferenceArrayInput>
        </CustomForm>
      </Edit>
    </LoadingView>
  );
};

SiteProjectEdit.displayName = 'SiteProjectEdit';
export default SiteProjectEdit;
