import React, { FC, useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Loading, useDataProvider, useVersion } from 'react-admin';
import { omit } from 'lodash';
import { useParams } from 'react-router-dom';
import { RetrofitData } from '../../../../dataProvider';
import {
  useResource,
  usePageProps,
  useGetSelefDetailData,
  useAccount,
} from '../../../../hooks';
import {
  Edit,
  CustomForm,
  BreadcrumbsFiled,
  TextField,
  IconField,
  FunctionField,
} from '../../..';
import SaveButton from './views/SaveButton';
import DeleteButton from './views/DeleteButton';
import CancelButton from './views/CancelButton';
import MachineTypeField from './views/MachineTypeField';

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      '& > div': {
        marginLeft: -14,
      },
    },
  }),
);

const RetrofitTransferEdit: FC = () => {
  const props = usePageProps('assignments');
  const dataProvider = useDataProvider();
  const version = useVersion();
  const resource = useResource('assignments');
  const retrofits = useResource('retrofits');
  const classes = useStyles();
  const getAccount = useAccount();
  const getSelefDetailData = useGetSelefDetailData();
  // const redirectTo = useRedirect();

  const [apiState, setApiState] = useState<{
    loading: boolean;
    version: number;
    data?: {
      retrofit: RetrofitData;
      fetchedRetrofitId: string;
      fetchedTransferId: string;
      isOwnRetrofits: boolean;
      isOwnData: boolean;
    };
  }>({ loading: true, version: -1 });

  const { retrofitId, id } = useParams<{ retrofitId: string; id: string }>();
  const { basePath } = props;

  useEffect(() => {
    if (
      apiState.version === version &&
      apiState.data?.fetchedRetrofitId === retrofitId &&
      apiState.data?.fetchedTransferId === id
    )
      return;

    const api = async () => {
      // accounts
      const {
        data: { corporationId },
      } = await getAccount();

      // 自社のretrofitsか判定
      const filter = {
        ownerCorporationId: corporationId,
        adminCorporationId: corporationId,
      };

      const isOwnRetrofits = await getSelefDetailData(
        'retrofits',
        filter,
        retrofitId,
      );
      const isOwnTransferData = await getSelefDetailData(
        'assignments',
        { retrofitId },
        id,
      );

      // retrofits
      const { data: retrofit } = await dataProvider.getOne<RetrofitData>(
        'retrofits',
        {
          id: retrofitId,
        },
      );

      return {
        retrofit,
        fetchedRetrofitId: retrofitId,
        fetchedTransferId: id,
        isOwnRetrofits: !!isOwnRetrofits,
        isOwnData: !!isOwnTransferData,
      };
    };

    api().then(data => {
      setApiState({
        loading: false,
        version,
        data,
      });
    });
  }, [
    id,
    apiState,
    version,
    dataProvider,
    retrofitId,
    getAccount,
    getSelefDetailData,
  ]); // MEMO:LinetError回避のためretrofitIdを追加

  if (
    apiState.loading ||
    !apiState.data ||
    apiState.data?.fetchedRetrofitId !== retrofitId ||
    apiState.data?.fetchedTransferId !== id
  )
    return <Loading />;
  // TODO: 閲覧不可なデータは現場一覧に遷移する機能を有効にする場合、isOwnRetrofits・isOwnDataを追加する
  const { retrofit } = apiState.data;
  // if (!isOwnRetrofits || !isOwnData) redirectTo('/');

  return (
    <Edit {...omit(props, 'siteId')}>
      <CustomForm
        title="admin.pages.retrofitTransferEdit"
        deleteButton={<DeleteButton />}
        cancelButton={<CancelButton basePath={basePath} />}
        redirect={false}
        saveButton={<SaveButton retrofitdata={retrofit} />}
      >
        <BreadcrumbsFiled
          breadcrumbs={[
            'resources.retrofits.name',
            'admin.breadcrumbs.retrofitEdit',
            '',
          ]}
          label="admin.pages.retrofitTransferEdit"
        />
        <FunctionField
          resource={retrofits}
          label="resources.retrofits.fields.machineInfoMachineType"
          record={retrofit}
          render={record => <MachineTypeField record={record} />}
        />
        <TextField
          record={retrofit}
          resource={retrofits}
          source="machineInfoMachineId"
          label="resources.retrofits.fields.machineInfoMachineId"
        />
        <TextField
          record={retrofit}
          resource={retrofits}
          source="machineInfoMachineName"
          label="resources.retrofits.fields.machineInfoMachineName"
        />
        <TextField
          record={retrofit}
          resource={retrofits}
          source="machineInfoCompanyName"
          label="resources.retrofits.fields.machineInfoCompanyName"
        />
        <TextField resource={resource} source="toCorporationName" />
        <TextField
          resource={resource}
          source="toMailAddress"
          label="resources.assignments.fields.email"
        />
        <IconField
          resource={resource}
          source="isApproved"
          className={classes.icon}
        />
      </CustomForm>
    </Edit>
  );
};

RetrofitTransferEdit.displayName = 'RetrofitTransferEdit';
export default RetrofitTransferEdit;
