import { usePermissions } from 'react-admin';
import { Permissions } from '../auth';
import { logger } from '../utils';

const useGetUserRole = () => {
  const { permissions, loading } = usePermissions();
  return {
    isLoading(): boolean {
      return loading;
    },
    isAdmin(): boolean {
      if (!permissions) return false;
      const { userRoles } = permissions as Permissions;
      logger().debug('useGetUserRole:', userRoles);
      return userRoles.includes('GnssSetting'); // UserRoleに GnssSetting が付与されているユーザが Admin
    },
  };
};

export default useGetUserRole;
