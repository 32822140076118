import { AdminApiConverter } from '..';
import { TermsConditionData } from '../../types';

const converter: AdminApiConverter<'termsConditions', 'termsConditions'> = {
  resource: 'termsConditions',
  defaultSort: { field: 'id', order: 'ASC' },
  filterToApi: filter => filter,
  sortFieldToApi: field => field,
  recordsFromApi: records =>
    Promise.resolve(
      records.map((record): TermsConditionData => {
        if (!record.id) throw new Error("Can't get id from API");
        return {
          id: record.id,
          regionName: record.regionName,
        };
      }),
    ),
  recordToApi: record => ({
    regionName: record.regionName,
  }),
};
export default converter;
