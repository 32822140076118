import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, DialogOKCancel } from '../../..';
import { mapStyle } from '../../../../theme';
import { MapDelete } from '../../../../assets/images';
import { GeofenceData } from '../../../../dataProvider';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      ...mapStyle.buttonDelete,
    },
  }),
);

const GeofenceListDeleteButton: React.FC<{
  onClick?: () => void;
  record?: GeofenceData;
  dialogMessage?: string;
}> = ({ onClick, record, dialogMessage }) => {
  const classes = useStyles();
  const content = dialogMessage || '';
  const [open, setOpen] = useState(false);
  return (
    <>
      <DialogOKCancel
        open={open}
        title=""
        message={content}
        nonTranslateMessage={true}
        onClose={() => setOpen(false)}
        onOKClick={() => {
          if (onClick) onClick();
        }}
        isDelete={true}
      />
      <Button
        className={classes.root}
        data-testid="admin.actions.geofenceListDelete"
        disabled={!record}
        onClick={() => setOpen(true)}
      >
        <MapDelete />
      </Button>
    </>
  );
};

GeofenceListDeleteButton.displayName = 'GeofenceListDeleteButton';
export default GeofenceListDeleteButton;
