import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CustomList,
  Datagrid,
  CustomDateField,
  DeleteFromSiteButton,
  FunctionField,
  IconField,
  TextField,
  ToggleAddSiteButton,
  CreateButton,
} from '../../..';
import { useResource } from '../../../../hooks';
import { AddIcon } from '../../../../assets';

const BulkActionButtons: React.FC = props => (
  <DeleteFromSiteButton {...props} />
);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 16,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 137,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 240,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 112,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 130,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 138,
          },
        },
      },
    },
  }),
);

const SiteBucketList: React.FC = props => {
  const location = useLocation();
  const resource = useResource('buckets');
  const classes = useStyles();

  return (
    <FunctionField
      {...props}
      resource="sites"
      addLabel={false}
      render={record =>
        record ? (
          <CustomList
            breadcrumbs={['resources.sites.name']}
            resource={resource}
            controllerResource={`${resource}_${record.id}`}
            basePath={location.pathname}
            location={location}
            filter={{ siteId: record.id }}
            actions={
              <ToggleAddSiteButton
                {...props}
                label="admin.actions.addBucketToSite"
              >
                <CreateButton>
                  <AddIcon />
                </CreateButton>
              </ToggleAddSiteButton>
            }
            bulkActionButtons={<BulkActionButtons />}
            hasCreate
          >
            <Datagrid rowClick="edit" className={classes.root}>
              <IconField resource={resource} source="bucketType" />
              <TextField resource={resource} source="name" />
              <IconField resource={resource} source="uploadedBy" />
              <IconField resource={resource} source="fileStatus" />
              <CustomDateField resource={resource} source="lastUpdated" />
            </Datagrid>
          </CustomList>
        ) : null
      }
    />
  );
};

SiteBucketList.displayName = 'SiteBucketList';
export default SiteBucketList;
