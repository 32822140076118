export const KEY_ROLE_TRANSFER_APPLOVALS = 'transfer_applovals';
export const KEY_ROLE_RETROFIT_CONTROLLER_REGIST_CREATE =
  'retrofit_controller_regist_create';
export const KEY_ROLE_RETROFIT_ALTERNATE_REGIST_CREATE =
  'retrofit_alternate_regist_create';

const hasRoleStore = {
  get: (key: string, email: string) => {
    return localStorage.getItem(`${key}_${email}`) === 'true';
  },
  set: (key: string, email: string, hasRole: boolean) => {
    localStorage.setItem(`${key}_${email}`, hasRole ? 'true' : 'false');
  },
};

export default hasRoleStore;
