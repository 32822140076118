import { RetrofitCorporationLicenseData } from '../../types';

const retrofitCorporationLicenses: RetrofitCorporationLicenseData[] = [
  {
    id: 1,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 11,
    licenseId: '866834af-0353-4dec-9971-7809efa2ba6f',
    licenseType: 'Corporation',
    licenseStatus: 'UnLicense',
    enable: true,
    issuedAt: '2021-02-01T08:00:00.000Z',
    expiresAt: '2021-06-01T12:34:56.789Z',
    comment: 'test',
    licensePermission: 'All',
    isLicenseAttached: false,
  },
  {
    id: 2,
    corporationId: '',
    retrofitLicenseId: 12,
    licenseId: 'f295c84f-a9f5-417d-8f0b-510a2aa5c8c9',
    licenseType: 'Corporation',
    licenseStatus: 'UnLicense',
    enable: true,
    issuedAt: '2020-03-01T08:00:00.000Z',
    expiresAt: '2050-04-01T12:34:56.789Z',
    licensePermission: 'All',
    isLicenseAttached: false,
  },
  {
    id: 3,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 13,
    licenseId: '01c535df-2a00-4186-a6d5-3ebc02345741',
    licenseType: 'User',
    licenseStatus: 'UnLicense',
    enable: true,
    issuedAt: '2021-01-15T08:00:00.000Z',
    expiresAt: '2050-07-15T12:34:56.789Z',
    licensePermission: 'FlexAll',
    retrofitId: 6,
    isLicenseAttached: false,
  },
  {
    id: 4,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 15,
    licenseId: '02d535df-2a00-4186-a6d5-3ebc02345741',
    licenseType: 'User',
    licenseStatus: 'UnLicense',
    enable: true,
    issuedAt: '2050-01-01T18:00:00.000Z',
    expiresAt: '2050-02-01T12:34:56.789Z',
    licensePermission: 'FlexAll',
    isLicenseAttached: false,
  },
  {
    id: 5,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 5,
    licenseId: '02d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'UnLicense',
    enable: true,
    issuedAt: '2021-05-01T08:00:00.000Z',
    expiresAt: '2021-07-01T12:34:56.789Z',
    licensePermission: 'All',
    isLicenseAttached: false,
  },
  {
    id: 6,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 6,
    licenseId: '06d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'UnLicense',
    enable: true,
    issuedAt: '2020-12-12T08:00:00.000Z',
    expiresAt: '2022-12-12T12:34:56.789Z',
    licensePermission: 'All',
    isLicenseAttached: false,
  },
  {
    id: 7,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 7,
    licenseId: '07d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'UnLicense',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2021-07-01T12:34:56.789Z',
    licensePermission: 'All',
    isLicenseAttached: false,
  },
  {
    id: 8,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 8,
    licenseId: '08d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'UnLicense',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2021-07-01T12:34:56.789Z',
    licensePermission: 'All',
    isLicenseAttached: false,
  },
  {
    id: 9,
    corporationId: 'System',
    retrofitLicenseId: 9,
    licenseId: '09d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'Enable',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2025-07-01T12:34:56.789Z',
    licensePermission: 'Unlimited',
    retrofitId: 9,
    isLicenseAttached: false,
  },
  {
    id: 10,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 10,
    licenseId: '10d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'Disable',
    enable: false,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2020-07-01T12:34:56.789Z',
    licensePermission: 'FlexUnlimited',
    retrofitId: 10,
    isLicenseAttached: false,
  },
  {
    id: 11,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 11,
    licenseId: '11d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'Enable',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2023-07-01T12:34:56.789Z',
    licensePermission: 'All',
    isLicenseAttached: false,
  },
  {
    id: 12,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 12,
    licenseId: '12d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'UnLicense',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2021-07-01T12:34:56.789Z',
    licensePermission: 'All',
    isLicenseAttached: false,
  },
  {
    id: 13,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 13,
    licenseId: '13d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'UnLicense',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2021-07-01T12:34:56.789Z',
    licensePermission: 'All',
    isLicenseAttached: false,
  },
  {
    id: 14,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 14,
    licenseId: '14d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'UnLicense',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2021-07-01T12:34:56.789Z',
    licensePermission: 'All',
    isLicenseAttached: false,
  },
  {
    id: 15,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 15,
    licenseId: '15d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'UnLicense',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2021-07-01T12:34:56.789Z',
    licensePermission: 'All',
    isLicenseAttached: false,
  },
  {
    id: 16,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 16,
    licenseId: '16d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'UnLicense',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2021-07-01T12:34:56.789Z',
    licensePermission: 'Temporary',
    isLicenseAttached: false,
  },
  {
    id: 17,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 17,
    licenseId: '17d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'Enable',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2021-07-01T12:34:56.789Z',
    licensePermission: 'All',
    retrofitId: 1,
    isLicenseAttached: true,
  },
  {
    id: 18,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 18,
    licenseId: '18d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'Enable',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2021-07-01T12:34:56.789Z',
    licensePermission: 'Temporary',
    retrofitId: 2,
    isLicenseAttached: true,
  },
  {
    id: 19,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 19,
    licenseId: '19d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'Enable',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2025-07-01T12:34:56.789Z',
    licensePermission: 'MachineGuidance2D',
    retrofitId: 3,
    isLicenseAttached: true,
  },
  {
    id: 20,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 20,
    licenseId: '20d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'Enable',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2021-07-01T12:34:56.789Z',
    licensePermission: 'PayloadMeter',
    retrofitId: 4,
    isLicenseAttached: false,
  },
  {
    id: 21,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 21,
    licenseId: '21d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'Disable',
    enable: false,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2021-07-01T12:34:56.789Z',
    licensePermission: 'Asbuilt',
    retrofitId: 5,
    isLicenseAttached: false,
  },
  {
    id: 22,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 22,
    licenseId: '22d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'Disable',
    enable: false,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2021-07-01T12:34:56.789Z',
    licensePermission: 'All',
    isLicenseAttached: false,
  },
  {
    id: 23,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 23,
    licenseId: '23d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'Enable',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2022-12-11T12:34:56.789Z',
    licensePermission: 'MachineGuidance',
    retrofitId: 8,
    isLicenseAttached: true,
  },
  {
    id: 24,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 24,
    licenseId: '24d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'Enable',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2022-07-11T12:34:56.789Z',
    licensePermission: 'PayloadMeter',
    retrofitId: 8,
    isLicenseAttached: true,
  },
  {
    id: 25,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 25,
    licenseId: '25d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'Enable',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2022-12-11T12:34:56.789Z',
    licensePermission: 'MachineGuidance',
    retrofitId: 7,
    isLicenseAttached: true,
  },
  {
    id: 26,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 26,
    licenseId: '26d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'Enable',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2025-07-11T12:34:56.789Z',
    licensePermission: 'PayloadMeter',
    retrofitId: 7,
    isLicenseAttached: true,
  },
  {
    id: 27,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 27,
    licenseId: '27d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'Enable',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2025-07-01T12:34:56.789Z',
    licensePermission: 'Asbuilt',
    retrofitId: 7,
    isLicenseAttached: true,
  },
  {
    id: 28,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 28,
    licenseId: '28d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'UnLicense',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2022-12-11T12:34:56.789Z',
    licensePermission: 'MachineGuidance',
    isLicenseAttached: false,
  },
  {
    id: 29,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 29,
    licenseId: '29d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'UnLicense',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2025-07-11T12:34:56.789Z',
    licensePermission: 'PayloadMeter',
    isLicenseAttached: false,
  },
  {
    id: 30,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 30,
    licenseId: '30d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'UnLicense',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2025-07-01T12:34:56.789Z',
    licensePermission: 'Asbuilt',
    isLicenseAttached: false,
  },
  {
    id: 31,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    retrofitLicenseId: 30,
    licenseId: '30d535df-2a00-4186-a6d5-3ebc02345742',
    licenseType: 'User',
    licenseStatus: 'UnLicense',
    enable: true,
    issuedAt: '2020-05-01T08:00:00.000Z',
    expiresAt: '2025-07-01T12:34:56.789Z',
    licensePermission: 'Asbuilt',
    retrofitId: 144,
    isLicenseAttached: false,
  },
];

export default retrofitCorporationLicenses;
