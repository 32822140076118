import { AdminApiConverter, ApiFilter } from '..';
import { RetrofitAccuraciesData } from '../../types';

const converter: AdminApiConverter<'retrofitAccuracies', 'retrofitAccuracies'> =
  {
    resource: 'retrofitAccuracies',
    defaultSort: { field: 'checkDateTime', order: 'DESC' },
    filterToApi: filter => {
      const apiFilter: ApiFilter<'retrofitAccuracies'> = {};
      if ('q' in filter) apiFilter.q = filter.q;
      if ('id' in filter) apiFilter.id = filter.id;
      if ('retrofitId' in filter) apiFilter.retrofitId = filter.retrofitId;
      if ('checkDateTime' in filter)
        apiFilter.checkDateTime = filter.checkDateTime;
      if ('surveyPoint' in filter) apiFilter.surveyPoint = filter.surveyPoint;
      if ('positionN' in filter) apiFilter.positionN = filter.positionN;
      if ('positionE' in filter) apiFilter.positionE = filter.positionE;
      if ('positionZ' in filter) apiFilter.positionZ = filter.positionZ;
      if ('machineOffsetN' in filter)
        apiFilter.machineOffsetN = filter.machineOffsetN;
      if ('machineOffsetE' in filter)
        apiFilter.machineOffsetE = filter.machineOffsetE;
      if ('machineOffsetZ' in filter)
        apiFilter.machineOffsetZ = filter.machineOffsetZ;
      if ('referencePointName' in filter)
        apiFilter.referencePointName = filter.referencePointName;
      if ('referencePointN' in filter)
        apiFilter.referencePointN = filter.referencePointN;
      if ('referencePointE' in filter)
        apiFilter.referencePointE = filter.referencePointE;
      if ('referencePointZ' in filter)
        apiFilter.referencePointZ = filter.referencePointZ;
      if ('userCorporationId' in filter)
        apiFilter.userCorporationId = filter.userCorporationId;
      if ('unitLength' in filter) apiFilter.unitLength = filter.unitLength;
      return apiFilter;
    },
    sortFieldToApi: field => {
      if (field === 'userCorporationId')
        throw new Error('Unsupported sort field: userCorporationId');
      return field;
    },
    recordsFromApi: records =>
      Promise.resolve(
        records.map((record): RetrofitAccuraciesData => {
          return {
            id: record.id || '',
            retrofitId: record.retrofitId,
            checkDateTime: record.checkDateTime,
            surveyPoint: record.surveyPoint || '',
            positionN: record.positionN,
            positionE: record.positionE,
            positionZ: record.positionZ,
            machineOffsetN: record.machineOffsetN,
            machineOffsetE: record.machineOffsetE,
            machineOffsetZ: record.machineOffsetZ,
            referencePointName: record.referencePointName || '',
            referencePointN: record.referencePointN,
            referencePointE: record.referencePointE,
            referencePointZ: record.referencePointZ,
            userCorporationId: record.userCorporationId || '',
          };
        }),
      ),
    recordToApi: record => {
      return {
        retrofitId: record.retrofitId,
        checkDateTime: record.checkDateTime,
        surveyPoint: record.surveyPoint,
        positionN: record.positionN,
        positionE: record.positionE,
        positionZ: record.positionZ,
        machineOffsetN: record.machineOffsetN,
        machineOffsetE: record.machineOffsetE,
        machineOffsetZ: record.machineOffsetZ,
        referencePointName: record.referencePointName,
        referencePointN: record.referencePointN,
        referencePointE: record.referencePointE,
        referencePointZ: record.referencePointZ,
      };
    },
  };

export default converter;
