import React from 'react';
import {
  Emergency as EmergencyIcon,
  Important as ImportantIcon,
} from '../../../../assets';

const dispachDialogIcon = (state?: 'compare' | 'error') => {
  switch (state) {
    case 'compare':
      return <ImportantIcon />;
    case 'error':
      return <EmergencyIcon />;
    default:
      return <></>;
  }
};

export default dispachDialogIcon;
