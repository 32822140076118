const setFillTime = (date: Date, hour: number, min: number, sec: number) => {
  date.setHours(hour);
  date.setMinutes(min);
  date.setSeconds(sec);
  date.setMilliseconds(0);
};

const convertFillDate = (
  inputDateString: string | undefined,
  time: {
    hour: number;
    min: number;
    sec: number;
  },
): string | undefined => {
  if (!inputDateString) return undefined;
  const date = new Date(inputDateString);
  setFillTime(date, time.hour, time.min, time.sec);
  return date.toISOString();
};

/**
 * 時分秒を 0 にしたDate型を返す
 *
 * @param dateString 日付文字列
 * @return Date型
 */
const dateStringToLocaleDate = (dateString: string): Date => {
  return new Date(new Date(dateString).toDateString());
};

/**
 * 指定日付に対して、タイムゾーン分オフセットしたDate型を返す
 *
 * @param date 日付 (Date,String)
 * @param isMinus オフセット方向
 * @return Date型
 */
const toTimezoneOffsetDate = ({
  date,
  isMinus,
}: {
  date: string | Date;
  isMinus: boolean;
}): Date => {
  const dir = isMinus ? -1 : 1; // タイムゾーンに対する補正する向き
  const localDate = new Date(date);
  const offset = localDate.getTimezoneOffset() / 60;
  return new Date(localDate.getTime() + offset * 3600000 * dir); // UTCを返す
};

export { dateStringToLocaleDate, convertFillDate, toTimezoneOffsetDate };
