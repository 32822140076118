// for de
// ドイツ語
import germanMessages from 'ra-language-german';
import en from './en';

const { ra } = germanMessages;
const message: typeof en = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: '',
        toggle_hidden: '',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: 'Nicht gefunden',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'OK',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: 'Möchten Sie %{name} löschen?',
    },
  },
  admin: {
    title: {
      normal: 'Smart Construction Pilot',
      split1: 'Smart Construction',
      split2: 'Pilot',
    },
    copyright: 'Copyright © EARTHBRAIN Ltd. All rights reserved.',
    approval: 'Warten auf Genehmigung',
    usermenu: {
      userNotice: 'Benachrichtigung',
      terms: 'NUTZUNGSBEDINGUNGEN',
      manual: 'Handbuch',
      downloads: 'Herunterladen',
    },
    breadcrumbs: {
      details: 'Details',
      retrofitEdit: 'Details',
      taskCreate: 'Aufgabe erstellen',
      taskEdit: 'Aufgabendetails',
      geofenceCreate: 'Erstellen',
      geofenceEdit: 'Bearb.',
      geofenceAlertList: 'Geofence-Alarm',
      geofenceAlertShow: 'Details',
      compactionWorkAreaCreate: 'Verdichtungsbereiche hinzufügen',
      compactionWorkAreaShow: 'Verdichtungsbereiche',
      compactionWorkAreaEdit: 'Verdichtungsbereiche bearbeiten',
      compactionLayerCreate: 'Erstellen von Verdichtungsschichten',
      compactionLayerEdit: 'Verdichtungsschichten bearbeiten',
      supportMode: 'Unterstützungsmodus',
      retrofitManage: 'Controller-Registrierung',
      retrofitManageAdmin: 'Controller-Registrierung (Admin)',
      retrofitNamePlateFiles: 'Controller-Registrierung',
      retrofitAlternateRegistCreate: 'Controller-Proxy-Registrierung',
      retrofitAlternateRegistEdit: 'Details',
      retrofitManageAdminBulkActionCreate: 'Registrierung',
      terms: 'NUTZUNGSBEDINGUNGEN',
      downloads: 'Herunterladen',
    },
    pages: {
      siteList: 'Baustellenliste',
      siteRetrofitCreate: 'Maschine zum Baustellenort hinzufügen',
      siteRetrofitEdit: '',
      siteBucketCreate: 'Eimer zum Baustellenort hinzufügen',
      siteBucketEdit: 'Eimerdetails',
      siteProjectEdit: 'Projekt-Details',
      siteProjectCreate: 'Projektdateiregistrierung',
      siteExtensionarmCreate: 'Verlängerungsarm zum Baustellenort hinzufügen',
      taskCreate: 'Aufgabe erstellen',
      taskEdit: 'Aufgabendetails',
      geofenceAlertList: 'Geofence-Alarm',
      geofenceAlertShow: 'Geofence-Alarmdetails',
      geofenceCreate: 'Geofence erstellen',
      geofenceEdit: 'Geofence bearbeiten',
      compactionWorkAreaCreate: 'Verdichtungsbereiche hinzufügen',
      compactionLayerCreate: 'Erstellen von Verdichtungsschichten',
      compactionLayerEdit: 'Verdichtungsschichten bearbeiten',
      siteFileEdit: 'Details',
      siteFileCreate: 'Datei zum Baustellenort hinzufügen',
      retrofitList: 'Maschinenliste',
      retrofitEdit: '',
      retrofitRentalCreate: 'Zuweisungsregistrierung',
      retrofitTransferCreate: 'Übertragungsregistrierung',
      retrofitSendBack: 'Zurücksenden Registrierung',
      bucketList: 'Eimerliste',
      bucketEdit: 'Eimerdetails',
      bucketCreate: 'Eimerregistrierung',
      bucketRentalCreate: 'Eimerfreigabe',
      extensionarmList: 'Liste der Verlängerungsarm',
      extensionarmEdit: 'Verlängerungsarmdetails',
      extensionarmShare: 'Verlängerungsarmfreigabe',
      retrofitBulkActionList: 'Liste der Massenregistrierung',
      retrofitBulkActionCreate: 'Massenregistrierung',
      retrofitBasicInfoList: 'Controllerliste',
      retrofitBasicInfoCreate: 'Controller-Registrierung',
      retrofitBasicInfoEdit: 'Controller-Update',
      gnssSettingList: 'Ntrip-Einstellungsliste',
      gnssSettingCreate: 'Ntrip-Einstellung erstellen',
      gnssSettingAdminCreate: 'Ntrip-Einstellung erstellen',
      gnssSettingOwnerCreate: 'Ntrip-Einstellung erstellen',
      gnssSettingEdit: 'Ntrip-Einstellung bearbeiten',
      gnssSettingAdminEdit: 'Ntrip-Einstellung bearbeiten',
      gnssSettingOwnerEdit: 'Ntrip-Einstellung bearbeiten',
      approvalList: 'Maschinenliste',
      retrofitRentalEdit: 'Zuweisungsdetails',
      retrofitTransferEdit: 'Übertragungsdetails',
      asbuiltsCreate: 'Bauergebnis erwerben',
      asbuiltsShow: 'Asbuild-Verlauf',
      retrofitConfigList: 'Konfigurationsinfo',
      retrofitKitInfoList: 'Controller-Info',
      retrofitAccuraciesList: 'Genauigkeitsprüfungsergebnis',
      retrofitAccuraciesShow: 'Details',
      retrofitCalibaList: 'Kalib-Info',
      retrofitCalibInfo: 'Details zur Maschinenkalibrierung',
      retrofitBasicSettingList: 'Grundlegende Einstellungen',
      retrofitErrorList: 'Fehlerinformation',
      retrofitErrorShow: 'Details',
      retrofitTopographicSurveyList: 'Topo.-Verm.',
      retrofitTopographicSurveyShow: 'Topografische Vermessungsdetails',
      retrofitShareFileList: 'Anwendungsprotokoll',
      retrofitCorporationLicenseList: 'Lizenzliste',
      retrofitCorporationLicenseEdit: 'Lizenzdetails',
      CompactionLayerList: 'Verdichtungsschichten',
      userNoticeList: 'Benachrichtigungsliste',
      userNoticeCreate: 'Benachrichtigung hinzufügen',
      userNoticeEdit: 'Benachrichtigung aktualisieren',
      userNoticeDrawer: 'Benachrichtigung',
      retrofitAlternateRegists: 'Liste der Controller-Proxy-Registrierung',
      firmwareList: 'Firmwareliste',
      firmwareShow: 'Firmwaredetails',
      firmwareCreate: 'Firmware hinzufügen',
      retrofitManage: 'Text',
      retrofitNamePlateFiles: 'Foto',
      retrofitManageAdminBulkAction: 'Massenregistrierung',
      retrofitManageAdminBulkActionCreate: 'Massenregistrierung',
      retrofitAlternateRegistCreate: 'Controller-Proxy-Registrierung',
      retrofitAlternateRegistEdit: 'Details zur Controller-Proxy-Registrierung',
      retrofitAlternateRegistCreateText: 'Text',
      retrofitAlternateRegistCreatePhoto: 'Foto',
      ntripList: 'Ntrip-Info',
      ntripEdit: 'Ntrip-Verwaltung',
    },
    label: {
      login: 'Einloggen',
      selected: 'Ausgewählte (%{length} Fälle)',
      listNoResults: 'Daten nicht gefunden',
      maintenance: {
        title: 'Derzeit in Wartung.',
        message: `Wir haben vorübergehend die Dienste wegen Wartungsarbeiten eingestellt.
      Wir entschuldigen uns für die Unannehmlichkeiten und bitten um etwas Geduld.`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, ln3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder:
          'Legen Sie die Datei(en) (%{fileTypes}) ab oder wählen Sie sie zum Hochladen aus.',
        maxFileNum:
          'Die maximale Anzahl an Controllern, die gleichzeitig registriert werden können, beträgt fünf.',
      },
      nosetting: 'Keine Einstellung',
      siteRetrofitList: {
        remoteSupport: 'Fernunterstützung',
      },
      retrofitEdit: {
        breadcrumbs: 'Details',
        belongsDetails: '',
        ntripSettingInfo: 'Ntrip-Einstellungsinfo',
        machineryDetails: 'Maschinen',
        retrofitDetails: 'Controller',
        basicInfo: 'Basisinfo',
        controller: 'Controller',
        reciverMain: 'GNSS-Hauptempfänger',
        reciverSub: 'GNSS-Hilfsempfänger',
        retrofitstatus: 'Controller-Status',
        license: 'Lizenz',
        licenseId: 'Lizenz-ID',
        expiredDate: 'Ablaufdatum',
      },
      retrofitRental: {
        rentalDetails: 'Zuweisungsdetails',
        rentalInfo: 'Zuweisungsdetails',
        lending: 'Zuweisung',
        return: 'Rückkehr',
        lendingDestination: 'Zugeteiltes Unternehmen',
        lendingCompany: 'Unternehmensname',
        lendingEmail: 'E-Mail',
        companyName: 'Unternehmensname',
      },
      retorfitTransfer: {
        tab: 'Übertragung',
        transferInfo: 'Übertragungsdetails',
        transferButton: 'Übertragung',
        transferEditButton: 'Transfer bearbeiten',
      },
      siteConfigureEdit: {
        onButton: 'ON (EIN)',
        offButton: 'OFF (AUS)',
      },
      approvals: {
        rentalTab: 'Zuweisung',
        transferTab: 'Übertragung',
      },
      buckets: {
        bucketTab: 'Löffel',
        extensionarmTab: 'Verlängerungsarm',
        extensionarmFile: 'Verlängerungsarmdatei',
        shareTo: 'Teilen mit',
      },
      bucketRental: {
        corporation: 'Teilen mit',
        bucketType: 'Typ',
        bk3File: 'BK3-Datei',
      },
      retrofitBasic: {
        basicInfoTab: 'Basisinfo',
        bulkActionTab: 'Massenregistrierung',
        ntripTab: 'Ntrip-Verwaltung',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'Zurücksenden',
        initialize: 'Initialisierung',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: 'Massenaktualisierung',
        retrofitDistributor: 'Händler',
        retrofitRegion: 'Bereich',
        corporationId: 'Unternehmens-ID',
        corporationName: 'Unternehmensname',
      },
      retrofitBasicInfoEdit: {
        corporationId: 'Unternehmen-ID in Verwendung',
        corporationName: 'Unternehmen in Verwendung',
      },
      gnssSettingsEdit: {
        ownerCorporationName: 'Unternehmensname',
      },
      terms: {
        title: 'NUTZUNGSBEDINGUNGEN',
        message:
          'Bitte klicken Sie auf den unten stehenden Link, um die Nutzungsbedingungen zu überprüfen.',
        confirmMessage1:
          'Um Smart Construction Pilot verwenden zu können, müssen Sie die folgenden Dokumente akzeptieren.',
        confirmMessage2:
          'Wenn Sie nicht zustimmen, können Sie Smart Construction Pilot nicht verwenden.',
        select: 'Bitte wählen Sie Ihre Region aus.',
      },
      downloads: {
        title: 'Projektdateikonverter',
        message:
          'Dies ist eine eigenständige Anwendung, die eine Projektdatei aus Konstruktionsdateien erstellt.',
        button: 'Herunterladen',
      },
      retrofitBasicInfoCreate: {
        uuid: 'Automatische Nummerierung',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'Herunterladen',
        resultFileDownload: 'Herunterladen',
      },
      tasks: {
        coordinate: 'Koordinaten (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'Blockgröße (%{unit})',
      },
      compactionLayers: {
        thickness: 'Dicke (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: 'Details',
        rentalDetail: 'Zuweisungsdetails',
      },
      pendingTransfers: {
        breadcrumbs: 'Details',
        transferDetail: 'Übertragungsdetails',
      },
      pendingAlternateRegists: {
        breadcrumbs: 'Details',
        alternateRegistDetail: 'Details zur Controller-Proxy-Registrierung',
      },
      supportMode: {
        retrofit: 'Maschinen',
        optional: 'Optional',
      },
      retrofitList: {
        assignment: 'Zuweisung',
        license: 'Lizenz',
      },
      userNotices: {
        languageDetails: 'Benachrichtigungsinhalt',
        language: 'Sprache',
        languageEn: 'English',
        languageJa: 'Japanisch',
        languageFr: 'Französisch',
        languageDe: 'Deutsch',
        languageEs: 'Spanisch',
        Information: 'Benachrichtigung',
        Maintenance: 'Wartung',
        emergency: 'Notfall',
        important: 'Wichtig',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'Controller-Registrierung',
        corporationName: 'Unternehmensname',
        basicInfoSerialNumber: 'Seriennummer',
        confirmInput: 'Seriennummer erneut eingeben',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'Seriennummer',
        status: 'Status',
        imageFile: 'Bild',
        message1:
          'Bitte machen Sie ein Bild des Controller-Seriennummerns oder des QR-Codes, das klar und unverwackelt ist.',
        message2: 'Bilder müssen kleiner als %{mbyte} MB sein.',
        errormessage:
          'Die maximale Anzahl an Controllern, die gleichzeitig registriert werden können, beträgt fünf.',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: 'Gültig',
          disable: 'Ungültig',
          unLicense: 'Nicht zugewiesen',
        },
        machine: 'Maschinen',
        before: 'Vor',
        after: 'Nach',
      },
      geofences: {
        project: 'Projekt',
        projectSourceLayer: 'Ebene',
        rgb: 'Farbe(RGB)',
        select: 'Auswahl Geofence',
        coordinates: 'Koordinaten',
        coordinatesLabel: 'Koordinaten (N,E)',
        unitLength: 'Einheit:%{unit}',
      },
      geofenceDialog: {
        coordinate: 'Koordinaten (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: 'Radius',
      },
      retrofitAlternateRegists: {
        menuTitle: 'Controller-Proxy-Registrierung',
        corporationName: 'Unternehmensname',
        email: 'E-Mail',
        basicInfoSerialNumber: 'Seriennummer',
        confirmInput: 'Seriennummer erneut eingeben',
      },
      ntripList: {
        unusedTotal: 'Unbenutzte Gesamtmenge',
      },
      calibrationDetail: {
        breadcrumbs: 'Details',
        machineInfo: 'Machine Info',
        machineGeometryInfo: 'Masch.-Geom-Info',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'IMU-Mont.-Info',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'Haupt-GNSS-Mont.-Info',
        bodyCenterToGnssSub: 'Hilfs-GNSS-Mont.-Info',
        extendedModel: 'Erweitertes Modell',
        measuredValues: 'Kalib-Info',
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'Datenkonvertierungsfehler',
        system_localization_quantity_error: `Es können nicht mehrere Dateien mit Lokalisierungsinformationen registriert werden.
      Bitte registrieren Sie nur eine Datei, die Lokalisierungsinformationen enthält.`,
        system_detect_0byte_file_error: `Die Registrierung einer Datei auf dem Server ist aufgrund eines Kommunikationsfehlers fehlgeschlagen.
      Bitte registrieren Sie die Datei erneut.`,
        system_batch_error: 'Systemfehler (P001)',
        system_parameter_error: 'Systemfehler (P002)',
        system_parameterfile_notfound: 'Systemfehler (P003)',
        system_exception: 'Systemfehler (P004)',
        system_logfile_error: 'Systemfehler (P005)',
        system_parameterfile_parameter_error: 'Systemfehler (P006)',
        desing_localization_error: `Es liegen keine Lokalisierungsinformationen vor.
      Bitte registrieren Sie eine der folgenden Dateien:

      ・GC3-Datei
      ・TP3-Datei mit Kontrollpunkten
      ・CAL-Datei`,
        desing_no_support_dxf_error:
          'Die DXF-Datei(en) konnten nicht gelesen werden, da ihre Version älter als AutoCAD2000 ist.',
        desing_tile_error: 'Projektdatei konnte nicht erstellt werden.',
        desing_outline_error:
          'Die Erstellung der äußeren Begrenzungslinie ist fehlgeschlagen.',
        desing_area_length_error:
          'Der Vorgang wurde abgebrochen, weil der Projektbereich zu groß ist. Bitte überprüfen Sie, ob die Konstruktionsdaten und der Maßstab der CAD-Daten korrekt sind.',
        desing_layer_name_duplication_error: `Projektdateien mit doppelten Ebenennamen können nicht registriert werden.
      Bitte ändern Sie den Entwurfs-Ebenennamen oder registrieren Sie ihn so, dass nur eine Datei mit demselben Entwurfs-Ebenennamen vorhanden ist.`,
        targetFiles: '[Dateiname]',
        targetLayers: '[Ebene]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: 'Kein(e)',
        system_batch_error: 'Systemfehler (T001)',
        system_parameter_error: 'Systemfehler (T002)',
        system_parameterfile_notfound: 'Systemfehler (T003)',
        system_exception: 'Systemfehler (T004)',
        system_logfile_error: 'Systemfehler (T005)',
        system_basefile_notfound: 'Systemfehler (T006)',
        system_prjfile_multi: 'Systemfehler (T007)',
        system_basefile_read_error: 'Systemfehler (T008)',
        system_target_layer_notfound: 'Systemfehler (T009)',
        system_target_layer_duplication:
          'Der gleiche Ebenenname existiert bereits.',
        system_taskarea_error: 'Ungültiger Aufgabenbereich',
        system_taskarea_design_notfound:
          'Es gibt keine Daten für die Projektdatei innerhalb des Aufgabenbereichs (TIN, Liniennetz, Punkte).',
        system_tile_error: 'Projektdatei konnte nicht erstellt werden.',
        system_outline_error:
          'Die Erstellung der äußeren Begrenzungslinie ist fehlgeschlagen.',
        system_parameterfile_error: 'Systemfehler',
      },
      retrofitError: {
        1000: 'UDP-Daten vom Controller nicht erkannt',
        2000: 'Karosserie-IMU nicht erkannt',
        2001: 'Karosserie-IMU Hardware-Fehler',
        2002: 'Karosserie-IMU Algorithmus-Fehler',
        2003: 'Karosserie-IMU Datenfehler',
        2004: 'Karosserie-IMU Initialisierungsfehler',
        2100: 'Ausleger-IMU nicht erkannt',
        2101: 'Ausleger-IMU Hardware-Fehler',
        2102: 'Ausleger-IMU Algorithmus-Fehler',
        2103: 'Ausleger-IMU Datenfehler',
        2104: 'Ausleger-IMU Initialisierungsfehler',
        2200: 'Löffelstiel-IMU nicht erkannt',
        2201: 'Löffelstiel-IMU Hardware-Fehler',
        2202: 'Löffelstiel-IMU Algorithmus-Fehler',
        2203: 'Löffelstiel-IMU Datenfehler',
        2204: 'Löffelstiel-IMU Initialisierungsfehler',
        2300: 'Löffel-IMU nicht erkannt',
        2301: 'Löffel-IMU Hardware-Fehler',
        2302: 'Löffel-IMU Algorithmus-Fehler',
        2303: 'Löffel-IMU Datenfehler',
        2304: 'Löffel-IMU Initialisierungsfehler',
        2400: 'Kipplöffel-IMU nicht erkannt',
        2401: 'Kipplöffel-IMU Hardware-Fehler',
        2402: 'Kipplöffel-IMU Algorithmus-Fehler',
        2403: 'Kipplöffel-IMU Datenfehler',
        2404: 'Kipplöffel-IMU Initialisierungsfehler',
        2500: 'IMU für 2. Ausleger nicht erkannt',
        2501: 'IMU für 2. Ausleger Hardware-Fehler',
        2502: 'IMU für 2. Ausleger Algorithmus-Fehler',
        2503: 'IMU für 2. Ausleger Daten-Fehler',
        2504: 'IMU für 2. Ausleger Initialisierungs-Fehler',
        2600: 'Drehwinkelsensor unerkannt',
        2601: 'Systemfehler des Drehwinkelsensor',
        2602: 'Hardwarefehler des Drehwinkelsensor',
        2700: 'Versatzsensor unerkannt',
        2701: 'Systemfehler des Versatzsensor',
        2702: 'Hardwarefehler des Versatzsensor',
        3000: 'Kopfseitiger Drucksensor nicht erkannt',
        3100: 'Bodenseitiger Drucksensor nicht erkannt',
        4000: 'GNSS-Antenne nicht erkannt',
        5000: 'Korrekturinformation nicht erkannt',
        5001: 'Funkempfängersignal nicht erkannt',
      },
    },
    dialog: {
      menu: {
        confirmManualLink:
          'Sie werden zu einer anderen Website weitergeleitet. Ist das in Ordnung?',
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'Löffel',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message: 'Unternehmen-ID und Unternehmensname sind erforderlich.',
        },
        save: {
          message1: 'Unternehmen-ID/-Name kann nicht geändert werden, da es',
          message2: 'Maschine(n) an ein anderes Unternehmen zugewiesen.',
          message3: '',
          message4: 'Bitte überprüfen Sie die folgenden Seriennummern.',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'Controller',
        },
        undeletable: {
          message:
            'Der Controller wird verwendet und kann nicht gelöscht werden.',
        },
      },
      Ntrip: {
        save: {
          stop: 'Möchten Sie diese Ntrip-Einstellung beenden?',
          delete: 'Möchten Sie die Ntrip-Einstellung löschen?',
        },
      },
      siteRetrofitList: {
        title: 'Zusatzinformationen',
        message: 'Die Bauzeit ist abgelaufen.',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: 'Gemeinsame Einstellung',
          siteSetting: 'Baustelleneinstellungen',
        },
        mapBackgroundColor: 'Kartenhintergrundfarbe',
        message:
          'Die Funktion zur Erkennung von Geofencing-Kontakten und zur Benachrichtigung über einen Alarm funktioniert je nach Umgebung und Bedingungen, unter denen sie verwendet wird, möglicherweise nicht richtig. Überschätzen Sie die Funktion nicht und stellen Sie sicher, dass Sie die Funktion und die Bedingungen für die Nutzung verstehen.',
      },
      geofenceList: {
        confirmDelete: {
          message: 'Möchten Sie dieses Geofence löschen?',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1:
            'Die Maschine ist mit einer Baustelle verbunden und kann nicht zugewiesen werden. Bitte trennen Sie das Gerät von der Baustelle.',
          message2: 'Bitte trennen Sie die Maschine von der Baustelle.',
        },
        sameOwner: {
          message:
            'Die Maschine kann nicht zugewiesen werden, da Ihr Unternehmen der Eigentümer ist.',
        },
        unableTransfer: {
          message:
            'Die Maschine wartet auf die Genehmigung zur Übertragung und kann nicht für die Zuweisung registriert werden.',
        },
        unableAlternateRegist: {
          message:
            'Die Maschine wartet auf die Genehmigung der Registrierung durch den Controller Proxy und kann nicht für die Zuweisung registriert werden.',
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          'Die Maschine ist mit einer Baustelle verknüpft und kann nicht übertragen werden. Bitte trennen Sie die Verbindung der Maschine mit der Baustelle.',
        messageRentaled:
          'Die Maschine ist zugewiesen und kann nicht übertragen werden.',
        messageLicense:
          'Die Maschine hat eine Lizenz und kann nicht übertragen werden.',
        pendingApprovals: {
          message1:
            'Der Überweisungsantrag ist eingegangen. Bitte warten Sie auf die Genehmigung.',
          message2:
            'Wenn Sie Fragen haben, kontaktieren Sie uns bitte über die folgende URL.',
        },
      },
      retrofitSendBacks: {
        submit:
          'Möchten Sie den Controller zurücksenden und die Einstellungen auf den Zielcontroller übertragen?',
        deleteNtrip:
          'Möchten Sie den zurückgegebenen Controller initialisieren?',
        unableRegion:
          'Dieser Controller wurde ins Ausland versandt. Wenn dieser Controller der Richtige ist, setzen Sie bitte die "Region" auf null zurück.',
        unableCorporation: {
          message1: 'Der Controller kann nicht zurückgesendet werden.',
          message2:
            'Wenn Sie glauben, dass dies ein Fehler ist, wenden Sie sich bitte an Ihren Händler.',
        },
        retrofitRentaled:
          'Der Controller ist zugewiesen und kann nicht zurückgesendet werden.',
        tiedToSite:
          'Die Maschine ist mit einer Baustelle verknüpft und kann nicht zurückgeschickt werden.',
      },
      retrofitAddToSiteAction: {
        title: 'Zusatzinformationen',
        message:
          'Es gibt eine Maschine, die auf die Genehmigung wartet und daher nicht mit der Baustelle verbunden werden kann.',
      },
      retrofitAddToSiteActionTransfer: {
        title: 'Zusatzinformationen',
        message:
          'Die Maschine wird übertragen und kann nicht mit der Baustelle verbunden werden.',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message:
            'Die ausgewählte Ntrip-Einstellung wird gerade an eine andere Maschine gesendet.',
        },
        unableTransferInfo: {
          message:
            'Um zu übertragen, brechen Sie bitte die folgenden Einstellungen ab.',
          reasonSiteId: 'Baustelle',
          reasonRentaled: 'Zuweisung',
          reasonLicense: 'Lizenz',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            'Das zugewiesene Unternehmen weist die Maschine derzeit einem anderen Unternehmen zu. Der Starttermin der Zuweisung an ein anderes Unternehmen ist der %{date}.',
          message2: 'Bitte wählen Sie ein Enddatum nach %{date} aus.',
        },
        confirmTieUpJobsite: {
          message:
            'Die Maschine ist mit einer Baustelle des zugewiesenen Unternehmens verknüpft. Möchten Sie diesen Auftrag beenden?',
        },
        confirmDoubleRental: {
          message:
            '"Das zugewiesene Unternehmen weist die Maschine derzeit einem anderen Unternehmen zu. Das zugewiesene Unternehmen weist die Maschine derzeit einem anderen Unternehmen zu. Das Festlegen eines Enddatums beendet auch die Zuweisung an ein anderes Unternehmen. Möchten Sie das Enddatum festlegen?"',
        },
        unapprovedSave: {
          message:
            'Diese Zuweisung wurde nicht genehmigt und kann nicht gespeichert werden.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Möchten Sie diese Aufgabe löschen?',
        },
        approvedDelete: {
          title: '',
          message:
            'Diese Aufgabe wurde genehmigt und kann nicht gelöscht werden.',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message:
            'Die Maschine ist derzeit zugewiesen und kann nicht übertragen werden.',
        },
        approvedSave: {
          message:
            'Die Maschine hat eine Lizenz und kann nicht übertragen werden.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Möchten Sie diesen Transfer löschen?',
        },
        approvedDelete: {
          title: '',
          message:
            'Diese Übertragung wurde genehmigt und kann nicht gelöscht werden.',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'Verlängerungsarm',
        },
      },
      projectFileUpload: {
        message:
          'Mehrere Kontrollpunktdateien (.gc3/.cal) können nicht registriert werden.',
      },
      projectEdit: {
        confirm: {
          message:
            'Beim Aktualisieren des Projekts werden die vorhandenen Aufgaben gelöscht. Möchten Sie aktualisieren?',
        },
      },
      projectConversionStatus: {
        title: 'Konvertierungsstatus',
        button: 'OK',
      },
      taskConversionStatus: {
        title: 'Konvertierungsstatus',
        button: 'OK',
      },
      taskList: {
        undeletable: {
          message:
            'Die ausgewählte Aufgabe kann nicht gelöscht werden, da sie in Gebrauch ist.',
        },
        unableToCreate: {
          message: 'Es wurden keine Konstruktionsdaten im Projekt registriert.',
        },
        unableToDelete: {
          message1:
            'Die Aufgabe kann nicht gelöscht werden, weil der Status des Projekts und der Status der Aufgabe wie folgt sind.',
          message2: 'Projekt: Konvertieren',
          message3: 'Aufgabe: Fehler',
        },
        unableToUpdate: {
          message1:
            'Die Aufgabe kann nicht bearbeitet werden, weil der Status des Projekts und der Status der Aufgabe nicht wie folgt sind.',
          message2: 'Projekt: [Konvertiert]',
          message3:
            'Aufgabe: [Übertragen][Übertragen][Übertragung fehlgeschlagen]',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'Aufgabe',
        },
        unableToDelete: {
          message:
            'Die ausgewählte Aufgabe kann nicht gelöscht werden, da sie in Gebrauch ist.',
        },
      },
      taskMap: {
        crossingMessage: 'Der Aufgabenbereich wird überschnitten.',
        sameValueMessage: 'Es wurden die gleichen Koordinaten eingegeben.',
        areaZeroMessage: 'Es gibt keinen Bereich für den Aufgabenbereich.',
        noPolygonMessage:
          'Die Aufgabe kann nicht registriert werden, weil sie noch nicht registriert ist.',
      },
      compactionMaterials: {
        add: {
          title: 'Ein Material hinzufügen',
        },
        update: {
          title: 'Material aktualisieren',
        },
      },
      weathers: {
        add: {
          title: 'Wetter hinzufügen',
        },
        update: {
          title: 'Wetter aktualisieren',
        },
      },
      operators: {
        add: {
          title: 'Einen Betreiber hinzufügen',
        },
        update: {
          title: 'Operator aktualisieren',
        },
      },
      compactionWorkAreas: {
        update: {
          message: 'Verdichtungsbereiche bearbeiten',
        },
        undeletable: {
          message:
            'Die Verdichtungsschicht im Baubereich wird verwendet und kann nicht gelöscht werden.',
        },
      },
      compactionLayers: {
        undeletable: {
          message:
            'Die Verdichtungsschicht wird verwendet und kann nicht gelöscht werden.',
        },
        deleteConfirm: {
          messageTargetItem: 'Verdichtungsschicht',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message:
            'Eine weitere Ntrip-Einstellung wird an die ausgewählte Maschine gesendet.',
        },
        confirmDelete: {
          message: 'Möchten Sie die Ntrip-Einstellung löschen?',
        },
        confirmDeleteWithMachine: {
          message:
            'Die Maschine ist derzeit verknüpft. Möchten Sie die Ntrip-Einstellung löschen?',
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'Benachrichtigung',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: 'Legen Sie die Lizenz(en) auf folgender Maschine fest.',
        },
        changeDisable: {
          message:
            'Bitte ändern Sie die Lizenz-ID der folgenden Baumaschinen in gültig.',
        },
        changeTemporary: {
          message:
            'Legen Sie die Lizenz(en) auf folgender Maschine fest. Die derzeitige temporäre Lizenz wird ungültig.',
        },
        errorAdd: {
          message:
            'Die Lizenz der Maschine kann nicht geändert werden, da bereits die Lizenz "Alle" eingestellt ist.',
        },
        errorSameType: {
          message:
            'Die Lizenz des Geräts kann nicht geändert werden, da derselbe Lizenztyp bereits eingestellt wurde.',
        },
        errorUnlimited: {
          message:
            'Die Maschine hat bereits eine unbegrenzte Lizenz, andere Lizenzen können nicht eingestellt werden.',
        },
        addAll: {
          message:
            'Für die folgende Maschine wurde(n) bereits eine Lizenz festgelegt. Möchten Sie alle Lizenzen einstellen?',
        },
        compare: {
          message: 'Ändern Sie die Lizenz(en) für die folgende Maschine.',
        },
        warning: {
          message:
            'Das %{retrofit} hat die Lizenznummer %{id}. Möchten Sie die Lizenz trennen?',
        },
        unableTransfer: {
          message:
            'Die Maschine wird transferiert, so dass die Lizenz nicht verknüpft werden kann.',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial: 'Es gibt Daten, die nicht analysiert werden konnten.',
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1:
            'Die Registrierungsanforderung wurde empfangen. Bitte warten Sie, bis sie genehmigt ist.',
          message2:
            'Wenn Sie Fragen haben, kontaktieren Sie uns bitte über die folgende URL.',
        },
        corporationIdCheck: {
          message1:
            'Der Controller wurde bereits registriert. Bitte überprüfen Sie durch Suche in den Maschinen.',
          message2:
            'Wenn Sie den Zielcontroller nicht in der Liste finden können, kontaktieren Sie uns bitte über die folgende URL.',
        },
        earthbrainIdCheck: {
          message1: 'Wir entschuldigen uns für die Unannehmlichkeiten',
          message2:
            'aber bitte kontaktieren Sie uns von der unten stehenden URL aus mit folgenden Informationen.',
          message3: '-Controller Seriennummer',
          message4: '-Name der zu registrierenden Maschine (optional)',
          message5: '-Zu registrierende Unternehmens-ID',
        },
        invalidSerialNumber: {
          message1:
            'Der Controller wird bereits von einem anderen Unternehmen verwendet.',
          message2: 'Wenn Sie es aus anderen Gründen ändern möchten,',
          message3:
            'Bitte kontaktieren Sie uns von der unten stehenden URL aus mit den folgenden Informationen.',
          message4: '-Controller Seriennummer',
          message5: '-Name der zu registrierenden Maschine (optional)',
          message6: '-Zu registrierende Unternehmens-ID',
          message7: '-Grund für die Änderung',
        },
        lendingRetrofits: {
          message1: 'Der Controller ist zugewiesen.',
          message2:
            'Bitte bewerben Sie sich erneut, nachdem die Zuordnung bei der zuweisenden Firma endet.',
          message3:
            'Wenn Sie Fragen haben, kontaktieren Sie uns bitte über die folgende URL.',
        },
        siteAttached: {
          message1: 'Der Controller ist mit einer Baustelle verknüpft.',
          message2:
            'Bitte bewerben Sie sich erneut, nachdem Sie die Maschine von der Baustelle getrennt haben.',
          message3:
            'Wenn Sie Fragen haben, kontaktieren Sie uns bitte über die folgende URL.',
        },
        serialNumberExistError: {
          message1:
            'Dies ist die Seriennummer eines Controllers, der nicht existiert. Bitte stellen Sie sicher, dass die eingegebene Seriennummer der Steuerung korrekt ist.',
          message2:
            'Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte über die folgende URL.',
        },
        ntripShortage: {
          message1:
            'Wir konnten Ihre Ntrip-Benutzer-ID und Ihr Passwort nicht ausstellen.',
          message2: 'Bitte kontaktieren Sie uns unter der unten stehenden URL.',
        },
        serialnumberCheck: {
          message:
            'Bitte überprüfen Sie die Produkte mit den folgenden Seriennummern',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: 'retrofitAlternateRegists',
        },
        saveValidation: {
          message: 'E-Mail ist erforderlich',
        },
        pendingApprovals: {
          message1:
            'Die Registrierungsanfrage wurde empfangen. Bitte warten Sie, bis sie genehmigt wird.',
          message2:
            'Wenn Sie Fragen haben, kontaktieren Sie uns bitte über die folgende URL.',
        },
        corporationIdCheck: {
          message1:
            'Der Controller wurde bereits registriert. Bitte überprüfen Sie durch Suche in den Maschinen.',
          message2:
            'Wenn Sie den Zielcontroller nicht in der Liste finden können, kontaktieren Sie uns bitte über die folgende URL.',
        },
        earthbrainIdCheck: {
          message1: 'Wir entschuldigen uns für die Unannehmlichkeiten',
          message2:
            'aber bitte kontaktieren Sie uns von der unten stehenden URL aus mit folgenden Informationen.',
          message3: '-Controller Seriennummer',
          message4: '-Name der zu registrierenden Maschine (optional)',
          message5: '-Zu registrierende Unternehmens-ID',
        },
        invalidSerialNumber: {
          message1:
            'Der Controller wird bereits von einem anderen Unternehmen verwendet.',
          message2: 'Wenn Sie es aus anderen Gründen ändern möchten,',
          message3:
            'Bitte kontaktieren Sie uns von der unten stehenden URL aus mit den folgenden Informationen.',
          message4: '-Controller Seriennummer',
          message5: '-Name der zu registrierenden Maschine (optional)',
          message6: '-Zu registrierende Unternehmens-ID',
          message7: '-Grund für die Änderung',
        },
        lendingRetrofits: {
          message1: 'Der Controller ist zugewiesen.',
          message2:
            'Bitte bewerben Sie sich erneut, nachdem die Zuordnung bei der zuweisenden Firma endet.',
          message3:
            'Wenn Sie Fragen haben, kontaktieren Sie uns bitte über die folgende URL.',
        },
        siteAttached: {
          message1: 'Der Controller ist mit einer Baustelle verknüpft.',
          message2:
            'Bitte bewerben Sie sich erneut, nachdem Sie die Maschine von der Baustelle getrennt haben.',
          message3:
            'Wenn Sie Fragen haben, kontaktieren Sie uns bitte über die folgende URL.',
        },
        serialNumberExistError: {
          message1:
            'Dies ist die Seriennummer eines Controllers, der nicht existiert. Bitte stellen Sie sicher, dass die eingegebene Seriennummer der Steuerung korrekt ist.',
          message2:
            'Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte über die folgende URL.',
        },
        ntripShortage: {
          message1:
            'Wir konnten Ihre Ntrip-Benutzer-ID und Ihr Passwort nicht ausstellen.',
          message2: 'Bitte kontaktieren Sie uns unter der unten stehenden URL.',
        },
        serialnumberCheck: {
          message:
            'Bitte überprüfen Sie die Produkte mit den folgenden Seriennummern',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1: 'Diese Steuerung ist bereits im Besitz Ihres Unternehmens.',
          message2: 'Bitte kontaktieren Sie das Antragstellerunternehmen.',
        },
        userCorporation: {
          message1:
            'Sie können diese Anfrage nicht genehmigen, da Sie diesen Controller bereits verleihen.',
          message2: 'Bitte kontaktieren Sie das Antragstellerunternehmen.',
        },
      },
      licensePurchaseNotice: {
        button: 'OK',
        message1: `Vor Ort ist eine Maschine ohne Lizenz im Einsatz.
        Für den Einsatz vor Ort ist eine Lizenz erforderlich.
        Wenn Sie keine Lizenz erworben haben, erwerben Sie diese bitte auf dem Smart Construction Marketplace.`,
        message2: `
        Wenn Sie die Maschine von einem anderen Unternehmen mieten, fordern Sie bitte eine Lizenz für die Maschine bei der Mietquelle an.

        Wenn Sie Fragen haben, schauen Sie bitte auf der folgenden Support-Seite nach.`,
      },
      siteBuriedStructureEdit: {
        title: 'Buried Structure',
      },
    },
    tootip: {
      retrofitShareFileList: 'Anwendungsprotokoll',
      retrofitConfigList: 'Konfigurationsinfo',
      retrofitKitInfoList: 'Controller-Info',
      retrofitAccuraciesList: 'Genauigkeitsprüfungsergebnis',
      retrofitCalibaList: 'Kalib-Info',
      retrofitBasicSettingList: 'Grundeinstellungen',
      retrofitErrorList: 'Fehlerdetails',
      retrofitTopographicSurveyList: 'Topo.-Vermessung',
    },
    actions: {
      ok: 'OK',
      add: 'Hinzufügen',
      create: 'Registrierung',
      yes: 'Ja',
      no: 'Nein',
      confirmation: 'Bestätigen',
      save: 'SPEICHERN',
      cancel: 'Abbrechen',
      required: 'Erforderlich',
      addMachineToSite: 'Maschine zum Baustellenort hinzufügen',
      addBucketToSite: 'Hinzufügen',
      deleteFromSite: 'Trennen von Jobsite',
      removeLicense: 'Maschine trennen',
      lendMachine: 'Zuweisung',
      transferMachine: 'Übertragung',
      remoteSupport: 'Fernunterstützung',
      lendBucket: 'Teilen',
      approval: 'Genehmigung',
      connect: 'Verbinden',
      creationInstructions: 'Bauergebnis erwerben',
      csvDownload: 'Herunterladen',
      retrofitBundleCreate: 'Massenregistrierung',
      termsAccept: 'Zustimmen',
      termsDeny: 'Nicht einverstanden',
      bulkRegistration: 'Massenaktualisierung',
      bulkRegistrationOK: 'OK',
      compactionItems: 'Verdichtungsartikel',
      addLanguage: 'Sprache hinzufügen',
    },
    message: {
      error: 'Ein Fehler ist aufgetreten',
      confirm: 'Ist es in Ordnung?',
      fileUpload: 'Dateien hier ablegen',
      success: 'Registrierung abgeschlossen',
      approved: 'Die Genehmigung wurde abgeschlossen',
      deleteConfirmTitle: '',
      deleteConfirmContent: 'Möchten Sie %{title} löschen?',
      bulkDeleteConfirmContent: 'Möchten Sie %{title} löschen?',
      disconnectFromSite:
        'Möchten Sie die Verbindung zum Baustellenstandort trennen?',
      saveConfirmContent: 'Bestätigen',
      removeLicense: 'Möchten Sie die Verbindung zur Maschine trennen?',
      task: {
        crossingMessage: 'Der Aufgabenbereich wird überschnitten.',
        sameValueMessage: 'Es wurden die gleichen Koordinaten eingegeben.',
        areaZeroMessage: 'Es gibt keinen Bereich für den Aufgabenbereich.',
        invalidPolygonMessage: 'Es wurden die gleichen Koordinaten eingegeben.',
      },
      geofence: {
        crossingMessage: 'Der Geofence-Bereich wird durchschnitten.',
        sameValueMessage: 'Es wurden die gleichen Koordinaten eingegeben.',
        areaZeroMessage: 'Es gibt keinen Bereich für den Geofence-Bereich.',
      },
      licenseError:
        'Sie haben keine Lizenz für Smart Construction Pilot(Web App).',
      retrofitsManage: {
        serialNumberMatchError: 'Die Seriennummer stimmt nicht überein.',
        serialNumberInputError: 'Die Seriennummer existiert nicht.',
        invalidAccess:
          'Sie sind nicht berechtigt, diese Seite zu sehen, kehren Sie zur Startseite zurück.',
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'Die Seriennummer stimmt nicht überein.',
        serialNumberInputError: 'Die Seriennummer existiert nicht.',
        retrofitRentaled:
          'Unternehmensinformationen können nicht aktualisiert werden, solange der Controller im Einsatz ist.',
        invalidCorporation:
          'Da Ihre Firma kein Distributor ist, können Sie keine Proxy-Registrierung des Controllers durchführen.',
      },
    },
    validation: {
      startDate: 'Startdatum',
      endDate: 'Enddatum',
      invalidDateFormat: 'Das Datumsformat ist ungültig.',
      invalidDateTimeFormat: 'Das Datums- und Uhrzeitformat ist ungültig.',
      duplicateProject: 'Projektname ist dupliziert',
      incorrectBeforeDate: '%{target} muss vor %{before} eingestellt werden.',
      incorrectAfterDate: '%{target} muss nach %{after} eingestellt werden.',
      incorrectPlanStartDate:
        'Das geplante Startdatum muss vor dem geplanten Enddatum liegen.',
      incorrectPlanEndDate:
        'Das geplante Enddatum muss ein Datum nach dem geplanten Startdatum sein.',
      incorrectActualStartDate: 'Das Startdatum muss vor dem Enddatum liegen.',
      incorrectActualEndDate:
        'Das Enddatum muss nach dem Startdatum festgelegt werden.',
      withActualStartDate: 'Bitte geben Sie auch das Startdatum ein.',
      inputAvailableDate: 'Das einzugebende Datum liegt zwischen %{date}.',
      registrationRequired:
        'Bitte wählen Sie entweder das zugewiesene Unternehmen oder die E-Mail-Adresse aus.',
      bothInput:
        'Sie können nur entweder das zugewiesene Unternehmen oder die E-Mail-Adresse eingeben.',
      supportMode:
        'Bitte geben Sie entweder eine Unternehmens-ID oder eine Baustellen-ID ein.',
      invalidBkFile: 'Es ist keine BK3-Datei.',
      invalidPdfFile: 'Es ist keine PDF-Datei.',
      duplicatedBucketName: 'Eimername ist dupliziert.',
      duplicatedTaskName: 'Aufgabenname ist dupliziert.',
      duplicateGeofenceName: 'Der Geofence-Name ist doppelt.',
      duplicatedCompactionWorkAreaName: 'Duplizierter Verdichtungsbereichname',
      duplicatedCompactionLayerName: 'Duplizierter Verdichtungsschichtname',
      duplicated: {
        compactionMaterials: {
          message: 'Duplizierter Materialname',
        },
        weathers: {
          message: 'Doppelter Wettername',
        },
        operators: {
          message: 'Duplizierter Betreibername',
        },
      },
      containInvalidString:
        'Ungültige Zeichen sind enthalten. (%{msg} ist nicht erlaubt)',
      validHalfWidthAlphanumeric:
        'Es sind nur halbbreite alphanumerische Zeichen erlaubt.',
      validNumberFormat: 'Keine Nummer.',
      validIntegerFormat: 'Kein Integer.',
      validIntegerRangeFormat: 'Eingabe ist von 0 bis 2147483647 zulässig.',
      validDuplicateLanguage: 'Es wurde die gleiche Sprache ausgewählt.',
      validBeforeDate:
        '%{target} muss ein Datum und eine Uhrzeit vor %{before} eingestellt werden.',
      validAfterDate:
        '%{target} muss ein Datum und eine Uhrzeit nach %{after} eingestellt werden.',
    },
  },
  resources: {
    sites: {
      name: 'Baustelle',
      fields: {
        name: 'Baustelle',
        corporationName: 'Unternehmensname',
        status: 'Status',
        workPeriodStartDate: 'Startdatum',
        workPeriodEndDate: 'Enddatum',
      },
      status: {
        completed: 'Abgeschlossen',
        waiting: 'Vor Baubeginn',
        working: 'Im Bau',
      },
    },
    retrofits: {
      name: 'Maschinen',
      fields: {
        information: 'Maschinen-Info',
        rental: 'Zuweisung',
        sendBack: 'Zurücksenden',
        corporationId: 'Unternehmen in Verwendung',
        siteId: 'Baustelle',
        uuid: 'UUID',
        isError: 'Status',
        isOnline: 'Online',
        lastOnlineTime: 'Letzte Verbindung',
        basicInfoManufacturer: 'Hersteller',
        basicInfoModel: 'Modell',
        basicInfoProductNumber: 'Produktnummer',
        basicInfoSerialNumber: 'Seriennummer',
        controllerManufacturer: 'Hersteller',
        controllerModel: 'Modell',
        controllerFirmwareVersion: 'Firmware Ver.',
        gnssReceiverMainManufacturer: 'Hersteller',
        gnssReceiverMainModel: 'Modell',
        gnssReceiverMainFirmwareVersion: 'Firmware Ver.',
        gnssReceiverSubManufacturer: 'Hersteller',
        gnssReceiverSubModel: 'Modell',
        gnssReceiverSubFirmwareVersion: 'Firmware Ver.',
        machineInfoCompanyName: 'Hersteller',
        machineInfoMachineType: 'Maschinentyp',
        machineInfoMachineName: 'Maschinenname',
        machineInfoMachineId: 'Maschinen-ID',
        rentalStatus: 'Zuweisung',
        lastUpdated: 'Zuletzt aktualisiert',
      },
      isError: {
        true: 'Fehler',
        false: 'Normal',
      },
      isOnline: {
        true: 'Online',
        false: 'Offline',
      },
      machineInfoMachineType: {
        excavator: 'Bagger',
        roadroller: 'Straßenwalze',
        dozer: 'Bulldozer',
        rigiddump: 'Starrkipper',
        wheelloader: 'Radlader',
        grader: 'Grader',
        automobile: 'Kraftfahrzeug',
      },
      licenseMachineType: {
        excavator: 'Bagger',
        roadroller: 'Straßenwalze',
        dozer: 'Bulldozer',
        rigiddump: 'Starrkipper',
        wheelloader: 'Radlader',
        grader: 'Grader',
        automobile: 'Kraftfahrzeug',
        excavator_license: 'Bagger : %{licenseGrantInfo}',
        roadroller_license: 'Straßenwalze: %{licenseGrantInfo}',
        dozer_license: 'Bulldozer : %{licenseGrantInfo}',
        rigiddump_license: 'Starrkipper: %{licenseGrantInfo}',
        wheelloader_license: 'Radlader: %{licenseGrantInfo}',
        grader_license: 'Grader : %{licenseGrantInfo}',
        automobile_license: 'Kraftfahrzeug: %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: 'Übertragend',
        sent: 'Übertragen',
        failtosend: 'Übertragung fehlgeschlagen',
      },
      rentalStatus: {
        approved: 'Genehmigt',
        unapproved: 'Nicht genehmigt',
        returned: 'Zurückgegeben',
      },
    },
    retrofitRentals: {
      name: 'Zuweisung',
      fields: {
        id: 'ID',
        retrofitId: 'Maschinen-ID',
        fromCorporationId: 'Zuweisende Unternehmens-ID',
        fromCorporationName: 'Zuweisendes Unternehmen',
        toCorporationId: 'Zugeteiltes Unternehmen',
        toCorporationName: 'Unternehmen in Verwendung',
        retrofitRentalId: 'Zuweisungs-ID',
        isApproved: 'Status',
        isReturned: 'Zurückgegeben',
        planStartDate: 'Geplantes Startdatum',
        planEndDate: 'Geplantes Enddatum',
        actualStartDate: 'Startdatum',
        actualEndDate: 'Enddatum',
        rentalUsers: 'E-Mail',
        email: 'E-Mail',
        rentalStatus: 'Status',
      },
      isApproved: {
        true: 'Genehmigt',
        false: 'Nicht genehmigt',
      },
      rentalStatus: {
        approved: 'Genehmigt',
        unapproved: 'Nicht genehmigt',
        returned: 'Zurückgegeben',
      },
    },
    pendingRentals: {
      name: 'Warten auf Genehmigung',
      fields: {
        fromCorporationName: 'Zuweisendes Unternehmen',
        planStartDate: 'Geplantes Startdatum',
        planEndDate: 'Geplantes Enddatum',
      },
    },
    pendingTransfers: {
      name: 'Warten auf Genehmigung',
      fields: {
        fromCorporationName: 'Übertragende Firma',
        planDate: 'Geplantes Übertragungsdatum',
        lastUpdated: 'Zuletzt aktualisiert',
      },
    },
    pendingAlternateRegists: {
      name: 'Warten auf Genehmigung',
      fields: {
        fromCorporationName: 'Aktueller Besitzer',
        lastUpdated: 'Zuletzt aktualisiert',
      },
    },
    assignments: {
      name: 'Übertragung',
      fields: {
        id: 'ID',
        retrofitId: 'Maschinen-ID',
        fromCorporationId: 'Übertragende Firmen-ID',
        fromCorporationName: 'Übertragende Firma',
        toCorporationId: 'Übertragene Firma',
        toCorporationName: 'Unternehmen',
        isApproved: 'Status',
        planDate: 'Geplantes Übertragungsdatum',
        actualDate: 'Übertragungsdatum',
        transferUsers: 'E-Mail',
        email: 'E-Mail',
        lastUpdated: 'Zuletzt aktualisiert',
      },
      isApproved: {
        true: 'Genehmigt',
        false: 'Nicht genehmigt',
      },
    },
    buckets: {
      name: 'Löffel',
      fields: {
        companyName: 'Unternehmensname',
        siteId: 'Baustelle',
        name: 'Name',
        corporationId: 'Unternehmen in Verwendung',
        bucketType: 'Typ',
        bk3File: 'BK3-Datei',
        'bk3File.title': 'BK3-Datei',
        gltfFile: 'Löffeldatei',
        uploadedBy: 'Registriert von',
        updatedBy: 'Aktualisiert von',
        fileStatus: 'Status',
        lastUpdated: 'Zuletzt aktualisiert',
        uploadBk: 'Hochladen BK3',
      },
      bucketType: {
        slope: 'Neigung',
        standard: 'Standard',
        tilt: 'Kippen',
        tiltrotator: 'Schwenk-/Kipprotator',
      },
      uploadedBy: {
        admin: 'Administrator',
        tablet: 'Tablet',
      },
      fileStatus: {
        converting: 'Konvertierung',
        completed: 'Abgeschlossen',
        error: 'Fehler',
      },
    },
    projects: {
      name: 'Projekt',
      fields: {
        name: 'Projektname',
        coordinate: 'Koord.-system',
        version: 'Version',
        status: 'Status',
        lastUpdated: 'Zuletzt aktualisiert',
        regionId: 'Bereich',
        projectionId: 'Projektion',
        geoidId: 'Geoid',
        datumId: 'Datum',
        file: 'Datei(en)',
        retrofitId: 'Zielmaschine',
      },
      status: {
        converted: 'Abgeschlossen',
        waitforconvert: 'Konvertierung',
        error: 'Fehler',
      },
    },
    extensionarms: {
      name: 'Verlängerungsarm',
      fields: {
        extensionarmType: 'Typ',
        name: 'Name',
        corporationId: 'Unternehmen in Verwendung',
        corporationName: 'Unternehmen in Verwendung',
        siteId: 'Baustelle',
        siteName: 'Baustelle',
        lastUpdated: 'Zuletzt aktualisiert',
        extensionarmFile: 'Verlängerungsarmdatei',
      },
      extensionarmType: {
        basic: 'Verlängerungsarm',
        a: 'Typ A',
        b: 'Typ B',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: 'Teilen mit',
        name: 'Name',
        extensionarmType: 'Typ',
        extensionarmFile: {
          title: 'Verlängerungsarmdatei',
        },
      },
    },
    corporations: {
      name: 'Unternehmen',
      fields: {
        name: 'Unternehmen in Verwendung',
      },
    },
    retrofitShareFiles: {
      name: 'Anwendungsprotokoll',
      fields: {
        name: 'Dateiname',
        fileTime: 'Empfangsdatum und -zeit',
        url: 'Herunterladen',
      },
    },
    retrofitConfigs: {
      name: 'Konfigurationsinfo',
      fields: {
        timeStamp: 'Empfangsdatum und -zeit',
        url: 'Herunterladen',
      },
    },
    retrofitKitInfos: {
      name: 'Controller-Info',
      fields: {
        timeStamp: 'Empfangsdatum und -zeit',
        url: 'Herunterladen',
      },
    },
    retrofitCalibs: {
      name: 'Kalib-Info',
      fields: {
        timeStamp: 'Empfangsdatum und -zeit',
        url: 'Herunterladen',
        detailInfo: {
          createDateTime: 'Erstellungsdatum und -uhrzeit',
          updateDateTime: 'Aktualisierungsdatum und -uhrzeit',
          machineInfo: {
            companyName: 'Hersteller',
            machineType: 'Maschinentyp',
            machineName: 'Maschinenname',
            machineId: 'Maschinen-ID',
            drumConfiguration: 'Konfiguration der Maschine',
            guidanceMode: 'Führungsmodus',
          },
          machineGeometryInfo: {
            boomLength: 'Auslegerlänge',
            secondBoomLength: 'Länge des 2. Auslegers',
            armLength: 'Löffelstiellänge',
            armTopPinToBucketSideLinkPinLength:
              'Abst. zw. ob. Löff.st.bolzen u. seitl. Löffelverb.bolzen',
            boomTopPinToBucketSideLinkPinLength:
              'Abst. zw. ob. Ausl.bolzen u. seitl. Löffelverb.bolzen',
            bucketLink1Length:
              'Abst. zw. seitl. Löffelverb.bolzen u. Löff.zyl.bolzen',
            bucketLink2Length:
              'Abst. zw. Löffel.zyl.bolzen u. Löffelverb.bolzen',
            bodyCenterToBoomFootPin: {
              x: 'Ausl.fußb. X rel. zu Kar.mitte',
              y: 'Ausl.fußb. Y rel. zu Kar.mitte',
              z: 'Ausl.fußb. Z rel. zu Kar.mitte',
            },
            drumWidth: 'Breite der Klinge',
            length: 'Länge zwischen den Schuhen',
            shoeInterval: 'Intervalllänge der Schuhe',
            bladeWidthForDozer: 'Breite der Schaufel für Dozer',
            distanceFromBodyToBlade: 'Abstand vom Körper zur Schaufel',
            frontToMainGnssAntenna:
              'Länge von der Mitte einer geraden Verbindungslinie zwischen dem vorderen geerdeten Ende und der Hauptantenne X',
            frontToSubGnssAntenna:
              'Länge von der Mitte einer geraden Linie, die das vordere geerdete Ende mit der Sub-GNSS-Antenne verbindet X',
            centerToMainGnssAntenna:
              'Y Abstand von der Mitte zur elektrischen Haupt-GNSS-Phasenmitte',
            centerToSubGnssAntenna:
              'Y Abstand von der Mitte zum elektrischen Phasenmittelpunkt des Sub-GNSS',
            groundToMainGnssAntenna:
              'Z Abstand von der Erde zum elektrischen Haupt-GNSS-Phasenzentrum',
            groundToSubGnssAntenna: 'Z-Abstand vom Bode',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'Versatzwinkel v. Ausl.-IMU',
            secondBoomImuOffsetAngle: 'Versatzwinkel v. 2. Ausl.-IMU',
            armImuOffsetAngle: 'Versatzwinkel v. Löff.st.-IMU',
            bucketImuOffsetAngle: 'Versatzwinkel v. Löff.-IMU',
            bodyImuOffsetRollAngle: 'Versatzdrehw. v. Kaross.-IMU',
            bodyImuOffsetPitchAngle: 'Versatzneig.w. v. Kaross.-IMU',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: 'Haupt-GNSS X rel. zu Kar.mitte',
                y: 'Haupt-GNSS Y rel. zu Kar.mitte',
                z: 'Haupt-GNSS Z rel. zu Kar.mitte',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: 'Hilfs-GNSS X rel. zu Kar.mitte',
                y: 'Hilfs-GNSS Y rel. zu Kar.mitte',
                z: 'Hilfs-GNSS Z rel. zu Kar.mitte',
              },
            },
          },
          extendedModel: {
            swingBoom: 'Schwenkausleger Modell',
            twoPieceBoom: '2-teiliger Auslegers Modell',
            minimalSwingRadius: 'Versetzter Ausleger Modell',
          },
          measuredValues: {
            otherLength: {
              machineHeight: 'Gegengewicht-Abstand',
            },
            bodyImu1: {
              roll: 'Roll des IMU-Messwerts',
              pitch: 'Pitch des IMU-Messwerts',
              yaw: 'Gieren des IMU-Messwerts',
            },
            bodyImu2: {
              roll: 'Roll von IMU gemessener Wert',
              pitch: 'Pitch von IMU gemessener Wert',
              yaw: 'Gieren von IMU gemessener Wert',
            },
            frontLeft: {
              x: 'Gemessener Wert vorne links X',
              y: 'Gemessener Wert vorne links Y',
              z: 'Gemessener Wert vorne links Z',
            },
            frontRight: {
              x: 'Vermessungswert Front rechts X',
              y: 'Gemessener Wert vorne rechts Y',
              z: 'Erhobener Wert der Vorderseite rechts Z',
            },
            frontGroundingEnd: {
              x: 'Ermittelter Wert des vorderen Schwellenendes X',
              y: 'Vermessungswert des vorderen Endes des Fugenbandes Y',
              z: 'Vermessungswert des vorderen Fugenabschlusses Z',
            },
            rearGroundingEnd: {
              x: 'Ermittelter Wert des hinteren Fugenendes X',
              y: 'Ermittelter Wert des hinteren Fugenabschlusses Y',
              z: 'Vermessungswert des hinteren Gussendes Z',
            },
            mainGnssAntenna: {
              x: 'Gemessener Wert der GNSS-Hauptantenne X',
              y: 'Gemessener Wert der GNSS-Hauptantenne Y',
              z: 'Vermessungswert der GNSS-Hauptantenne Z',
            },
            subGnssAntenna: {
              x: 'Gemessener Wert der Sub-GNSS-Antenne X',
              y: 'Gemessener Wert der Sub-GNSS-Antenne Y',
              z: 'Gemessener Wert der Sub-GNSS-Antenne Z',
            },
            prismPoleOffset: 'Höhe des Prismenstabs',
            antennaElectricalCenterHeight:
              'Höhe vom elektrischen Mittelpunkt der Antenne',
          },
        },
      },
    },
    retrofitAccuracies: {
      name: 'Genauigkeitsprüfungsergebnis',
      fields: {
        checkDateTime: 'Bestätigungsdatum und -zeit',
        surveyPoint: 'Messpunkt',
        positionN: 'Gemessener Wert : N',
        positionE: 'E',
        positionZ: 'Z',
        machineOffsetN: 'Versatzwert : N',
        machineOffsetE: 'E',
        machineOffsetZ: 'Z',
        referencePointName: 'Bezeichnung des Bezugspunkts',
        referencePointN: 'Referenzpunkt: N',
        referencePointE: 'E',
        referencePointZ: 'Z',
        unitLength: 'Längeneinheit',
      },
    },
    retrofitBasicSettings: {
      name: 'Grundeinstellungen',
      fields: {
        timeStamp: 'Empfangsdatum und -zeit',
        url: 'Herunterladen',
      },
    },
    retrofitErrors: {
      name: 'Fehlerinformation',
      fields: {
        notifiedTime: 'Datum und Uhrzeit des Auftretens',
        errorCodes: 'Fehlercode: Details',
      },
    },
    retrofitToposurveys: {
      name: 'Topo.-Verm.',
      fields: {
        surveyDate: 'Datum und Uhrzeit der Befragung',
        projectName: 'Projektname',
        layerName: 'Ebene',
        pointName: 'Punktname',
        surveyPoint: 'Messpunkt',
        n: 'Gemessener Wert : N',
        e: 'E',
        z: 'Z',
        unitLength: 'Längeneinheit',
      },
    },
    asbuilts: {
      name: 'Baueergebnisse',
      fields: {
        execStartDate: 'Ausführungsstart',
        execEndDate: 'Ausführungsende',
        startPeriod: 'Erwerbszeitraum von',
        endPeriod: 'Erwerbszeitraum bis',
        execStatus: 'Status',
        url: '',
        csvType: 'Datenformat',
        createUser: 'Benutzer erstellen',
      },
      execStatus: {
        complete: 'Abgeschlossen',
        inprogress: 'Verarbeitung',
        error: 'Fehler',
        completewithoutdata: 'Abgeschlossen (Keine Daten)',
      },
    },
    retrofitBasicInfos: {
      name: 'Basisinfo',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: 'Händler',
        retrofitRegionId: 'Bereich',
        corporationId: 'Unternehmens-ID',
        corporationName: 'Unternehmensname',
        basicInfoManufacturer: 'Hersteller',
        basicInfoModel: 'Modell',
        basicInfoProductNumber: 'Produktnummer',
        basicInfoSerialNumber: 'Seriennummer',
        lastUpdated: 'Zuletzt aktualisiert',
        description: 'Beschreibung',
        ownerId: 'Unternehmens-ID',
        ownerName: 'Unternehmensname',
      },
      tag: {
        retrofitBasicInfosDetail: 'Basisinfo',
        sendBack: 'Zurücksenden',
      },
      sendBack: {
        fields: {
          site: 'Baustelle',
          corporation: 'Unternehmen in Verwendung',
          ntripSettingInfo: 'Ntrip-ID',
          toRetrofit: 'Alternativer Controller',
          notificationEmailAddress: 'E-Mail',
        },
      },
    },
    retrofitBulkActions: {
      name: 'Massenregistrierung',
      fields: {
        uploadFileName: 'Dateiname',
        execStartDate: 'Ausführungsstartdatum und -uhrzeit',
        execEndDate: 'Ausführungsenddatum und -uhrzeit',
        execStatus: 'Status',
        uploadFile: 'Registrierte Datei',
        resultFile: 'Registrierungsergebnisse',
      },
      execStatus: {
        complete: 'Abgeschlossen',
        inprogress: 'Verarbeitung',
        error: 'Fehler',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: 'Administrator-Unternehmensmassenaktion',
      fields: {
        uploadFileName: 'Dateiname',
        execStartDate: 'Ausführungsstartdatum und -uhrzeit',
        execEndDate: 'Ausführungsenddatum und -uhrzeit',
        execStatus: 'Status',
        uploadFile: 'Registrierte Datei',
        resultFile: 'Registrierungsergebnisse',
      },
      execStatus: {
        complete: 'Abgeschlossen',
        inprogress: 'Verarbeitung',
        error: 'Fehler',
      },
    },
    tasks: {
      name: 'Aufgabe',
      fields: {
        name: 'Name',
        status: 'Status',
        description: 'Beschreibung',
        projectSourceLayerId: 'Ebene',
        retrofitIds: 'Zielmaschine',
        expiredDate: 'Ablaufdatum',
        createDate: 'Ablaufdatum',
        rectangle: 'Unterstützter Bereich',
        lastUpdated: 'Zuletzt aktualisiert',
      },
      status: {
        error: 'Fehler',
        converting: 'Konvertierung',
        sending: 'Übertragend',
        sent: 'Übertragen',
        failtosend: 'Übertragung fehlgeschlagen',
      },
    },
    compactionMaterials: {
      name: 'Materialien',
      fields: {
        name: 'Name',
      },
    },
    weathers: {
      name: 'Wetterbedingungen',
      fields: {
        name: 'Name',
      },
    },
    operators: {
      name: 'Operatoren',
      fields: {
        name: 'Name',
      },
    },
    compactionWorkAreas: {
      name: 'Verdichtungsbereiche',
      fields: {
        name: 'Arbeitsbereichsnamen',
        blockSize: 'Blockgröße',
        description: 'Beschreibung',
      },
    },
    compactionLayers: {
      name: 'Verdichtungsschichten',
      fields: {
        name: 'Name',
        thickness: 'Dicke',
        passCount: 'Anzahl der Durchgänge',
        compactionMaterialId: 'Material',
        inUse: 'Status',
        description: 'Beschreibung',
      },
      inUse: {
        true: 'in Gebrauch',
        false: 'Unbenutzt',
      },
    },
    gnssSettings: {
      name: 'Ntrip-Einstellung',
      fields: {
        registCorporationName: 'Registriert von',
        ownerCorporationId: 'Unternehmens-ID',
        ownerCorporationName: 'Unternehmensname',
        name: 'Name',
        url: 'Server-URL',
        portnum: 'Port',
        userId: 'ID',
        password: 'Passwort',
        retrofitId: 'Zielmaschine',
        status: 'Status',
        updateDate: 'Zuletzt aktualisiert',
      },
      status: {
        waitforsend: 'Konvertierung',
        sending: 'Übertragend',
        sent: 'Übertragen',
        failtosend: 'Übertragung fehlgeschlagen',
      },
    },
    supportMode: {
      name: 'Unterstützungsmodus',
      fields: {
        enabled: 'Supportmodus aktivieren',
        corporationId: 'Unternehmens-ID',
        corporationName: 'Unternehmensname',
        siteId: 'Baustellen-ID',
        siteName: 'Baustelle',
        latitude: 'Breitengrad',
        longitude: 'Längengrad',
        workPeriodStartDate: 'Tatsächlicher Bauzeitraum (Startdatum)',
        workPeriodEndDate: 'Tatsächlicher Bauzeitraum (Enddatum)',
        photoUrl: 'Site-Bild URL',
        description: 'Beschreibung',
      },
    },
    userNotices: {
      name: 'Benachrichtigung',
      noticeType: {
        emergencyimportant: 'Notfall/Wichtig',
        emergency: 'Notfall',
        important: 'Wichtig',
      },
      fields: {
        noticeType: 'Wichtigkeit',
        noticeTypes: 'Wichtigkeit',
        noticeCategory: 'Benachrichtigungstyp',
        title: 'Titel',
        content: 'Inhalt',
        publicationStartDate: 'Startdatum und -zeit (UTC)',
        publicationEndDate: 'Enddatum und -uhrzeit(UTC)',
        file: 'Anhänge',
        language: 'Sprache',
        emergency: 'Notfall',
        important: 'Wichtig',
      },
    },
    firmwares: {
      name: 'Firmware',
      fields: {
        version: 'Version',
        compatibilityLevel: 'Kompatibilitätsstufe',
        firmwareFile: 'Firmwaredatei',
        firmwareFileCheckSum: 'Firmware-Prüfsumme',
        firstInitializationFile: 'Initialisierungsdatei 1',
        firstInitializationFileCheckSum: 'Initialisierungsdatei 1 Prüfsumme',
        secondInitializationFile: 'Initialisierungsdatei 2',
        secondInitializationFileCheckSum: 'Initialisierungsdatei 2 Prüfsumme',
      },
    },
    bucketShares: {
      name: 'Eimerfreigabe',
      fields: {
        fromBucketId: 'Von Eimer-ID',
        corporationId: 'Unternehmens-ID',
        name: 'Name',
      },
    },
    extensionarmShare: {
      name: 'Verlängerungsarmfreigabe',
      fields: {
        name: 'Name',
      },
    },
    retrofitCorporationLicenses: {
      name: 'LIZENZ',
      fields: {
        licenseId: 'Lizenz-ID',
        licenseType: 'Typ',
        issuedAt: 'Ausgabedatum',
        expiresAt: 'Ablaufdatum',
        retrofitId: 'Maschinen',
        comment: 'Beschreibung',
        licenseStatus: 'Status',
        licensePermission: 'LIZENZ',
        productType: 'Produktname',
      },
      licenseStatus: {
        unlicense: 'Nicht zugewiesen',
        disable: 'Ungültig',
        enable: 'Gültig',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: 'Unternehmensname',
        namePlates: 'Foto',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: 'Längeneinheit',
      },
    },
    geofences: {
      name: 'Geofence',
      fields: {
        type: 'Typ',
        name: 'Name',
        dimension: 'Abmessung',
        trigger: 'Auslöser',
        valid: 'Gültig',
        alertType: 'Warnung Typ',
        startDate: 'Startdatum',
        endDate: 'Enddatum',
        transparency: 'Transparent',
        elevation: 'Höhe',
        height: 'Höhe',
        radius: 'Radius',
        lastUpdated: 'Zuletzt aktualisiert',
        points: 'Unterstützter Bereich',
        thickness: 'Dicke',
      },
      type: {
        line: 'Linie',
        polygon: 'Polygon',
        circle: 'Kreis',
        point: 'Punkt',
        wall: 'Wand',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: 'Gültig',
        false: 'Ungültig',
      },
      alertType: {
        attention: 'Achtung',
        notice: 'Hinweis',
        warning: 'Warnung',
        caution: 'Vorsicht',
        danger: 'Gefahr',
      },
      transparency: {
        true: 'Transparent',
        false: 'Undurchsichtig',
      },
    },
    geofenceAlerts: {
      name: 'Warnungen',
      fields: {
        timeStamp: 'Erkennungsdatum und -zeit',
        geofenceName: 'Geofence-Name',
        alertType: 'Warnung Typ',
        collisionPoint: 'Kollisionspunkt',
        retrofit: {
          machineInfoMachineName: 'Maschinen',
        },
      },
      alertType: {
        attention: 'Achtung',
        notice: 'Hinweis',
        warning: 'Warnung',
        caution: 'Vorsicht',
        danger: 'Gefahr',
      },
    },
    ntrips: {
      name: 'Ntrip-Verwaltung',
      fields: {
        name: 'Benutzer-ID',
        ntripPass: 'Passwort',
        serial: 'Seriennummer',
        customerCorporationId: 'Unternehmens-ID',
        customerCorporationName: 'Unternehmensname',
        ntrip: 'Status',
        numberOfExchanges: 'Anzahl der Austausche',
        id: 'ID',
        licenceId: 'Lizenz-ID',
        dateOfRegistration: 'Registrierungsdatum',
        billingToCustomers: 'Abrechnung an Kunden',
        paymentToKomatsu: 'Zahlung an Komatsu',
        applicantId: 'Bewerber-ID',
        applicant: 'Antragsteller',
        requestCorporationId: 'Unternehmens-ID anfordern',
        requestCorporationName: 'Unternehmensname anfordern',
        isUsed: 'Wird verwendet',
      },
      ntrip: {
        publish: 'Ausgezahlt',
        stop: 'Stop',
        delete: 'Gelöscht',
      },
    },
    retrofitAlternateRegists: {
      name: 'Controller-Proxy-Registrierung',
      fields: {
        id: 'ID',
        retrofitId: 'retrofitId',
        fromCorporationId: 'von Unternehmens-ID',
        fromCorporationName: 'Controller-Proxy-Registrierung',
        toCorporationId: 'Benutzer',
        toCorporationName: 'Unternehmensname',
        isApproved: 'Status',
        createDate: 'Antragsdatum',
        actualDate: 'Genehmigungsdatum',
        transferUsers: 'E-Mail',
        email: 'E-Mail',
        lastUpdated: 'Zuletzt aktualisiert',
      },
      isApproved: {
        true: 'Genehmigt',
        false: 'Nicht genehmigt',
      },
    },
    pendingApprovals: {
      name: 'Warten auf Genehmigung',
      fields: {
        approvalType: 'Typ',
        requestedBy: 'Antragstellerunternehmen',
        lastUpdated: 'Zuletzt aktualisiert',
      },
      approvalType: {
        assignment: 'Zuweisung',
        transfer: 'Übertragung',
        agent: 'Controller-Proxy-Registrierung',
      },
    },
    retrofitSendbacks: {
      name: 'Zurücksenden',
      fields: {
        fromRetrofitId: 'Von Retrofit',
        toRetrofitId: 'Alternativer Controller',
        notificationEmailAddress: 'E-Mail',
        siteId: 'Baustelle',
        corporationId: 'Unternehmens-ID',
        corporationName: 'Unternehmen in Verwendung',
        ntripId: 'Ntrip-Info',
        isComplete: 'Vollständige Sendung',
      },
    },
    siteBuriedStructures: {
      fields: {
        epsgCode: 'Epsg',
      },
    },
  },
};

export default message;
