import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { UserData } from '../dataProvider';

const useGetUserData = () => {
  const dataProvider = useDataProvider();

  return useCallback(
    async (corporationId: string, email: string): Promise<UserData[]> => {
      const { data } = await dataProvider.getList('users', {
        pagination: {
          page: 1,
          perPage: 1,
        },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          corporationId,
          email,
        },
      });

      if (data.length > 0) {
        return data as UserData[];
      }

      // 取得結果0の場合は空配列を返却
      return [];
    },
    [dataProvider],
  );
};

export default useGetUserData;
