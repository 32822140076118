/**
 * 参考元:
 * https://stackoverflow.com/questions/38213668/promise-retry-design-patterns
 */
const retry = <T>(fn: () => Promise<T>, count: number, delay: number) => {
  let cnt = count;
  return new Promise((resolve, reject) => {
    let error: any;
    const attempt = () => {
      if (cnt === 0) {
        reject(error);
      } else {
        fn()
          .then(resolve)
          .catch((e: any) => {
            cnt -= 1;
            error = e;
            setTimeout(() => {
              attempt();
            }, delay);
          });
      }
    };
    attempt();
  });
};

export default retry;
