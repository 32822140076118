import { DataProvider, useDataProvider, Record } from 'react-admin';

const MAX_PERPAGE = 100;

interface AfterSecondPageResult {
  page: number;
  data: Record[];
}

const useGetAfterSecondPage = (dataProvider: DataProvider) => {
  return {
    fetchAfterSecondPage: (
      resources: string,
      page: number,
      filter: any,
      sort: any,
    ): Promise<AfterSecondPageResult> => {
      const fn = async () => {
        const { data } = await dataProvider.getList(resources, {
          pagination: { page, perPage: MAX_PERPAGE },
          filter,
          sort,
        });
        return { page, data };
      };
      return fn();
    },
  };
};
type PageRecordDictionary = Map<number, Record[]>;

const useGetListAllPage = () => {
  const dataProvider = useDataProvider();
  const { fetchAfterSecondPage } = useGetAfterSecondPage(dataProvider);
  return async ({
    resource,
    filter = {},
    sort = {},
  }: {
    resource: string;
    filter?: any;
    sort?: any;
  }): Promise<{
    data: Record[];
  }> => {
    const firstPageData = await dataProvider.getList(resource, {
      filter,
      pagination: { page: 1, perPage: MAX_PERPAGE },
      sort,
    });

    if (!firstPageData) return { data: [] };
    const { data, total } = firstPageData as {
      data: Record[];
      total: number;
    };
    // limitCountの値とtotalの値次第では、2ページ目以降のデータを取得する
    if (total > MAX_PERPAGE) {
      const maxPage = Math.ceil(total / MAX_PERPAGE);
      const pages: number[] = [];
      // ページ順序制御のため2ページ目以降の情報を格納した配列を準備
      for (let i = 2; i <= maxPage; i += 1) {
        pages.push(i);
      }
      // Promise.allにて全ページのgetListを並列非同期にて実施
      const fetchAfterPage: Promise<AfterSecondPageResult>[] = pages.map(page =>
        fetchAfterSecondPage(resource, page, filter, sort),
      );
      const afterPage = await Promise.all(fetchAfterPage);

      // Promise.allの順序に保証がないため、実行結果の各ページ情報を一旦dictionaryに格納
      const dicPageInfo: PageRecordDictionary = new Map<number, Record[]>();
      afterPage.forEach(value => {
        dicPageInfo.set(value.page, value.data);
      });
      // pagesは2ページ目以降昇順に格納されているため、forEachにて、1ページ目のデータと順番にマージ
      let allPageData = data;
      pages.forEach(value => {
        const addRecoreds = dicPageInfo.get(value);
        if (addRecoreds) allPageData = allPageData.concat(addRecoreds);
      });
      // 全ページ分のデータをマージしたものを返却
      return { data: allPageData };
    }
    // 1ページに収まるデータはそのまま返却
    return { data };
  };
};

export default useGetListAllPage;
