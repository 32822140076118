import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useTranslate } from 'react-admin';
import { AssignmentIcon } from '../../../assets/images';
import { useJumpTo } from '../../../hooks';
import { DotBadge, Typography } from '../../atoms/mui';
import Button from '../../bases/button/Button';
import { config } from '../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    BadgeInBox: {
      lineHeight: 1.2,
    },
    BadgeInLabel: {
      fontSize: 10,
      textTransform: 'none',
    },
  }),
);

const ApprovalButton = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const { gotoList } = useJumpTo();
  const dataProvider = useDataProvider();
  const [fetched, setFetched] = useState(false);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (fetched) return;
    setFetched(true);

    const fn = async () => {
      const { data: rentalData } = await dataProvider.getList(
        'pendingRentals',
        {
          pagination: {
            page: 1,
            perPage: 1, // 1件しか取る必要がない
          },
          sort: { field: 'id', order: 'ASC' },
          filter: {},
        },
      );
      const rentalTotal = rentalData ? rentalData.length : undefined;

      // REACT_APP_FEATURE_TRANSFERが削除されたらlet変数とif文を削除
      let transferTotal: number | undefined;
      if (config.feature.transfer) {
        const { data: transferData } = await dataProvider.getList(
          'pendingTransfers',
          {
            pagination: {
              page: 1,
              perPage: 1, // 1件しか取る必要がない
            },
            sort: { field: 'id', order: 'ASC' },
            filter: {},
          },
        );
        transferTotal = transferData ? transferData.length : undefined;
      }

      const { data: retrofitAlternateRegistData } = await dataProvider.getList(
        'pendingAlternateRegists',
        {
          pagination: {
            page: 1,
            perPage: 1, // 1件しか取る必要がない
          },
          sort: { field: 'id', order: 'ASC' },
          filter: {},
        },
      );
      const retrofitAlternateRegistTotal = retrofitAlternateRegistData
        ? retrofitAlternateRegistData.length
        : undefined;
      return { rentalTotal, transferTotal, retrofitAlternateRegistTotal };
    };
    fn().then(result => {
      const { rentalTotal, transferTotal, retrofitAlternateRegistTotal } =
        result;
      let total: number | undefined;
      if (!!rentalTotal || !!transferTotal || !!retrofitAlternateRegistTotal) {
        total =
          (rentalTotal || 0) +
          (transferTotal || 0) +
          (retrofitAlternateRegistTotal || 0);
      }
      setVisible(!!total);
    });
  }, [dataProvider, fetched]);

  return isVisible ? (
    <Button
      onClick={() => {
        gotoList('approvals');
      }}
    >
      <DotBadge>
        <div className={classes.BadgeInBox}>
          <AssignmentIcon />
          <Typography className={classes.BadgeInLabel}>
            {translate('admin.approval')}
          </Typography>
        </div>
      </DotBadge>
    </Button>
  ) : (
    <></>
  );
};

ApprovalButton.displayName = 'ApprovalButton';
export default ApprovalButton;
