import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { FormDataConsumer, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import {
  Edit,
  CustomForm,
  BreadcrumbsFiled,
  TextField,
  TextInput,
  CustomDateField,
  RetrofitAutoCompleteInput,
  Loading,
  LicensePermissionField,
} from '../..';
import { useAccount, useGetSelefDetailData } from '../../../hooks';
import { styles } from '../../../theme';

import { SaveButton, DeleteButton } from './views';
import { usePreUpdate } from './hooks';

const useStyles = makeStyles(() =>
  createStyles({
    description: {
      ...styles.multilineText,
    },
  }),
);

const RetrofitCorporationLicenseEdit: React.FC = (props: any) => {
  const classes = useStyles();
  const { form, basePath, record, resource } = props;
  const getAccount = useAccount();
  const getSelefDetailData = useGetSelefDetailData();
  const redirectTo = useRedirect();
  const { id: retrofitCorporationLicenseId } = useParams<{ id: string }>();
  const enable = true;
  const {
    handlePreUpdate,
    component: preUpdateView,
    doUpdate,
  } = usePreUpdate();

  const [apiState, setApiState] = useState<{
    fetched: boolean;
    loading: boolean;
    retrofitCorporationLicenseId: string | undefined;
    data?: { isOwnData?: boolean; corporationId: string };
  }>({
    fetched: false,
    loading: true,
    retrofitCorporationLicenseId: undefined,
    data: undefined,
  });

  useEffect(() => {
    if (
      apiState.fetched &&
      apiState.retrofitCorporationLicenseId === retrofitCorporationLicenseId
    )
      return;
    setApiState({ fetched: true, loading: true, retrofitCorporationLicenseId });

    const api = async () => {
      // accounts
      const {
        data: { corporationId: userCorporationId },
      } = await getAccount();

      const filter = { corporationId: userCorporationId, enable };
      const retrofitCorporationLicense = await getSelefDetailData(
        resource,
        filter,
        retrofitCorporationLicenseId,
      );
      return {
        isOwnData: !!retrofitCorporationLicense,
        corporationId: userCorporationId,
      };
    };
    api().then(data => {
      setApiState({
        fetched: true,
        loading: false,
        retrofitCorporationLicenseId,
        data,
      });
    });
  }, [
    getSelefDetailData,
    apiState.data,
    apiState.retrofitCorporationLicenseId,
    getAccount,
    enable,
    resource,
    retrofitCorporationLicenseId,
    apiState.fetched,
  ]);

  // AutoCompleteInputで選択した建機情報を取得
  const [machineInfoMachineId, setMachineInfoMachineId] = useState('');
  const [machineInfoMachineName, setMachineInfoMachineName] = useState('');
  const [basicInfoSerialNumber, setBasicInfoSerialNumber] = useState('');
  const handleOption = useCallback(
    (
      machineInfoMachineIdParameter: string,
      machineInfoMachineNameParameter: string,
      basicInfoSerialNumberParameter: string,
    ) => {
      setMachineInfoMachineId(machineInfoMachineIdParameter);
      setMachineInfoMachineName(machineInfoMachineNameParameter);
      setBasicInfoSerialNumber(basicInfoSerialNumberParameter);
    },
    [],
  );
  const selectedMachine = {
    machineInfoMachineId,
    machineInfoMachineName,
    basicInfoSerialNumber,
  };

  const corporationId = apiState.data?.corporationId;
  const { loading } = apiState;
  if (loading || corporationId === '') return <Loading />;
  const isOwnData = apiState.data?.isOwnData;
  if (!isOwnData) redirectTo('/');

  const transform = (data: any) => ({
    ...data,
    // RetrofitAutoCompleteInput の出力として、string型 で設定される為、 Number 型に変換する必要がある
    retrofitId: Number(data.retrofitId),
  });

  const saveButtonProps = {
    basePath,
    record,
    form,
    selectMachineInfo: selectedMachine,
    handlePreUpdate,
    doUpdate,
  };

  return (
    <Edit {...props} transform={transform}>
      <CustomForm
        title="admin.pages.retrofitCorporationLicenseEdit"
        saveButton={<SaveButton {...saveButtonProps} />}
        deleteButton={<DeleteButton />}
      >
        {preUpdateView}
        <BreadcrumbsFiled
          breadcrumbs={['resources.retrofitCorporationLicenses.name']}
          label="ra.action.edit"
        />
        <TextField resource="retrofitCorporationLicenses" source="licenseId" />
        <TextField
          resource="retrofitCorporationLicenses"
          source="licenseType"
        />
        <LicensePermissionField
          resource="retrofitCorporationLicenses"
          source="licensePermission"
        />
        <CustomDateField
          resource="retrofitCorporationLicenses"
          source="issuedAt"
        />
        <CustomDateField
          resource="retrofitCorporationLicenses"
          source="expiresAt"
        />
        <FormDataConsumer>
          {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ({ formData, ...rest }) => {
              return (
                <RetrofitAutoCompleteInput
                  {...rest}
                  label="resources.retrofitCorporationLicenses.fields.retrofitId"
                  source="retrofitId"
                  filter={{ ownerId: corporationId }}
                  handleOption={handleOption}
                />
              );
            }
          }
        </FormDataConsumer>

        <TextInput
          resource="retrofitCorporationLicenses"
          source="comment"
          className={classes.description}
          multiline
          rows={3}
        />
      </CustomForm>
    </Edit>
  );
};
RetrofitCorporationLicenseEdit.displayName = 'RetrofitCorporationLicenseEdit';

export default RetrofitCorporationLicenseEdit;
