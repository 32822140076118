import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import Circle from 'ol/geom/Circle';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import {
  Style,
  Stroke,
  Fill,
  Circle as Image,
  Icon,
  RegularShape,
} from 'ol/style';
import { Geometry } from './types';
import { GeofenceAlertsData, GeofenceAlertType } from '../../dataProvider';

/**
 * Geometry からタイプに応じた Feature を生成
 *
 * @param param Geometry 型
 * @returns Feature 型 (未サポートの type を指定すると null を返す)
 */
const createFeatureFromGeometry = (geometry: Geometry): Feature | null => {
  const { type } = geometry;
  switch (type) {
    case 'Polygon':
      return createPolygonFeature(geometry);
    case 'Circle':
      return createCircleFeature(geometry);
    case 'Line':
      return createLineFeature(geometry);
    case 'Point':
      return createPointFeature(geometry);
    case 'Unknown':
      return null;
    default:
  }
  console.warn(`Unsupported Geometry: ${type}`);
  return null;
};

/**
 * 多角形の Feature を生成
 *
 * @param param Geometry 型
 * @returns Feature 型
 */
const createPolygonFeature = (geometry: Geometry): Feature => {
  const tmp = geometry.coordinates.map(({ lon, lat }) => [lon, lat]);
  const coordinates = tmp.length > 0 ? [...tmp, tmp[0]] : []; // 末尾に先頭データを足す
  const feature = new Feature<Polygon>({
    polygon: new Polygon([coordinates]),
    shape: 'polygon',
  });
  feature.setGeometryName('polygon'); // 消すとレンダーされなくなる
  feature.setId(geometry.id);
  feature.setProperties({ name: geometry.name });
  setFeatureStyle(feature, geometry.fillColor, geometry.strokeColor);
  return feature;
};

/**
 * 真円の Feature を生成
 *
 * @param  param Geometry 型
 * @returns Feature 型
 */
const createCircleFeature = (geometry: Geometry): Feature => {
  const [center] = geometry.coordinates;
  const coordinates = [center.lon, center.lat];
  const radius = geometry.radius || 0;
  const feature = new Feature(new Circle(coordinates, radius));
  feature.setId(geometry.id);
  feature.setProperties({ name: geometry.name });
  setFeatureStyle(feature, geometry.fillColor, geometry.strokeColor);
  return feature;
};

/**
 * 直線の Feature を生成
 *
 * @param  param Geometry 型
 * @returns Feature 型
 */
const createLineFeature = (geometry: Geometry): Feature => {
  const tmp = geometry.coordinates.map(({ lon, lat }) => [lon, lat]);
  const feature = new Feature(new LineString(tmp));
  feature.setId(geometry.id);
  feature.setProperties({ name: geometry.name });
  setFeatureStyle(feature, geometry.fillColor, geometry.strokeColor);
  return feature;
};

/**
 * 点の Feature を生成
 *
 * @param  param Geometry 型
 * @returns Feature 型
 */
const createPointFeature = (geometry: Geometry) => {
  const [tmp] = geometry.coordinates.map(({ lon, lat }) => [lon, lat]);
  const feature = new Feature(new Point(tmp));
  feature.setId(geometry.id);
  feature.setProperties({ name: geometry.name });
  setFeatureStyle(feature, geometry.fillColor, geometry.strokeColor);
  return feature;
};

/**
 * ジオフェンスアラートの Feature を生成
 *
 * @param  param GeofenceAlertsData 型
 * @returns Feature 型
 */
export const createAlertFeature = (alert: GeofenceAlertsData) => {
  const feature = new Feature(new Point([alert.x, alert.y]));
  feature.setId(alert.geofenceId);
  feature.setProperties({ name: 'alerts' });
  setAlertIconStyle(feature, alert.alertType);
  return feature;
};

/**
 * Featureにスタイルを適応
 *
 * @param feature 対象のFeature
 * @param fillColor 塗り潰し色 (CSSの指定方法)
 * @param strokeColor アウトライン色 (CSSの指定方法)
 */
export const setFeatureStyle = (
  feature: Feature,
  fillColor = 'rgba(255, 255, 255, 0.65)',
  strokeColor = '#ff7c0a',
) => {
  const defaultFill = new Fill({
    color: fillColor,
  });
  const defaultStroke = new Stroke({
    color: strokeColor,
    width: 2,
  });
  const defaultImage = new Image({
    stroke: defaultStroke,
    fill: defaultFill,
    radius: 5,
  });
  const defaultStyle = new Style({
    fill: defaultFill,
    stroke: defaultStroke,
    image: defaultImage,
  });
  feature.setStyle(defaultStyle);
};

/**
 * ジオフェンスAlertのFeatureにスタイルを適応
 * @param feature
 * @param alertType
 */
export const setAlertIconStyle = (
  feature: Feature,
  alertType: GeofenceAlertType,
) => {
  let iconFileName;
  // アラート種別によってsvgアイコンを決定
  switch (alertType) {
    case 'Attention':
      iconFileName = 'alert_attention.svg';
      break;
    case 'Caution':
      iconFileName = 'alert_caution.svg';
      break;
    case 'Warning':
      iconFileName = 'alert_warning.svg';
      break;
    case 'Danger':
      iconFileName = 'alert_danger.svg';
      break;
    default:
      iconFileName = 'alert_notice.svg';
  }

  const defaultFill = new Fill({
    color: '#000344', // ジオフェンス編集画面の現在選択中のアイコンの背景色と同一
  });
  const defaultImage = new Icon({
    src: `./icon/geofence_alert/${iconFileName}`,
    offset: [0, 0],
    opacity: 1,
  });
  const defaultStyle = [
    new Style({
      image: new RegularShape({
        points: 4,
        radius: 28,
        fill: defaultFill,
        angle: Math.PI / 4, // これを指定しないと四角ではなくひし形になる
      }),
    }),
    new Style({
      image: defaultImage,
    }),
  ];
  feature.setStyle(defaultStyle);
};

export default createFeatureFromGeometry;
