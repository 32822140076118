import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { Identifier } from 'react-admin';

export type TooltipDirection = 'top' | 'right' | 'bottom' | 'left';
const OpenlayerTooltip: React.FC<{
  direction: TooltipDirection;
  top: Identifier;
  left: Identifier;
}> = ({ direction, top, left }) => {
  const tooltipStyes = makeStyles(() =>
    createStyles({
      tooltip: {
        display: 'none',
        position: 'absolute',
        zIndex: 1500, // tooltip: 1500 => material-ui/packages/material-ui/src/styles/zIndex.js
        backgroundColor: '#616161',
        padding: '4px 8px',
        borderRadius: '4px',
        wordWrap: 'break-word',
        userSelect: 'none',
        width: '154px',
        textAlign: 'center',
        top,
        left,
      },
      arrowTop: {
        '&::after': {
          content: '""',
          position: 'absolute',
          right: 0,
          top: '-10px',
          left: 'calc(50% - 10px)',
          width: 0,
          height: 0,
          borderStyle: 'solid',
          borderColor: 'transparent transparent #616161 transparent',
          borderWidth: '0 10px 10px 10px',
        },
      },
      arrowRight: {
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 'calc(50% - 10px)',
          bottom: 0,
          right: '-10px',
          width: 0,
          height: 0,
          borderStyle: 'solid',
          borderColor: 'transparent transparent transparent #616161',
          borderWidth: '10px 0 10px 10px',
        },
      },
      arrowBottom: {
        '&::after': {
          content: '""',
          position: 'absolute',
          left: 'calc(50% - 10px)',
          bottom: '-10px',
          width: 0,
          height: 0,
          borderStyle: 'solid',
          borderColor: '#616161 transparent transparent transparent',
          borderWidth: '10px 10px 0 10px',
        },
      },
      arrowLeft: {
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 'calc(50% - 10px)',
          left: '-10px',
          width: 0,
          height: 0,
          borderStyle: 'solid',
          borderColor: 'transparent #616161 transparent transparent',
          borderWidth: '10px 10px 10px 0',
        },
      },
    }),
  );

  const classes = tooltipStyes();
  if (direction === 'top') {
    return (
      <div
        id="tooltip"
        className={`${classes.tooltip} ${classes.arrowTop}`}
      ></div>
    );
  }

  if (direction === 'right') {
    return (
      <div
        id="tooltip"
        className={`${classes.tooltip} ${classes.arrowRight}`}
      ></div>
    );
  }

  if (direction === 'bottom') {
    return (
      <div
        id="tooltip"
        className={`${classes.tooltip} ${classes.arrowBottom}`}
      ></div>
    );
  }

  if (direction === 'left') {
    return (
      <div
        id="tooltip"
        className={`${classes.tooltip} ${classes.arrowLeft}`}
      ></div>
    );
  }

  return <div id="tooltip" className={classes.tooltip}></div>;
};

OpenlayerTooltip.displayName = 'OpenlayerTooltip';
export default OpenlayerTooltip;
