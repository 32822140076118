import { useDataProvider } from 'react-admin';
import { RetrofitData } from '../../../../dataProvider';

export class SerialError extends Error {}

const useVerifySerialNumber = () => {
  const dataProvider = useDataProvider();
  return async (
    targetSerials: string[],
  ): Promise<{ retrofits: RetrofitData[] }> => {
    const { data: retrofits } = await dataProvider.getList<RetrofitData>(
      'retrofits',
      {
        pagination: {
          page: 1,
          perPage: 100, // ただし、シリアル番号 1回での取得を100にする場合は、コントローラ登録で一回でチェックするシリアル番号を件数を絞り込みすることも合わせて検討する
        },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          basicInfoSerialNumber: targetSerials,
        },
      },
    );
    const validSerials = retrofits.map(it => it.basicInfoSerialNumber);
    const invalidSerials = targetSerials.filter(
      (it: string) => !validSerials.includes(it),
    );
    if (invalidSerials.length > 0) {
      throw new SerialError(invalidSerials.join(' , '));
    }
    return { retrofits };
  };
};

export default useVerifySerialNumber;
