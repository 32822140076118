import React, { useEffect } from 'react';
import { BucketData } from '../../dataProvider';

const BucketStateView: React.FC<{
  record?: BucketData;
  onStateChange: (status: string) => void;
}> = ({ record, onStateChange }) => {
  const status = record?.fileStatus ?? '';
  useEffect(() => {
    onStateChange(status);
  }, [status, onStateChange]);
  return <></>;
};

BucketStateView.displayName = 'BucketStateView';
export default BucketStateView;
