import { LandlogappData } from '../../types';

const data: LandlogappData[] = [
  {
    id: 8,
    itemId: 'scpi_jp',
    planId: '324',
    productName: 'SMART CONSTRUCTION Pilot',
  },
  {
    id: 9,
    itemId: 'retrofit_sc-pilot_kcsj',
    planId: '373',
    productName:
      'SMART CONSTRUCTION Pilot KCSJプラン （GNSS補正情報サービス付）',
  },
  {
    id: 10,
    itemId: 'retrofit_sc-pilot_general',
    planId: '375',
    productName:
      'SMART CONSTRUCTION Pilot 一般プラン （GNSS補正情報サービス付）',
  },
];
export default data;
