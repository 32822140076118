import React, { ReactText, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { required, useTranslate } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  BreadcrumbsFiled,
  Loading,
  Create,
  CustomForm,
  LinkButton,
  TextInput,
  SelectInput,
} from '../../../..';
import {
  useCreateInSite,
  useCustomGetOne,
  useGetSelefDetailData,
  useGetUnitLength,
  useUnitLength,
} from '../../../../../hooks';
import { getParentParentPath, blockSize } from '../../../../../utils';
import { styles } from '../../../../../theme';
import { ReturnIcon } from '../../../../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    description: {
      ...styles.multilineText,
    },
    alignRight: {
      ...styles.selectInputRightAligned,
    },
  }),
);

const CancelButton: React.FC<{ basePath?: string }> = props => {
  const { basePath = '' } = props;
  const to = getParentParentPath(basePath);
  return (
    <LinkButton path={to} label="ra.action.cancel">
      <ReturnIcon />
    </LinkButton>
  );
};

const CompactionWorkAreaCreate: React.FC = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const resource = 'compactionWorkAreas';
  const { toDisplayUnitLength, meterToUnitLengthRoundUp } = useUnitLength();
  const getSelefDetailData = useGetSelefDetailData();
  // const redirectTo = useRedirect();
  const { siteId, projectId } = useParams<{
    siteId: string;
    projectId: string;
  }>();
  const { props, site } = useCreateInSite(resource);
  const { data: projectData } = useCustomGetOne(
    'projects',
    projectId as ReactText,
  );
  const {
    data: { unitLength },
  } = useGetUnitLength({ siteId: props.siteId });

  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: {
      fetchedprojectId: string;
      isOwnData: boolean;
    };
  }>({ loading: true, fetched: false });

  const blockSizeChoices = useMemo(
    () =>
      blockSize.map(({ id, name }) => ({
        id,
        name: meterToUnitLengthRoundUp(Number(name), unitLength),
      })),
    [unitLength, meterToUnitLengthRoundUp],
  );

  useEffect(() => {
    if (apiState.fetched && apiState.data?.fetchedprojectId === projectId)
      return;
    setApiState({ loading: true, fetched: true });

    const api = async () => {
      // 自社のプロジェクトであるか判定
      const filter = { siteId };
      const isOwnProjects = await getSelefDetailData(
        'projects',
        filter,
        projectId,
      );

      return {
        fetchedprojectId: projectId,
        isOwnData: !!isOwnProjects,
      };
    };
    api().then(result => {
      setApiState({
        loading: false,
        fetched: true,
        data: result,
      });
    });
  }, [siteId, apiState.fetched, apiState.data, projectId, getSelefDetailData]);

  if (!projectData || !site || apiState.loading || !apiState.data)
    return <Loading />;
  // TODO: 閲覧不可なデータは現場一覧に遷移する機能を有効にする場合、コメントアウトを外す
  // const { isOwnData } = apiState.data;
  // プロジェクトが自社のものでない場合、転圧施工領域登録画面は表示しない
  // if (!isOwnData) redirectTo('/');

  const { basePath } = props as { basePath: string };
  const { name: siteName } = site;
  const { name: projectName } = projectData;
  const blockSizeLabel = translate(
    'admin.label.compactionWorkAreas.blockSize',
    {
      unit: toDisplayUnitLength(unitLength),
    },
  );

  return (
    <Create {...props}>
      <CustomForm
        redirect={getParentParentPath(basePath)}
        title="admin.pages.compactionWorkAreaCreate"
        resource={resource}
        cancelButton={<CancelButton basePath={basePath} />}
        initialValues={{
          projectId: Number(projectId) || 0,
          enable: true,
        }}
      >
        <BreadcrumbsFiled
          breadcrumbs={[
            'resources.sites.name',
            '',
            siteName,
            'resources.projects.name',
            projectName,
          ]}
          label="admin.breadcrumbs.compactionWorkAreaCreate"
        />
        <TextInput resource={resource} source="name" validate={[required()]} />
        <SelectInput
          resource={resource}
          source="blockSize"
          choices={blockSizeChoices}
          validate={[required()]}
          label={blockSizeLabel}
          className={classes.alignRight}
        />
        <TextInput
          resource={resource}
          source="description"
          multiline
          rows={3}
          className={classes.description}
        />
      </CustomForm>
    </Create>
  );
};

CompactionWorkAreaCreate.displayName = 'CompactionWorkAreaCreate';
export default CompactionWorkAreaCreate;
