import React, { useState } from 'react';
import {
  Identifier,
  useTranslate,
  useDeleteMany,
  CRUD_DELETE_MANY,
  useRefresh,
  useNotify,
  useUnselectAll,
} from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { Button, DialogOKCancel } from '..';
import { colors } from '../../theme';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../theme/define';
import { DeleteIcon } from '../../assets';
import { apiErrorHandler } from '../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: colors.button.delete,
      color: colors.text.primaryLight,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        background: colors.button.deleteHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.deleteDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
  }),
);

interface Props {
  realResource?: string;
  label?: string;
  resource?: string;
  selectedIds?: Identifier[];
}

const CustomBulkDeleteButton: React.FC<Props & ButtonProps> = ({
  resource = '',
  realResource = '',
  label = '',
  disabled,
  selectedIds = [],
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const title = translate(`resources.${realResource}.name`);
  const content = translate('admin.message.bulkDeleteConfirmContent', {
    title,
  });
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [deleteMany] = useDeleteMany(resource, selectedIds, {
    action: CRUD_DELETE_MANY,
    onSuccess: () => {
      refresh();
      notify('ra.notification.deleted', 'info', {
        smart_count: selectedIds.length,
      });
      unselectAll(resource);
    },
    onFailure: error => {
      notify(apiErrorHandler(error), 'warning');
      setOpen(false);
    },
  });
  return (
    <>
      <DialogOKCancel
        open={open}
        title=""
        message={content}
        nonTranslateMessage={true}
        onClose={() => setOpen(false)}
        onOKClick={deleteMany}
        isDelete={true}
      />
      <Button
        className={classes.root}
        data-testid={label}
        disabled={disabled}
        onClick={() => setOpen(true)}
      >
        <DeleteIcon />
      </Button>
    </>
  );
};

CustomBulkDeleteButton.displayName = 'CustomBulkDeleteButton';
export default CustomBulkDeleteButton;
