import {
  MapProps,
  createProjectSourceLocationPointEPSG3857,
} from '../../../../../hooks';

const addProjectSourceLocationPoint = (
  { map }: MapProps,
  versionId: number,
  token: string,
  visibility?: boolean,
) =>
  map.addLayer(
    createProjectSourceLocationPointEPSG3857(versionId, token, visibility),
  );

export default addProjectSourceLocationPoint;
