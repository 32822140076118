import { DataProvider } from 'react-admin';
import { getRestDataProvider } from '..';
import getAsbuiltDownloadFiles from './getAsbuiltDownloadFiles';

export default (): DataProvider => {
  return {
    getList: (): any => {
      throw new Error('Not supported for asbuiltDownloadFiles');
    },
    getOne: () => {
      throw new Error('Not supported for asbuiltDownloadFiles');
    },
    getMany: () => {
      throw new Error('Not supported for asbuiltDownloadFiles');
    },
    getManyReference: () => {
      throw new Error('Not supported for asbuiltDownloadFiles');
    },
    update: () => {
      throw new Error('Not supported for asbuiltDownloadFiles');
    },
    updateMany: () => {
      throw new Error('Not supported for asbuiltDownloadFiles');
    },
    create: (_, params): any => {
      return getAsbuiltDownloadFiles(getRestDataProvider(), params);
    },
    delete: () => {
      throw new Error('Not supported for asbuiltDownloadFiles');
    },
    deleteMany: () => {
      throw new Error('Not supported for asbuiltDownloadFiles');
    },
  };
};
