import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { isUndefined } from 'lodash';

export const CustomToolbarButtonType = {
  delete: 'delete',
  action: 'action',
  action2: 'action2',
  cancel: 'cancel',
  save: 'save',
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },
    child: {
      marginLeft: 12,
    },
    cancelButton: {
      marginLeft: 12,
      pointerEvents: 'auto', // キャンセルボタンのみ <LoadingView/> による非活性の対象外とする
    },
  }),
);

interface Props {
  saveButton: React.ReactElement<any> | boolean;
  deleteButton: React.ReactElement<any> | boolean;
  cancelButton: React.ReactElement<any> | boolean;
  actionButton: React.ReactElement<any> | boolean;
  action2Button: React.ReactElement<any> | boolean;
}

const CustomToolbar: React.FC<Partial<Props & { [key: string]: any }>> = ({
  saveButton,
  deleteButton,
  cancelButton,
  actionButton,
  action2Button,
  basePath,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  pristine,
  record,
  resource,
  redirect,
  saving,
  submitOnEnter,
  form,
  buttonOrder = [
    CustomToolbarButtonType.delete,
    CustomToolbarButtonType.action2,
    CustomToolbarButtonType.action,
    CustomToolbarButtonType.cancel,
    CustomToolbarButtonType.save,
  ],
}) => {
  const classes = useStyles();
  const elements = buttonOrder.map((it: string) => {
    switch (it) {
      case CustomToolbarButtonType.delete:
        return (
          <React.Fragment key={CustomToolbarButtonType.delete}>
            {deleteButton && record && !isUndefined(record.id) && (
              <div className={classes.child}>
                {React.isValidElement(deleteButton)
                  ? React.cloneElement(deleteButton, {
                      basePath,
                      record,
                      resource,
                    })
                  : null}
              </div>
            )}
          </React.Fragment>
        );
      case CustomToolbarButtonType.action:
        return (
          <React.Fragment key={CustomToolbarButtonType.action}>
            {actionButton && record && !isUndefined(record.id) && (
              <div className={classes.child}>
                {React.isValidElement(actionButton)
                  ? React.cloneElement(actionButton, {
                      basePath,
                      record,
                      resource,
                    })
                  : null}
              </div>
            )}
          </React.Fragment>
        );
      case CustomToolbarButtonType.action2:
        return (
          <React.Fragment key={CustomToolbarButtonType.action2}>
            {action2Button && record && !isUndefined(record.id) && (
              <div className={classes.child}>
                {React.isValidElement(action2Button)
                  ? React.cloneElement(action2Button, {
                      basePath,
                      record,
                      resource,
                    })
                  : null}
              </div>
            )}
          </React.Fragment>
        );
      case CustomToolbarButtonType.cancel:
        return (
          <React.Fragment key={CustomToolbarButtonType.cancel}>
            {cancelButton && (
              <div className={classes.cancelButton}>
                {React.isValidElement(cancelButton)
                  ? React.cloneElement(cancelButton, {
                      path: basePath,
                    })
                  : null}
              </div>
            )}
          </React.Fragment>
        );
      case CustomToolbarButtonType.save:
        return (
          <React.Fragment key={CustomToolbarButtonType.save}>
            {saveButton && (
              <div className={classes.child}>
                {React.isValidElement(saveButton)
                  ? React.cloneElement(saveButton, {
                      basePath, // NOTE: 保存時の画面遷移で利用したい為
                      handleSubmitWithRedirect:
                        handleSubmitWithRedirect || handleSubmit,
                      invalid,
                      redirect,
                      saving,
                      submitOnEnter,
                      disabled: pristine || invalid,
                      record,
                      form,
                      pristine,
                    })
                  : null}
              </div>
            )}
          </React.Fragment>
        );
      default:
    }
    return <></>;
  });

  return <div className={classes.root}>{elements}</div>;
};

export default CustomToolbar;
