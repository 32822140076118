import { Vector as SourceVector } from 'ol/source';
import Polygon from 'ol/geom/Polygon';
import { featureToLonLatArray } from '../../../hooks';
import {
  checkAreaZeroByArray,
  checkIntersectByArray,
  checkSameValueByArray,
} from './utils';

export type Coordinates = {
  lon: number;
  lat: number;
};

class TaskMapModel {
  sourceVector: SourceVector | null;

  modified: boolean;

  constructor() {
    this.sourceVector = null;
    this.modified = false;
  }

  setModified() {
    this.modified = true;
  }

  isModified(): boolean {
    return this.modified;
  }

  setSourceVector(sourceVector: SourceVector) {
    this.sourceVector = sourceVector;
  }

  checkIntersect(): boolean {
    if (!this.sourceVector || this.sourceVector.getFeatures().length <= 0) {
      return false;
    }
    const features = this.sourceVector.getFeatures()[0];
    const geometry = features.getGeometry() as Polygon;
    const [coordinates] = geometry.getCoordinates();
    return checkIntersectByArray(coordinates);
  }

  checkSameValue(): boolean {
    if (!this.sourceVector || this.sourceVector.getFeatures().length <= 0) {
      return false;
    }
    const features = this.sourceVector.getFeatures();
    const lonLatArray =
      features.length > 0
        ? featureToLonLatArray(this.sourceVector.getFeatures()[0]).map(
            ({ lon, lat }) => [lon, lat],
          )
        : [];
    return checkSameValueByArray(lonLatArray);
  }

  checkAreaZero(coordinates: number[][]): boolean {
    if (!this.sourceVector || this.sourceVector.getFeatures().length <= 0) {
      return false;
    }
    return checkAreaZeroByArray(coordinates);
  }

  getCoordinates(): Coordinates[] {
    if (!this.sourceVector) return [];
    const features = this.sourceVector.getFeatures();
    const lonLatArray =
      features.length > 0
        ? featureToLonLatArray(this.sourceVector.getFeatures()[0])
        : [];

    // 末尾に先頭要素を追加する対応
    const newlonLatArray =
      lonLatArray.length > 0 ? [...lonLatArray, lonLatArray[0]] : [];

    return newlonLatArray.map(({ lon, lat }) => ({ lon, lat }));
  }
}

export default TaskMapModel;
