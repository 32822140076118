import React from 'react';
import { SelectInput } from '..';

interface Props {
  removeCorporationId: string;
  validate: any;
  disabled: boolean;
  label: string;
  source: string;
  resource: string;
  optionText: string;
  choices: { id: string; name: string }[];
}

const FilterCorporationSelectInput: React.FC<Partial<Props>> = ({
  removeCorporationId,
  ...props
}) => {
  const choices = props.choices || [];
  const filterChoices = removeCorporationId
    ? choices.filter((corporation: { id: string; name: string }) => {
        return corporation.id !== removeCorporationId;
      })
    : choices;
  const newProps = { ...props, choices: filterChoices };
  return <SelectInput {...newProps} />;
};

FilterCorporationSelectInput.displayName = 'FilterCorporationSelectInput';
export default FilterCorporationSelectInput;
