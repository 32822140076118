import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import useResource from './useResource';

interface InParams {
  retrofitIds: number[];
}

interface OutParams {
  data: {
    isPendingTransfer: boolean;
    pendingRetrofitIds: number[];
  };
}

const usePendingTransferred = () => {
  const dataProvider = useDataProvider();
  const resource = useResource('assignments');

  return useCallback(
    async ({ retrofitIds }: InParams): Promise<OutParams> => {
      const { data: transferData } = await dataProvider.getList(resource, {
        pagination: {
          page: 1,
          perPage: retrofitIds.length, // 登録件数分データを取得
        },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          retrofitId: retrofitIds,
          isApproved: false,
        },
      });

      return {
        data: {
          isPendingTransfer: transferData.length > 0,
          pendingRetrofitIds: transferData.map(({ retrofitId }) => retrofitId),
        },
      };
    },
    [dataProvider, resource],
  );
};

export default usePendingTransferred;
