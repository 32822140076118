import React, { useCallback } from 'react';
import { omit } from 'lodash';
import {
  useInput,
  FieldTitle,
  InputProps,
  resolveBrowserLocale,
} from 'ra-core';
import { InputHelperText } from 'ra-ui-materialui';
import {
  KeyboardDatePicker,
  DatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { enUS, ja } from 'date-fns/locale';
import { Locale } from 'date-fns';
import { makeStyles, createStyles } from '@material-ui/core';
import { ResourceFC } from '..';

const useStyles = makeStyles(() =>
  createStyles({
    picker: {
      '& .MuiInputBase-input': {
        minWidth: 244,
        padding: 4,
      },
      '& .MuiFormHelperText-root': {
        marginLeft: 14,
        marginRight: 14,
      },
    },
  }),
);
// ブラウザの言語設定を元にLocaleを設定
const localeMap: { [key: string]: Locale } = { ja };
const getDateLocale = (): Locale => {
  const browserLocale = resolveBrowserLocale();
  if (localeMap[browserLocale]) return localeMap[browserLocale];
  return enUS;
};

const getDateFormatLocale = (): string => {
  const baseYear = '2000';
  const baseMonth = '01';
  const baseDate = '02';
  const localeBaseDateTime = new Date(
    `${baseYear}/${baseMonth}/${baseDate}`,
  ).toLocaleDateString();
  return localeBaseDateTime
    .replace(baseYear, 'yyyy')
    .replace(baseMonth, 'MM')
    .replace(baseDate, 'dd')
    .replace(`${Number(baseMonth)}`, 'M') // 月の単位が一桁時に0埋めなしの場合は M に置換
    .replace(`${Number(baseDate)}`, 'd'); // 日の単位が一桁時に0埋めなしの場合は d に置換
};

type Props = InputProps<DatePickerProps> & { minDate?: Date; maxDate?: Date };

const DatePickerInput: React.FC<Props> = ({
  format,
  label,
  source,
  resource,
  helperText,
  margin = 'dense',
  onBlur,
  onChange,
  onFocus,
  parse,
  validate,
  variant = 'filled',
  autoComplete = 'off',
  form,
  minDate,
  maxDate,
  ...rest
}) => {
  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  });
  const providerOptions = {
    utils: DateFnsUtils,
    locale: getDateLocale(),
  };
  const handleChange = useCallback(
    (value: Date | null) => {
      let isMaxDateOver = false;
      let isMinDateBelow = false;
      if (value) {
        // 指定時のみ範囲チェックを行う
        if (maxDate) isMaxDateOver = value > maxDate;
        if (minDate) isMinDateBelow = value < minDate;
      }
      const validDate = value ? value.toString() !== 'Invalid Date' : false;
      if (!validDate) return;

      if (isMaxDateOver) {
        // maxDate より 未来 の場合、maxDate で丸める
        input.onChange(maxDate?.toISOString());
      } else if (isMinDateBelow) {
        // minDate より 過去 の場合、minDate で丸める
        input.onChange(minDate?.toISOString());
      } else {
        // 通常時
        input.onChange(value?.toISOString());
      }
    },
    [input, maxDate, minDate],
  );
  const classes = useStyles();

  const datetimeFormat = getDateFormatLocale();

  return (
    <div className={classes.picker}>
      <MuiPickersUtilsProvider {...providerOptions}>
        <KeyboardDatePicker
          id={id}
          {...input}
          autoComplete={autoComplete}
          onClose={() => {
            form.blur(input.name);
          }}
          minDate={minDate}
          maxDate={maxDate}
          variant={variant}
          margin={margin}
          error={!!(touched && error)}
          helperText={
            <InputHelperText
              touched={touched}
              error={error}
              helperText={helperText}
            />
          }
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          }
          InputLabelProps={{
            shrink: true,
          }}
          value={input.value ? new Date(input.value) : null}
          onChange={date => handleChange(date)}
          format={datetimeFormat}
          {...omit(rest, 'basePath')}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

DatePickerInput.displayName = 'DatePickerInput';
export default DatePickerInput as ResourceFC<Props>;
