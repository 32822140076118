import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  TextField,
  CustomDateField,
  Datagrid,
  IconField,
  SiteListImageField,
  DatagridProps,
} from '..';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 48,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 272,
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 200,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 80,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 89,
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 89,
          },
        },
      },
    },
  }),
);

const SiteDatagrid: React.FC<Partial<DatagridProps>> = props => {
  const classes = useStyles();

  return (
    <Datagrid {...props} rowClick="show" className={classes.root}>
      <SiteListImageField resource="sites" source="photoUrl" label="" />
      <TextField resource="sites" source="name" />
      <TextField resource="sites" source="corporationName" sortable={false} />
      <IconField resource="sites" source="status" sortable={false} />
      <CustomDateField
        resource="sites"
        source="workPeriodStartDate"
        sortable={false}
      />
      <CustomDateField
        resource="sites"
        source="workPeriodEndDate"
        sortable={false}
      />
    </Datagrid>
  );
};

SiteDatagrid.displayName = 'SiteDatagrid';

export default SiteDatagrid;
