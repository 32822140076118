import React, { FC } from 'react';
import { Loading, Show, TextField } from 'react-admin';
import {
  BreadcrumbsFiled,
  CustomDateField,
  CustomForm,
  ReferenceField,
} from '../../..';
import { RetrofitAlternateRegistData } from '../../../../dataProvider';
import { useResource } from '../../../../hooks';

const AlternateRegistShow: FC<{
  props: any;
  retrofitAlternateRegistData: RetrofitAlternateRegistData | undefined;
}> = ({ props, retrofitAlternateRegistData }) => {
  const resource = useResource('retrofitAlternateRegists');

  if (!retrofitAlternateRegistData) {
    return <Loading />;
  }

  return (
    <>
      <Show {...props}>
        <CustomForm
          title="admin.pages.retrofitAlternateRegistEdit"
          saveButton={false}
          deleteButton={false}
        >
          <BreadcrumbsFiled
            breadcrumbs={['resources.retrofitAlternateRegists.name']}
            label="admin.breadcrumbs.retrofitAlternateRegistEdit"
          />
          <TextField resource={resource} source="toCorporationName" />
          <TextField
            resource={resource}
            source="toMailAddress"
            label="resources.retrofitAlternateRegists.fields.email"
          />
          <ReferenceField
            resource={resource}
            source="retrofitId"
            reference="retrofits"
            link=""
            label="resources.retrofits.fields.basicInfoSerialNumber"
          >
            <TextField resource="retrofits" source="basicInfoSerialNumber" />
          </ReferenceField>
          <CustomDateField
            resource={resource}
            source="actualDate"
            label="resources.retrofitAlternateRegists.fields.actualDate"
          />
        </CustomForm>
      </Show>
    </>
  );
};

AlternateRegistShow.displayName = 'AlternateRegistShow';
export default AlternateRegistShow;
