import { AdminApiConverter, ApiFilter } from '..';

const converter: AdminApiConverter<'extensionarms', 'extensionarms'> = {
  resource: 'extensionarms',
  defaultSort: { field: 'lastUpdated', order: 'DESC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'extensionarms'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('extensionarmType' in filter)
      throw new Error('Unsupported filter field: extensionarmType');
    if ('name' in filter) apiFilter.name = filter.name;
    if ('corporationId' in filter)
      apiFilter.corporationId = filter.corporationId;
    if ('siteId' in filter) apiFilter.siteId = filter.siteId;
    if ('lastUpdated' in filter) apiFilter.updateDate = filter.lastUpdated;
    return apiFilter;
  },
  sortFieldToApi: field => {
    if (field === 'lastUpdated') return 'updateDate';
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(record => ({
        id: record.id || 0,
        extensionarmType: record.extensionarmType,
        name: record.name,
        corporationId: record.corporationId || '',
        siteId: record.siteId === '' ? undefined : record.siteId,
        extensionarmFileId: record.extensionarmFileId,
        extensionarmFile: {
          id: record.extensionarmFile?.id || '',
          src: record.extensionarmFile?.url || '',
          title: record.extensionarmFile?.name || '',
        },
        lastUpdated: record.updateDate || '',
      })),
    ),
  recordToApi: record => ({
    name: record.name,
    corporationId: record.corporationId,
    siteId: record.siteId,
    extensionarmFileId: record.extensionarmFileId,
  }),
  embed: ['extensionarmFile'],
};

export default converter;
