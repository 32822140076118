import { AdminApiConverter } from '..';
import { CompactionLayerData } from '../../types';

const converter: AdminApiConverter<'compactionLayers', 'compactionLayers'> = {
  resource: 'compactionLayers',
  defaultSort: { field: 'name', order: 'ASC' },
  filterToApi: filter => filter,
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(
        (record): CompactionLayerData => ({
          id: record.id || 0,
          compactionWorkAreaId: record.compactionWorkAreaId,
          layerNo: record.layerNo,
          name: record.name || '',
          thickness: record.thickness,
          passCount: record.passCount,
          compactionMaterialId: record.compactionMaterialId,
          description: record.description,
          inUse: record.inUse,
          enable: record.enable,
        }),
      ),
    ),
  recordToApi: record => ({
    compactionWorkAreaId: record.compactionWorkAreaId,
    layerNo: record.layerNo,
    name: record.name,
    thickness: record.thickness,
    passCount: record.passCount,
    compactionMaterialId: record.compactionMaterialId,
    description: record.description,
    inUse: record.inUse,
    enable: record.enable,
  }),
};

export default converter;
