import { getDataProvider, LandlogappData } from '../dataProvider';

let landlogapps: LandlogappData[];

export default async () => {
  if (!landlogapps) {
    const dataProvider = getDataProvider();
    const { data } = await dataProvider.getList('landlogapps', {
      filter: {},
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 6000 },
    });
    landlogapps = data as LandlogappData[];
  }
  return landlogapps;
};
