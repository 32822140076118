import { useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { userInfoStore } from '../auth';
import { config, agreementFetchableStore, agreementCacheStore } from '../utils';
import { LicenseAgreementData } from '../dataProvider';

/**
 * LocalStorageに同意フラグを格納する
 * @example
 *
 * // 同意しているかを取得する
 * const { getAgreement } = useAgreementStore();
 * const agreement = getAgreement();
 *
 * // 同意フラグをセットする
 * const { setAgreement } = useAgreementStore();
 * setAgreement()
 *
 */
const useAgreementStore = () => {
  const dataProvider = useDataProvider();
  const resource = 'licenseAgreements';
  useEffect(() => {
    if (!config.api.landlog.useFake) {
      return;
    }
    // NOTE: fake時は、fakeのDataProviderからアカウント情報を取得し、LocalStorageに格納する
    dataProvider.getOne('accounts', { id: 1 }).then(result => {
      if (!result) {
        return;
      }
      userInfoStore.set({
        sub: '',
        name: '',
        givenName: '',
        familyName: '',
        email: result.data.email,
        emailVerified: true,
      });
    });
  }, [dataProvider]);

  const getLicenseAgreement = async () => {
    const email = userInfoStore.get()?.email;
    if (email) {
      const { data: licenseAgreements } = await dataProvider.getList(resource, {
        filter: { email },
        sort: { field: 'id', order: 'ASC' },
        pagination: { page: 1, perPage: 1 },
      });
      // レコードが存在する場合、true
      const result = licenseAgreements && licenseAgreements.length > 0;
      agreementFetchableStore.set(false);
      agreementCacheStore.set(result);
      return result;
    }
    agreementFetchableStore.set(false);
    return false;
  };

  return {
    async fetchLicenseAgreement(): Promise<LicenseAgreementData | undefined> {
      const email = userInfoStore.get()?.email || '';
      const { data } = await dataProvider.getList<LicenseAgreementData>(
        'licenseAgreements',
        {
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'id', order: 'ASC' },
          filter: { email },
        },
      );
      if (!data || data.length <= 0) return undefined;
      return data[0];
    },
    async getAgreement(): Promise<boolean> {
      const fetchable = agreementFetchableStore.get();
      if (!fetchable) {
        return agreementCacheStore.get();
      }
      return getLicenseAgreement();
    },
    setAgreement: async (termsConditionId: number) => {
      const email = userInfoStore.get()?.email;
      if (email) {
        await dataProvider.create(resource, {
          data: { email, agreement: true, termsConditionId },
        });
        agreementFetchableStore.set(true);
      }
    },
  };
};

export default useAgreementStore;
