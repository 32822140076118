import React, { FC, useEffect } from 'react';
import { useRedirect } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { CustomForm, Loading, BreadcrumbsFiled, CustomEdit } from '../..';
import { findRecordById, LonLat } from '../../../utils';
import { useGeofenceProps, useConvertMapCoordinate } from '../geofences/hooks';
import useAlertMapController from './hooks/useAlertMapController';
import { getGeometriesArray } from '../geofences/utils';
import { GeofenceData } from '../../../dataProvider';
import GeofenceAlertMapShow from './view/GeofenceAlertMapShow';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& > div .ra-input-mapInput > div > label': {
        display: 'none',
      },
    },
  }),
);

const GeofenceAlertShow: FC = () => {
  const classes = useStyles();
  const { id, siteId } = useParams<{ id: string; siteId: string }>();
  const {
    props,
    loading,
    sites,
    unitLength,
    geofences,
    isOwnData,
    geofenceAlert,
    mapBackgroundColor,
  } = useGeofenceProps(id);
  const geofenceId = geofences.length > 0 ? geofences[0].id : 0;
  const convertMapCoordinate = useConvertMapCoordinate();
  const { mapController } = useAlertMapController();
  const redirectTo = useRedirect();

  const depsGeofences = JSON.stringify(geofences);

  useEffect(() => {
    if (!isOwnData) return;
    const api = async (targetGeofenceId: number) => {
      if (depsGeofences !== '[]') {
        const rawGeofences: GeofenceData[] = JSON.parse(depsGeofences);
        const lonLatId = rawGeofences
          .filter(geofence => geofence.points !== undefined)
          .map(
            geofence =>
              ({
                id: geofence.id,
                coordinates: geofence.points?.map(lonLats => ({
                  lon: lonLats[0],
                  lat: lonLats[1],
                })),
              } as { id: number; coordinates: LonLat[] }),
          );

        mapController.setSiteUnitLength(unitLength);

        // ジオメトリ配列に変換
        const { geometries, boundaryLonLat } = getGeometriesArray(
          lonLatId,
          rawGeofences,
          targetGeofenceId,
        );
        // setGeometriesの前に全ジオフェンスオブジェクトのtypeのdictionaryを作成
        geometries.forEach(it => {
          mapController.getGeofenceTypeList().set(it.id, it.type);
        });
        // マップにジオフェンス情報を反映
        mapController.setGeometries(geometries);

        // ジオフェンス情報が存在する場合は、boudary を自動調整
        if (boundaryLonLat) {
          mapController.setBoundary(boundaryLonLat);
          mapController.navigateToHome(0);
        }

        // 各種初期値の設定
        const geofenceData = findRecordById<GeofenceData>(
          rawGeofences,
          targetGeofenceId,
        );
        if (geofenceData) {
          const { alertType, id: featureId } = geofenceData;
          mapController.setSelectedAlertype(alertType);
          mapController.setSelectFeature(featureId);
        }
      }

      // マップにジオフェンスアラート通知情報を反映
      mapController.setAlert(geofenceAlert);
    };
    api(Number(geofenceId));
  }, [
    depsGeofences,
    convertMapCoordinate,
    mapController,
    geofenceId,
    isOwnData,
    unitLength,
    geofenceAlert,
  ]);

  if (loading || !sites) return <Loading />;

  // 一覧に存在しないgeofenceのデータは表示させない
  if (!isOwnData) redirectTo('/');

  const {
    record: { name: siteName },
  } = sites;

  return (
    <CustomEdit {...props} resource="geofenceAlerts">
      <CustomForm
        className={classes.root}
        title="admin.pages.geofenceAlertShow"
        saveButton={false}
        deleteButton={false}
      >
        <BreadcrumbsFiled
          breadcrumbs={[
            'resources.sites.name',
            '',
            siteName,
            'resources.geofences.name',
            'admin.breadcrumbs.geofenceAlertList',
          ]}
          label="admin.breadcrumbs.geofenceAlertShow"
        />
        <GeofenceAlertMapShow
          source="mapInput"
          siteId={siteId}
          geofenceId={geofenceId}
          mapController={mapController}
          mapBackgroundColor={mapBackgroundColor}
          visible={true}
          record={geofenceAlert}
        />
      </CustomForm>
    </CustomEdit>
  );
};

GeofenceAlertShow.displayName = 'GeofenceAlertShow';
export default GeofenceAlertShow;
