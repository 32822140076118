import React, { useState, useCallback } from 'react';
import { ReactNodeLike } from 'prop-types';
import {
  Identifier,
  useTranslate,
  useRedirect,
  useGetList,
  GetListParams,
  ResourceContextProvider,
} from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import { Button } from '../../../index';
import {
  Datagrid,
  Typography,
  CreateButton,
  BulkActionConfirm,
  NormalButton,
} from '../../..';
import { listResultToDatagridData } from '../../../../utils';
import { colors } from '../../../../theme';
import { ProjectsData } from '../../../../dataProvider';
import { ConfirmMessage } from '../../../organisms';
import { AddIcon, DeleteIcon } from '../../../../assets';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../../../theme/define';

const useStyles = (override: object | undefined = undefined) => {
  return makeStyles(() =>
    createStyles({
      root: {
        paddingLeft: 64,
        paddingRight: 16,
        border: `2px solid ${colors.line}`,
      },
      datagrid: {
        // use ovrride
        ...override,
      },
      topActions: {
        marginTop: 16,
        marginBottom: 0,
        display: 'flex',
        '& p': {
          margin: 'auto 0',
        },
        '& a': {
          marginLeft: 'auto',
          maxHeight: 36,
        },
        '& button': {
          marginLeft: 'auto',
          maxHeight: 36,
        },
        '& .icon': {
          position: 'relative',
          left: -55,
          width: 0,
        },
      },
      bottomActions: {
        marginTop: 0,
        marginBottom: 16,
      },
    }),
  )();
};

const useDeleteButtonStyles = makeStyles(() =>
  createStyles({
    button: {
      background: colors.button.delete,
      color: colors.text.primaryLight,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        background: colors.button.deleteHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.deleteDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
  }),
);

type SVG = React.SVGAttributes<SVGElement>;
interface TopProps {
  resource: string;
  projectId: Identifier;
  basePath: string;
  icon: SVG;
  disabled?: boolean;
  addClickEnable: boolean;
  onAddClick?: () => void;
}

const TopActions: React.FC<TopProps> = ({
  resource,
  projectId,
  basePath,
  icon,
  disabled,
  addClickEnable,
  onAddClick,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <div className={classes.topActions}>
      <div className="icon">{icon}</div>
      <Typography>{translate(`resources.${resource}.name`)}</Typography>
      {addClickEnable ? (
        <NormalButton
          label="ra.action.create"
          onClick={onAddClick}
          disabled={disabled}
        >
          <AddIcon />
        </NormalButton>
      ) : (
        <CreateButton
          basePath={`${basePath}/${projectId}/${resource}`}
          label="ra.action.create"
          disabled={disabled}
        >
          <AddIcon />
        </CreateButton>
      )}
    </div>
  );
};

const BottomActions: React.FC<{
  disabled: boolean;
  onClick: () => void;
}> = ({ disabled, onClick }) => {
  const classes = useStyles();
  const deleteButtonClasses = useDeleteButtonStyles();
  return (
    <div className={classes.bottomActions}>
      <Button
        data-testid="ra.action.delete"
        onClick={onClick}
        disabled={disabled}
        className={deleteButtonClasses.button}
      >
        <DeleteIcon />
      </Button>
    </div>
  );
};

interface RaProps {
  id: Identifier;
  record: ProjectsData;
  basePath: string;
}

interface Props {
  gridClasses: object; // Gridの列幅スタイルを親コンポーネント側で指定可能にする
  children?: ReactNodeLike; // GridのField定義
  params: GetListParams;
  resource: string;
  icon: SVG;
  bulkActionMessage: ConfirmMessage;
  onBulkAction: (resource: string, selectedIds: string[]) => void;
  onDeleteClick?: (
    resource: string,
    selectedIds: string[],
    selectedItems: any[],
  ) => void;
  onConfirmHide?: () => void;
  defaultConfirmOpen?: boolean;
  creatable: boolean;
  addClickEnable?: boolean;
  onAddClick?: () => void;
}

const SiteProjectExpandList: React.FC<Props & Partial<RaProps>> = ({
  // RaProps
  id,
  record,
  basePath,
  // Props
  gridClasses,
  children,
  params,
  resource,
  icon,
  bulkActionMessage,
  onBulkAction,
  onDeleteClick,
  onConfirmHide,
  defaultConfirmOpen = false,
  creatable,
  addClickEnable = false,
  onAddClick,
}) => {
  const projectId = id;
  if (!projectId) throw Error('Invalid Project ID');
  if (!record) throw Error('Invalid record');
  if (!basePath) throw Error('Invalid basePath');

  const classes = useStyles(gridClasses);
  const redirectTo = useRedirect();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { data, error, loading } = useGetList(
    resource,
    params.pagination,
    params.sort,
    params.filter,
  );
  const dataList = Object.values(data || {});
  const gridData = listResultToDatagridData(dataList);

  const gridProps = {
    resource,
    error,
    loading,
    hasBulkActions: true,
    currentSort: params.sort,
    data: gridData.data,
    ids: gridData.ids,
    selectedIds,
    onToggleItem: (index: string) => {
      const newState = selectedIds.includes(index)
        ? selectedIds.filter(it => it !== index)
        : [...selectedIds, index];
      setSelectedIds(newState);
    },
  };
  const selectedItems =
    dataList.length > 0 ? selectedIds.map(it => gridData.data[it]) : [];
  const selectedItemIds = selectedItems.map(it => it.id);
  const deleteDisabled = selectedIds.length <= 0;

  const handleDefaultDelete = useCallback(() => {
    if (onDeleteClick) {
      onDeleteClick(resource, selectedItemIds, selectedItems);
      return;
    }
    setConfirmOpen(true);
  }, [setConfirmOpen, onDeleteClick, resource, selectedItemIds, selectedItems]);

  const handleConfirmClose = useCallback(() => {
    if (onConfirmHide) {
      onConfirmHide();
    }
    setConfirmOpen(false);
  }, [onConfirmHide]);

  return (
    <div className={classes.root}>
      <BulkActionConfirm
        resource={resource}
        selectedIds={selectedItemIds}
        open={confirmOpen || defaultConfirmOpen}
        message={bulkActionMessage}
        onClose={handleConfirmClose}
        onBulkAction={onBulkAction}
        isDelete={true}
      />
      <TopActions
        resource={resource}
        projectId={projectId}
        basePath={basePath}
        icon={icon}
        disabled={!creatable}
        addClickEnable={addClickEnable}
        onAddClick={onAddClick}
      />
      <ResourceContextProvider value={resource}>
        <Datagrid
          {...gridProps}
          className={classes.datagrid}
          rowClick={(targetId: Identifier) => {
            redirectTo(`${basePath}/${projectId}/${resource}/${targetId}`);
          }}
        >
          {children}
        </Datagrid>
      </ResourceContextProvider>
      <BottomActions disabled={deleteDisabled} onClick={handleDefaultDelete} />
    </div>
  );
};

SiteProjectExpandList.displayName = 'SiteProjectExpandList';
export default SiteProjectExpandList;
