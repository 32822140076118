import React from 'react';
import {
  LinkButton,
  GnssSettingAdminList,
  GnssSettingOwnerList,
  Loading,
} from '../..';
import { useGetUserRole } from '../../../hooks';
import { AddIcon } from '../../../assets';

const Actions: React.FC<{
  basePath?: string;
}> = ({ basePath }) => (
  <LinkButton path={`${basePath}/create`} label="admin.actions.add">
    <AddIcon />
  </LinkButton>
);

interface Props {
  basePath: string;
}

const GnssSettingList: React.FC = props => {
  const { basePath } = props as Props;
  const userRole = useGetUserRole();
  if (userRole.isLoading()) {
    return <Loading />;
  }
  return userRole.isAdmin() ? (
    <GnssSettingAdminList
      {...props}
      addAction={<Actions basePath={basePath} />}
    />
  ) : (
    <GnssSettingOwnerList
      {...props}
      addAction={<Actions basePath={basePath} />}
    />
  );
};

GnssSettingList.displayName = 'GnssSettingList';
export default GnssSettingList;
