import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button } from '../..';
import { styles } from '../../../theme';
import { ArrayInputDeleteIcon } from '../../../assets/images';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      ...styles.buttonArrayInputDelete,
    },
  }),
);

const ArrayInputDeleteButton: React.FC<{
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Button onClick={onClick} className={classes.root}>
      <ArrayInputDeleteIcon />
    </Button>
  );
};

ArrayInputDeleteButton.displayName = 'ArrayInputDeleteButton';
export default ArrayInputDeleteButton;
