import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { RetrofitOwnerRegistCorporationData } from '../../../../../dataProvider';

interface InParams {
  corporationId: string;
}

interface OutParams {
  isErrorCorporation: boolean;
}

const useRetrofitCorporationCheck = () => {
  const dataProvider = useDataProvider();

  return useCallback(
    async ({ corporationId }: InParams): Promise<OutParams> => {
      // センドバック先建機が登録可能な場合、1件データを返す
      const { data: checkOwnerRegistCorporation } =
        await dataProvider.getList<RetrofitOwnerRegistCorporationData>(
          'retrofitOwnerRegistCorporations',
          {
            filter: { corporationId },
            pagination: { page: 1, perPage: 1 }, // 1件しか取る必要がない
            sort: { field: 'id', order: 'ASC' },
          },
        );

      // 登録先のコントローラの利用中企業がコントローラ権限企業に含まれない場合、
      // trueを返して登録不可
      const isErrorCorporation = checkOwnerRegistCorporation.length === 0;
      return {
        isErrorCorporation,
      };
    },
    [dataProvider],
  );
};

export default useRetrofitCorporationCheck;
