import React from 'react';
import { MapProps, crearteOverlayControl } from '../../../../../hooks';
import { MapColorPicker } from '../view';

const addColerControl = (
  { map }: MapProps,
  onHandleChangeComplete: (color: any) => void,
  rgbR?: number,
  rgbG?: number,
  rgbB?: number,
) => {
  map.addControl(
    crearteOverlayControl(
      'map_color',
      <MapColorPicker
        onHandleChangeComplete={onHandleChangeComplete}
        rgbR={rgbR}
        rgbG={rgbG}
        rgbB={rgbB}
      />,
      {
        left: 386,
        top: 350,
      },
    ),
  );
};

export default addColerControl;
