import React, { FC, ReactElement } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import Button from '../../bases/button/Button';
import { useBackTo } from '../../../hooks';
import { colors } from '../../../theme';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../../theme/define';

interface Props {
  label: string;
  children?: ReactElement;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: colors.button.cancel,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.saveDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
  }),
);

const BackButton: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { gotoBack } = useBackTo();
  const { children, label } = props;
  return (
    <Button
      className={classes.root}
      label=""
      data-testid={label}
      onClick={() => {
        gotoBack();
      }}
    >
      {children}
    </Button>
  );
};

BackButton.displayName = 'BackButton';
export default BackButton;
