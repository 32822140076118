import React, { FC } from 'react';
import { Identifier } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CustomList,
  Datagrid,
  SiteSearchInput,
  Filter,
  TextField,
  CustomDateField,
  ReferenceField,
  Loading,
  ExtensionarmTypeField,
} from '../..';
import { useGetAccount, useJumpTo } from '../../../hooks';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 16,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 80,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 200,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 180,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 200,
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 112,
          },
        },
      },
    },
    columnFilter: {
      marginTop: 0,
      marginBottom: 0,
    },
  }),
);

const ListFilter: FC = props => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <SiteSearchInput className={classes.columnFilter} source="q" alwaysOn />
    </Filter>
  );
};

const ExtensionarmList: FC = props => {
  const classes = useStyles();
  const { gotoListEditRoutingTabbed } = useJumpTo();
  const { pathname } = useLocation();
  const { getCorporationId, isLoading } = useGetAccount();
  const corporationId = getCorporationId();
  if (isLoading() || corporationId === '') return <Loading />;
  return (
    <CustomList
      {...props}
      resource="extensionarms"
      title="admin.pages.extensionarmList"
      filters={<ListFilter />}
      filter={{
        corporationId,
      }}
    >
      <Datagrid
        className={classes.root}
        rowClick={(id: Identifier) => {
          gotoListEditRoutingTabbed(pathname, id);
        }}
      >
        <ExtensionarmTypeField
          resource="extensionarms"
          source="extensionarmType"
        />
        <TextField resource="extensionarms" source="name" />
        <ReferenceField
          resource="extensionarms"
          source="corporationId"
          reference="corporations"
          link=""
        >
          <TextField resource="corporations" source="name" />
        </ReferenceField>
        <ReferenceField
          resource="extensionarms"
          source="siteId"
          reference="sites"
          link=""
        >
          <TextField resource="sites" source="name" />
        </ReferenceField>
        <CustomDateField
          resource="extensionarms"
          source="lastUpdated"
          showTime
        />
      </Datagrid>
    </CustomList>
  );
};

ExtensionarmList.displayName = 'ExtensionarmList';
export default ExtensionarmList;
