import { useCallback } from 'react';
import { RetrofitData } from '../../../../dataProvider';

interface InParams {
  retrofits: RetrofitData[];
  ownerCorporationIds: string[];
}

interface OutParams {
  data: { serialNumbers: string[] };
}

const useExtractSerialNumbers = () => {
  return useCallback(
    async ({
      retrofits,
      ownerCorporationIds,
    }: InParams): Promise<OutParams> => {
      if (retrofits.length <= 0) return { data: { serialNumbers: [] } };

      return {
        data: {
          serialNumbers: retrofits
            .filter(
              ({ corporationId }) =>
                !ownerCorporationIds.includes(corporationId),
            )
            .map(({ basicInfoSerialNumber }) => basicInfoSerialNumber),
        },
      };
    },
    [],
  );
};

export default useExtractSerialNumbers;
