import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { RetrofitData } from '../dataProvider';

interface InParams {
  retrofitIds: number[];
}

interface OutParams {
  data: {
    serialNumbers: string[];
    retrofits: RetrofitData[];
  };
}

const useRetrofitSerialNumber = () => {
  const dataProvider = useDataProvider();
  return useCallback(
    async ({ retrofitIds }: InParams): Promise<OutParams> => {
      const { data: retrofits } = await dataProvider.getList<RetrofitData>(
        'retrofits',
        {
          pagination: {
            page: 1,
            perPage: retrofitIds.length,
          },
          sort: {
            field: 'id',
            order: 'ASC',
          },
          filter: {
            id: retrofitIds,
          },
        },
      );
      return {
        data: {
          serialNumbers: retrofits.map(
            ({ basicInfoSerialNumber }) => basicInfoSerialNumber,
          ),
          retrofits,
        },
      };
    },
    [dataProvider],
  );
};

export default useRetrofitSerialNumber;
