import { useHistory } from 'react-router-dom';

/**
 * 前の画面に遷移を行う
 *
 * @example
 *
 * const { gotoBack } = useBackTo();
 * // 前の画面に戻る
 * gotoBack();
 */
const useBackTo = () => {
  const history = useHistory();
  return {
    gotoBack() {
      history.goBack();
    },
  };
};

export default useBackTo;
