import { useDataProvider } from 'react-admin';
import { RetrofitRentalData } from '../../../../dataProvider';

const useGetRentalInfo = (retrofitId: number) => {
  const dataProvider = useDataProvider();
  return async (): Promise<{ unapproved: boolean; rented: boolean }> => {
    const pagination = { page: 1, perPage: 100 };
    const sort = { field: 'id', order: 'ASC' };

    const { data: unapprovedRentalData } =
      await dataProvider.getList<RetrofitRentalData>('retrofitRentals', {
        filter: {
          retrofitId, // 対象建機
          isApproved: false, // 未承認のデータを抽出
        },
        pagination,
        sort,
      });

    const { data: rentedRentalData } =
      await dataProvider.getList<RetrofitRentalData>('retrofitRentals', {
        filter: {
          retrofitId, // 対象建機
          isReturned: false, // 貸与中のデータを抽出
        },
        pagination,
        sort,
      });

    return {
      unapproved: unapprovedRentalData.length > 0, // 貸与未承認のデータが1件でも存在するか
      rented: rentedRentalData.length > 0, // 貸与中のデータが1件でも存在するか
    };
  };
};

export default useGetRentalInfo;
