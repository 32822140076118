import React, { FC } from 'react';
import { Grid } from '@material-ui/core';

const CenterContainer: FC = ({ children }) => (
  <Grid
    container
    alignItems="center"
    direction="column"
    style={{ height: '100%' }}
  >
    {children}
  </Grid>
);

CenterContainer.displayName = 'CenterContainer';
export default CenterContainer;
