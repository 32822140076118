// math.ts

const roundUp = (value: number, n: number): number => {
  const base = 10 ** n;
  return Math.round(value * base) / base;
};

const math = { roundUp };

export default math;
