import React, { FC, useCallback, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useDataProvider, useNotify, useRedirect } from 'react-admin';
import Button from '../../../../bases/button/Button';
import { colors } from '../../../../../theme';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../../../../theme/define';
import { apiErrorHandler } from '../../../../../utils';
import { DialogOKCancel } from '../../../..';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: colors.button.cancel,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
    initialize: {
      background: colors.button.delete,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        background: colors.button.deleteHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.deleteDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
  }),
);

interface Props {
  record: any;
  label: string;
  isInitialized: boolean;
}

const InitializeRetrofitSendbackButton: FC<Props> = ({
  record,
  label,
  isInitialized,
}) => {
  if (!record) throw Error('Invalid record');

  const testId = label || '';
  const classes = useStyles();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const dataProvider = useDataProvider();
  const redirect = `/retrofitBasicInfos/${record.fromRetrofitId}`;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleHide = useCallback(() => setOpen(false), [setOpen]);

  const handleSubmit = useCallback(() => {
    // 前回値のレコードとマージ(初期化：shouldInitialization、保存：isComplete)
    const data = {
      ...record,
      serial: '',
      shouldInitialization: true,
      isComplete: true,
    };
    // 対象リソースの更新処理
    dataProvider
      .update('retrofitSendbacks', {
        id: record.id,
        data,
        previousData: record,
      })
      .then(() => {
        notify('admin.message.success', 'info');
        redirectTo(redirect);
      })
      .catch(error => {
        notify(apiErrorHandler(error), 'warning');
      });
  }, [dataProvider, notify, redirectTo, record, redirect]);

  return (
    <>
      <Button
        className={classes.initialize}
        label="admin.label.retrofitSendbacks.initialize"
        onClick={handleClick}
        disabled={isInitialized}
        data-testid={testId}
      />
      <DialogOKCancel
        open={open}
        onClose={handleHide}
        onOKClick={handleSubmit}
        okLabel="admin.actions.yes"
        cancelLabel="admin.actions.no"
        title=""
        message="admin.dialog.retrofitSendBacks.deleteNtrip"
      />
    </>
  );
};

InitializeRetrofitSendbackButton.displayName =
  'InitializeRetrofitSendbackButton';
export default InitializeRetrofitSendbackButton;
