import React, { useEffect, useState } from 'react';
import { Loading, TextField, useDataProvider, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import { useEdit } from '../../../../hooks';
import {
  BreadcrumbsFiled,
  CorporationNameField,
  CustomForm,
  Edit,
  SiteNameField,
  FunctionField,
  Typography,
  PropsConsumer,
  NtripNameField,
} from '../../..';
import { RetrofitData } from '../../../../dataProvider';
import { CancelButton, InitializeRetrofitSendbackButton } from './views';
import { getParentParentPath } from '../../../../utils';

const RetrofitSendBackReturn: React.FC = () => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const { props } = useEdit('retrofitSendbacks', {
    resource: 'retrofits',
    key: 'retrofitId',
  });
  const { basePath = '' } = props;
  const { retrofitId, id } = useParams<{ retrofitId: string; id: string }>();
  const [apiState, setApiState] = useState<{
    fetched: boolean;
    loading: boolean;
    data?: { toRetrofits?: any; sendback?: any };
    retrofitId?: string;
  }>({
    fetched: false,
    loading: true,
    data: undefined,
    retrofitId: undefined,
  });

  // Landlogの企業Idを取得
  useEffect(() => {
    if (apiState.fetched && apiState.retrofitId === retrofitId) return;

    const api = async () => {
      const { data: sendback } = await dataProvider.getOne(
        'retrofitSendbacks',
        {
          id,
        },
      );

      const toRetrofitId = sendback?.toRetrofitId;

      // 交換品レトロフィット情報を取得
      const { data: toRetrofits } = await dataProvider.getOne('retrofits', {
        id: toRetrofitId,
      });

      return {
        toRetrofits,
        sendback,
      };
    };
    api().then(data => {
      setApiState({
        fetched: true,
        loading: false,
        data,
        retrofitId,
      });
    });
  }, [apiState.fetched, apiState.retrofitId, dataProvider, retrofitId, id]);

  if (apiState.loading || !apiState.data) {
    return <Loading />;
  }
  const { toRetrofits, sendback } = apiState.data;
  const { isInitialized } = sendback;

  return (
    <Edit {...props}>
      <CustomForm
        title=""
        redirect={getParentParentPath(basePath)}
        cancelButton={<CancelButton basePath={basePath} />}
        deleteButton={false}
        saveButton={
          <PropsConsumer>
            {formProps => {
              const { record } = formProps;
              return (
                <InitializeRetrofitSendbackButton
                  label="admin.actions.deleteNtrip"
                  record={record}
                  isInitialized={isInitialized}
                />
              );
            }}
          </PropsConsumer>
        }
      >
        <BreadcrumbsFiled
          breadcrumbs={['resources.retrofitBasicInfos.name']}
          label="resources.retrofitBasicInfos.tag.sendBack"
        />
        <Typography>{translate('admin.pages.retrofitSendBack')}</Typography>
        <SiteNameField
          label="resources.retrofitBasicInfos.sendBack.fields.site"
          record={toRetrofits}
        />
        <CorporationNameField
          label="resources.retrofitBasicInfos.sendBack.fields.corporation"
          record={toRetrofits}
        />
        <NtripNameField
          label="resources.retrofitBasicInfos.sendBack.fields.ntripSettingInfo"
          record={toRetrofits}
        />
        <FunctionField
          label="resources.retrofitSendbacks.fields.toRetrofitId"
          record={toRetrofits}
          render={record => {
            const renderData = record as RetrofitData;
            return `${renderData?.machineInfoMachineId} / ${renderData?.machineInfoMachineName} / ${renderData?.basicInfoSerialNumber}`;
          }}
        />
        <TextField
          label="resources.retrofitSendbacks.fields.notificationEmailAddress"
          record={sendback}
          source="notificationEmailAddress"
        />
      </CustomForm>
    </Edit>
  );
};

RetrofitSendBackReturn.displayName = 'RetrofitSendBackReturn';
export default RetrofitSendBackReturn;
