import { RetrofitByTaskData } from '../../types';

const retrofitByTasks: RetrofitByTaskData[] = [
  {
    id: 1,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    machineInfoMachineName: 'テスト建機A',
    taskId: 1,
  },
  {
    id: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    machineInfoMachineName: 'テスト建機Z',
    taskId: 1,
  },
  {
    id: 3,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    machineInfoMachineName: 'テスト建機B',
    taskId: 1,
  },
  {
    id: 4,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    machineInfoMachineName: 'テスト建機C',
    taskId: 1,
  },
];

export default retrofitByTasks;
