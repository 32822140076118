import { AdminApiConverter, ApiFilter } from '..';
import { convertFillDate } from '../../../utils';

const converter: AdminApiConverter<
  'retrofitAlternateRegists',
  'retrofitAlternateRegists'
> = {
  resource: 'retrofitAlternateRegists',
  defaultSort: { field: 'id', order: 'DESC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'retrofitAlternateRegists'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('retrofitId' in filter) apiFilter.retrofitId = filter.retrofitId;
    if ('fromCorporationId' in filter)
      apiFilter.fromCorporationId = filter.fromCorporationId;
    if ('fromCorporationName' in filter)
      apiFilter.fromCorporationName = filter.fromCorporationName;
    if ('toCorporationId' in filter)
      apiFilter.toCorporationId = filter.toCorporationId;
    if ('toCorporationName' in filter)
      apiFilter.toCorporationName = filter.toCorporationName;
    if ('toMailAddress' in filter)
      apiFilter.toMailAddress = filter.toMailAddress;
    if ('isApproved' in filter) apiFilter.isApproved = filter.isApproved;
    if ('actualDate' in filter) apiFilter.actualDate = filter.actualDate;
    if ('applicationDate' in filter)
      apiFilter.createDate = filter.applicationDate;
    if ('fromApplicantId' in filter)
      throw new Error('Unsupported filter field: fromApplicantId');
    if ('fromApplicantName' in filter)
      throw new Error('Unsupported filter field: fromApplicantName');
    if ('lastUpdated' in filter) apiFilter.updateDate = filter.lastUpdated;
    return apiFilter;
  },
  sortFieldToApi: field => {
    if (field === 'applicationDate') return 'createDate';
    if (field === 'fromApplicantId')
      throw new Error('Unsupported sort field: fromApplicantId');
    if (field === 'fromApplicantName')
      throw new Error('Unsupported sort field: fromApplicantName');
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(record => ({
        id: record.id || 0,
        retrofitId: record.retrofitId,
        fromCorporationId: record.fromCorporationId,
        fromCorporationName: record.fromCorporationName,
        toCorporationId: record.toCorporationId,
        toCorporationName: record.toCorporationName,
        toMailAddress: record.toMailAddress,
        isApproved: record.isApproved || false,
        actualDate: record.actualDate,
        applicationDate: record.createDate || '',
        lastUpdated: record.updateDate || '',
      })),
    ),
  recordToApi: record => ({
    retrofitId: record.retrofitId,
    fromCorporationId: record.fromCorporationId,
    fromCorporationName: record.fromCorporationName,
    fromApplicantId: record.fromApplicantId,
    fromApplicantName: record.fromApplicantName,
    toCorporationId: record.toCorporationId,
    toCorporationName: record.toCorporationName,
    toMailAddress: record.toMailAddress,
    isApproved: record.isApproved,
    actualDate: record.actualDate,
    requestLocalDate: convertFillDate(new Date().toString(), {
      hour: 0,
      min: 0,
      sec: 0,
    }),
  }),
};

export default converter;
