import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { useResource } from '../../../../../hooks';

const useRentaled = () => {
  const dataProvider = useDataProvider();
  const resource = useResource('retrofitRentals');

  return useCallback(
    async (retrofitId: number): Promise<boolean> => {
      const { data: rentalData } = await dataProvider.getList(resource, {
        pagination: {
          page: 1,
          perPage: 1,
        },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          retrofitId,
          isReturned: false,
        },
      });

      return rentalData.length > 0;
    },
    [dataProvider, resource],
  );
};

export default useRentaled;
