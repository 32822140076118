import { Resources } from '../dataProvider';
import { useEditInSite } from '.';

const useCreateInSite = <T extends keyof Resources>(resource: T) => {
  const { props: editProps, site } = useEditInSite(resource);
  const props = {
    location: editProps.location,
    basePath: editProps.basePath,
    resource,
    siteId: editProps.siteId,
  };

  return { props, site };
};

export default useCreateInSite;
