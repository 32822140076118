import { AdminApiConverter, ApiFilter } from '..';
import { RetrofitCalibsData } from '../../types';

const converter: AdminApiConverter<'retrofitCalibs', 'retrofitCalibs'> = {
  resource: 'retrofitCalibs',
  defaultSort: { field: 'id', order: 'DESC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'retrofitCalibs'> = {};
    if ('retrofitId' in filter) apiFilter.retrofitId = filter.retrofitId;
    if ('userCorporationId' in filter)
      apiFilter.userCorporationId = filter.userCorporationId;
    if ('timeStamp' in filter) apiFilter.timeStamp = filter.timeStamp;
    return apiFilter;
  },
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map((record): RetrofitCalibsData => {
        return {
          id: record.id || '',
          retrofitId: record.retrofitId || 0,
          timeStamp: record.timeStamp || '',
          url: record.url || '',
          userCorporationId: record.userCorporationId || '',
          detailInfo: record.detailInfo
            ? {
                createDateTime: record.detailInfo?.createDateTime || '',
                updateDateTime: record.detailInfo?.updateDateTime || '',
                machineInfo: {
                  companyName: record.detailInfo?.machineInfo?.companyName,
                  machineType: record.detailInfo?.machineInfo?.machineType,
                  machineName: record.detailInfo?.machineInfo?.machineName,
                  machineId: record.detailInfo?.machineInfo?.machineId,
                  drumConfiguration:
                    record.detailInfo?.machineInfo?.drumConfiguration,
                  guidanceMode: record.detailInfo?.machineInfo?.guidanceMode,
                },
                machineGeometryInfo: record.detailInfo?.machineGeometryInfo
                  ? {
                      boomLength:
                        record.detailInfo?.machineGeometryInfo?.boomLength,
                      secondBoomLength:
                        record.detailInfo?.machineGeometryInfo
                          ?.secondBoomLength,
                      armLength:
                        record.detailInfo?.machineGeometryInfo?.armLength,
                      armTopPinToBucketSideLinkPinLength:
                        record.detailInfo?.machineGeometryInfo
                          ?.armTopPinToBucketSideLinkPinLength,
                      boomTopPinToBucketSideLinkPinLength:
                        record.detailInfo?.machineGeometryInfo
                          ?.boomTopPinToBucketSideLinkPinLength,
                      bucketLink1Length:
                        record.detailInfo?.machineGeometryInfo
                          ?.bucketLink1Length,
                      bucketLink2Length:
                        record.detailInfo?.machineGeometryInfo
                          ?.bucketLink2Length,
                      bodyCenterToBoomFootPin: record.detailInfo
                        ?.machineGeometryInfo?.bodyCenterToBoomFootPin
                        ? {
                            x: record.detailInfo?.machineGeometryInfo
                              ?.bodyCenterToBoomFootPin?.x,
                            y: record.detailInfo?.machineGeometryInfo
                              ?.bodyCenterToBoomFootPin?.y,
                            z: record.detailInfo?.machineGeometryInfo
                              ?.bodyCenterToBoomFootPin?.z,
                          }
                        : record.detailInfo?.machineGeometryInfo
                            ?.bodyCenterToBoomFootPin,
                      drumWidth:
                        record.detailInfo?.machineGeometryInfo?.drumWidth,
                      length: record.detailInfo?.machineGeometryInfo?.length,
                      shoeInterval:
                        record.detailInfo?.machineGeometryInfo?.shoeInterval,
                      bladeWidthForDozer:
                        record.detailInfo?.machineGeometryInfo
                          ?.bladeWidthForDozer,
                      distanceFromBodyToBlade:
                        record.detailInfo?.machineGeometryInfo
                          ?.distanceFromBodyToBlade,
                      frontToMainGnssAntenna:
                        record.detailInfo?.machineGeometryInfo
                          ?.frontToMainGnssAntenna,
                      frontToSubGnssAntenna:
                        record.detailInfo?.machineGeometryInfo
                          ?.frontToSubGnssAntenna,
                      centerToMainGnssAntenna:
                        record.detailInfo?.machineGeometryInfo
                          ?.centerToMainGnssAntenna,
                      centerToSubGnssAntenna:
                        record.detailInfo?.machineGeometryInfo
                          ?.centerToSubGnssAntenna,
                      groundToMainGnssAntenna:
                        record.detailInfo?.machineGeometryInfo
                          ?.groundToMainGnssAntenna,
                      groundToSubGnssAntenna:
                        record.detailInfo?.machineGeometryInfo
                          ?.groundToSubGnssAntenna,
                    }
                  : record.detailInfo?.machineGeometryInfo,

                imuMountInfo: record.detailInfo?.imuMountInfo
                  ? {
                      boomImuOffsetAngle:
                        record.detailInfo?.imuMountInfo?.boomImuOffsetAngle,
                      secondBoomImuOffsetAngle:
                        record.detailInfo?.imuMountInfo
                          ?.secondBoomImuOffsetAngle,
                      armImuOffsetAngle:
                        record.detailInfo?.imuMountInfo?.armImuOffsetAngle,
                      bucketImuOffsetAngle:
                        record.detailInfo?.imuMountInfo?.bucketImuOffsetAngle,
                      bodyImuOffsetRollAngle:
                        record.detailInfo?.imuMountInfo?.bodyImuOffsetRollAngle,
                      bodyImuOffsetPitchAngle:
                        record.detailInfo?.imuMountInfo
                          ?.bodyImuOffsetPitchAngle,
                    }
                  : record.detailInfo?.imuMountInfo,
                gnssMountInfo: record.detailInfo?.gnssMountInfo
                  ? {
                      main: record.detailInfo?.gnssMountInfo?.main
                        ? {
                            bodyCenterToGnss: record.detailInfo?.gnssMountInfo
                              ?.main?.bodyCenterToGnss
                              ? {
                                  x: record.detailInfo?.gnssMountInfo?.main
                                    ?.bodyCenterToGnss?.x,
                                  y: record.detailInfo?.gnssMountInfo?.main
                                    ?.bodyCenterToGnss?.y,
                                  z: record.detailInfo?.gnssMountInfo?.main
                                    ?.bodyCenterToGnss?.z,
                                }
                              : record.detailInfo?.gnssMountInfo?.main
                                  ?.bodyCenterToGnss,
                          }
                        : record.detailInfo?.gnssMountInfo?.main,
                      sub: record.detailInfo?.gnssMountInfo?.sub
                        ? {
                            bodyCenterToGnss: record.detailInfo?.gnssMountInfo
                              ?.sub?.bodyCenterToGnss
                              ? {
                                  x: record.detailInfo?.gnssMountInfo?.sub
                                    ?.bodyCenterToGnss?.x,
                                  y: record.detailInfo?.gnssMountInfo?.sub
                                    ?.bodyCenterToGnss?.y,
                                  z: record.detailInfo?.gnssMountInfo?.sub
                                    ?.bodyCenterToGnss?.z,
                                }
                              : record.detailInfo?.gnssMountInfo?.sub
                                  ?.bodyCenterToGnss,
                          }
                        : record.detailInfo?.gnssMountInfo?.sub,
                    }
                  : record.detailInfo?.gnssMountInfo,
                extendedModel: record.detailInfo?.extendedModel
                  ? {
                      swingBoom: record.detailInfo?.extendedModel?.swingBoom,
                      twoPieceBoom:
                        record.detailInfo?.extendedModel?.twoPieceBoom,
                      minimalSwingRadius:
                        record.detailInfo?.extendedModel?.minimalSwingRadius,
                    }
                  : record.detailInfo?.extendedModel,
                measuredValues: record.detailInfo?.measuredValues
                  ? {
                      otherLength: record.detailInfo?.measuredValues
                        ?.otherLength
                        ? {
                            machineHeight:
                              record.detailInfo?.measuredValues?.otherLength
                                ?.machineHeight,
                          }
                        : record.detailInfo?.measuredValues?.otherLength,
                      bodyImu1: record.detailInfo?.measuredValues?.bodyImu1
                        ? {
                            roll: record.detailInfo?.measuredValues?.bodyImu1
                              ?.roll,
                            pitch:
                              record.detailInfo?.measuredValues?.bodyImu1
                                ?.pitch,
                            yaw: record.detailInfo?.measuredValues?.bodyImu1
                              ?.yaw,
                          }
                        : record.detailInfo?.measuredValues?.bodyImu1,
                      bodyImu2: record.detailInfo?.measuredValues?.bodyImu2
                        ? {
                            roll: record.detailInfo?.measuredValues?.bodyImu2
                              ?.roll,
                            pitch:
                              record.detailInfo?.measuredValues?.bodyImu2
                                ?.pitch,
                            yaw: record.detailInfo?.measuredValues?.bodyImu2
                              ?.yaw,
                          }
                        : record.detailInfo?.measuredValues?.bodyImu2,
                      frontLeft: record.detailInfo?.measuredValues?.frontLeft
                        ? {
                            x: record.detailInfo?.measuredValues?.frontLeft?.x,
                            y: record.detailInfo?.measuredValues?.frontLeft?.y,
                            z: record.detailInfo?.measuredValues?.frontLeft?.z,
                          }
                        : record.detailInfo?.measuredValues?.frontLeft,
                      frontRight: record.detailInfo?.measuredValues?.frontRight
                        ? {
                            x: record.detailInfo?.measuredValues?.frontRight?.x,
                            y: record.detailInfo?.measuredValues?.frontRight?.y,
                            z: record.detailInfo?.measuredValues?.frontRight?.z,
                          }
                        : record.detailInfo?.measuredValues?.frontRight,
                      frontGroundingEnd: record.detailInfo?.measuredValues
                        ?.frontGroundingEnd
                        ? {
                            x: record.detailInfo?.measuredValues
                              ?.frontGroundingEnd?.x,
                            y: record.detailInfo?.measuredValues
                              ?.frontGroundingEnd?.y,
                            z: record.detailInfo?.measuredValues
                              ?.frontGroundingEnd?.z,
                          }
                        : record.detailInfo?.measuredValues?.frontGroundingEnd,
                      rearGroundingEnd: record.detailInfo?.measuredValues
                        ?.rearGroundingEnd
                        ? {
                            x: record.detailInfo?.measuredValues
                              ?.rearGroundingEnd?.x,
                            y: record.detailInfo?.measuredValues
                              ?.rearGroundingEnd?.y,
                            z: record.detailInfo?.measuredValues
                              ?.rearGroundingEnd?.z,
                          }
                        : record.detailInfo?.measuredValues?.rearGroundingEnd,
                      mainGnssAntenna: record.detailInfo?.measuredValues
                        ?.mainGnssAntenna
                        ? {
                            x: record.detailInfo?.measuredValues
                              ?.mainGnssAntenna?.x,
                            y: record.detailInfo?.measuredValues
                              ?.mainGnssAntenna?.y,
                            z: record.detailInfo?.measuredValues
                              ?.mainGnssAntenna?.z,
                          }
                        : record.detailInfo?.measuredValues?.mainGnssAntenna,
                      subGnssAntenna: record.detailInfo?.measuredValues
                        ?.subGnssAntenna
                        ? {
                            x: record.detailInfo?.measuredValues?.subGnssAntenna
                              ?.x,
                            y: record.detailInfo?.measuredValues?.subGnssAntenna
                              ?.y,
                            z: record.detailInfo?.measuredValues?.subGnssAntenna
                              ?.z,
                          }
                        : record.detailInfo?.measuredValues?.subGnssAntenna,
                      prismPoleOffset:
                        record.detailInfo?.measuredValues?.prismPoleOffset,
                      antennaElectricalCenterHeight:
                        record.detailInfo?.measuredValues
                          ?.antennaElectricalCenterHeight,
                    }
                  : record.detailInfo?.measuredValues,
              }
            : undefined,
        };
      }),
    ),
  recordToApi: record => {
    return {
      id: record.id,
      retrofitId: record.retrofitId,
      userCorporationId: record.userCorporationId,
      timeStamp: record.timeStamp,
      url: record.url,
      detailInfo: {
        createDateTime: record.detailInfo?.createDateTime || '',
        updateDateTime: record.detailInfo?.updateDateTime || '',
        machineInfo: {
          companyName: record.detailInfo?.machineInfo?.companyName,
          machineType: record.detailInfo?.machineInfo?.machineType,
          machineName: record.detailInfo?.machineInfo?.machineName,
          machineId: record.detailInfo?.machineInfo?.machineId,
          drumConfiguration: record.detailInfo?.machineInfo?.drumConfiguration,
          guidanceMode: record.detailInfo?.machineInfo?.guidanceMode,
        },
        machineGeometryInfo: record.detailInfo?.machineGeometryInfo
          ? {
              boomLength: record.detailInfo?.machineGeometryInfo?.boomLength,
              secondBoomLength:
                record.detailInfo?.machineGeometryInfo?.secondBoomLength,
              armLength: record.detailInfo?.machineGeometryInfo?.armLength,
              armTopPinToBucketSideLinkPinLength:
                record.detailInfo?.machineGeometryInfo
                  ?.armTopPinToBucketSideLinkPinLength,
              boomTopPinToBucketSideLinkPinLength:
                record.detailInfo?.machineGeometryInfo
                  ?.boomTopPinToBucketSideLinkPinLength,
              bucketLink1Length:
                record.detailInfo?.machineGeometryInfo?.bucketLink1Length,
              bucketLink2Length:
                record.detailInfo?.machineGeometryInfo?.bucketLink2Length,
              bodyCenterToBoomFootPin: record.detailInfo?.machineGeometryInfo
                ?.bodyCenterToBoomFootPin
                ? {
                    x: record.detailInfo?.machineGeometryInfo
                      ?.bodyCenterToBoomFootPin?.x,
                    y: record.detailInfo?.machineGeometryInfo
                      ?.bodyCenterToBoomFootPin?.y,
                    z: record.detailInfo?.machineGeometryInfo
                      ?.bodyCenterToBoomFootPin?.z,
                  }
                : record.detailInfo?.machineGeometryInfo
                    ?.bodyCenterToBoomFootPin,
              drumWidth: record.detailInfo?.machineGeometryInfo?.drumWidth,
              length: record.detailInfo?.machineGeometryInfo?.length,
              shoeInterval:
                record.detailInfo?.machineGeometryInfo?.shoeInterval,
              bladeWidthForDozer:
                record.detailInfo?.machineGeometryInfo?.bladeWidthForDozer,
              distanceFromBodyToBlade:
                record.detailInfo?.machineGeometryInfo?.distanceFromBodyToBlade,
              frontToMainGnssAntenna:
                record.detailInfo?.machineGeometryInfo?.frontToMainGnssAntenna,
              frontToSubGnssAntenna:
                record.detailInfo?.machineGeometryInfo?.frontToSubGnssAntenna,
              centerToMainGnssAntenna:
                record.detailInfo?.machineGeometryInfo?.centerToMainGnssAntenna,
              centerToSubGnssAntenna:
                record.detailInfo?.machineGeometryInfo?.centerToSubGnssAntenna,
              groundToMainGnssAntenna:
                record.detailInfo?.machineGeometryInfo?.groundToMainGnssAntenna,
              groundToSubGnssAntenna:
                record.detailInfo?.machineGeometryInfo?.groundToSubGnssAntenna,
            }
          : record.detailInfo?.machineGeometryInfo,

        imuMountInfo: record.detailInfo?.imuMountInfo
          ? {
              boomImuOffsetAngle:
                record.detailInfo?.imuMountInfo?.boomImuOffsetAngle,
              secondBoomImuOffsetAngle:
                record.detailInfo?.imuMountInfo?.secondBoomImuOffsetAngle,
              armImuOffsetAngle:
                record.detailInfo?.imuMountInfo?.armImuOffsetAngle,
              bucketImuOffsetAngle:
                record.detailInfo?.imuMountInfo?.bucketImuOffsetAngle,
              bodyImuOffsetRollAngle:
                record.detailInfo?.imuMountInfo?.bodyImuOffsetRollAngle,
              bodyImuOffsetPitchAngle:
                record.detailInfo?.imuMountInfo?.bodyImuOffsetPitchAngle,
            }
          : record.detailInfo?.imuMountInfo,
        gnssMountInfo: record.detailInfo?.gnssMountInfo
          ? {
              main: record.detailInfo?.gnssMountInfo?.main
                ? {
                    bodyCenterToGnss: record.detailInfo?.gnssMountInfo?.main
                      ?.bodyCenterToGnss
                      ? {
                          x: record.detailInfo?.gnssMountInfo?.main
                            ?.bodyCenterToGnss?.x,
                          y: record.detailInfo?.gnssMountInfo?.main
                            ?.bodyCenterToGnss?.y,
                          z: record.detailInfo?.gnssMountInfo?.main
                            ?.bodyCenterToGnss?.z,
                        }
                      : record.detailInfo?.gnssMountInfo?.main
                          ?.bodyCenterToGnss,
                  }
                : record.detailInfo?.gnssMountInfo?.main,
              sub: record.detailInfo?.gnssMountInfo?.sub
                ? {
                    bodyCenterToGnss: record.detailInfo?.gnssMountInfo?.sub
                      ?.bodyCenterToGnss
                      ? {
                          x: record.detailInfo?.gnssMountInfo?.sub
                            ?.bodyCenterToGnss?.x,
                          y: record.detailInfo?.gnssMountInfo?.sub
                            ?.bodyCenterToGnss?.y,
                          z: record.detailInfo?.gnssMountInfo?.sub
                            ?.bodyCenterToGnss?.z,
                        }
                      : record.detailInfo?.gnssMountInfo?.sub?.bodyCenterToGnss,
                  }
                : record.detailInfo?.gnssMountInfo?.sub,
            }
          : record.detailInfo?.gnssMountInfo,
        extendedModel: record.detailInfo?.extendedModel
          ? {
              swingBoom: record.detailInfo?.extendedModel?.swingBoom,
              twoPieceBoom: record.detailInfo?.extendedModel?.twoPieceBoom,
              minimalSwingRadius:
                record.detailInfo?.extendedModel?.minimalSwingRadius,
            }
          : record.detailInfo?.extendedModel,
        measuredValues: record.detailInfo?.measuredValues
          ? {
              otherLength: record.detailInfo?.measuredValues?.otherLength
                ? {
                    machineHeight:
                      record.detailInfo?.measuredValues?.otherLength
                        ?.machineHeight,
                  }
                : record.detailInfo?.measuredValues?.otherLength,
              bodyImu1: record.detailInfo?.measuredValues?.bodyImu1
                ? {
                    roll: record.detailInfo?.measuredValues?.bodyImu1?.roll,
                    pitch: record.detailInfo?.measuredValues?.bodyImu1?.pitch,
                    yaw: record.detailInfo?.measuredValues?.bodyImu1?.yaw,
                  }
                : record.detailInfo?.measuredValues?.bodyImu1,
              bodyImu2: record.detailInfo?.measuredValues?.bodyImu2
                ? {
                    roll: record.detailInfo?.measuredValues?.bodyImu2?.roll,
                    pitch: record.detailInfo?.measuredValues?.bodyImu2?.pitch,
                    yaw: record.detailInfo?.measuredValues?.bodyImu2?.yaw,
                  }
                : record.detailInfo?.measuredValues?.bodyImu2,
              frontLeft: record.detailInfo?.measuredValues?.frontLeft
                ? {
                    x: record.detailInfo?.measuredValues?.frontLeft?.x,
                    y: record.detailInfo?.measuredValues?.frontLeft?.y,
                    z: record.detailInfo?.measuredValues?.frontLeft?.z,
                  }
                : record.detailInfo?.measuredValues?.frontLeft,
              frontRight: record.detailInfo?.measuredValues?.frontRight
                ? {
                    x: record.detailInfo?.measuredValues?.frontRight?.x,
                    y: record.detailInfo?.measuredValues?.frontRight?.y,
                    z: record.detailInfo?.measuredValues?.frontRight?.z,
                  }
                : record.detailInfo?.measuredValues?.frontRight,
              frontGroundingEnd: record.detailInfo?.measuredValues
                ?.frontGroundingEnd
                ? {
                    x: record.detailInfo?.measuredValues?.frontGroundingEnd?.x,
                    y: record.detailInfo?.measuredValues?.frontGroundingEnd?.y,
                    z: record.detailInfo?.measuredValues?.frontGroundingEnd?.z,
                  }
                : record.detailInfo?.measuredValues?.frontGroundingEnd,
              rearGroundingEnd: record.detailInfo?.measuredValues
                ?.rearGroundingEnd
                ? {
                    x: record.detailInfo?.measuredValues?.rearGroundingEnd?.x,
                    y: record.detailInfo?.measuredValues?.rearGroundingEnd?.y,
                    z: record.detailInfo?.measuredValues?.rearGroundingEnd?.z,
                  }
                : record.detailInfo?.measuredValues?.rearGroundingEnd,
              mainGnssAntenna: record.detailInfo?.measuredValues
                ?.mainGnssAntenna
                ? {
                    x: record.detailInfo?.measuredValues?.mainGnssAntenna?.x,
                    y: record.detailInfo?.measuredValues?.mainGnssAntenna?.y,
                    z: record.detailInfo?.measuredValues?.mainGnssAntenna?.z,
                  }
                : record.detailInfo?.measuredValues?.mainGnssAntenna,
              subGnssAntenna: record.detailInfo?.measuredValues?.subGnssAntenna
                ? {
                    x: record.detailInfo?.measuredValues?.subGnssAntenna?.x,
                    y: record.detailInfo?.measuredValues?.subGnssAntenna?.y,
                    z: record.detailInfo?.measuredValues?.subGnssAntenna?.z,
                  }
                : record.detailInfo?.measuredValues?.subGnssAntenna,
              prismPoleOffset:
                record.detailInfo?.measuredValues?.prismPoleOffset,
              antennaElectricalCenterHeight:
                record.detailInfo?.measuredValues
                  ?.antennaElectricalCenterHeight,
            }
          : record.detailInfo?.measuredValues,
      },
    };
  },
};
export default converter;
