import React from 'react';
import { GeofenceType } from '../../../../../dataProvider';
import { MapProps, crearteOverlayControl } from '../../../../../hooks';
import { TypeEditButton } from '../view';

const addTypeControl = (
  { map }: MapProps,
  onClickPolygon: () => void,
  onClickCircle: () => void,
  onClickLine: () => void,
  onClickWall: () => void,
  selectedType?: GeofenceType,
  cancelEvent?: boolean,
) => {
  map.addControl(
    crearteOverlayControl(
      'map_type',
      <TypeEditButton
        onClickPolygon={onClickPolygon}
        onClickCircle={onClickCircle}
        onClickLine={onClickLine}
        onClickWall={onClickWall}
        selectedType={selectedType}
        cancelEvent={cancelEvent}
      />,
      {
        left: 151,
        top: 350,
      },
    ),
  );
};

export default addTypeControl;
