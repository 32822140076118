import { RetrofitDistributorData } from '../../types';

const retrofitDistributors: RetrofitDistributorData[] = [
  {
    id: 1,
    name: 'LANDLOG',
    description: 'LANDLOG',
  },
  {
    id: 2,
    name: 'KCSJ',
    description: 'KCSJ',
  },
  {
    id: 3,
    name: 'MUL',
    description: 'MUL',
  },
  {
    id: 4,
    name: '海外０１',
    description: '海外０１',
  },
  {
    id: 5,
    name: '海外０２',
    description: '海外０２',
  },
  {
    id: 6,
    name: '海外０３',
    description: '海外０３',
  },
  {
    id: 7,
    name: '海外０４',
    description: '海外０４',
  },
  {
    id: 8,
    name: '海外０５',
    description: '海外０５',
  },
  {
    id: 9,
    name: '海外０６',
    description: '海外０６',
  },
  {
    id: 10,
    name: '海外０７５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    description:
      '海外０７５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
  },
];

export default retrofitDistributors;
