import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import inflection from 'inflection';
import {
  Exporter,
  ReactAdminComponentProps,
  ReduxState,
  registerResource,
  ResourceContextProvider,
  useListController,
  useTranslate,
  useVersion,
  useLoading,
} from 'react-admin';

import { useDispatch, useSelector } from 'react-redux';
import {
  BreadcrumbsPortal,
  CustomBulkDeleteButton,
  CustomListToolbar,
  LinkButton,
  Pagination,
  TopPagination,
  BottomPagination,
  PaginationLimit,
  ResourceFilter,
  ResourceName,
  Sort,
  Loading,
} from '..';
import { AddIcon, DeleteIcon } from '../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      // Firefox
      scrollbarColor: '#717284 transparent',
      scrollbarWidth: 'thin',
      // chrome,safari,Edge
      '&::-webkit-scrollbar': {
        width: 8,
        background: 'transparent',
      },
      '&::-webkit-scrollbar:horizontal': {
        height: 8,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 4,
        background: '#717284',
      },
      '&::-webkit-scrollbar-corner': {
        background: 'transparent',
      },
    },
    footer: {
      marginTop: 8,
      marginBottom: 4,
      display: 'flex',
    },
    flexCenter: {
      justifyContent: 'center',
      width: '100%',
      '& > div': {
        // TopPagination の margin を消す
        margin: 0,
      },
    },
    alignRight: { flexGrow: 1 },
  }),
);

type ListProps = Parameters<typeof useListController>[0];

interface ListViewProps {
  actions: React.ReactElement<any>;
  aside: React.ReactElement<any>;
  breadcrumbs: string[];
  breadcrumbsTitle: string;
  bulkActionButtons: React.ReactElement<any> | boolean;
  classes: object;
  className: string;
  controllerResource: string;
  subActions: React.ReactElement<any>;
  title: string;
  columnfilters?: React.ReactElement<any>;
}

type Props = Partial<
  ReactAdminComponentProps &
    ListProps &
    ListViewProps & {
      breadcrumbsVisible: boolean;
      useScrollPagination?: boolean;
      exporter?: Exporter;
    }
>;

const DefaultAction: React.FC<{
  basePath?: string;
  label?: string;
  hasCreate?: boolean;
}> = props => {
  const { basePath = '', label = 'ra.action.create', hasCreate } = props;
  if (!hasCreate) return null;
  return (
    <LinkButton path={`${basePath}/create`} label={label}>
      <AddIcon />
    </LinkButton>
  );
};

const DefaultBulkActionButtons: React.FC = props => (
  <CustomBulkDeleteButton {...props}>
    <DeleteIcon />
  </CustomBulkDeleteButton>
);

const BulkActionsToolbar: React.FC<
  ReturnType<typeof useListController> & {
    realResource: string;
  }
> = ({
  basePath,
  children,
  filterValues,
  realResource,
  resource,
  selectedIds,
  data,
}) => {
  const disabled = selectedIds.length === 0;
  return (
    <>
      {React.Children.map(
        children,
        child =>
          React.isValidElement(child) &&
          React.cloneElement(React.Children.only(child), {
            basePath,
            filterValues,
            resource,
            selectedIds,
            disabled,
            realResource,
            data,
          }),
      )}
    </>
  );
};

const useCustomListController = (props: any) => {
  const controllerProps = useListController(props);
  const { displayedFilters } = controllerProps;
  return {
    ...controllerProps,
    displayedFilters: displayedFilters || {},
  };
};

const CoreCustomList: React.FC<Props> = ({
  actions = <DefaultAction />,
  aside,
  breadcrumbs,
  breadcrumbsTitle,
  breadcrumbsVisible = true,
  bulkActionButtons = <DefaultBulkActionButtons />,
  children,
  className,
  controllerResource = '',
  resource: realResource = '',
  subActions,
  title,
  icon,
  useScrollPagination = false,
  exporter,
  // useListControllerに渡せるように初期化
  basePath = '',
  query = {
    sort: '',
    order: '',
    page: 0,
    perPage: 0,
    filter: {},
    displayedFilters: {},
  },
  ...rest
}) => {
  const classes = useStyles();
  const {
    filter,
    filters,
    hasEdit,
    hasList,
    hasShow,
    hasCreate,
    columnfilters,
  } = rest;
  // 現場ごとに各リソースのListを表示しているが、リソースが共通の場合、
  // フィルターやページネーションの情報がグローバルストアで共有されてしまう。
  // 現場ごとにリソースを動的に作成することで、これを回避する。
  const resource = controllerResource || realResource;
  const isRegistered = useSelector(
    (state: ReduxState) => !!state.admin.resources[resource],
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (isRegistered || !resource) return;
    dispatch(
      registerResource({
        name: resource,
        options: {},
        hasList,
        hasEdit,
        hasShow,
        hasCreate,
      }),
    );
  }, [dispatch, isRegistered, resource, hasList, hasEdit, hasShow, hasCreate]);
  const translate = useTranslate();
  const resourceName = translate(`resources.${realResource}.name`, {
    smart_count: 2,
    _: inflection.humanize(inflection.pluralize(realResource)),
  });
  const defaultTitle = translate('ra.page.list', {
    name: resourceName,
  });
  // 出力データの取得
  const controllerProps = useCustomListController({
    perPage: 20,
    ...rest,
    resource,
    basePath,
    query,
  });
  const version = useVersion();
  const { total, currentSort, filterValues } = controllerProps;
  const perPageSettings = [20, 50, 100];
  const topPagination = useScrollPagination ? undefined : (
    <TopPagination
      {...controllerProps}
      rowsPerPageOptions={perPageSettings}
      limit={<></>} // CustomList側で実装するため、limit は非表示にする
    />
  );
  const bottomPagination = useScrollPagination ? (
    <Pagination
      labelRowsPerPage=""
      labelDisplayedRows={() => ''}
      rowsPerPageOptions={[]}
      limit={<></>} // CustomList側で実装するため、limit は非表示にする
    />
  ) : (
    <BottomPagination
      {...controllerProps}
      rowsPerPageOptions={perPageSettings}
    />
  );

  const footerBottomPagination = useScrollPagination ? (
    <TopPagination
      {...controllerProps}
      rowsPerPageOptions={perPageSettings}
      limitEnabled={false}
    />
  ) : (
    <BottomPagination
      {...controllerProps}
      rowsPerPageOptions={perPageSettings}
    />
  );

  const loading = useLoading();
  const noData = !total; // 対象リソースがReduxのstoreに、一度もdataが格納されていない場合は、nullを返す

  // CustomList対策版
  return (
    <div className={className} key={version}>
      {aside && React.cloneElement(aside, controllerProps)}
      {breadcrumbsVisible ? (
        <BreadcrumbsPortal
          breadcrumbs={breadcrumbs}
          title={breadcrumbsTitle || defaultTitle}
        />
      ) : null}
      <CustomListToolbar
        {...controllerProps}
        resource={resource}
        actions={actions}
        subActions={subActions}
        filters={filters}
        permanentFilter={filter}
        topPagination={topPagination}
        bottomPagination={bottomPagination}
        title={title}
        icon={icon}
        columnfilters={columnfilters}
        filterValues={{ ...filterValues, ...filter }}
        currentSort={currentSort}
        exporter={exporter}
        listTotal={total}
      />
      {noData && loading ? (
        <Loading />
      ) : (
        <>
          {total > 0 ? (
            <div className={classes.body}>
              {React.isValidElement(children) &&
                React.cloneElement(React.Children.only(children), {
                  ...controllerProps,
                  resource: realResource,
                  hasBulkActions: bulkActionButtons !== false,
                })}
            </div>
          ) : null}
          <div className={classes.footer}>
            {total > 0 && bulkActionButtons !== false && bulkActionButtons && (
              <BulkActionsToolbar {...{ ...controllerProps, realResource }}>
                {bulkActionButtons}
              </BulkActionsToolbar>
            )}
            {total > 0 ? (
              <div className={classes.alignRight}>{topPagination}</div>
            ) : (
              // 0件表示時は中央寄せのスタイルを適応する
              <div className={classes.flexCenter}>
                <PaginationLimit />
              </div>
            )}
          </div>
          <div>{footerBottomPagination}</div>
        </>
      )}
    </div>
  );
};

const CustomList: React.FC<Props> = props => {
  const { controllerResource, resource } = props;
  const setOtherResource = resource || '';

  // 親画面となるListのリソースと異なるリソースにてList表示する場合、ResourceContextProviderでwrap
  return controllerResource ? (
    <ResourceContextProvider value={setOtherResource}>
      <CoreCustomList {...props} />
    </ResourceContextProvider>
  ) : (
    <CoreCustomList {...props} />
  );
};

CustomList.displayName = 'CustomList';

interface ResourceProps<T extends ResourceName> {
  filter?: ResourceFilter<T>;
  filterDefaultValues?: ResourceFilter<T>;
  resource?: T;
  sort?: Sort<T>;
}

interface ResourceFC<P = {}> extends React.FC {
  <T extends ResourceName>(
    props: React.PropsWithChildren<P & ResourceProps<T>>,
    context?: any,
  ): React.ReactElement | null;
}

export default CustomList as ResourceFC<Props>;
