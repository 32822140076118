import { Map, View } from 'ol';
import { Attribution } from 'ol/control';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import { defaults } from 'ol/interaction';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { transform } from 'ol/proj';
import { OSM, Vector as SourceVector } from 'ol/source';
import { Fill, Stroke, Style, Circle } from 'ol/style';
import { useMemo } from 'react';
import Geometry from 'ol/geom/Geometry';
import Polygon from 'ol/geom/Polygon';
import { LonLat } from '../utils';

export type MapResult = {
  map: Map;
  props: MapProps;
};

export type MapProps = {
  view: View;
  map: Map;
  sourceVector: SourceVector;
  vectorLayer: VectorLayer;
  defaultLineWork: boolean;
  record?: any;
};

type FeatureType = {
  geometry: GeometryType;
};

type GeometryType = {
  coordinates: number[][][];
};

export const coordinatesToLonLatArray = (
  coordinates: number[][][],
): LonLat[] => {
  if (coordinates.length <= 0) return [];
  return coordinates[0].map(
    lonLat => ({ lon: lonLat[0], lat: lonLat[1] } as LonLat),
  );
};

export const lonLatArrayToFeature = (lonLat: LonLat[]): Feature<Geometry> => {
  const rawLatLon = lonLat.map(it => [it.lon, it.lat]);
  const latLonArray = rawLatLon.length > 0 ? [...rawLatLon, rawLatLon[0]] : []; // NOTE: pop の逆
  const feature: Feature = new Feature({
    polygon: new Polygon([latLonArray]),
    shape: 'polygon',
  });
  feature.setGeometryName('polygon');
  return feature;
};

export const featureToLonLatArray = (feature: Feature<Geometry>): LonLat[] => {
  const geoJson = new GeoJSON();
  const geoJsonObject = geoJson.writeFeatureObject(feature);
  const rawJson = JSON.stringify(geoJsonObject);
  const featureObject = JSON.parse(rawJson) as FeatureType;
  const lonLatArray = featureObject.geometry.coordinates.flatMap(it => it);
  const ret = lonLatArray.map(it => ({ lon: it[0], lat: it[1] } as LonLat));
  ret.pop(); // NOTE: 末尾の経度緯度を削除する（始点と同じため）
  return ret;
};

const useMap = (
  defaultLonLat: LonLat | undefined = { lon: 0, lat: 0 },
  showOpenStreetMap: boolean | undefined = true,
): MapResult => {
  const { lon, lat } = defaultLonLat;
  return useMemo(() => {
    const openStreetMapLayer = new TileLayer({
      source: new OSM(),
    });

    const sourceVector = new SourceVector({
      features: [],
    });

    const vectorLayer = new VectorLayer({
      source: sourceVector,
      style: [
        new Style({
          // image: GeometryType.POINT のスタイル
          image: new Circle({
            fill: new Fill({
              color: 'rgba(255,255,255,0.65)',
            }),
            stroke: new Stroke({
              color: '#ff7c0a',
              width: 2,
            }),
            radius: 5,
          }),
          // GeometryType.POINT 以外のスタイル
          stroke: new Stroke({
            color: '#ff7c0a',
            width: 2,
          }),
          fill: new Fill({
            color: 'rgba(255,255,255,0.65)',
          }),
        }),
      ],
    });
    vectorLayer.setZIndex(1); // 矩形描画のレイヤは最前面にする

    const centerPosition = [lon, lat];
    const view = new View({
      center: transform(centerPosition, 'EPSG:4326', 'EPSG:3857'),
      zoom: 18, // NOTE: 初期表示時の暫定zoom値(18) ※基本的には上書きされる
      // 拡大の最大率
      minResolution: 0.005,
    });

    // クレジット表記
    const attribution = new Attribution({
      collapsible: false,
    });

    const map = new Map({
      controls: [attribution],
      target: undefined,
      layers: showOpenStreetMap
        ? [openStreetMapLayer, vectorLayer]
        : [vectorLayer],
      interactions: defaults({
        altShiftDragRotate: false,
        doubleClickZoom: true,
        keyboard: false,
        mouseWheelZoom: true,
        shiftDragZoom: true,
        dragPan: true,
        pinchRotate: false,
        pinchZoom: true,
      }),
      view,
    });
    const props: MapProps = {
      view,
      map,
      sourceVector,
      vectorLayer,
      defaultLineWork: true,
    };
    return { map, props };
  }, [lon, lat, showOpenStreetMap]);
};

export default useMap;
