/**
 * 同一座標か否か検証するメソッド
 *
 * @param coordinates 座標配列
 *
 * @returns true: 同一座標が含まれている / false 同一座標が含まれていない
 */
const checkSameValueByArray = (coordinates: number[][]): boolean => {
  for (let i = 0; i < coordinates.length; i += 1) {
    for (let j = i + 1; j < coordinates.length; j += 1) {
      const a = coordinates[i];
      const b = coordinates[j];
      if (JSON.stringify(a) === JSON.stringify(b)) {
        return true;
      }
    }
  }
  return false;
};

export default checkSameValueByArray;
