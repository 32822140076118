import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ImageField, ResourceFieldFC } from '../../bases';

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      height: '48px',
      width: '48px',
      verticalAlign: 'bottom',
      margin: '0px',
    },
  }),
);

const FC: React.FC = props => {
  const classes = useStyles();
  return <ImageField classes={{ image: classes.image }} {...props} />;
};
const SiteListImageField = FC as ResourceFieldFC;
SiteListImageField.displayName = 'SiteListImageField';
export default SiteListImageField;
