import React, { useEffect, useState } from 'react';
import { CompactPicker } from 'react-color';
import { makeStyles, createStyles } from '@material-ui/core';
import PaletteIcon from '@material-ui/icons/Palette';
import { Button } from '../../../..';
import { mapStyle } from '../../../../../theme';

const useStyle = makeStyles(() =>
  createStyles({
    root: {
      ...mapStyle.button,
      border: '2px solid gray',
      boxSizing: 'border-box',
      raButtonsmallIcon: {
        color: 'black',
      },
    },
    popright: {
      position: 'absolute',
      top: '20px',
      left: '220px',
      zIndex: 2,
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
    compactpicker: {
      minWidth: '290px',
      '& > div': {
        minWidth: '290px',
        '& > span': {
          '& > div': {
            minWidth: '25px',
            minHeight: '25px',
            border: '1px solid #cccccc',
          },
        },
      },
    },
    button: {
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  }),
);

interface Props {
  classes?: object;
  mapColor?: string;
  onHandleChangeComplete: (color: any) => void;
}
const getIconColor = (r: number, g: number, b: number) => {
  const m = Math.max(r, g, b);
  const n = Math.min(r, g, b);
  const v = ((m / 255) * 10) / 10;
  const s = m > 0 ? (((m - n) / m) * 10) / 10 : 0;
  const addValue = ((0.5 - s * 0.2) * 10) / 10;
  const newColor = 255 * ((v + addValue) % 1.0);
  return `rgba(${newColor}, ${newColor}, ${newColor}, 1)`;
};

const MapBackgroundColorPicker: React.FC<Props> = ({
  classes: classOverride,
  mapColor = '#ffffff', // white
  onHandleChangeComplete,
}) => {
  const classes = useStyle({ classes: classOverride });
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  // mapcolorで受け取る文字列を16進数の数値に変換
  const rgbR = parseInt(mapColor.substring(1, 3), 16);
  const rgbG = parseInt(mapColor.substring(3, 5), 16);
  const rgbB = parseInt(mapColor.substring(5, 7), 16);
  const [colorValue, setColorValue] = useState({
    r: rgbR,
    g: rgbG,
    b: rgbB,
    a: 1,
  });

  // ダイアログ表示時にカラーパレットアイコン色を変更する
  useEffect(() => {
    if (rgbR !== 255 || rgbG !== 255 || rgbB !== 255) {
      setColorValue({
        r: rgbR,
        g: rgbG,
        b: rgbB,
        a: 1,
      });
    }
  }, [rgbR, rgbG, rgbB]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleColorChange = (color: any) => {
    setColorValue(color.rgb); // アイコン背景の色情報を変更
    onHandleChangeComplete(color); // API送信用データを変更
  };

  // お客様指定の54色パレットカラー
  const customColors = [
    'RGB(236,98,235)',
    'RGB(175,167,248)',
    'RGB(23,132,204)',
    'RGB(0,92,110)',
    'RGB(155,167,17)',
    'RGB(238,112,0)',
    'RGB(240,127,139)',
    'RGB(34,51,119)',
    'RGB(0,0,0)',
    'RGB(206,48,178)',
    'RGB(132,100,201)',
    'RGB(14,45,149)',
    'RGB(28,116,163)',
    'RGB(13,125,59)',
    'RGB(255,140,17)',
    'RGB(255,0,100)',
    'RGB(149,149,149)',
    'RGB(100,100,100)',
    'RGB(145,0,100)',
    'RGB(94,55,141)',
    'RGB(58,71,204)',
    'RGB(56,163,192)',
    'RGB(49,191,65)',
    'RGB(255,175,37)',
    'RGB(253,0,0)',
    'RGB(112,88,88)',
    'RGB(110,104,104)',
    'RGB(185,0,196)',
    'RGB(117,30,223)',
    'RGB(49,93,154)',
    'RGB(37,219,255)',
    'RGB(48,255,138)',
    'RGB(251,229,0)',
    'RGB(171,0,0)',
    'RGB(166,149,149)',
    'RGB(181,181,181)',
    'RGB(255,89,100)',
    'RGB(162,0,253)',
    'RGB(100,102,191)',
    'RGB(124,209,255)',
    'RGB(5,196,129)',
    'RGB(215,215,71)',
    'RGB(86,0,0)',
    'RGB(234,152,200)',
    'RGB(207,207,207)',
    'RGB(255,137,206)',
    'RGB(199,48,208)',
    'RGB(97,143,208)',
    'RGB(3,190,255)',
    'RGB(0,132,83)',
    'RGB(131,213,32)',
    'RGB(115,66,41)',
    'RGB(233,130,162)',
    'RGB(255,255,255)',
  ];

  return (
    <>
      <Button
        onClick={() => {
          handleClick();
        }}
        className={classes.root}
        data-testid="admin.actions.mapBackgroundColor"
        style={{
          background: `rgba(${colorValue.r}, ${colorValue.g}, ${colorValue.b}, ${colorValue.a})`,
        }}
      >
        <PaletteIcon
          className={classes.button}
          style={{
            color: getIconColor(colorValue.r, colorValue.g, colorValue.b),
          }}
        />
      </Button>
      {displayColorPicker ? (
        <div
          className={classes.popright}
          data-testid={'mapColorPicker.popright'}
        >
          <div className={classes.cover} onClick={handleClose} />
          <CompactPicker
            className={classes.compactpicker}
            colors={customColors}
            color={colorValue}
            onChange={handleColorChange}
          />
        </div>
      ) : null}
    </>
  );
};

MapBackgroundColorPicker.displayName = 'MapBackgroundColorPicker';
export default MapBackgroundColorPicker;
