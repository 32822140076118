import { GeofenceData } from '../../types';

const geofences: GeofenceData[] = [
  {
    id: 1,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    geofenceId: 'aa722951-5301-4092-a46b-5f20a0b3199c',
    name: 'テスト-polygon',
    type: 'Polygon',
    lastUpdated: '2020-01-01T01:01:01.000Z',
    valid: true,
    alertType: 'Attention',
    dimension: 'ThreeDimension',
    transparency: true,
    rgbRed: 255,
    rgbGreen: 0,
    rgbBlue: 0,
    elevation: 40,
    height: 50,
    latLons: [
      [35.602779293, 140.085446529],
      [35.601663939, 140.083839705],
      [35.602503261, 140.082982332],
      [35.603861211, 140.084361251],
    ],
    points: [
      [12789.12527397556, -3982.56405113906],
      [12782.7372506508, -3983.28737054658],
      [12780.479943123264, -4002.73127413714],
      [12790.093917793303, -4014.01591938891],
      [12805.714054347132, -4014.15230935339],
      [12808.030177609267, -4017.50382367852],
      [12816.387574790828, -4023.31367946001],
      [12820.81631331128, -4019.94473983106],
      [12817.062865530424, -4009.88195261546],
      [12789.12527397556, -3982.56405113906],
    ],
    uploadedBy: 'Admin',
    geofenceFile: {
      src: 'http://example.com/polygon.gltf',
      title: 'polygon.gltf',
    },
  },
  {
    id: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    geofenceId: 'bb722951-5301-4092-a46b-5f20a0b3199d',
    name: 'テスト-circle',
    type: 'Circle',
    lastUpdated: '2020-01-02T02:01:01.000Z',
    valid: false,
    alertType: 'Attention',
    dimension: 'TwoDimension',
    trigger: 'In',
    transparency: false,
    rgbRed: 255,
    rgbGreen: 128,
    rgbBlue: 0,
    elevation: 10,
    height: 10,
    points: [[16054.203322826104, -6071.6214769686508]],
    radius: 363.3480126746,
    uploadedBy: 'Tablet',
  },
  {
    id: 3,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    geofenceId: '5f014e26-b10c-420a-878a-c7ae7acc0416',
    name: 'テスト-line',
    type: 'Line',
    lastUpdated: '2020-01-03T03:01:01.000Z',
    valid: true,
    alertType: 'Warning',
    dimension: 'TwoDimension',
    trigger: 'Out',
    transparency: false,
    rgbRed: 0,
    rgbGreen: 255,
    rgbBlue: 0,
    elevation: 10,
    height: 10,
    points: [
      [10494.544021553334, -5253.741139835507],
      [7404.647083090127, -7634.833912837778],
    ],
    uploadedBy: 'Admin',
    geofenceFile: {
      src: 'http://example.com/line.gltf',
      title: 'linee.gltf',
    },
  },
  /* Pointは対象外になったためコメントアウト
  {
    id: 4,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'テスト-point',
    type: 'Point',
    lastUpdated: '2020-01-04T04:01:01.000Z',
    valid: true,
    alertType: 'Caution',
    dimension: 'TwoDimension',
    transparency: false,
    rgbRed: 128,
    rgbGreen: 128,
    rgbBlue: 128,
    points: [[13968.963617171054, -3569.5588053183797]],
    elevation: 10,
    height: 10,
    uploadedBy: 'Admin',
    geofenceFile: {
      src: 'http://example.com/point.gltf',
      title: 'point.gltf',
    },
  },
  */
  {
    id: 5,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    geofenceId: 'ff72e660-9ab3-409e-b1b7-c2fb56b6e2d4',
    name: 'テスト-wall',
    type: 'Wall',
    lastUpdated: '2020-01-05T05:01:01.000Z',
    valid: false,
    alertType: 'Danger',
    dimension: 'ThreeDimension',
    transparency: true,
    rgbRed: 255,
    rgbGreen: 0,
    rgbBlue: 255,
    elevation: 10,
    height: 10,
    points: [
      [15968.904639828426, -3269.561116621689],
      [12969.258805291736, -4669.4476360163135],
    ],
    uploadedBy: 'Admin',
    geofenceFile: {
      src: 'http://example.com/wall.gltf',
      title: 'wall.gltf',
    },
  },
  {
    id: 6,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    geofenceId: 'd8d4ca48-1022-4bf6-b9ae-468ed3fd0085',
    name: 'テスト-polygon-2',
    type: 'Polygon',
    lastUpdated: '2020-01-01T01:01:01.000Z',
    valid: true,
    alertType: 'Attention',
    dimension: 'ThreeDimension',
    transparency: true,
    rgbRed: 0,
    rgbGreen: 0,
    rgbBlue: 255,
    elevation: 40,
    height: 50,
    points: [
      [12195.66193554064, -5605.552446522922],
      [9791.57590930091, -6154.376399581452],
      [10771.628506557794, -7250.510983157717],
      [14347.536230723163, -7112.305346076821],
    ],
    uploadedBy: 'Admin',
    geofenceFile: {
      src: 'http://example.com/polygon.gltf',
      title: 'polygon.gltf',
    },
  },
];

export default geofences;
