import {
  DataProvider,
  GetListParams,
  GetListResult,
  DeleteManyParams,
  DeleteManyResult,
} from 'react-admin';
import { getDataProvider, AccountData, RetrofitRentalData } from '.';

const getPendingRentals = async (
  dataProvider: DataProvider,
  params: GetListParams,
): Promise<GetListResult> => {
  const { getOne, getList } = dataProvider;

  // ログイン中のユーザーのメールアドレスと企業IDを取得
  const { data: accountData } = await getOne('accounts', { id: 1 });
  const { email, corporationId } = accountData as AccountData;

  // 承認待ちのレンタル情報を企業IDを指定して検索
  const corporationIdFilter: Partial<RetrofitRentalData> = {
    ...params.filter,
    isApproved: false,
    toCorporationId: corporationId,
  };
  const { data: rentalsByCorporation } = await getList('retrofitRentals', {
    sort: { field: 'id', order: 'ASC' },
    pagination: { page: 1, perPage: 100 },
    filter: corporationIdFilter,
  });

  // 承認待ちのレンタル情報をメールアドレスを指定して検索
  const emailFilter: Partial<RetrofitRentalData> = {
    ...params.filter,
    isApproved: false,
    email,
  };
  const { data: rentalsByEmail } = await getList('retrofitRentals', {
    sort: { field: 'id', order: 'ASC' },
    pagination: { page: 1, perPage: 100 },
    filter: emailFilter,
  });

  const data = [...rentalsByCorporation, ...rentalsByEmail];

  // ソート
  const { field, order } =
    params.sort.field === 'id'
      ? { field: 'planStartDate', order: 'ASC' }
      : params.sort;
  const sortData = [...data].sort((a, b) => {
    if (typeof a[field] === 'number') {
      return order === 'ASC' ? a[field] - b[field] : b[field] - a[field];
    }
    const strA = a[field] || '';
    const strB = b[field] || '';
    return order === 'ASC'
      ? strA.localeCompare(strB)
      : strB.localeCompare(strA);
  });

  // ページネーション
  const { page, perPage } = params.pagination;
  const start = (page - 1) * perPage;
  const end = start + perPage;
  const pageData = sortData.slice(start, end);
  return { total: data.length, data: pageData };
};

const setApproval = async (
  dataProvider: DataProvider,
  { ids }: DeleteManyParams,
): Promise<DeleteManyResult> => {
  // ユーザー所属企業の情報を取得
  const { data: accountData } = await dataProvider.getOne('accounts', {
    id: 1,
  });
  const { corporationId, corporationName } = accountData as AccountData;

  // 更新対象のレンタル情報を取得
  const { data: rentalData } = await dataProvider.getMany('retrofitRentals', {
    ids,
  });
  const rentals = rentalData as RetrofitRentalData[];
  await Promise.all(
    rentals.map(async rental => {
      // レンタル情報を更新
      await dataProvider.update('retrofitRentals', {
        id: rental.id,
        data: {
          ...rental,
          toCorporationId: corporationId,
          toCorporationName: corporationName,
          isApproved: true,
          actualStartDate: new Date().toISOString(),
        },
        previousData: rental,
      });
    }),
  );
  return { data: ids };
};

export default (): DataProvider => {
  const dataProvider = getDataProvider();
  return {
    getList: (_, params): any => getPendingRentals(dataProvider, params),
    getOne: (_, params): any => {
      return (async () => {
        const { data } = await getPendingRentals(dataProvider, {
          pagination: {
            page: 1,
            perPage: 1,
          },
          sort: {
            field: 'id',
            order: 'asc',
          },
          filter: { id: params.id },
        });
        return { data: data[0] };
      })();
    },
    getMany: () => {
      throw new Error('Not supported for pending rentals');
    },
    getManyReference: () => {
      throw new Error('Not supported for pending rentals');
    },
    update: () => {
      throw new Error('Not supported for pending rentals');
    },
    updateMany: () => {
      throw new Error('Not supported for pending rentals');
    },
    create: () => {
      throw new Error('Not supported for pending rentals');
    },
    delete: () => {
      throw new Error('Not supported for pending rentals');
    },
    deleteMany: (resource, params) => setApproval(dataProvider, params),
  };
};
