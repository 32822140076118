import React, { FC, useEffect, useState } from 'react';
import { Loading, useDataProvider, useRedirect, useVersion } from 'react-admin';
import {
  useAccount,
  useGetSelefDetailData,
  usePageProps,
} from '../../../../hooks';
import { RetrofitData, RetrofitRentalData } from '../../../../dataProvider';
import { RentalShow, RentalEdit } from './views';
import { getParentPath } from '../../../../utils';

const RetrofitRentalEdit: FC = () => {
  const props = usePageProps('retrofitRentals');
  const dataProvider = useDataProvider();
  const version = useVersion();
  const getAccount = useAccount();
  const redirectTo = useRedirect();
  const getSelefDetailData = useGetSelefDetailData();
  const { id: retrofitRentalId, basePath, resource } = props;

  const [apiState, setApiState] = useState<{
    loading: boolean;
    version: number;
    retrofitRentalId: string | undefined;
    data?: {
      retrofit: RetrofitData;
      isEditMode: boolean;
      actualStartDate: string | undefined;
      retrofitRentalId: number | undefined;
      isOwnData: boolean | undefined;
    };
  }>({ loading: true, version: -1, retrofitRentalId: undefined });

  useEffect(() => {
    if (
      apiState.version === version &&
      apiState.retrofitRentalId === retrofitRentalId
    )
      return;
    setApiState({ loading: true, version, retrofitRentalId });

    const api = async () => {
      // retrofitRentals
      const { data: retrofitRental } =
        await dataProvider.getOne<RetrofitRentalData>('retrofitRentals', {
          id: retrofitRentalId,
        });
      const {
        retrofitId,
        isReturned,
        actualStartDate,
        retrofitRentalId: parentRentalId,
      } = retrofitRental;

      // retrofitRentalのretrofitIdとurlのretrofitIdが一致するか判定
      const url = getParentPath(basePath).split('/');
      const urlRetrofitId = Number(url[2]);

      // retrofits
      const { data: retrofit } = await dataProvider.getOne<RetrofitData>(
        'retrofits',
        {
          id: retrofitId,
        },
      );

      // 自社のrentalIdか判定
      const {
        data: { corporationId },
      } = await getAccount();
      const filter = {
        retrofitId: urlRetrofitId,
        fromCorporationId: corporationId,
      };
      const rental = await getSelefDetailData(
        resource,
        filter,
        retrofitRentalId,
      );

      return {
        retrofit,
        isEditMode: !isReturned,
        actualStartDate,
        retrofitRentalId: parentRentalId,
        isOwnData: !!rental,
      };
    };

    api().then(data => {
      setApiState({
        loading: false,
        version,
        retrofitRentalId,
        data,
      });
    });
  }, [
    apiState,
    version,
    dataProvider,
    retrofitRentalId,
    basePath,
    resource,
    redirectTo,
    getAccount,
    getSelefDetailData,
  ]);

  if (apiState.loading || !apiState.data) return <Loading />;

  const {
    retrofit,
    isEditMode,
    actualStartDate,
    retrofitRentalId: parentRentalId,
    isOwnData,
  } = apiState.data;
  if (!isOwnData) redirectTo('/');

  return !isEditMode ? (
    <RentalShow props={props} retrofitData={retrofit} />
  ) : (
    <RentalEdit
      props={props}
      retrofitData={retrofit}
      actualStartDate={actualStartDate}
      retrofitRentalId={parentRentalId}
    />
  );
};

RetrofitRentalEdit.displayName = 'RetrofitRentalEdit';
export default RetrofitRentalEdit;
