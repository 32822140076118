import React, { FC } from 'react';
import { LinkButton } from '../../../..';
import { getParentPath } from '../../../../../utils';
import { ReturnIcon } from '../../../../../assets';

interface Props {
  basePath: string;
}

const CancelButton: FC<Partial<Props>> = props => {
  const { basePath = '' } = props;
  const to = getParentPath(basePath);
  return (
    <LinkButton path={to} label="ra.action.cancel">
      <ReturnIcon />
    </LinkButton>
  );
};

CancelButton.displayName = 'CancelButton';
export default CancelButton;
