import React, { FC, useState } from 'react';
import {
  Identifier,
  useNotify,
  useRefresh,
  useUnselectAll,
  useDataProvider,
  useLoading,
} from 'react-admin';
import { DoneButton, DialogOKOnly } from '../../..';
import { SaveIcon } from '../../../../assets';
import { FormState } from './BulkRegistrationSaveButtonState';

interface Props {
  ids: Identifier[];
  disabled: boolean;
  form: any;
  formState: FormState;
}

const BulkRegistrationSaveButton: FC<Props> = ({
  ids,
  disabled,
  form,
  formState,
}) => {
  const loading = useLoading();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();
  const notify = useNotify();

  const [errorDialog, setErrorDialog] = useState<{
    open: boolean;
    message: string;
  }>({ open: false, message: '' });

  const handleSave = () => {
    const retrofitDistributorId = form.getFieldState(
      'retrofitDistributorId',
    ).value;
    const corporationId = form.getFieldState('corporationId').value;
    const corporationName = form.getFieldState('corporationName').value;
    const retrofitRegionId = form.getFieldState('retrofitRegionId').value;

    // 企業ID/企業名の必須チェック処理
    if (!formState.disableCorporation && (!corporationId || !corporationName)) {
      setErrorDialog({
        open: true,
        message: 'admin.dialog.retrofitBasicInfoList.saveValidation.message',
      });
      return;
    }

    // 更新データの準備
    const retrofitDistributorData = formState.disableRetrofitDistributor
      ? {}
      : {
          retrofitDistributorId: retrofitDistributorId || null,
        };
    const corporationData = formState.disableCorporation
      ? {}
      : {
          corporationId,
          corporationName,
        };
    const retrofitRegionData = formState.disableRetrofitRegion
      ? {}
      : {
          retrofitRegionId: retrofitRegionId || null,
        };
    const updateData = {
      ...retrofitDistributorData,
      ...corporationData,
      ...retrofitRegionData,
    };

    // 一括更新
    dataProvider
      .updateMany('retrofitBasicInfos', {
        ids,
        data: updateData,
      })
      .then(() => {
        refresh();
        unselectAll('retrofitBasicInfos');
        notify('ra.notification.updated', 'info', {
          smart_count: ids.length,
        });
      })
      .catch((error: Error) => {
        notify(`Error: ${error.message}`, 'warning');
      });
  };

  return (
    <>
      <DoneButton
        disabled={disabled || loading}
        label="admin.actions.bulkRegistrationOK"
        onClick={handleSave}
      >
        <SaveIcon />
      </DoneButton>
      <DialogOKOnly
        open={errorDialog.open}
        onClose={() => setErrorDialog({ open: false, message: '' })}
        title=""
        message={errorDialog.message}
      />
    </>
  );
};

BulkRegistrationSaveButton.displayName = 'BulkRegistrationSaveButton';
export default BulkRegistrationSaveButton;
