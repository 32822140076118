import { SelectArrayInput, ChoicesProps, InputProps } from 'react-admin';
import { TextFieldProps } from '@material-ui/core/TextField';
import { ResourceFC } from '..';

SelectArrayInput.displayName = 'SelectArrayInput';
export default SelectArrayInput as ResourceFC<
  Partial<
    ChoicesProps &
      InputProps<TextFieldProps> &
      Omit<TextFieldProps, 'label' | 'helperText'>
  >
>;
