import React from 'react';
import {
  Datagrid,
  TextField,
  Filter,
  SiteSearchInput,
  CustomList,
  LinkButton,
} from '../..';
import { AddIcon } from '../../../assets';

const ListFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

const Actions: React.FC<{
  basePath?: string;
}> = ({ basePath }) => (
  <LinkButton path={`${basePath}/create`} label="admin.actions.add">
    <AddIcon />
  </LinkButton>
);

const FirmwareList: React.FC = props => {
  return (
    <CustomList
      filters={<ListFilter />}
      {...props}
      title="admin.pages.firmwareList"
      actions={<Actions />}
    >
      <Datagrid rowClick="show">
        <TextField resource="firmwares" source="version" />
        <TextField resource="firmwares" source="compatibilityLevel" />
      </Datagrid>
    </CustomList>
  );
};

FirmwareList.displayName = 'FirmwareList';
export default FirmwareList;
