import { Tile as TileLayer, Image as ImageLayer } from 'ol/layer';
import { TileWMS, ImageWMS } from 'ol/source';
import { ImageTile } from 'ol';
import { config } from '../../utils';

const createTileLayer = (
  params: object,
  token: string,
  name: string,
  visibility = true,
) =>
  new TileLayer({
    className: name,
    source: new TileWMS({
      url: config.url.mapServer,
      params,
      serverType: 'mapserver',
      crossOrigin: 'anonymous',
      reprojectionErrorThreshold: 1.5,
      tileLoadFunction: (tile, src) => {
        // d.tsが間違っている。第一引数はTileではなく、ImageTile
        const imageTile = tile as ImageTile;
        fetch(src, {
          headers: {
            authorization: token,
          },
        })
          .then(res => res.blob())
          .then(blob => {
            const image = imageTile.getImage();
            if ('src' in image) image.src = URL.createObjectURL(blob);
          });
      },
    }),
    visible: visibility,
  });
const createImageLayer = (
  params: object,
  token: string,
  name: string,
  visibility = true,
) =>
  new ImageLayer({
    className: name,
    source: new ImageWMS({
      url: config.url.mapServer,
      params,
      serverType: 'mapserver',
      crossOrigin: 'anonymous',
      imageLoadFunction: (tile, src) => {
        // d.tsが間違っている。第一引数はTileではなく、ImageTile
        fetch(src, {
          headers: {
            authorization: token,
          },
        })
          .then(res => res.blob())
          .then(blob => {
            const image = tile.getImage();
            if ('src' in image) image.src = URL.createObjectURL(blob);
          });
      },
    }),
    visible: visibility,
  });

const createProjectSourceLayer = (
  id: number,
  versionId: number,
  token: string,
) =>
  createTileLayer(
    {
      layers: ['face_fill', 'face_line'],
      transparent: false,
      layer_id: id,
      version_id: versionId,
    },
    token,
    'mapserverFaceLayer',
  );

const createProjectSourceLineWork = (
  id: number,
  versionId: number,
  token: string,
) =>
  createTileLayer(
    {
      layers: ['line_xdf_smooth_on'],
      transparent: false,
      linework_id: id,
      version_id: versionId,
    },
    token,
    'mapserverLineworkLayer',
  );

const createProjectSourceLayerLabelPoint = (versionId: number, token: string) =>
  createImageLayer(
    {
      layers: ['label_point'],
      transparent: false,
      version_id: versionId,
    },
    token,
    'mapserverLabelPointkLayer',
  );

const createProjectSourceLayerPointLabel = (versionId: number, token: string) =>
  createImageLayer(
    {
      layers: ['point_label'],
      transparent: false,
      version_id: versionId,
    },
    token,
    'mapserverPointLabelkLayer',
  );

const createProjectSourceLocationPoint = (versionId: number, token: string) =>
  createImageLayer(
    {
      layers: ['location_point'],
      transparent: false,
      version_id: versionId,
    },
    token,
    'mapserverLocationPointLayer',
  );

const createProjectSourceLocationLabel = (versionId: number, token: string) =>
  createImageLayer(
    {
      layers: ['location_label'],
      transparent: false,
      version_id: versionId,
    },
    token,
    'mapserverLocationLabelLayer',
  );

const createProjectSourceLayerEPSG3857 = (
  id: number,
  versionId: number,
  token: string,
  visibility?: boolean,
) =>
  createTileLayer(
    {
      layers: ['face_fill_EPSG3857', 'face_line_EPSG3857'],
      transparent: false,
      layer_id: id,
      version_id: versionId,
    },
    token,
    'mapserverFaceLayer',
    visibility,
  );

const createProjectSourceLineWorkEPSG3857 = (
  id: number,
  versionId: number,
  token: string,
  visibility?: boolean,
) =>
  createTileLayer(
    {
      layers: ['line_xdf_smooth_on_epsg3857'],
      transparent: false,
      linework_id: id,
      version_id: versionId,
    },
    token,
    'mapserverLineworkLayer',
    visibility,
  );

const createProjectSourceLayerLabelPointEPSG3857 = (
  versionId: number,
  token: string,
  visibility?: boolean,
) =>
  createImageLayer(
    {
      layers: ['label_point_EPSG3857'],
      transparent: false,
      version_id: versionId,
    },
    token,
    'mapserverLabelPointkLayer',
    visibility,
  );

const createProjectSourceLayerPointLabelEPSG3857 = (
  versionId: number,
  token: string,
  visibility?: boolean,
) =>
  createImageLayer(
    {
      layers: ['point_label_EPSG3857'],
      transparent: false,
      version_id: versionId,
    },
    token,
    'mapserverPointLabelkLayer',
    visibility,
  );

const createProjectSourceLocationPointEPSG3857 = (
  versionId: number,
  token: string,
  visibility?: boolean,
) =>
  createImageLayer(
    {
      layers: ['location_point_EPSG3857'],
      transparent: false,
      version_id: versionId,
    },
    token,
    'mapserverLocationPointLayer',
    visibility,
  );

const createProjectSourceLocationLabelEPSG3857 = (
  versionId: number,
  token: string,
  visibility?: boolean,
) =>
  createImageLayer(
    {
      layers: ['location_label_EPSG3857'],
      transparent: false,
      version_id: versionId,
    },
    token,
    'mapserverLocationLabelLayer',
    visibility,
  );

export {
  createProjectSourceLayer,
  createProjectSourceLineWork,
  createProjectSourceLayerLabelPoint,
  createProjectSourceLayerPointLabel,
  createProjectSourceLocationPoint,
  createProjectSourceLocationLabel,
  createProjectSourceLayerEPSG3857,
  createProjectSourceLayerLabelPointEPSG3857,
  createProjectSourceLayerPointLabelEPSG3857,
  createProjectSourceLocationPointEPSG3857,
  createProjectSourceLocationLabelEPSG3857,
  createProjectSourceLineWorkEPSG3857,
};
