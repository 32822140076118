import React, { FC, useState } from 'react';
import {
  ArrayInput,
  Loading,
  required,
  email as emailValidation,
  TextInput,
} from 'react-admin';
import { createStyles, makeStyles } from '@material-ui/core';
import {
  CustomForm,
  Divider,
  Button,
  SimpleFormIterator,
  CustomerAutoCompleteInput,
  LendCorporationData,
} from '../..';
import {
  useResource,
  useGetAccount,
  useConfirmArrayValidation,
} from '../../../hooks';
import { styles } from '../../../theme';
import { inputPasswordWidth } from '../../../theme/define';
import { RetrofitSsidImage, AddIcon, DeleteIcon } from '../../../assets';
import { CreateTextSaveButton as SaveButton } from './views';
import { CustomerData } from '../../../dataProvider/types';
import RetrofitTextInput from '../../organisms/RetrofitTextInput';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 14,
    },
    divider: {
      marginTop: 8,
      marginBottom: 12,
      backgroundColor: 'white',
    },
    description: {
      ...styles.multilineText,
    },
    corporationId: {
      ...styles.longWidthText,
    },
    icon: {
      marginTop: 24,
    },
    arrayInput: {
      marginTop: 0,
      marginBottom: 0,
      '& > label': {
        // ArrayInput の Label をCSSで非表示にする (TextInput のように addLabel が用意されていない為)
        display: 'none',
      },
      '& > ul': {
        width: '100%',
      },
      '& > ul > li > p': {
        display: 'none',
      },
    },
    confirm: {
      '& .MuiInputBase-input': {
        minWidth: inputPasswordWidth,
      },
    },
  }),
);

const useIteratorAddButtonStyle = makeStyles(() =>
  createStyles({
    button: {
      ...styles.buttonIteratorAdd,
      marginLeft: 148,
    },
  }),
);

const useIteratorAddButton = (): JSX.Element => {
  const classes = useIteratorAddButtonStyle();
  return (
    <Button classes={classes} onClick={e => e.preventDefault()}>
      <AddIcon />
    </Button>
  );
};

const useIteratorDeleteButtonStyle = makeStyles(() =>
  createStyles({
    button: {
      ...styles.buttonIteratorDelete,
    },
  }),
);

const useIteratorDeleteButton = (): JSX.Element => {
  const classes = useIteratorDeleteButtonStyle();
  return (
    <Button classes={classes} onClick={e => e.preventDefault()}>
      <DeleteIcon />
    </Button>
  );
};

const RetrofitSsidFiled: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.icon}>
      <RetrofitSsidImage />
    </div>
  );
};
// メールアドレスに全角文字がないかチェック
const halfWidthValidation =
  (message = 'ra.validation.email') =>
  (value: any) =>
    typeof value === 'string' &&
    // eslint-disable-next-line no-control-regex
    /([^\x01-\x7E\uFF65-\uFF9F]|[ｦ-ﾟ])+/.test(value)
      ? message
      : undefined;

const RetrofitAlternateRegistCreateText: FC = props => {
  const classes = useStyles();
  let retrofitId;
  const addButton = useIteratorAddButton();
  const deleteButton = useIteratorDeleteButton();
  const [customer, setCustomer] = useState<LendCorporationData | null>(null);
  const resource = useResource('retrofitAlternateRegists');
  const { getAccount } = useGetAccount();
  const accountData = getAccount();
  const confirmArrayValidation = useConfirmArrayValidation(
    'retrofitsSerial',
    'basicInfoSerialNumber',
    'admin.message.retrofitsAlternateRegistText.serialNumberMatchError',
  );

  const [disableAddSerial, setDisableAddSerial] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const formValidation = (value: any) => {
    // 言語の追加制限制御
    const formData = value;
    if (formData && formData.retrofitsSerial) {
      setDisableAddSerial(formData.retrofitsSerial?.length >= 5);
    }

    const basicInfoSerials = formData.retrofitsSerial?.map((it: any) =>
      it?.basicInfoSerialNumber ? it.basicInfoSerialNumber : undefined,
    );
    const confirmSerials = formData.retrofitsSerial?.map((it: any) =>
      it?.confirmInput ? it.confirmInput : undefined,
    );

    // 入力欄が1つでも初期状態であれば保存ボタン非活性
    const isValueDefault =
      basicInfoSerials?.some(
        (it: string) => it === undefined || it === 'Retro-',
      ) ||
      confirmSerials?.some((it: string) => it === undefined || it === 'Retro-');

    // 入力欄と確認用入力欄が完全一致しない場合は保存ボタンを非活性
    const isUnMatched =
      JSON.stringify(basicInfoSerials) !== JSON.stringify(confirmSerials);

    // 保存ボタンの活性状態制御
    if (
      isValueDefault ||
      isUnMatched ||
      formData.retrofitsSerial?.length === 0
    ) {
      setSaveButtonDisabled(true);
    } else {
      setSaveButtonDisabled(false);
    }

    return {};
  };

  if (!accountData) {
    return <Loading />;
  }
  const { corporationId, corporationName, name, email } = accountData;

  return (
    <CustomForm
      className={classes.root}
      resource={resource}
      redirect={false}
      saveButton={
        <SaveButton
          customer={customer}
          saveButtonDisabled={saveButtonDisabled}
          {...props}
        />
      }
      deleteButton={false}
      cancelButton={false}
      title="admin.pages.retrofitAlternateRegistCreate"
      validate={formValidation}
      initialValues={
        {
          retrofitId,
          fromCorporationId: corporationId,
          fromCorporationName: corporationName,
          fromApplicantId: email,
          fromApplicantName: name,
          toCorporationId: '',
          toCorporationName: '',
          toMailAddress: '',
        } as any
      }
      {...props}
    >
      <CustomerAutoCompleteInput
        label="resources.retrofitAlternateRegists.fields.toCorporationName"
        resource={resource}
        source="toCorporationId"
        fromCorporationId={corporationId}
        onNotifyChange={(value: CustomerData) => setCustomer(value)}
      />
      <TextInput
        label="resources.retrofitAlternateRegists.fields.email"
        data-testid="toMailAddress"
        resource={resource}
        source="toMailAddress"
        validate={[required(), emailValidation(), halfWidthValidation()]}
      />
      <Divider className={classes.divider} />
      <RetrofitSsidFiled />
      <ArrayInput
        source="retrofitsSerial"
        label=""
        className={classes.arrayInput}
        defaultValue={[
          { basicInfoSerialNumber: 'Retro-', confirmInput: 'Retro-' },
        ]}
      >
        <SimpleFormIterator
          disableAdd={disableAddSerial}
          addButton={addButton}
          removeButton={deleteButton}
        >
          <RetrofitTextInput
            source="basicInfoSerialNumber"
            label="admin.label.retrofitAlternateRegists.basicInfoSerialNumber"
            validate={required()}
          />
          <RetrofitTextInput
            source="confirmInput"
            label="admin.label.retrofitAlternateRegists.confirmInput"
            validate={[required(), confirmArrayValidation]}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </CustomForm>
  );
};

RetrofitAlternateRegistCreateText.displayName =
  'RetrofitAlternateRegistCreateText';
export default RetrofitAlternateRegistCreateText;
