import { GetStartRemoteApiData } from '../../types';

const startremote: GetStartRemoteApiData[] = [
  {
    id: '484520296778',
    remoteStatus: 'WaitingToStart',
    statusComment: '1000',
    remoteSessionId: 'remotesession7788222xxxxx431',
    remoteSupportUrl:
      'https://ictremote-adminweb.komconnect.komatsu/operators/support?remote_session_id=remotesession7788222xxxxx431',
  },
  {
    id: '484520296779',
    remoteStatus: 'WaitingToStart',
    statusComment: '1000',
    remoteSessionId: 'remotesession7788222xxxxx432',
    remoteSupportUrl:
      'https://ictremote-adminweb.komconnect.komatsu/operators/support?remote_session_id=remotesession7788222xxxxx432',
  },
  {
    id: '847BEB10B0A3',
    remoteStatus: 'WaitingToStart',
    statusComment: '1000',
    remoteSessionId: 'remotesession7788222xxxxx433',
    remoteSupportUrl:
      'https://ictremote-adminweb.komconnect.komatsu/operators/support?remote_session_id=remotesession7788222xxxxx433',
  },
];
export default startremote;
