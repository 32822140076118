import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  useDataProvider,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import {
  useMap,
  MapProps,
  setOverlayRender,
  useGetUnitLength,
} from '../../../../hooks';
import { MapController } from '../map';
import { GeofenceData, GeofenceType } from '../../../../dataProvider';
import { colors } from '../../../../theme';
import GeofenceInputDialog from './GeofenceInputDialog';
import { LonLat } from '../../../../utils';
import {
  checkIntersectByArray,
  checkSameValueByArray,
  checkAreaZeroByArray,
} from '../../../organisms';

export interface GeofenceMapFieldProps {
  mapController: MapController;
  visible: boolean;
  rerender?: boolean;
  record?: GeofenceData;
  width?: number;
  height?: number;
  mapBackgroundColor?: string;
  featureId?: number;
  readOnly?: boolean;
  label?: string;
  addLabel?: boolean;
  isRequired?: boolean;
  onConfigure?: (mapProps: MapProps) => void;
  onModify?: () => void;
}

const useGeofenceListShowToolTip = () => {
  const translate = useTranslate();
  return useCallback(
    res => {
      return translate(res);
    },
    [translate],
  );
};

const useGeofenceListUnitLength = () => {
  const translate = useTranslate();
  return useCallback(
    unitLength => {
      // toDisplayUnitLength関数を使用するとジオフェンスの表示するタイミングがずれるため、直接条件分岐でunitLengthを判断
      let unit;
      if (unitLength === 'M') {
        unit = 'm';
      } else if (unitLength === 'Ft') {
        unit = 'ft';
      } else if (unitLength === 'UsFt') {
        unit = 'Usft';
      } else {
        console.error(`Illegal unit: ${unitLength}`);
        unit = unitLength;
      }
      return translate('admin.label.geofences.unitLength', {
        unit,
      });
    },
    [translate],
  );
};

const GeofenceMapField: FC<GeofenceMapFieldProps> = ({
  mapController,
  record,
  visible,
  onConfigure,
  onModify,
  width = 812,
  height = 365,
  mapBackgroundColor = '#ffffff', // white
  featureId = 0,
  readOnly = false,
  rerender = false,
}) => {
  const { props: mapProps } = useMap(undefined, false);
  const translate = useTranslate();
  const redirectTo = useRedirect();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const geofenceListShowToolTip = useGeofenceListShowToolTip();
  const geofenceListShowUnitLength = useGeofenceListUnitLength();
  const recordString = record ? JSON.stringify(record) : undefined;
  const geofenceType: GeofenceType = mapController.getSelectedType();
  const [geofenceInput, setGeofenceInput] = useState<{
    open: boolean;
    lonLat: LonLat[];
    radius?: number;
  }>({ open: false, lonLat: [] });
  const { siteId } = useParams<{ siteId: string }>();
  const {
    data: { unitLength },
  } = useGetUnitLength({ siteId });
  const unit = unitLength;

  // --------------------------------------------
  // 入力ダイアログの表示イベント
  // --------------------------------------------
  const handleShow = useCallback(
    async (lonLatArray: LonLat[], radius?: number) => {
      if (lonLatArray.length <= 0) {
        setGeofenceInput({ ...geofenceInput, open: true, lonLat: [], radius });
        return;
      }
      // 入力ダイアログのパラメータを設定
      setGeofenceInput({
        ...geofenceInput,
        open: true,
        lonLat: lonLatArray,
        radius,
      });
    },
    [geofenceInput],
  );

  // --------------------------------------------
  // 入力ダイアログの非表示イベント
  // --------------------------------------------
  const handleHide = useCallback(
    (lonLat: LonLat[] | undefined, radius?: number) => {
      if (mapProps && lonLat) {
        if (onModify) onModify();
        // Map 上のポリゴンを更新
        mapController.update(lonLat, radius);
      } else {
        mapController.setModify();
      }
      // 入力ダイアログのパラメータを設定
      setGeofenceInput({ ...geofenceInput, open: false, radius });
    },
    [mapProps, mapController, geofenceInput, onModify],
  );

  // --------------------------------------------
  // 入力ダイアログ確定前のポリゴン交差チェック
  // --------------------------------------------
  const handleIntersectCheck = useCallback((lonLat: LonLat[]) => {
    // 末尾に先頭要素を追加する対応
    const newLonLat = lonLat.length > 0 ? [...lonLat, lonLat[0]] : [];
    // 引数用の配列型に変換
    const checkArray = newLonLat.map(({ lon, lat }) => [lon, lat]);
    // 交差チェック
    return checkIntersectByArray(checkArray);
  }, []);

  // --------------------------------------------
  // 入力ダイアログ確定前の同一座標チェック
  // --------------------------------------------
  const handleSameValueCheck = useCallback((lonLat: LonLat[]) => {
    // 引数用の配列型に変換
    const checkArray = lonLat.map(({ lon, lat }) => [lon, lat]);
    // 同一チェック
    return checkSameValueByArray(checkArray);
  }, []);

  // --------------------------------------------
  // 入力ダイアログ確定前の面積ゼロチェック
  // --------------------------------------------
  const handleAreaZeroCheck = useCallback((lonLat: LonLat[]) => {
    // 末尾に先頭要素を追加する対応
    const newLonLat = lonLat.length > 0 ? [...lonLat, lonLat[0]] : [];
    // 引数用の配列型に変換
    const checkArray = newLonLat.map(({ lon, lat }) => [lon, lat]);
    // 面積ゼロチェック
    return checkAreaZeroByArray(checkArray);
  }, []);

  // 以下の useEffect は、Map を初期化する為のイベント
  useEffect(() => {
    if (onConfigure) onConfigure(mapProps);

    // useTranslateはReactコンポーネントでしか動作しないため、詳細コンポーネントで使用するラベルはここで翻訳
    const labelInfoList = {
      title: translate('admin.label.geofences.select'),
      name: translate('resources.geofences.fields.name'),
      type: translate('resources.geofences.fields.type'),
      dimension: translate('resources.geofences.fields.dimension'),
      valid: translate('resources.geofences.fields.valid'),
      alertType: translate('resources.geofences.fields.alertType'),
      lastUpdated: translate('resources.geofences.fields.lastUpdated'),
      deleteDialogMessage: translate(
        'admin.dialog.geofenceList.confirmDelete.message',
      ),
      coordinatesLabel: translate('admin.label.geofences.coordinatesLabel'),
      unitLengthLabel: geofenceListShowUnitLength,
      listShowToolTip: geofenceListShowToolTip,
    };
    const labelInfoInput = {
      coordinates: translate('admin.label.geofences.coordinates'),
      elevation: translate('resources.geofences.fields.elevation'),
      height: translate('resources.geofences.fields.height'),
      radius: translate('resources.geofences.fields.radius'),
      thickness: translate('resources.geofences.fields.thickness'),
      trigger: translate('resources.geofences.fields.trigger'),
      coordinatesLabel: translate('admin.label.geofences.coordinatesLabel'),
      reqiredLabel: translate('admin.actions.required'),
      unitLengthLabel: geofenceListShowUnitLength,
    };

    // hooksはReactコンポーネントでしか動作しないため、一覧のedit,deleteコンポーネントのイベントはここで作成
    const onClickListEdit = (editRecord: any) => {
      redirectTo(`geofences/${editRecord.id}`);
    };
    const onClickListDelete = (deleteRecord: any) => {
      dataProvider
        .delete('geofences', {
          id: deleteRecord.id,
          previousData: deleteRecord,
        })
        .then(() => {
          refresh();
        });
    };

    mapProps.map.setTarget('map');
    mapController.bindMap({
      mapProps,
      featureId,
      readOnly,
      labelInfoList,
      labelInfoInput,
      record: recordString ? JSON.parse(recordString) : undefined,
      onClickListEdit,
      onClickListDelete,
      onModify,
      onShowInput: handleShow,
    });
    setOverlayRender(true);
    return () => {
      // clean up
      setOverlayRender(false);
      mapProps.map.setTarget('');
    };
  }, [
    mapProps,
    mapController,
    onConfigure,
    featureId,
    readOnly,
    translate,
    recordString,
    geofenceListShowUnitLength,
    redirectTo,
    dataProvider,
    refresh,
    geofenceListShowToolTip,
    onModify,
    visible,
    rerender,
    handleShow,
  ]);
  return (
    <>
      {visible ? (
        <div
          id="map"
          data-testid="map"
          style={{ width, height, backgroundColor: mapBackgroundColor }}
        />
      ) : (
        false
      )}
      {visible ? (
        <GeofenceInputDialog
          open={geofenceInput.open}
          onClose={handleHide}
          onIntersectCheck={handleIntersectCheck}
          onSameValueCheck={handleSameValueCheck}
          onAreaZeroCheck={handleAreaZeroCheck}
          lonLat={geofenceInput.lonLat}
          geofenceType={geofenceType}
          radius={geofenceInput.radius}
          unit={unit}
        />
      ) : (
        false
      )}
      {visible ? (
        false
      ) : (
        <div
          style={{
            width: '100%',
            height,
            background: colors.backgroud,
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      )}
    </>
  );
};

GeofenceMapField.defaultProps = {
  label: 'resources.geofences.fields.points',
  addLabel: true,
  isRequired: true,
};
GeofenceMapField.displayName = 'GeofenceMapField';
export default GeofenceMapField;
