import { RetrofitConfigData } from '../../types';

const retrofitConfigs: RetrofitConfigData[] = [
  {
    id: '6_2020-02-01T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/6.json',
  },
  {
    id: '7_2020-02-01T08:00:02.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:02.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '8_2020-02-01T08:00:03.000Z',
    retrofitId: 8,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:03.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/8.json',
  },
  {
    id: '9_2020-02-01T08:00:04.000Z',
    retrofitId: 9,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:04.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/9.json',
  },
  {
    id: '10_2020-02-01T08:00:05.000Z',
    retrofitId: 10,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:05.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/10.json',
  },
  {
    id: '6_2020-01-31T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-31T08:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/6.json',
  },
  {
    id: '7_2020-01-31T08:00:02.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-31T08:00:02.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '8_2020-01-31T08:00:03.000Z',
    retrofitId: 8,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-31T08:00:03.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/8.json',
  },
  {
    id: '9_2020-01-31T08:00:04.000Z',
    retrofitId: 9,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-31T08:00:04.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/9.json',
  },
  {
    id: '10_2020-01-31T08:00:05.000Z',
    retrofitId: 10,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-31T08:00:05.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/10.json',
  },
  {
    id: '6_2020-01-30T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-30T08:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/6.json',
  },
  {
    id: '7_2020-01-30T08:00:02.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-30T08:00:02.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '8_2020-01-30T08:00:03.000Z',
    retrofitId: 8,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-30T08:00:03.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/8.json',
  },
  {
    id: '9_2020-01-30T08:00:04.000Z',
    retrofitId: 9,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-30T08:00:04.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/9.json',
  },
  {
    id: '10_2020-01-30T08:00:05.000Z',
    retrofitId: 10,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-30T08:00:05.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/10.json',
  },
  {
    id: '6_2020-01-29T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-29T08:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/6.json',
  },
  {
    id: '7_2020-01-29T08:00:02.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-29T08:00:02.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '8_2020-01-29T08:00:03.000Z',
    retrofitId: 8,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-29T08:00:03.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/8.json',
  },
  {
    id: '9_2020-01-29T08:00:04.000Z',
    retrofitId: 9,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-29T08:00:04.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/9.json',
  },
  {
    id: '10_2020-01-29T08:00:05.000Z',
    retrofitId: 10,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-29T08:00:05.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/10.json',
  },
  {
    id: '11_2020-03-06T01:06:01.000Z',
    retrofitId: 11,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-03-06T01:06:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/11.json',
  },
  {
    id: '12_2020-03-06T01:07:01.000Z',
    retrofitId: 12,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-03-06T01:07:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/12.json',
  },
  {
    id: '13_2020-03-06T01:08:01.000Z',
    retrofitId: 13,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-03-06T01:08:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/13.json',
  },
  {
    id: '14_2020-03-06T01:09:01.000Z',
    retrofitId: 14,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-03-06T01:09:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/14.json',
  },
  {
    id: '15_2020-03-06T01:10:01.000Z',
    retrofitId: 15,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-03-06T01:10:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/15.json',
  },
  {
    id: '16_2020-02-01T08:00:01.000Z',
    retrofitId: 16,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/16.json',
  },
  {
    id: '17_2020-02-01T08:00:02.000Z',
    retrofitId: 17,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:02.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/17.json',
  },
  {
    id: '18_2020-02-01T08:00:03.000Z',
    retrofitId: 18,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:03.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/18.json',
  },
  {
    id: '19_2020-02-01T08:00:04.000Z',
    retrofitId: 19,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:04.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/19.json',
  },
  {
    id: '20_2020-02-01T08:00:05.000Z',
    retrofitId: 20,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:05.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/20.json',
  },
  {
    id: '21_2020-02-01T08:00:01.000Z',
    retrofitId: 21,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/21.json',
  },
  {
    id: '22_2020-02-01T08:00:02.000Z',
    retrofitId: 22,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:02.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/22.json',
  },
  {
    id: '23_2020-02-01T08:00:03.000Z',
    retrofitId: 23,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:03.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/23.json',
  },
  {
    id: '24_2020-02-01T08:00:04.000Z',
    retrofitId: 24,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:04.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/24.json',
  },
  {
    id: '25_2020-02-01T08:00:05.000Z',
    retrofitId: 25,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:05.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/25.json',
  },
  {
    id: '26_2020-02-01T08:00:01.000Z',
    retrofitId: 26,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/26.json',
  },
  {
    id: '27_2020-02-01T08:00:02.000Z',
    retrofitId: 27,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:02.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/27.json',
  },
  {
    id: '28_2020-02-01T08:00:03.000Z',
    retrofitId: 28,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:03.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/28.json',
  },
  {
    id: '29_2020-02-01T08:00:04.000Z',
    retrofitId: 29,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:04.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/29.json',
  },
  {
    id: '30_2020-02-01T08:00:05.000Z',
    retrofitId: 30,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:05.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/30.json',
  },
  {
    id: '7_2020-02-02T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-02T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-03T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-03T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-04T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-04T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-05T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-05T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-06T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-06T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-07T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-07T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-08T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-08T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-09T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-09T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-10T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-10T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-11T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-11T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-12T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-12T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-13T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-13T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-14T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-14T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-15T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-15T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-16T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-16T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-17T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-17T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-18T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-18T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-19T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-19T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-20T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-20T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-21T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-21T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-22T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-22T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-23T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-23T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-24T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-24T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-25T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-25T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-26T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-26T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-27T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-27T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-28T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-28T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
  {
    id: '7_2020-02-29T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-29T00:00:01.000Z',
    url: 'https://3dmg-froentend-site-data.s3-ap-northeast-1.amazonaws.com/ConfigurationInfo/7.json',
  },
];

export default retrofitConfigs;
