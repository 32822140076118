import { RetrofitCalibsData } from '../../types';

const retrofitCalibs: RetrofitCalibsData[] = [
  {
    id: '6_2020-02-01T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/6.json',
    detailInfo: {
      createDateTime: '2023-11-17T00:00:00Z',
      updateDateTime: '2023-08-30T00:49:51Z',
      machineInfo: {
        companyName: 'QUALICA',
        machineType: 'Excavator',
        machineName: 'PC200-001',
        machineId: 'EX01',
      },
      machineGeometryInfo: {
        boomLength: 5.70249906520008669,
        secondBoomLength: 123,
        armLength: 2.92753986694484161234512345,
        armTopPinToBucketSideLinkPinLength: 0.4109734996444172112345,
        boomTopPinToBucketSideLinkPinLength: 2.517871691367726212345,
        bucketLink1Length: 0.643,
        bucketLink2Length: 0.6,
        bodyCenterToBoomFootPin: {
          x: 0.09,
          y: -0.04,
          z: 0.8,
        },
      },
      imuMountInfo: {
        boomImuOffsetAngle: 5.99318546520545,
        secondBoomImuOffsetAngle: 123,
        armImuOffsetAngle: -2.218074577532557,
        bucketImuOffsetAngle: 0.34724717288933621,
        bodyImuOffsetRollAngle: 0.00385,
        bodyImuOffsetPitchAngle: 0.236116666666667,
      },
      gnssMountInfo: {
        main: {
          bodyCenterToGnss: {
            x: -1.2332137107663126,
            y: 1.1021617115397064,
            z: 2.1462996825967142,
          },
        },
        sub: {
          bodyCenterToGnss: {
            x: -0.76781890578631262,
            y: -1.0869202872546941,
            z: 2.1159678001967142,
          },
        },
      },
      extendedModel: {
        swingBoom: true,
        twoPieceBoom: false,
        minimalSwingRadius: false,
      },
      measuredValues: {
        otherLength: {
          machineHeight: 1.08,
        },
      },
    },
  },
  {
    id: '6_2020-02-02T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-02T08:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/6.json',
    detailInfo: {
      createDateTime: '2023-11-17T00:00:00Z',
      updateDateTime: '2023-08-30T00:49:51Z',
      machineInfo: {
        companyName: 'QUALICA',
        machineType: 'Dozer',
        machineName: 'PC200-001',
        machineId: 'EX01',
        drumConfiguration: '',
        guidanceMode: 'Body',
      },
      machineGeometryInfo: {
        drumWidth: 1,
        length: 2,
        shoeInterval: 3,
        bladeWidthForDozer: 4.0,
        distanceFromBodyToBlade: 5,
        frontToMainGnssAntenna: 6,
        frontToSubGnssAntenna: 7,
        centerToMainGnssAntenna: 8,
        centerToSubGnssAntenna: 9,
        groundToMainGnssAntenna: 10,
        groundToSubGnssAntenna: 11,
      },
      imuMountInfo: {
        bodyImuOffsetRollAngle: 12,
        bodyImuOffsetPitchAngle: 13,
      },
      gnssMountInfo: {
        main: { bodyCenterToGnss: { x: 14, y: 15, z: 16 } },
        sub: { bodyCenterToGnss: { x: 17, y: 18, z: 19 } },
      },
      measuredValues: {
        bodyImu1: { roll: 20, pitch: 21, yaw: 22 },
        bodyImu2: { roll: 23, pitch: 24, yaw: 25 },
        frontLeft: { x: 26, y: 27, z: 28 },
        frontRight: { x: 29, y: 30, z: 31 },
        frontGroundingEnd: { x: 32, y: 33, z: 34 },
        rearGroundingEnd: { x: 35, y: 36, z: 37 },
        mainGnssAntenna: { x: 38, y: 39, z: 40 },
        subGnssAntenna: { x: 41, y: 42, z: 43 },
        prismPoleOffset: 44,
        antennaElectricalCenterHeight: 45,
      },
    },
  },
  {
    id: '7_2020-02-01T08:00:02.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:02.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '8_2020-02-01T08:00:03.000Z',
    retrofitId: 8,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:03.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/8.json',
  },
  {
    id: '9_2020-02-01T08:00:04.000Z',
    retrofitId: 9,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:04.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/9.json',
  },
  {
    id: '10_2020-02-01T08:00:05.000Z',
    retrofitId: 10,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:05.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/10.json',
  },
  {
    id: '6_2020-01-31T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-31T08:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/6.json',
  },
  {
    id: '7_2020-01-31T08:00:02.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-31T08:00:02.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '8_2020-01-31T08:00:03.000Z',
    retrofitId: 8,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-31T08:00:03.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/8.json',
  },
  {
    id: '9_2020-01-31T08:00:04.000Z',
    retrofitId: 9,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-31T08:00:04.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/9.json',
  },
  {
    id: '10_2020-01-31T08:00:05.000Z',
    retrofitId: 10,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-31T08:00:05.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/10.json',
  },
  {
    id: '6_2020-01-30T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-30T08:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/6.json',
  },
  {
    id: '7_2020-01-30T08:00:02.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-30T08:00:02.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '8_2020-01-30T08:00:03.000Z',
    retrofitId: 8,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-30T08:00:03.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/8.json',
  },
  {
    id: '9_2020-01-30T08:00:04.000Z',
    retrofitId: 9,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-30T08:00:04.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/9.json',
  },
  {
    id: '10_2020-01-30T08:00:05.000Z',
    retrofitId: 10,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-30T08:00:05.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/10.json',
  },
  {
    id: '6_2020-01-29T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-29T08:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/6.json',
  },
  {
    id: '7_2020-01-29T08:00:02.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-29T08:00:02.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '8_2020-01-29T08:00:03.000Z',
    retrofitId: 8,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-29T08:00:03.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/8.json',
  },
  {
    id: '9_2020-01-29T08:00:04.000Z',
    retrofitId: 9,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-29T08:00:04.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/9.json',
  },
  {
    id: '10_2020-01-29T08:00:05.000Z',
    retrofitId: 10,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-01-29T08:00:05.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/10.json',
  },
  {
    id: '11_2020-03-06T01:06:01.000Z',
    retrofitId: 11,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-03-06T01:06:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/11.json',
  },
  {
    id: '12_2020-03-06T01:07:01.000Z',
    retrofitId: 12,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-03-06T01:07:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/12.json',
  },
  {
    id: '13_2020-03-06T01:08:01.000Z',
    retrofitId: 13,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-03-06T01:08:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/13.json',
  },
  {
    id: '14_2020-03-06T01:09:01.000Z',
    retrofitId: 14,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-03-06T01:09:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/14.json',
  },
  {
    id: '15_2020-03-06T01:10:01.000Z',
    retrofitId: 15,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-03-06T01:10:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/15.json',
  },
  {
    id: '16_2020-02-01T08:00:01.000Z',
    retrofitId: 16,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/16.json',
  },
  {
    id: '17_2020-02-01T08:00:02.000Z',
    retrofitId: 17,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:02.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/17.json',
  },
  {
    id: '18_2020-02-01T08:00:03.000Z',
    retrofitId: 18,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:03.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/18.json',
  },
  {
    id: '19_2020-02-01T08:00:04.000Z',
    retrofitId: 19,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:04.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/19.json',
  },
  {
    id: '20_2020-02-01T08:00:05.000Z',
    retrofitId: 20,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:05.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/20.json',
  },
  {
    id: '21_2020-02-01T08:00:01.000Z',
    retrofitId: 21,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/21.json',
  },
  {
    id: '22_2020-02-01T08:00:02.000Z',
    retrofitId: 22,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:02.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/22.json',
  },
  {
    id: '23_2020-02-01T08:00:03.000Z',
    retrofitId: 23,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:03.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/23.json',
  },
  {
    id: '24_2020-02-01T08:00:04.000Z',
    retrofitId: 24,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:04.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/24.json',
  },
  {
    id: '25_2020-02-01T08:00:05.000Z',
    retrofitId: 25,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:05.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/25.json',
  },
  {
    id: '26_2020-02-01T08:00:01.000Z',
    retrofitId: 26,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/26.json',
  },
  {
    id: '27_2020-02-01T08:00:02.000Z',
    retrofitId: 27,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:02.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/27.json',
  },
  {
    id: '28_2020-02-01T08:00:03.000Z',
    retrofitId: 28,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:03.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/28.json',
  },
  {
    id: '29_2020-02-01T08:00:04.000Z',
    retrofitId: 29,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:04.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/29.json',
  },
  {
    id: '30_2020-02-01T08:00:05.000Z',
    retrofitId: 30,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-01T08:00:05.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/30.json',
  },
  {
    id: '7_2020-02-02T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-02T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-03T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-03T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-04T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-04T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-05T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-05T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-06T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-06T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-07T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-07T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-08T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-08T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-09T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-09T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-10T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-10T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-11T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-11T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-12T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-12T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-13T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-13T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-14T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-14T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-15T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-15T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-16T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-16T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-17T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-17T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-18T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-18T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-19T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-19T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-20T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-20T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-21T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-21T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-22T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-22T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-23T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-23T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-24T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-24T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-25T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-25T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-26T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-26T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-27T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-27T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-28T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-28T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
  {
    id: '7_2020-02-29T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    timeStamp: '2020-02-29T00:00:01.000Z',
    url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/CalibrationInfo/7.json',
  },
];

export default retrofitCalibs;
