import React from 'react';
import { ReactElementLike } from 'prop-types';
import { SiteData } from '../../dataProvider';
import { AddToSiteButton } from '..';

const ToggleAddSiteButton: React.FC<
  Partial<{
    record: SiteData;
    basePath: string;
    children: ReactElementLike;
    label: string;
  }>
> = ({ record, basePath, children, label }) => {
  if (record && record.status === 'Completed') {
    return <AddToSiteButton label={label} />;
  }
  const childComponent = React.Children.only(children) || <></>;
  return <>{React.cloneElement(childComponent, { basePath, label })}</>;
};

ToggleAddSiteButton.displayName = 'ToggleAddSiteButton';
export default ToggleAddSiteButton;
