import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  required,
  email as emailValidation,
  Loading,
  useCreate,
  useNotify,
  useRedirect,
  useDataProvider,
  useTranslate,
  FormDataConsumer,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import {
  CustomForm,
  BreadcrumbsFiled,
  TextField,
  Create,
  TextInput,
  CustomSaveButton,
  FunctionField,
  RadioButtonGroupInput,
  CustomerAutoCompleteInput,
  LendCorporationData,
  DialogOKOnlyWithUrl,
} from '../../..';
import {
  useResource,
  useCreate as useCreateHook,
  useGetSelefDetailData,
  useAccount,
  usePendingTransferred,
  usePendingAlternateRegisted,
} from '../../../../hooks';
import { Record } from '../../../bases';
import { CustomerData } from '../../../../dataProvider/types';
import { apiErrorHandler, config, getParentPath } from '../../../../utils';
import { SaveIcon } from '../../../../assets';
import CancelButton from './views/CancelButton';
import { useLicense, useRentaled } from './hooks';
import { useGetRetrofitsSiteId } from '../info/hooks';

const useStyles = makeStyles(() =>
  createStyles({
    assignmentDestination: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
    },
    selectRadio: {
      gridRow: '1 / 3',
      gridColumn: '1 / 2',
    },
    selectToCorporation: {
      gridRow: '1 / 2',
      gridColumn: '2 / 3',
      '& .MuiFormLabel-root': {
        display: 'none',
      },
      '& .MuiInputBase-input': {
        width: 500,
      },
    },
    inputEmail: {
      gridRow: '2 / 3',
      gridColumn: '2 / 3',
    },
    emailHideLabel: {
      '& > .MuiFormLabel-root': {
        display: 'none', // 子コンポーネントのラベル領域を消すため
      },
    },
  }),
);

const SaveButton: React.FC<{
  basePath?: string;
  disabled?: boolean;
  customer: LendCorporationData | null; //
  retrofitId: number;
}> = ({ basePath, disabled, customer, retrofitId, ...props }) => {
  const [create] = useCreate('assignments');
  const redirectTo = useRedirect();
  const notify = useNotify();

  const toCorporationId = customer ? customer.id : 'unknown';
  const toCorporationName = customer ? customer.name : 'unknown';

  const getSiteId = useGetRetrofitsSiteId();
  const getRentaled = useRentaled();
  const getLicense = useLicense();
  const checkPendingTransfer = usePendingTransferred();
  const checkPendingAlternateRegisted = usePendingAlternateRegisted();
  const scSupportRequestUrl = config.url.scSupportRequest; // エラーダイアログ用URL

  const [dialogState, setDialogState] = useState<{
    open: boolean;
    messages?: string[];
    url?: string;
  }>({ open: false });

  const handleSave = useCallback(
    (values, redirect) => {
      const api = async () => {
        const retrofitIds = [retrofitId]; // checkPendingTransfer,checkPendingAlternateRegistedのために型定義を合わせる
        const { data } = await getSiteId({ retrofitId });
        const isRentaled = await getRentaled(retrofitId);
        const isLicense = await getLicense(retrofitId);
        const {
          data: { isPendingTransfer },
        } = await checkPendingTransfer({
          retrofitIds,
        });
        const {
          data: { isPendingAlternateRegisted },
        } = await checkPendingAlternateRegisted({
          retrofitIds,
        });
        return {
          siteId: data.siteId,
          isRentaled,
          isLicense,
          isPendingTransfer,
          isPendingAlternateRegisted,
        };
      };
      api().then(result => {
        // 建機に現場が紐付いてる場合
        if (result.siteId) {
          setDialogState({
            open: true,
            messages: ['admin.dialog.retrofitTransferCreate.messageSiteId'],
          });
          return;
        }
        // 建機が貸与中の場合
        if (result.isRentaled) {
          setDialogState({
            open: true,
            messages: ['admin.dialog.retrofitTransferCreate.messageRentaled'],
          });
          return;
        }
        // 建機にライセンスが紐づいている場合
        if (result.isLicense) {
          setDialogState({
            open: true,
            messages: ['admin.dialog.retrofitTransferCreate.messageLicense'],
          });
          return;
        }
        // 譲渡承認待ちの場合
        if (result.isPendingTransfer) {
          setDialogState({
            open: true,
            messages: [
              'admin.dialog.retrofitTransferCreate.pendingApprovals.message1',
              'admin.dialog.retrofitTransferCreate.pendingApprovals.message2',
            ],
            url: scSupportRequestUrl,
          });
          return;
        }
        // 代理申請承認待ちの場合
        if (result.isPendingAlternateRegisted) {
          setDialogState({
            open: true,
            messages: [
              'admin.dialog.retrofitTransferCreate.pendingApprovals.message1',
              'admin.dialog.retrofitTransferCreate.pendingApprovals.message2',
            ],
            url: scSupportRequestUrl,
          });
          return;
        }
        create(
          {
            payload: {
              data: {
                ...values,
                toCorporationId,
                toCorporationName,
              },
            },
          },
          {
            onSuccess: ({ data: newRecord }: any) => {
              notify('admin.message.success', 'info');
              redirectTo(
                redirect,
                basePath ? getParentPath(basePath) : '',
                newRecord.id,
                newRecord,
              );
            },
            onFailure: (error: Error) => {
              notify(apiErrorHandler(error), 'warning');
            },
          },
        );
      });
    },
    [
      getSiteId,
      getRentaled,
      getLicense,
      create,
      notify,
      redirectTo,
      checkPendingTransfer,
      checkPendingAlternateRegisted,
      basePath,
      toCorporationId,
      toCorporationName,
      retrofitId,
      scSupportRequestUrl,
    ],
  );

  return (
    <>
      <DialogOKOnlyWithUrl
        open={dialogState.open}
        onClose={() => setDialogState({ open: false })}
        title=""
        messages={dialogState?.messages}
        url={dialogState?.url}
      />
      <CustomSaveButton {...props} disabled={disabled} onSave={handleSave}>
        <SaveIcon />
      </CustomSaveButton>
    </>
  );
};

const RetrofitTransferCreate: React.FC = () => {
  const classes = useStyles();
  const resource = useResource('assignments');
  const retrofits = useResource('retrofits');
  const corporations = useResource('corporations');
  const dataProvider = useDataProvider();
  const { props, data } = useCreateHook(resource, {
    resource: retrofits,
    key: 'retrofitId',
  });
  const getAccount = useAccount();
  const accountData = getAccount();
  const { retrofitId: strRetrofitId } = useParams<{ retrofitId: string }>();
  const retrofitId = Number(strRetrofitId);
  const getSelefDetailData = useGetSelefDetailData();
  // const redirectTo = useRedirect();
  const translate = useTranslate();
  const [customer, setCustomer] = useState<LendCorporationData | null>(null);
  const { basePath } = props;

  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: {
      corporationId: string;
      corporationName: string;
      fetchedRetrofitId: number;
      isOwnData: boolean;
    };
  }>({ loading: true, fetched: false });

  useEffect(() => {
    if (apiState.fetched && apiState.data?.fetchedRetrofitId === retrofitId)
      return;

    const api = async () => {
      // accounts
      const {
        data: { corporationId, corporationName },
      } = await getAccount();
      // 自社のretrofitsか判定
      const filter = {
        ownerCorporationId: corporationId,
        adminCorporationId: corporationId,
      };

      const isOwnRetrofits = await getSelefDetailData(
        'retrofits',
        filter,
        retrofitId,
      );

      return {
        corporationId,
        corporationName,
        fetchedRetrofitId: retrofitId,
        isOwnData: !!isOwnRetrofits,
      };
    };
    api().then(result => {
      setApiState({
        loading: false,
        fetched: true,
        data: result,
      });
    });
  }, [
    dataProvider,
    corporations,
    apiState.data,
    apiState.fetched,
    getAccount,
    getSelefDetailData,
    retrofitId,
  ]);

  if (
    !data ||
    !accountData ||
    apiState.loading ||
    !apiState.data ||
    apiState.data?.fetchedRetrofitId !== retrofitId
  ) {
    return <Loading />;
  }
  // TODO: 閲覧不可なデータは現場一覧に遷移する機能を有効にする場合、isOwnDataを追加する
  const { corporationId, corporationName } = apiState.data;
  // if (!isOwnData) redirectTo('/');

  const [record] = [data as Record<typeof retrofits>];
  const toAssignmentValidation = (
    _: any,
    { toMailAddress, toCorporationId, assignmentDestination }: any,
    { name }: any,
  ) => {
    const isRequired = required();
    const isEmail = emailValidation();
    // メールアドレスに全角文字がないかチェック
    const isHalfWidth = (value: any, message = 'ra.validation.email') =>
      typeof value === 'string' &&
      // eslint-disable-next-line no-control-regex
      /([^\x01-\x7E\uFF65-\uFF9F]|[ｦ-ﾟ])+/.test(value)
        ? message
        : undefined;

    switch (name) {
      case 'toCorporationId': {
        if (assignmentDestination !== 'assignmentCompany') return null;
        return isRequired(toCorporationId, null);
      }

      case 'toMailAddress': {
        if (assignmentDestination !== 'assignmentEmail') return null;
        const error = isRequired(toMailAddress, null);
        if (error) return error;
        const errorEmail = isEmail(toMailAddress, null);
        if (errorEmail) return errorEmail;
        return isHalfWidth(toMailAddress);
      }

      default:
        return null;
    }
  };
  return (
    <Create {...props}>
      <CustomForm
        title="admin.pages.retrofitTransferCreate"
        deleteButton={false}
        cancelButton={<CancelButton basePath={basePath} />}
        saveButton={<SaveButton customer={customer} retrofitId={record.id} />}
        initialValues={
          {
            retrofitId: record.id,
            fromCorporationId: corporationId,
            fromCorporationName: corporationName,
            toMailAddress: '',
            toCorporationId: '',
            toCorporationName: '',
            planDate: '',
            assignmentDestination: 'assignmentCompany', // ラジオボタン初期値
          } as any
        }
      >
        <BreadcrumbsFiled
          breadcrumbs={[
            'resources.retrofits.name',
            'admin.breadcrumbs.retrofitEdit',
            '',
          ]}
          label="admin.pages.retrofitTransferCreate"
        />

        <FunctionField
          resource={retrofits}
          label="resources.retrofits.fields.machineInfoMachineType"
          record={record}
          render={() => {
            const res = 'resources.retrofits.machineInfoMachineType';
            const type = record?.machineInfoMachineType.toLowerCase();
            const trans = translate(`${res}.${type}`);
            // 翻訳できなければ、もとの内容をそのまま返す
            if (trans.startsWith(res)) return type;
            return trans;
          }}
        />
        <TextField
          resource={retrofits}
          record={record}
          source="machineInfoMachineId"
          label="resources.retrofits.fields.machineInfoMachineId"
        />
        <TextField
          resource={retrofits}
          record={record}
          source="machineInfoMachineName"
          label="resources.retrofits.fields.machineInfoMachineName"
        />
        <TextField
          resource={retrofits}
          record={record}
          source="machineInfoCompanyName"
          label="resources.retrofits.fields.machineInfoCompanyName"
        />

        <Box className={classes.assignmentDestination}>
          <Box className={classes.selectRadio}>
            <RadioButtonGroupInput
              {...props}
              resource={resource}
              source="assignmentDestination"
              label="resources.assignments.fields.toCorporationId"
              row={false}
              validate={[required()]}
              choices={[
                {
                  id: 'assignmentCompany',
                  name: translate(
                    'resources.assignments.fields.toCorporationName',
                  ),
                },
                {
                  id: 'assignmentEmail',
                  name: translate('resources.assignments.fields.transferUsers'),
                },
              ]}
            />
          </Box>
          <Box className={classes.selectToCorporation}>
            <FormDataConsumer>
              {({ formData }) => {
                let corporationEnabled = false;
                if (formData.assignmentDestination === 'assignmentCompany') {
                  corporationEnabled = true;
                  const workFormData = formData;
                  workFormData.email = '';
                }
                return (
                  <CustomerAutoCompleteInput
                    label={<></>}
                    resource="assignments"
                    source="toCorporationId"
                    validate={[toAssignmentValidation]}
                    disabled={!corporationEnabled}
                    fromCorporationId={corporationId}
                    defaultValue={customer}
                    onNotifyChange={(value: CustomerData) => setCustomer(value)}
                  />
                );
              }}
            </FormDataConsumer>
          </Box>
          <Box className={classes.inputEmail}>
            <FormDataConsumer>
              {({ formData }) => {
                let emailEnabled = false; // バリデーションは後ほど設定
                if (formData.assignmentDestination === 'assignmentEmail') {
                  emailEnabled = true;
                  const workFormData = formData;
                  workFormData.toCorporationId = '';
                  setCustomer(null);
                }
                return (
                  <TextInput
                    label=""
                    data-testid="email"
                    resource="assignments"
                    source="toMailAddress"
                    validate={[toAssignmentValidation]}
                    disabled={!emailEnabled}
                    className={classes.emailHideLabel}
                  />
                );
              }}
            </FormDataConsumer>
          </Box>
        </Box>
      </CustomForm>
    </Create>
  );
};

RetrofitTransferCreate.displayName = 'RetrofitTransferCreate';
export default RetrofitTransferCreate;
