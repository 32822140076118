import { fetchUtils } from 'react-admin';
import { config } from '../../utils';
import { LandlogSites, getOption } from '.';

const uri = `${config.api.landlog.apiUri}/v2/sites`;

export default async (id: string): Promise<LandlogSites> => {
  const { json } = await fetchUtils.fetchJson(
    `${uri}/${id}`,
    await getOption(),
  );
  return json;
};
