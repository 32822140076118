import React from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CustomList,
  Datagrid,
  TextField,
  CustomDateField,
  Filter,
  SiteSearchInput,
  LinkButton,
  FunctionField,
  IconField,
} from '../..';
import { useJumpTo } from '../../../hooks';
import { AddIcon } from '../../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 90,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 110,
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 120,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 234,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 112,
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 112,
          },
        },
      },
    },
  }),
);

const SerchFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

const Actions: React.FC<{
  basePath?: string;
}> = () => (
  <LinkButton path={`/userNotices/create`} label="admin.actions.add">
    <AddIcon />
  </LinkButton>
);

const UserNoticeList: React.FC = props => {
  const resource = 'userNotices';
  const classes = useStyles();
  const { gotoEdit } = useJumpTo();
  const translate = useTranslate();
  return (
    <CustomList
      {...props}
      resource={resource}
      title="admin.pages.userNoticeList"
      filter={{}}
      bulkActionButtons={false}
      actions={<Actions />}
      filters={<SerchFilter />}
    >
      <Datagrid
        className={classes.root}
        rowClick={(id: string) => {
          gotoEdit('userNotices', id);
        }}
      >
        <IconField resource={resource} source="noticeType" sortable={false} />
        <FunctionField
          resource={resource}
          source="noticeCategory"
          render={record => {
            const res = 'admin.label.userNotices';
            const type = record?.noticeCategory;
            const trans = translate(`${res}.${type}`);
            // 翻訳できなければ、もとの内容をそのまま返す
            if (trans.startsWith(res)) return type;
            return trans;
          }}
        />
        <TextField resource={resource} source="title" />
        <TextField resource={resource} source="content" />
        <CustomDateField
          resource={resource}
          source="publicationStartDate"
          showTime
          useUtc={true}
        />
        <CustomDateField
          resource={resource}
          source="publicationEndDate"
          showTime
          useUtc={true}
        />
      </Datagrid>
    </CustomList>
  );
};

UserNoticeList.displayName = 'UserNoticeList';
export default UserNoticeList;
