import React, { FC, useEffect, useCallback, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { usePermissions } from 'react-admin';
import { Link } from 'react-router-dom';
import {
  SiteData,
  GeofenceData,
  ProjectsData,
  UserConfigureData,
} from '../../../../dataProvider';
import { useConvertMapCoordinate, useMapController } from '../hooks';
import {
  GeofenceMapField,
  GeofenceListProjectInput,
  GeofenceListProjectLayerInput,
} from '../view';
import { getGeometriesArray } from '../utils';
import { objectToList, findRecordById, LonLat } from '../../../../utils';
import { geofenceStore } from '../utils/index';
import { useGetUnitLength } from '../../../../hooks';
import { Button } from '../../..';
import { colors } from '../../../../theme';
import { buttonHeight } from '../../../../theme/define';
import { GeofenceAlertListIcon } from '../../../../assets';

const optionalButtonWidth = 80;

const useAlertListButtonStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 0,
      textAlign: 'right',
      '& > a': {
        position: 'relative',
      },
    },
    button: {
      fontSize: 14,
      backgroundColor: colors.button.cancel,
      minWidth: optionalButtonWidth,
      minHeight: buttonHeight,
      textTransform: 'none',
      paddingLeft: 0,
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
    },
  }),
);

const AlertListButton: React.FC<{
  basePath?: string;
  label?: string;
}> = ({ label, basePath }) => {
  const compactionClasses = useAlertListButtonStyles();
  const path = `${basePath}/alerts`;
  return (
    <Button
      className={compactionClasses.button}
      component={Link}
      path={path}
      to={path}
      data-testid={label}
    >
      <GeofenceAlertListIcon />
    </Button>
  );
};

const AlertListButtonField: React.FC<{
  basePath?: string;
  label?: string;
}> = props => {
  const alertListClasses = useAlertListButtonStyles();
  return (
    <div className={alertListClasses.root}>
      <AlertListButton label="admin.actions.alertList" {...props} />
    </div>
  );
};

interface Props {
  basePath: string;
  site: SiteData;
  data: { [id: number]: GeofenceData };
  form?: any;
  userConfigure: UserConfigureData | undefined;
}

const GeofenceListMap: FC<Partial<Props>> = ({
  basePath,
  site,
  data,
  form,
  userConfigure,
}) => {
  const { mapController } = useMapController();
  const { permissions } = usePermissions();
  const convertMapCoordinate = useConvertMapCoordinate();
  const siteId = site?.id;
  const {
    data: { unitLength },
    loading,
  } = useGetUnitLength({ siteId: siteId || '' });
  const mapBackgroundColor = userConfigure
    ? userConfigure.mapBackgroundColor
    : '#ffffff'; // userConfigureデータがなければ地図背景色を白色で表示
  const geofences = data ? objectToList(data) : [];
  const depsGeofences = JSON.stringify(geofences);
  const [projectsData, setProjectsData] = useState<ProjectsData>();
  const handleChangeProject = useCallback(
    pd => {
      setProjectsData(pd);
      if (form) {
        form.change('layerId', null);
      }
      // ローカルストレージに選択プロジェクトを記録
      const storedGeofence = geofenceStore.get();
      const projectSourceLayerId =
        storedGeofence?.projectId === pd.id
          ? storedGeofence?.projectSourceLayerId
          : undefined;
      setProjectLayerId(projectSourceLayerId);
      if (siteId) {
        geofenceStore.set({
          siteId,
          projectId: pd.id,
          projectSourceLayerId,
        });
      }
    },
    [form, siteId],
  );
  const [projectLayerId, setProjectLayerId] = useState<number>();
  const handleChangeLayer = useCallback(
    ({ mapServerProjectSourceLayerId }) => {
      setProjectLayerId(mapServerProjectSourceLayerId);
      // ローカルストレージに選択レイヤを記録
      if (siteId) {
        geofenceStore.set({
          siteId,
          projectId: projectsData?.id,
          projectSourceLayerId: mapServerProjectSourceLayerId,
        });
      }
      // 選択したレイヤーにあわせたboundary再設定
      const projectLayers = projectsData?.projectSourceLayers;
      if (projectLayers) {
        // projectSourceLayersには全レイヤー情報が含まれているため、選択されたレイヤーの情報を選択
        const targetLayers = projectLayers.filter(
          layer =>
            layer.mapServerProjectSourceLayerId ===
            mapServerProjectSourceLayerId,
        );

        if (targetLayers && targetLayers.length > 0) {
          const targetBoundary = targetLayers[0].projectBoundary;
          if (!targetBoundary) return;

          // projectBoundary内の各座標データをBoundary調整できる形式に変換
          const boundaryLonLat = [
            {
              lon: targetBoundary.northEastLon,
              lat: targetBoundary.northEastLat,
            },
            {
              lon: targetBoundary.northWestLon,
              lat: targetBoundary.northWestLat,
            },
            {
              lon: targetBoundary.southWestLon,
              lat: targetBoundary.southWestLat,
            },
            {
              lon: targetBoundary.southEastLon,
              lat: targetBoundary.southEastLat,
            },
          ];
          mapController.setBoundary(boundaryLonLat);
        }
        // Homeボタン押下時と同じ動作を実施
        mapController.navigateToHome(0);
      }
    },
    [mapController, projectsData, siteId],
  );
  const [mapFieldRender, setMapFeildRender] = useState<boolean>(false);

  useEffect(() => {
    if (!depsGeofences || depsGeofences === '[]' || loading) return;
    setMapFeildRender(mapController.getSiteUnitLength() !== unitLength);
    mapController.setSiteUnitLength(unitLength);
    mapController.setAddOnEventEnd((event, args) => {
      // MEMO: eventにより複数処理分岐が必要な場合はswitch文へ
      if (event === 'select') {
        const settingId = args.id;
        const rawGeofences: GeofenceData[] = JSON.parse(depsGeofences);
        const paramRecord = settingId
          ? findRecordById(rawGeofences, settingId)
          : undefined;

        mapController.setSelectdRecord(paramRecord);
      }
    });
    const api = async () => {
      const rawGeofences: GeofenceData[] = JSON.parse(depsGeofences);
      // ジオフェンスリソースの緯度経度を取得
      const lonLatIdAll = rawGeofences
        .filter(geofence => geofence.points?.length)
        .map(
          geofence =>
            ({
              id: geofence.id,
              coordinates: geofence.points?.map(lonLats => ({
                lon: lonLats[0],
                lat: lonLats[1],
              })),
            } as { id: number; coordinates: LonLat[] }),
        );
      // ジオメトリ配列に変換
      const { geometries, boundaryLonLat } = getGeometriesArray(
        lonLatIdAll.filter(value => value.coordinates !== undefined),
        rawGeofences,
      );
      // マップにジオフェンス情報を反映
      mapController.setGeometries(geometries);

      // ジオフェンス情報が存在する場合は、boudary を自動調整
      if (boundaryLonLat) {
        mapController.setBoundary(boundaryLonLat);
        mapController.navigateToHome(0);
      }
    };
    api();
  }, [
    depsGeofences,
    loading,
    convertMapCoordinate,
    mapController,
    unitLength,
    setMapFeildRender,
  ]);

  useEffect(() => {
    mapController.removeProjectSourceLayer();
    if (!permissions) return;
    if (!projectsData?.latestProjectVersionId) return;
    if (!projectLayerId) return;
    const { token } = permissions;
    const projectVersionId = projectsData?.latestProjectVersionId;
    const projectLineWorkIds = projectsData?.projectSourceLineWorks.map(
      x => x.mapServerProjectSourceLineWorkId,
    );
    mapController.setProjectSourceLayer(
      projectLayerId,
      projectVersionId,
      token,
    );
    mapController.setProjectSourceLayerLabelPoint(projectVersionId, token);
    mapController.setProjectSourceLayerPointLabel(projectVersionId, token);
    mapController.setProjectSourceLocationPoint(projectVersionId, token);
    mapController.setProjectSourceLocationLabel(projectVersionId, token);
    if (!projectLineWorkIds) return;
    projectLineWorkIds.forEach(x => {
      mapController.setProjectSourceLineWork(x, projectVersionId, token);
    });
  }, [permissions, projectsData, projectLayerId, mapController]);

  return (
    <div>
      <AlertListButtonField basePath={basePath} />
      {siteId && (
        <GeofenceListProjectInput
          siteId={siteId}
          onChangeProject={handleChangeProject}
        />
      )}
      {siteId && projectsData && (
        <GeofenceListProjectLayerInput
          projectsData={projectsData}
          onChangeLayer={handleChangeLayer}
        />
      )}
      <GeofenceMapField
        mapController={mapController}
        visible={true}
        width={960}
        height={420}
        mapBackgroundColor={mapBackgroundColor}
        readOnly={true}
        featureId={-1}
        rerender={mapFieldRender}
      />
    </div>
  );
};

GeofenceListMap.displayName = 'GeofenceListMap';
export default GeofenceListMap;
