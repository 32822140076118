import React from 'react';
import { MapProps, crearteOverlayControl } from '../../../../../hooks';
import { ValidEditButton } from '../view';

const addValidControl = (
  { map }: MapProps,
  defaultValid?: boolean,
  onClick?: () => void,
) => {
  map.addControl(
    crearteOverlayControl(
      'map_valid',
      <ValidEditButton defaultValid={defaultValid} onClick={onClick} />,
      {
        right: 428,
        top: 350,
      },
    ),
  );
};

export default addValidControl;
