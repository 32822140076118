import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDataProvider, useNotify, useRedirect } from 'react-admin';
import { CustomSaveButton, DialogOKOnly } from '../../..';
import { SaveIcon } from '../../../../assets';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { apiErrorHandler, config } from '../../../../utils';
import { useLicenseUpdate } from '../hooks';
import { LicensePermissionType, RetrofitData } from '../../../../dataProvider';
import { LicenseParams } from './PreUpdateShow';

interface SelectMachineInfoData {
  machineInfoMachineId: string;
  machineInfoMachineName: string;
  basicInfoSerialNumber: string;
}

interface Props {
  basePath: string;
  record: any;
  form: any;
  selectMachineInfo: SelectMachineInfoData; // RetrofitAutoCompleteInput で選択した 建機情報
  handlePreUpdate: (params: LicenseParams) => void;
  doUpdate: boolean;
}

const SaveButton: FC<Partial<Props>> = ({
  form,
  basePath,
  record,
  selectMachineInfo,
  handlePreUpdate,
  doUpdate,
  ...rest
}) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const dataProvider = useDataProvider();
  const licenseUpdate = useLicenseUpdate(form, record);
  const [calledUpdate, setCalledUpdate] = useState(false);
  const [beforeMachineInfo, setBeforeMachineInfo] = useState<any | undefined>(
    undefined,
  );
  const updateRetrofitId = form.getFieldState('retrofitId')?.value;
  const retrofitIdChanged =
    updateRetrofitId && `${record.retrofitId}` !== `${updateRetrofitId}`;

  const machineInfoMachineId = selectMachineInfo?.machineInfoMachineId || '';
  const machineInfoMachineName =
    selectMachineInfo?.machineInfoMachineName || '';
  const basicInfoSerialNumber = selectMachineInfo?.basicInfoSerialNumber || '';

  // 変更前の情報を取得
  const beforeRetrofitId = record.retrofitId;
  useEffect(() => {
    // id が変更されていない場合、実施不要
    if (!retrofitIdChanged || !beforeRetrofitId) return;
    dataProvider.getOne('retrofits', { id: beforeRetrofitId }).then(result => {
      const beforeRetrofits = result.data as RetrofitData;
      setBeforeMachineInfo({
        beforeRetrofitId,
        machineInfoMachineId: beforeRetrofits.machineInfoMachineId,
        machineInfoMachineName: beforeRetrofits.machineInfoMachineName,
        basicInfoSerialNumber: beforeRetrofits.basicInfoSerialNumber,
      });
    });
  }, [dataProvider, retrofitIdChanged, beforeRetrofitId]);

  const machineInfo = {
    machineInfoMachineId,
    machineInfoMachineName,
    basicInfoSerialNumber,
  };

  const [dialogState, setDialogState] = useState<{
    open: boolean;
    message?: string;
  }>({ open: false });

  const api = useCallback(async () => {
    const isTransferred = await dataProvider.getList('assignments', {
      pagination: {
        page: 1,
        perPage: 1,
      },
      sort: { field: 'id', order: 'ASC' },
      filter: {
        retrofitId: updateRetrofitId,
        isApproved: false,
      },
    });
    return { isTransferred };
  }, [updateRetrofitId, dataProvider]);

  const handleUpdate = useCallback(() => {
    // UpdateAPIを実行
    if (
      updateRetrofitId &&
      updateRetrofitId !== '' &&
      config.feature.transfer
    ) {
      // 建機が設定されている場合（かつconfigで有効な場合）は譲渡中チェックを実施

      api().then(result => {
        // 建機が譲渡中の場合
        if (result.isTransferred.data.length > 0) {
          setDialogState({
            open: true,
            message:
              'admin.dialog.retrofitCorporationLicenses.unableTransfer.message',
          });
        } else {
          licenseUpdate()
            .then(() => {
              notify('admin.message.success', 'info');
              redirectTo('list', basePath);
            })
            .catch(error => {
              notify(apiErrorHandler(error), 'warning');
            });
        }
      });
    } else {
      licenseUpdate()
        .then(() => {
          notify('admin.message.success', 'info');
          redirectTo('list', basePath);
        })
        .catch(error => {
          notify(apiErrorHandler(error), 'warning');
        });
    }
  }, [basePath, notify, redirectTo, licenseUpdate, updateRetrofitId, api]);

  useEffect(() => {
    if (!doUpdate || calledUpdate) return;
    // handleUpdate のdependencyによる複数回呼び出し回避
    setCalledUpdate(true);
    handleUpdate();
  }, [doUpdate, calledUpdate, handleUpdate]);

  const handleSaveClick = useCallback(
    values => {
      const { retrofitId, licensePermission } = values as {
        retrofitId: string;
        licensePermission: LicensePermissionType;
      };

      if (config.feature.transfer) {
        api().then(result => {
          // 建機が譲渡中の場合
          if (result.isTransferred.data.length > 0) {
            setDialogState({
              open: true,
              message:
                'admin.dialog.retrofitCorporationLicenses.unableTransfer.message',
            });
          } else {
            if (!handlePreUpdate || dialogState.open) return;

            handlePreUpdate({
              retrofitId: Number(retrofitId),
              licensePermission,
              licenseRecord: record,
              machineInfo,
              beforeMachineInfo,
            });
          }
        });
      } else {
        if (!handlePreUpdate) return;

        handlePreUpdate({
          retrofitId: Number(retrofitId),
          licensePermission,
          licenseRecord: record,
          machineInfo,
          beforeMachineInfo,
        });
      }
    },
    [handlePreUpdate, record, machineInfo, beforeMachineInfo, dialogState, api],
  );

  return (
    <>
      <DialogOKOnly
        open={dialogState.open}
        onClose={() => setDialogState({ open: false })}
        title=""
        message={dialogState?.message}
      />
      <CustomSaveButton
        {...rest}
        basePath={basePath}
        onSave={retrofitIdChanged ? handleSaveClick : handleUpdate}
      >
        <SaveIcon />
      </CustomSaveButton>{' '}
    </>
  );
};

SaveButton.displayName = 'SaveButton';
export default SaveButton;
