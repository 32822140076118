import { useCallback } from 'react';
import { isInteger } from '../utils';

const INT_MAX = 2147483647;

// バリデーション: 整数のみ入力可能
const useUIntegerValidation = () => {
  return useCallback((value: string | number) => {
    const strValue = `${value}`;
    if (!isInteger(strValue)) {
      return 'admin.validation.validIntegerFormat';
    }
    const numValue = Number(strValue);
    if (numValue < 0 || numValue > INT_MAX) {
      return 'admin.validation.validIntegerRangeFormat';
    }
    return null;
  }, []);
};

export default useUIntegerValidation;
