import { fetchUtils } from 'react-admin';
import { encode } from 'querystring';
import { config } from '../../utils';
import { LandlogUsers, getOption } from '.';

export type landlogListUsersProps = Partial<{
  name: string;
  email: string;
  role: string;
  label: string;
  page: number;
  offset: number;
  limit: number;
  order_by: 'name'; // eslint-disable-line camelcase
  order: 'asc' | 'desc';
}>;

interface ListResponse {
  total: number;
  users: LandlogUsers[];
}

export default async (
  corporationId: string,
  props: landlogListUsersProps,
): Promise<ListResponse> => {
  const params = encode({ ...props });
  const uri = `${config.api.landlog.apiUri}/v2/corporations/${corporationId}/users`;
  const { json } = await fetchUtils.fetchJson(
    `${uri}/?${params}`,
    await getOption(),
  );
  return json;
};
