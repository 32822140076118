import { FirmwareData } from '../..';

const data: FirmwareData[] = [
  {
    id: 1,
    version: '1.0.0',
    firmwareFile: {
      src: 'http://dummy/file1',
      title: 'v100.bin',
    },
    firmwareFileCheckSum: 'checksum1',
    firstInitializationFile: {
      src: 'http://dummy/init1',
      title: 'init1.gfw',
    },
    firstInitializationFileCheckSum: 'checksuminit1',
    secondInitializationFile: {
      src: 'http://dummy/init2',
      title: 'init2.gfw',
    },
    secondInitializationFileCheckSum: 'checksuminit2',
    compatibilityLevel: 1,
  },
  {
    id: 2,
    version: '2.0.0',
    firmwareFile: {
      src: 'http://dummy/file2',
      title: 'v101.bin',
    },
    firmwareFileCheckSum: 'checksum2',
    firstInitializationFile: {
      src: 'http://dummy/init12',
      title: 'init12.gfw',
    },
    firstInitializationFileCheckSum: 'checksuminit12',
    secondInitializationFile: {
      src: 'http://dummy/init22',
      title: 'init22.gfw',
    },
    secondInitializationFileCheckSum: 'checksuminit22',
    compatibilityLevel: 2,
  },
  {
    id: 3,
    version: '3.0.0',
    firmwareFile: {
      src: 'http://dummy/file3',
      title: 'v102.bin',
    },
    firmwareFileCheckSum: 'checksum3',
    firstInitializationFile: {
      src: 'http://dummy/init13',
      title: 'init13.gfw',
    },
    firstInitializationFileCheckSum: 'checksuminit13',
    secondInitializationFile: {
      src: 'http://dummy/init23',
      title: 'init23.gfw',
    },
    secondInitializationFileCheckSum: 'checksuminit23',
    compatibilityLevel: 3,
  },
];

export default data;
