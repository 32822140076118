import React from 'react';
import { SwitchCardIcon } from '../../../assets';
import Button from '../../bases/button/Button';

export interface Props {
  onClick: () => void;
}

const SiteCardIcon = (props: Props) => {
  const { onClick } = props;
  return (
    <Button onClick={onClick}>
      <SwitchCardIcon />
    </Button>
  );
};

SiteCardIcon.displayName = 'SiteCardIcon';

export default SiteCardIcon;
