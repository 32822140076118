import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useUpdateMany,
  useUnselectAll,
  useNotify,
  Identifier,
  useDataProvider,
} from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { BulkActionButtonProps } from '../bases/list/List';
import { Button, DialogOKOnly } from '..';
import { useJumpTo } from '../../hooks';
import { colors } from '../../theme';
import { AddIcon } from '../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: colors.button.cancel,
      minWidth: 160,
      minHeight: 34,
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
      },
    },
  }),
);

const useId = () => {
  const { siteId } = useParams<{ siteId: string }>();
  return siteId;
};

const useGetMenyRentals = () => {
  const resources = 'retrofitRentals';
  const dataProvider = useDataProvider();
  return {
    fetchRentals: (id: Identifier): Promise<boolean> => {
      const fn = async () => {
        const { data } = await dataProvider.getManyReference(resources, {
          target: 'retrofitId',
          id,
          pagination: { page: 1, perPage: 1 }, // NOTE: 1件だけでよい
          sort: { field: 'id', order: 'DESC' },
          filter: { isApproved: false },
        });
        return data.length > 0;
      };
      return fn();
    },
  };
};

const useGetMenyTransfers = () => {
  const resources = 'assignments';
  const dataProvider = useDataProvider();
  return {
    fetchTransfers: (id: Identifier): Promise<boolean> => {
      const fn = async () => {
        const { data } = await dataProvider.getManyReference(resources, {
          target: 'retrofitId',
          id,
          pagination: { page: 1, perPage: 1 }, // NOTE: 1件だけでよい
          sort: { field: 'id', order: 'DESC' },
          filter: { isApproved: false },
        });
        return data.length > 0;
      };
      return fn();
    },
  };
};

type RetrofitAddToSiteActionButtonProps =
  React.ComponentProps<BulkActionButtonProps>;
const RetrofitAddToSiteActionButton: React.FC<
  RetrofitAddToSiteActionButtonProps & {
    disabled?: boolean;
  }
> = props => {
  const { selectedIds, disabled, undoable, resource = '' } = props;
  const ids = selectedIds as Identifier[];
  const unselectAll = useUnselectAll(resource);
  const notify = useNotify();
  const id = useId();
  const classes = useStyles();
  const { gotoShow } = useJumpTo();
  // NOTE: ※ 送信を未然に防ぐ処理の一例。
  //          デメリットはチェックをつけるたびにリクエストが発生すること
  // const { data } = useGetMany('retrofits', selectedIds as number[]);
  // NOTE: ※ updateMany にこちらを渡す
  // const selectedIdsWithoutDuplication = data
  //   .filter(
  //     (item: { [k: string]: unknown }) =>
  //       item && isBlank(item.siteId as string | null),
  //   )
  //   .map(item => item.id);
  // NOTE: ※ 別のアプローチのアイデアとして、チェックボックスの状態変化時に
  //          State に selectedIds を蓄積していく方法や、
  //          チェック時に上のバリデーションを走らせる方法などが思いつく。
  const [updateMany, { loading }] = useUpdateMany(
    resource,
    ids,
    // selectedIdsWithoutDuplication as [React.ReactText],
    {
      siteId: id,
    },
    {
      undoable,
      onSuccess: () => {
        // TODO: べた書きを直す
        gotoShow('/sites', id);
        unselectAll();
        notify(
          'admin.message.success',
          'info',
          {
            smart_count: ids.length,
          },
          undoable,
        );
      },
      onFailure: (error: Error) => notify(`Error: ${error.message}`, 'warning'),
    },
  );

  const [open, setOpen] = useState(false);
  const handleShow = useCallback(() => setOpen(true), [setOpen]);
  const handleHide = useCallback(() => setOpen(false), [setOpen]);

  const [dialogState, setDialogState] = useState<{
    title: string;
    message: string;
  }>({ title: '', message: '' });

  const { fetchRentals } = useGetMenyRentals();
  const { fetchTransfers } = useGetMenyTransfers();

  const handleAdd = useCallback(async () => {
    const fetchRental: Promise<boolean>[] = ids.map(it => fetchRentals(it));
    const resultRentals = await Promise.all(fetchRental);
    const isRentalWithUnaprove = resultRentals.includes(true);

    const fetchTransfer: Promise<boolean>[] = ids.map(it => fetchTransfers(it));
    const resultTransfers = await Promise.all(fetchTransfer);
    const isTransferWithUnaprove = resultTransfers.includes(true);

    if (isRentalWithUnaprove) {
      // 紐付けさせない為、ダイアログを表示
      handleShow();
      setDialogState({
        title: 'admin.dialog.retrofitAddToSiteAction.title',
        message: 'admin.dialog.retrofitAddToSiteAction.message',
      });
    } else if (isTransferWithUnaprove) {
      // 紐付けさせない為、ダイアログを表示
      handleShow();
      setDialogState({
        title: 'admin.dialog.retrofitAddToSiteActionTransfer.title',
        message: 'admin.dialog.retrofitAddToSiteActionTransfer.message',
      });
    } else {
      // 現場との紐付け処理を実行
      updateMany();
    }
  }, [ids, fetchRentals, fetchTransfers, updateMany, handleShow]);
  return (
    <>
      <Button
        data-testid="admin.actions.add"
        onClick={handleAdd}
        disabled={loading || disabled}
        classes={classes}
      >
        <AddIcon />
      </Button>
      <DialogOKOnly
        open={open}
        onClose={handleHide}
        title={dialogState.title}
        message={dialogState.message}
      />
    </>
  );
};

RetrofitAddToSiteActionButton.displayName = 'RetrofitAddToSiteActionButton';
export default RetrofitAddToSiteActionButton;
