import { AdminApiConverter } from '..';
import { RetrofitSendbackData } from '../../types';

const converter: AdminApiConverter<'retrofitSendbacks', 'retrofitSendbacks'> = {
  resource: 'retrofitSendbacks',
  defaultSort: { field: 'id', order: 'DESC' },
  filterToApi: filter => filter,
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(
        (record): RetrofitSendbackData => ({
          id: record.id || -1,
          fromRetrofitId: record.fromRetrofitId,
          toRetrofitId: record.toRetrofitId,
          notificationEmailAddress: record.notificationEmailAddress,
          shouldInitialization: record.shouldInitialization,
          isInitialized: record.isInitialized,
          isComplete: record.isComplete,
          registrationCorporationtId: record.registrationCorporationtId,
          registrationCorporationName: record.registrationCorporationName,
        }),
      ),
    ),
  recordToApi: record => ({
    fromRetrofitId: record.fromRetrofitId,
    toRetrofitId: record.toRetrofitId,
    notificationEmailAddress: record.notificationEmailAddress,
    shouldInitialization: record.shouldInitialization,
    isComplete: record.isComplete,
    registrationCorporationtId: record.registrationCorporationtId,
    registrationCorporationName: record.registrationCorporationName,
  }),
};

export default converter;
