import { AdminApiConverter, ApiFilter } from '..';

const converter: AdminApiConverter<'customers', 'customers'> = {
  resource: 'customers',
  defaultSort: { field: 'id', order: 'ASC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'customers'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('name' in filter) apiFilter.name = filter.name;
    if ('fromCorporationId' in filter)
      apiFilter.fromCorporationId = filter.fromCorporationId;
    return apiFilter;
  },
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(record => ({
        id: record.id || '',
        name: record.name || '',
        fromCorporationId: record.fromCorporationId || '',
      })),
    ),
  recordToApi: record => ({
    name: record.name,
    fromCorporationId: record.fromCorporationId,
  }),
};

export default converter;
