import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Button } from '../../..';
import { mapStyle } from '../../../../theme';
import { MapHome } from '../../../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      ...mapStyle.button,
      backgroundColor: '#000344',
    },
  }),
);

const MapHomeButton: FC<{
  onClick?: () => void;
}> = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Button
      onClick={() => {
        if (onClick) onClick();
      }}
      className={classes.root}
    >
      <MapHome />
    </Button>
  );
};

MapHomeButton.displayName = 'MapHomeButton';
export default MapHomeButton;
