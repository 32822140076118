import React, { FC, ReactElement, SyntheticEvent } from 'react';
import {
  Record,
  RedirectionSideEffect,
  useDeleteWithConfirmController,
} from 'ra-core';
import { makeStyles, createStyles } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { useTranslate } from 'react-admin';
import { Button, DialogOKCancel } from '..';
import { colors } from '../../theme';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../theme/define';
import { DeleteIcon } from '../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: colors.button.delete,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        background: colors.button.deleteHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.deleteDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
  }),
);

interface Props {
  basePath?: string;
  classes?: object;
  className?: string;
  icon?: ReactElement;
  label?: string;
  onClick?: (e: MouseEvent) => void;
  record?: Record;
  redirect?: RedirectionSideEffect;
  resource?: string;
  realResource?: string;
  handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<object>;
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void;
  invalid?: boolean;
  pristine?: boolean;
  saving?: boolean;
  submitOnEnter?: boolean;
  undoable?: boolean;
  messageTargetItem?: string;
}

const CustomDeleteButton: FC<Props & ButtonProps> = ({
  resource,
  realResource,
  label,
  disabled,
  record,
  redirect = 'list',
  basePath,
  messageTargetItem,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const messageResource = realResource || resource;
  const title = messageTargetItem
    ? translate(messageTargetItem)
    : translate(`resources.${messageResource}.name`);
  const translatedMessage = translate('admin.message.deleteConfirmContent', {
    title,
  });
  const testId = label || 'ra.action.delete';
  const { open, handleDialogOpen, handleDialogClose, handleDelete } =
    useDeleteWithConfirmController({
      record,
      redirect,
      basePath,
      resource,
    });
  return (
    <>
      <DialogOKCancel
        open={open}
        title=""
        message={translatedMessage}
        nonTranslateMessage={true}
        onClose={handleDialogClose}
        onOKClick={handleDelete}
        isDelete={true}
      />
      <Button
        className={classes.root}
        data-testid={testId}
        label=""
        disabled={disabled}
        onClick={handleDialogOpen}
      >
        <DeleteIcon />
      </Button>
    </>
  );
};

const { defaultProps } = CustomDeleteButton;
CustomDeleteButton.defaultProps = {
  ...defaultProps,
};

CustomDeleteButton.displayName = 'CustomDeleteButton';
export default CustomDeleteButton;
