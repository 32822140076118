import { GeofenceType } from '../../../../dataProvider';
import { GeometryType } from '../../../../utils';

const geofenceTypeToGeometryType = (
  geofenceType: GeofenceType,
): GeometryType => {
  switch (geofenceType) {
    case 'Polygon':
      return 'Polygon';
    case 'Circle':
      return 'Circle';
    case 'Line':
    case 'Wall':
      return 'Line'; // Wall は Line で表現する
    default:
  }
  console.warn(`Unconverted GeofenceType: ${geofenceType}`);
  return 'Unknown';
};

export default geofenceTypeToGeometryType;
