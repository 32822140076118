import { useDataProvider } from 'react-admin';
import { RetrofitData } from '../../../../dataProvider';

const useCreateRetrofitAlternateRegists = () => {
  const dataProvider = useDataProvider();
  const applicationDate = new Date();
  return async ({
    data,
    fromCorporationId,
    fromCorporationName,
    toCorporationId,
    toCorporationName,
    toMailAddress,
    fromApplicantId,
    fromApplicantName,
  }: {
    data: RetrofitData[];
    fromCorporationId: string;
    fromCorporationName: string;
    toCorporationId: string | null;
    toCorporationName: string | null;
    toMailAddress: string;
    fromApplicantId: string;
    fromApplicantName: string;
  }) => {
    const ids = data.map(it => it.id);
    const resource = 'retrofitAlternateRegists';

    const alternateRegist = await Promise.all(
      ids.map(retrofitId => {
        return dataProvider.create(resource, {
          data: {
            fromCorporationId,
            fromCorporationName,
            toCorporationId,
            toCorporationName,
            toMailAddress,
            retrofitId,
            applicationDate,
            fromApplicantId,
            fromApplicantName,
          },
        });
      }),
    );
    return {
      data: alternateRegist.map(({ data: registData }) => registData.id),
    };
  };
};

export default useCreateRetrofitAlternateRegists;
