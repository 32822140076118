import { LandlogUsers, UserData } from '..';

export default (User: LandlogUsers): UserData => ({
  id: User.id,
  name: User.name,
  email: User.email,
  picture: User.picture || '',
  language: User.language || '',
  country: User.address?.country || '',
  zipCode: User.address?.zip_code || '',
  state: User.address?.state || '',
  city: User.address?.city || '',
  address1: User.address?.address_1 || '',
  address2: User.address?.address_2 || '',
  role: User.role,
});
