import { RetrofitCorporationLicenseData } from '../../../../dataProvider';
import { LicenseGroup } from '../dialog/Layout';
import transformViewLicense from './transformViewLicense';
import extractLicense from './extractLicense';

export default (licenses: RetrofitCorporationLicenseData[]): LicenseGroup => ({
  all: transformViewLicense(
    extractLicense(licenses, 'All') ||
      extractLicense(licenses, 'Temporary') ||
      extractLicense(licenses, 'FlexAll'),
  ),
  machineGuidance: transformViewLicense(
    extractLicense(licenses, 'MachineGuidance') ||
      extractLicense(licenses, 'MachineGuidance2D'),
  ),
  payloadMeter: transformViewLicense(extractLicense(licenses, 'PayloadMeter')),
  asbuilt: transformViewLicense(extractLicense(licenses, 'Asbuilt')),
});
