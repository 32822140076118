import { DataProvider, GetListParams, GetListResult } from 'react-admin';
import {
  getDataProvider,
  AccountData,
  AssignmentData,
  RetrofitRentalData,
  RetrofitAlternateRegistData,
} from '.';
import { Record } from '../components';
import { config } from '../utils';

interface PendingApprovalData {
  approvalType: string;
  id: string;
  dataId: number;
  retrofitId: number;
  fromCorporationId: string;
  fromCorporationName: string;
  toCorporationId?: string;
  toCorporationName?: string;
  requestedBy: string;
  lastUpdated: string;
}

const getPendingApprovals = async (
  dataProvider: DataProvider,
  params: GetListParams,
): Promise<GetListResult> => {
  const { getOne, getList } = dataProvider;

  // ログイン中のユーザーのメールアドレスと企業IDを取得
  const { data: accountData } = await getOne('accounts', { id: 1 });
  const { email, corporationId } = accountData as AccountData;

  // ----------------------
  // 貸与
  // ----------------------

  // 承認待ちの貸与情報を企業IDを指定して検索
  const rentalCorporationIdFilter: Partial<RetrofitRentalData> = {
    ...params.filter,
    isApproved: false,
    toCorporationId: corporationId,
  };
  const { data: rentalsByCorporation } = await getList('retrofitRentals', {
    sort: { field: 'id', order: 'ASC' },
    pagination: { page: 1, perPage: 100 },
    filter: rentalCorporationIdFilter,
  });

  // 承認待ちの貸与情報をメールアドレスを指定して検索
  const rentalEmailFilter: Partial<RetrofitRentalData> = {
    ...params.filter,
    isApproved: false,
    email,
  };
  const { data: rentalsByEmail } = await getList('retrofitRentals', {
    sort: { field: 'id', order: 'ASC' },
    pagination: { page: 1, perPage: 100 },
    filter: rentalEmailFilter,
  });

  // 貸与情報を画面表示用の型RetrofitApprovalDataになるよう変換
  const rentalData = [...rentalsByCorporation, ...rentalsByEmail];

  const rentalApprovalData = rentalData.map(record => {
    const rental = record as RetrofitRentalData;

    return {
      approvalType: 'Assignment',
      id: `Assignment_${rental.id}`,
      dataId: rental.id,
      retrofitId: rental.retrofitId,
      fromCorporationId: rental.fromCorporationId,
      fromCorporationName: rental.fromCorporationName,
      toCorporationId: rental.toCorporationId,
      toCorporationName: rental.toCorporationName,
      requestedBy: rental.fromCorporationName,
      lastUpdated: rental.lastUpdated,
    };
  });

  // ※REACT_APP_FEATURE_TRANSFER,REACT_APP_FEATURE_RETROFIT_ALTERNATE_REGIST 両方の削除にて、末尾にconstでマージするよう修正する
  let maergedData = [...rentalApprovalData] as PendingApprovalData[];

  // ----------------------
  // 譲渡
  // ----------------------
  // ※REACT_APP_FEATURE_TRANSFER の削除にて、if文の分岐を削除すること
  if (config.feature.transfer) {
    // 承認待ちの譲渡情報を企業IDを指定して検索
    const assignmentCorporationIdFilter: Partial<AssignmentData> = {
      ...params.filter,
      isApproved: false,
      toCorporationId: corporationId,
    };
    const { data: transfersByCorporation } = await getList('assignments', {
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 100 },
      filter: assignmentCorporationIdFilter,
    });

    // 承認待ちの譲渡情報をメールアドレスを指定して検索
    const assignmentEmailFilter: Partial<AssignmentData> = {
      ...params.filter,
      isApproved: false,
      toMailAddress: email,
    };
    const { data: transfersByEmail } = await getList('assignments', {
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 100 },
      filter: assignmentEmailFilter,
    });

    // 譲渡情報を画面表示用の型RetrofitApprovalDataになるよう変換
    const transferData = [...transfersByCorporation, ...transfersByEmail];

    const transferApprovalData = transferData.map(record => {
      const tranfer = record as AssignmentData;

      return {
        approvalType: 'Transfer',
        id: `Transfer_${tranfer.id}`,
        dataId: tranfer.id,
        retrofitId: tranfer.retrofitId,
        fromCorporationId: tranfer.fromCorporationId,
        fromCorporationName: tranfer.fromCorporationName,
        toCorporationId: tranfer.toCorporationId,
        toCorporationName: tranfer.toCorporationName,
        requestedBy: tranfer.fromCorporationName,
        lastUpdated: tranfer.lastUpdated,
      };
    });

    // 今までの取得結果とマージ
    maergedData = [...maergedData, ...transferApprovalData];
  }

  // ----------------------
  // 代理申請
  // ----------------------
  // ※REACT_APP_FEATURE_RETROFIT_ALTERNATE_REGIST の削除にて、if文の分岐を削除すること
  if (config.feature.retrofitAlternateRegist) {
    // 承認待ちの代理申請情報をメールアドレスを指定して検索
    const alternateEmailFilter: Partial<RetrofitAlternateRegistData> = {
      ...params.filter,
      isApproved: false,
      toMailAddress: email,
    };
    const { data: alternateByEmail } = await getList(
      'retrofitAlternateRegists',
      {
        sort: { field: 'id', order: 'ASC' },
        pagination: { page: 1, perPage: 100 },
        filter: alternateEmailFilter,
      },
    );

    // 代理申請情報を画面表示用の型RetrofitApprovalDataになるよう変換
    const alternateApprovalData = alternateByEmail.map(record => {
      const alternateRegist = record as RetrofitAlternateRegistData;

      return {
        approvalType: 'Agent',
        id: `Agent_${alternateRegist.id}`,
        dataId: alternateRegist.id,
        retrofitId: alternateRegist.retrofitId,
        fromCorporationId: alternateRegist.fromCorporationId,
        fromCorporationName: alternateRegist.fromCorporationName,
        toCorporationId: alternateRegist.toCorporationId,
        toCorporationName: alternateRegist.toCorporationName,
        requestedBy: alternateRegist.fromCorporationName,
        lastUpdated: alternateRegist.lastUpdated,
      };
    });

    // 今までの取得結果とマージ
    maergedData = [...maergedData, ...alternateApprovalData];
  }

  const data = maergedData as Record<any>;

  // ソート
  const { field, order } =
    params.sort.field === 'id'
      ? { field: 'planDate', order: 'ASC' }
      : params.sort;
  const sortData = [...data].sort((a, b) => {
    if (typeof a[field] === 'number') {
      return order === 'ASC' ? a[field] - b[field] : b[field] - a[field];
    }
    const strA = a[field] || '';
    const strB = b[field] || '';
    return order === 'ASC'
      ? strA.localeCompare(strB)
      : strB.localeCompare(strA);
  });

  // ページネーション
  const { page, perPage } = params.pagination;
  const start = (page - 1) * perPage;
  const end = start + perPage;
  const pageData = sortData.slice(start, end);
  return { total: data.length, data: pageData };
};

export default (): DataProvider => {
  const dataProvider = getDataProvider();
  return {
    getList: (_, params): any => getPendingApprovals(dataProvider, params),
    getOne: (_, params): any => {
      return (async () => {
        const { data } = await getPendingApprovals(dataProvider, {
          pagination: {
            page: 1,
            perPage: 1,
          },
          sort: {
            field: 'id',
            order: 'asc',
          },
          filter: { id: params.id },
        });
        return { data: data[0] };
      })();
    },
    getMany: () => {
      throw new Error('Not supported for pending approvals');
    },
    getManyReference: () => {
      throw new Error('Not supported for pending approvals');
    },
    update: () => {
      throw new Error('Not supported for pending approvals');
    },
    updateMany: () => {
      throw new Error('Not supported for pending approvals');
    },
    create: () => {
      throw new Error('Not supported for pending approvals');
    },
    delete: () => {
      throw new Error('Not supported for pending approvals');
    },
    deleteMany: () => {
      throw new Error('Not supported for pending approvals');
    },
  };
};
