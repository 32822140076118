import { RegionData } from '../../types';

const regions: RegionData[] = [
  {
    id: 1,
    name: 'Global',
  },
  {
    id: 2,
    name: 'Germany',
  },
  {
    id: 3,
    name: 'France',
  },
  {
    id: 4,
    name: 'UK',
  },
  {
    id: 5,
    name: 'Denmark',
  },
  {
    id: 6,
    name: 'Belgium',
  },
  {
    id: 7,
    name: 'Australia',
  },
  {
    id: 8,
    name: 'New Zealand',
  },
  {
    id: 9,
    name: 'Japan',
  },
  {
    id: 10,
    name: '地域１',
  },
];
export default regions;
