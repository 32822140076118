import { Tab as RaTab } from 'react-admin';
import { TabProps as MuiProps } from '@material-ui/core/Tab';
import { ReactElementLike, ReactNodeLike } from 'prop-types';

interface RaProps {
  className: string;
  contentClassName: string;
  children: ReactNodeLike;
  context: 'header' | 'content';
  icon: ReactElementLike;
  label: string;
  translate: Function;
  value: string;
}

const Tab = RaTab as ForgivingComponentType<RaProps & MuiProps>;
Tab.displayName = 'Tab';

export default Tab;
