import { colors } from '.';

export default {
  button: {
    '& span': {
      padding: 0,
    },
    width: 44,
    minWidth: 44,
    height: 44,
    minHeight: 44,
    boxShadow: '0px 0px 4px #00000040',
    borderRadius: 3,
    background: colors.button.cancel,
    '&:hover': {
      background: colors.button.cancelHover,
    },
    '&.Mui-disabled': {
      backgroundColor: colors.button.cancelDisabled,
    },
  },
  buttonDelete: {
    '& span': {
      padding: 0,
    },
    width: 44,
    minWidth: 44,
    height: 44,
    minHeight: 44,
    borderRadius: 3,
    boxShadow: '0px 0px 4px #00000040',
    background: colors.button.delete,
    '&:hover': {
      background: colors.button.deleteHover,
    },
    '&.Mui-disabled': {
      backgroundColor: colors.button.deleteDisabled,
    },
  },
  buttonWrapHorizontal: {
    '& .MuiButtonBase-root': {
      borderRadius: 0,
      '&:first-child': {
        borderRadius: '3px 0 0 3px',
      },
      '&:last-child': {
        borderRadius: '0 3px 3px 0',
      },
    },
  },
  buttonWrapVertical: {
    '& .MuiButtonBase-root': {
      borderRadius: 0,
      '&:first-child': {
        borderRadius: '3px 3px 0 0',
      },
      '&:last-child': {
        borderRadius: '0 0 3px 3px',
      },
    },
  },
};
