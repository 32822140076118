import { SiteBuriedStructureData } from '../../types';

const siteBuriedStructures: SiteBuriedStructureData[] = [
  {
    id: 1,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    epsgCode: 'EPSG:4326',
    siteUserEmail: undefined,
    buriedStructureFileId: '',
    blobFileStatus: 'WaitForCreate',
    fileTime: undefined,
    url: undefined,
  },
  {
    id: 2,
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    epsgCode: 'EPSG:4269',
    siteUserEmail: undefined,
    buriedStructureFileId: '',
    blobFileStatus: 'Created',
    fileTime: '2020-02-01T08:00:01.000Z',
    url: 'https://example.com/', // TODO ファイルの用意ができたら記入
  },
  {
    id: 3,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    epsgCode: 'EPSG:3857',
    siteUserEmail: undefined,
    buriedStructureFileId: '',
    blobFileStatus: 'Error',
    fileTime: undefined,
    url: undefined,
  },
];

export default siteBuriedStructures;
