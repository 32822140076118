import React, { useState, useCallback } from 'react';
import { NormalButton, DialogOKOnly } from '..';
import { AddIcon } from '../../assets';

const AddToSiteButton: React.FC<{ label?: string }> = ({ label }) => {
  const [open, setOpen] = useState(false);
  const handleShow = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const handleHide = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <NormalButton label={label} onClick={handleShow}>
        <AddIcon />
      </NormalButton>
      <DialogOKOnly
        open={open}
        onClose={handleHide}
        title="admin.dialog.siteRetrofitList.title"
        message="admin.dialog.siteRetrofitList.message"
      />
    </>
  );
};

AddToSiteButton.displayName = 'AddToSiteButton';
export default AddToSiteButton;
