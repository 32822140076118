import React, { FC, useState } from 'react';
import {
  BooleanInput,
  DateField,
  Edit,
  FunctionField,
  SimpleShowLayout,
  TextInput,
  useTranslate,
} from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import { useResource } from '../../../../hooks';
import {
  BreadcrumbsFiled,
  CustomChildBox,
  CustomForm,
  CustomParentBox,
} from '../../../molecules';
import {
  CustomNumberInput,
  IconFieldPaddingZeroStyle,
  Typography,
} from '../../../atoms';
import SaveButton from './views/SaveButton';

const useStyles = makeStyles(() =>
  createStyles({
    titleFirst: {
      padding: '12px 0px',
    },
    title: {
      marginTop: '12px',
      padding: '12px 0px',
      borderTop: '1px solid #F5F5FF33', // 各項目表題の上部に白線追加
    },
    form: {
      '& .MuiFormControl-root': {
        marginTop: 8,
        marginBottom: 10,
        '& .MuiFormLabel-root': {
          width: 290,
        },
      },
      '& .MuiCardContent-root': {
        width: 490,
        padding: 0,
      },
      ...IconFieldPaddingZeroStyle,
    },
    machineName: {
      fontSize: 18,
      fontWeight: 'bold',
      paddingTop: 16,
    },
    dateTime: {
      paddingLeft: 90,
    },
    machineType: {
      '& .MuiTypography-root': {
        paddingLeft: 95,
        fontSize: 16,
      },
    },
    textInput: {
      '& input': {
        minWidth: 150,
      },
    },
    extendedModel: {
      alignItems: 'baseline',
      '& > p': {
        fontSize: 12,
      },
      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        '& > div': {
          paddingRight: '33%',
        },
      },
    },
    toggleBox: {
      '& .MuiTypography-root': {
        position: 'relative',
        left: 200,
      },
    },
    space: {
      marginBottom: 8,
    },
    flexCenter: {
      justifyContent: 'center',
      width: '100%',
      '& > div': {
        // TopPagination の margin を消す
        margin: 0,
      },
    },
  }),
);

const RetrofitCalibEdit: FC<{
  props: any;
  breadcrumbs: string[];
  detailInfo: any;
}> = ({ props, breadcrumbs, detailInfo }) => {
  const resource = useResource('retrofitCalibs');
  const classes = useStyles();
  const translate = useTranslate();

  const defaultSwingBoom = detailInfo.extendedModel?.swingBoom
    ? detailInfo.extendedModel.swingBoom
    : false;
  const defaultTwoPieceBoom = detailInfo.extendedModel?.twoPieceBoom
    ? detailInfo.extendedModel.twoPieceBoom
    : false;
  const defaultMinimalSwingRadius = detailInfo.extendedModel?.minimalSwingRadius
    ? detailInfo.extendedModel.minimalSwingRadius
    : false;
  const [swingBoom, setSwingBoom] = useState(defaultSwingBoom);
  const [twoPieceBoom, setTwoPieceBoom] = useState(defaultTwoPieceBoom);
  const [minimalSwingRadius, setMinimalSwingRadius] = useState(
    defaultMinimalSwingRadius,
  );

  return (
    <Edit {...props}>
      <CustomForm
        title="admin.pages.retrofitCalibInfo"
        redirect={false}
        saveButton={
          <SaveButton
            swingBoom={swingBoom}
            twoPieceBoom={twoPieceBoom}
            minimalSwingRadius={minimalSwingRadius}
          />
        }
        deleteButton={false}
        className={classes.form}
      >
        <BreadcrumbsFiled
          breadcrumbs={breadcrumbs}
          label="admin.label.calibrationDetail.breadcrumbs"
        />

        {/* 作成日時・更新日時 */}
        <DateField
          className={classes.dateTime}
          resource={resource}
          source="detailInfo.createDateTime"
          showTime
        />
        <DateField
          className={classes.dateTime}
          resource={resource}
          source="detailInfo.updateDateTime"
          showTime
        />

        {/* マシン情報 */}
        <Typography className={classes.title}>
          {translate('admin.label.calibrationDetail.machineInfo')}
        </Typography>
        <CustomParentBox>
          <SimpleShowLayout className={classes.space}>
            <FunctionField
              className={classes.machineType}
              resource={resource}
              source="detailInfo.machineInfo.machineType"
              render={(record: any) => {
                const res = 'resources.retrofits.machineInfoMachineType'; // 建機情報のためretrofitsリソース使用
                const type =
                  record?.detailInfo?.machineInfo?.machineType.toLowerCase();
                const trans = translate(`${res}.${type}`);
                // 翻訳できなければ、もとの内容をそのまま返す
                if (trans.startsWith(res)) return type;
                return trans;
              }}
            />
            <TextInput
              className={classes.textInput}
              resource={resource}
              source="detailInfo.machineInfo.machineId"
            />
            <TextInput
              className={classes.textInput}
              resource={resource}
              source="detailInfo.machineInfo.machineName"
            />
            <TextInput
              className={classes.textInput}
              resource={resource}
              source="detailInfo.machineInfo.companyName"
            />
          </SimpleShowLayout>
        </CustomParentBox>

        {/* マシンジオメトリ情報 */}
        <Typography className={classes.title} />
        <CustomParentBox>
          <CustomChildBox
            title={translate(
              'admin.label.calibrationDetail.machineGeometryInfo',
            )}
          >
            <SimpleShowLayout className={classes.space}>
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.machineGeometryInfo.boomLength"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.machineGeometryInfo.secondBoomLength"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.machineGeometryInfo.armLength"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.machineGeometryInfo.armTopPinToBucketSideLinkPinLength"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.machineGeometryInfo.boomTopPinToBucketSideLinkPinLength"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.machineGeometryInfo.bucketLink1Length"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.machineGeometryInfo.bucketLink2Length"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.machineGeometryInfo.bodyCenterToBoomFootPin.x"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.machineGeometryInfo.bodyCenterToBoomFootPin.y"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.machineGeometryInfo.bodyCenterToBoomFootPin.z"
              />
            </SimpleShowLayout>
          </CustomChildBox>

          {/* IMUマウント情報 */}
          <CustomChildBox
            title={translate('admin.label.calibrationDetail.imuMountInfo')}
          >
            <SimpleShowLayout className={classes.space}>
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.imuMountInfo.boomImuOffsetAngle"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.imuMountInfo.secondBoomImuOffsetAngle"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.imuMountInfo.armImuOffsetAngle"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.imuMountInfo.bucketImuOffsetAngle"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.imuMountInfo.bodyImuOffsetRollAngle"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.imuMountInfo.bodyImuOffsetPitchAngle"
              />
            </SimpleShowLayout>
          </CustomChildBox>
        </CustomParentBox>

        {/* メイン・サブGNSSマウント情報 */}
        <CustomParentBox>
          <CustomChildBox
            title={translate(
              'admin.label.calibrationDetail.bodyCenterToGnssMain',
            )}
          >
            <SimpleShowLayout className={classes.space}>
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.gnssMountInfo.main.bodyCenterToGnss.x"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.gnssMountInfo.main.bodyCenterToGnss.y"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.gnssMountInfo.main.bodyCenterToGnss.z"
              />
            </SimpleShowLayout>
          </CustomChildBox>
          <CustomChildBox
            title={translate(
              'admin.label.calibrationDetail.bodyCenterToGnssSub',
            )}
          >
            <SimpleShowLayout className={classes.space}>
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.gnssMountInfo.sub.bodyCenterToGnss.x"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.gnssMountInfo.sub.bodyCenterToGnss.y"
              />
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.gnssMountInfo.sub.bodyCenterToGnss.z"
              />
            </SimpleShowLayout>
          </CustomChildBox>
        </CustomParentBox>

        {/* 拡張モデル */}
        {/* キャリブレーション情報 */}
        <CustomParentBox>
          <CustomChildBox
            title={translate('admin.label.calibrationDetail.extendedModel')}
          >
            <SimpleShowLayout className={classes.space}>
              <Box className={classes.extendedModel}>
                <Typography>
                  {translate(
                    'resources.retrofitCalibs.fields.detailInfo.extendedModel.swingBoom',
                  )}
                </Typography>
                <BooleanInput
                  className={classes.toggleBox}
                  resource={resource}
                  source="detailInfo.extendedModel.swingBoom"
                  label=""
                  format={() => {
                    return swingBoom;
                  }}
                  onChange={(value: boolean) => {
                    if (value) {
                      setSwingBoom(value);
                      setTwoPieceBoom(false);
                      setMinimalSwingRadius(false);
                    } else {
                      setSwingBoom(value);
                    }
                  }}
                  data-testId="swingBoom"
                />
              </Box>

              <Box className={classes.extendedModel}>
                <Typography>
                  {translate(
                    'resources.retrofitCalibs.fields.detailInfo.extendedModel.twoPieceBoom',
                  )}
                </Typography>
                <BooleanInput
                  className={classes.toggleBox}
                  resource={resource}
                  source="detailInfo.extendedModel.twoPieceBoom"
                  label=""
                  data-testId="twoPieceBoom"
                  format={() => {
                    return twoPieceBoom;
                  }}
                  onChange={(value: boolean) => {
                    if (value) {
                      setSwingBoom(false);
                      setTwoPieceBoom(value);
                      setMinimalSwingRadius(false);
                    } else {
                      setTwoPieceBoom(value);
                    }
                  }}
                />
              </Box>
              <Box className={classes.extendedModel}>
                <Typography>
                  {translate(
                    'resources.retrofitCalibs.fields.detailInfo.extendedModel.minimalSwingRadius',
                  )}
                </Typography>
                <BooleanInput
                  className={classes.toggleBox}
                  resource={resource}
                  source="detailInfo.extendedModel.minimalSwingRadius"
                  label=""
                  data-testId="minimalSwingRadius"
                  format={() => {
                    return minimalSwingRadius;
                  }}
                  onChange={(value: boolean) => {
                    if (value) {
                      setSwingBoom(false);
                      setTwoPieceBoom(false);
                      setMinimalSwingRadius(value);
                    } else {
                      setMinimalSwingRadius(value);
                    }
                  }}
                />
              </Box>
            </SimpleShowLayout>
          </CustomChildBox>
          <CustomChildBox
            title={translate('admin.label.calibrationDetail.measuredValues')}
          >
            <SimpleShowLayout className={classes.space}>
              <CustomNumberInput
                className={classes.textInput}
                resource={resource}
                source="detailInfo.measuredValues.otherLength.machineHeight"
              />
            </SimpleShowLayout>
          </CustomChildBox>
        </CustomParentBox>
      </CustomForm>
    </Edit>
  );
};

RetrofitCalibEdit.displayName = 'RetrofitFlexCalibEdit';
export default RetrofitCalibEdit;
