import React, {
  FC,
  Fragment,
  ReactElement,
  isValidElement,
  cloneElement,
} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },
    space: {
      width: 10,
    },
  }),
);

const Buttons: FC = ({ children }) => {
  const classes = useStyles();
  if (!Array.isArray(children)) return <></>;
  const elems = children as ReactElement[];
  return (
    <div className={classes.root}>
      {elems.map((elem, index) => (
        <Fragment key={index}>
          {isValidElement(elem) && cloneElement(elem)}
          {index === 0 && <span className={classes.space} />}
        </Fragment>
      ))}
    </div>
  );
};

Buttons.displayName = 'Buttons';
export default Buttons;
