import { useGetOne } from 'react-admin';
import { SupportModeData } from '../dataProvider';
import useLoginEmail from './useLoginEmail';

interface OutParams {
  loading: boolean;
  enable: boolean;
  data?: SupportModeData;
}

const useGetSupportMode = (): OutParams => {
  const { email } = useLoginEmail();
  const { data, loading } = useGetOne<SupportModeData>('supportMode', email);
  return {
    loading,
    enable: data?.enabled || false,
    data,
  };
};

export default useGetSupportMode;
