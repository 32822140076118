import { Map } from 'ol';
import { Select } from 'ol/interaction';
import { Vector as SourceVector } from 'ol/source';
import { Interactions, Interaction, PrimitiveType } from './types';

export const toInteractionArray = (src: Interactions): Interaction[] => {
  return [
    src.polygon,
    src.circle,
    src.line,
    src.polygonModify,
    src.circleModify,
    src.lineModify,
    src.point,
    src.pointModify,
    src.select,
    src.translation,
  ];
};

export const dispatchDrawInteraction = (
  interactions: Interactions,
  primitiveType: PrimitiveType,
) => {
  switch (primitiveType) {
    case 'polygon':
      return interactions.polygon;
    case 'circle':
      return interactions.circle;
    case 'line':
      return interactions.line;
    case 'point':
      return interactions.point;
    default:
      throw new Error(`Invalid PrimitiveType: ${primitiveType}`);
  }
};

export const dispatchModifyInteraction = (
  interactions: Interactions,
  primitiveType: PrimitiveType,
) => {
  switch (primitiveType) {
    case 'polygon':
      return interactions.polygonModify;
    case 'circle':
      return interactions.circleModify;
    case 'line':
      return interactions.lineModify;
    case 'point':
      return interactions.pointModify;
    default:
      throw new Error(`Invalid PrimitiveType: ${primitiveType}`);
  }
};

export const removeFeatureBySelectedItem = (
  map: Map,
  select: Select,
  sourceVector: SourceVector,
  featureId: number,
): boolean => {
  if (sourceVector.getFeatures().length <= 0) return false;
  const keyFeatureId = 'featureId';
  const mapLayers = map.getLayers().getArray();
  // MEMO：意味の分からないコード
  const [targetLayer] = mapLayers.filter(
    it => it.get(keyFeatureId) === featureId,
  );
  if (targetLayer) {
    map.removeLayer(targetLayer);
  }

  const feature = sourceVector.getFeatureById(featureId);
  if (!feature) return false;
  // 地図からジオフェンスを削除
  sourceVector.removeFeature(feature);
  select.getFeatures().clear();
  return true;
};

export const existEditFeature = (
  featureId: number,
  sourceVector: SourceVector,
): boolean => {
  const allFeatures = sourceVector.getFeatures();
  if (allFeatures.length <= 0) return false;

  let exsitFlg = false;

  allFeatures.forEach(feature => {
    if (feature.getId() === featureId) {
      exsitFlg = true;
    }
  });

  return exsitFlg;
};
