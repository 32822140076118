// for nl
// オランダ語
import raMessage from 'ra-language-dutch';
import en from './en';

const { ra } = raMessage;
const message: typeof en = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: '',
        toggle_hidden: '',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: 'Niet gevonden',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'OK',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: 'Wilt u %{name} verwijderen?',
    },
  },
  admin: {
    title: {
      normal: 'Smart Construction Pilot',
      split1: 'Smart Construction',
      split2: 'Pilot',
    },
    copyright: 'Copyright © EARTHBRAIN Ltd. All rights reserved.',
    approval: 'In afwachting van goedkeuring',
    usermenu: {
      userNotice: 'Melding',
      terms: 'SERVICEVOORWAARDEN',
      manual: 'Handleiding',
      downloads: 'Downloaden',
    },
    breadcrumbs: {
      details: 'Details',
      retrofitEdit: 'Details',
      taskCreate: 'Maak taak aan',
      taskEdit: 'Taakdetails',
      geofenceCreate: 'Creëren',
      geofenceEdit: 'Bewerken',
      geofenceAlertList: 'Geofence-alarm',
      geofenceAlertShow: 'Details',
      compactionWorkAreaCreate: 'Verdichtingswerkgebieden toevoegen',
      compactionWorkAreaShow: 'Verdichtingswerkgebieden',
      compactionWorkAreaEdit: 'Bewerk verdichtingswerkgebieden',
      compactionLayerCreate: 'Maak verdichtingslagen aan',
      compactionLayerEdit: 'Bewerk verdichtingslagen',
      supportMode: 'Ondersteuningsmodus',
      retrofitManage: 'Controller Registratie',
      retrofitManageAdmin: 'Controller Registratie (Beheerder)',
      retrofitNamePlateFiles: 'Controller Registratie',
      retrofitAlternateRegistCreate: 'Controller Proxy Registratie',
      retrofitAlternateRegistEdit: 'Details',
      retrofitManageAdminBulkActionCreate: 'Registratie',
      terms: 'SERVICEVOORWAARDEN',
      downloads: 'Downloaden',
    },
    pages: {
      siteList: 'Lijst van bouwplaatsen',
      siteRetrofitCreate: 'Machine toevoegen aan werklocatie',
      siteRetrofitEdit: '',
      siteBucketCreate: 'Emmer toevoegen aan werklocatie',
      siteBucketEdit: 'Emmerdetails',
      siteProjectEdit: 'Projectdetails',
      siteProjectCreate: 'Projectbestandsregistratie',
      siteExtensionarmCreate: 'Uitbreidingsarm toevoegen aan werklocatie',
      taskCreate: 'Maak taak aan',
      taskEdit: 'Taakdetails',
      geofenceAlertList: 'Geofence-alarm',
      geofenceAlertShow: 'Geofence-alarm Details',
      geofenceCreate: 'Maak geofence aan',
      geofenceEdit: 'Bewerk geofence',
      compactionWorkAreaCreate: 'Verdichtingswerkgebieden toevoegen',
      compactionLayerCreate: 'Maak verdichtingslagen aan',
      compactionLayerEdit: 'Bewerk verdichtingslagen',
      siteFileEdit: 'Details',
      siteFileCreate: 'Bestand toevoegen aan werklocatie',
      retrofitList: 'Machineoverzicht',
      retrofitEdit: '',
      retrofitRentalCreate: 'Opdrachtregistratie',
      retrofitTransferCreate: 'Overdracht registratie',
      retrofitSendBack: 'Terugstuurregistratie',
      bucketList: 'Emmerlijst',
      bucketEdit: 'Emmerdetails',
      bucketCreate: 'Emmerregistratie',
      bucketRentalCreate: 'Emmer delen',
      extensionarmList: 'Uitbreidingsarmlijst',
      extensionarmEdit: 'Uitbreidingsarmdetails',
      extensionarmShare: 'Uitbreidingsarmdelen',
      retrofitBulkActionList: 'Bulk registratielijst',
      retrofitBulkActionCreate: 'Bulk registratie',
      retrofitBasicInfoList: 'Controllerlijst',
      retrofitBasicInfoCreate: 'Controller Registratie',
      retrofitBasicInfoEdit: 'Controller Update',
      gnssSettingList: 'Lijst met Ntrip-instellingen',
      gnssSettingCreate: 'Maak Ntrip-instelling',
      gnssSettingAdminCreate: 'Maak Ntrip-instelling',
      gnssSettingOwnerCreate: 'Maak Ntrip-instelling',
      gnssSettingEdit: 'Bewerk Ntrip-instelling',
      gnssSettingAdminEdit: 'Bewerk Ntrip-instelling',
      gnssSettingOwnerEdit: 'Bewerk Ntrip-instelling',
      approvalList: 'Machineoverzicht',
      retrofitRentalEdit: 'Opdrachtdetails',
      retrofitTransferEdit: 'Overdrachtsdetails',
      asbuiltsCreate: 'Bouwresultaat verkrijgen',
      asbuiltsShow: 'Asbuild Geschiedenis',
      retrofitConfigList: 'Configuratie-informatie',
      retrofitKitInfoList: 'Info controller',
      retrofitAccuraciesList: 'Nauwkeurigheidscontrole resultaat',
      retrofitAccuraciesShow: 'Details',
      retrofitCalibaList: 'Info kalibratie',
      retrofitCalibInfo: 'Details machinekalibratie-info',
      retrofitBasicSettingList: 'Basisinstellingen',
      retrofitErrorList: 'Foutinformatie',
      retrofitErrorShow: 'Details',
      retrofitTopographicSurveyList: 'Lijst topograf. meting',
      retrofitTopographicSurveyShow: 'Topografische details',
      retrofitShareFileList: 'Toepassingslog',
      retrofitCorporationLicenseList: 'Lijst met licenties',
      retrofitCorporationLicenseEdit: 'Licentiedetails',
      CompactionLayerList: 'Verdichtingslagen',
      userNoticeList: 'Meldingenlijst',
      userNoticeCreate: 'Melding toevoegen',
      userNoticeEdit: 'Melding bijwerken',
      userNoticeDrawer: 'Melding',
      retrofitAlternateRegists: 'Lijst Controller Proxy Registratie',
      firmwareList: 'Firmwarelijst',
      firmwareShow: 'Firmware details',
      firmwareCreate: 'Firmware toevoegen',
      retrofitManage: 'Tekst',
      retrofitNamePlateFiles: 'Foto',
      retrofitManageAdminBulkAction: 'Bulk registratie',
      retrofitManageAdminBulkActionCreate: 'Bulk registratie',
      retrofitAlternateRegistCreate: 'Controller Proxy Registratie',
      retrofitAlternateRegistEdit: 'Details Controller Proxy Registratie',
      retrofitAlternateRegistCreateText: 'Tekst',
      retrofitAlternateRegistCreatePhoto: 'Foto',
      ntripList: 'Ntrip-informatie',
      ntripEdit: 'Beheer van Ntrip',
    },
    label: {
      login: 'Inloggen',
      selected: 'Geselecteerde (%{length} gevallen)',
      listNoResults: 'Gegevens niet gevonden',
      maintenance: {
        title: 'Momenteel in onderhoud.',
        message: `We hebben de diensten tijdelijk opgeschort vanwege onderhoud.
      Onze excuses voor het ongemak, maar wacht alstublieft nog even.`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, ln3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder:
          'Sleep of selecteer die lêer(s) (%{fileTypes}) om op te laai.',
        maxFileNum:
          'Het maximale aantal controllers dat tegelijkertijd kan worden geregistreerd is vijf.',
      },
      nosetting: 'Geen instelling',
      siteRetrofitList: {
        remoteSupport: 'Externe ondersteuning',
      },
      retrofitEdit: {
        breadcrumbs: 'Details',
        belongsDetails: '',
        ntripSettingInfo: 'Informatie over Ntrip-instellingen',
        machineryDetails: 'Machine',
        retrofitDetails: 'Controller',
        basicInfo: 'Basisinfo',
        controller: 'Controller',
        reciverMain: 'GNSS-hoofdontvanger',
        reciverSub: 'GNSS-subontvanger',
        retrofitstatus: 'Controllerstatus',
        license: 'Licentie',
        licenseId: 'Licentie-ID',
        expiredDate: 'Vervaldatum',
      },
      retrofitRental: {
        rentalDetails: 'Opdrachtdetails',
        rentalInfo: 'Opdrachtdetails',
        lending: 'Opdracht',
        return: 'Terug',
        lendingDestination: 'Toegewezen bedrijf',
        lendingCompany: 'Bedrijfsnaam',
        lendingEmail: 'E-mail',
        companyName: 'Bedrijfsnaam',
      },
      retorfitTransfer: {
        tab: 'Overdracht',
        transferInfo: 'Overdrachtsdetails',
        transferButton: 'Overdracht',
        transferEditButton: 'Bewerk overdracht',
      },
      siteConfigureEdit: {
        onButton: 'AAN',
        offButton: 'UIT',
      },
      approvals: {
        rentalTab: 'Opdracht',
        transferTab: 'Overdracht',
      },
      buckets: {
        bucketTab: 'Grijper',
        extensionarmTab: 'Uitbreidingsarm',
        extensionarmFile: 'Uitbreidingsarmbestand',
        shareTo: 'Delen met',
      },
      bucketRental: {
        corporation: 'Delen met',
        bucketType: 'Type',
        bk3File: 'BK3 Bestand',
      },
      retrofitBasic: {
        basicInfoTab: 'Basisinfo',
        bulkActionTab: 'Bulk registratie',
        ntripTab: 'Beheer van Ntrip',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'Terugsturen',
        initialize: 'Initialisatie',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: 'Bulk update',
        retrofitDistributor: 'Distributeur',
        retrofitRegion: 'Zone',
        corporationId: 'Bedrijfs-ID',
        corporationName: 'Bedrijfsnaam',
      },
      retrofitBasicInfoEdit: {
        corporationId: 'Bedrijfs-ID in gebruik',
        corporationName: 'Bedrijf in gebruik',
      },
      gnssSettingsEdit: {
        ownerCorporationName: 'Bedrijfsnaam',
      },
      terms: {
        title: 'SERVICEVOORWAARDEN',
        message:
          'Klik op de onderstaande link om de gebruiksvoorwaarden te bekijken.',
        confirmMessage1:
          'Om de Smart Construction Pilot te gebruiken, moet u akkoord gaan met het volgende document of de volgende documenten.',
        confirmMessage2:
          'Als u niet akkoord gaat, kunt u geen gebruikmaken van Smart Construction Pilot.',
        select: 'Kies uw regio.',
      },
      downloads: {
        title: 'Projectbestandconverter',
        message:
          'Dit is een stand-alone toepassing die een projectbestand maakt van ontwerpbestanden.',
        button: 'Downloaden',
      },
      retrofitBasicInfoCreate: {
        uuid: 'Automatische nummering',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'Downloaden',
        resultFileDownload: 'Downloaden',
      },
      tasks: {
        coordinate: 'Coördinaten (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'Blokgrootte (%{unit})',
      },
      compactionLayers: {
        thickness: 'Dikte (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: 'Details',
        rentalDetail: 'Opdrachtdetails',
      },
      pendingTransfers: {
        breadcrumbs: 'Details',
        transferDetail: 'Overdrachtsdetails',
      },
      pendingAlternateRegists: {
        breadcrumbs: 'Details',
        alternateRegistDetail: 'Details Controller Proxy Registratie',
      },
      supportMode: {
        retrofit: 'Machine',
        optional: 'Optioneel',
      },
      retrofitList: {
        assignment: 'Opdracht',
        license: 'Licentie',
      },
      userNotices: {
        languageDetails: 'Inhoud van de melding',
        language: 'Taal',
        languageEn: 'English',
        languageJa: 'Japans',
        languageFr: 'Frans',
        languageDe: 'Duits',
        languageEs: 'Spaans',
        Information: 'Melding',
        Maintenance: 'Onderhoud',
        emergency: 'Noodsituatie',
        important: 'Belangrijk',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'Controller Registratie',
        corporationName: 'Bedrijfsnaam',
        basicInfoSerialNumber: 'Serienummer',
        confirmInput: 'Voer het serienummer opnieuw in',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'Serienummer',
        status: 'Status',
        imageFile: 'Afbeelding',
        message1:
          'Maak een foto van het serienummer van de controller of de QR-code die duidelijk en onscherp is.',
        message2: 'Afbeeldingen moeten kleiner zijn dan %{mbyte} MB.',
        errormessage:
          'Het maximale aantal controllers dat tegelijkertijd kan worden geregistreerd is vijf.',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: 'Geldig',
          disable: 'Ongeldig',
          unLicense: 'Niet toegewezen',
        },
        machine: 'Machine',
        before: 'Voor',
        after: 'Na',
      },
      geofences: {
        project: 'Project',
        projectSourceLayer: 'Laag',
        rgb: 'Kleur(RGB)',
        select: 'Selectie geofence',
        coordinates: 'Coördinaten',
        coordinatesLabel: 'Coördinaten (N,O)',
        unitLength: 'Eenheid:%{unit}',
      },
      geofenceDialog: {
        coordinate: 'Coördinaten (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: 'Straal',
      },
      retrofitAlternateRegists: {
        menuTitle: 'Controller Proxy Registratie',
        corporationName: 'Bedrijfsnaam',
        email: 'E-mail',
        basicInfoSerialNumber: 'Serienummer',
        confirmInput: 'Voer het serienummer opnieuw in',
      },
      ntripList: {
        unusedTotal: 'Totaal ongebruikt',
      },
      calibrationDetail: {
        breadcrumbs: 'Details',
        machineInfo: 'Info machine',
        machineGeometryInfo: 'Info machinegeometrie',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'Mount-info IMU',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'Mount-info hoofd-GNSS',
        bodyCenterToGnssSub: 'Mount-info sub-GNSS',
        extendedModel: 'Uitgebreid model',
        measuredValues: 'Info kalibratie',
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'Fout bij gegevensconversie',
        system_localization_quantity_error: `Meerdere bestanden met lokalisatie-informatie kunnen niet worden geregistreerd.
      Registreer slechts één bestand dat lokalisatie-informatie bevat.`,
        system_detect_0byte_file_error: `Bestandsregistratie bij de server is mislukt vanwege een communicatiefout.
      Registreer het bestand opnieuw.`,
        system_batch_error: 'Systeemfout (P001)',
        system_parameter_error: 'Systeemfout (P002)',
        system_parameterfile_notfound: 'Systeemfout (P003)',
        system_exception: 'Systeemfout (P004)',
        system_logfile_error: 'Systeemfout (P005)',
        system_parameterfile_parameter_error: 'Systeemfout (P006)',
        desing_localization_error: `Er is geen lokaliseringsinformatie beschikbaar.
      Registreer alstublieft een van de volgende bestanden:

      ・GC3-bestand
      ・TP3-bestand met controlepunten
      ・CAL-bestand`,
        desing_no_support_dxf_error:
          'De DXF-bestand(en) kon(den) niet worden gelezen omdat de versie ouder is dan AutoCAD2000.',
        desing_tile_error: 'Het maken van het projectbestand is mislukt.',
        desing_outline_error:
          'Het maken van de buitenste grenslijn is mislukt.',
        desing_area_length_error:
          'Het proces is afgebroken omdat het projectgebied te groot is. Controleer of de ontwerpgegevens en de schaal van de CAD-gegevens correct zijn.',
        desing_layer_name_duplication_error: `Projectgegevens met dubbele laagnamen kunnen niet worden geregistreerd.
      Wijzig alstublieft de ontwerp laagnaam of registreer zodat er slechts één bestand is met dezelfde ontwerp laagnaam.`,
        targetFiles: '[Bestandsnaam]',
        targetLayers: '[Laag]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: 'Geen',
        system_batch_error: 'Systeemfout (T001)',
        system_parameter_error: 'Systeemfout (T002)',
        system_parameterfile_notfound: 'Systeemfout (T003)',
        system_exception: 'Systeemfout (T004)',
        system_logfile_error: 'Systeemfout (T005)',
        system_basefile_notfound: 'Systeemfout (T006)',
        system_prjfile_multi: 'Systeemfout (T007)',
        system_basefile_read_error: 'Systeemfout (T008)',
        system_target_layer_notfound: 'Systeemfout (T009)',
        system_target_layer_duplication: 'Dezelfde laagnaam bestaat al.',
        system_taskarea_error: 'Ongeldig taakgebied',
        system_taskarea_design_notfound:
          'Er is geen data voor projectbestand binnen het taakgebied (TIN, lijnwerk, punten).',
        system_tile_error: 'Het maken van het projectbestand is mislukt.',
        system_outline_error:
          'Het maken van de buitenste grenslijn is mislukt.',
        system_parameterfile_error: 'Systeemfout',
      },
      retrofitError: {
        1000: 'UDP-gegevens van controller niet gedetecteerd',
        2000: 'IMU machine niet gedetecteerd',
        2001: 'Hardwarefout IMU machine',
        2002: 'Algoritmefout IMU machine',
        2003: 'Gegevensfout IMU machine',
        2004: 'Initialisatiefout IMU machine',
        2100: 'IMU giek niet gedetecteerd',
        2101: 'Hardwarefout IMU giek',
        2102: 'Algoritmefout IMU giek',
        2103: 'Gegevensfout IMU giek',
        2104: 'Initialisatiefout IMU giek',
        2200: 'IMU arm niet gedetecteerd',
        2201: 'Hardwarefout IMU arm',
        2202: 'Algoritmefout IMU arm',
        2203: 'Gegevensfout IMU arm',
        2204: 'Initialisatiefout IMU arm',
        2300: 'IMU grijper niet gedetecteerd',
        2301: 'Hardwarefout IMU grijper',
        2302: 'Algoritmefout IMU grijper',
        2303: 'Gegevensfout IMU grijper',
        2304: 'Initialisatiefout IMU grijper',
        2400: 'IMU kantelgrijper niet gedetecteerd',
        2401: 'Hardwarefout IMU kantelgrijper',
        2402: 'Algoritmefout IMU kantelgrijper',
        2403: 'Gegevensfout IMU kantelgrijper',
        2404: 'Initialisatiefout IMU kantelgrijper',
        2500: 'IMU 2e giek niet gedetecteerd',
        2501: 'Hardwarefout IMU 2e giek',
        2502: 'Algoritmefout IMU 2e giek',
        2503: 'Gegevensfout IMU 2e giek',
        2504: 'Fout bij opstarten IMU 2e giek',
        2600: 'Zwenkhoeksensor niet gedetecteerd',
        2601: 'Systeemfout in de zwenkhoeksensor',
        2602: 'Hardwarefout met de zwenkhoeksensor',
        2700: 'Offsetsensor niet gedetecteerd',
        2701: 'Systeemfout in de Offsetsensor',
        2702: 'Hardwarefout met de Offsetsensor',
        3000: 'Druksensor kopzijde niet gedetecteerd',
        3100: 'Druksensor onderzijde niet gedetecteerd',
        4000: 'GNSS-antenne niet gedetecteerd',
        5000: 'Correctiegegevens niet gedetecteerd',
        5001: 'Signaal radio-ontvanger niet gedetecteerd',
      },
    },
    dialog: {
      menu: {
        confirmManualLink:
          'U wordt doorgestuurd naar een andere website. Is dit OK?',
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'Grijper',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message: 'Bedrijfs-ID en bedrijfsnaam zijn vereist.',
        },
        save: {
          message1: 'Bedrijfs-ID/naam kan niet worden gewijzigd omdat het',
          message2: 'machine(s) toegewezen aan een ander bedrijf.',
          message3: '',
          message4: 'Controleer de volgende serienummers',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'Controller',
        },
        undeletable: {
          message: 'De controller is in gebruik en kan niet worden verwijderd.',
        },
      },
      Ntrip: {
        save: {
          stop: 'Wilt u deze Ntrip-instelling stoppen?',
          delete: 'Wilt u de Ntrip-instelling verwijderen?',
        },
      },
      siteRetrofitList: {
        title: 'Aanvullende informatie',
        message: 'De bouwperiode is voorbij.',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: 'Gemeenschappelijke instellingen',
          siteSetting: 'Instellingen bouwplaats',
        },
        mapBackgroundColor: 'Achtergrondkleur',
        message:
          'De functie geofence-contactdetectie/waarschuwingsmelding werkt mogelijk niet naar behoren, afhankelijk van de omgeving en de omstandigheden waarin deze wordt gebruikt. Gebruik de functie niet overmoedig, en zorg ervoor dat u de functie en de gebruiksvoorwaarden begrijpt.',
      },
      geofenceList: {
        confirmDelete: {
          message: 'Wilt u deze geofence verwijderen?',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1:
            'De machine is gekoppeld aan een jobsite en kan niet worden toegewezen. Koppel de machine los van de jobsite.',
          message2: 'Koppel de machine los van de bouwplaats.',
        },
        sameOwner: {
          message:
            'De machine kan niet worden toegewezen omdat uw bedrijf de eigenaar is.',
        },
        unableTransfer: {
          message:
            'De machine wacht op goedkeuring voor overdracht en kan niet worden geregistreerd voor toewijzing.',
        },
        unableAlternateRegist: {
          message:
            'De machine wacht op goedkeuring van de Proxy-registratie van de controller en kan niet worden geregistreerd voor toewijzing.',
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          'De machine is gekoppeld aan een jobsite en kan niet worden overgedragen. Koppel de machine los van de jobsite.',
        messageRentaled:
          'De machine is toegewezen en kan niet worden overgedragen.',
        messageLicense:
          'De machine heeft een licentie en kan niet worden overgedragen.',
        pendingApprovals: {
          message1: 'De transferaanvraag is ontvangen. Wacht op goedkeuring.',
          message2:
            'Als u vragen hebt, neem dan contact met ons op via de onderstaande URL.',
        },
      },
      retrofitSendBacks: {
        submit:
          'Wilt u de controller terugsturen en de instellingen overerven naar de doelcontroller?',
        deleteNtrip: 'Wilt u de teruggegeven controller initialiseren?',
        unableRegion:
          'Deze controller is overzees verzonden. Als deze controller juist is, stel dan de "Regio" in op null.',
        unableCorporation: {
          message1: 'De controller kan niet worden teruggestuurd.',
          message2:
            'Als u denkt dat dit een fout is, neem dan contact op met uw dealer.',
        },
        retrofitRentaled:
          'De controller is toegewezen en kan niet worden teruggestuurd.',
        tiedToSite:
          'De machine is gekoppeld aan een jobsite en kan niet worden teruggestuurd.',
      },
      retrofitAddToSiteAction: {
        title: 'Aanvullende informatie',
        message:
          'Er wacht een machine op goedkeuring, dus deze kan niet worden gekoppeld aan de jobsite.',
      },
      retrofitAddToSiteActionTransfer: {
        title: 'Aanvullende informatie',
        message:
          'De machine wordt overgebracht en kan niet aan de jobsite worden gekoppeld.',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message:
            'De geselecteerde Ntrip-instelling wordt naar een andere machine verzonden.',
        },
        unableTransferInfo: {
          message: 'Om over te dragen, annuleer de volgende instellingen.',
          reasonSiteId: 'Bouwplaats',
          reasonRentaled: 'Opdracht',
          reasonLicense: 'Licentie',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            'Het toegewezen bedrijf kent momenteel de machine toe aan een ander bedrijf. Startdatum van de toewijzing aan een ander bedrijf is %{date}.',
          message2: 'Selecteer een einddatum na %{date}.',
        },
        confirmTieUpJobsite: {
          message:
            'De machine is gekoppeld aan een jobsite bij het toegewezen bedrijf. Wilt u deze toewijzing beëindigen?',
        },
        confirmDoubleRental: {
          message:
            '"Het toegewezen bedrijf kent momenteel de machine toe aan een ander bedrijf. Het toegewezen bedrijf kent momenteel de machine toe aan een ander bedrijf. Het instellen van een einddatum beëindigt ook de toewijzing aan een ander bedrijf. Wilt u de einddatum instellen?"',
        },
        unapprovedSave: {
          message:
            'Deze opdracht is niet goedgekeurd en kan niet worden opgeslagen.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Wilt u deze taak verwijderen?',
        },
        approvedDelete: {
          title: '',
          message:
            'Deze opdracht is goedgekeurd en kan niet worden verwijderd.',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message:
            'De machine is momenteel toegewezen en kan niet worden overgedragen.',
        },
        approvedSave: {
          message:
            'De machine heeft een licentie en kan niet worden overgedragen.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Wilt u deze overdracht verwijderen?',
        },
        approvedDelete: {
          title: '',
          message:
            'Deze overdracht is goedgekeurd en kan niet worden verwijderd.',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'Uitbreidingsarm',
        },
      },
      projectFileUpload: {
        message:
          'Meerdere bestanden met controlepunten (.gc3/.cal) kunnen niet worden geregistreerd.',
      },
      projectEdit: {
        confirm: {
          message:
            'Bij het bijwerken van het project worden de bestaande taken verwijderd. Wil je bijwerken?',
        },
      },
      projectConversionStatus: {
        title: 'Conversiestatus',
        button: 'OK',
      },
      taskConversionStatus: {
        title: 'Conversiestatus',
        button: 'OK',
      },
      taskList: {
        undeletable: {
          message:
            'De geselecteerde taak kan niet worden verwijderd omdat deze in gebruik is.',
        },
        unableToCreate: {
          message: 'Er zijn geen ontwerpdata geregistreerd in het project.',
        },
        unableToDelete: {
          message1:
            'De taak kan niet worden verwijderd omdat de status van het project en de status van de taak als volgt zijn.',
          message2: 'Project: Converteren',
          message3: 'Taak: Fout',
        },
        unableToUpdate: {
          message1:
            'De taak kan niet worden bewerkt omdat de status van het project en de status van de taak niet als volgt zijn.',
          message2: 'Project: [Geconverteerd]',
          message3: 'Taak: [Overdragen][Overgedragen][Overdrachtsfout]',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'Taak',
        },
        unableToDelete: {
          message:
            'De geselecteerde taak kan niet worden verwijderd omdat deze in gebruik is.',
        },
      },
      taskMap: {
        crossingMessage: 'Het taakgebied wordt doorsneden.',
        sameValueMessage: 'Dezelfde coördinaten zijn ingevoerd.',
        areaZeroMessage: 'Er is geen gebied voor het taakgebied.',
        noPolygonMessage:
          'De taak kan niet worden geregistreerd omdat deze nog niet is geregistreerd.',
      },
      compactionMaterials: {
        add: {
          title: 'Materiaal toevoegen',
        },
        update: {
          title: 'Materiaal bijwerken',
        },
      },
      weathers: {
        add: {
          title: 'Weer toevoegen',
        },
        update: {
          title: 'Weer bijwerken',
        },
      },
      operators: {
        add: {
          title: 'Operator toevoegen',
        },
        update: {
          title: 'Operator bijwerken',
        },
      },
      compactionWorkAreas: {
        update: {
          message: 'Bewerk verdichtingswerkgebieden',
        },
        undeletable: {
          message:
            'De verdichtingslaag in het bouwgebied is in gebruik en kan niet worden verwijderd.',
        },
      },
      compactionLayers: {
        undeletable: {
          message:
            'De verdichtingslaag is in gebruik en kan niet worden verwijderd.',
        },
        deleteConfirm: {
          messageTargetItem: 'Verdichtingslaag',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message:
            'Er wordt een andere Ntrip-instelling naar de geselecteerde machine gestuurd.',
        },
        confirmDelete: {
          message: 'Wilt u de Ntrip-instelling verwijderen?',
        },
        confirmDeleteWithMachine: {
          message:
            'De machine is momenteel gekoppeld. Wilt u de Ntrip-instelling verwijderen?',
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'Melding',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: 'Stel de licentie(s) in op de volgende machine.',
        },
        changeDisable: {
          message:
            'Wijzig de licentie-ID van de volgende bouwmachines in geldig.',
        },
        changeTemporary: {
          message:
            'Stel de licentie(s) in op de volgende machine. De huidige tijdelijke licentie wordt ongeldig verklaard.',
        },
        errorAdd: {
          message:
            'De licentie van de machine kan niet veranderd worden omdat All license al ingesteld is.',
        },
        errorSameType: {
          message:
            'De licentie van de machine kan niet worden gewijzigd omdat hetzelfde type licentie al is ingesteld.',
        },
        errorUnlimited: {
          message:
            'De machine heeft al een licentie ingesteld Onbeperkt, andere licenties kunnen niet worden ingesteld.',
        },
        addAll: {
          message:
            'De volgende machine heeft al een licentie. Wilt u alle licenties instellen?',
        },
        compare: {
          message: 'Wijzig de licentie(s) voor de volgende machine.',
        },
        warning: {
          message:
            'De %{retrofit} heeft licentie van %{id}. Wilt u de licentie verbreken?',
        },
        unableTransfer: {
          message:
            'De machine wordt overgeplaatst zodat de licentie niet kan worden gekoppeld.',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial: 'Er zijn gegevens die niet geanalyseerd konden worden.',
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1:
            'De registratieaanvraag is ontvangen. Wacht tot het is goedgekeurd.',
          message2:
            'Als u vragen hebt, neem dan contact met ons op via de onderstaande URL.',
        },
        corporationIdCheck: {
          message1:
            'De controller is al geregistreerd. Controleer dit door te zoeken in de machines.',
          message2:
            'Als u de doelcontroller niet kunt vinden in de lijst, neem dan contact met ons op via de onderstaande URL.',
        },
        earthbrainIdCheck: {
          message1: 'Onze excuses voor het ongemak',
          message2:
            'maar neem contact met ons op vanaf de onderstaande URL met de volgende informatie.',
          message3: '-Controller serienummer',
          message4:
            '-Naam van de machine die moet worden geregistreerd (optioneel)',
          message5: '-Bedrijfs-ID om te registreren',
        },
        invalidSerialNumber: {
          message1: 'De controller wordt al gebruikt door een ander bedrijf.',
          message2: 'Als u het om andere redenen wilt wijzigen,',
          message3:
            'Neem contact met ons op via de onderstaande URL met de volgende informatie.',
          message4: '-Controller serienummer',
          message5:
            '-Naam van de machine die moet worden geregistreerd (optioneel)',
          message6: '-Bedrijfs-ID om te registreren',
          message7: '-Reden voor wijziging',
        },
        lendingRetrofits: {
          message1: 'De controller is toegewezen.',
          message2:
            'Dien een nieuwe aanvraag in nadat de toewijzing bij de toewijzende onderneming is beëindigd.',
          message3:
            'Als u vragen hebt, neem dan contact met ons op via de onderstaande URL.',
        },
        siteAttached: {
          message1: 'De controller is gekoppeld aan een werklocatie.',
          message2:
            'Dien een nieuwe aanvraag in nadat u de machine van de bouwplaats heeft losgekoppeld.',
          message3:
            'Als u vragen hebt, neem dan contact met ons op via de onderstaande URL.',
        },
        serialNumberExistError: {
          message1:
            'Dit is het serienummer van een controller die niet bestaat. Controleer of het ingevoerde serienummer van de controller correct is.',
          message2:
            'Als het probleem aanhoudt, neem dan contact met ons op via de onderstaande URL.',
        },
        ntripShortage: {
          message1:
            'We konden uw Ntrip-gebruikers-ID en wachtwoord niet uitgeven.',
          message2: 'Neem contact met ons op via de onderstaande URL.',
        },
        serialnumberCheck: {
          message: 'Controleer de producten met de volgende serienummers',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: 'retrofitAlternateRegists',
        },
        saveValidation: {
          message: 'E-mail is vereist',
        },
        pendingApprovals: {
          message1:
            'Het registratieverzoek is ontvangen. Wacht tot het is goedgekeurd.',
          message2:
            'Als u vragen hebt, neem dan contact met ons op via de onderstaande URL.',
        },
        corporationIdCheck: {
          message1:
            'De controller is al geregistreerd. Controleer dit door te zoeken in de machines.',
          message2:
            'Als u de doelcontroller niet kunt vinden in de lijst, neem dan contact met ons op via de onderstaande URL.',
        },
        earthbrainIdCheck: {
          message1: 'Onze excuses voor het ongemak',
          message2:
            'maar neem contact met ons op vanaf de onderstaande URL met de volgende informatie.',
          message3: '-Controller serienummer',
          message4:
            '-Naam van de machine die moet worden geregistreerd (optioneel)',
          message5: '-Bedrijfs-ID om te registreren',
        },
        invalidSerialNumber: {
          message1: 'De controller wordt al gebruikt door een ander bedrijf.',
          message2: 'Als u het om andere redenen wilt wijzigen,',
          message3:
            'Neem contact met ons op via de onderstaande URL met de volgende informatie.',
          message4: '-Controller serienummer',
          message5:
            '-Naam van de machine die moet worden geregistreerd (optioneel)',
          message6: '-Bedrijfs-ID om te registreren',
          message7: '-Reden voor wijziging',
        },
        lendingRetrofits: {
          message1: 'De controller is toegewezen.',
          message2:
            'Dien een nieuwe aanvraag in nadat de toewijzing bij de toewijzende onderneming is beëindigd.',
          message3:
            'Als u vragen hebt, neem dan contact met ons op via de onderstaande URL.',
        },
        siteAttached: {
          message1: 'De controller is gekoppeld aan een werklocatie.',
          message2:
            'Dien een nieuwe aanvraag in nadat u de machine van de bouwplaats heeft losgekoppeld.',
          message3:
            'Als u vragen hebt, neem dan contact met ons op via de onderstaande URL.',
        },
        serialNumberExistError: {
          message1:
            'Dit is het serienummer van een controller die niet bestaat. Controleer of het ingevoerde serienummer van de controller correct is.',
          message2:
            'Als het probleem aanhoudt, neem dan contact met ons op via de onderstaande URL.',
        },
        ntripShortage: {
          message1:
            'We konden uw Ntrip-gebruikers-ID en wachtwoord niet uitgeven.',
          message2: 'Neem contact met ons op via de onderstaande URL.',
        },
        serialnumberCheck: {
          message: 'Controleer de producten met de volgende serienummers',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1: 'Deze controller is al eigendom van uw bedrijf.',
          message2: 'Neem contact op met de aanvragende onderneming.',
        },
        userCorporation: {
          message1:
            'U kunt deze aanvraag niet goedkeuren omdat u deze controller al uitleent.',
          message2: 'Neem contact op met de aanvragende onderneming.',
        },
      },
      licensePurchaseNotice: {
        button: 'OK',
        message1: `Er is een machine in gebruik op locatie zonder licentie.
        Een licentie is vereist voor gebruik op locatie.
        Als u geen licentie hebt aangeschaft, schaf er dan een aan via de Smart Construction Marketplace.`,
        message2: `
        Als u huurt van een ander bedrijf, vraag dan een licentie voor de machine aan bij de verhuurder.

        Als u vragen hebt, kijk dan op de volgende ondersteuningssite.`,
      },
      siteBuriedStructureEdit: {
        title: 'Buried Structure',
      },
    },
    tootip: {
      retrofitShareFileList: 'Toepassingslog',
      retrofitConfigList: 'Configuratie-informatie',
      retrofitKitInfoList: 'Info controller',
      retrofitAccuraciesList: 'Nauwkeurigheidscontrole resultaat',
      retrofitCalibaList: 'Info kalibratie',
      retrofitBasicSettingList: 'Basisinstell.',
      retrofitErrorList: 'Foutdetails',
      retrofitTopographicSurveyList: 'Topografische meting',
    },
    actions: {
      ok: 'OK',
      add: 'Toevoegen',
      create: 'Registratie',
      yes: 'Ja',
      no: 'Nee',
      confirmation: 'Bevestigen',
      save: 'OPSL.',
      cancel: 'Annuleren',
      required: 'Vereist',
      addMachineToSite: 'Machine toevoegen aan werklocatie',
      addBucketToSite: 'Toevoegen',
      deleteFromSite: 'Verbreek verbinding met Jobsite',
      removeLicense: 'Verbreek verbinding met Machine',
      lendMachine: 'Opdracht',
      transferMachine: 'Overdracht',
      remoteSupport: 'Externe ondersteuning',
      lendBucket: 'Delen',
      approval: 'Goedkeuring',
      connect: 'Verbinden',
      creationInstructions: 'Bouwresultaat verkrijgen',
      csvDownload: 'Downloaden',
      retrofitBundleCreate: 'Bulk registratie',
      termsAccept: 'Akkoord',
      termsDeny: 'Oneens',
      bulkRegistration: 'Bulk update',
      bulkRegistrationOK: 'OK',
      compactionItems: 'Verdichtingsitems',
      addLanguage: 'Taal toevoegen',
    },
    message: {
      error: 'Er is een fout opgetreden',
      confirm: 'Is het ok?',
      fileUpload: 'Sleep bestanden hier',
      success: 'Registratie is voltooid',
      approved: 'De goedkeuring is voltooid',
      deleteConfirmTitle: '',
      deleteConfirmContent: 'Wilt u %{title} verwijderen?',
      bulkDeleteConfirmContent: 'Wilt u %{title} verwijderen?',
      disconnectFromSite: 'Wilt u verbinding verbreken met Jobsite?',
      saveConfirmContent: 'Bevestigen',
      removeLicense: 'Wilt u verbinding verbreken met Machine?',
      task: {
        crossingMessage: 'Het taakgebied wordt doorsneden.',
        sameValueMessage: 'Dezelfde coördinaten zijn ingevoerd.',
        areaZeroMessage: 'Er is geen gebied voor het taakgebied.',
        invalidPolygonMessage: 'Dezelfde coördinaten zijn ingevoerd.',
      },
      geofence: {
        crossingMessage: 'Het geofence gebied wordt doorsneden.',
        sameValueMessage: 'Dezelfde coördinaten zijn ingevoerd.',
        areaZeroMessage: 'Er is geen gebied voor het geofence-gebied.',
      },
      licenseError:
        'U hebt geen licentie voor Smart Construction Pilot(Web App).',
      retrofitsManage: {
        serialNumberMatchError: 'Het serienummer komt niet overeen.',
        serialNumberInputError: 'Het serienummer bestaat niet.',
        invalidAccess:
          'U bent niet gemachtigd om deze pagina te bekijken, keer terug naar de bovenste pagina.',
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'Het serienummer komt niet overeen.',
        serialNumberInputError: 'Het serienummer bestaat niet.',
        retrofitRentaled:
          'Bedrijfsinformatie kan niet worden bijgewerkt terwijl de controller in opdracht is.',
        invalidCorporation:
          'Aangezien uw bedrijf geen distributeur is, kunt u geen proxyregistratie van de controller uitvoeren.',
      },
    },
    validation: {
      startDate: 'Startdatum',
      endDate: 'Einddatum',
      invalidDateFormat: 'De datumnotatie is ongeldig.',
      invalidDateTimeFormat: 'De datum- en tijdnotatie is ongeldig.',
      duplicateProject: 'Projectnaam is gedupliceerd',
      incorrectBeforeDate: '%{target} moet worden ingesteld voor %{before}.',
      incorrectAfterDate: '%{target} moet worden ingesteld na %{after}.',
      incorrectPlanStartDate:
        'De geplande startdatum moet voor de geplande einddatum liggen.',
      incorrectPlanEndDate:
        'De geplande einddatum moet een datum zijn na de geplande startdatum.',
      incorrectActualStartDate: 'De startdatum moet voor de einddatum liggen.',
      incorrectActualEndDate:
        'De einddatum moet na de begindatum worden ingesteld.',
      withActualStartDate: 'Voer ook de startdatum in.',
      inputAvailableDate:
        'De datum die kan worden ingevoerd, is vanaf %{date}.',
      registrationRequired: 'Kies het toegewezen bedrijf of e-mailadres.',
      bothInput:
        'U kunt alleen het toegewezen bedrijf of het e-mailadres invoeren.',
      supportMode: 'Voer ofwel een bedrijfs-ID of een bouwplaats-ID in.',
      invalidBkFile: 'Dit is geen BK3-bestand.',
      invalidPdfFile: 'Dit is geen PDF-bestand.',
      duplicatedBucketName: 'Emmernaam is gedupliceerd',
      duplicatedTaskName: 'Taaknaam is gedupliceerd.',
      duplicateGeofenceName: 'Geofence-naam is gedupliceerd',
      duplicatedCompactionWorkAreaName: 'Duplicaatverdichtingswerknaam',
      duplicatedCompactionLayerName: 'Duplicaatverdichtingslaagnaam',
      duplicated: {
        compactionMaterials: {
          message: 'Duplicaatmateriaalnaam',
        },
        weathers: {
          message: 'Duplicaatweernaam',
        },
        operators: {
          message: 'Duplicaatoperatornaam',
        },
      },
      containInvalidString:
        'Ongeldige tekens zijn inbegrepen. (%{msg} is niet toegestaan)',
      validHalfWidthAlphanumeric:
        'Alleen halfbrede alfanumerieke tekens zijn toegestaan.',
      validNumberFormat: 'Geen nummer',
      validIntegerFormat: 'Geen geheel getal',
      validIntegerRangeFormat: 'Invoer is toegestaan van 0 tot 2147483647.',
      validDuplicateLanguage: 'Dezelfde taal is geselecteerd.',
      validBeforeDate:
        '%{target} moet een datum en tijd voor %{before} worden ingesteld.',
      validAfterDate:
        '%{target} moet een datum en tijd na %{after} worden ingesteld.',
    },
  },
  resources: {
    sites: {
      name: 'Bouwplaats',
      fields: {
        name: 'Bouwplaats',
        corporationName: 'Bedrijfsnaam',
        status: 'Status',
        workPeriodStartDate: 'Startdatum',
        workPeriodEndDate: 'Einddatum',
      },
      status: {
        completed: 'Voltooid',
        waiting: 'Vóór de bouw begint',
        working: 'In aanbouw',
      },
    },
    retrofits: {
      name: 'Machine',
      fields: {
        information: 'Info machine',
        rental: 'Opdracht',
        sendBack: 'Terugsturen',
        corporationId: 'Bedrijf in gebruik',
        siteId: 'Bouwplaats',
        uuid: 'UUID',
        isError: 'Status',
        isOnline: 'Online',
        lastOnlineTime: 'Laatste verbinding',
        basicInfoManufacturer: 'Fabrikant',
        basicInfoModel: 'Model',
        basicInfoProductNumber: 'Productnr.',
        basicInfoSerialNumber: 'Serienr.',
        controllerManufacturer: 'Fabrikant',
        controllerModel: 'Model',
        controllerFirmwareVersion: 'Firmwareversie',
        gnssReceiverMainManufacturer: 'Fabrikant',
        gnssReceiverMainModel: 'Model',
        gnssReceiverMainFirmwareVersion: 'Firmwareversie',
        gnssReceiverSubManufacturer: 'Fabrikant',
        gnssReceiverSubModel: 'Model',
        gnssReceiverSubFirmwareVersion: 'Firmwareversie',
        machineInfoCompanyName: 'Producent',
        machineInfoMachineType: 'Machinetype',
        machineInfoMachineName: 'Machinenaam',
        machineInfoMachineId: 'Machine-ID',
        rentalStatus: 'Opdracht',
        lastUpdated: 'Laatst bijgewerkt',
      },
      isError: {
        true: 'Fout',
        false: 'Normaal',
      },
      isOnline: {
        true: 'Online',
        false: 'Offline',
      },
      machineInfoMachineType: {
        excavator: 'Graafmachine',
        roadroller: 'Wals',
        dozer: 'Bulldozer',
        rigiddump: 'Starre kiepwagen',
        wheelloader: 'Wiellader',
        grader: 'Wegwerker',
        automobile: 'Auto',
      },
      licenseMachineType: {
        excavator: 'Graafmachine',
        roadroller: 'Wals',
        dozer: 'Bulldozer',
        rigiddump: 'Starre kiepwagen',
        wheelloader: 'Wiellader',
        grader: 'Wegwerker',
        automobile: 'Auto',
        excavator_license: 'Graafmachine : %{licenseGrantInfo}',
        roadroller_license: 'Wals : %{licenseGrantInfo}',
        dozer_license: 'Bulldozer : %{licenseGrantInfo}',
        rigiddump_license: 'Starre kiepwagen : %{licenseGrantInfo}',
        wheelloader_license: 'Wiellader: %{licenseGrantInfo}',
        grader_license: 'Wegwerker : %{licenseGrantInfo}',
        automobile_license: 'Auto: %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: 'Overdragen',
        sent: 'Overgedragen',
        failtosend: 'Overdracht mislukt',
      },
      rentalStatus: {
        approved: 'Goedgekeurd',
        unapproved: 'Niet goedgekeurd',
        returned: 'Teruggebracht',
      },
    },
    retrofitRentals: {
      name: 'Opdracht',
      fields: {
        id: 'ID',
        retrofitId: 'Machine-ID',
        fromCorporationId: 'Toewijzend bedrijfs-ID',
        fromCorporationName: 'Toewijzend bedrijf',
        toCorporationId: 'Toegewezen bedrijf',
        toCorporationName: 'Bedrijf in gebruik',
        retrofitRentalId: 'Opdracht-ID',
        isApproved: 'Status',
        isReturned: 'Teruggebracht',
        planStartDate: 'Geplande startdatum',
        planEndDate: 'Geplande einddatum',
        actualStartDate: 'Startdatum',
        actualEndDate: 'Einddatum',
        rentalUsers: 'E-mail',
        email: 'E-mail',
        rentalStatus: 'Status',
      },
      isApproved: {
        true: 'Goedgekeurd',
        false: 'Niet goedgekeurd',
      },
      rentalStatus: {
        approved: 'Goedgekeurd',
        unapproved: 'Niet goedgekeurd',
        returned: 'Teruggebracht',
      },
    },
    pendingRentals: {
      name: 'Wachten op goedkeuring',
      fields: {
        fromCorporationName: 'Toewijzend bedrijf',
        planStartDate: 'Geplande startdatum',
        planEndDate: 'Geplande einddatum',
      },
    },
    pendingTransfers: {
      name: 'Wachten op goedkeuring',
      fields: {
        fromCorporationName: 'Overdragende bedrijf',
        planDate: 'Geplande overdrachtsdatum',
        lastUpdated: 'Laatst bijgewerkt',
      },
    },
    pendingAlternateRegists: {
      name: 'Wachten op goedkeuring',
      fields: {
        fromCorporationName: 'Huidige eigenaar',
        lastUpdated: 'Laatst bijgewerkt',
      },
    },
    assignments: {
      name: 'Overdracht',
      fields: {
        id: 'ID',
        retrofitId: 'Machine-ID',
        fromCorporationId: 'ID van overdragend bedrijf',
        fromCorporationName: 'Overdragende bedrijf',
        toCorporationId: 'Overgedragen bedrijf',
        toCorporationName: 'Bedrijf',
        isApproved: 'Status',
        planDate: 'Geplande overdrachtsdatum',
        actualDate: 'Overdrachtsdatum',
        transferUsers: 'E-mail',
        email: 'E-mail',
        lastUpdated: 'Laatst bijgewerkt',
      },
      isApproved: {
        true: 'Goedgekeurd',
        false: 'Niet goedgekeurd',
      },
    },
    buckets: {
      name: 'Grijper',
      fields: {
        companyName: 'Bedrijfsnaam',
        siteId: 'Bouwplaats',
        name: 'Naam',
        corporationId: 'Bedrijf in gebruik',
        bucketType: 'Type',
        bk3File: 'BK3 Bestand',
        'bk3File.title': 'BK3 Bestand',
        gltfFile: 'Grijperbestand',
        uploadedBy: 'Geregistreerd door',
        updatedBy: 'Bijgewerkt door',
        fileStatus: 'Status',
        lastUpdated: 'Laatst bijgewerkt',
        uploadBk: 'Upload BK3',
      },
      bucketType: {
        slope: 'Helling',
        standard: 'Standaard',
        tilt: 'Kantelen',
        tiltrotator: 'Kantelrotator',
      },
      uploadedBy: {
        admin: 'Beheerder',
        tablet: 'Tablet',
      },
      fileStatus: {
        converting: 'Converteren',
        completed: 'Voltooid',
        error: 'Fout',
      },
    },
    projects: {
      name: 'Project',
      fields: {
        name: 'Projectnaam',
        coordinate: 'Coördinatensyst.',
        version: 'Versie',
        status: 'Status',
        lastUpdated: 'Laatst bijgewerkt',
        regionId: 'Zone',
        projectionId: 'Projectie',
        geoidId: 'Geoïde',
        datumId: 'Datum',
        file: 'Bestanden',
        retrofitId: 'Doelmachine',
      },
      status: {
        converted: 'Voltooid',
        waitforconvert: 'Converteren',
        error: 'Fout',
      },
    },
    extensionarms: {
      name: 'Uitbreidingsarm',
      fields: {
        extensionarmType: 'Type',
        name: 'Naam',
        corporationId: 'Bedrijf in gebruik',
        corporationName: 'Bedrijf in gebruik',
        siteId: 'Bouwplaats',
        siteName: 'Bouwplaats',
        lastUpdated: 'Laatst bijgewerkt',
        extensionarmFile: 'Uitbreidingsarmbestand',
      },
      extensionarmType: {
        basic: 'Uitbreidingsarm',
        a: 'Type A',
        b: 'Type B',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: 'Delen met',
        name: 'Naam',
        extensionarmType: 'Type',
        extensionarmFile: {
          title: 'Uitbreidingsarmbestand',
        },
      },
    },
    corporations: {
      name: 'Bedrijf',
      fields: {
        name: 'Bedrijf in gebruik',
      },
    },
    retrofitShareFiles: {
      name: 'Toepassingslog',
      fields: {
        name: 'Bestandsnaam',
        fileTime: 'Ontvangen datum en tijd',
        url: 'Downloaden',
      },
    },
    retrofitConfigs: {
      name: 'Configuratie-informatie',
      fields: {
        timeStamp: 'Ontvangen datum en tijd',
        url: 'Downloaden',
      },
    },
    retrofitKitInfos: {
      name: 'Info controller',
      fields: {
        timeStamp: 'Ontvangen datum en tijd',
        url: 'Downloaden',
      },
    },
    retrofitCalibs: {
      name: 'Info kalibratie',
      fields: {
        timeStamp: 'Ontvangen datum en tijd',
        url: 'Downloaden',
        detailInfo: {
          createDateTime: 'Datum en tijd aanmaken',
          updateDateTime: 'Datum en tijd bijwerken',
          machineInfo: {
            companyName: 'Producent',
            machineType: 'Machinetype',
            machineName: 'Machinenaam',
            machineId: 'Machine-ID',
            drumConfiguration: 'Machineconfiguratie',
            guidanceMode: 'Begeleidingsmodus',
          },
          machineGeometryInfo: {
            boomLength: 'Gieklengte',
            secondBoomLength: 'Lengte van 2e giek',
            armLength: 'Armlengte',
            armTopPinToBucketSideLinkPinLength:
              'Afstand tss pen armtop en koppelpen zijde grijper',
            boomTopPinToBucketSideLinkPinLength:
              'Afstand tss pen giektop en koppelpen zijde grijper',
            bucketLink1Length:
              'Afstand tss pen zijde grijper en cil.-pen grijper',
            bucketLink2Length:
              'Afstand tss cil.-pen grijper en koppelpen grijper',
            bodyCenterToBoomFootPin: {
              x: 'Voetpen giek X t.o.v. midden machine',
              y: 'Voetpen giek Y t.o.v. midden machine',
              z: 'Voetpen giek Z t.o.v. midden machine',
            },
            drumWidth: 'Breedte van het blad',
            length: 'Lengte tussen schoenen',
            shoeInterval: 'Intervallengte van schoenen',
            bladeWidthForDozer: 'Breedte van het blad voor dozer',
            distanceFromBodyToBlade: 'Afstand van laadbak tot blad',
            frontToMainGnssAntenna:
              'Lengte vanaf het midden van een rechte lijn die de voorkant van het geaarde uiteinde verbindt met de hoofdantenne X',
            frontToSubGnssAntenna:
              'Lengte vanaf het midden van een rechte lijn die het voorste geaarde uiteinde vooraan verbindt met de sub gnss antenne X',
            centerToMainGnssAntenna:
              'Y afstand van het midden tot het elektrische fasemiddelpunt van de hoofd GNSS',
            centerToSubGnssAntenna:
              'Y afstand van het midden tot het centrum van de sub-GNSS elektrische fase',
            groundToMainGnssAntenna:
              'Z afstand vanaf de grond tot het elektrische fasemiddelpunt van de hoofd-GNSS',
            groundToSubGnssAntenna:
              'Z afstand van grond tot sub GNSS elektrische fase centrum',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'Offsethoek IMU giek',
            secondBoomImuOffsetAngle: 'Offsethoek IMU 2e giek',
            armImuOffsetAngle: 'Offsethoek IMU arm',
            bucketImuOffsetAngle: 'Offsethoek IMU grijper',
            bodyImuOffsetRollAngle: 'Offsetrolh. IMU machine',
            bodyImuOffsetPitchAngle: 'Offsethell.-h. IMU mach.',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: 'Hoofd-GNSS X t.o.v. mid. mach.',
                y: 'Hoofd-GNSS Y t.o.v. mid. mach.',
                z: 'Hoofd-GNSS Z t.o.v. mid. mach.',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: 'Sub-GNSS X t.o.v. mid. mach.',
                y: 'Sub-GNSS Y t.o.v. mid. mach.',
                z: 'Sub-GNSS Z t.o.v. mid. mach.',
              },
            },
          },
          extendedModel: {
            swingBoom: 'Zwenkgiek Model',
            twoPieceBoom: '2-delige giek model',
            minimalSwingRadius: 'Offsetgiek Model',
          },
          measuredValues: {
            otherLength: {
              machineHeight: 'Tegengewichtspeling',
            },
            bodyImu1: {
              roll: 'Roll van IMU-meetwaarde',
              pitch: 'Pitch van IMU-meetwaarde',
              yaw: 'Yaw van IMU-meetwaarde',
            },
            bodyImu2: {
              roll: 'Rol IMU-meetwaarde',
              pitch: 'Verticale hoek IMU-meetwaarde',
              yaw: 'Yaw van IMU-meetwaarde',
            },
            frontLeft: {
              x: 'Overzichtswaarde linksvoor X',
              y: 'Taxatiewaarde van linksvoor Y',
              z: 'Enquêtewaarde van linksvoor Z',
            },
            frontRight: {
              x: 'Enquête waarde rechter voorkant X',
              y: 'Enquête waarde rechter voorkant Y',
              z: 'Enquête waarde rechtsvoor Z',
            },
            frontGroundingEnd: {
              x: 'Enquête waarde van voorkant grondeinde X',
              y: 'Enquête waarde van voorkant grondeinde Y',
              z: 'Enquête waarde van voorkant grondeinde Z',
            },
            rearGroundingEnd: {
              x: 'Enquête waarde van achterkant grondeinde X',
              y: 'Enquête waarde van achteraan grondeinde Y',
              z: 'Enquête waarde van achterste grondeinde Z',
            },
            mainGnssAntenna: {
              x: 'Enquête waarde van hoofd GNSS antenne X',
              y: 'Enquête waarde van hoofd GNSS antenne Y',
              z: 'Enquête waarde van hoofd GNSS antenne Z',
            },
            subGnssAntenna: {
              x: 'Enquête waarde van sub GNSS antenne X',
              y: 'Enquête waarde van sub GNSS antenne Y',
              z: 'Opgemeten waarde van sub GNSS antenne Z',
            },
            prismPoleOffset: 'Prismastok henght',
            antennaElectricalCenterHeight:
              'Hoogte vanaf het elektrische centrum van de antenne',
          },
        },
      },
    },
    retrofitAccuracies: {
      name: 'Nauwkeurigheidscontrole resultaat',
      fields: {
        checkDateTime: 'Bevestigingsdatum en -tijd',
        surveyPoint: 'Gemeten punt',
        positionN: 'Gemeten waarde : N',
        positionE: 'O',
        positionZ: 'Z',
        machineOffsetN: 'Verschoven waarde : N',
        machineOffsetE: 'O',
        machineOffsetZ: 'Z',
        referencePointName: 'Naam van het referentiepunt',
        referencePointN: 'Referentiepunt : N',
        referencePointE: 'O',
        referencePointZ: 'Z',
        unitLength: 'Lengte-eenheid',
      },
    },
    retrofitBasicSettings: {
      name: 'Basisinstell.',
      fields: {
        timeStamp: 'Ontvangen datum en tijd',
        url: 'Downloaden',
      },
    },
    retrofitErrors: {
      name: 'Foutinformatie',
      fields: {
        notifiedTime: 'Datum en tijd van optreden',
        errorCodes: 'Foutcode: Details',
      },
    },
    retrofitToposurveys: {
      name: 'Lijst topograf. meting',
      fields: {
        surveyDate: 'Datum en tijd van de enquête',
        projectName: 'Projectnaam',
        layerName: 'Laag',
        pointName: 'Puntnaam',
        surveyPoint: 'Gemeten punt',
        n: 'Gemeten waarde : N',
        e: 'O',
        z: 'Z',
        unitLength: 'Lengte-eenheid',
      },
    },
    asbuilts: {
      name: 'Bouwresultaten',
      fields: {
        execStartDate: 'Uitvoering start',
        execEndDate: 'Uitvoering einde',
        startPeriod: 'Acquisitieperiode vanaf',
        endPeriod: 'Acquisitieperiode tot',
        execStatus: 'Status',
        url: '',
        csvType: 'Gegevensformaat',
        createUser: 'Maak gebruiker aan',
      },
      execStatus: {
        complete: 'Voltooid',
        inprogress: 'Verwerking',
        error: 'Fout',
        completewithoutdata: 'Voltooid (Geen gegevens)',
      },
    },
    retrofitBasicInfos: {
      name: 'Basisinfo',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: 'Distributeur',
        retrofitRegionId: 'Zone',
        corporationId: 'Bedrijfs-ID',
        corporationName: 'Bedrijfsnaam',
        basicInfoManufacturer: 'Fabrikant',
        basicInfoModel: 'Model',
        basicInfoProductNumber: 'Productnr.',
        basicInfoSerialNumber: 'Serienr.',
        lastUpdated: 'Laatst bijgewerkt',
        description: 'Beschrijving',
        ownerId: 'Bedrijfs-ID',
        ownerName: 'Bedrijfsnaam',
      },
      tag: {
        retrofitBasicInfosDetail: 'Basisinfo',
        sendBack: 'Terugsturen',
      },
      sendBack: {
        fields: {
          site: 'Bouwplaats',
          corporation: 'Bedrijf in gebruik',
          ntripSettingInfo: 'Ntrip ID',
          toRetrofit: 'Alternatieve controller',
          notificationEmailAddress: 'E-mail',
        },
      },
    },
    retrofitBulkActions: {
      name: 'Bulk registratie',
      fields: {
        uploadFileName: 'Bestandsnaam',
        execStartDate: 'Uitvoering start datum en tijd',
        execEndDate: 'Uitvoering einde datum en tijd',
        execStatus: 'Status',
        uploadFile: 'Geregistreerd bestand',
        resultFile: 'Registratieresultaten',
      },
      execStatus: {
        complete: 'Voltooid',
        inprogress: 'Verwerking',
        error: 'Fout',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: 'Bulkactie van de beheerder van het bedrijf',
      fields: {
        uploadFileName: 'Bestandsnaam',
        execStartDate: 'Uitvoering start datum en tijd',
        execEndDate: 'Uitvoering einde datum en tijd',
        execStatus: 'Status',
        uploadFile: 'Geregistreerd bestand',
        resultFile: 'Registratieresultaten',
      },
      execStatus: {
        complete: 'Voltooid',
        inprogress: 'Verwerking',
        error: 'Fout',
      },
    },
    tasks: {
      name: 'Taak',
      fields: {
        name: 'Naam',
        status: 'Status',
        description: 'Beschrijving',
        projectSourceLayerId: 'Laag',
        retrofitIds: 'Doelmachine',
        expiredDate: 'Vervaldatum',
        createDate: 'Vervaldatum',
        rectangle: 'Ondersteund bereik',
        lastUpdated: 'Laatst bijgewerkt',
      },
      status: {
        error: 'Fout',
        converting: 'Converteren',
        sending: 'Overdragen',
        sent: 'Overgedragen',
        failtosend: 'Overdracht mislukt',
      },
    },
    compactionMaterials: {
      name: 'Materialen',
      fields: {
        name: 'Naam',
      },
    },
    weathers: {
      name: 'Weersomstandigheden',
      fields: {
        name: 'Naam',
      },
    },
    operators: {
      name: 'Operators',
      fields: {
        name: 'Naam',
      },
    },
    compactionWorkAreas: {
      name: 'Verdichtingswerkgebieden',
      fields: {
        name: 'Werkgebiednamen',
        blockSize: 'Blokgrootte',
        description: 'Beschrijving',
      },
    },
    compactionLayers: {
      name: 'Verdichtingslagen',
      fields: {
        name: 'Naam',
        thickness: 'Dikte',
        passCount: 'Aantal passages',
        compactionMaterialId: 'Materiaal',
        inUse: 'Status',
        description: 'Beschrijving',
      },
      inUse: {
        true: 'in gebruik',
        false: 'Ongebruikt',
      },
    },
    gnssSettings: {
      name: 'Ntrip-instellingen',
      fields: {
        registCorporationName: 'Geregistreerd door',
        ownerCorporationId: 'Bedrijfs-ID',
        ownerCorporationName: 'Bedrijfsnaam',
        name: 'Naam',
        url: 'Server-URL',
        portnum: 'Poort',
        userId: 'ID',
        password: 'Wachtwoord',
        retrofitId: 'Doelmachine',
        status: 'Status',
        updateDate: 'Laatst bijgewerkt',
      },
      status: {
        waitforsend: 'Converteren',
        sending: 'Overdragen',
        sent: 'Overgedragen',
        failtosend: 'Overdracht mislukt',
      },
    },
    supportMode: {
      name: 'Ondersteuningsmodus',
      fields: {
        enabled: 'Ondersteuningsmodus inschakelen',
        corporationId: 'Bedrijfs-ID',
        corporationName: 'Bedrijfsnaam',
        siteId: 'Bouwplaats-ID',
        siteName: 'Bouwplaats',
        latitude: 'Breedtegraad',
        longitude: 'Lengtegraad',
        workPeriodStartDate: 'Werkelijke bouwperiode (startdatum)',
        workPeriodEndDate: 'Werkelijke bouwperiode (einddatum)',
        photoUrl: 'Site-afbeeldings-URL',
        description: 'Beschrijving',
      },
    },
    userNotices: {
      name: 'Melding',
      noticeType: {
        emergencyimportant: 'Noodgeval/Belangrijk',
        emergency: 'Noodsituatie',
        important: 'Belangrijk',
      },
      fields: {
        noticeType: 'Belang',
        noticeTypes: 'Belang',
        noticeCategory: 'Type melding',
        title: 'Titel',
        content: 'Inhoud',
        publicationStartDate: 'Startdatum en tijd (UTC)',
        publicationEndDate: 'Einddatum en -tijd(UTC)',
        file: 'Bijlagen',
        language: 'Taal',
        emergency: 'Noodsituatie',
        important: 'Belangrijk',
      },
    },
    firmwares: {
      name: 'Firmware',
      fields: {
        version: 'Versie',
        compatibilityLevel: 'Compatibiliteitsniveau',
        firmwareFile: 'Firmwarebestand',
        firmwareFileCheckSum: 'Firmwarecontrolegetal',
        firstInitializationFile: 'Initialisatiebestand 1',
        firstInitializationFileCheckSum: 'Initialisatiebestand 1 CheckSum',
        secondInitializationFile: 'Initialisatiebestand 2',
        secondInitializationFileCheckSum: 'Initialisatiebestand 2 CheckSum',
      },
    },
    bucketShares: {
      name: 'Emmer delen',
      fields: {
        fromBucketId: 'Van emmer id',
        corporationId: 'Bedrijfs-ID',
        name: 'Naam',
      },
    },
    extensionarmShare: {
      name: 'Verlengarm delen',
      fields: {
        name: 'Naam',
      },
    },
    retrofitCorporationLicenses: {
      name: 'LICENTIE',
      fields: {
        licenseId: 'Licentie-ID',
        licenseType: 'Type',
        issuedAt: 'Publicatiedatum',
        expiresAt: 'Vervaldatum',
        retrofitId: 'Machine',
        comment: 'Beschrijving',
        licenseStatus: 'Status',
        licensePermission: 'LICENTIE',
        productType: 'Productnaam',
      },
      licenseStatus: {
        unlicense: 'Niet toegewezen',
        disable: 'Ongeldig',
        enable: 'Geldig',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: 'Bedrijfsnaam',
        namePlates: 'Foto',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: 'Lengte-eenheid',
      },
    },
    geofences: {
      name: 'Geofence',
      fields: {
        type: 'Type',
        name: 'Naam',
        dimension: 'Afmeting',
        trigger: 'Trigger',
        valid: 'Geldig',
        alertType: 'Type waarschuwing',
        startDate: 'Startdatum',
        endDate: 'Einddatum',
        transparency: 'Transparant',
        elevation: 'Hoogte',
        height: 'Hoogte',
        radius: 'Straal',
        lastUpdated: 'Laatst bijgewerkt',
        points: 'Ondersteund bereik',
        thickness: 'Dikte',
      },
      type: {
        line: 'Lijn',
        polygon: 'Veelhoek',
        circle: 'Cirkel',
        point: 'Punt',
        wall: 'Muur',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: 'Geldig',
        false: 'Ongeldig',
      },
      alertType: {
        attention: 'Aandacht',
        notice: 'Opmerking',
        warning: 'Waarschuwing',
        caution: 'Voorzichtigheid',
        danger: 'Gevaar',
      },
      transparency: {
        true: 'Transparant',
        false: 'Ondoorzichtig',
      },
    },
    geofenceAlerts: {
      name: 'Waarschuwingen',
      fields: {
        timeStamp: 'Detectiedatum en -tijd',
        geofenceName: 'Geofence-naam',
        alertType: 'Type waarschuwing',
        collisionPoint: 'Collisiepunt',
        retrofit: {
          machineInfoMachineName: 'Machine',
        },
      },
      alertType: {
        attention: 'Aandacht',
        notice: 'Opmerking',
        warning: 'Waarschuwing',
        caution: 'Voorzichtigheid',
        danger: 'Gevaar',
      },
    },
    ntrips: {
      name: 'Beheer van Ntrip',
      fields: {
        name: 'Gebr.-ID',
        ntripPass: 'Wachtwoord',
        serial: 'Serienr.',
        customerCorporationId: 'Bedrijfs-ID',
        customerCorporationName: 'Bedrijfsnaam',
        ntrip: 'Status',
        numberOfExchanges: 'Aantal uitwisselingen',
        id: 'ID',
        licenceId: 'Licentie-ID',
        dateOfRegistration: 'Datum van registratie',
        billingToCustomers: 'Facturatie aan klanten',
        paymentToKomatsu: 'Betaling aan Komatsu',
        applicantId: 'Aanvraag-ID',
        applicant: 'Aanvrager',
        requestCorporationId: 'Bedrijfs-ID aanvragen',
        requestCorporationName: 'Bedrijfsnaam aanvragen',
        isUsed: 'Wordt gebruikt',
      },
      ntrip: {
        publish: 'Uitbetaald',
        stop: 'Stoppen',
        delete: 'Verwijderd',
      },
    },
    retrofitAlternateRegists: {
      name: 'Controller Proxy Registratie',
      fields: {
        id: 'ID',
        retrofitId: 'retrofitId',
        fromCorporationId: 'van bedrijf-id',
        fromCorporationName: 'Controller Proxy Registratie',
        toCorporationId: 'Gebr.',
        toCorporationName: 'Bedrijfsnaam',
        isApproved: 'Status',
        createDate: 'Aanvraagdatum',
        actualDate: 'Goedkeuringsdatum',
        transferUsers: 'E-mail',
        email: 'E-mail',
        lastUpdated: 'Laatst bijgewerkt',
      },
      isApproved: {
        true: 'Goedgekeurd',
        false: 'Niet goedgekeurd',
      },
    },
    pendingApprovals: {
      name: 'Wachten op goedkeuring',
      fields: {
        approvalType: 'Type',
        requestedBy: 'Bedrijf van de aanvrager',
        lastUpdated: 'Laatst bijgewerkt',
      },
      approvalType: {
        assignment: 'Opdracht',
        transfer: 'Overdracht',
        agent: 'Controller Proxy Registratie',
      },
    },
    retrofitSendbacks: {
      name: 'Terugsturen',
      fields: {
        fromRetrofitId: 'Van Retrofit',
        toRetrofitId: 'Alternatieve controller',
        notificationEmailAddress: 'E-mail',
        siteId: 'Bouwplaats',
        corporationId: 'Bedrijfs-ID',
        corporationName: 'Bedrijf in gebruik',
        ntripId: 'Ntrip-informatie',
        isComplete: 'Compleet terugsturen',
      },
    },
    siteBuriedStructures: {
      fields: {
        epsgCode: 'Epsg',
      },
    },
  },
};

export default message;
