import { UserConfigureData } from '../../types';

const userConfigures: UserConfigureData[] = [
  {
    id: 1,
    userId: 1, // accountId
    email: 'test_user@example.com',
    mapBackgroundColor: '#6466BF', // purple
  },
];

export default userConfigures;
