import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

/**
 * '/sites/:siteId/show/0'へのアクセスを'/sites/:siteId/show'へリダイレクトする
 * 現場詳細の1タブ目が建機リストなので現場詳細のURL=建機リストのURLだが、
 * ルーティングの整合性を保つため、建機詳細へは'/sites/:siteId/show/0'をbasePathとして渡している。
 * 建機詳細からbasePathに戻ってくるとこのコンポーネントが呼ばれるので、現場詳細のURLにリダイレクトさせる。
 * ルーティングの定義はroutes/getCustomRoutes.tsを参照。
 */
const SiteRetrofitRedirect: React.FC = () => {
  const { pathname } = useLocation();
  const lastPathIndex = pathname.lastIndexOf('/');
  const basePath = pathname.slice(0, lastPathIndex);

  return <Redirect to={basePath} />;
};

SiteRetrofitRedirect.displayName = 'SiteRetrofitRedirect';
export default SiteRetrofitRedirect;
