import React, { FC, useEffect, useState } from 'react';
import {
  Identifier,
  useNotify,
  useRefresh,
  useUnselectAll,
  useDataProvider,
  useLoading,
} from 'react-admin';
import { DoneButton, DialogOKOnly, LendCorporationData } from '../../..';
import { SaveIcon } from '../../../../assets';

interface Props {
  ids: Identifier[];
  form: any;
  customer: LendCorporationData | null;
  disabled: boolean;
}

export interface FormState {
  disableEmail: boolean;
  disableCorporation: boolean;
  checkCount: number;
}

export const initialFormState: FormState = {
  disableEmail: true,
  disableCorporation: true,
  checkCount: 0,
};

const BulkRegistrationSaveButton: FC<Props> = ({
  ids,
  form,
  customer,
  disabled,
}) => {
  const loading = useLoading();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();
  const notify = useNotify();
  const [toCorporationId, setToCorporationId] = useState('');
  const [toCorporationName, setToCorporationIName] = useState('');

  const [errorDialog, setErrorDialog] = useState<{
    open: boolean;
    message: string;
  }>({ open: false, message: '' });

  // 企業名フォームから企業ID,企業名を取得
  useEffect(() => {
    if (customer !== null) {
      const { ...record } = customer;
      const { id: recordId, name: recordName } = record;
      setToCorporationId(recordId);
      setToCorporationIName(recordName);
    }
  }, [customer, toCorporationId, toCorporationName]);

  const handleSave = () => {
    // EmailフォームからEmailを取得
    const toMailAddress = form.getFieldState('toMailAddress').value;

    // Emailの必須チェック処理
    if (!form.getFieldState('toMailAddress').value) {
      setErrorDialog({
        open: true,
        message: 'admin.dialog.retrofitBasicInfoList.saveValidation.message',
      });
      return;
    }

    const updateData = {
      toMailAddress,
      toCorporationId:
        toCorporationId.length === 0 ? 'unknown' : toCorporationId,
      toCorporationName:
        toCorporationName.length === 0 ? 'unknown' : toCorporationName,
    };

    // 一括更新
    dataProvider
      .updateMany('retrofitAlternateRegists', {
        ids,
        data: updateData,
      })
      .then(() => {
        refresh();
        unselectAll('retrofitAlternateRegists');
        notify('ra.notification.updated', 'info', {
          smart_count: ids.length,
        });
      })
      .catch((error: Error) => {
        notify(`Error: ${error.message}`, 'warning');
      });
  };

  return (
    <>
      <DoneButton
        disabled={disabled || loading}
        label="admin.actions.bulkRegistrationOK"
        onClick={handleSave}
      >
        <SaveIcon />
      </DoneButton>
      <DialogOKOnly
        open={errorDialog.open}
        onClose={() => setErrorDialog({ open: false, message: '' })}
        title=""
        message={errorDialog.message}
      />
    </>
  );
};

BulkRegistrationSaveButton.displayName = 'BulkRegistrationSaveButton';
export default BulkRegistrationSaveButton;
