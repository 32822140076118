import { SimpleShowLayout as RaSimpleShowLayout } from 'react-admin';

// layout/CardContentInner.js
import { CardContentProps as MuiProps } from '@material-ui/core/CardContent';
import { createStyles, withStyles } from '@material-ui/core';

import { ReactNodeLike } from 'prop-types';

interface RaProps {
  basePath: string;
  className: string;
  children: ReactNodeLike;
  record: object;
  resource: string;
  version: number;
}

const SimpleShowLayout = withStyles(
  createStyles({
    root: {
      '& .MuiCardContent-root': {
        padding: 0,
      },
    },
  }),
)(RaSimpleShowLayout) as ForgivingComponentType<MuiProps & RaProps>;
SimpleShowLayout.displayName = 'SimpleShowLayout';

export default SimpleShowLayout;
