import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { useGetListAllPage } from '../../../../hooks';
import { RetrofitData } from '../../../../dataProvider';

interface InParams {
  retrofits: RetrofitData[];
}

interface OutParams {
  data: { ntripDBShortage: boolean };
}

const useCheckUnusedNtipDB = () => {
  const dataProvider = useDataProvider();
  const getListAllPage = useGetListAllPage();
  return useCallback(
    async ({ retrofits }: InParams): Promise<OutParams> => {
      const { data: ntripsUnusedTotal } = await dataProvider.getList(
        'ntripsUnusedTotal',
        {
          pagination: {
            page: 1,
            perPage: 1, // 1件しか取る必要がない
          },
          sort: { field: 'id', order: 'ASC' },
          filter: {},
        },
      );
      const { unusedTotal } = ntripsUnusedTotal[0]; // NtripDB 未使用合計数

      /**  Ntrip払い出し対象のレトロフィットの合計数を算出 */
      // 国内レトロフィットのシリアル番号を取得
      const regionUnassignedRetrofitSerial = retrofits
        .filter(({ retrofitRegionId }) => retrofitRegionId === undefined)
        .map(({ basicInfoSerialNumber }) => basicInfoSerialNumber);

      // 国内レトロフィットの中から既にNtripDBが紐づいているレトロフィット件数を取得
      const { data: ntripAttachedRetrofits } =
        regionUnassignedRetrofitSerial.length > 0
          ? await getListAllPage({
              resource: 'ntrips',
              filter: { serial: regionUnassignedRetrofitSerial },
              sort: { field: 'id', order: 'ASC' },
            })
          : { data: [] };

      // コントローラ登録されたレトロフィットに対して、NtripDBの払い出しが必要な件数を算出
      const requiredNtripTotalNumber =
        regionUnassignedRetrofitSerial.length - ntripAttachedRetrofits.length;

      // NtripDBの払い出しが必要な件数に対して、NtripDB 未使用合計数が不足しているか判定
      const ntripDBShortage = unusedTotal - requiredNtripTotalNumber < 0;

      return {
        data: {
          ntripDBShortage,
        },
      };
    },
    [dataProvider, getListAllPage],
  );
};

export default useCheckUnusedNtipDB;
