import React, { useEffect, useState } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { TextField as RaTextField, useDataProvider } from 'react-admin';
import { NtripData, RetrofitData } from '../../dataProvider';
import { useResource } from '../../hooks';

const NtripNameField: React.FC<{
  record?: RetrofitData;
  label?: string; // 內部で使わなくても <FormInput> 側で利用される
  addLabel?: boolean; // 內部で使わなくても <FormInput> 側で利用される
}> = ({ record }) => {
  const dataProvider = useDataProvider();
  const resource = useResource('ntrips');
  const [apiState, setApiState] = useState<{
    fetched: boolean;
    loading: boolean;
    ntrip?: NtripData;
  }>({
    fetched: false,
    loading: true,
    ntrip: undefined,
  });

  useEffect(() => {
    if (apiState.fetched) return;
    setApiState({ fetched: true, loading: true });
    const api = async () => {
      const { data: ntripData } = await dataProvider.getList<NtripData>(
        resource,
        {
          pagination: {
            page: 1,
            perPage: 1,
          },
          sort: { field: 'id', order: 'DESC' },
          filter: { serial: record?.basicInfoSerialNumber },
        },
      );
      return ntripData ? ntripData[0] : undefined;
    };
    api().then(ntrip => {
      setApiState({ fetched: true, loading: false, ntrip });
    });
  }, [record, resource, apiState.fetched, dataProvider]);
  if (apiState.loading) return <LinearProgress />;
  if (!apiState.ntrip) return <></>;

  const name = apiState.ntrip?.name;
  return <RaTextField record={{ name }} source="name" />;
};

NtripNameField.defaultProps = {
  addLabel: true, // Form 配下でラベルを出す為に設定
};

NtripNameField.displayName = 'NtripNameField';
export default NtripNameField;
