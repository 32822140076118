import React from 'react';
import { MapProps, crearteOverlayControl } from '../../../../../hooks';
import { TransparencyEditButton } from '../view';

const addTransparencyControl = (
  { map }: MapProps,
  defaultTransparency?: boolean,
  onClick?: () => void,
) => {
  map.addControl(
    crearteOverlayControl(
      'map_transparency}',
      <TransparencyEditButton
        defaultTransparency={defaultTransparency}
        onClick={onClick}
      />,
      {
        left: 436,
        top: 350,
      },
    ),
  );
};

export default addTransparencyControl;
