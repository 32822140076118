import { makeStyles, createStyles } from '@material-ui/core';
import { Edit } from 'react-admin';
import React, { FC } from 'react';
import { BreadcrumbsFiled, CustomForm } from '../../../molecules';
import { IconFieldPaddingZeroStyle, PaginationLimit } from '../../../atoms';

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      '& .MuiFormControl-root': {
        marginTop: 8,
        marginBottom: 10,
        '& .MuiFormLabel-root': {
          width: 290,
        },
      },
      '& .MuiCardContent-root': {
        width: 490,
        padding: 0,
      },
      ...IconFieldPaddingZeroStyle,
    },

    flexCenter: {
      justifyContent: 'center',
      width: '100%',
      '& > div': {
        // TopPagination の margin を消す
        margin: 0,
      },
    },
  }),
);

const RetrofitCalibPaginationLimit: FC<{
  props: any;
  breadcrumbs: string[];
}> = ({ props, breadcrumbs }) => {
  const classes = useStyles();

  return (
    <Edit {...props}>
      <CustomForm
        title="admin.pages.retrofitCalibInfo"
        redirect={false}
        saveButton={false}
        cancelButton={false}
        deleteButton={false}
        className={classes.form}
      >
        <BreadcrumbsFiled
          breadcrumbs={breadcrumbs}
          label="admin.label.calibrationDetail.breadcrumbs"
        />
        <div className={classes.flexCenter}>
          <PaginationLimit />
        </div>
      </CustomForm>
    </Edit>
  );
};
RetrofitCalibPaginationLimit.displayName = 'RetrofitCalibShow';
export default RetrofitCalibPaginationLimit;
