import { useState, useEffect, useMemo } from 'react';
import { useDataProvider } from 'react-admin';
import { UserConfigureData } from '../dataProvider';
import { logger } from '../utils';

interface OutParams {
  loading: boolean;
  data: {
    userConfigure: UserConfigureData | undefined;
  };
}

const useGetUserConfigure = (): OutParams => {
  const dataProvider = useDataProvider();
  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data: {
      userConfigure: UserConfigureData | undefined;
    };
  }>({
    loading: true,
    fetched: false,
    data: {
      userConfigure: undefined,
    },
  });

  const api = useMemo(
    () => async (): Promise<UserConfigureData | undefined> => {
      // ログイン中ユーザ情報
      const { data: accountData } = await dataProvider.getOne('accounts', {
        id: 1,
      });
      const { email } = accountData;

      // ユーザ設定
      const { data: userConfigure } =
        await dataProvider.getList<UserConfigureData>('userConfigures', {
          pagination: {
            page: 1,
            perPage: 1,
          },
          sort: { field: 'id', order: 'DESC' },
          filter: {
            email,
          },
        });
      return userConfigure?.[0] ? userConfigure[0] : undefined;
    },

    [dataProvider],
  );

  useEffect(() => {
    if (apiState.fetched) return;
    logger().debug('fetchUserConfigures ==>');
    api()
      .then(userConfigure => {
        logger().debug(`fetchUserConfigures <== '${userConfigure}'`);
        setApiState({
          ...apiState,
          loading: false,
          fetched: true,
          data: { userConfigure },
        });
      })
      .catch(e => {
        logger().error(`fetchUserConfigures <== ${e}`);
        setApiState({
          ...apiState,
          loading: false,
          fetched: true,
        });
      });
  }, [apiState, api]);

  return {
    loading: apiState.loading,
    data: apiState.data,
  };
};

export default useGetUserConfigure;
