import { useRedirect, Identifier } from 'react-admin';

/**
 * 画面遷移を行う
 *
 * NOTE: 他の移動先が増える場合は、適宜関数を増やしてください。
 *
 * @example
 *
 * const { gotoList, gotoAdd } = useJumpTo();
 * // Sites のリストに遷移
 * gotoList('sites');
 * // Sites の 1 番を machine に紐づける画面に遷移
 * gotoAdd('sites', '1', 'machine');
 *
 */
const useJumpTo = () => {
  const redirectTo = useRedirect();
  return {
    /** リストに遷移 */
    gotoList(name: string) {
      redirectTo('list', `/${name}`);
    },
    /** 新規追加画面に遷移 */
    gotoCreate(name: string) {
      redirectTo('create', `/${name}`);
    },
    /** 編集画面に遷移 */
    gotoEdit(name: string, id: Identifier) {
      redirectTo('edit', `/${name}`, id);
    },
    /** Show画面に遷移 */
    gotoShow(name: string, id: Identifier) {
      redirectTo(`${name}/${id}/show`);
    },
    /** 紐づけ画面に遷移 */
    gotoAdd(name: string, id: Identifier, category: string) {
      redirectTo(`${name}/${id}/show/${category}/add`);
    },
    /** Show画面(タブ)に遷移 */
    gotoShowTab(name: string, id: Identifier, index: string) {
      redirectTo(`${name}/${id}/show/${index}`);
    },
    /** 詳細画面内リストの登録に遷移 */
    gotoInnerListRegistration(
      name: string,
      id: Identifier,
      tabId?: Identifier,
    ) {
      if (tabId === undefined) {
        redirectTo(`/${name}/${id}/create`); // タブなし場合
        return;
      }
      redirectTo(`/${name}/${id}/${tabId}/create`);
    },
    /** 詳細画面内リストの詳細/更新画面に遷移 */
    gotoInnerListEditOrShow(
      name: string,
      id: Identifier,
      targetId: Identifier,
      tabId?: Identifier,
    ) {
      if (tabId === undefined) {
        redirectTo(`/${name}/${id}/${targetId}`); // タブなし場合
        return;
      }
      redirectTo(`/${name}/${id}/${tabId}/${targetId}`);
    },
    /** 建機情報画面に遷移 */
    gotoInfo(parentPath: string, id: Identifier, infoName: string) {
      redirectTo(`${parentPath}/${id}/${infoName}`);
    },
    /** 建機情報明細画面に遷移 */
    gotoInfoDetail(parentPath: string, detailId: Identifier) {
      redirectTo(`${parentPath}/${detailId}`);
    },
    /** レトロフィット一括登録画面に遷移 */
    gotoRetrofitBundleCreate(name: string) {
      redirectTo(`/${name}/create`);
    },
    /** タブ有リスト画面から編集画面に遷移 */
    gotoListEditRoutingTabbed(parentPath: string, id: Identifier) {
      redirectTo(`${parentPath}/${id}`);
    },
  };
};

export default useJumpTo;
