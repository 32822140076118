import { GetOneParams, GetOneResult } from 'react-admin';
import { landlogReadUser, landlogUserConvert } from '..';

export default async (params: GetOneParams): Promise<GetOneResult> => {
  const { id } = params;
  // APIの実行
  const User = await landlogReadUser(id as string);
  const data = landlogUserConvert(User);
  return { data };
};
