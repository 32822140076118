import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { RetrofitData } from '../../../../../dataProvider';

interface InParams {
  retrofitId: number;
}

interface OutParams {
  data: {
    siteId: string | undefined;
  };
}

const useGetRetrofitsSiteId = () => {
  const dataProvider = useDataProvider();

  return useCallback(
    async ({ retrofitId }: InParams): Promise<OutParams> => {
      const { data: retrofitData } = await dataProvider.getOne<RetrofitData>(
        'retrofits',
        {
          id: retrofitId,
        },
      );
      const { siteId } = retrofitData;

      return {
        data: {
          siteId,
        },
      };
    },
    [dataProvider],
  );
};

export default useGetRetrofitsSiteId;
