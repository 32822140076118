import { ProjectSourceLayerData } from '../../../../../../dataProvider';

export interface LayerParams {
  layerMap: { [key: number]: number };
  initialLayerValue: {
    id?: number;
    layerId?: number;
  };
  layerChoices: {
    id: number;
    name: string;
  }[];
}

const projectToLayerData = (
  projectSourceLayers: ProjectSourceLayerData[] | undefined,
): LayerParams => {
  const layerMap: { [key: number]: number } = projectSourceLayers
    ? projectSourceLayers.reduce((prev, current) => {
        const { id, mapServerProjectSourceLayerId } = current;
        return { ...prev, [id]: mapServerProjectSourceLayerId };
      }, {})
    : {};

  const layerChoices = projectSourceLayers
    ? projectSourceLayers
        .map(({ id, name }) => ({ id, name }))
        .sort((lhs, rhs) => {
          return lhs.name > rhs.name ? 1 : -1;
        })
    : [];
  const [initialSelect] = layerChoices;

  const initialLayerValue = initialSelect
    ? {
        id: initialSelect.id,
        layerId: layerMap[initialSelect.id],
      }
    : { id: undefined, layerId: undefined };

  return {
    layerMap,
    layerChoices,
    initialLayerValue,
  };
};

export default projectToLayerData;
