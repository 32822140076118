import { useDataProvider } from 'react-admin';
import { RetrofitData, AdminCorporation } from '../../../../dataProvider';

export interface UpdateDataRegular {
  corporationId: string;
  corporationName: string;
  registrationApplicantId: string;
  registrationApplicantName: string;
  needsNtripDataReration: boolean;
}

export interface UpdateDataAdmin {
  adminCorporations: AdminCorporation[];
}

export type UpdateData = UpdateDataRegular | UpdateDataAdmin;

const useUpdateRetrofitMany = () => {
  const dataProvider = useDataProvider();
  return async (retrofits: RetrofitData[], updateData: UpdateData) => {
    const ids = retrofits.map(it => it.id);
    return dataProvider.updateMany('retrofits', { ids, data: updateData });
  };
};

export default useUpdateRetrofitMany;
