import { AdminApiConverter, ApiFilter } from '..';

const converter: AdminApiConverter<'datums', 'datums'> = {
  resource: 'datums',
  defaultSort: { field: 'id', order: 'ASC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'datums'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('name' in filter) apiFilter.name = filter.name;
    apiFilter.projectionId = filter.projectionId || 0;
    return apiFilter;
  },
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(record => ({
        id: record.id || 0,
        name: record.name,
        projectionId: record.projectionId,
        proj4: record.proj4,
      })),
    ),
  recordToApi: record => ({
    name: record.name,
    proj4: '',
  }),
};

export default converter;
