import React, { FC, useState, useCallback } from 'react';
import { Identifier, useDataProvider, useLoading } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import { Button } from '../../..';
import { useRetrofitSerialNumber } from '../../../../hooks';
import { colors } from '../../../../theme';
import { buttonWidth, buttonHeight } from '../../../../theme/define';
import BulkRegistrationDialog from './BulkRegistrationDialog';

const useStyles = makeStyles(() =>
  createStyles({
    confirm: {
      background: colors.button.save,
      color: colors.text.primaryLight,
      fontSize: 14,
      textTransform: 'none',
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.saveHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.saveDisabled,
      },
      '& span': {
        padding: 0,
      },
    },
  }),
);

interface ViewState {
  open: boolean;
  openError: boolean;
  errorMessage: string;
  serialNumbers: string[];
  lendingSerialNumberText: string;
}

const initialViewState: ViewState = {
  open: false,
  serialNumbers: [],
  openError: false,
  errorMessage: '',
  lendingSerialNumberText: '',
};

interface Props {
  selectedIds: Identifier[];
  disabled: boolean;
}

const BulkRegistrationButton: FC<Partial<Props>> = ({
  selectedIds: ids = [0],
  disabled,
}) => {
  const classes = useStyles();
  const loading = useLoading();
  const retrofitSerialNumber = useRetrofitSerialNumber();
  const dataProvider = useDataProvider();

  const [viewState, setViewState] = useState<ViewState>(initialViewState);

  const handleClick = useCallback(async () => {
    const { data: retrofitAlternateRegists } = await dataProvider.getMany(
      'retrofitAlternateRegists',
      { ids },
    );
    const retrofitIds = retrofitAlternateRegists.map(item => item.retrofitId);

    // シリアル番号を取得
    const {
      data: { serialNumbers },
    } = await retrofitSerialNumber({
      retrofitIds,
    });

    setViewState({
      ...viewState,
      open: true,
      serialNumbers,
    });
  }, [retrofitSerialNumber, dataProvider, viewState, ids]);

  return (
    <>
      <Button
        className={classes.confirm}
        label="admin.actions.bulkRegistration"
        data-testid="admin.actions.bulkRegistration"
        onClick={handleClick}
        disabled={disabled || loading}
      />
      <BulkRegistrationDialog
        open={viewState.open}
        onClose={() => setViewState({ ...viewState, open: false })}
        ids={ids}
        serialNumbers={viewState.serialNumbers}
        lendingSerialNumberText={viewState.lendingSerialNumberText}
      />
    </>
  );
};

BulkRegistrationButton.displayName = 'BulkRegistrationButton';
export default BulkRegistrationButton;
