import React, { FC, useCallback, useEffect } from 'react';
import {
  useDataProvider,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin';
import {
  useAlertMap,
  AlertMapProps,
  setOverlayRender,
} from '../../../../hooks';
import { AlertMapController } from '../map';
import { GeofenceAlertsData } from '../../../../dataProvider';
import { colors } from '../../../../theme';

export interface GeofenceMapFieldProps {
  mapController: AlertMapController;
  visible: boolean;
  rerender?: boolean;
  record?: GeofenceAlertsData;
  width?: number;
  height?: number;
  featureId?: number;
  mapBackgroundColor?: string;
  readOnly?: boolean;
  label?: string;
  addLabel?: boolean;
  isRequired?: boolean;
  onConfigure?: (mapProps: AlertMapProps) => void;
  onModify?: () => void;
}

const useGeofenceListShowToolTip = () => {
  const translate = useTranslate();
  return useCallback(
    res => {
      return translate(res);
    },
    [translate],
  );
};

const useGeofenceListUnitLength = () => {
  const translate = useTranslate();
  return useCallback(
    unitLength => {
      // toDisplayUnitLength関数を使用するとジオフェンスの表示するタイミングがずれるため、直接条件分岐でunitLengthを判断
      let unit;
      if (unitLength === 'M') {
        unit = 'm';
      } else if (unitLength === 'Ft') {
        unit = 'ft';
      } else if (unitLength === 'UsFt') {
        unit = 'Usft';
      } else {
        console.error(`Illegal unit: ${unitLength}`);
        unit = unitLength;
      }
      return translate('admin.label.geofences.unitLength', {
        unit,
      });
    },
    [translate],
  );
};

const GeofenceAlertMapField: FC<GeofenceMapFieldProps> = ({
  mapController,
  record,
  visible,
  onConfigure,
  onModify,
  width = 812,
  height = 365,
  featureId = 0,
  mapBackgroundColor = '#fffffff',
  readOnly = false,
  rerender = false,
}) => {
  const { props: mapProps } = useAlertMap(undefined, false);
  const translate = useTranslate();
  const redirectTo = useRedirect();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const geofenceListShowToolTip = useGeofenceListShowToolTip();
  const geofenceListShowUnitLength = useGeofenceListUnitLength();
  const recordString = record ? JSON.stringify(record) : undefined;

  // 以下の useEffect は、Map を初期化する為のイベント
  useEffect(() => {
    if (onConfigure) onConfigure(mapProps);

    mapProps.map.setTarget('map');
    mapController.bindMap({
      mapProps,
      featureId,
      record: recordString ? JSON.parse(recordString) : undefined,
    });
    setOverlayRender(true);
    return () => {
      // clean up
      setOverlayRender(false);
      mapProps.map.setTarget('');
    };
  }, [
    mapProps,
    mapController,
    onConfigure,
    featureId,
    readOnly,
    translate,
    recordString,
    geofenceListShowUnitLength,
    redirectTo,
    dataProvider,
    refresh,
    geofenceListShowToolTip,
    onModify,
    visible,
    rerender,
  ]);

  return (
    <>
      {visible ? (
        <div
          id="map"
          data-testid="map"
          style={{ width, height, backgroundColor: mapBackgroundColor }}
        />
      ) : (
        false
      )}
      {visible ? (
        false
      ) : (
        <div
          style={{
            width: '100%',
            height,
            background: colors.backgroud,
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      )}
    </>
  );
};

GeofenceAlertMapField.defaultProps = {
  label: 'resources.geofences.fields.points',
  addLabel: true,
  isRequired: true,
};
GeofenceAlertMapField.displayName = 'GeofenceAlertMapField';
export default GeofenceAlertMapField;
