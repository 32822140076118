import { getDataProvider, LicenseData } from '../dataProvider';

let licenses: LicenseData;

export default async () => {
  if (!licenses) {
    const dataProvider = getDataProvider();
    const { data } = await dataProvider.getOne('licenses', { id: 1 });
    licenses = data as LicenseData;
  }
  return licenses;
};
