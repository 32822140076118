import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Button } from '../../../..';
import { mapStyle } from '../../../../../theme';
import {
  MapGeoTransparentOn,
  MapGeoTransparentOff,
} from '../../../../../assets';

const onUseStyle = makeStyles(() =>
  createStyles({
    root: {
      ...mapStyle.button,
      backgroundColor: '#000344',
    },
  }),
);

const offUseStyle = makeStyles(() =>
  createStyles({
    root: {
      ...mapStyle.button,
    },
  }),
);

const TransparencyEditButton: React.FC<{
  defaultTransparency?: boolean;
  onClick?: () => void;
}> = ({ defaultTransparency = true, onClick }) => {
  const [transparency, setTransparency] =
    useState<boolean>(defaultTransparency);
  const onStyle = onUseStyle();
  const offStyle = offUseStyle();
  return (
    <Button
      onClick={() => {
        if (onClick) onClick();
        setTransparency(!transparency);
      }}
      className={transparency ? onStyle.root : offStyle.root}
      data-testid={'admin.actions.mapTransparency'}
    >
      {transparency ? <MapGeoTransparentOn /> : <MapGeoTransparentOff />}
    </Button>
  );
};

TransparencyEditButton.displayName = 'TransparencyEditButton';
export default TransparencyEditButton;
