// for fr
// フランス語
import raMessage from 'ra-language-french';
import en from './en';

const { ra } = raMessage;
const message: typeof en = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: '',
        toggle_hidden: '',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: 'Non trouvé',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'OK',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: 'Voulez-vous supprimer %{name} ?',
    },
  },
  admin: {
    title: {
      normal: 'Smart Construction Pilot',
      split1: 'Smart Construction',
      split2: 'Pilot',
    },
    copyright: 'Copyright © EARTHBRAIN Ltd. All rights reserved.',
    approval: "En attente d'approbation",
    usermenu: {
      userNotice: 'Notification',
      terms: "CONDITIONS D'UTILISATION",
      manual: 'Manuel',
      downloads: 'Télécharger',
    },
    breadcrumbs: {
      details: 'Détails',
      retrofitEdit: 'Détails',
      taskCreate: 'Créer une tâche',
      taskEdit: 'Détails de la tâche',
      geofenceCreate: 'Créer',
      geofenceEdit: 'Éditer',
      geofenceAlertList: 'Alerte de géofençage',
      geofenceAlertShow: 'Détails',
      compactionWorkAreaCreate: 'Ajouter des zones de compactage',
      compactionWorkAreaShow: 'Zones de travail de compactage',
      compactionWorkAreaEdit: 'Modifier les zones de travail de compactage',
      compactionLayerCreate: 'Créer des couches de compactage',
      compactionLayerEdit: 'Modifier les couches de compactage',
      supportMode: 'Mode de support',
      retrofitManage: 'Enregistrement du contrôleur',
      retrofitManageAdmin: 'Enregistrement de contrôleur (Admin)',
      retrofitNamePlateFiles: 'Enregistrement du contrôleur',
      retrofitAlternateRegistCreate: 'Enregistrement de proxy de contrôleur',
      retrofitAlternateRegistEdit: 'Détails',
      retrofitManageAdminBulkActionCreate: 'Enregistrement',
      terms: "CONDITIONS D'UTILISATION",
      downloads: 'Télécharger',
    },
    pages: {
      siteList: 'Liste des chantiers',
      siteRetrofitCreate: 'Ajouter une machine sur le chantier',
      siteRetrofitEdit: '',
      siteBucketCreate: 'Ajouter un seau sur le chantier',
      siteBucketEdit: 'Détails du seau',
      siteProjectEdit: 'Détails du projet',
      siteProjectCreate: 'Enregistrement de fichiers de projet',
      siteExtensionarmCreate: "Ajouter un bras d'extension sur le chantier",
      taskCreate: 'Créer une tâche',
      taskEdit: 'Détails de la tâche',
      geofenceAlertList: 'Alerte de géofençage',
      geofenceAlertShow: "Détails de l'alerte de géofençage",
      geofenceCreate: 'Créer une clôture géographique',
      geofenceEdit: 'Modifier la clôture géographique',
      compactionWorkAreaCreate: 'Ajouter des zones de compactage',
      compactionLayerCreate: 'Créer des couches de compactage',
      compactionLayerEdit: 'Modifier les couches de compactage',
      siteFileEdit: 'Détails',
      siteFileCreate: 'Ajouter un fichier sur le chantier',
      retrofitList: 'Liste des machines',
      retrofitEdit: '',
      retrofitRentalCreate: "Enregistrement de l'attribution",
      retrofitTransferCreate: 'Enregistrement de transfert',
      retrofitSendBack: "Retour d'inscription",
      bucketList: 'Liste des seaux',
      bucketEdit: 'Détails du seau',
      bucketCreate: 'Enregistrement du seau',
      bucketRentalCreate: 'Partage du seau',
      extensionarmList: "Liste des bras d'extension",
      extensionarmEdit: "Détails du bras d'extension",
      extensionarmShare: "Partage de bras d'extension",
      retrofitBulkActionList: "Liste d'inscription en vrac",
      retrofitBulkActionCreate: 'Inscription en vrac',
      retrofitBasicInfoList: 'Liste des contrôleurs',
      retrofitBasicInfoCreate: 'Enregistrement du contrôleur',
      retrofitBasicInfoEdit: 'Mise à jour du contrôleur',
      gnssSettingList: 'Liste des paramètres Ntrip',
      gnssSettingCreate: 'Créer un réglage Ntrip',
      gnssSettingAdminCreate: 'Créer un réglage Ntrip',
      gnssSettingOwnerCreate: 'Créer un réglage Ntrip',
      gnssSettingEdit: 'Modifier le paramètre Ntrip',
      gnssSettingAdminEdit: 'Modifier le paramètre Ntrip',
      gnssSettingOwnerEdit: 'Modifier le paramètre Ntrip',
      approvalList: 'Liste des machines',
      retrofitRentalEdit: "Détails de l'attribution",
      retrofitTransferEdit: 'Détails du transfert',
      asbuiltsCreate: 'Acquérir le résultat de la construction',
      asbuiltsShow: 'Historique Asbuild',
      retrofitConfigList: 'Informations de configuration',
      retrofitKitInfoList: 'Info contrôleur',
      retrofitAccuraciesList: 'Résultat de vérification de précision',
      retrofitAccuraciesShow: 'Détails',
      retrofitCalibaList: 'Info étalonnage',
      retrofitCalibInfo: `Détails de l'information sur l'étalonnage de la machine`,
      retrofitBasicSettingList: 'Informations de base',
      retrofitErrorList: "Infos sur l'erreur",
      retrofitErrorShow: 'Détails',
      retrofitTopographicSurveyList: 'Liste levés topogr.',
      retrofitTopographicSurveyShow: "Détails de l'arpentage topographique",
      retrofitShareFileList: "Journal d'application",
      retrofitCorporationLicenseList: 'Liste des licences',
      retrofitCorporationLicenseEdit: 'Détails de la licence',
      CompactionLayerList: 'Couches de compactage',
      userNoticeList: 'Liste des notifications',
      userNoticeCreate: 'Ajouter une notification',
      userNoticeEdit: 'Mettre à jour la notification',
      userNoticeDrawer: 'Notification',
      retrofitAlternateRegists:
        'Liste des enregistrements de proxy de contrôleur',
      firmwareList: 'Liste du micrologiciel',
      firmwareShow: 'Détails du micrologiciel',
      firmwareCreate: 'Ajouter un firmware',
      retrofitManage: 'Texte',
      retrofitNamePlateFiles: 'Photo',
      retrofitManageAdminBulkAction: 'Inscription en vrac',
      retrofitManageAdminBulkActionCreate: 'Inscription en vrac',
      retrofitAlternateRegistCreate: 'Enregistrement de proxy de contrôleur',
      retrofitAlternateRegistEdit:
        "Détails d'enregistrement du proxy de contrôleur",
      retrofitAlternateRegistCreateText: 'Texte',
      retrofitAlternateRegistCreatePhoto: 'Photo',
      ntripList: 'Info Ntrip',
      ntripEdit: 'Gestion des Ntrip',
    },
    label: {
      login: 'Connexion',
      selected: 'Sélectionné (%{length} cas)',
      listNoResults: 'Données non trouvées',
      maintenance: {
        title: 'Actuellement en maintenance.',
        message: `Nous avons temporairement suspendu les services pour maintenance.
      Nous nous excusons pour la gêne occasionnée, veuillez patienter un instant de plus.`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, ln3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder:
          'Déposez ou sélectionnez le(s) fichier(s) (%{fileTypes}) à télécharger.',
        maxFileNum:
          'Le nombre maximum de contrôleurs pouvant être enregistrés en une seule fois est de cinq.',
      },
      nosetting: 'Aucun réglage',
      siteRetrofitList: {
        remoteSupport: 'Assistance à distance',
      },
      retrofitEdit: {
        breadcrumbs: 'Détails',
        belongsDetails: '',
        ntripSettingInfo: 'Info Paramètres Ntrip',
        machineryDetails: 'Engin',
        retrofitDetails: 'Contrôleur',
        basicInfo: 'Info de base',
        controller: 'Contrôleur',
        reciverMain: 'Récepteur principal GNSS',
        reciverSub: 'Sous-récepteur GNSS',
        retrofitstatus: 'Statut du contrôleur',
        license: 'Licence',
        licenseId: 'ID licence',
        expiredDate: "Date d'expiration",
      },
      retrofitRental: {
        rentalDetails: "Détails de l'attribution",
        rentalInfo: "Détails de l'attribution",
        lending: 'Attribution',
        return: 'Retour',
        lendingDestination: 'Entreprise assignée',
        lendingCompany: "Nom de l'entreprise",
        lendingEmail: 'Email',
        companyName: "Nom de l'entreprise",
      },
      retorfitTransfer: {
        tab: 'Transfert',
        transferInfo: 'Détails du transfert',
        transferButton: 'Transfert',
        transferEditButton: 'Modifier le transfert',
      },
      siteConfigureEdit: {
        onButton: 'Allumé',
        offButton: 'Eteint',
      },
      approvals: {
        rentalTab: 'Attribution',
        transferTab: 'Transfert',
      },
      buckets: {
        bucketTab: 'Godet',
        extensionarmTab: 'Extension balancier',
        extensionarmFile: "Fichier du bras d'extension",
        shareTo: 'Partager avec',
      },
      bucketRental: {
        corporation: 'Partager avec',
        bucketType: 'Type',
        bk3File: 'Fichier BK3',
      },
      retrofitBasic: {
        basicInfoTab: 'Info de base',
        bulkActionTab: 'Inscription en vrac',
        ntripTab: 'Gestion des Ntrip',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'Renvoyer',
        initialize: 'Initialisation',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: 'Mise à jour en vrac',
        retrofitDistributor: 'Distributeur',
        retrofitRegion: 'Région',
        corporationId: "ID d'entreprise",
        corporationName: "Nom de l'entreprise",
      },
      retrofitBasicInfoEdit: {
        corporationId: "ID d'entreprise en cours d'utilisation",
        corporationName: "Entreprise en cours d'utilisation",
      },
      gnssSettingsEdit: {
        ownerCorporationName: "Nom de l'entreprise",
      },
      terms: {
        title: "CONDITIONS D'UTILISATION",
        message:
          "Veuillez cliquer sur le lien ci-dessous pour examiner les conditions d'utilisation.",
        confirmMessage1:
          'Pour utiliser Smart Construction Pilot, vous devez accepter les documents suivants.',
        confirmMessage2:
          "Si vous n'êtes pas d'accord, vous ne pourrez pas utiliser Smart Construction Pilot.",
        select: 'Veuillez sélectionner votre région.',
      },
      downloads: {
        title: 'Convertisseur de fichiers de projet',
        message:
          "Il s'agit d'une application autonome qui crée un fichier de projet à partir de fichiers de conception.",
        button: 'Télécharger',
      },
      retrofitBasicInfoCreate: {
        uuid: 'Numérotation automatique',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'Télécharger',
        resultFileDownload: 'Télécharger',
      },
      tasks: {
        coordinate: 'Coordonnées (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'Taille de bloc (%{unit})',
      },
      compactionLayers: {
        thickness: 'Épaisseur (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: 'Détails',
        rentalDetail: "Détails de l'attribution",
      },
      pendingTransfers: {
        breadcrumbs: 'Détails',
        transferDetail: 'Détails du transfert',
      },
      pendingAlternateRegists: {
        breadcrumbs: 'Détails',
        alternateRegistDetail:
          "Détails d'enregistrement du proxy de contrôleur",
      },
      supportMode: {
        retrofit: 'Engin',
        optional: 'Facultatif',
      },
      retrofitList: {
        assignment: 'Attribution',
        license: 'Licence',
      },
      userNotices: {
        languageDetails: 'Contenu de la notification',
        language: 'Langue',
        languageEn: 'English',
        languageJa: 'Japonais',
        languageFr: 'Français',
        languageDe: 'Allemand',
        languageEs: 'Espagnol',
        Information: 'Notification',
        Maintenance: 'Maintenance',
        emergency: 'Urgence',
        important: 'Important',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'Enregistrement du contrôleur',
        corporationName: "Nom de l'entreprise",
        basicInfoSerialNumber: 'Numéro de série',
        confirmInput: 'Rentrer le numéro de série',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'Numéro de série',
        status: 'Statut',
        imageFile: 'Image',
        message1:
          'Veuillez prendre une photo du numéro de série ou du code QR du contrôleur qui est clair et non flou.',
        message2: 'Les images doivent être inférieures à %{mbyte} Mo.',
        errormessage:
          'Le nombre maximum de contrôleurs pouvant être enregistrés en une seule fois est de cinq.',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: 'Valide',
          disable: 'Incorrect',
          unLicense: 'Non alloué',
        },
        machine: 'Engin',
        before: 'Avant',
        after: 'Après',
      },
      geofences: {
        project: 'Projet',
        projectSourceLayer: 'Calque',
        rgb: 'Couleur(RGB)',
        select: 'Clôture de sélection',
        coordinates: 'Coordonnées',
        coordinatesLabel: 'Coordonnées (N,E)',
        unitLength: 'Unité:%{unit}',
      },
      geofenceDialog: {
        coordinate: 'Coordonnées (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: 'Rayon',
      },
      retrofitAlternateRegists: {
        menuTitle: 'Enregistrement de proxy de contrôleur',
        corporationName: "Nom de l'entreprise",
        email: 'Email',
        basicInfoSerialNumber: 'Numéro de série',
        confirmInput: 'Rentrer le numéro de série',
      },
      ntripList: {
        unusedTotal: 'Total inutilisé',
      },
      calibrationDetail: {
        breadcrumbs: 'Détails',
        machineInfo: 'Info engin',
        machineGeometryInfo: 'Info géométrie engin',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'Info montage IMU',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'Info montage GNSS princ.',
        bodyCenterToGnssSub: 'Info montage GNSS sec.',
        extendedModel: 'Modèle étendu',
        measuredValues: 'Info étalonnage',
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'Erreur de conversion des données',
        system_localization_quantity_error: `Il n'est pas possible d'enregistrer plusieurs fichiers contenant des informations de localisation.
      Veuillez enregistrer un seul fichier contenant des informations de localisation.`,
        system_detect_0byte_file_error: `L'enregistrement du fichier sur le serveur a échoué en raison d'une erreur de communication.
      Veuillez réenregistrer le fichier.`,
        system_batch_error: 'Erreur système (P001)',
        system_parameter_error: 'Erreur système (P002)',
        system_parameterfile_notfound: 'Erreur système (P003)',
        system_exception: 'Erreur système (P004)',
        system_logfile_error: 'Erreur système (P005)',
        system_parameterfile_parameter_error: 'Erreur système (P006)',
        desing_localization_error: `Aucune information de localisation n'est disponible.
      Veuillez enregistrer l'un des fichiers suivants :

      ・Fichier GC3
      ・Fichier TP3 contenant des points de contrôle
      ・Fichier CAL`,
        desing_no_support_dxf_error:
          "Le(s) fichier(s) DXF n'a/n'ont pas pu être lu(s) car sa/leur version est plus ancienne que AutoCAD2000.",
        desing_tile_error: 'Impossible de créer le fichier de projet.',
        desing_outline_error:
          'La création de la ligne de limite extérieure a échoué.',
        desing_area_length_error:
          "Le processus a été interrompu parce que la zone de projet est trop grande. Veuillez vérifier que les données de conception et l'échelle des données CAO sont correctes.",
        desing_layer_name_duplication_error: `Les données du projet avec des noms de calque en double ne peuvent pas être enregistrées.
      Veuillez changer le nom de calque de conception ou l'enregistrer de manière à ce qu'il n'y ait qu'un seul fichier avec le même nom de calque de conception.`,
        targetFiles: '[Nom de fichier]',
        targetLayers: '[Calque]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: 'Aucun',
        system_batch_error: 'Erreur système (T001)',
        system_parameter_error: 'Erreur système (T002)',
        system_parameterfile_notfound: 'Erreur système (T003)',
        system_exception: 'Erreur système (T004)',
        system_logfile_error: 'Erreur système (T005)',
        system_basefile_notfound: 'Erreur système (T006)',
        system_prjfile_multi: 'Erreur système (T007)',
        system_basefile_read_error: 'Erreur système (T008)',
        system_target_layer_notfound: 'Erreur système (T009)',
        system_target_layer_duplication: 'Le même nom de couche existe déjà.',
        system_taskarea_error: 'Zone de tâche non valide',
        system_taskarea_design_notfound:
          "Il n'y a pas de données pour le fichier de projet dans la zone de travail (TIN, réseau de lignes, points).",
        system_tile_error: 'Impossible de créer le fichier de projet.',
        system_outline_error:
          'La création de la ligne de limite extérieure a échoué.',
        system_parameterfile_error: 'Erreur système',
      },
      retrofitError: {
        1000: 'Données UDP du contrôleur non détectées',
        2000: 'IMU châssis non détectée',
        2001: 'Erreur matériel IMU châssis',
        2002: 'Erreur algorithme IMU châssis',
        2003: 'Erreur données IMU châssis',
        2004: 'Erreur initialisation IMU châssis',
        2100: 'IMU flèche non détectée',
        2101: 'Erreur matériel IMU flèche',
        2102: 'Erreur algorithme IMU flèche',
        2103: 'Erreur données IMU flèche',
        2104: 'Erreur initialisation IMU flèche',
        2200: 'IMU balancier non détectée',
        2201: 'Erreur matériel IMU balancier',
        2202: 'Erreur algorithme IMU balancier',
        2203: 'Erreur données IMU balancier',
        2204: 'Erreur initialisation IMU balancier',
        2300: 'IMU godet non détectée',
        2301: 'Erreur matériel IMU godet',
        2302: 'Erreur algorithme IMU godet',
        2303: 'Erreur données IMU godet',
        2304: 'Erreur initialisation IMU godet',
        2400: 'IMU godet inclinable non détectée',
        2401: 'Erreur matériel IMU godet inclinable',
        2402: 'Erreur algorithme IMU godet inclinable',
        2403: 'Erreur données IMU godet inclinable',
        2404: 'Erreur initialisation IMU godet inclinable',
        2500: 'IMU 2e flèche non détectée',
        2501: 'Erreur matériel IMU 2e flèche',
        2502: 'Erreur algorithme IMU 2e flèche',
        2503: 'Erreur données IMU 2e flèche',
        2504: "Erreur d'initialisation IMU 2e flèche",
        2600: "Capteur d'angle de pivotement non détecté",
        2601: "Erreur du système de capteur d'angle de rotation",
        2602: "Erreur matérielle du capteur d'angle de rotation",
        2700: 'Capteur de déport non détecté',
        2701: 'Erreur du système de capteur de déport',
        2702: 'Erreur matérielle du capteur de déport',
        3000: 'Capteur de pression haut non détecté',
        3100: 'Capteur de pression bas non détecté',
        4000: 'Antenne GNSS non détectée',
        5000: 'Information de correction non détectée',
        5001: 'Signal récepteur radio non détecté',
      },
    },
    dialog: {
      menu: {
        confirmManualLink:
          "Vous serez redirigé vers un autre site web. Est-ce que c'est correct ?",
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'Godet',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message:
            "L'ID de l'entreprise et le nom de l'entreprise sont nécessaires.",
        },
        save: {
          message1:
            "L'ID/nom de l'entreprise ne peut pas être modifié car il inclut",
          message2: 'machine(s) attribuée(s) à une autre société.',
          message3: '',
          message4: 'Veuillez vérifier les numéros de série ci-dessous.',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'Contrôleur',
        },
        undeletable: {
          message: 'Le contrôleur est utilisé et ne peut pas être supprimé.',
        },
      },
      Ntrip: {
        save: {
          stop: 'Voulez-vous arrêter ce réglage Ntrip ?',
          delete: 'Voulez-vous supprimer le paramètre Ntrip ?',
        },
      },
      siteRetrofitList: {
        title: 'Informations supplémentaires',
        message: 'La période de construction est terminée.',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: 'Paramètre commun',
          siteSetting: 'Paramètres du chantier',
        },
        mapBackgroundColor: 'Couleur de fond de la carte',
        message:
          "La fonction de détection de contact de grille géographique/de notification d'alerte peut ne pas fonctionner correctement en fonction de l'environnement et des conditions d'utilisation. Ne faites pas trop confiance au système lorsque vous utilisez la fonction et veillez à maitriser la fonction et les conditions d'utilisation.",
      },
      geofenceList: {
        confirmDelete: {
          message: 'Voulez-vous supprimer cette clôture géographique ?',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1:
            'La machine est liée à un chantier et ne peut pas être affectée. Veuillez déconnecter la machine du chantier.',
          message2: 'Veuillez déconnecter la machine du chantier.',
        },
        sameOwner: {
          message:
            'La machine ne peut pas être attribuée car votre société en est le propriétaire.',
        },
        unableTransfer: {
          message:
            "La machine est en attente d'approbation de transfert et ne peut pas être enregistrée pour l'affectation.",
        },
        unableAlternateRegist: {
          message:
            "La machine est en attente de l'approbation de l'enregistrement du proxy du contrôleur et ne peut pas être enregistrée pour l'attribution.",
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          'La machine est liée à un chantier et ne peut être transférée. Veuillez déconnecter la machine du chantier.',
        messageRentaled:
          'La machine est attribuée et ne peut pas être transférée.',
        messageLicense:
          "La machine dispose d'une licence et ne peut pas être transférée.",
        pendingApprovals: {
          message1:
            "La demande de transfert a été reçue. Veuillez attendre l'approbation.",
          message2:
            "Si vous avez des questions, veuillez nous contacter via l'URL ci-dessous.",
        },
      },
      retrofitSendBacks: {
        submit:
          'Voulez-vous renvoyer le contrôleur et hériter des paramètres sur le contrôleur cible ?',
        deleteNtrip: 'Voulez-vous initialiser le contrôleur retourné ?',
        unableRegion:
          "Ce contrôleur a été expédié à l'étranger. S'il s'agit du bon contrôleur, veuillez réinitialiser la\"Région\" à null.",
        unableCorporation: {
          message1: 'Le contrôleur ne peut pas être renvoyé.',
          message2:
            "Si vous pensez qu'il s'agit d'une erreur, veuillez contacter votre revendeur.",
        },
        retrofitRentaled:
          'Le contrôleur est attribué et ne peut pas être renvoyé.',
        tiedToSite:
          'La machine est liée à un chantier et ne peut pas être renvoyée.',
      },
      retrofitAddToSiteAction: {
        title: 'Informations supplémentaires',
        message:
          "Une machine est en attente d'approbation, elle ne peut donc pas être reliée au chantier.",
      },
      retrofitAddToSiteActionTransfer: {
        title: 'Informations supplémentaires',
        message:
          'La machine est en cours de transfert et ne peut pas être reliée au chantier.',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message:
            'Le réglage Ntrip sélectionné est envoyé à une autre machine.',
        },
        unableTransferInfo: {
          message: 'Pour transférer, veuillez annuler les paramètres suivants.',
          reasonSiteId: 'Chantier',
          reasonRentaled: 'Attribution',
          reasonLicense: 'Licence',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            "L'entreprise assignée est actuellement en train d'attribuer la machine à une autre entreprise. La date de début d'attribution à une autre entreprise est le %{date}.",
          message2: 'Veuillez sélectionner une date de fin après %{date}.',
        },
        confirmTieUpJobsite: {
          message:
            "La machine est liée à un chantier de l'entreprise assignée. Voulez-vous mettre fin à cette affectation ?",
        },
        confirmDoubleRental: {
          message:
            "\"L'entreprise assignée est actuellement en train d'attribuer la machine à une autre entreprise. L'entreprise assignée est actuellement en train d'attribuer la machine à une autre entreprise. Le fait de définir une date de fin met également fin à l'attribution à une autre entreprise. Souhaitez-vous définir la date de fin ?\"",
        },
        unapprovedSave: {
          message:
            "Cette affectation n'a pas été approuvée et ne peut pas être sauvegardée.",
        },
        unapprovedDelete: {
          title: '',
          message: 'Voulez-vous supprimer cette tâche ?',
        },
        approvedDelete: {
          title: '',
          message:
            'Cette mission a été approuvée et ne peut pas être supprimée.',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message:
            "La machine est en cours d'affectation et ne peut pas être transférée.",
        },
        approvedSave: {
          message:
            "La machine dispose d'une licence et ne peut pas être transférée.",
        },
        unapprovedDelete: {
          title: '',
          message: 'Voulez-vous supprimer ce transfert ?',
        },
        approvedDelete: {
          title: '',
          message: 'Ce transfert a été approuvé et ne peut être supprimé.',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'Extension balancier',
        },
      },
      projectFileUpload: {
        message:
          'Les fichiers de points de contrôle multiples (.gc3/.cal) ne peuvent pas être enregistrés.',
      },
      projectEdit: {
        confirm: {
          message:
            'Une fois la mise à jour du projet effectuée, les tâches existantes seront supprimées. Voulez-vous mettre à jour ?',
        },
      },
      projectConversionStatus: {
        title: 'État de conversion',
        button: 'OK',
      },
      taskConversionStatus: {
        title: 'État de conversion',
        button: 'OK',
      },
      taskList: {
        undeletable: {
          message:
            "La tâche sélectionnée ne peut pas être supprimée car elle est en cours d'utilisation.",
        },
        unableToCreate: {
          message:
            "Aucune donnée de conception n'a été enregistrée dans le projet.",
        },
        unableToDelete: {
          message1:
            'La tâche ne peut pas être supprimée car le statut du projet et le statut de la tâche sont les suivants.',
          message2: 'Projet : Conversion',
          message3: 'Tâche: Erreur',
        },
        unableToUpdate: {
          message1:
            'La tâche ne peut pas être modifiée car le statut du projet et le statut de la tâche ne sont pas les suivants.',
          message2: 'Projet : [Converti]',
          message3: 'Tâche: [Transfert][Transféré][Échec du transfert]',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'Tâche',
        },
        unableToDelete: {
          message:
            "La tâche sélectionnée ne peut pas être supprimée car elle est en cours d'utilisation.",
        },
      },
      taskMap: {
        crossingMessage: 'La zone de la tâche est intersectée.',
        sameValueMessage: 'Les mêmes coordonnées ont été saisies.',
        areaZeroMessage: "Il n'y a pas de zone pour la zone de tâche.",
        noPolygonMessage:
          "La tâche ne peut pas être enregistrée car elle n'est pas encore enregistrée.",
      },
      compactionMaterials: {
        add: {
          title: 'Ajouter un matériau',
        },
        update: {
          title: 'Mettre à jour un matériel',
        },
      },
      weathers: {
        add: {
          title: 'Ajouter une météo',
        },
        update: {
          title: 'Mettre à jour la météo',
        },
      },
      operators: {
        add: {
          title: 'Ajouter un opérateur',
        },
        update: {
          title: 'Mettre à jour un opérateur',
        },
      },
      compactionWorkAreas: {
        update: {
          message: 'Modifier les zones de travail de compactage',
        },
        undeletable: {
          message:
            'La couche de compactage incluse dans la zone de construction est utilisée et ne peut pas être supprimée.',
        },
      },
      compactionLayers: {
        undeletable: {
          message:
            'La couche de compactage est utilisée et ne peut pas être supprimée.',
        },
        deleteConfirm: {
          messageTargetItem: 'Couche de compactage',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message:
            'Un autre paramètre Ntrip est envoyé à la machine sélectionnée.',
        },
        confirmDelete: {
          message: 'Voulez-vous supprimer le paramètre Ntrip ?',
        },
        confirmDeleteWithMachine: {
          message:
            'La machine est actuellement liée. Voulez-vous supprimer le réglage Ntrip ?',
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'Notification',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: 'Définir la (les) licence(s) sur la machine suivante.',
        },
        changeDisable: {
          message:
            "Veuillez modifier l'identifiant de licence des machines de construction suivantes en valide.",
        },
        changeTemporary: {
          message:
            'Définissez la (les) licence(s) sur la machine suivante. La licence temporaire actuellement définie sera invalidée.',
        },
        errorAdd: {
          message:
            'La licence de la machine ne peut pas être modifiée car la licence Tous a déjà été définie.',
        },
        errorSameType: {
          message:
            'La licence de la machine ne peut pas être modifiée car le même type de licence a déjà été défini.',
        },
        errorUnlimited: {
          message:
            "La machine a déjà défini la licence Illimité, d'autres licences ne peuvent pas être définies.",
        },
        addAll: {
          message:
            'La machine suivante a déjà reçu une (des) licence(s). Voulez-vous définir toutes les licences ?',
        },
        compare: {
          message: 'Changez la licence(s) pour la machine suivante.',
        },
        warning: {
          message:
            'Le %{retrofit} a la licence du %{id}. Souhaitez-vous déconnecter la licence ?',
        },
        unableTransfer: {
          message:
            'La machine est transférée de sorte que la licence ne peut pas être liée.',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial: "Il y a des données qui n'ont pas pu être analysées.",
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1:
            "La demande d'enregistrement a été reçue. Veuillez attendre qu'elle soit approuvée.",
          message2:
            "Si vous avez des questions, veuillez nous contacter via l'URL ci-dessous.",
        },
        corporationIdCheck: {
          message1:
            'Le contrôleur a déjà été enregistré. Veuillez vérifier en recherchant dans les machines.',
          message2:
            "Si vous ne trouvez pas le contrôleur cible dans la liste, veuillez nous contacter via l'URL ci-dessous.",
        },
        earthbrainIdCheck: {
          message1: 'Nous nous excusons pour la gêne occasionnée',
          message2:
            "mais veuillez nous contacter à partir de l'URL ci-dessous avec les informations suivantes.",
          message3: '-Numéro de série du contrôleur',
          message4: '-Nom de la machine à enregistrer (facultatif)',
          message5: "-ID d'entreprise à enregistrer",
        },
        invalidSerialNumber: {
          message1: 'Le contrôleur est déjà utilisé par une autre entreprise.',
          message2: "Si vous souhaitez le modifier pour d'autres raisons,",
          message3:
            "Veuillez nous contacter à partir de l'URL ci-dessous avec les informations suivantes.",
          message4: '-Numéro de série du contrôleur',
          message5: '-Nom de la machine à enregistrer (facultatif)',
          message6: "-ID d'entreprise à enregistrer",
          message7: '-Raison du changement',
        },
        lendingRetrofits: {
          message1: 'Le contrôleur est attribué.',
          message2:
            "Veuillez refaire la demande après la fin de l'affectation chez l'entreprise attributrice.",
          message3:
            "Si vous avez des questions, veuillez nous contacter via l'URL ci-dessous.",
        },
        siteAttached: {
          message1: 'Le contrôleur est lié à un chantier.',
          message2:
            'Veuillez refaire la demande après avoir déconnecté la machine du chantier.',
          message3:
            "Si vous avez des questions, veuillez nous contacter via l'URL ci-dessous.",
        },
        serialNumberExistError: {
          message1:
            "Il s'agit du numéro de série d'un contrôleur qui n'existe pas. Veuillez vous assurer que le numéro de série du contrôleur que vous avez saisi est correct.",
          message2:
            "Si le problème persiste, veuillez nous contacter via l'URL ci-dessous.",
        },
        ntripShortage: {
          message1:
            "Nous n'avons pas pu émettre votre identifiant et votre mot de passe Ntrip.",
          message2: "Veuillez nous contacter à l'URL ci-dessous.",
        },
        serialnumberCheck: {
          message:
            'Veuillez vérifier les produits avec les numéros de série suivants',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: 'retrofitAlternateRegists',
        },
        saveValidation: {
          message: 'Email requis',
        },
        pendingApprovals: {
          message1:
            "La demande d'inscription a été reçue. Veuillez patienter jusqu'à ce qu'elle soit approuvée.",
          message2:
            "Si vous avez des questions, veuillez nous contacter via l'URL ci-dessous.",
        },
        corporationIdCheck: {
          message1:
            'Le contrôleur a déjà été enregistré. Veuillez vérifier en recherchant dans les machines.',
          message2:
            "Si vous ne trouvez pas le contrôleur cible dans la liste, veuillez nous contacter via l'URL ci-dessous.",
        },
        earthbrainIdCheck: {
          message1: 'Nous nous excusons pour la gêne occasionnée',
          message2:
            "mais veuillez nous contacter à partir de l'URL ci-dessous avec les informations suivantes.",
          message3: '-Numéro de série du contrôleur',
          message4: '-Nom de la machine à enregistrer (facultatif)',
          message5: "-ID d'entreprise à enregistrer",
        },
        invalidSerialNumber: {
          message1: 'Le contrôleur est déjà utilisé par une autre entreprise.',
          message2: "Si vous souhaitez le modifier pour d'autres raisons,",
          message3:
            "Veuillez nous contacter à partir de l'URL ci-dessous avec les informations suivantes.",
          message4: '-Numéro de série du contrôleur',
          message5: '-Nom de la machine à enregistrer (facultatif)',
          message6: "-ID d'entreprise à enregistrer",
          message7: '-Raison du changement',
        },
        lendingRetrofits: {
          message1: 'Le contrôleur est attribué.',
          message2:
            "Veuillez refaire la demande après la fin de l'affectation chez l'entreprise attributrice.",
          message3:
            "Si vous avez des questions, veuillez nous contacter via l'URL ci-dessous.",
        },
        siteAttached: {
          message1: 'Le contrôleur est lié à un chantier.',
          message2:
            'Veuillez refaire la demande après avoir déconnecté la machine du chantier.',
          message3:
            "Si vous avez des questions, veuillez nous contacter via l'URL ci-dessous.",
        },
        serialNumberExistError: {
          message1:
            "Il s'agit du numéro de série d'un contrôleur qui n'existe pas. Veuillez vous assurer que le numéro de série du contrôleur que vous avez saisi est correct.",
          message2:
            "Si le problème persiste, veuillez nous contacter via l'URL ci-dessous.",
        },
        ntripShortage: {
          message1:
            "Nous n'avons pas pu émettre votre identifiant et votre mot de passe Ntrip.",
          message2: "Veuillez nous contacter à l'URL ci-dessous.",
        },
        serialnumberCheck: {
          message:
            'Veuillez vérifier les produits avec les numéros de série suivants',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1: 'Ce contrôleur appartient déjà à votre entreprise.',
          message2: "Veuillez contacter l'entreprise demandeuse.",
        },
        userCorporation: {
          message1:
            'Vous ne pouvez pas approuver cette demande car vous prêtez déjà ce contrôleur.',
          message2: "Veuillez contacter l'entreprise demandeuse.",
        },
      },
      licensePurchaseNotice: {
        button: 'OK',
        message1: `Une machine est utilisée sur le site sans licence.
        Une licence est requise pour l'utilisation sur site.
        Si vous n'avez pas acheté de licence, veuillez en acheter une sur le Smart Construction Marketplace.`,
        message2: `
        Si vous louez une machine auprès d'une autre entreprise, veuillez demander une licence pour la machine auprès de la source de location.

        Si vous avez des questions, veuillez consulter le site d'assistance suivant.`,
      },
      siteBuriedStructureEdit: {
        title: 'Buried Structure',
      },
    },
    tootip: {
      retrofitShareFileList: "Journal d'application",
      retrofitConfigList: 'Informations de configuration',
      retrofitKitInfoList: 'Info contrôleur',
      retrofitAccuraciesList: 'Résultat de vérification de précision',
      retrofitCalibaList: 'Info étalonnage',
      retrofitBasicSettingList: 'Paramètres de base',
      retrofitErrorList: "Détails de l'erreur",
      retrofitTopographicSurveyList: 'Levé topographique',
    },
    actions: {
      ok: 'OK',
      add: 'Ajouter',
      create: 'Enregistrement',
      yes: 'Oui',
      no: 'Non',
      confirmation: 'Confirmer',
      save: 'SAUV',
      cancel: 'Annuler',
      required: 'Requis',
      addMachineToSite: 'Ajouter une machine sur le chantier',
      addBucketToSite: 'Ajouter',
      deleteFromSite: 'Déconnecter du site de construction',
      removeLicense: 'Déconnecter de la machine',
      lendMachine: 'Attribution',
      transferMachine: 'Transfert',
      remoteSupport: 'Assistance à distance',
      lendBucket: 'Partager',
      approval: 'Approbation',
      connect: 'Connecter',
      creationInstructions: 'Acquérir le résultat de la construction',
      csvDownload: 'Télécharger',
      retrofitBundleCreate: 'Inscription en vrac',
      termsAccept: 'Accepter',
      termsDeny: 'Désaccord',
      bulkRegistration: 'Mise à jour en vrac',
      bulkRegistrationOK: 'OK',
      compactionItems: 'Articles de compactage',
      addLanguage: 'Ajouter une langue',
    },
    message: {
      error: "Une erreur s'est produite",
      confirm: "Est-ce que c'est bon ?",
      fileUpload: 'Faites glisser les fichiers ici',
      success: "L'inscription est terminée",
      approved: "L'approbation a été complétée",
      deleteConfirmTitle: '',
      deleteConfirmContent: 'Voulez-vous supprimer %{title} ?',
      bulkDeleteConfirmContent: 'Voulez-vous supprimer %{title} ?',
      disconnectFromSite:
        'Voulez-vous vous déconnecter du site de construction ?',
      saveConfirmContent: 'Confirmer',
      removeLicense: 'Voulez-vous vous déconnecter de la machine ?',
      task: {
        crossingMessage: 'La zone de la tâche est intersectée.',
        sameValueMessage: 'Les mêmes coordonnées ont été saisies.',
        areaZeroMessage: "Il n'y a pas de zone pour la zone de tâche.",
        invalidPolygonMessage: 'Les mêmes coordonnées ont été saisies.',
      },
      geofence: {
        crossingMessage: 'La zone de géofence est intersectée.',
        sameValueMessage: 'Les mêmes coordonnées ont été saisies.',
        areaZeroMessage: "Il n'y a pas de zone pour la zone de géofence.",
      },
      licenseError:
        "Vous n'avez pas de licence pour Smart Construction Pilot(Web App).",
      retrofitsManage: {
        serialNumberMatchError: 'Le numéro de série ne correspond pas.',
        serialNumberInputError: "Le numéro de série n'existe pas.",
        invalidAccess:
          "Vous n'êtes pas autorisé à consulter cette page, retournez au début de la page.",
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'Le numéro de série ne correspond pas.',
        serialNumberInputError: "Le numéro de série n'existe pas.",
        retrofitRentaled:
          "Les informations sur l'entreprise ne peuvent pas être mises à jour tant que le contrôleur est en mission.",
        invalidCorporation:
          "Étant donné que votre entreprise n'est pas un distributeur, vous ne pouvez pas effectuer l'enregistrement par proxy du contrôleur.",
      },
    },
    validation: {
      startDate: 'Date de début',
      endDate: 'Date de fin',
      invalidDateFormat: 'Le format de date est invalide.',
      invalidDateTimeFormat: "Le format de date et d'heure est invalide.",
      duplicateProject: 'Le nom du projet est dupliqué',
      incorrectBeforeDate: '%{target} doit être défini avant %{before}.',
      incorrectAfterDate: '%{target} doit être défini après %{after}.',
      incorrectPlanStartDate:
        'La date de début prévue doit être antérieure à la date de fin prévue.',
      incorrectPlanEndDate:
        'La date de fin prévue doit être une date après la date de début prévue.',
      incorrectActualStartDate:
        'La date de début doit être antérieure à la date de fin.',
      incorrectActualEndDate:
        'La date de fin doit être fixée après la date de début.',
      withActualStartDate: 'Veuillez également entrer la date de début.',
      inputAvailableDate:
        'La date pouvant être saisie est à partir de %{date}.',
      registrationRequired:
        "Veuillez sélectionner soit l'entreprise assignée, soit l'adresse e-mail.",
      bothInput:
        "Vous ne pouvez saisir que l'entreprise assignée ou l'adresse électronique.",
      supportMode:
        "Veuillez entrer soit un identifiant d'entreprise, soit un identifiant de chantier.",
      invalidBkFile: "Ce n'est pas un fichier BK3.",
      invalidPdfFile: "Ce n'est pas un fichier PDF.",
      duplicatedBucketName: 'Le nom du seau est dupliqué',
      duplicatedTaskName: 'Le nom de la tâche est dupliqué.',
      duplicateGeofenceName: 'Le nom de géofençage est en double',
      duplicatedCompactionWorkAreaName:
        'Nom de zone de travail de compactage en double',
      duplicatedCompactionLayerName: 'Nom de couche de compactage en double',
      duplicated: {
        compactionMaterials: {
          message: 'Nom de matériau en double',
        },
        weathers: {
          message: 'Nom de météo en double',
        },
        operators: {
          message: "Nom d'opérateur en double",
        },
      },
      containInvalidString:
        "Des caractères non valides sont inclus. (%{msg} n'est pas autorisé)",
      validHalfWidthAlphanumeric:
        'Seuls les caractères alphanumériques demi-largeur sont autorisés.',
      validNumberFormat: 'Pas un numéro.',
      validIntegerFormat: 'Pas un entier.',
      validIntegerRangeFormat: "L'entrée est autorisée de 0 à 2147483647.",
      validDuplicateLanguage: 'La même langue est sélectionnée.',
      validBeforeDate:
        '%{target} doit être défini à une date et une heure avant %{before}.',
      validAfterDate:
        '%{target} doit être défini à une date et une heure après %{after}.',
    },
  },
  resources: {
    sites: {
      name: 'Chantier',
      fields: {
        name: 'Chantier',
        corporationName: "Nom de l'entreprise",
        status: 'Statut',
        workPeriodStartDate: 'Date de début',
        workPeriodEndDate: 'Date de fin',
      },
      status: {
        completed: 'Complété',
        waiting: 'Avant le début de la construction',
        working: 'En cours de construction',
      },
    },
    retrofits: {
      name: 'Engin',
      fields: {
        information: 'Info engin',
        rental: 'Attribution',
        sendBack: 'Renvoyer',
        corporationId: "Entreprise en cours d'utilisation",
        siteId: 'Chantier',
        uuid: 'UUID',
        isError: 'Statut',
        isOnline: 'En ligne',
        lastOnlineTime: 'Dernière connexion',
        basicInfoManufacturer: 'Fabricant',
        basicInfoModel: 'Modèle',
        basicInfoProductNumber: 'N° produit',
        basicInfoSerialNumber: 'N° serie',
        controllerManufacturer: 'Fabricant',
        controllerModel: 'Modèle',
        controllerFirmwareVersion: 'Version du firmware',
        gnssReceiverMainManufacturer: 'Fabricant',
        gnssReceiverMainModel: 'Modèle',
        gnssReceiverMainFirmwareVersion: 'Version du firmware',
        gnssReceiverSubManufacturer: 'Fabricant',
        gnssReceiverSubModel: 'Modèle',
        gnssReceiverSubFirmwareVersion: 'Version du firmware',
        machineInfoCompanyName: 'Fabricant',
        machineInfoMachineType: 'Type d’engin',
        machineInfoMachineName: 'Nom engin',
        machineInfoMachineId: 'ID engin',
        rentalStatus: 'Attribution',
        lastUpdated: 'Dernière mise à jour',
      },
      isError: {
        true: 'Erreur',
        false: 'Normal',
      },
      isOnline: {
        true: 'En ligne',
        false: 'Hors ligne',
      },
      machineInfoMachineType: {
        excavator: 'Excavatrice',
        roadroller: 'Compacteur routier',
        dozer: 'Bulldozer',
        rigiddump: 'Benne rigide',
        wheelloader: 'Chargeuse sur pneus',
        grader: 'Niveleuse',
        automobile: 'Automobile',
      },
      licenseMachineType: {
        excavator: 'Excavatrice',
        roadroller: 'Compacteur routier',
        dozer: 'Bulldozer',
        rigiddump: 'Benne rigide',
        wheelloader: 'Chargeuse sur pneus',
        grader: 'Niveleuse',
        automobile: 'Automobile',
        excavator_license: 'Excavatrice : %{licenseGrantInfo}',
        roadroller_license: 'Compacteur routier : %{licenseGrantInfo}',
        dozer_license: 'Bulldozer : %{licenseGrantInfo}',
        rigiddump_license: 'Benne rigide : %{licenseGrantInfo}',
        wheelloader_license: 'Chargeuse sur pneus: %{licenseGrantInfo}',
        grader_license: 'Niveleuse : %{licenseGrantInfo}',
        automobile_license: 'Automobile : %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: 'Transférant',
        sent: 'Transféré',
        failtosend: 'Échec de transfert',
      },
      rentalStatus: {
        approved: 'Approuvé',
        unapproved: 'Non approuvé',
        returned: 'Retourné',
      },
    },
    retrofitRentals: {
      name: 'Attribution',
      fields: {
        id: 'ID',
        retrofitId: 'ID engin',
        fromCorporationId: "ID de l'entreprise assignatrice",
        fromCorporationName: 'Entreprise assignatrice',
        toCorporationId: 'Entreprise assignée',
        toCorporationName: "Entreprise en cours d'utilisation",
        retrofitRentalId: "ID de l'attribution",
        isApproved: 'Statut',
        isReturned: 'Retourné',
        planStartDate: 'Date de début prévue',
        planEndDate: 'Date de fin prévue',
        actualStartDate: 'Date de début',
        actualEndDate: 'Date de fin',
        rentalUsers: 'Email',
        email: 'Email',
        rentalStatus: 'Statut',
      },
      isApproved: {
        true: 'Approuvé',
        false: 'Non approuvé',
      },
      rentalStatus: {
        approved: 'Approuvé',
        unapproved: 'Non approuvé',
        returned: 'Retourné',
      },
    },
    pendingRentals: {
      name: "En attente d'approbation",
      fields: {
        fromCorporationName: 'Entreprise assignatrice',
        planStartDate: 'Date de début prévue',
        planEndDate: 'Date de fin prévue',
      },
    },
    pendingTransfers: {
      name: "En attente d'approbation",
      fields: {
        fromCorporationName: 'Société transférante',
        planDate: 'Date de transfert prévue',
        lastUpdated: 'Dernière mise à jour',
      },
    },
    pendingAlternateRegists: {
      name: "En attente d'approbation",
      fields: {
        fromCorporationName: 'Propriétaire actuel',
        lastUpdated: 'Dernière mise à jour',
      },
    },
    assignments: {
      name: 'Transfert',
      fields: {
        id: 'ID',
        retrofitId: 'ID engin',
        fromCorporationId: 'ID de la société transférante',
        fromCorporationName: 'Société transférante',
        toCorporationId: 'Société transférée',
        toCorporationName: 'Entreprise',
        isApproved: 'Statut',
        planDate: 'Date de transfert prévue',
        actualDate: 'Date de transfert',
        transferUsers: 'Email',
        email: 'Email',
        lastUpdated: 'Dernière mise à jour',
      },
      isApproved: {
        true: 'Approuvé',
        false: 'Non approuvé',
      },
    },
    buckets: {
      name: 'Godet',
      fields: {
        companyName: "Nom de l'entreprise",
        siteId: 'Chantier',
        name: 'Nom',
        corporationId: "Entreprise en cours d'utilisation",
        bucketType: 'Type',
        bk3File: 'Fichier BK3',
        'bk3File.title': 'Fichier BK3',
        gltfFile: 'Fichier godets',
        uploadedBy: 'Enregistré par',
        updatedBy: 'Mis à jour par',
        fileStatus: 'Statut',
        lastUpdated: 'Dernière mise à jour',
        uploadBk: 'Télécharger BK3',
      },
      bucketType: {
        slope: 'Pente',
        standard: 'Standard',
        tilt: 'Inclinaison',
        tiltrotator: 'Rotateur inclinable',
      },
      uploadedBy: {
        admin: 'Administrateur',
        tablet: 'Tablette',
      },
      fileStatus: {
        converting: 'Conversion',
        completed: 'Complété',
        error: 'Erreur',
      },
    },
    projects: {
      name: 'Projet',
      fields: {
        name: 'Nom projet',
        coordinate: 'Coordonnée syst.',
        version: 'Version',
        status: 'Statut',
        lastUpdated: 'Dernière mise à jour',
        regionId: 'Région',
        projectionId: 'Projection',
        geoidId: 'Géoïde',
        datumId: 'Datum',
        file: 'Fichier(s)',
        retrofitId: 'Machine cible',
      },
      status: {
        converted: 'Complété',
        waitforconvert: 'Conversion',
        error: 'Erreur',
      },
    },
    extensionarms: {
      name: 'Extension balancier',
      fields: {
        extensionarmType: 'Type',
        name: 'Nom',
        corporationId: "Entreprise en cours d'utilisation",
        corporationName: "Entreprise en cours d'utilisation",
        siteId: 'Chantier',
        siteName: 'Chantier',
        lastUpdated: 'Dernière mise à jour',
        extensionarmFile: "Fichier du bras d'extension",
      },
      extensionarmType: {
        basic: 'Extension balancier',
        a: 'Type A',
        b: 'Type B',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: 'Partager avec',
        name: 'Nom',
        extensionarmType: 'Type',
        extensionarmFile: {
          title: "Fichier du bras d'extension",
        },
      },
    },
    corporations: {
      name: 'Entreprise',
      fields: {
        name: "Entreprise en cours d'utilisation",
      },
    },
    retrofitShareFiles: {
      name: "Journal d'application",
      fields: {
        name: 'Nom de fichier',
        fileTime: 'Date et heure de réception',
        url: 'Télécharger',
      },
    },
    retrofitConfigs: {
      name: 'Informations de configuration',
      fields: {
        timeStamp: 'Date et heure de réception',
        url: 'Télécharger',
      },
    },
    retrofitKitInfos: {
      name: 'Info contrôleur',
      fields: {
        timeStamp: 'Date et heure de réception',
        url: 'Télécharger',
      },
    },
    retrofitCalibs: {
      name: 'Info étalonnage',
      fields: {
        timeStamp: 'Date et heure de réception',
        url: 'Télécharger',
        detailInfo: {
          createDateTime: 'Date et heure de création',
          updateDateTime: 'Date et heure de mise à jour',
          machineInfo: {
            companyName: 'Fabricant',
            machineType: 'Type d’engin',
            machineName: 'Nom engin',
            machineId: 'ID engin',
            drumConfiguration: 'Configuration de la machine',
            guidanceMode: 'Mode de guidage',
          },
          machineGeometryInfo: {
            boomLength: 'Longueur de flèche',
            secondBoomLength: 'Longueur de la 2e flèche',
            armLength: 'Longueur de balancier',
            armTopPinToBucketSideLinkPinLength:
              'Dist broche ht balancier-broche artic. côté godet',
            boomTopPinToBucketSideLinkPinLength:
              'Dist broche ht flèche-broche artic côté godet',
            bucketLink1Length: 'Dist broche côté godet-broche vérin godet',
            bucketLink2Length: 'Dist broche vérin godet-broche artic godet',
            bodyCenterToBoomFootPin: {
              x: 'Broche pied flèche X comp. à centre ch',
              y: 'Broche pied flèche Y comp. à centre ch',
              z: 'Broche pied flèche Z comp. à centre ch',
            },
            drumWidth: 'Largeur de la lame',
            length: 'Longueur entre les patins',
            shoeInterval: `Longueur de l'intervalle entre les patins`,
            bladeWidthForDozer: `Largeur de la lame d'un bulldozer`,
            distanceFromBodyToBlade: 'Distance entre le corps et la lame',
            frontToMainGnssAntenna: `Longueur à partir du centre d'une ligne droite reliant l'extrémité avant mise à la terre à l'antenne principale X`,
            frontToSubGnssAntenna: `Longueur à partir du centre d'une ligne droite reliant l'extrémité avant mise à la terre à l'antenne GNSS secondaire X`,
            centerToMainGnssAntenna:
              'Distance Y entre le centre et le centre de la phase électrique du GNSS principal',
            centerToSubGnssAntenna:
              'Distance Y entre le centre et le centre de la phase électrique du GNSS secondaire',
            groundToMainGnssAntenna:
              'Distance Z entre le sol et le centre de phase électrique du GNSS principal',
            groundToSubGnssAntenna:
              'Distance Z de la terre au centre de phase électrique du GNSS secondaire',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'Angle déport IMU flèche',
            secondBoomImuOffsetAngle: 'Angle déport IMU 2e flèche',
            armImuOffsetAngle: 'Angle déport IMU bal.',
            bucketImuOffsetAngle: 'Angle déport IMU godet',
            bodyImuOffsetRollAngle: 'Angle roulis déport IMU ch',
            bodyImuOffsetPitchAngle: 'Angle basc. déport IMU ch',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: 'GNSS princ. X comp. à centre ch',
                y: 'GNSS princ. Y comp. à centre ch',
                z: 'GNSS princ. Z comp. à centre ch',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: 'GNSS sec. X comp. à centre ch',
                y: 'GNSS sec. Y comp. à centre ch',
                z: 'GNSS sec. Z comp. à centre ch',
              },
            },
          },
          extendedModel: {
            swingBoom: 'Modèle flèche rotative',
            twoPieceBoom: 'Modèle flèche en 2 parties',
            minimalSwingRadius: `Fonction d'déport de flèche`,
          },
          measuredValues: {
            otherLength: {
              machineHeight: 'Tolérance du contrepoids',
            },
            bodyImu1: {
              roll: `Roulis de la valeur mesurée par l'IMU`,
              pitch: `Tangage de la valeur mesurée par l'IMU`,
              yaw: `Valeur de mesure du lacet de l'IMU`,
            },
            bodyImu2: {
              roll: `Roulage de la valeur mesurée par l'IMU`,
              pitch: `Valeur mesurée pour le tangage de l'IMU`,
              yaw: `Valeur mesurée du lacet de l'IMU`,
            },
            frontLeft: {
              x: `Valeur mesurée de l'avant gauche X`,
              y: `Valeur mesurée de l'avant gauche Y`,
              z: `Valeur mesurée de l'avant gauche Z`,
            },
            frontRight: {
              x: `Valeur mesurée de l'avant droit X`,
              y: `Valeur mesurée de l'avant droit Y`,
              z: `Valeur de l'enquête sur l'avant droit Z`,
            },
            frontGroundingEnd: {
              x: `Valeur de l'enquête sur l'extrémité du front X`,
              y: `Valeur de l'enquête sur l'extrémité du front Y`,
              z: `Valeur de l'enquête sur l'extrémité de l'avant Z`,
            },
            rearGroundingEnd: {
              x: `Valeur mesurée de l'extrémité arrière X`,
              y: `Valeur mesurée de l'extrémité arrière Y`,
              z: `Valeur mesurée de l'extrémité arrière Z`,
            },
            mainGnssAntenna: {
              x: `Valeur relevée de l'antenne GNSS principale X`,
              y: `Valeur relevée de l'antenne GNSS principale Y`,
              z: `Valeur relevée de l'antenne GNSS principale Z`,
            },
            subGnssAntenna: {
              x: `Valeur relevée de l'antenne GNSS secondaire X`,
              y: `Valeur relevée de l'antenne GNSS secondaire Y`,
              z: `Valeur relevée de l'antenne GNSS secondaire Z`,
            },
            prismPoleOffset: 'Hauteur du pôle du prisme',
            antennaElectricalCenterHeight: `Hauteur à partir du centre électrique de l'antenne`,
          },
        },
      },
    },
    retrofitAccuracies: {
      name: 'Résultat de vérification de précision',
      fields: {
        checkDateTime: 'Date et heure de confirmation',
        surveyPoint: 'Point mesuré',
        positionN: 'Valeur mesurée : N',
        positionE: 'E',
        positionZ: 'Z',
        machineOffsetN: 'Valeur de décalage : N',
        machineOffsetE: 'E',
        machineOffsetZ: 'Z',
        referencePointName: 'Nom du point de référence',
        referencePointN: 'Point de référence : N',
        referencePointE: 'E',
        referencePointZ: 'Z',
        unitLength: 'Unité de longueur',
      },
    },
    retrofitBasicSettings: {
      name: 'Paramètres de base',
      fields: {
        timeStamp: 'Date et heure de réception',
        url: 'Télécharger',
      },
    },
    retrofitErrors: {
      name: "Infos sur l'erreur",
      fields: {
        notifiedTime: "Date et heure de l'occurrence",
        errorCodes: "Code d'erreur : Détails",
      },
    },
    retrofitToposurveys: {
      name: 'Liste levés topogr.',
      fields: {
        surveyDate: "Date et heure de l'enquête",
        projectName: 'Nom projet',
        layerName: 'Calque',
        pointName: 'Nom du point',
        surveyPoint: 'Point mesuré',
        n: 'Valeur mesurée : N',
        e: 'E',
        z: 'Z',
        unitLength: 'Unité de longueur',
      },
    },
    asbuilts: {
      name: 'Résultats construction',
      fields: {
        execStartDate: "Début d'exécution",
        execEndDate: "Fin d'exécution",
        startPeriod: "Période d'acquisition de",
        endPeriod: "Période d'acquisition à",
        execStatus: 'Statut',
        url: '',
        csvType: 'Format de données',
        createUser: 'Créer un utilisateur',
      },
      execStatus: {
        complete: 'Complété',
        inprogress: 'Traitement',
        error: 'Erreur',
        completewithoutdata: 'Complété (Pas de données)',
      },
    },
    retrofitBasicInfos: {
      name: 'Info de base',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: 'Distributeur',
        retrofitRegionId: 'Région',
        corporationId: "ID d'entreprise",
        corporationName: "Nom de l'entreprise",
        basicInfoManufacturer: 'Fabricant',
        basicInfoModel: 'Modèle',
        basicInfoProductNumber: 'N° produit',
        basicInfoSerialNumber: 'N° serie',
        lastUpdated: 'Dernière mise à jour',
        description: 'Description',
        ownerId: "ID d'entreprise",
        ownerName: "Nom de l'entreprise",
      },
      tag: {
        retrofitBasicInfosDetail: 'Info de base',
        sendBack: 'Renvoyer',
      },
      sendBack: {
        fields: {
          site: 'Chantier',
          corporation: "Entreprise en cours d'utilisation",
          ntripSettingInfo: 'ID Ntrip',
          toRetrofit: 'Contrôleur alternatif',
          notificationEmailAddress: 'Email',
        },
      },
    },
    retrofitBulkActions: {
      name: 'Inscription en vrac',
      fields: {
        uploadFileName: 'Nom de fichier',
        execStartDate: "Date et heure de début d'exécution",
        execEndDate: "Date et heure de fin d'exécution",
        execStatus: 'Statut',
        uploadFile: 'Fichier enregistré',
        resultFile: "Résultats de l'inscription",
      },
      execStatus: {
        complete: 'Complété',
        inprogress: 'Traitement',
        error: 'Erreur',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: "Action en masse de l'administrateur de l'entreprise",
      fields: {
        uploadFileName: 'Nom de fichier',
        execStartDate: "Date et heure de début d'exécution",
        execEndDate: "Date et heure de fin d'exécution",
        execStatus: 'Statut',
        uploadFile: 'Fichier enregistré',
        resultFile: "Résultats de l'inscription",
      },
      execStatus: {
        complete: 'Complété',
        inprogress: 'Traitement',
        error: 'Erreur',
      },
    },
    tasks: {
      name: 'Tâche',
      fields: {
        name: 'Nom',
        status: 'Statut',
        description: 'Description',
        projectSourceLayerId: 'Calque',
        retrofitIds: 'Machine cible',
        expiredDate: "Date d'expiration",
        createDate: "Date d'expiration",
        rectangle: 'Plage prise en charge',
        lastUpdated: 'Dernière mise à jour',
      },
      status: {
        error: 'Erreur',
        converting: 'Conversion',
        sending: 'Transférant',
        sent: 'Transféré',
        failtosend: 'Échec de transfert',
      },
    },
    compactionMaterials: {
      name: 'Matériaux',
      fields: {
        name: 'Nom',
      },
    },
    weathers: {
      name: 'Conditions météorologiques',
      fields: {
        name: 'Nom',
      },
    },
    operators: {
      name: 'Opérateurs',
      fields: {
        name: 'Nom',
      },
    },
    compactionWorkAreas: {
      name: 'Zones de travail de compactage',
      fields: {
        name: 'Noms des zones de travail',
        blockSize: 'Taille de bloc',
        description: 'Description',
      },
    },
    compactionLayers: {
      name: 'Couches de compactage',
      fields: {
        name: 'Nom',
        thickness: 'Épaisseur',
        passCount: 'Nombre de passages',
        compactionMaterialId: 'Matériel',
        inUse: 'Statut',
        description: 'Description',
      },
      inUse: {
        true: "en cours d'utilisation",
        false: 'Inutilisé',
      },
    },
    gnssSettings: {
      name: 'Paramètre Ntrip',
      fields: {
        registCorporationName: 'Enregistré par',
        ownerCorporationId: "ID d'entreprise",
        ownerCorporationName: "Nom de l'entreprise",
        name: 'Nom',
        url: 'URL serveur',
        portnum: 'Port',
        userId: 'ID',
        password: 'Mot de passe',
        retrofitId: 'Machine cible',
        status: 'Statut',
        updateDate: 'Dernière mise à jour',
      },
      status: {
        waitforsend: 'Conversion',
        sending: 'Transférant',
        sent: 'Transféré',
        failtosend: 'Échec de transfert',
      },
    },
    supportMode: {
      name: 'Mode de support',
      fields: {
        enabled: 'Activer le mode de support',
        corporationId: "ID d'entreprise",
        corporationName: "Nom de l'entreprise",
        siteId: 'ID du chantier',
        siteName: 'Chantier',
        latitude: 'Latitude',
        longitude: 'Longitude',
        workPeriodStartDate: 'Période de construction réelle (date de début)',
        workPeriodEndDate: 'Période de construction réelle (date de fin)',
        photoUrl: "URL de l'image du site",
        description: 'Description',
      },
    },
    userNotices: {
      name: 'Notification',
      noticeType: {
        emergencyimportant: 'Urgence/Importante',
        emergency: 'Urgence',
        important: 'Important',
      },
      fields: {
        noticeType: 'Importance',
        noticeTypes: 'Importance',
        noticeCategory: 'Type de notification',
        title: 'Titre',
        content: 'Contenu',
        publicationStartDate: 'Date et heure de début(UTC)',
        publicationEndDate: 'Date et heure de fin(UTC)',
        file: 'Pièces jointes',
        language: 'Langue',
        emergency: 'Urgence',
        important: 'Important',
      },
    },
    firmwares: {
      name: 'Firmware',
      fields: {
        version: 'Version',
        compatibilityLevel: 'Niveau de compatibilité',
        firmwareFile: 'Fichier du micrologiciel',
        firmwareFileCheckSum: 'Somme de contrôle du firmware',
        firstInitializationFile: "Fichier d'initialisation 1",
        firstInitializationFileCheckSum:
          "Somme de contrôle du fichier d'initialisation 1",
        secondInitializationFile: "Fichier d'initialisation 2",
        secondInitializationFileCheckSum:
          "Somme de contrôle du fichier d'initialisation 2",
      },
    },
    bucketShares: {
      name: 'Partage du seau',
      fields: {
        fromBucketId: "De l'identifiant du seau",
        corporationId: "ID d'entreprise",
        name: 'Nom',
      },
    },
    extensionarmShare: {
      name: "Partage de bras d'extension",
      fields: {
        name: 'Nom',
      },
    },
    retrofitCorporationLicenses: {
      name: 'LICENCE',
      fields: {
        licenseId: 'ID licence',
        licenseType: 'Type',
        issuedAt: 'Date de publication',
        expiresAt: "Date d'expiration",
        retrofitId: 'Engin',
        comment: 'Description',
        licenseStatus: 'Statut',
        licensePermission: 'LICENCE',
        productType: 'Nom du produit',
      },
      licenseStatus: {
        unlicense: 'Non alloué',
        disable: 'Incorrect',
        enable: 'Valide',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: "Nom de l'entreprise",
        namePlates: 'Photo',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: 'Unité de longueur',
      },
    },
    geofences: {
      name: 'Géorepère',
      fields: {
        type: 'Type',
        name: 'Nom',
        dimension: 'Dimension',
        trigger: 'Déclencheur',
        valid: 'Valide',
        alertType: "Type d'alerte",
        startDate: 'Date de début',
        endDate: 'Date de fin',
        transparency: 'Transparent',
        elevation: 'Hauteur',
        height: 'Hauteur',
        radius: 'Rayon',
        lastUpdated: 'Dernière mise à jour',
        points: 'Plage prise en charge',
        thickness: 'Épaisseur',
      },
      type: {
        line: 'Ligne',
        polygon: 'Polygone',
        circle: 'Cercle',
        point: 'Point',
        wall: 'Cloison',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: 'Valide',
        false: 'Incorrect',
      },
      alertType: {
        attention: 'Attention',
        notice: 'Note',
        warning: 'Avertissement',
        caution: 'Attention',
        danger: 'Danger',
      },
      transparency: {
        true: 'Transparent',
        false: 'Opacité',
      },
    },
    geofenceAlerts: {
      name: 'Alertes',
      fields: {
        timeStamp: 'Date et heure de détection',
        geofenceName: 'Nom de géofençage',
        alertType: "Type d'alerte",
        collisionPoint: 'Point de collision',
        retrofit: {
          machineInfoMachineName: 'Engin',
        },
      },
      alertType: {
        attention: 'Attention',
        notice: 'Note',
        warning: 'Avertissement',
        caution: 'Attention',
        danger: 'Danger',
      },
    },
    ntrips: {
      name: 'Gestion des Ntrip',
      fields: {
        name: 'ID utilisateur',
        ntripPass: 'Mot de passe',
        serial: 'N° serie',
        customerCorporationId: "ID d'entreprise",
        customerCorporationName: "Nom de l'entreprise",
        ntrip: 'Statut',
        numberOfExchanges: "Nombre d'échanges",
        id: 'ID',
        licenceId: 'ID licence',
        dateOfRegistration: "Date d'enregistrement",
        billingToCustomers: 'Facturation aux clients',
        paymentToKomatsu: 'Paiement à Komatsu',
        applicantId: 'ID du demandeur',
        applicant: 'Demandeur',
        requestCorporationId: "Demander l'identifiant de l'entreprise",
        requestCorporationName: "Demander le nom de l'entreprise",
        isUsed: 'Est utilisé',
      },
      ntrip: {
        publish: 'Payé',
        stop: 'Arrêt',
        delete: 'Supprimé',
      },
    },
    retrofitAlternateRegists: {
      name: 'Enregistrement de proxy de contrôleur',
      fields: {
        id: 'ID',
        retrofitId: 'retrofitId',
        fromCorporationId: "de l'identifiant de l'entreprise",
        fromCorporationName: 'Enregistrement de proxy de contrôleur',
        toCorporationId: 'Utilisateur',
        toCorporationName: "Nom de l'entreprise",
        isApproved: 'Statut',
        createDate: 'Date de la demande',
        actualDate: "Date d'approbation",
        transferUsers: 'Email',
        email: 'Email',
        lastUpdated: 'Dernière mise à jour',
      },
      isApproved: {
        true: 'Approuvé',
        false: 'Non approuvé',
      },
    },
    pendingApprovals: {
      name: "En attente d'approbation",
      fields: {
        approvalType: 'Type',
        requestedBy: 'Entreprise du demandeur',
        lastUpdated: 'Dernière mise à jour',
      },
      approvalType: {
        assignment: 'Attribution',
        transfer: 'Transfert',
        agent: 'Enregistrement de proxy de contrôleur',
      },
    },
    retrofitSendbacks: {
      name: 'Renvoyer',
      fields: {
        fromRetrofitId: 'Du retrofit',
        toRetrofitId: 'Contrôleur alternatif',
        notificationEmailAddress: 'Email',
        siteId: 'Chantier',
        corporationId: "ID d'entreprise",
        corporationName: "Entreprise en cours d'utilisation",
        ntripId: 'Info Ntrip',
        isComplete: 'Envoi complet',
      },
    },
    siteBuriedStructures: {
      fields: {
        epsgCode: 'Epsg',
      },
    },
  },
};

export default message;
