import React, { useState } from 'react';
import { DoneButton, DialogOKOnly } from '../../../..';
import { SaveIcon } from '../../../../../assets';
import { validFileType } from '../utils';

const targetFiledName = 'file';
const targetExtensions = ['gc3', 'cal'];

interface Props {
  form: any;
  disabled: boolean;
  onClick: any;
}

const SaveButton: React.FC<Partial<Props>> = ({ form, onClick, ...rest }) => {
  const [open, setOpen] = useState(false);

  const handleClick = async () => {
    const uploadFiles = form
      .getFieldState(targetFiledName)
      .value.map(({ title }: { title: string }) => title);

    if (!validFileType(uploadFiles, targetExtensions)) {
      setOpen(true);
      return;
    }
    if (onClick) {
      onClick();
    } else {
      form.submit();
    }
  };

  return (
    <>
      <DialogOKOnly
        open={open}
        onClose={() => setOpen(false)}
        title=""
        message="admin.dialog.projectFileUpload.message"
      />
      <DoneButton {...rest} onClick={handleClick} label="ra.action.save">
        <SaveIcon />
      </DoneButton>
    </>
  );
};

SaveButton.displayName = 'SaveButton';
export default SaveButton;
