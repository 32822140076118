import { fetchUtils } from 'react-admin';
import { encode } from 'querystring';
import { config } from '../../utils';
import { LandlogSites, getOption } from '.';

const uri = `${config.api.landlog.apiUri}/v2/sites`;

export type LandLogListSitesProps = Partial<{
  corporation_id: string; // eslint-disable-line camelcase
  member_only: string; // eslint-disable-line camelcase
  name: string;
  status: string;
  page: number;
  offset: number;
  limit: number;
  order_by: 'name'; // eslint-disable-line camelcase
  order: 'asc' | 'desc';
}>;

interface ListResponse {
  total: number;
  sites: LandlogSites[];
}

export default async (props: LandLogListSitesProps): Promise<ListResponse> => {
  const params = encode({ ...props });
  const { json } = await fetchUtils.fetchJson(
    `${uri}?${params}`,
    await getOption(),
  );
  return json;
};
