import { fetchUtils } from 'react-admin';
import { config } from '../../utils';
import { LandlogUsers, getOption } from '.';

const uri = `${config.api.landlog.apiUri}/v2/corporations`;

export default async (id: string): Promise<LandlogUsers> => {
  const { json } = await fetchUtils.fetchJson(
    `${uri}/${id}/users`,
    await getOption(),
  );
  return json;
};
