import { LandlogSites, SiteData } from '..';
import { toViewSiteStatus, toViewUnitLength } from '../../utils';

export default (site: LandlogSites): SiteData => ({
  id: site.id,
  name: site.name,
  corporationId: site.corporation.id,
  corporationName: site.corporation.name,
  longitude: site.coordinate.longitude,
  latitude: site.coordinate.latitude,
  workPeriodStartDate: site.work_period.start_date,
  workPeriodEndDate: site.work_period.end_date,
  photoUrl: site.photo_url,
  unitLength: toViewUnitLength(site.unit.distance),
  status: toViewSiteStatus(site.status),
});
