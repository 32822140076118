import React, { FC, useCallback, useState } from 'react';
import {
  Record,
  useTranslate,
  useNotify,
  useDataProvider,
  useLoading,
} from 'react-admin';
import { DoneButton, DialogOKOnlyWithUrl } from '../../..';
import { SaveIcon } from '../../../../assets';
import { UpdateData } from '../hooks';
import { RetrofitData } from '../../../../dataProvider';
import { apiErrorHandler, config } from '../../../../utils';

const useRetrofits = () => {
  const dataProvider = useDataProvider();
  return async (serials: string[]): Promise<RetrofitData[]> => {
    const { data } = await dataProvider.getList<RetrofitData>('retrofits', {
      pagination: {
        page: 1,
        perPage: 100, // ただし、シリアル番号 1回での取得を100にする場合は、コントローラ登録で一回でチェックするシリアル番号を件数を絞り込みすることも合わせて検討する
      },
      sort: { field: 'id', order: 'ASC' },
      filter: {
        basicInfoSerialNumber: serials,
      },
    });
    return data;
  };
};

const useUpdateRetrofits = () => {
  const dataProvider = useDataProvider();
  return async ({
    data,
    updateData,
  }: {
    data: RetrofitData[];
    updateData: any;
  }) => {
    const ids = data.map(it => it.id);
    return dataProvider.updateMany('retrofits', { ids, data: updateData });
  };
};

interface Props {
  basePath: string;
  record: Record;
  saveButtonDisabled: boolean;
  disabled: boolean;
  form: any;
  updateData: UpdateData;
  checkAlreadyRegistered: (retrofits: RetrofitData[]) => Promise<boolean>;
  checkEarthbrainCorporationId: (retrofits: RetrofitData[]) => Promise<boolean>;
  checkSiteAttachment: (retrofitIds: number[]) => Promise<boolean>;
  checkLending: (retrofitIds: number[]) => Promise<boolean>;
  checkPendingTransfer: (retrofitIds: number[]) => Promise<boolean>;
  checkPendingAlternateRegistration: (
    retrofitIds: number[],
  ) => Promise<boolean>;
  checkCorporation: (retrofits: RetrofitData[]) => Promise<boolean>;
  checkNtripUnusedTotal: (retrofits: RetrofitData[]) => Promise<boolean>;
  handleDisableness: () => void;
}

const RetrofitManageSaveButton: FC<Partial<Props>> = ({
  form,
  saveButtonDisabled, // 保存ボタン活性化状態の独自機能
  disabled = false, // 保存ボタン活性化状態のreact-Admin標準機能
  updateData,
  checkAlreadyRegistered = async () => Promise.resolve(true),
  checkEarthbrainCorporationId = async () => Promise.resolve(true),
  checkSiteAttachment = async () => Promise.resolve(true),
  checkLending = async () => Promise.resolve(true),
  checkPendingTransfer = async () => Promise.resolve(true),
  checkPendingAlternateRegistration = async () => Promise.resolve(true),
  checkCorporation = async () => Promise.resolve(true),
  checkNtripUnusedTotal = async () => Promise.resolve(true),
  handleDisableness = () => false,
}) => {
  const loading = useLoading();
  const scSupportRequestUrl = config.url.scSupportRequest; // エラーダイアログ用URL
  const getRetrofits = useRetrofits();
  const updateRetrofits = useUpdateRetrofits();
  const translate = useTranslate();
  const notify = useNotify();
  const [errorDialogInfo, setErrorDialogInfo] = useState<{
    open: boolean;
    errorMessages: string[];
    subMessages?: string[];
  }>({ open: false, errorMessages: [] });

  const handleSubmit = useCallback(() => {
    const serialNoControl = form.getFieldState('retrofitsSerial')?.value;
    const hideNofityError = 'hideNofityError';

    const serials =
      serialNoControl?.map((value: any) => {
        return value ? value.basicInfoSerialNumber : '';
      }) || [];

    // シリアル未入力チェック
    if (serials.length === 0) {
      setErrorDialogInfo({
        open: true,
        errorMessages: [
          translate('admin.message.retrofitsManage.serialNumberInputError'),
        ],
      });
      return;
    }

    const api = async (): Promise<boolean> => {
      const retrofits = await getRetrofits(serials);

      // 存在しないシリアル番号のチェック
      const validSerials = retrofits.map(it => it.basicInfoSerialNumber);
      const invalidSerials = serials.filter(
        (it: string) => !validSerials.includes(it),
      );
      if (invalidSerials.length > 0) {
        setErrorDialogInfo({
          open: true,
          errorMessages: [
            translate(
              'admin.dialog.retrofitManageTab.serialNumberExistError.message1',
            ),
            translate(
              'admin.dialog.retrofitManageTab.serialNumberExistError.message2',
            ),
          ],
          subMessages: [
            translate(
              'admin.dialog.retrofitManageTab.serialnumberCheck.message',
            ),
            invalidSerials.join(' , '),
          ],
        });
        throw Error(hideNofityError);
      }

      const retrofitIds = retrofits.map(({ id }) => id);
      // ログイン中企業とレトロフィットの登録先企業チェック
      if (!(await checkAlreadyRegistered(retrofits))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // EARTHBRAIN企業IDチェック
      if (!(await checkEarthbrainCorporationId(retrofits))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // 所有元企業の制限チェック
      if (!(await checkCorporation(retrofits))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // Ntrip払い出しで未使用NtripDBの合計数が足りるかチェック
      if (!(await checkNtripUnusedTotal(retrofits))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // レトロフィット貸与中チェック 2021/09/22 所有元企業の制限チェックとチェック順番を入替
      if (!(await checkLending(retrofitIds))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // 譲渡承認待ちチェック
      if (!(await checkPendingTransfer(retrofitIds))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      //  代理申請承認待ちチェック
      if (!(await checkPendingAlternateRegistration(retrofitIds))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // 現場の紐づき状態をチェック
      if (!(await checkSiteAttachment(retrofitIds))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // update処理
      await updateRetrofits({ data: retrofits, updateData });
      return true;
    };

    api()
      .then(result => {
        if (result) {
          notify('admin.message.success', 'info');
          handleDisableness();
        }
      })
      .catch(error => {
        const message = apiErrorHandler(error);
        if (message !== hideNofityError) {
          // 想定外エラーはダイアログ表示ではなくnotify表示
          notify(message, 'warning');
        }
      });
  }, [
    getRetrofits,
    checkAlreadyRegistered,
    checkEarthbrainCorporationId,
    checkSiteAttachment,
    checkLending,
    checkPendingTransfer,
    checkPendingAlternateRegistration,
    checkCorporation,
    checkNtripUnusedTotal,
    updateRetrofits,
    handleDisableness,
    translate,
    notify,
    form,
    updateData,
  ]);

  return (
    <>
      <DoneButton
        label="admin.actions.save"
        disabled={disabled || saveButtonDisabled || loading}
        onClick={handleSubmit}
      >
        <SaveIcon />
      </DoneButton>
      <DialogOKOnlyWithUrl
        open={errorDialogInfo.open}
        onClose={() => {
          setErrorDialogInfo({
            ...errorDialogInfo,
            open: false,
          });
        }}
        title=""
        nonTranslateMessage={true}
        messages={errorDialogInfo.errorMessages}
        url={scSupportRequestUrl}
        subMessages={errorDialogInfo.subMessages}
      />
    </>
  );
};

RetrofitManageSaveButton.displayName = 'RetrofitManageSaveButton';
export default RetrofitManageSaveButton;
