import React, { FC } from 'react';
import { SimpleShowLayout, TextField } from 'react-admin';
import {
  Box,
  createStyles,
  makeStyles,
  Grid,
  Typography,
} from '@material-ui/core';
import { GeofenceData, UnitLength } from '../../../../dataProvider';
import { CustomDateField, IconField } from '../../..';
import { LabelInfoList } from '../map/controller/types';
import GeofenceMapCoordinateList from './GeofenceMapCoordinateList';
import { useUnitLength } from '../../../../hooks/unitLength';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // Firefox
      scrollbarColor: '#717284 transparent',
      scrollbarWidth: 'thin',
      // chrome,safari,Edge
      '&::-webkit-scrollbar': {
        width: 8,
        background: 'transparent',
      },
      '&::-webkit-scrollbar:horizontal': {
        height: 8,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 4,
        background: '#717284',
      },
      '&::-webkit-scrollbar-corner': {
        background: 'transparent',
      },
      height: 360,
      width: 250,
      backgroundColor: '#31323A',
      overflowY: 'auto',
      display: 'flex',
      padding: '1px 9px 17px 9px',
      '& .name > div': {
        alignItems: 'center',
        flexWrap: 'wrap',
        height: 45,
        width: '100%',
        borderBottom: '1px solid #525259',
      },
      '& .type > div': {
        marginTop: '4px',
      },
      '& .type > div > div': {
        height: 45,
        display: 'flex',
        alignItems: 'center',
      },
      '& .dimension > div': {
        marginTop: '4px',
      },
      '& .dimension > div > div': {
        height: 35,
        display: 'flex',
        alignItems: 'center',
      },
      '& .valid > div': {
        marginTop: '4px',
      },
      '& .valid > div > div': {
        height: 35,
        display: 'flex',
        alignItems: 'center',
      },
      '& .alertType > div': {
        marginTop: '4px',
      },
      '& .alertType > div > div': {
        height: 35,
        display: 'flex',
        alignItems: 'center',
      },
      '& .lastUpdated > div': {
        alignItems: 'center',
        flexWrap: 'wrap',
        height: 45,
        display: 'flex',
      },
      '& > div .coordinatesLabel': {
        marginTop: '4px',
        marginBottom: '4px',
        textAlign: 'left',
        fontSize: 12,
      },
      '& > div .unitLengthLabel': {
        marginTop: '4px',
        marginBottom: '4px',
        paddingRight: '8px',
        textAlign: 'right',
        fontSize: 12,
      },
      '& div > label': {
        width: 86,
        minWidth: 86,
        paddingRight: 0,
      },
      '& .svg-icon.icon-root': {
        paddingLeft: '10px',
      },
    },
  }),
);

interface Props {
  record?: GeofenceData;
  unitLength: UnitLength;
  labelInfo?: LabelInfoList;
}

const unselectRecord = {
  name: 'ー',
  type: 'ー',
  dimension: 'ー',
  valid: 'ー',
  alertType: 'ー',
  lastUpdated: 'ー',
};

const GeofenceListShow: FC<Props> = ({ record, labelInfo, unitLength }) => {
  const classes = useStyles();
  const unselected = !record;
  const coordinateListHight = unselected ? 0 : record.points.length * 16;
  const { meterToUnitLengthRoundUp } = useUnitLength();

  return (
    <Box className={classes.root}>
      <Box flex={9.5}>
        <SimpleShowLayout
          record={record || unselectRecord}
          resource="geofences"
        >
          <TextField
            resource="geofences"
            source="name"
            label={labelInfo?.name}
            className="name"
          />
          {unselected ? (
            <TextField
              resource="geofences"
              source="type"
              label={labelInfo?.type}
              className="type"
            />
          ) : (
            <IconField
              resource="geofences"
              source="type"
              label={labelInfo?.type}
              className="type"
              onTooltip={({ res }) => {
                // onTooltipがstring型しか受け付けないため、undeifinedの代わりにresを返す
                return labelInfo ? labelInfo.listShowToolTip(res) : res;
              }}
            />
          )}
          {unselected ? (
            <TextField
              resource="geofences"
              source="dimension"
              label={labelInfo?.dimension}
              className="dimension"
            />
          ) : (
            <IconField
              resource="geofences"
              source="dimension"
              label={labelInfo?.dimension}
              className="dimension"
              onTooltip={({ res }) => {
                return labelInfo ? labelInfo.listShowToolTip(res) : res;
              }}
            />
          )}
          {unselected ? (
            <TextField
              resource="geofences"
              source="valid"
              label={labelInfo?.valid}
              className="valid"
            />
          ) : (
            <IconField
              resource="geofences"
              source="valid"
              label={labelInfo?.valid}
              className="valid"
              onTooltip={({ res }) => {
                return labelInfo ? labelInfo.listShowToolTip(res) : res;
              }}
            />
          )}
          {unselected ? (
            <TextField
              resource="geofences"
              source="alertType"
              label={labelInfo?.alertType}
              className="alertType"
            />
          ) : (
            <IconField
              resource="geofences"
              label={labelInfo?.alertType}
              source="alertType"
              className="alertType"
              onTooltip={({ res }) => {
                return labelInfo ? labelInfo.listShowToolTip(res) : res;
              }}
            />
          )}
          {unselected ? (
            <TextField
              resource="geofences"
              label={labelInfo?.lastUpdated}
              source="lastUpdated"
              className="lastUpdated"
            />
          ) : (
            <CustomDateField
              resource="geofences"
              source="lastUpdated"
              label={labelInfo?.lastUpdated}
              showTime
              className="lastUpdated"
            />
          )}
          {unitLength ? (
            <Box>
              <Grid style={{ display: 'flex' }}>
                <Grid item xs={12}>
                  <Typography className="coordinatesLabel">
                    {labelInfo?.coordinatesLabel}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className="unitLengthLabel">
                    {labelInfo?.unitLengthLabel(unitLength)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <></>
          )}
          {unitLength ? (
            <GeofenceMapCoordinateList
              width={224}
              height={coordinateListHight}
              maxHeight={160}
              data={{
                id: record?.id || -1,
                localCoordinates:
                  record?.points.map(point => [
                    meterToUnitLengthRoundUp(point[0], unitLength, 3),
                    meterToUnitLengthRoundUp(point[1], unitLength, 3),
                  ]) || new Array(0),
              }}
              onChange={() => {
                console.log('on Change!!');
              }}
            />
          ) : (
            <></>
          )}
        </SimpleShowLayout>
      </Box>
    </Box>
  );
};

GeofenceListShow.displayName = 'GeofenceListShow';
export default GeofenceListShow;
