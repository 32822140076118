import { CompactionMaterialData } from '../../types';

const compactionMaterials: CompactionMaterialData[] = [
  {
    id: 1,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料１',
  },
  {
    id: 2,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料２',
  },
  {
    id: 3,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料３',
  },
  {
    id: 4,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料４',
  },
  {
    id: 5,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料５',
  },
  {
    id: 6,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料６',
  },
  {
    id: 7,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料７',
  },
  {
    id: 8,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料８',
  },
  {
    id: 9,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料９',
  },
  {
    id: 10,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料１０',
  },
  {
    id: 11,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料１１',
  },
  {
    id: 12,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料１２',
  },
  {
    id: 13,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料１３',
  },
  {
    id: 14,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料１４',
  },
  {
    id: 15,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料１５',
  },
  {
    id: 16,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料１６',
  },
  {
    id: 17,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料１７',
  },
  {
    id: 18,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料１８',
  },
  {
    id: 19,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料１９',
  },
  {
    id: 20,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料２０',
  },
  {
    id: 21,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料２１',
  },
  {
    id: 22,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '転圧材料２２',
  },
  {
    id: 23,
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    name: '転圧材料１',
  },
  {
    id: 24,
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    name: '転圧材料２',
  },
  {
    id: 25,
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    name: '転圧材料３',
  },
  {
    id: 26,
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    name: '転圧材料４',
  },
  {
    id: 27,
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    name: '転圧材料５',
  },
  {
    id: 28,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: '転圧材料１',
  },
  {
    id: 29,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: '転圧材料２',
  },
  {
    id: 30,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: '転圧材料３',
  },
  {
    id: 31,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: '転圧材料４',
  },
  {
    id: 32,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: '転圧材料５',
  },
  {
    id: 33,
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    name: '転圧材料１',
  },
  {
    id: 34,
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    name: '転圧材料２',
  },
  {
    id: 35,
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    name: '転圧材料３',
  },
  {
    id: 36,
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    name: '転圧材料４',
  },
  {
    id: 37,
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    name: '転圧材料５',
  },
];

export default compactionMaterials;
