import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CustomList,
  Datagrid,
  TextField,
  ReferenceField,
  IconField,
  CustomDateField,
  Filter,
  SiteSearchInput,
  Loading,
} from '../..';
import { useGetAccount, useJumpTo } from '../../../hooks';
import { GnssOwner } from '../../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 160,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 160,
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 252,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 80,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 112,
          },
        },
      },
    },
  }),
);

const SerchFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

const GnssSettingOwnerList: React.FC<{
  addAction: React.ReactElement;
}> = ({ addAction, ...props }) => {
  const resource = 'gnssSettings';
  const { getCorporationId, isLoading } = useGetAccount();
  const corporationId = getCorporationId();
  const classes = useStyles();
  const { gotoEdit } = useJumpTo();
  if (isLoading() || corporationId === '') return <Loading />;
  return (
    <CustomList
      {...props}
      resource={resource}
      title="admin.pages.gnssSettingList"
      filter={{ ownerCorporationId: corporationId }} // Admin権限ユーザ以外の場合は、GetList で Filterに ownerCoroporationId にログインユーザの企業IDを指定
      bulkActionButtons={false}
      actions={addAction}
      filters={<SerchFilter />}
      icon={<GnssOwner />}
    >
      <Datagrid
        className={classes.root}
        rowClick={(id: string) => {
          gotoEdit('gnssSettings', id);
        }}
      >
        <TextField resource={resource} source="name" />
        <TextField resource={resource} source="userId" />
        <ReferenceField
          resource={resource}
          source="retrofitId"
          reference="retrofits"
          link=""
          label="resources.retrofits.fields.machineInfoMachineName"
        >
          <TextField resource="retrofits" source="machineInfoMachineName" />
        </ReferenceField>
        <IconField resource={resource} source="status" sortable={false} />
        <CustomDateField resource={resource} source="updateDate" showTime />
      </Datagrid>
    </CustomList>
  );
};

GnssSettingOwnerList.displayName = 'GnssSettingOwnerList';
export default GnssSettingOwnerList;
