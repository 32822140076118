import { RetrofitCorporationLicenseData } from '../../../../dataProvider';
import { License } from '../dialog/Layout';

export default (
  license?: RetrofitCorporationLicenseData,
): License | undefined =>
  license && {
    id: license.licenseId,
    expireDate: license.expiresAt,
    permission: license.licensePermission,
  };
