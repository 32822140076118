import { Vector as VectorLayer } from 'ol/layer';
import GeometryType from 'ol/geom/GeometryType';
import { Draw, Select, Translate, Modify } from 'ol/interaction';
import { Vector as SourceVector } from 'ol/source';
import { Feature, Collection } from 'ol';
import { never as olEventsConditionNever } from 'ol/events/condition';
import { Stroke, Style } from 'ol/style';

export const createPolygonInteraction = (
  sourceVector: SourceVector,
  featureId: number,
  onDrawEnd?: () => void,
): Draw => {
  const shape = 'polygon';
  const ret = new Draw({
    source: sourceVector,
    type: GeometryType.POLYGON,
    geometryFunction: undefined,
    geometryName: shape,
  });
  ret.on('drawend', e => {
    const shapes = e.feature.getGeometryName();
    e.feature.set('shape', shapes);
    e.feature.setId(featureId);
    if (onDrawEnd) onDrawEnd();
  });
  return ret;
};

export const createCircleInteraction = (
  sourceVector: SourceVector,
  featureId: number,
  onDrawEnd?: () => void,
) => {
  const shape = 'circle';
  const ret = new Draw({
    source: sourceVector,
    type: GeometryType.CIRCLE,
    geometryFunction: undefined,
    geometryName: shape,
  });
  ret.on('drawend', e => {
    e.feature.setId(featureId);
    if (onDrawEnd) onDrawEnd();
  });
  return ret;
};

export const createLineInteraction = (
  sourceVector: SourceVector,
  featureId: number,
  onDrawEnd?: () => void,
) => {
  const shape = 'line';
  const ret = new Draw({
    source: sourceVector,
    type: GeometryType.LINE_STRING,
    geometryFunction: undefined,
    geometryName: shape,
    maxPoints: 2,
  });
  ret.on('drawend', e => {
    e.feature.setId(featureId);
    if (onDrawEnd) onDrawEnd();
  });
  return ret;
};

export const createPointInteraction = (
  sourceVector: SourceVector,
  featureId: number,
  onDrawEnd?: () => void,
) => {
  const shape = 'point';
  const ret = new Draw({
    source: sourceVector,
    type: GeometryType.POINT,
    geometryFunction: undefined,
    geometryName: shape,
  });
  ret.on('drawend', e => {
    e.feature.setId(featureId);
    if (onDrawEnd) onDrawEnd();
  });
  return ret;
};

export const createSelectionInteraction = (
  vectorLayer: VectorLayer,
  onSelect?: (isSelected: boolean, features: Feature[]) => void,
): Select => {
  const defaultColor = '#3399CC';

  // 2重線 デフォルトカラードットライン
  const customLineStyleOut = new Style({
    stroke: new Stroke({
      width: 7,
      color: '#66CCFF',
    }),
  });
  const customLineStyleIn = new Style({
    stroke: new Stroke({
      width: 6,
      color: defaultColor,
      lineCap: 'round',
      lineDash: [3, 10],
    }),
  });

  const ret = new Select({
    layers: [vectorLayer],
    multi: false, // 複数選択は不可にする
    style: [customLineStyleOut, customLineStyleIn],
  });
  ret.on('select', e => {
    const isSelected = e.selected.length > 0;
    if (onSelect) {
      // getFeatures の結果は、通常のArray型ではないので洗替えを行う
      const features: Feature[] = [];
      e.target.getFeatures().forEach((it: any) => features.push(it));
      onSelect(isSelected, features);
    }
  });
  return ret;
};

export const createModifyInteractionByFeatures = (
  features: Collection<Feature>,
  onModifyEnd?: () => void,
  noAddPoint?: boolean,
): Modify => {
  // noAddPointにtrueが設定されている場合、頂点が増える類の編集操作を無効にする
  const ret = noAddPoint
    ? new Modify({
        features,
        insertVertexCondition: olEventsConditionNever,
      })
    : new Modify({
        features,
      });
  ret.on('modifyend', () => {
    if (onModifyEnd) onModifyEnd();
  });
  return ret;
};

export const createModifyInteraction = (
  sourceVector: SourceVector,
  insertVertex: boolean, // false: 頂点データの追加を禁止する
  onModifyEnd?: () => void,
): Modify => {
  const ret = new Modify({
    source: sourceVector,
    insertVertexCondition: () => {
      return insertVertex;
    },
  });
  ret.on('modifyend', () => {
    if (onModifyEnd) onModifyEnd();
  });
  return ret;
};

export const createTranslationInteraction = (
  select: Select,
  onTranslateEnd?: () => void,
): Translate => {
  const ret = new Translate({
    features: select.getFeatures(),
  });
  ret.on('translateend', () => {
    if (onTranslateEnd) onTranslateEnd();
  });
  return ret;
};
