import { config } from '../../utils';
import getHttpClient from './getHttpClient';

const { apiUri } = config.api.admin;
const resource = 'landlogaccess';

export default {
  sendAccessToken: async (
    accessToken: string,
    refreshToken: string,
    corporationId: string,
    email: string,
  ): Promise<void> => {
    if (config.api.admin.useFake || !config.feature.landlogAccess) {
      // fakeモードまたは、LandlogAccess実行フラグがtrue以外の場合は実施しない
      return Promise.resolve();
    }
    const { clientId, clientSecret } = config.auth.landlog;
    const data = {
      accessToken,
      corporationId,
      clientId,
      clientSecret,
      refreshToken,
      email,
    };
    const httpClient = getHttpClient();
    await httpClient(`${apiUri}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    return Promise.resolve();
  },
};
