import { FileInput as RaFileInput, InputProps } from 'react-admin';
import { DropzoneOptions } from 'react-dropzone';
import { ResourceFC } from '..';

const FileInput = RaFileInput;
interface FileInputProps {
  accept?: string;
  labelMultiple?: string;
  labelSingle?: string;
  maxSize?: number;
  minSize?: number;
  multiple?: boolean;
}

interface FileInputOptions extends DropzoneOptions {
  inputProps?: any;
}

FileInput.displayName = 'FileInput';
export default FileInput as ResourceFC<
  FileInputProps & InputProps<FileInputOptions>
>;
