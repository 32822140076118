import { useTranslate } from 'react-admin';
import {
  RetrofitCorporationLicenseData,
  LicensePermissionType,
} from '../../../../dataProvider';
import { getConfirmInfo } from '../utils';

interface InParams {
  data: RetrofitCorporationLicenseData[];
  settingLicensePermission: LicensePermissionType;
}

interface OutParams {
  data: {
    isError: boolean;
    isDeleteInsert: boolean;
    message: string;
    usingLicenseData: RetrofitCorporationLicenseData[];
    isCompactDialog?: boolean;
  };
}

const useGetConfirmInfo = () => {
  const translate = useTranslate();
  return ({ data, settingLicensePermission }: InParams): OutParams => {
    const confirmInfo = getConfirmInfo(data, settingLicensePermission);
    return {
      data: {
        ...confirmInfo,
        message: translate(confirmInfo.message),
      },
    };
  };
};

export default useGetConfirmInfo;
