/*
 * デプロイ環境によって異なる設定情報をビルド時に環境変数より取り込む。
 * この場所以外で環境変数にアクセスしないこと。
 * 環境変数の扱いについては以下の情報を参照。
 * https://create-react-app.dev/docs/adding-custom-environment-variables
 */

export default {
  auth: {
    useFake: process.env.REACT_APP_AUTH_USE_FAKE === 'true',
    landlog: {
      issuerUri: process.env.REACT_APP_AUTH_LANDLOG_AUTH_ISSUER_URI || '',
      realUri: process.env.REACT_APP_AUTH_LANDLOG_AUTH_REAL_URI || '',
      apiUri: process.env.REACT_APP_AUTH_LANDLOG_AUTH_URI || '',
      clientId: process.env.REACT_APP_AUTH_LANDLOG_CLIENT_ID || '',
      clientSecret: process.env.REACT_APP_AUTH_LANDLOG_CLIENT_SECRET || '',
      scope: process.env.REACT_APP_AUTH_LANDLOG_SCOPE || '',
    },
  },
  corporation: {
    earthbrain: {
      corporationId:
        process.env.REACT_APP_CORPORATION_EARTHBRAIN_CORPORATION_ID || '',
    },
    landlog: {
      corporationId:
        process.env.REACT_APP_CORPORATION_LANDLOG_CORPORATION_ID || '',
    },
    kal: {
      corporationId: process.env.REACT_APP_CORPORATION_KAL_CORPORATION_ID || '',
    },
  },
  googleAnalitycs: {
    determinationId: process.env.REACT_APP_GA_MEASUREMENT_ID || '',
  },
  api: {
    trace: process.env.REACT_APP_API_TRACE === 'true',
    landlog: {
      useFake: process.env.REACT_APP_API_LANDLOG_USE_FAKE === 'true',
      apiUri: process.env.REACT_APP_API_LANDLOG_URI || '',
    },
    admin: {
      useFake: process.env.REACT_APP_API_ADMIN_USE_FAKE === 'true',
      apiUri: process.env.REACT_APP_API_ADMIN_URI || '',
    },
    remote: {
      useFake: process.env.REACT_APP_API_REMOTE_USE_FAKE === 'true',
      apiUri: process.env.REACT_APP_API_REMOTE_URI || '',
    },
  },
  url: {
    terms: process.env.REACT_APP_URL_TERMS || '',
    consitions: process.env.REACT_APP_URL_CONDITIONS || '',
    manualDefault: process.env.REACT_APP_URL_MANUAL || '',
    manuals: {
      ja: process.env.REACT_APP_URL_MANUAL_JA || '',
    },
    mapServer: process.env.REACT_APP_URL_MAP_SERVER || '',
    siteManage: process.env.REACT_APP_URL_SITE_MANAGER || '',
    scSupportRequest: process.env.REACT_APP_URL_SC_SUPPORT_REQUEST || '',
  },
  feature: {
    compaction: process.env.REACT_APP_FEATURE_COMPACTION === 'true',
    useRetrofitLicense:
      process.env.REACT_APP_FEATURE_RETROFITLICENSE === 'true',
    retrofitManage: process.env.REACT_APP_FEATURE_RETROFIT_MANAGE === 'true',
    adminBulkAction: process.env.REACT_APP_FEATURE_ADMIN_BULK_ACTION === 'true',
    landlogAccess: process.env.REACT_APP_FEATURE_LANDLOG_ACCESS === 'true',
    geofence: process.env.REACT_APP_FEATURE_GEOFENCE === 'true',
    transfer: process.env.REACT_APP_FEATURE_TRANSFER === 'true',
    ntrips: process.env.REACT_APP_FEATURE_NTRIPS === 'true',
    retrofitCalibDetailInfo:
      process.env.REACT_APP_FEATURE_RETROFIT_CALIB_DETAILINFO === 'true',
    retrofitAlternateRegist:
      process.env.REACT_APP_FEATURE_RETROFIT_ALTERNATE_REGIST === 'true',
    siteBuriedStructure:
      process.env.REACT_APP_FEATURE_SITE_BUREID_STRUCTURE === 'true',
    roleTransferCreate:
      process.env.REACT_APP_FEATURE_ROLE_FOR_TRANSFER_CREATE || '',
    roleTransferApproval:
      process.env.REACT_APP_FEATURE_ROLE_FOR_TRANSFER_APPLOVALS || '',
    roleRetrofitControllerRegistCreate:
      process.env
        .REACT_APP_FEATURE_ROLE_FOR_RETROFIT_CONTROLLER_REGIST_CREATE || '',
    roleRetrofitAlternateRegistCreate:
      process.env.REACT_APP_FEATURE_ROLE_FOR_RETROFIT_ALTERNATE_REGIST_CREATE ||
      '',
  },
  maintenance: process.env.REACT_APP_NEXT_MAINT_MODE === 'true',
};
