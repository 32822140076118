import { geofenceTypeToGeometryType } from '.';
import { GeofenceData } from '../../../../dataProvider';
import { LonLat, findRecordById, Geometry } from '../../../../utils';

interface LonLatId {
  id: number;
  coordinates: LonLat[];
}

interface GeometriesArrayInfo {
  geometries: Geometry[];
  boundaryLonLat?: any;
}

export default (
  lonLatId: LonLatId[],
  rawGeofences: GeofenceData[],
  targetId?: number,
): GeometriesArrayInfo => {
  // boundary調整で必要になるLonLatの最小最大値初期値
  let minLon: number | undefined;
  let minLat: number | undefined;
  let maxLon: number | undefined;
  let maxLat: number | undefined;

  const geometries = lonLatId.map(({ id, coordinates }): Geometry => {
    const record: GeofenceData = findRecordById(rawGeofences, id);
    // boudary 設定
    coordinates.forEach(coordinate => {
      const { lon, lat } = coordinate;
      if (!maxLon || (maxLon && lon > maxLon)) maxLon = lon;
      if (!minLon || (minLon && lon < minLon)) minLon = lon;
      if (!maxLat || (maxLat && lat > maxLat)) maxLat = lat;
      if (!minLat || (minLat && lat < minLat)) minLat = lat;
    });

    const fillRgbaAlpha =
      targetId === undefined || id === targetId ? 0.05 : 0.001;
    const strokeRgbaAlpha =
      targetId === undefined || id === targetId ? 1.0 : 0.2;

    return {
      id,
      type: geofenceTypeToGeometryType(record.type),
      name: record.name,
      coordinates,
      radius: record.radius,
      fillColor: `rgba(${record.rgbRed}, ${record.rgbGreen}, ${record.rgbBlue}, ${fillRgbaAlpha})`,
      strokeColor: `rgba(${record.rgbRed}, ${record.rgbGreen}, ${record.rgbBlue}, ${strokeRgbaAlpha})`,
    };
  });

  const boundaryLonLat =
    geometries.length > 0
      ? [
          {
            lon: maxLon,
            lat: maxLat,
          },
          {
            lon: minLon,
            lat: maxLat,
          },
          {
            lon: minLon,
            lat: minLat,
          },
          {
            lon: maxLon,
            lat: minLat,
          },
        ]
      : undefined;
  return { geometries, boundaryLonLat };
};
