import { DataProvider } from 'react-admin';
import { SupportModeData } from '..';

const getKey = (id: string) => `support_mode_${id}`;

// eslint-disable-next-line import/prefer-default-export
const supportModeDataProvider: DataProvider = {
  getList: () => Promise.reject(new Error('not supported')),

  getOne: (resource, params): any => {
    if (resource !== 'supportMode')
      return Promise.reject(new Error('not supported'));
    const { id } = params;
    const key = getKey(id as string);
    const value = localStorage.getItem(key);
    const data: SupportModeData = value ? JSON.parse(value) : { id };
    return Promise.resolve({ data });
  },
  getMany: () => Promise.reject(new Error('not supported')),
  getManyReference: () => Promise.reject(new Error('not supported')),
  update: (resource, params) => {
    if (resource !== 'supportMode')
      return Promise.reject(new Error('not supported'));
    const { id, data } = params;
    const key = getKey(id as string);
    const value = JSON.stringify(data);
    localStorage.setItem(key, value);
    return Promise.resolve({ data });
  },
  updateMany: () => Promise.reject(new Error('not supported')),
  create: (resource, params): any => {
    if (resource !== 'supportMode')
      return Promise.reject(new Error('not supported'));
    const { data: paramData } = params;
    const data = paramData as SupportModeData;
    const { id } = data;
    const key = getKey(id);
    const value = JSON.stringify(data);
    localStorage.setItem(key, value);
    return Promise.resolve({ data });
  },
  delete: () => Promise.reject(new Error('not supported')),
  deleteMany: () => Promise.reject(new Error('not supported')),
};

export default supportModeDataProvider;
