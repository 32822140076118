import React, { FC, cloneElement } from 'react';
import { useLocation } from 'react-router-dom';
import { FunctionField, GeofenceList } from '../../..';
import { UserConfigureData } from '../../../../dataProvider';

interface Props {
  userConfigure: UserConfigureData | undefined;
}

const SiteGeofenceList: FC<Props> = props => {
  const { userConfigure } = props;
  const location = useLocation();
  const resource = 'geofences';
  return (
    <FunctionField
      {...props}
      resource="sites"
      addLabel={false}
      render={record => {
        if (!record) return null;
        const childrenProps = {
          breadcrumbs: ['resources.sites.name'],
          basePath: location.pathname,
          location,
          controllerResource: `${resource}_${record.id}`,
          filter: { siteId: record.id },
          site: record,
          userConfigure,
        };
        return cloneElement(<GeofenceList />, childrenProps);
      }}
    />
  );
};

SiteGeofenceList.displayName = 'SiteGeofenceList';
export default SiteGeofenceList;
