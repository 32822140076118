import { useLocation, useParams } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import { SiteData, Resources } from '../dataProvider';

// useEditInSite の改良版（任意のタイミングで sites を取得できる）
const useSite = <T extends keyof Resources>(resource: T) => {
  const dataProvider = useDataProvider();
  const location = useLocation();
  const { pathname } = location;
  const lastPathIndex = pathname.lastIndexOf('/');
  const basePath = location.pathname.slice(0, lastPathIndex);
  const { siteId, id } = useParams<{
    siteId: string;
    id: string;
  }>();
  return {
    props: { location, basePath, resource, id },
    getSite(): Promise<{ data: SiteData }> {
      return dataProvider.getOne<SiteData>('sites', {
        id: siteId,
      });
    },
  };
};

export default useSite;
