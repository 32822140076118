import { AdminApiConverter, ApiFilter } from '..';

const converter: AdminApiConverter<'bucketNames', 'buckets'> = {
  resource: 'buckets',
  defaultSort: { field: 'id', order: 'DESC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'buckets'> = {};
    if ('id' in filter) apiFilter.id = filter.id;
    if ('corporationId' in filter)
      apiFilter.corporationId = filter.corporationId;
    if ('name' in filter) apiFilter.name = filter.name;
    return apiFilter;
  },
  sortFieldToApi: () => 'id',
  recordsFromApi: async records =>
    records.map(record => ({
      id: record.id || 0,
      corporationId: record.corporationId || '',
      name: record.name,
    })),
  recordToApi: record => ({
    name: record.name,
    corporationId: record.corporationId,
    bucketType: 'Standard',
    uploadedBy: 'Admin',
  }),
};

export default converter;
