export enum LogLevelValue {
  error = 1,
  warn,
  info,
  debug,
}

type SupportLogType = {
  error: LogLevelValue;
  warn: LogLevelValue;
  info: LogLevelValue;
  debug: LogLevelValue;
};

export type LogLevel = keyof SupportLogType;

export const logLevelToLogValue: { [key: string]: LogLevelValue } = {
  error: LogLevelValue.error,
  warn: LogLevelValue.warn,
  info: LogLevelValue.info,
  debug: LogLevelValue.debug,
};

export const logLevelValueToTagName: { [key: string]: string } = {
  [LogLevelValue.error]: 'ERROR',
  [LogLevelValue.warn]: 'WARN',
  [LogLevelValue.info]: 'INFO',
  [LogLevelValue.debug]: 'DEBUG',
};
