import { useParams } from 'react-router-dom';
import { useCustomGetOne } from '.';
import { SiteData } from '../dataProvider';

const useGetSite = (): SiteData | undefined => {
  const { siteId } = useParams<{ siteId: string }>();
  const { data } = useCustomGetOne('sites', siteId);
  if (data) {
    return data as SiteData;
  }
  return undefined;
};

export default useGetSite;
