import { AdminApiConverter, ApiFilter } from '..';

const converter: AdminApiConverter<'projectNames', 'projects'> = {
  resource: 'projects',
  defaultSort: { field: 'id', order: 'ASC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'projects'> = {};
    if ('id' in filter) apiFilter.id = filter.id;
    if ('siteId' in filter) apiFilter.siteId = filter.siteId;
    if ('name' in filter) apiFilter.name = filter.name;
    return apiFilter;
  },
  sortFieldToApi: () => 'id',
  recordsFromApi: async records =>
    records.map(record => ({
      id: record.id || 0,
      siteId: record.siteId || '',
      name: record.name || '',
      unitLength: record.unitLength, // 参照されない
    })),
  recordToApi: record => ({
    name: record.name,
    coordinateType: 'Projection',
    projectSourceFileIds: [],
    unitLength: 'M', // 参照されない
  }),
};

export default converter;
