// 1つ上階層のBasePathを返す
const getParentPath = (basePath: string) => {
  const lastPathIndex = basePath.lastIndexOf('/');
  return basePath.slice(0, lastPathIndex);
};

// 2つ上階層のBasePathを返す
const getParentParentPath = (basePath: string) => {
  const parentPath = getParentPath(basePath);
  return getParentPath(parentPath);
};

export { getParentPath, getParentParentPath };
