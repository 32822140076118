import { RadioButtonGroupInput as RaRadioButtonGroupInput } from 'react-admin';
import { withStyles, createStyles } from '@material-ui/core';
import { InputProps as RaInputProps } from 'ra-core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { ResourceFC } from '..';

const styles = createStyles({
  label: {
    display: 'contents',
    '& > span': {
      /* MuiInputLabel と同等のスタイルを設定 */
      alignItems: 'center',
      minWidth: 148,
      width: 148,
      paddingRight: 12,
      color: undefined,
      fontSize: 12,
      marginTop: 15, // verticalAlignが聞かないためmargin追加
    },
  },
});

export type RadioButtonGroupInputProps = RaInputProps<TextFieldProps> &
  Omit<TextFieldProps, 'label' | 'helperText'>;

const RadioButtonGroupInput = withStyles(styles)(RaRadioButtonGroupInput);

const { defaultProps } = RadioButtonGroupInput;
RadioButtonGroupInput.defaultProps = {
  ...defaultProps,
};

RadioButtonGroupInput.displayName = 'RadioButtonGroupInput';
export default RadioButtonGroupInput as ResourceFC<RadioButtonGroupInputProps>;
