import React, {
  FC,
  forwardRef,
  ReactNode,
  useEffect,
  useState,
  useCallback,
} from 'react';
import {
  useGetIdentity,
  usePermissions,
  useTranslate,
  UserMenu,
} from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItemLink, DialogOKCancel } from '..';
import { UserPermissionsData } from '../../dataProvider';
import { useAgreementStore } from '../../hooks';
import { countryCodeStore, getManualFilePath } from '../../utils';

interface Props {
  onClick: (e: any) => void;
}

/* お知らせ */
const UserNoticeMenu = forwardRef((props: Partial<Props>, ref: any) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/userNotices"
      primaryText={translate('admin.usermenu.userNotice')}
      onClick={props?.onClick}
    />
  );
});
UserNoticeMenu.displayName = 'UserNoticeMenu';

/* サポートモード */
const SupportModeMenu = forwardRef(
  ({ onClick, email }: Partial<Props> & { email: string }, ref: any) => {
    const translate = useTranslate();
    return (
      <MenuItemLink
        ref={ref}
        to={`/supportMode/${email}`}
        primaryText={translate('resources.supportMode.name')}
        onClick={onClick}
      />
    );
  },
);
SupportModeMenu.displayName = 'SupportModeMenu';

/* 利用規約 */
const TermsMenu = forwardRef((props: Partial<Props>, ref: any) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/terms"
      primaryText={translate('admin.usermenu.terms')}
      onClick={props?.onClick}
    />
  );
});
TermsMenu.displayName = 'TermsMenu';

// マニュアルリンク関連メソッド群
const useManualMenuStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
  },
}));

const checkOtherSite = (urlString: string): boolean => {
  try {
    const url = new URL(urlString);
    if (url.hostname !== window.location.hostname) {
      return true;
    }
  } catch (e) {
    // ここに来るのはurlは相対パスである
  }
  return false;
};

const openTabToLink = (url: string) => {
  window.open(url, '_blank');
};

/* マニュアル */
const ManualMenu = forwardRef((props: Partial<Props>, ref: any) => {
  const translate = useTranslate();
  const classes = useManualMenuStyles();
  const [open, setOpen] = useState(false);

  const primaryText = translate('admin.usermenu.manual');
  const manualUrl = getManualFilePath();

  const handleClick = useCallback(() => {
    if (checkOtherSite(manualUrl)) {
      // 別サイトへのリンクの為、確認ダイアログを出す
      setOpen(true);
      return;
    }
    openTabToLink(manualUrl);
  }, [manualUrl]);

  const handleSubmit = useCallback(() => {
    openTabToLink(manualUrl);
  }, [manualUrl]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Tooltip title={primaryText} placement="right">
        <MenuItem ref={ref} onClick={handleClick} className={classes.root}>
          {primaryText}
        </MenuItem>
      </Tooltip>
      <DialogOKCancel
        open={open}
        onClose={handleClose}
        onOKClick={handleSubmit}
        title=""
        message="admin.dialog.menu.confirmManualLink"
      />
    </>
  );
});
ManualMenu.displayName = 'ManualMenu';

/* ダウンロード */
const DownloadMenu = forwardRef((props: Partial<Props>, ref: any) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/downloads"
      primaryText={translate('admin.usermenu.downloads')}
      onClick={props?.onClick}
    />
  );
});
DownloadMenu.displayName = 'DownloadMenu';

/**
 * データ取得などの副作用のある処理はこのコンポーネントでやること。
 * 個別のメニューコンポーネントはメニューを開くたびにマウント・アンマウントされるので、
 * 副作用のある処理には適さない。
 */
const CustomUserMenu: FC<{ logout: ReactNode }> = ({ logout }) => {
  const [userAgreement, setUserAgreement] = useState<boolean | null>(null);
  const { getAgreement } = useAgreementStore();
  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();

  useEffect(() => {
    const fetch = async () => {
      const result = await getAgreement();
      setUserAgreement(result);
    };
    fetch();
  }, [getAgreement]);

  if (!permissions) return null;
  if (!identity) return null;
  if (userAgreement === null) return null;
  const { id: email } = identity;
  if (typeof email !== 'string') return null;
  const userRole = permissions.userRoles as UserPermissionsData['userRoles'];
  const supportMode = userRole.includes('SupportMode');
  const userNotice = userRole.includes('UserNotice');
  const isOverseas = countryCodeStore.get() !== 'JP';

  return (
    <UserMenu logout={logout}>
      {userAgreement && userNotice && <UserNoticeMenu />}
      {userAgreement && supportMode && <SupportModeMenu email={email} />}
      {userAgreement && <TermsMenu />}
      {userAgreement && <ManualMenu />}
      {userAgreement && isOverseas && <DownloadMenu />}
    </UserMenu>
  );
};

export default CustomUserMenu;
