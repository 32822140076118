import React, { FC, useCallback, useState } from 'react';
import {
  useDataProvider,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { CustomSaveButton, DialogOKCancel } from '../../..';
import { SaveIcon } from '../../../../assets';
import { NtripData } from '../../../../dataProvider';
import { apiErrorHandler } from '../../../../utils';

interface Props {
  form: any;
  record?: NtripData;
}

const NtripSaveButton: FC<Partial<Props>> = ({ ...props }) => {
  const { form, record } = props;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const translate = useTranslate();
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const basePath = '/ntrips';

  const handleSave = useCallback(() => {
    if (!record) return;
    const updateData: NtripData = form.getState().values;
    const { ntrip } = updateData;
    // 保存処理
    const fn = async () => {
      if (!ntrip) return;
      // 選択されたNtripステータスが「停止」、「削除(=返却済)」の場合は、建機との紐づけを解除する
      const updateSerial =
        ntrip === '停止' || ntrip === '削除(=返却済)' ? null : record.serial;
      // 選択されたNtripステータスが「停止」の場合は、顧客への請求とコマツへの支払いを 「なし」に設定する
      const updateBillingToCustomers =
        ntrip === '停止' ? 'なし' : record.billingToCustomers;
      const updatePaymentToKomatsu =
        ntrip === '停止' ? 'なし' : record.paymentToKomatsu;

      try {
        await dataProvider.update('ntrips', {
          id: record.id,
          data: {
            ...record,
            ntrip, // Ntripのステータス情報
            serial: updateSerial, // Ntripのシリアル情報
            billingToCustomers: updateBillingToCustomers, // 顧客への請求
            paymentToKomatsu: updatePaymentToKomatsu, // コマツへの支払い
          },
          previousData: record,
        });
        notify('admin.message.success', 'info');
        redirectTo(basePath);
      } catch (error) {
        notify(apiErrorHandler(error), 'warning');
      }
    };
    fn();
  }, [dataProvider, notify, record, form, redirectTo]);

  const handleHide = useCallback(() => setOpen(false), [setOpen]);

  const handleClick = () => {
    const updateData: NtripData = form.getState().values;
    const { ntrip } = updateData;
    let dialogMessage = '';

    // 変更後のステータスが「停止」、「削除」によって確認ダイアログの出力メッセージを変更する
    if (ntrip === '停止') {
      dialogMessage = translate('admin.dialog.Ntrip.save.stop');
    } else if (ntrip === '削除(=返却済)') {
      dialogMessage = translate('admin.dialog.Ntrip.save.delete');
    }
    setMessage(dialogMessage);
    setOpen(true);
  };

  return (
    <>
      <CustomSaveButton {...props} onSave={handleClick}>
        <SaveIcon />
      </CustomSaveButton>
      <DialogOKCancel
        open={open}
        onClose={handleHide}
        onOKClick={handleSave}
        okLabel="admin.actions.yes"
        cancelLabel="admin.actions.no"
        title=""
        message={message}
      />
    </>
  );
};

NtripSaveButton.displayName = 'NtripSaveButton';
export default NtripSaveButton;
