import { useGetList } from 'react-admin';
import { SiteConfigureData } from '../../../../dataProvider';
import { objectToList } from '../../../../utils';

interface InParams {
  siteId: number;
}

interface OutParams {
  data: {
    enableGeofence: boolean | undefined;
  };
}

const useGetSiteConfigures = ({ siteId }: InParams): OutParams => {
  const { data: keyValueData } = useGetList<SiteConfigureData>(
    'siteConfigures',
    {
      page: 1,
      perPage: 1,
    },
    { field: 'id', order: 'DESC' },
    {
      siteId,
    },
  );

  const data = objectToList(keyValueData) as SiteConfigureData[];

  const existData = data.length > 0;
  const enableGeofence = existData ? data[0].enableGeofence : undefined;
  return {
    data: { enableGeofence },
  };
};

export default useGetSiteConfigures;
