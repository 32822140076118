import { NtripData } from '../../types';

const data: NtripData[] = [
  {
    id: '1',
    name: 'user001',
    serial: 'SERIAL_1',
    licenceId: 'test_licenseid01',
    customerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    customerCorporationName: '株式会社クオリカ建築　テスト事業部',
    ntripPass: 'pass001',
    ntrip: '払出済',
    dateOfRegistration: '2022/10/10',
    billingToCustomers: 'あり',
    paymentToKomatsu: 'あり',
    numberOfExchanges: '1',
    applicantId: 'll_id@mail',
    applicant: 'll_acount_name',
    requestCorporationId: 'll_corporationId',
    requestCorporationName: 'll_corporationName',
    isUsed: true,
  },
  {
    id: '2',
    name: 'user002',
    serial: 'SERIAL002',
    licenceId: 'test_licenseid02',
    customerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    customerCorporationName: '株式会社クオリカ建築　テスト事業部',
    ntripPass: 'pass002',
    ntrip: '停止',
    dateOfRegistration: '2022/10/10',
    billingToCustomers: 'あり',
    paymentToKomatsu: 'あり',
    numberOfExchanges: '1',
    applicantId: 'll_id@mail',
    applicant: 'll_acount_name',
    requestCorporationId: 'll_corporationId',
    requestCorporationName: 'll_corporationName',
    isUsed: true,
  },
  {
    id: '3',
    name: 'user003',
    serial: 'SERIAL003',
    licenceId: 'test_licenseid03',
    customerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    customerCorporationName: '株式会社クオリカ建築　テスト事業部',
    ntripPass: 'pass003',
    ntrip: '削除(=返却済)',
    dateOfRegistration: '2022/10/10',
    billingToCustomers: 'あり',
    paymentToKomatsu: 'あり',
    numberOfExchanges: '1',
    applicantId: 'll_id@mail',
    applicant: 'll_acount_name',
    requestCorporationId: 'll_corporationId',
    requestCorporationName: 'll_corporationName',
    isUsed: true,
  },
];
export default data;
