import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { ButtonProps as MuiProps } from '@material-ui/core/Button';
import Button, { RaProps } from '../../bases/button/Button';
import { colors } from '../../../theme';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../../theme/define';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: colors.button.cancel,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
  }),
);

interface Props {
  label: string;
  download?: boolean;
  classes: object;
}

const NormalButton = (props: Partial<Props & RaProps & MuiProps>) => {
  const {
    label,
    onClick,
    href,
    download,
    disabled,
    style,
    classes: classOverride,
    children,
  } = props;
  const testId = label || '';
  const classes = useStyles({ classes: classOverride });
  return (
    <Button
      style={style}
      className={classes.root}
      label=""
      onClick={onClick}
      href={href}
      download={download}
      disabled={disabled}
      data-testid={testId}
    >
      {children}
    </Button>
  );
};

NormalButton.displayName = 'NormalButton';
export default NormalButton;
