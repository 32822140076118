const KEY_FETCHABLE = 'agreement_fetchable';

const agreementFetchableStore = {
  init: () => {
    localStorage.setItem(KEY_FETCHABLE, 'true');
  },
  get: () => {
    const flag = localStorage.getItem(KEY_FETCHABLE);
    if (flag === undefined) return false;
    return flag === 'true';
  },
  set: (flag: boolean) => {
    localStorage.setItem(KEY_FETCHABLE, flag ? 'true' : 'false');
  },
};

export default agreementFetchableStore;
