import React from 'react';
import { MapProps, crearteOverlayControl } from '../../../../../hooks';
import { MapTransparentButton } from '../../../..';
import { MapPen } from '../../../../../assets';

const addListEditControl = ({ map, record }: MapProps, onClick: () => void) => {
  map.addControl(
    crearteOverlayControl(
      'map_edit',
      <MapTransparentButton onClick={onClick} disabled={!record}>
        <MapPen />
      </MapTransparentButton>,
      {
        right: 61,
        top: 370,
      },
    ),
  );
};

export default addListEditControl;
