import { fetchUtils, GetOneResult } from 'react-admin';
import { getOption, LandlogLicense, LicenseData } from '..';
import { config } from '../../utils';

const uri = `${config.api.landlog.apiUri}/v2/licenses/me`;

export default async (): Promise<GetOneResult<LicenseData>> => {
  const { json } = await fetchUtils.fetchJson(uri, await getOption());
  const license = json.licenses as LandlogLicense[];
  const items = license
    .map(l =>
      l.subscription.items.map(i => ({ itemId: i.item_id, planId: i.plan_id })),
    )
    .flat();
  return { data: { id: 1, items } };
};
