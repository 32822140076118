import { AdminApiConverter } from '..';
import { UserConfigureData } from '../../types';

const converter: AdminApiConverter<'userConfigures', 'userConfigures'> = {
  resource: 'userConfigures',
  defaultSort: { field: 'id', order: 'DESC' },
  filterToApi: filter => filter,
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map((record): UserConfigureData => {
        return {
          id: record.id || 0,
          userId: record.userId || 0,
          email: record.email || '',
          mapBackgroundColor: record.mapBackgroundColor || '',
        };
      }),
    ),
  recordToApi: record => {
    return {
      userId: record.userId,
      email: record.email,
      mapBackgroundColor: record.mapBackgroundColor,
    };
  },
};
export default converter;
