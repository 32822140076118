import React, { FC, useCallback, useState } from 'react';
import { useRedirect, useNotify, useDelete } from 'react-admin';
import { CRUD_DELETE } from 'ra-core';
import { makeStyles, createStyles } from '@material-ui/core';
import { Button, DialogOKOnly, DialogOKCancel } from '../../../..';
import { RetrofitRentalData } from '../../../../../dataProvider';
import { colors } from '../../../../../theme';
import { DeleteIcon } from '../../../../../assets';
import { apiErrorHandler } from '../../../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    deleteButton: {
      background: colors.button.delete,
      color: colors.text.primaryLight,
      minWidth: 160,
      minHeight: 36,
      textTransform: 'none',
      '&:hover': {
        background: colors.button.deleteHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.deleteDisabled,
      },
    },
  }),
);

const DeleteButton: FC<
  Partial<{
    record: RetrofitRentalData;
    basePath: string;
  }>
> = ({ record, basePath }) => {
  const { id, isApproved } = record || { id: '', isApproved: false };
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const resource = 'retrofitRentals';
  const notify = useNotify();
  const redirectTo = useRedirect();

  const [deleteOne] = useDelete(resource, id, record, {
    action: CRUD_DELETE,
    onSuccess: () => {
      notify('ra.notification.deleted', 'info', { smart_count: 1 });
      redirectTo(basePath || '');
    },
    onFailure: error => {
      notify(apiErrorHandler(error), 'warning');
      setOpen(false);
    },
    undoable: false,
  });

  const handleShow = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleHide = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const dialog = isApproved ? (
    <DialogOKOnly
      open={open}
      onClose={handleHide}
      title="admin.dialog.retrofitRentalEdit.approvedDelete.title"
      message="admin.dialog.retrofitRentalEdit.approvedDelete.message"
    />
  ) : (
    <DialogOKCancel
      open={open}
      onClose={handleHide}
      onOKClick={deleteOne}
      title="admin.dialog.retrofitRentalEdit.unapprovedDelete.title"
      message="admin.dialog.retrofitRentalEdit.unapprovedDelete.message"
      isDelete={true}
    />
  );

  return (
    <>
      <Button
        className={classes.deleteButton}
        onClick={handleShow}
        label=""
        data-testid="ra.action.delete"
      >
        <DeleteIcon />
      </Button>
      {dialog}
    </>
  );
};

DeleteButton.displayName = 'DeleteButton';
export default DeleteButton;
