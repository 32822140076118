import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import {
  CustomList,
  Datagrid,
  DatagridBody,
  Filter,
  IconField,
  LinkButton,
  Loading,
  ReferenceField,
  RetrofitAddToSiteActionButton,
  RetrofitAddToSiteDatagridRow,
  SiteSearchInput,
  TextField,
} from '../../..';
import { useAddInSite, useSpacerStyles } from '../../../../hooks';
import { ReturnIcon } from '../../../../assets';
import { config } from '../../../../utils';
import { RetrofitData } from '../../../../dataProvider';

const RetrofitAddToSiteListFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

const BulkActionButtons: React.FC<{ basePath?: string }> = props => {
  const classes = useSpacerStyles();
  const { basePath } = props;
  if (!basePath) return null;
  const lastPathIndex = basePath.lastIndexOf('/');
  const to = basePath.slice(0, lastPathIndex);

  return (
    <>
      <LinkButton className={classes.button} path={to} label="ra.action.cancel">
        <ReturnIcon />
      </LinkButton>
      <span className={classes.root} />
      <RetrofitAddToSiteActionButton {...props} />
    </>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 16,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 138,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 218,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 94,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 94,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 218,
          },
        },
      },
    },
  }),
);

const SiteRetrofitCreate: React.FC = () => {
  const { props, site } = useAddInSite('retrofits');
  const classes = useStyles();
  if (!site || !props) return <Loading />;
  const { resource } = props;
  const { name: siteName, corporationId } = site;

  return (
    <CustomList
      {...props}
      title="admin.pages.siteRetrofitCreate"
      breadcrumbs={['resources.sites.name', '', siteName]}
      bulkActionButtons={<BulkActionButtons />}
      filters={<RetrofitAddToSiteListFilter />}
      filter={{ corporationId }}
      sort={{ field: 'siteId', order: 'ASC' }}
    >
      <Datagrid
        body={<DatagridBody row={<RetrofitAddToSiteDatagridRow />} />}
        className={classes.root}
      >
        {config.feature.useRetrofitLicense ? (
          <IconField
            resource={resource}
            source="licenseMachineType"
            label="resources.retrofits.fields.machineInfoMachineType"
            sortable={false}
            onTooltip={({ res, record, translate }) => {
              const { hasLicense, licenseGrantInfo } = record as RetrofitData;
              if (hasLicense) {
                return translate(res, {
                  licenseGrantInfo,
                });
              }
              return translate(res);
            }}
          />
        ) : (
          <IconField resource={resource} source="machineInfoMachineType" />
        )}
        <TextField resource={resource} source="machineInfoMachineName" />
        <IconField resource={resource} source="isOnline" sortable={false} />
        <IconField resource={resource} source="isError" sortable={false} />
        <ReferenceField
          resource={resource}
          source="siteId"
          reference="sites"
          link={false}
        >
          <TextField resource="sites" source="name" />
        </ReferenceField>
      </Datagrid>
    </CustomList>
  );
};

SiteRetrofitCreate.displayName = 'SiteRetrofitCreate';
export default SiteRetrofitCreate;
