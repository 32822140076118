import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { SiteData } from '../../../../../dataProvider';

interface InParams {
  siteId: string;
}

interface OutParams {
  data: {
    siteName: string | undefined;
  };
}

const useGetSiteName = () => {
  const dataProvider = useDataProvider();

  return useCallback(
    async ({ siteId }: InParams): Promise<OutParams> => {
      const { data: siteData } = await dataProvider.getOne<SiteData>('sites', {
        id: siteId,
      });
      const { name } = siteData;

      return {
        data: {
          siteName: name,
        },
      };
    },
    [dataProvider],
  );
};

export default useGetSiteName;
