/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  useDataProvider,
  useLoading,
  useNotify,
  useRedirect,
} from 'react-admin';
import CustomSaveButton from '../../../../molecules/CustomSaveButton';
import { SaveIcon } from '../../../../../assets';
import { apiErrorHandler } from '../../../../../utils';
import { RetrofitCalibsData } from '../../../../../dataProvider';

interface Props {
  basePath: string;
  record: RetrofitCalibsData;
  disabled: boolean;
  swingBoom?: boolean;
  twoPieceBoom?: boolean;
  minimalSwingRadius?: boolean;
}

const SaveButton: React.FC<Partial<Props>> = ({ ...props }) => {
  const {
    record: previousData,
    disabled,
    basePath,
    swingBoom,
    twoPieceBoom,
    minimalSwingRadius,
  } = props;
  const resource = 'retrofitCalibs';
  const loading = useLoading();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const dataProvider = useDataProvider();

  const handleSave = (updateValues: any) => {
    if (!previousData) return;
    const newUpdateValue = {
      ...previousData,
      detailInfo: {
        ...previousData.detailInfo,
        //* * machineGeometryInfo */

        // 入力欄に値が入力された場合、入力された値で更新する
        // 入力欄の値がnullであり、入力前に数値型のデータを持っていた場合、0.0で更新する
        // 入力欄の値がnullであり、入力前は文字列型のデータを持っていた場合、空文字「' '」で更新する
        // 入力欄へ入力前も入力後も項目の値がnullである場合、そのままnullで更新する
        machineInfo: {
          companyName:
            updateValues.detailInfo.machineInfo.companyName !== null
              ? updateValues.detailInfo.machineInfo.companyName
              : previousData.detailInfo?.machineInfo?.companyName !== null
              ? ''
              : null,

          machineType:
            updateValues.detailInfo.machineInfo.machineType !== null
              ? updateValues.detailInfo.machineInfo.machineType
              : previousData.detailInfo?.machineInfo?.machineType !== null
              ? ''
              : null,

          machineName:
            updateValues.detailInfo.machineInfo.machineName !== null
              ? updateValues.detailInfo.machineInfo.machineName
              : previousData.detailInfo?.machineInfo?.machineName !== null
              ? ''
              : null,

          machineId:
            updateValues.detailInfo.machineInfo.machineId !== null
              ? updateValues.detailInfo.machineInfo.machineId
              : previousData.detailInfo?.machineInfo?.machineId !== null
              ? ''
              : null,

          drumConfiguration:
            updateValues.detailInfo.machineInfo.drumConfiguration !== null
              ? updateValues.detailInfo.machineInfo.drumConfiguration
              : previousData.detailInfo?.machineInfo?.drumConfiguration !== null
              ? ''
              : null,

          guidanceMode:
            updateValues.detailInfo.machineInfo.guidanceMode !== null
              ? updateValues.detailInfo.machineInfo.guidanceMode
              : previousData.detailInfo?.machineInfo?.guidanceMode !== null
              ? ''
              : null,
        },
        //* * machineGeometryInfo */
        machineGeometryInfo: {
          boomLength:
            updateValues.detailInfo.machineGeometryInfo?.boomLength !== null
              ? updateValues.detailInfo.machineGeometryInfo?.boomLength
              : previousData.detailInfo?.machineGeometryInfo?.boomLength !==
                null
              ? 0.0
              : null,

          secondBoomLength:
            updateValues.detailInfo.machineGeometryInfo?.secondBoomLength !==
            null
              ? updateValues.detailInfo.machineGeometryInfo.secondBoomLength
              : previousData.detailInfo?.machineGeometryInfo
                  ?.secondBoomLength !== null
              ? 0.0
              : null,

          armLength:
            updateValues.detailInfo.machineGeometryInfo?.armLength !== null
              ? updateValues.detailInfo.machineGeometryInfo?.armLength
              : previousData.detailInfo?.machineGeometryInfo?.armLength !== null
              ? 0.0
              : null,

          armTopPinToBucketSideLinkPinLength:
            updateValues.detailInfo.machineGeometryInfo
              ?.armTopPinToBucketSideLinkPinLength !== null
              ? updateValues.detailInfo.machineGeometryInfo
                  ?.armTopPinToBucketSideLinkPinLength
              : previousData.detailInfo?.machineGeometryInfo
                  ?.armTopPinToBucketSideLinkPinLength !== null
              ? 0.0
              : null,

          boomTopPinToBucketSideLinkPinLength:
            updateValues.detailInfo.machineGeometryInfo
              ?.boomTopPinToBucketSideLinkPinLength !== null
              ? updateValues.detailInfo.machineGeometryInfo
                  ?.boomTopPinToBucketSideLinkPinLength
              : previousData.detailInfo?.machineGeometryInfo
                  ?.boomTopPinToBucketSideLinkPinLength !== null
              ? 0.0
              : null,

          bucketLink1Length:
            updateValues.detailInfo.machineGeometryInfo?.bucketLink1Length !==
            null
              ? updateValues.detailInfo.machineGeometryInfo?.bucketLink1Length
              : previousData.detailInfo?.machineGeometryInfo
                  ?.bucketLink1Length !== null
              ? 0.0
              : null,

          bucketLink2Length:
            updateValues.detailInfo.machineGeometryInfo?.bucketLink2Length !==
            null
              ? updateValues.detailInfo.machineGeometryInfo?.bucketLink2Length
              : previousData.detailInfo?.machineGeometryInfo
                  ?.bucketLink2Length !== null
              ? 0.0
              : null,

          bodyCenterToBoomFootPin: {
            x:
              updateValues.detailInfo.machineGeometryInfo
                ?.bodyCenterToBoomFootPin?.x !== null
                ? updateValues.detailInfo.machineGeometryInfo
                    ?.bodyCenterToBoomFootPin?.x
                : previousData.detailInfo?.machineGeometryInfo
                    ?.bodyCenterToBoomFootPin?.x !== null
                ? 0.0
                : null,

            y:
              updateValues.detailInfo.machineGeometryInfo
                ?.bodyCenterToBoomFootPin?.y !== null
                ? updateValues.detailInfo.machineGeometryInfo
                    ?.bodyCenterToBoomFootPin?.y
                : previousData.detailInfo?.machineGeometryInfo
                    ?.bodyCenterToBoomFootPin?.y !== null
                ? 0.0
                : null,

            z:
              updateValues.detailInfo.machineGeometryInfo
                ?.bodyCenterToBoomFootPin?.z !== null
                ? updateValues.detailInfo.machineGeometryInfo
                    ?.bodyCenterToBoomFootPin?.z
                : previousData.detailInfo?.machineGeometryInfo
                    ?.bodyCenterToBoomFootPin?.z !== null
                ? 0.0
                : null,
          },

          drumWidth:
            updateValues.detailInfo.machineGeometryInfo?.drumWidth !== null
              ? updateValues.detailInfo.machineGeometryInfo?.drumWidth
              : previousData.detailInfo?.machineGeometryInfo?.drumWidth !== null
              ? 0.0
              : null,

          length:
            updateValues.detailInfo.machineGeometryInfo?.length !== null
              ? updateValues.detailInfo.machineGeometryInfo?.length
              : previousData.detailInfo?.machineGeometryInfo?.length !== null
              ? 0.0
              : null,

          shoeInterval:
            updateValues.detailInfo.machineGeometryInfo?.shoeInterval !== null
              ? updateValues.detailInfo.machineGeometryInfo?.shoeInterval
              : previousData.detailInfo?.machineGeometryInfo?.shoeInterval !==
                null
              ? 0.0
              : null,

          bladeWidthForDozer:
            updateValues.detailInfo.machineGeometryInfo?.bladeWidthForDozer !==
            null
              ? updateValues.detailInfo.machineGeometryInfo?.bladeWidthForDozer
              : previousData.detailInfo?.machineGeometryInfo
                  ?.bladeWidthForDozer !== null
              ? 0.0
              : null,

          distanceFromBodyToBlade:
            updateValues.detailInfo.machineGeometryInfo
              ?.distanceFromBodyToBlade !== null
              ? updateValues.detailInfo.machineGeometryInfo
                  ?.distanceFromBodyToBlade
              : previousData.detailInfo?.machineGeometryInfo
                  ?.distanceFromBodyToBlade !== null
              ? 0.0
              : null,

          frontToMainGnssAntenna:
            updateValues.detailInfo.machineGeometryInfo
              ?.frontToMainGnssAntenna !== null
              ? updateValues.detailInfo.machineGeometryInfo
                  ?.frontToMainGnssAntenna
              : previousData.detailInfo?.machineGeometryInfo
                  ?.frontToMainGnssAntenna !== null
              ? 0.0
              : null,

          frontToSubGnssAntenna:
            updateValues.detailInfo.machineGeometryInfo
              ?.frontToSubGnssAntenna !== null
              ? updateValues.detailInfo.machineGeometryInfo
                  ?.frontToSubGnssAntenna
              : previousData.detailInfo?.machineGeometryInfo
                  ?.frontToSubGnssAntenna !== null
              ? 0.0
              : null,

          centerToMainGnssAntenna:
            updateValues.detailInfo.machineGeometryInfo
              ?.centerToMainGnssAntenna !== null
              ? updateValues.detailInfo.machineGeometryInfo
                  ?.centerToMainGnssAntenna
              : previousData.detailInfo?.machineGeometryInfo
                  ?.centerToMainGnssAntenna !== null
              ? 0.0
              : null,

          centerToSubGnssAntenna:
            updateValues.detailInfo.machineGeometryInfo
              ?.centerToSubGnssAntenna !== null
              ? updateValues.detailInfo.machineGeometryInfo
                  ?.centerToSubGnssAntenna
              : previousData.detailInfo?.machineGeometryInfo
                  ?.centerToSubGnssAntenna !== null
              ? 0.0
              : null,

          groundToMainGnssAntenna:
            updateValues.detailInfo.machineGeometryInfo
              ?.groundToMainGnssAntenna !== null
              ? updateValues.detailInfo.machineGeometryInfo
                  ?.groundToMainGnssAntenna
              : previousData.detailInfo?.machineGeometryInfo
                  ?.groundToMainGnssAntenna !== null
              ? 0.0
              : null,

          groundToSubGnssAntenna:
            updateValues.detailInfo.machineGeometryInfo
              ?.groundToSubGnssAntenna !== null
              ? updateValues.detailInfo.machineGeometryInfo
                  ?.groundToSubGnssAntenna
              : previousData.detailInfo?.machineGeometryInfo
                  ?.groundToSubGnssAntenna !== null
              ? 0.0
              : null,
        },
        //* * imuMountInfo */
        imuMountInfo: {
          boomImuOffsetAngle:
            updateValues.detailInfo.imuMountInfo?.boomImuOffsetAngle !== null
              ? updateValues.detailInfo.imuMountInfo?.boomImuOffsetAngle
              : previousData.detailInfo?.imuMountInfo?.boomImuOffsetAngle !==
                null
              ? 0.0
              : null,

          secondBoomImuOffsetAngle:
            updateValues.detailInfo.imuMountInfo?.secondBoomImuOffsetAngle !==
            null
              ? updateValues.detailInfo.imuMountInfo?.secondBoomImuOffsetAngle
              : previousData.detailInfo?.imuMountInfo
                  ?.secondBoomImuOffsetAngle !== null
              ? 0.0
              : null,

          armImuOffsetAngle:
            updateValues.detailInfo.imuMountInfo?.armImuOffsetAngle !== null
              ? updateValues.detailInfo.imuMountInfo?.armImuOffsetAngle
              : previousData.detailInfo?.imuMountInfo?.armImuOffsetAngle !==
                null
              ? 0.0
              : null,

          bucketImuOffsetAngle:
            updateValues.detailInfo.imuMountInfo?.bucketImuOffsetAngle !== null
              ? updateValues.detailInfo.imuMountInfo?.bucketImuOffsetAngle
              : previousData.detailInfo?.imuMountInfo?.bucketImuOffsetAngle !==
                null
              ? 0.0
              : null,

          bodyImuOffsetRollAngle:
            updateValues.detailInfo.imuMountInfo?.bodyImuOffsetRollAngle !==
            null
              ? updateValues.detailInfo.imuMountInfo?.bodyImuOffsetRollAngle
              : previousData.detailInfo?.imuMountInfo
                  ?.bodyImuOffsetRollAngle !== null
              ? 0.0
              : null,

          bodyImuOffsetPitchAngle:
            updateValues.detailInfo.imuMountInfo?.bodyImuOffsetPitchAngle !==
            null
              ? updateValues.detailInfo.imuMountInfo?.bodyImuOffsetPitchAngle
              : previousData.detailInfo?.imuMountInfo
                  ?.bodyImuOffsetPitchAngle !== null
              ? 0.0
              : null,
        },
        //* * gnssMountInfo */
        gnssMountInfo: {
          main: {
            bodyCenterToGnss: {
              x:
                updateValues.detailInfo.gnssMountInfo?.main?.bodyCenterToGnss
                  ?.x !== null
                  ? updateValues.detailInfo.gnssMountInfo?.main
                      ?.bodyCenterToGnss?.x
                  : previousData.detailInfo?.gnssMountInfo?.main
                      ?.bodyCenterToGnss?.x !== null
                  ? 0.0
                  : null,

              y:
                updateValues.detailInfo.gnssMountInfo?.main?.bodyCenterToGnss
                  ?.y !== null
                  ? updateValues.detailInfo.gnssMountInfo?.main
                      ?.bodyCenterToGnss?.y
                  : previousData.detailInfo?.gnssMountInfo?.main
                      ?.bodyCenterToGnss?.y !== null
                  ? 0.0
                  : null,

              z:
                updateValues.detailInfo.gnssMountInfo?.main?.bodyCenterToGnss
                  ?.z !== null
                  ? updateValues.detailInfo.gnssMountInfo?.main
                      ?.bodyCenterToGnss?.z
                  : previousData.detailInfo?.gnssMountInfo?.main
                      ?.bodyCenterToGnss?.z !== null
                  ? 0.0
                  : null,
            },
          },
          sub: {
            bodyCenterToGnss: {
              x:
                updateValues.detailInfo.gnssMountInfo?.sub?.bodyCenterToGnss
                  ?.x !== null
                  ? updateValues.detailInfo.gnssMountInfo?.sub?.bodyCenterToGnss
                      ?.x
                  : previousData.detailInfo?.gnssMountInfo?.sub
                      ?.bodyCenterToGnss?.x !== null
                  ? 0.0
                  : null,

              y:
                updateValues.detailInfo.gnssMountInfo?.sub?.bodyCenterToGnss
                  ?.y !== null
                  ? updateValues.detailInfo.gnssMountInfo?.sub?.bodyCenterToGnss
                      ?.y
                  : previousData.detailInfo?.gnssMountInfo?.sub
                      ?.bodyCenterToGnss?.y !== null
                  ? 0.0
                  : null,

              z:
                updateValues.detailInfo.gnssMountInfo?.sub?.bodyCenterToGnss
                  ?.z !== null
                  ? updateValues.detailInfo.gnssMountInfo?.sub?.bodyCenterToGnss
                      ?.z
                  : previousData.detailInfo?.gnssMountInfo?.sub
                      ?.bodyCenterToGnss?.z !== null
                  ? 0.0
                  : null,
            },
          },
        },
        //* * extendedModel */
        extendedModel: {
          swingBoom,
          twoPieceBoom,
          minimalSwingRadius,
        },
        //* * measuredValues */
        measuredValues: {
          otherLength: {
            machineHeight:
              updateValues.detailInfo.measuredValues?.otherLength
                ?.machineHeight !== null
                ? updateValues.detailInfo.measuredValues?.otherLength
                    ?.machineHeight
                : previousData.detailInfo?.measuredValues?.otherLength
                    ?.machineHeight !== null
                ? 0.0
                : null,
          },
          bodyImu1: {
            roll:
              updateValues.detailInfo.measuredValues?.bodyImu1?.roll !== null
                ? updateValues.detailInfo.measuredValues?.bodyImu1?.roll
                : previousData.detailInfo?.measuredValues?.bodyImu1?.roll !==
                  null
                ? 0.0
                : null,
            pitch:
              updateValues.detailInfo.measuredValues?.bodyImu1?.pitch !== null
                ? updateValues.detailInfo.measuredValues?.bodyImu1?.pitch
                : previousData.detailInfo?.measuredValues?.bodyImu1?.pitch !==
                  null
                ? 0.0
                : null,
            yaw:
              updateValues.detailInfo.measuredValues?.bodyImu1?.yaw !== null
                ? updateValues.detailInfo.measuredValues?.bodyImu1?.yaw
                : previousData.detailInfo?.measuredValues?.bodyImu1?.yaw !==
                  null
                ? 0.0
                : null,
          },
          bodyImu2: {
            roll:
              updateValues.detailInfo.measuredValues?.bodyImu2?.roll !== null
                ? updateValues.detailInfo.measuredValues?.bodyImu2?.roll
                : previousData.detailInfo?.measuredValues?.bodyImu2?.roll !==
                  null
                ? 0.0
                : null,
            pitch:
              updateValues.detailInfo.measuredValues?.bodyImu2?.pitch !== null
                ? updateValues.detailInfo.measuredValues?.bodyImu2?.pitch
                : previousData.detailInfo?.measuredValues?.bodyImu2?.pitch !==
                  null
                ? 0.0
                : null,
            yaw:
              updateValues.detailInfo.measuredValues?.bodyImu2?.yaw !== null
                ? updateValues.detailInfo.measuredValues?.bodyImu2?.yaw
                : previousData.detailInfo?.measuredValues?.bodyImu2?.yaw !==
                  null
                ? 0.0
                : null,
          },
          frontLeft: {
            x:
              updateValues.detailInfo.measuredValues?.frontLeft?.x !== null
                ? updateValues.detailInfo.measuredValues?.frontLeft?.x
                : previousData.detailInfo?.measuredValues?.frontLeft?.x !== null
                ? 0.0
                : null,
            y:
              updateValues.detailInfo.measuredValues?.frontLeft?.y !== null
                ? updateValues.detailInfo.measuredValues?.frontLeft?.y
                : previousData.detailInfo?.measuredValues?.frontLeft?.y !== null
                ? 0.0
                : null,
            z:
              updateValues.detailInfo.measuredValues?.frontLeft?.z !== null
                ? updateValues.detailInfo.measuredValues?.frontLeft?.z
                : previousData.detailInfo?.measuredValues?.frontLeft?.z !== null
                ? 0.0
                : null,
          },
          frontRight: {
            x:
              updateValues.detailInfo.measuredValues?.frontRight?.x !== null
                ? updateValues.detailInfo.measuredValues?.frontRight?.x
                : previousData.detailInfo?.measuredValues?.frontRight?.x !==
                  null
                ? 0.0
                : null,
            y:
              updateValues.detailInfo.measuredValues?.frontRight?.y !== null
                ? updateValues.detailInfo.measuredValues?.frontRight?.y
                : previousData.detailInfo?.measuredValues?.frontRight?.y !==
                  null
                ? 0.0
                : null,
            z:
              updateValues.detailInfo.measuredValues?.frontRight?.z !== null
                ? updateValues.detailInfo.measuredValues?.frontRight?.z
                : previousData.detailInfo?.measuredValues?.frontRight?.z !==
                  null
                ? 0.0
                : null,
          },
          frontGroundingEnd: {
            x:
              updateValues.detailInfo.measuredValues?.frontGroundingEnd?.x !==
              null
                ? updateValues.detailInfo.measuredValues?.frontGroundingEnd?.x
                : previousData.detailInfo?.measuredValues?.frontGroundingEnd
                    ?.x !== null
                ? 0.0
                : null,
            y:
              updateValues.detailInfo.measuredValues?.frontGroundingEnd?.y !==
              null
                ? updateValues.detailInfo.measuredValues?.frontGroundingEnd?.y
                : previousData.detailInfo?.measuredValues?.frontGroundingEnd
                    ?.y !== null
                ? 0.0
                : null,
            z:
              updateValues.detailInfo.measuredValues?.frontGroundingEnd?.z !==
              null
                ? updateValues.detailInfo.measuredValues?.frontGroundingEnd?.z
                : previousData.detailInfo?.measuredValues?.frontGroundingEnd
                    ?.z !== null
                ? 0.0
                : null,
          },
          rearGroundingEnd: {
            x:
              updateValues.detailInfo.measuredValues?.rearGroundingEnd?.x !==
              null
                ? updateValues.detailInfo.measuredValues?.rearGroundingEnd?.x
                : previousData.detailInfo?.measuredValues?.rearGroundingEnd
                    ?.x !== null
                ? 0.0
                : null,
            y:
              updateValues.detailInfo.measuredValues?.rearGroundingEnd?.y !==
              null
                ? updateValues.detailInfo.measuredValues?.rearGroundingEnd?.y
                : previousData.detailInfo?.measuredValues?.rearGroundingEnd
                    ?.y !== null
                ? 0.0
                : null,
            z:
              updateValues.detailInfo.measuredValues?.rearGroundingEnd?.z !==
              null
                ? updateValues.detailInfo.measuredValues?.rearGroundingEnd?.z
                : previousData.detailInfo?.measuredValues?.rearGroundingEnd
                    ?.z !== null
                ? 0.0
                : null,
          },
          mainGnssAntenna: {
            x:
              updateValues.detailInfo.measuredValues?.mainGnssAntenna?.x !==
              null
                ? updateValues.detailInfo.measuredValues?.mainGnssAntenna?.x
                : previousData.detailInfo?.measuredValues?.mainGnssAntenna
                    ?.x !== null
                ? 0.0
                : null,
            y:
              updateValues.detailInfo.measuredValues?.mainGnssAntenna?.y !==
              null
                ? updateValues.detailInfo.measuredValues?.mainGnssAntenna?.y
                : previousData.detailInfo?.measuredValues?.mainGnssAntenna
                    ?.y !== null
                ? 0.0
                : null,
            z:
              updateValues.detailInfo.measuredValues?.mainGnssAntenna?.z !==
              null
                ? updateValues.detailInfo.measuredValues?.mainGnssAntenna?.z
                : previousData.detailInfo?.measuredValues?.mainGnssAntenna
                    ?.z !== null
                ? 0.0
                : null,
          },
          subGnssAntenna: {
            x:
              updateValues.detailInfo.measuredValues?.subGnssAntenna?.x !== null
                ? updateValues.detailInfo.measuredValues?.subGnssAntenna?.x
                : previousData.detailInfo?.measuredValues?.subGnssAntenna?.x !==
                  null
                ? 0.0
                : null,
            y:
              updateValues.detailInfo.measuredValues?.subGnssAntenna?.y !== null
                ? updateValues.detailInfo.measuredValues?.subGnssAntenna?.y
                : previousData.detailInfo?.measuredValues?.subGnssAntenna?.y !==
                  null
                ? 0.0
                : null,
            z:
              updateValues.detailInfo.measuredValues?.subGnssAntenna?.z !== null
                ? updateValues.detailInfo.measuredValues?.subGnssAntenna?.z
                : previousData.detailInfo?.measuredValues?.subGnssAntenna?.z !==
                  null
                ? 0.0
                : null,
          },
          prismPoleOffset:
            updateValues.detailInfo.measuredValues?.prismPoleOffset !== null
              ? updateValues.detailInfo.measuredValues?.prismPoleOffset
              : previousData.detailInfo?.measuredValues?.prismPoleOffset !==
                null
              ? 0.0
              : null,
          antennaElectricalCenterHeight:
            updateValues.detailInfo.measuredValues
              ?.antennaElectricalCenterHeight !== null
              ? updateValues.detailInfo.measuredValues
                  ?.antennaElectricalCenterHeight
              : previousData.detailInfo?.measuredValues
                  ?.antennaElectricalCenterHeight !== null
              ? 0.0
              : null,
        },
      },
    };

    const fn = async () => {
      try {
        await dataProvider.update(resource, {
          id: previousData.id,
          data: {
            ...previousData,
            ...newUpdateValue,
          },
          previousData,
        });
        notify('admin.message.success', 'info');
        redirectTo(basePath || '');
      } catch (error) {
        notify(apiErrorHandler(error), 'warning');
      }
    };
    fn();
  };

  return (
    <>
      <CustomSaveButton
        {...props}
        onSave={handleSave}
        disabled={disabled || loading}
      >
        <SaveIcon />
      </CustomSaveButton>
    </>
  );
};

SaveButton.displayName = 'SaveButton';
export default SaveButton;
