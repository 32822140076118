import { createTheme, ThemeOptions } from '@material-ui/core/styles';
import { colors, getCommonTheme } from '.';

const theme: ThemeOptions = {
  overrides: {
    MuiFormLabel: {
      root: {
        color: colors.text.secondaryLight,
        '&$focused': {
          color: undefined,
        },
      },
    },
    MuiFilledInput: {
      root: {
        color: colors.text.secondaryLight,
        fontSize: 15,
        backgroundColor: colors.filterField.color,
        borderRadius: '3px 3px 3px 3px',
        '&:hover': {
          backgroundColor: undefined,
        },
        '&.Mui-focused': {
          backgroundColor: colors.filterField.color,
        },
        '& > svg': {
          color: colors.text.secondaryLight,
        },
      },
      underline: {
        '&:before': {
          transition: undefined,
          borderBottom: undefined,
        },
        '&:after': {
          borderBottom: undefined,
        },
        '&:hover:before': {
          borderBottom: undefined,
        },
      },
    },
    MuiSvgIcon: {
      colorDisabled: {
        color: colors.text.primaryLight,
      },
    },
    MuiButton: {
      root: {
        minWidth: 'auto',
      },
      textPrimary: {
        color: colors.text.primaryLight,
      },
    },
    RaSearchInput: {
      input: {
        borderRadius: 3,
        marginTop: undefined,
      },
    },
    RaFilterFormInput: {
      body: {
        alignItems: 'center',
      },
      spacer: {
        order: 1,
        width: 0,
      },
      hideButton: {
        order: 2,
        color: colors.text.primaryLight,
      },
    },
    RaFilterButton: {
      root: {
        display: 'flex',
        marginLeft: 28,
        padding: 0,
        alignItems: 'center',
      },
    },
    RaFilterForm: {
      form: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        marginTop: 0,
      },
    },
    RaButton: {
      label: {
        padding: 0,
      },
    },
  },
};

export default () => createTheme(getCommonTheme(), theme);
