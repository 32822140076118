import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  required,
  useTranslate,
  useDataProvider,
  useNotify,
  useRefresh,
  useVersion,
  useLoading,
} from 'react-admin';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Dialog, makeStyles, createStyles } from '@material-ui/core';
import { ReactElementLike } from 'prop-types';
import {
  CustomForm,
  NormalButton,
  DoneButton,
  SelectInput,
  RadioButtonGroupInput,
} from '../../..';
import {
  LicenseAgreementData,
  SiteConfigureData,
  SiteData,
  UserConfigureData,
} from '../../../../dataProvider';
import { unitLength, apiErrorHandler, config, logger } from '../../../../utils';
import { SaveIcon, ReturnIcon } from '../../../../assets';
import { DialogOKCancel, Typography } from '../../../atoms';
import MapBackgroundColorPicker from '../../geofences/map/view/MapBackgroundColorPicker';

const dialogWidth = 790;
const dialogHight = 420;

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      fontSize: 18,
      margin: 0,
      padding: 24,
    },
    content: {
      fontSize: 18,
      height: 280 - 80, // 80: header
      '& > div > form > div': {
        '& > div > p': {
          fontWeight: 'bold',
          marginBottom: 20,
        },
      },
    },
    mapColor: {
      display: 'flex',
      marginBottom: 32,
      '& > p': {
        fontSize: 12,
        width: 148,
        alignSelf: 'center',
      },
    },
    toolbarButton: {
      position: 'relative',
      bottom: -26,
    },
    footer: {
      marginTop: 10,
    },
  }),
);

const useGetSiteconfigure = () => {
  const dataProvider = useDataProvider();
  return async (record: SiteData): Promise<SiteConfigureData> => {
    const { id: siteId, unitLength: inUnitLength } = record;
    const { data } = await dataProvider.getList<SiteConfigureData>(
      'siteConfigures',
      {
        pagination: {
          page: 1,
          perPage: 1,
        },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          siteId,
        },
      },
    );
    const [siteconfigureData] = data;
    // デフォルト単位を設定する際は、ダミーIDを設定して返却する
    const results = siteconfigureData || {
      id: undefined,
      siteId,
      unitLength: inUnitLength,
    };
    logger().debug('useGetSiteconfigure', results);
    return results;
  };
};

interface FormProps {
  disabled: boolean;
  record: SiteConfigureData;
  form: any;
}

const SaveButton: React.FC<
  {
    resource: string;
    label: string;
    onSaved: () => void;
    onChangeMapColor: (userConfigure: UserConfigureData) => void;
    siteId: string;
    mapColor?: string;
    registerUserConfigure?: any;
    mapColorChanged: boolean;
  } & Partial<FormProps>
> = ({
  disabled,
  resource,
  record,
  form,
  label,
  onSaved,
  onChangeMapColor,
  siteId,
  registerUserConfigure,
  mapColorChanged,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const loading = useLoading();
  const dataProvider = useDataProvider();
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    message?: string;
  }>({ open: false });

  const handleSave = useCallback(() => {
    if (!record && !registerUserConfigure) return;
    const siteConfigureChanged = !!(
      form.getFieldState('unitLength').value !== record?.unitLength ||
      form.getFieldState('enableGeofence').value !== record?.enableGeofence
    );

    // API呼び出し処理
    const fn = async () => {
      try {
        // 現場設定に変更があった場合
        if (siteConfigureChanged) {
          await dataProvider.create(resource, {
            data: {
              siteId,
              unitLength: form.getFieldState('unitLength').value,
              enableGeofence: form.getFieldState('enableGeofence')
                ? form.getFieldState('enableGeofence').value
                : false,
            },
          });
        }
        // 共通設定に変更があった場合
        if (mapColorChanged) {
          const { data: userConfigure } =
            await dataProvider.create<UserConfigureData>('userConfigures', {
              data: {
                userId: registerUserConfigure.userId,
                email: registerUserConfigure.email,
                mapBackgroundColor: registerUserConfigure?.mapBackgroundColor,
              },
            });
          onChangeMapColor(userConfigure);
        }
        notify('admin.message.success', 'info');
        refresh();
      } catch (error) {
        notify(apiErrorHandler(error), 'warning');
      } finally {
        onSaved();
      }
    };
    fn();
  }, [
    resource,
    record,
    form,
    onSaved,
    dataProvider,
    notify,
    refresh,
    siteId,
    onChangeMapColor,
    registerUserConfigure,
    mapColorChanged,
  ]);

  const handleClick = useCallback(() => {
    if (!record) return;
    // ジオフェンス機能ON判定
    if (
      !record.enableGeofence &&
      form.getFieldState('enableGeofence')?.value === true
    ) {
      setDialogState({
        open: true,
        message: 'admin.dialog.siteConfigureEdit.message',
      });
      return;
    }
    handleSave();
  }, [record, form, setDialogState, handleSave]);
  return (
    <>
      <DialogOKCancel
        open={dialogState.open}
        onClose={() => setDialogState({ open: false })}
        onOKClick={() => {
          handleSave();
        }}
        title=""
        message="admin.dialog.siteConfigureEdit.message"
      />
      <DoneButton
        label={label}
        disabled={(!!record?.id && disabled && !mapColorChanged) || loading}
        onClick={handleClick}
      >
        <SaveIcon />
      </DoneButton>
    </>
  );
};

// CustomForm の props を伝達するコンポーネント
// また、Button の位置調整は 本コンポーネントで行う
const ToolbarButton: React.FC<{ children?: ReactElementLike }> = ({
  children,
  ...props
}) => {
  const classes = useStyles();
  const button = React.isValidElement(children)
    ? React.cloneElement(children, { ...props })
    : null;
  return <div className={classes.toolbarButton}>{button}</div>;
};

const SiteConfiguresEdit: React.FC<{
  open: boolean;
  userConfigure: UserConfigureData | undefined;
  onClose: () => void;
  onChangeMapColor: (userConfigure: UserConfigureData) => void;
  record?: SiteData;
}> = ({ open, userConfigure, onClose, onChangeMapColor, record }) => {
  if (!record) throw new Error('Invalid record');
  const resource = 'siteConfigures';
  const translate = useTranslate();
  const classes = useStyles();
  const getUnit = useGetSiteconfigure();
  const [fetchedUnit, setFetchedUnit] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [siteConfigureRecord, setSiteConfigureRecord] = useState<
    SiteConfigureData | undefined
  >(undefined);
  const [userId, setUserId] = useState(0);
  const [email, setEmail] = useState('');
  const [preVersion, setPreVersion] = useState(0);
  const { id: siteId } = record;
  const dataProvider = useDataProvider();
  const mapColorBefore = userConfigure
    ? userConfigure.mapBackgroundColor
    : '#ffffff';
  const [mapColorAfter, setMapColorAfter] = useState(mapColorBefore);
  const [mapColorChanged, setMapColorChanged] = useState(false);
  const currentVersion = useVersion();
  useEffect(() => {
    if (preVersion !== currentVersion) {
      setPreVersion(currentVersion);
      setSiteConfigureRecord(undefined); // version変更時に明示的にクリアすることでダイアログ表示時にリストをリロードする
    }
  }, [preVersion, currentVersion]);

  // userId取得
  useEffect(() => {
    if (!userConfigure && userId !== 0) return;
    const api = async () => {
      // ログイン中ユーザーアカウント
      const { data: accountData } = await dataProvider.getOne('accounts', {
        id: 1,
      });
      const { email: userMail } = accountData;

      const { data: licenseAgreements } =
        await dataProvider.getList<LicenseAgreementData>('licenseAgreements', {
          pagination: {
            page: 1,
            perPage: 1, // 対象ユーザのデータのみ取得
          },
          sort: {
            field: 'id',
            order: 'DESC',
          },
          filter: { email: userMail },
        });
      const { id } = licenseAgreements[0];
      setUserId(id);
      setEmail(userMail);
    };
    api();
  }, [userConfigure, dataProvider, userId]);

  const handleDialogEnter = useCallback(() => {
    const dialogElement = ref?.current?.parentElement?.parentElement;
    if (!dialogElement) return;
    dialogElement.style.minWidth = `${dialogWidth}px`;
    dialogElement.style.minHeight = `${dialogHight}px`;
  }, [ref]);
  useEffect(() => {
    if (siteConfigureRecord === undefined && !fetchedUnit) {
      setFetchedUnit(true);
      getUnit(record).then((result: SiteConfigureData) => {
        setSiteConfigureRecord(result);
      });
    }
  }, [record, getUnit, siteConfigureRecord, fetchedUnit]);

  // カラーパレットで色を選択したときに保存ボタンを活性化する
  // 変更がない場合は保存ボタンは非活性状態を維持
  const handleChangeComplete = useCallback(
    async (color: any) => {
      setMapColorAfter(color.hex);
      if (mapColorBefore !== color.hex) {
        setMapColorChanged(true);
      } else {
        setMapColorChanged(false);
      }
    },
    [mapColorBefore],
  );

  // キャンセルボタン・ダイアログ枠外をクリックなど保存操作がない場合は地図背景色を変更前に戻す
  const resetColorInfo = useCallback(() => {
    setMapColorAfter(mapColorBefore);
    setMapColorChanged(false);
    onClose();
  }, [mapColorBefore, onClose]);

  const registerUserConfigure = {
    userId: userConfigure ? userConfigure.userId : userId,
    email: userConfigure ? userConfigure.email : email,
    mapBackgroundColor: mapColorAfter,
  };

  return (
    <Dialog open={open} onClose={resetColorInfo} onEnter={handleDialogEnter}>
      <MuiDialogTitle>
        {translate('admin.dialog.siteConfigureEdit.title.commonSetting')}
      </MuiDialogTitle>
      <MuiDialogContent className={classes.content}>
        <div ref={ref}>
          {open ? (
            <CustomForm
              resource={resource}
              record={siteConfigureRecord}
              bottomToolbar={true}
              deleteButton={false}
              cancelButton={
                <ToolbarButton>
                  <NormalButton
                    onClick={resetColorInfo}
                    label="admin.actions.cancel"
                  >
                    <ReturnIcon />
                  </NormalButton>
                </ToolbarButton>
              }
              saveButton={
                <ToolbarButton>
                  <SaveButton
                    resource={resource}
                    onSaved={onClose}
                    onChangeMapColor={onChangeMapColor}
                    label="admin.actions.save"
                    siteId={siteId}
                    mapColor={mapColorAfter}
                    registerUserConfigure={registerUserConfigure}
                    mapColorChanged={mapColorChanged}
                  />
                </ToolbarButton>
              }
            >
              <div className={classes.mapColor}>
                <Typography>
                  {translate(
                    'admin.dialog.siteConfigureEdit.mapBackgroundColor',
                  )}
                </Typography>
                <MapBackgroundColorPicker
                  mapColor={mapColorAfter}
                  onHandleChangeComplete={handleChangeComplete}
                />
              </div>
              <Typography>
                {translate('admin.dialog.siteConfigureEdit.title.siteSetting')}
              </Typography>
              <SelectInput
                resource={resource}
                source="unitLength"
                choices={unitLength}
                validate={[required()]}
                resettable={false}
              />
              {config.feature.geofence && (
                <RadioButtonGroupInput
                  resource={resource}
                  source="enableGeofence"
                  label="resources.geofences.name"
                  defaultValue={false}
                  row={true}
                  choices={[
                    {
                      id: true,
                      name: translate('admin.label.siteConfigureEdit.onButton'),
                    },
                    {
                      id: false,
                      name: translate(
                        'admin.label.siteConfigureEdit.offButton',
                      ),
                    },
                  ]}
                />
              )}
            </CustomForm>
          ) : null}
        </div>
      </MuiDialogContent>
    </Dialog>
  );
};

SiteConfiguresEdit.displayName = 'SiteConfiguresEdit';
export default SiteConfiguresEdit;
