import { AdminApiConverter, ApiFilter } from '..';
import { RetrofitErrorData } from '../../types';

const converter: AdminApiConverter<'retrofitErrors', 'retrofitErrors'> = {
  resource: 'retrofitErrors',
  defaultSort: { field: 'notifiedTime', order: 'DESC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'retrofitErrors'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('retrofitId' in filter) apiFilter.retrofitId = filter.retrofitId;
    if ('notifiedTime' in filter) apiFilter.notifiedTime = filter.notifiedTime;
    if ('errorCodes' in filter) apiFilter.errorCodes = filter.errorCodes;
    if ('userCorporationId' in filter)
      apiFilter.userCorporationId = filter.userCorporationId;
    return apiFilter;
  },
  sortFieldToApi: field => {
    if (field === 'userCorporationId')
      throw new Error('Unsupported sort field: userCorporationId');
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map((record): RetrofitErrorData => {
        return {
          id: record.id,
          retrofitId: record.retrofitId,
          notifiedTime: record.notifiedTime,
          errorCodes: record.errorCodes || [],
          userCorporationId: record.userCorporationId || '',
        };
      }),
    ),
  recordToApi: record => {
    return {
      retrofitId: record.retrofitId,
      errorCodes: record.errorCodes,
    };
  },
};

export default converter;
