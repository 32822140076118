import { AdminApiConverter, getRestDataProvider, File } from '..';
import { RetrofitBulkActionData } from '../../types';
import { retry } from '../../../utils';

const converter: AdminApiConverter<
  'retrofitBulkActions',
  'retrofitBulkActions'
> = {
  resource: 'retrofitBulkActions',
  defaultSort: { field: 'execStartDate', order: 'DESC' },
  filterToApi: filter => filter,
  sortFieldToApi: field => {
    if (field === 'uploadFile') throw Error('Invalid sort field');
    if (field === 'resultFile') throw Error('Invalid sort field');
    if (field === 'uploadFileName') return 'retrofitRegistFile.name';
    if (field === 'resultFileName') throw Error('Invalid sort field');
    return field;
  },
  prepareParam: async record => {
    if (!record.uploadFile) return record;
    const { rawFile, title } = record.uploadFile;
    if (!rawFile) return record;
    const dataProvider = getRestDataProvider();
    const data: File = {
      blobFileType: 'RetrofitBulkActions',
      name: title,
      blobFileStatus: undefined as any,
    };
    // ファイルリソースの追加
    const result = await dataProvider.create('files', { data });
    const apiData = result.data as File;
    const { id: blobFileId, url } = apiData;
    if (!blobFileId || !url) throw Error('Invalid file data');

    // ファイルのアップロード
    await fetch(url, {
      method: 'PUT',
      body: rawFile,
      headers: { 'Content-Type': 'application/octet-stream' },
    });

    // 指定されたCSVファイルのステータスが Created でない場合は指定回数リトライを行う
    await retry(
      () =>
        new Promise((resolve, reject) => {
          dataProvider
            .getOne('files', {
              id: blobFileId,
            })
            .then(({ data: fdata }) => {
              if (!fdata) {
                reject(new Error('File data is null')); // NOTE: エラーの場合、このメッセージが出る
                return;
              }
              const fileData = fdata as File;
              if (fileData.blobFileStatus !== 'Created') {
                reject(new Error('FileStatus not created')); // NOTE: エラーの場合、このメッセージが出る
              }
              resolve(true);
            });
        }),
      // NOTE: 以下のパラメータ要調整
      5, // リトライ回数
      3000, // インターバル(ms)
    );

    return {
      ...record,
      uploadFile: {
        ...record.uploadFile,
        id: apiData.id || '',
        src: apiData.url || '',
      },
    };
  },
  recordsFromApi: records => {
    return Promise.resolve(
      records.map((record): RetrofitBulkActionData => {
        if (!record.execStatus)
          throw new Error(
            "Can't get ExecStatus of RetrofitBulkAction from the server.",
          );
        return {
          id: record.id || '',
          execStatus: record.execStatus,
          execStartDate: record.execStartDate || '',
          execEndDate: record.execEndDate || '',
          uploadFile: {
            id: record.retrofitRegistFileId || '',
            src: record.retrofitRegistFile?.url || '',
            title: record.retrofitRegistFile?.name || '',
          },
          resultFile: {
            id: record.retrofitRegistResultFileId || '',
            src: record.retrofitRegistResultFile?.url || '',
            title: record.retrofitRegistResultFile?.name || '',
          },
          uploadFileName: record.retrofitRegistFile?.name || '',
          resultFileName: record.retrofitRegistResultFile?.name || '',
        };
      }),
    );
  },
  recordToApi: record => {
    if (!record.uploadFile.id)
      throw new Error("Upload File Id isn't specified");
    return {
      retrofitRegistFileId: record.uploadFile.id,
    };
  },
  embed: ['retrofitRegistFile', 'retrofitRegistResultFile'],
};

export default converter;
