import { useCallback } from 'react';
import { MapController } from '../map';

const useMapPrimitiveType = (mapController: MapController) => {
  return useCallback(
    (geofenceType: string) => {
      switch (geofenceType) {
        case 'Polygon':
          mapController.setPrimitiveType('polygon');
          break;
        case 'Circle':
          mapController.setPrimitiveType('circle');
          break;
        case 'Line':
        case 'Wall': // Wall も Line で表現する
          mapController.setPrimitiveType('line');
          break;
        case 'Point':
          mapController.setPrimitiveType('point');
          break;
        default:
          console.warn(`Invalid GeofenceType: ${geofenceType}`);
      }
    },
    [mapController],
  );
};

export default useMapPrimitiveType;
