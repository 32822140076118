import { GetOneParams, GetOneResult } from 'react-admin';
import { landlogReadSite, landlogSiteConvert } from '..';

export default async (params: GetOneParams): Promise<GetOneResult> => {
  const { id } = params;
  // APIの実行
  const site = await landlogReadSite(id as string);
  const data = landlogSiteConvert(site);
  return { data };
};
