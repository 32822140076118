import React from 'react';
import {
  required,
  useTranslate,
  useRedirect,
  FormDataConsumer,
} from 'react-admin';
import {
  Create,
  CustomForm,
  BreadcrumbsFiled,
  TextInput,
  SelectInput,
  FileInput,
  FileField,
  LoadingView,
  Loading,
  SiteAutoCompleteInput,
} from '../..';
import { useResource, useGetAccount, useNameValidation } from '../../../hooks';
import { bucketType, getValidSiteStatus } from '../../../utils';
import { BucketData } from '../../../dataProvider/types';

interface UploadFile {
  src: string;
  rawFile?: File;
}

const BucketCreate: React.FC<{ basePath?: string }> = props => {
  const { basePath } = props;
  const redirect = useRedirect();
  const translate = useTranslate();
  const resource = useResource('buckets');
  const { getCorporationId, isLoading } = useGetAccount();
  const corporationId = getCorporationId();

  const fileValidation = (file: UploadFile) => {
    if (!file.rawFile) {
      return null;
    }
    const ext = 'bk3';
    const { name } = file.rawFile;
    if (!name.toLowerCase().match(new RegExp(`\\.(${ext})$`))) {
      return 'admin.validation.invalidBkFile';
    }
    return null;
  };

  const nameValidation = useNameValidation(
    'bucketNames',
    'admin.validation.duplicatedBucketName',
    { corporationId },
  );

  if (isLoading() || corporationId === '') return <Loading />;
  return (
    <LoadingView>
      <Create
        {...props}
        onSuccess={({ data }: { data: BucketData }) => {
          const { id } = data;
          redirect(`${basePath}/${id}`);
        }}
      >
        <CustomForm
          title="admin.pages.bucketCreate"
          resource="buckets"
          initialValues={{ corporationId, uploadedBy: 'Admin' }}
        >
          <BreadcrumbsFiled
            breadcrumbs={['resources.buckets.name']}
            label="ra.action.create"
          />
          <FormDataConsumer>
            {({ ...rest }) => {
              return (
                <SiteAutoCompleteInput
                  {...rest}
                  className="sites"
                  label="resources.sites.name"
                  source="siteId"
                  filter={{ corporationId, status: getValidSiteStatus }}
                  sort={{ field: 'name', order: 'ASC' }}
                  resource={resource}
                />
              );
            }}
          </FormDataConsumer>
          <TextInput
            resource={resource}
            source="name"
            validate={[required(), nameValidation]}
          />
          <SelectInput
            resource={resource}
            source="bucketType"
            choices={bucketType}
            validate={required()}
            optionText="name"
          />
          <FileInput
            resource={resource}
            source="bk3File"
            validate={[required(), fileValidation]}
            accept=".bk3"
            placeholder={
              <p>
                {translate('admin.label.fileInput.plaseholder', {
                  fileTypes: translate('admin.label.fileInput.bucket'),
                })}
              </p>
            }
          >
            <FileField source="src" title="title" />
          </FileInput>
        </CustomForm>
      </Create>
    </LoadingView>
  );
};

BucketCreate.displayName = 'BucketCreate';
export default BucketCreate;
