import { useDataProvider } from 'react-admin';
import { AccountData } from '../dataProvider';

const useAccount = () => {
  const dataProvider = useDataProvider();
  return async () => {
    return dataProvider.getOne<AccountData>('accounts', { id: 1 });
  };
};

export default useAccount;
