import {
  LogLevel,
  LogLevelValue,
  logLevelToLogValue,
  logLevelValueToTagName,
} from './loggerType';
import logPrint from './logPrint';

const print = (
  logTypeValue: LogLevelValue,
  logLevel: LogLevel,
  text: string,
  args?: any,
) => {
  const logLevelValue = logLevelToLogValue[logLevel];
  if (logLevelValue < logTypeValue) {
    return;
  }
  const currentDate = new Date(Date.now()).toISOString();
  const tagName = logLevelValueToTagName[logTypeValue];
  const logFormat = `[${currentDate}] - ${tagName}`;
  const logText = `${logFormat}: ${text}`;
  logPrint(logText, args);
};

let logLevel: LogLevel = 'debug'; // default log level

const logger = () => {
  const self = {
    setLogLevel: (newLogLevel: LogLevel) => {
      logLevel = newLogLevel;
      return self;
    },
    error: (text: string, args?: any) => {
      print(LogLevelValue.error, logLevel, text, args);
      return self;
    },
    warn: (text: string, args?: any) => {
      print(LogLevelValue.warn, logLevel, text, args);
      return self;
    },
    info: (text: string, args?: any) => {
      print(LogLevelValue.info, logLevel, text, args);
      return self;
    },
    debug: (text: string, args?: any) => {
      print(LogLevelValue.debug, logLevel, text, args);
      return self;
    },
  };
  return self;
};

export default logger;
