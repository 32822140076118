import React, { useCallback } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Card,
  CardActionArea,
  CardContent,
} from '@material-ui/core';
import { Identifier, Record, linkToRecord } from 'ra-core';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { colors } from '../../../theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.background.default,
      '& .MuiPaper-rounded': {
        borderRadius: 0,
      },
    },
    card: {
      margin: 12,
      display: 'inline-block',
      verticalAlign: 'top',
      background: colors.paper,
      boxShadow: '0px 3px 6px #00000029',
    },
    cardContent: {
      margin: 12,
      padding: 0,
      '&:last-child': { padding: 0 },
    },
  }),
);

interface Props {
  basePath?: string;
  children?: React.ReactNode;
  className?: string;
  cardClassName?: string;
  cardContentClassName?: string;
  ids?: Identifier[];
  data?: {
    [key: string]: Record;
    [index: number]: Record;
  };
  resource?: string;
  cardClick?: 'edit' | 'show';
}
export type CardgridProps = Props;

const Cardgrid: React.FC<Props> = ({
  basePath,
  children,
  className,
  cardClassName,
  cardContentClassName,
  ids,
  data,
  resource,
  cardClick,
}: Props) => {
  const classes = useStyles();
  const CardBody = ({ id }: { id: Identifier }) => {
    const history = useHistory();
    const handleClick = useCallback(() => {
      if (cardClick === 'edit') {
        history.push(linkToRecord(basePath, id));
      } else {
        history.push(linkToRecord(basePath, id, 'show'));
      }
    }, [history, id]);
    const body = (
      <CardContent
        className={classnames(classes.cardContent, cardContentClassName)}
      >
        {React.Children.map(children, field => {
          if (React.isValidElement(field)) {
            return React.cloneElement(field, {
              record: data && data[id],
              basePath: field.props.basePath || basePath,
              resource,
            });
          }
          if (typeof field === 'string') {
            return field;
          }
          return null;
        })}
      </CardContent>
    );

    return cardClick ? (
      <CardActionArea onClick={handleClick}>{body}</CardActionArea>
    ) : (
      <>{body}</>
    );
  };

  return (
    <div className={classnames(classes.root, className)}>
      {ids &&
        ids.map(id => (
          <Card key={id} className={classnames(classes.card, cardClassName)}>
            <CardBody id={id} />
          </Card>
        ))}
    </div>
  );
};

Cardgrid.displayName = 'Cardgrid';
export default Cardgrid;
