import { useDataProvider } from 'react-admin';
import { GnssSettingData } from '../../../../dataProvider';

const useGetNtripInfoDisable = (corporationId: string) => {
  const dataProvider = useDataProvider();
  return async (): Promise<boolean> => {
    const { data } = await dataProvider.getList<GnssSettingData>(
      'gnssSettings',
      {
        filter: {
          ownerCorporationId: corporationId,
        },
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'ASC' },
      },
    );
    return data.length <= 0;
  };
};

export default useGetNtripInfoDisable;
