import React from 'react';
import { Typography as MuiTypography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import { sanitizeMaterialUiProps } from '../../../utils';

const Typography: React.FC<TypographyProps> = props => {
  return <MuiTypography {...sanitizeMaterialUiProps(props)} />;
};

Typography.displayName = 'Typography';

export default Typography;
