import { MapProps } from '../../../../../hooks';

const removeProjectSourceLayer = ({ map }: MapProps) =>
  map
    .getLayers()
    .getArray()
    .filter(
      layer =>
        layer.getClassName() === 'mapserverFaceLayer' ||
        layer.getClassName() === 'mapserverLineworkLayer' ||
        layer.getClassName() === 'mapserverLabelPointkLayer' ||
        layer.getClassName() === 'mapserverPointLabelkLayer' ||
        layer.getClassName() === 'mapserverLocationPointLayer' ||
        layer.getClassName() === 'mapserverLocationLabelLayer',
    )
    .forEach(layer => map.removeLayer(layer));

export default removeProjectSourceLayer;
