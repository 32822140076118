import React from 'react';
import { useForm } from 'react-final-form';

const FinalFormConsumer: React.FC<
  Partial<{
    children: (params: { form: any }) => React.ReactNode;
  }>
> = ({ children, ...rest }) => {
  const form = useForm();
  return <>{children ? children({ form, ...rest }) : null}</>;
};

FinalFormConsumer.displayName = 'FinalFormConsumer';
export default FinalFormConsumer;
