import { Map } from 'ol';
import { Draw, Modify, Select, Translate } from 'ol/interaction';
import {
  Interactor,
  Interactions,
  InteractionState,
  PrimitiveType,
} from './types';
import {
  toInteractionArray,
  dispatchDrawInteraction,
  dispatchModifyInteraction,
} from './utils';

const createInteractor = (): Interactor => {
  let interactions: Interactions;
  let map: Map;
  let select: Select;
  let isDrawed = false;
  let primitiveType: PrimitiveType = 'polygon';
  let prevState: InteractionState;

  const self: Interactor = {
    setup: (_interactions: Interactions, _map: Map) => {
      interactions = _interactions;
      select = _interactions.select;
      map = _map;
      self.changeState('none');
    },
    isDrawed: (): boolean => isDrawed,
    drawClear: () => {
      isDrawed = false;
    },
    setPrimitiveType: (type: PrimitiveType) => {
      primitiveType = type;
      if (prevState === 'draw') {
        self.changeState('draw');
      }
    },
    selectFeature: (feature: any) => {
      select.getFeatures().clear();
      select.getFeatures().push(feature);
    },
    changeState: (state: InteractionState) => {
      // FIXME: ModifyとDraw、Translateのinteractionが何故か2重登録されるので削除する対応
      const deleteTarget: any[] = [];
      map.getInteractions().forEach(it => {
        if (
          it instanceof Draw ||
          it instanceof Modify ||
          it instanceof Translate ||
          it instanceof Select
        ) {
          deleteTarget.push(it);
        }
      });
      deleteTarget.forEach(it => map.removeInteraction(it));
      // All remove interaction
      toInteractionArray(interactions).forEach(it => map.removeInteraction(it));
      switch (state) {
        case 'none':
          // No interaction
          break;
        case 'draw':
          map.addInteraction(
            dispatchDrawInteraction(interactions, primitiveType),
          );
          break;
        case 'modify':
          isDrawed = true;
          map.addInteraction(
            dispatchModifyInteraction(interactions, primitiveType),
          );
          break;
        case 'move':
          map.addInteraction(interactions.select);
          map.addInteraction(interactions.translation);
          break;
        case 'select':
          map.addInteraction(interactions.select);
          break;
        default:
      }
      prevState = state;
    },
    getState: () => {
      // 変数名はprevStateだが、実動作的にはchangeSateで設定されたstateの値のため問題なし
      return prevState;
    },
  };
  return self;
};

export default createInteractor;
