import { RetrofitOwnerChangeCorporationData } from '../../types';

const retrofitOwnerChangeCorporations: RetrofitOwnerChangeCorporationData[] = [
  {
    id: 1,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
  },
];

export default retrofitOwnerChangeCorporations;
