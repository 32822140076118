import { ExtensionarmData } from '../../types';

const extensionarms: ExtensionarmData[] = [
  {
    id: 1,
    extensionarmType: 'A',
    name: 'TEST-EXTENSIONARM',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    extensionarmFileId: '99072e43-44ff-40a6-81b2-fcf5d2e90827',
    lastUpdated: '2020-04-01T01:02:03.400Z',
    extensionarmFile: {
      src: 'http://example.com/dummy.json',
      title: 'extensionarm.json',
    },
  },
  {
    id: 2,
    extensionarmType: 'B',
    name: 'TEST-EXTENSIONARM2',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    extensionarmFileId: '99072e43-44ff-40a6-81b2-fcf5d2e90827',
    lastUpdated: '2020-04-02T01:02:03.400Z',
  },
  {
    id: 3,
    extensionarmType: 'A',
    name: 'TEST-EXTENSIONARM3',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    extensionarmFileId: '99072e43-44ff-40a6-81b2-fcf5d2e90827',
    lastUpdated: '2020-04-03T01:02:03.400Z',
    extensionarmFile: {
      src: 'http://example.com/dummy.json',
      title: 'extensionarm.json',
    },
  },
  {
    id: 4,
    extensionarmType: 'B',
    name: 'TEST-EXTENSIONARM4',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    extensionarmFileId: '99072e43-44ff-40a6-81b2-fcf5d2e90827',
    lastUpdated: '2020-05-03T01:02:03.400Z',
    extensionarmFile: {
      src: 'http://example.com/dummy.json',
      title: 'extensionarm.json',
    },
  },
  {
    id: 5,
    extensionarmType: undefined,
    name: 'TEST-EXTENSIONARM5',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    extensionarmFileId: '99072e43-44ff-40a6-81b2-fcf5d2e90827',
    lastUpdated: '2020-05-04T01:02:03.400Z',
    extensionarmFile: {
      src: 'http://example.com/dummy.json',
      title: 'extensionarm.json',
    },
  },
  {
    id: 6,
    extensionarmType: 'C',
    name: 'TEST-EXTENSIONARM6',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    extensionarmFileId: '99072e43-44ff-40a6-81b2-fcf5d2e90827',
    lastUpdated: '2020-05-04T01:02:03.400Z',
    extensionarmFile: {
      src: 'http://example.com/dummy.json',
      title: 'extensionarm.json',
    },
  },
  {
    id: 7,
    extensionarmType: 'A',
    name: 'TEST-EXTENSIONARM7',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '',
    extensionarmFileId: '99072e43-44ff-40a6-81b2-fcf5d2e90827',
    lastUpdated: '2020-04-03T01:02:03.400Z',
    extensionarmFile: {
      src: 'http://example.com/dummy.json',
      title: 'extensionarm.json',
    },
  },
  {
    id: 8,
    extensionarmType: 'C',
    name: 'TEST-EXTENSIONARM8',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    extensionarmFileId: '99072e43-44ff-40a6-81b2-fcf5d2e90827',
    lastUpdated: '2020-04-03T01:02:03.400Z',
    extensionarmFile: {
      src: 'http://example.com/dummy.json',
      title: 'extensionarm.json',
    },
  },
];
export default extensionarms;
