import { useCustomGetOne } from '.';
import { AccountData } from '../dataProvider';

/**
 * アカウント情報を取得する
 *
 * @example
 *
 * const { getCorporationId } = useGetAccount();
 *
 * // 所属企業IDを取得する
 * const corporationId = getCorporationId();
 */
const useGetAccount = () => {
  const accountId = 1; // TODO: ひとまず 1 固定でよいと
  const resources = 'accounts';

  const { data, loading } = useCustomGetOne(resources, accountId);
  const account: AccountData = data as AccountData;

  return {
    isLoading(): boolean {
      return loading;
    },
    getId(): number {
      // TODO: 暫定対応 エラーとわかりやいよう -1 を返却 ※実際に使用する際に適宜修正してください
      return account ? account.id : -1;
    },
    getName(): string {
      return account ? account.name : '';
    },
    getEmail(): string {
      return account ? account.email : '';
    },
    getCorporationId(): string {
      return account ? account.corporationId : '';
    },
    getCorporationName(): string {
      return account ? account.corporationName : '';
    },
    getAccount(): AccountData | undefined {
      return account || undefined;
    },
  };
};

export default useGetAccount;
