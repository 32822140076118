import { OperatorData } from '../../types';

const operators: OperatorData[] = [
  {
    id: 1,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ０１',
  },
  {
    id: 2,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ０２',
  },
  {
    id: 3,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ０３',
  },
  {
    id: 4,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ０４',
  },
  {
    id: 5,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ０５',
  },
  {
    id: 6,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ０６',
  },
  {
    id: 7,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ０７',
  },
  {
    id: 8,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ０８',
  },
  {
    id: 9,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ０９',
  },
  {
    id: 10,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ１０',
  },
  {
    id: 11,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ１１',
  },
  {
    id: 12,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ１２',
  },
  {
    id: 13,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ１３',
  },
  {
    id: 14,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ１４',
  },
  {
    id: 15,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ１５',
  },
  {
    id: 16,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ１６',
  },
  {
    id: 17,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ１７',
  },
  {
    id: 18,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ１８',
  },
  {
    id: 19,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ１９',
  },
  {
    id: 20,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ２０',
  },
  {
    id: 21,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ２１',
  },
  {
    id: 22,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'オペレータ２２',
  },
  {
    id: 23,
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    name: 'オペレータ０１',
  },
  {
    id: 24,
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    name: 'オペレータ０２',
  },
  {
    id: 25,
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    name: 'オペレータ０３',
  },
  {
    id: 26,
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    name: 'オペレータ０４',
  },
  {
    id: 27,
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    name: 'オペレータ０５',
  },
  {
    id: 28,
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    name: 'オペレータ０６',
  },
  {
    id: 29,
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    name: 'オペレータ０７',
  },
  {
    id: 30,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: 'オペレータ０１',
  },
  {
    id: 31,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: 'オペレータ０２',
  },
  {
    id: 32,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: 'オペレータ０３',
  },
  {
    id: 33,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: 'オペレータ０４',
  },
  {
    id: 34,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: 'オペレータ０５',
  },
  {
    id: 35,
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    name: 'オペレータ０６',
  },
  {
    id: 36,
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    name: 'オペレータ０７',
  },
  {
    id: 37,
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    name: 'オペレータ０１',
  },
  {
    id: 38,
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    name: 'オペレータ０２',
  },
  {
    id: 39,
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    name: 'オペレータ０３',
  },
  {
    id: 40,
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    name: 'オペレータ０４',
  },
  {
    id: 41,
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    name: 'オペレータ０５',
  },
  {
    id: 42,
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    name: 'オペレータ０６',
  },
  {
    id: 43,
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    name: 'オペレータ０７',
  },
];

export default operators;
