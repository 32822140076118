import React, { useState, useCallback } from 'react';
import {
  required,
  useTranslate,
  useDataProvider,
  TextField as RaTextField,
} from 'react-admin';
import { Dialog, makeStyles, createStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { useResource } from '../../../hooks';
import {
  Create,
  CustomForm,
  BreadcrumbsFiled,
  SimpleShowLayout,
  NormalButton,
  TextInput,
  TextField,
  DoneButton,
  FunctionField,
  ReferenceInput,
  SelectInput,
} from '../..';
import { styles, colors } from '../../../theme';
import { ReturnIcon, SaveIcon } from '../../../assets';
import { buttonWidth, buttonHeight } from '../../../theme/define';
import {
  RetrofitBasicInfoData,
  RetrofitDistributorData,
  RetrofitRegionData,
} from '../../../dataProvider';

const useStyles = makeStyles(() =>
  createStyles({
    confirmDialog: {
      maxHeight: '448px',
      width: '600px',
      paddingLeft: '10px',
    },
    confirmFooter: {
      padding: '24px',
    },
    description: {
      ...styles.multilineText,
    },
    confirmDescription: {
      whiteSpace: 'pre-wrap',
    },
    corporationId: {
      ...styles.longWidthText,
    },
    confirm: {
      background: colors.button.save,
      color: colors.text.primaryLight,
      fontSize: 14,
      textTransform: 'none',
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.saveHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.saveDisabled,
      },
      '& span': {
        padding: 0,
      },
    },
  }),
);

const SaveButtonWithConfirm: React.FC<
  Partial<{
    form: any;
    disabled: boolean;
  }>
> = ({ form, disabled }) => {
  const dataProvider = useDataProvider();
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    record?: RetrofitBasicInfoData;
  }>({ open: false });
  const resource = useResource('retrofitBasicInfos');
  const classes = useStyles();
  const translate = useTranslate();

  const handleShow = useCallback(() => {
    const api = async () => {
      const data = form.getState().values;
      const { retrofitDistributorId, retrofitRegionId } = data as {
        retrofitDistributorId: number | undefined;
        retrofitRegionId: number | undefined;
      };
      const retrofitDistributorName = retrofitDistributorId
        ? (
            await dataProvider.getOne<RetrofitDistributorData>(
              'retrofitDistributors',
              {
                id: retrofitDistributorId,
              },
            )
          ).data.name || ''
        : '';

      const retrofitRegionName = retrofitRegionId
        ? (
            await dataProvider.getOne<RetrofitRegionData>('retrofitRegions', {
              id: retrofitRegionId,
            })
          ).data.name || ''
        : '';
      return { ...data, retrofitDistributorName, retrofitRegionName };
    };
    api().then(data => {
      setDialogState({ open: true, record: data });
    });
  }, [form, dataProvider]);

  const handleDialogClose = useCallback(() => {
    setDialogState({ open: false });
  }, []);

  const handleSubmit = useCallback(() => {
    form.submit();
  }, [form]);
  return (
    <>
      <DoneButton
        className={classes.confirm}
        onClick={handleShow}
        disabled={disabled}
        label="admin.actions.confirmation"
        data-testid="admin.actions.confirmation"
      >
        <SaveIcon />
      </DoneButton>
      <Dialog
        open={dialogState.open}
        onClose={handleDialogClose}
        data-testid={'confirm_dialog'}
      >
        <MuiDialogTitle>
          {translate('admin.message.saveConfirmContent')}
        </MuiDialogTitle>
        <MuiDialogContent className={classes.confirmDialog}>
          <SimpleShowLayout record={dialogState.record}>
            <FunctionField
              resource={resource}
              source="uuid"
              label="resources.retrofitBasicInfos.fields.uuid"
              render={() => {
                return translate('admin.label.retrofitBasicInfoCreate.uuid');
              }}
            />
            <RaTextField
              source="retrofitDistributorName"
              label="resources.retrofitBasicInfos.fields.retrofitDistributorId"
            />
            <RaTextField
              source="retrofitRegionName"
              label="resources.retrofitBasicInfos.fields.retrofitRegionId"
            />
            <TextField
              resource={resource}
              source="corporationId"
              label="resources.retrofitBasicInfos.fields.corporationId"
            />
            <TextField
              resource={resource}
              source="corporationName"
              label="resources.retrofitBasicInfos.fields.corporationName"
            />
            <TextField
              resource={resource}
              source="basicInfoManufacturer"
              label="resources.retrofitBasicInfos.fields.basicInfoManufacturer"
            />
            <TextField
              resource={resource}
              source="basicInfoModel"
              label="resources.retrofitBasicInfos.fields.basicInfoModel"
            />
            <TextField
              resource={resource}
              source="basicInfoProductNumber"
              label="resources.retrofitBasicInfos.fields.basicInfoProductNumber"
            />
            <TextField
              resource={resource}
              source="basicInfoSerialNumber"
              label="resources.retrofitBasicInfos.fields.basicInfoSerialNumber"
            />
            <TextField
              resource={resource}
              source="description"
              label="resources.retrofitBasicInfos.fields.description"
              className={classes.confirmDescription}
            />
          </SimpleShowLayout>
        </MuiDialogContent>
        <MuiDialogActions className={classes.confirmFooter}>
          <NormalButton
            onClick={handleDialogClose}
            label="admin.actions.cancel"
          >
            <ReturnIcon />
          </NormalButton>
          <DoneButton onClick={handleSubmit} label="admin.actions.create">
            <SaveIcon />
          </DoneButton>
        </MuiDialogActions>
      </Dialog>
    </>
  );
};

const RetrofitBasicInfoCreate: React.FC = props => {
  const resource = useResource('retrofitBasicInfos');
  const translate = useTranslate();
  const redirect = '/retrofitBasicInfos'; // NOTE: タブがある為、ルート階層に戻るのに必要
  const classes = useStyles();
  return (
    <Create {...props}>
      <CustomForm
        title="admin.pages.retrofitBasicInfoCreate"
        resource={resource}
        redirect={redirect}
        initialValues={{
          siteId: '',
        }}
        saveButton={<SaveButtonWithConfirm />}
      >
        <BreadcrumbsFiled
          breadcrumbs={['resources.retrofitBasicInfos.name']}
          label="ra.action.create"
        />
        <FunctionField
          resource={resource}
          source="uuid"
          label="resources.retrofitBasicInfos.fields.uuid"
          render={() => {
            return translate('admin.label.retrofitBasicInfoCreate.uuid');
          }}
        />
        <ReferenceInput
          resource={resource}
          source="retrofitDistributorId"
          reference="retrofitDistributors"
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          resource={resource}
          source="retrofitRegionId"
          reference="retrofitRegions"
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput
          resource={resource}
          source="corporationId"
          validate={[required()]}
          className={classes.corporationId}
        />
        <TextInput resource={resource} source="corporationName" />
        <TextInput
          resource={resource}
          source="basicInfoManufacturer"
          validate={[required()]}
        />
        <TextInput
          resource={resource}
          source="basicInfoModel"
          validate={[required()]}
        />
        <TextInput
          resource={resource}
          source="basicInfoProductNumber"
          validate={[required()]}
        />
        <TextInput
          resource={resource}
          source="basicInfoSerialNumber"
          validate={[required()]}
        />
        <TextInput
          resource={resource}
          source="description"
          className={classes.description}
          multiline
          rows={3}
        />
      </CustomForm>
    </Create>
  );
};

RetrofitBasicInfoCreate.displayName = 'RetrofitBasicInfoCreate';
export default RetrofitBasicInfoCreate;
