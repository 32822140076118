import { fetchUtils } from 'react-admin';
import { encode } from 'querystring';
import { config } from '../../utils';
import { LandlogCorporations, getOption } from '.';

const uri = `${config.api.landlog.apiUri}/v2/corporations`;

export type LandlogListCorporationsProps = Partial<{
  name: string;
  domain: string;
  label: string;
  page: number;
  offset: number;
  limit: number;
  order_by: 'display_name'; // eslint-disable-line camelcase
  order: 'asc' | 'desc';
}>;

interface ListResponse {
  total: number;
  corporations: LandlogCorporations[];
}

export default async (
  props: LandlogListCorporationsProps,
): Promise<ListResponse> => {
  const params = encode({ ...props });
  const { json } = await fetchUtils.fetchJson(
    `${uri}?${params}`,
    await getOption(),
  );
  return json;
};
