import { DataProvider } from 'react-admin';
import getCustomers from './getCustomers';

export default (
  adminDataProvider: DataProvider,
  landlogDataProvider: DataProvider,
): DataProvider => {
  return {
    getList: (_, params): any => {
      return getCustomers(adminDataProvider, landlogDataProvider, params);
    },
    getOne: (): any => {
      throw new Error('Not supported for customers');
    },
    getMany: () => {
      throw new Error('Not supported for customers');
    },
    getManyReference: () => {
      throw new Error('Not supported for customers');
    },
    update: () => {
      throw new Error('Not supported for customers');
    },
    updateMany: () => {
      throw new Error('Not supported for customers');
    },
    create: () => {
      throw new Error('Not supported for customers');
    },
    delete: () => {
      throw new Error('Not supported for customers');
    },
    deleteMany: () => {
      throw new Error('Not supported for customers');
    },
  };
};
