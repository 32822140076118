import React, {
  ComponentType,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { LayoutComponent } from 'ra-core';
import {
  useRedirect,
  useDataProvider,
  useGetPermissions,
  CoreLayoutProps,
} from 'react-admin';
import { createTheme } from '@material-ui/core/styles';
import {
  CustomAppBar,
  CustomFooter,
  CustomMenu,
  CustomSidebar,
  CustomTitle,
  ErrorBoundary,
  Notification,
  ConsentTerms,
  Loading,
  UserNoticeDrawerList,
  LicensePurchaseNoticeDialog,
} from '..';
import {
  useLayoutStyles,
  useAgreementStore,
  useAccount,
  useGetSupportMode,
  useLoginEmail,
  useGetUserData,
  useIsRetrofitOwnerChangeCorporation,
  useCheckLicensePurchaseDialog,
} from '../../hooks';
import {
  redirectAfterLoginStore,
  countryCodeStore,
  config,
  hasRoleStore,
  manageUrlStore,
  hasRoleFromUserDataByConfigInfo,
  KEY_ROLE_TRANSFER_APPLOVALS,
  KEY_ROLE_RETROFIT_CONTROLLER_REGIST_CREATE,
  KEY_ROLE_RETROFIT_ALTERNATE_REGIST_CREATE,
  lastDialogOpenedTimeStore,
} from '../../utils';
import { tokenStore } from '../../auth';
import {
  fakeDataProvider,
  adminLandlogAccessProvider,
  AccountData,
  CorporationData,
  landlogDataProvider,
} from '../../dataProvider';

const Term: LayoutComponent = ({ logout }) => {
  const classes = useLayoutStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={classes.appFrame}>
        <div className={classes.appBar}>
          <CustomAppBar logout={logout} />
        </div>
        <main className={classes.contentWithSidebar}>
          <div className={classes.contentFrame}>
            <div className={classes.contentContainer}>
              <div className={classes.content}>
                <ConsentTerms />
              </div>
            </div>
            <CustomFooter className={classes.footer} />
          </div>
        </main>
        <Notification
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        />
      </div>
    </div>
  );
};

interface LayoutProps {
  enableChangeRetrofitOwner: boolean;
  hasControllerRegistRole: boolean;
  hasAlternateRegistRole: boolean;
  corporationId: string;
  showDialog: boolean;
}
// LayoutComponentのままだとパラメータが増やせないので、ComponentType<CoreLayoutProps>を使用
const Layout: ComponentType<CoreLayoutProps & LayoutProps> = ({
  children,
  logout,
  enableChangeRetrofitOwner,
  hasControllerRegistRole,
  hasAlternateRegistRole,
  corporationId,
  showDialog,
}) => {
  const classes = useLayoutStyles();
  const { enable: supportMode } = useGetSupportMode();

  // 以下の条件にすべて該当する場合、ログインのタイミングでライセンス紐付け・購入を促すダイアログを出力
  // ログイン時 もしくは 前回ダイアログ出力から24h経過
  // ログインユーザーが日本企業(JA) かつ
  // ライセンスが紐づかない建機が1件以上ある または 現場に紐づく建機が1件以上ある
  const openDialog = countryCodeStore.get() === 'JP' && showDialog;
  const [errorDialogOpen, setErrorDialogOpen] = useState(openDialog);
  const handleHide = useCallback(() => {
    setErrorDialogOpen(false);
    lastDialogOpenedTimeStore.set(); // ダイアログ出力時刻をローカルストレージに保存する
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={classes.appFrame}>
        <div className={classes.appBar}>
          <CustomAppBar logout={logout} supportMode={supportMode} />
        </div>
        <main className={classes.contentWithSidebar}>
          <div className={classes.sidebarContainer}>
            <CustomSidebar className={classes.sidebar}>
              <CustomMenu
                supportMode={supportMode}
                corporationId={corporationId}
                enableChangeRetrofitOwner={enableChangeRetrofitOwner}
                hasControllerRegistRole={hasControllerRegistRole}
                hasAlternateRegistRole={hasAlternateRegistRole}
              />
            </CustomSidebar>
            <div className={classes.sidebarSpacer} />
          </div>
          <UserNoticeDrawerList />
          <div className={classes.contentFrame}>
            <div className={classes.contentContainer}>
              <div className={classes.content}>
                <CustomTitle />
                {children}
              </div>
            </div>
            <CustomFooter className={classes.footer} />
          </div>
        </main>
        <Notification
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        />
        <LicensePurchaseNoticeDialog
          errorDialogOpen={errorDialogOpen}
          onClose={handleHide}
        />
      </div>
    </div>
  );
};

/**
 * 初期表示時に実行するカスタムフック
 *   - 国コードが取得済みであれば何も処理しない
 *   - 国コードが未取得であれば useEffect のロジックを実行する
 */
const useInitial = () => {
  const getAccount = useAccount();
  const dataProvider = useDataProvider();
  const getUserData = useGetUserData();
  const { setEmail } = useLoginEmail();
  const getPermissions = useGetPermissions();
  const isRetrofitOwnerChangeCorporation =
    useIsRetrofitOwnerChangeCorporation();
  const checkLicensePurchaseDialog = useCheckLicensePurchaseDialog();
  const defaulState = {
    loading: true,
    fetched: false,
    userRole: [],
    enableChangeRetrofitOwner: false,
    corporationId: '',
    showDialog: false,
  };
  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    userRole?: string[];
    enableChangeRetrofitOwner?: boolean;
    corporationId?: string;
    showDialog?: boolean;
  }>(defaulState);

  useEffect(() => {
    if (apiState.fetched) return;
    setApiState({ loading: true, fetched: true });

    const accountDataProvider = config.api.admin.useFake
      ? fakeDataProvider
      : landlogDataProvider;
    const apiAccount = async () => {
      // ログインユーザーのアカウント取得
      const { data: account } = await accountDataProvider.getOne<AccountData>(
        'accounts',
        {
          id: 1,
        },
      );
      const { corporationId, email } = account;
      return { corporationId, email };
    };

    // LANDLOGのアクセストークンを AdminAPI に送信する処理
    const apiToken = async (corporationId: string, email: string) => {
      const accessToken = tokenStore.get()?.accessToken || '';
      const refreshToken = tokenStore.get()?.refreshToken || '';
      const { sendAccessToken } = adminLandlogAccessProvider;
      await sendAccessToken(accessToken, refreshToken, corporationId, email);
    };

    const apiUser = async (corporationId: string, email: string) => {
      // アカウントのemailをローカルストレージへ保存
      setEmail(email);

      // ログインユーザーの企業情報を取得
      const {
        data: { countryCode },
      } = await accountDataProvider.getOne<CorporationData>('corporations', {
        id: corporationId,
      });

      // ユーザー情報を取得
      const userData = await getUserData(corporationId, email);
      // 譲渡権限を取得
      const hasTransferRole = hasRoleFromUserDataByConfigInfo(
        userData,
        config.feature.roleTransferApproval,
      );
      // 利用中企業 コントローラー登録権限を取得
      const hasControllerRegistRole = hasRoleFromUserDataByConfigInfo(
        userData,
        config.feature.roleRetrofitControllerRegistCreate,
      );
      // 利用中企業 代理申請登録権限を取得
      const hasAlternateRegistRole = hasRoleFromUserDataByConfigInfo(
        userData,
        config.feature.roleRetrofitAlternateRegistCreate,
      );
      // レトロフィット利用中企業変更可能企業チェック
      const enableChangeRetrofitOwner = await isRetrofitOwnerChangeCorporation(
        corporationId,
      );

      // ユーザーのお知らせ権限を取得
      const permission = await getPermissions();
      const { userRoles } = permission;

      // ユーザーの建機情報を取得
      const showDialog = await checkLicensePurchaseDialog(corporationId);

      return {
        countryCode,
        hasTransferRole,
        hasControllerRegistRole,
        hasAlternateRegistRole,
        enableChangeRetrofitOwner,
        userRoles,
        showDialog,
      };
    };

    // 各API呼び出し
    apiAccount().then(({ corporationId, email }) => {
      apiToken(corporationId, email);
      apiUser(corporationId, email).then(
        ({
          countryCode,
          hasTransferRole,
          hasControllerRegistRole,
          hasAlternateRegistRole,
          enableChangeRetrofitOwner,
          userRoles,
          showDialog,
        }) => {
          countryCodeStore.set(countryCode);
          hasRoleStore.set(KEY_ROLE_TRANSFER_APPLOVALS, email, hasTransferRole);
          hasRoleStore.set(
            KEY_ROLE_RETROFIT_CONTROLLER_REGIST_CREATE,
            email,
            hasControllerRegistRole,
          );
          hasRoleStore.set(
            KEY_ROLE_RETROFIT_ALTERNATE_REGIST_CREATE,
            email,
            hasAlternateRegistRole,
          );
          setApiState({
            loading: false,
            fetched: true,
            userRole: userRoles,
            enableChangeRetrofitOwner,
            corporationId,
            showDialog,
          });
        },
      );
    });
  }, [
    apiState.fetched,
    getAccount,
    getPermissions,
    dataProvider,
    setEmail,
    getUserData,
    isRetrofitOwnerChangeCorporation,
    checkLicensePurchaseDialog,
  ]);

  return {
    loading: apiState.loading,
    userRoles: apiState.userRole,
    enableChangeRetrofitOwner: apiState.enableChangeRetrofitOwner,
    corporationId: apiState.corporationId,
    showDialog: apiState.showDialog,
  };
};

// 権限の判定
const useHasUserRole = () => {
  return (roleName: string, userRoles: string[]): boolean => {
    if (userRoles.includes(roleName)) {
      return true;
    }
    return false;
  };
};

const CustomLayout: LayoutComponent = props => {
  const { theme: themeOverride } = props;
  const themeProp = useRef(themeOverride);
  const [theme, setTheme] = useState(createTheme(themeOverride));
  const [userAgreement, setUserAgreement] = useState<boolean | null>(null);
  const history = useHistory();
  const hasUserRole = useHasUserRole();
  const { getAgreement } = useAgreementStore();
  const { email } = useLoginEmail();
  useEffect(() => {
    if (themeProp.current !== themeOverride) {
      themeProp.current = themeOverride;
      setTheme(createTheme(themeOverride));
    }
  }, [themeOverride, themeProp, theme, setTheme]);
  useEffect(() => {
    const fetch = async () => {
      const result = await getAgreement();
      setUserAgreement(result);
    };
    fetch();
  }, [getAgreement]);

  // URL直指定で開こうとした時に未ログインの場合、ログイン認証後にリダイレクトさせる処理
  const redirect = useRedirect();
  const { enable: supportMode } = useGetSupportMode();
  const redirectTarget = redirectAfterLoginStore.get();
  redirectAfterLoginStore.clear();
  useEffect(() => {
    if (redirectTarget === '/controllerRegistrationAdmin') {
      redirect(redirectTarget);
    }
  }, [redirectTarget, redirect]);

  // URL判定処理
  const currentPathInfo = manageUrlStore.getCurrent().split('/');

  const {
    loading,
    userRoles,
    enableChangeRetrofitOwner,
    corporationId,
    showDialog,
  } = useInitial();
  if (userAgreement === null || userRoles === undefined || loading)
    return <Loading />;

  // ■ お知らせ関連の画面 ユーザー権限の判定
  const userNoticeRole = hasUserRole('UserNotice', userRoles);
  if (currentPathInfo[2] === 'userNotices' && !userNoticeRole) redirect('/');

  // ■ レトロフィット基本情報画面 レトロフィット一括登録画面 ユーザー権限の判定
  const userCreateRetrofitRole = hasUserRole('CreateRetrofit', userRoles);
  if (currentPathInfo[2] === 'retrofitBasicInfos' && !userCreateRetrofitRole)
    redirect('/');
  if (currentPathInfo[2] === 'retrofitBulkActions' && !userCreateRetrofitRole)
    redirect('/');

  // ■ 利用中企業変更 コントローラー登録画面 ユーザー権限の判定
  const hasControllerRegistRole = hasRoleStore.get(
    KEY_ROLE_RETROFIT_CONTROLLER_REGIST_CREATE,
    email,
  );
  if (
    currentPathInfo[2] === 'controllerRegistration' &&
    (supportMode || !hasControllerRegistRole)
  )
    redirect('/');

  // ■ 利用中企業変更 代理申請画面 ユーザー権限の判定
  const hasAlternateRegistRole = hasRoleStore.get(
    KEY_ROLE_RETROFIT_ALTERNATE_REGIST_CREATE,
    email,
  );
  if (
    currentPathInfo[2] === 'retrofitAlternateRegists' &&
    (supportMode || !hasAlternateRegistRole || !enableChangeRetrofitOwner)
  )
    redirect('/');

  // ■ サポートモード画面 ユーザー権限の判定
  const userSupportModeRole = hasUserRole('SupportMode', userRoles);
  if (currentPathInfo[2] === 'supportMode' && !userSupportModeRole)
    redirect('/');

  // ■ 共有ファイル画面 ユーザー権限の判定
  if (
    (currentPathInfo[4] === 'sharefiles' ||
      currentPathInfo[7] === 'sharefiles') &&
    !userSupportModeRole
  )
    redirect('/');

  // 正常に遷移できた場合は、ひとつ前のURL情報として保持
  manageUrlStore.set();

  return (
    <ErrorBoundary history={history}>
      <MuiThemeProvider theme={theme}>
        {!userAgreement ? (
          <Term {...props} />
        ) : (
          <Layout
            enableChangeRetrofitOwner={enableChangeRetrofitOwner || false}
            hasControllerRegistRole={hasControllerRegistRole || false}
            hasAlternateRegistRole={hasAlternateRegistRole || false}
            corporationId={corporationId || ''}
            showDialog={showDialog || false}
            {...props}
          />
        )}
      </MuiThemeProvider>
    </ErrorBoundary>
  );
};

CustomLayout.displayName = 'CustomLayout';
export default CustomLayout;
