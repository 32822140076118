import React, { FC, useCallback, useState } from 'react';
import {
  useDataProvider,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { CustomSaveButton, DialogOKCancel, DialogOKOnly } from '../../../..';
import { SaveIcon } from '../../../../../assets';
import { RetrofitData } from '../../../../../dataProvider';
import { useAccount, useRetrofitLending } from '../../../../../hooks';
import { apiErrorHandler, getParentPath } from '../../../../../utils';
import { useRetrofitCorporationCheck } from '../hooks';

interface Props {
  basePath: string;
  retrofit: RetrofitData;
  basicInfoSerialNumber: string;
  form: any;
}

const SaveButton: FC<Partial<Props>> = ({ basePath, ...props }) => {
  const { retrofit: fromRetrofit, form } = props;
  const dataProvider = useDataProvider();
  const getRetrofitCorporationCheck = useRetrofitCorporationCheck();
  const retrofitLending = useRetrofitLending();
  const translate = useTranslate();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const getAccount = useAccount();
  const [open, setOpen] = useState(false);
  const [errorDialog, setErrorDialog] = useState<{
    open: boolean;
    messages: string[];
  }>({ open: false, messages: [] });

  const handleSubmit = useCallback(() => {
    // create処理
    const fn = async () => {
      if (!fromRetrofit) return;
      const { id: fromRetrofitId } = fromRetrofit;

      // 申請者情報を取得
      const {
        data: {
          corporationId: registrationCorporationtId,
          corporationName: registrationCorporationName,
        },
      } = await getAccount();

      // センドバック先のレトロフィットを取得
      const toRetrofitIdString = form.getFieldState('retrofitId')?.value;
      const toRetrofitId = Number(toRetrofitIdString);
      const notificationEmailAddress = form.getFieldState(
        'notificationEmailAddress',
      )?.value;
      try {
        await dataProvider.create('retrofitSendbacks', {
          data: {
            fromRetrofitId,
            toRetrofitId,
            notificationEmailAddress,
            registrationCorporationtId,
            registrationCorporationName,
          },
        });
        notify('admin.message.success', 'info');
        redirectTo(basePath ? getParentPath(basePath) : '');
      } catch (error) {
        notify(apiErrorHandler(error), 'warning');
      }
    };
    fn();
  }, [
    dataProvider,
    getAccount,
    notify,
    basePath,
    form,
    fromRetrofit,
    redirectTo,
  ]);

  const handleHide = useCallback(() => setOpen(false), [setOpen]);

  const handleSave = () => {
    const api = async () => {
      // センドバック先のレトロフィットを取得
      const toRetrofitIdString = form.getFieldState('retrofitId')?.value;
      const toRetrofitId = Number(toRetrofitIdString);
      const { data: toRetrofit } = await dataProvider.getOne<RetrofitData>(
        'retrofits',
        {
          id: toRetrofitId,
        },
      );
      const { id, siteId, corporationId, retrofitRegionId } = toRetrofit;

      // 登録対象のコントローラのRegion(出荷先)が設定済みの場合
      if (retrofitRegionId !== undefined) {
        setErrorDialog({
          open: true,
          messages: [translate('admin.dialog.retrofitSendBacks.unableRegion')],
        });
        return;
      }

      // 登録対象のコントローラの所有企業IDがレトロフィット利用中企業登録可能企業IDに含まれない場合
      const { isErrorCorporation } = await getRetrofitCorporationCheck({
        corporationId,
      });
      if (isErrorCorporation) {
        setErrorDialog({
          open: true,
          messages: [
            translate(
              'admin.dialog.retrofitSendBacks.unableCorporation.message1',
            ),
            translate(
              'admin.dialog.retrofitSendBacks.unableCorporation.message2',
            ),
          ],
        });
        return; // 処理を中断し、エラーダイアログを出す
      }

      // 登録対象の建機が貸与中の場合
      const retrofitIds = [id]; // retrofitLendingを使用するために型を成型
      const {
        data: { isLending },
      } = await retrofitLending({
        retrofitIds,
      });
      if (isLending) {
        setErrorDialog({
          open: true,
          messages: [
            translate('admin.dialog.retrofitSendBacks.retrofitRentaled'),
          ],
        });
        return; // 処理を中断し、エラーダイアログを出す
      }

      // 登録対象の建機が現場に紐づいている場合
      if (siteId !== '' && siteId !== undefined) {
        setErrorDialog({
          open: true,
          messages: [translate('admin.dialog.retrofitSendBacks.tiedToSite')],
        });
        return; // 処理を中断し、エラーダイアログを出す
      }

      setOpen(true);
    };
    api();
  };

  return (
    <>
      <CustomSaveButton {...props} onSave={handleSave}>
        <SaveIcon />
      </CustomSaveButton>
      <DialogOKCancel
        open={open}
        onClose={handleHide}
        onOKClick={handleSubmit}
        okLabel="admin.actions.yes"
        cancelLabel="admin.actions.no"
        title=""
        message="admin.dialog.retrofitSendBacks.submit"
      />
      <DialogOKOnly
        open={errorDialog.open}
        onClose={() => {
          setErrorDialog({ open: false, messages: [] });
        }}
        title=""
        nonTranslateMessage={true}
        messages={errorDialog.messages}
      />
    </>
  );
};

SaveButton.displayName = 'SaveButton';
export default SaveButton;
