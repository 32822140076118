import React, { useRef, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import {
  CustomList,
  Datagrid,
  Filter,
  Loading,
  SiteSearchInput,
  TextField,
  CustomDateField,
  FunctionField,
  ReferenceField,
  SelectInput,
  LicensePermissionField,
  Typography,
} from '../..';
import { useGetAccount } from '../../../hooks';
import { RetrofitData } from '../../../dataProvider';
import { BulkActionButton } from './views';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '10px',
            paddingLeft: '16px',
            width: 16,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '10px',
            paddingLeft: '10px',
            width: 102,
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '10px',
            paddingLeft: '10px',
            width: 108,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '10px',
            paddingLeft: '10px',
            width: 198,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '10px',
            paddingLeft: '10px',
            width: 103,
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '10px',
            paddingLeft: '10px',
            width: 103,
          },
          '& .MuiTableCell-root:nth-child(7)': {
            paddingRight: '16px',
            paddingLeft: '10px',
            width: 198,
          },
        },
      },
    },
    columnFilter: {
      marginTop: 0,
      marginBottom: 0,
    },
  }),
);

const useLicenseStatusType = () => {
  const base = 'admin.label.retrofitCorporationLicenses.licenseStatusChoices';
  const translate = useTranslate();
  return [
    { id: 'Enable', name: translate(`${base}.enable`) },
    { id: 'Disable', name: translate(`${base}.disable`) },
    { id: 'UnLicense', name: translate(`${base}.unLicense`) },
  ];
};

const SerchFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

const ColumnFilters: React.FC = props => {
  const classes = useStyles();
  const licenseStatusType = useLicenseStatusType();
  return (
    <Filter {...props}>
      <SelectInput
        className={classes.columnFilter}
        resource="retrofitCorporationLicenses"
        source="licenseStatus"
        choices={licenseStatusType}
        alwaysOn
      />
    </Filter>
  );
};

const LicenseIdField: React.FC<{
  resource?: string;
  source?: string;
}> = (props: any) => {
  const retrofitId: number | undefined = props?.record?.retrofitId;
  // BulkActionのチェックボックスを非表示にする処理
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (retrofitId || !ref || !ref.current) return; // レトロフィットが紐付いている場合も弾く
    const span =
      ref.current.parentElement?.parentElement?.firstElementChild?.querySelector(
        'span',
      );
    if (span) span.style.display = 'none';
  }, [ref, retrofitId]);
  return (
    <>
      <TextField {...props} />
      <div ref={ref} />
    </>
  );
};

const LicenseTypeField: React.FC<{
  label?: string;
}> = (props: any) => {
  // Flexライセンス一覧
  const flexLicense = ['FlexAll'];
  const isFlexLicense = flexLicense.includes(props?.record?.licensePermission);
  const licenseType = isFlexLicense ? 'Flex' : 'Pilot';
  return <Typography>{licenseType}</Typography>;
};

const RetrofitCorporationLicenseList: React.FC = props => {
  const classes = useStyles();
  const { getCorporationId, isLoading } = useGetAccount();
  const corporationId = getCorporationId();
  if (isLoading() || corporationId === '') return <Loading />;
  return (
    <CustomList
      {...props}
      filters={<SerchFilter />}
      filter={{ corporationId, enable: true }}
      title="admin.pages.retrofitCorporationLicenseList"
      bulkActionButtons={<BulkActionButton />}
      columnfilters={<ColumnFilters />}
    >
      <Datagrid rowClick="edit" className={classes.root}>
        <LicensePermissionField
          resource="retrofitCorporationLicenses"
          source="licensePermission"
          isList={true}
        />
        <LicenseTypeField label="resources.retrofitCorporationLicenses.fields.productType" />
        <ReferenceField
          resource="retrofitCorporationLicenses"
          source="retrofitId"
          reference="retrofits"
          link=""
          sortable={false}
        >
          <FunctionField
            resource="retrofits"
            render={(retrofitData: RetrofitData | undefined) => {
              const {
                machineInfoMachineId,
                machineInfoMachineName,
                basicInfoSerialNumber,
              } = retrofitData || {
                machineInfoMachineId: '',
                machineInfoMachineName: '',
                basicInfoSerialNumber: '',
              };
              return (
                <>{`${machineInfoMachineId} / ${machineInfoMachineName} / ${basicInfoSerialNumber}`}</>
              );
            }}
          />
        </ReferenceField>
        <CustomDateField
          resource="retrofitCorporationLicenses"
          source="issuedAt"
        />
        <CustomDateField
          resource="retrofitCorporationLicenses"
          source="expiresAt"
        />
        <LicenseIdField
          resource="retrofitCorporationLicenses"
          source="licenseId"
        />
      </Datagrid>
    </CustomList>
  );
};
RetrofitCorporationLicenseList.displayName = 'RetrofitCorporationLicenseList';

export default RetrofitCorporationLicenseList;
