import React, { FC, useCallback, useState, useEffect } from 'react';
import {
  Record,
  useUpdate,
  useRefresh,
  useNotify,
  useTranslate,
  useDataProvider,
} from 'react-admin';
import { CustomSaveButton, DialogOKOnly, DialogOKCancel } from '../../../..';
import { useResource } from '../../../../../hooks';
import { RetrofitData, RetrofitRentalData } from '../../../../../dataProvider';
import { dateStringToLocaleDate, apiErrorHandler } from '../../../../../utils';
import { SaveIcon } from '../../../../../assets';

interface Props {
  basePath?: string;
  record?: Record;
  retrofitdata: RetrofitData;
  label?: string;
  siteId: string;
}

const useDoubleRental = (retrofitId: number, retrofitRentalId?: number) => {
  const [isDoubleRental, setIsDoubleRental] = useState(false);

  const dataProvider = useDataProvider();
  const resource = useResource('retrofitRentals');

  useEffect(() => {
    if (!retrofitId || !retrofitRentalId) return;
    const fn = async () => {
      const { data: rentalDataChild } = await dataProvider.getList(resource, {
        pagination: {
          page: 1,
          perPage: 1, // 1件しか取る必要がない
        },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          retrofitId,
          retrofitRentalId,
          isReturned: false,
        },
      });
      if (rentalDataChild.length > 0) {
        setIsDoubleRental(true);
      }
    };
    fn();
  }, [dataProvider, retrofitRentalId, resource, retrofitId]);

  return {
    getDoubleRental: (): boolean => {
      return isDoubleRental;
    },
  };
};

const useRentalChildren = () => {
  const resource = useResource('retrofitRentals');
  const dataProvider = useDataProvider();
  let targetRecord: RetrofitRentalData[] = [];

  const fetchChildRental = async (
    targetData: RetrofitRentalData,
    outData: RetrofitRentalData[],
    onFinish: (out: RetrofitRentalData[]) => void,
  ) => {
    outData.push(targetData);
    const { data: rentalData } = await dataProvider.getList(resource, {
      pagination: {
        page: 1,
        perPage: 1, // 1件しか取る必要がない
      },
      sort: { field: 'id', order: 'ASC' },
      filter: {
        retrofitRentalId: targetData.id,
        isReturned: false,
      },
    });
    if (rentalData.length > 0) {
      fetchChildRental(rentalData[0] as RetrofitRentalData, outData, onFinish);
    } else {
      outData.reverse();
      onFinish(outData);
    }
  };
  return {
    fetchRecursive: (
      targetData: RetrofitRentalData,
      callback: (out: RetrofitRentalData[]) => void,
    ) => {
      targetRecord = [];
      fetchChildRental(targetData, targetRecord, callback);
    },
  };
};

const useReturnRetrofit = () => {
  const resourceRentals = useResource('retrofitRentals');
  const dataProvider = useDataProvider();

  return {
    updateRental: (targetRental: RetrofitRentalData, actualEndDate: string) => {
      return async () => {
        // retrofitRentals 更新
        await dataProvider.update(resourceRentals, {
          id: targetRental.id,
          data: {
            ...targetRental,
            actualStartDate: targetRental.isApproved
              ? targetRental.actualStartDate
              : actualEndDate,
            actualEndDate, // 返却
            isApproved: true, // 返却
            isReturned: true, // 返却
          },
          previousData: targetRental,
        });
      };
    },
  };
};

const getMaxActualStartDate = (rentals: RetrofitRentalData[]) => {
  let ret = new Date(0);
  rentals.forEach(it => {
    const targetDate = dateStringToLocaleDate(it.actualStartDate);
    if (ret < targetDate) {
      ret = targetDate;
    }
  });
  return ret;
};
class ConfirmState {
  messages: string[];

  nextConfirm: boolean;

  constructor(messages: string[], nextConfirm: boolean) {
    this.messages = messages;
    this.nextConfirm = nextConfirm;
  }

  lastMessage(): string {
    if (this.messages.length <= 0) return '';
    return this.messages[this.messages.length - 1];
  }
}

const SaveButton: FC<Props> = (props: any) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const { retrofitdata, siteId, ...buttonProps } = props;
  const { record, label } = props;
  const [update] = useUpdate('retrofitRentals', record.id, undefined, record);
  const [rentalData, setRentalData] = useState<RetrofitRentalData | undefined>(
    undefined,
  );
  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [overDoubleRental, setOverDoubleRental] = useState<{
    open: boolean;
    date: Date;
  }>({ open: false, date: new Date() });
  const retrofitId = Number(retrofitdata.id);
  const retrofitRentalId = Number((record as RetrofitRentalData).id);

  const translate = useTranslate();
  const [confirmState] = useState<ConfirmState>(new ConfirmState([], false));
  const [confirmMessage, setConfirmMessage] = useState('');

  const { getDoubleRental } = useDoubleRental(retrofitId, retrofitRentalId);
  const isDoubleRental = getDoubleRental();

  const { fetchRecursive } = useRentalChildren();
  const { updateRental } = useReturnRetrofit();

  const handleHide = useCallback(() => {
    if (!confirmState.nextConfirm) {
      confirmState.messages = [];
      setRentalData(undefined); // キャンセル時は、ここで値を初期化しておく
      setOpen(false);
      return;
    }
    confirmState.nextConfirm = false;
    setConfirmMessage(confirmState.lastMessage());
    confirmState.messages.pop();
  }, [confirmState]);

  const handleHideErrorDialog = useCallback(() => {
    setOverDoubleRental({ ...overDoubleRental, open: false });
  }, [overDoubleRental]);

  const handleHideFirstErrorDialog = useCallback(() => {
    setErrorOpen(false);
  }, []);

  const handleSubmit = useCallback(() => {
    if (!rentalData) {
      console.error('Impossible case!'); // 想定外のケース
      return;
    }
    const inputActualEndDate = rentalData.actualEndDate; // 入力した返却日を利用する
    if (isDoubleRental && inputActualEndDate) {
      fetchRecursive(rentalData, targets => {
        // 一括返却時にレンタル先企業が別企業へレンタルした日付よりも返却日が過去日になっているかをチェックする
        const startDate = getMaxActualStartDate(targets);
        if (startDate > dateStringToLocaleDate(inputActualEndDate)) {
          setOverDoubleRental({ open: true, date: startDate });
          return;
        }
        const updatePromises = targets.map(rental =>
          updateRental(rental, inputActualEndDate),
        );
        const fn = async () => {
          try {
            // eslint-disable-next-line no-restricted-syntax
            for (const execPromise of updatePromises) {
              // eslint-disable-next-line no-await-in-loop
              await execPromise();
            }
            notify('admin.message.success', 'info');
            refresh();
          } catch (error) {
            notify(apiErrorHandler(error), 'warning');
          }
        };
        fn();
      });
      return;
    }
    const values = rentalData;
    update(
      {
        payload: {
          data: {
            ...values,
            isApproved: inputActualEndDate ? true : values.isApproved,
            isReturned: inputActualEndDate ? true : values.isReturned,
          },
        },
      },
      {
        onSuccess: () => {
          notify('admin.message.success', 'info');
          refresh();
        },
      },
    );
  }, [
    update,
    notify,
    refresh,
    rentalData,
    isDoubleRental,
    fetchRecursive,
    updateRental,
  ]);

  const handleSave = useCallback(
    values => {
      const { actualEndDate: isReturn, isApproved } = values;

      if (!isApproved && isReturn) {
        // 終了日が設定済みで、貸与が未承認の場合
        setErrorOpen(true);
        return;
      }

      // confirmダイアログ関連処理 ↓↓ここから↓↓
      if (isDoubleRental && isReturn) {
        // 終了日が設定済みで、建機と現場が紐づけなし、ダブルレンタル中
        confirmState.messages.push(
          'admin.dialog.retrofitRentalEdit.confirmDoubleRental.message',
        );
      }
      if (siteId !== '' && isReturn) {
        // 終了日が設定済みで、対象の建機と現場が紐づけ済み
        confirmState.messages.push(
          'admin.dialog.retrofitRentalEdit.confirmTieUpJobsite.message',
        );
      }
      if (confirmState.messages.length > 0) {
        setConfirmMessage(confirmState.lastMessage());
        setOpen(true);
        confirmState.messages.pop();
      }
      setRentalData(values); // 再レンダリング時に submit を走らせる
      // confirmダイアログ関連処理 ↑↑ここまで↑↑
    },
    [siteId, isDoubleRental, confirmState],
  );

  if (!open && rentalData && confirmState.messages.length <= 0) {
    // handleSave で 確認ダイアログを表示しない場合のみ、
    // このタイミングで保存処理を実行する
    // また、ダイアログのキャンセル時は本処理は通過しない
    setRentalData(undefined);
    handleSubmit();
  }

  const handleConfirmOk = useCallback(() => {
    if (confirmState.messages.length <= 0) {
      confirmState.nextConfirm = false;
      handleSubmit();
      return;
    }
    confirmState.nextConfirm = true;
  }, [confirmState, handleSubmit]);

  return (
    <>
      <CustomSaveButton {...buttonProps} onSave={handleSave} label={label}>
        <SaveIcon />
      </CustomSaveButton>
      <DialogOKOnly
        open={errorOpen}
        onClose={handleHideFirstErrorDialog}
        title=""
        message={'admin.dialog.retrofitRentalEdit.unapprovedSave.message'}
      />
      <DialogOKCancel
        open={open}
        onClose={handleHide}
        onOKClick={handleConfirmOk}
        okLabel="admin.actions.yes"
        cancelLabel="admin.actions.no"
        title=""
        message={confirmMessage}
      />
      <DialogOKOnly
        open={overDoubleRental.open}
        onClose={handleHideErrorDialog}
        title=""
        nonTranslateMessage
        messages={[
          translate(
            'admin.dialog.retrofitRentalEdit.overDoubleRentalStartDate.message1',
            { date: overDoubleRental.date.toLocaleDateString() },
          ),
          translate(
            'admin.dialog.retrofitRentalEdit.overDoubleRentalStartDate.message2',
            { date: overDoubleRental.date.toLocaleDateString() },
          ),
        ]}
      />
    </>
  );
};

SaveButton.displayName = 'SaveButton';
export default SaveButton;
