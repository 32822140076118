import React, { useRef, useEffect, useCallback, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  Identifier,
  useTranslate,
  useDataProvider,
  useNotify,
} from 'react-admin';
import {
  SiteProjectExpandList,
  TextField,
  IconField,
  CustomDateField,
  DialogOKOnly,
  CustomDialog,
  CustomForm,
  IconButton,
  ErrorInfoField,
  FunctionField,
  ListDownloadButton,
  UnitLengthField,
} from '../../..';
import { colors, styles } from '../../../../theme';
import { TasksIcon, CompactionWorkAreasIcon } from '../../../../assets';
import {
  useUpdateManyFn,
  useDeleteManyFn,
  useCreateMessage,
  useUnitLength,
} from '../../../../hooks';
import { ProjectsData, TaskData, UnitLength } from '../../../../dataProvider';
import { config, apiErrorHandler } from '../../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    space: {
      marginTop: 12,
    },
    dialogForm: {
      '& .icon-root': {
        position: 'absolute',
        left: 10,
      },
    },
  }),
);

const taskGridStyle = {
  tableLayout: 'fixed',
  '& .MuiTableHead-root': {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        paddingTop: '6px',
        paddingBottom: '6px',
        boxSizing: 'content-box',
      },
      '& .MuiTableCell-root:nth-child(1)': {
        paddingRight: '15px',
        paddingLeft: '16px',
        width: 32,
      },
      '& .MuiTableCell-root:nth-child(2)': {
        paddingRight: '15px',
        paddingLeft: '15px',
        width: 190,
      },
      '& .MuiTableCell-root:nth-child(3)': {
        paddingRight: '15px',
        paddingLeft: '16px',
        width: 80,
        textAlign: 'center',
      },
      '& .MuiTableCell-root:nth-child(4)': {
        paddingRight: '15px',
        paddingLeft: '15px',
        width: 112,
      },
      '& .MuiTableCell-root:nth-child(5)': {
        paddingRight: '15px',
        paddingLeft: '15px',
        width: 'auto',
        textAlign: 'left',
      },
      '& .MuiTableCell-root:nth-child(6)': {
        paddingRight: '16px',
        paddingLeft: '15px',
        width: 50,
      },
    },
  },
};

const compactionGridStyle = {
  tableLayout: 'fixed',
  '& .MuiTableHead-root': {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        paddingTop: '6px',
        paddingBottom: '6px',
        boxSizing: 'content-box',
      },
      '& .MuiTableCell-root:nth-child(1)': {
        paddingRight: '15px',
        paddingLeft: '16px',
        width: 32,
      },
      '& .MuiTableCell-root:nth-child(2)': {
        paddingRight: '15px',
        paddingLeft: '15px',
        width: 'auto',
      },
      '& .MuiTableCell-root:nth-child(3)': {
        paddingRight: '15px',
        paddingLeft: '15px',
        width: 100,
      },
      '& .MuiTableCell-root:nth-child(4)': {
        paddingRight: '16px',
        paddingLeft: '15px',
        width: 360,
      },
    },
  },
};

interface Props {
  id: Identifier;
  record: ProjectsData;
  unitLength: UnitLength;
}

const SiteProjectExpand: React.FC<Partial<Props>> = props => {
  const { id: projectId, record: projectData, unitLength } = props;
  if (!projectData) throw Error('Invalid record');
  if (!unitLength) throw Error('Invalid unitLength');
  const {
    status: projectStatus,
    latestProjectVersionId,
    projectSourceLayers,
  } = projectData;
  if (!latestProjectVersionId) throw Error('Invalid latestProjectVersionId');
  if (!projectStatus) throw Error('Invalid status');

  const classes = useStyles();
  const translate = useTranslate();
  const tasks = 'tasks';
  const compactionWorkAreas = 'compactionWorkAreas';
  const ref = useRef<HTMLDivElement>(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [compactionDeleteConfirmOpen, setCompactionDeleteConfirmOpen] =
    useState(false);
  const [taskDeleteConfirmOpen, setTaskDeleteConfirmOpen] = useState(false);
  const [taskDialog, setTaskDialog] = useState<{
    open: boolean;
    message: string;
  }>({ open: false, message: '' });

  const { createMessage } = useCreateMessage();
  const { deleteMany } = useDeleteManyFn();
  const { updateMany } = useUpdateManyFn('ra.notification.deleted');
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const creatable = projectStatus === 'Converted' || false;

  useEffect(() => {
    if (!ref.current) return;
    const { parentNode } = ref.current as any;
    parentNode.style.background = colors.backgroud;
    parentNode.style.padding = '0px'; // 元々のPaddingを消す
  }, [ref]);

  const { toDisplayUnitLength } = useUnitLength();

  const blockSizeLabel = translate(
    'admin.label.compactionWorkAreas.blockSize',
    {
      unit: toDisplayUnitLength(unitLength),
    },
  );

  const handleTasksDelete = useCallback(() => {
    setTaskDeleteConfirmOpen(true);
  }, []);

  const handleCompactionWorkAreasDelete = useCallback(
    (_resources: string, selectedIds: string[]) => {
      const fetchCompactionLayers = async (id: string) => {
        const { data } = await dataProvider.getManyReference(
          'compactionLayers',
          {
            target: 'compactionWorkAreaId',
            id,
            pagination: { page: 1, perPage: 1 }, // NOTE: 1件だけでよい
            sort: { field: 'id', order: 'DESC' },
            filter: { inUse: true },
          },
        );
        return data.length > 0;
      };
      const selectPromises = selectedIds.map(id => fetchCompactionLayers(id));
      Promise.all(selectPromises)
        .then(result => {
          const inUsed = result.includes(true);
          if (inUsed) {
            setDialogOpen(true); // 転圧レイヤ 使用中: 削除不可
            setDialogMessage(
              'admin.dialog.compactionWorkAreas.undeletable.message',
            );
            return;
          }
          setCompactionDeleteConfirmOpen(true); // 転圧レイヤ 未使用: 削除可能
        })
        .catch(error => {
          notify(apiErrorHandler(error), 'warning');
        });
    },
    [dataProvider, notify],
  );

  const handleConfirmHide = useCallback(() => {
    setTaskDeleteConfirmOpen(false);
    setCompactionDeleteConfirmOpen(false);
  }, []);

  const [taksRecord, setTaskRecord] = useState<TaskData | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const handleStatusIconClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, record) => {
      e.stopPropagation();
      setOpen(true);
      setTaskRecord(record);
    },
    [],
  );

  const handleValidButton = useCallback(record => {
    const { status } = record as TaskData;
    return status === 'Error';
  }, []);

  return (
    <div ref={ref}>
      <>
        <CustomDialog
          open={open}
          onClose={handleClose}
          title="admin.dialog.taskConversionStatus.title"
          okLabel="admin.dialog.taskConversionStatus.button"
          testId="tasks_info_dialog"
        >
          <div style={styles.errorInfo}>
            <CustomForm
              resource={tasks}
              record={taksRecord}
              deleteButton={false}
              cancelButton={false}
              saveButton={false}
              className={classes.dialogForm}
            >
              <IconField resource={tasks} source="status" addLabel={false} />
              <ErrorInfoField resource={tasks} source="errorInfo" />
            </CustomForm>
          </div>
        </CustomDialog>
        <DialogOKOnly
          open={taskDialog.open}
          onClose={() => setTaskDialog({ open: false, message: '' })}
          title=""
          message={taskDialog.message}
        />
        <SiteProjectExpandList
          {...props}
          gridClasses={taskGridStyle}
          resource={tasks}
          icon={<TasksIcon />}
          params={{
            filter: { projectVersionId: latestProjectVersionId },
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'id', order: 'ASC' },
          }}
          bulkActionMessage={createMessage(tasks)}
          onDeleteClick={handleTasksDelete}
          onBulkAction={deleteMany}
          onConfirmHide={handleConfirmHide}
          defaultConfirmOpen={taskDeleteConfirmOpen}
          creatable={creatable}
          addClickEnable={projectSourceLayers.length <= 0}
          onAddClick={() =>
            setTaskDialog({
              open: true,
              message: 'admin.dialog.taskList.unableToCreate.message',
            })
          }
        >
          <TextField resource={tasks} source="name" sortable={false} />
          <IconField
            resource={tasks}
            source="status"
            sortable={false}
            button={<IconButton onClick={handleStatusIconClick} />}
            validButton={handleValidButton}
          />
          <CustomDateField
            resource={tasks}
            source="expiredDate"
            sortable={false}
          />
          <TextField resource={tasks} source="description" sortable={false} />
          <FunctionField
            resource={tasks}
            source="projectZipFile"
            sortable={false}
            label=""
            render={taskRecord => {
              const data = taskRecord as TaskData;
              const url = data.projectZipFile?.src || '';
              return (
                <ListDownloadButton
                  url={url}
                  label="projectZipDownload"
                  smallicon={true}
                />
              );
            }}
          />
        </SiteProjectExpandList>
      </>
      {/* ↓ REACT_APP_FEATURE_COMPACTION が消えたら DOM を React.Fragment (<></>) の配下のみにする */}
      {config.feature.compaction ? (
        <>
          <div className={classes.space} />
        </>
      ) : null}
      {/* ↓ REACT_APP_FEATURE_COMPACTION が消えたら DOM を React.Fragment (<></>) の配下のみにする */}
      {config.feature.compaction ? (
        <>
          <DialogOKOnly
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            title=""
            message={dialogMessage}
          />
          <SiteProjectExpandList
            {...props}
            gridClasses={compactionGridStyle}
            resource={compactionWorkAreas}
            icon={<CompactionWorkAreasIcon />}
            params={{
              filter: { projectId: [projectId], enable: [true] },
              pagination: { page: 1, perPage: 100 },
              sort: { field: 'id', order: 'ASC' },
            }}
            bulkActionMessage={createMessage(compactionWorkAreas)}
            onBulkAction={(resource: string, selectedIds: string[]) => {
              updateMany(resource, selectedIds, { enable: false });
            }}
            onDeleteClick={handleCompactionWorkAreasDelete}
            onConfirmHide={handleConfirmHide}
            defaultConfirmOpen={compactionDeleteConfirmOpen}
            creatable={creatable}
          >
            <TextField
              resource={compactionWorkAreas}
              source="name"
              sortable={false}
            />
            <UnitLengthField
              resource={compactionWorkAreas}
              source="blockSize"
              label={blockSizeLabel}
              sortable={false}
              unitLength={unitLength}
            />
            <TextField
              resource={compactionWorkAreas}
              source="description"
              sortable={false}
            />
          </SiteProjectExpandList>
        </>
      ) : null}
    </div>
  );
};

SiteProjectExpand.displayName = 'SiteProjectExpand';
export default SiteProjectExpand;
