import { DataProvider } from 'react-admin';
import ntripUnusedTotalDataGetList from './ntripUnusedTotalDataGetList';

const ntripUnusedTotalDataProvider: DataProvider = {
  getList: (resource): any => {
    if (resource === 'ntripsUnusedTotal') return ntripUnusedTotalDataGetList();
    return Promise.reject(new Error('not supported'));
  },
  getOne: () => Promise.reject(new Error('not supported')),
  getMany: () => Promise.reject(new Error('not supported')),
  getManyReference: () => Promise.reject(new Error('not supported')),
  update: () => Promise.reject(new Error('not supported')),
  updateMany: () => Promise.reject(new Error('not supported')),
  create: () => Promise.reject(new Error('not supported')),
  delete: () => Promise.reject(new Error('not supported')),
  deleteMany: () => Promise.reject(new Error('not supported')),
};

export default ntripUnusedTotalDataProvider;
