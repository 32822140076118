import React from 'react';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { LoginComponent } from 'ra-core';
import { useHistory } from 'react-router-dom';
import {
  ErrorBoundary,
  LoginButton,
  Notification,
  CustomAppBar,
  CustomFooter,
} from '..';
import { useLayoutStyles } from '../../hooks';
import { getDefaultTheme } from '../../theme';
import { RockIcon } from '../../assets';

const Login: LoginComponent = () => {
  const history = useHistory();
  const classes = useLayoutStyles(getDefaultTheme());

  return (
    <ErrorBoundary history={history}>
      <MuiThemeProvider theme={getDefaultTheme()}>
        <div className={classes.root}>
          <CssBaseline />
          <div className={classes.appFrame}>
            <div className={classes.appBar}>
              <CustomAppBar login />
            </div>
            <main className={classes.contentFrame}>
              <div className={classes.contentContainer}>
                <div className={classes.login}>
                  <div className={classes.rock}>
                    <RockIcon />
                  </div>
                  <LoginButton />
                </div>
              </div>
              <CustomFooter className={classes.footer} />
            </main>
            <Notification />
          </div>
        </div>
      </MuiThemeProvider>
    </ErrorBoundary>
  );
};

Login.displayName = 'Login';
export default Login;
