import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  AppBar,
  Badge,
  Box,
  createStyles,
  makeStyles,
  Toolbar,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
  ReduxState,
  setSidebarVisibility,
  useDataProvider,
  useVersion,
} from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { ApprovalButton, Button, CustomUserMenu, LoadingIndicator } from '..';
import { AccountData } from '../../dataProvider';
import { getI18nProvider } from '../../i18n';
import { colors } from '../../theme';

const useStyles = makeStyles(() =>
  createStyles({
    logo: {
      height: 48,
      letterSpacing: 0,
      marginTop: -4,
      marginLeft: -16,
    },
  }),
);

const NotificationsButton: FC = () => {
  const dispach = useDispatch();
  const dataProvider = useDataProvider();
  const [count, setCount] = useState(0);
  const version = useVersion();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const handleClick = () => {
    dispach(setSidebarVisibility(true));
  };

  useEffect(() => {
    if (open) return; // お知らせを表示を閉じている場合のみ実行
    const fn = async () => {
      const { getOne, getList } = dataProvider;
      // ログイン中のユーザーのメールアドレスと企業IDを取得
      const accountResult = await getOne('accounts', { id: 1 });
      if (!accountResult) return;
      const { data: accountData } = accountResult;
      const { email } = accountData as AccountData;

      const i18nProvider = getI18nProvider();
      const language = i18nProvider.getLocale().toUpperCase();
      const result = await getList('userNoticeStatus', {
        filter: { email, language, read: false },
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
      });
      setCount(result.data.length);
    };
    fn();
  }, [dataProvider, version, open]);

  return (
    <Button style={{ borderRadius: '80%', minWidth: 48 }} onClick={handleClick}>
      <Badge
        badgeContent={count}
        style={{ minWidth: 30 }}
        max={99}
        color="error"
      >
        <NotificationsIcon />
      </Badge>
    </Button>
  );
};

const CustomAppBar: FC<{
  className?: string;
  logout?: React.ReactNode;
  login?: boolean;
  supportMode?: boolean;
}> = ({ className, logout, login, supportMode = false }) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const baseColor = supportMode ? colors.supportMode : colors.header;
  const headerColor =
    pathname.indexOf('/controllerRegistrationAdmin') === 0
      ? colors.adminMode
      : baseColor;

  return (
    <AppBar
      className={className}
      position="static"
      style={{ backgroundColor: headerColor }}
    >
      <Toolbar>
        <Box flexGrow={1}>
          <img src="logo.svg" className={classes.logo} alt="logo" />
        </Box>
        <Box minWidth={256} display="flex" alignItems="center">
          {login ? null : (
            <>
              <NotificationsButton />
              <LoadingIndicator />
              <ApprovalButton />
              <CustomUserMenu logout={logout} />
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
