import { TermsConditionData } from '../../types';

const termsConditions: TermsConditionData[] = [
  {
    id: 1,
    regionName: 'North America',
  },
  {
    id: 2,
    regionName: 'Oceania',
  },
  {
    id: 3,
    regionName: 'Asia',
  },
  {
    id: 4,
    regionName: 'Japan',
  },
];

export default termsConditions;
