import { AdminApiConverter, ApiFilter } from '..';

const converter: AdminApiConverter<'geofenceAlerts', 'geofenceAlerts'> = {
  resource: 'geofenceAlerts',
  defaultSort: { field: 'timeStamp', order: 'DESC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'geofenceAlerts'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('corporationId' in filter)
      apiFilter.corporationId = filter.corporationId;
    if ('siteId' in filter) apiFilter.siteId = filter.siteId;
    if ('retrofitId' in filter) apiFilter.retrofitId = filter.retrofitId;
    if ('geofenceId' in filter) apiFilter.geofenceId = filter.geofenceId;
    if ('geofenceName' in filter) apiFilter.geofenceName = filter.geofenceName;
    if ('alertType' in filter) apiFilter.alertType = filter.alertType;
    if ('collisionPoint' in filter)
      apiFilter.collisionPoint = filter.collisionPoint;
    if ('height' in filter) apiFilter.height = filter.height;
    if ('x' in filter) apiFilter.x = filter.x;
    if ('y' in filter) apiFilter.y = filter.y;
    if ('z' in filter) apiFilter.z = filter.z;
    if ('timeStamp' in filter) apiFilter.timeStamp = filter.timeStamp;
    return apiFilter;
  },
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(record => ({
        id: record.id,
        corporationId: record.corporationId,
        siteId: record.siteId,
        retrofitId: record.retrofitId,
        geofenceId: record.geofenceId,
        geofenceName: record.geofenceName,
        alertType: record.alertType,
        collisionPoint: record.collisionPoint,
        longitude: record.longitude,
        latitude: record.latitude,
        height: record.height,
        x: record.x,
        y: record.y,
        z: record.z,
        timeStamp: record.timeStamp,
        retrofit: record.retrofit
          ? {
              machineInfoMachineName: record.retrofit?.machineInfoMachineName,
              basicInfoManufacturer: record.retrofit?.basicInfoManufacturer,
              basicInfoModel: record.retrofit?.basicInfoModel,
              basicInfoProductNumber: record.retrofit?.basicInfoProductNumber,
              basicInfoSerialNumber: record.retrofit?.basicInfoSerialNumber,
            }
          : undefined,
      })),
    ),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  recordToApi: record => ({}),
  embed: ['retrofit'],
};

export default converter;
