import { SelectInput } from 'react-admin';
import { InputProps, ChoicesProps } from 'ra-core';
import { TextFieldProps as MuiProps } from '@material-ui/core/TextField';
import { ResourceFC } from '..';

interface RaResettableTextFieldProps {
  classes: object;
  clearAlwaysVisible: boolean;
  disabled: boolean;
  InputProps: object;
  onBlur: Function;
  onChange: Function;
  onFocus: Function;
  resettable: boolean;
  value: any;
}

type Props = ChoicesProps &
  InputProps<MuiProps> &
  Omit<MuiProps, 'label' | 'helperText'> &
  RaResettableTextFieldProps;

const { defaultProps } = SelectInput;
SelectInput.defaultProps = {
  ...defaultProps,
  resettable: true,
};

SelectInput.displayName = 'SelectInput';
export default SelectInput as ResourceFC<Partial<Props>>;
