import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import {
  downloadCSV,
  useTranslate,
  useDataProvider,
  NumberField,
} from 'react-admin';
import jsonExport from 'jsonexport';
import {
  CustomList,
  Datagrid,
  Filter,
  SiteSearchInput,
  CustomDateField,
  TextField,
  Loading,
} from '../../..';
import {
  useResource,
  useGetUnitLengthCallback,
  useAccount,
  useGetSelefDetailData,
} from '../../../../hooks';
import {
  RetrofitData,
  RetrofitAccuraciesData,
  UnitLength,
} from '../../../../dataProvider';
import { ColumnFilters } from './views';
import { useGetRetrofitsSiteId, useGetSiteName } from './hooks';
import { getRetrofitInfoBreadcrumbs } from './util';
import { convertNumberLocale, getCsvDelimiter } from '../../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      '& > div:nth-child(2)': {
        overflow: 'auto',
        height: 'calc(100vh - 320px)',
      },
    },
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
            // ヘッダ固定用スタイル
            position: 'sticky',
            top: 0,
            backgroundColor: '#29292f', // colors.tsのbackgroundと同じ色を設定すること
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 112,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 120,
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 130,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 130,
          },
          '& .MuiTableCell-root:nth-child(7)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(8)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(9)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 120,
          },
          '& .MuiTableCell-root:nth-child(10)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 130,
          },
          '& .MuiTableCell-root:nth-child(11)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(12)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
        },
      },
    },
  }),
);

const ListFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

interface ApiResult {
  isOwnData: boolean;
  isAdminCorporation: boolean;
  siteAttachedData: boolean;
  fetchedRetrofitId: number;
  userCorporationId: string;
  breadcrumbs: string[];
  unitLength: UnitLength;
}

const useExporter = (resource: string, retrofitId: number) => {
  const base = `resources.${resource}.fields`;
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  return async (record: RetrofitAccuraciesData[]) => {
    const result = await dataProvider.getOne<RetrofitData>('retrofits', {
      id: retrofitId,
    });
    if (!result) return;
    const { data: retrofitData } = result;
    const { basicInfoSerialNumber } = retrofitData;

    // 変数内のすべてのフィールドがCSV出力されてしまうため、不要なフィールドをomit
    // csv出力不要かつ以降の処理で未使用であるものはlintエラーになるため、lintエラー回避コメント文を追記
    const postsForExport = record.map(data => {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        id,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        retrofitId: _,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        userCorporationId,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        unitLength,
        positionN,
        positionE,
        positionZ,
        machineOffsetN,
        machineOffsetE,
        machineOffsetZ,
        referencePointN,
        referencePointE,
        referencePointZ,
        ...postForExport
      } = data;
      // convert
      postForExport.checkDateTime = new Date(
        data.checkDateTime,
      ).toLocaleString();
      // convert - 小数値変換
      postForExport.csvPositionN = convertNumberLocale(positionN);
      postForExport.csvPositionE = convertNumberLocale(positionE);
      postForExport.csvPositionZ = convertNumberLocale(positionZ);
      postForExport.csvMachineOffsetN = convertNumberLocale(machineOffsetN);
      postForExport.csvMachineOffsetE = convertNumberLocale(machineOffsetE);
      postForExport.csvMachineOffsetZ = convertNumberLocale(machineOffsetZ);
      postForExport.csvReferencePointN = convertNumberLocale(referencePointN);
      postForExport.csvReferencePointE = convertNumberLocale(referencePointE);
      postForExport.csvReferencePointZ = convertNumberLocale(referencePointZ);
      return postForExport;
    });
    jsonExport(
      postsForExport,
      {
        forceTextDelimiter: true,
        rowDelimiter: getCsvDelimiter(),
        headers: [
          'checkDateTime',
          'surveyPoint',
          'csvPositionN',
          'csvPositionE',
          'csvPositionZ',
          'csvMachineOffsetN',
          'csvMachineOffsetE',
          'csvMachineOffsetZ',
          'referencePointName',
          'csvReferencePointN',
          'csvReferencePointE',
          'csvReferencePointZ',
        ], // order fields in the export
        rename: [
          translate(`${base}.checkDateTime`),
          translate(`${base}.surveyPoint`),
          translate(`${base}.positionN`),
          translate(`${base}.positionE`),
          translate(`${base}.positionZ`),
          translate(`${base}.machineOffsetN`),
          translate(`${base}.machineOffsetE`),
          translate(`${base}.machineOffsetZ`),
          translate(`${base}.referencePointName`),
          translate(`${base}.referencePointN`),
          translate(`${base}.referencePointE`),
          translate(`${base}.referencePointZ`),
        ], // rename column name
      },
      (_, rawCsv: string) => {
        const BOM = '\uFEFF';
        const csv = `${BOM}${rawCsv}`; // UTF8でBOM付とするため、CSVの先頭にバイトオーダマークを付与
        downloadCSV(csv, `AccuracyCheck_${basicInfoSerialNumber}`);
      },
    );
  };
};

const AccuracieList: React.FC<any | { siteId: string; retrofitId: string }> = ({
  siteId,
  retrofitId,
}) => {
  const classes = useStyles();
  const resourceAccuracies = useResource('retrofitAccuracies');
  const { siteId: urlSiteId } = useParams<{ siteId: string }>();
  const exporter = useExporter('retrofitAccuracies', retrofitId);
  const getUnitLengthCallback = useGetUnitLengthCallback();

  const getAccount = useAccount();
  const getSelefDetailData = useGetSelefDetailData();
  const getSiteName = useGetSiteName();
  // const redirectTo = useRedirect();

  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: ApiResult;
  }>({
    loading: true,
    fetched: false,
    data: undefined,
  });

  useEffect(() => {
    // 既にDB取得済みでも、urlのretrofitIdが変わった場合は再度実施
    if (apiState.fetched && apiState.data?.fetchedRetrofitId === retrofitId) {
      return;
    }

    const api = async (): Promise<{
      data: {
        apiResult: ApiResult;
      };
    }> => {
      // accounts
      const {
        data: { corporationId },
      } = await getAccount();

      // retrofits
      const listFileter = {
        ownerCorporationId: corporationId,
        adminCorporationId: corporationId,
      };
      // 建機一覧から閲覧可能なレトロフィットであるか判定
      const retrofit = await getSelefDetailData(
        'retrofits',
        listFileter,
        retrofitId,
      );

      // 指定した建機をログインユーザーが利用中の場合、現場単位を取得
      const isRetrofitUser = corporationId === retrofit?.corporationId;
      const { unitLength } = await getUnitLengthCallback({
        siteId,
        isRetrofitUser,
      });

      // ログインユーザーはretrofitの管理者であるか判定
      const isAdminCorporation = retrofit?.adminCorporations
        ? retrofit.adminCorporations.some(
            (it: any) => it.corporationId === corporationId,
          )
        : false;

      // 現場に紐づくレトロフィットであるか判定
      const siteAttachedListFileter = {
        siteId: urlSiteId || '',
      };
      const siteAttachedRetrofit = await getSelefDetailData(
        'retrofits',
        siteAttachedListFileter,
        retrofitId,
      );

      // site ※現場建機詳細から呼ばれた場合に、パンくずリストで必要
      let siteName;
      if (urlSiteId) {
        const result = await getSiteName({ siteId: urlSiteId });
        siteName = result.data.siteName;
      }

      // DBの取得結果を使うため、パンくずリストはここで作成
      const breadcrumbs = getRetrofitInfoBreadcrumbs(
        siteAttachedRetrofit?.machineInfoMachineName
          ? siteAttachedRetrofit?.machineInfoMachineName
          : retrofit?.machineInfoMachineName || '',
        siteName,
      );

      return {
        data: {
          apiResult: {
            isOwnData: !!retrofit,
            isAdminCorporation,
            siteAttachedData: !!siteAttachedRetrofit,
            fetchedRetrofitId: retrofitId,
            userCorporationId: corporationId,
            breadcrumbs,
            unitLength,
          },
        },
      };
    };
    api().then(({ data: { apiResult } }) => {
      setApiState({ loading: false, fetched: true, data: apiResult });
    });
  }, [
    getSelefDetailData,
    getAccount,
    getSiteName,
    getUnitLengthCallback,
    retrofitId,
    siteId,
    urlSiteId,
    apiState,
  ]);

  if (apiState.loading || !apiState.data) return <Loading />;

  // TODO: 閲覧不可なデータは現場一覧に遷移する機能を有効にする場合、コメントアウトを外す
  const { breadcrumbs, userCorporationId, isAdminCorporation, unitLength } =
    apiState.data;

  // 建機一覧に存在しないretrofitの精度確認結果データは表示させない
  // または現場詳細画面から遷移する場合、対象現場に紐づかないretrofitの精度確認結果データは表示させない
  // if (urlSiteId ? !siteAttachedData : !isOwnData) redirectTo('/');

  const filter = isAdminCorporation
    ? { retrofitId }
    : { retrofitId, userCorporationId };

  return (
    <CustomList
      className={classes.table}
      breadcrumbs={breadcrumbs}
      resource={resourceAccuracies}
      title="admin.pages.retrofitAccuraciesList"
      bulkActionButtons={false}
      filter={filter}
      filterDefaultValues={{ unitLength }}
      filters={<ListFilter />}
      useScrollPagination={true}
      exporter={exporter}
      columnfilters={
        <ColumnFilters resource={resourceAccuracies} unitLength={unitLength} />
      }
    >
      <Datagrid className={classes.root}>
        <CustomDateField
          resource={resourceAccuracies}
          source="checkDateTime"
          showTime
        />
        <TextField resource={resourceAccuracies} source="surveyPoint" />
        <NumberField
          resource={resourceAccuracies}
          source="positionN"
          options={{ useGrouping: false }}
        />
        <NumberField
          resource={resourceAccuracies}
          source="positionE"
          options={{ useGrouping: false }}
        />
        <NumberField
          resource={resourceAccuracies}
          source="positionZ"
          options={{ useGrouping: false }}
        />
        <NumberField
          resource={resourceAccuracies}
          source="machineOffsetN"
          options={{ useGrouping: false }}
        />
        <NumberField
          resource={resourceAccuracies}
          source="machineOffsetE"
          options={{ useGrouping: false }}
        />
        <NumberField
          resource={resourceAccuracies}
          source="machineOffsetZ"
          options={{ useGrouping: false }}
        />
        <TextField resource={resourceAccuracies} source="referencePointName" />
        <NumberField
          resource={resourceAccuracies}
          source="referencePointN"
          options={{ useGrouping: false }}
        />
        <NumberField
          resource={resourceAccuracies}
          source="referencePointE"
          options={{ useGrouping: false }}
        />
        <NumberField
          resource={resourceAccuracies}
          source="referencePointZ"
          options={{ useGrouping: false }}
        />
      </Datagrid>
    </CustomList>
  );
};

const RetrofitAccuracieList: React.FC = () => {
  const getSiteId = useGetRetrofitsSiteId();
  const [siteId, setSiteId] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const { retrofitId: strRetrofitId } = useParams<{ retrofitId: string }>();
  const retrofitId = Number(strRetrofitId);
  // retrofit情報からsiteIdを取得
  useEffect(() => {
    getSiteId({ retrofitId }).then(({ data }) => {
      setSiteId(data.siteId);
      setIsLoading(false);
    });
  }, [getSiteId, retrofitId]);
  if (isLoading) return <Loading />;

  return <AccuracieList siteId={siteId} retrofitId={retrofitId} />;
};

RetrofitAccuracieList.displayName = 'RetrofitAccuracieList';
export default RetrofitAccuracieList;
