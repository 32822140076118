import { makeStyles, createStyles } from '@material-ui/core';
import { colors } from '../theme';

export default makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    appFrame: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    appBar: {
      position: 'sticky',
      fallbacks: {
        position: '-webkit-sticky',
      },
      top: 0,
      zIndex: 1100, // appBar: 1100 => material-ui/packages/material-ui/src/styles/zIndex.js
    },
    contentWithSidebar: { display: 'flex', flexGrow: 1 },
    sidebarContainer: {
      display: 'flex',
      flexDirection: 'column',
      background: colors.menu,
      boxShadow: '0px 3px 6px #00000029',
    },
    sidebar: {
      top: 60,
      position: 'sticky',
      fallbacks: {
        position: '-webkit-sticky',
      },
    },
    sidebarSpacer: {
      flexGrow: 1,
    },
    contentFrame: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    contentContainer: {
      padding: theme.spacing(2),
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
    },
    content: {
      width: 960,
    },
    rock: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 40,
    },
    login: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    maintenance: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    exclamation: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 10,
      textAlign: 'center',
    },
    title: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 20,
      textAlign: 'center',
      fontSize: 24,
    },
    message: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: colors.menu,
      minHeight: 30,
    },
  }),
);
