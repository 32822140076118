import { AuthProvider } from 'react-admin';

// eslint-disable-next-line import/prefer-default-export
export const fakeAuthProvider: AuthProvider = {
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  checkAuth: () => Promise.resolve(),
  checkError: () => Promise.resolve(),
  getPermissions: () => Promise.resolve(),
};
