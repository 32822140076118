import React from 'react';
import { createPortal } from 'react-dom';
import { BreadcrumbsContent } from '..';
import { BreadcrumbsProps } from '../atoms/layout/BreadcrumbsContent';

const BreadcrumbsPortal: React.FC<BreadcrumbsProps> = props => {
  const container =
    typeof document !== 'undefined'
      ? document.getElementById('admin-breadcrumbs')
      : null;
  if (!container) return null;

  return createPortal(<BreadcrumbsContent {...props} />, container);
};

export default BreadcrumbsPortal;
