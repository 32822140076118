import {
  MapProps,
  createProjectSourceLayerPointLabelEPSG3857,
} from '../../../../../hooks';

const addProjectSourceLayerPointLabel = (
  { map }: MapProps,
  versionId: number,
  token: string,
  visibility?: boolean,
) =>
  map.addLayer(
    createProjectSourceLayerPointLabelEPSG3857(versionId, token, visibility),
  );

export default addProjectSourceLayerPointLabel;
