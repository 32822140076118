import { DataProvider, GetListParams, GetListResult } from 'react-admin';
import { CustomerData } from '..';

const getCustomers = async (
  adminDataProvider: DataProvider,
  landlogDataProvider: DataProvider,
  params: GetListParams,
): Promise<GetListResult> => {
  const data = await Promise.all<Promise<GetListResult<CustomerData>>[]>([
    adminDataProvider.getList('customers', params),
    landlogDataProvider.getList('corporations', {
      ...params,
      filter: { q: params.filter.q },
    }), // corporations は q フィルタのみ設定する
  ]).then(result => {
    // 二次元配列を一次元配列にする ... [Array1[], Array2[]] => Array[]
    const mergeArray = result.flatMap(
      ({ data: customers }: { data: CustomerData[] }) => {
        return customers.map(({ id, name, fromCorporationId }) => ({
          id,
          name,
          fromCorporationId: fromCorporationId || '',
        }));
      },
    );
    // 重複データを弾く、この際に Array => Object になる
    const noDuplicateObject: { [key: string]: CustomerData } =
      mergeArray.reduce((prev, current) => {
        const { id } = current;
        return { ...prev, ...{ [id]: current } }; // IDをキーにして集約する
      }, {});
    // Object => Array に戻す
    const noDuplicateArray = Object.entries(noDuplicateObject).map(
      idWithRecord => {
        const [, record] = idWithRecord;
        return record;
      },
    );
    // 名前順にソート
    noDuplicateArray.sort((a, b) => (a.name > b.name ? 1 : -1));
    return Promise.resolve(noDuplicateArray);
  });
  return { data, total: data.length };
};

export default getCustomers;
