import React, { FC } from 'react';
import { ListControllerProps } from 'react-admin';
import { createStyles, makeStyles } from '@material-ui/core';
import { Pagination } from '../..';
import { colors } from '../../../theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginRight: 10,
      // プルダウンの背景色
      '& .MuiSelect-select': {
        background: colors.backgroud,
      },
    },
  }),
);

interface Props {
  rowsPerPageOptions: number[];
  limitEnabled?: boolean;
  limit?: React.ReactElement<any>;
}

const TopPagination: FC<ListControllerProps & Props> = ({
  limitEnabled = true,
  limit: defaultLimit,
  ...rest
}) => {
  const classes = useStyles();
  const limit = limitEnabled ? defaultLimit : <></>;
  return (
    <div className={classes.root}>
      <Pagination {...rest} ActionsComponent={() => <></>} limit={limit} />
    </div>
  );
};

TopPagination.displayName = 'TopPagination';
export default TopPagination;
