import { RetrofitBulkActionData } from '../../types';

const retrofitBulkActions: RetrofitBulkActionData[] = [
  {
    id: '01',
    execStatus: 'Complete',
    execStartDate: '2020-03-10T00:00:00.000Z',
    execEndDate: '2020-03-10T01:00:00.000Z',
    uploadFileName: 'upload_1.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_1.csv',
      title: 'upload_1.csv',
    },
    resultFileName: 'result_1.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_1.csv',
      title: 'result_1.csv',
    },
  },
  {
    id: '02',
    execStatus: 'Complete',
    execStartDate: '2020-03-11T00:00:00.000Z',
    execEndDate: '2020-03-11T01:00:00.000Z',
    uploadFileName: 'upload_2.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_2.csv',
      title: 'upload_2.csv',
    },
    resultFileName: 'result_2.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_2.csv',
      title: 'result_2.csv',
    },
  },
  {
    id: '03',
    execStatus: 'Complete',
    execStartDate: '2020-03-12T00:00:00.000Z',
    execEndDate: '2020-03-12T01:00:00.000Z',
    uploadFileName: 'upload_3.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_3.csv',
      title: 'upload_3.csv',
    },
    resultFileName: 'result_3.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_3.csv',
      title: 'result_3.csv',
    },
  },
  {
    id: '04',
    execStatus: 'Error',
    execStartDate: '2020-03-13T00:00:00.000Z',
    execEndDate: '2020-03-13T01:00:00.000Z',
    uploadFileName: 'upload_4.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_4.csv',
      title: 'upload_4.csv',
    },
    resultFileName: 'result_4.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_4.csv',
      title: 'result_4.csv',
    },
  },
  {
    id: '05',
    execStatus: 'Complete',
    execStartDate: '2020-03-14T00:00:00.000Z',
    execEndDate: '2020-03-14T01:00:00.000Z',
    uploadFileName: 'upload_5.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_5.csv',
      title: 'upload_5.csv',
    },
    resultFileName: 'result_5.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_5.csv',
      title: 'result_5.csv',
    },
  },
  {
    id: '06',
    execStatus: 'Complete',
    execStartDate: '2020-03-15T00:00:00.000Z',
    execEndDate: '2020-03-15T01:00:00.000Z',
    uploadFileName: 'upload_6.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_6.csv',
      title: 'upload_6.csv',
    },
    resultFileName: 'result_6.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_6.csv',
      title: 'result_6.csv',
    },
  },
  {
    id: '07',
    execStatus: 'Complete',
    execStartDate: '2020-03-16T00:00:00.000Z',
    execEndDate: '2020-03-16T01:00:00.000Z',
    uploadFileName: 'upload_7.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_7.csv',
      title: 'upload_7.csv',
    },
    resultFileName: 'result_7.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_7.csv',
      title: 'result_7.csv',
    },
  },
  {
    id: '08',
    execStatus: 'Complete',
    execStartDate: '2020-03-17T00:00:00.000Z',
    execEndDate: '2020-03-17T01:00:00.000Z',
    uploadFileName: 'upload_8.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_8.csv',
      title: 'upload_8.csv',
    },
    resultFileName: 'result_8.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_8.csv',
      title: 'result_8.csv',
    },
  },
  {
    id: '09',
    execStatus: 'Complete',
    execStartDate: '2020-03-18T00:00:00.000Z',
    execEndDate: '2020-03-18T01:00:00.000Z',
    uploadFileName: 'upload_9.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_9.csv',
      title: 'upload_9.csv',
    },
    resultFileName: 'result_9.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_9.csv',
      title: 'result_9.csv',
    },
  },
  {
    id: '10',
    execStatus: 'Complete',
    execStartDate: '2020-03-19T00:00:00.000Z',
    execEndDate: '2020-03-19T01:00:00.000Z',
    uploadFileName: 'upload_10.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_10.csv',
      title: 'upload_10.csv',
    },
    resultFileName: 'result_10.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_10.csv',
      title: 'result_10.csv',
    },
  },
  {
    id: '11',
    execStatus: 'Complete',
    execStartDate: '2020-03-20T00:00:00.000Z',
    execEndDate: '2020-03-20T01:00:00.000Z',
    uploadFileName: 'upload_11.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_11.csv',
      title: 'upload_11.csv',
    },
    resultFileName: 'result_11.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_11.csv',
      title: 'result_11.csv',
    },
  },
  {
    id: '12',
    execStatus: 'Complete',
    execStartDate: '2020-03-21T00:00:00.000Z',
    execEndDate: '2020-03-21T01:00:00.000Z',
    uploadFileName: 'upload_12.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_12.csv',
      title: 'upload_12.csv',
    },
    resultFileName: 'result_12.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_12.csv',
      title: 'result_12.csv',
    },
  },
  {
    id: '13',
    execStatus: 'Complete',
    execStartDate: '2020-03-22T00:00:00.000Z',
    execEndDate: '2020-03-22T01:00:00.000Z',
    uploadFileName: 'upload_13.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_13.csv',
      title: 'upload_13.csv',
    },
    resultFileName: 'result_13.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_13.csv',
      title: 'result_13.csv',
    },
  },
  {
    id: '14',
    execStatus: 'Complete',
    execStartDate: '2020-03-23T00:00:00.000Z',
    execEndDate: '2020-03-23T01:00:00.000Z',
    uploadFileName: 'upload_14.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_14.csv',
      title: 'upload_14.csv',
    },
    resultFileName: 'result_14.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_14.csv',
      title: 'result_14.csv',
    },
  },
  {
    id: '15',
    execStatus: 'Complete',
    execStartDate: '2020-03-24T00:00:00.000Z',
    execEndDate: '2020-03-24T01:00:00.000Z',
    uploadFileName: 'upload_15.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_15.csv',
      title: 'upload_15.csv',
    },
    resultFileName: 'result_15.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_15.csv',
      title: 'result_15.csv',
    },
  },
  {
    id: '16',
    execStatus: 'Complete',
    execStartDate: '2020-03-25T00:00:00.000Z',
    execEndDate: '2020-03-25T01:00:00.000Z',
    uploadFileName: 'upload_16.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_16.csv',
      title: 'upload_16.csv',
    },
    resultFileName: 'result_16.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_16.csv',
      title: 'result_16.csv',
    },
  },
  {
    id: '17',
    execStatus: 'Complete',
    execStartDate: '2020-03-26T00:00:00.000Z',
    execEndDate: '2020-03-26T01:00:00.000Z',
    uploadFileName: 'upload_17.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_17.csv',
      title: 'upload_17.csv',
    },
    resultFileName: 'result_17.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_17.csv',
      title: 'result_17.csv',
    },
  },
  {
    id: '18',
    execStatus: 'Complete',
    execStartDate: '2020-03-27T00:00:00.000Z',
    execEndDate: '2020-03-27T01:00:00.000Z',
    uploadFileName: 'upload_18.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_18.csv',
      title: 'upload_18.csv',
    },
    resultFileName: 'result_18.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_18.csv',
      title: 'result_18.csv',
    },
  },
  {
    id: '19',
    execStatus: 'Complete',
    execStartDate: '2020-03-28T00:00:00.000Z',
    execEndDate: '2020-03-28T01:00:00.000Z',
    uploadFileName: 'upload_19.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_19.csv',
      title: 'upload_19.csv',
    },
    resultFileName: 'result_19.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_19.csv',
      title: 'result_19.csv',
    },
  },
  {
    id: '20',
    execStatus: 'Complete',
    execStartDate: '2020-03-29T00:00:00.000Z',
    execEndDate: '2020-03-29T01:00:00.000Z',
    uploadFileName: 'upload_20.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_20.csv',
      title: 'upload_20.csv',
    },
    resultFileName: 'result_20.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_20.csv',
      title: 'result_20.csv',
    },
  },
  {
    id: '21',
    execStatus: 'Complete',
    execStartDate: '2020-03-30T00:00:00.000Z',
    execEndDate: '2020-03-30T01:00:00.000Z',
    uploadFileName: 'upload_21.csv',
    uploadFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/upload_21.csv',
      title: 'upload_21.csv',
    },
    resultFileName: 'result_21.csv',
    resultFile: {
      src: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/retrofitBulkAction/result_21.csv',
      title: 'result_21.csv',
    },
  },
];

export default retrofitBulkActions;
