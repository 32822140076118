import { GeoidData } from '../../types';

const geoids: GeoidData[] = [
  {
    id: 1,
    name: 'sample1',
    regionId: 1,
  },
  {
    id: 2,
    name: 'sample2',
    regionId: 2,
  },
  {
    id: 3,
    name: 'sample3',
    regionId: 3,
  },
  {
    id: 10,
    name: '地域1を選択すれば出る、それ以外は出ない',
    regionId: 10,
  },
];

export default geoids;
