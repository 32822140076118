import { RetrofitSendbackData } from '../../types';

const data: RetrofitSendbackData[] = [
  {
    id: 1,
    fromRetrofitId: 2,
    toRetrofitId: 143,
    notificationEmailAddress: 'text@xxxx.test.jp',
    shouldInitialization: false,
    isInitialized: false,
    isComplete: false,
  },
  {
    id: 2,
    fromRetrofitId: 9,
    toRetrofitId: 10,
    notificationEmailAddress: 'textb@xxxx.test.jp',
    shouldInitialization: false,
    isInitialized: false,
    isComplete: false,
  },
];
export default data;
