import React, { FC, useState } from 'react';
import { Box, Grid, createStyles, makeStyles } from '@material-ui/core';
import { LabelInfoInput } from '../map/controller/types';
import GeofenceMapCoordinateList from './GeofenceMapCoordinateList';
import GeofenceRadiusInputField from './GeofenceRadiusInputField';
import { Typography } from '../../../atoms';
import {
  Dimension,
  GeofenceTrigger,
  GeofenceType,
  UnitLength,
} from '../../../../dataProvider';
import { colors } from '../../../../theme';
import { LonLat } from '../../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // スクロールバー用スタイル（Firefox
      scrollbarColor: '#717284 transparent',
      scrollbarWidth: 'thin',
      // スクロールバー用スタイル（chrome,safari,Edge
      '&::-webkit-scrollbar': {
        width: 8,
        background: 'transparent',
      },
      '&::-webkit-scrollbar:horizontal': {
        height: 8,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 4,
        background: '#717284',
      },
      '&::-webkit-scrollbar-corner': {
        background: 'transparent',
      },
      height: 280,
      width: 240,
      backgroundColor: '#31323A',
      overflowY: 'auto',
      display: 'flex',
      padding: '17px 9px 17px 9px',
      '& > div .coordinates': {
        marginBottom: '8px',
        '& > p': {
          fontSize: 12,
        },
      },
      '& > div .coordinatesLabel': {
        marginTop: 4,
        marginBottom: 4,
        textAlign: 'left',
        fontSize: 12,
      },
      '& > div .unitLengthLabel': {
        marginTop: 4,
        marginBottom: 4,
        textAlign: 'right',
        fontSize: 12,
      },
      '& > div .circleRadius': {
        marginBottom: '8px',
        '& > p': {
          fontSize: 12,
        },
      },
      '& > div .elevation': {
        marginTop: '12px',
        '& > p': {
          fontSize: 12,
        },
        '& > div': {
          display: 'flex',
          alignItems: 'center',
          '& > input': {
            textAlign: 'right',
            flexWrap: 'wrap',
            border: 0,
            borderRadius: 3,
            background: '#17171A',
            width: 80,
            color: colors.text.primaryLight, // テキスト表示するので必要
            fontSize: 14, // テキスト表示するので必要
            textTransform: 'none', // テキスト表示するので必要
            // スピンボタンの削除（chrome,safari,Edge
            '&::-webkit-outer-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            '&::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            // スピンボタンの削除（Firefox
            '-moz-appearance': 'textfield',
          },
          '& > p': {
            fontSize: 12,
            marginLeft: '12px',
          },
        },
      },
      '& > div .height': {
        marginTop: '12px',
        '& > p': {
          fontSize: 12,
        },
        '& > div': {
          display: 'flex',
          alignItems: 'center',
          '& > input': {
            alignItems: 'center',
            textAlign: 'right',
            flexWrap: 'wrap',
            border: 0,
            borderRadius: 3,
            background: '#17171A',
            width: 80,
            color: colors.text.primaryLight, // テキスト表示するので必要
            fontSize: 14, // テキスト表示するので必要
            textTransform: 'none', // テキスト表示するので必要
            // スピンボタンの削除（chrome,safari,Edge
            '&::-webkit-outer-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            '&::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            // スピンボタンの削除（Firefox
            '-moz-appearance': 'textfield',
          },
          '& > p': {
            fontSize: 12,
            marginLeft: '12px',
          },
        },
      },
      '& > div .radius': {
        marginTop: '12px',
        '& > p': {
          fontSize: 12,
        },
        '& > div': {
          display: 'flex',
          alignItems: 'center',
          '& > input': {
            alignItems: 'center',
            textAlign: 'right',
            flexWrap: 'wrap',
            border: 0,
            borderRadius: 3,
            background: '#17171A',
            width: 80,
            color: colors.text.primaryLight, // テキスト表示するので必要
            fontSize: 14, // テキスト表示するので必要
            textTransform: 'none', // テキスト表示するので必要
            // スピンボタンの削除（chrome,safari,Edge
            '&::-webkit-outer-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            '&::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            // スピンボタンの削除（Firefox
            '-moz-appearance': 'textfield',
          },
          '& > p': {
            fontSize: 12,
            marginLeft: '12px',
          },
        },
      },
      '& > div .thickness': {
        marginTop: '12px',
        '& > p': {
          fontSize: 12,
        },
        '& > div': {
          display: 'flex',
          alignItems: 'center',
          '& > input': {
            alignItems: 'center',
            textAlign: 'right',
            flexWrap: 'wrap',
            border: 0,
            borderRadius: 3,
            background: '#17171A',
            width: 80,
            color: colors.text.primaryLight, // テキスト表示するので必要
            fontSize: 14, // テキスト表示するので必要
            textTransform: 'none', // テキスト表示するので必要
            // スピンボタンの削除（chrome,safari,Edge
            '&::-webkit-outer-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            '&::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            // スピンボタンの削除（Firefox
            '-moz-appearance': 'textfield',
          },
          '& > p': {
            fontSize: 12,
            marginLeft: '12px',
          },
        },
      },
      '& > div .trigger': {
        marginTop: '12px',
        '& > p': {
          fontSize: 12,
        },
        '& > div': {
          display: 'flex',
          alignItems: 'center',
          '& > select': {
            background: '#17171A',
            color: colors.text.primaryLight, // テキスト表示するので必要
            fontSize: 14, // テキスト表示するので必要
            textTransform: 'none', // テキスト表示するので必要
          },
          '& > p': {
            fontSize: 12,
            marginLeft: '12px',
          },
        },
      },
    },
  }),
);

export interface PopupData {
  id: number;
  coordinates: LonLat[];
  localCoordinates: number[][];
}

interface Props {
  data?: PopupData;
  labelInfo?: LabelInfoInput;
  unitLength: UnitLength;
  settingType: GeofenceType | undefined;
  settingDimension: Dimension | undefined;
  onChangeElevation: (event: any) => void;
  defaultElevation: number | undefined;
  onChangeHeight: (event: any) => void;
  defaultHeight: number | undefined;
  onChangeRadius: (event: any) => void;
  defaultRadius: number | undefined;
  onChangeThickness: (event: any) => void;
  defaultThickness: number | undefined;
  onChangeTrigger: (event: any) => void;
  defaultTrigger: GeofenceTrigger | undefined;
}

const GeofenceDetailInput: FC<Props> = ({
  data,
  labelInfo,
  unitLength,
  settingType,
  settingDimension,
  onChangeElevation,
  defaultElevation,
  onChangeHeight,
  defaultHeight,
  onChangeRadius,
  defaultRadius,
  onChangeThickness,
  defaultThickness,
  onChangeTrigger,
  defaultTrigger,
}) => {
  const classes = useStyles();
  const [elevation, setElevation] = useState(
    defaultElevation === undefined ? '' : defaultElevation,
  );
  const [elevationRequiredLabelDisplay, setElevationRequiredLabelDisplay] =
    useState(false);
  // height表示は3Dのみ
  const [height, setHeight] = useState(defaultHeight);
  const [heightRequiredLabelDisplay, setHeightRequiredLabelDisplay] =
    useState(false);
  // radius表示はCircle,Line(,Point?)のみ
  const [radius, setRadius] = useState(defaultRadius);
  const [radiusRequiredLabelDisplay, setRadiusRequiredLabelDisplay] =
    useState(false);
  // thickness表示はwallのみ
  const [thickness, setThickness] = useState(defaultThickness);
  const [thicknessRequiredLabelDisplay, setThicknessRequiredLabelDisplay] =
    useState(false);
  // trigger表示は2Dのみ
  const [trigger, setTrigger] = useState(defaultTrigger);
  const [triggerRequiredLabelDisplay, setTriggerRequiredLabelDisplay] =
    useState(false);

  // 高さ・半径・厚さの入力値を変換
  const convertInputValue = (inputValue: string) => {
    // 入力欄が空の場合、undefinedを返す
    if (inputValue === '') {
      return undefined;
    }
    // 負の数が入ってきたら正の数に変換して返す
    return Number(inputValue) < 0
      ? Math.abs(Number(inputValue))
      : Number(inputValue);
  };

  return (
    <Box className={classes.root}>
      <Box>
        <Box className="coordinates">
          <Grid style={{ display: 'flex' }}>
            <Grid item xs={12}>
              <Typography className="coordinatesLabel">
                {labelInfo?.coordinatesLabel}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="unitLengthLabel">
                {labelInfo?.unitLengthLabel(unitLength)}
              </Typography>
            </Grid>
          </Grid>
          <GeofenceMapCoordinateList
            width={228}
            height={
              data?.localCoordinates.length
                ? data?.localCoordinates.length * 20 + 3
                : 18
            }
            maxHeight={160}
            data={data}
            onChange={() => {
              // TODO: 選んだ座標に〇を付けたい
            }}
          />
        </Box>

        {settingType === 'Circle' ? (
          <Box className="circleRadius">
            <Typography>{labelInfo?.radius}</Typography>
            <Box className="radiusTypography">
              <GeofenceRadiusInputField
                width={84}
                height={radius ? 23 : 18}
                radius={radius}
              />
            </Box>
          </Box>
        ) : undefined}

        <Box className="elevation">
          <Typography
            color={elevationRequiredLabelDisplay ? 'error' : undefined}
          >
            {labelInfo?.elevation} *
          </Typography>
          <Box className="elevationInput">
            <input
              className="nonespin"
              type="number"
              id="elevation"
              data-testid="elevation"
              name="elevation"
              onChange={e => {
                onChangeElevation(e);
                setElevation(e.target.value ? Number(e.target.value) : '');
              }}
              onBlur={e => {
                setElevationRequiredLabelDisplay(!e.target.value);
              }}
              value={elevation === undefined ? '' : elevation}
              min="0"
              max="1000"
            />
            {elevationRequiredLabelDisplay ? (
              <Typography className="elevationRequiredLabel" color="error">
                {labelInfo?.reqiredLabel}
              </Typography>
            ) : (
              <></>
            )}
          </Box>
        </Box>

        {settingDimension === 'ThreeDimension' &&
        (settingType === 'Polygon' ||
          settingType === 'Circle' ||
          settingType === 'Wall') ? (
          <Box className="height">
            <Typography
              color={heightRequiredLabelDisplay ? 'error' : undefined}
            >
              {labelInfo?.height} *
            </Typography>
            <Box className="heightInput">
              <input
                type="number"
                id="height"
                data-testid="height"
                name="height"
                onChange={e => {
                  const heightValue = convertInputValue(e.target.value); // 高さの値を取得
                  onChangeHeight(e);
                  setHeight(heightValue);
                }}
                onBlur={() => {
                  // 値が0,または空欄の時に入力欄からカーソルを外した場合は必須エラーを表示する
                  if (!height) {
                    setHeight(undefined);
                  }
                  setHeightRequiredLabelDisplay(!height);
                }}
                value={height === undefined ? '' : height}
                min="0"
                max="1000"
              />
              {heightRequiredLabelDisplay ? (
                <Typography className="heightRequiredLabel" color="error">
                  {labelInfo?.reqiredLabel}
                </Typography>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        ) : undefined}

        {settingType === 'Line' ? (
          <Box className="radius">
            <Typography
              color={radiusRequiredLabelDisplay ? 'error' : undefined}
            >
              {labelInfo?.radius} *
            </Typography>
            <Box className="radiusInput">
              <input
                type="number"
                id="radius"
                data-testid="radius"
                name="radius"
                onChange={e => {
                  const radiusValue = convertInputValue(e.target.value); // 半径の値を取得
                  onChangeRadius(e);
                  setRadius(radiusValue);
                }}
                onBlur={() => {
                  // 値が0,または空欄の時に入力欄からカーソルを外した場合は必須エラーを表示する
                  if (!radius) {
                    setRadius(undefined);
                  }
                  setRadiusRequiredLabelDisplay(!radius);
                }}
                value={radius === undefined ? '' : radius}
                min="0"
                max="1000"
              />
              {radiusRequiredLabelDisplay ? (
                <Typography className="radiusRequiredLabel" color="error">
                  {labelInfo?.reqiredLabel}
                </Typography>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        ) : undefined}

        {settingType === 'Wall' ? (
          <Box className="thickness">
            <Typography
              color={thicknessRequiredLabelDisplay ? 'error' : undefined}
            >
              {labelInfo?.thickness} *
            </Typography>
            <Box className="thicknessInput">
              <input
                type="number"
                id="thickness"
                data-testid="thickness"
                name="thickness"
                onChange={e => {
                  const thicknessValue = convertInputValue(e.target.value); // 厚さの値を取得
                  onChangeThickness(e);
                  setThickness(thicknessValue);
                }}
                onBlur={() => {
                  // 値が0,または空欄の時に入力欄からカーソルを外した場合は必須エラーを表示する
                  if (!thickness) {
                    setThickness(undefined);
                  }
                  setThicknessRequiredLabelDisplay(!thickness);
                }}
                value={thickness === undefined ? '' : thickness}
                min="0"
                max="1000"
              />
              {thicknessRequiredLabelDisplay ? (
                <Typography className="thicknessRequiredLabel" color="error">
                  {labelInfo?.reqiredLabel}
                </Typography>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        ) : undefined}

        {settingDimension === 'TwoDimension' ? (
          <Box className="trigger">
            <Typography
              color={triggerRequiredLabelDisplay ? 'error' : undefined}
            >
              {labelInfo?.trigger} *
            </Typography>
            <Box className="triggerInput">
              <select
                id="trigger"
                data-testid="trigger"
                name="trigger"
                onChange={e => {
                  onChangeTrigger(e);
                  const inputValue = e.target.value;
                  if (
                    inputValue === 'In' ||
                    inputValue === 'Out' ||
                    inputValue === ''
                  ) {
                    setTrigger(inputValue === '' ? undefined : inputValue);
                  }
                }}
                onBlur={e => {
                  setTriggerRequiredLabelDisplay(!e.target.value);
                }}
                value={trigger}
              >
                <option value=""></option>
                <option value="In">In</option>
                <option value="Out">Out</option>
              </select>
              {triggerRequiredLabelDisplay ? (
                <Typography className="triggerRequiredLabel" color="error">
                  {labelInfo?.reqiredLabel}
                </Typography>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        ) : undefined}
      </Box>
    </Box>
  );
};

GeofenceDetailInput.displayName = 'GeofenceDetailInput';
export default GeofenceDetailInput;
