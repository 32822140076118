import React, { FC } from 'react';
import { FieldProps, InjectedFieldProps, TextField } from 'react-admin';
import { pick } from 'lodash';
import { useUnitLength } from '../../../hooks';
import { UnitLength } from '../../../dataProvider';

interface Props extends FieldProps, InjectedFieldProps {
  unitLength: UnitLength;
  roundUpDigits?: number;
}

const UnitLengthField: FC<Props> = ({
  source,
  record,
  unitLength,
  roundUpDigits,
  ...rest
}) => {
  const { meterToUnitLengthRoundUp } = useUnitLength();
  if (!record || !source) return null;
  const meterValue = record[source];
  const value = meterToUnitLengthRoundUp(meterValue, unitLength, roundUpDigits);
  return (
    <TextField
      // TextField には variant のみ props を伝達させるようにする
      //   - 任意のレコードを表示している為、ラップしている TextField には、addLabel, resource といった余計な props を直接伝達させたくない為、除外している
      //   - variant は TextField 内部のテキスト装飾パラメーターである（通常はデフォルト値として body2 が設定される）
      //   - variant を流さないと、Form / List で同じテキストサイズになってしまうため伝達が必要（List 表示の方が少し文字が小さい）
      //   - Form 配下では、Form 側から {... variant: undefined } の props が渡ってくる
      //   - List 配下では、variant は上書きされない
      {...pick(rest, ['variant'])}
      source="value"
      record={{ value }}
    />
  );
};

UnitLengthField.defaultProps = {
  ...UnitLengthField.defaultProps,
  addLabel: true,
};

UnitLengthField.displayName = 'UnitLengthField';
export default UnitLengthField;
