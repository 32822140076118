import React from 'react';
import { MapProps, crearteOverlayControl } from '../../../../../hooks';
import { GeofenceListDeleteButton } from '../../view';

const addListDeleteControl = (
  { map, record }: MapProps,
  onClick: () => void,
  dialogMessage?: string,
) => {
  map.addControl(
    crearteOverlayControl(
      'map_dele',
      <GeofenceListDeleteButton
        record={record}
        onClick={onClick}
        dialogMessage={dialogMessage}
      />,
      {
        right: 13,
        top: 370,
      },
    ),
  );
};

export default addListDeleteControl;
