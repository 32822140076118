import React from 'react';
import { useLocation } from 'react-router-dom';
import { TextField, Identifier } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CustomList,
  Datagrid,
  CustomDateField,
  Filter,
  SiteSearchInput,
  NtripNameField,
} from '../..';
import { config } from '../../../utils';
import { useResource, useJumpTo } from '../../../hooks';
import { BulkRegistrationButton } from './views';

const useStylesForProduction = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 16,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 92,
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 68,
          },
          '& .MuiTableCell-root:nth-child(7)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 60,
          },
          '& .MuiTableCell-root:nth-child(8)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 60,
          },
          '& .MuiTableCell-root:nth-child(9)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 92,
          },
        },
      },
    },
  }),
);

const useStylesForDevelopment = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 16,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 92,
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 68,
          },
          '& .MuiTableCell-root:nth-child(7)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 60,
          },
        },
      },
    },
  }),
);

const SerchFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

const RetrofitBasicInfoList: React.FC = props => {
  const classesForProduction = useStylesForProduction(); // REACT_APP_FEATURE_NTRIPS削除後、classesForProductionを削除する
  const classesForDevelopment = useStylesForDevelopment(); // REACT_APP_FEATURE_NTRIPS削除後、変数名をclassesに変更する
  const resource = useResource('retrofitBasicInfos');
  const { pathname: parentPath } = useLocation();
  const { gotoListEditRoutingTabbed } = useJumpTo();
  return config.feature.ntrips ? (
    <>
      <CustomList
        {...props}
        resource={resource}
        title="admin.pages.retrofitBasicInfoList"
        bulkActionButtons={<BulkRegistrationButton {...props} />}
        filters={<SerchFilter />}
        hasCreate
      >
        <Datagrid
          rowClick={(id: Identifier) => {
            gotoListEditRoutingTabbed(parentPath, id);
          }}
          className={classesForDevelopment.root}
        >
          <TextField resource={resource} source="uuid" />
          <TextField resource={resource} source="corporationId" />
          <TextField resource={resource} source="corporationName" />
          <TextField resource={resource} source="basicInfoSerialNumber" />
          <NtripNameField label="resources.ntrips.fields.name" />
          <CustomDateField resource={resource} source="lastUpdated" showTime />
        </Datagrid>
      </CustomList>
    </>
  ) : (
    // ↓ REACT_APP_FEATURE_NTRIPSが削除されたら合わせて削除する
    <>
      <CustomList
        {...props}
        resource={resource}
        title="admin.pages.retrofitBasicInfoList"
        bulkActionButtons={<BulkRegistrationButton {...props} />}
        filters={<SerchFilter />}
        hasCreate
      >
        <Datagrid
          rowClick={(id: Identifier) => {
            gotoListEditRoutingTabbed(parentPath, id);
          }}
          className={classesForProduction.root}
        >
          <TextField resource={resource} source="uuid" />
          <TextField resource={resource} source="corporationId" />
          <TextField resource={resource} source="corporationName" />
          <TextField resource={resource} source="basicInfoManufacturer" />
          <TextField resource={resource} source="basicInfoModel" />
          <TextField resource={resource} source="basicInfoProductNumber" />
          <TextField resource={resource} source="basicInfoSerialNumber" />
          <CustomDateField resource={resource} source="lastUpdated" showTime />
        </Datagrid>
      </CustomList>
    </>
  );
};

RetrofitBasicInfoList.displayName = 'RetrofitBasicInfoList';
export default RetrofitBasicInfoList;
