import { ImageField as RaImageField } from 'react-admin';
import { ResourceFieldFC } from '.';

interface Props {
  src?: string;
  title?: string;
  classes?: object;
}

const ImageField = RaImageField as ResourceFieldFC<Props>;
ImageField.displayName = 'ImageField';

export default ImageField;
