import React, { ReactElement, SyntheticEvent } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { SaveButton, RedirectionSideEffect, Record } from 'react-admin';
import { colors } from '../../theme';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../theme/define';

interface Props {
  classes?: object;
  className?: string;
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void;
  onSave?: (values: object, redirect: RedirectionSideEffect) => void;
  icon?: ReactElement;
  invalid?: boolean;
  label?: string;
  onClick?: () => void;
  pristine?: boolean;
  redirect?: RedirectionSideEffect;
  saving?: boolean;
  submitOnEnter?: boolean;
  variant?: string;
  basePath?: string;
  handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<object>;
  record?: Record;
  resource?: string;
  undoable?: boolean;
}

export type SaveButtonProps = Props & ButtonProps;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: colors.button.save,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.saveHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.saveDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
  }),
);

const CustomSaveButton: React.FC<SaveButtonProps> = props => {
  const classes = useStyles();
  const { label, children } = props;
  const testId = label || 'ra.action.save';
  return (
    <SaveButton
      {...props}
      className={classes.root}
      data-testid={testId}
      label=""
      icon={children}
    />
  );
};

const { defaultProps } = CustomSaveButton;
CustomSaveButton.defaultProps = {
  ...defaultProps,
};

CustomSaveButton.displayName = 'CustomSaveButton';
export default CustomSaveButton;
