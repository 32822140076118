import { config } from '../../utils';
import { UnusedTotal } from '.';
import { getHttpClient } from '..';

export default async (): Promise<UnusedTotal> => {
  const httpClient = getHttpClient();
  const uri = `${config.api.admin.apiUri}/ntrips/unusedTotal`;
  const { json } = await httpClient(uri);
  return json;
};
