import { RetrofitRegionData } from '../../types';

const retrofitRegions: RetrofitRegionData[] = [
  {
    id: 1,
    name: 'テスト出荷先０１',
    description: 'テスト出荷先０１備考',
  },
  {
    id: 2,
    name: 'テスト出荷先０２',
    description: 'テスト出荷先０２備考',
  },
];

export default retrofitRegions;
