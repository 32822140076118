import React, { FC, useState, cloneElement, isValidElement } from 'react';
import { ImageField } from 'react-admin';
import { makeStyles, createStyles, Dialog } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      '& > div': {
        justifyContent: 'center',
      },
      '& img': {
        objectFit: 'contain',
        maxWidth: '100%',
        maxHeight: '100%',
        margin: 0,
      },
    },
  }),
);

const DialogImagePreview: FC = ({ children, ...props }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <>
      <div onClick={() => setOpen(true)}>
        {children && isValidElement(children)
          ? cloneElement(children, props)
          : false}
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        data-testid={'image_preview_dialog'}
        fullWidth={true}
        maxWidth="lg"
      >
        <MuiDialogContent
          className={classes.image}
          onClick={() => setOpen(false)}
        >
          <ImageField {...props} />
        </MuiDialogContent>
      </Dialog>
    </>
  );
};

DialogImagePreview.displayName = 'DialogImagePreview';
export default DialogImagePreview;
