import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { colors } from '../../../../theme';
import { DownloadIcon } from '../../../../assets';
import { buttonDisabledOpacity } from '../../../../theme/define';
import { Button } from '../../..';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: colors.button.cancel,
      minWidth: 200,
      minHeight: 34, // 固有サイズ
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
  }),
);

const DownloadButton: FC = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const text = translate('admin.label.downloads.button');
  return (
    <Button
      className={classes.button}
      label={text}
      href={'/files/ProjectFileConverter.zip'}
      onClick={e => e.stopPropagation()}
      download
    >
      <DownloadIcon />
    </Button>
  );
};

DownloadButton.displayName = 'DownloadButton';
export default DownloadButton;
