import React from 'react';
import { UnitLength } from '../../../../../dataProvider';
import { MapProps, crearteOverlayControl } from '../../../../../hooks';
import GeofenceListShow from '../../view/GeofenceListShow';

const addDetailInfoControl = (
  { map, record }: MapProps,
  unitLength: UnitLength,
  labelInfo?: any,
) => {
  map.addControl(
    crearteOverlayControl(
      'map_detail',
      <GeofenceListShow
        record={record}
        unitLength={unitLength}
        labelInfo={labelInfo}
      />,
      {
        right: 8,
        top: 3,
      },
    ),
  );
};

export default addDetailInfoControl;
