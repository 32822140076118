/**
 * 入力確認バリデーション（ArrayInput用）
 * @param arryaInputSource 対象ArryaInputのsource名
 * @param targetSorce 入力チェックの比較元となるTextInputのsource名
 * @param errorMessage バリデーションエラー時の文言
 *
 * @example
 *
 * const confirmArrayValidation = useConfirmArrayValidation(
 *  'arryaInputSource',
 *  'targetSorce',
 *  'admin.message.functionxxxx.xxxxxxxxxx',
 * );
 *
 * [JSX]
 *   <ArrayInput src="arryaInputSource">
 *    <SimpleFormIterator>
 *      <TextInput src="targetSorce" />
 *      <TextInput
 *        ...
 *        validate={confirmArrayValidation}
 *      />
 *    </SimpleFormIterator>
 *   </ArrayInput>
 */
const useConfirmArrayValidation = (
  fieldName: string,
  targetName: string,
  errorMessage: string,
) => {
  return (value: any, allValues: any, props: any) => {
    // propsからIndex値を取得し、イベント発生行のコントロールにてチェック
    const nameProps = props.name;
    const startIndex = nameProps.indexOf('[');
    const endIndex = nameProps.indexOf(']');
    const index = nameProps.slice(startIndex + 1, endIndex);
    const targetCtrl = allValues[`${fieldName}`][index];
    if (!value || !targetCtrl) return null;
    if (value !== targetCtrl[`${targetName}`]) {
      return errorMessage;
    }
    return null;
  };
};

export default useConfirmArrayValidation;
