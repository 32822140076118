import { BooleanInput } from 'react-admin';
import { InputProps } from 'ra-core';
import { FormGroupProps } from '@material-ui/core/FormGroup';
import { SwitchProps } from '@material-ui/core/Switch';
import { ResourceFC } from '..';

type Props = InputProps<SwitchProps> &
  Omit<FormGroupProps, 'defaultValue' | 'onChange' | 'onBlur' | 'onFocus'>;

BooleanInput.displayName = 'BooleanInput';

export default BooleanInput as ResourceFC<Props>;
