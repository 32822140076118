import { useDataProvider } from 'react-admin';
import { SiteConfigureData } from '../../../../../../dataProvider';

const useSiteConfigure = () => {
  const dataProvider = useDataProvider();
  return async ({ siteId }: { siteId: string }) => {
    return dataProvider.getList<SiteConfigureData>('siteConfigures', {
      pagination: {
        page: 1,
        perPage: 1,
      },
      sort: { field: 'id', order: 'ASC' },
      filter: {
        siteId,
      },
    });
  };
};

export default useSiteConfigure;
