import React from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CustomList,
  Datagrid,
  IconField,
  TextField,
  ReferenceField,
  CustomDateField,
  FunctionField,
} from '../..';
import { useResource, useJumpTo } from '../../../hooks';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 102,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 96,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 230,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 230,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 150,
          },
        },
      },
    },
  }),
);
const ApprovalList: React.FC = () => {
  const translate = useTranslate();
  const resource = useResource('pendingApprovals');
  const classes = useStyles();
  const { gotoEdit } = useJumpTo();

  return (
    <CustomList
      resource={resource}
      title="admin.pages.approvalList"
      bulkActionButtons={false}
    >
      <Datagrid
        className={classes.root}
        rowClick={(id: string, baseResouce: string, record: any) => {
          // approvalTypeにより遷移先を制御
          switch (record.approvalType) {
            case 'Assignment':
              gotoEdit('approvals/assignments', record.dataId);
              break;
            case 'Transfer':
              gotoEdit('approvals/transfers', record.dataId);
              break;
            case 'Agent':
              gotoEdit('approvals', record.dataId);
              break;
            default:
              console.error(
                'invalid parameter on ApprovalList row click:',
                id,
                baseResouce,
                record,
              );
              break;
          }
        }}
      >
        <FunctionField
          resource={resource}
          label="resources.pendingApprovals.fields.approvalType"
          render={record => {
            const res = 'resources.pendingApprovals.approvalType';
            const type = record?.approvalType.toLowerCase();
            const trans = translate(`${res}.${type}`);
            // 翻訳できなければ、もとの内容をそのまま返す
            if (trans.startsWith(res)) return type;
            return trans;
          }}
        />
        <ReferenceField
          resource={resource}
          source="retrofitId"
          reference="retrofits"
          label="resources.retrofits.fields.machineInfoMachineType"
          link=""
        >
          <IconField resource="retrofits" source="machineInfoMachineType" />
        </ReferenceField>
        <ReferenceField
          resource={resource}
          label="resources.retrofits.fields.machineInfoMachineName"
          source="retrofitId"
          reference="retrofits"
          link=""
        >
          <TextField resource="retrofits" source="machineInfoMachineName" />
        </ReferenceField>
        <TextField resource={resource} source="requestedBy" />
        <CustomDateField resource={resource} source="lastUpdated" />
      </Datagrid>
    </CustomList>
  );
};

ApprovalList.displayName = 'ApprovalList';
export default ApprovalList;
