import { ReactElement } from 'react';
import { BulkDeleteButton as RaBulkDeleteButton } from 'react-admin';
import { ButtonProps } from '@material-ui/core/Button';
import { IconButtonProps } from '@material-ui/core/IconButton';
import { DialogProps } from '@material-ui/core/Dialog';

type MuiButtonProps = ButtonProps & IconButtonProps & DialogProps;

type MuiProps = MuiButtonProps;

interface RaProps {
  basePath?: string;
  classes?: object;
  label?: string;
  resource?: string;
  selectedIds?: any[];
  icon?: ReactElement;
}

const BulkDeleteButton = RaBulkDeleteButton as ForgivingComponentType<
  RaProps & MuiProps
>;
BulkDeleteButton.displayName = 'BulkDeleteButton';

export default BulkDeleteButton;
