import { SiteConfigureData } from '../../types';

const siteConfigures: SiteConfigureData[] = [
  {
    id: 1,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    unitLength: 'Ft',
    enableGeofence: true,
  },
  {
    id: 2,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    unitLength: 'M',
    enableGeofence: true,
  },
];

export default siteConfigures;
