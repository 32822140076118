import React, { useEffect, useState } from 'react';
import { ButtonProps as MuiProps } from '@material-ui/core/Button';
import { makeStyles, createStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useDataProvider } from 'react-admin';
import { Button } from '../../../..';
import { colors } from '../../../../../theme';
import { DownloadIcon } from '../../../../../assets';
import { buttonDisabledOpacity } from '../../../../../theme/define';
import { AsbuiltDownloadFileData } from '../../../../../dataProvider';
import { getDelimiterTypeApiString } from '../../../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: colors.button.cancel,
      minWidth: 80, // 固有サイズ
      minHeight: 34, // 固有サイズ
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
  }),
);

interface Props {
  label: string;
  asbuiltFileId?: string;
  decimalDelimiter: string;
  separatorDelimiter: string;
  execStatus: 'InProgress' | 'Complete' | 'Error' | 'CompleteWithoutData';
}

const AsbuiltCsvDownloadButton: React.FC<Props & MuiProps> = (
  props: Props & MuiProps,
) => {
  const {
    label,
    asbuiltFileId,
    decimalDelimiter,
    separatorDelimiter,
    execStatus,
  } = props;
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [apiState, setApiState] = useState<{
    fetched: boolean;
    loading: boolean;
    url?: string;
  }>({
    fetched: false,
    loading: true,
    url: undefined,
  });
  useEffect(() => {
    if (!asbuiltFileId) {
      // パラメータ不正時(asbuiltFileId未設定時)はステータスだけ更新してreturn
      setApiState({
        fetched: true,
        loading: false,
        url: '',
      });
      return;
    }
    if (apiState.fetched && apiState.url !== '') return;
    setApiState({ fetched: true, loading: true });
    const api = async () => {
      const result = await dataProvider.create('asbuiltDownloadFiles', {
        data: {
          asbuiltFileId,
          decimalDelimiter: getDelimiterTypeApiString(decimalDelimiter),
          separatorDelimiter: getDelimiterTypeApiString(separatorDelimiter),
        },
      });
      return result.data as AsbuiltDownloadFileData;
    };
    api().then(createdData => {
      setApiState({
        fetched: true,
        loading: false,
        url: execStatus === 'Complete' ? createdData.url : '',
      });
    });
  }, [
    dataProvider,
    apiState.fetched,
    apiState.url,
    asbuiltFileId,
    decimalDelimiter,
    separatorDelimiter,
    execStatus,
  ]);

  if (apiState.loading) return <LinearProgress />;

  return (
    <Button
      className={classes.root}
      label=""
      data-testid={label}
      href={apiState.url}
      onClick={e => e.stopPropagation()}
      download
      disabled={!apiState.url}
    >
      <DownloadIcon />
    </Button>
  );
};

AsbuiltCsvDownloadButton.displayName = 'AsbuiltCsvDownloadButton';
export default AsbuiltCsvDownloadButton;
