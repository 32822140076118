import { UserData } from '../..';

const users: UserData[] = [
  {
    id: '37ff0077-6319-4e80-af49-b8b2f2d26cfd',
    name: 'レトロフィット太郎',
    email: 'test_user@example.com',
    picture: 'https://sample.co.jp/v2/profile_image/pictures/default.png',
    language: 'ja',
    country: 'JP',
    zipCode: '105-0022',
    state: '東京都',
    city: '港区',
    address1: '海岸1-2-20',
    address2: '汐留ビルディング',
    role: 'super_admin', // staff

    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
  },
];

export default users;
