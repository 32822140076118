import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { RetrofitRentalData } from '../../../../../dataProvider';

interface InParams {
  retrofitRentalId: number;
  defaultDate: Date;
}

interface OutParams {
  data: {
    endDate: Date | undefined;
  };
}

const useGetRentalRecursive = () => {
  const dataProvider = useDataProvider();

  return useCallback(
    async ({ retrofitRentalId, defaultDate }: InParams): Promise<OutParams> => {
      // 返却済みの終了日を探索する再帰メソッド
      const getRentalRecursive = async (
        recursiveRetrofitRentalId: number,
        recursiveDefaultDate: Date,
      ): Promise<Date | undefined> => {
        const { data: rentalData } =
          await dataProvider.getList<RetrofitRentalData>('retrofitRentals', {
            pagination: {
              page: 1,
              perPage: 1, // 1件しか取る必要がない
            },
            sort: { field: 'id', order: 'DESC' },
            filter: {
              retrofitRentalId: recursiveRetrofitRentalId,
            },
          });
        if (rentalData.length <= 0) {
          // 紐付いているレンタル情報がない場合
          return recursiveDefaultDate;
        }

        const [childData] = rentalData;
        const { id, isReturned, actualStartDate, actualEndDate } = childData;

        if (isReturned) {
          // 返却済みであれば終了日を返す
          return new Date(actualEndDate);
        }

        // 未返却の場合は再起して更に紐づくレンタル情報を探す
        return getRentalRecursive(id, new Date(actualStartDate));
      };

      const endDate = retrofitRentalId
        ? await getRentalRecursive(retrofitRentalId, defaultDate)
        : undefined;
      return { data: { endDate } };
    },
    [dataProvider],
  );
};

export default useGetRentalRecursive;
