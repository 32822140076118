import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Breadcrumbs, Typography } from '@material-ui/core';
import { LinkProps } from '@material-ui/core/Link';
import { useTranslate } from 'react-admin';

export interface BreadcrumbsProps {
  breadcrumbs?: string[];
  customPaths?: string[];
  title?: string;
}

const BreadcrumbsContent: React.FC<BreadcrumbsProps> = ({
  breadcrumbs,
  customPaths,
  title,
}) => {
  const translate = useTranslate();
  // パンくずリストに表示するパスと名前のリストを作成して保存
  const { pathname } = useLocation();

  const pathmap = useMemo(() => {
    if (!breadcrumbs || !pathname) return [];
    // PG実装ミス時しかありえない想定だが念のためエラーハンドル
    if (customPaths && customPaths.length !== breadcrumbs.length)
      throw new Error('Invalid Params customPaths');
    const paths = pathname.split('/').filter(x => x);
    const map = breadcrumbs.map((value, index) => {
      const path = customPaths
        ? customPaths[index]
        : `/${paths.slice(0, index + 1).join('/')}`;
      const name = value;
      return { path, name };
    });
    // 空文字列を指定されている場合、パンくずリストに出さない
    return map.filter(({ name }) => name);
  }, [breadcrumbs, customPaths, pathname]);

  // React RouterのLinkコンポーネントをMaterial UIのLinkコンポーネントでラップ
  const LinkRouter = (props: { to: string; replace?: boolean } & LinkProps) => (
    <Link {...props} component={RouterLink as any} />
  );
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {pathmap.map(({ path, name }, index) => (
        <LinkRouter color="inherit" to={path} key={index}>
          {translate(name, { _: name })}
        </LinkRouter>
      ))}
      {title ? (
        <Typography color="textPrimary">
          {translate(title, { _: title })}
        </Typography>
      ) : null}
    </Breadcrumbs>
  );
};

export default BreadcrumbsContent;
