import React from 'react';
import { ButtonProps as MuiProps } from '@material-ui/core/Button';
import { makeStyles, createStyles } from '@material-ui/core';
import { Button } from '../..';
import { colors } from '../../../theme';
import { DownloadIcon } from '../../../assets';
import { buttonDisabledOpacity } from '../../../theme/define';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: colors.button.cancel,
      minWidth: 80, // 固有サイズ
      minHeight: 34, // 固有サイズ
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
    small: {
      backgroundColor: colors.button.cancel,
      minWidth: 44, // 固有サイズ（小）
      minHeight: 34, // 固有サイズ（小）
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
  }),
);

interface Props {
  label: string;
  url: string;
  smallicon?: boolean;
}

const ListDownloadButton: React.FC<Props & MuiProps> = (
  props: Props & MuiProps,
) => {
  const { label, url, smallicon } = props;
  const classes = useStyles();
  const settingClass = smallicon ? classes.small : classes.root;

  return (
    <Button
      className={settingClass}
      label=""
      data-testid={label}
      href={url}
      onClick={e => e.stopPropagation()}
      download
      disabled={!url}
    >
      <DownloadIcon />
    </Button>
  );
};

ListDownloadButton.displayName = 'ListDownloadButton';
export default ListDownloadButton;
