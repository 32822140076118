import React, { FC } from 'react';
import { useTranslate } from 'react-admin';

import { RetrofitData } from '../../../../../dataProvider';

const MachineTypeField: FC<{ record?: RetrofitData }> = ({ record }) => {
  const translate = useTranslate();
  const res = 'resources.retrofits.machineInfoMachineType';
  const type = record ? record?.machineInfoMachineType.toLowerCase() : <></>;
  const trans = translate(`${res}.${type}`);
  // 翻訳できなければ、もとの内容をそのまま返す
  if (trans.startsWith(res)) return <>{type}</>;
  return <>{trans}</>;
};

MachineTypeField.displayName = 'MachineTypeField';
export default MachineTypeField;
