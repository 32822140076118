/**
 * 使用ブラウザの小数点を確認
 * 小数点がピリオド(.)対応の時は1.23, コンマ(,)対応の時は1,23と出力
 * @returns
 */
const isConvertedToComma = (1.23).toLocaleString() === '1,23';

/**
 * 小数点を取得する
 * @returns
 */
const getDecimalPoint = (): string => {
  const defaultDecimalPoint = '.'; // 通常は小数点がドット
  const commaDecimalPoint = ','; // 一部地域は小数点がコンマ

  // 3パターン以上増えた場合は、三項演算子ではなく、switch文に書き換えること
  return isConvertedToComma ? commaDecimalPoint : defaultDecimalPoint;
};

/**
 * CSV区切り文字を取得する
 * @returns
 */
const getCsvDelimiter = (): string => {
  const delimiterDefault = ','; // 通常はカンマ区切り
  const delimiterSemicolon = ';'; // 一部地域はセミコロン区切り

  // 3パターン以上増えた場合は、三項演算子ではなく、switch文に書き換えること
  return isConvertedToComma ? delimiterSemicolon : delimiterDefault;
};

/**
 * 使用ブラウザに準じた小数値を取得する
 * @param target 変換対象数値
 * @returns 変換後数値文字列
 */
const convertNumberLocale = (
  target: number | undefined,
): string | undefined => {
  // 空欄の場合はそのまま返却
  if (!target) return undefined;
  // 使用ブラウザに準じて小数値を変換
  return target.toLocaleString(undefined, {
    useGrouping: false,
  });
};

/**
 * DelimiterTypeへの設定する記号がAPIへ渡す際の文字列になるよう変換
 * @param value 「.」「,」「;」
 * @returns 「Dot」「Comma」「Semicolon」
 */
const getDelimiterTypeApiString = (value: string): string => {
  switch (value) {
    case '.':
      return 'Dot';
    case ',':
      return 'Comma';
    case ';':
      return 'Semicolon';
    default:
      console.log('Error parm value from getDelimiterTypeApiString', value);
  }
  return '';
};

export {
  getDecimalPoint,
  getCsvDelimiter,
  convertNumberLocale,
  getDelimiterTypeApiString,
};
