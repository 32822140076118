import React, { useEffect, useState } from 'react';
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
} from '@material-ui/core';
import { Loading, useLogin, useNotify, useTranslate } from 'react-admin';
import { Button } from '../../bases';
import { redirectToLogin } from '../../../auth';
import { colors } from '../../../theme';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../../theme/define';
import { OkIcon } from '../../../assets';
import { apiErrorHandler } from '../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: colors.button.cancel,
      color: colors.text.primaryLight, // テキスト表示するので必要
      fontSize: 14, // テキスト表示するので必要
      textTransform: 'none', // テキスト表示するので必要
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
      '& span': {
        padding: 0,
      },
    },
    button: {
      background: colors.button.save,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.saveHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.saveDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
      '& span': {
        padding: 0,
      },
    },
  }),
);

const LoginButton: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const login = useLogin();
  const notify = useNotify();
  const translate = useTranslate();
  useEffect(() => {
    const { searchParams } = new URL(window.location.href);
    const code = searchParams.get('code');
    if (code) {
      setLoading(true);
      login({ code })
        .catch(error => {
          setLoading(false);
          setErrorMessage(apiErrorHandler(error, 'ra.auth.sign_in_error'));
        })
        .finally(() => {
          // URLからクエリ文字列を削除
          window.history.replaceState(
            {},
            window.document.title,
            window.location.origin,
          );
        });
    }
  }, [login, notify]);
  const handleLogin = () => {
    setLoading(true);
    redirectToLogin();
  };
  const handleClose = () => {
    setErrorMessage('');
  };

  if (loading) return <Loading />;

  return (
    <>
      <Button
        className={classes.root}
        onClick={handleLogin}
        disabled={loading}
        label="admin.label.login"
      />
      <Dialog open={!!errorMessage} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>{translate(errorMessage)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={handleClose}
            data-testid="admin.actions.ok"
          >
            <OkIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

LoginButton.displayName = 'LoginButton';
export default LoginButton;
