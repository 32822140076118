import React from 'react';
import { useRedirect, useTranslate } from 'react-admin';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import {
  SiteMasterMaterialList,
  SiteMasterWeatherList,
  SiteMasterOperatorList,
  Divider,
  TabData,
  TabContent,
} from '../../..';
import { useCustomLocation, useGetSite } from '../../../../hooks';

const useCreateSiteMasterShowTabs = (props: any): TabData[] => {
  const translate = useTranslate();
  const tabData: TabData[] = [];
  tabData.push({
    name: 'compactionMaterials',
    tab: (
      <MuiTab
        label={translate('resources.compactionMaterials.name')}
        key="tab_compactionMaterials"
        value="compactionMaterials"
      />
    ),
    content: <SiteMasterMaterialList {...props} />,
    order: 1,
  });

  tabData.push({
    name: 'weathers',
    tab: (
      <MuiTab
        label={translate('resources.weathers.name')}
        key="tab_weathers"
        value="weathers"
      />
    ),
    content: <SiteMasterWeatherList {...props} />,
    order: 2,
  });

  tabData.push({
    name: 'operators',
    tab: (
      <MuiTab
        label={translate('resources.operators.name')}
        key="tab_operators"
        value="operators"
      />
    ),
    content: <SiteMasterOperatorList {...props} />,
    order: 3,
  });
  return tabData.sort((lhs, rhs) => (lhs.order > rhs.order ? 1 : -1));
};

const SiteMasterShow: React.FC = () => {
  const uri = '/master';
  const { getValue, getBasePath } = useCustomLocation(uri);
  const redirectTo = useRedirect();
  const basePath = getBasePath();
  const handleChange = (_event: any, newValue: string) => {
    redirectTo(`${basePath}/${newValue}`);
  };

  const { name: siteName, id: siteId } = useGetSite() || { name: '', id: '' };
  const props = {
    basePath,
    siteName,
    siteId,
  };
  const tabs = useCreateSiteMasterShowTabs(props);
  const [firstTab] = tabs;
  const currentTab = getValue() || firstTab.name;
  return (
    <>
      <MuiTabs value={currentTab} onChange={handleChange}>
        {tabs.map(({ tab }) => tab)};
      </MuiTabs>
      <Divider />
      {tabs.map(({ name, content }) => {
        return (
          <TabContent name={name} key={name} value={currentTab}>
            {content}
          </TabContent>
        );
      })}
    </>
  );
};

SiteMasterShow.displayName = 'SiteMasterShow';
export default SiteMasterShow;
