import React from 'react';
import { Dimension, GeofenceType } from '../../../../../dataProvider';
import { MapProps, crearteOverlayControl } from '../../../../../hooks';
import { DimensionEditButton } from '../view';

const addDimensionControl = (
  { map }: MapProps,
  defaultDimension?: Dimension,
  settingType?: GeofenceType,
  onClick?: () => void,
) => {
  map.addControl(
    crearteOverlayControl(
      'map_dimension',
      <DimensionEditButton
        defaultDimension={defaultDimension}
        settingType={settingType}
        onClick={onClick}
      />,
      {
        left: 336,
        top: 350,
      },
    ),
  );
};

export default addDimensionControl;
