import { AdminApiConverter, ApiFilter } from '..';
import { RetrofitToposurveysData } from '../../types';

const converter: AdminApiConverter<
  'retrofitToposurveys',
  'retrofitToposurveys'
> = {
  resource: 'retrofitToposurveys',
  defaultSort: { field: 'surveyDate', order: 'DESC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'retrofitToposurveys'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('unitLength' in filter) apiFilter.unitLength = filter.unitLength;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('retrofitId' in filter) apiFilter.retrofitId = filter.retrofitId;
    if ('surveyDate' in filter) apiFilter.surveyDate = filter.surveyDate;
    if ('projectName' in filter) apiFilter.projectName = filter.projectName;
    if ('layerName' in filter) apiFilter.layerName = filter.layerName;
    if ('pointName' in filter) apiFilter.pointName = filter.pointName;
    if ('surveyPoint' in filter) apiFilter.surveyPoint = filter.surveyPoint;
    if ('n' in filter) apiFilter.n = filter.n;
    if ('e' in filter) apiFilter.e = filter.e;
    if ('z' in filter) apiFilter.z = filter.z;
    if ('userCorporationId' in filter)
      apiFilter.userCorporationId = filter.userCorporationId;
    return apiFilter;
  },
  sortFieldToApi: field => {
    if (field === 'userCorporationId')
      throw new Error('Unsupported sort field: userCorporationId');
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map((record): RetrofitToposurveysData => {
        return {
          id: record.id || '',
          retrofitId: record.retrofitId,
          surveyDate: record.surveyDate,
          projectName: record.projectName || '',
          layerName: record.layerName || '',
          pointName: record.pointName || '',
          surveyPoint: record.surveyPoint || '',
          n: record.n,
          e: record.e,
          z: record.z,
          userCorporationId: record.userCorporationId || '',
        };
      }),
    ),
  recordToApi: record => {
    return {
      retrofitId: record.retrofitId,
      surveyDate: record.surveyDate,
      projectName: record.projectName,
      layerName: record.layerName,
      pointName: record.pointName,
      surveyPoint: record.surveyPoint,
      n: record.n,
      e: record.e,
      z: record.z,
    };
  },
};
export default converter;
