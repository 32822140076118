import { ReferenceField as RaReferenceField } from 'react-admin';
import { ResourceFieldFC } from '.';
import { Resources } from '../../../dataProvider';

interface Props {
  reference?: keyof Resources;
  resource?: keyof Resources;
  translateChoice?: Function;
  linkType?: string | boolean | Function;
  link?: string | boolean | Function;
}

const ReferenceField = RaReferenceField as ResourceFieldFC<Props>;
ReferenceField.displayName = 'ReferenceField';

export default ReferenceField;
