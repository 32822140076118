import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button } from '../../..';
import { styles } from '../../../../theme';
import { MapDialogDelete } from '../../../../assets/images';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      ...styles.buttonIteratorDelete,
    },
  }),
);

const MapDialogDeleteButton: React.FC<{
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}> = ({ onClick, disabled }) => {
  const classes = useStyles();
  return (
    <Button onClick={onClick} className={classes.root} disabled={disabled}>
      <MapDialogDelete />
    </Button>
  );
};

MapDialogDeleteButton.displayName = 'MapDialogDeleteButton';
export default MapDialogDeleteButton;
