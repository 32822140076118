import { TabbedShowLayoutTabs as RaTabbedShowLayoutTabs } from 'react-admin';
import { TabProps as MuiProps } from '@material-ui/core/Tab';
import { ReactNodeLike } from 'prop-types';

interface RaProps {
  children: ReactNodeLike;
}

const TabbedShowLayoutTabs = RaTabbedShowLayoutTabs as ForgivingComponentType<
  MuiProps & RaProps
>;
TabbedShowLayoutTabs.displayName = 'TabbedShowLayoutTabs';

export default TabbedShowLayoutTabs;
