import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { mapStyle } from '../../../../../theme';
import {
  MapGeoPolygon,
  MapGeoCircle,
  MapGeoLine,
  MapGeoWall,
} from '../../../../../assets';
import { GeofenceType } from '../../../../../dataProvider';
import MapCommonButton from './MapCommonButton';

const useCurrentStyle = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#000344',
    },
  }),
);

const useStyle = makeStyles(() =>
  createStyles({
    buttonWrap: {
      ...mapStyle.buttonWrapHorizontal,
    },
  }),
);

const TypeEditButton: React.FC<{
  onClickPolygon: () => void;
  onClickCircle: () => void;
  onClickLine: () => void;
  onClickWall: () => void;
  selectedType?: GeofenceType;
  cancelEvent?: boolean;
}> = ({
  onClickPolygon,
  onClickCircle,
  onClickLine,
  onClickWall,
  selectedType = 'Polygon',
  cancelEvent = false,
}) => {
  const currentStyle = useCurrentStyle();
  const classes = useStyle();
  const [currentType, setCurrentType] = useState<GeofenceType>(selectedType);

  return (
    <div className={classes.buttonWrap}>
      <MapCommonButton
        svgIcon={<MapGeoPolygon />}
        testId={'admin.actions.mapPolygon'}
        onClick={() => {
          if (cancelEvent) return;
          onClickPolygon();
          setCurrentType('Polygon');
        }}
        classes={currentType === 'Polygon' ? currentStyle : undefined}
      />
      <MapCommonButton
        svgIcon={<MapGeoCircle />}
        testId={'admin.actions.mapCircle'}
        onClick={() => {
          if (cancelEvent) return;
          onClickCircle();
          setCurrentType('Circle');
        }}
        classes={currentType === 'Circle' ? currentStyle : undefined}
      />
      <MapCommonButton
        svgIcon={<MapGeoLine />}
        testId={'admin.actions.mapLine'}
        onClick={() => {
          if (cancelEvent) return;
          onClickLine();
          setCurrentType('Line');
        }}
        classes={currentType === 'Line' ? currentStyle : undefined}
      />
      <MapCommonButton
        svgIcon={<MapGeoWall />}
        testId={'admin.actions.mapWall'}
        onClick={() => {
          if (cancelEvent) return;
          onClickWall();
          setCurrentType('Wall');
        }}
        classes={currentType === 'Wall' ? currentStyle : undefined}
      />
    </div>
  );
};

TypeEditButton.displayName = 'TypeEditButton';
export default TypeEditButton;
