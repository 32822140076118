import React, { useEffect, useState } from 'react';
import { useDataProvider, useRefresh } from 'react-admin';
import { Box } from '@material-ui/core';
import { UserNoticeListCell } from '.';
import { AccountData, UserNoticeStatusData } from '../../../../dataProvider';
import { getI18nProvider } from '../../../../i18n';

const UserNoticeDrawerBody: React.FC = () => {
  const dataProvider = useDataProvider();
  const [noticeLists, setNoticeLists] = useState<UserNoticeStatusData[]>([]);
  const refresh = useRefresh();
  useEffect(() => {
    const { getOne } = dataProvider;
    const fn = async () => {
      // ログイン中のユーザーのメールアドレスと企業IDを取得
      const { data: accountData } = await getOne('accounts', { id: 1 });
      const { email } = accountData as AccountData;

      const i18nProvider = getI18nProvider();
      const language = i18nProvider.getLocale().toUpperCase();
      const { data } = await dataProvider.getList<UserNoticeStatusData>(
        'userNoticeStatus',
        {
          filter: { email, language },
          pagination: { page: 1, perPage: 6000 }, // NOTE: 全件取得する
          sort: { field: 'publicationStartDate', order: 'DESC' },
        },
      );
      setNoticeLists(data);
    };
    fn();
  }, [dataProvider, refresh]);
  return (
    <Box data-testid="userNoticeDrawer">
      {noticeLists.map((notice: UserNoticeStatusData, index: number) => (
        <UserNoticeListCell key={index} record={notice} />
      ))}
    </Box>
  );
};

UserNoticeDrawerBody.displayName = 'UserNoticeDrawerBody';
export default UserNoticeDrawerBody;
