import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useGetMany, TextField as RaTextField } from 'react-admin';
import { RetrofitData, LandlogCorporations } from '../../dataProvider';

const CorporationNameField: React.FC<{
  record?: RetrofitData;
  label?: string; // 內部で使わなくても <FormInput> 側で利用される
  addLabel?: boolean; // 內部で使わなくても <FormInput> 側で利用される
}> = ({ record }) => {
  const retrofitCorporationName = record?.corporationName || '';
  const retrofitCorporationId = record?.corporationId || '';
  const { data, loading } = useGetMany('corporations', [retrofitCorporationId]);
  if (loading) return <LinearProgress />;
  if (!data) return <></>;
  const corporationData = data[0] as LandlogCorporations;
  const name = corporationData?.name || retrofitCorporationName;
  return <RaTextField record={{ name }} source="name" />;
};

CorporationNameField.defaultProps = {
  addLabel: true, // Form 配下でラベルを出す為に設定
};

CorporationNameField.displayName = 'CorporationNameField';
export default CorporationNameField;
