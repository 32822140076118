import Feature from 'ol/Feature';
import Circle from 'ol/geom/Circle';
import {
  featureToCoordinateFromPolygon,
  featureToCoordinateFromLine,
  featureToCoordinateFromPoint,
} from './transform';
import { Geometry } from './types';

/**
 * Feature からタイプに応じた Geometry を生成
 *
 * @param param Feature 型
 * @returns Geometry 型 (未サポートの type を指定すると null を返す)
 */
const createGeometryFromFeature = (
  feature: Feature,
  geometryType: string,
): Geometry | null => {
  const name = feature.getGeometryName();
  switch (name) {
    case 'polygon':
      return createPolygonGeometry(feature);
    case 'circle':
      return createCircleGeometry(feature);
    case 'line':
      return createLineGeometry(feature);
    case 'point':
      return createPointGeometry(feature);
    case 'geometry':
      // コード (createFeatureFromGeometry) で Feature を生成した場合、
      // 'polygon' 以外は全て 'geometry' になる為、再変換するには独自の変換が必要
      return createGeometry(feature, geometryType);
    default:
  }
  console.warn(`Unsupported Feature: ${name}`);
  return null;
};

/**
 * name:geometryで登録された Feature から Geometry（Circle、Line、Point）を生成
 *
 * @param param Feature 型
 * @returns Geometry 型
 */
const createGeometry = (
  feature: Feature,
  geometryType: string,
): Geometry | null => {
  const geometory = feature.getGeometry() as any;
  switch (geometryType) {
    case 'Circle': {
      const tmp: number[] = geometory.getCenter();
      return {
        id: Number(feature.getId()),
        type: 'Circle',
        name: feature.get('name') || '',
        coordinates: [
          {
            lon: tmp[0],
            lat: tmp[1],
          },
        ],
        radius: geometory.getRadius(),
      };
    }
    case 'Line':
    case 'Wall': {
      const tmp: number[][] = geometory.getCoordinates();
      if (!tmp) return null;
      return {
        id: Number(feature.getId()),
        type: 'Line',
        name: feature.get('name') || '',
        coordinates: tmp.map(it => {
          const coordinate = it;
          return { lon: coordinate[0], lat: coordinate[1] };
        }),
      };
    }
    case 'Point': {
      const tmp: number[] = geometory.getCoordinates();
      if (!tmp) return null;
      const coordinate = tmp;
      return {
        id: Number(feature.getId()),
        type: 'Point',
        name: feature.get('name') || '',
        coordinates: [{ lon: coordinate[0], lat: coordinate[1] }],
      };
    }
    default:
      console.warn(`Unsupported Geometory Type: ${geometryType}`);
  }
  return null;
};

/**
 * 多角形の Geometry を生成
 *
 * @param param Feature 型
 * @returns Geometry 型
 */
const createPolygonGeometry = (feature: Feature): Geometry => {
  const coordinates = featureToCoordinateFromPolygon(feature)
    .flatMap(it => it)
    .map(([lon, lat]) => ({ lon, lat }));
  coordinates.pop(); // 末尾のデータは消す (先頭と同じデータの為）
  return {
    id: Number(feature.getId()),
    type: 'Polygon',
    name: feature.get('name') || '',
    coordinates,
  };
};

/**
 * 真円の Geometry を生成
 *
 * @param param Feature 型
 * @returns Geometry 型
 */
const createCircleGeometry = (feature: Feature): Geometry | null => {
  const circle = (feature as Feature<Circle>).getGeometry();
  if (!circle) return null;
  const center = circle.getCenter();
  const radius = circle.getRadius();
  const coordinate = { lon: center[0], lat: center[1] };
  return {
    id: Number(feature.getId()),
    type: 'Circle',
    name: feature.get('name') || '',
    coordinates: [coordinate],
    radius,
  };
};

/**
 * 直線の Geometry を生成
 *
 * @param param Feature 型
 * @returns Geometry 型
 */
const createLineGeometry = (feature: Feature): Geometry | null => {
  const coordinates = featureToCoordinateFromLine(feature).map(
    ([lon, lat]) => ({ lon, lat }),
  );
  return {
    id: Number(feature.getId()),
    type: 'Line',
    name: feature.get('name') || '',
    coordinates,
  };
};

/**
 * 点の Geometry を生成
 *
 * @param param Feature 型
 * @returns Geometry 型
 */
const createPointGeometry = (feature: Feature): Geometry | null => {
  const tmp = featureToCoordinateFromPoint(feature);
  const coordinates = [{ lon: tmp[0], lat: tmp[1] }];
  return {
    id: Number(feature.getId()),
    type: 'Point',
    name: feature.get('name') || '',
    coordinates,
  };
};

export default createGeometryFromFeature;
