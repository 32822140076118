import { useLocation, useParams } from 'react-router-dom';
import { Resources } from '../dataProvider';
import { AnyObject } from '../utils';
import { useResource } from '.';

const usePageProps = <T extends keyof Resources>(resource: T) => {
  const location = useLocation();
  const { pathname } = location;
  const lastPathIndex = pathname.lastIndexOf('/');
  const basePath = location.pathname.slice(0, lastPathIndex);
  const params = useParams<AnyObject>();
  return {
    location,
    basePath,
    resource: useResource(resource),
    id: params?.id,
  };
};

export default usePageProps;
