import React from 'react';
import { ListControllerProps } from 'react-admin';
import { Pagination } from '../..';

interface Props {
  rowsPerPageOptions: number[];
  limit?: React.ReactElement<any>;
}

const BottomPagination: React.FC<ListControllerProps & Props> = ({
  limit = <></>,
  ...rest
}) => {
  return (
    <Pagination
      {...rest}
      labelRowsPerPage=""
      labelDisplayedRows={() => ''}
      rowsPerPageOptions={[]}
      limit={limit}
    />
  );
};

BottomPagination.displayName = 'BottomPagination';
export default BottomPagination;
