import React from 'react';
import { makeStyles, createStyles, MuiThemeProvider } from '@material-ui/core';
import { Exporter } from 'react-admin';
import { ContentHeader, CustomExportButton } from '..';
import { getFilterTheme, styles } from '../../theme';
import { DownloadIcon } from '../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 0,
      paddingTop: 8,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      '& > div.icon': {
        '& > svg': {
          paddingRight: 8,
        },
      },
    },
    contentHeader: {
      width: '100%',
      height: 48,
      display: 'flex',
      alignItems: 'center',
      flexGlow: 1,
    },
    actions: {
      padding: 0,
      height: 48,
      display: 'flex',
      alignItems: 'center',
      '&:empty': {
        height: 0,
      },
    },
    align_horizontal: { width: '100%', display: 'flex', flexDirection: 'row' },
    pagination: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flexGrow: 1,
    },
    top_pagination: {
      '& .MuiToolbar-root': {
        marginRight: '20px',
        '& > div:last-child': {
          display: 'none',
        },
      },
      '& .MuiCardContent-root': {
        display: 'none',
      },
    },
    bottom_pagination: {
      '& .MuiTablePagination-caption': {
        display: 'none',
      },
      '& .MuiSelect-select.MuiSelect-select': {
        display: 'none',
      },
      '& .MuiSelect-icon': {
        display: 'none',
      },
    },
    search: {
      display: 'flex',
    },
    right_top: {
      display: 'flex',
    },
    export_button: {
      ...styles.iconLightGrayButton,
    },
  }),
);

interface Props {
  actions?: React.ReactElement<any>;
  subActions?: React.ReactElement<any>;
  filters?: React.ReactElement<any> | JSX.Element[];
  filterValues?: any;
  currentSort?: any;
  topPagination?: React.ReactElement<any>;
  bottomPagination?: React.ReactElement<any>;
  permanentFilter?: object;
  title?: string;
  icon?: React.SVGAttributes<SVGElement>;
  columnfilters?: React.ReactElement<any>;
  exporter?: Exporter;
  resource: string;
  listTotal: number;
}

const CustomListToolbar: React.FC<Props> = ({
  actions,
  subActions,
  filters,
  filterValues, // dynamically set via the UI by the user
  permanentFilter, // set in the List component by the developer
  currentSort,
  topPagination,
  bottomPagination,
  title,
  icon,
  columnfilters,
  exporter,
  resource,
  listTotal,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {title && (
          <ContentHeader
            className={classes.contentHeader}
            title={title}
            icon={icon}
          />
        )}
        <div className={classes.right_top}>
          {exporter && (
            <div>
              <CustomExportButton
                className={classes.export_button}
                disabled={listTotal === 0}
                label=""
                data-testid="list_export_button"
                exporter={exporter}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                icon={<DownloadIcon />}
              />
            </div>
          )}
          <div>
            {React.isValidElement(subActions)
              ? React.cloneElement(subActions, {
                  ...rest,
                  filters,
                  filterValues,
                  permanentFilter,
                })
              : null}
          </div>
        </div>
      </div>
      <div className={classes.align_horizontal}>
        <div className={classes.actions}>
          {React.isValidElement(actions)
            ? React.cloneElement(actions, {
                ...rest,
                filters,
                filterValues,
                permanentFilter,
              })
            : null}
        </div>
        <div className={classes.pagination}>
          {React.isValidElement(topPagination)
            ? React.cloneElement(topPagination, {
                ...rest,
                limitEnabled: false, // 0件時における、Empty 表示は、CustomList.tsx (footer 領域) の <TopPagination /> で表示する
              })
            : null}
        </div>
      </div>
      <div className={classes.align_horizontal}>
        <div>
          <MuiThemeProvider theme={getFilterTheme()}>
            <div className={classes.search}>
              {React.isValidElement(filters)
                ? React.cloneElement(filters, {
                    ...rest,
                    filterValues,
                    context: 'form',
                  })
                : null}
              {React.isValidElement(columnfilters)
                ? React.cloneElement(columnfilters, {
                    ...rest,
                    filterValues,
                    context: 'button',
                  })
                : null}
            </div>
            {React.isValidElement(columnfilters)
              ? React.cloneElement(columnfilters, {
                  ...rest,
                  filterValues,
                  context: 'form',
                })
              : null}
          </MuiThemeProvider>
        </div>
        <div className={classes.pagination}>
          {React.isValidElement(bottomPagination)
            ? React.cloneElement(bottomPagination, {
                ...rest,
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default CustomListToolbar;
