import React, { useState, useCallback } from 'react';
import { required, useNotify, useRedirect } from 'react-admin';
import {
  Create,
  CustomForm,
  BreadcrumbsFiled,
  TextInput,
  FunctionField,
  Typography,
  Loading,
  DialogOKOnly,
  CustomSaveButton,
  RetrofitAutoCompleteInput,
} from '../..';
import { useGetAccount, useGnssSettingProvider } from '../../../hooks';
import { GnssOwner, SaveIcon } from '../../../assets';
import gnssSettingUtils from './gnssSettingUtils';
import { GnssSettingData } from '../../../dataProvider';

const SaveButton: React.FC = (props: any) => {
  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const { form, basePath } = props;
  const notify = useNotify();
  const redirectTo = useRedirect();
  const gnssSettingProvider = useGnssSettingProvider(basePath);

  const handleShow = useCallback(
    (message: string) => {
      setDialogMessage(message);
      setOpen(true);
    },
    [setOpen, setDialogMessage],
  );
  const handleHide = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleResult = {
    onSuccess: () => {
      notify('admin.message.success', 'info');
      redirectTo(basePath);
    },
    onFailure: (error: Error) => {
      notify(`Error: ${error.message}`, 'warning');
    },
  };

  const handleClick = useCallback(
    values => {
      const retrofitId = form.getFieldState('retrofitId')?.value;
      if (!retrofitId) {
        // 対象建機が未設定の場合、そのままcreate処理を実施
        gnssSettingProvider.create(values, handleResult);
        return;
      }
      // 対象建機が設定されている場合は、選択した建機に別のGnss設定が紐づけられていないかを確認
      gnssSettingProvider.getRecordByRetrofitId(
        retrofitId,
        (gnssSetting: GnssSettingData | undefined) => {
          if (gnssSetting) {
            const { status } = gnssSetting;
            if (status === 'Sending') {
              // 選択した建機に紐づくGnss設定のステータスが 「Sending」であるためエラー
              handleShow(
                'admin.dialog.gnssSettings.unableSettingGnessInfo.message',
              );
              return;
            }
          }
          gnssSettingProvider.create(values, handleResult);
        },
      );
    },
    [form, handleShow, gnssSettingProvider, handleResult],
  );

  return (
    <>
      <DialogOKOnly
        open={open}
        onClose={handleHide}
        title=""
        message={dialogMessage}
      />
      <CustomSaveButton {...props} onSave={handleClick}>
        <SaveIcon />
      </CustomSaveButton>
    </>
  );
};

const GnssSettingOwnerCreate: React.FC<{
  basePath: string;
}> = ({ basePath }) => {
  const resource = 'gnssSettings';
  const { getCorporationId, getCorporationName, isLoading } = useGetAccount();
  const corporationId = getCorporationId();
  const corporationName = getCorporationName();
  const createProps = { resource, basePath };
  if (isLoading() || corporationId === '') return <Loading />;

  return (
    <Create {...createProps}>
      <CustomForm
        icon={<GnssOwner />}
        title="admin.pages.gnssSettingOwnerCreate"
        resource="gnssSettings"
        saveButton={<SaveButton />}
        initialValues={
          {
            ownerCorporationId: corporationId,
            ownerCorporationName: corporationName,
            registCorporationId: corporationId,
            registCorporationName: corporationName,
          } as any
        }
      >
        <BreadcrumbsFiled
          breadcrumbs={['resources.gnssSettings.name']}
          label="ra.action.create"
        />
        <FunctionField
          resource={resource}
          source="ownerCorporationId"
          label="resources.gnssSettings.fields.ownerCorporationId"
          render={() => {
            return <Typography>{corporationId}</Typography>;
          }}
        />
        <FunctionField
          resource={resource}
          source="ownerCorporationName"
          label="resources.gnssSettings.fields.ownerCorporationName"
          render={() => {
            return <Typography>{corporationName}</Typography>;
          }}
        />
        <TextInput resource={resource} source="name" validate={[required()]} />
        <TextInput resource={resource} source="url" validate={[required()]} />
        <TextInput
          resource={resource}
          source="portnum"
          validate={[required(), gnssSettingUtils.halfWidthAlphanumericValid]}
        />
        <TextInput
          resource={resource}
          source="userId"
          validate={[required()]}
        />
        <TextInput
          resource={resource}
          source="password"
          validate={[required()]}
        />
        <RetrofitAutoCompleteInput
          source="retrofitId"
          filter={{
            ownerCorporationId: corporationId,
          }}
          label="resources.gnssSettings.fields.retrofitId"
        />
      </CustomForm>
    </Create>
  );
};

GnssSettingOwnerCreate.displayName = 'gnssSettingOwnerCreate';
export default GnssSettingOwnerCreate;
