import { fetchUtils } from 'react-admin';
import { config } from '../../utils';
import { Token, UserInfo } from '.';

const { apiUri } = config.auth.landlog;
const uri = `${apiUri}/connect/userinfo`;

export default async ({ accessToken }: Token): Promise<UserInfo> => {
  const response = await fetchUtils.fetchJson(uri, {
    user: { authenticated: true, token: `Bearer ${accessToken}` },
  });
  const {
    sub,
    name,
    given_name: givenName,
    family_name: familyName,
    email,
    email_verified: emailVerified,
  } = response.json;
  return { sub, name, givenName, familyName, email, emailVerified };
};
