import { AdminApiConverter, ApiFilter } from '..';
import { RetrofitByTaskData } from '../../types';

const converter: AdminApiConverter<'retrofitByTasks', 'retrofitByTasks'> = {
  resource: 'retrofitByTasks',
  defaultSort: { field: 'machineInfoMachineName', order: 'ASC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'retrofitByTasks'> = {};
    if ('q' in filter) throw new Error('Unsupported "q" filter');
    if ('id' in filter) throw new Error('Unsupported filter field: id');
    if ('corporationId' in filter)
      throw new Error('Unsupported filter field: corporationId');
    if ('machineInfoMachineName' in filter)
      throw new Error('Unsupported filter field: machineInfoMachineName');
    if ('siteId' in filter) apiFilter.siteId = filter.siteId;
    if ('taskId' in filter) apiFilter.taskId = filter.taskId;
    return apiFilter;
  },
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map((record): RetrofitByTaskData => {
        return {
          id: record.id,
          siteId: record.siteId || '',
          corporationId: record.corporationId || '',
          machineInfoMachineName: record.machineInfoMachineName || '',
          taskId: record.taskId,
        };
      }),
    ),
  recordToApi: () => ({}), // 取得のみのAPIなので recordToApi は実装不要
};
export default converter;
