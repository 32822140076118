/**
 * 座標変換クラス (vbのコードをtsに移植)
 */
const M_E = 2.7182818284590451;
const DegToRadCoefficient = Math.PI / 180.0;
const RadToDegCoefficient = 1.0 / DegToRadCoefficient;
const A = 6378137.0;
const Small = 1.0e-15;
const E = 0.081819191042815792;
const IterationLimit = 100;

/**
 * ラジアンから角度への変換
 *
 * @param x ラジアン
 *
 * @returns 角度
 */
const radToDeg = (x: number): number => {
  return x * RadToDegCoefficient;
};

/**
 * 角度からラジアンへの変換
 * <param name="x">角度</param>
 * <returns>ラジアン</returns>
 */
const degToRad = (x: number): number => {
  return x * DegToRadCoefficient;
};

/**
 * 緯度経度を現場座標に変換する。
 *
 * @param longitude 変換対象の経度 [rad]
 * @param latitude 変換対象の緯度 [rad]
 * @param centerOfLatitude 緯度の原点 [rad]
 * @param centerOfLongitude 経度の原点 [rad]
 * @param falseEasting E 座標の原点 [m]
 * @param falseNorthing N 座標の原点 [m]
 * @param scale スケール
 * @param rotation 回転 [rad]
 *
 * @returns x: E 座標, y: N 座標
 */
const forward = (
  longitude: number,
  latitude: number,
  centerOfLatitude: number,
  centerOfLongitude: number,
  falseEasting: number,
  falseNorthing: number,
  scale: number,
  rotation: number,
): number[] => {
  const rho =
    (A * (1.0 - E ** 2)) /
    (1.0 - E ** 2 * Math.sin(centerOfLatitude) ** 2) ** (3.0 / 2.0);
  const nu = A / Math.sqrt(1.0 - E ** 2 * Math.sin(centerOfLatitude) ** 2);
  const coefR = Math.sqrt(rho * nu);
  const coefN = Math.sqrt(
    1.0 + (E ** 2 * Math.cos(centerOfLatitude) ** 4) / (1.0 - E ** 2),
  );
  const s1 =
    (1.0 + Math.sin(centerOfLatitude)) / (1.0 - Math.sin(centerOfLatitude));
  const s2 =
    (1.0 - E * Math.sin(centerOfLatitude)) /
    (1.0 + E * Math.sin(centerOfLatitude));
  const w1 = (s1 * s2 ** E) ** coefN;
  const sinChi = (w1 - 1.0) / (w1 + 1.0);
  const coefC =
    ((coefN + Math.sin(centerOfLatitude)) * (1.0 - sinChi)) /
    ((coefN - Math.sin(centerOfLatitude)) * (1.0 + sinChi));
  const w2 = coefC * w1;
  const chi0 = Math.asin((w2 - 1.0) / (w2 + 1.0));
  const delta0 = centerOfLongitude;
  const sinLatitude = Math.sin(latitude);
  const sA = (1.0 + sinLatitude) / (1.0 - sinLatitude);
  const sB = (1.0 - E * sinLatitude) / (1.0 + E * sinLatitude);
  const coefW = coefC * (sA * sB ** E) ** coefN;
  const delta = coefN * (longitude - delta0) + delta0;
  const chi = Math.asin((coefW - 1.0) / (coefW + 1.0));
  const coefB =
    1.0 +
    Math.sin(chi) * Math.sin(chi0) +
    Math.cos(chi) * Math.cos(chi0) * Math.cos(delta - delta0);
  const coefEast =
    falseEasting +
    (2.0 * coefR * scale * Math.cos(chi) * Math.sin(delta - delta0)) / coefB;
  const coefNorth =
    falseNorthing +
    (2.0 *
      coefR *
      scale *
      (Math.sin(chi) * Math.cos(chi0) -
        Math.cos(chi) * Math.sin(chi0) * Math.cos(delta - delta0))) /
      coefB;
  const east =
    falseEasting +
    ((coefEast - falseEasting) * Math.cos(-rotation) -
      (coefNorth - falseNorthing) * Math.sin(-rotation));
  const north =
    falseNorthing +
    ((coefEast - falseEasting) * Math.sin(-rotation) +
      (coefNorth - falseNorthing) * Math.cos(-rotation));
  return [east, north];
};

/**
 * 現場座標を緯度経度に変換する
 *
 * @param east Target Easting
 * @param north Target Northing
 * @param phi0 緯度の中心[ラジアン]
 * @param lamda0 経度の中心[ラジアン]
 * @param fe False Easting
 * @param fn False Northin
 * @param k0 Scale
 * @param r Rotation
 *
 * @returns x経度,y:緯度
 */
const reverse = (
  east: number,
  north: number,
  phi0: number,
  lamda0: number,
  fe: number,
  fn: number,
  k0: number,
  r: number,
): number[] => {
  const sinR = Math.sin(r);
  const cosR = Math.cos(r);

  const rE = fe + ((east - fe) * cosR - (north - fn) * sinR);
  const rN = fn + ((east - fe) * sinR + (north - fn) * cosR);

  const rho0 =
    (A * (1.0 - E ** 2)) / (1.0 - E ** 2 * Math.sin(phi0) ** 2) ** (3.0 / 2.0);

  const nu0 = A / Math.sqrt(1.0 - E ** 2 * Math.sin(phi0) ** 2);

  const rValue = Math.sqrt(rho0 * nu0);
  const n = Math.sqrt(1.0 + (E ** 2 * Math.cos(phi0) ** 4) / (1.0 - E ** 2));

  const s1 = (1.0 + Math.sin(phi0)) / (1.0 - Math.sin(phi0));
  const s2 = (1.0 - E * Math.sin(phi0)) / (1.0 + E * Math.sin(phi0));
  const w1 = (s1 * s2 ** E) ** n;
  const sinchi0 = (w1 - 1.0) / (w1 + 1.0);
  const c =
    ((n + Math.sin(phi0)) * (1.0 - sinchi0)) /
    ((n - Math.sin(phi0)) * (1.0 + sinchi0));
  const w2 = c * w1;
  const chi0 = Math.asin((w2 - 1.0) / (w2 + 1.0));
  const lLamda0 = lamda0;

  const g = 2.0 * rValue * k0 * Math.tan(Math.PI / 4.0 - chi0 / 2.0);
  const h = 4.0 * rValue * k0 * Math.tan(chi0) + g;
  const i = Math.atan((rE - fe) / (h + rN - fn));
  const j = Math.atan((rE - fe) / (g - rN + fn)) - i;
  const chi =
    chi0 +
    2.0 *
      Math.atan(
        (rN - fn - (rE - fe) * Math.tan(j / 2.0)) / (2.0 * rValue * k0),
      );
  const lLamda = j + 2.0 * i + lLamda0;
  const psi0 =
    (0.5 * Math.log((1.0 + Math.sin(chi)) / (c * (1.0 - Math.sin(chi))))) / n;

  const phi = [IterationLimit + 1];
  const psi = [IterationLimit + 1];
  phi[0] = 0;

  phi[1] = 2.0 * Math.atan(M_E ** psi0) - Math.PI / 2.0;
  psi[1] = Math.log(
    Math.tan(phi[1] / 2.0 + Math.PI / 4.0) *
      ((1.0 - E * Math.sin(phi[1])) / (1.0 + E * Math.sin(phi[1]))) **
        (E / 2.0),
  );

  let k = 1;
  while (Math.abs(phi[k] - phi[k - 1]) > Small && k < IterationLimit) {
    phi[k + 1] =
      phi[k] -
      ((psi[k] - psi0) *
        Math.cos(phi[k]) *
        Math.cos(1.0 - E ** 2 * Math.sin(phi[k]) ** 2)) /
        (1.0 - E ** 2);
    psi[k + 1] = Math.log(
      Math.tan(phi[k] / 2.0 + Math.PI / 4.0) *
        ((1.0 - E * Math.sin(phi[k])) / (1.0 + E * Math.sin(phi[k]))) **
          (E / 2.0),
    );
    k += 1;
  }

  const lamda = (lLamda - lLamda0) / n + lLamda0;

  return [radToDeg(lamda), radToDeg(phi[k])];
};

export { degToRad, forward, reverse };
