import React, { useState, useEffect } from 'react';
import { Button, makeStyles, createStyles } from '@material-ui/core';
import { ReactAdminComponentPropsWithId } from 'react-admin';
import { getDataProvider } from '../../../dataProvider';
import { colors } from '../../../theme';
import { RemoteConnectionIcon } from '../../../assets';
import { logger } from '../../../../src/utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 80,
      color: colors.text.primaryLight,
      backgroundColor: colors.button.cancel,
      fontWeight: 'normal',
      boxShadow: '0px 0px 4px #00000040',
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
    },
  }),
);

type RemoteSupportButtonProps = React.PropsWithChildren<
  Partial<
    ReactAdminComponentPropsWithId & {
      record: any;
      classes: object;
      label: string;
    }
  >
>;

const POINT_AVAILABILLITY_RETRY_MAX = 30;
const RemoteSupportButton: React.FC<RemoteSupportButtonProps> = (
  props: RemoteSupportButtonProps,
) => {
  const [remoteSupportUrl, setRemoteSupportUrl] = useState('');
  const [remoteSessionId, setRemoteSessionId] = useState('');
  const [pointAvailability, setPointAvailability] = useState<{
    requestStart: boolean;
    retryCount: number;
  }>({ requestStart: false, retryCount: 0 });
  const { record, classes: classOverride } = props;
  const classes = useStyles({ classes: classOverride });

  useEffect(() => {
    const dp = getDataProvider();
    const mac = record.id;

    dp.getOne('startremote', { id: mac }).then(startremoteapi => {
      const settingUrl = startremoteapi.data.remoteSupportUrl;
      setRemoteSupportUrl(settingUrl);
      // updateをbaseにしているため、id,previousData が必須だが、実際に使うのはdata.id内に格納されたremoteSessionIdのみ
      // ※id使用時は型エラーになってしまう
      setRemoteSessionId(startremoteapi.data.remoteSessionId);
    });
  }, [record]);

  // 指さしオンオフ制御要求
  useEffect(() => {
    // リモート接続完了するまで指さしオンオフ制御要求 API が成功しないため、成功するまで1秒おきにリトライする
    if (
      !pointAvailability.requestStart ||
      pointAvailability.retryCount > POINT_AVAILABILLITY_RETRY_MAX
    ) {
      return;
    }

    logger().debug(
      `StartPointAvailability RetryCount: ${pointAvailability.retryCount}`,
    );
    const dp = getDataProvider();
    dp.update('pointavailability', {
      id: remoteSessionId,
      data: { id: remoteSessionId },
      previousData: { id: remoteSessionId },
    }).then(pointavailabilityapi => {
      // 1秒遅延して API の結果を state に反映
      setTimeout(() => {
        setPointAvailability({
          requestStart:
            pointavailabilityapi.data.pointAvailabilityStatus !== '1000',
          retryCount: pointAvailability.retryCount + 1,
        });
      }, 1000);
    });
  }, [pointAvailability, remoteSessionId]);

  return remoteSupportUrl ? (
    <Button
      className={classes.root}
      onClick={(e: any) => {
        e.stopPropagation();
        // リモートサポート画面URL＆パラメータの設定
        window.open(`${remoteSupportUrl}`, '_blank');
        // 指さしオンオフ制御要求 API 送信開始
        setPointAvailability({
          requestStart: true,
          retryCount: 0,
        });
      }}
    >
      <RemoteConnectionIcon />
    </Button>
  ) : null;
};

RemoteSupportButton.displayName = 'RemoteSupportButton';
export default RemoteSupportButton;
