import { TermsConditionDocumentData } from '../../types';

const termsConditionDocuments: TermsConditionDocumentData[] = [
  {
    id: 1,
    termsConditionId: 1,
    title: 'Terms of service',
    termsFile: {
      src: '/files/TermsOfService_en.pdf',
      title: 'TermsOfService_en.pdf',
    },
    language: 'EN',
    referenceOrder: 1,
  },
  {
    id: 2,
    termsConditionId: 1,
    title: 'Agreement',
    termsFile: {
      src: '/files/Agreement_en.pdf',
      title: 'Agreement_en.pdf',
    },
    language: 'EN',
    referenceOrder: 2,
  },
  {
    id: 3,
    termsConditionId: 4,
    title: '約款',
    termsFile: {
      src: '/files/Agreement_ja.pdf',
      title: 'Agreement_ja.pdf',
    },
    language: 'JA',
    referenceOrder: 2,
  },
  {
    id: 4,
    termsConditionId: 4,
    title: '利用規約',
    termsFile: {
      src: '/files/TermsOfService_ja.pdf',
      title: 'TermsOfService_ja.pdf',
    },
    language: 'JA',
    referenceOrder: 1,
  },
  {
    id: 5,
    termsConditionId: 2,
    title: 'Oceania Service only',
    termsFile: {
      src: '/files/TermsOfService_en.pdf',
      title: 'TermsOfService_en.pdf',
    },
    language: 'EN',
    referenceOrder: 1,
  },
];

export default termsConditionDocuments;
