import { math as mathUtil, logger } from '../../../../utils';

/**
 * 面積ゼロの領域か検証するメソッド
 *
 * @param coordinates 座標配列
 *
 * @returns true: 面積がゼロである / false 面積がゼロではない
 */
const checkAreaZeroByArray = (coordinates: number[][]): boolean => {
  if (coordinates.length <= 0) {
    return true;
  }
  const [firstCoordinate] = coordinates;
  const lastCoordinate = coordinates[coordinates.length - 1];
  const roundDigits = 1; // 小数第1位まで確保する（第2位で四捨五入する）
  const result = coordinates
    .map(coordinate => {
      if (coordinate.length !== 2) {
        // x, y の要素が無いと無効とする
        return 0;
      }
      // 最初の座標とのベクトルを求める
      const vec = {
        x: coordinate[0] - firstCoordinate[0],
        y: coordinate[1] - firstCoordinate[1],
      };
      const radian = Math.atan2(vec.y, vec.x);
      const rawDegree = (radian * 180.0) / Math.PI;
      const degree = rawDegree >= 0 ? rawDegree : rawDegree + 180;
      return mathUtil.roundUp(degree, roundDigits);
    })
    .filter(degree => degree !== 0);

  logger().debug('checkAreaZeroByArray', { coordinates, result });

  const degrees = [...Array.from(new Set(result))]; // 重複角度のデータを除去する
  if (degrees.length <= 1) return true;
  // 始点と終点の同一座標チェック
  if (JSON.stringify(firstCoordinate) !== JSON.stringify(lastCoordinate)) {
    return true;
  }

  return false;
};

export default checkAreaZeroByArray;
