import { GnssSettingData } from '../../types';

const gnssSettings: GnssSettingData[] = [
  {
    id: 'b1d7cc36-6d37-9570-98b8-c7c86f88760b',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting001',
    url: 'https://gnsssetting.com/Setting001',
    portnum: '1001',
    userId: 'user001',
    password: 'pass001',
    retrofitId: 1,
    status: 'Sending',
    ntripInfo: 'Setting001/user001',
    updateDate: '2020-08-24T01:01:01.000Z',
  },
  {
    id: 'e21267ca-f3b4-adc0-27a1-0cc02b769ebd',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting002',
    url: 'https://gnsssetting.com/Setting002',
    portnum: '1001',
    userId: 'user002',
    password: 'pass002',
    retrofitId: 2,
    status: 'Sent',
    ntripInfo: 'Setting002/user002',
    updateDate: '2020-08-24T01:01:02.000Z',
  },
  {
    id: 'b2547913-f868-a927-27de-d19fc4fb415a',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting003',
    url: 'https://gnsssetting.com/Setting003',
    portnum: '1001',
    userId: 'user003',
    password: 'pass003',
    retrofitId: 3,
    status: 'FailToSend',
    ntripInfo: 'Setting003/user003',
    updateDate: '2020-08-24T01:01:03.000Z',
  },
  {
    id: '3be73c81-3fef-16f6-3570-23219ac088d1',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting004',
    url: 'https://gnsssetting.com/Setting004',
    portnum: '1001',
    userId: 'user004',
    password: 'pass004',
    retrofitId: 4,
    status: 'Sending',
    ntripInfo: 'Setting004/user004',
    updateDate: '2020-08-24T01:01:04.000Z',
  },
  {
    id: 'e6bf09ae-e294-dfe4-31df-9b56bf5d0279',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting005',
    url: 'https://gnsssetting.com/Setting005',
    portnum: '1001',
    userId: 'user005',
    password: 'pass005',
    retrofitId: 5,
    status: 'Sending',
    ntripInfo: 'Setting005/user005',
    updateDate: '2020-08-24T01:01:05.000Z',
  },
  {
    id: 'd83f9b34-2920-7ca0-cddd-86213e6169a9',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting006',
    url: 'https://gnsssetting.com/Setting006',
    portnum: '1001',
    userId: 'user006',
    password: 'pass006',
    retrofitId: 6,
    status: 'Sending',
    ntripInfo: 'Setting006/user006',
    updateDate: '2020-08-24T01:01:06.000Z',
  },
  {
    id: '61ea898a-9369-3044-fa80-4893a1e6f518',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting007',
    url: 'https://gnsssetting.com/Setting007',
    portnum: '1001',
    userId: 'user007',
    password: 'pass007',
    retrofitId: 7,
    status: 'Sending',
    ntripInfo: 'Setting007/user007',
    updateDate: '2020-08-24T01:01:07.000Z',
  },
  {
    id: '6cbc9e4f-67da-b0ab-ef04-32ddc5e3c6a9',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting008',
    url: 'https://gnsssetting.com/Setting008',
    portnum: '1001',
    userId: 'user008',
    password: 'pass008',
    retrofitId: 8,
    status: 'Sending',
    ntripInfo: 'Setting008/user008',
    updateDate: '2020-08-24T01:01:08.000Z',
  },
  {
    id: '7931cc1d-5683-c1cb-1bb1-12434560ce46',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting009',
    url: 'https://gnsssetting.com/Setting009',
    portnum: '1001',
    userId: 'user009',
    password: 'pass009',
    retrofitId: 9,
    status: 'Sending',
    ntripInfo: 'Setting009/user009',
    updateDate: '2020-08-24T01:01:09.000Z',
  },
  {
    id: 'c28aed65-e111-5b08-a5f4-456e79bcfe70',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting010',
    url: 'https://gnsssetting.com/Setting010',
    portnum: '1001',
    userId: 'user010',
    password: 'pass010',
    retrofitId: 10,
    status: 'Sending',
    ntripInfo: 'Setting010/user010',
    updateDate: '2020-08-24T01:01:10.000Z',
  },
  {
    id: '81297680-41c7-1077-8444-ab6307682459',
    registCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    registCorporationName: 'クオリカ',
    ownerCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationName: 'クオリカ',
    name: 'Setting011',
    url: 'https://gnsssetting.com/Setting011',
    portnum: '1001',
    userId: 'user011',
    password: 'pass011',
    retrofitId: 11,
    status: 'Sent',
    ntripInfo: 'Setting011/user011',
    updateDate: '2020-08-24T01:01:11.000Z',
  },
  {
    id: '5980c64a-2676-9418-9a96-fefd92160bdc',
    registCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    registCorporationName: 'クオリカ',
    ownerCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationName: 'クオリカ',
    name: 'Setting012',
    url: 'https://gnsssetting.com/Setting012',
    portnum: '1001',
    userId: 'user012',
    password: 'pass012',
    retrofitId: 12,
    status: 'Sent',
    ntripInfo: 'Setting012/user012',
    updateDate: '2020-08-24T01:01:12.000Z',
  },
  {
    id: '163abcfa-d76d-00ca-31a8-f42fcd3786b6',
    registCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    registCorporationName: 'クオリカ',
    ownerCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationName: 'クオリカ',
    name: 'Setting013',
    url: 'https://gnsssetting.com/Setting013',
    portnum: '1001',
    userId: 'user013',
    password: 'pass013',
    retrofitId: 13,
    status: 'Sent',
    ntripInfo: 'Setting013/user013',
    updateDate: '2020-08-24T01:01:13.000Z',
  },
  {
    id: 'c004cfcc-0102-e48a-9847-8e5a6fc4b9e9',
    registCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    registCorporationName: 'クオリカ',
    ownerCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationName: 'クオリカ',
    name: 'Setting014',
    url: 'https://gnsssetting.com/Setting014',
    portnum: '1001',
    userId: 'user014',
    password: 'pass014',
    retrofitId: 14,
    status: 'Sent',
    ntripInfo: 'Setting014/user014',
    updateDate: '2020-08-24T01:01:14.000Z',
  },
  {
    id: 'bc53abc1-b4be-2f31-c7a4-3bce4835d985',
    registCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    registCorporationName: 'クオリカ',
    ownerCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationName: 'クオリカ',
    name: 'Setting015',
    url: 'https://gnsssetting.com/Setting015',
    portnum: '1001',
    userId: 'user015',
    password: 'pass015',
    retrofitId: 15,
    status: 'Sent',
    ntripInfo: 'Setting015/user015',
    updateDate: '2020-08-24T01:01:15.000Z',
  },
  {
    id: '5de9b67b-6c4f-30b8-6a58-43b96e6f90b5',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting016',
    url: 'https://gnsssetting.com/Setting016',
    portnum: '1001',
    userId: 'user016',
    password: 'pass016',
    retrofitId: 16,
    status: 'Sent',
    ntripInfo: 'Setting016/user016',
    updateDate: '2020-08-24T01:01:16.000Z',
  },
  {
    id: '612dfabc-3962-a47a-ccae-1674186f4b72',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting017',
    url: 'https://gnsssetting.com/Setting017',
    portnum: '1001',
    userId: 'user017',
    password: 'pass017',
    retrofitId: 17,
    status: 'Sent',
    ntripInfo: 'Setting017/user017',
    updateDate: '2020-08-24T01:01:17.000Z',
  },
  {
    id: '611be486-82e2-f639-c2bf-9658eb66908a',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting018',
    url: 'https://gnsssetting.com/Setting018',
    portnum: '1001',
    userId: 'user018',
    password: 'pass018',
    retrofitId: 18,
    status: 'Sent',
    ntripInfo: 'Setting018/user018',
    updateDate: '2020-08-24T01:01:18.000Z',
  },
  {
    id: 'ec7bc267-c64f-37ee-3e1d-60e2420ac6be',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting019',
    url: 'https://gnsssetting.com/Setting019',
    portnum: '1001',
    userId: 'user019',
    password: 'pass019',
    retrofitId: 19,
    status: 'Sent',
    ntripInfo: 'Setting019/user019',
    updateDate: '2020-08-24T01:01:19.000Z',
  },
  {
    id: 'd84f56f1-8edb-9a70-d1e3-c7d05dbed46b',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting020',
    url: 'https://gnsssetting.com/Setting020',
    portnum: '1001',
    userId: 'user020',
    password: 'pass020',
    retrofitId: 20,
    status: 'Sent',
    ntripInfo: 'Setting020/user020',
    updateDate: '2020-08-24T01:01:20.000Z',
  },
  {
    id: '8cc897bd-962c-ee4e-261a-325c8ff9010a',
    registCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    registCorporationName: 'クオリカ',
    ownerCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationName: 'クオリカ',
    name: 'Setting021',
    url: 'https://gnsssetting.com/Setting021',
    portnum: '1001',
    userId: 'user021',
    password: 'pass021',
    retrofitId: 21,
    status: 'Sent',
    ntripInfo: 'Setting021/user021',
    updateDate: '2020-08-24T01:01:21.000Z',
  },
  {
    id: 'addc0b71-9768-bde4-5f63-9edca04e61cf',
    registCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    registCorporationName: 'クオリカ',
    ownerCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationName: 'クオリカ',
    name: 'Setting022',
    url: 'https://gnsssetting.com/Setting022',
    portnum: '1001',
    userId: 'user022',
    password: 'pass022',
    retrofitId: 22,
    status: 'Sent',
    ntripInfo: 'Setting022/user022',
    updateDate: '2020-08-24T01:01:22.000Z',
  },
  {
    id: '728dc8b6-9c9d-b577-5207-d4d05ad02ca7',
    registCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    registCorporationName: 'クオリカ',
    ownerCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationName: 'クオリカ',
    name: 'Setting023',
    url: 'https://gnsssetting.com/Setting023',
    portnum: '1001',
    userId: 'user023',
    password: 'pass023',
    retrofitId: 23,
    status: 'Sent',
    ntripInfo: 'Setting023/user023',
    updateDate: '2020-08-24T01:01:23.000Z',
  },
  {
    id: 'b794495a-7485-41ad-3b3e-04d274126f5e',
    registCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    registCorporationName: 'クオリカ',
    ownerCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationName: 'クオリカ',
    name: 'Setting024',
    url: 'https://gnsssetting.com/Setting024',
    portnum: '1001',
    userId: 'user024',
    password: 'pass024',
    retrofitId: 24,
    status: 'Sent',
    ntripInfo: 'Setting024/user024',
    updateDate: '2020-08-24T01:01:24.000Z',
  },
  {
    id: '1c28d065-b6da-7096-7fdb-7b73eb53d78d',
    registCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    registCorporationName: 'クオリカ',
    ownerCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    ownerCorporationName: 'クオリカ',
    name: 'Setting025',
    url: 'https://gnsssetting.com/Setting025',
    portnum: '1001',
    userId: 'user025',
    password: 'pass025',
    retrofitId: 25,
    status: 'Sent',
    ntripInfo: 'Setting025/user025',
    updateDate: '2020-08-24T01:01:25.000Z',
  },
  {
    id: '94fc22df-5b77-2b54-86ee-ff850f1104f1',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting026',
    url: 'https://gnsssetting.com/Setting026',
    portnum: '1001',
    userId: 'user026',
    password: 'pass026',
    retrofitId: 26,
    status: 'Sent',
    ntripInfo: 'Setting026/user026',
    updateDate: '2020-08-24T01:01:26.000Z',
  },
  {
    id: 'c204e96b-ba25-852a-9d08-3bc7368a8403',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting027',
    url: 'https://gnsssetting.com/Setting027',
    portnum: '1001',
    userId: 'user027',
    password: 'pass027',
    retrofitId: 27,
    status: 'Sent',
    ntripInfo: 'Setting027/user027',
    updateDate: '2020-08-24T01:01:27.000Z',
  },
  {
    id: 'a00b7e2b-ef98-7abe-1a16-b45ab50ab5b1',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting028',
    url: 'https://gnsssetting.com/Setting028',
    portnum: '1001',
    userId: 'user028',
    password: 'pass028',
    retrofitId: 28,
    status: 'Sent',
    ntripInfo: 'Setting028/user028',
    updateDate: '2020-08-24T01:01:28.000Z',
  },
  {
    id: 'fef037f2-02ff-1263-2f9e-973a4fe0ace1',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting029',
    url: 'https://gnsssetting.com/Setting029',
    portnum: '1001',
    userId: 'user029',
    password: 'pass029',
    retrofitId: 29,
    status: 'Sent',
    ntripInfo: 'Setting029/user029',
    updateDate: '2020-08-24T01:01:29.000Z',
  },
  {
    id: '82563fa6-cf5f-3302-ed70-b54d24ad0003',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting030',
    url: 'https://gnsssetting.com/Setting030',
    portnum: '1001',
    userId: 'user030',
    password: 'pass030',
    retrofitId: 30,
    status: 'Sent',
    ntripInfo: 'Setting030/user030',
    updateDate: '2020-08-24T01:01:30.000Z',
  },
  {
    id: '3d48464c-acbc-488c-6e96-b1e858226195',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting031',
    url: 'https://gnsssetting.com/Setting031',
    portnum: '1001',
    userId: 'user031',
    password: 'pass031',
    retrofitId: 31,
    status: 'Sent',
    ntripInfo: 'Setting031/user031',
    updateDate: '2020-08-24T01:01:31.000Z',
  },
  {
    id: 'e7276d3c-221b-6b94-da37-343df5d511d0',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting032',
    url: 'https://gnsssetting.com/Setting032',
    portnum: '1001',
    userId: 'user032',
    password: 'pass032',
    retrofitId: 32,
    status: 'Sent',
    ntripInfo: 'Setting032/user032',
    updateDate: '2020-08-24T01:01:32.000Z',
  },
  {
    id: '0eb03572-9712-21f5-f08c-11a7e2c46e8d',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting033',
    url: 'https://gnsssetting.com/Setting033',
    portnum: '1001',
    userId: 'user033',
    password: 'pass033',
    retrofitId: 33,
    status: 'Sent',
    ntripInfo: 'Setting033/user033',
    updateDate: '2020-08-24T01:01:33.000Z',
  },
  {
    id: '6d213445-bed6-eb18-24e1-bf608158ba98',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting034',
    url: 'https://gnsssetting.com/Setting034',
    portnum: '1001',
    userId: 'user034',
    password: 'pass034',
    retrofitId: 34,
    status: 'Sent',
    ntripInfo: 'Setting034/user034',
    updateDate: '2020-08-24T01:01:34.000Z',
  },
  {
    id: '9c496ed3-24db-9f07-4e53-e4297a8c879d',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting035',
    url: 'https://gnsssetting.com/Setting035',
    portnum: '1001',
    userId: 'user035',
    password: 'pass035',
    retrofitId: 35,
    status: 'Sent',
    ntripInfo: 'Setting035/user035',
    updateDate: '2020-08-24T01:01:35.000Z',
  },
  {
    id: 'fdb7d343-2ad4-512b-899b-72d2f22e6c87',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting036',
    url: 'https://gnsssetting.com/Setting036',
    portnum: '1001',
    userId: 'user036',
    password: 'pass036',
    retrofitId: 36,
    status: 'Sent',
    ntripInfo: 'Setting036/user036',
    updateDate: '2020-08-24T01:01:36.000Z',
  },
  {
    id: '7f557e1b-3514-44e9-6c85-9d65edf8efa2',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting037',
    url: 'https://gnsssetting.com/Setting037',
    portnum: '1001',
    userId: 'user037',
    password: 'pass037',
    retrofitId: 37,
    status: 'Sent',
    ntripInfo: 'Setting037/user037',
    updateDate: '2020-08-24T01:01:37.000Z',
  },
  {
    id: '1ad72789-0988-4292-532f-fbe395ca19ac',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting038',
    url: 'https://gnsssetting.com/Setting038',
    portnum: '1001',
    userId: 'user038',
    password: 'pass038',
    retrofitId: 38,
    status: 'Sent',
    ntripInfo: 'Setting038/user038',
    updateDate: '2020-08-24T01:01:38.000Z',
  },
  {
    id: '853daa2c-0021-9c68-6a7c-ee79bb2f6743',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting039',
    url: 'https://gnsssetting.com/Setting039',
    portnum: '1001',
    userId: 'user039',
    password: 'pass039',
    retrofitId: 39,
    status: 'Sent',
    ntripInfo: 'Setting039/user039',
    updateDate: '2020-08-24T01:01:39.000Z',
  },
  {
    id: '474d09a2-c193-f761-1e26-f9a429e2c6b7',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting040',
    url: 'https://gnsssetting.com/Setting040',
    portnum: '1001',
    userId: 'user040',
    password: 'pass040',
    retrofitId: 40,
    status: 'Sent',
    ntripInfo: 'Setting040/user040',
    updateDate: '2020-08-24T01:01:40.000Z',
  },
  {
    id: 'dcca8904-a73d-1600-e0da-b8227cbec6ae',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting041',
    url: 'https://gnsssetting.com/Setting041',
    portnum: '1001',
    userId: 'user041',
    password: 'pass041',
    retrofitId: 41,
    status: 'Sent',
    ntripInfo: 'Setting041/user041',
    updateDate: '2020-08-24T01:01:41.000Z',
  },
  {
    id: '0af85762-87b3-71b2-deb7-32abde22fc7d',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting042',
    url: 'https://gnsssetting.com/Setting042',
    portnum: '1001',
    userId: 'user042',
    password: 'pass042',
    retrofitId: 42,
    status: 'Sent',
    ntripInfo: 'Setting042/user042',
    updateDate: '2020-08-24T01:01:42.000Z',
  },
  {
    id: '97e03475-c54f-f5cf-87c5-e9bd002b3d76',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting043',
    url: 'https://gnsssetting.com/Setting043',
    portnum: '1001',
    userId: 'user043',
    password: 'pass043',
    retrofitId: 43,
    status: 'Sent',
    ntripInfo: 'Setting043/user043',
    updateDate: '2020-08-24T01:01:43.000Z',
  },
  {
    id: 'dec070bd-ec6e-d8ea-09e4-605f1f8eaffd',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting044',
    url: 'https://gnsssetting.com/Setting044',
    portnum: '1001',
    userId: 'user044',
    password: 'pass044',
    retrofitId: 44,
    status: 'Sent',
    ntripInfo: 'Setting044/user044',
    updateDate: '2020-08-24T01:01:44.000Z',
  },
  {
    id: '51a5265d-2550-2475-a98d-a4aa81203a49',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting045',
    url: 'https://gnsssetting.com/Setting045',
    portnum: '1001',
    userId: 'user045',
    password: 'pass045',
    retrofitId: 45,
    status: 'Sent',
    ntripInfo: 'Setting045/user045',
    updateDate: '2020-08-24T01:01:45.000Z',
  },
  {
    id: 'a62b0d26-ea1a-d741-a959-a00fe73d7dab',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting046',
    url: 'https://gnsssetting.com/Setting046',
    portnum: '1001',
    userId: 'user046',
    password: 'pass046',
    retrofitId: 46,
    status: 'Sent',
    ntripInfo: 'Setting046/user046',
    updateDate: '2020-08-24T01:01:46.000Z',
  },
  {
    id: '0018968d-ea52-cfc0-3511-e7eb58b45eea',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'SettingUndefined',
    url: 'https://gnsssetting.com/SettingUndefined',
    portnum: '1001',
    userId: 'userUndefined',
    password: 'passUndefined',
    retrofitId: undefined,
    status: 'Sent',
    ntripInfo: 'SettingUndefined/userUndefined',
    updateDate: '2020-08-24T01:01:47.000Z',
  },
  {
    id: 'ee0c0e91-f7b0-3dc7-3694-23d4a9c0eea8',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting048',
    url: 'https://gnsssetting.com/Setting048',
    portnum: '1001',
    userId: 'user048',
    password: 'pass048',
    retrofitId: 48,
    status: 'Sent',
    ntripInfo: 'Setting048/user048',
    updateDate: '2020-08-24T01:01:48.000Z',
  },
  {
    id: '378f9d2e-3ed4-ee2a-ed45-22069a6c933e',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting049',
    url: 'https://gnsssetting.com/Setting049',
    portnum: '1001',
    userId: 'user049',
    password: 'pass049',
    retrofitId: 49,
    status: 'Sent',
    ntripInfo: 'Setting049/user049',
    updateDate: '2020-08-24T01:01:49.000Z',
  },
  {
    id: 'e709dfc4-6f8a-76f4-4741-33939ae8f2f1',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting050',
    url: 'https://gnsssetting.com/Setting050',
    portnum: '1001',
    userId: 'user050',
    password: 'pass050',
    retrofitId: 50,
    status: 'Sent',
    ntripInfo: 'Setting050/user050',
    updateDate: '2020-08-24T01:01:50.000Z',
  },
  {
    id: '4794565d-f91b-e040-864b-5d7c43f41d07',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting051',
    url: 'https://gnsssetting.com/Setting051',
    portnum: '1001',
    userId: 'user051',
    password: 'pass051',
    retrofitId: 51,
    status: 'Sent',
    ntripInfo: 'Setting051/user051',
    updateDate: '2020-08-24T01:01:51.000Z',
  },
  {
    id: '0c7ebb96-6394-6a23-3e9b-98a67303c10c',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting052',
    url: 'https://gnsssetting.com/Setting052',
    portnum: '1001',
    userId: 'user052',
    password: 'pass052',
    retrofitId: 52,
    status: 'Sent',
    ntripInfo: 'Setting052/user052',
    updateDate: '2020-08-24T01:01:52.000Z',
  },
  {
    id: 'defcf5b9-4b52-1684-0b2d-11c8b7cbd261',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting053',
    url: 'https://gnsssetting.com/Setting053',
    portnum: '1001',
    userId: 'user053',
    password: 'pass053',
    retrofitId: 53,
    status: 'Sent',
    ntripInfo: 'Setting053/user053',
    updateDate: '2020-08-24T01:01:53.000Z',
  },
  {
    id: '1bcf9b3e-1bc5-aa85-ca14-34097cb87705',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting054',
    url: 'https://gnsssetting.com/Setting054',
    portnum: '1001',
    userId: 'user054',
    password: 'pass054',
    retrofitId: 54,
    status: 'Sent',
    ntripInfo: 'Setting054/user054',
    updateDate: '2020-08-24T01:01:54.000Z',
  },
  {
    id: '3203a69e-d080-f4a8-4bff-002f8ef7d275',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting055',
    url: 'https://gnsssetting.com/Setting055',
    portnum: '1001',
    userId: 'user055',
    password: 'pass055',
    retrofitId: 55,
    status: 'Sent',
    ntripInfo: 'Setting055/user055',
    updateDate: '2020-08-24T01:01:55.000Z',
  },
  {
    id: '1f75e400-b9fc-8c77-a447-aebe382e9aa9',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting056',
    url: 'https://gnsssetting.com/Setting056',
    portnum: '1001',
    userId: 'user056',
    password: 'pass056',
    retrofitId: 56,
    status: 'Sent',
    ntripInfo: 'Setting056/user056',
    updateDate: '2020-08-24T01:01:56.000Z',
  },
  {
    id: 'c86a8942-2dce-e513-29e7-c7731274b205',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting057',
    url: 'https://gnsssetting.com/Setting057',
    portnum: '1001',
    userId: 'user057',
    password: 'pass057',
    retrofitId: 57,
    status: 'Sent',
    ntripInfo: 'Setting057/user057',
    updateDate: '2020-08-24T01:01:57.000Z',
  },
  {
    id: '2688bda7-b694-100e-e93b-07ceead42c10',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting058',
    url: 'https://gnsssetting.com/Setting058',
    portnum: '1001',
    userId: 'user058',
    password: 'pass058',
    retrofitId: 58,
    status: 'Sent',
    ntripInfo: 'Setting058/user058',
    updateDate: '2020-08-24T01:01:58.000Z',
  },
  {
    id: '94520b79-de73-f137-c13c-42d3066e252c',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting059',
    url: 'https://gnsssetting.com/Setting059',
    portnum: '1001',
    userId: 'user059',
    password: 'pass059',
    retrofitId: 59,
    status: 'Sent',
    ntripInfo: 'Setting059/user059',
    updateDate: '2020-08-24T01:01:59.000Z',
  },
  {
    id: '475a9c40-6a53-f267-38ab-dc6015ffeed5',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting060',
    url: 'https://gnsssetting.com/Setting060',
    portnum: '1001',
    userId: 'user060',
    password: 'pass060',
    retrofitId: 60,
    status: 'Sent',
    ntripInfo: 'Setting060/user060',
    updateDate: '2020-08-24T01:02:00.000Z',
  },
  {
    id: '94128772-38d4-256b-1e2e-8754f2a97e54',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting061',
    url: 'https://gnsssetting.com/Setting061',
    portnum: '1001',
    userId: 'user061',
    password: 'pass061',
    retrofitId: 61,
    status: 'Sent',
    ntripInfo: 'Setting061/user061',
    updateDate: '2020-08-24T01:02:01.000Z',
  },
  {
    id: '4280253b-873d-6ca5-f176-475059911fd9',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting062',
    url: 'https://gnsssetting.com/Setting062',
    portnum: '1001',
    userId: 'user062',
    password: 'pass062',
    retrofitId: 62,
    status: 'Sent',
    ntripInfo: 'Setting062/user062',
    updateDate: '2020-08-24T01:02:02.000Z',
  },
  {
    id: 'a6425a2d-0e19-d78f-ea57-0cb3c871317e',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting063',
    url: 'https://gnsssetting.com/Setting063',
    portnum: '1001',
    userId: 'user063',
    password: 'pass063',
    retrofitId: 63,
    status: 'Sent',
    ntripInfo: 'Setting063/user063',
    updateDate: '2020-08-24T01:02:03.000Z',
  },
  {
    id: '568b1408-6fcf-ad90-8c31-433fb02d184b',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting064',
    url: 'https://gnsssetting.com/Setting064',
    portnum: '1001',
    userId: 'user064',
    password: 'pass064',
    retrofitId: 64,
    status: 'Sent',
    ntripInfo: 'Setting064/user064',
    updateDate: '2020-08-24T01:02:04.000Z',
  },
  {
    id: '67b41560-917b-7faa-a01c-18d793e1a621',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting065',
    url: 'https://gnsssetting.com/Setting065',
    portnum: '1001',
    userId: 'user065',
    password: 'pass065',
    retrofitId: 65,
    status: 'Sent',
    ntripInfo: 'Setting065/user065',
    updateDate: '2020-08-24T01:02:05.000Z',
  },
  {
    id: '4f969748-5efc-7461-b0e8-5ecdda96e836',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting066',
    url: 'https://gnsssetting.com/Setting066',
    portnum: '1001',
    userId: 'user066',
    password: 'pass066',
    retrofitId: 66,
    status: 'Sent',
    ntripInfo: 'Setting066/user066',
    updateDate: '2020-08-24T01:02:06.000Z',
  },
  {
    id: '1e142b67-b447-8868-6e21-aae9d21089ba',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting067',
    url: 'https://gnsssetting.com/Setting067',
    portnum: '1001',
    userId: 'user067',
    password: 'pass067',
    retrofitId: 67,
    status: 'Sent',
    ntripInfo: 'Setting067/user067',
    updateDate: '2020-08-24T01:02:07.000Z',
  },
  {
    id: '83bb62d6-bd67-6d9f-6612-1e51c55250e9',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting068',
    url: 'https://gnsssetting.com/Setting068',
    portnum: '1001',
    userId: 'user068',
    password: 'pass068',
    retrofitId: 68,
    status: 'Sent',
    ntripInfo: 'Setting068/user068',
    updateDate: '2020-08-24T01:02:08.000Z',
  },
  {
    id: '964dd2b9-d30e-1cf0-4aed-2444687d3e45',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting069',
    url: 'https://gnsssetting.com/Setting069',
    portnum: '1001',
    userId: 'user069',
    password: 'pass069',
    retrofitId: 69,
    status: 'Sent',
    ntripInfo: 'Setting069/user069',
    updateDate: '2020-08-24T01:02:09.000Z',
  },
  {
    id: 'f295b33d-bfa0-1c91-e0b0-a232d64aa0eb',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting070',
    url: 'https://gnsssetting.com/Setting070',
    portnum: '1001',
    userId: 'user070',
    password: 'pass070',
    retrofitId: 70,
    status: 'Sent',
    ntripInfo: 'Setting070/user070',
    updateDate: '2020-08-24T01:02:10.000Z',
  },
  {
    id: 'b65a6455-d503-3f67-56d9-e397042b021f',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting071',
    url: 'https://gnsssetting.com/Setting071',
    portnum: '1001',
    userId: 'user071',
    password: 'pass071',
    retrofitId: 71,
    status: 'Sent',
    ntripInfo: 'Setting071/user071',
    updateDate: '2020-08-24T01:02:11.000Z',
  },
  {
    id: '89b4398b-f52a-14fb-2597-3dabe1a7d740',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting072',
    url: 'https://gnsssetting.com/Setting072',
    portnum: '1001',
    userId: 'user072',
    password: 'pass072',
    retrofitId: 72,
    status: 'Sent',
    ntripInfo: 'Setting072/user072',
    updateDate: '2020-08-24T01:02:12.000Z',
  },
  {
    id: 'b3b4f67e-fdd0-03c5-9aff-ef931b4f2367',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting073',
    url: 'https://gnsssetting.com/Setting073',
    portnum: '1001',
    userId: 'user073',
    password: 'pass073',
    retrofitId: 73,
    status: 'Sent',
    ntripInfo: 'Setting073/user073',
    updateDate: '2020-08-24T01:02:13.000Z',
  },
  {
    id: '5cd1eed3-c0a2-dcbc-d640-2216f2ee14e0',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting074',
    url: 'https://gnsssetting.com/Setting074',
    portnum: '1001',
    userId: 'user074',
    password: 'pass074',
    retrofitId: 74,
    status: 'Sent',
    ntripInfo: 'Setting074/user074',
    updateDate: '2020-08-24T01:02:14.000Z',
  },
  {
    id: 'c9c80b07-8f35-e1c0-bcdd-97acf3e89329',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting075',
    url: 'https://gnsssetting.com/Setting075',
    portnum: '1001',
    userId: 'user075',
    password: 'pass075',
    retrofitId: 75,
    status: 'Sent',
    ntripInfo: 'Setting075/user075',
    updateDate: '2020-08-24T01:02:15.000Z',
  },
  {
    id: 'f016912f-abe5-2f5d-dae8-cd7400ef3bae',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting076',
    url: 'https://gnsssetting.com/Setting076',
    portnum: '1001',
    userId: 'user076',
    password: 'pass076',
    retrofitId: 76,
    status: 'Sent',
    ntripInfo: 'Setting076/user076',
    updateDate: '2020-08-24T01:02:16.000Z',
  },
  {
    id: '0a30b729-4452-6b37-752f-15a32a65a903',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting077',
    url: 'https://gnsssetting.com/Setting077',
    portnum: '1001',
    userId: 'user077',
    password: 'pass077',
    retrofitId: 77,
    status: 'Sent',
    ntripInfo: 'Setting077/user077',
    updateDate: '2020-08-24T01:02:17.000Z',
  },
  {
    id: 'f60eea4b-a918-85ff-4ea8-db7cc75a9ae0',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting078',
    url: 'https://gnsssetting.com/Setting078',
    portnum: '1001',
    userId: 'user078',
    password: 'pass078',
    retrofitId: 78,
    status: 'Sent',
    ntripInfo: 'Setting078/user078',
    updateDate: '2020-08-24T01:02:18.000Z',
  },
  {
    id: '3a6fd62c-f738-cae7-7122-52a4c26e4d44',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting079',
    url: 'https://gnsssetting.com/Setting079',
    portnum: '1001',
    userId: 'user079',
    password: 'pass079',
    retrofitId: 79,
    status: 'Sent',
    ntripInfo: 'Setting079/user079',
    updateDate: '2020-08-24T01:02:19.000Z',
  },
  {
    id: 'db732888-fadb-dee4-7e1f-abf485a2640a',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting080',
    url: 'https://gnsssetting.com/Setting080',
    portnum: '1001',
    userId: 'user080',
    password: 'pass080',
    retrofitId: 80,
    status: 'Sent',
    ntripInfo: 'Setting080/user080',
    updateDate: '2020-08-24T01:02:20.000Z',
  },
  {
    id: 'adfed798-9610-fe83-ba6c-7914e1b15748',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting081',
    url: 'https://gnsssetting.com/Setting081',
    portnum: '1001',
    userId: 'user081',
    password: 'pass081',
    retrofitId: 81,
    status: 'Sent',
    ntripInfo: 'Setting081/user081',
    updateDate: '2020-08-24T01:02:21.000Z',
  },
  {
    id: '3475451b-63bf-1641-20e5-42144d672f23',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting082',
    url: 'https://gnsssetting.com/Setting082',
    portnum: '1001',
    userId: 'user082',
    password: 'pass082',
    retrofitId: 82,
    status: 'Sent',
    ntripInfo: 'Setting082/user082',
    updateDate: '2020-08-24T01:02:22.000Z',
  },
  {
    id: '5dede9ff-7adc-071d-c5f9-49e7dc86fae9',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting083',
    url: 'https://gnsssetting.com/Setting083',
    portnum: '1001',
    userId: 'user083',
    password: 'pass083',
    retrofitId: 83,
    status: 'Sent',
    ntripInfo: 'Setting083/user083',
    updateDate: '2020-08-24T01:02:23.000Z',
  },
  {
    id: '340f8028-fa16-67aa-e8ff-35b8debc66b3',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting084',
    url: 'https://gnsssetting.com/Setting084',
    portnum: '1001',
    userId: 'user084',
    password: 'pass084',
    retrofitId: 84,
    status: 'Sent',
    ntripInfo: 'Setting084/user084',
    updateDate: '2020-08-24T01:02:24.000Z',
  },
  {
    id: 'cea905e2-b920-c7aa-528e-7031c532c811',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting085',
    url: 'https://gnsssetting.com/Setting085',
    portnum: '1001',
    userId: 'user085',
    password: 'pass085',
    retrofitId: 85,
    status: 'Sent',
    ntripInfo: 'Setting085/user085',
    updateDate: '2020-08-24T01:02:25.000Z',
  },
  {
    id: '95c1f193-e9f6-4c59-2620-ed4e29b55413',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting086',
    url: 'https://gnsssetting.com/Setting086',
    portnum: '1001',
    userId: 'user086',
    password: 'pass086',
    retrofitId: 86,
    status: 'Sent',
    ntripInfo: 'Setting086/user086',
    updateDate: '2020-08-24T01:02:26.000Z',
  },
  {
    id: '09e00b3b-6ed2-37e8-75ae-0e78f81f8345',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting087',
    url: 'https://gnsssetting.com/Setting087',
    portnum: '1001',
    userId: 'user087',
    password: 'pass087',
    retrofitId: 87,
    status: 'Sent',
    ntripInfo: 'Setting087/user087',
    updateDate: '2020-08-24T01:02:27.000Z',
  },
  {
    id: 'd9b8a515-a5a1-b6f5-16b7-92ca7be5fafd',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting088',
    url: 'https://gnsssetting.com/Setting088',
    portnum: '1001',
    userId: 'user088',
    password: 'pass088',
    retrofitId: 88,
    status: 'Sent',
    ntripInfo: 'Setting088/user088',
    updateDate: '2020-08-24T01:02:28.000Z',
  },
  {
    id: '85d6e797-8092-9e7a-32a0-dd09cf605cfe',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting089',
    url: 'https://gnsssetting.com/Setting089',
    portnum: '1001',
    userId: 'user089',
    password: 'pass089',
    retrofitId: 89,
    status: 'Sent',
    ntripInfo: 'Setting089/user089',
    updateDate: '2020-08-24T01:02:29.000Z',
  },
  {
    id: '22ded954-beb6-094c-e53e-f3d0aec0664e',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting090',
    url: 'https://gnsssetting.com/Setting090',
    portnum: '1001',
    userId: 'user090',
    password: 'pass090',
    retrofitId: 90,
    status: 'Sent',
    ntripInfo: 'Setting090/user090',
    updateDate: '2020-08-24T01:02:30.000Z',
  },
  {
    id: 'e153aa6a-2137-a7bb-fcf3-fae83568239e',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting091',
    url: 'https://gnsssetting.com/Setting091',
    portnum: '1001',
    userId: 'user091',
    password: 'pass091',
    retrofitId: 91,
    status: 'Sent',
    ntripInfo: 'Setting091/user091',
    updateDate: '2020-08-24T01:02:31.000Z',
  },
  {
    id: 'a583d773-0b90-9d83-54a7-6df0b4f683d8',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting092',
    url: 'https://gnsssetting.com/Setting092',
    portnum: '1001',
    userId: 'user092',
    password: 'pass092',
    retrofitId: 92,
    status: 'Sent',
    ntripInfo: 'Setting092/user092',
    updateDate: '2020-08-24T01:02:32.000Z',
  },
  {
    id: '9f0f6e42-d115-8ef6-e543-5a560f61c32a',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting093',
    url: 'https://gnsssetting.com/Setting093',
    portnum: '1001',
    userId: 'user093',
    password: 'pass093',
    retrofitId: 93,
    status: 'Sent',
    ntripInfo: 'Setting093/user093',
    updateDate: '2020-08-24T01:02:33.000Z',
  },
  {
    id: 'b24d4ba3-3317-a9a4-9dab-57e416480354',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting094',
    url: 'https://gnsssetting.com/Setting094',
    portnum: '1001',
    userId: 'user094',
    password: 'pass094',
    retrofitId: 94,
    status: 'Sent',
    ntripInfo: 'Setting094/user094',
    updateDate: '2020-08-24T01:02:34.000Z',
  },
  {
    id: '457c70c7-0f86-f562-3533-1b51c26730e3',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting095',
    url: 'https://gnsssetting.com/Setting095',
    portnum: '1001',
    userId: 'user095',
    password: 'pass095',
    retrofitId: 95,
    status: 'Sent',
    ntripInfo: 'Setting095/user095',
    updateDate: '2020-08-24T01:02:35.000Z',
  },
  {
    id: '714cd2f9-09f4-18c2-b9e6-ebc0aa7c5d45',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting096',
    url: 'https://gnsssetting.com/Setting096',
    portnum: '1001',
    userId: 'user096',
    password: 'pass096',
    retrofitId: 96,
    status: 'Sent',
    ntripInfo: 'Setting096/user096',
    updateDate: '2020-08-24T01:02:36.000Z',
  },
  {
    id: '3216f1d9-12dc-bedc-4857-9112c1b4d77d',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting097',
    url: 'https://gnsssetting.com/Setting097',
    portnum: '1001',
    userId: 'user097',
    password: 'pass097',
    retrofitId: 97,
    status: 'Sent',
    ntripInfo: 'Setting097/user097',
    updateDate: '2020-08-24T01:02:37.000Z',
  },
  {
    id: '59b11818-a2fc-1074-219d-fdea49cd634e',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting098',
    url: 'https://gnsssetting.com/Setting098',
    portnum: '1001',
    userId: 'user098',
    password: 'pass098',
    retrofitId: 98,
    status: 'Sent',
    ntripInfo: 'Setting098/user098',
    updateDate: '2020-08-24T01:02:38.000Z',
  },
  {
    id: '45b6e3d7-bd1b-a12e-edcd-04c7abc058c8',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting099',
    url: 'https://gnsssetting.com/Setting099',
    portnum: '1001',
    userId: 'user099',
    password: 'pass099',
    retrofitId: 99,
    status: 'Sent',
    ntripInfo: 'Setting099/user099',
    updateDate: '2020-08-24T01:02:39.000Z',
  },
  {
    id: '414956d1-d744-eaa0-fd8b-c3d94774598f',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting100',
    url: 'https://gnsssetting.com/Setting100',
    portnum: '1001',
    userId: 'user100',
    password: 'pass100',
    retrofitId: 100,
    status: 'Sent',
    ntripInfo: 'Setting100/user100',
    updateDate: '2020-08-24T01:02:40.000Z',
  },
  {
    id: 'aba17ef3-dff6-58cd-e023-4d596262f821',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting101',
    url: 'https://gnsssetting.com/Setting101',
    portnum: '1001',
    userId: 'user101',
    password: 'pass101',
    retrofitId: 101,
    status: 'Sent',
    ntripInfo: 'Setting101/user101',
    updateDate: '2020-08-24T01:02:41.000Z',
  },
  {
    id: 'b67de8b0-c5c2-c1b8-1b60-840d6580a71e',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting102',
    url: 'https://gnsssetting.com/Setting102',
    portnum: '1001',
    userId: 'user102',
    password: 'pass102',
    retrofitId: 102,
    status: 'Sent',
    ntripInfo: 'Setting102/user102',
    updateDate: '2020-08-24T01:02:42.000Z',
  },
  {
    id: 'df7f58c7-568b-ca0e-60d4-b560ea6899a9',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting103',
    url: 'https://gnsssetting.com/Setting103',
    portnum: '1001',
    userId: 'user103',
    password: 'pass103',
    retrofitId: 103,
    status: 'Sent',
    ntripInfo: 'Setting103/user103',
    updateDate: '2020-08-24T01:02:43.000Z',
  },
  {
    id: 'c0f04dbf-7ea1-644f-f9c2-4206535fcc98',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting104',
    url: 'https://gnsssetting.com/Setting104',
    portnum: '1001',
    userId: 'user104',
    password: 'pass104',
    retrofitId: 104,
    status: 'Sent',
    ntripInfo: 'Setting104/user104',
    updateDate: '2020-08-24T01:02:44.000Z',
  },
  {
    id: 'f67d2117-c73a-483d-e359-3debce898a4f',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting105',
    url: 'https://gnsssetting.com/Setting105',
    portnum: '1001',
    userId: 'user105',
    password: 'pass105',
    retrofitId: 105,
    status: 'Sent',
    ntripInfo: 'Setting105/user105',
    updateDate: '2020-08-24T01:02:45.000Z',
  },
  {
    id: '0411c039-c219-d88c-d22a-7a77e3ac9058',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting106',
    url: 'https://gnsssetting.com/Setting106',
    portnum: '1001',
    userId: 'user106',
    password: 'pass106',
    retrofitId: 106,
    status: 'Sent',
    ntripInfo: 'Setting106/user106',
    updateDate: '2020-08-24T01:02:46.000Z',
  },
  {
    id: '50b70e9a-92df-9ad9-3afe-b17925bcdf43',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting107',
    url: 'https://gnsssetting.com/Setting107',
    portnum: '1001',
    userId: 'user107',
    password: 'pass107',
    retrofitId: 107,
    status: 'Sent',
    ntripInfo: 'Setting107/user107',
    updateDate: '2020-08-24T01:02:47.000Z',
  },
  {
    id: '149a0a99-05cd-2ab6-c38c-7753ff3bb7c7',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting108',
    url: 'https://gnsssetting.com/Setting108',
    portnum: '1001',
    userId: 'user108',
    password: 'pass108',
    retrofitId: 108,
    status: 'Sent',
    ntripInfo: 'Setting108/user108',
    updateDate: '2020-08-24T01:02:48.000Z',
  },
  {
    id: 'fb62c010-d06e-9ccb-1f95-1f816c7e446c',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting109',
    url: 'https://gnsssetting.com/Setting109',
    portnum: '1001',
    userId: 'user109',
    password: 'pass109',
    retrofitId: 109,
    status: 'Sent',
    ntripInfo: 'Setting109/user109',
    updateDate: '2020-08-24T01:02:49.000Z',
  },
  {
    id: 'f9f64656-16f6-fea3-bd83-5848bd21c450',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting110',
    url: 'https://gnsssetting.com/Setting110',
    portnum: '1001',
    userId: 'user110',
    password: 'pass110',
    retrofitId: 110,
    status: 'Sent',
    ntripInfo: 'Setting110/user110',
    updateDate: '2020-08-24T01:02:50.000Z',
  },
  {
    id: '6180b85a-9351-6ce7-01b6-805885f41122',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting111',
    url: 'https://gnsssetting.com/Setting111',
    portnum: '1001',
    userId: 'user111',
    password: 'pass111',
    retrofitId: 111,
    status: 'Sent',
    ntripInfo: 'Setting111/user111',
    updateDate: '2020-08-24T01:02:51.000Z',
  },
  {
    id: 'dd609f73-ab6a-c0e3-8009-7f757084456b',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting112',
    url: 'https://gnsssetting.com/Setting112',
    portnum: '1001',
    userId: 'user112',
    password: 'pass112',
    retrofitId: 112,
    status: 'Sent',
    ntripInfo: 'Setting112/user112',
    updateDate: '2020-08-24T01:02:52.000Z',
  },
  {
    id: 'f2d5df22-1bab-5a23-697b-ef70d7fe318e',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting113',
    url: 'https://gnsssetting.com/Setting113',
    portnum: '1001',
    userId: 'user113',
    password: 'pass113',
    retrofitId: 113,
    status: 'Sent',
    ntripInfo: 'Setting113/user113',
    updateDate: '2020-08-24T01:02:53.000Z',
  },
  {
    id: '23a005ab-29fc-83cf-0572-85c8c2d476db',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting114',
    url: 'https://gnsssetting.com/Setting114',
    portnum: '1001',
    userId: 'user114',
    password: 'pass114',
    retrofitId: 114,
    status: 'Sent',
    ntripInfo: 'Setting114/user114',
    updateDate: '2020-08-24T01:02:54.000Z',
  },
  {
    id: 'b1f8deb7-c232-8dac-96ee-e73f5e9e7f26',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting115',
    url: 'https://gnsssetting.com/Setting115',
    portnum: '1001',
    userId: 'user115',
    password: 'pass115',
    retrofitId: 115,
    status: 'Sent',
    ntripInfo: 'Setting115/user115',
    updateDate: '2020-08-24T01:02:55.000Z',
  },
  {
    id: '6f919fcb-b4a1-dc77-830f-74a3adf8f602',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting116',
    url: 'https://gnsssetting.com/Setting116',
    portnum: '1001',
    userId: 'user116',
    password: 'pass116',
    retrofitId: 116,
    status: 'Sent',
    ntripInfo: 'Setting116/user116',
    updateDate: '2020-08-24T01:02:56.000Z',
  },
  {
    id: '33f0596e-6974-06ba-4276-8588ae74daaf',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting117',
    url: 'https://gnsssetting.com/Setting117',
    portnum: '1001',
    userId: 'user117',
    password: 'pass117',
    retrofitId: 117,
    status: 'Sent',
    ntripInfo: 'Setting117/user117',
    updateDate: '2020-08-24T01:02:57.000Z',
  },
  {
    id: 'b55e53da-6024-90ab-8308-a844435e2de1',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting118',
    url: 'https://gnsssetting.com/Setting118',
    portnum: '1001',
    userId: 'user118',
    password: 'pass118',
    retrofitId: 118,
    status: 'Sent',
    ntripInfo: 'Setting118/user118',
    updateDate: '2020-08-24T01:02:58.000Z',
  },
  {
    id: '3d5c3b5b-3cda-370a-d1eb-26752e7363f3',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting119',
    url: 'https://gnsssetting.com/Setting119',
    portnum: '1001',
    userId: 'user119',
    password: 'pass119',
    retrofitId: 119,
    status: 'Sent',
    ntripInfo: 'Setting119/user119',
    updateDate: '2020-08-24T01:02:59.000Z',
  },
  {
    id: '2026f7aa-829c-3c91-092e-9421a9475f5a',
    registCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    registCorporationName: '株式会社クオリカ建築　テスト事業部',
    ownerCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    ownerCorporationName: '株式会社クオリカ建築　テスト事業部',
    name: 'Setting120',
    url: 'https://gnsssetting.com/Setting120',
    portnum: '1001',
    userId: 'user120',
    password: 'pass120',
    retrofitId: 120,
    status: 'Sent',
    ntripInfo: 'Setting120/user120',
    updateDate: '2020-08-24T01:03:00.000Z',
  },
  {
    id: '49362511-22ed-7661-70b1-20d9d2841954',
    registCorporationId: '8715c009-aada-9c22-20e3-a63305b46db9',
    registCorporationName:
      '会社名５０６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    ownerCorporationId: '8715c009-aada-9c22-20e3-a63305b46db9',
    ownerCorporationName:
      '会社名５０６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    name: 'Setting121',
    url: 'https://gnsssetting.com/Setting121',
    portnum: '1001',
    userId: 'user121',
    password: 'pass121',
    retrofitId: 121,
    status: 'Sent',
    ntripInfo: 'Setting121/user121',
    updateDate: '2020-08-24T01:03:01.000Z',
  },
  {
    id: '5b18983c-8cb8-380e-6944-e7cc5b580789',
    registCorporationId: '8715c009-aada-9c22-20e3-a63305b46db9',
    registCorporationName:
      '会社名５０６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    ownerCorporationId: '8715c009-aada-9c22-20e3-a63305b46db9',
    ownerCorporationName:
      '会社名５０６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    name: 'Setting122',
    url: 'https://gnsssetting.com/Setting122',
    portnum: '1001',
    userId: 'user122',
    password: 'pass122',
    retrofitId: 122,
    status: 'Sent',
    ntripInfo: 'Setting122/user122',
    updateDate: '2020-08-24T01:03:02.000Z',
  },
  {
    id: 'd940daca-e3ea-5bac-c58a-4a3771490f26',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting123',
    url: 'https://gnsssetting.com/Setting123',
    portnum: '1001',
    userId: 'user123',
    password: 'pass123',
    retrofitId: 123,
    status: 'Sent',
    ntripInfo: 'Setting123/user123',
    updateDate: '2020-08-24T01:03:03.000Z',
  },
  {
    id: '9b648044-25e8-e8d9-1941-582dff445038',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting124',
    url: 'https://gnsssetting.com/Setting124',
    portnum: '1001',
    userId: 'user124',
    password: 'pass124',
    retrofitId: 124,
    status: 'Sent',
    ntripInfo: 'Setting124/user124',
    updateDate: '2020-08-24T01:03:04.000Z',
  },
  {
    id: 'a96a4a6b-76ed-b829-4190-edcd6699e9ee',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting125',
    url: 'https://gnsssetting.com/Setting125',
    portnum: '1001',
    userId: 'user125',
    password: 'pass125',
    retrofitId: 125,
    status: 'Sent',
    ntripInfo: 'Setting125/user125',
    updateDate: '2020-08-24T01:03:05.000Z',
  },
  {
    id: '245a4261-9a57-7499-f29a-9a518649286a',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting126',
    url: 'https://gnsssetting.com/Setting126',
    portnum: '1001',
    userId: 'user126',
    password: 'pass126',
    retrofitId: 126,
    status: 'Sent',
    ntripInfo: 'Setting126/user126',
    updateDate: '2020-08-24T01:03:06.000Z',
  },
  {
    id: 'd4a7f663-4244-0da2-6a1b-cbadbb053674',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting127',
    url: 'https://gnsssetting.com/Setting127',
    portnum: '1001',
    userId: 'user127',
    password: 'pass127',
    retrofitId: 127,
    status: 'Sent',
    ntripInfo: 'Setting127/user127',
    updateDate: '2020-08-24T01:03:07.000Z',
  },
  {
    id: 'f1dcabaf-064c-23d0-ac2e-8bb4b4416e55',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting128',
    url: 'https://gnsssetting.com/Setting128',
    portnum: '1001',
    userId: 'user128',
    password: 'pass128',
    retrofitId: 128,
    status: 'Sent',
    ntripInfo: 'Setting128/user128',
    updateDate: '2020-08-24T01:03:08.000Z',
  },
  {
    id: 'efa13ee2-92f4-11e3-015e-4ffe70e8479f',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting129',
    url: 'https://gnsssetting.com/Setting129',
    portnum: '1001',
    userId: 'user129',
    password: 'pass129',
    retrofitId: 129,
    status: 'Sent',
    ntripInfo: 'Setting129/user129',
    updateDate: '2020-08-24T01:03:09.000Z',
  },
  {
    id: '1a134bc0-956e-fda2-3ca6-f5ed8bf50807',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting130',
    url: 'https://gnsssetting.com/Setting130',
    portnum: '1001',
    userId: 'user130',
    password: 'pass130',
    retrofitId: 130,
    status: 'Sent',
    ntripInfo: 'Setting130/user130',
    updateDate: '2020-08-24T01:03:10.000Z',
  },
  {
    id: 'd5c4ad2f-cd3a-fc91-e380-4a0b79e92fcd',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting131',
    url: 'https://gnsssetting.com/Setting131',
    portnum: '1001',
    userId: 'user131',
    password: 'pass131',
    retrofitId: 131,
    status: 'Sent',
    ntripInfo: 'Setting131/user131',
    updateDate: '2020-08-24T01:03:11.000Z',
  },
  {
    id: '88d68069-bfd1-ba98-fc8a-4d6a8087a319',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting132',
    url: 'https://gnsssetting.com/Setting132',
    portnum: '1001',
    userId: 'user132',
    password: 'pass132',
    retrofitId: 132,
    status: 'Sent',
    ntripInfo: 'Setting132/user132',
    updateDate: '2020-08-24T01:03:12.000Z',
  },
  {
    id: '5d8bf094-1c29-16e5-ab71-c55daaca8555',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting133',
    url: 'https://gnsssetting.com/Setting133',
    portnum: '1001',
    userId: 'user133',
    password: 'pass133',
    retrofitId: 133,
    status: 'Sent',
    ntripInfo: 'Setting133/user133',
    updateDate: '2020-08-24T01:03:13.000Z',
  },
  {
    id: '8c8f2f59-dc1c-90f8-8fd5-43211b453517',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting134',
    url: 'https://gnsssetting.com/Setting134',
    portnum: '1001',
    userId: 'user134',
    password: 'pass134',
    retrofitId: 134,
    status: 'Sent',
    ntripInfo: 'Setting134/user134',
    updateDate: '2020-08-24T01:03:14.000Z',
  },
  {
    id: 'ad34e155-9791-dc19-ceed-07cfc79cd522',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting135',
    url: 'https://gnsssetting.com/Setting135',
    portnum: '1001',
    userId: 'user135',
    password: 'pass135',
    retrofitId: 135,
    status: 'Sent',
    ntripInfo: 'Setting135/user135',
    updateDate: '2020-08-24T01:03:15.000Z',
  },
  {
    id: 'c97a77d2-e4a1-875b-d1f7-18cccca7760b',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting136',
    url: 'https://gnsssetting.com/Setting136',
    portnum: '1001',
    userId: 'user136',
    password: 'pass136',
    retrofitId: 136,
    status: 'Sent',
    ntripInfo: 'Setting136/user136',
    updateDate: '2020-08-24T01:03:16.000Z',
  },
  {
    id: '9de4169f-f5fd-e037-8e07-81b2690e85e8',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting137',
    url: 'https://gnsssetting.com/Setting137',
    portnum: '1001',
    userId: 'user137',
    password: 'pass137',
    retrofitId: 137,
    status: 'Sent',
    ntripInfo: 'Setting137/user137',
    updateDate: '2020-08-24T01:03:17.000Z',
  },
  {
    id: 'f29f0e91-01a8-7707-c07c-c25b75a25f0f',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting138',
    url: 'https://gnsssetting.com/Setting138',
    portnum: '1001',
    userId: 'user138',
    password: 'pass138',
    retrofitId: 138,
    status: 'Sent',
    ntripInfo: 'Setting138/user138',
    updateDate: '2020-08-24T01:03:18.000Z',
  },
  {
    id: '5002d76f-3ddf-ac72-8665-c647f2e0f9bd',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting139',
    url: 'https://gnsssetting.com/Setting139',
    portnum: '1001',
    userId: 'user139',
    password: 'pass139',
    retrofitId: 139,
    status: 'Sent',
    ntripInfo: 'Setting139/user139',
    updateDate: '2020-08-24T01:03:19.000Z',
  },
  {
    id: '61c623cf-fb07-f671-75cc-f5c92649b9c7',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting140',
    url: 'https://gnsssetting.com/Setting140',
    portnum: '1001',
    userId: 'user140',
    password: 'pass140',
    retrofitId: 140,
    status: 'Sent',
    ntripInfo: 'Setting140/user140',
    updateDate: '2020-08-24T01:03:20.000Z',
  },
  {
    id: '4928d275-508f-2c43-9c82-b22e09e73658',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting141',
    url: 'https://gnsssetting.com/Setting141',
    portnum: '1001',
    userId: 'user141',
    password: 'pass141',
    retrofitId: 141,
    status: 'Sent',
    ntripInfo: 'Setting141/user141',
    updateDate: '2020-08-24T01:03:21.000Z',
  },
  {
    id: 'b658b49c-2a05-5242-f7cc-be65f9542200',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting142',
    url: 'https://gnsssetting.com/Setting142',
    portnum: '1001',
    userId: 'user142',
    password: 'pass142',
    retrofitId: 142,
    status: 'Sent',
    ntripInfo: 'Setting142/user142',
    updateDate: '2020-08-24T01:03:22.000Z',
  },
  {
    id: '506284f2-04c5-4186-26b4-979c999f29b2',
    registCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    registCorporationName: 'LANDLOG',
    ownerCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    ownerCorporationName: 'LANDLOG',
    name: 'Setting143',
    url: 'https://gnsssetting.com/Setting143',
    portnum: '1001',
    userId: 'user143',
    password: 'pass143',
    retrofitId: 143,
    status: 'Sent',
    ntripInfo: 'Setting143/user143',
    updateDate: '2020-08-24T01:03:23.000Z',
  },
];

export default gnssSettings;
