import { useTranslate } from 'react-admin';
import { ConfirmMessage } from '../components';

const useCreateMessage = () => {
  const translate = useTranslate();
  return {
    createMessage: (resource: string): ConfirmMessage => {
      const resourceTitle = translate(`resources.${resource}.name`);
      return {
        title: translate('admin.message.deleteConfirmTitle', {
          title: resourceTitle,
        }),
        content: translate('admin.message.bulkDeleteConfirmContent', {
          title: resourceTitle,
        }),
      };
    },
  };
};

export default useCreateMessage;
