import React, { useCallback } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { DatagridRow } from '../bases';
import { useJumpTo } from '../../hooks';

type RetrofitAlternateRegistBulkActionDatagridRowProps = React.ComponentProps<
  typeof DatagridRow
>;
const RetrofitAlternateRegistBulkActionDatagridRow: React.FC<RetrofitAlternateRegistBulkActionDatagridRowProps> =
  ({
    record,
    resource,
    id,
    onToggleItem,
    children,
    selected,
    basePath,
    classes = {},
  }) => {
    const { gotoEdit } = useJumpTo();
    const junpToEdit = useCallback(async () => {
      gotoEdit('retrofitAlternateRegists', id);
    }, [gotoEdit, id]);

    return (
      <TableRow key={id} hover={true}>
        {record && !record.isApproved ? (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              className={`select-item ${classes.checkbox}`}
              checked={selected}
              onClick={(e: any) => onToggleItem && onToggleItem(id, e)}
            />
          </TableCell>
        ) : (
          <td />
        )}
        {React.Children.map(children, field => {
          if (React.isValidElement(field)) {
            return (
              <TableCell key={id} onClick={junpToEdit}>
                {React.cloneElement(field, {
                  record,
                  basePath,
                  resource,
                })}
              </TableCell>
            );
          }
          if (typeof field === 'string') {
            return field;
          }
          return null;
        })}
      </TableRow>
    );
  };

RetrofitAlternateRegistBulkActionDatagridRow.displayName =
  'RetrofitAlternateRegistBulkActionDatagridRow';
export default RetrofitAlternateRegistBulkActionDatagridRow;
