import React from 'react';
import {
  MachineGuidanceIcon,
  PayloadMeterIcon,
  AsbuiltIcon,
  LicenseAllIcon,
  LicenseAllActivateIcon,
  MachineGuidanceActivateIcon,
  PayloadMeterActivateIcon,
  AsbuiltActivateIcon,
} from '../assets';
import { RetrofitCorporationLicenseData } from '../dataProvider';

export interface LicenseElement {
  iconList: React.FC[];
  expiresAt: string;
  permission: string;
  licenseId: string;
}

const licenseToConvertElement = (
  license: RetrofitCorporationLicenseData | undefined,
  isLicenseAttachedIconValid = false,
): LicenseElement | undefined => {
  if (!license) return undefined;

  const { licenseId, licensePermission, expiresAt, isLicenseAttached } =
    license;
  const isAttachedIcon = isLicenseAttachedIconValid && isLicenseAttached;
  switch (licensePermission) {
    case 'All':
    case 'FlexAll': {
      const icon = isAttachedIcon ? LicenseAllActivateIcon : LicenseAllIcon;
      return {
        iconList: [icon],
        licenseId,
        expiresAt,
        permission: licensePermission,
      };
    }
    case 'Temporary': {
      const icon = LicenseAllIcon;
      return {
        iconList: [icon],
        licenseId,
        expiresAt,
        permission: licensePermission,
      };
    }
    case 'MachineGuidance': {
      const icon = isAttachedIcon
        ? MachineGuidanceActivateIcon
        : MachineGuidanceIcon;
      return {
        iconList: [icon],
        licenseId,
        expiresAt,
        permission: licensePermission,
      };
    }
    case 'MachineGuidance2D': {
      const icon = isAttachedIcon
        ? MachineGuidanceActivateIcon
        : MachineGuidanceIcon;
      return {
        iconList: [icon],
        licenseId,
        expiresAt,
        permission: '2D MachineGuidance',
      };
    }
    case 'PayloadMeter': {
      const icon = isAttachedIcon ? PayloadMeterActivateIcon : PayloadMeterIcon;
      return {
        iconList: [icon],
        licenseId,
        expiresAt,
        permission: licensePermission,
      };
    }
    case 'Asbuilt': {
      const icon = isAttachedIcon ? AsbuiltActivateIcon : AsbuiltIcon;
      return {
        iconList: [icon],
        licenseId,
        expiresAt,
        permission: licensePermission,
      };
    }
    case 'Unlimited':
    case 'FlexUnlimited':
      return undefined;
    default:
  }
  console.error(`Illegal Permission: '${licensePermission}'`);
  return undefined;
};

export default licenseToConvertElement;
