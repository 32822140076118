import React from 'react';
import { GnssSettingAdminEdit, GnssSettingOwnerEdit } from '../..';
import { useGetUserRole } from '../../../hooks';

const GnssSettingEdit: React.FC = props => {
  const userRole = useGetUserRole();
  return userRole.isAdmin() ? (
    <GnssSettingAdminEdit {...props} />
  ) : (
    <GnssSettingOwnerEdit {...props} />
  );
};

GnssSettingEdit.displayName = 'GnssSettingEdit';
export default GnssSettingEdit;
