import { TabbedShowLayout as RaTabbedShowLayout } from 'react-admin';
import { ReactNodeLike, ReactElementLike } from 'prop-types';
import { createStyles, withStyles } from '@material-ui/core';

interface RaProps {
  children: ReactNodeLike;
  className: string;
  location: object;
  match: object;
  record: object;
  resource: string;
  basePath: string;
  value: number;
  version: number;
  tabs: ReactElementLike;
}

const TabbedShowLayout = withStyles(createStyles({ content: { padding: 0 } }))(
  RaTabbedShowLayout,
) as ForgivingComponentType<RaProps>;

TabbedShowLayout.displayName = 'TabbedShowLayout';
export default TabbedShowLayout;
