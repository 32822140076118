import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, TextField, createStyles, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { throttle } from 'lodash';
import {
  FieldTitle,
  InputHelperText,
  InputProps,
  useDataProvider,
  useInput,
} from 'react-admin';

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      '& > div.MuiFilledInput-root': {
        paddingTop: 'unset',
        paddingLeft: 'unset',
      },
    },
    textFieldWithValidate: {
      '& > div.MuiFilledInput-root': {
        paddingTop: 'unset',
        paddingLeft: 'unset',
        width: 700,
      },
    },
  }),
);
interface RetrofitItem {
  id: number; // id
  uuid: string; // uuid
  corporationId: string; // 企業Id
  siteId: string; // 現場Id
  corporationName: string; // 企業名
  machineInfoMachineName: string; // AutoComplete表示用 建機名称
  machineInfoMachineNameParameter: string; // ダイアログ表示用 建機名称
  machineInfoMachineId: string; // 管理番号/ID
  basicInfoSerialNumber: string; // シリアル番号
}

interface Props {
  handleChange?: (value: RetrofitItem, form: any) => void; // multiple モード時は呼ばれないので注意（未サポート）
  form?: any;
  filter?: { [key: string]: string | number };
  handleOption?: (text: string) => void;
  disabled: boolean;
  keepValidateSpace?: boolean;
}

const RetrofitAutoCompleteInput: FC<InputProps<Props>> = ({
  filter: argFilter = {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleOption = () => {},
  ...props
}) => {
  const {
    input: { onChange, multiple, value: finalFormValue, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const {
    disabled,
    helperText,
    label,
    margin = 'dense',
    resource,
    source,
    variant = 'filled',
    handleChange,
    form,
    keepValidateSpace = false,
  } = props;
  const classes = useStyles();
  const [autocompleteValue, setAutocompleteValue] = useState<
    RetrofitItem | RetrofitItem[] | null
  >(multiple ? [] : null);

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<RetrofitItem[]>([]);
  const jsonArgFilter = JSON.stringify(argFilter); // useMemo の deps に渡す為、文字列にしている
  const dataProvider = useDataProvider();

  const fetch = useMemo(
    () =>
      throttle(async (search: string) => {
        const tmp = search.split(' / ');

        const searchWord = tmp.length === 3 ? tmp[0] : search;
        const filter: { [key: string]: string | number } =
          searchWord !== ''
            ? {
                ...JSON.parse(jsonArgFilter),
                q: searchWord,
              }
            : {
                ...JSON.parse(jsonArgFilter),
              };
        const result = await dataProvider.getList<RetrofitItem>('retrofits', {
          filter,
          pagination: { page: 1, perPage: 9 },
          sort: { field: 'id', order: 'ASC' },
        });
        if (!result) return;

        setOptions(result.data);
      }),
    [dataProvider, jsonArgFilter],
  );

  useEffect(() => {
    fetch(inputValue);
  }, [inputValue, fetch]);

  const jsonAutocompleteValue = JSON.stringify(autocompleteValue); // useEffect の deps に渡す為、文字列にしている

  // レトロフィット取得API
  const fetchRetrofit = useMemo(() => {
    return async (retrofitId: number): Promise<RetrofitItem> => {
      const result = await dataProvider.getOne<RetrofitItem>('retrofits', {
        id: retrofitId,
      });
      if (!result) throw Error(`No Results: 'retrofits' id=${retrofitId}`);
      const { data } = result;
      return data;
    };
  }, [dataProvider]);

  useEffect(() => {
    if (Array.isArray(finalFormValue)) {
      // ----------------------------------
      // multiple の場合
      // ----------------------------------

      // form が管理しているレトロフィットID
      const retrofitIds = finalFormValue.map(id => Number(id));

      // state で管理している取得済みのレトロフィット配列
      const fetchedRetrofit = (
        JSON.parse(jsonAutocompleteValue) as RetrofitItem[]
      ).filter(({ id }) => retrofitIds.includes(id)); // クリアボタンで削除された項目は除外する

      // 取得済みのレトロフィットID
      const fetchedRetrofitIds = fetchedRetrofit
        .filter(({ id }) => retrofitIds.includes(id))
        .map(({ id }) => id);

      // 未取得のレトロフィットID
      const targetRetrofitIds = retrofitIds.filter(
        id => !fetchedRetrofitIds.includes(id),
      );
      Promise.all(
        targetRetrofitIds.map((retrofitId: number) =>
          fetchRetrofit(retrofitId),
        ),
      )
        .then(data => {
          setAutocompleteValue(fetchedRetrofit.concat(data));
        })
        .catch(e => {
          console.error(e);
        });
    } else {
      // ----------------------------------
      // single の場合
      // ----------------------------------
      if (finalFormValue === '' || Number.isNaN(Number(finalFormValue))) {
        return;
      }
      const retrofitId = Number(finalFormValue);
      const retrofit = JSON.parse(jsonAutocompleteValue) as RetrofitItem;
      if (retrofit && retrofit.id === retrofitId) {
        return; // 既に取得済み
      }
      fetchRetrofit(retrofitId)
        .then(data =>
          setAutocompleteValue({
            id: data.id,
            uuid: '',
            corporationId: '',
            siteId: '',
            corporationName: '',
            machineInfoMachineName: data.machineInfoMachineName,
            machineInfoMachineNameParameter:
              data.machineInfoMachineNameParameter,
            machineInfoMachineId: data.machineInfoMachineId,
            basicInfoSerialNumber: data.basicInfoSerialNumber,
          }),
        )
        .catch(e => {
          console.error(e);
        });
    }
  }, [finalFormValue, jsonAutocompleteValue, fetchRetrofit]);

  // validationメッセージの表示が必要な場合は右端に表示領域を確保
  const textFieldClass = keepValidateSpace
    ? classes.textFieldWithValidate
    : classes.textField;

  return (
    <Autocomplete
      multiple={multiple}
      {...rest}
      getOptionLabel={record => {
        const {
          machineInfoMachineId,
          machineInfoMachineName,
          machineInfoMachineNameParameter,
          basicInfoSerialNumber,
        } = record;
        const text = `${machineInfoMachineId} / ${machineInfoMachineName} / ${basicInfoSerialNumber}`;
        handleOption(
          machineInfoMachineId,
          machineInfoMachineNameParameter,
          basicInfoSerialNumber,
        );
        return text;
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={autocompleteValue}
      disabled={disabled}
      onChange={(_, newValue: RetrofitItem | RetrofitItem[] | null) => {
        if (!Array.isArray(newValue) && !newValue) {
          handleOption('');
          setAutocompleteValue(null);
          setOptions([]);
          onChange(undefined);
          return;
        }
        if (Array.isArray(newValue) && newValue.length === 0) {
          setAutocompleteValue([]);
          setOptions([]);
          onChange([]);
          return;
        }
        if (!Array.isArray(newValue)) {
          setOptions(newValue ? [newValue, ...options] : options);
          if (newValue) {
            onChange(String(newValue.id));
          }
          if (handleChange && newValue && form) {
            handleChange(newValue, form);
          }
        }
        if (Array.isArray(newValue)) {
          setOptions(newValue);
          onChange(newValue.map(v => String(v.id)));
        }
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField
          className={textFieldClass}
          {...params}
          label={
            label !== '' &&
            label !== false && (
              <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />
            )
          }
          error={!!(touched && error)}
          helperText={
            <InputHelperText
              touched={touched as boolean}
              error={error}
              helperText={helperText}
            />
          }
          disabled={disabled}
          variant={variant}
          margin={margin}
        />
      )}
      renderOption={({
        machineInfoMachineId,
        machineInfoMachineName,
        basicInfoSerialNumber,
      }) => (
        <Box>
          {' '}
          {machineInfoMachineId} / {machineInfoMachineName} /{' '}
          {basicInfoSerialNumber}
        </Box>
      )}
    />
  );
};

export default RetrofitAutoCompleteInput;
