import React, { useEffect, useState } from 'react';
import {
  downloadCSV,
  useTranslate,
  Identifier,
  TextField as RaTextField,
} from 'react-admin';
import { useLocation, useParams } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import jsonExport from 'jsonexport';
import { GeofenceAlertsData } from '../../../dataProvider';
import {
  useAccount,
  useGetSelefDetailData,
  useJumpTo,
  useResource,
} from '../../../hooks';
import {
  CustomList,
  CustomDateField,
  Datagrid,
  Loading,
  IconField,
  Filter,
  SiteSearchInput,
} from '../..';
import { getCsvDelimiter } from '../../../utils';
import { useGetSiteName } from '../retrofits/info/hooks';
import ColumnFilters from './view/ColumnFilters';

const ListFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      '& > div:nth-child(2)': {
        overflow: 'auto',
        height: 'calc(100vh - 320px)',
      },
    },
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
            // ヘッダ固定用スタイル
            position: 'sticky',
            top: 0,
            backgroundColor: '#29292f', // colors.tsのbackgroundと同じ色を設定すること
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 191,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 230,
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 110,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 169,
          },
        },
      },
    },
  }),
);

const useExporter = (resource: string) => {
  const base = `resources.${resource}.fields`;
  const translate = useTranslate();
  return async (record: GeofenceAlertsData[]) => {
    // 変数内のすべてのフィールドがCSV出力されてしまうため、不要なフィールドをomit
    // csv出力不要かつ以降の処理で未使用であるものはlintエラーになるため、lintエラー回避コメント文を追記
    const postsForExport = record.map(data => {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        id,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        corporationId,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        siteId,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        retrofitId,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        geofenceId,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        longitude,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        latitude,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        height,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        x,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        y,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        z,
        retrofit,
        ...postForExport
      } = data; // omit
      // convert
      postForExport.timeStamp = new Date(data.timeStamp).toLocaleString();
      postForExport.csvRetrofitName = retrofit?.machineInfoMachineName;
      // convert - 小数値変換
      return postForExport;
    });
    jsonExport(
      postsForExport,
      {
        forceTextDelimiter: true,
        rowDelimiter: getCsvDelimiter(),
        headers: [
          'timeStamp',
          'geofenceName',
          'alertType',
          'collisionPoint',
          'csvRetrofitName',
        ], // order fields in the export
        rename: [
          translate(`${base}.timeStamp`),
          translate(`${base}.geofenceName`),
          translate(`${base}.alertType`),
          translate(`${base}.collisionPoint`),
          translate(`${base}.retrofit.machineInfoMachineName`),
        ], // rename column name
      },
      (_, rawCsv: string) => {
        const BOM = '\uFEFF';
        const csv = `${BOM}${rawCsv}`; // UTF8でBOM付とするため、CSVの先頭にバイトオーダマークを付与
        downloadCSV(csv, `GeofenceAlert`);
      },
    );
  };
};

interface ApiResult {
  userCorporationId: string;
  breadcrumbs: string[];
}

const GeofenceAlertList: React.FC = () => {
  const resource = useResource('geofenceAlerts');
  const classes = useStyles();
  const { pathname: parentPath } = useLocation();
  const { gotoListEditRoutingTabbed } = useJumpTo();
  const getAccount = useAccount();
  const getSelefDetailData = useGetSelefDetailData();
  const getSiteName = useGetSiteName();
  // const redirectTo = useRedirect();
  const { siteId: urlSiteId } = useParams<{ siteId: string }>();
  const exporter = useExporter('geofenceAlerts');

  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: ApiResult;
  }>({
    loading: true,
    fetched: false,
    data: undefined,
  });

  useEffect(() => {
    // 既にDB取得済みでも、urlのretrofitIdが変わった場合は再度実施
    if (apiState.fetched) {
      return;
    }

    const api = async (): Promise<{
      data: {
        apiResult: ApiResult;
      };
    }> => {
      // accounts
      const {
        data: { corporationId },
      } = await getAccount();
      /*

        // retrofits
        const listFileter = {
          ownerCorporationId: corporationId,
          adminCorporationId: corporationId,
        };
        // 建機一覧から閲覧可能なレトロフィットであるか判定
        const retrofit = await getSelefDetailData(
          'retrofits',
          listFileter,
          retrofitId,
        );

        // 現場に紐づくレトロフィットであるか判定
        const siteAttachedListFileter = {
          siteId: urlSiteId || '',
        };
        const siteAttachedRetrofit = await getSelefDetailData(
          'retrofits',
          siteAttachedListFileter,
          retrofitId,
        );
        */

      // site ※パンくずリストで必要
      let siteName = '';
      if (urlSiteId) {
        const result = await getSiteName({ siteId: urlSiteId });
        siteName = result.data.siteName || '';
      }

      // DBの取得結果を使うため、パンくずリストはここで作成
      const breadcrumbs = [
        'resources.sites.name',
        '',
        siteName,
        'resources.geofences.name',
      ];
      return {
        data: {
          apiResult: {
            userCorporationId: corporationId,
            breadcrumbs,
          },
        },
      };
    };
    api().then(({ data: { apiResult } }) => {
      setApiState({ loading: false, fetched: true, data: apiResult });
    });
  }, [getSelefDetailData, getAccount, getSiteName, urlSiteId, apiState]);

  if (apiState.loading || !apiState.data) return <Loading />;
  const { breadcrumbs } = apiState.data;
  // 建機一覧に存在しないretrofitの地形計測結果は表示させない
  // または現場詳細画面から遷移する場合、対象現場に紐づかないretrofitの地形計測結果は表示させない
  // if (urlSiteId ? !siteAttachedData : !isOwnData) redirectTo('/');

  return (
    <CustomList
      className={classes.table}
      breadcrumbs={breadcrumbs}
      resource={resource}
      title="admin.pages.geofenceAlertList"
      bulkActionButtons={false}
      filter={{ siteId: urlSiteId }}
      filters={<ListFilter />}
      exporter={exporter}
      columnfilters={<ColumnFilters />}
    >
      <Datagrid
        className={classes.root}
        rowClick={(id: Identifier) => {
          gotoListEditRoutingTabbed(parentPath, id);
        }}
      >
        <CustomDateField resource={resource} source="timeStamp" showTime />
        <RaTextField resource={resource} source="geofenceName" />
        <IconField resource={resource} source="alertType" />
        <RaTextField resource={resource} source="collisionPoint" />
        <RaTextField
          resource={resource}
          source="retrofit.machineInfoMachineName"
        />
      </Datagrid>
    </CustomList>
  );
};

GeofenceAlertList.displayName = 'GeofenceAlertList';
export default GeofenceAlertList;
