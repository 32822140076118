import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { required, TextField } from 'react-admin';
import {
  Create,
  CustomForm,
  BreadcrumbsFiled,
  CustomerAutoCompleteInput,
  Loading,
} from '../..';
import { getParentPath } from '../../../utils';
import {
  useCustomGetOne,
  useGetSelefDetailData,
  useAccount,
} from '../../../hooks';

const ExtensionarmShare: FC = () => {
  const { pathname } = useLocation();
  const basePath = getParentPath(pathname);
  const props = {
    basePath,
    resource: 'extensionarmShares',
  };
  const baseParentPath = getParentPath(basePath);
  const bucketId = basePath.replace(`${baseParentPath}/`, '');
  const getAccount = useAccount();
  const { data: extensionarm, loading: extensionarmLoading } = useCustomGetOne(
    'extensionarms',
    bucketId,
  );
  const getSelefDetailData = useGetSelefDetailData();
  // const redirectTo = useRedirect();
  const [apiState, setApiState] = useState<{
    fetched: boolean;
    loading: boolean;
    data?: {
      fetchedBucketId: string;
      isOwnData: boolean;
      corporationId: string;
    };
  }>({ fetched: false, loading: true });

  useEffect(() => {
    if (apiState.fetched && apiState.data?.fetchedBucketId === bucketId) return;

    const api = async () => {
      // accounts
      const {
        data: { corporationId },
      } = await getAccount();

      // 自社のエクステンションアームであるか判定
      const filter = { corporationId };
      const isOwnExtensionarm = await getSelefDetailData(
        'extensionarms',
        filter,
        bucketId,
      );
      return {
        data: {
          apiResult: {
            isOwnData: !!isOwnExtensionarm,
            fetchedBucketId: bucketId,
            corporationId,
          },
        },
      };
    };
    api().then(({ data: { apiResult } }) => {
      setApiState({ fetched: true, loading: false, data: apiResult });
    });
  }, [bucketId, getAccount, getSelefDetailData, apiState]);

  if (
    apiState.loading ||
    extensionarmLoading ||
    !extensionarm ||
    !apiState.data
  )
    return <Loading />;
  // TODO: 閲覧不可なデータは現場一覧に遷移する機能を有効にする場合、isOwnDataを追加する
  const { corporationId } = apiState.data;
  // 一覧に存在しないエクステンションアームデータは表示させない
  // if (!isOwnData) redirectTo('/');

  return (
    <Create {...props}>
      <CustomForm
        title="admin.pages.extensionarmShare"
        redirect={basePath}
        initialValues={{
          fromExtensionarmId: Number(bucketId),
          name: extensionarm.name,
        }}
      >
        <BreadcrumbsFiled
          breadcrumbs={[
            'resources.buckets.name',
            'resources.extensionarms.name',
            extensionarm.name,
          ]}
          customPaths={[
            '/buckets',
            '/extensionarms',
            `/extensionarms/${extensionarm.id}`,
          ]}
          label="admin.pages.extensionarmShare"
        />
        <CustomerAutoCompleteInput
          resource="extensionarmShares"
          source="corporationId"
          validate={[required()]}
          fromCorporationId={corporationId}
        />
        <TextField
          resource="extensionarmShares"
          source="name"
          record={extensionarm}
        />
        <TextField
          resource="extensionarmShares"
          source="extensionarmType"
          record={extensionarm}
        />
        <TextField
          resource="extensionarmShares"
          source="extensionarmFile.title"
          record={extensionarm}
        />
      </CustomForm>
    </Create>
  );
};

ExtensionarmShare.displayName = 'ExtensionarmShare';
export default ExtensionarmShare;
