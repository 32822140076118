import { Identifier } from 'react-admin';
import { AnyObject } from '../utils';

export type DatagridParams = {
  data: AnyObject;
  ids: Identifier[];
};

export const listResultToDatagridData = (list: AnyObject[]): DatagridParams => {
  // Array を 以下の Object 形式に変換する
  // { $id: object, $id: object, ... }
  const data = list.reduce<{ [key: string]: AnyObject }>((r, it) => {
    const obj = r;
    obj[it.id] = it;
    return { ...obj };
  }, {});

  const ids = list.map(it => it.id);

  return { data, ids };
};
