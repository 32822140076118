import { SiteData } from '../../types';

const sites: SiteData[] = [
  {
    id: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '新宿タワー構築PJ現場',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2020-10-31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    name: 'コマツビル(仮)',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6708503458004,
    longitude: 139.742144219049,
    workPeriodStartDate: '2020-01-01',
    workPeriodEndDate: '2020-12-31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: '新宿タワー構築ＰＪ現場２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2020-01-01',
    workPeriodEndDate: '2021-03-31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'UsFt',
    status: 'Waiting',
  },
  {
    id: '74bdcc81-330b-4d66-900a-48b0d452c784',
    name: 'レンタル用現場０１',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6445897,
    longitude: 139.7344833,
    workPeriodStartDate: '2020-01-01',
    workPeriodEndDate: '2021-03-31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'Ft',
    status: 'Working',
  },
  {
    id: '4101d0da-4759-37e8-a502-d0f7de33f8ca',
    name: '新宿タワー構築PJ現場０３',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Completed',
  },
  {
    id: '6db4340a-4f63-abe4-aee3-66ae53424591',
    name: '新宿タワー構築PJ現場０４',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '9684c0ec-2913-ed24-33a4-ac424fc89171',
    name: '新宿タワー構築PJ現場０５',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '3ebca2cc-5f6e-b63b-f58e-16dddc62c2f8',
    name: '新宿タワー構築PJ現場０６',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '526d513e-aad0-f9bf-6ff6-c9d3ce1bc963',
    name: '新宿タワー構築PJ現場０７',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '4b54acba-d7cf-222d-006d-f4ecad1f09e1',
    name: '新宿タワー構築PJ現場０８',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'a3c3ccdb-2edf-aa15-2e26-366b9a21fb60',
    name: '新宿タワー構築PJ現場０９',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'e4e78652-46af-5370-1dd2-9c7ad4936d83',
    name: '新宿タワー構築PJ現場１０',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '7d97bbe4-d5c3-ac25-88f3-1c80b7d484ba',
    name: '新宿タワー構築PJ現場１１',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '069aecf5-3c6a-db06-48c2-b8a8d7f6e873',
    name: '新宿タワー構築PJ現場１２',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '2581b680-3a16-4d81-3f22-b79154cc0598',
    name: '新宿タワー構築PJ現場１３',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '304109ca-eeb7-73d4-5c8b-b53609d4f89f',
    name: '新宿タワー構築PJ現場１４',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '3ae35bfc-03b8-a1c5-717e-5f66c44319cb',
    name: '新宿タワー構築PJ現場１５',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'd676609b-83b7-1216-a543-25a875ac80f6',
    name: '新宿タワー構築PJ現場１６',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '54e2d43c-0ad9-7415-5d42-4fc4a25b6973',
    name: '新宿タワー構築PJ現場１７',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'd9c5b67b-364b-334b-a302-1d5ab5ce7fbd',
    name: '新宿タワー構築PJ現場１８',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'aacf44eb-2a30-2baf-7bea-1e9d41a572a5',
    name: '新宿タワー構築PJ現場１９',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'fba78689-0600-ec97-86b5-9a7c845773c6',
    name: '新宿タワー構築PJ現場２０',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '9edf296f-22b3-2669-61f9-9a6b5aa5f570',
    name: '新宿タワー構築PJ現場２１',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '87429b7a-c023-7123-d0fa-0e0417af8da2',
    name: '新宿タワー構築PJ現場２２',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'ae192166-db6b-3511-de44-23af569940f7',
    name: '新宿タワー構築PJ現場２３',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'a6783cfa-d06c-db1a-f3e2-9ef5e9d0a9ca',
    name: '新宿タワー構築PJ現場２４',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'b3cd8708-6be2-31de-7096-fa3ed8dfc948',
    name: '新宿タワー構築PJ現場２５',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'c536b391-fcd6-4375-4a8f-8380800f1ca5',
    name: '新宿タワー構築PJ現場２６',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'dcb36924-755c-64ce-0383-e9d89f7ff7ae',
    name: '新宿タワー構築PJ現場２７',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '23cba094-f382-dcf7-8011-20d9ab3781cc',
    name: '新宿タワー構築PJ現場２８',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '692034df-3c7a-fe85-ebf1-89d718b4bb58',
    name: '新宿タワー構築PJ現場２９',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'bfa2c637-3e0e-b309-64e6-6517267cab23',
    name: '新宿タワー構築PJ現場３０',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'bc8f86fd-1372-5954-eda3-97ace7edece9',
    name: '新宿タワー構築PJ現場３１',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '99f6d061-30b8-5a09-39f8-6378c08b284e',
    name: '新宿タワー構築PJ現場３２',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '83de4eb9-345b-a128-4eff-eb2c4a49dee9',
    name: '新宿タワー構築PJ現場３３',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '7c9a9448-7616-f608-fcf6-d6edeb384f20',
    name: '新宿タワー構築PJ現場３４',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'ac78f1aa-614f-543f-d5d6-c57bb3a036be',
    name: '新宿タワー構築PJ現場３５',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '2aa77cad-677c-2cd9-0979-aad269e50f7b',
    name: '新宿タワー構築PJ現場３６',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '383122e5-46ed-e41b-6e48-e42792fe6056',
    name: '新宿タワー構築PJ現場３７',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '4d53fb1e-0813-f78e-eee8-66a26302a3b8',
    name: '新宿タワー構築PJ現場３８',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '217904c5-6f23-705d-4957-fb60aae500cd',
    name: '新宿タワー構築PJ現場３９',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '80bf53e8-3963-af58-9e00-bed93c785607',
    name: '新宿タワー構築PJ現場４０',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '295507f0-890d-4581-70a0-1c09bcb49217',
    name: '新宿タワー構築PJ現場４１',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'e6e2b415-9c84-e1ea-4964-8c10f564eae7',
    name: '新宿タワー構築PJ現場４２',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '34b50ec9-4ed1-cb64-127c-da020de21a03',
    name: '新宿タワー構築PJ現場４３',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'fee1cab6-d66c-56c6-ad5a-1d41e40328d8',
    name: '新宿タワー構築PJ現場４４',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'e270e349-e7ad-ad65-eb14-c40b18b33cb1',
    name: '新宿タワー構築PJ現場４５',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'd201fd65-3674-a656-cc49-f174afa13954',
    name: '新宿タワー構築PJ現場４６',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '7e435380-afc8-6c5c-d60b-554c360ac181',
    name: '新宿タワー構築PJ現場４７',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'cd884866-4161-d6c0-f41b-5929ab338e77',
    name: '新宿タワー構築PJ現場４８',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '43a7e58c-2942-0acf-6b6b-cc7f46418bf8',
    name: '新宿タワー構築PJ現場４９',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'bed6e7a0-25ed-b26d-13b9-7f8b138f63c6',
    name: '新宿タワー構築PJ現場５０',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '97f9fc82-aefa-4087-6b6d-0ac34d76269f',
    name: '新宿タワー構築PJ現場５１',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '2942d9a4-22c9-63c1-0efa-9c1023559841',
    name: '新宿タワー構築PJ現場５２',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'e7904d51-820e-cc44-e748-0cffb2bfbbdd',
    name: '新宿タワー構築PJ現場５３',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'caa8f630-9e33-0504-7a70-13af5072b6c5',
    name: '新宿タワー構築PJ現場５４',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '885ee810-f780-2021-c592-ee65a9e15f71',
    name: '新宿タワー構築PJ現場５５',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '38010dfd-94ee-f82a-1c40-ce4e645c097f',
    name: '新宿タワー構築PJ現場５６',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '9aaa9314-9fd0-fa41-63de-67960d2d9eb7',
    name: '新宿タワー構築PJ現場５７',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '243acb50-0ab0-a5da-2a88-9752ce9e3622',
    name: '新宿タワー構築PJ現場５８',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'a4b26bfa-2ae5-ca97-688c-7011ed1e68e1',
    name: '新宿タワー構築PJ現場５９',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'fbdef450-24eb-88bf-ec08-9d1723cef0e7',
    name: '新宿タワー構築PJ現場６０',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'd558a380-f090-f3c1-9a91-8bc2f4a03ff0',
    name: '新宿タワー構築PJ現場６１',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '6c5c9584-ef07-5779-9186-3afd0c52f743',
    name: '新宿タワー構築PJ現場６２',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '2575bdea-9263-908d-e41e-2b5e83a96d94',
    name: '新宿タワー構築PJ現場６３',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '422f7ba3-7f86-2268-603d-5ed1b5443815',
    name: '新宿タワー構築PJ現場６４',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '136f8518-834b-3727-9f70-8b8520357535',
    name: '新宿タワー構築PJ現場６５',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '1bc2f271-6176-9cdf-acf8-0c115c5d6578',
    name: '新宿タワー構築PJ現場６６',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '28a903d8-1b26-3e3a-1dfb-ffebf09767fd',
    name: '新宿タワー構築PJ現場６７',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '07260d11-7597-0849-59b2-71778daa641e',
    name: '新宿タワー構築PJ現場６８',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '1d04310b-b284-75c3-2a29-598891f1c8fa',
    name: '新宿タワー構築PJ現場６９',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'a0167596-f824-4b4b-8fe9-5cc2f569f031',
    name: '新宿タワー構築PJ現場７０',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '7bfb9fec-b8be-49d4-7ae5-cd540cc72e73',
    name: '新宿タワー構築PJ現場７１',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '1dd6eb00-c986-d6de-c08b-cc1a35124a08',
    name: '新宿タワー構築PJ現場７２',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '7ed3deaa-c6d6-5de7-208e-16c8b4e513c7',
    name: '新宿タワー構築PJ現場７３',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '245eea95-d8bb-b20b-1be7-a70593fa9a11',
    name: '新宿タワー構築PJ現場７４',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '75078529-a5cc-7444-0347-f9a8e776848d',
    name: '新宿タワー構築PJ現場７５',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '72ca92fc-88a8-647a-cd21-671a5e908a84',
    name: '新宿タワー構築PJ現場７６',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_136440303_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'b616cd6b-78b4-f7ce-687c-36ac8a95f125',
    name: '新宿タワー構築PJ現場７７',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_166313709_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '42627971-ac6b-35c8-1bd7-4797ee789623',
    name: '新宿タワー構築PJ現場７８',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_246099773_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '88bd131f-8a1c-1609-7939-3aa123e5d465',
    name: '新宿タワー構築PJ現場７９',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
    latitude: 35.6958003,
    longitude: 139.6903789,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    name: '粟津クオリカオフィス工事',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    corporationName: 'クオリカ',
    latitude: 36.3608956939804,
    longitude: 136.423437390841,
    workPeriodStartDate: '2020-04-01',
    workPeriodEndDate: '2020-09-30',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/ebe0e417-a2ba-4a24-9052-2d78f69f122f.png',
    unitLength: 'M',
    status: 'Waiting',
  },
  {
    id: '39bbc4dd-6960-46c0-b6b3-75f431be1221',
    name: 'Site Test 1',
    corporationId: 'ef12300e-822d-4e8e-b820-2864288d0051',
    corporationName: 'Komatsu Ltd.',
    latitude: 35.6708503458004,
    longitude: 139.742144219049,
    workPeriodStartDate: '2019-08-01',
    workPeriodEndDate: '2020-07-30',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/AdobeStock_286309662_Preview.jpeg',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: 'f837c090-f5ac-4973-8b65-53ee677c1dcb',
    name: '砺波クオリカオフィス工事',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    corporationName: 'クオリカ',
    latitude: 36.6326617320766,
    longitude: 136.957107986651,
    workPeriodStartDate: '2020-01-01',
    workPeriodEndDate: '2020-12-31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/f837c090-f5ac-4973-8b65-53ee677c1dcb.png',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '5fc8b9e5-996e-4f4e-acae-0dc5d4ffae96',
    name: 'Test：現場登録',
    corporationId: '0da5298e-0081-08af-c9dd-3c8ae99fa742',
    corporationName: 'クオリカその２',
    latitude: 35.669194,
    longitude: 139.744605,
    workPeriodStartDate: '2019-11-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/5fc8b9e5-996e-4f4e-acae-0dc5d4ffae96.png',
    unitLength: 'UsFt',
    status: 'Working',
  },
  {
    id: '87bab37d-6121-8712-b46f-db62854ffb7c',
    name: '現場名５０６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    corporationId: '8715c009-aada-9c22-20e3-a63305b46db9',
    corporationName:
      '会社名５０６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    latitude: 35.659186,
    longitude: 139.705696,
    workPeriodStartDate: '2020-05-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/87bab37d-6121-8712-b46f-db62854ffb7c.png',
    unitLength: 'M',
    status: 'Working',
  },
  {
    id: '46fb0806-b4fe-6dc5-3127-d6fdc814c2f7',
    name: '現場名１００７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    corporationId: '8715c009-aada-9c22-20e3-a63305b46db9',
    corporationName:
      '会社名５０６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    latitude: 35.659186,
    longitude: 139.705696,
    workPeriodStartDate: '2020-05-01',
    workPeriodEndDate: '2021/03/31',
    photoUrl:
      'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/site_photo/46fb0806-b4fe-6dc5-3127-d6fdc814c2f7.png',
    unitLength: 'M',
    status: 'Working',
  },
];

export default sites;

/* 以下はLandLog Site APIのサンプル
{
id: '9d5e2a6a-c7dc-4df4-808a-24161fd835b0',
name: '汐留新築工事',
corporation: {
    id: 'a23228a1-fb63-4183-822c-123456789012',
    name: '株式会社ランドログ'
},
author: {
    id: 'dc252f15-e050-459f-a07d-7addfeff1f95',
    name: 'ランドログ 太郎'
},
abbreviated_name: '汐留工事',
address: {
    country: 'JP',
    zip_code: '105-0022',
    state: '東京都',
    city: '港区',
    address_1: '海岸1-2-20',
    address_2: '汐留ビルディング 15F'
},
coordinate: {
    latitude: '35.66689081094339',
    longitude: '139.76969528307006',
    time_zone: 'Asia/Tokyo'
},
unit: {
    distance: 'm',
    area: 'm2',
    volume: 'm3',
    currency: 'JPY'
},
work_period: {
    planned_start_date: '2019-08-01',
    planned_end_date: '2020-07-30',
    start_date: '2019-08-01',
    end_date: '2020-07-30'
},
work_type: {
    i_con: 'supported',
    type: '道路土工',
    name: null
},
photo_url: 'https://dev.azure.com/3dmg/_apis/GraphProfile/MemberAvatars/5b5d6cdc-9f8b-48ec-b0b6-25262fbc825e?overrideDisplayName=3dmg-server&amp',
note: 'テスト',
detailed_preference: {
    working_start_time: '9:30',
    working_end_time: '18:30',
    agent_name: 'ランドログ 太郎',
    earthmoving_start_date: '2019-08-01',
    earthmoving_end_date: '2019-09-30',
    earthmoving_area: '100',
    construction_number: '2019082900014412',
    work_place_name: '汐留ビルディング',
    tel: '03-3578-7757',
    fax: '03-3578-7758',
    contractor_name: 'ランドログ 二郎',
    permit_industries: '土木工事業',
    permit_number: '第00001号',
    permit_date: '2019-04-01',
    management: '株式会社ランドログ',
    manager_name: 'ランドログ 三郎',
    chief_name: 'ランドログ 四郎',
    construction_responsible_name: 'ランドログ 五郎',
    construction_detail: '河川土工',
    purpose: '防災ステーション',
    orderer_name: 'ランドログ 六郎',
    orderer_zip_code: '105-0022',
    orderer_state: '東京都',
    orderer_city: '港区',
    orderer_address_1: '海岸１?2?20',
    orderer_address_2: '汐留ビルディング 15F',
    responsible_name: 'ランドログ 七郎',
    contract_money_initially: '1000000',
    contract_money_change: '1000000',
    document_number: '001-000001'
},
archive: false,
status: 'working'
},
*/
