import { useCallback } from 'react';
import { useGetListAllPage } from '../../../../hooks';

const useGetRetrofitOwnerRegistCorporations = () => {
  const getListAllPage = useGetListAllPage();

  return useCallback(async (): Promise<string[]> => {
    const ownerCorporations = await getListAllPage({
      resource: 'retrofitOwnerRegistCorporations',
      filter: {},
      sort: { field: 'id', order: 'ASC' },
    });
    // ownerCorporations一覧から企業Idのみ取得
    return ownerCorporations.data.map(it => it.corporationId);
  }, [getListAllPage]);
};

export default useGetRetrofitOwnerRegistCorporations;
