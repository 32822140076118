import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { Typography } from '../../..';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: 18,
      fontWeight: 'bold',
      marginTop: 90,
      marginBottom: 40,
    },
  }),
);

const Title: FC = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const text = translate('admin.label.terms.title');
  return <Typography className={classes.root}>{text}</Typography>;
};

Title.displayName = 'Title';
export default Title;
