import { RetrofitShareFileData } from '../../types';

const retrofitShareFiles: RetrofitShareFileData[] = [
  {
    id: '6_2020-02-01T08:00:01.000Z',
    retrofitId: 6,
    shareFile: {
      id: '6_2020-02-01T08:00:01.000Z',
      name: 'UploadFiles6_1.zip',
      description: '',
      blobFileStatus: 'Created',
      fileTime: '2020-02-01T08:00:01.000Z',
      url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/RetrofitShareFile/6.zip',
    },
    createUser: 'unknown',
    createDate: '2020-02-01T08:00:01.000Z',
    updateUser: 'unknown',
    updateDate: '2020-02-01T08:00:01.000Z',
  },
  {
    id: '6_2020-02-02T08:00:01.000Z',
    retrofitId: 6,
    shareFile: {
      id: '6_2020-02-02T08:00:01.000Z',
      name: 'UploadFiles6_2.zip',
      description: '',
      blobFileStatus: 'Created',
      fileTime: '2020-02-02T08:00:01.000Z',
      url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/RetrofitShareFile/6.zip',
    },
    createUser: 'unknown',
    createDate: '2020-02-02T08:00:01.000Z',
    updateUser: 'unknown',
    updateDate: '2020-02-02T08:00:01.000Z',
  },
  {
    id: '6_2020-02-03T08:00:01.000Z',
    retrofitId: 6,
    shareFile: {
      id: '6_2020-02-03T08:00:01.000Z',
      name: 'UploadFiles6_3.zip',
      description: '',
      blobFileStatus: 'Created',
      fileTime: '2020-02-03T08:00:01.000Z',
      url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/RetrofitShareFile/6.zip',
    },
    createUser: 'unknown',
    createDate: '2020-02-03T08:00:01.000Z',
    updateUser: 'unknown',
    updateDate: '2020-02-03T08:00:01.000Z',
  },
  {
    id: '7_2020-02-01T08:00:01.000Z',
    retrofitId: 7,
    shareFile: {
      id: '7_2020-02-01T08:00:01.000Z',
      name: 'UploadFiles7_1.zip',
      description: '',
      blobFileStatus: 'Created',
      fileTime: '2020-02-01T08:00:01.000Z',
      url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/RetrofitShareFile/7.zip',
    },
    createUser: 'unknown',
    createDate: '2020-02-01T08:00:01.000Z',
    updateUser: 'unknown',
    updateDate: '2020-02-01T08:00:01.000Z',
  },
  {
    id: '7_2020-02-02T08:00:01.000Z',
    retrofitId: 7,
    shareFile: {
      id: '7_2020-02-02T08:00:01.000Z',
      name: 'UploadFiles7_1.zip',
      description: '',
      blobFileStatus: 'Created',
      fileTime: '2020-02-02T08:00:01.000Z',
      url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/RetrofitShareFile/7.zip',
    },
    createUser: 'unknown',
    createDate: '2020-02-02T08:00:01.000Z',
    updateUser: 'unknown',
    updateDate: '2020-02-02T08:00:01.000Z',
  },
  {
    id: '7_2020-02-03T08:00:01.000Z',
    retrofitId: 7,
    shareFile: {
      id: '7_2020-02-03T08:00:01.000Z',
      name: 'UploadFiles7_1.zip',
      description: '',
      blobFileStatus: 'Created',
      fileTime: '2020-02-03T08:00:01.000Z',
      url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/RetrofitShareFile/7.zip',
    },
    createUser: 'unknown',
    createDate: '2020-02-03T08:00:01.000Z',
    updateUser: 'unknown',
    updateDate: '2020-02-03T08:00:01.000Z',
  },
  {
    id: '8_2020-02-01T08:00:01.000Z',
    retrofitId: 8,
    shareFile: {
      id: '8_2020-02-01T08:00:01.000Z',
      name: 'UploadFiles8_1.zip',
      description: '',
      blobFileStatus: 'Created',
      fileTime: '2020-02-01T08:00:01.000Z',
      url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/RetrofitShareFile/8.zip',
    },
    createUser: 'unknown',
    createDate: '2020-02-01T08:00:01.000Z',
    updateUser: 'unknown',
    updateDate: '2020-02-01T08:00:01.000Z',
  },
  {
    id: '8_2020-02-02T08:00:01.000Z',
    retrofitId: 8,
    shareFile: {
      id: '8_2020-02-02T08:00:01.000Z',
      name: 'UploadFiles8_2.zip',
      description: '',
      blobFileStatus: 'Created',
      fileTime: '2020-02-02T08:00:01.000Z',
      url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/RetrofitShareFile/8.zip',
    },
    createUser: 'unknown',
    createDate: '2020-02-02T08:00:01.000Z',
    updateUser: 'unknown',
    updateDate: '2020-02-02T08:00:01.000Z',
  },
  {
    id: '8_2020-02-03T08:00:01.000Z',
    retrofitId: 8,
    shareFile: {
      id: '8_2020-02-03T08:00:01.000Z',
      name: 'UploadFiles8_3.zip',
      description: '',
      blobFileStatus: 'Created',
      fileTime: '2020-02-03T08:00:01.000Z',
      url: 'https://3dmg-frontend-retrofitdata.s3-ap-northeast-1.amazonaws.com/RetrofitShareFile/8.zip',
    },
    createUser: 'unknown',
    createDate: '2020-02-03T08:00:01.000Z',
    updateUser: 'unknown',
    updateDate: '2020-02-03T08:00:01.000Z',
  },
];

export default retrofitShareFiles;
