import { DataProvider } from 'react-admin';
import startremoteGetOne from './startremoteGetOne';
import panelstatusGetMany from './panelstatusGetMany';
import pointavailabilityUpdate from './pointavailabilityUpdate';

const remoteDataProvider: DataProvider = {
  getList: () => Promise.reject(new Error('not supported')),

  getOne: (resource, params): any => {
    if (resource === 'startremote') return startremoteGetOne(params);
    return Promise.reject(new Error('not supported'));
  },
  getMany: (resource, params): any => {
    if (resource === 'panelstatus') return panelstatusGetMany(params);
    return Promise.reject(new Error('not supported'));
  },
  getManyReference: () => Promise.reject(new Error('not supported')),
  update: (resource, params): any => {
    if (resource === 'pointavailability')
      return pointavailabilityUpdate(params.data.id);
    return Promise.reject(new Error('not supported'));
  },
  updateMany: () => Promise.reject(new Error('not supported')),
  create: () => Promise.reject(new Error('not supported')),
  delete: () => Promise.reject(new Error('not supported')),
  deleteMany: () => Promise.reject(new Error('not supported')),
};

export default remoteDataProvider;
