import React, { FC, useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Loading, useDataProvider } from 'react-admin';
import { useParams } from 'react-router-dom';
import { omit } from 'lodash';
import {
  Edit,
  CustomForm,
  BreadcrumbsFiled,
  TextField,
  IconField,
  DatePickerInput,
  FunctionField,
} from '../../../..';
import { useResource, useAccount } from '../../../../../hooks';
import { RetrofitData, RetrofitRentalData } from '../../../../../dataProvider';
import { dateStringToLocaleDate } from '../../../../../utils';
import SaveButton from './SaveButton';
import DeleteButton from './DeleteButton';
import MachineTypeField from './MachineTypeField';
import { useGetAvailableEndDate, useGetAvailableStartDate } from '../hooks';

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      '& > div': {
        marginLeft: -14,
      },
    },
  }),
);

const RentalEdit: FC<{
  props: any;
  retrofitData: RetrofitData;
  actualStartDate: string | undefined;
  retrofitRentalId: number | undefined;
}> = ({ props, retrofitData, actualStartDate, retrofitRentalId }) => {
  const resource = useResource('retrofitRentals');
  const retrofits = useResource('retrofits');
  const getAccount = useAccount();
  const { id: rentalId } = useParams<{ id: string }>();
  const availableDate = '2020/01/01';
  const classes = useStyles();
  const { id: retrofitId } = retrofitData;

  const dataProvider = useDataProvider();
  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: {
      availableStartDate: Date;
      availableEndDate: Date;
    };
  }>({ loading: true, fetched: false });

  const getAvailableStartDate = useGetAvailableStartDate(
    new Date(availableDate),
  );
  const getAvailableEndDate = useGetAvailableEndDate();

  // レンタルリストを取得し、最後の actualEndDate の日付を取得する
  useEffect(() => {
    if (apiState.fetched) return;
    setApiState({ loading: true, fetched: true });

    const api = async () => {
      // account
      const {
        data: { corporationId },
      } = await getAccount();

      // 貸出開始日コントロールで選択できる日時の最小値を取得
      const {
        data: { startDate: availableStartDate },
      } = await getAvailableStartDate({
        retrofitId,
        corporationId,
        parentRentalId: retrofitRentalId,
      });

      // 貸出終了日コントロールで選択できる日時の最小値を取得
      const {
        data: { endDate: availableEndDate },
      } = await getAvailableEndDate({
        currentRentalId: Number(rentalId),
        availableStartDate,
      });

      return {
        availableStartDate,
        availableEndDate,
      };
    };
    api().then(result => {
      setApiState({
        loading: false,
        fetched: true,
        data: result,
      });
    });
  }, [
    dataProvider,
    resource,
    retrofitId,
    rentalId,
    actualStartDate,
    retrofitRentalId,
    apiState.fetched,
    getAccount,
    getAvailableStartDate,
    getAvailableEndDate,
  ]);

  // 引数のretrofitDataがそもそも設定されていない場合は空
  if (!retrofitData) return <></>;
  if (apiState.loading || !apiState.data) return <Loading />;

  const { availableStartDate, availableEndDate } = apiState.data;

  const planDateValidation = (
    allValues: RetrofitRentalData,
    isStart: boolean,
  ) => {
    if (!allValues.planStartDate) {
      return null;
    }
    if (
      dateStringToLocaleDate(allValues.planStartDate) <
      dateStringToLocaleDate(availableDate)
    ) {
      return {
        message: 'admin.validation.inputAvailableDate',
        args: {
          date: new Date(availableDate).toLocaleDateString(),
        },
      };
    }
    if (!allValues.planEndDate) {
      return null;
    }
    if (
      dateStringToLocaleDate(allValues.planStartDate) <=
      dateStringToLocaleDate(allValues.planEndDate)
    ) {
      return null;
    }
    return isStart
      ? 'admin.validation.incorrectPlanStartDate'
      : 'admin.validation.incorrectPlanEndDate';
  };

  const planStartDateValidation = (
    unusedValue: any,
    allValues: RetrofitRentalData,
  ) => {
    return planDateValidation(allValues, true);
  };

  const planEndDateValidation = (
    unusedValue: any,
    allValues: RetrofitRentalData,
  ) => {
    return planDateValidation(allValues, false);
  };

  const actualDateValidation = (
    allValues: RetrofitRentalData,
    isStart: boolean,
  ) => {
    if (!allValues.actualStartDate && allValues.actualEndDate) {
      return 'admin.validation.withActualStartDate';
    }
    if (!allValues.actualStartDate) {
      return null;
    }
    if (!allValues.actualEndDate) {
      return null;
    }
    if (
      dateStringToLocaleDate(allValues.actualStartDate) <=
      dateStringToLocaleDate(allValues.actualEndDate)
    ) {
      return null;
    }
    return isStart
      ? 'admin.validation.incorrectActualStartDate'
      : 'admin.validation.incorrectActualEndDate';
  };

  const actualStartDateValidation = (
    unusedValue: any,
    allValues: RetrofitRentalData,
  ) => {
    return actualDateValidation(allValues, true);
  };

  const actualEndDateValidation = (
    unusedValue: any,
    allValues: RetrofitRentalData,
  ) => {
    return actualDateValidation(allValues, false);
  };

  return (
    <Edit {...omit(props, 'siteId')}>
      <CustomForm
        title="admin.pages.retrofitRentalEdit"
        deleteButton={<DeleteButton />}
        redirect={false}
        saveButton={
          <SaveButton
            retrofitdata={retrofitData}
            siteId={
              retrofitData.siteId === undefined ? '' : retrofitData.siteId
            }
          />
        }
      >
        <BreadcrumbsFiled
          breadcrumbs={[
            'resources.retrofits.name',
            'admin.breadcrumbs.retrofitEdit',
          ]}
          label="admin.pages.retrofitRentalEdit"
        />
        <FunctionField
          resource={retrofits}
          label="resources.retrofits.fields.machineInfoMachineType"
          record={retrofitData}
          render={record => <MachineTypeField record={record} />}
        />
        <TextField
          record={retrofitData}
          resource={retrofits}
          source="machineInfoMachineId"
          label="resources.retrofits.fields.machineInfoMachineId"
        />
        <TextField
          record={retrofitData}
          resource={retrofits}
          source="machineInfoMachineName"
          label="resources.retrofits.fields.machineInfoMachineName"
        />
        <TextField
          record={retrofitData}
          resource={retrofits}
          source="machineInfoCompanyName"
          label="resources.retrofits.fields.machineInfoCompanyName"
        />
        <TextField resource={resource} source="toCorporationName" />
        <TextField resource={resource} source="email" />
        <DatePickerInput
          resource={resource}
          source="planStartDate"
          validate={[planStartDateValidation]}
        />
        <DatePickerInput
          resource={resource}
          source="planEndDate"
          validate={[planEndDateValidation]}
        />
        <DatePickerInput
          resource={resource}
          source="actualStartDate"
          validate={actualStartDateValidation}
          minDate={availableStartDate}
          maxDate={new Date()}
        />
        <DatePickerInput
          resource={resource}
          source="actualEndDate"
          validate={[actualEndDateValidation]}
          minDate={availableEndDate}
          maxDate={new Date()}
        />
        <IconField
          resource={resource}
          source="isApproved"
          className={classes.icon}
        />
      </CustomForm>
    </Edit>
  );
};

RentalEdit.displayName = 'RentalEdit';
export default RentalEdit;
