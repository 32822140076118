import React, { FormHTMLAttributes } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  FormWithRedirect as RaFormWithRedirect,
  RedirectionSideEffect,
} from 'ra-core';
import {
  FormInput,
  CustomToolbar,
  ContentHeader,
  ResourceName,
  Record,
  CustomSaveButton,
  LinkButton,
  CustomDeleteButton,
} from '..';
import { Resources } from '../../dataProvider';
import { ReturnIcon, SaveIcon, DeleteIcon } from '../../assets';

const FormWithRedirect: React.ComponentType<any> = RaFormWithRedirect;
type FormHTMLProps = FormHTMLAttributes<HTMLFormElement>;
interface Props<T extends ResourceName> {
  resource: T;
  initialValues: Partial<Record<T>>;
  record: Record<T>;
  redirect: RedirectionSideEffect;
  save: (
    data: Record<T>,
    redirect?: RedirectionSideEffect,
    callbacks?: {
      onSuccess: () => void;
      onFailure: (error: string | { message?: string }) => void;
    },
  ) => void;
  saving: object | boolean;
  submitOnEnter: boolean;
  title: string;
  undoable: boolean;
  validate: (value: Record<T>) => object;
  version: number;
  saveButton: React.ReactElement<any> | boolean;
  deleteButton: React.ReactElement<any> | boolean;
  cancelButton: React.ReactElement<any> | boolean;
  actionButton: React.ReactElement<any> | boolean;
  action2Button: React.ReactElement<any> | boolean;
  bottomToolbar: boolean;
  buttonOrder: string[];
  classes: object;
  icon: React.ReactElement<any> | boolean;
  right: React.ReactElement<any> | boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > div.right': {
        flexGrow: 1,
        paddingLeft: 8,
      },
      '& > div.icon': {
        '& > svg': {
          paddingRight: 8,
        },
      },
    },
    footer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      marginTop: 39,
      marginBottom: 24,
    },
  }),
);

const CustomForm = <T extends keyof Resources>(
  props: React.PropsWithChildren<Partial<Props<T> & FormHTMLProps>>,
): React.ReactElement | null => {
  const {
    cancelButton,
    saveButton,
    deleteButton,
    actionButton,
    action2Button,
  } = props;
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <CustomFormView
          cancelButton={cancelButton}
          saveButton={saveButton}
          deleteButton={deleteButton}
          actionButton={actionButton}
          action2Button={action2Button}
          {...formProps}
        />
      )}
    />
  );
};

const CustomFormView = (props: any) => {
  const {
    bottomToolbar = false,
    // Toolbar内のボタン配置順を制御するprops
    buttonOrder,
    // React AdminのSimpleFormで利用しているprops
    basePath,
    children,
    className,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    margin,
    pristine,
    record,
    redirect,
    resource,
    saving,
    submitOnEnter,
    undoable,
    variant,
    form, // add: 画面側で final-form のAPIを利用したい為
    // Customで追加
    title,
    saveButton: givenSaveButton,
    deleteButton: givenDeleteButton,
    cancelButton: givenCancelButton,
    actionButton: givenActionButton,
    action2Button: givenAction2Button,
    classes: classOverride,
    icon,
    right,
  } = props;
  const classes = useStyles({ classes: classOverride });
  const saveButton =
    givenSaveButton !== false && !givenSaveButton ? (
      <CustomSaveButton>
        <SaveIcon />
      </CustomSaveButton>
    ) : (
      givenSaveButton
    );
  const deleteButton =
    givenDeleteButton !== false && !givenDeleteButton ? (
      <CustomDeleteButton>
        <DeleteIcon />
      </CustomDeleteButton>
    ) : (
      givenDeleteButton
    );
  const cancelButton =
    givenCancelButton !== false && !givenCancelButton ? (
      <LinkButton>
        <ReturnIcon />
      </LinkButton>
    ) : (
      givenCancelButton
    );
  const actionButton =
    givenActionButton !== false && !givenActionButton
      ? null
      : givenActionButton;
  const action2Button =
    givenAction2Button !== false && !givenAction2Button
      ? null
      : givenAction2Button;

  const toolBar = (
    <CustomToolbar
      {...{
        buttonOrder,
        saveButton,
        deleteButton,
        cancelButton,
        actionButton,
        action2Button,
        basePath,
        handleSubmitWithRedirect,
        handleSubmit,
        invalid,
        pristine,
        record,
        redirect,
        resource,
        saving,
        submitOnEnter,
        undoable,
        form,
      }}
    />
  );

  const body = React.Children.map(
    children,
    input =>
      input && (
        <FormInput
          form={form}
          basePath={basePath}
          input={input}
          record={record}
          resource={resource}
          variant={input.props.variant || variant}
          margin={input.props.margin || margin}
        />
      ),
  );

  return (
    <form className={className}>
      <div className={classes.header}>
        <ContentHeader title={title} icon={icon} right={right} />
        {!bottomToolbar && toolBar}
      </div>
      <div>{body}</div>
      {bottomToolbar && <div className={classes.footer}>{toolBar}</div>}
    </form>
  );
};

export default CustomForm;
