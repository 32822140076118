import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Button } from '../../../..';
import { mapStyle } from '../../../../../theme';

const useStyle = makeStyles(() =>
  createStyles({
    root: {
      ...mapStyle.button,
    },
  }),
);

const MapCommonButton: FC<{
  svgIcon: any;
  testId?: string;
  onClick?: () => void;
  classes?: object;
}> = ({ svgIcon, testId, onClick, classes: classOverride }) => {
  const classes = useStyle({ classes: classOverride });
  return (
    <Button
      onClick={() => {
        if (onClick) onClick();
      }}
      className={classes.root}
      data-testid={testId}
    >
      {svgIcon}
    </Button>
  );
};

MapCommonButton.displayName = 'MapCommonButton';
export default MapCommonButton;
