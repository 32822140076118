import { DataProvider, CreateParams, CreateResult } from 'react-admin';
import { retryAsync } from 'ts-retry';
import { File as FileData, RetrofitNamePlateFileApiData } from '..';
import defaultRetryParams from './defaultRetryParams';

export class RetrofitNamePlateError extends Error {}

const createRetrofitNamePlate = async (
  dataProvider: DataProvider,
  params: CreateParams,
): Promise<CreateResult> => {
  const { title, rawFile } = params.data;
  const { type: mimeType } = rawFile;

  // ファイルの作成
  const blobFileType =
    mimeType === 'image/png' ? 'NamePlatePngImage' : 'NamePlateImage';
  const fileData: FileData = {
    blobFileType,
    name: title,
  };
  const fileResult = await dataProvider.create('files', {
    data: fileData,
  });
  const { id: imageFileId, url } = fileResult.data as FileData;
  if (!imageFileId || !url) throw new Error('Invalid file data');

  // ファイルのアップロード
  await fetch(url, {
    method: 'PUT',
    body: rawFile,
    headers: { 'Content-Type': 'application/octet-stream' },
  });

  // 銘板解析API実行
  try {
    const result = await retryAsync(
      async () =>
        dataProvider.create<RetrofitNamePlateFileApiData>(
          'retrofitNamePlates',
          {
            data: {
              imageFileId,
            },
          },
        ),
      defaultRetryParams,
    );
    const { id, serialNumber, extractStatus } = result.data;
    return {
      data: { id, serialNumber, extractStatus },
    };
  } catch {
    throw new RetrofitNamePlateError();
  }
};

export default createRetrofitNamePlate;
