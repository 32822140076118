import React from 'react';
import { LinkButton } from '..';

const CreateButton: React.FC<
  Partial<{
    basePath: string;
    label: string;
    disabled?: boolean;
  }>
> = ({ basePath, label, disabled, children }) => (
  <LinkButton
    path={`${basePath}/create`}
    data-testid={label}
    disabled={disabled}
  >
    {children}
  </LinkButton>
);

CreateButton.displayName = 'CreateButton';
export default CreateButton;
