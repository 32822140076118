import { GetListParams, GetListResult } from 'react-admin';
import {
  BasicSettingDownloadInfo,
  CalibrationDownloadInfo,
  ConfigDownloadInfo,
  getHttpClient,
  RetrofitKitInfoDataObject,
} from '.';
import {
  RetrofitBasicSettingsData,
  RetrofitCalibsData,
  RetrofitConfigData,
  RetrofitKitInfoData,
} from '..';
import { config } from '../../utils';

// Range Interface
type range = {
  Start: number;
  End: number;
  Count: number;
};

const makeId = (retrofitId: number, timeStamp: string) =>
  `${retrofitId}_${timeStamp}`;

const convertWithUrl = (
  data: {
    retrofitId: number;
    timeStamp: string;
    url?: string;
    userCorporationId?: string;
  }[],
) =>
  data.map(({ retrofitId, timeStamp, url, userCorporationId }) => ({
    id: makeId(retrofitId, timeStamp),
    retrofitId,
    timeStamp,
    url: url || '',
    userCorporationId: userCorporationId || '',
  }));

const resourceHeaderRange = (
  apiResource: string,
  contentRange: string | null,
) => {
  if (contentRange === null)
    return {
      Start: 0,
      End: 0,
      Count: 0,
    } as range;

  const range = contentRange.replace(apiResource, ``).trim().split(/[-/]/);

  return {
    Start: Number(range[0]),
    End: Number(range[1]),
    Count: Number(range[2]),
  } as range;
};

const resourceMap = {
  retrofitConfigs: {
    apiResource: 'retrofitconfig',
    convert: (data: ConfigDownloadInfo[]): RetrofitConfigData[] =>
      convertWithUrl(data),
  },
  retrofitKitInfos: {
    apiResource: 'retrofitkitinfo',
    convert: (data: RetrofitKitInfoDataObject[]): RetrofitKitInfoData[] =>
      convertWithUrl(data),
  },
  retrofitCalibs: {
    apiResource: 'retrofitcalibs',
    convert: (data: CalibrationDownloadInfo[]): RetrofitCalibsData[] =>
      convertWithUrl(data),
  },
  retrofitBasicSettings: {
    apiResource: 'retrofitbasicsetting',
    convert: (data: BasicSettingDownloadInfo[]): RetrofitBasicSettingsData[] =>
      convertWithUrl(data),
  },
};

export default async (
  resource: keyof typeof resourceMap,
  { filter, sort, pagination: { page, perPage } }: GetListParams,
): Promise<GetListResult> => {
  if (!('retrofitId' in filter))
    throw new Error('retrofitId shoud be in filter');
  const { retrofitId, userCorporationId } = filter;
  const apiUrl = config.api.admin.apiUri;
  const { apiResource, convert } = resourceMap[resource];
  const jsonFilter = userCorporationId
    ? JSON.stringify({
        userCorporationId,
      })
    : undefined;

  // Retrofit Id Param
  const retrofitIdQueryParams = `retrofitId=${retrofitId}`;

  // Filter Param
  const filterQueryParams = jsonFilter
    ? `&filter=${encodeURIComponent(jsonFilter)}`
    : ``;

  // Sort Param
  const sortParam = sort.field === 'id' ? 'DESC' : sort.order;
  const sortParamValue = `["TimeStamp","${sortParam}"]`;
  const sortQueryParams = `&sort=${encodeURIComponent(sortParamValue)}`;

  // Range Param
  const currentNumber = (page - 1) * perPage;
  const nextMaxNumber = page * perPage - 1;
  const rangeParam = encodeURIComponent(`[${currentNumber},${nextMaxNumber}]`);
  const rangeQueryParams = `&range=${rangeParam}`;

  // URI Conversion
  const encodeUrl = jsonFilter
    ? `${apiUrl}/${apiResource}?${retrofitIdQueryParams}${filterQueryParams}${sortQueryParams}${rangeQueryParams}`
    : `${apiUrl}/${apiResource}?${retrofitIdQueryParams}${sortQueryParams}${rangeQueryParams}`;

  const httpClient = getHttpClient();
  const { headers, json } = await httpClient(encodeUrl);
  const contentRange = headers.get('Content-Range');
  const range = resourceHeaderRange(apiResource, contentRange);
  const total = range.Count;
  const data = convert(json);
  return { data, total };
};
