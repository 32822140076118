import React, { Component, ErrorInfo } from 'react';
import { History } from 'history';
import { Error as RaError } from 'react-admin';

interface Props {
  history: History;
}

interface State {
  hasError: boolean;
  error?: Error;
  info?: ErrorInfo;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
    /**
     * Reset the error state upon navigation
     *
     * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
     */
    props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true, error, info });
  }

  render() {
    const { hasError, error, info } = this.state;
    return (
      <>
        {hasError ? (
          <RaError error={error} errorInfo={info} />
        ) : (
          this.props.children
        )}
      </>
    );
  }
}

export default ErrorBoundary;
