import { Confirm as RaConfirm } from 'react-admin';
import { ReactElementLike } from 'prop-types';

interface RaProps {
  cancel: string;
  classes: object;
  confirm: string;
  confirmColor: string;
  ConfirmIcon: ReactElementLike;
  CancelIcon: ReactElementLike;
  content: string;
  isOpen: boolean;
  loading: boolean;
  onClose: Function;
  onConfirm: Function;
  title: string;
}

const Confirm = RaConfirm as ForgivingComponentType<RaProps>;
Confirm.displayName = 'Confirm';

export default Confirm;
