import { RetrofitAccuraciesData } from '../../types';

const retrofitAccuracies: RetrofitAccuraciesData[] = [
  {
    id: '6_2020-02-01T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-11T08:00:01.000Z',
    surveyPoint: 'center',
    positionN: 22842.631,
    positionE: -44039.998,
    positionZ: 3.141,
    machineOffsetN: 69.057,
    machineOffsetE: -59.976,
    machineOffsetZ: -1.434,
    referencePointName: 'c',
    referencePointN: 22842.649,
    referencePointE: -44040.093,
    referencePointZ: 3.26,
  },
  {
    id: '6_2020-02-29T00:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'Ft',
    checkDateTime: '2020-12-29T00:00:01.000Z',
    surveyPoint: '000',
    positionN: 22242.631,
    positionE: -54039.998,
    positionZ: 3.241,
    machineOffsetN: 79.057,
    machineOffsetE: -69.976,
    machineOffsetZ: -2.434,
    referencePointName: '000',
    referencePointN: 22242.649,
    referencePointE: -44440.093,
    referencePointZ: 3.36,
  },
  {
    id: '6_2020-02-02T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-11T08:00:01.000Z',
    surveyPoint: 'center',
    positionN: 22842.631,
    positionE: -44039.998,
    positionZ: 3.141,
    machineOffsetN: 69.057,
    machineOffsetE: -59.976,
    machineOffsetZ: -1.434,
    referencePointName: 'c',
    referencePointN: 22842.649,
    referencePointE: -44040.093,
    referencePointZ: 3.26,
  },
  {
    id: '6_2020-02-03T00:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-29T00:00:01.000Z',
    surveyPoint: 'center02',
    positionN: 22242.631,
    positionE: -54039.998,
    positionZ: 3.241,
    machineOffsetN: 79.057,
    machineOffsetE: -69.976,
    machineOffsetZ: -2.434,
    referencePointName: 'd',
    referencePointN: 22242.649,
    referencePointE: -44440.093,
    referencePointZ: 3.36,
  },
  {
    id: '6_2020-02-04T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-11T08:00:01.000Z',
    surveyPoint: 'center',
    positionN: 22842.631,
    positionE: -44039.998,
    positionZ: 3.141,
    machineOffsetN: 69.057,
    machineOffsetE: -59.976,
    machineOffsetZ: -1.434,
    referencePointName: 'c',
    referencePointN: 22842.649,
    referencePointE: -44040.093,
    referencePointZ: 3.26,
  },
  {
    id: '6_2020-02-05T00:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-29T00:00:01.000Z',
    surveyPoint: 'center02',
    positionN: 22242.631,
    positionE: -54039.998,
    positionZ: 3.241,
    machineOffsetN: 79.057,
    machineOffsetE: -69.976,
    machineOffsetZ: -2.434,
    referencePointName: 'd',
    referencePointN: 22242.649,
    referencePointE: -44440.093,
    referencePointZ: 3.36,
  },
  {
    id: '6_2020-02-06T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-11T08:00:01.000Z',
    surveyPoint: 'center',
    positionN: 22842.631,
    positionE: -44039.998,
    positionZ: 3.141,
    machineOffsetN: 69.057,
    machineOffsetE: -59.976,
    machineOffsetZ: -1.434,
    referencePointName: 'c',
    referencePointN: 22842.649,
    referencePointE: -44040.093,
    referencePointZ: 3.26,
  },
  {
    id: '6_2020-02-07T00:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-29T00:00:01.000Z',
    surveyPoint: 'center02',
    positionN: 22242.631,
    positionE: -54039.998,
    positionZ: 3.241,
    machineOffsetN: 79.057,
    machineOffsetE: -69.976,
    machineOffsetZ: -2.434,
    referencePointName: 'd',
    referencePointN: 22242.649,
    referencePointE: -44440.093,
    referencePointZ: 3.36,
  },
  {
    id: '6_2020-02-08T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-11T08:00:01.000Z',
    surveyPoint: 'center',
    positionN: 22842.631,
    positionE: -44039.998,
    positionZ: 3.141,
    machineOffsetN: 69.057,
    machineOffsetE: -59.976,
    machineOffsetZ: -1.434,
    referencePointName: 'c',
    referencePointN: 22842.649,
    referencePointE: -44040.093,
    referencePointZ: 3.26,
  },
  {
    id: '6_2020-02-09T00:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-29T00:00:01.000Z',
    surveyPoint: 'center02',
    positionN: 22242.631,
    positionE: -54039.998,
    positionZ: 3.241,
    machineOffsetN: 79.057,
    machineOffsetE: -69.976,
    machineOffsetZ: -2.434,
    referencePointName: 'd',
    referencePointN: 22242.649,
    referencePointE: -44440.093,
    referencePointZ: 3.36,
  },
  {
    id: '6_2020-02-10T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-11T08:00:01.000Z',
    surveyPoint: 'center',
    positionN: 22842.631,
    positionE: -44039.998,
    positionZ: 3.141,
    machineOffsetN: 69.057,
    machineOffsetE: -59.976,
    machineOffsetZ: -1.434,
    referencePointName: 'c',
    referencePointN: 22842.649,
    referencePointE: -44040.093,
    referencePointZ: 3.26,
  },
  {
    id: '6_2020-02-11T00:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-29T00:00:01.000Z',
    surveyPoint: 'center02',
    positionN: 22242.631,
    positionE: -54039.998,
    positionZ: 3.241,
    machineOffsetN: 79.057,
    machineOffsetE: -69.976,
    machineOffsetZ: -2.434,
    referencePointName: 'd',
    referencePointN: 22242.649,
    referencePointE: -44440.093,
    referencePointZ: 3.36,
  },
  {
    id: '6_2020-02-12T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-11T08:00:01.000Z',
    surveyPoint: 'center',
    positionN: 22842.631,
    positionE: -44039.998,
    positionZ: 3.141,
    machineOffsetN: 69.057,
    machineOffsetE: -59.976,
    machineOffsetZ: -1.434,
    referencePointName: 'c',
    referencePointN: 22842.649,
    referencePointE: -44040.093,
    referencePointZ: 3.26,
  },
  {
    id: '6_2020-02-13T00:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-29T00:00:01.000Z',
    surveyPoint: 'center02',
    positionN: 22242.631,
    positionE: -54039.998,
    positionZ: 3.241,
    machineOffsetN: 79.057,
    machineOffsetE: -69.976,
    machineOffsetZ: -2.434,
    referencePointName: 'd',
    referencePointN: 22242.649,
    referencePointE: -44440.093,
    referencePointZ: 3.36,
  },
  {
    id: '6_2020-02-14T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-11T08:00:01.000Z',
    surveyPoint: 'center',
    positionN: 22842.631,
    positionE: -44039.998,
    positionZ: 3.141,
    machineOffsetN: 69.057,
    machineOffsetE: -59.976,
    machineOffsetZ: -1.434,
    referencePointName: 'c',
    referencePointN: 22842.649,
    referencePointE: -44040.093,
    referencePointZ: 3.26,
  },
  {
    id: '6_2020-02-15T00:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-29T00:00:01.000Z',
    surveyPoint: 'center02',
    positionN: 22242.631,
    positionE: -54039.998,
    positionZ: 3.241,
    machineOffsetN: 79.057,
    machineOffsetE: -69.976,
    machineOffsetZ: -2.434,
    referencePointName: 'd',
    referencePointN: 22242.649,
    referencePointE: -44440.093,
    referencePointZ: 3.36,
  },
  {
    id: '6_2020-02-16T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-11T08:00:01.000Z',
    surveyPoint: 'center',
    positionN: 22842.631,
    positionE: -44039.998,
    positionZ: 3.141,
    machineOffsetN: 69.057,
    machineOffsetE: -59.976,
    machineOffsetZ: -1.434,
    referencePointName: 'c',
    referencePointN: 22842.649,
    referencePointE: -44040.093,
    referencePointZ: 3.26,
  },
  {
    id: '6_2020-02-17T00:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-29T00:00:01.000Z',
    surveyPoint: 'center02',
    positionN: 22242.631,
    positionE: -54039.998,
    positionZ: 3.241,
    machineOffsetN: 79.057,
    machineOffsetE: -69.976,
    machineOffsetZ: -2.434,
    referencePointName: 'd',
    referencePointN: 22242.649,
    referencePointE: -44440.093,
    referencePointZ: 3.36,
  },
  {
    id: '6_2020-02-18T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-11T08:00:01.000Z',
    surveyPoint: 'center',
    positionN: 22842.631,
    positionE: -44039.998,
    positionZ: 3.141,
    machineOffsetN: 69.057,
    machineOffsetE: -59.976,
    machineOffsetZ: -1.434,
    referencePointName: 'c',
    referencePointN: 22842.649,
    referencePointE: -44040.093,
    referencePointZ: 3.26,
  },
  {
    id: '6_2020-02-19T00:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-29T00:00:01.000Z',
    surveyPoint: 'center02',
    positionN: 22242.631,
    positionE: -54039.998,
    positionZ: 3.241,
    machineOffsetN: 79.057,
    machineOffsetE: -69.976,
    machineOffsetZ: -2.434,
    referencePointName: 'd',
    referencePointN: 22242.649,
    referencePointE: -44440.093,
    referencePointZ: 3.36,
  },
  {
    id: '6_2020-02-20T00:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-12-29T00:00:01.000Z',
    surveyPoint: 'center02',
    positionN: 22242.631,
    positionE: -54039.998,
    positionZ: 3.241,
    machineOffsetN: 79.057,
    machineOffsetE: -69.976,
    machineOffsetZ: -2.434,
    referencePointName: 'd',
    referencePointN: 22242.649,
    referencePointE: -44440.093,
    referencePointZ: 3.36,
  },
  {
    id: '7_2020-02-29T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    checkDateTime: '2020-02-29T00:00:01.000Z',
    surveyPoint: 'center02',
    positionN: 22242.631,
    positionE: -54039.998,
    positionZ: 3.241,
    machineOffsetN: 79.057,
    machineOffsetE: -69.976,
    machineOffsetZ: -2.434,
    referencePointName: 'd',
    referencePointN: 22242.649,
    referencePointE: -44440.093,
    referencePointZ: 3.36,
  },
];

export default retrofitAccuracies;
