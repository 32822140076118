import React, { FC, ReactElement } from 'react';
import { makeStyles, createStyles, Tooltip } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { Typography, CustomDateField } from '../../..';
import {
  ArrowIcon,
  MachineGuidanceIcon,
  PayloadMeterIcon,
  AsbuiltIcon,
  LicenseAllIcon,
} from '../../../../assets';
import { LicensePermissionType } from '../../../../dataProvider';

// ------------------------------------------------------------------------------------------------
// Space
// ------------------------------------------------------------------------------------------------

const Space: FC<{ width?: number; height?: number }> = ({ width, height }) => {
  return <div style={{ width, height }} />;
};

// ------------------------------------------------------------------------------------------------
// Title
// ------------------------------------------------------------------------------------------------

const useTitleStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      '& p': {
        fontSize: 18,
      },
    },
  }),
);

const Title: FC<{
  icon?: ReactElement;
  message: string;
}> = ({ icon, message }) => {
  const classes = useTitleStyles();
  return (
    <div className={classes.root}>
      {icon}
      <Space width={8} />
      <Typography>{message}</Typography>
    </div>
  );
};

// ------------------------------------------------------------------------------------------------
// RetrofitField
// ------------------------------------------------------------------------------------------------

const useRetrofitFieldStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      '& > div:first-child': {
        width: 120,
        fontSize: 12,
      },
      '& > div:last-child': {
        fontSize: 14,
      },
    },
  }),
);

const RetrofitField: FC<{
  label: string;
  value: string;
}> = ({ label, value }) => {
  const classes = useRetrofitFieldStyles();
  return (
    <div className={classes.root}>
      <div>{label}</div>
      <div>{value}</div>
    </div>
  );
};

// ------------------------------------------------------------------------------------------------
// RetrofitInfo
// ------------------------------------------------------------------------------------------------

export interface RetrofitInfoProps {
  machineInfoMachineId: string;
  machineInfoMachineName: string;
  basicInfoSerialNumber: string;
}

const useRetrofitInfoStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 12,
      '& p': {
        fontSize: 18,
      },
    },
  }),
);

const RetrofitInfo: FC<{
  retrofitInfo: RetrofitInfoProps;
}> = ({ retrofitInfo }) => {
  const {
    machineInfoMachineId,
    machineInfoMachineName,
    basicInfoSerialNumber,
  } = retrofitInfo;
  const classes = useRetrofitInfoStyles();
  const translate = useTranslate();
  return (
    <div className={classes.root}>
      <Typography>
        {translate('admin.label.retrofitCorporationLicenses.machine')}
      </Typography>
      <Space height={10} />
      <RetrofitField
        label={translate('resources.retrofits.fields.machineInfoMachineId')}
        value={machineInfoMachineId}
      />
      <Space height={8} />
      <RetrofitField
        label={translate('resources.retrofits.fields.machineInfoMachineName')}
        value={machineInfoMachineName}
      />
      <Space height={8} />
      <RetrofitField
        label={translate(
          'resources.retrofitBasicInfos.fields.basicInfoSerialNumber',
        )}
        value={basicInfoSerialNumber}
      />
      <Space height={8} />
    </div>
  );
};

const useRetrofitCompareStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 12,
      '& p': {
        fontSize: 18,
      },
      '& > div.table': {
        display: 'flex',
        '& > div:nth-child(1)': {
          flex: 1,
        },
        '& > div:nth-child(2)': {
          width: 60,
        },
        '& > div:nth-child(3)': {
          flex: 1,
        },
        '& div.header': {
          height: 32,
          background: '#31323A',
          display: 'flex',
          alignItems: 'center',
          marginBottom: 10,
          '& > span': {
            marginLeft: 10,
          },
        },
        '& div.arrow': {
          display: 'flex',
          justifyContent: 'center',
          marginTop: 10,
        },
      },
    },
  }),
);

const RetrofitCompareInfo: FC<{
  beforeRetrofitInfo: RetrofitInfoProps;
  afterRetrofitInfo: RetrofitInfoProps;
}> = ({ beforeRetrofitInfo, afterRetrofitInfo }) => {
  const {
    machineInfoMachineId: beforeMachineInfoMachineId,
    machineInfoMachineName: beforeMachineInfoMachineName,
    basicInfoSerialNumber: beforeBasicInfoSerialNumber,
  } = beforeRetrofitInfo;
  const {
    machineInfoMachineId: afterMachineInfoMachineId,
    machineInfoMachineName: afterMachineInfoMachineName,
    basicInfoSerialNumber: afterBasicInfoSerialNumber,
  } = afterRetrofitInfo;
  const classes = useRetrofitCompareStyles();
  const translate = useTranslate();
  return (
    <div className={classes.root}>
      <Typography>
        {translate('admin.label.retrofitCorporationLicenses.machine')}
      </Typography>
      <Space height={10} />
      <div className="table">
        {/* Before cells */}
        <div data-testid="beforeRetrofit">
          <div className="header">
            <span>
              {translate('admin.label.retrofitCorporationLicenses.before')}
            </span>
          </div>
          <RetrofitField
            label={translate('resources.retrofits.fields.machineInfoMachineId')}
            value={beforeMachineInfoMachineId}
          />
          <Space height={8} />
          <RetrofitField
            label={translate(
              'resources.retrofits.fields.machineInfoMachineName',
            )}
            value={beforeMachineInfoMachineName}
          />
          <Space height={8} />
          <RetrofitField
            label={translate(
              'resources.retrofitBasicInfos.fields.basicInfoSerialNumber',
            )}
            value={beforeBasicInfoSerialNumber}
          />
        </div>
        {/* Arrow cells */}
        <div>
          <div className="arrow">
            <ArrowIcon />
          </div>
        </div>
        {/* After cells */}
        <div data-testid="afterRetrofit">
          <div className="header">
            <span>
              {translate('admin.label.retrofitCorporationLicenses.after')}
            </span>
          </div>
          <RetrofitField
            label={translate('resources.retrofits.fields.machineInfoMachineId')}
            value={afterMachineInfoMachineId}
          />
          <Space height={8} />
          <RetrofitField
            label={translate(
              'resources.retrofits.fields.machineInfoMachineName',
            )}
            value={afterMachineInfoMachineName}
          />
          <Space height={8} />
          <RetrofitField
            label={translate(
              'resources.retrofitBasicInfos.fields.basicInfoSerialNumber',
            )}
            value={afterBasicInfoSerialNumber}
          />
        </div>
      </div>
      <Space height={8} />
    </div>
  );
};

// ------------------------------------------------------------------------------------------------
// LicenseField
// ------------------------------------------------------------------------------------------------

const useLicenseFieldStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      '& span': {
        fontSize: 12,
      },
      '& > div:first-child': {
        width: 40,
        display: 'flex',
        justifyContent: 'center',
      },
      '& > div:last-child': {
        marginLeft: 8,
      },
      '& div.expire': {
        '& > span:last-child': {
          marginLeft: 12,
        },
      },
    },
  }),
);

export interface License {
  id: string;
  expireDate: string;
  permission: LicensePermissionType;
}

const LicenseField: FC<{
  disabled?: boolean;
  icon: ReactElement;
  license?: License;
  licensePermission: string;
}> = ({ disabled, icon, license, licensePermission }) => {
  const classes = useLicenseFieldStyles();
  const translate = useTranslate();
  const style = disabled ? { opacity: 0.3 } : {};
  const { id, expireDate } = license || {
    id: translate('admin.label.nosetting'),
    expireDate: undefined,
  };
  return (
    <div className={classes.root} style={style} data-testid="field">
      <div>
        <Tooltip title={licensePermission}>{icon}</Tooltip>
      </div>
      <div>
        <span>{id}</span>
        <div className="expire">
          <span>
            {translate(
              'resources.retrofitCorporationLicenses.fields.expiresAt',
            )}
          </span>
          {expireDate ? (
            <CustomDateField
              resource="dummy" // dummy resources
              source="expireDate"
              record={{ id, expireDate }}
              addLabel={false}
            />
          ) : (
            <span>ー</span>
          )}
        </div>
      </div>
    </div>
  );
};

// ------------------------------------------------------------------------------------------------
// LicenseCompare
// ------------------------------------------------------------------------------------------------

const useLicenseCompareStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 12,
      '& p': {
        fontSize: 18,
      },
      '& > div.table': {
        display: 'flex',
        '& > div:nth-child(1)': {
          flex: 1,
        },
        '& > div:nth-child(2)': {
          width: 60,
        },
        '& > div:nth-child(3)': {
          flex: 1,
        },
        '& div.header': {
          height: 32,
          background: '#31323A',
          display: 'flex',
          alignItems: 'center',
          marginBottom: 10,
          '& > span': {
            marginLeft: 10,
          },
        },
        '& div.arrow': {
          display: 'flex',
          justifyContent: 'center',
          marginTop: 10,
        },
      },
    },
  }),
);

export interface LicenseGroup {
  all?: License;
  machineGuidance?: License;
  payloadMeter?: License;
  asbuilt?: License;
}

const LicenseCompare: FC<{
  before: LicenseGroup;
  after: LicenseGroup;
}> = ({ before, after }) => {
  const classes = useLicenseCompareStyles();
  const translate = useTranslate();
  const getMachineGuidancePermissionName = (permission: string | undefined) => {
    if (permission) {
      return permission === 'MachineGuidance2D'
        ? '2D MachineGuidance'
        : 'MachineGuidance';
    }
    return 'MachineGuidance / 2D MachineGuidance';
  };
  return (
    <div className={classes.root}>
      <Typography>
        {translate('resources.retrofitCorporationLicenses.fields.licenseId')}
      </Typography>
      <Space height={10} />
      <div className="table">
        {/* Before cells */}
        <div data-testid="before">
          <div className="header">
            <span>
              {translate('admin.label.retrofitCorporationLicenses.before')}
            </span>
          </div>
          <LicenseField
            icon={<LicenseAllIcon />}
            license={before.all}
            disabled={!after.all}
            licensePermission={
              before.all?.permission ? before.all?.permission : 'ALL / FlexAll'
            }
          />
          <Space height={12} />
          <LicenseField
            icon={<MachineGuidanceIcon />}
            license={before.machineGuidance}
            disabled={!after.machineGuidance}
            licensePermission={getMachineGuidancePermissionName(
              before.machineGuidance?.permission,
            )}
          />
          <Space height={12} />
          <LicenseField
            icon={<PayloadMeterIcon />}
            license={before.payloadMeter}
            disabled={!after.payloadMeter}
            licensePermission="PayloadMeter"
          />
          <Space height={12} />
          <LicenseField
            icon={<AsbuiltIcon />}
            license={before.asbuilt}
            disabled={!after.asbuilt}
            licensePermission="Asbuilt"
          />
        </div>
        {/* Arrow cells */}
        <div>
          <div className="arrow">
            <ArrowIcon />
          </div>
        </div>
        {/* After cells */}
        <div data-testid="after">
          <div className="header">
            <span>
              {translate('admin.label.retrofitCorporationLicenses.after')}
            </span>
          </div>
          <LicenseField
            icon={<LicenseAllIcon />}
            license={after.all}
            disabled={!after.all}
            licensePermission={
              after.all?.permission ? after.all?.permission : 'ALL / FlexAll'
            }
          />
          <Space height={12} />
          <LicenseField
            icon={<MachineGuidanceIcon />}
            license={after.machineGuidance}
            disabled={!after.machineGuidance}
            licensePermission={getMachineGuidancePermissionName(
              after.machineGuidance?.permission,
            )}
          />
          <Space height={12} />
          <LicenseField
            icon={<PayloadMeterIcon />}
            license={after.payloadMeter}
            disabled={!after.payloadMeter}
            licensePermission="PayloadMeter"
          />
          <Space height={12} />
          <LicenseField
            icon={<AsbuiltIcon />}
            license={after.asbuilt}
            disabled={!after.asbuilt}
            licensePermission="Asbuilt"
          />
        </div>
      </div>
    </div>
  );
};

// ------------------------------------------------------------------------------------------------
// Layout
// ------------------------------------------------------------------------------------------------

export interface LayoutProps {
  icon: ReactElement;
  title: string;
  retrofitInfo: RetrofitInfoProps;
  beforeRetrofitInfo?: RetrofitInfoProps;
  beforeLicense: LicenseGroup;
  afterLicense: LicenseGroup;
  isCompactDialog?: boolean;
}

const Layout: FC<LayoutProps> = ({
  icon,
  title,
  retrofitInfo,
  beforeRetrofitInfo,
  beforeLicense,
  afterLicense,
  isCompactDialog,
}) => {
  return (
    <>
      <Title icon={icon} message={title} />
      {beforeRetrofitInfo ? (
        <RetrofitCompareInfo
          beforeRetrofitInfo={beforeRetrofitInfo}
          afterRetrofitInfo={retrofitInfo}
        />
      ) : (
        <RetrofitInfo retrofitInfo={retrofitInfo} />
      )}
      {isCompactDialog ? (
        <></>
      ) : (
        <LicenseCompare before={beforeLicense} after={afterLicense} />
      )}
    </>
  );
};

Layout.displayName = 'Layout';
export default Layout;
