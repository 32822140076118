import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { RetrofitRentalData } from '../../../../../dataProvider';

interface InParams {
  retrofitId: number;
  corporationId: string;
}

interface OutParams {
  data: {
    latestReturnDate: Date | undefined;
  };
}

const useGetLatestReturnDate = () => {
  const dataProvider = useDataProvider();

  return useCallback(
    async ({ retrofitId, corporationId }: InParams): Promise<OutParams> => {
      const { data: returnedRentalData } = await dataProvider.getList(
        'retrofitRentals',
        {
          filter: {
            retrofitId,
            fromCorporationId: corporationId,
            isReturned: true,
          },
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'actualEndDate', order: 'DESC' }, // 返却日の降順
        },
      );
      if (returnedRentalData.length > 0) {
        // 直近のレンタル情報の返却日を格納
        const latestRentalData = returnedRentalData[0] as RetrofitRentalData;
        const latestReturnDate = new Date(latestRentalData.actualEndDate);
        return { data: { latestReturnDate } };
      }
      return { data: { latestReturnDate: undefined } };
    },
    [dataProvider],
  );
};

export default useGetLatestReturnDate;
