import React, { FC, useMemo } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { useTranslate, useGetManyReference } from 'react-admin';
import {
  CustomDateField,
  LicenseIconField,
  SimpleShowLayout,
  Typography,
} from '..';
import { objectToList, licenseToConvertElement } from '../../utils';
import {
  RetrofitCorporationLicenseData,
  RetrofitData,
} from '../../dataProvider';

const useStyles = makeStyles(() =>
  createStyles({
    titleFirst: {
      padding: '12px 0px',
    },
    licenseRow: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
    },
    licenseColumn: {
      '& > div': {
        ':first-of-type': {
          paddingTop: '0px',
        },
        ':last-of-type': {
          paddingBottom: '0px',
        },
        padding: '6px 0px',
      },
    },
    licenseColumnDetail: {
      minWidth: '160px',
    },
    colIcon: {
      gridColumn: '1 / 2',
    },
    colDate: {
      marginLeft: 5,
      '& .MuiFormLabel-root': {
        display: 'none',
      },
    },
    component: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      '& > p': {
        fontSize: '12px',
        paddingRight: '10px',
      },
    },
  }),
);

interface Props {
  resource: string;
  record: RetrofitData;
  addLabel: boolean;
  label: string;
}

const RetrofitLicenseShowLayout: FC<Partial<Props>> = props => {
  const classes = useStyles();
  const translate = useTranslate();
  const { resource, record } = props;
  if (!record) throw Error('Invalid record');

  const { id } = record;

  const { data } = useGetManyReference(
    'retrofitCorporationLicenses',
    'retrofitId',
    id,
    {
      page: 1,
      perPage: 3, // 現状の個別ライセンス種別最大3種類
    },
    { field: 'id', order: 'ASC' },
    { enable: true },
    'retrofits',
  );

  const elements = useMemo(() => {
    const licenseList = objectToList(data) as RetrofitCorporationLicenseData[];
    const isLicenseAttachedIconValid = true;
    return licenseList.map(license => {
      return licenseToConvertElement(license, isLicenseAttachedIconValid);
    });
  }, [data]);

  return (
    <SimpleShowLayout {...props}>
      {elements && elements.length > 0 && elements[0] ? (
        <>
          <Typography className={classes.titleFirst}>
            {translate('admin.label.retrofitEdit.license')}
          </Typography>
          <Box className={classes.licenseColumn}>
            {elements.map((element, index) => {
              const styleGridRow = `${index + 1} / ${index + 2}`;
              return (
                <Box
                  key={`license${index}`}
                  className={classes.licenseRow}
                  style={{ gridRow: styleGridRow }}
                >
                  <Box className={classes.colIcon}>
                    <LicenseIconField element={element} />
                  </Box>
                  <Box className={classes.colDate}>
                    <Box className={classes.component}>
                      <Typography className={classes.licenseColumnDetail}>
                        {translate('admin.label.retrofitEdit.licenseId')}
                      </Typography>
                      <Typography>{element?.licenseId}</Typography>
                    </Box>
                    <Box className={classes.component}>
                      <Typography className={classes.licenseColumnDetail}>
                        {translate('admin.label.retrofitEdit.expiredDate')}
                      </Typography>
                      <CustomDateField
                        resource={resource}
                        record={{ id: 1, expiresAt: element?.expiresAt }}
                        source="expiresAt"
                        showTime
                      />
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </>
      ) : null}
    </SimpleShowLayout>
  );
};

RetrofitLicenseShowLayout.defaultProps = {
  addLabel: true,
};
RetrofitLicenseShowLayout.displayName = 'RetrofitLicenseShowLayout';
export default RetrofitLicenseShowLayout;
