import React, { FC, useState, useCallback, useEffect } from 'react';
import { TextField, createStyles, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useForm } from 'react-final-form';
import { throttle } from 'lodash';
import {
  FieldTitle,
  InputHelperText,
  InputProps,
  useDataProvider,
  useInput,
} from 'react-admin';
import { CustomerData } from '../../dataProvider';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& div.MuiInputBase-root': {
        width: 314,
      },
      '& p.MuiFormHelperText-marginDense': {
        marginTop: 12,
      },
    },
    textField: {
      '& > div.MuiFilledInput-root': {
        paddingTop: 'unset',
        paddingLeft: 'unset',
      },
    },
  }),
);

export interface LendCorporationData {
  id: string; // ID
  name: string; // 企業名
}

interface IntialState {
  data: LendCorporationData[];
  loading: boolean;
  fetched: boolean;
}

interface Props {
  disabled: boolean;
  fromCorporationId: string;
  defaultValue?: LendCorporationData;
  onNotifyChange: (value: LendCorporationData) => void;
}

const CustomerAutoCompleteInput: FC<InputProps<Props>> = ({
  fromCorporationId,
  defaultValue,
  onNotifyChange,
  ...props
}) => {
  const form = useForm();
  const {
    input: { onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const {
    disabled,
    helperText,
    label,
    margin = 'dense',
    resource,
    source,
    variant = 'filled',
  } = props;
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [lendCorporation, setLendCorporation] = useState<LendCorporationData[]>(
    [],
  );
  const [initialState, setInitialState] = useState<IntialState>({
    data: [],
    loading: true,
    fetched: false,
  });

  useEffect(() => {
    if (initialState.fetched) return;
    // 初期表示用のデータを取得する
    const maxData = 50;
    dataProvider
      .getList<CustomerData>('customers', {
        filter: { fromCorporationId },
        pagination: { page: 1, perPage: maxData },
        sort: { field: 'name', order: 'ASC' }, // landlogSutesGetList は name ソートしか対応していない
      })
      .then(({ data }) => {
        const newData = data
          .filter(({ id }) => id !== fromCorporationId) // 自身の企業ID項目は除外する
          .map<LendCorporationData>(({ id, name }) => ({ id, name }));
        setInitialState({ data: newData, loading: false, fetched: true });
      });
  }, [dataProvider, fromCorporationId, initialState.fetched]);

  const handleInputChange = useCallback(
    throttle(async (_event, searchText: string) => {
      if (!searchText) {
        // クリアボタン押下 or BackSpace等でクリアした時
        setLendCorporation([]);
        return;
      }
      // 入力値を元にデータを取得する（入力候補選択時にも呼ばれれる）
      const maxData = 9;
      const { data } = await dataProvider.getList<CustomerData>('customers', {
        filter: { fromCorporationId, q: searchText },
        pagination: { page: 1, perPage: maxData },
        sort: { field: 'name', order: 'ASC' }, // landlogSutesGetList は name ソートしか対応していない
      });
      const newData = data
        .filter(({ id }) => id !== fromCorporationId) // 自身の企業ID項目は除外する
        .map<LendCorporationData>(({ id, name }) => ({ id, name }));

      const fieldValue = form.getFieldState(source)?.value; // getList の実行中にクリアボタンでクリアされたときの対策
      setLendCorporation(fieldValue ? newData : []);
    }, 1_000),
    [dataProvider, fromCorporationId, form],
  );

  if (initialState.loading) return null;

  return (
    <Autocomplete
      {...rest}
      className={classes.root}
      getOptionLabel={record => {
        const { name } = record;
        return name;
      }}
      data-testid={source}
      options={
        lendCorporation.length === 0 ? initialState.data : lendCorporation
      }
      autoComplete
      value={defaultValue}
      disabled={disabled}
      getOptionSelected={(option, value) => option.id === value.id}
      includeInputInList
      filterSelectedOptions
      onChange={(
        _event: any,
        data: LendCorporationData | LendCorporationData[] | null,
      ) => {
        if (!data) {
          onChange(null);
        } else if (data && !Array.isArray(data)) {
          onChange(data.id);
        }
        if (onNotifyChange) {
          onNotifyChange(data);
        }
      }}
      onInputChange={handleInputChange}
      renderInput={params => (
        <TextField
          {...params}
          className={classes.textField}
          label={
            label !== '' &&
            label !== false && (
              <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />
            )
          }
          error={!!(touched && error)}
          helperText={
            <InputHelperText
              touched={touched as boolean}
              error={error}
              helperText={helperText}
            />
          }
          disabled={disabled}
          variant={variant}
          margin={margin}
        />
      )}
    />
  );
};

CustomerAutoCompleteInput.displayName = 'CustomerAutoCompleteInput';
export default CustomerAutoCompleteInput;
