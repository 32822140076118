import { AdminApiConverter, ApiFilter } from '..';
import { convertFillDate } from '../../../utils';

const converter: AdminApiConverter<'assignments', 'assignments'> = {
  resource: 'assignments',
  defaultSort: { field: 'id', order: 'DESC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'assignments'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('retrofitId' in filter) apiFilter.retrofitId = filter.retrofitId;
    if ('fromCorporationId' in filter)
      apiFilter.fromCorporationId = filter.fromCorporationId;
    if ('fromCorporationName' in filter)
      apiFilter.fromCorporationName = filter.fromCorporationName;
    if ('toCorporationId' in filter)
      apiFilter.toCorporationId = filter.toCorporationId;
    if ('toCorporationName' in filter)
      apiFilter.toCorporationName = filter.toCorporationName;
    if ('toMailAddress' in filter)
      apiFilter.toMailAddress = filter.toMailAddress;
    if ('isApproved' in filter) apiFilter.isApproved = filter.isApproved;
    if ('actualDate' in filter) apiFilter.actualDate = filter.actualDate;
    if ('planDate' in filter) {
      console.error('planDate is not supported for filter of Assignments');
    }
    if ('lastUpdated' in filter) apiFilter.updateDate = filter.lastUpdated;
    return apiFilter;
  },
  sortFieldToApi: field => {
    if (field === 'lastUpdated') return 'updateDate';
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(record => ({
        id: record.id || 0,
        retrofitId: record.retrofitId,
        fromCorporationId: record.fromCorporationId,
        fromCorporationName: record.fromCorporationName,
        toCorporationId: record.toCorporationId,
        toCorporationName: record.toCorporationName,
        toMailAddress: record.toMailAddress,
        isApproved: record.isApproved || false,
        planDate: record.planDate,
        actualDate: record.actualDate,
        lastUpdated: record.updateDate || '',
      })),
    ),
  recordToApi: record => ({
    retrofitId: record.retrofitId,
    fromCorporationId: record.fromCorporationId,
    fromCorporationName: record.fromCorporationName,
    toCorporationId: record.toCorporationId,
    toCorporationName: record.toCorporationName,
    toMailAddress: record.toMailAddress,
    isApproved: record.isApproved,
    planDate: record.planDate,
    actualDate: record.actualDate,
    requestLocalDate: convertFillDate(new Date().toString(), {
      hour: 0,
      min: 0,
      sec: 0,
    }),
  }),
};

export default converter;
