import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { useResource } from '../../../../../hooks';

const useLicense = () => {
  const dataProvider = useDataProvider();
  const resource = useResource('retrofitCorporationLicenses');

  return useCallback(
    async (retrofitId: number): Promise<boolean> => {
      const { data: licenseDataChild } = await dataProvider.getList(resource, {
        pagination: {
          page: 1,
          perPage: 100,
        },
        sort: { field: 'id', order: 'DESC' },
        filter: {
          retrofitId,
          enable: true,
        },
      });

      const licenseDataChildPermission = licenseDataChild.filter(
        it =>
          it.licensePermission === 'All' ||
          it.licensePermission === 'MachineGuidance' ||
          it.licensePermission === 'MachineGuidance2D' ||
          it.licensePermission === 'PayloadMeter' ||
          it.licensePermission === 'Asbuilt' ||
          it.licensePermission === 'FlexAll',
      );

      return licenseDataChildPermission.length > 0;
    },
    [dataProvider, resource],
  );
};

export default useLicense;
