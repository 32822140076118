import { GetListParams, GetListResult } from 'react-admin';
import { omitBy, isUndefined, keys } from 'lodash';
import {
  landlogListUsersProps,
  landlogListUsers,
  UserData,
  landlogUserConvert,
} from '..';

// MEMO: 本APIはfilterにて「corporationId」の設定が必須
export default async (params: GetListParams): Promise<GetListResult> => {
  const { q, corporationId, email, role, ...rest } = params.filter;
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;

  if (!corporationId) {
    console.error(
      `corporationId is required in Get Corporation Users List's Filters`,
    );
  }

  if (keys(rest).length > 0) {
    console.error(
      `Filters are not supported in Get Corporation Users List: ${rest}`,
    );
  }
  if (field && field !== 'name') {
    // APIはdisplay_name以外のソートに対応していない
    console.error(`Unsupported sort field: ${field} `);
  }

  // APIの実行
  const props: landlogListUsersProps = {
    name: q,
    email,
    role,
    offset: (page - 1) * perPage,
    limit: perPage,
    order_by: field === 'name' ? 'name' : undefined,
    order: order && order === 'ASC' ? 'asc' : 'desc',
  };

  const { total, users } = await landlogListUsers(
    corporationId,
    omitBy(props, isUndefined),
  );
  const data: UserData[] = users.map(user => landlogUserConvert(user));
  return { total, data };
};
