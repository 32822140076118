// for lv
// ラトビア語
import raMessage from 'ra-language-latvian';
import en from './en';

const { ra } = raMessage;
const message: typeof en = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: '',
        toggle_hidden: '',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: 'Nav atrasts',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'OK',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: 'Vai vēlaties dzēst %{name}?',
    },
  },
  admin: {
    title: {
      normal: 'Smart Construction Pilot',
      split1: 'Smart Construction',
      split2: 'Pilot',
    },
    copyright: 'Copyright © EARTHBRAIN Ltd. All rights reserved.',
    approval: 'Gaida apstiprinājumu',
    usermenu: {
      userNotice: 'Paziņojums',
      terms: 'LIETOŠANAS NOTEIKUMI',
      manual: 'Vadība',
      downloads: 'Lejupielādēt',
    },
    breadcrumbs: {
      details: 'Detaļas',
      retrofitEdit: 'Detaļas',
      taskCreate: 'Izveidot uzdevumu',
      taskEdit: 'Uzdevuma detaļas',
      geofenceCreate: 'Izveidot',
      geofenceEdit: 'Rediģēt',
      geofenceAlertList: 'Ģeofence trauksme',
      geofenceAlertShow: 'Detaļas',
      compactionWorkAreaCreate: 'Pievienot sadzīšanas darba zonas',
      compactionWorkAreaShow: 'Sakārtošanas darba apgabali',
      compactionWorkAreaEdit: 'Rediģēt saspiestās darba vietas',
      compactionLayerCreate: 'Izveidot saspiestu slāņu',
      compactionLayerEdit: 'Rediģēt saspiestās slāņus',
      supportMode: 'Atbalsta režīms',
      retrofitManage: 'Kontroliera reģistrācija',
      retrofitManageAdmin: 'Kontroliera reģistrācija (Admin)',
      retrofitNamePlateFiles: 'Kontroliera reģistrācija',
      retrofitAlternateRegistCreate: 'Kontroliera Proxy Reģistrācija',
      retrofitAlternateRegistEdit: 'Detaļas',
      retrofitManageAdminBulkActionCreate: 'Reģistrācija',
      terms: 'LIETOŠANAS NOTEIKUMI',
      downloads: 'Lejupielādēt',
    },
    pages: {
      siteList: 'Būvobjektu saraksts',
      siteRetrofitCreate: 'Pievienot mašīnu darbam uz vietas',
      siteRetrofitEdit: '',
      siteBucketCreate: 'Pievienot kastu darbam uz vietas',
      siteBucketEdit: 'Spainīšu informācija',
      siteProjectEdit: 'Projekta detaļas',
      siteProjectCreate: 'Projekta failu reģistrācija',
      siteExtensionarmCreate: 'Pievienot izbīdāmā svira darbam uz vietas',
      taskCreate: 'Izveidot uzdevumu',
      taskEdit: 'Uzdevuma detaļas',
      geofenceAlertList: 'Ģeofence trauksme',
      geofenceAlertShow: 'Ģeofence trauksme detalizēti',
      geofenceCreate: 'Izveidot ģeofence',
      geofenceEdit: 'Rediģēt ģeofence',
      compactionWorkAreaCreate: 'Pievienot sadzīšanas darba zonas',
      compactionLayerCreate: 'Izveidot saspiestu slāņu',
      compactionLayerEdit: 'Rediģēt saspiestās slāņus',
      siteFileEdit: 'Detaļas',
      siteFileCreate: 'Pievienot failu darbam uz vietas',
      retrofitList: 'Iekārtu saraksts',
      retrofitEdit: '',
      retrofitRentalCreate: 'Uzdevuma reģistrācija',
      retrofitTransferCreate: 'Pārnesuma reģistrācija',
      retrofitSendBack: 'Atsūtīt reģistrāciju atpakaļ',
      bucketList: 'Spainīšu saraksts',
      bucketEdit: 'Spainīšu informācija',
      bucketCreate: 'Spainīšu reģistrācija',
      bucketRentalCreate: 'Spainīšu kopīgošana',
      extensionarmList: 'Izbīdāmā svira saraksts',
      extensionarmEdit: 'Izbīdāmā svira detaļas',
      extensionarmShare: 'Izbīdāmā svira koplietošana',
      retrofitBulkActionList: 'Masveida reģistrācijas saraksts',
      retrofitBulkActionCreate: 'Masveida reģistrācija',
      retrofitBasicInfoList: 'Kontrolieru saraksts',
      retrofitBasicInfoCreate: 'Kontroliera reģistrācija',
      retrofitBasicInfoEdit: 'Kontroliera atjaunināšana',
      gnssSettingList: 'Ntrip iestatījumu saraksts',
      gnssSettingCreate: 'Izveidot Ntrip iestatījumu',
      gnssSettingAdminCreate: 'Izveidot Ntrip iestatījumu',
      gnssSettingOwnerCreate: 'Izveidot Ntrip iestatījumu',
      gnssSettingEdit: 'Rediģēt Ntrip iestatījumu',
      gnssSettingAdminEdit: 'Rediģēt Ntrip iestatījumu',
      gnssSettingOwnerEdit: 'Rediģēt Ntrip iestatījumu',
      approvalList: 'Iekārtu saraksts',
      retrofitRentalEdit: 'Uzdevuma detaļas',
      retrofitTransferEdit: 'Pārnesuma detaļas',
      asbuiltsCreate: 'Iegūt būvniecības rezultātu',
      asbuiltsShow: 'Asbuild vēsture',
      retrofitConfigList: 'Konfigurācijas informācija',
      retrofitKitInfoList: 'Kontroliera info',
      retrofitAccuraciesList: 'Precizitātes pārbaudes rezultāts',
      retrofitAccuraciesShow: 'Detaļas',
      retrofitCalibaList: 'Kalibrācijas info.',
      retrofitCalibInfo: 'Mašīnu kalibrēšanas informācija Sīkāka informācija',
      retrofitBasicSettingList: 'Pamata iestatījumi',
      retrofitErrorList: 'Kļūdas informācija',
      retrofitErrorShow: 'Detaļas',
      retrofitTopographicSurveyList: 'Topogr. izpētes saraksts',
      retrofitTopographicSurveyShow: 'Topogrāfiskā apsekošanas detaļas',
      retrofitShareFileList: 'Pieteikumu žurnāls',
      retrofitCorporationLicenseList: 'Licenciju saraksts',
      retrofitCorporationLicenseEdit: 'Licences detaļas',
      CompactionLayerList: 'Sakārtošanas slāņi',
      userNoticeList: 'Paziņojumu saraksts',
      userNoticeCreate: 'Pievienot paziņojumu',
      userNoticeEdit: 'Atjaunināt paziņojumu',
      userNoticeDrawer: 'Paziņojums',
      retrofitAlternateRegists: 'Kontroliera Proxy Reģistrācijas saraksts',
      firmwareList: 'Firmware saraksts',
      firmwareShow: 'Firmware detaļas',
      firmwareCreate: 'Pievienot programmatūru',
      retrofitManage: 'Teksts',
      retrofitNamePlateFiles: 'Foto',
      retrofitManageAdminBulkAction: 'Masveida reģistrācija',
      retrofitManageAdminBulkActionCreate: 'Masveida reģistrācija',
      retrofitAlternateRegistCreate: 'Kontroliera Proxy Reģistrācija',
      retrofitAlternateRegistEdit: 'Kontroliera Proxy Reģistrācijas detaļas',
      retrofitAlternateRegistCreateText: 'Teksts',
      retrofitAlternateRegistCreatePhoto: 'Foto',
      ntripList: 'Ntrip Info',
      ntripEdit: 'Ntrip vadība',
    },
    label: {
      login: 'Ielogoties',
      selected: 'Izvēlētie (%{length} gadījumi)',
      listNoResults: 'Dati nav atrasti',
      maintenance: {
        title: 'Pašlaik notiek apkope.',
        message: `Mēs pagaidu apturam pakalpojumus apkopes dēļ.
      Atvainojamies par sagādātajām neērtībām.`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, ln3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder:
          'Nomest vai atlasiet failu(s) (%{fileTypes}) augšupielādei.',
        maxFileNum:
          'Maksimālais kontrolieru skaits, kas var tikt reģistrēts vienlaikus, ir pieci.',
      },
      nosetting: 'Nav iestatījumu',
      siteRetrofitList: {
        remoteSupport: 'Attālā atbalsta',
      },
      retrofitEdit: {
        breadcrumbs: 'Detaļas',
        belongsDetails: '',
        ntripSettingInfo: 'Ntrip iestatījuma informācija',
        machineryDetails: 'Mašīnas',
        retrofitDetails: 'Vadības bloks',
        basicInfo: 'Pamatinfo.',
        controller: 'Vadības bloks',
        reciverMain: 'GNSS galvenais uztvērējs',
        reciverSub: 'GNSS papildu uztvērējs',
        retrofitstatus: 'Kontroliera statuss',
        license: 'Licence',
        licenseId: 'Licences ID',
        expiredDate: 'Derīguma termiņš',
      },
      retrofitRental: {
        rentalDetails: 'Uzdevuma detaļas',
        rentalInfo: 'Uzdevuma detaļas',
        lending: 'Uzdevums',
        return: 'Atgriešanās',
        lendingDestination: 'Piešķirtā uzņēmuma',
        lendingCompany: 'Uzņēmuma nosaukums',
        lendingEmail: 'E-pasts',
        companyName: 'Uzņēmuma nosaukums',
      },
      retorfitTransfer: {
        tab: 'Pārnesums',
        transferInfo: 'Pārnesuma detaļas',
        transferButton: 'Pārnesums',
        transferEditButton: 'Rediģēt pārsūtīšanu',
      },
      siteConfigureEdit: {
        onButton: 'IESLĒGTS',
        offButton: 'IZSLĒGTS',
      },
      approvals: {
        rentalTab: 'Uzdevums',
        transferTab: 'Pārnesums',
      },
      buckets: {
        bucketTab: 'Kauss',
        extensionarmTab: 'Izbīdāmā svira',
        extensionarmFile: 'Izbīdāmā svira fails',
        shareTo: 'Koplietot uz',
      },
      bucketRental: {
        corporation: 'Koplietot uz',
        bucketType: 'Veids',
        bk3File: 'BK3 fails',
      },
      retrofitBasic: {
        basicInfoTab: 'Pamatinfo.',
        bulkActionTab: 'Masveida reģistrācija',
        ntripTab: 'Ntrip vadība',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'Sūtīt atpakaļ',
        initialize: 'Inicializācija',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: 'Masveida atjaunināšana',
        retrofitDistributor: 'Izplatītājs',
        retrofitRegion: 'Reģions',
        corporationId: 'Uzņēmuma ID',
        corporationName: 'Uzņēmuma nosaukums',
      },
      retrofitBasicInfoEdit: {
        corporationId: 'Uzņēmuma ID jau ir izmantots',
        corporationName: 'Uzņēmums tiek izmantots',
      },
      gnssSettingsEdit: {
        ownerCorporationName: 'Uzņēmuma nosaukums',
      },
      terms: {
        title: 'LIETOŠANAS NOTEIKUMI',
        message:
          'Lūdzu, noklikšķiniet uz zemāk esošā saites, lai pārskatītu Lietošanas noteikumus',
        confirmMessage1:
          'Lai izmantotu Smart Construction Pilot, jums jāpiekrīt šiem dokumentiem.',
        confirmMessage2:
          'Ja nepiekrītat, jūs nevarēsiet izmantot Smart Construction Pilot.',
        select: 'Lūdzu, izvēlieties savu reģionu.',
      },
      downloads: {
        title: 'Projekta failu konvertētājs',
        message:
          'Šis ir atsevišķs lietojumprogramma, kas izveido projekta failu no projektēšanas failiem.',
        button: 'Lejupielādēt',
      },
      retrofitBasicInfoCreate: {
        uuid: 'Automātiskā numerācija',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'Lejupielādēt',
        resultFileDownload: 'Lejupielādēt',
      },
      tasks: {
        coordinate: 'Koordinātes (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'Bloka izmērs (%{unit})',
      },
      compactionLayers: {
        thickness: 'Biezums (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: 'Detaļas',
        rentalDetail: 'Uzdevuma detaļas',
      },
      pendingTransfers: {
        breadcrumbs: 'Detaļas',
        transferDetail: 'Pārnesuma detaļas',
      },
      pendingAlternateRegists: {
        breadcrumbs: 'Detaļas',
        alternateRegistDetail: 'Kontroliera Proxy Reģistrācijas detaļas',
      },
      supportMode: {
        retrofit: 'Mašīnas',
        optional: 'Neobligāti',
      },
      retrofitList: {
        assignment: 'Uzdevums',
        license: 'Licence',
      },
      userNotices: {
        languageDetails: 'Paziņojuma saturs',
        language: 'Valoda',
        languageEn: 'English',
        languageJa: 'Japāņu',
        languageFr: 'Franču',
        languageDe: 'Vācu',
        languageEs: 'Spāņu valoda',
        Information: 'Paziņojums',
        Maintenance: 'Uzturēšana',
        emergency: 'Ārkārtas',
        important: 'Svarbu',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'Kontroliera reģistrācija',
        corporationName: 'Uzņēmuma nosaukums',
        basicInfoSerialNumber: 'Seriāla numurs',
        confirmInput: 'Ievadiet serijas numuru vēlreiz',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'Seriāla numurs',
        status: 'Statuss',
        imageFile: 'Attēls',
        message1:
          'Lūdzu, uzņemiet skaidru un nesamākslotu bildi ar kontrolētāja sērijas numuru vai QR koda.',
        message2: 'Attēliem jābūt mazākiem par %{mbyte} MB izmēra.',
        errormessage:
          'Maksimālais kontrolieru skaits, kas var tikt reģistrēts vienlaikus, ir pieci.',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: 'Derīgs',
          disable: 'Nederīgs',
          unLicense: 'Neiestrēdzināts',
        },
        machine: 'Mašīnas',
        before: 'Pirms',
        after: 'Pēc',
      },
      geofences: {
        project: 'Projekts',
        projectSourceLayer: 'Slānis',
        rgb: 'Krāsa(RGB)',
        select: 'Atlases ģeofence apgabals',
        coordinates: 'Koordinātes',
        coordinatesLabel: 'Koordinātes (N,E)',
        unitLength: 'Vienība:%{unit}',
      },
      geofenceDialog: {
        coordinate: 'Koordinātes (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: 'Rādiuss',
      },
      retrofitAlternateRegists: {
        menuTitle: 'Kontroliera Proxy Reģistrācija',
        corporationName: 'Uzņēmuma nosaukums',
        email: 'E-pasts',
        basicInfoSerialNumber: 'Seriāla numurs',
        confirmInput: 'Ievadiet serijas numuru vēlreiz',
      },
      ntripList: {
        unusedTotal: 'Neizmantotā kopējā summa',
      },
      calibrationDetail: {
        breadcrumbs: 'Detaļas',
        machineInfo: 'Mašīnas info.',
        machineGeometryInfo: 'Mašīnas gabarītu info.',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'IMV montāžas info.',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'Galv. GNSS montāžas info.',
        bodyCenterToGnssSub: 'Papild. GNSS montāžas info.',
        extendedModel: 'Paplašinātais modelis',
        measuredValues: 'Kalibrācijas info.',
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'Datu konvertēšanas kļūda',
        system_localization_quantity_error: `Nav iespējams reģistrēt vairākus lokalizācijas informāciju saturošus failus.
      Lūdzu, reģistrējiet tikai vienu failu, kas satur lokalizācijas informāciju.`,
        system_detect_0byte_file_error: `Failu reģistrācija serverī neizdevās komunikācijas kļūdas dēļ.
      Lūdzu, reģistrējiet failu vēlreiz.`,
        system_batch_error: 'Sistēmas kļūda (P001)',
        system_parameter_error: 'Sistēmas kļūda (P002)',
        system_parameterfile_notfound: 'Sistēmas kļūda (P003)',
        system_exception: 'Sistēmas kļūda (P004)',
        system_logfile_error: 'Sistēmas kļūda (P005)',
        system_parameterfile_parameter_error: 'Sistēmas kļūda (P006)',
        desing_localization_error: `Nav pieejama lokalizācijas informācija.
      Lūdzu, reģistrējiet vienu no šādiem failiem:

      ・GC3 fails
      ・TP3 fails ar kontrolpunktiem
      ・CAL fails`,
        desing_no_support_dxf_error:
          'DXF fail(s) nevarēja lasīt, jo tā versija ir vecāka par AutoCAD2000.',
        desing_tile_error: 'Neizdevās izveidot projektu failu.',
        desing_outline_error: 'Ārējās robežlīnijas izveide neizdevās.',
        desing_area_length_error:
          'Process ir pārtraukts, jo projekta apgabals ir pārāk liels. Lūdzu, pārbaudiet, vai projekta dati un CAD datu mērogs ir pareizi.',
        desing_layer_name_duplication_error: `Projekta dati ar dublētiem slāņu nosaukumiem nevar reģistrēt.
      Lūdzu, mainiet dizaina slāņa nosaukumu vai reģistrējiet to tā, lai būtu tikai viens fails ar tādu pašu dizaina slāņa nosaukumu.`,
        targetFiles: '[Faila nosaukums]',
        targetLayers: '[Slānis]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: 'Nav',
        system_batch_error: 'Sistēmas kļūda (T001)',
        system_parameter_error: 'Sistēmas kļūda (T002)',
        system_parameterfile_notfound: 'Sistēmas kļūda (T003)',
        system_exception: 'Sistēmas kļūda (T004)',
        system_logfile_error: 'Sistēmas kļūda (T005)',
        system_basefile_notfound: 'Sistēmas kļūda (T006)',
        system_prjfile_multi: 'Sistēmas kļūda (T007)',
        system_basefile_read_error: 'Sistēmas kļūda (T008)',
        system_target_layer_notfound: 'Sistēmas kļūda (T009)',
        system_target_layer_duplication:
          'Tāds pats slāņa nosaukums jau pastāv.',
        system_taskarea_error: 'Nederīga uzdevuma zona',
        system_taskarea_design_notfound:
          'Uzdevuma apgabalā nav projekta faila datu (TIN, līnijzīmju, punktu).',
        system_tile_error: 'Neizdevās izveidot projektu failu.',
        system_outline_error: 'Ārējās robežlīnijas izveide neizdevās.',
        system_parameterfile_error: 'Sistēmas kļūda',
      },
      retrofitError: {
        1000: 'UDP dati no kontroliera nav konstatēti',
        2000: 'Korpusa IMV nav konstatēts',
        2001: 'Korpusa IMV aparatūras kļūda',
        2002: 'Korpusa IMV algoritma kļūda',
        2003: 'Korpusa IMV datu kļūda',
        2004: 'Korpusa IMV inicializācijas kļūda',
        2100: 'Izlices IMV nav konstatēts',
        2101: 'Izlices IMV aparatūras kļūda',
        2102: 'Izlices IMV algoritma kļūda',
        2103: 'Izlices IMV datu kļūda',
        2104: 'Izlices IMV inicializācijas kļūda',
        2200: 'Sviras pleca IMV nav konstatēts',
        2201: 'Sviras pleca IMV aparatūras kļūda',
        2202: 'Sviras pleca IMV algoritma kļūda',
        2203: 'Sviras pleca IMV datu kļūda',
        2204: 'Sviras pleca IMV inicializācijas kļūda',
        2300: 'Kausa IMV nav konstatēts',
        2301: 'Kausa IMV aparatūras kļūda',
        2302: 'Kausa IMV algoritma kļūda',
        2303: 'Kausa IMV datu kļūda',
        2304: 'Kausa IMV inicializācijas kļūda',
        2400: 'Slīpuma kausa IMV nav konstatēts',
        2401: 'Slīpuma kausa IMV aparatūras kļūda',
        2402: 'Slīpuma kausa IMV algoritma kļūda',
        2403: 'Slīpuma kausa IMV datu kļūda',
        2404: 'Slīpuma kausa IMV inicializācijas kļūda',
        2500: '2. izlices IMV nav konstatēts',
        2501: '2. izlices IMV aparatūras kļūda',
        2502: '2. izlices IMV algoritma kļūda',
        2503: '2. izlices IMV datu kļūda',
        2504: '2. izlices IMV inicializācijas kļūda',
        2600: 'Neatklāts šūpoļu leņķa sensors',
        2601: 'Kustības leņķa sensora sistēmas kļūda',
        2602: 'Šūpoļu leņķa sensora aparatūras kļūda',
        2700: 'Neatklāts Novirzes sensors',
        2701: 'Novirzes sensors sistēmas kļūda',
        2702: 'Novirzes sensors aparatūras kļūda',
        3000: 'Augšpuses spiediena sensors nav konstatēts',
        3100: 'Apakšpuses spiediena sensors nav konstatēts',
        4000: 'GNSS antena nav konstatēta',
        5000: 'Korekcijas informācija nav konstatēta',
        5001: 'Radio uztvērēja signāls nav konstatēts',
      },
    },
    dialog: {
      menu: {
        confirmManualLink:
          'Jūs tiksiet novirzīts uz citu tīmekļa vietni. Vai ar to viss ir kārtībā?',
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'Kauss',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message: 'Uzņēmuma ID un uzņēmuma nosaukums ir obligāti.',
        },
        save: {
          message1: 'Uzņēmuma ID/nosaukums nevar tikt mainīts, jo tas ietver',
          message2: 'mašīna(i) piešķirta(i) citam uzņēmumam.',
          message3: '',
          message4: 'Lūdzu, pārbaudiet sekojošos sērijas numurus',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'Vadības bloks',
        },
        undeletable: {
          message: 'Kontrolieris tiek izmantots un nevar tikt dzēsts.',
        },
      },
      Ntrip: {
        save: {
          stop: 'Vai vēlaties apturēt šo Ntrip iestatījumu?',
          delete: 'Vai vēlaties dzēst Ntrip iestatījumu?',
        },
      },
      siteRetrofitList: {
        title: 'Papildu informācija',
        message: 'Būvniecības periods ir beidzies.',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: 'Kopējais iestatījums',
          siteSetting: 'Būvobjekta iestatījumi',
        },
        mapBackgroundColor: 'Kartes fona krāsa',
        message:
          'Ģeofence kontakta noteikšanas/brīdinājuma funkcija var nedarboties pareizi atkarībā no vides un apstākļiem, kuros tā tiek izmantota. Neizmantojiet šo funkciju neapdomīgi un pārliecinieties, ka jūs saprotat tās darbību un izmantošanas nosacījumus.',
      },
      geofenceList: {
        confirmDelete: {
          message: 'Vai vēlaties dzēst šo ģeofence?',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1:
            'Mašīna ir piesaistīta darba vietai, un to nevar piešķirt. Lūdzu, atvienojiet mašīnu no darba vietas.',
          message2: 'Lūdzu, atvienojiet mašīnu no būvniecības vietas.',
        },
        sameOwner: {
          message:
            'Mašīnai nevar piešķirt licenci, jo īpašnieks ir jūsu uzņēmums.',
        },
        unableTransfer: {
          message:
            'Mašīna gaida nodošanas apstiprinājumu, un to nevar reģistrēt piešķiršanai.',
        },
        unableAlternateRegist: {
          message:
            'Mašīna gaida kontroliera pilnvarotās personas reģistrācijas apstiprinājumu, un to nevar reģistrēt piešķiršanai.',
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          'Mašīna ir piesaistīta darba vietai, to nevar pārsūtīt. Lūdzu, atvienojiet mašīnu no darba vietas.',
        messageRentaled: 'Mašīna ir piešķirta, un to nevar nodot.',
        messageLicense: 'Mašīnai ir licence, un to nevar nodot.',
        pendingApprovals: {
          message1:
            'Pārsūtīšanas pieprasījums ir saņemts. Lūdzu, gaidiet apstiprinājumu.',
          message2:
            'Ja jums ir jautājumi, lūdzu, sazinieties ar mums, izmantojot zemāk norādīto URL.',
        },
      },
      retrofitSendBacks: {
        submit:
          'Vai vēlaties atgriezt kontrolieri un mantot iestatījumus uz mērķa kontrolieri?',
        deleteNtrip: 'Vai vēlaties inicializēt atgriezto kontroleri?',
        unableRegion:
          'Šis kontrolieris ir nosūtīts ārzemēs. Ja šis kontrolieris ir pareizais, atiestatiet "Reģionu" uz nulli.',
        unableCorporation: {
          message1: 'Kontrolieris nevar tikt nosūtīts atpakaļ.',
          message2:
            'Ja uzskatāt, ka šis ir kļūda, lūdzu, sazinieties ar savu izplatītāju.',
        },
        retrofitRentaled:
          'Kontrolieris ir piešķirts un nevar tikt nosūtīts atpakaļ.',
        tiedToSite:
          'Mašīna ir piesaistīta darba vietai, un to nevar nosūtīt atpakaļ.',
      },
      retrofitAddToSiteAction: {
        title: 'Papildu informācija',
        message:
          'Ir mašīna, kas gaida apstiprinājumu, tāpēc to nevar savienot ar darba vietu.',
      },
      retrofitAddToSiteActionTransfer: {
        title: 'Papildu informācija',
        message: 'Mašīna tiek nodota, un to nevar piesaistīt darba vietai.',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message: 'Izvēlētais Ntrip iestatījums tiek nosūtīts uz citu mašīnu.',
        },
        unableTransferInfo: {
          message: 'Lai pārsūtītu, lūdzu, atceliet šādas iestatījumus.',
          reasonSiteId: 'Būvobjekts',
          reasonRentaled: 'Uzdevums',
          reasonLicense: 'Licence',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            'Piešķirtajai uzņēmumam pašlaik tiek piešķirta mašīna citam uzņēmumam. Uzdevuma sākuma datums citam uzņēmumam ir %{date}.',
          message2: 'Lūdzu izvēlieties beigu datumu pēc %{date}.',
        },
        confirmTieUpJobsite: {
          message:
            'Mašīna ir piesaistīta darba vietai piešķirtajā uzņēmumā. Vai vēlaties izbeigt šo piešķiršanu?',
        },
        confirmDoubleRental: {
          message:
            '"Piešķirtajai uzņēmumam pašlaik tiek piešķirta mašīna citam uzņēmumam. Piešķirtajai uzņēmumam pašlaik tiek piešķirta mašīna citam uzņēmumam. Beigu datuma iestatīšana beidz arī piešķiršanu citam uzņēmumam. Vai vēlaties noteikt beigu datumu?"',
        },
        unapprovedSave: {
          message: 'Šis uzdevums nav apstiprināts, un to nevar saglabāt.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Vai vēlaties dzēst šo uzdevumu?',
        },
        approvedDelete: {
          title: '',
          message: 'Šis uzdevums ir apstiprināts, un to nevar dzēst.',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message: 'Mašīna pašlaik ir piešķirta, un to nevar pārsūtīt.',
        },
        approvedSave: {
          message: 'Mašīnai ir licence, un to nevar nodot.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Vai vēlaties dzēst šo pārsūtīšanu?',
        },
        approvedDelete: {
          title: '',
          message: 'Šis pārnesums ir apstiprināts, un to nevar dzēst.',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'Izbīdāmā svira',
        },
      },
      projectFileUpload: {
        message:
          'Nav iespējams reģistrēt vairākas kontroles punktu datnes (.gc3/.cal).',
      },
      projectEdit: {
        confirm: {
          message:
            'Kad projekts tiks atjaunināts, esošie uzdevumi tiks dzēsti. Vai vēlaties atjaunināt?',
        },
      },
      projectConversionStatus: {
        title: 'Konversijas statuss',
        button: 'OK',
      },
      taskConversionStatus: {
        title: 'Konversijas statuss',
        button: 'OK',
      },
      taskList: {
        undeletable: {
          message:
            'Izvēlētais uzdevums nevar tikt dzēsts, jo tas tiek izmantots.',
        },
        unableToCreate: {
          message: 'Projektā nav reģistrēti konstrukcijas dati.',
        },
        unableToDelete: {
          message1:
            'Uzdevumu nevar dzēst, jo projekta un uzdevuma statuss ir šāds.',
          message2: 'Projekts: Pārveidošana',
          message3: 'Uzdevums: Kļūda',
        },
        unableToUpdate: {
          message1:
            'Uzdevumu nevar rediģēt, jo projekta statuss un uzdevuma statuss nav šāds.',
          message2: 'Projekts: [Pārveidots]',
          message3: 'Uzdevums: [Pārsūtīšana][Pārsūtīta][Neizdevās pārsūtīt]',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'Uzdevums',
        },
        unableToDelete: {
          message:
            'Izvēlētais uzdevums nevar tikt dzēsts, jo tas tiek izmantots.',
        },
      },
      taskMap: {
        crossingMessage: 'Uzdevuma apgabals ir krustots.',
        sameValueMessage: 'Ir ievadītas tās pašas koordinātas.',
        areaZeroMessage: 'Uzdevuma apgabalam nav apgabala.',
        noPolygonMessage:
          'Uzdevumu nevar reģistrēt, jo tas vēl nav reģistrēts.',
      },
      compactionMaterials: {
        add: {
          title: 'Pievienot materiālu',
        },
        update: {
          title: 'Atjaunināt materiālu',
        },
      },
      weathers: {
        add: {
          title: 'Pievienot laikapstākli',
        },
        update: {
          title: 'Atjaunināt laika apstākļus',
        },
      },
      operators: {
        add: {
          title: 'Pievienot operatoru',
        },
        update: {
          title: 'Atjaunināt operatoru',
        },
      },
      compactionWorkAreas: {
        update: {
          message: 'Rediģēt saspiestās darba vietas',
        },
        undeletable: {
          message:
            'Kompaktēšanas slānis, kas iekļauts būvējā, tiek izmantots un nevar tikt dzēsts.',
        },
      },
      compactionLayers: {
        undeletable: {
          message: 'Kompaktēšanas slānis tiek izmantots un nevar tikt dzēsts.',
        },
        deleteConfirm: {
          messageTargetItem: 'Sakārtošanas slānis',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message: 'Izvēlētajai iekārtai tiek nosūtīts cita Ntrip iestatījums.',
        },
        confirmDelete: {
          message: 'Vai vēlaties dzēst Ntrip iestatījumu?',
        },
        confirmDeleteWithMachine: {
          message:
            'Mašīna pašlaik ir piesaistīta. Vai vēlaties dzēst Ntrip iestatījumu?',
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'Paziņojums',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: 'Iestatiet licences(-es) uz sekojošo iekārtu.',
        },
        changeDisable: {
          message:
            'Lūdzu, mainiet šādām celtniecības iekārtām licencijas ID uz derīgu.',
        },
        changeTemporary: {
          message:
            'Iestatiet licences(-es) uz sekojošo iekārtu. Pašreizējā pagaidu licence tiks anulēta.',
        },
        errorAdd: {
          message:
            'Mašīnas licenci nevar mainīt, jo jau ir iestatīta All licence (Visas licences).',
        },
        errorSameType: {
          message:
            'Mašīnas licenci nevar mainīt, jo jau ir iestatīta tāda paša veida licence.',
        },
        errorUnlimited: {
          message:
            'Mašīnai jau ir iestatīta Neierobežota licence, citas licences nevar iestatīt.',
        },
        addAll: {
          message:
            'Šādai(-ām) iekārtai jau ir iestatīta(-as) licence(-as). Vai vēlaties iestatīt Visas licences?',
        },
        compare: {
          message: 'Mainiet licenci(es) sekojošajai mašīnai.',
        },
        warning: {
          message:
            '%{retrofit} ir licenciavimo raktas %{id}. Ar norite atjungti licenciją?',
        },
        unableTransfer: {
          message: 'Mašīna tiek pārvietota tā, ka licenci nevar sasaistīt.',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial: 'Ir dati, kas neizdevās analizēt attēlu.',
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1:
            'Reģistrācijas pieprasījums ir saņemts. Lūdzu, pagaidiet, kamēr tas tiks apstiprināts.',
          message2:
            'Ja jums ir jautājumi, lūdzu, sazinieties ar mums, izmantojot zemāk norādīto URL.',
        },
        corporationIdCheck: {
          message1:
            'Kontrolieris jau ir reģistrēts. Lūdzu, pārbaudiet, meklējot mašīnās.',
          message2:
            'Ja nevarat atrast mērķa kontrolieri sarakstā, lūdzu, sazinieties ar mums, izmantojot zemāk norādīto URL.',
        },
        earthbrainIdCheck: {
          message1: 'Mēs atvainojamies par sagādātajām neērtībām',
          message2:
            'bet lūdzu, sazinieties ar mums no zemāk norādītā URL ar šādu informāciju.',
          message3: '-Kontroliera sērijas numurs',
          message4: '-Reģistrējamas mašīnas nosaukums (neobligāti)',
          message5: '-Uzņēmuma ID, kas jāreģistrē',
        },
        invalidSerialNumber: {
          message1: 'Kontrolieris jau tiek izmantots citā uzņēmumā.',
          message2: 'Ja vēlaties to mainīt citu iemeslu dēļ,',
          message3:
            'Lūdzu, sazinieties ar mums, izmantojot zemāk norādīto URL, un norādiet šādu informāciju.',
          message4: '-Kontroliera sērijas numurs',
          message5: '-Reģistrējamas mašīnas nosaukums (neobligāti)',
          message6: '-Uzņēmuma ID, kas jāreģistrē',
          message7: '-Iemesls maiņai',
        },
        lendingRetrofits: {
          message1: 'Kontrolieris ir piešķirts.',
          message2:
            'Lūdzu, pēc piešķiršanas uzņēmuma beigām, atkārtoti pieteikties.',
          message3:
            'Ja jums ir jautājumi, lūdzu, sazinieties ar mums, izmantojot zemāk norādīto URL.',
        },
        siteAttached: {
          message1: 'Kontrolieris ir saistīts ar darbavietu.',
          message2:
            'Lūdzu, pēc mašīnas atvienošanas no būvniecības vietas, pieteikties atkārtoti.',
          message3:
            'Ja jums ir jautājumi, lūdzu, sazinieties ar mums, izmantojot zemāk norādīto URL.',
        },
        serialNumberExistError: {
          message1:
            'Šis ir neesoša kontroliera sērijas numurs. Lūdzu, pārliecinieties, vai ievadītais kontroliera sērijas numurs ir pareizs.',
          message2:
            'Ja problēma turpina pastāvēt, lūdzu, sazinieties ar mums, izmantojot zemāk norādīto URL.',
        },
        ntripShortage: {
          message1: 'Mēs nevarējām izsniegt jūsu Ntrip lietotāja ID un paroli.',
          message2: 'Lūdzu, sazinieties ar mums zemāk norādītajā URL.',
        },
        serialnumberCheck: {
          message:
            'Lūdzu, pārbaudiet produktus ar sekojošajiem sērijas numuriem',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: 'retrofitAlternateRegists',
        },
        saveValidation: {
          message: 'E-pastam ir nepieciešams',
        },
        pendingApprovals: {
          message1:
            'Saņemts reģistrācijas pieprasījums. Lūdzu, uzgaidiet, līdz tas tiks apstiprināts.',
          message2:
            'Ja jums ir jautājumi, lūdzu, sazinieties ar mums, izmantojot zemāk norādīto URL.',
        },
        corporationIdCheck: {
          message1:
            'Kontrolieris jau ir reģistrēts. Lūdzu, pārbaudiet, meklējot mašīnās.',
          message2:
            'Ja nevarat atrast mērķa kontrolieri sarakstā, lūdzu, sazinieties ar mums, izmantojot zemāk norādīto URL.',
        },
        earthbrainIdCheck: {
          message1: 'Mēs atvainojamies par sagādātajām neērtībām',
          message2:
            'bet lūdzu, sazinieties ar mums no zemāk norādītā URL ar šādu informāciju.',
          message3: '-Kontroliera sērijas numurs',
          message4: '-Reģistrējamas mašīnas nosaukums (neobligāti)',
          message5: '-Uzņēmuma ID, kas jāreģistrē',
        },
        invalidSerialNumber: {
          message1: 'Kontrolieris jau tiek izmantots citā uzņēmumā.',
          message2: 'Ja vēlaties to mainīt citu iemeslu dēļ,',
          message3:
            'Lūdzu, sazinieties ar mums, izmantojot zemāk norādīto URL, un norādiet šādu informāciju.',
          message4: '-Kontroliera sērijas numurs',
          message5: '-Reģistrējamas mašīnas nosaukums (neobligāti)',
          message6: '-Uzņēmuma ID, kas jāreģistrē',
          message7: '-Iemesls maiņai',
        },
        lendingRetrofits: {
          message1: 'Kontrolieris ir piešķirts.',
          message2:
            'Lūdzu, pēc piešķiršanas uzņēmuma beigām, atkārtoti pieteikties.',
          message3:
            'Ja jums ir jautājumi, lūdzu, sazinieties ar mums, izmantojot zemāk norādīto URL.',
        },
        siteAttached: {
          message1: 'Kontrolieris ir saistīts ar darbavietu.',
          message2:
            'Lūdzu, pēc mašīnas atvienošanas no būvniecības vietas, pieteikties atkārtoti.',
          message3:
            'Ja jums ir jautājumi, lūdzu, sazinieties ar mums, izmantojot zemāk norādīto URL.',
        },
        serialNumberExistError: {
          message1:
            'Šis ir neesoša kontroliera sērijas numurs. Lūdzu, pārliecinieties, vai ievadītais kontroliera sērijas numurs ir pareizs.',
          message2:
            'Ja problēma turpina pastāvēt, lūdzu, sazinieties ar mums, izmantojot zemāk norādīto URL.',
        },
        ntripShortage: {
          message1: 'Mēs nevarējām izsniegt jūsu Ntrip lietotāja ID un paroli.',
          message2: 'Lūdzu, sazinieties ar mums zemāk norādītajā URL.',
        },
        serialnumberCheck: {
          message:
            'Lūdzu, pārbaudiet produktus ar sekojošajiem sērijas numuriem',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1: 'Šis kontrolieris jau pieder jūsu uzņēmumam.',
          message2: 'Lūdzu, sazinieties ar pieteikuma iesniedzēja uzņēmumu',
        },
        userCorporation: {
          message1:
            'Jūs nevarat apstiprināt šo pieprasījumu, jo jau esat aizdevis šo kontrolieri.',
          message2: 'Lūdzu, sazinieties ar pieteikuma iesniedzēja uzņēmumu',
        },
      },
      licensePurchaseNotice: {
        button: 'OK',
        message1: `Uz vietas tiek izmantota mašīna bez licences.
        Lietošanai uz vietas ir nepieciešama licence.
        Ja neesat iegādājies licenci, lūdzu, iegādājieties to Smart Construction Marketplace.`,
        message2: `
        Ja mašīnu nomājat no cita uzņēmuma, lūdzu, pieprasiet licenci mašīnai no nomas avota.

        Ja jums ir kādi jautājumi, lūdzu, apmeklējiet šādu atbalsta vietni.`,
      },
      siteBuriedStructureEdit: {
        title: 'Buried Structure',
      },
    },
    tootip: {
      retrofitShareFileList: 'Pieteikumu žurnāls',
      retrofitConfigList: 'Konfigurācijas informācija',
      retrofitKitInfoList: 'Kontroliera info',
      retrofitAccuraciesList: 'Precizitātes pārbaudes rezultāts',
      retrofitCalibaList: 'Kalibrācijas info.',
      retrofitBasicSettingList: 'Pamatiestatījumi',
      retrofitErrorList: 'Kļūdas detaļas',
      retrofitTopographicSurveyList: 'Topogr. izpēte',
    },
    actions: {
      ok: 'OK',
      add: 'Pievienot',
      create: 'Reģistrācija',
      yes: 'Jā',
      no: 'Nē',
      confirmation: 'Apstiprināt',
      save: 'SAGLABĀT',
      cancel: 'Atcelt',
      required: 'Nepieciešams',
      addMachineToSite: 'Pievienot mašīnu darbam uz vietas',
      addBucketToSite: 'Pievienot',
      deleteFromSite: 'Atvienoties no darbavieta',
      removeLicense: 'Atvienoties no mašīnas',
      lendMachine: 'Uzdevums',
      transferMachine: 'Pārnesums',
      remoteSupport: 'Attālā atbalsta',
      lendBucket: 'Koplietot',
      approval: 'Apstiprinājums',
      connect: 'Pieslēgties',
      creationInstructions: 'Iegūt būvniecības rezultātu',
      csvDownload: 'Lejupielādēt',
      retrofitBundleCreate: 'Masveida reģistrācija',
      termsAccept: 'Piekrist',
      termsDeny: 'Nesaprot',
      bulkRegistration: 'Masveida atjaunināšana',
      bulkRegistrationOK: 'OK',
      compactionItems: 'Sakārtošanas priekšmeti',
      addLanguage: 'Pievienot valodu',
    },
    message: {
      error: 'Radusies kļūda',
      confirm: 'Vai ir ok?',
      fileUpload: 'Velciet un nometiet failus šeit',
      success: 'Reģistrācija ir pabeigta',
      approved: 'Apstiprinājums ir pabeigts',
      deleteConfirmTitle: '',
      deleteConfirmContent: 'Vai vēlaties dzēst %{title}?',
      bulkDeleteConfirmContent: 'Vai vēlaties dzēst %{title}?',
      disconnectFromSite: 'Vai vēlaties atvienoties no darbavietas?',
      saveConfirmContent: 'Apstiprināt',
      removeLicense: 'Vai vēlaties atvienoties no mašīnas?',
      task: {
        crossingMessage: 'Uzdevuma apgabals ir krustots.',
        sameValueMessage: 'Ir ievadītas tās pašas koordinātas.',
        areaZeroMessage: 'Uzdevuma apgabalam nav apgabala.',
        invalidPolygonMessage: 'Ir ievadītas tās pašas koordinātas.',
      },
      geofence: {
        crossingMessage: 'Ģeofence robežas zona ir pārgriezta.',
        sameValueMessage: 'Ir ievadītas tās pašas koordinātas.',
        areaZeroMessage: 'Ģeofence apgabala apgabalā nav apgabala.',
      },
      licenseError:
        'Jūs neesat saņēmis Smart Construction Pilot(Web App) licenci.',
      retrofitsManage: {
        serialNumberMatchError: 'Seriāla numurs nesakrīt',
        serialNumberInputError: 'Seriāla numurs nepastāv',
        invalidAccess:
          'Jūs neesat pilnvarots apskatīt šo lapu, atgriezieties uz sākumlapu.',
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'Seriāla numurs nesakrīt',
        serialNumberInputError: 'Seriāla numurs nepastāv',
        retrofitRentaled:
          'Uzņēmuma informāciju nevar atjaunināt, kamēr vadītājs ir piešķirts.',
        invalidCorporation:
          'Tā kā jūsu uzņēmums nav izplatītājs, jūs nevarat veikt kontroliera pilnvaroto reģistrāciju.',
      },
    },
    validation: {
      startDate: 'Sākuma datums',
      endDate: 'Beigu datums',
      invalidDateFormat: 'Datuma formāts nav derīgs.',
      invalidDateTimeFormat: 'Datuma un laika formāts nav derīgs.',
      duplicateProject: 'Projekta nosaukums ir dublēts',
      incorrectBeforeDate: '%{target} jābūt iestatītam pirms %{before}.',
      incorrectAfterDate: '%{target} jābūt iestatītam pēc %{after}.',
      incorrectPlanStartDate:
        'Plānotajai sākuma datumam jābūt pirms plānotā beigu datuma.',
      incorrectPlanEndDate:
        'Plānotajai beigu datumam jābūt pēc plānotā sākuma datuma.',
      incorrectActualStartDate: 'Pradžios datums jābūt pirms beigu datuma.',
      incorrectActualEndDate:
        'Pabeigšanas datumam jābūt iestatītam datumam pēc sākuma datuma.',
      withActualStartDate: 'Lūdzu, ievadiet arī sākuma datumu.',
      inputAvailableDate: 'Datumam, ko var ievadīt, ir no %{date}.',
      registrationRequired:
        'Lūdzu, izvēlieties piešķirto uzņēmumu vai e-pasta adresi.',
      bothInput:
        'Jūs varat ievadīt tikai piešķirto uzņēmumu vai e-pasta adresi.',
      supportMode: 'Lūdzu, ievadiet uzņēmuma ID vai darbavieta ID.',
      invalidBkFile: 'Tas nav BK3 fails.',
      invalidPdfFile: 'Tas nav PDF fails.',
      duplicatedBucketName: 'Spainīšu nosaukums ir dublēts',
      duplicatedTaskName: 'Uzdevuma nosaukums ir dublēts.',
      duplicateGeofenceName: 'Ģeofence ierobežojuma nosaukums ir dublēts',
      duplicatedCompactionWorkAreaName:
        'Dublēta saspiestā darba platības nosaukums',
      duplicatedCompactionLayerName: 'Dublēta saspiesto slāņa nosaukums',
      duplicated: {
        compactionMaterials: {
          message: 'Dublēta materiāla nosaukums',
        },
        weathers: {
          message: 'Dublēta laika nosaukums',
        },
        operators: {
          message: 'Dublēta operatora nosaukums',
        },
      },
      containInvalidString: 'Ietverti nederīgi simboli. (%{msg} nav atļauts)',
      validHalfWidthAlphanumeric:
        'Atļauti tikai pusplatīgā tipogrāfijā alfa-numeriski rakstzīmes.',
      validNumberFormat: 'Nav numurs',
      validIntegerFormat: 'Nav vesela skaitļa',
      validIntegerRangeFormat: 'Ievadīt no 0 līdz 2147483647 ir atļauts.',
      validDuplicateLanguage: 'Ir izvēlēta tā pati valoda.',
      validBeforeDate:
        '%{target} jābūt iestatītam datumā un laikā pirms %{before}.',
      validAfterDate:
        '%{target} jābūt iestatītam datumā un laikā pēc %{after}.',
    },
  },
  resources: {
    sites: {
      name: 'Būvobjekts',
      fields: {
        name: 'Būvobjekts',
        corporationName: 'Uzņēmuma nosaukums',
        status: 'Statuss',
        workPeriodStartDate: 'Sākuma datums',
        workPeriodEndDate: 'Beigu datums',
      },
      status: {
        completed: 'Pabeigts',
        waiting: 'Pirms būvniecības',
        working: 'Būvēšanā',
      },
    },
    retrofits: {
      name: 'Mašīnas',
      fields: {
        information: 'Mašīnas info.',
        rental: 'Uzdevums',
        sendBack: 'Sūtīt atpakaļ',
        corporationId: 'Uzņēmums tiek izmantots',
        siteId: 'Būvobjekts',
        uuid: 'UUID',
        isError: 'Statuss',
        isOnline: 'Tiešsaistē',
        lastOnlineTime: 'Pēdējā savienojuma datums',
        basicInfoManufacturer: 'Ražotājs',
        basicInfoModel: 'Modelis',
        basicInfoProductNumber: 'Produkta Nr.',
        basicInfoSerialNumber: 'Sērijas Nr.',
        controllerManufacturer: 'Ražotājs',
        controllerModel: 'Modelis',
        controllerFirmwareVersion: 'Aparātprogramm. ver.',
        gnssReceiverMainManufacturer: 'Ražotājs',
        gnssReceiverMainModel: 'Modelis',
        gnssReceiverMainFirmwareVersion: 'Aparātprogramm. ver.',
        gnssReceiverSubManufacturer: 'Ražotājs',
        gnssReceiverSubModel: 'Modelis',
        gnssReceiverSubFirmwareVersion: 'Aparātprogramm. ver.',
        machineInfoCompanyName: 'Ražotājs',
        machineInfoMachineType: 'Mašīnas veids',
        machineInfoMachineName: 'Mašīnas nosauk.',
        machineInfoMachineId: 'Mašīnas ID',
        rentalStatus: 'Uzdevums',
        lastUpdated: 'Pēdējais atjauninājums',
      },
      isError: {
        true: 'Kļūda',
        false: 'Parasts',
      },
      isOnline: {
        true: 'Tiešsaistē',
        false: 'Ārpus tīkla',
      },
      machineInfoMachineType: {
        excavator: 'Ekskavators',
        roadroller: 'Ceļu valcējs',
        dozer: 'Buldozers',
        rigiddump: 'Nolaižamais dumpis',
        wheelloader: 'Riteņu iekrāvējs',
        grader: 'Līmeņrādejs',
        automobile: 'Automobilis',
      },
      licenseMachineType: {
        excavator: 'Ekskavators',
        roadroller: 'Ceļu valcējs',
        dozer: 'Buldozers',
        rigiddump: 'Nolaižamais dumpis',
        wheelloader: 'Riteņu iekrāvējs',
        grader: 'Līmeņrādejs',
        automobile: 'Automobilis',
        excavator_license: 'Ekskavators : %{licenseGrantInfo}',
        roadroller_license: 'Ceļu valcējs : %{licenseGrantInfo}',
        dozer_license: 'Buldozers : %{licenseGrantInfo}',
        rigiddump_license: 'Nolaižamais dumpis : %{licenseGrantInfo}',
        wheelloader_license: 'Riteņu iekrāvējs: %{licenseGrantInfo}',
        grader_license: 'Līmeņrādejs : %{licenseGrantInfo}',
        automobile_license: 'Automobilis: %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: 'Pārnesešana',
        sent: 'Pārnests',
        failtosend: 'Pārnesums neizdevās',
      },
      rentalStatus: {
        approved: 'Apstiprināts',
        unapproved: 'Neapstiprināts',
        returned: 'Atgriezts',
      },
    },
    retrofitRentals: {
      name: 'Uzdevums',
      fields: {
        id: 'ID',
        retrofitId: 'Mašīnas ID',
        fromCorporationId: 'Piešķiroša uzņēmuma ID',
        fromCorporationName: 'Piešķiroša uzņēmuma',
        toCorporationId: 'Piešķirtā uzņēmuma',
        toCorporationName: 'Uzņēmums tiek izmantots',
        retrofitRentalId: 'Uzdevuma ID',
        isApproved: 'Statuss',
        isReturned: 'Atgriezts',
        planStartDate: 'Plānotais sākuma datums',
        planEndDate: 'Plānotais beigu datums',
        actualStartDate: 'Sākuma datums',
        actualEndDate: 'Beigu datums',
        rentalUsers: 'E-pasts',
        email: 'E-pasts',
        rentalStatus: 'Statuss',
      },
      isApproved: {
        true: 'Apstiprināts',
        false: 'Neapstiprināts',
      },
      rentalStatus: {
        approved: 'Apstiprināts',
        unapproved: 'Neapstiprināts',
        returned: 'Atgriezts',
      },
    },
    pendingRentals: {
      name: 'Gaida apstiprinājumu',
      fields: {
        fromCorporationName: 'Piešķiroša uzņēmuma',
        planStartDate: 'Plānotais sākuma datums',
        planEndDate: 'Plānotais beigu datums',
      },
    },
    pendingTransfers: {
      name: 'Gaida apstiprinājumu',
      fields: {
        fromCorporationName: 'Pārnesošā uzņēmuma',
        planDate: 'Plānotais pārsūtīšanas datums',
        lastUpdated: 'Pēdējais atjauninājums',
      },
    },
    pendingAlternateRegists: {
      name: 'Gaida apstiprinājumu',
      fields: {
        fromCorporationName: 'Pašreizējais īpašnieks',
        lastUpdated: 'Pēdējais atjauninājums',
      },
    },
    assignments: {
      name: 'Pārnesums',
      fields: {
        id: 'ID',
        retrofitId: 'Mašīnas ID',
        fromCorporationId: 'Pārnesošās uzņēmuma ID',
        fromCorporationName: 'Pārnesošā uzņēmuma',
        toCorporationId: 'Pārnestā uzņēmuma',
        toCorporationName: 'Uzņēmums',
        isApproved: 'Statuss',
        planDate: 'Plānotais pārsūtīšanas datums',
        actualDate: 'Pārnesuma datums',
        transferUsers: 'E-pasts',
        email: 'E-pasts',
        lastUpdated: 'Pēdējais atjauninājums',
      },
      isApproved: {
        true: 'Apstiprināts',
        false: 'Neapstiprināts',
      },
    },
    buckets: {
      name: 'Kauss',
      fields: {
        companyName: 'Uzņēmuma nosaukums',
        siteId: 'Būvobjekts',
        name: 'Nosaukums',
        corporationId: 'Uzņēmums tiek izmantots',
        bucketType: 'Veids',
        bk3File: 'BK3 fails',
        'bk3File.title': 'BK3 fails',
        gltfFile: 'Kausa fails',
        uploadedBy: 'Reģistrēts ar',
        updatedBy: 'Atjaunināts ar',
        fileStatus: 'Statuss',
        lastUpdated: 'Pēdējais atjauninājums',
        uploadBk: 'Augšupielādēt BK3',
      },
      bucketType: {
        slope: 'Slīpums',
        standard: 'Standarta',
        tilt: 'Noliekt',
        tiltrotator: 'Noliec Rotators',
      },
      uploadedBy: {
        admin: 'Administrators',
        tablet: 'Planšete',
      },
      fileStatus: {
        converting: 'Konvertēšana',
        completed: 'Pabeigts',
        error: 'Kļūda',
      },
    },
    projects: {
      name: 'Projekts',
      fields: {
        name: 'Projekta nosaukums',
        coordinate: 'Koordinātu sist.',
        version: 'Versija',
        status: 'Statuss',
        lastUpdated: 'Pēdējais atjauninājums',
        regionId: 'Reģions',
        projectionId: 'Projekcija',
        geoidId: 'Ḡeoids',
        datumId: 'Datums',
        file: 'Fails(i)',
        retrofitId: 'Mērķa ierīce',
      },
      status: {
        converted: 'Pabeigts',
        waitforconvert: 'Konvertēšana',
        error: 'Kļūda',
      },
    },
    extensionarms: {
      name: 'Izbīdāmā svira',
      fields: {
        extensionarmType: 'Veids',
        name: 'Nosaukums',
        corporationId: 'Uzņēmums tiek izmantots',
        corporationName: 'Uzņēmums tiek izmantots',
        siteId: 'Būvobjekts',
        siteName: 'Būvobjekts',
        lastUpdated: 'Pēdējais atjauninājums',
        extensionarmFile: 'Izbīdāmā svira fails',
      },
      extensionarmType: {
        basic: 'Izbīdāmā svira',
        a: 'Tips A',
        b: 'Tips B',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: 'Koplietot uz',
        name: 'Nosaukums',
        extensionarmType: 'Veids',
        extensionarmFile: {
          title: 'Izbīdāmā svira fails',
        },
      },
    },
    corporations: {
      name: 'Uzņēmums',
      fields: {
        name: 'Uzņēmums tiek izmantots',
      },
    },
    retrofitShareFiles: {
      name: 'Pieteikumu žurnāls',
      fields: {
        name: 'Faila nosaukums',
        fileTime: 'Saņemšanas datums un laiks',
        url: 'Lejupielādēt',
      },
    },
    retrofitConfigs: {
      name: 'Konfigurācijas informācija',
      fields: {
        timeStamp: 'Saņemšanas datums un laiks',
        url: 'Lejupielādēt',
      },
    },
    retrofitKitInfos: {
      name: 'Kontroliera info',
      fields: {
        timeStamp: 'Saņemšanas datums un laiks',
        url: 'Lejupielādēt',
      },
    },
    retrofitCalibs: {
      name: 'Kalibrācijas info.',
      fields: {
        timeStamp: 'Saņemšanas datums un laiks',
        url: 'Lejupielādēt',
        detailInfo: {
          createDateTime: 'Izveidošanas datums un laiks',
          updateDateTime: 'Atjaunināšanas datums un laiks',
          machineInfo: {
            companyName: 'Ražotājs',
            machineType: 'Mašīnas veids',
            machineName: 'Mašīnas nosauk.',
            machineId: 'Mašīnas ID',
            drumConfiguration: 'Mašīnas konfigurācija',
            guidanceMode: 'Virzīšanas režīms',
          },
          machineGeometryInfo: {
            boomLength: 'Izlices garums',
            secondBoomLength: '2. izlices garums',
            armLength: 'Sviras pleca garums',
            armTopPinToBucketSideLinkPinLength:
              'Attāl. starp sviras augš. un kausa sānu saites',
            boomTopPinToBucketSideLinkPinLength:
              'Attāl. starp izlices augš. un kausa sānu saites',
            bucketLink1Length: 'Attāl. starp kausa sānu un kausa cil.',
            bucketLink2Length: 'Attāl. starp kausa cilindra un kausa saites',
            bodyCenterToBoomFootPin: {
              x: 'Izlices apakš. tapas X w.r.t korp. centr.',
              y: 'Izlices apakš. tapas Y w.r.t korp. centr.',
              z: 'Izlices apakš. tapas Z w.r.t korp. centr.',
            },
            drumWidth: 'Asmeņa platums',
            length: 'Garums starp kurpēm',
            shoeInterval: 'Intervāla garums starp kurpēm',
            bladeWidthForDozer: 'Asmeņa platums buldozerim',
            distanceFromBodyToBlade: 'Attālums no korpusa līdz asmeņiem',
            frontToMainGnssAntenna:
              'Garums no taisnas līnijas centra, kas savieno priekšējo piezemēto galu ar galveno gnss antenu X',
            frontToSubGnssAntenna:
              'Garums no taisnas līnijas centra, kas savieno priekšējo iezemēto galu priekšpusē ar apakšējo gnss antenu X',
            centerToMainGnssAntenna:
              'Y attālums no centra līdz galvenās GNSS elektriskās fāzes centram',
            centerToSubGnssAntenna:
              'Y attālums no centra līdz sub GNSS elektriskās fāzes centram',
            groundToMainGnssAntenna:
              'Z attālums no zemes līdz galvenajam GNSS elektriskās fāzes centram',
            groundToSubGnssAntenna:
              'Z attālums no zemes līdz apakš GNSS elektriskās fāzes centram',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'Izlices IMV novirz. leņķ.',
            secondBoomImuOffsetAngle: '2. izlices IMV novirzes leņķis',
            armImuOffsetAngle: 'Sviras IMV novirz. leņķ.',
            bucketImuOffsetAngle: 'Kausa IMV novirz. leņķ.',
            bodyImuOffsetRollAngle: 'Korp. IMV novirz. sānsveres leņķ. ',
            bodyImuOffsetPitchAngle: 'Korp. IMV novirz. sānsveres leņķ.',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: 'Galv. GNSS X w.r.t korp. centr.',
                y: 'Galv. GNSS Y w.r.t korp. centr.',
                z: 'Galv. GNSS Z w.r.t korp. centr.',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: 'Papild. GNSS X w.r.t korp. centr.',
                y: 'Papild. GNSS Y w.r.t korp. centr.',
                z: 'Papild. GNSS Z w.r.t korp. centr.',
              },
            },
          },
          extendedModel: {
            swingBoom: 'Pagriešanas strēle modelis',
            twoPieceBoom: 'Divdaļīga strēle modelis',
            minimalSwingRadius: 'Nobīdes strēles modelis',
          },
          measuredValues: {
            otherLength: {
              machineHeight: 'Atsvara atstatums',
            },
            bodyImu1: {
              roll: 'IMU izmērītās vērtības apgāšanās',
              pitch: 'IMU izmērītās vērtības slīpums',
              yaw: 'IMU izmērītās vērtības novirze',
            },
            bodyImu2: {
              roll: 'IMU izmērītā apgāšanās vērtība',
              pitch: 'IMU izmērītā slīpuma izmērītā vērtība',
              yaw: 'IMU izmērītā novirzes vērtība',
            },
            frontLeft: {
              x: 'Priekšējās kreisās puses izmērītā vērtība X',
              y: 'Priekšējās kreisās puses izmērītā vērtība Y',
              z: 'Priekšējās kreisās puses izmērītā vērtība Z',
            },
            frontRight: {
              x: 'Priekšējās labās puses izmērītā vērtība X',
              y: 'Priekšējās labās puses izmērītā vērtība Y',
              z: 'Apsekojuma vērtība priekšējā labajā pusē Z',
            },
            frontGroundingEnd: {
              x: 'Apsekojuma vērtība priekšējam gounding end X',
              y: 'Apsekojuma vērtība priekšējā uzbēruma galā Y',
              z: 'Apsekojuma vērtība priekšējā ielaiduma galā Z',
            },
            rearGroundingEnd: {
              x: 'Apsekojuma vērtība aizmugurējā uzmavas galā X',
              y: 'Apsekojuma vērtība aizmugurējā ielaiduma galā Y',
              z: 'Apsekojuma vērtība aizmugurējā gounding galā Z',
            },
            mainGnssAntenna: {
              x: 'Galvenās GNSS antenas izmērītā vērtība X',
              y: 'Galvenās GNSS antenas izmērītā vērtība Y',
              z: 'Galvenās GNSS antenas apsekojuma vērtība Z',
            },
            subGnssAntenna: {
              x: 'Apsekojamā apakš GNSS antenas vērtība X',
              y: 'Apsekojamā apakš GNSS antenas vērtība Y',
              z: 'Apsekojamā apakš GNSS antenas vērtība Z',
            },
            prismPoleOffset: 'Prism pole henght',
            antennaElectricalCenterHeight:
              'Augstums no antenas elektriskā centra',
          },
        },
      },
    },
    retrofitAccuracies: {
      name: 'Precizitātes pārbaudes rezultāts',
      fields: {
        checkDateTime: 'Apstiprināšanas datums un laiks',
        surveyPoint: 'Mērījuma punkts',
        positionN: 'Izrautā vērtība : N',
        positionE: 'E',
        positionZ: 'Z',
        machineOffsetN: 'Nobīdes vērtība : N',
        machineOffsetE: 'E',
        machineOffsetZ: 'Z',
        referencePointName: 'Atsauces punkta nosaukums',
        referencePointN: 'Atsauces punkts : N',
        referencePointE: 'E',
        referencePointZ: 'Z',
        unitLength: 'Garuma mērvienība',
      },
    },
    retrofitBasicSettings: {
      name: 'Pamatiestatījumi',
      fields: {
        timeStamp: 'Saņemšanas datums un laiks',
        url: 'Lejupielādēt',
      },
    },
    retrofitErrors: {
      name: 'Kļūdas informācija',
      fields: {
        notifiedTime: 'Parādīšanās datums un laiks',
        errorCodes: 'Kļūdas kods: Detaļas',
      },
    },
    retrofitToposurveys: {
      name: 'Topogr. izpētes saraksts',
      fields: {
        surveyDate: 'Aptaujas datums un laiks',
        projectName: 'Projekta nosaukums',
        layerName: 'Slānis',
        pointName: 'Punkta nosauk.',
        surveyPoint: 'Mērījuma punkts',
        n: 'Izrautā vērtība : N',
        e: 'E',
        z: 'Z',
        unitLength: 'Garuma mērvienība',
      },
    },
    asbuilts: {
      name: 'Būvdarbu rezultāti',
      fields: {
        execStartDate: 'Izpildes sākums',
        execEndDate: 'Izpildes beigas',
        startPeriod: 'Iegādes periods no',
        endPeriod: 'Iegādes periods līdz',
        execStatus: 'Statuss',
        url: '',
        csvType: 'Datu formāts',
        createUser: 'Izveidot lietotāju',
      },
      execStatus: {
        complete: 'Pabeigts',
        inprogress: 'Apstrāde',
        error: 'Kļūda',
        completewithoutdata: 'Pabeigts (Nav datu)',
      },
    },
    retrofitBasicInfos: {
      name: 'Pamatinfo.',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: 'Izplatītājs',
        retrofitRegionId: 'Reģions',
        corporationId: 'Uzņēmuma ID',
        corporationName: 'Uzņēmuma nosaukums',
        basicInfoManufacturer: 'Ražotājs',
        basicInfoModel: 'Modelis',
        basicInfoProductNumber: 'Produkta Nr.',
        basicInfoSerialNumber: 'Sērijas Nr.',
        lastUpdated: 'Pēdējais atjauninājums',
        description: 'Apraksts',
        ownerId: 'Uzņēmuma ID',
        ownerName: 'Uzņēmuma nosaukums',
      },
      tag: {
        retrofitBasicInfosDetail: 'Pamatinfo.',
        sendBack: 'Sūtīt atpakaļ',
      },
      sendBack: {
        fields: {
          site: 'Būvobjekts',
          corporation: 'Uzņēmums tiek izmantots',
          ntripSettingInfo: 'Ntrip ID',
          toRetrofit: 'Alternatīvais kontrolieris',
          notificationEmailAddress: 'E-pasts',
        },
      },
    },
    retrofitBulkActions: {
      name: 'Masveida reģistrācija',
      fields: {
        uploadFileName: 'Faila nosaukums',
        execStartDate: 'Izpildes sākuma datums un laiks',
        execEndDate: 'Izpildes beigas datums un laiks',
        execStatus: 'Statuss',
        uploadFile: 'Reģistrētais fails',
        resultFile: 'Rezultāti reģistrācijai',
      },
      execStatus: {
        complete: 'Pabeigts',
        inprogress: 'Apstrāde',
        error: 'Kļūda',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: 'Administratora uzņēmuma masveida darbība',
      fields: {
        uploadFileName: 'Faila nosaukums',
        execStartDate: 'Izpildes sākuma datums un laiks',
        execEndDate: 'Izpildes beigas datums un laiks',
        execStatus: 'Statuss',
        uploadFile: 'Reģistrētais fails',
        resultFile: 'Rezultāti reģistrācijai',
      },
      execStatus: {
        complete: 'Pabeigts',
        inprogress: 'Apstrāde',
        error: 'Kļūda',
      },
    },
    tasks: {
      name: 'Uzdevums',
      fields: {
        name: 'Nosaukums',
        status: 'Statuss',
        description: 'Apraksts',
        projectSourceLayerId: 'Slānis',
        retrofitIds: 'Mērķa ierīce',
        expiredDate: 'Derīguma termiņš',
        createDate: 'Derīguma termiņš',
        rectangle: 'Atbalstītais diapazons',
        lastUpdated: 'Pēdējais atjauninājums',
      },
      status: {
        error: 'Kļūda',
        converting: 'Konvertēšana',
        sending: 'Pārnesešana',
        sent: 'Pārnests',
        failtosend: 'Pārnesums neizdevās',
      },
    },
    compactionMaterials: {
      name: 'Materiāli',
      fields: {
        name: 'Nosaukums',
      },
    },
    weathers: {
      name: 'Laika apstākļi',
      fields: {
        name: 'Nosaukums',
      },
    },
    operators: {
      name: 'Operatori',
      fields: {
        name: 'Nosaukums',
      },
    },
    compactionWorkAreas: {
      name: 'Sakārtošanas darba apgabali',
      fields: {
        name: 'Darba apgabalu nosaukumi',
        blockSize: 'Bloka izmērs',
        description: 'Apraksts',
      },
    },
    compactionLayers: {
      name: 'Sakārtošanas slāņi',
      fields: {
        name: 'Nosaukums',
        thickness: 'Biezums',
        passCount: 'Pārvietošanās skaits',
        compactionMaterialId: 'Materiāls',
        inUse: 'Statuss',
        description: 'Apraksts',
      },
      inUse: {
        true: 'izmantošanā',
        false: 'Neizmantots',
      },
    },
    gnssSettings: {
      name: 'Ntrip Iestatījums',
      fields: {
        registCorporationName: 'Reģistrēts ar',
        ownerCorporationId: 'Uzņēmuma ID',
        ownerCorporationName: 'Uzņēmuma nosaukums',
        name: 'Nosaukums',
        url: 'Servera URL',
        portnum: 'Ports',
        userId: 'ID',
        password: 'Parole',
        retrofitId: 'Mērķa ierīce',
        status: 'Statuss',
        updateDate: 'Pēdējais atjauninājums',
      },
      status: {
        waitforsend: 'Konvertēšana',
        sending: 'Pārnesešana',
        sent: 'Pārnests',
        failtosend: 'Pārnesums neizdevās',
      },
    },
    supportMode: {
      name: 'Atbalsta režīms',
      fields: {
        enabled: 'Iespējot atbalsta režīmu',
        corporationId: 'Uzņēmuma ID',
        corporationName: 'Uzņēmuma nosaukums',
        siteId: 'Būvobjekta ID',
        siteName: 'Būvobjekts',
        latitude: 'Platums',
        longitude: 'Garums',
        workPeriodStartDate: 'Patiesais būvniecības periods (sākuma datums)',
        workPeriodEndDate: 'Patiesais būvniecības periods (beigu datums)',
        photoUrl: 'Vietnes attēla URL',
        description: 'Apraksts',
      },
    },
    userNotices: {
      name: 'Paziņojums',
      noticeType: {
        emergencyimportant: 'Ārkārtas/Svarīgi',
        emergency: 'Ārkārtas',
        important: 'Svarbu',
      },
      fields: {
        noticeType: 'Svarba',
        noticeTypes: 'Svarba',
        noticeCategory: 'Paziņojuma tips',
        title: 'Nosaukums',
        content: 'Satura',
        publicationStartDate: 'Sākuma datums un laiks(UTC)',
        publicationEndDate: 'Beigu datums un laiks(UTC)',
        file: 'Pielikumi',
        language: 'Valoda',
        emergency: 'Ārkārtas',
        important: 'Svarbu',
      },
    },
    firmwares: {
      name: 'Firmware',
      fields: {
        version: 'Versija',
        compatibilityLevel: 'Saderības līmenis',
        firmwareFile: 'Firmware fails',
        firmwareFileCheckSum: 'Firmware pārbaudes summa',
        firstInitializationFile: 'Inicializācijas fails 1',
        firstInitializationFileCheckSum:
          'Inicializācijas faila 1 kontroles summa',
        secondInitializationFile: 'Inicializācijas fails 2',
        secondInitializationFileCheckSum:
          'Inicializācijas faila 2 kontroles summa',
      },
    },
    bucketShares: {
      name: 'Spainīšu kopīgošana',
      fields: {
        fromBucketId: 'No spainota ID',
        corporationId: 'Uzņēmuma ID',
        name: 'Nosaukums',
      },
    },
    extensionarmShare: {
      name: 'Pagarinājuma roku koplietošana',
      fields: {
        name: 'Nosaukums',
      },
    },
    retrofitCorporationLicenses: {
      name: 'LICENCE',
      fields: {
        licenseId: 'Licences ID',
        licenseType: 'Veids',
        issuedAt: 'Izdošanas datums',
        expiresAt: 'Derīguma termiņš',
        retrofitId: 'Mašīnas',
        comment: 'Apraksts',
        licenseStatus: 'Statuss',
        licensePermission: 'LICENCE',
        productType: 'Produkta nosaukums',
      },
      licenseStatus: {
        unlicense: 'Neiestrēdzināts',
        disable: 'Nederīgs',
        enable: 'Derīgs',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: 'Uzņēmuma nosaukums',
        namePlates: 'Foto',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: 'Garuma mērvienība',
      },
    },
    geofences: {
      name: 'Ģeofence',
      fields: {
        type: 'Veids',
        name: 'Nosaukums',
        dimension: 'Izmērs',
        trigger: 'Izraisītājs',
        valid: 'Derīgs',
        alertType: 'Brīdinājuma tips',
        startDate: 'Sākuma datums',
        endDate: 'Beigu datums',
        transparency: 'Caurspīdīgs',
        elevation: 'Pacēlums',
        height: 'Augstums',
        radius: 'Rādiuss',
        lastUpdated: 'Pēdējais atjauninājums',
        points: 'Atbalstītais diapazons',
        thickness: 'Biezums',
      },
      type: {
        line: 'Līnija',
        polygon: 'Poligons',
        circle: 'Riņķis',
        point: 'Punkta',
        wall: 'Siena',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: 'Derīgs',
        false: 'Nederīgs',
      },
      alertType: {
        attention: 'Uzmanība',
        notice: 'Paziņojums',
        warning: 'Brīdinājums',
        caution: 'Uzmanība',
        danger: 'Bīstamība',
      },
      transparency: {
        true: 'Caurspīdīgs',
        false: 'Necaurredzams',
      },
    },
    geofenceAlerts: {
      name: 'Brīdinājumi',
      fields: {
        timeStamp: 'Konstatēšanas datums un laiks',
        geofenceName: 'Ģeofence nosaukums',
        alertType: 'Brīdinājuma tips',
        collisionPoint: 'Sadursmes punkts',
        retrofit: {
          machineInfoMachineName: 'Mašīnas',
        },
      },
      alertType: {
        attention: 'Uzmanība',
        notice: 'Paziņojums',
        warning: 'Brīdinājums',
        caution: 'Uzmanība',
        danger: 'Bīstamība',
      },
    },
    ntrips: {
      name: 'Ntrip vadība',
      fields: {
        name: 'Lietotāja ID',
        ntripPass: 'Parole',
        serial: 'Sērijas Nr.',
        customerCorporationId: 'Uzņēmuma ID',
        customerCorporationName: 'Uzņēmuma nosaukums',
        ntrip: 'Statuss',
        numberOfExchanges: 'Pārvietojumu skaits',
        id: 'ID',
        licenceId: 'Licences ID',
        dateOfRegistration: 'Reģistrācijas datums',
        billingToCustomers: 'Norēķini klientiem',
        paymentToKomatsu: 'Samaksai Komatsu',
        applicantId: 'Pieteicēja ID',
        applicant: 'Pieteicējs',
        requestCorporationId: 'Pieprasiet uzņēmuma ID',
        requestCorporationName: 'Pieprasiet uzņēmuma nosaukumu',
        isUsed: 'Tiek izmantots',
      },
      ntrip: {
        publish: 'Apmaksāts',
        stop: 'Atpūta',
        delete: 'Izdzēsts',
      },
    },
    retrofitAlternateRegists: {
      name: 'Kontroliera Proxy Reģistrācija',
      fields: {
        id: 'ID',
        retrofitId: 'retrofitId',
        fromCorporationId: 'no uzņēmuma ID',
        fromCorporationName: 'Kontroliera Proxy Reģistrācija',
        toCorporationId: 'Lietotāja',
        toCorporationName: 'Uzņēmuma nosaukums',
        isApproved: 'Statuss',
        createDate: 'Pieteikuma datums',
        actualDate: 'Apstiprinājuma datums',
        transferUsers: 'E-pasts',
        email: 'E-pasts',
        lastUpdated: 'Pēdējais atjauninājums',
      },
      isApproved: {
        true: 'Apstiprināts',
        false: 'Neapstiprināts',
      },
    },
    pendingApprovals: {
      name: 'Gaida apstiprinājumu',
      fields: {
        approvalType: 'Veids',
        requestedBy: 'Pieteicēja uzņēmums',
        lastUpdated: 'Pēdējais atjauninājums',
      },
      approvalType: {
        assignment: 'Uzdevums',
        transfer: 'Pārnesums',
        agent: 'Kontroliera Proxy Reģistrācija',
      },
    },
    retrofitSendbacks: {
      name: 'Sūtīt atpakaļ',
      fields: {
        fromRetrofitId: 'No Retrofit',
        toRetrofitId: 'Alternatīvais kontrolieris',
        notificationEmailAddress: 'E-pasts',
        siteId: 'Būvobjekts',
        corporationId: 'Uzņēmuma ID',
        corporationName: 'Uzņēmums tiek izmantots',
        ntripId: 'Ntrip Info',
        isComplete: 'Atpakaļ sūtīšana pabeigta',
      },
    },
    siteBuriedStructures: {
      fields: {
        epsgCode: 'Epsg',
      },
    },
  },
};

export default message;
