import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core';
import { useDataProvider, usePermissions } from 'react-admin';
import { useCustomGetOne, useGetAccount, useResource } from '../../../hooks';
import {
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  RetrofitInfoMenu,
  Loading,
} from '../..';
// test時にmoock化するため明示的にimport
import RetrofitInfoEdit from './retrofitInfoEdit/RetrofitInfoEdit';
import RetrofitRentalDetails from './RetrofitRentalDetails';
import RetrofitRentalList from './RetrofitRentalList';
import {
  isAdminCorporation,
  adminCorporationIdsFromRetrofitData,
} from './retrofitInfoEdit';
import { colors, styles } from '../../../theme';
import { RetrofitData, UserPermissionsData } from '../../../dataProvider';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      marginTop: 12,
      marginBottom: 8,
    },
    machineName: {
      marginTop: 7,
      fontSize: '18px',
      fontWeight: 'bold',
      width: 630,
    },
    iconMenu: {
      height: 0,
      position: 'relative',
      textAlign: 'right',
      color: colors.text.primaryLight,
    },
    simpleShowLayout: {
      ...styles.zeroPaddingHorizontal,
    },
  }),
);

const RetrofitShow: React.FC = props => {
  const classes = useStyles();
  const resource = useResource('retrofits');
  const dataProvider = useDataProvider();
  const [assignmentLoading, setAssignmentLoading] = useState(false);
  const [existAssignment, setExistAssignment] = useState(false);
  const { id: retrofitId } = useParams<{ id: string }>();
  const { getCorporationId, isLoading: accountLoading } = useGetAccount();
  const corporationId = getCorporationId();
  const { data } = useCustomGetOne(resource, retrofitId);
  const { permissions } = usePermissions();

  useEffect(() => {
    if (!corporationId) return;
    setAssignmentLoading(true);
    dataProvider
      .getList('retrofitRentals', {
        filter: {
          retrofitId,
          fromCorporationId: corporationId,
          isApproved: true, // 承認済み
        },
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
      })
      .then(result => {
        if (result === undefined) return;
        const { data: assignment } = result;
        if (assignment === undefined) return;
        setExistAssignment(assignment.length > 0);
      })
      .finally(() => {
        setAssignmentLoading(false);
      });
  }, [dataProvider, retrofitId, corporationId]);

  if (accountLoading() || !data || assignmentLoading) return <Loading />;
  const retrofit = data as RetrofitData;
  const unMatchCorporation = corporationId !== retrofit.corporationId;
  const admindisabled =
    isAdminCorporation(
      adminCorporationIdsFromRetrofitData(retrofit),
      corporationId,
    ) && unMatchCorporation;
  const assignTabStyle =
    admindisabled && !existAssignment ? { display: 'none' } : {};
  const userRole = permissions.userRoles as UserPermissionsData['userRoles'];
  const supportMode = userRole.includes('SupportMode');

  return (
    <SimpleShowLayout className={classes.simpleShowLayout} {...props}>
      <div className={classes.header}>
        <div className={classes.iconMenu}>
          <RetrofitInfoMenu
            retrofitId={retrofitId}
            parentPath={`/${resource}`}
            supportMode={supportMode}
          />
        </div>
        <div className={classes.machineName}>{data.machineInfoMachineName}</div>
      </div>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs />}>
        <Tab label="resources.retrofits.fields.information">
          <RetrofitInfoEdit
            {...{ admindisabled, corporationid: corporationId, ...props }}
          />
        </Tab>
        <Tab label="resources.retrofits.fields.rental" style={assignTabStyle}>
          <div>
            <RetrofitRentalDetails
              {...{ corporationid: corporationId, ...props }}
            />
            <RetrofitRentalList
              {...{ corporationid: corporationId, ...props }}
            />
          </div>
        </Tab>
      </TabbedShowLayout>
    </SimpleShowLayout>
  );
};

RetrofitShow.displayName = 'RetrofitShow';
export default RetrofitShow;
