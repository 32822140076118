import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Button } from 'react-admin';
import { colors } from '../../theme';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../theme/define';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      backgroundColor: colors.button.cancel,
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
  }),
);

export type LinkButtonProps = { path?: string; label?: string } & ButtonProps;

const LinkButton: React.FC<LinkButtonProps> = ({ path, label, ...props }) => {
  const classes = useStyles();
  const testId = label || '';
  return (
    <Button
      className={classes.root}
      component={Link}
      to={path}
      label=""
      data-testid={testId}
      {...props}
    />
  );
};

LinkButton.displayName = 'LinkButton';
export default LinkButton;
