import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { ButtonProps as MuiProps } from '@material-ui/core/Button';
import Button, { RaProps } from '../../bases/button/Button';
import { colors } from '../../../theme';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../../theme/define';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: colors.button.save,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.saveHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.saveDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
  }),
);

export interface Props {
  label: string;
}

const DoneButton = (props: Partial<Props & RaProps & MuiProps>) => {
  const { label, onClick, disabled, style, children } = props;
  const classes = useStyles();
  return (
    <Button
      style={style}
      className={classes.root}
      label=""
      data-testid={label}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

DoneButton.displayName = 'DoneButton';
export default DoneButton;
