/* eslint-disable camelcase */
import { fetchUtils } from 'react-admin';
import { config } from '../../utils';
import { RemoteStartRemoteApi } from '.';

const uri = `${config.api.remote.apiUri}/operators/start_remote`;

export default async (mac: string): Promise<RemoteStartRemoteApi> => {
  // TODO: 国際化対応
  const encodeRemoteMsg = '画面共有を開始します';
  const encodeScreenShareMsg = '遠隔操作を開始します';

  // JSONをkeyにてJSON変数名[kye名]で取得するためのinterface
  interface ParamKey {
    [key: string]: string | number;
    target_mac: string;
    confirm_screen_sharing: number;
    confirmation_message_screen_sharing: string;
    confirm_remote: number;
    confirmation_message_remote: string;
    operator_permissions: number;
    operator_timeout: number;
    support_number: number;
  }
  const setParam: ParamKey = {
    target_mac: mac,
    // ■画面共有確認ダイアログ表示有無
    // 0:表示なし,1:表示あり
    confirm_screen_sharing: 0,
    // ■画面共有確認ダイアログ文言
    confirmation_message_screen_sharing: encodeRemoteMsg,
    // ■遠隔操作確認ダイアログ表示有無
    // 0:表示なし,1:表示あり
    confirm_remote: 0,
    // ■遠隔操作確認ダイアログ文言
    confirmation_message_remote: encodeScreenShareMsg,
    // ■オペレーター権限
    // 0:画面共有のみ,3:画面共有、赤ペン、指さし指示,7:3＋遠隔操作,15:3＋ボタンで遠隔操作
    operator_permissions: 3,
    // ■未操作時のタイムアウト時間
    operator_timeout: 1500,
    support_number: 0,
  };
  const setbody = Object.keys(setParam)
    .map(key => `${key}=${encodeURIComponent(setParam[key])}`)
    .join('&');

  const { json } = await fetchUtils.fetchJson(uri, {
    method: 'POST',
    mode: 'no-cors',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
    body: setbody,
  });
  return json;
};
