import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { SimpleShowLayout, Typography } from '..';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '0px 24px 24px 0px',
      '& .MuiFormLabel-root': {
        width: '100%',
      },
    },
    title: {
      padding: '4px',
      backgroundColor: '#31323A',
    },
  }),
);

interface CustomChildBoxProps {
  title: string;
}

const CustomChildBox: React.FC<Partial<CustomChildBoxProps>> = props => {
  const classes = useStyles();
  return (
    <Box flex={1} className={classes.root}>
      <Typography className={classes.title}>{props.title}</Typography>
      <SimpleShowLayout {...props} />
    </Box>
  );
};
CustomChildBox.displayName = 'CustomChildBox';
export default CustomChildBox;
