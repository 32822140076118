import React from 'react';
import { Grid } from '@material-ui/core';
import { GridProps } from '@material-ui/core/Grid';
import { Record } from 'react-admin';

interface Props {
  resource?: string;
  basePath?: string;
  record?: Record;
  version?: number;
}

const GridLayout: React.FC<GridProps & Props> = props => {
  const { children, resource, basePath, record, version, ...rest } = props;

  return (
    <Grid {...rest}>
      {React.Children.map(children, field => {
        if (React.isValidElement(field)) {
          return React.cloneElement(field, {
            resource,
            basePath,
            record,
            version,
          });
        }
        if (typeof field === 'string') {
          return field;
        }
        return null;
      })}
    </Grid>
  );
};

GridLayout.displayName = 'GridLayout';
export default GridLayout;
