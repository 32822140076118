import {
  DataProvider,
  GetListParams,
  GetListResult,
  DeleteManyParams,
  DeleteManyResult,
} from 'react-admin';
import { getDataProvider, AccountData, RetrofitAlternateRegistData } from '.';

const getPendingAlternateRegists = async (
  dataProvider: DataProvider,
  params: GetListParams,
): Promise<GetListResult> => {
  const { getOne, getList } = dataProvider;

  // ログイン中のユーザーのメールアドレスと企業IDを取得
  const { data: accountData } = await getOne('accounts', { id: 1 });
  const { email } = accountData as AccountData;

  // 承認待ちの代理申請情報をメールアドレスを指定して検索
  const emailFilter: Partial<RetrofitAlternateRegistData> = {
    ...params.filter,
    isApproved: false,
    toMailAddress: email,
  };

  const { data: registsByEmail } = await getList('retrofitAlternateRegists', {
    sort: { field: 'id', order: 'ASC' },
    pagination: { page: 1, perPage: 100 },
    filter: emailFilter,
  });

  const data = [...registsByEmail];

  // ソート
  const { field, order } =
    params.sort.field === 'id'
      ? { field: 'createDate', order: 'ASC' }
      : params.sort;
  const sortData = [...data].sort((a, b) => {
    if (typeof a[field] === 'number') {
      return order === 'ASC' ? a[field] - b[field] : b[field] - a[field];
    }
    const strA = a[field] || '';
    const strB = b[field] || '';
    return order === 'ASC'
      ? strA.localeCompare(strB)
      : strB.localeCompare(strA);
  });

  // ページネーション
  const { page, perPage } = params.pagination;
  const start = (page - 1) * perPage;
  const end = start + perPage;
  const pageData = sortData.slice(start, end);
  return { total: data.length, data: pageData };
};

const setApproval = async (
  dataProvider: DataProvider,
  { ids }: DeleteManyParams,
): Promise<DeleteManyResult> => {
  // ユーザー所属企業の情報を取得
  const { data: accountData } = await dataProvider.getOne('accounts', {
    id: 1,
  });
  const { corporationId, corporationName } = accountData as AccountData;

  // 更新対象の代理申請情報を取得
  const { data: alternateRegistData } = await dataProvider.getMany(
    'retrofitAlternateRegists',
    {
      ids,
    },
  );
  const alternateRegists = alternateRegistData as RetrofitAlternateRegistData[];
  await Promise.all(
    alternateRegists.map(async alternateRegist => {
      const targetId = alternateRegist.id || undefined;
      if (!targetId)
        throw new Error('Invalid updateMany paramerter! id is undefined'); // idの型がnull許容の影響で必要となるエラー処理※実際は到達不可
      // 代理申請情報を更新
      await dataProvider.update('retrofitAlternateRegists', {
        id: targetId,
        data: {
          ...alternateRegist,
          toCorporationId: corporationId,
          toCorporationName: corporationName,
          isApproved: true,
          actualDate: new Date().toISOString(),
        },
        previousData: { ...alternateRegist, id: targetId },
      });
    }),
  );
  return { data: ids };
};

export default (): DataProvider => {
  const dataProvider = getDataProvider();
  return {
    getList: (_, params): any =>
      getPendingAlternateRegists(dataProvider, params),
    getOne: (_, params): any => {
      return (async () => {
        const { data } = await getPendingAlternateRegists(dataProvider, {
          pagination: {
            page: 1,
            perPage: 1,
          },
          sort: {
            field: 'id',
            order: 'asc',
          },
          filter: { id: params.id },
        });
        return { data: data[0] };
      })();
    },
    getMany: () => {
      throw new Error('Not supported for pending alternateRegists');
    },
    getManyReference: () => {
      throw new Error('Not supported for pending alternateRegists');
    },
    update: () => {
      throw new Error('Not supported for pending alternateRegists');
    },
    updateMany: () => {
      throw new Error('Not supported for pending alternateRegists');
    },
    create: () => {
      throw new Error('Not supported for pending alternateRegists');
    },
    delete: () => {
      throw new Error('Not supported for pending alternateRegists');
    },
    deleteMany: (resource, params) => setApproval(dataProvider, params),
  };
};
