import { Identifier, Record, ReduxState, useQueryWithStore } from 'react-admin';

type UseGetOneHookValue = {
  data?: Record;
  loading: boolean;
  loaded: boolean;
  error?: any;
};

/*
 * 以下の修正で事前にStoreにデータがない場合、例外を投げるようになった。
 * https://github.com/marmelab/react-admin/commit/52a0e800e46e28a13ab4d26a2bdbf6927c362b06?diff=split#diff-64ba6c6277a7a009705c8e152e715d76
 * 個別ページをリロードした場合などで例外が発生するので以下の古いバージョンと同内容のHookを利用する
 */
const useCustomGetOne = (
  resource: string,
  id: Identifier,
  options?: any,
): UseGetOneHookValue =>
  useQueryWithStore(
    { type: 'getOne', resource, payload: { id } },
    options,
    (state: ReduxState) =>
      state.admin.resources[resource]
        ? state.admin.resources[resource].data[id]
        : null,
  );

export default useCustomGetOne;
