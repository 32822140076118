import { Pagination as RaPagination } from 'ra-ui-materialui';

interface RaProps {
  ids: any[];
  loading: boolean;
  page: number;
  perPage: number;
  rowsPerPageOptions: number[];
  setPage: Function;
  setPerPage: Function;
  total: number;
}

const Pagination = RaPagination as ForgivingComponentType<RaProps>;

Pagination.displayName = 'Pagination';

export default Pagination;
