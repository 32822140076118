import { GetPanelStatusApiData } from '../../types';

const panelstatus: GetPanelStatusApiData[] = [
  {
    id: '484520296778',
    remoteStatus: 'WaitingToStart',
    statusComment: '1000',
    supportNumber: 0,
  },
  {
    id: '484520296779',
    remoteStatus: 'WaitingToStart',
    statusComment: '1000',
    supportNumber: 0,
  },
  {
    id: '847BEB10B0A3',
    remoteStatus: 'WaitingToStart',
    statusComment: '1000',
    supportNumber: 0,
  },
  {
    id: '999999995003',
    remoteStatus: 'UnableToConnect',
    statusComment: '5003',
    supportNumber: 0,
  },
  {
    id: '999999999000',
    remoteStatus: 'Error',
    statusComment: '9000',
    supportNumber: 0,
  },
];
export default panelstatus;
