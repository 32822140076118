import { AdminApiConverter, ApiFilter } from '..';

const converter: AdminApiConverter<'retrofitRegions', 'retrofitRegions'> = {
  resource: 'retrofitRegions',
  defaultSort: { field: 'id', order: 'ASC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'retrofitRegions'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('name' in filter) apiFilter.name = filter.name;
    if ('description' in filter) apiFilter.description = filter.description;
    return apiFilter;
  },
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(record => {
        if (!record.id) throw new Error("Can't get id from API");
        return {
          id: record.id,
          name: record.name,
          description: record.description,
        };
      }),
    ),
  recordToApi: record => ({
    name: record.name,
    description: record.description,
  }),
};

export default converter;
