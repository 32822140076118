import { RetrofitToposurveysData } from '../../types';

const retrofitToposurveys: RetrofitToposurveysData[] = [
  {
    id: '6_2020-02-01T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    surveyDate: '2020-02-01T08:00:01.000Z',
    projectName: '東京IOTセンタ',
    layerName: 'ALL',
    pointName: 'pointName',
    surveyPoint: 'surveyPoint',
    n: 101,
    e: 201,
    z: 301,
  },
  {
    id: '6_2020-01-31T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    surveyDate: '2020-11-30T08:00:01.000Z',
    projectName: '000',
    layerName: '000',
    pointName: '000',
    surveyPoint: '000',
    n: 102,
    e: 202,
    z: 302,
  },
  {
    id: '6_2020-02-02T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    surveyDate: '2020-02-01T08:00:01.000Z',
    projectName: '東京IOTセンタ03',
    layerName: 'ALL03',
    pointName: 'pointName03',
    surveyPoint: 'surveyPoint03',
    n: 103,
    e: 203,
    z: 303,
  },
  {
    id: '6_2020-02-03T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    surveyDate: '2020-02-01T08:00:01.000Z',
    projectName: '東京IOTセンタ04',
    layerName: 'ALL04',
    pointName: 'pointName04',
    surveyPoint: 'surveyPoint04',
    n: 104,
    e: 204,
    z: 304,
  },
  {
    id: '6_2020-02-04T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    surveyDate: '2020-02-01T08:00:01.000Z',
    projectName: '東京IOTセンタ05',
    layerName: 'ALL05',
    pointName: 'pointName05',
    surveyPoint: 'surveyPoint05',
    n: 105,
    e: 205,
    z: 305,
  },
  {
    id: '6_2020-02-05T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    surveyDate: '2020-02-01T08:00:01.000Z',
    projectName: '東京IOTセンタ06_zero',
    layerName: '0',
    pointName: '0',
    surveyPoint: '0',
    n: 0,
    e: 0,
    z: 0,
  },
  {
    id: '6_2020-02-06T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    surveyDate: '2020-02-01T08:00:01.000Z',
    projectName: '東京IOTセンタ07',
    layerName: 'ALL07',
    pointName: 'pointName07',
    surveyPoint: 'surveyPoint07',
    n: 107,
    e: 207,
    z: 307,
  },
  {
    id: '6_2020-02-07T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    surveyDate: '2020-02-01T08:00:01.000Z',
    projectName: '東京IOTセンタ08',
    layerName: 'ALL08',
    pointName: 'pointName08',
    surveyPoint: 'surveyPoint08',
    n: 108,
    e: 208,
    z: 308,
  },
  {
    id: '6_2020-02-08T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    surveyDate: '2020-02-01T08:00:01.000Z',
    projectName: '東京IOTセンタ09',
    layerName: 'ALL09',
    pointName: 'pointName09',
    surveyPoint: 'surveyPoint09',
    n: 109,
    e: 209,
    z: 309,
  },
  {
    id: '6_2020-02-09T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    surveyDate: '2020-02-01T08:00:01.000Z',
    projectName: '東京IOTセンタ10',
    layerName: 'ALL10',
    pointName: 'pointName10',
    surveyPoint: 'surveyPoint10',
    n: 110,
    e: 210,
    z: 310,
  },
  {
    id: '6_2020-02-10T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    surveyDate: '2020-02-01T08:00:01.000Z',
    projectName: '東京IOTセンタ11',
    layerName: 'ALL11',
    pointName: 'pointName11',
    surveyPoint: 'surveyPoint11',
    n: 111,
    e: 211,
    z: 311,
  },
  {
    id: '7_2020-01-31T08:00:02.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    unitLength: 'M',
    surveyDate: '2019-12-22T08:00:01.000Z',
    projectName: '東京IOTセンタ',
    layerName: 'ALL',
    pointName: 'pointName',
    surveyPoint: 'surveyPoint',
    n: 100,
    e: 200,
    z: 300,
  },
];

export default retrofitToposurveys;
