import React, { FC, ReactElement } from 'react';
import { Identifier } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import { useJumpTo } from '../../../hooks';
import { Button } from '../..';
import { colors } from '../../../theme';
import { buttonWidth, buttonHeight } from '../../../theme/define';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: colors.button.cancel,
      color: colors.text.primaryLight, // テキスト表示するので必要
      fontSize: 14, // テキスト表示するので必要
      textTransform: 'none', // テキスト表示するので必要
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
      },
      // テキスト表示するので必要
      '& span': {
        padding: 0,
      },
    },
  }),
);

interface Props {
  label: string;
  name: string;
  id: Identifier;
  tabId?: Identifier;
  children?: ReactElement;
}

const GotoRegistrationButton: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { gotoInnerListRegistration } = useJumpTo();
  const { children, label, name, id, tabId } = props;
  return (
    <Button
      className={classes.root}
      label={label}
      data-testid={label}
      onClick={() => {
        gotoInnerListRegistration(name, id, tabId);
      }}
    >
      {children}
    </Button>
  );
};

GotoRegistrationButton.displayName = 'GotoRegistrationButton';
export default GotoRegistrationButton;
