import React from 'react';
import {
  Dimension,
  GeofenceTrigger,
  GeofenceType,
  UnitLength,
} from '../../../../../dataProvider';
import { MapProps, crearteOverlayControl } from '../../../../../hooks';
import GeofenceDetailInput, { PopupData } from '../../view/GeofenceDetailInput';

const addDetailInputControl = (
  { map }: MapProps,
  data: PopupData,
  unitLength: UnitLength,
  settingType: GeofenceType | undefined,
  settingDimension: Dimension | undefined,
  onChangeElevation: (event: any) => void,
  defaultElevation: number | undefined,
  onChangeHeight: (event: any) => void,
  defaultHeight: number | undefined,
  onChangeRadius: (event: any) => void,
  defaultRadius: number | undefined,
  onChangeThickness: (event: any) => void,
  defaultThickness: number | undefined,
  onChangeTrigger: (event: any) => void,
  defaultTrigger: GeofenceTrigger | undefined,
  labelInfo?: any,
) => {
  map.addControl(
    crearteOverlayControl(
      'map_detailInput',
      <GeofenceDetailInput
        data={data}
        labelInfo={labelInfo}
        unitLength={unitLength}
        settingType={settingType}
        settingDimension={settingDimension}
        onChangeElevation={onChangeElevation}
        defaultElevation={defaultElevation}
        onChangeHeight={onChangeHeight}
        defaultHeight={defaultHeight}
        onChangeRadius={onChangeRadius}
        defaultRadius={defaultRadius}
        onChangeThickness={onChangeThickness}
        defaultThickness={defaultThickness}
        onChangeTrigger={onChangeTrigger}
        defaultTrigger={defaultTrigger}
      />,
      {
        right: 8,
        top: 12,
      },
    ),
  );
};

export default addDetailInputControl;
