import React from 'react';
import { Tooltip } from '@material-ui/core';
import { ReactElementLike } from 'prop-types';
import { useTranslate } from 'react-admin';
import { IconButton } from '..';

export interface Props {
  onClick: () => void;
  children: ReactElementLike;
  tooltip: string;
}

const TooltipIconButton: React.FC<Props> = (props: Props) => {
  const { onClick, children, tooltip } = props;
  const translate = useTranslate();
  return (
    <IconButton onClick={onClick}>
      <Tooltip title={translate(tooltip)}>{children}</Tooltip>
    </IconButton>
  );
};

TooltipIconButton.displayName = 'TooltipIconButton';
export default TooltipIconButton;
