const getRetrofitInfoBreadcrumbs = (
  machineName: string,
  siteName: string | undefined,
): string[] => {
  const breadcrumbs = siteName
    ? `${'resources.sites.name'}${','}${','}${siteName}${','}${'resources.retrofits.name'}${','}${machineName}`
    : `${'resources.retrofits.name'}${','}${machineName}`;
  return breadcrumbs.split(',');
};

export default getRetrofitInfoBreadcrumbs;
