import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CustomList,
  Datagrid,
  FunctionField,
  LinkButton,
  CustomDateField,
  ListDownloadButton,
  TextField,
  DeleteFromSiteButton,
  ToggleAddSiteButton,
  ExtensionarmTypeField,
} from '../../..';
import { useResource } from '../../../../hooks';
import { ExtensionarmData } from '../../../../dataProvider';
import { AddIcon } from '../../../../assets';

const CreateButton: React.FC<{
  basePath?: string;
}> = ({ basePath }) => (
  <LinkButton
    path={`${basePath}/create`}
    label="admin.actions.creationInstructions"
  >
    <AddIcon />
  </LinkButton>
);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 16,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 80,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 'auto',
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 200,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 112,
          },
        },
      },
    },
  }),
);

const SiteExtensionarmList: FC = props => {
  const location = useLocation();
  const resource = useResource('extensionarms');
  const classes = useStyles();
  return (
    <FunctionField
      {...props}
      resource="sites"
      addLabel={false}
      render={record =>
        record ? (
          <CustomList
            breadcrumbs={['resources.sites.name']}
            resource={resource}
            controllerResource={`${resource}_${record.id}`}
            basePath={location.pathname}
            location={location}
            filter={{ siteId: record.id }}
            actions={
              <ToggleAddSiteButton {...props}>
                <CreateButton />
              </ToggleAddSiteButton>
            }
            bulkActionButtons={<DeleteFromSiteButton />}
            hasCreate
          >
            <Datagrid className={classes.root}>
              <ExtensionarmTypeField
                resource="extensionarms"
                source="extensionarmType"
              />
              <TextField resource="extensionarms" source="name" />
              <CustomDateField
                resource="extensionarms"
                source="lastUpdated"
                showTime
              />
              <FunctionField
                resource={resource}
                source="extensionarmFile"
                sortable={false}
                label=""
                render={extensionarmRecord => {
                  const data = extensionarmRecord as ExtensionarmData;
                  const url = data.extensionarmFile?.src || '';
                  return (
                    <ListDownloadButton url={url} label="extensionarmFile" />
                  );
                }}
              />
            </Datagrid>
          </CustomList>
        ) : null
      }
    />
  );
};

SiteExtensionarmList.displayName = 'SiteExtensionarmList';
export default SiteExtensionarmList;
