import React, { FC, ChangeEvent, useEffect, useState } from 'react';
import { SelectInput } from 'react-admin';
import { ProjectsData, ProjectSourceLayerData } from '../../../../dataProvider';
import { findRecordById } from '../../../../utils';
import { geofenceStore } from '../utils/index';

interface Props {
  projectsData: ProjectsData;
  onChangeLayer?: (project: ProjectSourceLayerData) => void;
}

const GeofenceListProjectLayerInput: FC<Props> = ({
  projectsData,
  onChangeLayer,
}) => {
  const [fetched, setFetched] = useState(false);
  const [defaultLayerId, setDefaultLayerId] = useState<number>();
  useEffect(() => {
    if (fetched) return; // NOTE: 再取得防止
    setFetched(true);
    // ローカルストレージのジオフェンスプロジェクト選択情報を取得
    const storedGeofenceProject = geofenceStore.get();
    // プロジェクトソースレイヤ一覧の中にローカルストレージのジオフェンスプロジェクト情報と一致するものがあれば、初期選択状態に設定する
    const projectSourceLayer = projectsData.projectSourceLayers.find(
      l =>
        l.mapServerProjectSourceLayerId ===
        storedGeofenceProject?.projectSourceLayerId,
    );
    setDefaultLayerId(projectSourceLayer?.id);
    if (onChangeLayer && projectSourceLayer) {
      onChangeLayer(projectSourceLayer);
    }
  }, [fetched, onChangeLayer, projectsData]);

  const handleChange = ({
    target: { value: projectLayerId },
  }: ChangeEvent<HTMLInputElement>) => {
    if (!onChangeLayer) return;
    if (!projectsData) return;
    if (!projectsData.projectSourceLayers) return;
    onChangeLayer(
      findRecordById<ProjectSourceLayerData>(
        projectsData.projectSourceLayers,
        projectLayerId,
      ),
    );
  };

  return (
    <div>
      <SelectInput
        record={{ id: 1 }}
        source="layerId"
        optionText="name"
        label="admin.label.geofences.projectSourceLayer"
        resettable={false}
        choices={projectsData.projectSourceLayers
          .map(({ id, name }) => ({
            id,
            name,
          }))
          .sort((lhs, rhs) => {
            return lhs.name > rhs.name ? 1 : -1;
          })}
        onChange={handleChange}
        style={{ boxSizing: 'border-box' }}
        defaultValue={defaultLayerId}
      />
      <div style={{ height: 8 }} />
    </div>
  );
};

GeofenceListProjectLayerInput.displayName = 'GeofenceListProjectLayerInput';
export default GeofenceListProjectLayerInput;
