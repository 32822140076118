import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { SiteConfigureData, SiteData, UnitLength } from '../../dataProvider';

interface InParams {
  siteId?: string;
  isRetrofitUser?: boolean;
}

interface OutParams {
  unitLength: UnitLength;
}

const useGetUnitLengthCallback = () => {
  const dataProvider = useDataProvider();

  return useCallback(
    async ({ siteId, isRetrofitUser = true }: InParams): Promise<OutParams> => {
      if (!siteId) return { unitLength: 'M' };

      // 現場設定を取得
      const { data: siteConfigures } =
        await dataProvider.getList<SiteConfigureData>('siteConfigures', {
          pagination: {
            page: 1,
            perPage: 1,
          },
          sort: { field: 'id', order: 'ASC' },
          filter: {
            siteId,
          },
        });
      if (siteConfigures.length > 0) {
        const { unitLength: siteConfigureUnitLength } = siteConfigures[0];
        return { unitLength: siteConfigureUnitLength };
      }
      if (!isRetrofitUser) return { unitLength: 'M' };

      // 現場を取得
      const {
        data: { unitLength: siteUnitLength },
      } = await dataProvider.getOne<SiteData>('sites', {
        id: siteId,
      });
      return { unitLength: siteUnitLength };
    },
    [dataProvider],
  );
};
export default useGetUnitLengthCallback;
