import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
// TODO: basesのDateFieldでは型エラーが出る
import { DateField, Record } from 'react-admin';

const useStyles = makeStyles(() =>
  createStyles({
    text: { color: '#FFFFFF80' },
  }),
);

interface Props {
  resource: string;
  source: string;
  record: Record;
  showTime: boolean | undefined;
  sortable: boolean | undefined;
  addLabel: boolean;
  label: string | undefined;
  useUtc: boolean;
  className: string | undefined;
}

const CustomDateField: React.FC<Partial<Props>> = ({
  resource,
  source,
  record,
  showTime,
  sortable,
  useUtc = false,
  label,
  className,
}) => {
  const classes = useStyles();
  if (!resource || !source || !record || !record[source]) {
    return <span className={classes.text}></span>;
  }
  return (
    <DateField
      record={record}
      resource={resource}
      source={source}
      showTime={showTime}
      sortable={sortable}
      label={label}
      options={useUtc ? { timeZone: 'UTC' } : {}}
      className={className}
    />
  );
};

CustomDateField.defaultProps = {
  addLabel: true,
};
CustomDateField.displayName = 'CustomDateField';
export default CustomDateField;
