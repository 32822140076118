import { createStyles, withStyles } from '@material-ui/core';
import { ReactElementLike, ReactNodeLike } from 'prop-types';
import React from 'react';
import { ReactAdminComponentPropsWithId, Show as RaShow } from 'react-admin';

interface RaProps {
  actions: ReactElementLike;
  aside: ReactElementLike;
  basePath: string;
  children: ReactElementLike;
  classes: object;
  className: string;
  defaultTitle: any;
  hasEdit: boolean;
  hasList: boolean;
  loading: boolean;
  loaded: boolean;
  record: object;
  resource: string;
  title: any;
  version: ReactNodeLike;
}

const Show = withStyles(
  createStyles({
    // TODO: Showコンポーネントのcomponent propに何か渡すこととで対処できないか検討する
    root: {
      '& > div': {
        marginTop: 12,
      },
      '& .MuiCard-root': {
        // 内部のテーブルをsticky表示するために必要
        overflow: 'inherit',
      },
      '& .MuiCardContent-root': {
        padding: 0,
      },
    },
    noActions: {
      marginTop: '12px',
    },
  }),
)(RaShow) as ForgivingComponentType<RaProps & ReactAdminComponentPropsWithId>;

const { defaultProps } = Show;
Show.defaultProps = {
  ...defaultProps,
  actions: <></>,
};

Show.displayName = 'Show';
export default Show;
