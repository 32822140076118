import React, { FC } from 'react';
import { makeStyles, createStyles, Dialog } from '@material-ui/core';
import Content from '@material-ui/core/DialogContent';
import Actions from '@material-ui/core/DialogActions';
import Layout, { LayoutProps } from './Layout';
import { ReturnIcon, OkIcon } from '../../../../assets';
import { DoneButton, NormalButton } from '../../..';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiDialogContent-root:first-child': {
        paddingTop: 30,
      },
      '& .MuiDialogContent-root': {
        padding: undefined,
        paddingLeft: 36,
        paddingRight: 36,
        paddingBottom: 24,
      },
      '& .MuiDialogActions-spacing > :not(:first-child)': {
        marginLeft: 10,
      },
      '& .MuiDialog-paperWidthMd': {
        maxWidth: 792,
      },
    },
    footer: {
      margin: 0,
      paddingTop: 0,
      paddingBottom: 30,
      paddingRight: 36,
      paddingLeft: 36,
    },
  }),
);

interface Props {
  open: boolean;
  onCancel?: () => void;
  onOk: () => void;
  layoutProps: LayoutProps;
}

const LicenseDialog: FC<Props> = ({ open, onCancel, onOk, layoutProps }) => {
  const classes = useStyles();
  // title（ダイアログ用メッセージ）が空の場合はコンポーネントを表示しない（2重に表示されてしまう不具合対応）
  return layoutProps.title === '' ? null : (
    <Dialog
      open={open}
      maxWidth={'md'}
      fullWidth={true}
      className={classes.root}
    >
      <Content>
        <Layout {...layoutProps} />
      </Content>
      <Actions className={classes.footer}>
        {onCancel && (
          <NormalButton onClick={onCancel} label="admin.actions.cancel">
            <ReturnIcon />
          </NormalButton>
        )}
        <DoneButton onClick={onOk} label="admin.actions.ok">
          <OkIcon />
        </DoneButton>
      </Actions>
    </Dialog>
  );
};

LicenseDialog.displayName = 'LicenseDialog';
export default LicenseDialog;
