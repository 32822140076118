import React from 'react';

const TabContent: React.FC<{
  children?: React.ReactNode;
  name: string;
  value: string;
}> = ({ children, name, value }) => {
  return name === value ? (
    <div style={{ marginTop: 8 }}>{children}</div>
  ) : (
    <></>
  );
};

export type TabData = {
  name: string;
  tab: React.ReactElement;
  content: React.ReactElement;
  order: number;
};

TabContent.displayName = 'TabbedShowLayout';
export default TabContent;
