import { DataProvider } from 'react-admin';
import createRetrofitNamePlate from './createRetrofitNamePlate';
import { getRestDataProvider } from '..';

export default (): DataProvider => {
  return {
    getList: () => {
      throw new Error('Not supported for retrofitNamePlate');
    },
    getOne: () => {
      throw new Error('Not supported for retrofitNamePlate');
    },
    getMany: () => {
      throw new Error('Not supported for retrofitNamePlate');
    },
    getManyReference: () => {
      throw new Error('Not supported for retrofitNamePlate');
    },
    update: () => {
      throw new Error('Not supported for retrofitNamePlate');
    },
    updateMany: () => {
      throw new Error('Not supported for retrofitNamePlate');
    },
    create: (_, params): any => {
      return createRetrofitNamePlate(getRestDataProvider(), params);
    },
    delete: () => {
      throw new Error('Not supported for retrofitNamePlate');
    },
    deleteMany: () => {
      throw new Error('Not supported for retrofitNamePlate');
    },
  };
};
