import React, { FC, useCallback, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import { useRedirect, useTranslate } from 'react-admin';
import {
  SimpleShowLayout,
  TabData,
  TabContent,
  Divider,
  Loading,
  BreadcrumbsFiled,
  DialogOKOnlyWithUrl,
} from '../..';
import { styles } from '../../../theme';
import {
  RetrofitAlternateRegistCreatePhoto,
  RetrofitAlternateRegistCreateText,
} from '.';
import {
  useCheckSiteAttachment,
  useCustomLocation,
  useGetAccount,
  usePendingTransferred,
  useRetrofitLending,
  useRetrofitSerialNumber,
} from '../../../hooks';
import { config, toSerialNumberText } from '../../../utils';
import { RetrofitData } from '../../../dataProvider/types';
import {
  useCheckDoubleRegistration,
  useCheckUnusedNtipDB,
  useIncludesOwnerCorporationId,
} from './hooks';
import useGetRetrofitOwnerRegistCorporations from './hooks/useGetRetrofitOwnerRegistCorporations';

const useStyles = makeStyles(() =>
  createStyles({
    simpleShowLayout: {
      ...styles.zeroPaddingHorizontal,
    },
    breadcrumbs: {
      marginBottom: 12,
    },
  }),
);

const useCreateAlternateRegistTabs = (props: any): TabData[] => {
  const translate = useTranslate();
  const tabData: TabData[] = [];
  tabData.push({
    name: 'photo/create',
    tab: (
      <MuiTab
        label={translate('admin.pages.retrofitAlternateRegistCreatePhoto')}
        key="tab_photo"
        value="photo/create"
      />
    ),
    content: <RetrofitAlternateRegistCreatePhoto {...props} />,
    order: 1,
  });

  tabData.push({
    name: 'text/create',
    tab: (
      <MuiTab
        label={translate('admin.pages.retrofitAlternateRegistCreateText')}
        key="tab_text"
        value="text/create"
      />
    ),
    content: <RetrofitAlternateRegistCreateText {...props} />,
    order: 2,
  });
  return tabData.sort((lhs, rhs) => (lhs.order > rhs.order ? 1 : -1));
};

const RetrofitAlternateRegistTab: FC = props => {
  const uri = '/retrofitAlternateRegists';
  const resource = 'retrofitAlternateRegists';
  const scSupportRequestUrl = config.url.scSupportRequest; // エラーダイアログ用URL
  const classes = useStyles();
  const { getValue, getBasePath } = useCustomLocation(uri);
  const redirectTo = useRedirect();
  const basePath = getBasePath();
  const handleChange = (_event: any, newValue: string) => {
    redirectTo(`${basePath}/${newValue}`);
  };

  const { isLoading } = useGetAccount();
  const loading = isLoading();

  const [errorDialog, setErrorDialog] = useState<{
    open: boolean;
    messages: string[];
    subMessages?: string[];
  }>({ open: false, messages: [], subMessages: [] });
  const translate = useTranslate();

  // 紐付け申請の対象コントローラが『EARTHBRAIN』の企業IDに紐づいているとき
  const handleCheckEarthbrainCorporationId = useCallback(
    async (retrofits: RetrofitData[]) => {
      // EARTHBRAIN企業ID取得
      const { corporationId } = config.corporation.earthbrain;

      // 登録対象の建機のうち、チェック対象の建機のみ選定
      const targetRetrofits = retrofits.filter(
        it => it.corporationId === corporationId,
      );
      const serialNumbers = targetRetrofits.map(it => it.basicInfoSerialNumber);
      const serialNumberText = toSerialNumberText(serialNumbers);
      if (targetRetrofits.length === 0) {
        return true;
      }

      // エラーダイアログを表示
      setErrorDialog({
        open: true,
        messages: [
          translate(
            'admin.dialog.retrofitAlternateRegists.earthbrainIdCheck.message1',
          ),
          translate(
            'admin.dialog.retrofitAlternateRegists.earthbrainIdCheck.message2',
          ),
          translate(
            'admin.dialog.retrofitAlternateRegists.earthbrainIdCheck.message3',
          ),
          translate(
            'admin.dialog.retrofitAlternateRegists.earthbrainIdCheck.message4',
          ),
          translate(
            'admin.dialog.retrofitAlternateRegists.earthbrainIdCheck.message5',
          ),
        ],
        subMessages: [
          translate(
            'admin.dialog.retrofitAlternateRegists.serialnumberCheck.message',
          ),
          serialNumberText,
        ],
      });

      return false;
    },
    [translate],
  );

  const checkSiteAttachment = useCheckSiteAttachment();
  const retrofitLending = useRetrofitLending();
  const retrofitSerialNumber = useRetrofitSerialNumber();

  const handleCheckSiteAttachment = useCallback(
    async (retrofitIds: number[]): Promise<boolean> => {
      // 現場の紐づきチェック
      const {
        data: { isAttachedToSite, attachedRetrofitIds },
      } = await checkSiteAttachment({ retrofitIds });

      if (!isAttachedToSite) {
        return true; // 現場に紐づいている建機はリスト内にない
      }

      // シリアル番号を取得
      const {
        data: { serialNumbers },
      } = await retrofitSerialNumber({
        retrofitIds: attachedRetrofitIds,
      });
      const serialNumberText = toSerialNumberText(serialNumbers);

      // エラーダイアログを表示
      setErrorDialog({
        open: true,
        messages: [
          translate(
            'admin.dialog.retrofitAlternateRegists.siteAttached.message1',
          ),
          translate(
            'admin.dialog.retrofitAlternateRegists.siteAttached.message2',
          ),
          translate(
            'admin.dialog.retrofitAlternateRegists.siteAttached.message3',
          ),
        ],
        subMessages: [
          translate(
            'admin.dialog.retrofitAlternateRegists.serialnumberCheck.message',
          ),
          serialNumberText,
        ],
      });
      return false;
    },
    [checkSiteAttachment, retrofitSerialNumber, translate],
  );

  const handleCheckLending = useCallback(
    async (retrofitIds: number[]): Promise<boolean> => {
      // 貸与チェック
      const {
        data: { isLending, rentalRetrofitIds },
      } = await retrofitLending({ retrofitIds });

      if (!isLending) {
        return true; // 未貸与のレトロフィットである
      }

      // シリアル番号を取得
      const {
        data: { serialNumbers },
      } = await retrofitSerialNumber({
        retrofitIds: rentalRetrofitIds,
      });
      const serialNumberText = toSerialNumberText(serialNumbers);

      // エラーダイアログを表示
      setErrorDialog({
        open: true,
        messages: [
          translate(
            'admin.dialog.retrofitAlternateRegists.lendingRetrofits.message1',
          ),
          translate(
            'admin.dialog.retrofitAlternateRegists.lendingRetrofits.message2',
          ),
          translate(
            'admin.dialog.retrofitAlternateRegists.lendingRetrofits.message3',
          ),
        ],
        subMessages: [
          translate(
            'admin.dialog.retrofitAlternateRegists.serialnumberCheck.message',
          ),
          serialNumberText,
        ],
      });
      return false;
    },
    [retrofitLending, retrofitSerialNumber, translate],
  );

  const getOwnerCorporationIdList = useGetRetrofitOwnerRegistCorporations();
  const checkIncludesOwnerCorporationId = useIncludesOwnerCorporationId();

  const handleCheckCorporation = useCallback(
    async (retrofits: RetrofitData[]) => {
      const ownerCorporationIds = await getOwnerCorporationIdList();
      const ownerIds = retrofits.map(({ ownerId }) => ownerId);
      // レトロフィット利用中企業登録可能企業であることをチェック
      const {
        data: { allIncluded, serialNumbers },
      } = await checkIncludesOwnerCorporationId({
        ownerIds,
        ownerCorporationIds,
        retrofits,
      });
      const serialNumberText = toSerialNumberText(serialNumbers);

      if (!allIncluded) {
        // エラーダイアログを表示
        setErrorDialog({
          open: true,
          messages: [
            translate(
              'admin.dialog.retrofitAlternateRegists.invalidSerialNumber.message1',
            ),
            translate(
              'admin.dialog.retrofitAlternateRegists.invalidSerialNumber.message2',
            ),
            translate(
              'admin.dialog.retrofitAlternateRegists.invalidSerialNumber.message3',
            ),
            translate(
              'admin.dialog.retrofitAlternateRegists.invalidSerialNumber.message4',
            ),
            translate(
              'admin.dialog.retrofitAlternateRegists.invalidSerialNumber.message5',
            ),
            translate(
              'admin.dialog.retrofitAlternateRegists.invalidSerialNumber.message6',
            ),
            translate(
              'admin.dialog.retrofitAlternateRegists.invalidSerialNumber.message7',
            ),
          ],
          subMessages: [
            translate(
              'admin.dialog.retrofitAlternateRegists.serialnumberCheck.message',
            ),
            serialNumberText,
          ],
        });
      }
      return allIncluded;
    },
    [getOwnerCorporationIdList, checkIncludesOwnerCorporationId, translate],
  );

  const checkDoubleRegistration = useCheckDoubleRegistration();

  // 既に申請済み
  const handleCheckDoubleRegistration = useCallback(
    async (
      retrofits: RetrofitData[],
      retrofitIds: number[],
      corporationId: string,
    ) => {
      // 既に代理申請で登録されていないかチェック
      const {
        data: { isRegistrated, serialNumbers },
      } = await checkDoubleRegistration({
        retrofitIds,
        corporationId,
        retrofits,
      });
      const serialNumberText = toSerialNumberText(serialNumbers);

      if (isRegistrated) {
        // エラーダイアログを表示
        setErrorDialog({
          open: true,
          messages: [
            translate(
              'admin.dialog.retrofitAlternateRegists.pendingApprovals.message1',
            ),
            translate(
              'admin.dialog.retrofitAlternateRegists.pendingApprovals.message2',
            ),
          ],
          subMessages: [
            translate(
              'admin.dialog.retrofitAlternateRegists.serialnumberCheck.message',
            ),
            serialNumberText,
          ],
        });
      }
      return isRegistrated;
    },
    [checkDoubleRegistration, translate],
  );

  const checkPendingTransfer = usePendingTransferred();
  const handleCheckPendingTransfer = useCallback(
    async (retrofitIds: number[]): Promise<boolean> => {
      // 譲渡承認待ちチェック
      const {
        data: { isPendingTransfer, pendingRetrofitIds },
      } = await checkPendingTransfer({ retrofitIds });

      if (!isPendingTransfer) {
        return true; // 未譲渡のレトロフィットである
      }

      // シリアル番号を取得
      const {
        data: { serialNumbers },
      } = await retrofitSerialNumber({
        retrofitIds: pendingRetrofitIds,
      });
      const serialNumberText = toSerialNumberText(serialNumbers);

      // エラーダイアログを表示
      setErrorDialog({
        open: true,
        messages: [
          translate(
            'admin.dialog.retrofitAlternateRegists.pendingApprovals.message1',
          ),
          translate(
            'admin.dialog.retrofitAlternateRegists.pendingApprovals.message2',
          ),
        ],
        subMessages: [
          translate(
            'admin.dialog.retrofitAlternateRegists.serialnumberCheck.message',
          ),
          serialNumberText,
        ],
      });
      return false;
    },
    [checkPendingTransfer, retrofitSerialNumber, translate],
  );

  const checkUnusedNtipDB = useCheckUnusedNtipDB();
  const handleCheckUnusedNtipDB = useCallback(
    async (retrofits: RetrofitData[]) => {
      if (!config.feature.ntrips) return true; // REACT_APP_FEATURE_NTRIPSが削除されたら合わせて削除する

      const {
        data: { ntripDBShortage },
      } = await checkUnusedNtipDB({ retrofits });

      if (ntripDBShortage) {
        setErrorDialog({
          open: true,
          messages: [
            translate(
              'admin.dialog.retrofitAlternateRegists.ntripShortage.message1',
            ),
            translate(
              'admin.dialog.retrofitAlternateRegists.ntripShortage.message2',
            ),
          ],
        });
      }
      return !ntripDBShortage;
    },
    [checkUnusedNtipDB, translate],
  );
  const tabs = useCreateAlternateRegistTabs({
    ...props,
    ...{
      basePath,
      resource,
      checkEarthbrainCorporationId: handleCheckEarthbrainCorporationId,
      checkSiteAttachment: handleCheckSiteAttachment,
      checkLending: handleCheckLending,
      checkCorporation: handleCheckCorporation,
      checkDoubleRegistration: handleCheckDoubleRegistration,
      checkPendingTransfer: handleCheckPendingTransfer,
      checkUnusedNtipDB: handleCheckUnusedNtipDB,
    },
  });
  const [firstTab] = tabs;
  const currentTab = getValue() || firstTab.name;

  return (
    <>
      <SimpleShowLayout className={classes.simpleShowLayout} {...props}>
        <BreadcrumbsFiled
          breadcrumbs={['resources.retrofitAlternateRegists.name']}
          label="admin.breadcrumbs.retrofitAlternateRegistCreate"
        />
        <MuiTabs value={currentTab} onChange={handleChange}>
          {tabs.map(({ tab }) => tab)};
        </MuiTabs>
        <Divider />
        {tabs.map(({ name, content }) => {
          return (
            <TabContent name={name} key={name} value={currentTab}>
              {!loading ? content : <Loading />}
            </TabContent>
          );
        })}
      </SimpleShowLayout>
      <DialogOKOnlyWithUrl
        open={errorDialog.open}
        onClose={() => {
          setErrorDialog({
            ...errorDialog,
            open: false,
          });
        }}
        title=""
        nonTranslateMessage={true}
        messages={errorDialog.messages}
        url={scSupportRequestUrl}
        subMessages={errorDialog.subMessages}
      />
    </>
  );
};

RetrofitAlternateRegistTab.displayName = 'RetrofitAlternateRegistTab';
export default RetrofitAlternateRegistTab;
