import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { SimpleShowLayout, ContentHeader } from '..';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 9,
      background: '#31323A',
      border: '1px solid #E10009',
      borderRadius: 3,
    },
  }),
);

const ErrorInfoLayout: React.FC<{ title?: string }> = ({
  title,
  children,
  ...props
}) => {
  const classes = useStyles();
  return (
    <SimpleShowLayout {...props} className={classes.root}>
      <ContentHeader title={title} />
      <div style={{ height: 4 }}></div>
      {children}
    </SimpleShowLayout>
  );
};

ErrorInfoLayout.displayName = 'ErrorInfoLayout';
export default ErrorInfoLayout;
