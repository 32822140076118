import { useGetList } from 'react-admin';
import { RetrofitRentalData } from '../../../../dataProvider';
import { objectToList } from '../../../../utils';

interface InParams {
  retrofitId: number;
}

interface OutParams {
  loading: boolean;
  data: {
    isLending: boolean;
    firstRentalItem?: RetrofitRentalData;
  };
}

const useGetRetrofitLending = ({ retrofitId }: InParams): OutParams => {
  const { data: keyValueData, loading } = useGetList<RetrofitRentalData>(
    'retrofitRentals',
    {
      page: 1,
      perPage: 1,
    },
    { field: 'id', order: 'DESC' },
    {
      retrofitId,
      isReturned: false,
    },
  );

  const data = objectToList(keyValueData) as RetrofitRentalData[];

  const isLending = data.length > 0;
  const firstRentalItem = isLending ? data[0] : undefined;

  return {
    loading,
    data: {
      isLending,
      firstRentalItem,
    },
  };
};

export default useGetRetrofitLending;
