import { AdminApiConverter, ApiFilter } from '..';

const converter: AdminApiConverter<'geoids', 'geoids'> = {
  resource: 'geoids',
  defaultSort: { field: 'id', order: 'ASC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'geoids'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('name' in filter) apiFilter.name = filter.name;
    apiFilter.regionId = filter.regionId || 0;
    return apiFilter;
  },
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(record => ({
        id: record.id || 0,
        name: record.name || '',
        regionId: record.regionId || 0,
      })),
    ),
  recordToApi: record => ({
    name: record.name,
    interpolationMethod: '',
  }),
};

export default converter;
