import { DatagridRow as RaDatagridRow } from 'react-admin';
import { TableRowProps as MuiProps } from '@material-ui/core/TableRow';
import { ReactElementLike, ReactComponentLike } from 'prop-types';
import { AnyObject } from '../../../utils/types';

interface RaProps {
  basePath: string;
  children: React.ReactNode;
  classes: AnyObject;
  className: string;
  expand: ReactElementLike | ReactComponentLike;
  hasBulkActions: boolean;
  hover: boolean;
  id: any;
  onToggleItem: Function;
  record: AnyObject;
  resource: string;
  rowClick: string | Function;
  selected: boolean;
  style: AnyObject;
}

const DatagridRow = RaDatagridRow as ForgivingComponentType<MuiProps & RaProps>;
DatagridRow.displayName = 'DatagridRow';

export default DatagridRow;
