import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 150,
      top: -84,
      left: -84,
      position: 'relative',
      display: 'inline-block',
      margin: '1.5em 0',
      padding: 12,
      color: '#000',
      background: '#fff',
      border: '1px solid #cccccc',
      borderRadius: 3,
      '&:before, &:after': {
        content: "''",
        pointerEvents: 'none',
        position: 'absolute',
        top: '100%',
        left: '50%',
        border: 'solid transparent',
      },
      '&:before': {
        borderTopColor: '#cccccc',
        borderWidth: 11,
        marginLeft: -15,
      },
      '&:after': {
        borderTopColor: '#fff',
        borderWidth: 10,
        marginLeft: -14,
      },
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      '& > span': {
        width: 50,
        fontSize: 12,
      },
      '& > div.space': {
        width: 12,
      },
      '& > input': {
        width: 80,
      },
    },
  }),
);
interface Props {
  id: string;
  label: string;
}

const GeofenceMapCoordinatePopup: FC<Props> = ({ id, label }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div id={id} className={classes.root}>
      <div className={classes.content}>
        <span>{translate(label)}</span>
        <div className="space" />
        <input type="number" />
      </div>
    </div>
  );
};

GeofenceMapCoordinatePopup.displayName = 'GeofenceMapCoordinatePopup';
export default GeofenceMapCoordinatePopup;
