import { UserPermissionsData } from '../../types';

const userPermissions: UserPermissionsData[] = [
  {
    id: 1,
    email: 'test_user@example.com',
    userRoles: [
      'CreateRetrofit',
      'SupportMode',
      'GnssSetting',
      'UserNotice',
      'Firmware',
    ],
  },
  {
    id: 2,
    email: 'tsurumi_kazuhiro@qualica.co.jp',
    userRoles: ['CreateRetrofit'],
  },
  {
    id: 3,
    email: 'yuuichi_tamashiro@qualica.co.jp',
    userRoles: ['CreateRetrofit', 'Firmware'],
  },
  {
    id: 4,
    email: 'shingo_nakashima@qualica.co.jp',
    userRoles: ['CreateRetrofit', 'Firmware'],
  },
  {
    id: 5,
    email: 'yukio_aki@qualica.co.jp',
    userRoles: ['CreateRetrofit', 'SupportMode', 'GnssSetting', 'Firmware'],
  },
  {
    id: 6,
    email: 'kazuya.tominaga@cct-inc.co.jp',
    userRoles: [
      'CreateRetrofit',
      'SupportMode',
      'GnssSetting',
      'UserNotice',
      'Firmware',
    ],
  },
];

export default userPermissions;
