/** 汎用 Array Utils */

const mapWithNext = <T>(array: T[], callback: (cur: T, next: T) => any) => {
  const ret = [];
  for (let i = 0; i < array.length - 1; i += 1) {
    ret.push(callback(array[i], array[i + 1]));
  }
  return ret;
};

const notNull = <T>(value: T): value is NonNullable<T> => value != null;

export { mapWithNext, notNull };
