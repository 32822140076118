import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useRedirect } from 'react-admin';
import { colors } from '../../../../theme';
import { buttonWidth, buttonHeight } from '../../../../theme/define';
import { Button } from '../../..';
import { landlogAuthProvider } from '../../../../auth';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: colors.button.cancel,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      color: colors.text.primaryLight,
      fontSize: 14,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
      },
      '& span': {
        padding: 0,
      },
    },
  }),
);

const DenyButton: FC = () => {
  const classes = useStyles();
  const redirect = useRedirect();

  const handleClick = () => {
    landlogAuthProvider.logout({});
    redirect('login');
  };

  return (
    <Button
      className={classes.root}
      label="admin.actions.termsDeny"
      data-testid="admin.actions.termsDeny"
      onClick={handleClick}
    />
  );
};

DenyButton.displayName = 'DenyButton';
export default DenyButton;
