import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useDataProvider, useTranslate, Record } from 'react-admin';
import { useParams } from 'react-router-dom';
import {
  Edit,
  Show,
  CustomForm,
  BreadcrumbsFiled,
  ListApprovalButton,
  TextField,
  CustomDateField,
  Typography,
  FunctionField,
  CustomChildBox,
  CustomParentBox,
  IconField,
  IconFieldPaddingZeroStyle,
  CustomToolbarButtonType,
  Loading,
  LinkButton,
} from '../..';
import { useResource, useEdit, usePageProps } from '../../../hooks';
import { RetrofitRentalData } from '../../../dataProvider/types';
import { ReturnIcon } from '../../../assets';
import { getParentPath } from '../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    machineName: {
      fontSize: 18,
      fontWeight: 'bold',
      paddingTop: 16,
    },
    titleFirst: {
      padding: '12px 0px',
    },
    title: {
      padding: '12px 0px',
      borderTop: '1px solid #F5F5FF33',
    },
    footer: {
      margin: 0,
    },
    form: {
      ...IconFieldPaddingZeroStyle,
    },
  }),
);

const CancelButton: React.FC<{ basePath?: string }> = props => {
  const { basePath = '' } = props;
  const to = getParentPath(basePath);
  return (
    <LinkButton path={to} label="ra.action.cancel">
      <ReturnIcon />
    </LinkButton>
  );
};

const ApprovalEdit: React.FC = () => {
  const [retrofitData, setRetrofitData] = useState<Record>();
  const resource = useResource('pendingRentals');
  const retrofitResource = useResource('retrofits');
  const { id } = useParams<{ id: string }>();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const classes = useStyles();
  const retrofitProps = usePageProps(retrofitResource);
  const { props, data } = useEdit(resource, {
    resource,
    key: 'id',
  });
  const rentalData = data as RetrofitRentalData;
  const { basePath } = props as { basePath: string };

  useEffect(() => {
    if (!rentalData) return;
    const fn = async () => {
      const { data: retrofit } = await dataProvider.getOne(retrofitResource, {
        id: rentalData.retrofitId,
      });
      setRetrofitData(retrofit);
    };
    fn();
  }, [rentalData, setRetrofitData, dataProvider, retrofitResource]);

  if (!rentalData) return <Loading />;
  const showProps = { ...retrofitProps, id: rentalData.retrofitId.toString() };

  return (
    <>
      <Typography className={classes.machineName}>
        {retrofitData?.machineInfoMachineName}
      </Typography>
      <Edit {...props}>
        <CustomForm
          title="admin.label.pendingRentals.rentalDetail"
          actionButton={
            <ListApprovalButton
              resource={resource}
              selectedIds={[Number(id)]}
            />
          }
          saveButton={false}
          deleteButton={false}
          classes={{ footer: classes.footer }}
          cancelButton={<CancelButton basePath={basePath} />}
          buttonOrder={[
            CustomToolbarButtonType.cancel,
            CustomToolbarButtonType.action,
          ]}
        >
          <BreadcrumbsFiled
            breadcrumbs={['resources.pendingRentals.name']}
            label="admin.label.pendingRentals.breadcrumbs"
          />
          <TextField resource={resource} source="fromCorporationName" />
          <CustomDateField resource={resource} source="planStartDate" />
          <CustomDateField resource={resource} source="planEndDate" />
        </CustomForm>
      </Edit>

      <Show {...showProps}>
        <CustomForm
          saveButton={false}
          deleteButton={false}
          cancelButton={false}
          record={retrofitData as RetrofitRentalData}
          className={classes.form}
        >
          <Typography className={classes.title}>
            {translate('admin.label.retrofitEdit.machineryDetails')}
          </Typography>
          <FunctionField
            resource={retrofitResource}
            source="machineInfoMachineType"
            render={record => {
              const res = 'resources.retrofits.machineInfoMachineType';
              const type = record?.machineInfoMachineType.toLowerCase();
              const trans = translate(`${res}.${type}`);
              // 翻訳できなければ、もとの内容をそのまま返す
              if (trans.startsWith(res)) return type;
              return trans;
            }}
          />
          <TextField
            resource={retrofitResource}
            source="machineInfoMachineId"
          />
          <TextField
            resource={retrofitResource}
            source="machineInfoMachineName"
          />
          <TextField
            resource={retrofitResource}
            source="machineInfoCompanyName"
          />
          <Typography className={classes.title}>
            {translate('admin.label.retrofitEdit.retrofitDetails')}
          </Typography>
          <CustomParentBox>
            <CustomChildBox
              title={translate('admin.label.retrofitEdit.basicInfo')}
            >
              <TextField
                resource={retrofitResource}
                source="basicInfoManufacturer"
              />
              <TextField resource={retrofitResource} source="basicInfoModel" />
              <TextField
                resource={retrofitResource}
                source="basicInfoProductNumber"
              />
              <TextField
                resource={retrofitResource}
                source="basicInfoSerialNumber"
              />
            </CustomChildBox>

            <CustomChildBox
              title={translate('admin.label.retrofitEdit.controller')}
            >
              <TextField
                resource={retrofitResource}
                source="controllerManufacturer"
              />
              <TextField resource={retrofitResource} source="controllerModel" />
              <TextField
                resource={retrofitResource}
                source="controllerFirmwareVersion"
              />
            </CustomChildBox>
          </CustomParentBox>
          <CustomParentBox>
            <CustomChildBox
              title={translate('admin.label.retrofitEdit.reciverMain')}
            >
              <TextField
                resource={retrofitResource}
                source="gnssReceiverMainManufacturer"
              />
              <TextField
                resource={retrofitResource}
                source="gnssReceiverMainModel"
              />
              <TextField
                resource={retrofitResource}
                source="gnssReceiverMainFirmwareVersion"
              />
            </CustomChildBox>

            <CustomChildBox
              title={translate('admin.label.retrofitEdit.reciverSub')}
            >
              <TextField
                resource={retrofitResource}
                source="gnssReceiverSubManufacturer"
              />
              <TextField
                resource={retrofitResource}
                source="gnssReceiverSubModel"
              />
              <TextField
                resource={retrofitResource}
                source="gnssReceiverSubFirmwareVersion"
              />
            </CustomChildBox>
          </CustomParentBox>
          <Typography className={classes.title}>
            {translate('admin.label.retrofitEdit.retrofitstatus')}
          </Typography>
          <IconField resource={retrofitResource} source="isOnline" />
          <IconField resource={retrofitResource} source="isError" />
          <CustomDateField
            resource={retrofitResource}
            source="lastOnlineTime"
            showTime
          />
        </CustomForm>
      </Show>
    </>
  );
};

ApprovalEdit.displayName = 'ApprovalEdit';
export default ApprovalEdit;
