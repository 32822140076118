import React, { useCallback, useRef } from 'react';
import {
  TextInput as RaTextInput,
  useTranslate,
  useDataProvider,
  useNotify,
  useRefresh,
  required,
} from 'react-admin';
import { makeStyles, createStyles, Dialog } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { NormalButton, CustomForm, DoneButton } from '..';
import { Resources } from '../../dataProvider';
import { useNameValidation } from '../../hooks';
import { ReturnIcon, SaveIcon } from '../../assets';
import { apiErrorHandler } from '../../utils';

const dialogWidth = 710;

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      fontSize: 18,
      margin: 0,
      padding: 24,
    },
    content: {
      fontSize: 18,
      width: dialogWidth,
      height: 260 - 80, // 80: header
      '& button': {
        position: 'relative',
        bottom: -26,
      },
    },
  }),
);

interface Input {
  isNameValidation: boolean;
  fields: string[]; // InputField の項目名
  defaultValues?: { [key: string]: string }; // InputField 外の項目値
  record?: any; // 更新の場合設定する
}

export type { Input as CompactionInputDialogInput };

interface FormProps {
  disabled: boolean;
  form: any;
}

const SaveButton: React.FC<
  {
    resource: string;
    label: string;
    input: Input;
    onSaved: () => void;
  } & Partial<FormProps>
> = ({ disabled, form, resource, label, input, onSaved }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleSave = useCallback(() => {
    const { fields, defaultValues, record } = input;
    const isUpdate = !!record;
    const fieldKeyValues = fields.map(name => {
      return { [name]: form.getFieldState(name).value };
    });
    // Array to Object
    const updateValues = fieldKeyValues.reduce((prev, current) => {
      return { ...prev, ...current };
    }, {});
    const data = {
      ...updateValues,
      ...defaultValues,
    };
    // API呼び出し処理
    const fn = async () => {
      try {
        if (isUpdate) {
          await dataProvider.update(resource, {
            id: record.id,
            data: {
              ...record,
              ...data,
            },
            previousData: record,
          });
        } else {
          await dataProvider.create(resource, {
            data,
          });
        }
        notify('admin.message.success', 'info');
        refresh();
      } catch (error) {
        notify(apiErrorHandler(error), 'warning');
      } finally {
        onSaved();
      }
    };
    fn();
  }, [form, input, resource, dataProvider, notify, onSaved, refresh]);

  return (
    <DoneButton label={label} disabled={disabled} onClick={handleSave}>
      <SaveIcon />
    </DoneButton>
  );
};

interface Props {
  resource: keyof Resources;
  siteId: string;
  input: Input;
  open: boolean;
  onClose: () => void;
}

const CompactionInputDialog: React.FC<Props> = ({
  resource,
  siteId,
  input,
  open,
  onClose,
}) => {
  const isUpdate = !!input?.record;
  const { id } = input?.record || { id: undefined };
  const mode = isUpdate ? 'update' : 'add'; // タイトルの文言定義を切り替える
  const classes = useStyles();
  const translate = useTranslate();
  const nameValidation = useNameValidation(
    resource,
    `admin.validation.duplicated.${resource}.message`,
    { siteId },
    id ? [id] : undefined,
  );
  const ref = useRef<HTMLDivElement>(null);
  const onEnter = useCallback(() => {
    if (
      !ref ||
      !ref.current ||
      !ref.current.parentElement ||
      !ref.current.parentElement.parentElement
    ) {
      return;
    }
    const messageParentDiv = ref.current.parentElement.parentElement;
    messageParentDiv.style.minWidth = `${dialogWidth}px`;
  }, [ref]);
  return (
    <Dialog open={open} onClose={onClose} onEnter={onEnter}>
      <MuiDialogTitle className={classes.header}>
        {translate(`admin.dialog.${resource}.${mode}.title`)}
      </MuiDialogTitle>
      <MuiDialogContent className={classes.content}>
        <div ref={ref}>
          <CustomForm
            resource={resource}
            record={input.record}
            bottomToolbar={true}
            deleteButton={false}
            cancelButton={
              <NormalButton onClick={onClose} label="admin.actions.cancel">
                <ReturnIcon />
              </NormalButton>
            }
            saveButton={
              <SaveButton
                resource={resource}
                onSaved={onClose}
                label="admin.actions.save"
                input={input}
              />
            }
          >
            {input.fields.map((name, i) => (
              <RaTextInput
                source={name}
                key={i}
                validate={[
                  required(),
                  input.isNameValidation ? nameValidation : undefined,
                ]}
              />
            ))}
          </CustomForm>
        </div>
      </MuiDialogContent>
    </Dialog>
  );
};

CompactionInputDialog.displayName = 'CompactionInputDialog';
export default CompactionInputDialog;
