import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { CoordinateData } from '../../../../dataProvider';

interface InParams {
  coordinate: { id?: number; e: number; n: number }[];
}

interface OutParams {
  data: { id?: number; longitude: number; latitude: number }[];
}

const useLocalGeofenceCoordinateToLonLat = () => {
  const dataProvider = useDataProvider();
  return useCallback(
    async ({ coordinate }: InParams): Promise<OutParams> => {
      const points = coordinate.map(({ id, e, n }, index) => ({
        id: id || index + 1,
        e,
        n,
      }));

      const { data } = await dataProvider.getList<CoordinateData>(
        'geofenceCoordinates',
        {
          pagination: {
            page: 1,
            perPage: points.length,
          },
          sort: {
            field: 'id',
            order: 'ASC',
          },
          filter: { points },
        },
      );

      return {
        data: data.map(({ id, point }) => ({
          id,
          longitude: point.longitude || 0,
          latitude: point.latitude || 0,
        })),
      };
    },
    [dataProvider],
  );
};

export default useLocalGeofenceCoordinateToLonLat;
