import { Datagrid as RaDatagrid } from 'react-admin';

import { TableProps as MuiProps } from '@material-ui/core/Table';

import {
  ReactNodeLike,
  ReactElementLike,
  ReactComponentLike,
} from 'prop-types';

interface RaProps {
  basePath: string;
  body: ReactElementLike;
  children: ReactNodeLike;
  classes: object;
  className: string;
  currentSort: {
    field: string;
    order: string;
  };
  data: object;
  expand: ReactElementLike | ReactComponentLike;
  hasBulkActions: boolean;
  hover: boolean;
  ids: any[];
  loading: boolean;
  onSelect: Function;
  onToggleItem: Function;
  resource: string;
  rowClick: string | Function;
  rowStyle: Function;
  selectedIds: any[];
  setSort: Function;
  total: number;
  version: number;
}

export type DatagridProps = ForgivingComponentType<MuiProps & RaProps>;
const Datagrid = RaDatagrid as DatagridProps;
Datagrid.displayName = 'Datagrid';

export default Datagrid;
