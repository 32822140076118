import { useCallback } from 'react';
import useGetRentalRecursive from './useGetRentalRecursive';

interface InParams {
  currentRentalId: number;
  availableStartDate: Date;
}

interface OutParams {
  data: {
    endDate: Date;
  };
}

const useGetAvailableEndDate = () => {
  const getRentalRecursive = useGetRentalRecursive();

  return useCallback(
    async ({
      currentRentalId,
      availableStartDate,
    }: InParams): Promise<OutParams> => {
      const {
        data: { endDate },
      } = await getRentalRecursive({
        retrofitRentalId: currentRentalId,
        defaultDate: availableStartDate,
      });
      return {
        data: {
          endDate: endDate || availableStartDate,
        },
      };
    },
    [getRentalRecursive],
  );
};

export default useGetAvailableEndDate;
