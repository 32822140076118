import { UserNoticeData } from '../../types';

const userNotices: UserNoticeData[] = [
  {
    id: 1,
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    language: 'EN',
    title: 'Welcome to SC Pilot',
    content:
      'Welcome to SC Pilot\r\n The SC pilot is a function for managing after adding the machine guidance function. Refer to the operation manual for the required operations.',
    file: [
      {
        src: 'https://dummy/welcome_en.pdf',
        title: 'Welcome to SC Pilot',
      },
    ],
    translations: [
      {
        id: 'd1ff25f5-882e-e40d-9a41-7cb964544db7',
        language: 'JA',
        title: 'SC Pilotへようこそ',
        content:
          'SC Pilotへようこそ\r\n SC pilotはマシンガイダンス機能を後付けしたあと、管理するための機能となっています。必要な操作は操作マニュアルを参照してください',
        file: [
          {
            src: 'https://dummy/welcome_ja.pdf',
            title: 'SC Pilotへようこそ',
          },
        ],
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: 2,
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2021-03-09T01:00:00.000Z',
    publicationEndDate: '2021-03-23T01:00:00.000Z',
    language: 'EN',
    title: 'SC Pilot Version3.0.0 release',
    content:
      'SC Pilot Version 3.0.0 will be released on 03/22/2021.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 03/22/2021 18:00\r\n Maintenance end date: 03/22/2021 20:00',
    file: [
      {
        src: 'https://dummy/release_3_0_0_en.pdf',
        title: 'SC Pilot Version3.0.0 release',
      },
    ],
    translations: [
      {
        id: 'a92b4953-8de8-6999-9ccd-f094fd376c97',
        language: 'JA',
        title: 'SC Pilot Version3.0.0 releaseのお知らせ',
        content:
          'SC Pilot Version3.0.0 releaseのお知らせ\r\n \r\n 2021/03/22にSC Pilot Version3.0.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2021/03/22 18:00\r\n メンテナンス終了日:2021/03/22 20:00',
        file: [
          {
            src: 'https://dummy/release_3_0_0_ja.pdf',
            title: 'SC Pilot Version3.0.0 releaseのお知らせ',
          },
        ],
      },
    ],
    updateDate: '2021-03-23T01:00:00.000Z',
  },
  {
    id: 3,
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2021-02-23T01:00:00.000Z',
    publicationEndDate: '2021-03-09T01:00:00.000Z',
    language: 'EN',
    title: 'SC Pilot Version2.11.0 release',
    content:
      'SC Pilot Version 2.11.0 will be released on 03/08/2021.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 03/08/2021 18:00\r\n Maintenance end date: 03/08/2021 20:00',
    file: [
      {
        src: 'https://dummy/release_2_11_0_en.pdf',
        title: 'SC Pilot Version2.11.0 release',
      },
    ],
    translations: [
      {
        id: '23600f79-418e-8a80-97a6-5d01389e9dce',
        language: 'JA',
        title: 'SC Pilot Version2.11.0 releaseのお知らせ',
        content:
          'SC Pilot Version2.11.0 releaseのお知らせ\r\n \r\n 2021/03/08にSC Pilot Version2.11.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2021/03/08 18:00\r\n メンテナンス終了日:2021/03/08 20:00',
        file: [
          {
            src: 'https://dummy/release_2_11_0_ja.pdf',
            title: 'SC Pilot Version2.11.0 releaseのお知らせ',
          },
        ],
      },
    ],
    updateDate: '2021-03-09T01:00:00.000Z',
  },
  {
    id: 4,
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2021-02-09T01:00:00.000Z',
    publicationEndDate: '2021-02-23T01:00:00.000Z',
    language: 'EN',
    title: 'SC Pilot Version2.10.0 release',
    content:
      'SC Pilot Version 2.10.0 will be released on 02/22/2021.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 02/22/20212 18:00\r\n Maintenance end date: 02/22/2021 20:00',
    file: [
      {
        src: 'https://dummy/release_2_10_0_en.pdf',
        title: 'SC Pilot Version2.10.0 release',
      },
    ],
    translations: [
      {
        id: 'eacec543-119e-eeef-421a-88dea4638501',
        language: 'JA',
        title: 'SC Pilot Version2.10.0 releaseのお知らせ',
        content:
          'SC Pilot Version2.10.0 releaseのお知らせ\r\n \r\n 2021/02/22にSC Pilot Version2.10.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2021/02/22 18:00\r\n メンテナンス終了日:2021/02/22 20:00',
        file: [
          {
            src: 'https://dummy/release_2_10_0_ja.pdf',
            title: 'SC Pilot Version2.10.0 releaseのお知らせ',
          },
        ],
      },
    ],
    updateDate: '2021-02-23T01:00:00.000Z',
  },
  {
    id: 5,
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2021-01-26T01:00:00.000Z',
    publicationEndDate: '2021-02-09T01:00:00.000Z',
    language: 'EN',
    title: 'SC Pilot Version2.9.0 release',
    content:
      'SC Pilot Version 2.9.0 will be released on 02/08/2021.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 02/08/2021 18:00\r\n Maintenance end date: 02/08/2021 20:00',
    file: [
      {
        src: 'https://dummy/release_2_9_0_en.pdf',
        title: 'SC Pilot Version2.9.0 release',
      },
    ],
    translations: [
      {
        id: '4a7787a1-206a-9a09-ee7d-38ff14e7d95a',
        language: 'JA',
        title: 'SC Pilot Version2.9.0 releaseのお知らせ',
        content:
          'SC Pilot Version2.9.0 releaseのお知らせ\r\n \r\n 2021/02/08にSC Pilot Version2.9.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2021/02/08 18:00\r\n メンテナンス終了日:2021/02/08 20:00',
        file: [
          {
            src: 'https://dummy/release_2_9_0_ja.pdf',
            title: 'SC Pilot Version2.9.0 releaseのお知らせ',
          },
        ],
      },
    ],
    updateDate: '2021-02-09T01:00:00.000Z',
  },
  {
    id: 6,
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2021-01-13T01:00:00.000Z',
    publicationEndDate: '2021-01-26T01:00:00.000Z',
    language: 'EN',
    title: 'SC Pilot Version2.8.0 release',
    content:
      'SC Pilot Version 2.8.0 will be released on 01/25/2021.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 01/25/2021 18:00\r\n Maintenance end date: 01/25/2021 20:00',
    file: [
      {
        src: 'https://dummy/release_2_8_0_en.pdf',
        title: 'SC Pilot Version2.8.0 release',
      },
    ],
    translations: [
      {
        id: 'cd6e17b7-e9f2-575b-0989-f45efb18adea',
        language: 'JA',
        title: 'SC Pilot Version2.8.0 releaseのお知らせ',
        content:
          'SC Pilot Version2.8.0 releaseのお知らせ\r\n \r\n 2021/01/25にSC Pilot Version2.8.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2021/01/25 18:00\r\n メンテナンス終了日:2021/01/25 20:00',
        file: [
          {
            src: 'https://dummy/release_2_8_0_ja.pdf',
            title: 'SC Pilot Version2.8.0 releaseのお知らせ',
          },
        ],
      },
    ],
    updateDate: '2021-01-26T01:00:00.000Z',
  },
  {
    id: 7,
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-12-15T01:00:00.000Z',
    publicationEndDate: '2021-01-13T01:00:00.000Z',
    language: 'EN',
    title: 'SC Pilot Version2.7.0 release',
    content:
      'SC Pilot Version 2.7.0 will be released on 01/12/2021.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 01/12/2021 18:00\r\n Maintenance end date: 01/12/2021 20:00',
    file: [
      {
        src: 'https://dummy/release_2_7_0_en.pdf',
        title: 'SC Pilot Version2.7.0 release',
      },
    ],
    translations: [
      {
        id: 'd5a3e9b5-cc17-f747-2041-de5c59ff101a',
        language: 'JA',
        title: 'SC Pilot Version2.7.0 releaseのお知らせ',
        content:
          'SC Pilot Version2.7.0 releaseのお知らせ\r\n \r\n 2021/01/12にSC Pilot Version2.7.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2021/01/12 18:00\r\n メンテナンス終了日:2021/01/12 20:00',
        file: [
          {
            src: 'https://dummy/release_2_7_0_ja.pdf',
            title: 'SC Pilot Version2.7.0 releaseのお知らせ',
          },
        ],
      },
    ],
    updateDate: '2021-01-13T01:00:00.000Z',
  },
  {
    id: 8,
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-12-01T01:00:00.000Z',
    publicationEndDate: '2020-12-15T01:00:00.000Z',
    language: 'EN',
    title: 'SC Pilot Version2.6.0 release',
    content:
      'SC Pilot Version 2.6.0 will be released on 12/14/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 12/14/2020 18:00\r\n Maintenance end date: 12/14/2020 20:00',
    file: [
      {
        src: 'https://dummy/release_2_6_0_en.pdf',
        title: 'SC Pilot Version2.6.0 release',
      },
    ],
    translations: [
      {
        id: '2865f89c-d4c3-6a91-4b90-0eac4b94c1de',
        language: 'JA',
        title: 'SC Pilot Version2.6.0 releaseのお知らせ',
        content:
          'SC Pilot Version2.6.0 releaseのお知らせ\r\n \r\n 2020/12/14にSC Pilot Version2.6.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/12/14 18:00\r\n メンテナンス終了日:2020/12/14 20:00',
        file: [
          {
            src: 'https://dummy/release_2_6_0_ja.pdf',
            title: 'SC Pilot Version2.6.0 releaseのお知らせ',
          },
        ],
      },
    ],
    updateDate: '2020-12-15T01:00:00.000Z',
  },
  {
    id: 9,
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-11-17T01:00:00.000Z',
    publicationEndDate: '2020-12-01T01:00:00.000Z',
    language: 'EN',
    title: 'SC Pilot Version2.5.0 release',
    content:
      'SC Pilot Version 2.5.0 will be released on 11/30/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 11/30/2020 18:00\r\n Maintenance end date: 11/30/2020 20:00',
    file: [
      {
        src: 'https://dummy/release_2_5_0_en.pdf',
        title: 'SC Pilot Version2.5.0 release',
      },
    ],
    translations: [
      {
        id: '42e86822-0aec-e58a-b393-2d74b7162b15',
        language: 'JA',
        title: 'SC Pilot Version2.5.0 releaseのお知らせ',
        content:
          'SC Pilot Version2.5.0 releaseのお知らせ\r\n \r\n 2020/11/30にSC Pilot Version2.5.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/11/30 18:00\r\n メンテナンス終了日:2020/11/30 20:00',
        file: [
          {
            src: 'https://dummy/release_2_5_0_ja.pdf',
            title: 'SC Pilot Version2.5.0 releaseのお知らせ',
          },
        ],
      },
    ],
    updateDate: '2020-12-01T01:00:00.000Z',
  },
  {
    id: 10,
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-11-03T01:00:00.000Z',
    publicationEndDate: '2020-11-17T01:00:00.000Z',
    language: 'EN',
    title: 'SC Pilot Version2.4.0 release',
    content:
      'SC Pilot Version 2.4.0 will be released on 11/16/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 11/15/2020 18:00\r\n Maintenance end date: 11/16/2020 20:00',
    file: [
      {
        src: 'https://dummy/release_2_4_0_en.pdf',
        title: 'SC Pilot Version2.4.0 release',
      },
    ],
    translations: [
      {
        id: 'ca7ee916-a0b0-db2b-4195-85fc1e950bc2',
        language: 'JA',
        title: 'SC Pilot Version2.4.0 releaseのお知らせ',
        content:
          'SC Pilot Version2.4.0 releaseのお知らせ\r\n \r\n 2020/11/16にSC Pilot Version2.4.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/11/16 18:00\r\n メンテナンス終了日:2020/11/16 20:00',
        file: [
          {
            src: 'https://dummy/release_2_4_0_ja.pdf',
            title: 'SC Pilot Version2.4.0 releaseのお知らせ',
          },
        ],
      },
    ],
    updateDate: '2020-11-17T01:00:00.000Z',
  },
  {
    id: 11,
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-10-20T01:00:00.000Z',
    publicationEndDate: '2020-11-03T01:00:00.000Z',
    language: 'EN',
    title: 'SC Pilot Version2.3.0 release',
    content:
      'SC Pilot Version 2.3.0 will be released on 11/02/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 11/02/2020 18:00\r\n Maintenance end date: 11/02/2020 20:00',
    file: [
      {
        src: 'https://dummy/release_2_3_0_en.pdf',
        title: 'SC Pilot Version2.3.0 release',
      },
    ],
    translations: [
      {
        id: '3c999333-a773-b6d1-b37c-00d0d7bef3ef',
        language: 'JA',
        title: 'SC Pilot Version2.3.0 releaseのお知らせ',
        content:
          'SC Pilot Version2.3.0 releaseのお知らせ\r\n \r\n 2020/11/02にSC Pilot Version2.3.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/11/02 18:00\r\n メンテナンス終了日:2020/11/02 20:00',
        file: [
          {
            src: 'https://dummy/release_2_3_0_ja.pdf',
            title: 'SC Pilot Version2.3.0 releaseのお知らせ',
          },
        ],
      },
    ],
    updateDate: '2020-11-03T01:00:00.000Z',
  },
  {
    id: 12,
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-10-06T01:00:00.000Z',
    publicationEndDate: '2020-10-20T01:00:00.000Z',
    language: 'EN',
    title: 'SC Pilot Version2.2.0 release',
    content:
      'SC Pilot Version 2.2.0 will be released on 10/19/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/10/19 18:00\r\n Maintenance end date: 2020/10/19 20:00',
    file: [
      {
        src: 'https://dummy/release_2_2_0_en.pdf',
        title: 'SC Pilot Version2.2.0 release',
      },
    ],
    translations: [
      {
        id: '8ade814e-7aa8-023a-b425-bcf572d4cbc9',
        language: 'JA',
        title: 'SC Pilot Version2.2.0 releaseのお知らせ',
        content:
          'SC Pilot Version2.2.0 releaseのお知らせ\r\n \r\n 2020/10/19にSC Pilot Version2.1.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/10/19 18:00\r\n メンテナンス終了日:2020/10/19 20:00',
        file: [
          {
            src: 'https://dummy/release_2_2_0_ja.pdf',
            title: 'SC Pilot Version2.2.0 releaseのお知らせ',
          },
        ],
      },
    ],
    updateDate: '2020-10-20T01:00:00.000Z',
  },
  {
    id: 13,
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-09-15T01:00:00.000Z',
    publicationEndDate: '2020-10-06T01:00:00.000Z',
    language: 'EN',
    title: 'SC Pilot Version2.1.0 release',
    content:
      'SC Pilot Version 2.1.0 will be released on 10/05/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/10/05 18:00\r\n Maintenance end date: 2020/10/05 20:00',
    file: [
      {
        src: 'https://dummy/release_2_1_0_en.pdf',
        title: 'SC Pilot Version2.1.0 release',
      },
    ],
    translations: [
      {
        id: 'b77cc297-17e2-f718-fabf-d3c172b294d6',
        language: 'JA',
        title: 'SC Pilot Version2.1.0 releaseのお知らせ',
        content:
          'SC Pilot Version2.1.0 releaseのお知らせ\r\n \r\n 2020/10/05にSC Pilot Version2.1.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/10/05 18:00\r\n メンテナンス終了日:2020/10/05 20:00',
        file: [
          {
            src: 'https://dummy/release_2_1_0_ja.pdf',
            title: 'SC Pilot Version2.1.0 releaseのお知らせ',
          },
        ],
      },
    ],
    updateDate: '2020-10-06T01:00:00.000Z',
  },
  {
    id: 14,
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-09-01T01:00:00.000Z',
    publicationEndDate: '2020-09-15T01:00:00.000Z',
    language: 'EN',
    title: 'SC Pilot Version2.0.0 release',
    content:
      'SC Pilot Version 2.0.0 will be released on 09 /14/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/09/14 18:00\r\n Maintenance end date: 2020/09/14 20:00',
    file: [
      {
        src: 'https://dummy/release_2_0_0_en.pdf',
        title: 'SC Pilot Version2.0.0 release',
      },
    ],
    translations: [
      {
        id: '6e019171-2def-e60c-7e77-e9cb18d8da19',
        language: 'JA',
        title: 'SC Pilot Version2.0.0 releaseのお知らせ',
        content:
          'SC Pilot Version2.0.0 releaseのお知らせ\r\n \r\n 2020/09/14にSC Pilot Version2.0.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/09/14 18:00\r\n メンテナンス終了日:2020/09/14 20:00',
        file: [
          {
            src: 'https://dummy/release_2_0_0_ja.pdf',
            title: 'SC Pilot Version2.0.0 releaseのお知らせ',
          },
        ],
      },
    ],
    updateDate: '2020-09-15T01:00:00.000Z',
  },
  {
    id: 15,
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-08-18T01:00:00.000Z',
    publicationEndDate: '2020-09-01T01:00:00.000Z',
    language: 'EN',
    title: 'SC Pilot Version1.2.3 release',
    content:
      'SC Pilot Version 1.2.3 will be released on 08 /31/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/08/31 18:00\r\n Maintenance end date: 2020/08/31 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_3_en.pdf',
        title: 'SC Pilot Version1.2.3 release',
      },
    ],
    translations: [
      {
        id: 'ca1407bb-e5bb-b491-ca03-d763454f43e5',
        language: 'JA',
        title: 'SC Pilot Version1.2.3 releaseのお知らせ',
        content:
          'SC Pilot Version1.2.3 releaseのお知らせ\r\n \r\n 2020/08/29にSC Pilot Version1.2.3 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/08/31 18:00\r\n メンテナンス終了日:2020/08/31 20:00',
        file: [
          {
            src: 'https://dummy/release_1_2_3_ja.pdf',
            title: 'SC Pilot Version1.2.3 releaseのお知らせ',
          },
        ],
      },
    ],
    updateDate: '2020-09-01T01:00:00.000Z',
  },
  {
    id: 16,
    noticeType: 'EmergencyImportant',
    noticeTypes: ['Emergency', 'Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-07-28T01:00:00.000Z',
    publicationEndDate: '2020-08-18T01:00:00.000Z',
    language: 'EN',
    title: 'SC Pilot Version1.2.2 release',
    content:
      'SC Pilot Version 1.2.2 will be released on 08 /17/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/08/17 18:00\r\n Maintenance end date: 2020/08/17 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_2_en.pdf',
        title: 'SC Pilot Version1.2.2 release',
      },
    ],
    translations: [
      {
        id: '2f9617cf-aa38-8623-2060-633fc84e0d52',
        language: 'JA',
        title: 'SC Pilot Version1.2.2 releaseのお知らせ',
        content:
          'SC Pilot Version1.2.2 releaseのお知らせ\r\n \r\n 2020/08/17にSC Pilot Version1.2.2 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/08/17 18:00\r\n メンテナンス終了日:2020/08/17 20:00',
        file: [
          {
            src: 'https://dummy/release_1_2_2_ja.pdf',
            title: 'SC Pilot Version1.2.2 releaseのお知らせ',
          },
        ],
      },
    ],
    updateDate: '2020-08-18T01:00:00.000Z',
  },
  {
    id: 17,
    noticeType: 'Emergency',
    noticeTypes: ['Emergency'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-07-14T01:00:00.000Z',
    publicationEndDate: '2020-07-28T01:00:00.000Z',
    language: 'EN',
    title: 'SC Pilot Version1.2.1 release',
    content:
      'SC Pilot Version 1.2.1 will be released on 07 /27/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/07/27 18:00\r\n Maintenance end date: 2020/07/27 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_1_en.pdf',
        title: 'SC Pilot Version1.2.1 release',
      },
    ],
    translations: [
      {
        id: '8b5ad0db-552c-8940-9f80-d4dd817a0b03',
        language: 'JA',
        title: 'SC Pilot Version1.2.1 releaseのお知らせ',
        content:
          'SC Pilot Version1.2.1 releaseのお知らせ\r\n \r\n 2020/07/27にSC Pilot Version1.2.2 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/07/27 18:00\r\n メンテナンス終了日:2020/07/27 20:00',
        file: [
          {
            src: 'https://dummy/release_1_2_1_ja.pdf',
            title: 'SC Pilot Version1.2.1 releaseのお知らせ',
          },
        ],
      },
    ],
    updateDate: '2020-07-28T01:00:00.000Z',
  },
  {
    id: 18,
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-07-14T01:00:00.000Z',
    publicationEndDate: '2020-07-28T01:00:00.000Z',
    language: 'EN',
    title: 'SC Pilot Version1.2.1 release',
    content:
      'SC Pilot Version 1.2.1 will be released on 07 /27/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/07/27 18:00\r\n Maintenance end date: 2020/07/27 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_1_en.pdf',
        title: 'SC Pilot Version1.2.1 release',
      },
    ],
    translations: [
      {
        id: '4aacf171-9a12-4692-6960-e671f41b19c5',
        language: 'JA',
        title: 'SC Pilot Version1.2.1 releaseのお知らせ',
        content:
          'SC Pilot Version1.2.1 releaseのお知らせ\r\n \r\n 2020/07/27にSC Pilot Version1.2.2 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/07/27 18:00\r\n メンテナンス終了日:2020/07/27 20:00',
        file: [
          {
            src: 'https://dummy/release_1_2_1_ja.pdf',
            title: 'SC Pilot Version1.2.1 releaseのお知らせ',
          },
        ],
      },
    ],
    updateDate: '2020-07-28T01:00:00.000Z',
  },
  {
    id: 19,
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    language: 'EN',
    title: 'Welcome to SC Pilot 2',
    content:
      'Welcome to SC Pilot\r\n The SC pilot is a function for managing after adding the machine guidance function. Refer to the operation manual for the required operations.',
    file: [
      {
        src: 'https://dummy/welcome_en.pdf',
        title: 'Welcome to SC Pilot 2',
      },
    ],
    translations: [
      {
        id: 'bcb9076f-7a69-f334-68e8-76a423a343f0',
        language: 'JA',
        title: 'SC Pilotへようこそ 2',
        content:
          'SC Pilotへようこそ\r\n SC pilotはマシンガイダンス機能を後付けしたあと、管理するための機能となっています。必要な操作は操作マニュアルを参照してください',
        file: [
          {
            src: 'https://dummy/welcome_ja.pdf',
            title: 'SC Pilotへようこそ2',
          },
        ],
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: 20,
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    language: 'EN',
    title: 'Welcome to SC Pilot 3',
    content:
      'Welcome to SC Pilot\r\n The SC pilot is a function for managing after adding the machine guidance function. Refer to the operation manual for the required operations.',
    file: [
      {
        src: 'https://dummy/welcome_en.pdf',
        title: 'Welcome to SC Pilot 3',
      },
    ],
    translations: [
      {
        id: '04033c5f-df2f-9ae2-f4cd-56c8dea716b9',
        language: 'JA',
        title: 'SC Pilotへようこそ 3',
        content:
          'SC Pilotへようこそ\r\n SC pilotはマシンガイダンス機能を後付けしたあと、管理するための機能となっています。必要な操作は操作マニュアルを参照してください',
        file: [
          {
            src: 'https://dummy/welcome_ja.pdf',
            title: 'SC Pilotへようこそ3',
          },
        ],
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: 21,
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    language: 'EN',
    title: 'Welcome to SC Pilot 4',
    content:
      'Welcome to SC Pilot\r\n The SC pilot is a function for managing after adding the machine guidance function. Refer to the operation manual for the required operations.',
    file: [
      {
        src: 'https://dummy/welcome_en.pdf',
        title: 'Welcome to SC Pilot 4',
      },
    ],
    translations: [
      {
        id: '32b11ce6-b765-caa3-f056-a0f9b6f5c7e0',
        language: 'JA',
        title: 'SC Pilotへようこそ 4',
        content:
          'SC Pilotへようこそ\r\n SC pilotはマシンガイダンス機能を後付けしたあと、管理するための機能となっています。必要な操作は操作マニュアルを参照してください',
        file: [
          {
            src: 'https://dummy/welcome_ja.pdf',
            title: 'SC Pilotへようこそ4',
          },
        ],
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
];

export default userNotices;
