import { AdminApiConverter } from '..';
import { RetrofitManageCorporationData } from '../../types';

const converter: AdminApiConverter<
  'retrofitManageCorporations',
  'retrofitManageCorporations'
> = {
  resource: 'retrofitManageCorporations',
  defaultSort: { field: 'corporationId', order: 'ASC' },
  filterToApi: filter => filter,
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map((record): RetrofitManageCorporationData => {
        return {
          id: record.id || -1,
          corporationId: record.corporationId || '',
          corporationName: record.corporationName || '',
        };
      }),
    ),
  recordToApi: record => {
    return {
      corporationId: record.corporationId || '',
      corporationName: record.corporationName || '',
    };
  },
};
export default converter;
