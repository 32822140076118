import { AdminApiConverter, ApiFilter } from '..';
import { AsbuiltData } from '../../types';

const converter: AdminApiConverter<'asbuilts', 'asbuilt'> = {
  resource: 'asbuilt',
  defaultSort: { field: 'createDate', order: 'DESC' },
  embed: ['asbuiltFile'],
  filterToApi: filter => {
    const apiFilter: ApiFilter<'asbuilt'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('corporationId' in filter)
      apiFilter.corporationId = filter.corporationId;
    if ('siteId' in filter) apiFilter.siteId = filter.siteId;
    if ('startPeriod' in filter) apiFilter.startPeriod = filter.startPeriod;
    if ('endPeriod' in filter) apiFilter.endPeriod = filter.endPeriod;
    if ('csvType' in filter) apiFilter.csvType = filter.csvType;
    if ('execStatus' in filter) apiFilter.execStatus = filter.execStatus;
    if ('execStartDate' in filter)
      apiFilter.execStartDate = filter.execStartDate;
    if ('execEndDate' in filter) apiFilter.execEndDate = filter.execEndDate;

    return apiFilter;
  },
  sortFieldToApi: field => {
    if (field === 'url') return 'id';
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map((record): AsbuiltData => {
        if (!record.execStatus)
          throw new Error("Can't get ExecStatus of Asbuilt from the server.");
        return {
          id: record.id || '',
          corporationId: record.corporationId || '',
          siteId: record.siteId || '',
          startPeriod: record.startPeriod,
          endPeriod: record.endPeriod,
          csvType: record.csvType,
          execStatus: record.execStatus,
          asbuiltFileId: record.asbuiltFileId,
          execStartDate: record.execStartDate,
          execEndDate: record.execEndDate,
          url: record.asbuiltFile?.url,
        };
      }),
    ),
  recordToApi: record => ({
    corporationId: record.corporationId,
    siteId: record.siteId,
    startPeriod: new Date(record.startPeriod).toISOString(),
    endPeriod: new Date(record.endPeriod).toISOString(),
    csvType: record.csvType,
  }),
};

export default converter;
