import { useCallback } from 'react';
import { useDataProvider, useRefresh, useNotify } from 'react-admin';

const useUpdateMany = (resource: string, ids: string[], data: any) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  return useCallback(() => {
    dataProvider.updateMany(resource, { ids, data }).then(() => {
      refresh();
      notify('ra.notification.updated', 'info', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        smart_count: ids.length,
      });
    });
  }, [resource, ids, data, dataProvider, refresh, notify]);
};

const useUpdateManyFn = (
  overrideCompleteMessage: string | undefined = undefined,
) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  return {
    updateMany: (resource: string, ids: string[], data: any) => {
      dataProvider.updateMany(resource, { ids, data }).then(() => {
        refresh();
        const message = overrideCompleteMessage || 'ra.notification.updated';
        notify(message, 'info', {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          smart_count: ids.length,
        });
      });
    },
  };
};

export { useUpdateManyFn };
export default useUpdateMany;
