import { useDataProvider, useRefresh, useNotify } from 'react-admin';

const useDeleteManyFn = () => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  return {
    deleteMany: (resource: string, ids: string[]) => {
      dataProvider.deleteMany(resource, { ids }).then(() => {
        refresh();
        notify('ra.notification.deleted', 'info', {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          smart_count: ids.length,
        });
      });
    },
  };
};

export default useDeleteManyFn;
