import { LonLat } from '../../../../utils';
import {
  checkAreaZeroByArray,
  checkIntersectByArray,
  checkSameValueByArray,
} from '../../../organisms';

const useCoordinatesCheck = () => {
  return {
    /* ポリゴン交差チェック */
    checkIntersect: (lonLat: LonLat[]): boolean => {
      // 末尾に先頭要素を追加する対応
      const newLonLat = lonLat.length > 0 ? [...lonLat, lonLat[0]] : [];
      // 引数用の配列型に変換
      const checkArray = newLonLat.map(({ lon, lat }) => [lon, lat]);
      // 交差チェック (登録不可の場合trueを返す)
      return checkIntersectByArray(checkArray);
    },

    /* 同一座標チェック*/
    checkSameValue: (lonLat: LonLat[]): boolean => {
      // 引数用の配列型に変換
      const checkArray = lonLat.map(({ lon, lat }) => [lon, lat]);
      // 同一チェック
      return checkSameValueByArray(checkArray);
    },

    /* 面積ゼロチェック */
    checkAreaZero: (lonLat: LonLat[]): boolean => {
      // 末尾に先頭要素を追加する対応
      const newLonLat = lonLat.length > 0 ? [...lonLat, lonLat[0]] : [];
      // 引数用の配列型に変換
      const checkArray = newLonLat.map(({ lon, lat }) => [lon, lat]);
      // 面積ゼロチェック
      return checkAreaZeroByArray(checkArray);
    },
  };
};

export default useCoordinatesCheck;
