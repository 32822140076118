import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import {
  RetrofitData,
  RetrofitAlternateRegistData,
} from '../../../../dataProvider/types';

interface InParams {
  retrofitIds: number[];
  corporationId: string;
  retrofits: RetrofitData[];
}

interface OutParams {
  data: {
    isRegistrated: boolean;
    serialNumbers: string[];
  };
}

const useCheckDoubleRegistration = () => {
  const dataProvider = useDataProvider();
  return useCallback(
    async ({
      retrofitIds,
      corporationId,
      retrofits,
    }: InParams): Promise<OutParams> => {
      // 未承認のretrofitAlternateRegistsのデータを取得
      const { data: retrofitAlternateRegistData } =
        await dataProvider.getList<RetrofitAlternateRegistData>(
          'retrofitAlternateRegists',
          {
            sort: { field: 'id', order: 'ASC' },
            pagination: { page: 1, perPage: 100 },
            filter: {
              fromCorporationId: corporationId,
              isApproved: false,
            },
          },
        );

      const registretedRetrofitIds: number[] = retrofitAlternateRegistData.map(
        it => it.retrofitId,
      );

      const isRegistrated = retrofitIds
        .map(retrofitId => registretedRetrofitIds.includes(retrofitId))
        .some((alreadyRegistrated: boolean) => alreadyRegistrated); // 1つでも要素が true の場合、戻り値として true を返す

      const serialNumbers = retrofits
        .filter(({ id }) => registretedRetrofitIds.includes(id))
        .map(({ basicInfoSerialNumber }) => basicInfoSerialNumber);

      return {
        data: {
          isRegistrated,
          serialNumbers,
        },
      };
    },
    [dataProvider],
  );
};

export default useCheckDoubleRegistration;
