import React, {
  FC,
  useEffect,
  useState,
  cloneElement,
  isValidElement,
  useCallback,
} from 'react';
import {
  Labeled,
  ImageField,
  TextInput,
  useDataProvider,
  useTranslate,
  required,
  email as emailValidation,
  Loading,
} from 'react-admin';
import { Box, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  CustomForm,
  Divider,
  Typography,
  FileInput,
  DialogImagePreview,
  LendCorporationData,
  CustomerAutoCompleteInput,
} from '../..';
import { styles, colors } from '../../../theme';
import { buttonWidth, buttonHeight } from '../../../theme/define';
import { ErrorIcon, RetrofitSsidImage } from '../../../assets';
import {
  CustomerData,
  RetrofitNamePlateFileApiData,
} from '../../../dataProvider';
import { CreatePhotoSaveButton as SaveButton } from './views';
import { useGetAccount, useResource } from '../../../hooks';

const serialNumberPrefix = 'serialNumber_';
const serialNumberErrorPrefix = 'serialNumberError_';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 14,
      '& div.previews': {
        '& > div': {
          borderRadius: 0,
          marginLeft: 0,
          background: colors.paper,
        },
      },
    },
    corpNameAndErrorMessage: {
      display: 'flex',
      justifyContent: 'space-between',
      '& > p': {
        color: 'red',
        fontSize: 16,
        paddingTop: 8,
      },
    },
    divider: {
      marginTop: 8,
      marginBottom: 12,
      backgroundColor: 'white',
    },
    description: {
      ...styles.multilineText,
    },
    corporationId: {
      ...styles.longWidthText,
    },
    ssidImage: {
      marginTop: 24,
      marginLeft: 18,
    },
    buttonParent: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      backgroundColor: colors.button.cancel,
      color: colors.text.primaryLight, // テキスト表示するので必要
      fontSize: 14, // テキスト表示するので必要
      textTransform: 'none', // テキスト表示するので必要
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
      },
      // テキスト表示するので必要
      '& span': {
        padding: 0,
      },
    },
    namePlateHeader: {
      display: 'flex',
      flex: 10,
    },
    namePlateField: {
      width: 906,
      height: 193,
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      '& .MuiFormControl-marginDense': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    dropZone: {
      '& > div > div:first-child': {
        visibility: 'hidden',
      },
    },
    firstField: {
      marginTop: 8,
    },
    imageLabel: {
      '& > div': {
        height: 20,
      },
    },
    fileInput: {
      '& > div > label[for="namePlates"]': {
        display: 'none',
      },
    },
    serialInput: {
      '& input': {
        minWidth: 200,
      },
    },
    imageField: {
      width: 266,
      height: 'fit-content',
      marginRight: 29,
      marginTop: 8,
      '& img': {
        margin: 0,
        maxWidth: 266,
      },
    },
    legendText: {
      '& > .MuiTypography-root': {
        fontSize: 12,
      },
      '& > .MuiTypography-root:first-child': {
        marginTop: 18,
      },
    },
    progress: {
      padding: 12,
    },
    errorField: {
      display: 'none',
    },
  }),
);

type NamePlate = {
  id: string;
  serialNumber: string;
  status: string;
};

type NamePlateState = {
  [key: string]: NamePlate;
};

const NamePlateField: FC<
  Partial<{
    form: any;
    resource: string;
    source: string;
    title: string;
    record: { rawFile: File; title: string; url: string };
    namePlateState: { value: NamePlateState };
  }>
> = ({ form, resource, namePlateState, ...rest }) => {
  const { record } = rest;
  if (!resource) throw Error('Invalid resource');
  if (!record) throw Error('Invalid record');
  if (!namePlateState) throw Error('Invalid namePlateState');

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const { rawFile, title, url } = record;

  const cache: NamePlate | undefined = namePlateState.value[url];
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (fetching) return;
    if (cache) {
      form.change(`${serialNumberPrefix}${cache.id}`, cache.serialNumber);
      return;
    }
    setFetching(true);
    const api = async (): Promise<RetrofitNamePlateFileApiData> => {
      const { data } = await dataProvider.create<RetrofitNamePlateFileApiData>(
        resource,
        {
          data: {
            rawFile,
          },
        },
      );
      return data;
    };
    api()
      .then(({ id, serialNumber, extractStatus }) => {
        const state = namePlateState;
        state.value = {
          ...namePlateState.value,
          [url]: {
            id, // TextInput の source 文字列の一部に割り当てる
            serialNumber,
            status: extractStatus,
          },
        };
      })
      .catch(() => {
        const state = namePlateState;
        state.value = {
          ...namePlateState.value,
          [url]: {
            id: url, // 重複しなければサーバーから付与された値ではなくても問題はない
            serialNumber: '',
            status: 'Error',
          },
        };
      })
      .finally(() => {
        setFetching(false);
      });
  }, [
    resource,
    dataProvider,
    rawFile,
    form,
    cache,
    url,
    namePlateState,
    fetching,
  ]);

  const createField = (namePlate: NamePlate | undefined) => {
    if (namePlate?.status === 'Complete') {
      return (
        <TextInput
          className={classes.serialInput}
          label="admin.label.retrofitNamePlateFiles.serialNumber"
          source={`${serialNumberPrefix}${namePlate.id}`}
          initialValue={namePlate.serialNumber}
          validate={[required()]}
        />
      );
    }
    return (
      <>
        <Labeled
          label="admin.label.retrofitNamePlateFiles.status"
          className={classes.imageLabel}
        >
          {namePlate ? (
            <Tooltip title={namePlate.status}>
              <ErrorIcon />
            </Tooltip>
          ) : undefined}
        </Labeled>
        <div className={classes.errorField}>
          <TextInput source={`${serialNumberErrorPrefix}${cache?.id}`} />
        </div>
      </>
    );
  };

  return (
    <div className={classes.namePlateField}>
      <Box gridRow="1 / 2" gridColumn="1 / 10">
        {!cache ? (
          <div className={classes.progress}>
            <LinearProgress />
          </div>
        ) : null}
      </Box>
      <Box gridRow="2 / 3" gridColumn="1 / 6" className={classes.firstField}>
        {createField(cache)}
      </Box>
      <Box gridRow="3 / 10" gridColumn="1 / 6">
        <Labeled label={'admin.label.retrofitNamePlateFiles.imageFile'}>
          <Typography variant="body1">{title}</Typography>
        </Labeled>
      </Box>
      <Box gridRow="2 / 10" gridColumn="6 / 10" className={classes.imageField}>
        <DialogImagePreview {...rest}>
          <ImageField />
        </DialogImagePreview>
      </Box>
    </div>
  );
};

const RetrofitSsidField: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.ssidImage}>
      <RetrofitSsidImage />
    </div>
  );
};

const FileInputWithForm: FC<any> = ({ children, form, ...rest }) => {
  const classes = useStyles();
  return (
    <div className={classes.fileInput}>
      <FileInput {...rest}>
        {isValidElement(children)
          ? cloneElement(children, { form } as any)
          : null}
      </FileInput>
    </div>
  );
};

// メールアドレスに全角文字がないかチェック
const halfWidthValidation =
  (message = 'ra.validation.email') =>
  (value: any) =>
    typeof value === 'string' &&
    // eslint-disable-next-line no-control-regex
    /([^\x01-\x7E\uFF65-\uFF9F]|[ｦ-ﾟ])+/.test(value)
      ? message
      : undefined;

const RetrofitAlternateRegistCreatePhoto: FC = props => {
  const classes = useStyles();
  const resource = useResource('retrofitAlternateRegists');
  const [customer, setCustomer] = useState<LendCorporationData | null>(null);
  const { getAccount } = useGetAccount();
  const accountData = getAccount();
  const [namePlateState] = useState<{
    value: NamePlateState;
  }>({ value: {} });
  const translate = useTranslate();
  let retrofitId;

  // maxFilesプロパティは動作しないため、自前のvalidationを用意
  const maxFiles = () => (value: any[]) =>
    value && value.length > 5 ? 'message over!!!' : undefined;

  const mbyte = 4.5;
  const maxSize = 1024 * 1024 * mbyte; // アップロード可能サイズ (単位MB)

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [disableAddSerial, setDisableAddSerial] = useState(false);
  const handleChange = useCallback((record: any) => {
    if (record) {
      setDisableAddSerial(record.length > 4);
      setShowErrorMessage(record.length > 5);
    }
  }, []);

  if (!accountData) {
    return <Loading />;
  }
  const { corporationId, corporationName, name, email } = accountData;
  return (
    <CustomForm
      className={classes.root}
      resource={resource}
      redirect={false}
      saveButton={<SaveButton customer={customer} {...props} />}
      deleteButton={false}
      cancelButton={false}
      title="admin.pages.retrofitAlternateRegistCreate"
      initialValues={
        {
          retrofitId,
          fromCorporationId: corporationId,
          fromCorporationName: corporationName,
          fromApplicantId: email,
          fromApplicantName: name,
          toCorporationId: '',
          toCorporationName: '',
          toMailAddress: '',
        } as any
      }
      {...props}
    >
      <Box className={classes.corpNameAndErrorMessage}>
        <CustomerAutoCompleteInput
          label="resources.retrofitAlternateRegists.fields.toCorporationName"
          resource={resource}
          source="toCorporationId"
          fromCorporationId={corporationId}
          onNotifyChange={(value: CustomerData) => setCustomer(value)}
        />
        {showErrorMessage ? (
          <Typography>
            {translate('admin.label.retrofitNamePlateFiles.errormessage')}
          </Typography>
        ) : (
          <></>
        )}
      </Box>
      <TextInput
        label="resources.retrofitAlternateRegists.fields.email"
        data-testid="toMailAddress"
        resource={resource}
        source="toMailAddress"
        validate={[required(), emailValidation(), halfWidthValidation()]}
      />
      <Divider className={classes.divider} />
      <Box className={classes.namePlateHeader}>
        <Box flex={3.5}>
          <RetrofitSsidField />
        </Box>
        <Box flex={6.5} className={classes.legendText}>
          <Typography>
            {translate('admin.label.retrofitNamePlateFiles.message1')}
          </Typography>
          <Typography>
            {translate('admin.label.retrofitNamePlateFiles.message2', {
              mbyte,
            })}
          </Typography>
        </Box>
      </Box>
      <Box marginTop={2} />
      <FileInputWithForm
        resource="retrofitNamePlateFiles"
        source="namePlates"
        className={disableAddSerial ? classes.dropZone : null}
        multiple
        validate={[required(), maxFiles()]}
        maxSize={maxSize}
        label=""
        accept=".jpg,.jpeg,.png"
        placeholder={
          <p>
            {translate('admin.label.fileInput.plaseholder', {
              fileTypes: translate('admin.label.fileInput.namePlate'),
            })}
            <br />
            {translate('admin.label.fileInput.maxFileNum')}
          </p>
        }
        onChange={(record: any) => handleChange(record)}
      >
        <NamePlateField
          resource="retrofitNamePlateFiles"
          source="url"
          title="title"
          namePlateState={namePlateState}
        />
      </FileInputWithForm>
    </CustomForm>
  );
};

RetrofitAlternateRegistCreatePhoto.displayName =
  'RetrofitAlternateRegistCreatePhoto';
export default RetrofitAlternateRegistCreatePhoto;
