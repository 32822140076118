import { RemotePanelStatusApi, RemotePanelStatus } from '..';

export default (api: RemotePanelStatusApi): RemotePanelStatus[] => {
  const data = [];
  const dataLength = api.target_mac.length;

  for (let i = 0; i < dataLength; i += 1) {
    // MACアドレス
    const apiMac = api.target_mac[i];

    // コードの値を「WaitingToStart」「UnableToConnect」「Error」のステータスに変換
    const apiRemoteStatus = api.remote_status[i];
    let dataRemoteStatus = 'Error';

    // 1000番台は「WaitingToStart」
    if (apiRemoteStatus.length === 4 && apiRemoteStatus.startsWith('1')) {
      dataRemoteStatus = 'WaitingToStart';
    }
    // 5000番台は「UnableToConnect」
    if (apiRemoteStatus.length === 4 && apiRemoteStatus.startsWith('5')) {
      dataRemoteStatus = 'UnableToConnect';
    }

    // ステータスコードを元に表示用のメッセージを作成
    // TODO: 国際化対応 暫定:ステータスコード＋RemoteStatus
    let dataStatusComent = api.status_comment[i];
    switch (dataRemoteStatus) {
      case 'WaitingToStart':
        dataStatusComent = `${apiRemoteStatus} Waiting to Start`;
        break;
      case 'UnableToConnect':
        dataStatusComent = `${apiRemoteStatus} Unable to Connect`;
        break;
      case 'Error':
        dataStatusComent = `${apiRemoteStatus} Error`;
        break;
      default:
    }

    // 1レコードずつ戻り値にpushしていく
    const addRemotePanelStatus: RemotePanelStatus = {
      id: apiMac,
      remoteStatus: dataRemoteStatus,
      statusComment: dataStatusComent,
      supportNumber: 0,
    };
    data.push(addRemotePanelStatus);
  }
  return data;
};
