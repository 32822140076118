import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';

const useIsRetrofitOwnerChangeCorporation = () => {
  const dataProvider = useDataProvider();

  return useCallback(
    async (corporationId: string): Promise<boolean> => {
      const { data } = await dataProvider.getList(
        'retrofitOwnerChangeCorporations',
        {
          pagination: {
            page: 1,
            perPage: 1,
          },
          sort: { field: 'id', order: 'DESC' },
          filter: { corporationId },
        },
      );

      if (data.length > 0) {
        return true;
      }

      return false;
    },
    [dataProvider],
  );
};

export default useIsRetrofitOwnerChangeCorporation;
