import { AdminApiConverter } from '..';
import { SiteConfigureData } from '../../types';

const converter: AdminApiConverter<'siteConfigures', 'siteConfigures'> = {
  resource: 'siteConfigures',
  defaultSort: { field: 'id', order: 'DESC' },
  filterToApi: filter => filter,
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map((record): SiteConfigureData => {
        return {
          id: record.id,
          siteId: record.siteId || '',
          unitLength: record.unitLength,
          enableGeofence: record.enableGeofence,
        };
      }),
    ),
  recordToApi: record => {
    return {
      siteId: record.siteId,
      unitLength: record.unitLength,
      enableGeofence: record.enableGeofence,
    };
  },
};
export default converter;
