import { useTranslate } from 'react-admin';
import { useCallback } from 'react';
import { checkInvalidString } from '../utils';

/**
 * ファイル名称バリデーション（ファイル名にも使用する文字列のバリテーション）
 * @param value 判定文字列
 *
 * @example
 *
 * [JSX]
 *   <TextInput
 *     ...
 *     validate={fileNameValidation}
 *     />
 */
const useFileNameValidation = () => {
  const translate = useTranslate();
  return useCallback(
    (value: string | undefined) => {
      if (!value) return null;
      const result = checkInvalidString(value);
      return result !== ''
        ? {
            message: translate('admin.validation.containInvalidString'),
            args: { msg: result },
          }
        : null;
    },
    [translate],
  );
};

export default useFileNameValidation;
