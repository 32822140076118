import React from 'react';
import {
  Toolbar,
  Typography,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles(() =>
  createStyles({
    typography: {
      fontSize: 12,
      fontWeight: 700,
    },
  }),
);

const CustomFooter: React.FC<{ className: string }> = ({ className }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Toolbar className={className}>
      <Typography className={classes.typography}>
        {translate('admin.copyright')}
      </Typography>
    </Toolbar>
  );
};

export default CustomFooter;
