import { AdminApiConverter } from '..';
import { NtripData } from '../../types';

const converter: AdminApiConverter<'ntrips', 'ntrips'> = {
  resource: 'ntrips',
  defaultSort: { field: 'name', order: 'DESC' },
  filterToApi: filter => filter,
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(
        (record): NtripData => ({
          id: record.id || '',
          name: record.name,
          serial: record.serial,
          licenceId: record.licenceId,
          customerCorporationId: record.customerCorporationId,
          customerCorporationName: record.customerCorporationName,
          ntripPass: record.ntripPass,
          ntrip: record.ntrip,
          dateOfRegistration: record.dateOfRegistration,
          billingToCustomers: record.billingToCustomers,
          paymentToKomatsu: record.paymentToKomatsu,
          numberOfExchanges: record.numberOfExchanges,
          applicantId: record.applicantId,
          applicant: record.applicant,
          requestCorporationId: record.requestCorporationId,
          requestCorporationName: record.requestCorporationName,
          isUsed: record.isUsed,
        }),
      ),
    ),
  recordToApi: record => ({
    serial: record.serial,
    customerCorporationId: record.customerCorporationId,
    customerCorporationName: record.customerCorporationName,
    ntrip: record.ntrip,
    dateOfRegistration: record.dateOfRegistration,
    billingToCustomers: record.billingToCustomers,
    paymentToKomatsu: record.paymentToKomatsu,
    numberOfExchanges: record.numberOfExchanges,
    applicantId: record.applicantId,
    applicant: record.applicant,
    requestCorporationId: record.requestCorporationId,
    requestCorporationName: record.requestCorporationName,
    isUsed: record.isUsed,
  }),
};

export default converter;
