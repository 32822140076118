import React, { FC, useMemo } from 'react';
import { RetrofitCorporationLicenseData } from '../../dataProvider';
import { LicenseIconField, ResourceFieldFC } from '..';
import { licenseToConvertElement } from '../../utils';

interface Props {
  record: RetrofitCorporationLicenseData;
  source: string;
  resource: string;
  basePath: string;
  addLabel: boolean;
  isList?: boolean;
}

const LicensePermissionField: FC<Partial<Props>> = ({
  record,
  isList = false,
}) => {
  const element = useMemo(() => {
    return licenseToConvertElement(record);
  }, [record]);

  return element ? (
    <LicenseIconField element={element} isList={isList} />
  ) : null;
};

LicensePermissionField.defaultProps = {
  addLabel: true,
};
LicensePermissionField.displayName = 'LicensePermissionField';
export default LicensePermissionField as ResourceFieldFC<Partial<Props>>;
