import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  useDataProvider,
  useNotify,
  usePermissions,
  useRefresh,
} from 'react-admin';
import { createStyles, makeStyles } from '@material-ui/core';
import {
  BooleanInput,
  CustomForm,
  Edit,
  TextInput,
  BackButton,
  Loading,
  Divider,
  ContentHeader,
  BreadcrumbsFiled,
  RetrofitAutoCompleteInput,
  DoneButton,
} from '../..';
import { Permissions } from '../../../auth';
import { SupportModeData } from '../../../dataProvider';
import { styles } from '../../../theme';
import { ReturnIcon, SaveIcon } from '../../../assets';
import { apiErrorHandler } from '../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    toggleBox: {
      position: 'relative',
      top: 12,
      left: 18,
    },
    divider: {
      marginTop: 8,
      marginBottom: 12,
      backgroundColor: 'white',
    },
    description: {
      ...styles.multilineText,
    },
    corporationId: {
      ...styles.longWidthText,
    },
    siteId: {
      ...styles.longWidthText,
    },
  }),
);

const useSupportModeUpdate = (record: SupportModeData) => {
  const { id } = record;
  const dataProvider = useDataProvider();
  return async (data: SupportModeData) => {
    return dataProvider.update('supportMode', {
      id,
      data,
      previousData: record,
    });
  };
};

interface SaveButtonProps {
  record: any;
  form: any;
  disabled: boolean;
}

const SaveButton: React.FC<Partial<SaveButtonProps>> = ({
  form,
  record,
  disabled = false,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const update = useSupportModeUpdate(record);

  const handleUpdate = useCallback(() => {
    // UpdateAPIを実行
    const { values: data } = form.getState() as {
      values: SupportModeData;
    };

    update(data)
      .then(() => {
        notify('admin.message.success', 'info');
        refresh();
      })
      .catch(error => {
        notify(apiErrorHandler(error), 'warning');
      });
  }, [form, notify, refresh, update]);

  return (
    <DoneButton
      disabled={disabled}
      label="ra.action.save"
      onClick={handleUpdate}
    >
      <SaveIcon />
    </DoneButton>
  );
};

const formValidation = (value: any) => {
  const { corporationId, siteId, enabled } = value as SupportModeData;
  if (enabled && !corporationId && !siteId) {
    return {
      corporationId: 'admin.validation.supportMode',
      siteId: 'admin.validation.supportMode',
    };
  }
  return {};
};

const SupportModeEdit: React.FC = props => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { permissions } = usePermissions();
  if (!permissions) return <Loading />;
  const {
    userRoles,
    userInfo: { email },
  } = permissions as Permissions;
  if (!userRoles.includes('SupportMode')) return null;
  if (id !== email) return null;

  return (
    <Edit {...props}>
      <CustomForm
        validate={formValidation}
        title="resources.supportMode.name"
        redirect={false}
        deleteButton={false}
        cancelButton={
          <BackButton label="admin.actions.cancel">
            <ReturnIcon />
          </BackButton>
        }
        saveButton={<SaveButton />}
        right={
          <div className={classes.toggleBox}>
            <BooleanInput resource="supportMode" source="enabled" label="" />
          </div>
        }
      >
        <BreadcrumbsFiled label="admin.breadcrumbs.supportMode" />
        <RetrofitAutoCompleteInput
          label="admin.label.supportMode.retrofit"
          source="retrofitId"
          handleChange={(value: any, form: any) => {
            const corporationId =
              value.corporationId === '' ? undefined : value.corporationId;
            const siteId = value.siteId === '' ? undefined : value.siteId;
            form.change('corporationId', corporationId);
            form.change('siteId', siteId);
          }}
        />
        <TextInput
          resource="supportMode"
          source="corporationId"
          className={classes.corporationId}
        />
        <TextInput
          resource="supportMode"
          source="siteId"
          className={classes.siteId}
        />

        <Divider className={classes.divider} />
        <ContentHeader title="admin.label.supportMode.optional" />
        <TextInput resource="supportMode" source="corporationName" />
        <TextInput resource="supportMode" source="siteName" />
        <TextInput
          resource="supportMode"
          source="description"
          className={classes.description}
          multiline
          rows={3}
        />
      </CustomForm>
    </Edit>
  );
};

export default SupportModeEdit;
