import React from 'react';
import { Record } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ReactElementLike } from 'prop-types';
import { Button } from '../..';

const useStyles = makeStyles(() =>
  createStyles({
    root: { minWidth: 'auto', verticalAlign: 'unset' },
  }),
);

interface Props {
  style: React.CSSProperties;
  children: ReactElementLike;
  onClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    record?: Record,
  ) => void;
}

const IconButton: React.FC<Partial<Props>> = ({
  onClick,
  children,
  style,
  ...rest
}) => {
  const classes = useStyles();
  const { record } = rest as { record?: Record };
  return (
    <Button
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (onClick) onClick(e, record);
      }}
      className={[classes.root, 'IconButton'].join(' ')}
      style={style}
    >
      {children}
    </Button>
  );
};

IconButton.displayName = 'IconButton';
export default IconButton;
