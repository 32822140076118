import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import { useRedirect, useTranslate } from 'react-admin';
import {
  SimpleShowLayout,
  TabData,
  TabContent,
  Divider,
  ExtensionarmList,
} from '../..';
import { styles } from '../../../theme';
import BucketList from './BucketList';
import { trimOneString } from '../../../utils';
import { useCustomLocation } from '../../../hooks';

const useCreateBucketShowTabs = (props: any): TabData[] => {
  const translate = useTranslate();
  const tabData: TabData[] = [];
  tabData.push({
    name: 'buckets',
    tab: (
      <MuiTab
        label={translate('admin.label.buckets.bucketTab')}
        key="tab_buckets"
        value="buckets"
      />
    ),
    content: <BucketList {...props} />,
    order: 1,
  });

  tabData.push({
    name: 'extensionarms',
    tab: (
      <MuiTab
        label={translate('admin.label.buckets.extensionarmTab')}
        key="tab_extensionarms"
        value="extensionarms"
      />
    ),
    content: <ExtensionarmList {...props} />,
    order: 2,
  });

  return tabData.sort((lhs, rhs) => (lhs.order > rhs.order ? 1 : -1));
};

const useStyles = makeStyles(() =>
  createStyles({
    simpleShowLayout: {
      ...styles.zeroPaddingHorizontal,
    },
  }),
);

const BucketTab: FC = props => {
  const { getBasePath } = useCustomLocation('');
  const basePath = getBasePath();
  const classes = useStyles();
  const tabs = useCreateBucketShowTabs(props);
  const redirectTo = useRedirect();
  const currentTab = trimOneString(basePath);
  const handleChange = (_event: any, newValue: string) => {
    redirectTo(`/${newValue}`);
  };
  return (
    <SimpleShowLayout className={classes.simpleShowLayout} {...props}>
      <>
        <MuiTabs value={currentTab} onChange={handleChange}>
          {tabs.map(({ tab }) => tab)};
        </MuiTabs>
        <Divider />
        {tabs.map(({ name, content }) => {
          return (
            <TabContent name={name} key={name} value={currentTab}>
              {content}
            </TabContent>
          );
        })}
      </>
    </SimpleShowLayout>
  );
};

BucketTab.displayName = 'BucketTab';
export default BucketTab;
