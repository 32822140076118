import React from 'react';
import { ReferenceField } from 'react-admin';
import { RemoteSupportField } from '..';
import { useResource } from '../../hooks';

interface Props {
  listLabel?: string | boolean;
  label?: string;
  buttonLabel?: string;
  classes?: object;
  sortable?: boolean;
}

const RetrofitRemoteSupportField: React.FC<Props> = ({
  buttonLabel = undefined,
  classes = {},
  ...props
}) => {
  const { record }: any = props;
  const resource = useResource('retrofits');
  if (!record.isOnline) {
    return <></>;
  }
  return (
    <ReferenceField
      {...props}
      resource={resource}
      source="macAddress"
      reference="panelstatus"
      link=""
    >
      <RemoteSupportField label={buttonLabel} classes={classes} />
    </ReferenceField>
  );
};
RetrofitRemoteSupportField.displayName = 'RetrofitRemoteSupportField';
export default RetrofitRemoteSupportField;
