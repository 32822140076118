import { DataProvider, CreateParams, CreateResult } from 'react-admin';

const getAsbuiltDownloadFiles = async (
  dataProvider: DataProvider,
  params: CreateParams,
): Promise<CreateResult> => {
  const { data } = await dataProvider.create('asbuiltDownloadFiles', params);
  return {
    data,
  };
};

export default getAsbuiltDownloadFiles;
