/**
 * 以下のチェック対象文字が含まれている場合に文字列配列に格納して返却
 * チェック対象文字：\ / : * ? " < >  ｜
 * @param target チェック対象文字列
 * @returns チェック対象の文字が含まれている場合、文字列を返す。含まれていない場合は空文字を返す。
 */
const checkInvalidString = (target: string): string => {
  const invalidStrings = ['\\', '/', ':', '*', '?', '"', '<', '>', '|'];
  const resultString: string[] = invalidStrings.filter(element => {
    return target.indexOf(element) > -1;
  });

  return resultString.length <= 0 ? '' : invalidStrings.join(' ');
};

/**
 * 数値変換できる文字列かのチェック
 * @param value チェック対象文字列
 * @returns 数値変換可能：true 数値変換不可：false
 */
const isInteger = (value: string): boolean => {
  const pattern = /^[-]?([1-9]\d*|0)?$/;
  return pattern.test(value);
};

/**
 * 先頭一文字を削除する
 * @param string 対象文字列
 * @returns 対象文字列の先頭を削除した文字列
 */
const trimOneString = (string: string) => {
  if (string === '') return '';
  return string.slice(1); // 先頭一文字を削除する
};

export { checkInvalidString, isInteger, trimOneString };
