import { DateField as RaDateField } from 'react-admin';
import { TypographyProps } from '@material-ui/core/Typography';
import { ResourceFieldFC } from '.';

interface Props {
  locales?: string | string[];
  options?: object;
  showTime?: boolean;
}

const DateField = RaDateField as ResourceFieldFC<Props & TypographyProps>;
DateField.displayName = 'DateField';

export default DateField;
