import { Map, Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';

// eslint-disable-next-line import/prefer-default-export
export const mapViewFitByFeature = (
  map: Map,
  feature: Feature<Geometry>,
  defaultPadding?: number,
  defaultDuration?: number,
) => {
  // 倍率と初期表示位置の自動調整
  const padding = defaultPadding || 40;
  const duration = defaultDuration || 0;
  const geometry = feature.getGeometry();
  if (!geometry) return;
  const extent = geometry.getExtent();
  map.getView().fit(extent, {
    padding: [padding, padding, padding, padding],
    duration,
  });
};
