import { GetOneParams, GetOneResult } from 'react-admin';
import { landlogReadCorporation, landlogCorporationConvert } from '..';

export default async (params: GetOneParams): Promise<GetOneResult> => {
  const { id } = params;
  // APIの実行
  const Corporation = await landlogReadCorporation(id as string);
  const data = landlogCorporationConvert(Corporation);
  return { data };
};
