import {
  MapProps,
  createProjectSourceLineWorkEPSG3857,
} from '../../../../../hooks';

const addProjectSourceLineWork = (
  { map }: MapProps,
  id: number,
  versionId: number,
  token: string,
  visibility?: boolean,
) =>
  map.addLayer(
    createProjectSourceLineWorkEPSG3857(id, versionId, token, visibility),
  );

export default addProjectSourceLineWork;
