import { RetrofitCorporationLicenseData } from '../../../../dataProvider';

export default (
  record: RetrofitCorporationLicenseData | undefined,
  usingLicenses: RetrofitCorporationLicenseData[],
) => {
  if (!record) return [];
  if (usingLicenses.length <= 0) return [record];
  // useStateを使用すると過剰レンダリングを引き起こすため、letを使用
  let newUsingLicense = usingLicenses;

  // 更新対象のライセンス種別が個別ライセンスであるか判定
  const individualLicense = [
    'MachineGuidance',
    'MachineGuidance2D',
    'PayloadMeter',
    'Asbuilt',
  ];
  // 変更前に紐づくライセンス種別一覧
  const usingLicensePermissionNow = usingLicenses.map(
    it => it.licensePermission,
  );
  // ** 通常ライセンス・Flexライセンス 付け替え処理 */
  if (
    record.licensePermission === 'FlexAll' &&
    (usingLicensePermissionNow.includes('All') ||
      usingLicensePermissionNow.includes('MachineGuidance') ||
      usingLicensePermissionNow.includes('MachineGuidance2D') ||
      usingLicensePermissionNow.includes('PayloadMeter') ||
      usingLicensePermissionNow.includes('Asbuilt'))
  ) {
    // 通常ライセンスの紐づけ解除
    newUsingLicense = usingLicenses.filter(
      it =>
        it.licensePermission !== 'All' &&
        it.licensePermission !== 'MachineGuidance' &&
        it.licensePermission !== 'MachineGuidance2D' &&
        it.licensePermission !== 'PayloadMeter' &&
        it.licensePermission !== 'Asbuilt',
    );
  }
  if (
    (record.licensePermission === 'All' ||
      record.licensePermission === 'MachineGuidance' ||
      record.licensePermission === 'MachineGuidance2D' ||
      record.licensePermission === 'PayloadMeter' ||
      record.licensePermission === 'Asbuilt') &&
    usingLicensePermissionNow.includes('FlexAll')
  ) {
    // Flexライセンスの紐づけ解除
    newUsingLicense = usingLicenses.filter(
      it => it.licensePermission !== 'FlexAll',
    );
  }

  // ** MachineGuidance・MachineGuidance2D 付け替え処理 */
  if (
    record.licensePermission === 'MachineGuidance' &&
    usingLicensePermissionNow.includes('MachineGuidance2D')
  ) {
    // MachineGuidance2Dの紐づけ解除
    newUsingLicense = usingLicenses.filter(
      it => it.licensePermission !== 'MachineGuidance2D',
    );
  }
  if (
    record.licensePermission === 'MachineGuidance2D' &&
    usingLicensePermissionNow.includes('MachineGuidance')
  ) {
    // MachineGuidanceの紐づけ解除
    newUsingLicense = usingLicenses.filter(
      it => it.licensePermission !== 'MachineGuidance',
    );
  }

  // ** All・個別ライセンス付け替え処理 */
  // ALLライセンスが紐づいている建機に対して個別ライセンスを追加で紐づける場合、
  // ALLライセンスの紐づけを解除する

  // 既に個別ライセンスが紐づいているか判定
  const updateLicenseIsIndividual = individualLicense.includes(
    record.licensePermission,
  );
  const usingLicensePermission = newUsingLicense.map(
    it => it.licensePermission,
  );
  const convertedUsingLicenses =
    usingLicensePermission.includes('All') && updateLicenseIsIndividual
      ? newUsingLicense.filter(it => it.licensePermission !== 'All')
      : newUsingLicense;

  const ignoreLicensePermission = record.licensePermission;
  return [
    record,
    ...convertedUsingLicenses.filter(
      ({ licensePermission }) => licensePermission !== ignoreLicensePermission,
    ),
  ];
};
