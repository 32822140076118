import { RetrofitData } from '../../../../dataProvider';

const adminCorporationIdsFromRetrofitData = (
  record: RetrofitData | undefined,
): string[] => {
  if (!record || !record.adminCorporations) return [];
  return record.adminCorporations
    .filter(value => !!value.corporationId)
    .map(value => {
      return value.corporationId || '';
    });
};

const isAdminCorporation = (
  adminCorporationIds: string[],
  corporationId: string,
) => {
  return adminCorporationIds.includes(corporationId);
};

// eslint-disable-next-line import/prefer-default-export
export { adminCorporationIdsFromRetrofitData, isAdminCorporation };
