import { useCallback } from 'react';

interface InParams {
  ownerIds: (string | undefined)[];
  ownerCorporationIds: string[];
}

interface OutParams {
  data: { allIncluded: boolean };
}

const useIncludesOwnerCorporationId = () => {
  return useCallback(
    async ({ ownerIds, ownerCorporationIds }: InParams): Promise<OutParams> => {
      if (ownerIds.length <= 0)
        return {
          data: {
            allIncluded: false,
          },
        };

      const allIncluded = ownerIds
        .map(
          (ownerId: string | undefined) =>
            ownerId !== undefined && ownerCorporationIds.includes(ownerId),
        )
        .every((included: boolean) => included); // 全て要素が true の場合、戻り値として true を返す

      return {
        data: {
          allIncluded,
        },
      };
    },
    [],
  );
};

export default useIncludesOwnerCorporationId;
