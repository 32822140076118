import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  TextField,
  CustomDateField,
  ImageField,
  IconField,
  Cardgrid,
  GridLayout,
  CardgridProps,
} from '..';

const useStyles = makeStyles({
  card: {
    width: 452,
  },
  image: {
    paddingRight: 12,
    '& img': {
      margin: 0,
      height: 106,
      width: 106,
    },
  },
});

const SiteCardgrid: React.FC<Partial<CardgridProps>> = props => {
  const classes = useStyles();

  return (
    <Cardgrid cardClassName={classes.card} {...props} cardClick="show">
      <GridLayout container direction="row" alignItems="stretch">
        <GridLayout item>
          <ImageField
            className={classes.image}
            resource="sites"
            source="photoUrl"
          />
        </GridLayout>
        <GridLayout
          item
          xs={12}
          sm
          container
          direction="column"
          justify="space-between"
          alignItems="stretch"
        >
          <GridLayout item>
            <TextField resource="sites" source="name" />
          </GridLayout>
          <GridLayout item>
            <TextField resource="sites" source="corporationName" />
          </GridLayout>
          <GridLayout
            item
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
          >
            <GridLayout item>
              <CustomDateField resource="sites" source="workPeriodStartDate" />
              &nbsp;-&nbsp;
              <CustomDateField resource="sites" source="workPeriodEndDate" />
            </GridLayout>
            <GridLayout item>
              <IconField resource="sites" source="status" />
            </GridLayout>
          </GridLayout>
        </GridLayout>
      </GridLayout>
    </Cardgrid>
  );
};

SiteCardgrid.displayName = 'SiteCardgrid';
export default SiteCardgrid;
