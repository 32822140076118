import { GetListParams, GetListResult } from 'react-admin';
import { CoordinateData, Point } from '..';

const getCoordinateFake = async (
  params: GetListParams,
): Promise<GetListResult> => {
  if (
    !params.filter ||
    !params.filter.projectVersionId ||
    !params.filter.points
  ) {
    return { data: [], total: 0 };
  }
  const { points } = params.filter;
  // Fake なので Filter の中身を加工して返す
  const data: CoordinateData[] = points.map((point: Point) => {
    const { n, e, latitude, longitude } = point;
    let newPoint = point;
    // 疑似変換処理
    if ((!latitude || !longitude) && n && e) {
      newPoint = {
        ...point,
        latitude: n / 100,
        longitude: e / 100,
      };
    } else if ((!n || !e) && latitude && longitude) {
      newPoint = {
        ...point,
        n: latitude * 100,
        e: longitude * 100,
      };
    }
    return {
      id: point.id,
      point: newPoint,
    };
  });
  return {
    data,
    total: data.length,
  };
};

export default getCoordinateFake;
