import React, { useEffect, useState } from 'react';
import {
  FormDataConsumer,
  Loading,
  required,
  useDataProvider,
  email as emailValidation,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { useCreate as useCreateHook } from '../../../../hooks';
import {
  BreadcrumbsFiled,
  CorporationNameField,
  CustomForm,
  Create,
  RetrofitAutoCompleteInput,
  SiteNameField,
  TextInput,
  NtripNameField,
} from '../../..';
import { RetrofitData } from '../../../../dataProvider';
import { CancelButton, SaveButton } from './views';
import { config } from '../../../../utils';

interface ApiResult {
  retrofit: RetrofitData;
  landlogCorporationId: string;
}

// メールアドレスに全角文字がないかチェック
const halfWidthValidation =
  (message = 'ra.validation.email') =>
  (value: any) =>
    typeof value === 'string' &&
    // eslint-disable-next-line no-control-regex
    /([^\x01-\x7E\uFF65-\uFF9F]|[ｦ-ﾟ])+/.test(value)
      ? message
      : undefined;

const RetrofitSendBack: React.FC = () => {
  const { retrofitId: retrofitIdString } = useParams<{ retrofitId: string }>();
  const retrofitIdNumber = Number(retrofitIdString);
  const dataProvider = useDataProvider();
  const { props } = useCreateHook('retrofitSendbacks', {
    resource: 'retrofits',
    key: 'retrofitId',
  });
  const { basePath } = props;
  const [apiState, setApiState] = useState<{
    fetched: boolean;
    loading: boolean;
    data?: ApiResult;
  }>({
    fetched: false,
    loading: true,
    data: undefined,
  });

  useEffect(() => {
    if (apiState.fetched) return;
    setApiState({ fetched: true, loading: true });

    const api = async () => {
      // 返送品レトロフィットの情報を取得
      const { data: retrofitData } = await dataProvider.getOne<RetrofitData>(
        'retrofits',
        {
          id: retrofitIdNumber,
        },
      );

      // Landlogの企業Idを取得
      const { corporationId } = config.corporation.landlog;

      return {
        retrofit: retrofitData,
        landlogCorporationId: corporationId,
      };
    };
    api().then(data => {
      setApiState({ fetched: true, loading: false, data });
    });
  }, [apiState.fetched, dataProvider, retrofitIdNumber]);

  if (apiState.loading || !apiState.data) {
    return <Loading />;
  }
  const { retrofit, landlogCorporationId } = apiState.data;
  const saveButtonProps = { retrofit, ...props };

  return (
    <Create {...props}>
      <CustomForm
        title="admin.pages.retrofitSendBack"
        saveButton={<SaveButton {...saveButtonProps} />}
        cancelButton={<CancelButton basePath={basePath} />}
        deleteButton={false}
      >
        <BreadcrumbsFiled
          breadcrumbs={['resources.retrofitBasicInfos.name']}
          label="resources.retrofitBasicInfos.tag.sendBack"
        />
        <SiteNameField
          label="resources.retrofitBasicInfos.sendBack.fields.site"
          record={retrofit}
        />
        <CorporationNameField
          label="resources.retrofitBasicInfos.sendBack.fields.corporation"
          record={retrofit}
        />
        <NtripNameField
          label="resources.retrofitBasicInfos.sendBack.fields.ntripSettingInfo"
          record={retrofit}
        />
        <FormDataConsumer>
          {({ ...rest }) => {
            return (
              <RetrofitAutoCompleteInput
                {...rest}
                label="resources.retrofitBasicInfos.sendBack.fields.toRetrofit"
                source="retrofitId"
                filter={{
                  corporationId: landlogCorporationId, // Landlogに紐づくRetrofits
                  siteId: '',
                  inSendingBackSupported: false,
                }}
                validate={required()}
                keepValidateSpace={true}
              />
            );
          }}
        </FormDataConsumer>
        <TextInput
          label="resources.retrofitBasicInfos.sendBack.fields.notificationEmailAddress"
          data-testid="notificationEmailAddress"
          resource="retrofitSendbacks"
          source="notificationEmailAddress"
          validate={[required(), emailValidation(), halfWidthValidation()]}
        />
      </CustomForm>
    </Create>
  );
};

RetrofitSendBack.displayName = 'RetrofitSendBack';
export default RetrofitSendBack;
