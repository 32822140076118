import { AdminApiConverter, ApiFilter } from '..';
import { SiteBuriedStructureData } from '../../types';

const converter: AdminApiConverter<
  'siteBuriedStructures',
  'siteBuriedStructures'
> = {
  resource: 'siteBuriedStructures',
  defaultSort: { field: 'id', order: 'DESC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'siteBuriedStructures'> = {};
    if ('id' in filter) apiFilter.id = filter.id;
    if ('siteId' in filter) apiFilter.siteId = filter.siteId;
    if ('epsgCode' in filter) apiFilter.epsgCode = filter.epsgCode;
    if ('buriedStructureFileId' in filter)
      apiFilter.buriedStructureFileId = filter.buriedStructureFileId;
    if ('blobFileStatus' in filter)
      apiFilter.blobFileStatus = filter.blobFileStatus;
    return apiFilter;
  },
  sortFieldToApi: field => {
    if (field === 'siteUserEmail')
      throw new Error('Unsupported sort field: siteUserEmail');
    if (field === 'url') throw new Error('Unsupported sort field: url');
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map((record): SiteBuriedStructureData => {
        return {
          id: record.id,
          siteId: record.siteId,
          epsgCode: record.epsgCode,
          buriedStructureFileId: record.buriedStructureFileId,
          blobFileStatus: record.blobFileStatus,
          fileTime: record.fileTime,
          url: record.url,
        };
      }),
    ),
  recordToApi: record => ({
    siteId: record.siteId,
    epsgCode: record.epsgCode,
    siteUserEmail: record.siteUserEmail,
  }),
};
export default converter;
