export default [
  { id: 'EPSG:4326', name: 'WSG84' },
  { id: 'EPSG:4269', name: 'NAD83' },
  { id: 'EPSG:3857', name: 'Pseudo-Mercator' },
  { id: 'EPSG:6669', name: 'JGD2011 1' },
  { id: 'EPSG:6670', name: 'JGD2011 2' },
  { id: 'EPSG:6671', name: 'JGD2011 3' },
  { id: 'EPSG:6672', name: 'JGD2011 4' },
  { id: 'EPSG:6673', name: 'JGD2011 5' },
  { id: 'EPSG:6674', name: 'JGD2011 6' },
  { id: 'EPSG:6675', name: 'JGD2011 7' },
  { id: 'EPSG:6676', name: 'JGD2011 8' },
  { id: 'EPSG:6677', name: 'JGD2011 9' },
  { id: 'EPSG:6678', name: 'JGD2011 10' },
  { id: 'EPSG:6679', name: 'JGD2011 11' },
  { id: 'EPSG:6680', name: 'JGD2011 12' },
  { id: 'EPSG:6681', name: 'JGD2011 13' },
  { id: 'EPSG:6682', name: 'JGD2011 14' },
  { id: 'EPSG:6683', name: 'JGD2011 15' },
  { id: 'EPSG:6684', name: 'JGD2011 16' },
  { id: 'EPSG:6685', name: 'JGD2011 17' },
  { id: 'EPSG:6686', name: 'JGD2011 18' },
  { id: 'EPSG:6687', name: 'JGD2011 19' },
];
