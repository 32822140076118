import { GetManyParams, GetManyResult } from 'react-admin';
import { callApiPanelStatus, panelstatusConvertFromApi } from '.';

export default async (params: GetManyParams): Promise<GetManyResult> => {
  const { ids } = params;

  const keyName = 'target_mac[]';
  let paramVal = '';

  // 配列型macアドレスをAPIのパラメータとして整形
  ids.forEach(id => {
    paramVal += `${keyName}=${id},`;
  });

  const result = await callApiPanelStatus(paramVal);
  const data = result ? panelstatusConvertFromApi(result) : [];

  return { data };
};
