import React from 'react';
import { GeofenceListMap } from '../view';

interface Props {
  form?: any;
}

const GeofenceListAside: React.FC<Partial<Props>> = props => {
  return (
    <div>
      <GeofenceListMap {...props} />
    </div>
  );
};

GeofenceListAside.displayName = 'GeofenceListAside';
export default GeofenceListAside;
