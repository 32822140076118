import { BucketShareData } from '../../types';

const bucketShares: BucketShareData[] = [
  {
    id: 2,
    fromBucketId: 2,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    name: '標準バケット０２',
  },
];

export default bucketShares;
