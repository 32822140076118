import React, { FC } from 'react';
import { Record } from 'react-admin';
import get from 'lodash/get';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { DownloadIcon } from '../../../assets';
import { colors } from '../../../theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'inline-flex',
      padding: '6px 18px',
      borderRadius: 60,
      marginBottom: 12,
      backgroundColor: '#717284',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        height: 16,
      },
      '& > a': {
        color: colors.text.secondaryLight,
        fontSize: 12,
        marginRight: 12,
        marginLeft: 10,
        textDecoration: 'none',
      },
    },
  }),
);

interface Props {
  record?: Record;
  source: string;
}

const IconFileField: FC<Props> = ({ record, source }) => {
  const classes = useStyles();
  if (!record) return null;
  const file = get(record, source);
  if (!file) return null;
  const sourceValue = get(file, 'src') || '';
  const titleValue = get(file, 'title') || '';
  if (!sourceValue || !titleValue) return null;
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <DownloadIcon />
        <a href={sourceValue} download>
          {titleValue}
        </a>
      </div>
    </div>
  );
};

IconFileField.displayName = 'IconFileField';
export default IconFileField;
