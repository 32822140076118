import { Map, View } from 'ol';
import { defaults } from 'ol/interaction';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { transform } from 'ol/proj';
import { OSM, Vector as SourceVector } from 'ol/source';
import { Fill, Stroke, Style, Circle } from 'ol/style';
import { Attribution } from 'ol/control';
import { useMemo } from 'react';
import { LonLat } from '../utils';

export type AlertMapResult = {
  map: Map;
  props: AlertMapProps;
};

export type AlertMapProps = {
  view: View;
  map: Map;
  sourceVector: SourceVector;
  vectorLayer: VectorLayer;
  sourceAlertVector: SourceVector;
  vectorAlertLayer: VectorLayer;
  defaultLineWork: boolean;
  record?: any;
};

const useAlertMap = (
  defaultLonLat: LonLat | undefined = { lon: 0, lat: 0 },
  showOpenStreetMap: boolean | undefined = true,
): AlertMapResult => {
  const { lon, lat } = defaultLonLat;
  return useMemo(() => {
    const openStreetMapLayer = new TileLayer({
      source: new OSM(),
    });

    const sourceVector = new SourceVector({
      features: [],
    });

    const vectorLayer = new VectorLayer({
      source: sourceVector,
      style: [
        new Style({
          // image: GeometryType.POINT のスタイル
          image: new Circle({
            fill: new Fill({
              color: 'rgba(255,255,255,0.65)',
            }),
            stroke: new Stroke({
              color: '#ff7c0a',
              width: 2,
            }),
            radius: 5,
          }),
          // GeometryType.POINT 以外のスタイル
          stroke: new Stroke({
            color: '#ff7c0a',
            width: 2,
          }),
          fill: new Fill({
            color: 'rgba(255,255,255,0.65)',
          }),
        }),
      ],
    });
    vectorLayer.setZIndex(1); // 矩形描画のレイヤは最前面にする

    const sourceAlertVector = new SourceVector({
      features: [],
    });

    const vectorAlertLayer = new VectorLayer({
      source: sourceAlertVector,
      style: [
        new Style({
          // image: GeometryType.POINT のスタイル
          image: new Circle({
            fill: new Fill({
              color: 'rgba(255,255,255,0.65)',
            }),
            stroke: new Stroke({
              color: '#ff7c0a',
              width: 2,
            }),
            radius: 5,
          }),
          // GeometryType.POINT 以外のスタイル
          stroke: new Stroke({
            color: '#ff7c0a',
            width: 2,
          }),
          fill: new Fill({
            color: 'rgba(255,255,255,0.65)',
          }),
        }),
      ],
    });
    vectorAlertLayer.setZIndex(5); // アラートレイヤーはさらに上位

    const centerPosition = [lon, lat];
    const view = new View({
      center: transform(centerPosition, 'EPSG:4326', 'EPSG:3857'),
      zoom: 18, // NOTE: 初期表示時の暫定zoom値(18) ※基本的には上書きされる
      // 拡大の最大率
      minResolution: 0.005,
    });

    // クレジット表記
    const attribution = new Attribution({
      collapsible: false,
    });

    const map = new Map({
      controls: [attribution],
      target: undefined,
      layers: showOpenStreetMap
        ? [openStreetMapLayer, vectorLayer, vectorAlertLayer]
        : [vectorLayer, vectorAlertLayer],
      interactions: defaults({
        altShiftDragRotate: false,
        doubleClickZoom: true,
        keyboard: false,
        mouseWheelZoom: true,
        shiftDragZoom: true,
        dragPan: true,
        pinchRotate: false,
        pinchZoom: true,
      }),
      view,
    });
    const props: AlertMapProps = {
      view,
      map,
      sourceVector,
      vectorLayer,
      sourceAlertVector,
      vectorAlertLayer,
      defaultLineWork: true,
    };
    return { map, props };
  }, [lon, lat, showOpenStreetMap]);
};

export default useAlertMap;
