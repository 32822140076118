import React, { ReactText, useCallback, useState } from 'react';
import { useTranslate, FormDataConsumer, useGetList } from 'react-admin';
import { createStyles, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import {
  BreadcrumbsFiled,
  Loading,
  Show,
  CustomForm,
  Button,
  TextField,
  UnitLengthField,
  CompactionWorkAreaList,
  CompactionWorkAreaEdit,
} from '../../../..';
import {
  useEditInSite,
  useCustomGetOne,
  useGetUnitLength,
  useUnitLength,
} from '../../../../../hooks';
import { getParentParentPath } from '../../../../../utils';
import {
  CompactionWorkAreaData,
  CompactionLayer,
} from '../../../../../dataProvider';
import { colors } from '../../../../../theme';
import {
  buttonWidth,
  buttonHeight,
  buttonDisabledOpacity,
} from '../../../../../theme/define';

const useEditButtonStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: colors.button.cancel,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      color: colors.text.primaryLight,
      fontSize: 14,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
      '& span': {
        padding: 0,
      },
    },
  }),
);

/**
 * 転圧施工領域に紐付いている転圧レイヤの情報を返すHooks
 */
const useLinkCompactionLayer = (compactionWorkAreaId: string) => {
  const { loading, total } = useGetList<CompactionLayer>(
    'compactionLayers',
    { page: 1, perPage: 1 },
    { field: 'id', order: 'ASC' },
    {
      compactionWorkAreaId,
      inUse: true,
    },
  );
  return {
    loading,
    using: total ? total > 0 : false, // 一つでも使用中の転圧レイヤがあれば true を返す
  };
};

const CompactionWorkAreaShow: React.FC = () => {
  const classes = useEditButtonStyles();
  const resource = 'compactionWorkAreas';
  const translate = useTranslate();
  const { projectId, id } = useParams<{
    projectId: string;
    id: string;
  }>();
  const { props, site } = useEditInSite(resource);
  const { data: projectData } = useCustomGetOne(
    'projects',
    projectId as ReactText,
  );
  const [record, setRecord] = useState<CompactionWorkAreaData | undefined>();
  const [open, setOpen] = useState(false);
  const handleEdit = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const { toDisplayUnitLength } = useUnitLength();
  const {
    loading: unitLengthLoading,
    data: { unitLength },
  } = useGetUnitLength({
    siteId: props.siteId,
  });
  const { loading, using } = useLinkCompactionLayer(id);

  if (!projectData || !site || loading || unitLengthLoading) return <Loading />;
  const { basePath } = props as { basePath: string };
  const { name: siteName } = site;
  const { name: projectName } = projectData;

  const blockSizeLabel = translate(
    'admin.label.compactionWorkAreas.blockSize',
    {
      unit: toDisplayUnitLength(unitLength),
    },
  );

  return (
    <>
      <CompactionWorkAreaEdit
        open={open}
        record={record}
        onClose={handleClose}
        unitLength={unitLength}
      />
      <Show {...props}>
        <CustomForm
          redirect={getParentParentPath(basePath)}
          title={record?.name || ''}
          resource={resource}
          actionButton={
            <Button
              className={classes.button}
              label="ra.action.edit"
              data-testid="ra.action.edit"
              onClick={handleEdit}
              disabled={using}
            />
          }
          cancelButton={false}
          deleteButton={false}
          saveButton={false}
        >
          <BreadcrumbsFiled
            breadcrumbs={[
              'resources.sites.name',
              '',
              siteName,
              'resources.projects.name',
              projectName,
            ]}
            label="admin.breadcrumbs.compactionWorkAreaShow"
          />
          <UnitLengthField
            resource={resource}
            source="blockSize"
            label={blockSizeLabel}
            unitLength={unitLength}
          />
          <TextField resource={resource} source="description" />
          <FormDataConsumer>
            {({ formData }) => {
              setRecord(formData);
              return null;
            }}
          </FormDataConsumer>
        </CustomForm>
      </Show>
      {record ? (
        <CompactionWorkAreaList
          basePath={`${basePath}/${record.id}`}
          compactionWorkAreaId={record.id}
          unitLength={unitLength}
        />
      ) : null}
    </>
  );
};

CompactionWorkAreaShow.displayName = 'CompactionWorkAreaShow';
export default CompactionWorkAreaShow;
