import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { AssignmentData } from '../dataProvider';

interface InParams {
  retrofitIds: number[];
}

interface OutParams {
  data: {
    isTransfering: boolean;
    transferRetrofitIds: number[];
  };
}

const useRetrofitTransfering = () => {
  const dataProvider = useDataProvider();
  return useCallback(
    async ({ retrofitIds }: InParams): Promise<OutParams> => {
      const { data: transfers } = await dataProvider.getList<AssignmentData>(
        'assignments',
        {
          pagination: {
            page: 1,
            perPage: 100,
          },
          sort: {
            field: 'id',
            order: 'DESC',
          },
          filter: {
            retrofitId: retrofitIds,
            isApproved: false,
          },
        },
      );

      return {
        data: {
          isTransfering: transfers.length > 0,
          transferRetrofitIds: transfers.map(({ retrofitId }) => retrofitId),
        },
      };
    },
    [dataProvider],
  );
};

export default useRetrofitTransfering;
