import React, { FC } from 'react';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { CustomAppBar, CustomFooter, Notification, Typography } from '../..';
import { ExclamationMark } from '../../../assets';
import { useLayoutStyles, useTranslateMultiLine } from '../../../hooks';
import { getDefaultTheme } from '../../../theme';

const MaintenancePage: FC = () => {
  const classes = useLayoutStyles(getDefaultTheme());
  const translateMultiLine = useTranslateMultiLine();

  return (
    <MuiThemeProvider theme={getDefaultTheme()}>
      <div className={classes.root}>
        <CssBaseline />
        <div className={classes.appFrame}>
          <div className={classes.appBar}>
            <CustomAppBar login />
          </div>
          <main className={classes.contentFrame}>
            <div className={classes.contentContainer}>
              <div className={classes.maintenance}>
                <div className={classes.exclamation}>
                  <ExclamationMark />
                </div>
                <Typography className={classes.title}>
                  {translateMultiLine('admin.label.maintenance.title')}
                </Typography>
                <Typography className={classes.message}>
                  {translateMultiLine('admin.label.maintenance.message')}
                </Typography>
              </div>
            </div>
            <CustomFooter className={classes.footer} />
          </main>
          <Notification />
        </div>
      </div>
    </MuiThemeProvider>
  );
};

MaintenancePage.displayName = 'MaintenancePage';
export default MaintenancePage;
