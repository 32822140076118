import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Tile as TileLayer } from 'ol/layer';
import { Map } from 'ol';
import {
  useMap,
  MapProps,
  coordinatesToLonLatArray,
  setOverlayRender,
  useRerender,
  createProjectSourceLayerEPSG3857,
  createProjectSourceLineWorkEPSG3857,
  createProjectSourceLayerLabelPointEPSG3857,
  createProjectSourceLayerPointLabelEPSG3857,
  createProjectSourceLocationPointEPSG3857,
  createProjectSourceLocationLabelEPSG3857,
} from '../../../hooks';
import { LonLat, logger, projectBoundaryToLonLat } from '../../../utils';
import {
  TaskInputDialog,
  TaskMapModel,
  checkIntersectByArray,
  checkSameValueByArray,
  checkAreaZeroByArray,
  TaskMapController,
} from '../..';
import {
  ProjectsData,
  UnitLength,
  ProjectSourceLayerData,
} from '../../../dataProvider';

const THRESHOLD_SHOW_LABEL_ZOOM_VALUE = 17; // 基準点ラベル表示の閾値（値が小さほど広域となる）

const projectSourceLayersToCoodinates = (
  projectSourceLayers: ProjectSourceLayerData[],
  layerId: number,
): LonLat[] => {
  // 指定レイヤIDの ProjectSourceLayer を取得
  const [projectSourceLayer] = projectSourceLayers.filter(
    it => it.mapServerProjectSourceLayerId === layerId,
  );
  if (!projectSourceLayer) return [];
  // ProjectBoundary から LonLat型配列に変換
  const { projectBoundary } = projectSourceLayer;
  if (!projectBoundary) return [];
  return projectBoundaryToLonLat(projectBoundary);
};

const useStyle = makeStyles(() =>
  createStyles({
    root: { width: '960px', height: '470px', position: 'relative' },
    blockView: {
      position: 'absolute',
      top: 0,
      pointerEvents: 'auto', // 背面に click を伝達させない
    },
    hide: {
      display: 'none',
    },
  }),
);

interface MapLayer {
  layer: TileLayer | undefined;
}

interface TaskMapProps {
  onBindModel?: (model: TaskMapModel) => void;
  onModify?: () => void;
  coordinates?: number[][][];
  projectsData: ProjectsData;
  isEditable: boolean;
  layerId?: number;
  isBlocking?: boolean;
  token: string;
  unit?: UnitLength;
  projectVersionId: number;
  mapBackgroundColor?: string;
}

const TaskMap: React.FC<TaskMapProps> = ({
  onBindModel,
  onModify,
  coordinates,
  projectsData,
  isEditable,
  layerId,
  token,
  unit = 'M',
  projectVersionId,
  mapBackgroundColor = '#ffffff', // white
}) => {
  const { projectLocation, projectSourceLayers } = projectsData;
  const classes = useStyle();

  const [mapProps, setMapProps] = useState<MapProps | undefined>(undefined);
  const [mapLayer] = useState<MapLayer>({ layer: undefined });
  const [showLineWork, setShowLineWork] = useState<boolean>();
  const [taskInput, setTaskInput] = useState<{
    open: boolean;
    lonLat: LonLat[];
  }>({ open: false, lonLat: [] });
  const [mapModel] = useState<TaskMapModel>(new TaskMapModel());
  const [mapController] = useState<TaskMapController>(new TaskMapController());
  const rerender = useRerender();

  // 指定の ProjectBoundary 値を設定
  const targetLayerBoundary = layerId
    ? projectSourceLayersToCoodinates(projectSourceLayers, layerId)
    : [];
  mapController.setNavigateHomeBoundary(targetLayerBoundary);

  // --------------------------------------------
  // 入力ダイアログの表示イベント
  // --------------------------------------------
  const handleShow = useCallback(
    async (lonLatArray: LonLat[]) => {
      if (lonLatArray.length <= 0) {
        setTaskInput({ ...taskInput, open: true, lonLat: [] });
        return;
      }
      // 入力ダイアログのパラメータを設定
      setTaskInput({ ...taskInput, open: true, lonLat: lonLatArray });
    },
    [taskInput],
  );

  // --------------------------------------------
  // 入力ダイアログの非表示イベント
  // --------------------------------------------
  const handleHide = useCallback(
    (lonLat: LonLat[] | undefined) => {
      if (mapProps && lonLat) {
        mapModel.setModified();
        if (onModify) onModify();
        // Map 上のポリゴンを更新
        mapController.update(lonLat);
      }
      // 入力ダイアログのパラメータを設定
      setTaskInput({ ...taskInput, open: false });
    },
    [mapModel, mapProps, mapController, taskInput, onModify],
  );

  // --------------------------------------------
  // 入力ダイアログ確定前のポリゴン交差チェック
  // --------------------------------------------
  const handleIntersectCheck = useCallback((lonLat: LonLat[]) => {
    // 末尾に先頭要素を追加する対応
    const newLonLat = lonLat.length > 0 ? [...lonLat, lonLat[0]] : [];
    // 引数用の配列型に変換
    const checkArray = newLonLat.map(({ lon, lat }) => [lon, lat]);
    // 交差チェック
    return checkIntersectByArray(checkArray);
  }, []);

  // --------------------------------------------
  // 入力ダイアログ確定前のポリゴン同一チェック
  // --------------------------------------------
  const handleSameValueCheck = useCallback((lonLat: LonLat[]) => {
    // 引数用の配列型に変換
    const checkArray = lonLat.map(({ lon, lat }) => [lon, lat]);
    // 同一チェック
    return checkSameValueByArray(checkArray);
  }, []);

  // --------------------------------------------
  // 入力ダイアログ確定前の面積ゼロチェック
  // --------------------------------------------
  const handleAreaZeroCheck = useCallback((lonLat: LonLat[]) => {
    // 末尾に先頭要素を追加する対応
    const newLonLat = lonLat.length > 0 ? [...lonLat, lonLat[0]] : [];
    // 引数用の配列型に変換
    const checkArray = newLonLat.map(({ lon, lat }) => [lon, lat]);
    // 面積ゼロチェック
    return checkAreaZeroByArray(checkArray);
  }, []);

  // --------------------------------------------
  // ラインワークの設定イベント
  // --------------------------------------------
  const handleLineWork = useCallback((newLineWork: boolean) => {
    setShowLineWork(newLineWork);
  }, []);

  // --------------------------------------------
  // OpenLayerの初期化
  // --------------------------------------------
  const { map: olmap, props } = useMap(undefined, false) as {
    map: Map;
    props: MapProps;
  };
  const renderZoom = olmap.getView().getZoom() || 0; // 描画時のズーム値
  const version = projectsData.latestProjectVersionId;

  // --------------------------------------------
  // プロジェクトレイヤー（TIN）の初期化
  // --------------------------------------------
  const addProjectLayer = useMemo(() => {
    if (!layerId) return undefined;
    mapController.navigateToHome(); // layerId 切り替わり時は表示位置を元に戻す
    return createProjectSourceLayerEPSG3857(layerId, version, token);
  }, [version, layerId, token, mapController]);

  if (mapLayer.layer) {
    olmap.removeLayer(mapLayer.layer);
    mapLayer.layer = undefined;
  }
  if (addProjectLayer && layerId) {
    mapLayer.layer = addProjectLayer;
    olmap.addLayer(addProjectLayer);
  }

  // --------------------------------------------
  // ラインワークの初期化
  // --------------------------------------------
  const { projectSourceLineWorks } = projectsData;
  const lineWorkStrIds = projectSourceLineWorks
    .map(lineWorks => lineWorks.mapServerProjectSourceLineWorkId)
    .join(','); // NOTE: 意図的に Int(Array) を String にしている
  const addLineWorkLayer = useMemo(() => {
    const lineWorkIds = lineWorkStrIds.split(',').map(strId => Number(strId)); // String -> Int(Array)
    return lineWorkIds.map(id => {
      return createProjectSourceLineWorkEPSG3857(id, version, token);
    });
  }, [version, token, lineWorkStrIds]); // NOTE: Array(Int) で扱うと、再度 useMemo のラムダが実行されるので String で扱う

  useEffect(() => {
    addLineWorkLayer.forEach(layer => olmap.addLayer(layer));
    return () => {
      addLineWorkLayer.forEach(layer => olmap.removeLayer(layer));
    };
  }, [addLineWorkLayer, olmap]);

  // --------------------------------------------
  // 設計ポイントマークの初期化
  // --------------------------------------------
  const addProjectLayerLabelPoint = useMemo(() => {
    return createProjectSourceLayerLabelPointEPSG3857(version, token);
  }, [version, token]);

  if (addProjectLayerLabelPoint) {
    olmap.removeLayer(addProjectLayerLabelPoint);
    olmap.addLayer(addProjectLayerLabelPoint);
  }

  // --------------------------------------------
  // 設計ポイントラベルの初期化
  // --------------------------------------------
  const showLayerPointLabel = renderZoom >= THRESHOLD_SHOW_LABEL_ZOOM_VALUE;

  const addProjectLayerPointLabel = useMemo(() => {
    return createProjectSourceLayerPointLabelEPSG3857(version, token);
  }, [version, token]);

  if (addProjectLayerPointLabel) {
    olmap.removeLayer(addProjectLayerPointLabel);
    if (showLayerPointLabel) {
      olmap.addLayer(addProjectLayerPointLabel);
    }
  }

  // --------------------------------------------
  // 基準点マークの初期化
  // --------------------------------------------
  const addProjectLayerLocationPoint = useMemo(() => {
    return createProjectSourceLocationPointEPSG3857(version, token);
  }, [version, token]);

  if (addProjectLayerLocationPoint) {
    olmap.removeLayer(addProjectLayerLocationPoint);
    olmap.addLayer(addProjectLayerLocationPoint);
  }

  // --------------------------------------------
  // 基準点ラベルの初期化
  // --------------------------------------------
  const showLayerLocationLabel = renderZoom >= THRESHOLD_SHOW_LABEL_ZOOM_VALUE;

  const addProjectLayerLocationLabel = useMemo(() => {
    return createProjectSourceLocationLabelEPSG3857(version, token);
  }, [version, token]);

  if (addProjectLayerLocationLabel) {
    olmap.removeLayer(addProjectLayerLocationLabel);
    if (showLayerLocationLabel) {
      olmap.addLayer(addProjectLayerLocationLabel);
    }
  }

  // --------------------------------------------
  // ラインワーク表示ON/OFF
  // --------------------------------------------
  if (showLineWork !== undefined) {
    addLineWorkLayer.forEach(it => it.setVisible(showLineWork));
    if (addProjectLayerLabelPoint) {
      addProjectLayerLabelPoint.setVisible(showLineWork);
    }
    if (addProjectLayerPointLabel) {
      addProjectLayerPointLabel.setVisible(showLineWork);
    }
    if (addProjectLayerLocationPoint) {
      addProjectLayerLocationPoint.setVisible(showLineWork);
    }
    if (addProjectLayerLocationLabel) {
      addProjectLayerLocationLabel.setVisible(showLineWork);
    }
  }

  // --------------------------------------------
  // OpenLayer 側でのポリゴン差分検知
  // --------------------------------------------
  const handleModify = useCallback(() => {
    mapModel.setModified();
    if (onModify) onModify();
  }, [mapModel, onModify]);

  // --------------------------------------------
  // ズームの変更通知
  // --------------------------------------------
  const handleZoomEnd = useCallback(
    zoom => {
      logger().debug('zoom', zoom);
      // ズーム変更時はコンポーネントを再描画させる
      rerender();
    },
    [rerender],
  );

  // --------------------------------------------
  // OpenLayer レンダリング後の初期化処理
  // --------------------------------------------
  const mapRef = useRef<HTMLDivElement>(null);
  const blockingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOverlayRender(true);
    setMapProps(props);
    olmap.setTarget('map');

    if (blockingRef.current) {
      blockingRef.current.style.width = `${mapRef.current?.clientWidth}px`;
      blockingRef.current.style.height = `${mapRef.current?.clientHeight}px`;
    }
    const inited = mapController.init(
      props,
      isEditable,
      handleShow,
      handleLineWork,
      handleModify,
      handleZoomEnd,
    );

    if (!inited && coordinates) {
      // 二次元配列 [ [e,n], [e,n] ] から ローカル座標 { e, n }[] に変換
      const orgLocalCoordinates = coordinatesToLonLatArray(coordinates);
      const localCoordinates = [...orgLocalCoordinates];

      // 末尾の座標要素を削る対応 (先頭要素と同じデータが入っている為)
      localCoordinates.pop();

      // Map 上のポリゴンを更新
      mapController.update(localCoordinates);
      mapController.updateState();
    } else if (!inited) {
      mapController.navigateToHome();
      mapController.updateState();
    }

    if (onBindModel && mapModel) {
      mapModel.setSourceVector(props.sourceVector);
      onBindModel(mapModel);
    }

    return () => {
      // Unmount
      setOverlayRender(false);
    };
  }, [
    olmap,
    props,
    handleShow,
    handleLineWork,
    handleModify,
    handleZoomEnd,
    onBindModel,
    mapModel,
    coordinates,
    projectLocation,
    isEditable,
    mapRef,
    blockingRef,
    mapController,
    showLineWork,
    mapProps,
    projectVersionId,
  ]);

  return (
    <>
      <div
        id="map"
        className={classes.root}
        ref={mapRef}
        style={{ backgroundColor: mapBackgroundColor }}
      />
      <TaskInputDialog
        open={taskInput.open}
        onClose={handleHide}
        onIntersectCheck={handleIntersectCheck}
        onSameValueCheck={handleSameValueCheck}
        onAreaZeroCheck={handleAreaZeroCheck}
        lonLat={taskInput.lonLat}
        unit={unit}
      />
    </>
  );
};

TaskMap.displayName = 'TaskMap';
export default TaskMap;
