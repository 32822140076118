import React, { ReactText, useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  required,
  useTranslate,
  useDataProvider,
  useNotify,
  useRedirect,
} from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  BreadcrumbsFiled,
  Loading,
  CustomEdit,
  CustomForm,
  LinkButton,
  TextInput,
  SelectInput,
  ReferenceInput,
  IconField,
  Button,
  UpdateButton,
  DialogOKCancel,
  CustomNumberInput,
  Record,
} from '../../../..';
import {
  useEditInSite,
  useCustomGetOne,
  useUIntegerValidation,
  useGetUnitLength,
  useUnitLength,
  useGetSelefDetailData,
} from '../../../../../hooks';
import { styles, colors } from '../../../../../theme';
import { DeleteIcon, ReturnIcon } from '../../../../../assets';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../../../../theme/define';
import { apiErrorHandler } from '../../../../../utils';
import {
  CompactionLayerData,
  CompactionWorkAreaData,
  ProjectsData,
} from '../../../../../dataProvider';

const useStyles = makeStyles(() =>
  createStyles({
    description: {
      ...styles.multilineText,
    },
    icon: {
      '& > div': {
        marginLeft: -12,
      },
    },
  }),
);

const useDeleteButtonStyles = makeStyles(() =>
  createStyles({
    button: {
      background: colors.button.delete,
      color: colors.text.primaryLight,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        background: colors.button.deleteHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.deleteDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
  }),
);

const SaveButton: React.FC<{
  resource: string;
  record?: any;
  disabled?: boolean;
  transform: (record: Record<any>) => Record<any>;
}> = ({ resource, record, disabled, transform, ...rest }) => {
  if (!record) throw Error('Invalid record');
  const { inUse } = record;
  return (
    <UpdateButton
      resource={resource}
      disabled={disabled || inUse}
      record={record}
      transform={transform}
      {...rest}
    />
  );
};

interface RaDeleteProps {
  resource: string;
  record: any;
  disabled: boolean;
  basePath: string;
}

type DeleteButtonProps = Partial<RaDeleteProps>;

const DeleteButton: React.FC<DeleteButtonProps> = ({
  resource,
  record,
  disabled,
  basePath,
}) => {
  if (!resource) throw Error('Invalid resource');
  if (!record) throw Error('Invalid record');
  const { id, inUse } = record;

  const [confirmOpen, setConfirmOpen] = useState(false);
  const deleteButtonClasses = useDeleteButtonStyles();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const translate = useTranslate();
  const title = translate(
    `admin.dialog.compactionLayers.deleteConfirm.messageTargetItem`,
  );
  const translatedMessage = translate('admin.message.deleteConfirmContent', {
    title,
  });
  const dataProvider = useDataProvider();

  const handleUpdate = useCallback(() => {
    setConfirmOpen(false);
    const fn = async () => {
      try {
        await dataProvider.update(resource, {
          id,
          data: { ...record, enable: false },
          previousData: record,
        });
        notify('ra.notification.deleted', 'info', { smart_count: 1 });
        redirectTo(basePath || '');
      } catch (error) {
        notify(apiErrorHandler(error), 'warning');
      }
    };
    fn();
  }, [
    dataProvider,
    resource,
    id,
    record,
    basePath,
    notify,
    redirectTo,
    setConfirmOpen,
  ]);

  return (
    <>
      <DialogOKCancel
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onOKClick={handleUpdate}
        title=""
        message={translatedMessage}
        nonTranslateMessage={true}
        isDelete={true}
      />
      <Button
        data-testid="ra.action.delete"
        onClick={() => setConfirmOpen(true)}
        disabled={disabled || inUse}
        className={deleteButtonClasses.button}
      >
        <DeleteIcon />
      </Button>
    </>
  );
};

const CancelButton: React.FC<{ basePath?: string }> = ({ basePath }) => {
  return (
    <LinkButton path={basePath} label="ra.action.cancel">
      <ReturnIcon />
    </LinkButton>
  );
};

const CompactionLayerEdit: React.FC = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const resource = 'compactionLayers';
  const { siteId, projectId, compactionWorkAreaId } = useParams<{
    siteId: string;
    projectId: string;
    compactionWorkAreaId: string;
  }>();
  const { props, site } = useEditInSite(resource);
  const compactionLayerId = props.id;
  const getSelefDetailData = useGetSelefDetailData();
  const redirectTo = useRedirect();
  const dataProvider = useDataProvider();
  const [apiState, setApiState] = useState<{
    fetched: boolean;
    compactionLayerId: string | undefined;
    data?: { isOwnData?: boolean; hasData?: boolean };
  }>({
    fetched: false,
    compactionLayerId,
    data: undefined,
  });
  const integerValidation = useUIntegerValidation();
  const { data: projectData } = useCustomGetOne(
    'projects',
    projectId as ReactText,
  );
  const { data: compactionWorkAreaData } = useCustomGetOne(
    'compactionWorkAreas',
    compactionWorkAreaId as ReactText,
  );
  const { toDisplayUnitLength, feetToUnitLength, meterToUnitLengthRoundUp } =
    useUnitLength();
  const {
    loading: unitLengthLoading,
    data: { unitLength },
  } = useGetUnitLength({ siteId });

  // 自社の転圧レイヤIdか判定
  useEffect(() => {
    if (apiState.fetched && apiState.compactionLayerId === compactionLayerId)
      return;
    setApiState({ fetched: true, compactionLayerId });

    const api = async () => {
      // compactionWorkArea
      const { data: compactionWorkArea } =
        await dataProvider.getOne<CompactionWorkAreaData>(
          'compactionWorkAreas',
          {
            id: compactionWorkAreaId,
          },
        );

      // projects
      // URL上のprojectIdと転圧施工領域が紐づくprojectIdが一致するか判定する
      const { projectId: projectDataId } = compactionWorkArea;
      const { data: project } = await dataProvider.getOne<ProjectsData>(
        'projects',
        {
          id: projectDataId,
        },
      );

      let isOwnData = true;
      if (Number(projectId) !== projectDataId) {
        isOwnData = false;
      }

      // sites
      // URL上のsiteIdとプロジェクトが紐づくsiteIdが一致するか判定する
      const { siteId: siteDataId } = project;
      if (siteId !== siteDataId) {
        isOwnData = false;
      }

      const filter = {
        siteId,
        projectId: Number(projectId),
        compactionWorkAreaId,
        enable: true,
      };

      const compactionLayer = await getSelefDetailData(
        resource,
        filter,
        compactionLayerId,
      );
      return {
        isOwnData,
        hasData: !!compactionLayer,
      };
    };
    api().then(data => {
      setApiState({ fetched: true, compactionLayerId, data });
    });
  }, [
    apiState.fetched,
    apiState.compactionLayerId,
    getSelefDetailData,
    redirectTo,
    dataProvider,
    resource,
    compactionLayerId,
    compactionWorkAreaId,
    projectId,
    siteId,
  ]);

  if (
    !projectData ||
    !site ||
    !compactionWorkAreaData ||
    unitLengthLoading ||
    !apiState.data
  )
    return <Loading />;
  const { isOwnData, hasData } = apiState.data;
  if (!isOwnData || !hasData) redirectTo('/');
  const { basePath } = props as { basePath: string };
  const { name: siteName } = site;
  const { name: projectName } = projectData;
  const { name: compactionWorkAreaName } = compactionWorkAreaData;

  const thicknessLabel = translate('admin.label.compactionLayers.thickness', {
    unit: toDisplayUnitLength(unitLength),
  });

  const roundUpDigits = 3; // 小数第四位で四捨五入し小数第三位で扱う

  // 初期表示用の単位変換
  const handleInitialTransfrom = (record: CompactionLayerData) => ({
    ...record,
    thickness:
      // ft 系単位の場合は m -> ft へ変換する
      meterToUnitLengthRoundUp(record.thickness, unitLength, roundUpDigits),
  });
  // 保存用の単位変換
  const handleSaveTransform = (record: CompactionLayerData) => ({
    ...record,
    thickness:
      // ft 系単位の場合は ft -> m へ変換する
      feetToUnitLength(record.thickness, unitLength),
  });

  return (
    <CustomEdit {...props} transform={handleInitialTransfrom}>
      <CustomForm
        redirect={basePath}
        title="admin.pages.compactionLayerEdit"
        resource={resource}
        cancelButton={<CancelButton basePath={basePath} />}
        deleteButton={<DeleteButton />}
        saveButton={
          <SaveButton resource={resource} transform={handleSaveTransform} />
        }
        initialValues={{
          description: '',
          inUse: false,
          enable: true,
        }}
      >
        <BreadcrumbsFiled
          breadcrumbs={[
            'resources.sites.name',
            '',
            siteName,
            'resources.projects.name',
            projectName,
            '',
            compactionWorkAreaName,
          ]}
          label="admin.breadcrumbs.compactionLayerEdit"
        />
        <TextInput resource={resource} source="name" validate={[required()]} />
        <CustomNumberInput
          resource={resource}
          source="thickness"
          label={thicknessLabel}
          validate={[required()]}
          limitDecimal={roundUpDigits}
        />
        <CustomNumberInput
          resource={resource}
          source="passCount"
          validate={[required(), integerValidation]}
        />
        <ReferenceInput
          resource={resource}
          source="compactionMaterialId"
          reference="compactionMaterials"
          link=""
          sort={{ field: 'name', order: 'ASC' }}
          filter={{ siteId }}
        >
          <SelectInput resource={resource} source="compactionMaterialId" />
        </ReferenceInput>
        <IconField
          resource={resource}
          source="inUse"
          className={classes.icon}
        />
        <TextInput
          resource={resource}
          source="description"
          multiline
          rows={3}
          className={classes.description}
        />
      </CustomForm>
    </CustomEdit>
  );
};

CompactionLayerEdit.displayName = 'CompactionLayerEdit';
export default CompactionLayerEdit;
