import { GetListResult } from 'react-admin';
import { callApiUnusedTotal, UnusedTotalData } from '.';

export default async (): Promise<GetListResult> => {
  // 戻り値に、getOneと同様の型であるUpdateResultを使用する
  const unusedTotalData = await callApiUnusedTotal();
  const data: UnusedTotalData = {
    id: 1,
    unusedTotal: unusedTotalData.unusedTotal,
  };
  return { data: [data], total: 1 };
};
