import { Edit } from 'react-admin';

const { defaultProps } = Edit;
Edit.defaultProps = {
  ...defaultProps,
  successMessage: 'admin.message.success',
  undoable: false,
  mutationMode: 'optimistic',
};
Edit.displayName = 'Edit';
export default Edit;
