import React, { FC, ChangeEvent, useEffect, useState } from 'react';
import { SelectInput } from 'react-admin';
import { ProjectsData } from '../../../../dataProvider';
import { useGetListAllPage } from '../../../../hooks';
import { findRecordById } from '../../../../utils';
import { geofenceStore } from '../utils/index';

interface Props {
  siteId: string;
  onChangeProject?: (project: ProjectsData) => void;
}

const GeofenceListProjectInput: FC<Props> = ({ siteId, onChangeProject }) => {
  const getListAllPage = useGetListAllPage();
  const [fetched, setFetched] = useState(false);
  const [projects, setProjects] = useState<ProjectsData[]>([]);
  const [defaultProject, setDefaultProject] = useState<
    ProjectsData | undefined
  >();

  useEffect(() => {
    if (fetched) return; // NOTE: 再取得防止
    setFetched(true);

    // Projectデータを取得
    getListAllPage({
      resource: 'projects',
      filter: {
        siteId,
        status: 'Converted',
      },
      sort: {
        field: 'name',
        order: 'ASC',
      },
    }).then(result => {
      const projectData = result.data as ProjectsData[];
      setProjects(projectData);
      // ローカルストレージのジオフェンスプロジェクト選択情報を取得
      const storedGeofenceProject = geofenceStore.get();
      if (storedGeofenceProject?.siteId === siteId) {
        // プロジェクト一覧の中にローカルストレージのジオフェンスプロジェクト情報と一致するものがあれば、初期選択状態に設定する
        const defaultSelectProject = projectData.find(
          e => e.id === storedGeofenceProject.projectId,
        );
        if (defaultSelectProject) {
          setDefaultProject(defaultSelectProject);
          if (onChangeProject) {
            onChangeProject(defaultSelectProject);
          }
        }
      } else {
        // ローカルストレージのジオフェンスプロジェクト情報の siteId が現在の siteId と違う場合はローカルストレージをクリア
        setDefaultProject(undefined);
        geofenceStore.remove();
      }
    });
  }, [fetched, siteId, getListAllPage, onChangeProject]);

  const handleChange = ({
    target: { value: projectId },
  }: ChangeEvent<HTMLInputElement>) => {
    if (!onChangeProject) return;
    const project = findRecordById<ProjectsData>(projects, projectId);
    onChangeProject(project);
  };

  return (
    <div>
      <SelectInput
        record={{ id: 1 }}
        source="projectId"
        optionText="name"
        label="admin.label.geofences.project"
        resettable={false}
        choices={projects
          .map(({ id, name }) => ({ id, name }))
          .sort((lhs, rhs) => {
            return lhs.name > rhs.name ? 1 : -1;
          })}
        onChange={handleChange}
        style={{ boxSizing: 'border-box' }}
        defaultValue={defaultProject?.id}
      />
      <div style={{ height: 8 }} />
    </div>
  );
};

GeofenceListProjectInput.displayName = 'GeofenceListProjectInput';
export default GeofenceListProjectInput;
