import React, { FC, useState, useEffect } from 'react';
import { BreadcrumbsFiled } from '../..';
import { CenterContainer, Title, Message, TermsCondition } from './views';
import { useAgreementStore } from '../../../hooks';

const ShowTerms: FC = () => {
  const [termsConditionId, setTermsConditionId] = useState<
    number | undefined
  >();
  const { fetchLicenseAgreement } = useAgreementStore();

  useEffect(() => {
    const api = async () => {
      const data = await fetchLicenseAgreement();
      if (data) {
        return data.termsConditionId;
      }
      return undefined;
    };
    api().then(it => setTermsConditionId(it));
  }, [fetchLicenseAgreement]);

  return (
    <>
      <BreadcrumbsFiled label="admin.breadcrumbs.terms" />
      <CenterContainer>
        <Title />
        <Message label="admin.label.terms.message" />
        {termsConditionId && (
          <TermsCondition termsConditionId={termsConditionId} />
        )}
      </CenterContainer>
    </>
  );
};

ShowTerms.displayName = 'ShowTerms';
export default ShowTerms;
