import React, { FC, useState } from 'react';
import {
  Identifier,
  useTranslate,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { Dialog, makeStyles, createStyles, Box } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {
  NormalButton,
  CustomForm,
  TextInput,
  Divider,
  FunctionField,
  CheckBox,
  FinalFormConsumer,
  PropsConsumer,
  DialogOKOnly,
} from '../../..';
import { ReturnIcon } from '../../../../assets';
import BulkRegistrationSaveButton from './BulkRegistrationSaveButton';
import { FormState, initialFormState } from './BulkRegistrationSaveButtonState';

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      minWidth: 561,
      '& .selectCount': {
        fontSize: 18,
        marginTop: 12,
      },
      '& .serialNumber': {
        fontSize: 14,
      },
      '& .dividerTop': {
        marginTop: 16,
      },
    },
    checkBox: {
      marginTop: 14,
      marginRight: 14,
    },
    indent: {
      width: 38,
    },
  }),
);

const SelectedCounter: FC<{ count: number }> = ({ count }) => {
  const translate = useTranslate();
  return (
    <div className="selectCount">
      {translate('admin.label.selected', {
        length: count,
      })}
    </div>
  );
};

interface Props {
  open: boolean;
  onClose: () => void;
  ids: Identifier[];
  serialNumbers: string[];
  hasLending: boolean;
  lendingSerialNumberText: string;
}

const BulkRegistrationDialog: FC<Props> = ({
  open,
  onClose,
  ids,
  serialNumbers,
  hasLending,
  lendingSerialNumberText,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [formState, setFormState] = useState<FormState>(initialFormState);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const saveButtonDisabled = formState.checkCount === 0;
  return (
    <>
      <DialogOKOnly
        open={errorDialogOpen}
        onClose={() => setErrorDialogOpen(false)}
        title=""
        nonTranslateMessage={true}
        messages={[
          translate('admin.dialog.retrofitBasicInfoList.save.message1'),
          translate('admin.dialog.retrofitBasicInfoList.save.message2'),
          translate('admin.dialog.retrofitBasicInfoList.save.message3'),
          translate('admin.dialog.retrofitBasicInfoList.save.message4'),
          lendingSerialNumberText,
        ]}
      />
      <Dialog
        open={open}
        onClose={onClose}
        data-testid="bulk_registration_dialog"
      >
        {open ? (
          <>
            <MuiDialogTitle>
              {translate(
                'admin.label.retrofitBasicInfos.bulkRegistrationTitle',
              )}
            </MuiDialogTitle>
            <MuiDialogContent className={classes.dialog}>
              <CustomForm
                bottomToolbar={true}
                cancelButton={
                  <NormalButton label="admin.actions.cancel" onClick={onClose}>
                    <ReturnIcon />
                  </NormalButton>
                }
                saveButton={
                  <PropsConsumer>
                    {({ form }) => (
                      <BulkRegistrationSaveButton
                        ids={ids}
                        disabled={saveButtonDisabled}
                        form={form}
                        formState={formState}
                      />
                    )}
                  </PropsConsumer>
                }
              >
                <FinalFormConsumer>
                  {({ form }) => (
                    <>
                      {/* 販売元 */}
                      <Box display="flex">
                        <CheckBox
                          testId="distributor"
                          className={classes.checkBox}
                          onChecked={checked => {
                            setFormState({
                              ...formState,
                              disableRetrofitDistributor: !checked,
                              checkCount:
                                checked === true
                                  ? formState.checkCount + 1
                                  : formState.checkCount - 1,
                            });
                            if (!checked) {
                              form.change('retrofitDistributorId', undefined);
                            }
                          }}
                        />
                        <ReferenceInput
                          resource="retrofits"
                          source="retrofitDistributorId"
                          reference="retrofitDistributors"
                          label="admin.label.retrofitBasicInfos.retrofitDistributor"
                          sort={{ field: 'name', order: 'ASC' }}
                          disabled={formState.disableRetrofitDistributor}
                        >
                          <SelectInput
                            resource="retrofits"
                            source="retrofitDistributorId"
                            optionText="name"
                          />
                        </ReferenceInput>
                      </Box>
                      {/* 企業ID */}
                      <Box display="flex">
                        <CheckBox
                          testId="corporation"
                          className={classes.checkBox}
                          onClick={() => {
                            if (hasLending) {
                              setErrorDialogOpen(true);
                              // return で false を返すと onChecked が呼ばれないようになる
                              return false;
                            }
                            return true;
                          }}
                          onChecked={checked => {
                            setFormState({
                              ...formState,
                              disableCorporation: !checked,
                              checkCount:
                                checked === true
                                  ? formState.checkCount + 1
                                  : formState.checkCount - 1,
                            });
                            if (!checked) {
                              form.change('corporationId', undefined);
                              form.change('corporationName', undefined);
                            }
                          }}
                        />
                        <TextInput
                          resource="retrofits"
                          label="admin.label.retrofitBasicInfos.corporationId"
                          source="corporationId"
                          disabled={formState.disableCorporation}
                        />
                      </Box>
                      {/* 企業名 */}
                      <Box display="flex">
                        <div className={classes.indent} />
                        <TextInput
                          resource="retrofits"
                          label="admin.label.retrofitBasicInfos.corporationName"
                          source="corporationName"
                          disabled={formState.disableCorporation}
                        />
                      </Box>
                      {/* リージョン */}
                      <Box display="flex">
                        <CheckBox
                          testId="region"
                          className={classes.checkBox}
                          onChecked={checked => {
                            setFormState({
                              ...formState,
                              disableRetrofitRegion: !checked,
                              checkCount:
                                checked === true
                                  ? formState.checkCount + 1
                                  : formState.checkCount - 1,
                            });
                            if (!checked) {
                              form.change('retrofitRegionId', undefined);
                            }
                          }}
                        />
                        <ReferenceInput
                          resource="retrofits"
                          label="admin.label.retrofitBasicInfos.retrofitRegion"
                          source="retrofitRegionId"
                          reference="retrofitRegions"
                          sort={{ field: 'name', order: 'ASC' }}
                          disabled={formState.disableRetrofitRegion}
                        >
                          <SelectInput optionText="name" />
                        </ReferenceInput>
                      </Box>
                    </>
                  )}
                </FinalFormConsumer>
                <Divider className="dividerTop" />
                <Divider />
                <SelectedCounter count={ids.length} />
                {serialNumbers.map((serialNumber: string, index: number) => {
                  const labelResources =
                    'resources.retrofits.fields.basicInfoSerialNumber';
                  return (
                    <FunctionField
                      key={index}
                      label={index === 0 ? labelResources : ' '}
                      record={{} as any} // NOTE: レコードは使用しないけど、Renderするのに何かしら値を設定しないといけない
                      render={() => {
                        return (
                          <div className="serialNumber">{serialNumber}</div>
                        );
                      }}
                    />
                  );
                })}
              </CustomForm>
            </MuiDialogContent>
          </>
        ) : (
          false
        )}
      </Dialog>
    </>
  );
};

BulkRegistrationDialog.displayName = 'BulkRegistrationDialog';
export default BulkRegistrationDialog;
