import React from 'react';
import { LocationMap } from '..';
import { RetrofitData } from '../../dataProvider';
import IconOnline from '../../assets/images/ico_map_online.svg';
import IconOffline from '../../assets/images/ico_map_offline.svg';

type Positions = React.ComponentProps<typeof LocationMap>['positions'];
type SitePosition = React.ComponentProps<typeof LocationMap>['sitePosition'];

interface Props {
  defaultView?: React.ComponentProps<typeof LocationMap>['defaultView'];
  retrofitData: RetrofitData[];
  classes?: object;
  sitePosition: SitePosition;
}

const RetrofitLocationMap: React.FC<Props> = ({
  defaultView,
  retrofitData,
  classes,
  sitePosition,
}) => {
  const positions: Positions = retrofitData.map(
    ({ latitude, longitude, isOnline, machineInfoMachineName }) => ({
      latitude,
      longitude,
      isOnline,
      machineName: machineInfoMachineName,
    }),
  );
  return (
    <LocationMap
      classes={classes}
      defaultView={defaultView}
      positions={positions}
      iconSelector={({ isOnline }) => (isOnline ? IconOnline : IconOffline)}
      sitePosition={sitePosition}
    />
  );
};

RetrofitLocationMap.displayName = 'RetrofitLocationMap';
export default RetrofitLocationMap;
