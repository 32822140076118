import { CompactionLayerData } from '../../types';

const compactionLayers: CompactionLayerData[] = [
  {
    id: 1,
    compactionWorkAreaId: 1,
    layerNo: 1,
    name: 'プロジェクト1.1-転圧施工領域１-レイヤ１',
    thickness: 1,
    passCount: 1,
    compactionMaterialId: 1,
    description: '',
    inUse: false,
    enable: true,
  },
  {
    id: 2,
    compactionWorkAreaId: 2,
    layerNo: 1,
    name: 'プロジェクト1.2-転圧施工領域１-レイヤ１',
    thickness: 1,
    passCount: 2,
    compactionMaterialId: 2,
    description: '施工領域内に転圧レイヤ2つ(１)',
    inUse: false,
    enable: true,
  },
  {
    id: 3,
    compactionWorkAreaId: 2,
    layerNo: 2,
    name: 'プロジェクト1.2-転圧施工領域１-レイヤ２',
    thickness: 1.1,
    passCount: 3,
    compactionMaterialId: 2,
    description: '施工領域内に転圧レイヤ2つ(２)',
    inUse: false,
    enable: true,
  },
  {
    id: 4,
    compactionWorkAreaId: 3,
    layerNo: 1,
    name: 'プロジェクト1.2-転圧施工領域２-レイヤ１',
    thickness: 1.221,
    passCount: 10,
    compactionMaterialId: 3,
    description: '施工領域内に転圧レイヤ3つ(１)',
    inUse: false,
    enable: true,
  },
  {
    id: 5,
    compactionWorkAreaId: 3,
    layerNo: 2,
    name: 'プロジェクト1.2-転圧施工領域２-レイヤ２',
    thickness: 1.222,
    passCount: 11,
    compactionMaterialId: 4,
    description: '施工領域内に転圧レイヤ3つ(２)',
    inUse: false,
    enable: true,
  },
  {
    id: 6,
    compactionWorkAreaId: 3,
    layerNo: 3,
    name: 'プロジェクト1.2-転圧施工領域２-レイヤ３',
    thickness: 1.223,
    passCount: 12,
    compactionMaterialId: 5,
    description: '施工領域内に転圧レイヤ3つ(３)',
    inUse: false,
    enable: true,
  },
  {
    id: 7,
    compactionWorkAreaId: 4,
    layerNo: 1,
    name: 'プロジェクト1.3-転圧施工領域１-レイヤ１',
    thickness: 12.3,
    passCount: 4,
    compactionMaterialId: 6,
    description: '使用中',
    inUse: true,
    enable: true,
  },
  {
    id: 8,
    compactionWorkAreaId: 5,
    layerNo: 1,
    name: 'プロジェクト1.3-転圧施工領域２-レイヤ１',
    thickness: 1,
    passCount: 1,
    compactionMaterialId: 7,
    description: '削除済み',
    inUse: false,
    enable: false,
  },
  {
    id: 9,
    compactionWorkAreaId: 5,
    layerNo: 2,
    name: 'プロジェクト1.3-転圧施工領域２-レイヤ２',
    thickness: 2,
    passCount: 2,
    compactionMaterialId: 8,
    description: '削除済み(使用中)',
    inUse: true,
    enable: false,
  },
  {
    id: 10,
    compactionWorkAreaId: 5,
    layerNo: 3,
    name: 'プロジェクト1.3-転圧施工領域２-レイヤ３',
    thickness: 3,
    passCount: 3,
    compactionMaterialId: 9,
    description: '使用中',
    inUse: true,
    enable: true,
  },
  {
    id: 11,
    compactionWorkAreaId: 6,
    layerNo: 1,
    name: 'プロジェクト1.3-転圧施工領域３-レイヤ１',
    thickness: 1.2345,
    passCount: 6,
    compactionMaterialId: undefined,
    description: '転圧レイヤ未選択',
    inUse: false,
    enable: true,
  },
  {
    id: 12,
    compactionWorkAreaId: 7,
    layerNo: 1,
    name: 'プロジェクト1.4-転圧施工領域１-レイヤ１',
    thickness: 0.1,
    passCount: 123,
    compactionMaterialId: undefined,
    description: 'undefined',
    inUse: false,
    enable: true,
  },
  {
    id: 13,
    compactionWorkAreaId: 8,
    layerNo: 1,
    name: 'プロジェクト1.4-転圧施工領域２-レイヤ１',
    thickness: 0.01,
    passCount: 1234,
    compactionMaterialId: 10,
    description: 'undefined',
    inUse: false,
    enable: true,
  },
  {
    id: 14,
    compactionWorkAreaId: 9,
    layerNo: 1,
    name: 'プロジェクト1.4-転圧施工領域３-レイヤ１',
    thickness: 0.001,
    passCount: 12345,
    compactionMaterialId: 11,
    description: '',
    inUse: false,
    enable: true,
  },
  {
    id: 15,
    compactionWorkAreaId: 10,
    layerNo: 1,
    name: 'プロジェクト1.4-転圧施工領域４-レイヤ１',
    thickness: 0.0001,
    passCount: 123456,
    compactionMaterialId: 12,
    description: '',
    inUse: false,
    enable: true,
  },
  {
    id: 16,
    compactionWorkAreaId: 11,
    layerNo: 1,
    name: 'プロジェクト1.5　！”＃＄％＆’（）＝～｜｀｛＋＊｝＜＞？＿ー＾￥＠「；：」、。・￥-レイヤ１',
    thickness: 10,
    passCount: 100,
    compactionMaterialId: 13,
    description:
      '！”＃＄％＆’（）＝～｜｀｛＋＊｝＜＞？＿ー＾￥＠「；：」、。・￥',
    inUse: true,
    enable: true,
  },
  {
    id: 17,
    compactionWorkAreaId: 15,
    layerNo: 1,
    name: 'プロジェクト2.3-転圧施工領域１',
    thickness: 1,
    passCount: 1,
    compactionMaterialId: 28,
    description: '施工領域削除済み',
    inUse: false,
    enable: true,
  },
  {
    id: 18,
    compactionWorkAreaId: 16,
    layerNo: 1,
    name: 'プロジェクト2.3-転圧施工領域２',
    thickness: 1,
    passCount: 1,
    compactionMaterialId: undefined,
    description: '施工領域削除済み',
    inUse: true,
    enable: true,
  },
  {
    id: 19,
    compactionWorkAreaId: 17,
    layerNo: 1,
    name: 'プロジェクト2.3-転圧施工領域３',
    thickness: 1,
    passCount: 1,
    compactionMaterialId: undefined,
    description: '施工領域削除済み',
    inUse: true,
    enable: false,
  },
  {
    id: 20,
    compactionWorkAreaId: 18,
    layerNo: 1,
    name: 'プロジェクト2.4-転圧施工領域１',
    thickness: 1,
    passCount: 1,
    compactionMaterialId: undefined,
    description: '施工領域削除済み',
    inUse: false,
    enable: false,
  },
  {
    id: 21,
    compactionWorkAreaId: 19,
    layerNo: 1,
    name: 'プロジェクト2.4-転圧施工領域２',
    thickness: 1,
    passCount: 1,
    compactionMaterialId: undefined,
    description: '',
    inUse: false,
    enable: true,
  },
  {
    id: 22,
    compactionWorkAreaId: 20,
    layerNo: 1,
    name: 'プロジェクト2.4-転圧施工領域３',
    thickness: 1,
    passCount: 1,
    compactionMaterialId: 29,
    description: '',
    inUse: false,
    enable: true,
  },
  {
    id: 23,
    compactionWorkAreaId: 21,
    layerNo: 1,
    name: 'プロジェクト2.4-転圧施工領域４',
    thickness: 1,
    passCount: 1,
    compactionMaterialId: 30,
    description: '',
    inUse: false,
    enable: true,
  },
  {
    id: 24,
    compactionWorkAreaId: 22,
    layerNo: 1,
    name: 'プロジェクト2.5 !"#$%&\'() = ~| `{+*}<>?_-^\\@[;:],./\\',
    thickness: 1,
    passCount: 1,
    compactionMaterialId: 32,
    description: '!"#$%&\'()=~|`{+*}<>? _ -^\\@[;: ],./\\',
    inUse: true,
    enable: true,
  },
  {
    id: 25,
    compactionWorkAreaId: 23,
    layerNo: 1,
    name: '厚さ 0.5 m は ft 単位で表示される',
    thickness: 0.5,
    passCount: 1,
    description: '',
    inUse: false,
    enable: true,
  },
];

export default compactionLayers;
