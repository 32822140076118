import { UserData } from '../dataProvider';

const hasRoleFromUserDataByConfigInfo = (
  data: any[],
  configInfo: string,
): boolean => {
  let hasRole = false;
  if (data.length > 0 && configInfo.length > 0) {
    const userData = data as UserData[];
    const enableRoles = configInfo.split(' ');
    hasRole = enableRoles.includes(userData[0].role);
  }

  return hasRole;
};

export default hasRoleFromUserDataByConfigInfo;
