import React from 'react';
import { ReactElementLike } from 'prop-types';
import { Tooltip } from '@mui/material';
import { makeStyles, createStyles } from '@material-ui/core';
import { CustomForm, Typography } from '..';
import { useTranslateMultiLine } from '../../hooks';
import { ErrorIcon } from '../../assets';
import { styles } from '../../theme';
import DialogOKOnlyMulitiLine from '../atoms/dialog/DialogOKOnlyMultiLine';

const useStyles = makeStyles(() =>
  createStyles({
    message: {
      fontSize: 16,
    },
    url: {
      fontSize: 16,
      fontWeight: 'bold',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
);

interface Props {
  errorDialogOpen: boolean;
  onClose: () => void;
  title?: string;
  okLabel?: string;
  children?: ReactElementLike;
  testId?: string;
}

const LicensePurchaseNoticeDialog: React.FC<Props> = ({
  errorDialogOpen,
  onClose,
}) => {
  const classes = useStyles();
  const translateMultiLine = useTranslateMultiLine();
  const message1 = 'admin.dialog.licensePurchaseNotice.message1';
  const message2 = 'admin.dialog.licensePurchaseNotice.message2';
  const marketplaceUrl =
    'https://marketplace.smartconstruction.com/jp/products?store=eb'; // ライセンス購入サイト
  const articlesUrl =
    'https://support.smartconstruction.com/hc/ja/articles/28550420849561'; // ライセンスによる利用制限機能リリースに伴うご案内の記事

  const handleClick = (src: string) => {
    window.open(src, '_blank');
  };

  return (
    <DialogOKOnlyMulitiLine
      open={errorDialogOpen}
      onCloseButton={onClose}
      title="admin.dialog.licensePurchaseNotice.title"
      okLabel="admin.dialog.projectConversionStatus.button"
      testId="projects_info_dialog"
    >
      <div style={styles.lincensePurchaceNoticeDialog}>
        <CustomForm
          deleteButton={false}
          cancelButton={false}
          saveButton={false}
        >
          <Tooltip title="ErrorIcon">
            <ErrorIcon />
          </Tooltip>
          <Typography className={classes.message}>
            {translateMultiLine(message1)}
          </Typography>
          <Typography
            onClick={() => handleClick(marketplaceUrl)}
            className={classes.url}
            data-testid={'marketplaceUrl'}
          >
            {marketplaceUrl}
          </Typography>
          <Typography className={classes.message}>
            {translateMultiLine(message2)}
          </Typography>
          <Typography
            onClick={() => handleClick(articlesUrl)}
            className={classes.url}
            data-testid={'articlesUrl'}
          >
            {articlesUrl}
          </Typography>
        </CustomForm>
      </div>
    </DialogOKOnlyMulitiLine>
  );
};

LicensePurchaseNoticeDialog.displayName = 'LicensePurchaseNoticeDialog';
LicensePurchaseNoticeDialog.defaultProps = {
  title: '',
  okLabel: '',
};
export default LicensePurchaseNoticeDialog;
