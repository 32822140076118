import { List as RaList } from 'react-admin';
import { ReactText } from 'react';
import { ReactNodeLike, ReactElementLike } from 'prop-types';
import { AnyObject } from '../../../utils/types';

interface TitlePropType {
  defaultTitle: string;
  className: string;
  locale: string;
  record: AnyObject;
  title: TitlePropType;
}

export type BulkActionButtonProps = React.ComponentType<
  Partial<{
    basePath: string;
    label: string;
    resource: string;
    selectedIds: ReactText[] | (string | number)[];
    undoable: boolean;
    icon: ReactElementLike;
  }>
>;

interface RaProps {
  actions: ReactElementLike;
  aside: ReactElementLike;
  bulkActionButtons: BulkActionButtonProps | ReactElementLike | boolean;
  children: ReactNodeLike;
  classes: AnyObject;
  className: string;
  filter: AnyObject;
  filterDefaultValues: AnyObject;
  filters: ReactElementLike;
  pagination: ReactElementLike;
  perPage: number;
  sort: {
    field: string;
    order: string;
  };
  title: TitlePropType;
  authProvider: Function;
  hasCreate: boolean;
  hasEdit: boolean;
  hasList: boolean;
  hasShow: boolean;
  location: AnyObject;
  match: AnyObject;
  path: string;
  resource: string;
}

const List = RaList as ForgivingComponentType<RaProps>;
List.displayName = 'List';

export default List;
