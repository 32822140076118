import {
  asbuiltConverter,
  assignmentConverter,
  bucketConverter,
  bucketNameConverter,
  bucketShareConverter,
  compactionLayersConverter,
  compactionMaterialsConverter,
  compactionWorkAreasConverter,
  customerConverter,
  datumConverter,
  extensionarmConverter,
  extensionarmShareConverter,
  firmwareConverter,
  geofenceAlertConverter,
  geofenceConverter,
  geoidConverter,
  gnssSettingConverter,
  landlogappConverter,
  licenseAgreementConverter,
  ntripConverter,
  operatorsConverter,
  projectConverter,
  projectionConverter,
  projectNameConverter,
  regionConverter,
  rentalConverter,
  retrofitAccuracyConverter,
  retrofitAdminCorporationBulkActionConverter,
  retrofitAlternateRegistConverter,
  retrofitBulkActionConverter,
  retrofitByTaskConverter,
  retrofitConverter,
  retrofitCorporationLicenseConverter,
  retrofitDistributorConverter,
  retrofitRegionConverter,
  retrofitErrorConverter,
  retrofitTopographicSurveyConverter,
  retrofitManageCorporationConverter,
  retrofitOwnerRegistCorporationConverter,
  retrofitOwnerChangeCorporationConverter,
  retrofitSendbackConverter,
  retrofitShareFileConverter,
  siteBuriedStructureConverter,
  siteConfigureConverter,
  taskConverter,
  termsConditionConverter,
  termsConditionDocumentConverter,
  userConfigureConverter,
  userNoticeConverter,
  userNoticeStatusConverter,
  userPermissionConverter,
  weathersConverter,
  retrofitCalibInfoConverter,
} from '.';

export default () => ({
  asbuilts: asbuiltConverter,
  assignments: assignmentConverter,
  bucketNames: bucketNameConverter,
  buckets: bucketConverter,
  bucketShares: bucketShareConverter,
  compactionLayers: compactionLayersConverter,
  compactionMaterials: compactionMaterialsConverter,
  compactionWorkAreas: compactionWorkAreasConverter,
  customers: customerConverter,
  datums: datumConverter,
  extensionarms: extensionarmConverter,
  extensionarmShares: extensionarmShareConverter,
  geofences: geofenceConverter,
  geofenceAlerts: geofenceAlertConverter,
  firmwares: firmwareConverter,
  geoids: geoidConverter,
  gnssSettings: gnssSettingConverter,
  landlogapps: landlogappConverter,
  licenseAgreements: licenseAgreementConverter,
  ntrips: ntripConverter,
  operators: operatorsConverter,
  projections: projectionConverter,
  projectNames: projectNameConverter,
  projects: projectConverter,
  regions: regionConverter,
  retrofitAccuracies: retrofitAccuracyConverter,
  retrofitAdminCorporationBulkActions:
    retrofitAdminCorporationBulkActionConverter,
  retrofitAwaitingApprovals: rentalConverter,
  retrofitAlternateRegists: retrofitAlternateRegistConverter,
  retrofitBulkActions: retrofitBulkActionConverter,
  retrofitByTasks: retrofitByTaskConverter,
  retrofitCorporationLicenses: retrofitCorporationLicenseConverter,
  retrofitCalibs: retrofitCalibInfoConverter,
  retrofitDistributors: retrofitDistributorConverter,
  retrofitRegions: retrofitRegionConverter,
  retrofitErrors: retrofitErrorConverter,
  retrofitManageCorporations: retrofitManageCorporationConverter,
  retrofitOwnerRegistCorporations: retrofitOwnerRegistCorporationConverter,
  retrofitOwnerChangeCorporations: retrofitOwnerChangeCorporationConverter,
  retrofitRentals: rentalConverter,
  retrofits: retrofitConverter,
  retrofitSendbacks: retrofitSendbackConverter,
  retrofitShareFiles: retrofitShareFileConverter,
  retrofitToposurveys: retrofitTopographicSurveyConverter,
  siteBuriedStructures: siteBuriedStructureConverter,
  siteConfigures: siteConfigureConverter,
  tasks: taskConverter,
  termsConditions: termsConditionConverter,
  termsConditionDocuments: termsConditionDocumentConverter,
  userConfigures: userConfigureConverter,
  userNotices: userNoticeConverter,
  userNoticeStatus: userNoticeStatusConverter,
  userPermissions: userPermissionConverter,
  weathers: weathersConverter,
});
