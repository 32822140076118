import { GetManyParams, GetManyResult } from 'react-admin';
import { landlogReadSite, landlogSiteConvert } from '..';
import { LandlogSites } from './types';

export default async (params: GetManyParams): Promise<GetManyResult> => {
  const { ids } = params;
  // APIの実行
  const sites = await Promise.all(
    ids.map(id =>
      landlogReadSite(id as string).catch(() => Promise.resolve(null)),
    ),
  );
  const sitesWithoutError = sites.filter(site => site) as LandlogSites[];
  const data = sitesWithoutError.map(site => landlogSiteConvert(site));
  return { data };
};
