import React, { useEffect, useState } from 'react';
import { InputProps } from 'ra-core';
import { AutocompleteInput as RaAutocompleteInput, Record } from 'react-admin';
import { AnyObject } from '../../utils';
import { useGetListAllPage } from '../../hooks';

interface ChoiceType {
  id: string | number;
  name: string;
}

type Props = InputProps<{
  source: string;
  resources: string;
  choiceResources: string;
  choicesCreate: (record: Record) => AnyObject;
  filter?: any;
  sort?: any;
  autoCompleteOptions?: any;
}>;

const AutocompleteInput: React.FC<Props> = ({
  source,
  resources,
  choiceResources,
  choicesCreate,
  filter = {},
  sort = {},
  autoCompleteOptions = {},
  ...rest
}) => {
  const [choices, setChoices] = useState<ChoiceType[]>([]);
  const [fetching, setFetching] = useState(false);
  const getListAllPage = useGetListAllPage();
  useEffect(() => {
    if (fetching) return; // NOTE: 再取得防止
    setFetching(true);
    getListAllPage({
      resource: choiceResources,
      filter,
      sort,
    }).then(result => {
      const { data } = result;
      // choisesCreateにて非表示データはnullで渡されるため除去する
      const incluedeNullData = data.map(it => {
        return choicesCreate(it); // NOTE: 呼び出し元で choices を生成する
      });
      const settingChoices = incluedeNullData.filter(it => it != null);
      setChoices(settingChoices);
    });
  }, [choiceResources, filter, sort, choicesCreate, fetching, getListAllPage]);
  return (
    <RaAutocompleteInput
      resources={resources}
      source={source}
      choices={choices}
      allowEmpty={true}
      options={autoCompleteOptions}
      {...rest}
    />
  );
};

AutocompleteInput.displayName = 'AutocompleteInput';
export default AutocompleteInput;
