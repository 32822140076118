import React, { useState, useCallback } from 'react';
import {
  NormalButton,
  CustomList,
  Datagrid,
  TextField,
  CompactionInputDialog,
  CompactionInputDialogInput,
} from '../../..';
import { AddIcon } from '../../../../assets';

const SiteMasterMaterialList: React.FC<{
  basePath: string;
  siteName: string;
  siteId: string;
}> = ({ basePath, siteName, siteId }) => {
  const resource = 'compactionMaterials';
  const defaultInput: CompactionInputDialogInput = {
    fields: ['name'],
    defaultValues: {
      siteId,
    },
    isNameValidation: true,
  };

  const [input, setInput] = useState<CompactionInputDialogInput>(defaultInput);
  const [open, setOpen] = useState(false);

  const handleCreate = useCallback(() => {
    setInput(defaultInput);
    setOpen(true);
  }, [setOpen, setInput, defaultInput]);

  const handleUpdate = useCallback(
    (_id, _basePath, record) => {
      setInput({ ...defaultInput, record });
      setOpen(true);
    },
    [setOpen, setInput, defaultInput],
  );
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <CompactionInputDialog
        resource={resource}
        siteId={siteId}
        input={input}
        open={open}
        onClose={handleClose}
      />
      <CustomList
        basePath={basePath}
        breadcrumbs={['resources.sites.name', '', siteName]}
        resource={resource}
        actions={
          <NormalButton label="admin.actions.add" onClick={handleCreate}>
            <AddIcon />
          </NormalButton>
        }
        filter={{ siteId }}
      >
        <Datagrid rowClick={handleUpdate}>
          <TextField resource={resource} source="name" />
        </Datagrid>
      </CustomList>
    </>
  );
};

SiteMasterMaterialList.displayName = 'SiteMasterMaterialList';
export default SiteMasterMaterialList;
