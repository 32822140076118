import React, { FC, useCallback, useState } from 'react';
import {
  useDataProvider,
  useLoading,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { DialogOKOnlyWithUrl, DoneButton, LendCorporationData } from '../../..';
import { SaveIcon } from '../../../../assets';
import { RetrofitData } from '../../../../dataProvider';
import { useAccount } from '../../../../hooks';
import { apiErrorHandler, config } from '../../../../utils';
import { useCreateRetrofitAlternateRegists } from '../hooks';

const useRetrofits = () => {
  const dataProvider = useDataProvider();
  return async (serials: string[]): Promise<RetrofitData[]> => {
    const { data } = await dataProvider.getList<RetrofitData>('retrofits', {
      pagination: {
        page: 1,
        perPage: 100, // ただし、シリアル番号 1回での取得を100にする場合は、コントローラ登録で一回でチェックするシリアル番号を件数を絞り込みすることも合わせて検討する
      },
      sort: { field: 'id', order: 'ASC' },
      filter: {
        basicInfoSerialNumber: serials,
      },
    });
    return data;
  };
};

interface Props {
  saveButtonDisabled: boolean;
  disabled: boolean;
  basePath: string;
  customer: LendCorporationData | null;
  form: any;
  checkEarthbrainCorporationId: (retrofits: RetrofitData[]) => Promise<boolean>;
  checkSiteAttachment: (retrofitIds: number[]) => Promise<boolean>;
  checkLending: (retrofitIds: number[]) => Promise<boolean>;
  checkPendingTransfer: (retrofitIds: number[]) => Promise<boolean>;
  checkCorporation: (retrofits: RetrofitData[]) => Promise<boolean>;
  checkUnusedNtipDB: (retrofits: RetrofitData[]) => Promise<boolean>;
  checkDoubleRegistration: (
    retrofits: RetrofitData[],
    retrofitIds: number[],
    corporationId: string,
  ) => Promise<boolean>;
}

const CreateTextSaveButton: FC<Partial<Props>> = ({
  saveButtonDisabled, // 保存ボタン活性化状態の独自機能
  disabled,
  form,
  customer,
  checkEarthbrainCorporationId = async () => Promise.resolve(true),
  checkSiteAttachment = async () => Promise.resolve(true),
  checkLending = async () => Promise.resolve(true),
  checkPendingTransfer = async () => Promise.resolve(true),
  checkCorporation = async () => Promise.resolve(true),
  checkUnusedNtipDB = async () => Promise.resolve(true),
  checkDoubleRegistration = async () => Promise.resolve(false),
}) => {
  const getRetrofits = useRetrofits();
  const createRetrofitAlternateRegists = useCreateRetrofitAlternateRegists();
  const translate = useTranslate();
  const notify = useNotify();
  const loading = useLoading();
  const redirectTo = useRedirect();
  const getAccount = useAccount();
  const [errorDialogInfo, setErrorDialogInfo] = useState<{
    open: boolean;
    errorMessage: string[];
    subMessages?: string[];
  }>({ open: false, errorMessage: [], subMessages: [] });
  const basePath = '/retrofitAlternateRegists';
  const toCorporationId = customer ? customer.id : 'unknown';
  const toCorporationName = customer ? customer.name : 'unknown';
  const scSupportRequestUrl = config.url.scSupportRequest; // エラーダイアログ用URL

  const handleSave = useCallback(() => {
    const toMailAddress = form.getFieldState('toMailAddress').value;
    const serialNoControl = form.getFieldState('retrofitsSerial').value;
    const hideNofityError = 'hideNofityError';

    const serials =
      serialNoControl?.map((value: any) => {
        return value ? value.basicInfoSerialNumber : '';
      }) || [];

    // シリアル未入力チェック
    if (serials.length === 0) {
      setErrorDialogInfo({
        open: true,
        errorMessage: [
          translate(
            'admin.message.retrofitsAlternateRegistText.serialNumberInputError',
          ),
        ],
      });
      return;
    }

    const api = async (): Promise<boolean> => {
      const data = await getRetrofits(serials);
      const validData = data.filter(it =>
        serials.includes(it.basicInfoSerialNumber),
      );

      // 自身の企業情報を取得
      const {
        data: {
          corporationId: fromCorporationId,
          corporationName: fromCorporationName,
          email: fromApplicantId,
          name: fromApplicantName,
        },
      } = await getAccount();

      // 存在しないシリアル番号のチェック
      const validSerials = validData.map(it => it.basicInfoSerialNumber);
      const invalidSerials = serials.filter(
        (it: string) => !validSerials.includes(it),
      );

      if (invalidSerials.length > 0) {
        setErrorDialogInfo({
          open: true,
          errorMessage: [
            translate(
              'admin.dialog.retrofitAlternateRegists.serialNumberExistError.message1',
            ),
            translate(
              'admin.dialog.retrofitAlternateRegists.serialNumberExistError.message2',
            ),
          ],
          subMessages: [
            translate(
              'admin.dialog.retrofitAlternateRegists.serialnumberCheck.message',
            ),
            invalidSerials.join(' , '),
          ],
        });
        throw Error(hideNofityError);
      }

      const retrofitIds = validData.map(({ id }) => id);
      // 代理申請承認待ちチェック
      if (
        await checkDoubleRegistration(validData, retrofitIds, fromCorporationId)
      ) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // すでに代理申請先企業に紐づいているかチェック ※ メールアドレスのみ入力した場合は判定不可
      const retrofitCorporationId = validData.map(it => it.corporationId);
      const findRetrofitIds = retrofitCorporationId.includes(toCorporationId);
      if (findRetrofitIds) {
        // エラーダイアログを表示
        setErrorDialogInfo({
          open: true,
          errorMessage: [
            translate(
              'admin.dialog.retrofitAlternateRegists.corporationIdCheck.message1',
            ),
            translate(
              'admin.dialog.retrofitAlternateRegists.corporationIdCheck.message2',
            ),
          ],
          subMessages: [
            translate(
              'admin.dialog.retrofitAlternateRegists.serialnumberCheck.message',
            ),
            validSerials.join(' , '),
          ],
        });
        return false;
      }
      // EARTHBRAIN企業IDチェック
      if (!(await checkEarthbrainCorporationId(validData))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // 利用中企業の制限チェック
      if (!(await checkCorporation(validData))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // Ntrip払い出しで未使用NtripDBの合計数が足りるかチェック
      if (!(await checkUnusedNtipDB(validData))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // レトロフィット貸与中チェック
      if (!(await checkLending(retrofitIds))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // 譲渡承認待ちチェック
      if (!(await checkPendingTransfer(retrofitIds))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // 現場の紐づきをチェック
      if (!(await checkSiteAttachment(retrofitIds))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // 登録処理
      await createRetrofitAlternateRegists({
        data,
        fromCorporationId,
        fromCorporationName,
        toCorporationId,
        toCorporationName,
        toMailAddress,
        fromApplicantId,
        fromApplicantName,
      });
      return true;
    };

    api()
      .then(result => {
        if (result) {
          notify('admin.message.success', 'info');
          redirectTo(basePath);
        }
      })
      .catch(error => {
        const message = apiErrorHandler(error);
        if (message !== hideNofityError) {
          // 想定外エラーはダイアログ表示ではなくnotify表示
          notify(message, 'warning');
        }
      });
  }, [
    getRetrofits,
    checkEarthbrainCorporationId,
    checkSiteAttachment,
    checkLending,
    checkPendingTransfer,
    checkCorporation,
    checkUnusedNtipDB,
    checkDoubleRegistration,
    createRetrofitAlternateRegists,
    translate,
    notify,
    redirectTo,
    getAccount,
    form,
    toCorporationId,
    toCorporationName,
  ]);

  return (
    <>
      <DoneButton
        label="admin.actions.save"
        onClick={handleSave}
        disabled={disabled || saveButtonDisabled || loading}
      >
        <SaveIcon />
      </DoneButton>
      <DialogOKOnlyWithUrl
        open={errorDialogInfo.open}
        onClose={() => {
          setErrorDialogInfo({
            ...errorDialogInfo,
            open: false,
          });
        }}
        title=""
        nonTranslateMessage={true}
        messages={errorDialogInfo.errorMessage}
        url={scSupportRequestUrl}
        subMessages={errorDialogInfo.subMessages}
      />
    </>
  );
};

CreateTextSaveButton.displayName = 'CreateTextSaveButton';
export default CreateTextSaveButton;
