import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Filter, SelectInput } from '../../..';
import { GeofenceAlertType } from '../../../../dataProvider';

const useStyles = makeStyles(() =>
  createStyles({
    columnFilter: {
      marginTop: 0,
      marginBottom: 0,
    },
  }),
);

const CustomSelectInput: React.FC<
  any | { geofenceAlertType: GeofenceAlertType }
> = ({ geofenceAlertType, ...rest }) => {
  const classes = useStyles();
  return (
    <SelectInput
      {...rest}
      className={classes.columnFilter}
      initialValue={geofenceAlertType}
      allowEmpty={true}
      resettable={true}
    />
  );
};

const choiceGeofenceAlertType = [
  { id: 'Notice', name: 'Notice' },
  { id: 'Attention', name: 'Attention' },
  { id: 'Caution', name: 'Caution' },
  { id: 'Warning', name: 'Warning' },
  { id: 'Danger', name: 'Danger' },
];

interface Props {
  geofenceAlertType?: GeofenceAlertType;
}

const ColumnFilters: React.FC<Props> = (props: Props) => {
  const { geofenceAlertType, ...rest } = props; // コンソールエラー対策（独自パラメータを除去）
  return (
    <Filter {...rest}>
      <CustomSelectInput
        resource={'geofenceAlerts'}
        label={`resources.geofenceAlerts.fields.alertType`}
        source="alertType"
        choices={choiceGeofenceAlertType}
        alwaysOn
        geofenceAlertType={geofenceAlertType}
      />
    </Filter>
  );
};

export default ColumnFilters;
