import {
  MapProps,
  createProjectSourceLayerEPSG3857,
} from '../../../../../hooks';

const addProjectSourceLayer = (
  { map }: MapProps,
  id: number,
  versionId: number,
  token: string,
  visibility?: boolean,
) =>
  map.addLayer(
    createProjectSourceLayerEPSG3857(id, versionId, token, visibility),
  );

export default addProjectSourceLayer;
