import React, { FC, useCallback, useEffect, useState } from 'react';
import Overlay from 'ol/Overlay';
import { usePermissions } from 'react-admin';
import { MapProps } from '../../../../hooks';
import GeofenceMapField, { GeofenceMapFieldProps } from './GeofenceMapField';
import GeofenceMapCoordinatePopup from './GeofenceMapCoordinatePopup';
import { LonLat, createGeometryFromFeature } from '../../../../utils';
import { geofenceStore } from '../utils/index';
import { ProjectsData } from '../../../../dataProvider';
import {
  GeofenceListProjectInput,
  GeofenceListProjectLayerInput,
} from '../view';
import { existEditFeature } from '../map/interaction/utils';

const COORDINATE_INPUT_ID = 'coordinate-input';

type Props = GeofenceMapFieldProps;
type AddProps = {
  source: string;
  siteId: string;
  form?: any;
};

const GeofenceMapInput: FC<Props & AddProps> = props => {
  const {
    record,
    mapController,
    source: paramName,
    siteId,
    form,
    mapBackgroundColor,
  } = props;
  const featureId = record && record?.id ? record.id : 0;
  const { permissions } = usePermissions();
  const [projectsData, setProjectsData] = useState<ProjectsData>();
  const [projectLayerId, setProjectLayerId] = useState<number>();

  const handleChangeProject = useCallback(
    pd => {
      setProjectsData(pd);
      form.change('layerId', null);
      // ローカルストレージに選択プロジェクトを記録
      const storedGeofence = geofenceStore.get();
      const projectSourceLayerId =
        storedGeofence?.projectId === pd.id
          ? storedGeofence?.projectSourceLayerId
          : undefined;
      setProjectLayerId(projectSourceLayerId);
      geofenceStore.set({
        siteId,
        projectId: pd.id,
        projectSourceLayerId,
      });
    },
    [form, siteId],
  );
  const handleChangeLayer = useCallback(
    ({ mapServerProjectSourceLayerId }) => {
      setProjectLayerId(mapServerProjectSourceLayerId);
      // ローカルストレージに選択レイヤを記録
      geofenceStore.set({
        siteId,
        projectId: projectsData?.id,
        projectSourceLayerId: mapServerProjectSourceLayerId,
      });
      // 選択したレイヤーにあわせたboundary再設定
      const projectLayers = projectsData?.projectSourceLayers;
      if (projectLayers) {
        // projectSourceLayersには全レイヤー情報が含まれているため、選択されたレイヤーの情報を選択
        const targetLayers = projectLayers.filter(
          layer =>
            layer.mapServerProjectSourceLayerId ===
            mapServerProjectSourceLayerId,
        );

        if (targetLayers && targetLayers.length > 0) {
          const targetBoundary = targetLayers[0].projectBoundary;
          if (!targetBoundary) return;

          // projectBoundary内の各座標データをBoundary調整できる形式に変換
          const boundaryLonLat = [
            {
              lon: targetBoundary.northEastLon,
              lat: targetBoundary.northEastLat,
            },
            {
              lon: targetBoundary.northWestLon,
              lat: targetBoundary.northWestLat,
            },
            {
              lon: targetBoundary.southWestLon,
              lat: targetBoundary.southWestLat,
            },
            {
              lon: targetBoundary.southEastLon,
              lat: targetBoundary.southEastLat,
            },
          ];
          // 更新画面からの遷移する場合、対象ジオフェンスにフォーカスを当てる
          if (record !== undefined && Object.keys(record).length !== 0) {
            const centerPoint = record.points[0];
            const geofenceRadius = record.radius;
            // ジオフェンスタイプがCircleの場合、setBoundaryに渡す座標を調整する
            const recordBoundaryLonLat: LonLat[] =
              record?.type === 'Circle' && geofenceRadius
                ? [
                    {
                      lon: centerPoint[0] + geofenceRadius, // northEastLon
                      lat: centerPoint[1] + geofenceRadius, // northEastLat
                    },
                    {
                      lon: centerPoint[0] - geofenceRadius, // northWestLon
                      lat: centerPoint[1] + geofenceRadius, // northWestLat
                    },
                    {
                      lon: centerPoint[0] - geofenceRadius, // southWestLon
                      lat: centerPoint[1] - geofenceRadius, // southWestLat
                    },
                    {
                      lon: centerPoint[0] + geofenceRadius, // southEastLon
                      lat: centerPoint[1] - geofenceRadius, // southEastLat
                    },
                  ]
                : record.points.map(lonLats => ({
                    lon: lonLats[0],
                    lat: lonLats[1],
                  }));
            mapController.setBoundary(recordBoundaryLonLat);
          } else {
            mapController.setBoundary(boundaryLonLat);
          }

          // Homeボタン押下時と同じ動作を実施
          mapController.navigateToHome(0);
        }
        // Homeボタン押下時と同じ動作を実施
        mapController.navigateToHome(0);
      }
    },
    [siteId, mapController, projectsData, record],
  );

  useEffect(() => {
    mapController.setAddOnEventEnd(event => {
      switch (event) {
        case 'loaded':
        case 'draw':
        case 'translate':
        case 'modify':
        case 'delete':
          {
            const { sourceVector } = mapController.getMapProps();

            // Typeコントロールを最新の状況へ更新しておく ※featureが存在しなくても更新が必要
            mapController.renderTypeControl(
              mapController.getMapProps(),
              existEditFeature(featureId, sourceVector),
            );
            // feature,geometryが存在しない場合は、後続処理の実施不要
            const feature = sourceVector.getFeatureById(`${featureId}`);
            if (!feature) return;
            const geometry = createGeometryFromFeature(
              feature,
              mapController.getSelectedType(),
            );
            if (!geometry) return;

            mapController.setInputObjectData({
              id: geometry.id,
              coordinates: geometry.coordinates,
              localCoordinates: geometry.coordinates.map(ll => [
                ll.lon,
                ll.lat,
              ]),
            });
            mapController.setSelectFeature(featureId);
            if (event !== 'delete' && event !== 'translate') {
              mapController.setModifyMode();
            }
            // 円の場合はRadiusの値を更新しておく
            if (mapController.getSelectedType() === 'Circle') {
              mapController.setSettingRadius(geometry.radius);
            }
            // setInputObjectDataで設定したデータを入力コンポーネントに反映する
            mapController.renderInputComponent(mapController.getMapProps());
          }
          break;
        default:
      }
    });
  }, [mapController, featureId]);

  const handleConfigre = useCallback(
    (_mapProps: MapProps) => {
      const { map, sourceVector } = _mapProps;
      const mapOverlay = new Overlay({
        element: document.getElementById(COORDINATE_INPUT_ID)!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      });
      map.addOverlay(mapOverlay);

      const removeTarget = () => {
        mapOverlay.setPosition(undefined);
      };

      map.on('pointerdrag', () => removeTarget());
      map.on('click', ({ pixel }) => {
        removeTarget();
        map.forEachFeatureAtPixel(pixel, feature => {
          const id = feature.getId();
          if (!id) return;
          const geometry = createGeometryFromFeature(
            sourceVector.getFeatureById(`${id}`),
            mapController.getSelectedType(),
          );
          if (!geometry) return;
          mapController.setInputObjectData({
            id: geometry.id,
            coordinates: geometry.coordinates,
            localCoordinates: geometry.coordinates.map(ll => [ll.lon, ll.lat]),
          });
        });
      });
    },
    [mapController],
  );

  /*
  // MEMO: 右側のリストにクリック時に座標を表示していた際のコード ※不要になったら削除すること 
  const handleSelectCoordinate = useCallback(
    ({ coordinate }) => {
      if (!mapProps || !overlay) return;
      if (circle) {
        mapProps.sourceVector.removeFeature(circle);
      }
      const coordinates = geographicToMercator([
        coordinate.lon,
        coordinate.lat,
      ]);
      overlay.setPosition(coordinates);
      circle = createCircle(coordinates);
      mapProps.sourceVector.addFeature(circle);
    },
    [mapProps, overlay],
  );
  */

  useEffect(() => {
    mapController.removeProjectSourceLayer();
    if (!permissions) return;
    if (!projectsData?.latestProjectVersionId) return;
    if (!projectLayerId) return;
    const { token } = permissions;
    const projectVersionId = projectsData?.latestProjectVersionId;
    const projectLineWorkIds = projectsData?.projectSourceLineWorks.map(
      x => x.mapServerProjectSourceLineWorkId,
    );
    mapController.setProjectSourceLayer(
      projectLayerId,
      projectVersionId,
      token,
    );
    mapController.setProjectSourceLayerLabelPoint(projectVersionId, token);
    mapController.setProjectSourceLayerPointLabel(projectVersionId, token);
    mapController.setProjectSourceLocationPoint(projectVersionId, token);
    mapController.setProjectSourceLocationLabel(projectVersionId, token);
    if (!projectLineWorkIds) return;
    projectLineWorkIds.forEach(x => {
      mapController.setProjectSourceLineWork(x, projectVersionId, token);
    });
  }, [permissions, projectsData, projectLayerId, mapController]);

  return (
    <div>
      <div>
        {siteId && (
          <GeofenceListProjectInput
            siteId={siteId}
            onChangeProject={handleChangeProject}
          />
        )}
        {siteId && projectsData && (
          <GeofenceListProjectLayerInput
            projectsData={projectsData}
            onChangeLayer={handleChangeLayer}
          />
        )}
      </div>
      <div className={`${paramName}Imple`} style={{ display: 'flex' }}>
        <GeofenceMapField
          {...props}
          onConfigure={handleConfigre}
          height={405}
          width={960}
          featureId={featureId}
          mapBackgroundColor={mapBackgroundColor}
        />
        <GeofenceMapCoordinatePopup
          id={COORDINATE_INPUT_ID}
          label="resources.geofences.fields.height"
        />
      </div>
    </div>
  );
};

GeofenceMapInput.defaultProps = {
  label: 'resources.geofences.fields.points',
  addLabel: true,
  isRequired: true,
};
GeofenceMapInput.displayName = 'GeofenceMapInput';
export default GeofenceMapInput;
