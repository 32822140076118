import React from 'react';
import { Route } from 'react-router-dom';

type CreateCustomRoutesItems = {
  path: string;
  component: React.FC;
}[];

/**
 * カスタムルートの生成
 * @param items ルーター情報
 */
export default (items: CreateCustomRoutesItems) =>
  items.map(({ path, component }, key) => (
    <Route key={key} exact path={path} component={component} />
  ));
