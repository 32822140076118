import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useRedirect } from 'react-admin';
import { colors } from '../../../../theme';
import { buttonWidth, buttonHeight } from '../../../../theme/define';
import { Button } from '../../..';
import { useAgreementStore } from '../../../../hooks';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: colors.button.save,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      color: colors.text.primaryLight,
      fontSize: 14,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: colors.button.saveHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.saveDisabled,
      },
      '& span': {
        padding: 0,
      },
    },
  }),
);

interface Props {
  termsConditionId: number;
  disabled?: boolean;
}

const AcceptButton: FC<Props> = ({ termsConditionId, disabled }) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const { setAgreement } = useAgreementStore();

  const handleClick = () => {
    setAgreement(termsConditionId).then(() => redirect('sites'));
  };

  return (
    <Button
      className={classes.root}
      label="admin.actions.termsAccept"
      data-testid="admin.actions.termsAccept"
      disabled={disabled}
      onClick={handleClick}
    />
  );
};

AcceptButton.displayName = 'AcceptButton';
export default AcceptButton;
