import React, { FC, Fragment } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { LicenseElement } from '../../utils/licenseToConvertElement';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      '& > .list-icon': {
        paddingLeft: '26px',
      },
      '& > div': {
        display: 'flex',
      },
    },
  }),
);

interface Props {
  element: LicenseElement;
  isList?: boolean;
}

const trancelateLicensePermission = (permission: string) => {
  if (permission === 'MachineGuidance2D') {
    return '2D MachineGuidance';
  }
  return permission;
};

const LicenseIconField: FC<Props> = ({ element, isList }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tooltip
        className={isList ? 'list-icon' : 'detail-icon'}
        title={trancelateLicensePermission(element.permission)}
      >
        <div>
          {element.iconList.map((icon, index) => {
            const Icon = icon;
            return (
              <Fragment key={index}>
                <Icon />
                <Box m={0.3} />
              </Fragment>
            );
          })}
        </div>
      </Tooltip>
    </div>
  );
};

LicenseIconField.displayName = 'LicenseIconField';
export default LicenseIconField as FC<Partial<Props>>;
