import { UserNoticeStatusData } from '../../types';

const userNoticeStatus: UserNoticeStatusData[] = [
  {
    id: '1/test_user@example.com/EN',
    userNoticeId: 1,
    email: 'test_user@example.com',
    language: 'EN',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    read: false,
    title: 'Welcome to SC Pilot',
    content:
      'Welcome to SC Pilot\r\n The SC pilot is a function for managing after adding the machine guidance function. Refer to the operation manual for the required operations.',
    file: [
      {
        src: 'https://dummy/welcome_en.pdf',
        title: 'Welcome to SC Pilot',
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: '7/test_user@example.com/EN',
    userNoticeId: 7,
    email: 'test_user@example.com',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-12-15T01:00:00.000Z',
    publicationEndDate: '2021-01-13T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.7.0 release',
    content:
      'SC Pilot Version 2.7.0 will be released on 01/12/2021.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 01/12/2021 18:00\r\n Maintenance end date: 01/12/2021 20:00',
    file: [
      {
        src: 'https://dummy/release_2_7_0_en.pdf',
        title: 'SC Pilot Version2.7.0 release',
      },
    ],
    updateDate: '2021-01-13T01:00:00.000Z',
  },
  {
    id: '8/test_user@example.com/EN',
    userNoticeId: 8,
    email: 'test_user@example.com',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-12-01T01:00:00.000Z',
    publicationEndDate: '2020-12-15T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.6.0 release',
    content:
      'SC Pilot Version 2.6.0 will be released on 12/14/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 12/14/2020 18:00\r\n Maintenance end date: 12/14/2020 20:00',
    file: [
      {
        src: 'https://dummy/release_2_6_0_en.pdf',
        title: 'SC Pilot Version2.6.0 release',
      },
    ],
    updateDate: '2020-12-15T01:00:00.000Z',
  },
  {
    id: '9/test_user@example.com/EN',
    userNoticeId: 9,
    email: 'test_user@example.com',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-11-17T01:00:00.000Z',
    publicationEndDate: '2020-12-01T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.5.0 release',
    content:
      'SC Pilot Version 2.5.0 will be released on 11/30/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 11/30/2020 18:00\r\n Maintenance end date: 11/30/2020 20:00',
    file: [
      {
        src: 'https://dummy/release_2_5_0_en.pdf',
        title: 'SC Pilot Version2.5.0 release',
      },
    ],
    updateDate: '2020-12-01T01:00:00.000Z',
  },
  {
    id: '10/test_user@example.com/EN',
    userNoticeId: 10,
    email: 'test_user@example.com',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-11-03T01:00:00.000Z',
    publicationEndDate: '2020-11-17T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.4.0 release',
    content:
      'SC Pilot Version 2.4.0 will be released on 11/16/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 11/15/2020 18:00\r\n Maintenance end date: 11/16/2020 20:00',
    file: [
      {
        src: 'https://dummy/release_2_4_0_en.pdf',
        title: 'SC Pilot Version2.4.0 release',
      },
    ],
    updateDate: '2020-11-17T01:00:00.000Z',
  },
  {
    id: '11/test_user@example.com/EN',
    userNoticeId: 11,
    email: 'test_user@example.com',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-10-20T01:00:00.000Z',
    publicationEndDate: '2020-11-03T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.3.0 release',
    content:
      'SC Pilot Version 2.3.0 will be released on 11/02/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 11/02/2020 18:00\r\n Maintenance end date: 11/02/2020 20:00',
    file: [
      {
        src: 'https://dummy/release_2_3_0_en.pdf',
        title: 'SC Pilot Version2.3.0 release',
      },
    ],
    updateDate: '2020-11-03T01:00:00.000Z',
  },
  {
    id: '12/test_user@example.com/EN',
    userNoticeId: 12,
    email: 'test_user@example.com',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-10-06T01:00:00.000Z',
    publicationEndDate: '2020-10-20T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.2.0 release',
    content:
      'SC Pilot Version 2.2.0 will be released on 10/19/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/10/19 18:00\r\n Maintenance end date: 2020/10/19 20:00',
    file: [
      {
        src: 'https://dummy/release_2_2_0_en.pdf',
        title: 'SC Pilot Version2.2.0 release',
      },
    ],
    updateDate: '2020-10-20T01:00:00.000Z',
  },
  {
    id: '13/test_user@example.com/EN',
    userNoticeId: 13,
    email: 'test_user@example.com',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-09-15T01:00:00.000Z',
    publicationEndDate: '2020-10-06T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version2.1.0 release',
    content:
      'SC Pilot Version 2.1.0 will be released on 10/05/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/10/05 18:00\r\n Maintenance end date: 2020/10/05 20:00',
    file: [
      {
        src: 'https://dummy/release_2_1_0_en.pdf',
        title: 'SC Pilot Version2.1.0 release',
      },
    ],
    updateDate: '2020-10-06T01:00:00.000Z',
  },
  {
    id: '14/test_user@example.com/EN',
    userNoticeId: 14,
    email: 'test_user@example.com',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-09-01T01:00:00.000Z',
    publicationEndDate: '2020-09-15T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version2.0.0 release',
    content:
      'SC Pilot Version 2.0.0 will be released on 09 /14/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/09/14 18:00\r\n Maintenance end date: 2020/09/14 20:00',
    file: [
      {
        src: 'https://dummy/release_2_0_0_en.pdf',
        title: 'SC Pilot Version2.0.0 release',
      },
    ],
    updateDate: '2020-09-15T01:00:00.000Z',
  },
  {
    id: '15/test_user@example.com/EN',
    userNoticeId: 15,
    email: 'test_user@example.com',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-08-18T01:00:00.000Z',
    publicationEndDate: '2020-09-01T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version1.2.3 release',
    content:
      'SC Pilot Version 1.2.3 will be released on 08 /31/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/08/31 18:00\r\n Maintenance end date: 2020/08/31 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_3_en.pdf',
        title: 'SC Pilot Version1.2.3 release',
      },
    ],
    updateDate: '2020-09-01T01:00:00.000Z',
  },
  {
    id: '16/test_user@example.com/EN',
    userNoticeId: 16,
    email: 'test_user@example.com',
    language: 'EN',
    noticeType: 'EmergencyImportant',
    noticeTypes: ['Emergency', 'Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-07-28T01:00:00.000Z',
    publicationEndDate: '2020-08-18T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version1.2.2 release',
    content:
      'SC Pilot Version 1.2.2 will be released on 08 /17/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/08/17 18:00\r\n Maintenance end date: 2020/08/17 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_2_en.pdf',
        title: 'SC Pilot Version1.2.2 release',
      },
    ],
    updateDate: '2020-08-18T01:00:00.000Z',
  },
  {
    id: '17/test_user@example.com/EN',
    userNoticeId: 17,
    email: 'test_user@example.com',
    language: 'EN',
    noticeType: 'Emergency',
    noticeTypes: ['Emergency'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-07-14T01:00:00.000Z',
    publicationEndDate: '2020-07-28T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version1.2.1 release',
    content:
      'SC Pilot Version 1.2.1 will be released on 07 /27/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/07/27 18:00\r\n Maintenance end date: 2020/07/27 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_1_en.pdf',
        title: 'SC Pilot Version1.2.1 release',
      },
    ],
    updateDate: '2020-07-28T01:00:00.000Z',
  },
  {
    id: '18/test_user@example.com/EN',
    userNoticeId: 18,
    email: 'test_user@example.com',
    language: 'EN',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-07-14T01:00:00.000Z',
    publicationEndDate: '2020-07-28T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version1.2.1 release',
    content:
      'SC Pilot Version 1.2.1 will be released on 07 /27/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/07/27 18:00\r\n Maintenance end date: 2020/07/27 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_1_en.pdf',
        title: 'SC Pilot Version1.2.1 release',
      },
    ],
    updateDate: '2020-07-28T01:00:00.000Z',
  },
  {
    id: '19/test_user@example.com/EN',
    userNoticeId: 19,
    email: 'test_user@example.com',
    language: 'EN',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    read: true,
    title: 'Welcome to SC Pilot 2',
    content:
      'Welcome to SC Pilot\r\n The SC pilot is a function for managing after adding the machine guidance function. Refer to the operation manual for the required operations.',
    file: [
      {
        src: 'https://dummy/welcome_en.pdf',
        title: 'Welcome to SC Pilot 2',
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: '20/test_user@example.com/EN',
    userNoticeId: 20,
    email: 'test_user@example.com',
    language: 'EN',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    read: true,
    title: 'Welcome to SC Pilot 3',
    content:
      'Welcome to SC Pilot\r\n The SC pilot is a function for managing after adding the machine guidance function. Refer to the operation manual for the required operations.',
    file: [
      {
        src: 'https://dummy/welcome_en.pdf',
        title: 'Welcome to SC Pilot 3',
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: '21/test_user@example.com/EN',
    userNoticeId: 21,
    email: 'test_user@example.com',
    language: 'EN',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    read: true,
    title: 'Welcome to SC Pilot 4',
    content:
      'Welcome to SC Pilot\r\n The SC pilot is a function for managing after adding the machine guidance function. Refer to the operation manual for the required operations.',
    file: [
      {
        src: 'https://dummy/welcome_en.pdf',
        title: 'Welcome to SC Pilot 4',
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: '1/test_user@example.com/JA',
    userNoticeId: 1,
    email: 'test_user@example.com',
    language: 'JA',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    read: false,
    title: 'SC Pilotへようこそ',
    content:
      'SC Pilotへようこそ\r\n SC pilotはマシンガイダンス機能を後付けしたあと、管理するための機能となっています。必要な操作は操作マニュアルを参照してください',
    file: [
      {
        src: 'https://dummy/welcome_ja.pdf',
        title: 'SC Pilotへようこそ',
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: '7/test_user@example.com/JA',
    userNoticeId: 7,
    email: 'test_user@example.com',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-12-15T01:00:00.000Z',
    publicationEndDate: '2021-01-13T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.7.0 releaseのお知らせ',
    content:
      'SC Pilot Version2.7.0 releaseのお知らせ\r\n \r\n 2021/01/12にSC Pilot Version2.7.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2021/01/12 18:00\r\n メンテナンス終了日:2021/01/12 20:00',
    file: [
      {
        src: 'https://dummy/release_2_7_0_ja.pdf',
        title: 'SC Pilot Version2.7.0 releaseのお知らせ',
      },
    ],
    updateDate: '2021-01-13T01:00:00.000Z',
  },
  {
    id: '8/test_user@example.com/JA',
    userNoticeId: 8,
    email: 'test_user@example.com',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-12-01T01:00:00.000Z',
    publicationEndDate: '2020-12-15T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.6.0 releaseのお知らせ',
    content:
      'SC Pilot Version2.6.0 releaseのお知らせ\r\n \r\n 2020/12/14にSC Pilot Version2.6.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/12/14 18:00\r\n メンテナンス終了日:2020/12/14 20:00',
    file: [
      {
        src: 'https://dummy/release_2_6_0_ja.pdf',
        title: 'SC Pilot Version2.6.0 releaseのお知らせ',
      },
    ],
    updateDate: '2020-12-15T01:00:00.000Z',
  },
  {
    id: '9/test_user@example.com/JA',
    userNoticeId: 9,
    email: 'test_user@example.com',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-11-17T01:00:00.000Z',
    publicationEndDate: '2020-12-01T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.5.0 releaseのお知らせ',
    content:
      'SC Pilot Version2.5.0 releaseのお知らせ\r\n \r\n 2020/11/30にSC Pilot Version2.5.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/11/30 18:00\r\n メンテナンス終了日:2020/11/30 20:00',
    file: [
      {
        src: 'https://dummy/release_2_5_0_ja.pdf',
        title: 'SC Pilot Version2.5.0 releaseのお知らせ',
      },
    ],
    updateDate: '2020-12-01T01:00:00.000Z',
  },
  {
    id: '10/test_user@example.com/JA',
    userNoticeId: 10,
    email: 'test_user@example.com',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-11-03T01:00:00.000Z',
    publicationEndDate: '2020-11-17T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.4.0 releaseのお知らせ',
    content:
      'SC Pilot Version2.4.0 releaseのお知らせ\r\n \r\n 2020/11/16にSC Pilot Version2.4.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/11/16 18:00\r\n メンテナンス終了日:2020/11/16 20:00',
    file: [
      {
        src: 'https://dummy/release_2_4_0_ja.pdf',
        title: 'SC Pilot Version2.4.0 releaseのお知らせ',
      },
    ],
    updateDate: '2020-11-17T01:00:00.000Z',
  },
  {
    id: '11/test_user@example.com/JA',
    userNoticeId: 11,
    email: 'test_user@example.com',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-10-20T01:00:00.000Z',
    publicationEndDate: '2020-11-03T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.3.0 releaseのお知らせ',
    content:
      'SC Pilot Version2.3.0 releaseのお知らせ\r\n \r\n 2020/11/02にSC Pilot Version2.3.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/11/02 18:00\r\n メンテナンス終了日:2020/11/02 20:00',
    file: [
      {
        src: 'https://dummy/release_2_3_0_ja.pdf',
        title: 'SC Pilot Version2.3.0 releaseのお知らせ',
      },
    ],
    updateDate: '2020-11-03T01:00:00.000Z',
  },
  {
    id: '12/test_user@example.com/JA',
    userNoticeId: 12,
    email: 'test_user@example.com',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-10-06T01:00:00.000Z',
    publicationEndDate: '2020-10-20T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.2.0 releaseのお知らせ',
    content:
      'SC Pilot Version2.2.0 releaseのお知らせ\r\n \r\n 2020/10/19にSC Pilot Version2.1.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/10/19 18:00\r\n メンテナンス終了日:2020/10/19 20:00',
    file: [
      {
        src: 'https://dummy/release_2_2_0_ja.pdf',
        title: 'SC Pilot Version2.2.0 releaseのお知らせ',
      },
    ],
    updateDate: '2020-10-20T01:00:00.000Z',
  },
  {
    id: '13/test_user@example.com/JA',
    userNoticeId: 13,
    email: 'test_user@example.com',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-09-15T01:00:00.000Z',
    publicationEndDate: '2020-10-06T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version2.1.0 releaseのお知らせ',
    content:
      'SC Pilot Version2.1.0 releaseのお知らせ\r\n \r\n 2020/10/05にSC Pilot Version2.1.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/10/05 18:00\r\n メンテナンス終了日:2020/10/05 20:00',
    file: [
      {
        src: 'https://dummy/release_2_1_0_ja.pdf',
        title: 'SC Pilot Version2.1.0 releaseのお知らせ',
      },
    ],
    updateDate: '2020-10-06T01:00:00.000Z',
  },
  {
    id: '14/test_user@example.com/JA',
    userNoticeId: 14,
    email: 'test_user@example.com',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-09-01T01:00:00.000Z',
    publicationEndDate: '2020-09-15T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version2.0.0 releaseのお知らせ',
    content:
      'SC Pilot Version2.0.0 releaseのお知らせ\r\n \r\n 2020/09/14にSC Pilot Version2.0.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/09/14 18:00\r\n メンテナンス終了日:2020/09/14 20:00',
    file: [
      {
        src: 'https://dummy/release_2_0_0_ja.pdf',
        title: 'SC Pilot Version2.0.0 releaseのお知らせ',
      },
    ],
    updateDate: '2020-09-15T01:00:00.000Z',
  },
  {
    id: '15/test_user@example.com/JA',
    userNoticeId: 15,
    email: 'test_user@example.com',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-08-18T01:00:00.000Z',
    publicationEndDate: '2020-09-01T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version1.2.3 releaseのお知らせ',
    content:
      'SC Pilot Version1.2.3 releaseのお知らせ\r\n \r\n 2020/08/29にSC Pilot Version1.2.3 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/08/31 18:00\r\n メンテナンス終了日:2020/08/31 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_3_ja.pdf',
        title: 'SC Pilot Version1.2.3 releaseのお知らせ',
      },
    ],
    updateDate: '2020-09-01T01:00:00.000Z',
  },
  {
    id: '16/test_user@example.com/JA',
    userNoticeId: 16,
    email: 'test_user@example.com',
    language: 'JA',
    noticeType: 'EmergencyImportant',
    noticeTypes: ['Emergency', 'Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-07-28T01:00:00.000Z',
    publicationEndDate: '2020-08-18T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version1.2.2 releaseのお知らせ',
    content:
      'SC Pilot Version1.2.2 releaseのお知らせ\r\n \r\n 2020/08/17にSC Pilot Version1.2.2 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/08/17 18:00\r\n メンテナンス終了日:2020/08/17 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_2_ja.pdf',
        title: 'SC Pilot Version1.2.2 releaseのお知らせ',
      },
    ],
    updateDate: '2020-08-18T01:00:00.000Z',
  },
  {
    id: '17/test_user@example.com/JA',
    userNoticeId: 17,
    email: 'test_user@example.com',
    language: 'JA',
    noticeType: 'Emergency',
    noticeTypes: ['Emergency'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-07-14T01:00:00.000Z',
    publicationEndDate: '2020-07-28T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version1.2.1 releaseのお知らせ',
    content:
      'SC Pilot Version1.2.1 releaseのお知らせ\r\n \r\n 2020/07/27にSC Pilot Version1.2.2 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/07/27 18:00\r\n メンテナンス終了日:2020/07/27 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_1_ja.pdf',
        title: 'SC Pilot Version1.2.1 releaseのお知らせ',
      },
    ],
    updateDate: '2020-07-28T01:00:00.000Z',
  },
  {
    id: '18/test_user@example.com/JA',
    userNoticeId: 18,
    email: 'test_user@example.com',
    language: 'JA',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-07-14T01:00:00.000Z',
    publicationEndDate: '2020-07-28T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version1.2.1 releaseのお知らせ',
    content:
      'SC Pilot Version1.2.1 releaseのお知らせ\r\n \r\n 2020/07/27にSC Pilot Version1.2.2 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/07/27 18:00\r\n メンテナンス終了日:2020/07/27 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_1_ja.pdf',
        title: 'SC Pilot Version1.2.1 releaseのお知らせ',
      },
    ],
    updateDate: '2020-07-28T01:00:00.000Z',
  },
  {
    id: '19/test_user@example.com/JA',
    userNoticeId: 19,
    email: 'test_user@example.com',
    language: 'JA',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    read: true,
    title: 'SC Pilotへようこそ 2',
    content:
      'SC Pilotへようこそ\r\n SC pilotはマシンガイダンス機能を後付けしたあと、管理するための機能となっています。必要な操作は操作マニュアルを参照してください',
    file: [
      {
        src: 'https://dummy/welcome_ja.pdf',
        title: 'SC Pilotへようこそ2',
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: '20/test_user@example.com/JA',
    userNoticeId: 20,
    email: 'test_user@example.com',
    language: 'JA',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    read: true,
    title: 'SC Pilotへようこそ 3',
    content:
      'SC Pilotへようこそ\r\n SC pilotはマシンガイダンス機能を後付けしたあと、管理するための機能となっています。必要な操作は操作マニュアルを参照してください',
    file: [
      {
        src: 'https://dummy/welcome_ja.pdf',
        title: 'SC Pilotへようこそ3',
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: '21/test_user@example.com/JA',
    userNoticeId: 21,
    email: 'test_user@example.com',
    language: 'JA',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    read: true,
    title: 'SC Pilotへようこそ 4',
    content:
      'SC Pilotへようこそ\r\n SC pilotはマシンガイダンス機能を後付けしたあと、管理するための機能となっています。必要な操作は操作マニュアルを参照してください',
    file: [
      {
        src: 'https://dummy/welcome_ja.pdf',
        title: 'SC Pilotへようこそ4',
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: '1/3dmg_test_user_a@qualica.co.jp/EN',
    userNoticeId: 1,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'EN',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    read: false,
    title: 'Welcome to SC Pilot',
    content:
      'Welcome to SC Pilot\r\n The SC pilot is a function for managing after adding the machine guidance function. Refer to the operation manual for the required operations.',
    file: [
      {
        src: 'https://dummy/welcome_en.pdf',
        title: 'Welcome to SC Pilot',
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: '7/3dmg_test_user_a@qualica.co.jp/EN',
    userNoticeId: 7,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-12-15T01:00:00.000Z',
    publicationEndDate: '2021-01-13T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.7.0 release',
    content:
      'SC Pilot Version 2.7.0 will be released on 01/12/2021.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 01/12/2021 18:00\r\n Maintenance end date: 01/12/2021 20:00',
    file: [
      {
        src: 'https://dummy/release_2_7_0_en.pdf',
        title: 'SC Pilot Version2.7.0 release',
      },
    ],
    updateDate: '2021-01-13T01:00:00.000Z',
  },
  {
    id: '8/3dmg_test_user_a@qualica.co.jp/EN',
    userNoticeId: 8,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-12-01T01:00:00.000Z',
    publicationEndDate: '2020-12-15T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.6.0 release',
    content:
      'SC Pilot Version 2.6.0 will be released on 12/14/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 12/14/2020 18:00\r\n Maintenance end date: 12/14/2020 20:00',
    file: [
      {
        src: 'https://dummy/release_2_6_0_en.pdf',
        title: 'SC Pilot Version2.6.0 release',
      },
    ],
    updateDate: '2020-12-15T01:00:00.000Z',
  },
  {
    id: '9/3dmg_test_user_a@qualica.co.jp/EN',
    userNoticeId: 9,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-11-17T01:00:00.000Z',
    publicationEndDate: '2020-12-01T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.5.0 release',
    content:
      'SC Pilot Version 2.5.0 will be released on 11/30/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 11/30/2020 18:00\r\n Maintenance end date: 11/30/2020 20:00',
    file: [
      {
        src: 'https://dummy/release_2_5_0_en.pdf',
        title: 'SC Pilot Version2.5.0 release',
      },
    ],
    updateDate: '2020-12-01T01:00:00.000Z',
  },
  {
    id: '10/3dmg_test_user_a@qualica.co.jp/EN',
    userNoticeId: 10,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-11-03T01:00:00.000Z',
    publicationEndDate: '2020-11-17T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.4.0 release',
    content:
      'SC Pilot Version 2.4.0 will be released on 11/16/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 11/15/2020 18:00\r\n Maintenance end date: 11/16/2020 20:00',
    file: [
      {
        src: 'https://dummy/release_2_4_0_en.pdf',
        title: 'SC Pilot Version2.4.0 release',
      },
    ],
    updateDate: '2020-11-17T01:00:00.000Z',
  },
  {
    id: '11/3dmg_test_user_a@qualica.co.jp/EN',
    userNoticeId: 11,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-10-20T01:00:00.000Z',
    publicationEndDate: '2020-11-03T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.3.0 release',
    content:
      'SC Pilot Version 2.3.0 will be released on 11/02/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 11/02/2020 18:00\r\n Maintenance end date: 11/02/2020 20:00',
    file: [
      {
        src: 'https://dummy/release_2_3_0_en.pdf',
        title: 'SC Pilot Version2.3.0 release',
      },
    ],
    updateDate: '2020-11-03T01:00:00.000Z',
  },
  {
    id: '12/3dmg_test_user_a@qualica.co.jp/EN',
    userNoticeId: 12,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-10-06T01:00:00.000Z',
    publicationEndDate: '2020-10-20T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.2.0 release',
    content:
      'SC Pilot Version 2.2.0 will be released on 10/19/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/10/19 18:00\r\n Maintenance end date: 2020/10/19 20:00',
    file: [
      {
        src: 'https://dummy/release_2_2_0_en.pdf',
        title: 'SC Pilot Version2.2.0 release',
      },
    ],
    updateDate: '2020-10-20T01:00:00.000Z',
  },
  {
    id: '13/3dmg_test_user_a@qualica.co.jp/EN',
    userNoticeId: 13,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-09-15T01:00:00.000Z',
    publicationEndDate: '2020-10-06T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version2.1.0 release',
    content:
      'SC Pilot Version 2.1.0 will be released on 10/05/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/10/05 18:00\r\n Maintenance end date: 2020/10/05 20:00',
    file: [
      {
        src: 'https://dummy/release_2_1_0_en.pdf',
        title: 'SC Pilot Version2.1.0 release',
      },
    ],
    updateDate: '2020-10-06T01:00:00.000Z',
  },
  {
    id: '14/3dmg_test_user_a@qualica.co.jp/EN',
    userNoticeId: 14,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-09-01T01:00:00.000Z',
    publicationEndDate: '2020-09-15T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version2.0.0 release',
    content:
      'SC Pilot Version 2.0.0 will be released on 09 /14/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/09/14 18:00\r\n Maintenance end date: 2020/09/14 20:00',
    file: [
      {
        src: 'https://dummy/release_2_0_0_en.pdf',
        title: 'SC Pilot Version2.0.0 release',
      },
    ],
    updateDate: '2020-09-15T01:00:00.000Z',
  },
  {
    id: '15/3dmg_test_user_a@qualica.co.jp/EN',
    userNoticeId: 15,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'EN',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-08-18T01:00:00.000Z',
    publicationEndDate: '2020-09-01T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version1.2.3 release',
    content:
      'SC Pilot Version 1.2.3 will be released on 08 /31/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/08/31 18:00\r\n Maintenance end date: 2020/08/31 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_3_en.pdf',
        title: 'SC Pilot Version1.2.3 release',
      },
    ],
    updateDate: '2020-09-01T01:00:00.000Z',
  },
  {
    id: '16/3dmg_test_user_a@qualica.co.jp/EN',
    userNoticeId: 16,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'EN',
    noticeType: 'EmergencyImportant',
    noticeTypes: ['Emergency', 'Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-07-28T01:00:00.000Z',
    publicationEndDate: '2020-08-18T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version1.2.2 release',
    content:
      'SC Pilot Version 1.2.2 will be released on 08 /17/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/08/17 18:00\r\n Maintenance end date: 2020/08/17 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_2_en.pdf',
        title: 'SC Pilot Version1.2.2 release',
      },
    ],
    updateDate: '2020-08-18T01:00:00.000Z',
  },
  {
    id: '17/3dmg_test_user_a@qualica.co.jp/EN',
    userNoticeId: 17,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'EN',
    noticeType: 'Emergency',
    noticeTypes: ['Emergency'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-07-14T01:00:00.000Z',
    publicationEndDate: '2020-07-28T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version1.2.1 release',
    content:
      'SC Pilot Version 1.2.1 will be released on 07 /27/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/07/27 18:00\r\n Maintenance end date: 2020/07/27 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_1_en.pdf',
        title: 'SC Pilot Version1.2.1 release',
      },
    ],
    updateDate: '2020-07-28T01:00:00.000Z',
  },
  {
    id: '18/3dmg_test_user_a@qualica.co.jp/EN',
    userNoticeId: 18,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'EN',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-07-14T01:00:00.000Z',
    publicationEndDate: '2020-07-28T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version1.2.1 release',
    content:
      'SC Pilot Version 1.2.1 will be released on 07 /27/2020.\r\n \r\n The system stop date and time are as follows.\r\n \r\n Maintenance start date and time: 2020/07/27 18:00\r\n Maintenance end date: 2020/07/27 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_1_en.pdf',
        title: 'SC Pilot Version1.2.1 release',
      },
    ],
    updateDate: '2020-07-28T01:00:00.000Z',
  },
  {
    id: '19/3dmg_test_user_a@qualica.co.jp/EN',
    userNoticeId: 19,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'EN',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    read: true,
    title: 'Welcome to SC Pilot 2',
    content:
      'Welcome to SC Pilot\r\n The SC pilot is a function for managing after adding the machine guidance function. Refer to the operation manual for the required operations.',
    file: [
      {
        src: 'https://dummy/welcome_en.pdf',
        title: 'Welcome to SC Pilot 2',
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: '20/3dmg_test_user_a@qualica.co.jp/EN',
    userNoticeId: 20,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'EN',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    read: true,
    title: 'Welcome to SC Pilot 3',
    content:
      'Welcome to SC Pilot\r\n The SC pilot is a function for managing after adding the machine guidance function. Refer to the operation manual for the required operations.',
    file: [
      {
        src: 'https://dummy/welcome_en.pdf',
        title: 'Welcome to SC Pilot 3',
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: '21/3dmg_test_user_a@qualica.co.jp/EN',
    userNoticeId: 21,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'EN',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    read: true,
    title: 'Welcome to SC Pilot 4',
    content:
      'Welcome to SC Pilot\r\n The SC pilot is a function for managing after adding the machine guidance function. Refer to the operation manual for the required operations.',
    file: [
      {
        src: 'https://dummy/welcome_en.pdf',
        title: 'Welcome to SC Pilot 4',
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: '1/3dmg_test_user_a@qualica.co.jp/JA',
    userNoticeId: 1,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'JA',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    read: false,
    title: 'SC Pilotへようこそ',
    content:
      'SC Pilotへようこそ\r\n SC pilotはマシンガイダンス機能を後付けしたあと、管理するための機能となっています。必要な操作は操作マニュアルを参照してください',
    file: [
      {
        src: 'https://dummy/welcome_ja.pdf',
        title: 'SC Pilotへようこそ',
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: '7/3dmg_test_user_a@qualica.co.jp/JA',
    userNoticeId: 7,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-12-15T01:00:00.000Z',
    publicationEndDate: '2021-01-13T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.7.0 releaseのお知らせ',
    content:
      'SC Pilot Version2.7.0 releaseのお知らせ\r\n \r\n 2021/01/12にSC Pilot Version2.7.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2021/01/12 18:00\r\n メンテナンス終了日:2021/01/12 20:00',
    file: [
      {
        src: 'https://dummy/release_2_7_0_ja.pdf',
        title: 'SC Pilot Version2.7.0 releaseのお知らせ',
      },
    ],
    updateDate: '2021-01-13T01:00:00.000Z',
  },
  {
    id: '8/3dmg_test_user_a@qualica.co.jp/JA',
    userNoticeId: 8,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-12-01T01:00:00.000Z',
    publicationEndDate: '2020-12-15T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.6.0 releaseのお知らせ',
    content:
      'SC Pilot Version2.6.0 releaseのお知らせ\r\n \r\n 2020/12/14にSC Pilot Version2.6.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/12/14 18:00\r\n メンテナンス終了日:2020/12/14 20:00',
    file: [
      {
        src: 'https://dummy/release_2_6_0_ja.pdf',
        title: 'SC Pilot Version2.6.0 releaseのお知らせ',
      },
    ],
    updateDate: '2020-12-15T01:00:00.000Z',
  },
  {
    id: '9/3dmg_test_user_a@qualica.co.jp/JA',
    userNoticeId: 9,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-11-17T01:00:00.000Z',
    publicationEndDate: '2020-12-01T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.5.0 releaseのお知らせ',
    content:
      'SC Pilot Version2.5.0 releaseのお知らせ\r\n \r\n 2020/11/30にSC Pilot Version2.5.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/11/30 18:00\r\n メンテナンス終了日:2020/11/30 20:00',
    file: [
      {
        src: 'https://dummy/release_2_5_0_ja.pdf',
        title: 'SC Pilot Version2.5.0 releaseのお知らせ',
      },
    ],
    updateDate: '2020-12-01T01:00:00.000Z',
  },
  {
    id: '10/3dmg_test_user_a@qualica.co.jp/JA',
    userNoticeId: 10,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-11-03T01:00:00.000Z',
    publicationEndDate: '2020-11-17T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.4.0 releaseのお知らせ',
    content:
      'SC Pilot Version2.4.0 releaseのお知らせ\r\n \r\n 2020/11/16にSC Pilot Version2.4.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/11/16 18:00\r\n メンテナンス終了日:2020/11/16 20:00',
    file: [
      {
        src: 'https://dummy/release_2_4_0_ja.pdf',
        title: 'SC Pilot Version2.4.0 releaseのお知らせ',
      },
    ],
    updateDate: '2020-11-17T01:00:00.000Z',
  },
  {
    id: '11/3dmg_test_user_a@qualica.co.jp/JA',
    userNoticeId: 11,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-10-20T01:00:00.000Z',
    publicationEndDate: '2020-11-03T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.3.0 releaseのお知らせ',
    content:
      'SC Pilot Version2.3.0 releaseのお知らせ\r\n \r\n 2020/11/02にSC Pilot Version2.3.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/11/02 18:00\r\n メンテナンス終了日:2020/11/02 20:00',
    file: [
      {
        src: 'https://dummy/release_2_3_0_ja.pdf',
        title: 'SC Pilot Version2.3.0 releaseのお知らせ',
      },
    ],
    updateDate: '2020-11-03T01:00:00.000Z',
  },
  {
    id: '12/3dmg_test_user_a@qualica.co.jp/JA',
    userNoticeId: 12,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-10-06T01:00:00.000Z',
    publicationEndDate: '2020-10-20T01:00:00.000Z',
    read: false,
    title: 'SC Pilot Version2.2.0 releaseのお知らせ',
    content:
      'SC Pilot Version2.2.0 releaseのお知らせ\r\n \r\n 2020/10/19にSC Pilot Version2.1.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/10/19 18:00\r\n メンテナンス終了日:2020/10/19 20:00',
    file: [
      {
        src: 'https://dummy/release_2_2_0_ja.pdf',
        title: 'SC Pilot Version2.2.0 releaseのお知らせ',
      },
    ],
    updateDate: '2020-10-20T01:00:00.000Z',
  },
  {
    id: '13/3dmg_test_user_a@qualica.co.jp/JA',
    userNoticeId: 13,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-09-15T01:00:00.000Z',
    publicationEndDate: '2020-10-06T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version2.1.0 releaseのお知らせ',
    content:
      'SC Pilot Version2.1.0 releaseのお知らせ\r\n \r\n 2020/10/05にSC Pilot Version2.1.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/10/05 18:00\r\n メンテナンス終了日:2020/10/05 20:00',
    file: [
      {
        src: 'https://dummy/release_2_1_0_ja.pdf',
        title: 'SC Pilot Version2.1.0 releaseのお知らせ',
      },
    ],
    updateDate: '2020-10-06T01:00:00.000Z',
  },
  {
    id: '14/3dmg_test_user_a@qualica.co.jp/JA',
    userNoticeId: 14,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-09-01T01:00:00.000Z',
    publicationEndDate: '2020-09-15T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version2.0.0 releaseのお知らせ',
    content:
      'SC Pilot Version2.0.0 releaseのお知らせ\r\n \r\n 2020/09/14にSC Pilot Version2.0.0 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/09/14 18:00\r\n メンテナンス終了日:2020/09/14 20:00',
    file: [
      {
        src: 'https://dummy/release_2_0_0_ja.pdf',
        title: 'SC Pilot Version2.0.0 releaseのお知らせ',
      },
    ],
    updateDate: '2020-09-15T01:00:00.000Z',
  },
  {
    id: '15/3dmg_test_user_a@qualica.co.jp/JA',
    userNoticeId: 15,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'JA',
    noticeType: 'Important',
    noticeTypes: ['Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-08-18T01:00:00.000Z',
    publicationEndDate: '2020-09-01T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version1.2.3 releaseのお知らせ',
    content:
      'SC Pilot Version1.2.3 releaseのお知らせ\r\n \r\n 2020/08/29にSC Pilot Version1.2.3 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/08/31 18:00\r\n メンテナンス終了日:2020/08/31 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_3_ja.pdf',
        title: 'SC Pilot Version1.2.3 releaseのお知らせ',
      },
    ],
    updateDate: '2020-09-01T01:00:00.000Z',
  },
  {
    id: '16/3dmg_test_user_a@qualica.co.jp/JA',
    userNoticeId: 16,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'JA',
    noticeType: 'EmergencyImportant',
    noticeTypes: ['Emergency', 'Important'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-07-28T01:00:00.000Z',
    publicationEndDate: '2020-08-18T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version1.2.2 releaseのお知らせ',
    content:
      'SC Pilot Version1.2.2 releaseのお知らせ\r\n \r\n 2020/08/17にSC Pilot Version1.2.2 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/08/17 18:00\r\n メンテナンス終了日:2020/08/17 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_2_ja.pdf',
        title: 'SC Pilot Version1.2.2 releaseのお知らせ',
      },
    ],
    updateDate: '2020-08-18T01:00:00.000Z',
  },
  {
    id: '17/3dmg_test_user_a@qualica.co.jp/JA',
    userNoticeId: 17,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'JA',
    noticeType: 'Emergency',
    noticeTypes: ['Emergency'],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-07-14T01:00:00.000Z',
    publicationEndDate: '2020-07-28T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version1.2.1 releaseのお知らせ',
    content:
      'SC Pilot Version1.2.1 releaseのお知らせ\r\n \r\n 2020/07/27にSC Pilot Version1.2.2 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/07/27 18:00\r\n メンテナンス終了日:2020/07/27 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_1_ja.pdf',
        title: 'SC Pilot Version1.2.1 releaseのお知らせ',
      },
    ],
    updateDate: '2020-07-28T01:00:00.000Z',
  },
  {
    id: '18/3dmg_test_user_a@qualica.co.jp/JA',
    userNoticeId: 18,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'JA',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Maintenance',
    publicationStartDate: '2020-07-14T01:00:00.000Z',
    publicationEndDate: '2020-07-28T01:00:00.000Z',
    read: true,
    title: 'SC Pilot Version1.2.1 releaseのお知らせ',
    content:
      'SC Pilot Version1.2.1 releaseのお知らせ\r\n \r\n 2020/07/27にSC Pilot Version1.2.2 releaseします。\r\n \r\n システム停止日時は以下の通りです。\r\n \r\n メンテナンス開始日時:2020/07/27 18:00\r\n メンテナンス終了日:2020/07/27 20:00',
    file: [
      {
        src: 'https://dummy/release_1_2_1_ja.pdf',
        title: 'SC Pilot Version1.2.1 releaseのお知らせ',
      },
    ],
    updateDate: '2020-07-28T01:00:00.000Z',
  },
  {
    id: '19/3dmg_test_user_a@qualica.co.jp/JA',
    userNoticeId: 19,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'JA',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    read: true,
    title: 'SC Pilotへようこそ 2',
    content:
      'SC Pilotへようこそ\r\n SC pilotはマシンガイダンス機能を後付けしたあと、管理するための機能となっています。必要な操作は操作マニュアルを参照してください',
    file: [
      {
        src: 'https://dummy/welcome_ja.pdf',
        title: 'SC Pilotへようこそ2',
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: '20/3dmg_test_user_a@qualica.co.jp/JA',
    userNoticeId: 20,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'JA',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    read: true,
    title: 'SC Pilotへようこそ 3',
    content:
      'SC Pilotへようこそ\r\n SC pilotはマシンガイダンス機能を後付けしたあと、管理するための機能となっています。必要な操作は操作マニュアルを参照してください',
    file: [
      {
        src: 'https://dummy/welcome_ja.pdf',
        title: 'SC Pilotへようこそ3',
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
  {
    id: '21/3dmg_test_user_a@qualica.co.jp/JA',
    userNoticeId: 21,
    email: '3dmg_test_user_a@qualica.co.jp',
    language: 'JA',
    noticeType: '',
    noticeTypes: [],
    noticeCategory: 'Information',
    publicationStartDate: '2020-04-01T01:00:00.000Z',
    publicationEndDate: '2021-03-31T16:00:00.000Z',
    read: true,
    title: 'SC Pilotへようこそ 4',
    content:
      'SC Pilotへようこそ\r\n SC pilotはマシンガイダンス機能を後付けしたあと、管理するための機能となっています。必要な操作は操作マニュアルを参照してください',
    file: [
      {
        src: 'https://dummy/welcome_ja.pdf',
        title: 'SC Pilotへようこそ4',
      },
    ],
    updateDate: '2021-03-31T16:00:00.000Z',
  },
];

export default userNoticeStatus;
