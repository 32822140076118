import React, { FC, useEffect, useState } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import { useParams } from 'react-router-dom';
import { RetrofitCalibsData, RetrofitData } from '../../../../dataProvider';
import RetrofitFlexCalibEdit from './RetrofitFlexCalibEdit';
import RetrofitCalibPaginationLimit from './RetrofitCalibPaginationLimit';
import RetrofitCalibEdit from './RetrofitCalibEdit';
import {
  useAccount,
  useGetSelefDetailData,
  useGetUserData,
  usePageProps,
} from '../../../../hooks';
import { getRetrofitInfoBreadcrumbs } from './util';
import { useGetSiteName } from './hooks';
import RetrofitFlexCalibShow from './RetrofitFlexCalibShow';
import RetrofitCalibPilotShow from './RetrofitCalibPilotShow';

const RetrofitCalibShow: FC = () => {
  const props = usePageProps('retrofitCalibs');
  const dataProvider = useDataProvider();
  const getSelefDetailData = useGetSelefDetailData();
  const getSiteName = useGetSiteName();
  const {
    siteId,
    retrofitId: strRetrofitId,
    id: calibFileId,
  } = useParams<{
    siteId: string;
    retrofitId: string;
    id: string;
  }>();
  const getAccount = useAccount();
  const getUserData = useGetUserData();

  const retrofitId = Number(strRetrofitId);
  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: {
      detailInfoExists: boolean;
      detailInfo: any;
      machineType?: string;
      breadcrumbs: string[];
      isEdit: boolean;
    };
  }>({ loading: true, fetched: false });

  useEffect(() => {
    if (apiState.fetched) return;

    const api = async () => {
      // アカウント権限
      const {
        data: { email, corporationId },
      } = await getAccount();
      const userData = await getUserData(corporationId, email);
      const { role } = userData[0];
      // スタッフ以外だったら編集可能にする。
      const isEdit = ['super_admin', 'admin', 'manager'].includes(role);

      // キャリブレーション情報
      const { data: calibsData } =
        await dataProvider.getOne<RetrofitCalibsData>('retrofitCalibs', {
          id: calibFileId,
        });
      const detailInfoExists = !!calibsData.detailInfo;
      const machineType = calibsData.detailInfo?.machineInfo?.machineType;

      // レトロフィット情報
      const { data: retrofitInfo } = await dataProvider.getOne<RetrofitData>(
        'retrofits',
        {
          id: Number(retrofitId),
        },
      );

      // 現場に紐づくレトロフィットであるか判定
      const siteAttachedListFileter = {
        siteId: siteId || '',
      };
      const siteAttachedRetrofit = await getSelefDetailData(
        'retrofits',
        siteAttachedListFileter,
        retrofitId,
      );

      // site ※現場建機詳細から呼ばれた場合に、パンくずリストで必要
      let siteName;
      if (siteId) {
        const result = await getSiteName({ siteId });
        siteName = result.data.siteName;
      }

      // DBの取得結果を使うため、パンくずリストはここで作成
      const breadcrumbs = getRetrofitInfoBreadcrumbs(
        siteAttachedRetrofit?.machineInfoMachineName
          ? siteAttachedRetrofit?.machineInfoMachineName
          : retrofitInfo?.machineInfoMachineName || '',
        siteName,
      );
      breadcrumbs.push('resources.retrofitCalibs.name');

      return {
        detailInfoExists,
        detailInfo: calibsData.detailInfo,
        machineType,
        breadcrumbs,
        isEdit,
      };
    };
    api().then(data => {
      setApiState({
        fetched: true,
        loading: false,
        data,
      });
    });
  }, [
    apiState.fetched,
    calibFileId,
    dataProvider,
    getSelefDetailData,
    getAccount,
    getUserData,
    getSiteName,
    retrofitId,
    siteId,
  ]);

  if (apiState.loading || !apiState.data) return <Loading />;
  const { detailInfoExists, detailInfo, machineType, breadcrumbs, isEdit } =
    apiState.data;
  const machineTypeLowerCase = machineType
    ? machineType.toLowerCase()
    : undefined;
  const isFlex =
    machineTypeLowerCase === 'roadroller' ||
    machineTypeLowerCase === 'dozer' ||
    machineTypeLowerCase === 'rigiddump' ||
    machineTypeLowerCase === 'wheelloader' ||
    machineTypeLowerCase === 'grader' ||
    machineTypeLowerCase === 'automobile';

  if (!detailInfoExists) {
    return (
      <RetrofitCalibPaginationLimit props={props} breadcrumbs={breadcrumbs} />
    );
  }
  // Flex
  if (isFlex) {
    return isEdit ? (
      <RetrofitFlexCalibEdit props={props} breadcrumbs={breadcrumbs} />
    ) : (
      <RetrofitFlexCalibShow props={props} breadcrumbs={breadcrumbs} />
    );
  }
  // Pilot
  return isEdit ? (
    <RetrofitCalibEdit
      props={props}
      detailInfo={detailInfo}
      breadcrumbs={breadcrumbs}
    />
  ) : (
    <RetrofitCalibPilotShow
      props={props}
      detailInfo={detailInfo}
      breadcrumbs={breadcrumbs}
    />
  );
};

RetrofitCalibShow.displayName = 'RetrofitCalibShow';
export default RetrofitCalibShow;
