import { useState } from 'react';
import { AlertMapController, getAlertMapController } from '../map';

interface OutParams {
  mapController: AlertMapController;
}

const useAlertMapController = (): OutParams => {
  const [mapController] = useState<AlertMapController>(getAlertMapController);

  return {
    mapController,
  };
};

export default useAlertMapController;
