import React from 'react';
import { required, Loading, useTranslate } from 'react-admin';
import {
  Create,
  CustomForm,
  BreadcrumbsFiled,
  DateTimePickerInput,
  SelectInput,
} from '../../..';
import { useResource, useCreateInSite, useGetAccount } from '../../../../hooks';
import { AsbuiltData } from '../../../../dataProvider';
import { config } from '../../../../utils';

const SiteAsbuiltCreate: React.FC = () => {
  const { props, site } = useCreateInSite('asbuilts');
  const resource = useResource('asbuilts');
  const translate = useTranslate();
  //  getEmailは、fakedataの場合のみ参照
  const { getEmail } = useGetAccount();

  if (!site) return <Loading />;
  const { id: siteId, corporationId, name: siteName } = site;

  // validation 処理
  const msgStartPeriod = translate('resources.asbuilts.fields.startPeriod');
  const msgEndPeriod = translate('resources.asbuilts.fields.endPeriod');

  // 日付判定用funciton
  function nomalFromToDate(start: string, end: string): boolean {
    return new Date(start) <= new Date(end);
  }

  const startPeriodValidation = (unusedValue: any, allValues: AsbuiltData) => {
    const fromDate = allValues.startPeriod;
    const toDate = allValues.endPeriod;

    if (!fromDate || !toDate) {
      return null;
    }
    if (nomalFromToDate(fromDate, toDate)) {
      return null;
    }
    return {
      message: 'admin.validation.validBeforeDate',
      args: {
        target: msgStartPeriod,
        before: msgEndPeriod,
      },
    };
  };

  const endPeriodValidation = (unusedValue: any, allValues: AsbuiltData) => {
    const fromDate = allValues.startPeriod;
    const toDate = allValues.endPeriod;

    if (!fromDate || !toDate) {
      return null;
    }
    if (nomalFromToDate(fromDate, toDate)) {
      return null;
    }
    return {
      message: 'admin.validation.validAfterDate',
      args: {
        target: msgEndPeriod,
        after: msgStartPeriod,
      },
    };
  };

  // fakedataの場合は以下のCreateUserが必要
  const createUser = config.api.landlog.useFake ? getEmail() : undefined;

  return (
    <Create {...props}>
      <CustomForm
        title="admin.pages.asbuiltsCreate"
        resource="asbuilts"
        initialValues={{ siteId, corporationId, createUser }}
      >
        <BreadcrumbsFiled
          breadcrumbs={[
            'resources.sites.name',
            '',
            siteName,
            'resources.asbuilts.name',
          ]}
          label="ra.action.create"
        />

        <DateTimePickerInput
          resource={resource}
          source="startPeriod"
          validate={[
            required('admin.validation.invalidDateTimeFormat'),
            startPeriodValidation,
          ]}
        />
        <DateTimePickerInput
          resource={resource}
          source="endPeriod"
          validate={[
            required('admin.validation.invalidDateTimeFormat'),
            endPeriodValidation,
          ]}
        />
        <SelectInput
          resource={resource}
          source="csvType"
          optionText="name"
          choices={[
            { id: 'NEZ', name: 'NEZ' },
            { id: 'ENZ', name: 'ENZ' },
          ]}
          validate={required()}
        />
      </CustomForm>
    </Create>
  );
};

SiteAsbuiltCreate.displayName = 'SiteAsbuiltCreate';
export default SiteAsbuiltCreate;
