import { AdminApiConverter, ApiFilter } from '..';
import { TermsConditionDocumentData } from '../../types';

const converter: AdminApiConverter<
  'termsConditionDocuments',
  'termsConditionDocuments'
> = {
  resource: 'termsConditionDocuments',
  defaultSort: { field: 'id', order: 'ASC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'termsConditionDocuments'> = {};
    if ('termsConditionId' in filter)
      apiFilter.termsConditionId = filter.termsConditionId;
    if ('language' in filter) apiFilter.language = filter.language;
    return apiFilter;
  },
  sortFieldToApi: field => field,
  recordsFromApi: records =>
    Promise.resolve(
      records.map((record): TermsConditionDocumentData => {
        if (!record.id) throw new Error("Can't get id from API");
        return {
          id: record.id,
          termsConditionId: record.termsConditionId,
          title: record.title,
          language: record.language,
          referenceOrder: record.referenceOrder,
          termsFile: {
            id: record.file?.id || '',
            src: record.file?.url || '',
            title: record.file?.name || '',
          },
        };
      }),
    ),
  recordToApi: record => ({
    termsConditionId: record.termsConditionId,
    language: record.language,
    title: record.title,
    referenceOrder: record.referenceOrder,
    fileId: '',
  }),
  embed: ['file'],
};
export default converter;
