import React from 'react';
import { GnssSettingAdminCreate, GnssSettingOwnerCreate, Loading } from '../..';
import { useGetUserRole, usePageProps } from '../../../hooks';

const GnssSettingCreate: React.FC = () => {
  const props = usePageProps('gnssSettings');
  const { basePath } = props;
  const userRole = useGetUserRole();
  if (userRole.isLoading()) {
    return <Loading />;
  }
  return userRole.isAdmin() ? (
    <GnssSettingAdminCreate basePath={basePath} />
  ) : (
    <GnssSettingOwnerCreate basePath={basePath} />
  );
};

GnssSettingCreate.displayName = 'GnssSettingCreate';
export default GnssSettingCreate;
