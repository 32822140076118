import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  DateField,
  TextField as RaTextField,
  useDataProvider,
} from 'react-admin';
import {
  CustomList,
  Datagrid,
  FunctionField,
  ListDownloadButton,
  Loading,
} from '../../..';
import {
  useAccount,
  useGetSelefDetailData,
  useResource,
} from '../../../../hooks';
import { RetrofitShareFileData } from '../../../../dataProvider';
import { useGetSiteName } from './hooks';
import { getRetrofitInfoBreadcrumbs } from './util';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '14px',
            paddingLeft: '16px',
            width: 200,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '0px',
            paddingLeft: '16px',
            width: 585,
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '8px',
            paddingLeft: '8px',
            width: 110,
          },
        },
      },
    },
  }),
);

interface Props {
  retrofitId: string;
}

interface ApiResult {
  fetchedRetrofitId: number;
  breadcrumbs: string[];
}

const RetrofitShareFileList: FC<Partial<Props>> = props => {
  const resourceShareFile = useResource('retrofitShareFiles');
  const classes = useStyles();
  const { retrofitId: strRetrofitId, siteId } =
    useParams<{ retrofitId: string; siteId: string }>();
  const retrofitId = Number(strRetrofitId);
  const getAccount = useAccount();
  const getSelefDetailData = useGetSelefDetailData();
  const getSiteName = useGetSiteName();
  const dataProvider = useDataProvider();
  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: ApiResult;
  }>({
    loading: true,
    fetched: false,
    data: undefined,
  });

  useEffect(() => {
    // 既にDB取得済みでも、urlのretrofitIdが変わった場合は再度実施
    if (apiState.fetched && apiState.data?.fetchedRetrofitId === retrofitId) {
      return;
    }

    const api = async (): Promise<{
      data: {
        apiResult: ApiResult;
      };
    }> => {
      const { data: retrofit } = await dataProvider.getOne('retrofits', {
        id: retrofitId,
      });

      // 現場に紐づくレトロフィットであるか判定
      const siteAttachedListFileter = {
        siteId: siteId || '',
      };
      const siteAttachedRetrofit = await getSelefDetailData(
        'retrofits',
        siteAttachedListFileter,
        retrofitId,
      );

      // site ※現場建機詳細から呼ばれた場合に、パンくずリストで必要
      let siteName;
      if (siteId) {
        const result = await getSiteName({ siteId });
        siteName = result.data.siteName;
      }

      // DBの取得結果を使うため、パンくずリストはここで作成
      const breadcrumbs = getRetrofitInfoBreadcrumbs(
        siteAttachedRetrofit?.machineInfoMachineName
          ? siteAttachedRetrofit?.machineInfoMachineName
          : retrofit?.machineInfoMachineName || '',
        siteName,
      );

      return {
        data: {
          apiResult: {
            fetchedRetrofitId: retrofitId,
            breadcrumbs,
          },
        },
      };
    };
    api().then(({ data: { apiResult } }) => {
      setApiState({ loading: false, fetched: true, data: apiResult });
    });
  }, [
    getSelefDetailData,
    getAccount,
    getSiteName,
    dataProvider,
    retrofitId,
    siteId,
    apiState,
  ]);

  if (apiState.loading || !apiState.data) return <Loading />;
  // TODO: 閲覧不可なデータは現場一覧に遷移する機能を有効にする場合、コメントアウトを外す
  const { breadcrumbs } = apiState.data;

  return (
    <CustomList
      breadcrumbs={breadcrumbs}
      resource={resourceShareFile}
      title="admin.pages.retrofitShareFileList"
      bulkActionButtons={false}
      filter={{ retrofitId }}
      sort={{ field: 'shareFile.fileTime', order: 'DESC' }}
    >
      <Datagrid className={classes.root}>
        <RaTextField
          resource={resourceShareFile}
          source="shareFile.name"
          label="resources.retrofitShareFiles.fields.name"
        />
        <DateField
          resource={resourceShareFile}
          source="shareFile.fileTime"
          label="resources.retrofitShareFiles.fields.fileTime"
          showTime
        />
        <FunctionField
          {...props}
          resource={resourceShareFile}
          source="shareFile"
          sortable={false}
          label=""
          render={RetrofitShareFileRecord => {
            const data = RetrofitShareFileRecord as RetrofitShareFileData;
            const url = data.shareFile?.url || '';
            const label = 'resources.retrofitShareFiles.fields.url';
            return <ListDownloadButton label={label} url={url} />;
          }}
        />
      </Datagrid>
    </CustomList>
  );
};

RetrofitShareFileList.displayName = 'RetrofitShareFileList';
export default RetrofitShareFileList;
