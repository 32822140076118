import { useCallback } from 'react';
import { GeofenceData } from '../../../../dataProvider';
import { LonLat } from '../../../../utils';
import useLocalGeofenceCoordinateToLonLat from './useLocalGeofenceCoordinateToLonLat';

interface InParams {
  points: number[][] | GeofenceData[];
}

interface OutParams {
  data: {
    lonLat: LonLat[];
    lonLatId: {
      id: number;
      coordinates: LonLat[];
    }[];
  };
}

const useConvertMapCoordinate = () => {
  const localCoordinateToLonLat = useLocalGeofenceCoordinateToLonLat();
  return useCallback(
    async ({ points }: InParams): Promise<OutParams> => {
      if (points.length <= 0) return { data: { lonLat: [], lonLatId: [] } };
      let coordinate: {
        id?: number;
        e: number;
        n: number;
      }[] = [];
      if (isGeofenceData(points)) {
        coordinate = (points as GeofenceData[])
          .map(({ id, points: geoPoints }) =>
            geoPoints.map(([e, n]) => ({
              id,
              e,
              n,
            })),
          )
          .flat();
      } else {
        coordinate = (points as number[][]).map(([e, n]) => ({
          e,
          n,
        }));
      }

      const { data } = await localCoordinateToLonLat({
        coordinate,
      });

      const lonLatId = data.map(({ id, longitude: lon, latitude: lat }) => ({
        id: id || -1,
        lon,
        lat,
      }));

      const lonLatIdObject = lonLatId.reduce((prev: any, { id, lon, lat }) => {
        const array = prev[id] || [];
        array.push({ lon, lat });
        return { ...prev, [id]: array };
      }, {});

      return {
        data: {
          lonLat: lonLatId.map(({ lon, lat }) => ({ lon, lat })),
          lonLatId: Object.entries(lonLatIdObject).map(([id, lonLat]) => ({
            id: Number(id),
            coordinates: lonLat,
          })) as { id: number; coordinates: LonLat[] }[],
        },
      };
    },
    [localCoordinateToLonLat],
  );
};

const isGeofenceData = (arg: unknown[]): boolean => {
  if (arg.length <= 0) return false;
  const [head] = arg;
  return (
    typeof head === 'object' &&
    head !== null &&
    typeof (head as GeofenceData).id === 'number' &&
    typeof (head as GeofenceData).name === 'string' &&
    Object.prototype.toString.call((head as GeofenceData).points) ===
      '[object Array]'
  );
};

export default useConvertMapCoordinate;
