import React, { ChangeEvent } from 'react';
import { omit } from 'lodash';
import {
  NumberInput as RaNumberInput,
  FieldProps,
  InjectedFieldProps,
} from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // 数値Inputのスピンボタン（+,-）を非表示に設定
      '& input': {
        textAlign: 'right',
        // Firefox
        '-moz-appearance': 'textfield',
        // chrome,safari,Edge
        '&::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
        },
        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
        },
      },
    },
  }),
);

interface Props {
  validate?: any | any[];
  disabled?: boolean;
  form?: any;
  limitDecimal?: number;
}

const CustomNumberInput: React.FC<Props & FieldProps & InjectedFieldProps> = ({
  form,
  limitDecimal,
  ...rest
}) => {
  const classes = useStyles();
  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    if (!limitDecimal) return;
    const decimalLen = (value.split('.')[1] || '').length;
    if (decimalLen > limitDecimal) {
      const { source } = rest;
      form.change(
        source,
        value.substring(0, value.indexOf('.') + limitDecimal + 1),
      );
    }
  };
  return (
    <RaNumberInput
      className={classes.root}
      {...omit(rest, 'className')}
      onChange={handleChange}
    />
  );
};

CustomNumberInput.displayName = 'CustomNumberInput';
export default CustomNumberInput;
