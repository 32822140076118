const KEY = 'login_email';

const loginEmailStore = {
  get: () => {
    return localStorage.getItem(KEY) || '';
  },
  set: (email: string) => {
    localStorage.setItem(KEY, email);
  },
};

export default loginEmailStore;
