import { useLocation } from 'react-router-dom';

const useCustomLocation = (uri: string) => {
  const location = useLocation();
  const { pathname } = location;
  const lastPathIndex = pathname.lastIndexOf(uri) + uri.length;
  const lastPath = location.pathname.slice(lastPathIndex, pathname.length);
  return {
    getBasePath(): string {
      return location.pathname.slice(0, lastPathIndex);
    },
    getValue(): string {
      return lastPath.replace('/', '');
    },
    getNumberValue(): number {
      const value = lastPath.replace('/', '');
      return Number(value) || 0;
    },
  };
};

export default useCustomLocation;
