import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { MapTransparentButton } from '..';
import { mapStyle } from '../../theme';
import { MapCoordinate, MapCursor, MapPen } from '../../assets/images';

const useCurrentStyle = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#000344',
    },
  }),
);

const useStyle = makeStyles(() =>
  createStyles({
    buttonWrap: {
      ...mapStyle.buttonWrapHorizontal,
    },
  }),
);

export type CurrentState = 'draw' | 'move';

const MapOperationButton: React.FC<{
  onClickInput?: () => void;
  onClickDraw: () => void;
  onClickSelect: () => void;
  showInput?: boolean;
  defaultState?: CurrentState;
}> = ({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClickInput = () => {},
  onClickDraw,
  onClickSelect,
  showInput = true,
  defaultState = 'draw',
}) => {
  const currentStyle = useCurrentStyle();
  const classes = useStyle();
  const [current, setCurrent] = useState<CurrentState>(defaultState);
  return (
    <div className={classes.buttonWrap}>
      {showInput && (
        <MapTransparentButton onClick={onClickInput}>
          <MapCoordinate />
        </MapTransparentButton>
      )}
      <MapTransparentButton
        onClick={() => {
          setCurrent('draw');
          onClickDraw();
        }}
        classes={current === 'draw' ? currentStyle : undefined}
      >
        <MapPen />
      </MapTransparentButton>
      <MapTransparentButton
        onClick={() => {
          setCurrent('move');
          onClickSelect();
        }}
        classes={current === 'move' ? currentStyle : undefined}
      >
        <MapCursor />
      </MapTransparentButton>
    </div>
  );
};

MapOperationButton.displayName = 'MapOperationButton';
export default MapOperationButton;
