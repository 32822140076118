import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Resources } from '../dataProvider';
import { AnyObject } from '../utils';
import { ResourceName } from '../components';
import { useCustomGetOne } from '.';

/** Site 以外で GetOne を使用できる */
const useEdit = (
  resource: ResourceName,
  one: { resource: ResourceName; key: string },
) => {
  const location = useLocation();
  const { pathname } = location;
  const lastPathIndex = pathname.lastIndexOf('/');
  const basePath = location.pathname.slice(0, lastPathIndex);
  const params = useParams<AnyObject>();
  const props = { location, basePath, resource, id: params?.id };

  const [data, setData] = useState<Resources[ResourceName]>();
  const { data: record } = useCustomGetOne(one.resource, params[one.key]);
  useEffect(() => {
    if (!record) return;
    setData(record as Resources[ResourceName]);
  }, [record]);

  return { props, data };
};

export default useEdit;
