import { AdminApiConverter, ApiFilter, Language, UserNoticeFile } from '..';
import {
  FileField,
  noticeType,
  noticeTypes,
  UserNoticeStatusData,
} from '../../types';

const converter: AdminApiConverter<'userNoticeStatus', 'userNoticeStatus'> = {
  resource: 'userNoticeStatus',
  defaultSort: { field: 'publicationStartDate', order: 'DESC' },
  embed: ['files'],
  filterToApi: filter => {
    let convetedLanguage: Language = 'EN';
    // ブラウザの設定言語がLanguage型に定義されている言語のみ変換可能
    if (
      filter.language === 'EN' ||
      filter.language === 'JA' ||
      filter.language === 'FR' ||
      filter.language === 'DE' ||
      filter.language === 'ES'
    ) {
      convetedLanguage = filter.language;
    }
    const apiFilter: ApiFilter<'userNoticeStatus'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('userNoticeId' in filter) apiFilter.userNoticeId = filter.userNoticeId;
    if ('email' in filter) apiFilter.email = filter.email;
    if ('language' in filter) apiFilter.language = convetedLanguage;
    if ('noticeCategory' in filter)
      apiFilter.noticeCategory = filter.noticeCategory;
    if ('publicationStartDate' in filter)
      apiFilter.publicationStartDate = filter.publicationStartDate;
    if ('publicationEndDate' in filter)
      apiFilter.publicationEndDate = filter.publicationEndDate;
    if ('noticeTypes' in filter) {
      const isEmergency = !!(
        filter.noticeType === 'EmergencyImportant' || 'Emergency'
      );
      apiFilter.emergency = isEmergency;
      const isImportant = !!(
        filter.noticeType === 'EmergencyImportant' || 'Important'
      );
      apiFilter.important = isImportant;
    }
    if ('read' in filter) apiFilter.read = filter.read;
    if ('title' in filter) apiFilter.title = filter.title;
    if ('content' in filter) apiFilter.content = filter.content;
    return apiFilter;
  },
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map((record): UserNoticeStatusData => {
        /*
          type noticeType = 'EmergencyImportant' | 'Emergency' | 'Important' | '';
          type noticeTypes = 'Emergency' | 'Important';
          */
        let settingNoticeType: noticeType = '';
        const settingNoticeTypes: noticeTypes[] = [];
        if (record.emergency) {
          settingNoticeType = 'Emergency';
          settingNoticeTypes.push('Emergency');
        }
        if (record.important) {
          settingNoticeType = 'Important';
          settingNoticeTypes.push('Important');
        }
        if (record.emergency && record.important)
          settingNoticeType = 'EmergencyImportant';

        return {
          id: record.id || '',
          userNoticeId: record.userNoticeId,
          email: record.email || '',
          language: record.language,
          noticeType: settingNoticeType,
          noticeTypes: settingNoticeTypes,
          noticeCategory: record.noticeCategory,
          publicationStartDate: record.publicationStartDate,
          publicationEndDate: record.publicationEndDate,
          read: record.read,
          title: record.title || '',
          content: record.content || '',
          file: record.files
            ? record.files.map((f: UserNoticeFile) => {
                return {
                  id: f.id,
                  src: f.url,
                  title: f.name,
                } as FileField;
              })
            : undefined,
          updateDate: record.updateDate,
        };
      }),
    ),
  recordToApi: record => {
    const { noticeTypes: recordNoticeTypes } = record;
    const isEmergency = recordNoticeTypes
      ? recordNoticeTypes.indexOf('Emergency') > -1
      : false;
    const isImportant = recordNoticeTypes
      ? recordNoticeTypes.indexOf('Important') > -1
      : false;

    return {
      userNoticeId: record.userNoticeId,
      email: record.email,
      language: record.language,
      noticeCategory: record.noticeCategory,
      emergency: isEmergency,
      important: isImportant,
      publicationStartDate: record.publicationStartDate,
      publicationEndDate: record.publicationEndDate,
      read: record.read,
      title: record.title,
      content: record.content,
      fileIds: record.file
        ? (record.file?.map(f => f.id) as string[])
        : undefined,
      files: record.file
        ? record.file.map(s => {
            return {
              id: s.id,
              url: s.src,
              name: s.title,
            } as UserNoticeFile;
          })
        : undefined,
    };
  },
};

export default converter;
