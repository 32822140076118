import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button } from '../../..';
import { mapStyle } from '../../../../theme';
import { MapDelete } from '../../../../assets/images';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      ...mapStyle.buttonDelete,
    },
  }),
);

const MapDeleteButton: React.FC<{
  onClick?: () => void;
}> = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Button onClick={onClick} className={classes.root}>
      <MapDelete />
    </Button>
  );
};

MapDeleteButton.displayName = 'MapDeleteButton';
export default MapDeleteButton;
