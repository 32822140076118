import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { TermsConditionDocumentData } from '../../../../dataProvider';

interface InParams {
  termsConditionId: number;
  language: string;
}

interface OutParams {
  data: TermsConditionDocumentData[];
}

const useGetTermsConditions = () => {
  const dataProvider = useDataProvider();
  return useCallback(
    async ({ termsConditionId, language }: InParams): Promise<OutParams> => {
      const { data } = await dataProvider.getList<TermsConditionDocumentData>(
        'termsConditionDocuments',
        {
          pagination: {
            page: 1,
            perPage: 100,
          },
          sort: {
            field: 'referenceOrder',
            order: 'ASC',
          },
          filter: {
            termsConditionId,
            language,
          },
        },
      );
      return { data };
    },
    [dataProvider],
  );
};

export default useGetTermsConditions;
