import { Feature } from 'ol';
import { MapProps } from '../../../../../hooks';
import {
  createPolygonInteraction,
  createCircleInteraction,
  createLineInteraction,
  createPointInteraction,
  createModifyInteractionByFeatures,
  createSelectionInteraction,
  createTranslationInteraction,
} from '../../../../../utils';
import { Interactions } from './types';

const setupInteraction = (
  { sourceVector, vectorLayer }: MapProps,
  featureId: number,
  events: {
    onDrawEnd: () => void;
    onModifyEnd: () => void;
    onTranslateEnd: () => void;
    onSelectEnd: (features: Feature[]) => void;
  },
): Interactions => {
  const polygon = createPolygonInteraction(
    sourceVector,
    featureId,
    events.onDrawEnd,
  );
  const circle = createCircleInteraction(
    sourceVector,
    featureId,
    events.onDrawEnd,
  );
  const line = createLineInteraction(sourceVector, featureId, events.onDrawEnd);
  const point = createPointInteraction(
    sourceVector,
    featureId,
    events.onDrawEnd,
  );
  const select = createSelectionInteraction(vectorLayer, (_, features) => {
    // 今回複数選択をサポートしない為、0 要素のIndexを参照する
    // 一覧のfeatureIdは -1 が設定されているため、clear()処理から除外
    if (
      features.length <= 0 ||
      (featureId !== -1 && features[0].getId() !== featureId)
    ) {
      select.getFeatures().clear(); // 編集対象の featureId 以外の feature は選択状態を即解除する
      return;
    }
    events.onSelectEnd(features);
  });
  const polygonModify = createModifyInteractionByFeatures(
    select.getFeatures(),
    events.onModifyEnd,
  );
  const circleModify = createModifyInteractionByFeatures(
    select.getFeatures(),
    events.onModifyEnd,
  );
  const lineModify = createModifyInteractionByFeatures(
    select.getFeatures(),
    events.onModifyEnd,
    true,
  );
  const pointModify = createModifyInteractionByFeatures(
    select.getFeatures(),
    events.onModifyEnd,
  );
  const translation = createTranslationInteraction(
    select,
    events.onTranslateEnd,
  );
  return {
    polygon,
    circle,
    line,
    point,
    polygonModify,
    circleModify,
    lineModify,
    pointModify,
    select,
    translation,
  };
};

export default setupInteraction;
