import React from 'react';
import { GeofenceAlertType } from '../../../../../dataProvider';
import { MapProps, crearteOverlayControl } from '../../../../../hooks';
import { AlertTypeEditButton } from '../view';

const addAlertTypeControl = (
  { map }: MapProps,
  onClickNotice: () => void,
  onClickAttention: () => void,
  onClickCaution: () => void,
  onClickWarning: () => void,
  onClickDanger: () => void,
  selectedAlertType?: GeofenceAlertType,
) => {
  map.addControl(
    crearteOverlayControl(
      'map_alertType',
      <AlertTypeEditButton
        onClickNotice={onClickNotice}
        onClickAttention={onClickAttention}
        onClickCaution={onClickCaution}
        onClickWarning={onClickWarning}
        onClickDanger={onClickDanger}
        selectedAlertType={selectedAlertType}
      />,
      {
        right: 198,
        top: 350,
      },
    ),
  );
};

export default addAlertTypeControl;
