import React, {
  FC,
  useState,
  useCallback,
  ChangeEvent,
  useEffect,
} from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { colors } from '../../../../theme';
import { getCsvDelimiter, getDecimalPoint } from '../../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: '#31323A',
      color: colors.text.primaryLight, // テキスト表示するので必要
      fontSize: 14, // テキスト表示するので必要
      textTransform: 'none', // テキスト表示するので必要
      '& > select': {
        backgroundColer: '#31323A',
        width: '100%',
        height: '100%',
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        appearance: 'none',
        borderRadius: 0,
        outline: 'none',
        scrollbarColor: '#717284 transparent',
        scrollbarWidth: 'thin',
        overflowX: 'scroll',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: 8,
          background: 'transparent',
        },
        '&::-webkit-scrollbar:horizontal': {
          height: 8,
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 4,
          background: '#717284',
        },
        '&::-webkit-scrollbar-corner': {
          background: 'transparent',
        },
      },
      '& > select:-ms-expand': {
        display: 'none',
      },
      '& > select:focus': {
        outline: 'none',
      },
    },
    select: {
      background: '#31323A',
      color: colors.text.primaryLight, // テキスト表示するので必要
      fontSize: 14, // テキスト表示するので必要
      textTransform: 'none', // テキスト表示するので必要
      marginBottom: 10,
    },
  }),
);

export interface PopupData {
  id: number;
  localCoordinates: number[][];
}

interface Props {
  width: number;
  height: number;
  maxHeight: number;
  data?: PopupData;
  onChange: (data: { index: number; coordinate: number[] }) => void;
}

const GeofenceMapCoordinateList: FC<Props> = ({
  width,
  height,
  maxHeight,
  data,
  onChange,
}) => {
  const classes = useStyles();
  const [selectValue, setSelectValue] = useState<number | undefined>();
  const [listCoordinates, setListCoordinates] = useState<
    string[][] | undefined
  >();
  const [beforeDepsData, setBeforeDepsData] = useState('{}');
  const nbsp = String.fromCharCode(160); // &nbsp
  const delimiter = getCsvDelimiter(); // 区切り文字表記をブラウザの言語設定に対応

  const depsData = data ? JSON.stringify(data) : '{}';
  const handleChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
      const selectedId = Number(value);
      setSelectValue(selectedId);
      const rawData: PopupData = JSON.parse(depsData);
      onChange({
        index: selectedId,
        coordinate: rawData.localCoordinates[selectedId],
      });
    },
    [depsData, onChange],
  );
  useEffect(() => {
    // 座標に変更がない場合は実施しない
    if (depsData === beforeDepsData) return;
    // 数字の文字列化と最大桁数で1週
    let eIntMaxLength = -1;
    let nIntMaxLength = -1;

    const rawData: PopupData = JSON.parse(depsData);
    const prepareCoordinates = rawData?.localCoordinates?.map(
      localcoodinate => {
        const numE = localcoodinate[0];
        const numN = localcoodinate[1];
        const strE = numE.toString();
        const strN = numN.toString();
        const tmpE = strE.split('.');
        const tmpN = strN.split('.');
        // 整数値のみ取得
        const strIntegerE = tmpE[0];
        const strIntegerN = tmpN[0];
        if (strIntegerE.length > eIntMaxLength)
          eIntMaxLength = strIntegerE.length;
        if (strIntegerN.length > nIntMaxLength)
          nIntMaxLength = strIntegerN.length;
        // 少数値のみ取得
        const strDecimalE = tmpE.length === 2 ? tmpE[1] : '0';
        const strDecimalN = tmpN.length === 2 ? tmpN[1] : '0';
        // 以下の形式で返却
        // [E整数値、E小数値、N整数値、N小数値]
        return [strIntegerE, strDecimalE, strIntegerN, strDecimalN];
      },
    );

    // 0付与でもう1週
    const viewCoordinates = prepareCoordinates?.map(coordinates => {
      // 整数値文字列は半角スペース(nbsp)埋め
      // padStartの第2引数で指定できるのは1文字だけなため、一旦「_」で桁埋め
      const tmpViewIntegerE = coordinates[0].padStart(eIntMaxLength, '_');
      const tmpViewIntegerN = coordinates[2].padStart(nIntMaxLength, '_');
      // nbsp1つだけだと横幅が足りないため２つ付与するよう文字列を置換
      // 「-」の記号の横幅が小さいため、nbspを付与することで位置を調整する
      const viewIntegerE = tmpViewIntegerE
        .replaceAll('_', nbsp + nbsp)
        .replace('-', `${nbsp}-`);
      const viewIntegerN = tmpViewIntegerN
        .replaceAll('_', nbsp + nbsp)
        .replace('-', `${nbsp}-`);

      // 小数値文字列は0埋め
      const viewDecimalE = coordinates[1].padEnd(3, '0');
      const viewDecimalN = coordinates[3].padEnd(3, '0');
      // 小数点表記をブラウザの言語設定に対応
      const decimalPoint = getDecimalPoint();
      return [
        `${viewIntegerE}${decimalPoint}${viewDecimalE}`,
        `${viewIntegerN}${decimalPoint}${viewDecimalN}`,
      ];
    });
    setBeforeDepsData(depsData);
    setListCoordinates(viewCoordinates);
  }, [nbsp, depsData, beforeDepsData]);

  return (
    <div style={{ width, height, maxHeight }} className={classes.root}>
      <select
        className={classes.select}
        value={selectValue}
        size={data?.localCoordinates?.length}
        name="coordinates"
        data-testid="coordinates"
        onChange={handleChange}
      >
        {listCoordinates?.map((obj, index) => (
          <option value={index} key={index}>
            {obj[1]}
            {nbsp + nbsp}
            {`${delimiter}\t`}
            {obj[0]}
          </option>
        ))}
      </select>
    </div>
  );
};

GeofenceMapCoordinateList.displayName = 'GeofenceMapCoordinateList';
export default GeofenceMapCoordinateList;
