import config from './config';

const getBrowserLanguage = (): string => {
  return window.navigator.languages[0].slice(0, 2);
};

const getExtension = (fileNameWithExt: string): string => {
  return fileNameWithExt.substring(
    fileNameWithExt.lastIndexOf('.'),
    fileNameWithExt.length,
  );
};

const getFileName = (fileNameWithExt: string): string => {
  return fileNameWithExt.substring(0, fileNameWithExt.lastIndexOf('.'));
};

const getManualFilePath = (): string => {
  const suportedLanguages = ['en', 'ja'];
  const defaultLanguage = 'en';
  const browserLanguage = getBrowserLanguage();
  const { manualDefault } = config.url;
  const ext = getExtension(manualDefault);
  const fileName = getFileName(manualDefault);

  const currentLanguage = suportedLanguages.includes(browserLanguage)
    ? browserLanguage
    : defaultLanguage;

  const defaultPath = `${fileName}${currentLanguage}${ext}`;

  const { manuals } = config.url as any;
  return manuals[`${currentLanguage}`] || defaultPath;
};

export default getManualFilePath;
