import { AdminApiConverter, ApiFilter } from '..';
import { GnssSettingData } from '../../types';

const converter: AdminApiConverter<'gnssSettings', 'gnssSettings'> = {
  resource: 'gnssSettings',
  defaultSort: { field: 'updateDate', order: 'DESC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'gnssSettings'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('ownerCorporationId' in filter)
      apiFilter.ownerCorporationId = filter.ownerCorporationId;
    if ('ownerCorporationName' in filter)
      apiFilter.ownerCorporationName = filter.ownerCorporationName;
    if ('registCorporationId' in filter)
      apiFilter.registCorporationId = filter.registCorporationId;
    if ('registCorporationName' in filter)
      apiFilter.registCorporationName = filter.registCorporationName;
    if ('name' in filter) apiFilter.name = filter.name;
    if ('url' in filter) apiFilter.url = filter.url;
    if ('portnum' in filter) apiFilter.portnum = filter.portnum;
    if ('userId' in filter) apiFilter.userId = filter.userId;
    if ('password' in filter) apiFilter.password = filter.password;
    if ('retrofitId' in filter) apiFilter.retrofitId = filter.retrofitId;
    if ('status' in filter) apiFilter.status = filter.status;

    return apiFilter;
  },
  sortFieldToApi: field => {
    if (field === 'status') throw new Error('Unsupported sort field: status');
    if (field === 'ntripInfo') return 'name';
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(
        (record): GnssSettingData => ({
          id: record.id || '',
          ownerCorporationId: record.ownerCorporationId,
          ownerCorporationName: record.ownerCorporationName,
          registCorporationId: record.registCorporationId,
          registCorporationName: record.registCorporationName,
          name: record.name,
          url: record.url,
          portnum: record.portnum,
          userId: record.userId,
          password: record.password,
          retrofitId:
            record.retrofitId === null ? undefined : record.retrofitId,
          status: record.status,
          ntripInfo: `${record.name}/${record.userId}`,
          updateDate: record.updateDate,
        }),
      ),
    ),
  recordToApi: record => ({
    ownerCorporationId: record.ownerCorporationId,
    ownerCorporationName: record.ownerCorporationName,
    registCorporationId: record.registCorporationId,
    registCorporationName: record.registCorporationName,
    name: record.name,
    url: record.url,
    portnum: record.portnum,
    userId: record.userId,
    password: record.password,
    retrofitId: record.retrofitId,
  }),
};

export default converter;
