import React from 'react';
import {
  Typography,
  Divider,
  createStyles,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    normal: {
      fontSize: 18,
      marginBottom: 16,
    },
  }),
);

const CustomTitle: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography
        className={classes.normal}
        variant="h6"
        color="inherit"
        id="admin-breadcrumbs"
      />
      <Divider />
    </div>
  );
};

export default CustomTitle;
