import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Button } from '../../..';
import { mapStyle } from '../../../../theme';

const useStyle = makeStyles(() =>
  createStyles({
    root: {
      ...mapStyle.button,
    },
  }),
);

const MapTransparentButton: React.FC<{
  classes?: object;
  children: JSX.Element;
  onClick: () => void;
  disabled?: boolean;
}> = ({ classes: classOverride, onClick, children, disabled = false }) => {
  const classes = useStyle({ classes: classOverride });
  return (
    <Button onClick={onClick} className={classes.root} disabled={disabled}>
      {children}
    </Button>
  );
};

MapTransparentButton.displayName = 'MapTransparentButton';
export default MapTransparentButton;
