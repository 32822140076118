import { useEdit } from '.';
import { ResourceName } from '../components';

/** Site 以外で GetOne を使用できる */
const useCreate = (
  resource: ResourceName,
  one: { resource: ResourceName; key: string },
) => {
  const {
    props: { location, basePath },
    data,
  } = useEdit(resource, one);
  const props = { location, basePath, resource };

  return { props, data };
};

export default useCreate;
