import { Map } from 'ol';
import { Select } from 'ol/interaction';
import { Vector as SourceVector } from 'ol/source';

const removePolygon = (
  map: Map,
  select: Select,
  sourceVector: SourceVector,
): boolean => {
  if (sourceVector.getFeatures().length <= 0) return false;
  const keyFeatureId = 'featureId';
  const mapLayers = map.getLayers().getArray();

  const target = select
    .getFeatures()
    .getArray()
    .filter(feature => feature.get('shape') === 'polygon');
  if (target.length <= 0) return false;

  target.forEach(feature => {
    const layer = mapLayers.filter(
      it => it.get(keyFeatureId) === feature.getId(),
    )[0];
    if (layer) {
      map.removeLayer(layer);
    }
    sourceVector.removeFeature(feature);
  });
  select.getFeatures().clear();
  return true;
};

export default removePolygon;
