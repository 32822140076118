import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';

interface InParams {
  parentRentalId: number | undefined;
}

interface OutParams {
  data: {
    parentStartDate: Date | undefined;
  };
}

const useGetParentStartDate = () => {
  const dataProvider = useDataProvider();

  return useCallback(
    async ({ parentRentalId }: InParams): Promise<OutParams> => {
      if (!parentRentalId)
        return {
          data: {
            parentStartDate: undefined,
          },
        };

      // Wレンタルの貸出元情報の取得
      const {
        data: { actualStartDate },
      } = await dataProvider.getOne('retrofitRentals', {
        id: parentRentalId,
      });

      // Wレンタルの貸出元の貸出開始日を格納
      const parentStartDate = new Date(actualStartDate);
      return {
        data: {
          parentStartDate,
        },
      };
    },
    [dataProvider],
  );
};

export default useGetParentStartDate;
