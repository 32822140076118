import React, { useCallback, useState } from 'react';
import {
  Identifier,
  useDeleteMany,
  useUnselectAll,
  useNotify,
  CRUD_DELETE_MANY,
  useDataProvider,
  useTranslate,
} from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import { Button, DialogOKOnly } from '..';
import { BulkActionButtonProps } from '../bases/list/List';
import { colors } from '../../theme';
import { useAccount, useJumpTo } from '../../hooks';
import { buttonWidth, buttonHeight } from '../../theme/define';
import { apiErrorHandler } from '../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      background: colors.button.save,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      color: colors.text.primaryLight,
      fontSize: 14,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: colors.button.saveHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.saveDisabled,
      },
      '& span': {
        padding: 0,
      },
    },
  }),
);

interface Props {
  resource: string;
  record?: any;
  disabled?: boolean;
}

type ListApprovalButtonrops = React.ComponentProps<BulkActionButtonProps>;

const ListApprovalButton: React.FC<ListApprovalButtonrops & Props> = props => {
  const classes = useStyles();
  const { resource, record, selectedIds, disabled, undoable } = props;
  const ids = selectedIds as Identifier[];
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const { gotoList } = useJumpTo();
  const getAccount = useAccount();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const gotoListUrl = 'approvals';
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    messages: string[];
  }>({ open: false, messages: [] });

  // 'pendingRentals'のdeleteManyを呼ぶことで、DataProvider内の処理でrentalsのisApprovalの更新等を行う
  const [updateMany, { loading }] = useDeleteMany(resource, ids, {
    action: CRUD_DELETE_MANY,
    undoable,
    onSuccess: () => {
      notify(
        'admin.message.approved',
        'info',
        {
          smart_count: ids.length,
        },
        undoable,
      );
      unselectAll();
      gotoList(gotoListUrl);
    },
    onFailure: error => {
      return notify(apiErrorHandler(error), 'warning');
    },
  });
  const handleClick = useCallback(async () => {
    // 承認種別が貸与
    if (resource === 'pendingRentals') {
      const { retrofitId } = record;
      const { data: retrofitInfo } = await dataProvider.getOne('retrofits', {
        id: retrofitId,
      });
      const { ownerId, corporationId: retrofitCorporationId } = retrofitInfo;

      const {
        data: { corporationId },
      } = await getAccount();

      // レトロフィットの所有企業がログイン企業と同一である場合エラーダイアログを表示;
      if (ownerId === corporationId) {
        setDialogState({
          open: true,
          messages: [
            translate(
              'admin.dialog.pendingApprovals.ownerCorporation.message1',
            ),
            translate(
              'admin.dialog.pendingApprovals.ownerCorporation.message2',
            ),
          ],
        });
        return;
      }

      // レトロフィットの利用中企業がログイン企業と同一である場合エラーダイアログを表示;
      if (retrofitCorporationId === corporationId) {
        setDialogState({
          open: true,
          messages: [
            translate('admin.dialog.pendingApprovals.userCorporation.message1'),
            translate('admin.dialog.pendingApprovals.userCorporation.message2'),
          ],
        });
        return;
      }
    }
    updateMany();
  }, [record, dataProvider, resource, getAccount, translate, updateMany]);

  return (
    <>
      <DialogOKOnly
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, messages: [] })}
        title=""
        messages={dialogState?.messages}
      />
      <Button
        className={classes.button}
        disabled={loading || disabled}
        label="admin.actions.approval"
        data-testid="admin.actions.approval"
        onClick={handleClick}
      />
    </>
  );
};

ListApprovalButton.displayName = 'ListApprovalButton';
export default ListApprovalButton;
