/*
 * アイコンマップオブジェクト
 */
import React from 'react';
import {
  NormalIcon,
  CompletedIcon,
  ConvertingIcon,
  SiteCompletedIcon,
  SiteWaitingIcon,
  SiteWorkingIcon,
  ErrorIcon,
  SlopeIcon,
  StandardIcon,
  TiltIcon,
  TiltrotatorIcon,
  ExtensionarmTypeA,
  ExtensionarmTypeB,
  ExcavationIcon,
  RoadrollerIcon,
  DozerIcon,
  RigiddumpIcon,
  WheelloaderIcon,
  GraderIcon,
  AutomobileIcon,
  ExcavationLicenseIcon,
  ExcavationLicenseActivateIcon,
  RoadrollerLicenseIcon,
  RoadrollerLicenseActivateIcon,
  DozerLicenseIcon,
  DozerLicenseActivateIcon,
  RigiddumpLicenseIcon,
  RigiddumpLicenseActivateIcon,
  WheelloaderLicenseIcon,
  WheelloaderLicenseActivateIcon,
  GraderLicenseIcon,
  GraderLicenseActivateIcon,
  AutomobileLicenseIcon,
  AutomobileLicenseActivateIcon,
  AssignmentApprovedIcon,
  AssignmentUnapprovedIcon,
  TransferApprovedIcon,
  TransferUnapprovedIcon,
  AdminIcon,
  TabletIcon,
  TaskConvertingIcon,
  TaskFailtoConvertIcon,
  TaskFailtoTransferIcon,
  TaskTransferringIcon,
  TaskTransferredIcon,
  GnssFailtoConvertIcon,
  GnssTransferringIcon,
  GnssTransferredIcon,
  InUseIcon,
  UnUseIcon,
  Emergency as EmergencyIcon,
  Important as ImportantIcon,
  OnlineIcon,
  OfflineIcon,
  ProcessingIcon,
  UnLicenseIcon,
  InvalidLicenseIcon,
  ValidLicenseIcon,
  DisabledIcon,
  Dimension2dIcon,
  Dimension3dIcon,
  GeoCircleIcon,
  GeoLineIcon,
  GeoPointIcon,
  GeoPolygonIcon,
  GeoWallIcon,
  AlertAttentionIcon,
  AlertCautionIcon,
  AlertNoticeIcon,
  AlertWarningIcon,
  AlertDangerIcon,
} from '../assets';

const iconMap: {
  [resource: string]: {
    [source: string]: {
      [value: string]: React.FC | null;
    };
  };
} = {
  sites: {
    status: {
      completed: SiteCompletedIcon,
      waiting: SiteWaitingIcon,
      working: SiteWorkingIcon,
    },
  },
  retrofits: {
    isError: {
      true: ErrorIcon,
      false: NormalIcon,
    },
    isOnline: {
      true: OnlineIcon,
      false: OfflineIcon,
    },
    machineInfoMachineType: {
      excavator: ExcavationIcon,
      roadroller: RoadrollerIcon,
      dozer: DozerIcon,
      rigiddump: RigiddumpIcon,
      wheelloader: WheelloaderIcon,
      grader: GraderIcon,
      automobile: AutomobileIcon,
    },
    licenseMachineType: {
      excavator: ExcavationIcon,
      roadroller: RoadrollerIcon,
      dozer: DozerIcon,
      rigiddump: RigiddumpIcon,
      wheelloader: WheelloaderIcon,
      grader: GraderIcon,
      automobile: AutomobileIcon,
      excavator_license: ExcavationLicenseIcon,
      excavator_license_activate: ExcavationLicenseActivateIcon,
      roadroller_license: RoadrollerLicenseIcon,
      roadroller_license_activate: RoadrollerLicenseActivateIcon,
      dozer_license: DozerLicenseIcon,
      dozer_license_activate: DozerLicenseActivateIcon,
      rigiddump_license: RigiddumpLicenseIcon,
      rigiddump_license_activate: RigiddumpLicenseActivateIcon,
      wheelloader_license: WheelloaderLicenseIcon,
      wheelloader_license_activate: WheelloaderLicenseActivateIcon,
      grader_license: GraderLicenseIcon,
      grader_license_activate: GraderLicenseActivateIcon,
      automobile_license: AutomobileLicenseIcon,
      automobile_license_activate: AutomobileLicenseActivateIcon,
    },
    gnssSettingStatus: {
      sending: GnssTransferringIcon,
      sent: GnssTransferredIcon,
      failtosend: GnssFailtoConvertIcon,
    },
    licenseStatus: {
      unlicense: UnLicenseIcon,
      disable: InvalidLicenseIcon,
      enable: ValidLicenseIcon,
    },
    rentalStatus: {
      approved: AssignmentApprovedIcon,
      unapproved: AssignmentUnapprovedIcon,
      returned: React.Fragment, // 空のコンポーネントで非表示を実現
    },
  },
  buckets: {
    bucketType: {
      slope: SlopeIcon,
      standard: StandardIcon,
      tilt: TiltIcon,
      tiltrotator: TiltrotatorIcon,
    },
    uploadedBy: {
      admin: AdminIcon,
      tablet: TabletIcon,
    },
    fileStatus: {
      converting: ConvertingIcon,
      completed: CompletedIcon,
      error: ErrorIcon,
    },
  },
  extensionarms: {
    extensionarmType: {
      a: ExtensionarmTypeA,
      b: ExtensionarmTypeB,
    },
  },
  projects: {
    status: {
      converted: CompletedIcon,
      waitforconvert: ConvertingIcon,
      error: ErrorIcon,
    },
  },
  retrofitRentals: {
    isApproved: {
      true: AssignmentApprovedIcon,
      false: AssignmentUnapprovedIcon,
    },
    rentalStatus: {
      approved: AssignmentApprovedIcon,
      unapproved: AssignmentUnapprovedIcon,
      returned: React.Fragment, // 空のコンポーネントで非表示を実現
    },
  },
  assignments: {
    isApproved: {
      true: TransferApprovedIcon,
      false: TransferUnapprovedIcon,
    },
  },
  panelstatus: {
    remoteStatus: {
      unabletoconnect: NormalIcon,
      error: ErrorIcon,
    },
  },
  asbuilts: {
    execStatus: {
      inprogress: ProcessingIcon,
      complete: CompletedIcon,
      error: ErrorIcon,
      completewithoutdata: CompletedIcon,
    },
  },
  retrofitBulkActions: {
    execStatus: {
      inprogress: ProcessingIcon,
      complete: CompletedIcon,
      error: ErrorIcon,
    },
  },
  retrofitAdminCorporationBulkActions: {
    execStatus: {
      inprogress: ProcessingIcon,
      complete: CompletedIcon,
      error: ErrorIcon,
    },
  },
  tasks: {
    status: {
      error: TaskFailtoConvertIcon,
      converting: TaskConvertingIcon,
      sending: TaskTransferringIcon,
      sent: TaskTransferredIcon,
      failtosend: TaskFailtoTransferIcon,
    },
  },
  compactionLayers: {
    inUse: {
      true: InUseIcon,
      false: UnUseIcon,
    },
  },
  gnssSettings: {
    status: {
      sending: GnssTransferringIcon,
      sent: GnssTransferredIcon,
      failtosend: GnssFailtoConvertIcon,
    },
  },
  userNotices: {
    noticeType: {
      emergencyimportant: EmergencyIcon,
      emergency: EmergencyIcon,
      important: ImportantIcon,
    },
  },
  retrofitAlternateRegists: {
    isApproved: {
      true: CompletedIcon,
      false: UnUseIcon,
    },
  },
  retrofitCorporationLicenses: {
    licenseStatus: {
      unlicense: UnLicenseIcon,
      disable: InvalidLicenseIcon,
      enable: ValidLicenseIcon,
    },
  },
  geofences: {
    dimension: {
      twodimension: Dimension2dIcon,
      threedimension: Dimension3dIcon,
    },
    type: {
      line: GeoLineIcon,
      polygon: GeoPolygonIcon,
      circle: GeoCircleIcon,
      point: GeoPointIcon,
      wall: GeoWallIcon,
    },
    valid: {
      true: CompletedIcon,
      false: DisabledIcon,
    },
    alertType: {
      notice: AlertNoticeIcon,
      attention: AlertAttentionIcon,
      caution: AlertCautionIcon,
      warning: AlertWarningIcon,
      danger: AlertDangerIcon,
    },
  },
  geofenceAlerts: {
    alertType: {
      notice: AlertNoticeIcon,
      attention: AlertAttentionIcon,
      caution: AlertCautionIcon,
      warning: AlertWarningIcon,
      danger: AlertDangerIcon,
    },
  },
  siteBuriedStructures: {
    status: {
      created: CompletedIcon,
      waitforcreate: ConvertingIcon,
      error: ErrorIcon,
    },
  },
};

export default iconMap;
