import { LicenseData } from '../../types';

const data: LicenseData[] = [
  {
    id: 1,
    items: [
      {
        itemId: 'scpi_jp',
        planId: '324',
      },
    ],
  },
];
export default data;
