import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { colors } from '../../../../theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderStyle: 'solid',
      borderColor: '#808080',
      borderWidth: '1px',
      background: '#31323A',
    },
    p: {
      background: '#31323A',
      color: colors.text.primaryLight, // テキスト表示するので必要
      fontSize: 14, // テキスト表示するので必要
      fontFamily: 'Arial', // テキスト表示するので必要
      textTransform: 'none', // テキスト表示するので必要
      margin: '2px 0px',
    },
  }),
);

interface Props {
  width: number;
  height: number;
  radius: number | undefined;
}

const GeofenceRadiusInputField: FC<Props> = ({ width, height, radius }) => {
  const classes = useStyles();

  return (
    <div style={{ width, height }} className={classes.root}>
      <p className={classes.p} data-testid="circleRadius">
        {radius}
      </p>
    </div>
  );
};

GeofenceRadiusInputField.displayName = 'GeofenceRadiusInputField';
export default GeofenceRadiusInputField;
