import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { mapStyle } from '../../../../../theme';
import {
  MapGeoNotice,
  MapGeoAttention,
  MapGeoCaution,
  MapGeoWarning,
  MapGeoDanger,
} from '../../../../../assets';
import { GeofenceAlertType } from '../../../../../dataProvider';
import MapCommonButton from './MapCommonButton';

const useCurrentStyle = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#000344',
    },
  }),
);

const useStyle = makeStyles(() =>
  createStyles({
    buttonWrap: {
      ...mapStyle.buttonWrapHorizontal,
    },
  }),
);

const AlertTypeEditButton: React.FC<{
  onClickNotice: () => void;
  onClickAttention: () => void;
  onClickCaution: () => void;
  onClickWarning: () => void;
  onClickDanger: () => void;
  selectedAlertType?: GeofenceAlertType;
}> = ({
  onClickNotice,
  onClickAttention,
  onClickCaution,
  onClickWarning,
  onClickDanger,
  selectedAlertType = 'Notice',
}) => {
  const currentStyle = useCurrentStyle();
  const classes = useStyle();
  const [currentAlert, setCurrentAlert] =
    useState<GeofenceAlertType>(selectedAlertType);
  return (
    <div className={classes.buttonWrap}>
      <MapCommonButton
        svgIcon={<MapGeoNotice />}
        testId={'admin.actions.mapNotice'}
        onClick={() => {
          onClickNotice();
          setCurrentAlert('Notice');
        }}
        classes={currentAlert === 'Notice' ? currentStyle : undefined}
      />
      <MapCommonButton
        svgIcon={<MapGeoAttention />}
        testId={'admin.actions.mapAttention'}
        onClick={() => {
          onClickAttention();
          setCurrentAlert('Attention');
        }}
        classes={currentAlert === 'Attention' ? currentStyle : undefined}
      />
      <MapCommonButton
        svgIcon={<MapGeoCaution />}
        testId={'admin.actions.mapCaution'}
        onClick={() => {
          onClickCaution();
          setCurrentAlert('Caution');
        }}
        classes={currentAlert === 'Caution' ? currentStyle : undefined}
      />
      <MapCommonButton
        svgIcon={<MapGeoWarning />}
        testId={'admin.actions.mapWarning'}
        onClick={() => {
          onClickWarning();
          setCurrentAlert('Warning');
        }}
        classes={currentAlert === 'Warning' ? currentStyle : undefined}
      />
      <MapCommonButton
        svgIcon={<MapGeoDanger />}
        testId={'admin.actions.mapDanger'}
        onClick={() => {
          onClickDanger();
          setCurrentAlert('Danger');
        }}
        classes={currentAlert === 'Danger' ? currentStyle : undefined}
      />
    </div>
  );
};

AlertTypeEditButton.displayName = 'AlertTypeEditButton';
export default AlertTypeEditButton;
