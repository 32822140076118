import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { useParams } from 'react-router-dom';
import {
  usePageProps,
  useAccount,
  useGetSelefDetailData,
  useGetListAllPage,
} from '../../../../hooks';

import {
  SiteData,
  GeofenceData,
  UnitLength,
  SiteConfigureData,
  UserConfigureData,
  GeofenceAlertsData,
} from '../../../../dataProvider';

interface OutParams {
  props: {
    location: any;
    basePath: string;
    resource: string;
    id?: string;
  };
  loading: boolean;
  sites?: {
    siteId: string;
    record: SiteData;
  };
  unitLength: UnitLength;
  geofences: GeofenceData[];
  corporationId: string;
  isOwnData: boolean;
  geofenceAlert?: GeofenceAlertsData;
  mapBackgroundColor: string;
}

const useGeofenceProps = (geofenceAlertId?: string): OutParams => {
  const props = usePageProps('geofences');
  const geofenceId = props.id || '';
  const { siteId } = useParams<{ siteId: string }>();
  const [apiState, setApiState] = useState<{
    fetched: boolean;
    loading: boolean;
    site?: SiteData;
    unitLength: UnitLength;
    geofences: GeofenceData[];
    corporationId: string;
    geofenceId: string | undefined;
    isOwnData: boolean;
    geofenceAlert?: GeofenceAlertsData;
    mapBackgroundColor: string;
  }>({
    fetched: false,
    loading: true,
    unitLength: 'M',
    geofences: [],
    corporationId: '',
    geofenceId: undefined,
    isOwnData: false,
    mapBackgroundColor: '#ffffff',
  });

  const dataProvider = useDataProvider();
  const getAccount = useAccount();
  const getSelefDetailData = useGetSelefDetailData();
  const getListAllPage = useGetListAllPage();

  useEffect(() => {
    if (apiState.fetched && apiState.geofenceId === geofenceId) return;
    const api = async (): Promise<{
      data: {
        site: SiteData;
        unitLength: UnitLength;
        geofences: GeofenceData[];
        corporationId: string;
        isOwnData: boolean;
        geofenceAlert?: GeofenceAlertsData;
        mapBackgroundColor: string;
      };
    }> => {
      // sites
      const { data: site } = await dataProvider.getOne<SiteData>('sites', {
        id: siteId,
      });
      const { unitLength: landLogUnitLength } = site;
      const { data: siteConfigures } =
        await dataProvider.getList<SiteConfigureData>('siteConfigures', {
          pagination: {
            page: 1,
            perPage: 1,
          },
          sort: { field: 'id', order: 'ASC' },
          filter: {
            siteId,
          },
        });

      // unitLength
      const unitLength =
        siteConfigures.length > 0
          ? siteConfigures[0].unitLength
          : landLogUnitLength;

      // accounts
      const {
        data: { corporationId, email },
      } = await getAccount();

      let geofenceAlert;

      // ジオフェンスアラートIDが設定されている場合はジオフェンスアラート情報を取得する
      if (geofenceAlertId) {
        const { data: geofenceAlerts } =
          await dataProvider.getOne<GeofenceAlertsData>('geofenceAlerts', {
            id: Number(geofenceAlertId),
          });
        geofenceAlert = geofenceAlerts;
      }

      // geofences
      // URLチェックに使用するデータ
      let targetGeofence;
      const resourceName = !geofenceAlertId ? 'geofences' : 'geofenceAlerts';
      if (geofenceId !== '') {
        targetGeofence = await getSelefDetailData(
          resourceName,
          { siteId },
          geofenceId,
        );
      }

      // 画面に使用するgeofenceデータ
      const filter = geofenceAlertId
        ? { geofenceId: geofenceAlert?.geofenceId || '' }
        : { siteId };
      const geofenceRecords = await getListAllPage({
        resource: 'geofences',
        filter,
        sort: {
          field: 'name',
          order: 'ASC',
        },
      });

      // Record型で受け取っているため、型を成形
      const geofences = geofenceRecords.data as GeofenceData[];

      // ユーザ設定
      const { data: userConfigures } =
        await dataProvider.getList<UserConfigureData>('userConfigures', {
          pagination: {
            page: 1,
            perPage: 1,
          },
          sort: { field: 'id', order: 'ASC' },
          filter: {
            email,
          },
        });
      // ユーザー設定情報がなければ白色を返す
      const mapBackgroundColor = userConfigures[0]
        ? userConfigures[0].mapBackgroundColor
        : '#ffffff';

      return {
        data: {
          site,
          unitLength,
          geofences,
          corporationId,
          isOwnData: !!targetGeofence,
          geofenceAlert,
          mapBackgroundColor,
        },
      };
    };
    api().then(
      ({
        data: {
          site,
          unitLength,
          geofences,
          corporationId,
          isOwnData,
          geofenceAlert,
          mapBackgroundColor,
        },
      }) => {
        setApiState({
          fetched: true,
          loading: false,
          site,
          unitLength,
          geofences,
          corporationId,
          geofenceId,
          isOwnData,
          geofenceAlert,
          mapBackgroundColor,
        });
      },
    );
  }, [
    apiState.fetched,
    apiState.geofenceId,
    dataProvider,
    siteId,
    getAccount,
    geofenceId,
    geofenceAlertId,
    getSelefDetailData,
    getListAllPage,
  ]);

  return {
    props,
    loading: apiState.loading,
    sites: apiState.site
      ? {
          siteId,
          record: apiState.site,
        }
      : undefined,
    unitLength: apiState.unitLength,
    geofences: apiState.geofences,
    corporationId: apiState.corporationId,
    isOwnData: apiState.isOwnData,
    geofenceAlert: apiState.geofenceAlert,
    mapBackgroundColor: apiState.mapBackgroundColor,
  };
};
export default useGeofenceProps;
