import React, { useCallback, useRef, useEffect, useState } from 'react';
import { makeStyles, createStyles, Dialog } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {
  ArrayInput as RaArrayInput,
  useTranslate,
  useLoading,
} from 'react-admin';
import {
  Button,
  DoneButton,
  NormalButton,
  CustomForm,
  MapDialogDeleteButton,
  CustomNumberInput,
  SimpleFormIterator,
} from '..';
import { useUnitLength } from '../../hooks';
import { LonLat } from '../../utils';
import { AddIcon, ReturnIcon, SaveIcon } from '../../assets';
import { styles } from '../../theme';
import { UnitLength } from '../../dataProvider';

const RoundUpDigits = 3; // 小数第四位で四捨五入し小数第三位で扱う

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& li': {
        border: 'none',
      },
      '& section': {
        display: 'flex',
        '& label': {
          width: 30,
          minWidth: 30,
        },
        '& > div': {
          marginLeft: 4,
          marginRight: 4,
          minWidth: 260,
          '& div.MuiInputBase-root': {
            '& > input': {
              width: 120,
              minWidth: 120,
            },
          },
        },
      },
    },
    header: {
      fontSize: 18,
      margin: 0,
      padding: 24,
    },
    error: {
      fontSize: 12,
      color: '#f44336',
    },
    content: {
      overflowX: 'hidden',
      fontSize: 18,
    },
    footer: {
      margin: 0,
      padding: 24,
      minHeight: 36, // ボタン分の高さ
      boxSizing: 'content-box', // paddingをheightの高さとして扱わない
    },
    arrayInput: {
      marginTop: 0,
      marginBottom: 0,
      '& > label': {
        // ArrayInput の Label をCSSで非表示にする (TextInput のように addLabel が用意されていない為)
        display: 'none',
      },
    },
  }),
);

const useAddButtonStyles = makeStyles(() =>
  createStyles({
    button: {
      ...styles.buttonIteratorAdd,
    },
  }),
);

const useIteratorAddButton = (disabled: boolean) => {
  const classes = useAddButtonStyles();
  return (
    <Button
      onClick={e => e.preventDefault()}
      classes={classes}
      disabled={disabled}
    >
      <AddIcon />
    </Button>
  );
};

const useIteratorDeleteButton = (disabled: boolean) => {
  return (
    <MapDialogDeleteButton
      onClick={e => e.preventDefault()}
      disabled={disabled}
    />
  );
};

const useStyleFormHeader = makeStyles(() =>
  createStyles({
    header: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      padding: 24,
    },
  }),
);

interface Coordinate {
  lon: number;
  lat: number;
}

interface CoordinateInput {
  lon?: number | string;
  lat?: number | string;
}

export const useNumberCoordinates = () => {
  const { feetToUnitLength } = useUnitLength();
  return (
    coordinates: CoordinateInput[],
    unitLength: UnitLength,
  ): Coordinate[] => {
    return coordinates.map(({ lon, lat }: CoordinateInput) => {
      const newLon = feetToUnitLength(Number(lon), unitLength);
      const newLat = feetToUnitLength(Number(lat), unitLength);

      return {
        lon: newLon,
        lat: newLat,
      } as Coordinate;
    });
  };
};

const dialogMinWidth = 674;
const dialogMinHeight = 382;

const TaskInputDialogForm: React.FC<
  {
    record: any;
    saveButton: React.ReactElement<any> | boolean;
    cancelButton: React.ReactElement<any> | boolean;
    onValueChange: (value: any) => void;
    unit: UnitLength;
  } & React.ReactNode
> = ({ children, record, saveButton, onValueChange, cancelButton, unit }) => {
  const classes = useStyleFormHeader();
  const ref = useRef<HTMLDivElement>(null);

  const toNumberCoordinates = useNumberCoordinates();

  useEffect(() => {
    const dialogElement = ref?.current?.parentElement?.parentElement;
    if (!dialogElement) return;
    dialogElement.style.minWidth = `${dialogMinWidth}px`;
    dialogElement.style.minHeight = `${dialogMinHeight}px`;
  }, [ref]);

  /**
   * フォームバリデーション
   *
   * @param value form の入力値
   *
   * @return form の型に沿ったオブジェクト
   *
   * エラーメッセージは Field に対応する Value に文字列を設定する
   *  (例)
   *   一番目のデータの lon の InputField にバリデーションエラーを表示する
   *
   *   return { coordinates: [{ lon: 'error' }] };
   */
  const formValidation = (value: any) => {
    const { coordinates } = value as {
      coordinates: CoordinateInput[];
    };
    const raRequired = 'ra.validation.required';
    const validation = coordinates.map(it => {
      // 追加ボタン押下時は (array) => [undefined] が設定される
      if (it === undefined) {
        return { lon: raRequired, lat: raRequired };
      }

      const fieldCheck = (fieldValue: any, fieldName: string) => {
        // 未入力時は undefined、削除時は null が設定される
        if (fieldValue === undefined || fieldValue === null) {
          return { [fieldName]: raRequired };
        }
        return undefined;
      };
      const lonError = fieldCheck(it.lon, 'lon');
      const latError = fieldCheck(it.lat, 'lat');

      return { ...lonError, ...latError };
    });

    // 経度/緯度の必須チェックバリデーション
    const lonLatError =
      validation.filter(it => {
        return JSON.stringify(it) !== '{}'; // エラーがあるかどうか、`{}` 比較している
      }).length > 0;
    if (lonLatError) {
      return { coordinates: validation };
    }

    // 経度/緯度の3点チェックバリデーション
    const minPointsError = coordinates.length < 3; // 矩形を描くのに3点必須である
    if (minPointsError) {
      // エラーとして検出するためにダミー値を返す（画面上にはでない、対応する Field が存在しない為）
      return { coordinates: ['error'] };
    }
    // バリデーションを通過したら、親コンポーネントに最新の値を通知する
    onValueChange(toNumberCoordinates(coordinates, unit));
    return {};
  };

  return (
    <div ref={ref}>
      <CustomForm
        validate={formValidation}
        saveButton={saveButton}
        cancelButton={cancelButton}
        record={record}
        classes={classes}
      >
        {children}
      </CustomForm>
    </div>
  );
};

const emptyValues = [
  { lon: undefined, lat: undefined },
  { lon: undefined, lat: undefined },
  { lon: undefined, lat: undefined },
];

const checkAllCoordinate = (coordinates: LonLat[]) => {
  const src = coordinates.map((it: Coordinate) => `${it.lon},${it.lat}`); // 経度/緯度の情報を一つの文字列にする
  const dst = Array.from(new Set(src)); // Set を利用して、重複した値を集約する
  return dst.length === 1; //  全て(X, Y)が同じかチェックする
};

export const useDefaultValues = () => {
  const { meterToUnitLengthRoundUp } = useUnitLength();
  return (lonLat: LonLat[], unitLength: UnitLength): LonLat[] =>
    lonLat.map(({ lon, lat }) => ({
      lon: meterToUnitLengthRoundUp(lon, unitLength, RoundUpDigits),
      lat: meterToUnitLengthRoundUp(lat, unitLength, RoundUpDigits),
    }));
};

const TaskInputDialog: React.FC<{
  open: boolean;
  lonLat: LonLat[];
  unit: UnitLength;
  onClose: (lonLat: LonLat[] | undefined) => void;
  onIntersectCheck: (lonLat: LonLat[]) => boolean;
  onSameValueCheck: (lonLat: LonLat[]) => boolean;
  onAreaZeroCheck: (lonLat: LonLat[]) => boolean;
}> = ({
  open,
  lonLat,
  unit,
  onClose,
  onIntersectCheck,
  onSameValueCheck,
  onAreaZeroCheck,
}) => {
  const classes = useStyles();
  const loading = useLoading();
  const translate = useTranslate();
  const [saveCoordinates, setSaveCoordinates] = useState<string>(''); // NOTE: Array で扱うと、何故かset時に無限ループが起きる
  const errorMessageRef = useRef<HTMLSpanElement>(null);
  const getDefaultValue = useDefaultValues();
  const defaultValues = getDefaultValue(lonLat, unit);

  const record = {
    coordinates: defaultValues.length <= 0 ? emptyValues : defaultValues,
  };

  const handleValueChange = useCallback(
    (newValue: any) => {
      setSaveCoordinates(JSON.stringify(newValue));
    },
    [setSaveCoordinates],
  );

  const handleSave = useCallback(async () => {
    const results: LonLat[] = JSON.parse(saveCoordinates);
    if (checkAllCoordinate(results)) {
      if (errorMessageRef && errorMessageRef.current) {
        const message = 'admin.message.task.invalidPolygonMessage';
        errorMessageRef.current.innerText = translate(message);
      }
      return;
    }
    if (onIntersectCheck(results)) {
      if (errorMessageRef && errorMessageRef.current) {
        const message = 'admin.message.task.crossingMessage';
        errorMessageRef.current.innerText = translate(message);
      }
      return;
    }
    if (onSameValueCheck(results)) {
      if (errorMessageRef && errorMessageRef.current) {
        const message = 'admin.message.task.sameValueMessage';
        errorMessageRef.current.innerText = translate(message);
      }
      return;
    }
    // 面積チェックはローカル座標で行う → 'results' 変数
    if (onAreaZeroCheck(results)) {
      if (errorMessageRef && errorMessageRef.current) {
        const message = 'admin.message.task.areaZeroMessage';
        errorMessageRef.current.innerText = translate(message);
      }
      return;
    }
    onClose(results);
  }, [
    onClose,
    onIntersectCheck,
    onSameValueCheck,
    onAreaZeroCheck,
    translate,
    errorMessageRef,
    saveCoordinates,
  ]);

  const { toDisplayUnitLength } = useUnitLength();

  const addButton = useIteratorAddButton(loading);
  const deleteButton = useIteratorDeleteButton(loading);
  const coordinateLabel = translate('admin.label.tasks.coordinate', {
    unit: toDisplayUnitLength(unit),
  });

  return (
    <>
      <Dialog open={open} className={classes.root}>
        {open ? (
          <>
            <MuiDialogTitle className={classes.header}>
              {coordinateLabel}
            </MuiDialogTitle>
            <MuiDialogContent className={classes.content}>
              <span className={classes.error} ref={errorMessageRef}></span>
              <TaskInputDialogForm
                record={record}
                saveButton={
                  <DoneButton onClick={handleSave} disabled={loading}>
                    <SaveIcon />
                  </DoneButton>
                }
                cancelButton={
                  <NormalButton
                    onClick={() => onClose(undefined)}
                    disabled={loading}
                  >
                    <ReturnIcon />
                  </NormalButton>
                }
                onValueChange={handleValueChange}
                unit={unit}
              >
                <RaArrayInput
                  className={classes.arrayInput}
                  source="coordinates"
                  defaultValue={record}
                >
                  <SimpleFormIterator
                    addButton={addButton}
                    removeButton={deleteButton}
                  >
                    <CustomNumberInput
                      source="lon"
                      label="admin.label.tasks.coordinateX"
                      disabled={loading}
                    />
                    <CustomNumberInput
                      source="lat"
                      label="admin.label.tasks.coordinateY"
                      disabled={loading}
                    />
                  </SimpleFormIterator>
                </RaArrayInput>
              </TaskInputDialogForm>
            </MuiDialogContent>
            <MuiDialogActions className={classes.footer}></MuiDialogActions>
          </>
        ) : (
          false
        )}
      </Dialog>
    </>
  );
};

TaskInputDialog.displayName = 'TaskInputDialog';
export default TaskInputDialog;
