import { ProjectsData } from '../../types';

const projects: ProjectsData[] = [
  {
    id: 1,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: '大手町地区プロジェクト',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 283,
    datumId: 283,
    geoidId: 0,
    retrofitId: [1, 2, 3, 4],
    version: 1,
    latestProjectVersionId: 11,
    status: 'Converted',
    lastUpdated: '2020-01-01T01:01:01.000Z',
    file: [
      {
        src: 'http://example.com/dummy',
        title: 'file01.xml',
      },
    ],
    projectSourceLayers: [
      {
        id: 2,
        name: 'test project source file02',
        mapServerProjectSourceLayerId: 10,
        projectBoundary: {
          northEastLat: -3047.6944,
          northEastLon: 14513.5278,
          northWestLat: -3059.5833,
          northWestLon: 6235.1944,
          southEastLat: -7262.8056,
          southEastLon: 14540.0833,
          southWestLat: -7272.5833,
          southWestLon: 6204.5278,
        },
      },
      {
        id: 1,
        name: 'test project source file01',
        mapServerProjectSourceLayerId: 1,
        projectBoundary: {
          northEastLat: -3500.4667,
          northEastLon: 14001.4895,
          northWestLat: -3500.4667,
          northWestLon: 7034.0018,
          southEastLat: -7400.0716,
          southEastLon: 14000.4895,
          southWestLat: -7400.0716,
          southWestLon: 7000.0018,
        },
      },
    ],
    projectSourceLineWorks: [],
    projectLocation: {
      originEast: 0,
      originNorth: 0,
      originLongitude: 0,
      originLatitude: 0,
      scale: 0,
      rotationAngle: 0,
      verticalOffset: 0,
      inclineX: 0,
      inclineY: 0,
      wkt: 'string',
      colorFace: 'string',
      colorEdge: 'string',
      projectLocationId: 0,
      projectSourceOriginPoints: [],
    },
    errorInfo: [],
    projectZipFileId: '99a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    projectZipFile: {
      src: 'http//www.3dmg.test/aaa.zip',
      title: 'testfile.zip',
    },
    unitLength: 'M',
  },
  {
    id: 2,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: '宮崎県西都市黒生野プロジェクト',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 284,
    datumId: 284,
    geoidId: 0,
    retrofitId: [51, 52, 53],
    version: 1,
    latestProjectVersionId: 12,
    status: 'Converted',
    lastUpdated: '2020-01-02T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [
      {
        id: 1,
        name: 'test project source file01',
        mapServerProjectSourceLayerId: 1,
        projectBoundary: {
          northEastLat: 32.06559194032224,
          northEastLon: 131.40905195106856,
          northWestLat: 32.06559194032224,
          northWestLon: 131.40321911715156,
          southEastLat: 32.06401996735172,
          southEastLon: 131.40905195106856,
          southWestLat: 32.06401996735172,
          southWestLon: 131.40321911715156,
        },
      },
    ],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 3,
    siteId: '4101d0da-4759-37e8-a502-d0f7de33f8ca',
    name: '飯塚本町東地区プロジェクト',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 285,
    datumId: 285,
    geoidId: 0,
    retrofitId: [5],
    version: 1,
    latestProjectVersionId: 13,
    status: 'Converted',
    lastUpdated: '2020-01-03T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 4,
    siteId: '6db4340a-4f63-abe4-aee3-66ae53424591',
    name: '大阪駅北地区（うめきた）プロジェクト',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 286,
    datumId: 286,
    geoidId: 0,
    retrofitId: [6],
    version: 1,
    latestProjectVersionId: 14,
    status: 'Converted',
    lastUpdated: '2020-01-04T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 5,
    siteId: '9684c0ec-2913-ed24-33a4-ac424fc89171',
    name: '押上・業平橋駅周辺地区プロジェクト',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 287,
    datumId: 287,
    geoidId: 0,
    retrofitId: [7],
    version: 1,
    latestProjectVersionId: 15,
    status: 'Converted',
    lastUpdated: '2020-01-05T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 6,
    siteId: '3ebca2cc-5f6e-b63b-f58e-16dddc62c2f8',
    name: 'みなとみらい21中央地区プロジェクト',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 288,
    datumId: 288,
    geoidId: 0,
    retrofitId: [8],
    version: 1,
    latestProjectVersionId: 16,
    status: 'Converted',
    lastUpdated: '2020-01-06T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 7,
    siteId: '526d513e-aad0-f9bf-6ff6-c9d3ce1bc963',
    name: '城野駅北地区プロジェクト',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 289,
    datumId: 289,
    geoidId: 0,
    retrofitId: [9],
    version: 1,
    latestProjectVersionId: 17,
    status: 'Converted',
    lastUpdated: '2020-01-07T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 8,
    siteId: '4b54acba-d7cf-222d-006d-f4ecad1f09e1',
    name: '柏崎駅前地区プロジェクト',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 290,
    datumId: 290,
    geoidId: 0,
    retrofitId: [10],
    version: 1,
    latestProjectVersionId: 18,
    status: 'Converted',
    lastUpdated: '2020-01-08T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 9,
    siteId: 'a3c3ccdb-2edf-aa15-2e26-366b9a21fb60',
    name: '渋谷駅街区プロジェクト',
    coordinate: 'Localization',
    regionId: 0,
    projectionId: 0,
    datumId: 0,
    geoidId: 0,
    retrofitId: [11],
    version: 1,
    latestProjectVersionId: 19,
    status: 'Error',
    lastUpdated: '2020-01-09T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [
      'SYSTEM_BATCH_ERROR',
      'SYSTEM_SCAPI_ERROR',
      'SYSTEM_PARAMETER_ERROR',
      'SYSTEM_PARAMETERFILE_NOTFOUND',
      'SYSTEM_EXCEPTION',
      'SYSTEM_LOGFILE_ERROR',
      'SYSTEM_PARAMETERFILE_PARAMETER_ERROR',
      'DESING_LOCALIZATION_ERROR',
      'DESING_TILE_ERROR',
      'DESING_OUTLINE_ERROR',
      'DESING_AREA_LENGTH_ERROR',
      'DESING_NO_SUPPORT_DXF_ERROR',
    ],
    unitLength: 'M',
  },
  {
    id: 10,
    siteId: 'e4e78652-46af-5370-1dd2-9c7ad4936d83',
    name: '品川駅周辺地区プロジェクト',
    coordinate: 'Localization',
    regionId: 0,
    projectionId: 0,
    datumId: 0,
    geoidId: 0,
    retrofitId: [0],
    version: 1,
    latestProjectVersionId: 20,
    status: 'WaitForConvert',
    lastUpdated: '2020-01-10T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [
      {
        id: 16,
        name: 'メッシュ',
        mapServerProjectSourceLayerId: 13,
      },
      {
        id: 17,
        name: '★newMIHAMA_SekkeiData',
        mapServerProjectSourceLayerId: 12,
      },
    ],
    projectSourceLineWorks: [
      {
        id: 144,
        name: 'tominaga71269872941',
        mapServerProjectSourceLineWorkId: 9,
      },
      {
        id: 145,
        name: 'tominaga71444472941',
        mapServerProjectSourceLineWorkId: 10,
      },
    ],
    projectLocation: {
      originEast: 22725.863750000004,
      originNorth: -44048.97325,
      originLongitude: 140.08415745417508,
      originLatitude: 35.60270192589384,
      scale: 0.9999271247912556,
      rotationAngle: -0.147433896742623,
      verticalOffset: -34.83720072434843,
      inclineX: -0.000051390910073,
      inclineY: -0.000159198850008,
      wkt: '',
      colorFace: '223 0 0',
      colorEdge: '0 0 0',
      projectLocationId: 11,
      projectSourceOriginPoints: [
        {
          basePointX: 140.085446529,
          basePointY: 35.602779293,
          projectSourceOriginPointId: 41,
        },
        {
          basePointX: 140.083839705,
          basePointY: 35.601663939,
          projectSourceOriginPointId: 42,
        },
        {
          basePointX: 140.082982332,
          basePointY: 35.602503261,
          projectSourceOriginPointId: 43,
        },
        {
          basePointX: 140.084361251,
          basePointY: 35.603861211,
          projectSourceOriginPointId: 44,
        },
        {
          basePointX: 140.085446529,
          basePointY: 35.602779293,
          projectSourceOriginPointId: 45,
        },
        {
          basePointX: 140.083839705,
          basePointY: 35.601663939,
          projectSourceOriginPointId: 46,
        },
        {
          basePointX: 140.082982332,
          basePointY: 35.602503261,
          projectSourceOriginPointId: 47,
        },
        {
          basePointX: 140.084361251,
          basePointY: 35.603861211,
          projectSourceOriginPointId: 48,
        },
      ],
    },
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 11,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'プロジェクト1.1-宮崎県西都市黒生野プロジェクト',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 293,
    datumId: 293,
    geoidId: 0,
    retrofitId: [13],
    version: 11,
    latestProjectVersionId: 21,
    status: 'Converted',
    lastUpdated: '2020-01-11T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [
      {
        id: 1,
        name: 'test project source file01',
        mapServerProjectSourceLayerId: 1,
        projectBoundary: {
          northEastLat: 32.06559194032224,
          northEastLon: 131.40905195106856,
          northWestLat: 32.06559194032224,
          northWestLon: 131.40321911715156,
          southEastLat: 32.06401996735172,
          southEastLon: 131.40905195106856,
          southWestLat: 32.06401996735172,
          southWestLon: 131.40321911715156,
        },
      },
    ],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 12,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'プロジェクト1.2',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 294,
    datumId: 294,
    geoidId: 0,
    retrofitId: [14],
    version: 12,
    latestProjectVersionId: 22,
    status: 'WaitForConvert',
    lastUpdated: '2020-01-12T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 13,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'プロジェクト1.3',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 295,
    datumId: 295,
    geoidId: 0,
    retrofitId: [15],
    version: 13,
    latestProjectVersionId: 23,
    status: 'Error',
    lastUpdated: '2020-01-13T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: ['SYSTEM_BATCH_ERROR'],
    unitLength: 'M',
  },
  {
    id: 14,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'プロジェクト1.4',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 296,
    datumId: 296,
    geoidId: 0,
    retrofitId: [16],
    version: 14,
    latestProjectVersionId: 24,
    status: 'Converted',
    lastUpdated: '2020-01-14T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 15,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'プロジェクト1.5',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 297,
    datumId: 297,
    geoidId: 0,
    retrofitId: [17],
    version: 15,
    latestProjectVersionId: 25,
    status: 'Converted',
    lastUpdated: '2020-01-15T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 16,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'プロジェクト1.6',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 298,
    datumId: 298,
    geoidId: 0,
    retrofitId: [18],
    version: 16,
    latestProjectVersionId: 26,
    status: 'Converted',
    lastUpdated: '2020-01-16T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 17,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'プロジェクト1.7',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 299,
    datumId: 299,
    geoidId: 0,
    retrofitId: [19],
    version: 17,
    latestProjectVersionId: 27,
    status: 'Converted',
    lastUpdated: '2020-01-17T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 18,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'プロジェクト1.8',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 300,
    datumId: 300,
    geoidId: 0,
    retrofitId: [20],
    version: 18,
    latestProjectVersionId: 28,
    status: 'Converted',
    lastUpdated: '2020-01-18T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 19,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'プロジェクト1.9 (現場:新宿タワー)',
    coordinate: 'Localization',
    regionId: 0,
    projectionId: 0,
    datumId: 0,
    geoidId: 0,
    retrofitId: [21],
    version: 19,
    latestProjectVersionId: 29,
    status: 'Converted',
    lastUpdated: '2020-01-19T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [
      {
        id: 1,
        name: 'レイヤー1',
        mapServerProjectSourceLayerId: 10,
        projectBoundary: {
          northEastLat: 35.69689176371023,
          northEastLon: 139.69268836867187,
          northWestLat: 35.696874337607355,
          northWestLon: 139.68925514119397,
          southEastLat: 35.69511428160276,
          southEastLon: 139.6913579930242,
          southWestLat: 35.69527983308002,
          southWestLon: 139.68928732770158,
        },
      },
    ],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 20,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    name: 'プロジェクト2.0',
    coordinate: 'Localization',
    regionId: 0,
    projectionId: 0,
    datumId: 0,
    geoidId: 0,
    retrofitId: [0],
    version: 20,
    latestProjectVersionId: 30,
    status: 'WaitForConvert',
    lastUpdated: '2020-01-20T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 21,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: 'ローカライゼーション情報未所持プロジェクト',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 302,
    datumId: 302,
    geoidId: 0,
    retrofitId: [23],
    version: 21,
    latestProjectVersionId: 31,
    status: 'Error',
    lastUpdated: '2020-01-21T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: ['DESING_LOCALIZATION_ERROR'],
    unitLength: 'M',
  },
  {
    id: 22,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: 'ローカライゼーション情報複数所持(ファイル数3))',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 307,
    datumId: 307,
    geoidId: 0,
    retrofitId: [28],
    version: 22,
    latestProjectVersionId: 36,
    status: 'Error',
    lastUpdated: '2020-01-26T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: ['SYSTEM_LOCALIZATION_QUANTITY_ERROR'],
    resultInfo: {
      errorFileNames: ['File1', 'File2', 'File3'],
      errorLayerNames: [],
    },
    unitLength: 'M',
  },
  {
    id: 23,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: 'ローカライゼーション情報複数所持プロジェクト(ファイル数4)',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 303,
    datumId: 303,
    geoidId: 0,
    retrofitId: [24],
    version: 23,
    latestProjectVersionId: 32,
    status: 'Error',
    lastUpdated: '2020-01-22T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: ['SYSTEM_LOCALIZATION_QUANTITY_ERROR'],
    resultInfo: {
      errorFileNames: ['File1', 'File2', 'File3', 'File4'],
      errorLayerNames: [],
    },
    unitLength: 'M',
  },
  {
    id: 24,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: '0byteファイルプロジェクト',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 304,
    datumId: 304,
    geoidId: 0,
    retrofitId: [25],
    version: 24,
    latestProjectVersionId: 33,
    status: 'Error',
    lastUpdated: '2020-01-23T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: ['SYSTEM_DETECT_0BYTE_FILE_ERROR'],
    resultInfo: {
      errorFileNames: ['File5'],
      errorLayerNames: [],
    },
    unitLength: 'M',
  },
  {
    id: 25,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: 'レイヤー重複プロジェクト',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 305,
    datumId: 305,
    geoidId: 0,
    retrofitId: [26],
    version: 25,
    latestProjectVersionId: 34,
    status: 'Error',
    lastUpdated: '2020-01-24T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: ['DESING_LAYER_NAME_DUPLICATION_ERROR'],
    resultInfo: {
      errorFileNames: [],
      errorLayerNames: ['Layer1', 'Layer2', 'Layer3', 'Layer4'],
    },
    unitLength: 'M',
  },
  {
    id: 26,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: 'バッチエラープロジェクト',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 306,
    datumId: 306,
    geoidId: 0,
    retrofitId: [27],
    version: 26,
    latestProjectVersionId: 35,
    status: 'Error',
    lastUpdated: '2020-01-25T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: ['SYSTEM_BATCH_ERROR'],
    unitLength: 'M',
  },
  {
    id: 27,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: 'プロジェクト2.7',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 308,
    datumId: 308,
    geoidId: 0,
    retrofitId: [29],
    version: 27,
    latestProjectVersionId: 37,
    status: 'Converted',
    lastUpdated: '2020-01-27T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 28,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: 'プロジェクト2.8',
    coordinate: 'Projection',
    regionId: 9,
    projectionId: 309,
    datumId: 309,
    geoidId: 0,
    retrofitId: [30],
    version: 28,
    latestProjectVersionId: 38,
    status: 'Converted',
    lastUpdated: '2020-01-28T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 29,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: 'プロジェクト2.9',
    coordinate: 'Localization',
    regionId: 0,
    projectionId: 0,
    datumId: 0,
    geoidId: 0,
    retrofitId: [31],
    version: 29,
    latestProjectVersionId: 39,
    status: 'Converted',
    lastUpdated: '2020-01-29T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 30,
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    name: 'プロジェクト2.0',
    coordinate: 'Localization',
    regionId: 0,
    projectionId: 0,
    datumId: 0,
    geoidId: 0,
    retrofitId: [0],
    version: 30,
    latestProjectVersionId: 40,
    status: 'WaitForConvert',
    lastUpdated: '2020-01-30T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 31,
    siteId: '6db4340a-4f63-abe4-aee3-66ae53424591',
    name: 'プロジェクト3.1',
    coordinate: 'Projection',
    regionId: 1,
    projectionId: 1,
    datumId: 1,
    geoidId: 0,
    retrofitId: [33],
    version: 31,
    latestProjectVersionId: 41,
    status: 'Converted',
    lastUpdated: '2020-01-31T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 32,
    siteId: '6db4340a-4f63-abe4-aee3-66ae53424591',
    name: 'プロジェクト3.2',
    coordinate: 'Projection',
    regionId: 1,
    projectionId: 2,
    datumId: 2,
    geoidId: 0,
    retrofitId: [34],
    version: 32,
    latestProjectVersionId: 42,
    status: 'Converted',
    lastUpdated: '2020-01-01T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 33,
    siteId: '6db4340a-4f63-abe4-aee3-66ae53424591',
    name: 'プロジェクト3.3',
    coordinate: 'Projection',
    regionId: 1,
    projectionId: 3,
    datumId: 3,
    geoidId: 0,
    retrofitId: [35],
    version: 33,
    latestProjectVersionId: 43,
    status: 'Converted',
    lastUpdated: '2020-01-02T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 34,
    siteId: '6db4340a-4f63-abe4-aee3-66ae53424591',
    name: 'プロジェクト3.4',
    coordinate: 'Projection',
    regionId: 1,
    projectionId: 4,
    datumId: 4,
    geoidId: 0,
    retrofitId: [36],
    version: 34,
    latestProjectVersionId: 44,
    status: 'Converted',
    lastUpdated: '2020-01-03T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 35,
    siteId: '6db4340a-4f63-abe4-aee3-66ae53424591',
    name: 'プロジェクト3.5',
    coordinate: 'Projection',
    regionId: 1,
    projectionId: 5,
    datumId: 5,
    geoidId: 0,
    retrofitId: [37],
    version: 35,
    latestProjectVersionId: 45,
    status: 'Converted',
    lastUpdated: '2020-01-04T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 36,
    siteId: '6db4340a-4f63-abe4-aee3-66ae53424591',
    name: 'プロジェクト3.6',
    coordinate: 'Projection',
    regionId: 1,
    projectionId: 6,
    datumId: 6,
    geoidId: 0,
    retrofitId: [38],
    version: 36,
    latestProjectVersionId: 46,
    status: 'Converted',
    lastUpdated: '2020-01-05T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 37,
    siteId: '6db4340a-4f63-abe4-aee3-66ae53424591',
    name: 'プロジェクト3.7',
    coordinate: 'Projection',
    regionId: 1,
    projectionId: 7,
    datumId: 7,
    geoidId: 0,
    retrofitId: [39],
    version: 37,
    latestProjectVersionId: 47,
    status: 'Converted',
    lastUpdated: '2020-01-06T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 38,
    siteId: '6db4340a-4f63-abe4-aee3-66ae53424591',
    name: 'プロジェクト3.8',
    coordinate: 'Projection',
    regionId: 1,
    projectionId: 8,
    datumId: 8,
    geoidId: 0,
    retrofitId: [40],
    version: 38,
    latestProjectVersionId: 48,
    status: 'Converted',
    lastUpdated: '2020-01-07T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 39,
    siteId: '6db4340a-4f63-abe4-aee3-66ae53424591',
    name: 'プロジェクト3.9',
    coordinate: 'Localization',
    regionId: 0,
    projectionId: 0,
    datumId: 0,
    geoidId: 0,
    retrofitId: [41],
    version: 39,
    latestProjectVersionId: 49,
    status: 'Converted',
    lastUpdated: '2020-01-08T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 40,
    siteId: '6db4340a-4f63-abe4-aee3-66ae53424591',
    name: 'プロジェクト4.0',
    coordinate: 'Localization',
    regionId: 0,
    projectionId: 0,
    datumId: 0,
    geoidId: 0,
    retrofitId: [0],
    version: 40,
    latestProjectVersionId: 50,
    status: 'WaitForConvert',
    lastUpdated: '2020-01-09T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 41,
    siteId: '9684c0ec-2913-ed24-33a4-ac424fc89171',
    name: 'プロジェクト4.1',
    coordinate: 'Projection',
    regionId: 2,
    projectionId: 131,
    datumId: 131,
    geoidId: 0,
    retrofitId: [43],
    version: 41,
    latestProjectVersionId: 51,
    status: 'Converted',
    lastUpdated: '2020-01-10T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 42,
    siteId: '9684c0ec-2913-ed24-33a4-ac424fc89171',
    name: 'プロジェクト4.2',
    coordinate: 'Projection',
    regionId: 3,
    projectionId: 142,
    datumId: 142,
    geoidId: 0,
    retrofitId: [44],
    version: 42,
    latestProjectVersionId: 52,
    status: 'Converted',
    lastUpdated: '2020-01-11T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 43,
    siteId: '9684c0ec-2913-ed24-33a4-ac424fc89171',
    name: 'プロジェクト4.3',
    coordinate: 'Projection',
    regionId: 4,
    projectionId: 167,
    datumId: 167,
    geoidId: 0,
    retrofitId: [45],
    version: 43,
    latestProjectVersionId: 53,
    status: 'Converted',
    lastUpdated: '2020-01-12T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 44,
    siteId: '9684c0ec-2913-ed24-33a4-ac424fc89171',
    name: 'プロジェクト4.4',
    coordinate: 'Projection',
    regionId: 5,
    projectionId: 170,
    datumId: 170,
    geoidId: 0,
    retrofitId: [46],
    version: 44,
    latestProjectVersionId: 54,
    status: 'Converted',
    lastUpdated: '2020-01-13T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 45,
    siteId: '9684c0ec-2913-ed24-33a4-ac424fc89171',
    name: 'プロジェクト4.5',
    coordinate: 'Projection',
    regionId: 6,
    projectionId: 177,
    datumId: 177,
    geoidId: 0,
    retrofitId: [47],
    version: 45,
    latestProjectVersionId: 55,
    status: 'Converted',
    lastUpdated: '2020-01-14T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 46,
    siteId: '9684c0ec-2913-ed24-33a4-ac424fc89171',
    name: 'プロジェクト4.6',
    coordinate: 'Projection',
    regionId: 7,
    projectionId: 179,
    datumId: 179,
    geoidId: 0,
    retrofitId: [48],
    version: 46,
    latestProjectVersionId: 56,
    status: 'Converted',
    lastUpdated: '2020-01-15T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 47,
    siteId: '9684c0ec-2913-ed24-33a4-ac424fc89171',
    name: 'プロジェクト4.7',
    coordinate: 'Projection',
    regionId: 8,
    projectionId: 254,
    datumId: 254,
    geoidId: 0,
    retrofitId: [49],
    version: 47,
    latestProjectVersionId: 57,
    status: 'Converted',
    lastUpdated: '2020-01-16T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 48,
    siteId: '9684c0ec-2913-ed24-33a4-ac424fc89171',
    name: 'プロジェクト4.8',
    coordinate: 'Projection',
    regionId: 8,
    projectionId: 255,
    datumId: 255,
    geoidId: 0,
    retrofitId: [50],
    version: 48,
    latestProjectVersionId: 58,
    status: 'Converted',
    lastUpdated: '2020-01-17T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 49,
    siteId: '9684c0ec-2913-ed24-33a4-ac424fc89171',
    name: 'プロジェクト4.9',
    coordinate: 'Localization',
    regionId: 0,
    projectionId: 0,
    datumId: 0,
    geoidId: 0,
    retrofitId: [54],
    version: 49,
    latestProjectVersionId: 59,
    status: 'Converted',
    lastUpdated: '2020-01-18T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 50,
    siteId: '9684c0ec-2913-ed24-33a4-ac424fc89171',
    name: 'プロジェクト5.0',
    coordinate: 'Localization',
    regionId: 0,
    projectionId: 0,
    datumId: 0,
    geoidId: 0,
    retrofitId: [0],
    version: 50,
    latestProjectVersionId: 60,
    status: 'WaitForConvert',
    lastUpdated: '2020-01-19T01:01:01.000Z',
    file: [
      {
        src: '',
        title: '',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 51,
    siteId: '87bab37d-6121-8712-b46f-db62854ffb7c',
    name: 'プロジェクト名５００１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    coordinate: 'Localization',
    regionId: 0,
    projectionId: 0,
    datumId: 0,
    geoidId: 0,
    retrofitId: [121],
    version: 1,
    latestProjectVersionId: 61,
    status: 'Converted',
    lastUpdated: '2020-05-13T01:01:01.000Z',
    file: [
      {
        src: 'project500123456789012345678901234567890123456.xml',
        title: 'project500123456789012345678901234567890123456.xml',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
  {
    id: 52,
    siteId: '46fb0806-b4fe-6dc5-3127-d6fdc814c2f7',
    name: 'プロジェクト名１００１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
    coordinate: 'Localization',
    regionId: 0,
    projectionId: 0,
    datumId: 0,
    geoidId: 0,
    retrofitId: [122],
    version: 1,
    latestProjectVersionId: 62,
    status: 'Converted',
    lastUpdated: '2020-05-13T01:01:01.000Z',
    file: [
      {
        src: 'project10012345678901234567890123456789012345678901234567890123456789012345678901234567890123456.xml',
        title:
          'project10012345678901234567890123456789012345678901234567890123456789012345678901234567890123456.xml',
      },
    ],
    projectSourceLayers: [],
    projectSourceLineWorks: [],
    errorInfo: [],
    unitLength: 'M',
  },
];

export default projects;
