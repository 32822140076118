import React, { FC, useCallback, useState, useEffect } from 'react';
import {
  required,
  usePermissions,
  useDataProvider,
  FormDataConsumer,
  // useRedirect,
} from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import {
  useResource,
  useNameValidation,
  useFileNameValidation,
  useSite,
  useAccount,
  useGetSelefDetailData,
  useGetUserConfigure,
} from '../../../../../hooks';
import {
  Create,
  CustomForm,
  BreadcrumbsFiled,
  TextInput,
  SelectInput,
  SelectArrayInput,
  Loading,
  CustomDateField,
  TaskMap,
  TaskMapModel,
} from '../../../..';
import { styles } from '../../../../../theme';
import {
  ProjectsData,
  UnitLength,
  RetrofitByTaskData,
} from '../../../../../dataProvider';
import { getParentParentPath } from '../../../../../utils';
import { useRetrofitByTasks, useSiteConfigure } from './hooks';
import { CancelButton, SaveButton } from './views';
import { projectToLayerData, LayerParams } from './utils';

const useStyles = makeStyles(() =>
  createStyles({
    description: {
      ...styles.multilineText,
    },
    root: {
      '& > div': {
        '& > div': {
          '& > div': {
            display: 'flex', // 注意: デフォルトの inline-flex だと width 100% が効かない
          },
        },
      },
    },
  }),
);

const TaskCreate: FC = () => {
  const resource = useResource('tasks');
  const classes = useStyles();
  const { siteId, projectId: strProjectId } = useParams<{
    siteId: string;
    projectId: string;
  }>();
  const projectId = Number(strProjectId);
  const { props, getSite } = useSite('tasks');
  const { basePath } = props;
  const { permissions } = usePermissions();

  const getAccount = useAccount();
  const getSiteConfigure = useSiteConfigure();
  const getRetrofitByTasks = useRetrofitByTasks();
  const dataProvider = useDataProvider();
  const getSelefDetailData = useGetSelefDetailData();
  // const redirectTo = useRedirect();

  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: {
      siteName: string;
      corporationId: string;
      project: ProjectsData;
      retrofitByTasks: RetrofitByTaskData[];
      latestProjectVersionId: number;
      unit: UnitLength;
      layerParams: LayerParams;
      isOwnData: boolean;
    };
  }>({ loading: true, fetched: false });

  const { data } = apiState;

  const [mapModel, setMapModel] = useState<TaskMapModel>();
  const handleBindModel = useCallback(model => setMapModel(model), []);

  const {
    data: { userConfigure },
  } = useGetUserConfigure();
  const mapBackgroundColor = userConfigure
    ? userConfigure.mapBackgroundColor
    : '#ffffff'; // userConfigureデータがなければ地図背景色を白色で表示

  const nameValidation = useNameValidation(
    'tasks',
    'admin.validation.duplicatedTaskName',
    { projectVersionId: data?.latestProjectVersionId || -1 },
  );
  const fileNameValidation = useFileNameValidation();

  const handleSubmit = useCallback(
    async record => {
      await dataProvider.create(resource, {
        data: record,
      });
    },
    [resource, dataProvider],
  );

  useEffect(() => {
    if (apiState.fetched) return;
    setApiState({ loading: true, fetched: true });

    const api = async () => {
      // sites
      const { data: site } = await getSite();
      const { name: siteName, unitLength } = site;

      // accounts
      const { data: account } = await getAccount();
      const { corporationId } = account;

      // 現場に紐づいたプロジェクトであるか判定
      const filter = { siteId };

      // 自社のプロジェクトか判定
      const isOwnProjects = await getSelefDetailData(
        'projects',
        filter,
        projectId,
      );

      // projects
      const { data: project } = await dataProvider.getOne<ProjectsData>(
        'projects',
        {
          id: projectId,
        },
      );

      // siteConfigures
      const { data: siteConfigures } = await getSiteConfigure({ siteId });
      const [siteConfigure] = siteConfigures;
      const unit = siteConfigure?.unitLength || unitLength;

      // retrofitByTasks
      const {
        data: { retrofitByTasks },
      } = await getRetrofitByTasks({ siteId });

      // init layer
      const layerParams = projectToLayerData(project.projectSourceLayers);

      return {
        siteName,
        corporationId,
        project,
        retrofitByTasks,
        latestProjectVersionId: project.latestProjectVersionId,
        unit,
        layerParams,
        isOwnData: !!isOwnProjects,
      };
    };
    api().then(result => {
      setApiState({
        loading: false,
        fetched: true,
        data: result,
      });
    });
  }, [
    siteId,
    apiState.fetched,
    projectId,
    dataProvider,
    getSite,
    getAccount,
    getSiteConfigure,
    getRetrofitByTasks,
    getSelefDetailData,
  ]);

  if (apiState.loading || !apiState.data) return <Loading />;
  // TODO: 閲覧不可なデータは現場一覧に遷移する機能を有効にする場合、コメントアウトを外す
  // const { isOwnData } = apiState.data;
  // プロジェクトが自社のものでない場合、タスク作成画面は表示させない
  // if (!isOwnData) redirectTo('/');

  const {
    version,
    latestProjectVersionId,
    name: projectName,
  } = apiState.data.project;

  const {
    siteName,
    corporationId,
    project,
    retrofitByTasks,
    unit,
    layerParams: { initialLayerValue, layerMap, layerChoices },
  } = apiState.data;

  const expiredDate = new Date();
  const OFFSET_DATE = 7;
  expiredDate.setDate(expiredDate.getDate() + OFFSET_DATE);
  const { token } = permissions;

  return (
    <Create {...props}>
      <CustomForm
        redirect={getParentParentPath(basePath)}
        title="admin.pages.taskCreate"
        resource={resource}
        cancelButton={<CancelButton basePath={basePath} />}
        saveButton={
          <SaveButton
            latestProjectVersionId={latestProjectVersionId}
            getCordinates={() => mapModel?.getCoordinates() || []}
            getIntersect={() => mapModel?.checkIntersect() || false}
            getSameValue={() => mapModel?.checkSameValue() || false}
            getAreaZero={(coordinates: number[][]) =>
              mapModel?.checkAreaZero(coordinates) || false
            }
            onSubmit={handleSubmit}
          />
        }
        initialValues={{
          corporationId,
          siteId: siteId || '',
          projectId: Number(projectId) || 0,
          projectVersionId: version,
          status: 'Converting',
          expiredDate: expiredDate.toISOString(),
          projectSourceLayerId: initialLayerValue.id,
        }}
        className={classes.root}
      >
        <BreadcrumbsFiled
          breadcrumbs={[
            'resources.sites.name',
            '',
            siteName,
            'resources.projects.name',
            projectName,
          ]}
          label="admin.breadcrumbs.taskCreate"
        />
        <TextInput
          resource={resource}
          source="name"
          validate={[required(), fileNameValidation, nameValidation]}
        />
        <SelectInput
          resource={resource}
          source="projectSourceLayerId"
          choices={layerChoices}
          optionText="name"
          validate={[required()]}
          resettable={false}
        />
        <FormDataConsumer>
          {({ formData: { projectSourceLayerId } }) => {
            const layerId = layerMap[projectSourceLayerId];
            return (
              <TaskMap
                onBindModel={handleBindModel}
                projectsData={project}
                layerId={layerId}
                isEditable={true}
                token={token}
                unit={unit}
                projectVersionId={latestProjectVersionId}
                mapBackgroundColor={mapBackgroundColor}
              />
            );
          }}
        </FormDataConsumer>
        <CustomDateField
          record={(() => {
            // NOTE: returnにid:0を指定しないとエラーになる
            return { expiredDate, id: 0 };
          })()}
          source="expiredDate"
        />
        <TextInput
          resource={resource}
          source="description"
          multiline
          rows={3}
          className={classes.description}
        />
        <SelectArrayInput
          resource={resource}
          source="retrofitIds"
          choices={retrofitByTasks}
          optionText="machineInfoMachineName"
        />
      </CustomForm>
    </Create>
  );
};

TaskCreate.displayName = 'TaskCreate';
export default TaskCreate;
