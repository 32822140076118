import { AuthProvider } from 'react-admin';
import { Mutex } from 'await-semaphore';
import { logger } from '../../utils';
import { getUserinfo, tokenProvider, tokenStore, userInfoStore } from '.';

const OFFSET_MINUTES = 1; // 有効期限の1分前にrefreshトークンを取得する

// 同時に呼ばれないように制御する
const mutex = new Mutex();
const updatePermission = async (code?: string) => {
  const inner = async () => {
    if (code) {
      const token = await tokenProvider.get(code);
      tokenStore.set(token);
      const userInfo = await getUserinfo(token);
      userInfoStore.set(userInfo);
    }

    const oldToken = tokenStore.get();
    if (!oldToken) throw new Error();

    const { expireDate: expireMillis } = oldToken;
    const currentMillis = Date.now() + OFFSET_MINUTES * 60 * 1_000;

    logger().debug('token', {
      expireMillis,
      currentMillis,
      diffMillis: expireMillis - currentMillis,
    });

    if (expireMillis > currentMillis) return;

    const token = await tokenProvider.refresh(oldToken);
    tokenStore.set(token);
    const userInfo = await getUserinfo(token);
    userInfoStore.set(userInfo);
  };

  const release = await mutex.acquire();
  await inner().finally(() => {
    release();
  });
};

const resetStore = () => {
  tokenStore.remove();
  userInfoStore.remove();
};

const landlogAuthProvider: AuthProvider = {
  login: async ({ code }: { code?: string }) => {
    if (!code) throw new Error('code');
    await updatePermission(code);
  },

  logout: () => {
    resetStore();
    return Promise.resolve();
  },

  checkError: error => {
    const { status } = error;
    if (status === 401 || status === 403) {
      resetStore();
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: async () => {
    await updatePermission();
  },

  getPermissions: async () => {
    await updatePermission();
    const token = tokenStore.get();
    if (!token) throw new Error();
    const userInfo = userInfoStore.get();
    if (!userInfo) throw new Error();
    return { token, userInfo };
  },
};

export default landlogAuthProvider;
