import { GetManyParams, GetManyResult } from 'react-admin';
import { landlogReadCorporation, landlogCorporationConvert } from '..';
import { LandlogCorporations } from './types';

export default async (params: GetManyParams): Promise<GetManyResult> => {
  const { ids } = params;
  // APIの実行
  const corporations = await Promise.all(
    ids.map(id =>
      landlogReadCorporation(id as string).catch(() => Promise.resolve(null)),
    ),
  );
  const corporationsWithoutError = corporations.filter(
    corporation => corporation,
  ) as LandlogCorporations[];
  const data = corporationsWithoutError.map(corporation =>
    landlogCorporationConvert(corporation),
  );
  return { data };
};
