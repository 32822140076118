import React, { FC, useState, useCallback } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import { useDataProvider, useRedirect, useTranslate } from 'react-admin';
import {
  SimpleShowLayout,
  TabData,
  TabContent,
  Divider,
  Loading,
  BreadcrumbsFiled,
  DialogOKOnlyWithUrl,
} from '../..';
import { styles } from '../../../theme';
import { RetrofitManageRegular, RetrofitNamePlateFile } from '.';
import { config, toSerialNumberText } from '../../../utils';
import {
  useCheckSiteAttachment,
  useCustomLocation,
  useGetAccount,
  useRetrofitLending,
  usePendingAlternateRegisted,
  usePendingTransferred,
  useRetrofitSerialNumber,
} from '../../../hooks';
import {
  UpdateDataRegular,
  useIncludesOwnerCorporationId,
  useExtractSerialNumbers,
  useCheckUnusedNtipDB,
} from './hooks';
import { AccountData, RetrofitData } from '../../../dataProvider';
import useGetRetrofitOwnerRegistCorporations from './hooks/useGetRetrofitOwnerRegistCorporations';

const useStyles = makeStyles(() =>
  createStyles({
    simpleShowLayout: {
      ...styles.zeroPaddingHorizontal,
    },
    breadcrumbs: {
      marginBottom: 12,
    },
  }),
);

const useCreateRetrofitManageTabs = (props: any): TabData[] => {
  const translate = useTranslate();
  const tabData: TabData[] = [];
  tabData.push({
    name: 'photo',
    tab: (
      <MuiTab
        label={translate('admin.pages.retrofitNamePlateFiles')}
        key="tab_photo"
        value="photo"
      />
    ),
    content: <RetrofitNamePlateFile {...props} />,
    order: 1,
  });

  tabData.push({
    name: 'text',
    tab: (
      <MuiTab
        label={translate('admin.pages.retrofitManage')}
        key="tab_text"
        value="text"
      />
    ),
    content: <RetrofitManageRegular {...props} />,
    order: 2,
  });
  return tabData.sort((lhs, rhs) => (lhs.order > rhs.order ? 1 : -1));
};

const RetrofitManageTab: FC = props => {
  const uri = '/controllerRegistration';
  const scSupportRequestUrl = config.url.scSupportRequest; // エラーダイアログ用URL
  const classes = useStyles();
  const translate = useTranslate();
  const { getValue, getBasePath } = useCustomLocation(uri);
  const redirectTo = useRedirect();
  const basePath = getBasePath();
  const handleChange = (_event: any, newValue: string) => {
    redirectTo(`${basePath}/${newValue}`);
  };

  const { getCorporationId, getCorporationName, getEmail, getName, isLoading } =
    useGetAccount();
  const updateData: UpdateDataRegular = {
    corporationId: getCorporationId(),
    corporationName: getCorporationName(),
    registrationApplicantId: getEmail(),
    registrationApplicantName: getName(),
    needsNtripDataReration: true,
  };

  const [errorDialog, setErrorDialog] = useState<{
    open: boolean;
    messages: string[];
    subMessages?: string[];
  }>({ open: false, messages: [], subMessages: [] });

  // 既に登録済みのレトロフィットか
  // ログイン中企業と登録予定建機の利用中企業IDを比較
  const dataProvider = useDataProvider();
  const handleCheckAlreadyRegistered = useCallback(
    async (retrofits: RetrofitData[]) => {
      const { getOne } = dataProvider;

      // ログイン中企業のID取得
      const accountResult = await getOne('accounts', { id: 1 });
      const { data: accountData } = accountResult;
      const { corporationId } = accountData as AccountData;

      // 登録対象の建機のうち、チェック対象の建機のみ選定
      const targetRetrofits = retrofits.filter(
        it => it.corporationId === corporationId,
      );
      const serialNumbers = targetRetrofits.map(it => it.basicInfoSerialNumber);
      const serialNumberText = toSerialNumberText(serialNumbers);

      if (targetRetrofits.length === 0) {
        return true;
      }

      // エラーダイアログを表示
      setErrorDialog({
        open: true,
        messages: [
          translate(
            'admin.dialog.retrofitManageTab.corporationIdCheck.message1',
          ),
          translate(
            'admin.dialog.retrofitManageTab.corporationIdCheck.message2',
          ),
        ],
        subMessages: [
          translate('admin.dialog.retrofitManageTab.serialnumberCheck.message'),
          serialNumberText,
        ],
      });

      return false;
    },
    [translate, dataProvider],
  );

  // 紐付け申請の対象コントローラが『EARTHBRAIN』の企業IDに紐づいているとき
  const handleCheckEarthbrainCorporationId = useCallback(
    async (retrofits: RetrofitData[]) => {
      // EARTHBRAIN企業ID取得
      const { corporationId } = config.corporation.earthbrain;

      // 登録対象の建機のうち、チェック対象の建機のみ選定
      const targetRetrofits = retrofits.filter(
        it => it.corporationId === corporationId,
      );
      const serialNumbers = targetRetrofits.map(it => it.basicInfoSerialNumber);
      const serialNumberText = toSerialNumberText(serialNumbers);

      if (targetRetrofits.length === 0) {
        return true;
      }

      // エラーダイアログを表示
      setErrorDialog({
        open: true,
        messages: [
          translate(
            'admin.dialog.retrofitManageTab.earthbrainIdCheck.message1',
          ),
          translate(
            'admin.dialog.retrofitManageTab.earthbrainIdCheck.message2',
          ),
          translate(
            'admin.dialog.retrofitManageTab.earthbrainIdCheck.message3',
          ),
          translate(
            'admin.dialog.retrofitManageTab.earthbrainIdCheck.message4',
          ),
          translate(
            'admin.dialog.retrofitManageTab.earthbrainIdCheck.message5',
          ),
        ],
        subMessages: [
          translate('admin.dialog.retrofitManageTab.serialnumberCheck.message'),
          serialNumberText,
        ],
      });

      return false;
    },
    [translate],
  );

  const checkSiteAttachment = useCheckSiteAttachment();
  const retrofitLending = useRetrofitLending();
  const retrofitSerialNumber = useRetrofitSerialNumber();

  const handleCheckSiteAttachment = useCallback(
    async (retrofitIds: number[]): Promise<boolean> => {
      // 現場の紐づきチェック
      const {
        data: { isAttachedToSite, attachedRetrofitIds },
      } = await checkSiteAttachment({ retrofitIds });

      if (!isAttachedToSite) {
        return true; // 現場に紐づいている建機はリスト内にない
      }

      // シリアル番号を取得
      const {
        data: { serialNumbers },
      } = await retrofitSerialNumber({
        retrofitIds: attachedRetrofitIds,
      });
      const serialNumberText = toSerialNumberText(serialNumbers);

      // エラーダイアログを表示
      setErrorDialog({
        open: true,
        messages: [
          translate('admin.dialog.retrofitManageTab.siteAttached.message1'),
          translate('admin.dialog.retrofitManageTab.siteAttached.message2'),
          translate('admin.dialog.retrofitManageTab.siteAttached.message3'),
        ],
        subMessages: [
          translate('admin.dialog.retrofitManageTab.serialnumberCheck.message'),
          serialNumberText,
        ],
      });
      return false;
    },
    [checkSiteAttachment, retrofitSerialNumber, translate],
  );

  const handleCheckLending = useCallback(
    async (retrofitIds: number[]): Promise<boolean> => {
      // 貸与チェック
      const {
        data: { isLending, rentalRetrofitIds },
      } = await retrofitLending({ retrofitIds });

      if (!isLending) {
        return true; // 未貸与のレトロフィットである
      }

      // シリアル番号を取得
      const {
        data: { serialNumbers },
      } = await retrofitSerialNumber({
        retrofitIds: rentalRetrofitIds,
      });
      const serialNumberText = toSerialNumberText(serialNumbers);

      // エラーダイアログを表示
      setErrorDialog({
        open: true,
        messages: [
          translate('admin.dialog.retrofitManageTab.lendingRetrofits.message1'),
          translate('admin.dialog.retrofitManageTab.lendingRetrofits.message2'),
          translate('admin.dialog.retrofitManageTab.lendingRetrofits.message3'),
        ],
        subMessages: [
          translate('admin.dialog.retrofitManageTab.serialnumberCheck.message'),
          serialNumberText,
        ],
      });
      return false;
    },
    [retrofitLending, retrofitSerialNumber, translate],
  );

  const getOwnerCorporationIdList = useGetRetrofitOwnerRegistCorporations();
  const checkIncludesOwnerCorporationId = useIncludesOwnerCorporationId();
  const extractSerialNumbers = useExtractSerialNumbers();
  const handleCheckCorporation = useCallback(
    async (retrofits: RetrofitData[]) => {
      const ownerCorporationIds = await getOwnerCorporationIdList();
      const ownerIds = retrofits.map(({ ownerId }) => ownerId);

      // レトロフィット利用中企業登録可能企業であることをチェック
      const {
        data: { allIncluded },
      } = await checkIncludesOwnerCorporationId({
        ownerIds,
        ownerCorporationIds,
      });
      if (!allIncluded) {
        const {
          data: { serialNumbers },
        } = await extractSerialNumbers({
          retrofits,
          ownerCorporationIds,
        });
        const serialNumberText = toSerialNumberText(serialNumbers);

        // エラーダイアログを表示
        setErrorDialog({
          open: true,
          messages: [
            translate(
              'admin.dialog.retrofitManageTab.invalidSerialNumber.message1',
            ),
            translate(
              'admin.dialog.retrofitManageTab.invalidSerialNumber.message2',
            ),
            translate(
              'admin.dialog.retrofitManageTab.invalidSerialNumber.message3',
            ),
            translate(
              'admin.dialog.retrofitManageTab.invalidSerialNumber.message4',
            ),
            translate(
              'admin.dialog.retrofitManageTab.invalidSerialNumber.message5',
            ),
            translate(
              'admin.dialog.retrofitManageTab.invalidSerialNumber.message6',
            ),
            translate(
              'admin.dialog.retrofitManageTab.invalidSerialNumber.message7',
            ),
          ],
          subMessages: [
            translate(
              'admin.dialog.retrofitManageTab.serialnumberCheck.message',
            ),
            serialNumberText,
          ],
        });
      }
      return allIncluded;
    },
    [
      getOwnerCorporationIdList,
      checkIncludesOwnerCorporationId,
      extractSerialNumbers,
      translate,
    ],
  );
  const checkPendingTransfer = usePendingTransferred();

  const handlecheckPendingTransfer = useCallback(
    async (retrofitIds: number[]): Promise<boolean> => {
      // 譲渡申請中チェック
      const {
        data: { isPendingTransfer, pendingRetrofitIds },
      } = await checkPendingTransfer({ retrofitIds });

      if (!isPendingTransfer) {
        return true; // 未譲渡のレトロフィットである
      }

      // シリアル番号を取得
      const {
        data: { serialNumbers },
      } = await retrofitSerialNumber({
        retrofitIds: pendingRetrofitIds,
      });
      const serialNumberText = toSerialNumberText(serialNumbers);

      // エラーダイアログを表示
      setErrorDialog({
        open: true,
        messages: [
          translate('admin.dialog.retrofitManageTab.pendingApprovals.message1'),
          translate('admin.dialog.retrofitManageTab.pendingApprovals.message2'),
        ],
        subMessages: [
          translate('admin.dialog.retrofitManageTab.serialnumberCheck.message'),
          serialNumberText,
        ],
      });
      return false;
    },
    [checkPendingTransfer, retrofitSerialNumber, translate],
  );

  const checkPendingAlternateRegistration = usePendingAlternateRegisted();
  // 代理申請済み
  const handleCheckPendingAlternateRegistration = useCallback(
    async (retrofitIds: number[]) => {
      // 既に代理申請で登録されていないかチェック
      const {
        data: { isPendingAlternateRegisted, pendingRetrofitIds },
      } = await checkPendingAlternateRegistration({
        retrofitIds,
      });
      if (!isPendingAlternateRegisted) {
        return true;
      }
      // シリアル番号を取得
      const {
        data: { serialNumbers },
      } = await retrofitSerialNumber({
        retrofitIds: pendingRetrofitIds,
      });
      const serialNumberText = toSerialNumberText(serialNumbers);

      // エラーダイアログを表示
      setErrorDialog({
        open: true,
        messages: [
          translate('admin.dialog.retrofitManageTab.pendingApprovals.message1'),
          translate('admin.dialog.retrofitManageTab.pendingApprovals.message2'),
        ],
        subMessages: [
          translate('admin.dialog.retrofitManageTab.serialnumberCheck.message'),
          serialNumberText,
        ],
      });
      return false;
    },
    [checkPendingAlternateRegistration, retrofitSerialNumber, translate],
  );

  const checkUnusedNtipDB = useCheckUnusedNtipDB();
  const handleCheckNtripUnusedTotal = useCallback(
    async (retrofits: RetrofitData[]) => {
      if (!config.feature.ntrips) return true; // REACT_APP_FEATURE_NTRIPSが削除されたら合わせて削除する

      const {
        data: { ntripDBShortage },
      } = await checkUnusedNtipDB({ retrofits });

      if (ntripDBShortage) {
        setErrorDialog({
          open: true,
          messages: [
            translate('admin.dialog.retrofitManageTab.ntripShortage.message1'),
            translate('admin.dialog.retrofitManageTab.ntripShortage.message2'),
          ],
        });
      }
      return !ntripDBShortage;
    },
    [checkUnusedNtipDB, translate],
  );

  const loading = isLoading();

  const tabs = useCreateRetrofitManageTabs({
    ...props,
    ...{
      updateData,
      corporationName: updateData.corporationName,
      checkAlreadyRegistered: handleCheckAlreadyRegistered,
      checkEarthbrainCorporationId: handleCheckEarthbrainCorporationId,
      checkSiteAttachment: handleCheckSiteAttachment,
      checkLending: handleCheckLending,
      checkCorporation: handleCheckCorporation,
      checkPendingTransfer: handlecheckPendingTransfer,
      checkPendingAlternateRegistration:
        handleCheckPendingAlternateRegistration,
      checkNtripUnusedTotal: handleCheckNtripUnusedTotal,
    },
  });
  const [firstTab] = tabs;
  const currentTab = getValue() || firstTab.name;

  return (
    <>
      <SimpleShowLayout className={classes.simpleShowLayout} {...props}>
        <BreadcrumbsFiled
          className={classes.breadcrumbs}
          label="admin.breadcrumbs.retrofitManage"
        />
        <MuiTabs value={currentTab} onChange={handleChange}>
          {tabs.map(({ tab }) => tab)};
        </MuiTabs>
        <Divider />
        {tabs.map(({ name, content }) => {
          return (
            <TabContent name={name} key={name} value={currentTab}>
              {!loading ? content : <Loading />}
            </TabContent>
          );
        })}
      </SimpleShowLayout>
      <DialogOKOnlyWithUrl
        open={errorDialog.open}
        onClose={() => {
          setErrorDialog({
            ...errorDialog,
            open: false,
          });
        }}
        title=""
        nonTranslateMessage={true}
        messages={errorDialog.messages}
        url={scSupportRequestUrl}
        subMessages={errorDialog.subMessages}
      />
    </>
  );
};

RetrofitManageTab.displayName = 'RetrofitManageTab';
export default RetrofitManageTab;
