import React from 'react';
import { FormDataConsumer, required } from 'react-admin';
import { ReferenceInput, SelectInput } from '..';

const RegionSelectInput: React.FC = (props: any) => {
  const { form, resource, coordinateType } = props;
  const onChangeEvent = () => {
    if (!form) return;
    form.change('projectionId', null);
    form.change('datumId', null);
    form.change('geoidId', null);
    form.change('retrofitId', null);
  };

  return (
    <FormDataConsumer>
      {({ formData }) =>
        formData.coordinate === coordinateType && (
          <ReferenceInput
            onChange={onChangeEvent}
            resource={resource}
            source="regionId"
            reference="regions"
          >
            <SelectInput optionText="name" validate={required()} />
          </ReferenceInput>
        )
      }
    </FormDataConsumer>
  );
};

RegionSelectInput.displayName = 'RegionSelectInput';
export default RegionSelectInput;
