import { encode } from 'querystring';
import { config } from '../../utils';
import { callbackUri } from '.';

export default () => {
  const { realUri, clientId, scope } = config.auth.landlog;
  const query = encode({
    client_id: clientId,
    scope,
    response_type: 'code',
    redirect_uri: callbackUri(),
  });
  window.location.href = `${realUri}/connect/authorize?${query}`;
};
