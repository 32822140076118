import { loginEmailStore } from '../utils';

const useLoginEmail = () => {
  const email = loginEmailStore.get();
  return {
    email,
    setEmail: (newEmail: string) => loginEmailStore.set(newEmail),
  };
};

export default useLoginEmail;
