import { FC, PropsWithChildren, ReactNode, ReactElement } from 'react';
import {
  FunctionField as RaFunctionField,
  FieldProps,
  InjectedFieldProps,
} from 'react-admin';
import { TypographyProps } from '@material-ui/core/Typography';
import { ResourceProps, Record, ResourceName } from '.';

interface FunctionFieldFC<P> extends FC<P> {
  <T extends ResourceName>(
    props: PropsWithChildren<
      FieldProps &
        InjectedFieldProps &
        P &
        ResourceProps<T> & {
          render: (
            record?: Record<T>,
            source?: keyof Record<T>,
          ) => ReactNode | null;
        }
    >,
    context?: any,
  ): ReactElement | null;
}

const FunctionField = RaFunctionField as FunctionFieldFC<TypographyProps>;
FunctionField.displayName = 'FunctionField';

export default FunctionField;
