import { config } from '../../utils';
import { LandlogSites } from '.';

const fetchJson = (url: string) => {
  const requestHeaders = new Headers({
    Accept: 'application/json',
  });

  return fetch(url, { headers: requestHeaders })
    .then(response =>
      response.text().then(text => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      })),
    )
    .then(({ status, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        console.log(`fetched error: status ${status}`);
      }
      return Promise.resolve({ status, headers, body, json });
    });
};

const uri = `${config.api.landlog.apiUri}/v2/sites`;
export default async (id: string): Promise<LandlogSites> => {
  const { json } = await fetchJson(`${uri}/${id}`);

  return json;
};
