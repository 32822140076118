import { CompactionWorkAreaData } from '../../types';

const compactionWorkAreas: CompactionWorkAreaData[] = [
  {
    id: 1,
    projectId: 11,
    name: 'プロジェクト1.1-転圧施工領域１',
    blockSize: 0.1,
    description: '',
    enable: true,
  },
  {
    id: 2,
    projectId: 12,
    name: 'プロジェクト1.2-転圧施工領域１',
    blockSize: 0.1,
    description: 'プロジェクト内に施工領域2つ(１)',
    enable: true,
  },
  {
    id: 3,
    projectId: 12,
    name: 'プロジェクト1.2-転圧施工領域２',
    blockSize: 0.1,
    description: 'プロジェクト内に施工領域2つ(２)',
    enable: true,
  },
  {
    id: 4,
    projectId: 13,
    name: 'プロジェクト1.3-転圧施工領域１',
    blockSize: 0.1,
    description: 'ブロックサイズ=0.1',
    enable: true,
  },
  {
    id: 5,
    projectId: 13,
    name: 'プロジェクト1.3-転圧施工領域２',
    blockSize: 0.25,
    description: 'ブロックサイズ=0.25',
    enable: true,
  },
  {
    id: 6,
    projectId: 13,
    name: 'プロジェクト1.3-転圧施工領域３',
    blockSize: 0.5,
    description: 'ブロックサイズ=0.5',
    enable: true,
  },
  {
    id: 7,
    projectId: 14,
    name: 'プロジェクト1.4-転圧施工領域１',
    blockSize: 0.1,
    description: 'プロジェクト内に施工領域4つ(１)',
    enable: true,
  },
  {
    id: 8,
    projectId: 14,
    name: 'プロジェクト1.4-転圧施工領域２',
    blockSize: 0.25,
    description: 'プロジェクト内に施工領域4つ(２)',
    enable: true,
  },
  {
    id: 9,
    projectId: 14,
    name: 'プロジェクト1.4-転圧施工領域３',
    blockSize: 0.5,
    description: 'プロジェクト内に施工領域4つ(３)',
    enable: true,
  },
  {
    id: 10,
    projectId: 14,
    name: 'プロジェクト1.4-転圧施工領域４',
    blockSize: 0.5,
    description: 'プロジェクト内に施工領域4つ(４)',
    enable: true,
  },
  {
    id: 11,
    projectId: 15,
    name: 'プロジェクト1.5　！”＃＄％＆’（）＝～｜｀｛＋＊｝＜＞？＿ー＾￥＠「；：」、。・￥',
    blockSize: 0.5,
    description:
      '！”＃＄％＆’（）＝～｜｀｛＋＊｝＜＞？＿ー＾￥＠「；：」、。・￥',
    enable: true,
  },
  {
    id: 12,
    projectId: 21,
    name: 'プロジェクト2.1-転圧施工領域１',
    blockSize: 0.5,
    description: '',
    enable: true,
  },
  {
    id: 13,
    projectId: 22,
    name: 'プロジェクト2.2-転圧施工領域１',
    blockSize: 0.25,
    description: '',
    enable: true,
  },
  {
    id: 14,
    projectId: 22,
    name: 'プロジェクト2.2-転圧施工領域２',
    blockSize: 0.5,
    description: '削除済み',
    enable: false,
  },
  {
    id: 15,
    projectId: 23,
    name: 'プロジェクト2.3-転圧施工領域１',
    blockSize: 0.1,
    description: '全施工領域削除済み１',
    enable: false,
  },
  {
    id: 16,
    projectId: 23,
    name: 'プロジェクト2.3-転圧施工領域２',
    blockSize: 0.25,
    description: '全施工領域削除済み２',
    enable: false,
  },
  {
    id: 17,
    projectId: 23,
    name: 'プロジェクト2.3-転圧施工領域３',
    blockSize: 0.5,
    description: '全施工領域削除済み３',
    enable: false,
  },
  {
    id: 18,
    projectId: 24,
    name: 'プロジェクト2.4-転圧施工領域１',
    blockSize: 0.25,
    description: '削除済み',
    enable: false,
  },
  {
    id: 19,
    projectId: 24,
    name: 'プロジェクト2.4-転圧施工領域２',
    blockSize: 0.1,
    description: '',
    enable: true,
  },
  {
    id: 20,
    projectId: 24,
    name: 'プロジェクト2.4-転圧施工領域３',
    blockSize: 0.25,
    description: '',
    enable: true,
  },
  {
    id: 21,
    projectId: 24,
    name: 'プロジェクト2.4-転圧施工領域４',
    blockSize: 0.5,
    description: '',
    enable: true,
  },
  {
    id: 22,
    projectId: 25,
    name: 'プロジェクト2.5 !"#$%&\'()=~|`{+*}<>?_-^\\@[;:],./\\',
    blockSize: 0.1,
    description: '!"#$%&\'()=~|`{+*}<>?_-^\\@[;:],./\\',
    enable: true,
  },
  {
    id: 23,
    projectId: 2,
    name: 'case 1',
    blockSize: 0.5,
    description: 'ブロックサイズは 0.5m は ft 単位で表示される',
    enable: true,
  },
  {
    id: 24,
    projectId: 2,
    name: 'case 2',
    blockSize: 0.25,
    description: 'ブロックサイズは 0.25m は ft 単位で表示される',
    enable: true,
  },
  {
    id: 25,
    projectId: 2,
    name: 'case 3',
    blockSize: 0.1,
    description: 'ブロックサイズは 0.1m は ft 単位で表示される',
    enable: true,
  },
];

export default compactionWorkAreas;
