import { SelectField as RaSelectField, FieldProps } from 'react-admin';
import { ReactComponentLike } from 'prop-types';
import { ResourceFieldFC } from '.';

interface Props extends FieldProps {
  choices: object[];
  optionText?: string | Function | ReactComponentLike;
  optionValue?: string;
  translateChoice?: boolean;
}
const SelectField = RaSelectField as ResourceFieldFC<Props>;
SelectField.displayName = 'SelectField';

export default SelectField;
