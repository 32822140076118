import {
  RetrofitCorporationLicenseData,
  LicensePermissionType,
} from '../../../../dataProvider';

interface ConfirmInfo {
  isError: boolean;
  isDeleteInsert: boolean;
  message: string;
  usingLicenseData: RetrofitCorporationLicenseData[];
  isCompactDialog?: boolean;
}

export default (
  data: RetrofitCorporationLicenseData[],
  settingLicensePermission: LicensePermissionType,
): ConfirmInfo => {
  const nomalIndividualLicense: LicensePermissionType[] = [
    'MachineGuidance',
    'MachineGuidance2D',
    'PayloadMeter',
    'Asbuilt',
  ];

  if (data.length <= 0) {
    // 紐付いていないケース
    return {
      isError: false,
      isDeleteInsert: false,
      message: 'admin.dialog.retrofitCorporationLicenses.confirm.message',
      usingLicenseData: data,
    };
  }
  // ------------------------------
  // 他の建機と紐付いているケース
  // ------------------------------

  // TODO: サーバ側処理の仕様に変更があったら以下の追加条件をAND条件として追加する
  // 追加条件：it.licenseStatus === 'Enable'
  const enableLicense = data.filter(it => it.enable === true);
  const beforeLicense = enableLicense.map(it => it.licensePermission);
  const beforeNormalIndividual = beforeLicense.some(it =>
    nomalIndividualLicense.includes(it),
  ); // 元の建機に1件でも通常ライセンスが紐づいていたらtrueを返す

  // 紐づいているライセンスがUnlimited ※北米ライセンスは他ライセンスとは共存しない
  const unlimitedLicense = enableLicense.filter(
    it =>
      it.licensePermission === 'Unlimited' ||
      it.licensePermission === 'FlexUnlimited',
  );
  if (unlimitedLicense.length > 0) {
    // 北米ライセンス付与済みのためエラーダイアログ
    return {
      isError: true,
      isDeleteInsert: false,
      message:
        'admin.dialog.retrofitCorporationLicenses.errorUnlimited.message',
      usingLicenseData: [],
      isCompactDialog: true,
    };
  }

  // 紐づいているライセンスは期限切れのもののみ
  if (enableLicense.length === 0) {
    // 期限切れ付け替え確認ダイアログ ※メッセージは紐づいていないケースと同様
    const disableLicense = data.filter(
      it => it.enable === false || it.licenseStatus === 'Disable',
    );
    // 消費期限切れライセンスの中でもTemporaryは非表示のため除外
    const showDisableLicense = disableLicense.filter(
      it => it.licensePermission !== 'Temporary',
    );

    return {
      isError: false,
      isDeleteInsert: true,
      message: 'admin.dialog.retrofitCorporationLicenses.confirm.message',
      usingLicenseData: showDisableLicense,
    };
  }

  // 紐づいているライセンスがTemporary ※一時ライセンスは他ライセンスとは共存しない
  const temporaryLicense = enableLicense.filter(
    it => it.licensePermission === 'Temporary',
  );
  if (temporaryLicense.length > 0) {
    // 一時ライセンスから付け替えダイアログ ※Temporaryの場合、付け替え元は表示しない
    return {
      isError: false,
      isDeleteInsert: true,
      message:
        'admin.dialog.retrofitCorporationLicenses.changeTemporary.message',
      usingLicenseData: [],
    };
  }

  // ------------------------------
  // 紐づいているライセンスがAllまたはAllが含まれている
  // ------------------------------
  const allLicense = enableLicense.filter(it => it.licensePermission === 'All');
  if (allLicense.length > 0) {
    // Allが既に紐づいている場合、変更可能（追加はNG ※対応未定）
    return {
      isError: false,
      isDeleteInsert: false,
      message: 'admin.dialog.retrofitCorporationLicenses.compare.message',
      usingLicenseData: enableLicense,
    };
  }

  // ------------------------------
  // 紐づいているライセンスは個別ライセンスのみ
  // かつ 同種のAllライセンス紐づけ操作
  // ------------------------------

  // Allライセンス紐づけ確認ダイアログ
  if (beforeNormalIndividual && settingLicensePermission === 'All') {
    return {
      isError: false,
      isDeleteInsert: false,
      message: 'admin.dialog.retrofitCorporationLicenses.addAll.message',
      usingLicenseData: enableLicense,
    };
  }

  // 同一ライセンスが紐づいていても変更は可能（追加はNG ※対応未定）
  // 個別ライセンス付け替え確認ダイアログ
  return {
    isError: false,
    isDeleteInsert: false,
    message: 'admin.dialog.retrofitCorporationLicenses.compare.message',
    usingLicenseData: enableLicense,
  };
};
