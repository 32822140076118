export interface FormState {
  disableRetrofitDistributor: boolean;
  disableCorporation: boolean;
  disableRetrofitRegion: boolean;
  checkCount: number;
}

export const initialFormState: FormState = {
  disableRetrofitDistributor: true,
  disableCorporation: true,
  disableRetrofitRegion: true,
  checkCount: 0,
};
