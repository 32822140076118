import { useMemo } from 'react';
import { Map, View, Feature } from 'ol';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { OSM, Vector as SourceVector } from 'ol/source';
import Point from 'ol/geom/Point';
import { transform, fromLonLat } from 'ol/proj';
import { Style, Icon } from 'ol/style';
import { defaults } from 'ol/interaction';
import { toSize } from 'ol/size';
import 'ol/ol.css';
import { Attribution } from 'ol/control';

import { AnyObject } from '../utils';

export type MachineInfo = AnyObject & {
  longitude: number;
  latitude: number;
  machineName: string;
  isOnline: boolean;
};

const useSiteRetrofitMap = (
  positions: any,
  defaultView: any,
  iconSelector: any,
  sitePosition: any,
) => {
  return useMemo(() => {
    const fixedPositions: MachineInfo[] = positions.reduce(
      (p: MachineInfo[], c: any) => [
        ...p,
        ...(() => {
          if (
            c.latitude &&
            c.longitude &&
            c.latitude >= -90 &&
            c.latitude <= 90 &&
            c.longitude >= -180 &&
            c.longitude <= 180
          )
            return [c];
          return [];
        })(),
      ],
      [],
    );

    const fixedDefaultZoom = defaultView?.zoom || 1;
    const isAutoZoom = ![
      fixedPositions.length < 1,
      !!defaultView,
      defaultView && defaultView.zoom,
    ].includes(true);

    const icons: Feature[] = fixedPositions.map((data: MachineInfo) => {
      const icon = new Feature({
        geometry: new Point(fromLonLat([data.longitude, data.latitude])),
        name: data.machineName,
      });

      const image = document.createElement('img');
      image.src = iconSelector ? iconSelector(data) : '';

      icon.setStyle(
        new Style({
          image: new Icon({
            crossOrigin: 'anonymous',
            img: image,
            imgSize: toSize([18, 17]),
          }),
        }),
      );

      return icon;
    });

    const openStreetMapLayer = new TileLayer({
      source: new OSM(),
    });
    const vecSrc = new SourceVector({
      features: icons,
    });
    const vectorLayer = new VectorLayer({
      source: vecSrc,
    });

    const centerPosition =
      fixedPositions.length !== 0
        ? [
            defaultView && defaultView.longitude
              ? defaultView.longitude
              : fixedPositions.reduce((p, c) => p + c.longitude, 0) /
                fixedPositions.length,
            defaultView && defaultView.latitude
              ? defaultView.latitude
              : fixedPositions.reduce((p, c) => p + c.latitude, 0) /
                fixedPositions.length,
          ]
        : [sitePosition.longitude, sitePosition.latitude];

    // クレジット表記
    const attribution = new Attribution({
      collapsible: false,
    });

    const map = new Map({
      controls: [attribution],
      target: undefined,
      layers: [openStreetMapLayer, vectorLayer],
      interactions: defaults({
        altShiftDragRotate: false,
        doubleClickZoom: true,
        keyboard: false,
        mouseWheelZoom: true,
        shiftDragZoom: true,
        dragPan: true,
        pinchRotate: false,
        pinchZoom: true,
      }),
      view: new View({
        center: transform(centerPosition, 'EPSG:4326', 'EPSG:3857'),
        zoom: isAutoZoom ? 1 : fixedDefaultZoom,
        // 拡大の最大率
        minResolution: 0.5,
      }),
    });

    if (isAutoZoom) {
      map
        .getView()
        .fit(vectorLayer.getSource().getExtent(), { size: map.getSize() });
    }
    return map;
  }, [positions, defaultView, iconSelector, sitePosition]);
};

export default useSiteRetrofitMap;
