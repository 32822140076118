import { ReferenceArrayInput, InputProps } from 'react-admin';
import { ReferenceFC } from '../field';

interface Props {
  allowEmpty: boolean;
  basePath: string;
  className: string;
  label: string;
  perPage: number;
}

const { defaultProps } = ReferenceArrayInput;
ReferenceArrayInput.defaultProps = {
  ...defaultProps,
  perPage: 100,
  sort: { field: 'name', order: 'ASC' },
};

ReferenceArrayInput.displayName = 'ReferenceArrayInput';
export default ReferenceArrayInput as ReferenceFC<Partial<InputProps & Props>>;
