import { useState, useEffect, useMemo } from 'react';
import { useDataProvider } from 'react-admin';
import { SiteData, SiteConfigureData, UnitLength } from '../../dataProvider';
import { logger } from '../../utils';

interface InParams {
  siteId?: string;
}

interface OutParams {
  loading: boolean;
  data: {
    unitLength: UnitLength;
  };
}

const useGetUnitLength = ({ siteId = '' }: InParams): OutParams => {
  const dataProvider = useDataProvider();
  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data: {
      unitLength: UnitLength;
    };
  }>({
    loading: true,
    fetched: false,
    data: {
      unitLength: 'M',
    },
  });

  const api = useMemo(
    () => async (): Promise<UnitLength> => {
      if (siteId === '') return 'M';
      // 現場設定を取得
      const { data: siteConfigures } =
        await dataProvider.getList<SiteConfigureData>('siteConfigures', {
          pagination: {
            page: 1,
            perPage: 1,
          },
          sort: { field: 'id', order: 'ASC' },
          filter: {
            siteId,
          },
        });
      if (siteConfigures.length > 0) {
        const { unitLength: siteConfigureUnitLength } = siteConfigures[0];
        return siteConfigureUnitLength;
      }
      // 現場を取得
      const {
        data: { unitLength: siteUnitLength },
      } = await dataProvider.getOne<SiteData>('sites', {
        id: siteId,
      });
      return siteUnitLength;
    },

    [dataProvider, siteId],
  );

  useEffect(() => {
    if (apiState.fetched) return;
    logger().debug('fetchUnitLength ==>');
    api()
      .then(unitLength => {
        logger().debug(`fetchUnitLength <== '${unitLength}'`);
        setApiState({
          ...apiState,
          loading: false,
          fetched: true,
          data: { unitLength },
        });
      })
      .catch(e => {
        logger().error(`fetchUnitLength <== ${e}`);
        setApiState({
          ...apiState,
          loading: false,
          fetched: true,
        });
      });
  }, [apiState, api]);

  return {
    loading: apiState.loading,
    data: apiState.data,
  };
};

export default useGetUnitLength;
