const hasUserRole = () => {
  return (roleName: string, userRoles: string[]): boolean => {
    if (userRoles.includes(roleName)) {
      return true;
    }
    return false;
  };
};

export default hasUserRole;
