import { AssignmentData } from '../../types';

const assignments: AssignmentData[] = [
  {
    id: 1,
    retrofitId: 1,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: '株式会社クオリカ建築　テスト事業部',
    toCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    toCorporationName: 'クオリカ',
    toMailAddress: 'aaa@mail.com',
    isApproved: false,
    planDate: '2022-04-01T01:02:03.400Z',
    actualDate: '2022-04-02T01:02:03.400Z',
    lastUpdated: '2022-04-02T01:02:03.400Z',
  },
  {
    id: 2,
    retrofitId: 2,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: '株式会社クオリカ建築　テスト事業部',
    toCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    toCorporationName: 'クオリカ',
    toMailAddress: 'bbb@mail.com',
    isApproved: false,
    planDate: '2022-04-02T01:02:03.400Z',
    actualDate: '2022-04-03T01:02:03.400Z',
    lastUpdated: '2022-04-03T01:02:03.400Z',
  },
  {
    id: 3,
    retrofitId: 6,
    fromCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    fromCorporationName: 'LANDLOG',
    toCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    toCorporationName: '株式会社クオリカ建築　テスト事業部',
    toMailAddress: 'test_user@example.com',
    isApproved: false,
    planDate: '2022-04-02T01:02:03.400Z',
    actualDate: '2022-04-03T01:02:03.400Z',
    lastUpdated: '2022-04-03T01:02:03.400Z',
  },
  {
    id: 4,
    retrofitId: 7,
    fromCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    fromCorporationName: 'LANDLOG',
    toCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    toCorporationName: '株式会社クオリカ建築　テスト事業部',
    toMailAddress: 'ddd@mail.com',
    isApproved: true,
    planDate: '2022-04-02T01:02:03.400Z',
    actualDate: '2022-04-03T01:02:03.400Z',
    lastUpdated: '2022-04-03T01:02:03.400Z',
  },
];

export default assignments;
