import React, { useEffect } from 'react';
import {
  Box,
  createStyles,
  Drawer,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { ReduxState, setSidebarVisibility, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import UserNoticeDrawerBody from './UserNoticeDrawerBody';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 400,
    },
    header: {
      position: 'sticky',
      top: 0,
      zIndex: 2, // お知らせ一覧で表示しているアイコン(SVG)がzIndex:1である為、それより上にする必要がある
      backgroundColor: theme.palette.background.default,
    },
  }),
);

const useIconStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 0,
    },
  }),
);

const UserNoticeDrawerList: React.FC = () => {
  const classes = useStyles();
  const iconClasses = useIconStyles();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);
  const handleClose = () => {
    dispatch(setSidebarVisibility(false));
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{
        variant: 'outlined',
        square: false,
        className: classes.root,
      }}
    >
      <Box>
        <Box p={1} display="flex" className={classes.header}>
          {translate('admin.pages.userNoticeDrawer')}
          <Box flexGrow={1} display="flex" justifyContent="flex-end">
            <IconButton
              aria-label="close"
              classes={iconClasses}
              onClick={handleClose}
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
        <UserNoticeDrawerBody />
        {/* 一番下のレコードが切れないように余白を出力 */}
        <Box height={60} />
      </Box>
    </Drawer>
  );
};

UserNoticeDrawerList.displayName = 'UserNoticeDrawerList';
export default UserNoticeDrawerList;
