import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';

const ContentHeader: React.FC<{
  title?: string;
  className?: string;
  icon?: React.SVGAttributes<SVGElement>;
  right?: React.ReactElement<any>;
}> = ({ title = '', className, icon, right }) => {
  const translate = useTranslate();
  return (
    <>
      <div className="icon">{icon}</div>
      <Typography variant="h2" color="inherit" className={className}>
        {translate(title, { _: title })}
      </Typography>
      <div className="right">{right}</div>
    </>
  );
};

ContentHeader.displayName = 'ContentHeader';
export default ContentHeader;
