/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
// import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Record,
  // useUpdate,
  // useRedirect,
  // useNotify,
  // useDataProvider,
} from 'react-admin';
import { CustomSaveButton } from '../../../..';
// import { CustomSaveButton, DialogOKOnly } from '../../../..';
import {
  RetrofitData,
  AssignmentData,
  // CorporationData,
} from '../../../../../dataProvider';
// import { useGetAccount, useResource } from '../../../../../hooks';
import { SaveIcon } from '../../../../../assets';
// import { getParentPath } from '../../../../../utils';
// import { useLicense, useRentaled } from '../hooks';
// import { useGetRetrofitsSiteId } from '../../info/hooks';

interface Props {
  basePath?: string;
  record?: Record;
  retrofitdata: RetrofitData;
  label?: string;
  assignments?: AssignmentData;
}

const SaveButton: FC<Props> = (props: any) => {
  // const redirectTo = useRedirect();
  // const notify = useNotify();

  // const retrofitId = props.retrofitdata.id;
  // const { getAccount } = useGetAccount();
  // const accountData = getAccount();
  // const corporations = useResource('corporations');

  // const [fetched, setFetched] = useState(false);
  // const [corporationId, setCorporationId] = useState('');
  // const [corporationName, setCorporationName] = useState('');
  // const dataProvider = useDataProvider();
  // const accountDataCorporationId = accountData?.corporationId || '';

  // const getSiteId = useGetRetrofitsSiteId();
  // const getRentaled = useRentaled();
  // const getLicense = useLicense();

  // const [dialogState, setDialogState] = useState<{
  //   open: boolean;
  //   message?: string;
  // }>({ open: false });
  // const { record, basePath = '' } = props;
  // const to = getParentPath(basePath);
  // const [update] = useUpdate('assignments', record.id, undefined, record);

  // const handleSave = useCallback(
  //   values => {
  //     const api = async () => {
  //       const { data } = await getSiteId({ retrofitId });
  //       const isRentaled = await getRentaled(retrofitId);
  //       const isLicense = await getLicense(retrofitId);
  //       return { siteId: data.siteId, isRentaled, isLicense };
  //     };
  //     api().then(result => {
  //       // 建機に現場が紐付いてる場合
  //       if (result.siteId) {
  //         setDialogState({
  //           open: true,
  //           message: 'admin.dialog.retrofitTransferCreate.messageSiteId',
  //         });
  //         return;
  //       }
  //       // 建機が貸与中の場合
  //       if (result.isRentaled) {
  //         setDialogState({
  //           open: true,
  //           message: 'admin.dialog.retrofitTransferCreate.messageRentaled',
  //         });
  //         return;
  //       }
  //       // 建機にライセンスが紐づいている場合
  //       if (result.isLicense) {
  //         setDialogState({
  //           open: true,
  //           message: 'admin.dialog.retrofitTransferCreate.messageLicense',
  //         });
  //       }
  //       // update(
  //       //   {
  //       //     payload: {
  //       //       data: {
  //       //         ...values,
  //       //       },
  //       //     },
  //       //   },
  //       //   {
  //       //     onSuccess: () => {
  //       //       notify('admin.message.success', 'info');
  //       //       redirectTo(to);
  //       //     },
  //       //   },
  //       // );
  //     });
  //   },
  //   [
  //     getSiteId,
  //     getRentaled,
  //     getLicense,
  //     update,
  //     notify,
  //     redirectTo,
  //     retrofitId,
  //     to,
  //   ],
  // );

  // useEffect(() => {
  //   if (accountDataCorporationId === '' || fetched) return;
  //   dataProvider
  //     .getOne(corporations, { id: accountDataCorporationId })
  //     .then((result: any) => {
  //       // if (!result) return;
  //       const corporationData = result.data as CorporationData;
  //       setCorporationId(corporationData?.id);
  //       setCorporationName(corporationData?.name);
  //       setFetched(true);
  //     });
  // }, [dataProvider, corporations, accountDataCorporationId, fetched]);

  // if (!accountData || !corporationId || !corporationName) {
  return (
    <CustomSaveButton {...props} disabled={true}>
      <SaveIcon />
    </CustomSaveButton>
  );
  // }

  //   return (
  //     <>
  //       <DialogOKOnly
  //         open={dialogState.open}
  //         onClose={() => setDialogState({ open: false })}
  //         title=""
  //         message={dialogState?.message}
  //       />
  //       <CustomSaveButton {...props} onSave={handleSave}>
  //         <SaveIcon />
  //       </CustomSaveButton>
  //     </>
  //   );
};

SaveButton.displayName = 'SaveButton';
export default SaveButton;
