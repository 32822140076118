import React from 'react';
import { MapProps, crearteOverlayControl } from '../../../../../hooks';
import { MapOperationButton } from '../../../..';

const addEditControl = (
  { map }: MapProps,
  defaultState: 'draw' | 'move',
  onClickDraw: () => void,
  onClickSelect: () => void,
  onClickInput: () => void,
) => {
  map.addControl(
    crearteOverlayControl(
      'map_opr',
      <MapOperationButton
        defaultState={defaultState}
        onClickInput={onClickInput}
        onClickDraw={onClickDraw}
        onClickSelect={onClickSelect}
      />,
      {
        left: 11,
        top: 350,
      },
    ),
  );
};

export default addEditControl;
