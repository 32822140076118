import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  Record,
  Identifier,
  useUpdateMany,
  useRedirect,
  useNotify,
  useTranslate,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import { Button, DialogOKCancel } from '..';
import { colors } from '../../theme';
import { apiErrorHandler } from '../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: colors.button.delete,
      color: colors.text.primaryLight,
      minWidth: 160,
      minHeight: 36,
      textTransform: 'none',
      '&:hover': {
        background: colors.button.deleteHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.deleteDisabled,
      },
    },
  }),
);

interface Props {
  basePath: string;
  record: Record;
  selectedIds: Identifier[];
  resource: string;
  undoable: boolean;
  disabled: boolean;
}

const DeleteFromSiteButton: React.FC<Partial<Props>> = ({
  basePath = '',
  selectedIds = [0],
  record = { id: 0 },
  resource = '',
  undoable = false,
  disabled = false,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const redirectTo = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();
  const translate = useTranslate();
  const { id } = record;
  const ids: Identifier[] = id !== 0 ? [id] : selectedIds;
  const content = translate('admin.message.disconnectFromSite');
  const [deleteFromSite] = useUpdateMany(
    resource,
    ids,
    { siteId: '' },
    {
      undoable,
      onSuccess: () => {
        if (id > 0) redirectTo('list', basePath);
        if (selectedIds.length > 0) {
          refresh();
          unselectAll(resource);
        }
        notify(
          'ra.notification.deleted',
          'info',
          {
            smart_count: 1,
          },
          undoable,
        );
      },
      onFailure: error => {
        return notify(apiErrorHandler(error), 'warning');
      },
    },
  );
  return (
    <>
      <DialogOKCancel
        open={open}
        title=""
        message={content}
        nonTranslateMessage={true}
        onClose={() => setOpen(false)}
        onOKClick={deleteFromSite}
      />
      <Button
        className={classes.root}
        label="admin.actions.deleteFromSite"
        data-testid="admin.actions.deleteFromSite"
        onClick={() => setOpen(true)}
        disabled={disabled}
      />
    </>
  );
};

DeleteFromSiteButton.displayName = 'DeleteFromSiteButton';
export default DeleteFromSiteButton;
