import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDataProvider, required } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import {
  Create,
  CustomForm,
  Loading,
  BreadcrumbsFiled,
  TextInput,
  PropsConsumer,
} from '../..';
import { GeofenceMapInput, SaveButton } from './view';
import {
  useGeofenceProps,
  useMapController,
  useConvertMapCoordinate,
} from './hooks';
import { GeofenceData } from '../../../dataProvider';
import { useResult } from '../../../hooks';
import { getGeometriesArray } from './utils';
import { LonLat } from '../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& > div .ra-input-mapInput > div > label': {
        display: 'none',
      },
    },
  }),
);

const GeofenceCreate: FC = () => {
  const classes = useStyles();
  const {
    props,
    loading,
    sites,
    unitLength,
    geofences,
    corporationId,
    mapBackgroundColor,
  } = useGeofenceProps();
  const result = useResult();
  const { mapController } = useMapController();
  const dataProvider = useDataProvider();
  const convertMapCoordinate = useConvertMapCoordinate();
  const { siteId } = useParams<{ siteId: string }>();
  const depsGeofences = JSON.stringify(geofences);

  // saveButtonInvalidがtrueの時、保存ボタンは非活性状態
  const [saveButtonInvalid, setSaveButtonInvalid] = useState(true);
  const handleModify = useCallback(() => {
    const validation = mapController.getDisableSaveButton();
    setSaveButtonInvalid(validation);
  }, [mapController]);

  useEffect(() => {
    // Map上コンポーネントのデフォルト値をあらかじめ設定しておく
    mapController.setSiteUnitLength(unitLength);
    mapController.setSelectedType('Polygon');
    mapController.setSelectedDimension('ThreeDimension');
    mapController.setSelectedColor({
      r: 255,
      g: 140,
      b: 17,
      a: 1,
    });
    mapController.setSelectedValid(true);
    mapController.setSelectedTransparency(true);
    mapController.setSelectedAlertype('Notice');
    // Map上の入力コンポーネントをrender
    mapController.renderInputComponent(mapController.getMapProps());

    // その他のジオフェンス情報が存在しない場合は後続処理は実施しない
    if (!depsGeofences || depsGeofences === '[]') return;
    const api = async () => {
      const rawGeofences: GeofenceData[] = JSON.parse(depsGeofences);
      const lonLatId = rawGeofences
        .filter(geofence => geofence.points !== undefined)
        .map(
          geofence =>
            ({
              id: geofence.id,
              coordinates: geofence.points?.map(lonLats => ({
                lon: lonLats[0],
                lat: lonLats[1],
              })),
            } as { id: number; coordinates: LonLat[] }),
        );
      // ジオメトリ配列に変換
      const { geometries, boundaryLonLat } = getGeometriesArray(
        lonLatId,
        rawGeofences,
        0,
      );
      // setGeometriesの前に全ジオフェンスオブジェクトのtypeのdictionaryを作成
      geometries.forEach(it => {
        mapController.getGeofenceTypeList().set(it.id, it.type);
      });
      // マップにジオフェンス情報を反映
      mapController.setGeometries(geometries);

      // ジオフェンス情報が存在する場合は、boudary を自動調整
      if (boundaryLonLat) {
        mapController.setBoundary(boundaryLonLat);
        mapController.navigateToHome(0);
      }
    };
    api();
  }, [depsGeofences, convertMapCoordinate, mapController, unitLength]);

  if (loading || !sites) return <Loading />;

  const {
    record: { name: siteName },
  } = sites;

  const handleSave = async () => {
    if (!mapController) {
      return Promise.reject(new Error(`Invalid mapController`));
    }
    const [geometry] = mapController
      .getGeometries()
      .filter(({ id }) => id === 0); // 作成画面では id=0 でジオメトリを参照する
    if (!geometry) {
      return Promise.reject(new Error(`Invalid geometry`));
    }

    return {
      points: geometry.coordinates.map(ll => [ll.lon, ll.lat]),
      type: geometry.type,
      radius: geometry.radius,
    };
  };

  const handleSubmit = async (data: GeofenceData) => {
    try {
      await dataProvider.create('geofences', { data });
      result.success(props.basePath);
    } catch (e) {
      result.failure(e);
    }
  };

  return (
    <Create {...props}>
      <CustomForm
        className={classes.root}
        title="admin.pages.geofenceCreate"
        saveButton={
          <PropsConsumer>
            {formProps => {
              const { pristine, invalid, form, record } = formProps;
              // 入力欄に空欄がある場合、または登録内容に変更がない場合は保存ボタンは非活性
              const saveButtonDisabled = !!(
                pristine ||
                invalid ||
                saveButtonInvalid
              );
              return (
                <SaveButton
                  mapController={mapController}
                  onClick={handleSave}
                  onSubmit={handleSubmit}
                  disabled={saveButtonDisabled}
                  form={form}
                  record={record}
                />
              );
            }}
          </PropsConsumer>
        }
        initialValues={{
          corporationId,
          siteId,
          type: 'Polygon',
          trigger: undefined,
          alertType: 'Notice',
          thickness: undefined, // TODO: thickness のフィールドが正式実装されたらこの行を消す（type: Wall時に設定する値）
          radius: undefined, // TODO: radius のフィールドが正式実装されたらこの行を消す (type: Polygon, Circleかつdimensionが3Dに設定する値)
          uploadedBy: 'Admin',
        }}
      >
        <BreadcrumbsFiled
          breadcrumbs={[
            'resources.sites.name',
            '',
            siteName,
            'resources.geofences.name',
          ]}
          label="admin.breadcrumbs.geofenceCreate"
        />
        <TextInput resource="geofences" source="name" validate={[required()]} />
        <GeofenceMapInput
          source="mapInput"
          siteId={siteId}
          mapController={mapController}
          mapBackgroundColor={mapBackgroundColor}
          visible={true}
          onModify={handleModify}
        />
      </CustomForm>
    </Create>
  );
};

GeofenceCreate.displayName = 'GeofenceCreate';
export default GeofenceCreate;
