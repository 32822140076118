import React, { FC, useState } from 'react';
import { useLoading } from 'react-admin';
import { DialogOKOnly, DoneButton } from '../../..';
import { OkIcon } from '../../../../assets';
import { GeofenceData } from '../../../../dataProvider';
import { GeometryType, LonLat, logger } from '../../../../utils';
import { MapController } from '../map';
import { useCoordinatesCheck } from '../hooks';

interface MapValue {
  points: number[][];
  type: GeometryType;
  radius?: number;
}

interface Props {
  disabled: boolean;
  form: any;
  record: GeofenceData;
  mapController: MapController;
  onClick: () => Promise<MapValue>;
  onSubmit: (data: GeofenceData, prevData?: GeofenceData) => Promise<void>;
}

const SaveButton: FC<Partial<Props>> = ({
  disabled,
  form,
  record,
  mapController,
  onClick,
  onSubmit,
}) => {
  const loading = useLoading();
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    message?: string;
  }>({ open: false });
  const { checkIntersect, checkSameValue, checkAreaZero } =
    useCoordinatesCheck();

  const handleSave = async () => {
    if (!onClick || !form || !onSubmit || !mapController) return;
    try {
      const { points, radius, type } = await onClick();
      if (points.length <= 0) return;

      const geometryCoordinates: LonLat[] = points.map(lonLats => ({
        lon: lonLats[0],
        lat: lonLats[1],
      }));
      // 入力ダイアログ確定前のポリゴン交差チェック
      if (checkIntersect(geometryCoordinates)) {
        setDialogState({
          open: true,
          message: 'admin.message.geofence.crossingMessage',
        });
        return;
      }
      // 入力ダイアログ確定前の同一座標チェック
      if (checkSameValue(geometryCoordinates)) {
        setDialogState({
          open: true,
          message: 'admin.message.geofence.sameValueMessage',
        });
        return;
      }
      // 入力ダイアログ確定前の面積ゼロチェック
      // 面積チェックはローカル座標で行う → 'results' 変数
      if (type === 'Polygon' && checkAreaZero(geometryCoordinates)) {
        setDialogState({
          open: true,
          message: 'admin.message.geofence.areaZeroMessage',
        });
        return;
      }

      const formValues = {
        ...form.getState().values,
        points,
        radius,
      } as GeofenceData;
      // Map上で操作した値を取得
      const settingType = mapController.getSelectedType();
      const settingDimension = mapController.getSelectedDimension();
      const settingAlertType = mapController.getSelectedAlertType();
      const settingValid = mapController.getSelectedValid();
      const settingTransparency = mapController.getSelectedTransparency();
      const settingColor = mapController.getSelectedColor();
      const settingElevation = mapController.getSettingElevation();
      const settingHeight = mapController.getSettingHeight();
      const settingRadius = mapController.getSettingRadius();
      const settingThickness = mapController.getSettingThickness();
      const settingTrigger = mapController.getSettingTrigger();

      const newRecord = record
        ? {
            ...record,
            ...formValues,
            type: settingType,
            dimension: settingDimension,
            alertType: settingAlertType,
            valid: settingValid,
            transparency: settingTransparency,
            rgbRed: settingColor.r,
            rgbBlue: settingColor.b,
            rgbGreen: settingColor.g,
            elevation: settingElevation,
            height: settingHeight,
            radius: settingRadius,
            thickness: settingThickness,
            trigger: settingTrigger,
          }
        : formValues;
      onSubmit(newRecord, record);
    } catch (e) {
      logger().error('', e);
    }
  };
  return (
    <>
      <DialogOKOnly
        open={dialogState.open}
        onClose={() => setDialogState({ open: false })}
        title=""
        message={dialogState?.message}
      />
      <DoneButton disabled={disabled || loading} onClick={handleSave}>
        <OkIcon />
      </DoneButton>
    </>
  );
};

SaveButton.displayName = 'SaveButton';
export default SaveButton;
