import React, { FC, useCallback, useState } from 'react';
import {
  useDataProvider,
  useNotify,
  useRedirect,
  useTranslate,
  useUpdate,
} from 'react-admin';
import {
  CustomSaveButton,
  LendCorporationData,
  DialogOKOnlyWithUrl,
} from '../../..';
import { SaveIcon } from '../../../../assets';
import {
  RetrofitAlternateRegistData,
  RetrofitData,
} from '../../../../dataProvider';
import { config } from '../../../../utils';
import {
  useCheckSiteAttachment,
  usePendingTransferred,
  useRetrofitLending,
} from '../../../../hooks';
import {
  useCheckUnusedNtipDB,
  useIncludesOwnerCorporationId,
  useGetRetrofitOwnerRegistCorporations,
} from '../hooks';

interface Props {
  record: RetrofitAlternateRegistData;
  customer: LendCorporationData | null;
  form: any;
  retrofitAlternateRegistData: RetrofitAlternateRegistData;
}

const useGetRetrofits = () => {
  const dataProvider = useDataProvider();
  return async (retrofitId: number): Promise<RetrofitData> => {
    const { data } = await dataProvider.getOne('retrofits', { id: retrofitId });
    return data as RetrofitData;
  };
};

const EditSaveButton: FC<Partial<Props>> = ({
  record,
  customer,
  form,
  retrofitAlternateRegistData,
  ...props
}) => {
  const basePath = '/retrofitAlternateRegists';
  const toCorporationId = customer ? customer.id : 'unknown';
  const toCorporationName = customer ? customer.name : 'unknown';
  const scSupportRequestUrl = config.url.scSupportRequest; // エラーダイアログ用URL
  const [errorDialog, setErrorDialog] = useState<{
    open: boolean;
    messages: string[];
    subMessages?: string[];
  }>({ open: false, messages: [], subMessages: [] });
  const translate = useTranslate();
  const getRetrofits = useGetRetrofits();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const checkUnusedNtipDB = useCheckUnusedNtipDB();
  const checkSiteAttachment = useCheckSiteAttachment();
  const retrofitLending = useRetrofitLending();
  const checkPendingTransfer = usePendingTransferred();
  const includesOwnerCorporationId = useIncludesOwnerCorporationId();
  const getOwnerCorporationIdList = useGetRetrofitOwnerRegistCorporations();

  const [update] = useUpdate(
    'retrofitAlternateRegists',
    record?.id,
    undefined,
    record,
  );
  const retrofitId = retrofitAlternateRegistData
    ? retrofitAlternateRegistData.retrofitId
    : 0;
  const retrofitIds = [retrofitId]; // checkSiteAttachment,retrofitLendingのために型定義を合わせる

  const handleSaveClick = useCallback(
    values => {
      const toMailAddress = form.getFieldState('toMailAddress')?.value;

      const api = async (): Promise<boolean> => {
        const retrofit = await getRetrofits(retrofitId);
        const { basicInfoSerialNumber } = retrofit;
        const hideNofityError = 'hideNofityError';
        const ownerCorporationIds = await getOwnerCorporationIdList();

        // 存在しないシリアル番号のチェック
        const invalidSerial = !basicInfoSerialNumber.includes(
          basicInfoSerialNumber,
        );
        if (invalidSerial) {
          setErrorDialog({
            open: true,
            messages: [
              translate(
                'admin.dialog.retrofitAlternateRegists.serialNumberExistError.message1',
              ),
              translate(
                'admin.dialog.retrofitAlternateRegists.serialNumberExistError.message2',
              ),
            ],
            subMessages: [
              translate(
                'admin.dialog.retrofitAlternateRegists.serialnumberCheck.message',
              ),
              basicInfoSerialNumber,
            ],
          });
          throw Error(hideNofityError);
        }

        // すでに代理申請先企業に紐づいているかチェック ※ メールアドレスのみ入力した場合は判定不可
        const isAlreadyRegisted = retrofit.corporationId === toCorporationId;
        if (isAlreadyRegisted) {
          // エラーダイアログを表示
          setErrorDialog({
            open: true,
            messages: [
              translate(
                'admin.dialog.retrofitAlternateRegists.corporationIdCheck.message1',
              ),
              translate(
                'admin.dialog.retrofitAlternateRegists.corporationIdCheck.message2',
              ),
            ],
            subMessages: [
              translate(
                'admin.dialog.retrofitAlternateRegists.serialnumberCheck.message',
              ),
              basicInfoSerialNumber,
            ],
          });
          return false;
        }
        // 紐付け申請の対象コントローラが『EARTHBRAIN』の企業IDに紐づいているとき
        // 登録予定のレトロフィットの利用中企業ID取得

        // EARTHBRAIN企業ID取得
        const { corporationId: earthbrainCorporationId } =
          config.corporation.earthbrain;

        const isEarthbrainRetrofit =
          retrofit.corporationId === earthbrainCorporationId;
        if (isEarthbrainRetrofit) {
          // エラーダイアログを表示
          setErrorDialog({
            open: true,
            messages: [
              translate(
                'admin.dialog.retrofitAlternateRegists.earthbrainIdCheck.message1',
              ),
              translate(
                'admin.dialog.retrofitAlternateRegists.earthbrainIdCheck.message2',
              ),
              translate(
                'admin.dialog.retrofitAlternateRegists.earthbrainIdCheck.message3',
              ),
              translate(
                'admin.dialog.retrofitAlternateRegists.earthbrainIdCheck.message4',
              ),
              translate(
                'admin.dialog.retrofitAlternateRegists.earthbrainIdCheck.message5',
              ),
            ],
            subMessages: [
              translate(
                'admin.dialog.retrofitAlternateRegists.serialnumberCheck.message',
              ),
              basicInfoSerialNumber,
            ],
          });
          return false;
        }

        // レトロフィット利用中企業登録可能企業であることをチェック
        const { ownerId } = retrofit;
        const ownerIds = [ownerId]; // hooks includesOwnerCorporationId のために型を修正
        const retrofits = [retrofit];
        const {
          data: { allIncluded },
        } = await includesOwnerCorporationId({
          ownerIds,
          ownerCorporationIds,
          retrofits,
        });

        if (!allIncluded) {
          // エラーダイアログを表示
          setErrorDialog({
            open: true,
            messages: [
              translate(
                'admin.dialog.retrofitAlternateRegists.invalidSerialNumber.message1',
              ),
              translate(
                'admin.dialog.retrofitAlternateRegists.invalidSerialNumber.message2',
              ),
              translate(
                'admin.dialog.retrofitAlternateRegists.invalidSerialNumber.message3',
              ),
              translate(
                'admin.dialog.retrofitAlternateRegists.invalidSerialNumber.message4',
              ),
              translate(
                'admin.dialog.retrofitAlternateRegists.invalidSerialNumber.message5',
              ),
              translate(
                'admin.dialog.retrofitAlternateRegists.invalidSerialNumber.message6',
              ),
              translate(
                'admin.dialog.retrofitAlternateRegists.invalidSerialNumber.message7',
              ),
            ],
            subMessages: [
              translate(
                'admin.dialog.retrofitAlternateRegists.serialnumberCheck.message',
              ),
              basicInfoSerialNumber,
            ],
          });
          return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
        }

        // Ntrip払い出しで未使用NtripDBの合計数が足りるかチェック
        // REACT_APP_FEATURE_NTRIPSが削除されたら合わせてconfig.feature.ntripsを削除する
        if (config.feature.ntrips) {
          const {
            data: { ntripDBShortage },
          } = await checkUnusedNtipDB({
            retrofits,
          });
          if (ntripDBShortage) {
            // エラーダイアログを表示
            setErrorDialog({
              open: true,
              messages: [
                translate(
                  'admin.dialog.retrofitAlternateRegists.ntripShortage.message1',
                ),
                translate(
                  'admin.dialog.retrofitAlternateRegists.ntripShortage.message2',
                ),
              ],
            });
            return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
          }
        }

        // 貸与中チェック
        const {
          data: { isLending },
        } = await retrofitLending({
          retrofitIds,
        });
        if (isLending) {
          // エラーダイアログを表示
          setErrorDialog({
            open: true,
            messages: [
              translate(
                'admin.dialog.retrofitAlternateRegists.lendingRetrofits.message1',
              ),
              translate(
                'admin.dialog.retrofitAlternateRegists.lendingRetrofits.message2',
              ),
              translate(
                'admin.dialog.retrofitAlternateRegists.lendingRetrofits.message3',
              ),
            ],
            subMessages: [
              translate(
                'admin.dialog.retrofitAlternateRegists.serialnumberCheck.message',
              ),
              basicInfoSerialNumber,
            ],
          });
          return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
        }

        // 譲渡承認待ちチェック
        const {
          data: { isPendingTransfer },
        } = await checkPendingTransfer({
          retrofitIds,
        });
        if (isPendingTransfer) {
          // エラーダイアログを表示
          setErrorDialog({
            open: true,
            messages: [
              translate(
                'admin.dialog.retrofitAlternateRegists.pendingApprovals.message1',
              ),
              translate(
                'admin.dialog.retrofitAlternateRegists.pendingApprovals.message2',
              ),
            ],
            subMessages: [
              translate(
                'admin.dialog.retrofitAlternateRegists.serialnumberCheck.message',
              ),
              basicInfoSerialNumber,
            ],
          });
          return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
        }

        // 現場の紐づきチェック
        const {
          data: { isAttachedToSite },
        } = await checkSiteAttachment({
          retrofitIds,
        });
        if (isAttachedToSite) {
          // エラーダイアログを表示
          setErrorDialog({
            open: true,
            messages: [
              translate(
                'admin.dialog.retrofitAlternateRegists.siteAttached.message1',
              ),
              translate(
                'admin.dialog.retrofitAlternateRegists.siteAttached.message2',
              ),
              translate(
                'admin.dialog.retrofitAlternateRegists.siteAttached.message3',
              ),
            ],
            subMessages: [
              translate(
                'admin.dialog.retrofitAlternateRegists.serialnumberCheck.message',
              ),
              basicInfoSerialNumber,
            ],
          });
          return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
        }

        // update処理
        update({
          payload: {
            data: {
              ...values,
              toMailAddress,
              toCorporationId,
              toCorporationName,
            },
          },
        });
        return true;
      };

      api()
        .then(result => {
          if (result) {
            notify('admin.message.success', 'info');
            redirectTo(basePath);
          }
        })
        .catch(error => {
          notify(`Error: ${error.message}`, 'warning');
        });
    },
    [
      notify,
      redirectTo,
      translate,
      update,
      checkUnusedNtipDB,
      checkSiteAttachment,
      retrofitLending,
      checkPendingTransfer,
      includesOwnerCorporationId,
      getRetrofits,
      getOwnerCorporationIdList,
      retrofitId,
      toCorporationId,
      toCorporationName,
      form,
      retrofitIds,
    ],
  );

  return (
    <>
      <CustomSaveButton {...props} onSave={handleSaveClick}>
        <SaveIcon />
      </CustomSaveButton>
      <DialogOKOnlyWithUrl
        open={errorDialog.open}
        onClose={() => {
          setErrorDialog({
            ...errorDialog,
            open: false,
          });
        }}
        title=""
        nonTranslateMessage={true}
        messages={errorDialog.messages}
        url={scSupportRequestUrl}
        subMessages={errorDialog.subMessages}
      />
    </>
  );
};

EditSaveButton.displayName = 'EditSaveButton';
export default EditSaveButton;
