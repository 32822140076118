import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { SimpleShowLayout } from '..';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flex: 2,
      '& > .ra-field': {
        width: '100%',
      },
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
  }),
);

const CustomParentBox: React.FC = props => {
  const classes = useStyles();
  return <SimpleShowLayout {...props} className={classes.root} />;
};
CustomParentBox.displayName = 'CustomParentBox';
export default CustomParentBox;
