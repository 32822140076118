import React, { useEffect, useState } from 'react';
import {
  useTranslate,
  useDataProvider,
  useRedirect,
  usePermissions,
} from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  BreadcrumbsFiled,
  CustomForm,
  CustomDateField,
  DeleteFromSiteButton,
  IconField,
  IconFieldPaddingZeroStyle,
  Show,
  TextField,
  Typography,
  CustomParentBox,
  CustomChildBox,
  RetrofitInfoMenu,
  FunctionField,
  CustomToolbarButtonType,
  Loading,
  SimpleShowLayout,
} from '../../..';
import RetrofitLicenseShowLayout from '../../../organisms/RetrofitLicenseShowLayout'; // テスト側でモックするため直接 import している
import RetrofitRemoteSupportField from '../../../organisms/RetrofitRemoteSupportField'; // テスト側でモックするため直接 import している
import { useResource, useSite, useGetSelefDetailData } from '../../../../hooks';
import {
  RetrofitRentalData,
  UserPermissionsData,
} from '../../../../dataProvider/types';
import { config } from '../../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    titleFirst: {
      padding: '12px 0px',
      '& > p': {
        padding: '0px',
      },
    },
    title: {
      padding: '12px 0px',
      borderTop: '1px solid #F5F5FF33',
    },
    infoMenu: {
      marginTop: 8,
      textAlign: 'right',
    },
    form: {
      ...IconFieldPaddingZeroStyle,
    },
    space: {
      marginBottom: 8,
    },
  }),
);

const useRemoteSupportStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 160,
    },
  }),
);

interface LendProps {
  rentalData: RetrofitRentalData;
}

const LendingDetail: React.FC<LendProps> = props => {
  const classes = useStyles();
  const translate = useTranslate();

  const { rentalData: record } = props;
  const rental = 'retrofitRentals';
  const rentalResource = useResource(rental);
  const layoutProps = { record, resource: rentalResource };

  return (
    <>
      <Typography className={classes.title}>
        {translate('admin.label.retrofitRental.rentalDetails')}
      </Typography>
      <CustomParentBox {...layoutProps}>
        <CustomChildBox title={translate('admin.label.retrofitRental.lending')}>
          <TextField resource={rentalResource} source="fromCorporationName" />
          <CustomDateField resource={rentalResource} source="planStartDate" />
          <CustomDateField resource={rentalResource} source="actualStartDate" />
          <FunctionField
            resource={rentalResource}
            record={record}
            source="isApproved"
            render={it => {
              const status = it?.isApproved ? 'Approved' : 'Unapproved';
              return <Typography>{status}</Typography>;
            }}
          />
        </CustomChildBox>
        <CustomChildBox title={translate('admin.label.retrofitRental.return')}>
          <CustomDateField resource={rentalResource} source="planEndDate" />
          <CustomDateField resource={rentalResource} source="actualEndDate" />
        </CustomChildBox>
      </CustomParentBox>
    </>
  );
};

const SiteRetrofitEdit: React.FC = () => {
  const { props, getSite } = useSite('retrofits');
  const { id: retrofitId, resource, basePath } = props;
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const remoteSupportClasses = useRemoteSupportStyles();
  const translate = useTranslate();
  const getSelefDetailData = useGetSelefDetailData();
  const redirectTo = useRedirect();

  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    retrofitId: string | undefined;
    data?: {
      siteName: string;
      retrofitRental?: RetrofitRentalData;
      isOwnData?: boolean;
    };
  }>({ loading: true, fetched: false, retrofitId: undefined });

  useEffect(() => {
    if (apiState.fetched && apiState.retrofitId === retrofitId) return;
    setApiState({ loading: true, fetched: true, retrofitId });

    const api = async () => {
      // sites
      const { data: site } = await getSite();
      const { name: siteName, id: siteId } = site;

      // retrofits
      const listFileter = {
        siteId,
      };
      const retrofit = await getSelefDetailData(
        resource,
        listFileter,
        retrofitId,
      );

      const corporationId = retrofit?.corporationId;

      let retrofitRentals: Array<RetrofitRentalData> = [];

      if (corporationId) {
        const retrofitRentalsData =
          await dataProvider.getManyReference<RetrofitRentalData>(
            'retrofitRentals',
            {
              id: retrofitId,
              target: 'retrofitId',
              pagination: { page: 1, perPage: 1 },
              sort: { field: 'id', order: 'DESC' },
              filter: {
                retrofitId, // レンタル.レトロフィットId(rentals.retrofitId) = 現場詳細 - 建機一覧で選択した明細のレトロフィットId
                toCorporationId: corporationId, // レンタル.貸出先企業Id(rentals.toCorporationId) =  現場詳細 - 建機一覧で選択した明細のレトロフィットIdの企業ID
                isReturned: false, // レンタル.返却済み(rentals.isReturned = false
              },
            },
          );
        retrofitRentals = retrofitRentalsData.data;
      }

      const retrofitRental =
        retrofitRentals.length > 0 ? retrofitRentals[0] : undefined;

      return {
        siteName,
        retrofitRental,
        isOwnData: !!retrofit,
      };
    };

    api().then(data => {
      setApiState({
        loading: false,
        fetched: true,
        retrofitId,
        data,
      });
    });
  }, [
    apiState,
    getSite,
    dataProvider,
    retrofitId,
    getSelefDetailData,
    resource,
  ]);
  const { permissions } = usePermissions();

  if (apiState.loading || !apiState.data) return <Loading />;
  const { siteName, retrofitRental, isOwnData } = apiState.data;
  // 一覧に存在しないretoriftのデータは表示させない
  if (!isOwnData) redirectTo('/');
  const userRole = permissions.userRoles as UserPermissionsData['userRoles'];
  const supportMode = userRole.includes('SupportMode');

  return (
    <Show {...props}>
      <CustomForm
        title="admin.pages.siteRetrofitEdit"
        redirect={false}
        saveButton={false}
        cancelButton={false}
        deleteButton={<DeleteFromSiteButton />}
        actionButton={
          <RetrofitRemoteSupportField
            classes={remoteSupportClasses}
            buttonLabel="admin.actions.remoteSupport"
          />
        }
        buttonOrder={[
          CustomToolbarButtonType.action2,
          CustomToolbarButtonType.action,
          CustomToolbarButtonType.delete,
        ]}
        className={classes.form}
      >
        <BreadcrumbsFiled
          breadcrumbs={[
            'resources.sites.name',
            '',
            siteName,
            'resources.retrofits.name',
          ]}
          label="ra.action.show"
        />

        <RetrofitInfoMenu
          retrofitId={retrofitId}
          parentPath={basePath}
          classes={{ root: classes.infoMenu }}
          supportMode={supportMode}
        />

        <CustomParentBox>
          <SimpleShowLayout className={classes.space}>
            <Typography className={classes.titleFirst}>
              {translate('admin.label.retrofitEdit.machineryDetails')}
            </Typography>
            <FunctionField
              resource={resource}
              source="machineInfoMachineType"
              render={record => {
                const res = 'resources.retrofits.machineInfoMachineType';
                const type = record?.machineInfoMachineType.toLowerCase();
                const trans = translate(`${res}.${type}`);
                // 翻訳できなければ、もとの内容をそのまま返す
                if (trans.startsWith(res)) return type;
                return trans;
              }}
            />
            <TextField resource={resource} source="machineInfoMachineId" />
            <TextField resource={resource} source="machineInfoMachineName" />
            <TextField resource={resource} source="machineInfoCompanyName" />
          </SimpleShowLayout>
          {config.feature.useRetrofitLicense ? (
            <RetrofitLicenseShowLayout addLabel={false} />
          ) : null}
        </CustomParentBox>
        <Typography className={classes.title}>
          {translate('admin.label.retrofitEdit.retrofitDetails')}
        </Typography>
        <CustomParentBox>
          <CustomChildBox
            title={translate('admin.label.retrofitEdit.basicInfo')}
          >
            <TextField resource={resource} source="basicInfoManufacturer" />
            <TextField resource={resource} source="basicInfoModel" />
            <TextField resource={resource} source="basicInfoProductNumber" />
            <TextField resource={resource} source="basicInfoSerialNumber" />
          </CustomChildBox>

          <CustomChildBox
            title={translate('admin.label.retrofitEdit.controller')}
          >
            <TextField resource={resource} source="controllerManufacturer" />
            <TextField resource={resource} source="controllerModel" />
            <TextField resource={resource} source="controllerFirmwareVersion" />
          </CustomChildBox>
        </CustomParentBox>
        <CustomParentBox>
          <CustomChildBox
            title={translate('admin.label.retrofitEdit.reciverMain')}
          >
            <TextField
              resource={resource}
              source="gnssReceiverMainManufacturer"
            />
            <TextField resource={resource} source="gnssReceiverMainModel" />
            <TextField
              resource={resource}
              source="gnssReceiverMainFirmwareVersion"
            />
          </CustomChildBox>

          <CustomChildBox
            title={translate('admin.label.retrofitEdit.reciverSub')}
          >
            <TextField
              resource={resource}
              source="gnssReceiverSubManufacturer"
            />
            <TextField resource={resource} source="gnssReceiverSubModel" />
            <TextField
              resource={resource}
              source="gnssReceiverSubFirmwareVersion"
            />
          </CustomChildBox>
        </CustomParentBox>

        <Typography className={classes.title}>
          {translate('admin.label.retrofitEdit.retrofitstatus')}
        </Typography>
        <IconField resource={resource} source="isOnline" />
        <IconField resource={resource} source="isError" />
        <CustomDateField resource={resource} source="lastOnlineTime" showTime />
        {/* レンタル情報 */}
        {retrofitRental && <LendingDetail rentalData={retrofitRental} />}
      </CustomForm>
    </Show>
  );
};

SiteRetrofitEdit.displayName = 'SiteRetrofitEdit';
export default SiteRetrofitEdit;
