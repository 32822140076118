import { CheckboxGroupInput as RaCheckboxGroupInput } from 'react-admin';
import { InputProps as RaInputProps } from 'ra-core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { ResourceFC } from '..';

export type CheckboxProps = RaInputProps<TextFieldProps> &
  Omit<TextFieldProps, 'label' | 'helperText'>;

const CheckboxGroupInput = RaCheckboxGroupInput;

const { defaultProps } = CheckboxGroupInput;
CheckboxGroupInput.defaultProps = {
  ...defaultProps,
};
CheckboxGroupInput.displayName = 'CheckboxGroupInput';
export default CheckboxGroupInput as ResourceFC<CheckboxProps>;
