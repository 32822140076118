import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CustomList,
  Datagrid,
  TextField,
  ReferenceField,
  IconField,
  CustomDateField,
  Filter,
  SiteSearchInput,
  LinkButton,
  CustomBulkDeleteButton,
  DatagridBody,
  RetrofitAlternateRegistBulkActionDatagridRow,
} from '../..';
import { useResource, useGetAccount, useSpacerStyles } from '../../../hooks';
import { AddIcon } from '../../../assets';
import { BulkRegistrationButton } from './views';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          // TODO: 要幅調整
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 112,
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 80,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(7)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 112,
          },
        },
      },
    },
  }),
);

const SerchFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

const Actions: React.FC<{
  basePath?: string;
}> = ({ basePath }) => (
  <LinkButton path={`${basePath}/photo/create`} label="admin.actions.add">
    <AddIcon />
  </LinkButton>
);

const BulkActionButtons: React.FC = props => {
  const classes = useSpacerStyles();
  return (
    <>
      <CustomBulkDeleteButton {...props} />
      <span className={classes.root} />
      <BulkRegistrationButton {...props} />
    </>
  );
};

const RetrofitAlternateRegistList: React.FC = props => {
  const resource = useResource('retrofitAlternateRegists');
  const classes = useStyles();
  const { getCorporationId } = useGetAccount();
  const corporationId = getCorporationId();

  return (
    <CustomList
      {...props}
      resource={resource}
      title="admin.pages.retrofitAlternateRegists"
      filters={<SerchFilter />}
      filter={{ fromCorporationId: corporationId }}
      bulkActionButtons={<BulkActionButtons />} // 一括削除ボタンと一括更新ボタンを横並びで表示
      actions={<Actions />}
    >
      <Datagrid
        className={classes.root}
        body={
          <DatagridBody
            row={<RetrofitAlternateRegistBulkActionDatagridRow />}
          />
        }
      >
        <TextField resource={resource} source="toCorporationName" />
        <TextField
          resource={resource}
          source="toMailAddress"
          label="resources.retrofitAlternateRegists.fields.email"
        />
        <ReferenceField
          resource={resource}
          source="retrofitId"
          reference="retrofits"
          link=""
          label="resources.retrofits.fields.basicInfoSerialNumber"
        >
          <TextField resource="retrofits" source="basicInfoSerialNumber" />
        </ReferenceField>
        <CustomDateField
          resource={resource}
          source="applicationDate"
          label="resources.retrofitAlternateRegists.fields.createDate"
        />
        <IconField
          resource={resource}
          source="isApproved"
          positionLastColumn={true}
        />
      </Datagrid>
    </CustomList>
  );
};

RetrofitAlternateRegistList.displayName = 'RetrofitAlternateRegistList';
export default RetrofitAlternateRegistList;
