import React, { FC, useState, useEffect } from 'react';
import {
  RetrofitCorporationLicenseData,
  LicensePermissionType,
} from '../../../../dataProvider';
import { LicenseDialog } from '../dialog';
import { useGetLicense, useGetConfirmInfo } from '../hooks';
import { toLicenseObject, mergeLicense, dispachDialogIcon } from '../utils';

export interface LicenseParams {
  retrofitId: number;
  licensePermission: LicensePermissionType;
  licenseRecord: RetrofitCorporationLicenseData;
  machineInfo: any;
  beforeMachineInfo?: any;
}

interface Props {
  shown: boolean;
  handleSubmit: () => void;
  handleClose: () => void;
  licenseParams?: LicenseParams;
}

const PreUpdateShow: FC<Props> = ({
  shown,
  handleSubmit,
  handleClose,
  licenseParams,
}) => {
  const getLicense = useGetLicense();
  const getConfirmInfo = useGetConfirmInfo();

  const [apiFetched, setApiFheched] = useState(false);
  const [beforeInfoFetched, setBeforeInfoFheched] = useState(false);
  const [submit, setSubmit] = useState(false);

  const [beforeLicense, setBeforeLicense] = useState<
    RetrofitCorporationLicenseData[] | undefined
  >(undefined);

  const [dialogState, setDialogState] = useState<{
    open: boolean;
    mode?: 'compare' | 'error';
    noMarge?: boolean;
    title?: string;
    licenses: RetrofitCorporationLicenseData[];
    isCompactDialog?: boolean;
  }>({ open: false, licenses: [] });

  const emptyParams = {
    licenseRecord: undefined,
    machineInfo: {
      beforeRetrofitId: undefined,
      machineInfoMachineId: '',
      machineInfoMachineName: '',
      basicInfoSerialNumber: '',
    },
    beforeMachineInfo: undefined,
  };
  const { licenseRecord, machineInfo, beforeMachineInfo } =
    licenseParams || emptyParams;

  useEffect(() => {
    if (!beforeMachineInfo || beforeInfoFetched) return;
    const { beforeRetrofitId } = beforeMachineInfo;
    const paramRetrofitId = parseInt(`${beforeRetrofitId}`, 10);
    getLicense({ retrofitId: paramRetrofitId }).then(
      ({ data: licenseData }) => {
        setBeforeLicense(licenseData);
      },
    );
    setBeforeInfoFheched(true);
  }, [beforeMachineInfo, getLicense, beforeInfoFetched]);

  useEffect(() => {
    if (!shown || !licenseParams || apiFetched) return;
    const { retrofitId, licensePermission } = licenseParams;

    getLicense({ retrofitId }).then(({ data: licenseData }) => {
      const {
        data: {
          isError,
          isDeleteInsert,
          message,
          usingLicenseData,
          isCompactDialog,
        },
      } = getConfirmInfo({
        data: licenseData,
        settingLicensePermission: licensePermission,
      });
      setDialogState({
        open: true,
        mode: isError ? 'error' : 'compare',
        noMarge: isDeleteInsert,
        title: message,
        licenses: usingLicenseData,
        isCompactDialog,
      });
      setApiFheched(true);
    });
  }, [shown, licenseParams, getConfirmInfo, getLicense, apiFetched]);

  useEffect(() => {
    if (!apiFetched && !dialogState.open) return;
    setApiFheched(false);
    handleClose();
  }, [handleClose, apiFetched, dialogState.open]);

  useEffect(() => {
    if (submit) {
      handleSubmit();
    }
  }, [handleSubmit, submit]);

  const handleDialogHide = (isSubmit: boolean) => {
    setDialogState({ open: false, licenses: [] });
    if (isSubmit) {
      setSubmit(true);
    }
  };

  const beforeLicenseRecords = beforeLicense || dialogState.licenses;
  const afterLicenseRecords = mergeLicense(
    licenseRecord,
    dialogState.noMarge ? [] : dialogState.licenses,
  );

  return (
    <LicenseDialog
      open={dialogState.open}
      onCancel={
        dialogState.mode === 'compare'
          ? () => handleDialogHide(false)
          : undefined
      }
      onOk={() => handleDialogHide(dialogState.mode === 'compare')}
      layoutProps={{
        icon: dispachDialogIcon(dialogState.mode),
        title: dialogState.title || '',
        retrofitInfo: machineInfo,
        beforeRetrofitInfo: beforeMachineInfo,
        beforeLicense: toLicenseObject(beforeLicenseRecords),
        afterLicense: toLicenseObject(afterLicenseRecords),
        isCompactDialog: dialogState.isCompactDialog,
      }}
    />
  );
};

PreUpdateShow.displayName = 'PreUpdateShow';
export default PreUpdateShow;
