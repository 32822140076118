/* UIの共通サイズ定義 */

export const inputWidth = 300;
export const inputHeight = 32;

export const buttonWidth = 160;
export const buttonHeight = 36;
export const buttonDisabledOpacity = 0.3;

export const checkBoxWidth = 24;
export const checkBoxHeight = 24;

export const alignRightPadding = 52; // 右寄せした時の右からの余白

export const inputWidthRight = inputWidth - alignRightPadding; // 右寄せの横幅

/* 各種UIの固有サイズ定義 */
/* passwordInput */
export const passwordVisibleButtonWidth = 65; // passwordInputの表示/非表示切り替えボタン領域の横幅
export const inputPasswordWidth = inputWidth - passwordVisibleButtonWidth;

/* AutocompleteInput */
export const autocompleteWidth = 314;
export const autocompleteInnerWidth = autocompleteWidth - 54;

/* AutocompleteInput (現場選択) */
export const siteAutocompleteWidth = 641;
export const siteAutocompleteInnerWidth = siteAutocompleteWidth - 54;
