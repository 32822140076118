import React, { useState } from 'react';
import PreUpdateShow, { LicenseParams } from '../views/PreUpdateShow';

interface OutParams {
  component: JSX.Element;
  handlePreUpdate: (params: LicenseParams) => void;
  doUpdate: boolean;
}

const usePreUpdate = (): OutParams => {
  const [doUpdate, setDoUpdate] = useState(false);
  const [licenseParams, setLicenseParams] = useState<LicenseParams>();
  const [shown, setShown] = useState(false);
  return {
    component: (
      <PreUpdateShow
        shown={shown}
        handleSubmit={() => {
          setDoUpdate(true);
        }}
        handleClose={() => {
          setShown(false);
        }}
        licenseParams={licenseParams}
      />
    ),
    handlePreUpdate: (params: LicenseParams) => {
      setShown(true);
      setLicenseParams(params);
    },
    doUpdate,
  };
};

export default usePreUpdate;
