import { RetrofitCorporationLicenseData } from '../../../../dataProvider';
import { useGetListAllPage } from '../../../../hooks';

interface InParams {
  retrofitId: number;
}

interface OutParams {
  data: RetrofitCorporationLicenseData[];
}

const useGetLicense = () => {
  const getListAllPage = useGetListAllPage();
  return async ({ retrofitId }: InParams): Promise<OutParams> => {
    const record = await getListAllPage({
      resource: 'retrofitCorporationLicenses',
      filter: { retrofitId },
      sort: { field: 'id', order: 'ASC' },
    });
    const data = record.data as RetrofitCorporationLicenseData[];
    return { data };
  };
};

export default useGetLicense;
