import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import {
  BucketAddToSiteActionButton,
  CustomList,
  Datagrid,
  CustomDateField,
  Filter,
  IconField,
  LinkButton,
  Loading,
  SiteSearchInput,
  TextField,
} from '../../..';
import { useAddInSite, useSpacerStyles } from '../../../../hooks';
import { ReturnIcon } from '../../../../assets';

const BucketAddToSiteListFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

const BulkActionButtons: React.FC<{ basePath?: string }> = props => {
  const { basePath = '' } = props;
  const classes = useSpacerStyles();
  return (
    <>
      <LinkButton
        className={classes.button}
        path={basePath}
        data-testid="ra.action.cancel"
      >
        <ReturnIcon />
      </LinkButton>
      <span className={classes.root} />
      <BucketAddToSiteActionButton {...props} />
    </>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 16,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 137,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 240,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 112,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 130,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 138,
          },
        },
      },
    },
  }),
);

const SiteBucketCreate: React.FC = () => {
  const { props, site } = useAddInSite('buckets');
  const classes = useStyles();
  if (!props || !site) return <Loading />;
  const { resource } = props;
  const { corporationId, name: siteName } = site;

  return (
    <CustomList
      {...props}
      title="admin.pages.siteBucketCreate"
      breadcrumbs={[
        'resources.sites.name',
        '',
        siteName,
        'resources.buckets.name',
      ]}
      breadcrumbsTitle="admin.actions.add"
      // どの現場にも紐づいていないデータを出力
      filter={{ corporationId, siteId: '' }}
      bulkActionButtons={<BulkActionButtons />}
      filters={<BucketAddToSiteListFilter />}
    >
      <Datagrid className={classes.root}>
        <IconField resource={resource} source="bucketType" />
        <TextField resource={resource} source="name" />
        <IconField resource={resource} source="uploadedBy" />
        <IconField resource={resource} source="fileStatus" />
        <CustomDateField resource={resource} source="lastUpdated" />
      </Datagrid>
    </CustomList>
  );
};

SiteBucketCreate.displayName = 'SiteBucketCreate';
export default SiteBucketCreate;
