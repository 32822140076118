const apiErrorHandler = (
  error: unknown,
  defaultErrorMessage = 'ra.notification.http_error',
): string => {
  if (typeof error === 'string') {
    return error;
  }
  if (error instanceof Error) {
    if (error.message !== '') {
      return error.message;
    }
  }
  return defaultErrorMessage;
};

export default apiErrorHandler;
