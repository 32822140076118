import React, { FC, ReactElement, isValidElement, cloneElement } from 'react';
import { Labeled } from 'react-admin';
import { omit } from 'lodash';
import { makeStyles, createStyles } from '@material-ui/core';
import { RetrofitBasicInfoData } from '../../../../dataProvider';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        '& > .MuiFormLabel-root': {
          display: 'none', // 子コンポーネントのラベル領域を消すため
        },
      },
    },
  }),
);

interface Props {
  source: string;
  resource: string;
  record: RetrofitBasicInfoData;
  label: string;
  id: string;
  isRequired: boolean;
  // onCompare の結果によって出力されるエレメント
  //   - true: inputElement
  //   - false: fieldElement
  onCompare: (record: RetrofitBasicInfoData) => boolean;
  inputElement: ReactElement;
  fieldElement: ReactElement;
}

const CompareField: FC<Partial<Props>> = ({
  isRequired,
  onCompare,
  inputElement,
  fieldElement,
  ...rest
}) => {
  const { record, label, id } = rest;
  const classes = useStyles();
  const isInput = onCompare && record ? onCompare(record) : null;
  const targetElement = isInput ? inputElement : fieldElement;
  const props = omit({ ...rest, addLabel: false, label: '' }, ['className']);
  return (
    <Labeled
      label={label}
      isRequired={isInput && isRequired}
      id={id}
      className={classes.root}
    >
      {targetElement && isValidElement(targetElement)
        ? cloneElement(targetElement, props)
        : null}
    </Labeled>
  );
};

CompareField.displayName = 'CompareField';
export default CompareField;
