import React, { FC, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  useTranslate,
  useRefresh,
  useUnselectAll,
  useNotify,
  useLoading,
} from 'react-admin';
import { Button, DialogOKCancel } from '../../..';
import { colors } from '../../../../theme';
import { buttonWidth, buttonHeight } from '../../../../theme/define';
import { useRemoveLicense } from '../hooks';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: colors.button.delete,
      color: colors.text.primaryLight,
      fontSize: 14,
      textTransform: 'none',
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.deleteHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.deleteDisabled,
      },
      '& span': {
        padding: 0,
      },
    },
  }),
);

interface Props {
  selectedIds: number[];
  disabled: boolean;
  resource: string;
}

const BulkActionButton: FC<Partial<Props>> = ({
  disabled,
  selectedIds = [],
  resource = '',
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const unselectAll = useUnselectAll(resource);
  const refresh = useRefresh();
  const notify = useNotify();
  const loading = useLoading();
  const removeLicense = useRemoveLicense();

  const [open, setOpen] = useState(false);

  const handleDelete = async () => {
    await removeLicense({ ids: selectedIds });
    unselectAll();
    refresh();
    notify('ra.notification.updated', 'info', {
      smart_count: selectedIds.length,
    });
  };

  return (
    <>
      <DialogOKCancel
        open={open}
        title=""
        message={translate('admin.message.removeLicense')}
        nonTranslateMessage={true}
        onClose={() => setOpen(false)}
        onOKClick={handleDelete}
      />
      <Button
        className={classes.root}
        disabled={loading || disabled}
        label="admin.actions.removeLicense"
        onClick={() => setOpen(true)}
      />
    </>
  );
};

BulkActionButton.displayName = 'BulkActionButton';
export default BulkActionButton;
