import React, { FC, useState } from 'react';
import {
  makeStyles,
  createStyles,
  Checkbox as MuiCheckbox,
} from '@material-ui/core';
import { colors } from '../../../theme';
import { checkBoxWidth, checkBoxHeight } from '../../../theme/define';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiCheckbox-colorPrimary.Mui-checked:hover ': {
        backgroundColor: colors.checkBox.backgroundColor,
      },
      '& .MuiCheckbox-root': {
        width: checkBoxWidth,
        height: checkBoxHeight,
      },
    },
  }),
);

interface Props {
  className: string;
  testId: string;
  onChecked: (checked: boolean) => void;
  onClick: () => boolean;
}

const CheckBox: FC<Partial<Props>> = ({
  className,
  testId,
  onChecked,
  onClick,
}) => {
  const classes = useStyles();
  const [checkState, setCheckState] = useState(false);
  return (
    <div
      className={className ? [classes.root, className].join(' ') : classes.root}
    >
      <MuiCheckbox
        data-testid={testId}
        checked={checkState}
        color="primary"
        onClick={() => {
          if (onClick && !onClick()) return;
          const checked = !checkState;
          setCheckState(checked);
          if (onChecked) onChecked(checked);
        }}
      />
    </div>
  );
};

CheckBox.displayName = 'CheckBox';
export default CheckBox;
