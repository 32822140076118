import {
  AdminApiConverter,
  ApiFilter,
  getRestDataProvider,
  File,
  UserNoticeFile,
  UserNoticeTranslation,
} from '..';
import {
  FileField,
  noticeType,
  noticeTypes,
  TranslationsField,
  UserNoticeData,
} from '../../types';

const fileType: { [key: string]: File['blobFileType'] } = {
  pdf: 'UserNoticePDF',
};

const createPdfFile = async ({
  files,
}: {
  files: FileField[];
}): Promise<FileField[]> =>
  Promise.all(
    files.map(async file => {
      const { rawFile, title, id } = file;
      if (id) return file;
      if (!rawFile) throw new Error(`RawFile dosen't supplied for ${title}`);
      const { name } = rawFile;
      const extention = name.slice(name.lastIndexOf('.') + 1).toLowerCase();
      const blobFileType = fileType[extention];
      if (!blobFileType) throw new Error(`Unkown file extention: ${name}`);
      // ファイルリソースの追加
      const dataProvider = getRestDataProvider();
      const fileData: File = {
        blobFileType,
        name: title,
      };
      const fileResult = await dataProvider.create('files', { data: fileData });
      const { id: blobFileId, url } = fileResult.data as File;
      if (!blobFileId || !url) throw Error('Invalid file data');

      // ファイルのアップロード
      await fetch(url, {
        method: 'PUT',
        body: rawFile,
        headers: { 'Content-Type': 'application/octet-stream' },
      });

      return { ...file, id: blobFileId };
    }),
  );

const createTranslationsWithPdfFile = async ({
  translations,
}: {
  translations: TranslationsField[];
}): Promise<TranslationsField[]> =>
  Promise.all(
    translations.map(async translation => {
      const files = translation.file
        ? await createPdfFile({
            files: translation.file,
          })
        : undefined;

      return { ...translation, file: files };
    }),
  );

const converter: AdminApiConverter<'userNotices', 'userNotices'> = {
  resource: 'userNotices',
  defaultSort: { field: 'updateDate', order: 'DESC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'userNotices'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('noticeCategory' in filter)
      apiFilter.noticeCategory = filter.noticeCategory;
    if ('publicationStartDate' in filter)
      apiFilter.publicationStartDate = filter.publicationStartDate;
    if ('publicationEndDate' in filter)
      apiFilter.publicationEndDate = filter.publicationEndDate;
    if ('emergency' in filter) {
      console.error('emergency is not supported for filter of userNotices');
    }
    if ('important' in filter) {
      console.error('important is not supported for filter of userNotices');
    }
    if ('title' in filter) apiFilter.title = filter.title;
    if ('content' in filter) apiFilter.content = filter.content;
    return apiFilter;
  },
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map((record): UserNoticeData => {
        /*
          emergency,importantの値から画面用のnoticeType関連の値を設定する
          type noticeType = 'EmergencyImportant' | 'Emergency' | 'Important' | '';
          type noticeTypes = 'Emergency' | 'Important';
          */
        let settingNoticeType: noticeType = '';
        const settingNoticeTypes: noticeTypes[] = [];
        if (record.emergency) {
          settingNoticeType = 'Emergency';
          settingNoticeTypes.push('Emergency');
        }
        if (record.important) {
          settingNoticeType = 'Important';
          settingNoticeTypes.push('Important');
        }
        if (record.emergency && record.important)
          settingNoticeType = 'EmergencyImportant';

        return {
          id: record.id || 0,
          noticeType: settingNoticeType,
          noticeTypes: settingNoticeTypes,
          noticeCategory: record.noticeCategory,
          publicationStartDate: record.publicationStartDate,
          publicationEndDate: record.publicationEndDate,
          language: 'EN',
          title: record.title || '',
          content: record.content || '',
          file: record.files
            ? record.files.map((f: UserNoticeFile) => {
                return { id: f.id, src: f.url, title: f.name } as FileField;
              })
            : undefined,
          translations: record.translations
            ? record.translations.map((t: UserNoticeTranslation) => {
                return {
                  id: t.id,
                  language: t.language,
                  title: t.title,
                  content: t.content,
                  file: t.files
                    ? t.files.map((f: UserNoticeFile) => {
                        return {
                          id: f.id,
                          src: f.url,
                          title: f.name,
                        } as FileField;
                      })
                    : undefined,
                };
              })
            : undefined,
          updateDate: record.updateDate,
        };
      }),
    ),
  prepareParam: async record => {
    const files = record.file
      ? await createPdfFile({
          files: record.file,
        })
      : undefined;
    // 元のtranslation からfile の情報を復元したものをセット
    const translations = record.translations
      ? await createTranslationsWithPdfFile({
          translations: record.translations,
        })
      : undefined;

    return { ...record, file: files, translations };
  },
  recordToApi: record => {
    const { noticeTypes: recordNoticeTypes } = record;
    const isEmergency = recordNoticeTypes
      ? recordNoticeTypes.indexOf('Emergency') > -1
      : false;
    const isImportant = recordNoticeTypes
      ? recordNoticeTypes.indexOf('Important') > -1
      : false;

    return {
      noticeCategory: record.noticeCategory,
      emergency: isEmergency,
      important: isImportant,
      publicationStartDate: record.publicationStartDate,
      publicationEndDate: record.publicationEndDate,
      title: record.title,
      content: record.content,
      fileIds: record.file
        ? (record.file?.map(f => f.id) as string[])
        : undefined,
      translations: record.translations
        ? record.translations.map(t => {
            return {
              id: t.id,
              language: t.language,
              title: t.title,
              content: t.content,
              fileIds: t.file
                ? (t.file?.map(f => f.id) as string[])
                : undefined,
            } as UserNoticeTranslation;
          })
        : undefined,
    };
  },
};

export default converter;
