import { GetListParams, GetListResult } from 'react-admin';
import { omitBy, isUndefined, keys } from 'lodash';
import {
  LandlogListCorporationsProps,
  landlogListCorporations,
  CorporationData,
  landlogCorporationConvert,
} from '..';

export default async (params: GetListParams): Promise<GetListResult> => {
  const { q, ...rest } = params.filter;
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;

  if (keys(rest).length > 0) {
    console.error(`Filters are not supported in Get Corporation List: ${rest}`);
  }
  if (field && field !== 'name') {
    // APIはdisplay_name以外のソートに対応していない
    console.error(`Unsupported sort field: ${field} `);
  }

  // APIの実行
  const props: LandlogListCorporationsProps = {
    name: q,
    offset: (page - 1) * perPage,
    limit: perPage,
    order_by: field === 'name' ? 'display_name' : undefined,
    order: order && order === 'ASC' ? 'asc' : 'desc',
  };
  const { corporations, total } = await landlogListCorporations(
    omitBy(props, isUndefined),
  );
  const data: CorporationData[] = corporations.map(s =>
    landlogCorporationConvert(s),
  );
  return { total, data };
};
