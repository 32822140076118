import React, { FC } from 'react';
import { Box, makeStyles, createStyles } from '@material-ui/core';
import { BreadcrumbsFiled } from '../..';
import { DownloadButton, Title, Message } from './views';

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      display: 'grid',
    },
  }),
);

const ShowDownloads: FC = () => {
  const classes = useStyles();
  return (
    <>
      <BreadcrumbsFiled label="admin.breadcrumbs.downloads" />
      <div className={classes.body}>
        <Box gridRow="1 / 2" gridColumn="1 / 10">
          <Title />
        </Box>
        <Box gridRow="2 / 3" gridColumn="1 / 6" width={'580px'}>
          <Message label="admin.label.downloads.message" />
        </Box>
        <Box gridRow="2 / 3" gridColumn="6 / 10">
          <DownloadButton />
        </Box>
      </div>
    </>
  );
};

ShowDownloads.displayName = 'ShowDownloads';
export default ShowDownloads;
