import React, { FC, useEffect } from 'react';
import { DatePickerInput } from '../../..';

interface Props {
  form: any;
  onChangeType: (type: string) => void;
}

const GeofenceBasicField: FC<Partial<Props>> = ({ form }) => {
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    document.querySelector('#rgbGreen-label')?.firstChild?.remove();
    // eslint-disable-next-line no-unused-expressions
    document.querySelector('#rgbBlue-label')?.firstChild?.remove();
  }, []);
  return (
    <div style={{ display: 'flex', padding: 8 }}>
      <DatePickerInput resource="geofences" source="startDate" {...{ form }} />
      <DatePickerInput resource="geofences" source="endDate" {...{ form }} />
    </div>
  );
};

GeofenceBasicField.displayName = 'GeofenceBasicField';
export default GeofenceBasicField;
