import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Filter, SelectInput } from '../../../..';
import { unitLength as choiceUnitLength } from '../../../../../utils';
import { UnitLength } from '../../../../../dataProvider';

const useStyles = makeStyles(() =>
  createStyles({
    columnFilter: {
      marginTop: 0,
      marginBottom: 0,
    },
  }),
);

const CustomSelectInput: React.FC<any | { unitLength: UnitLength }> = ({
  unitLength,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <SelectInput
      {...rest}
      className={classes.columnFilter}
      initialValue={unitLength}
      allowEmpty={false}
      resettable={false}
    />
  );
};

interface Props {
  resource: string;
  unitLength: UnitLength;
}

const ColumnFilters: React.FC<Props> = (props: Props) => {
  const { resource, unitLength, ...rest } = props; // コンソールエラー対策（独自パラメータを除去）
  return (
    <Filter {...rest}>
      <CustomSelectInput
        resource={resource}
        label={`resources.${resource}.fields.unitLength`}
        source="unitLength"
        choices={choiceUnitLength}
        alwaysOn
        unitLength={unitLength}
      />
    </Filter>
  );
};

export default ColumnFilters;
