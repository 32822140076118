import React, { useEffect, useState } from 'react';
// TODO: TextField をbaseから読み込むとbk3fileの入れ子構造に対応していないためreact-adminから読み込んでいる
import { TextField, Loading, required } from 'react-admin';
import { useLocation, useParams } from 'react-router-dom';
import {
  Create,
  CustomForm,
  BreadcrumbsFiled,
  CustomerAutoCompleteInput,
} from '../../..';
import {
  useResource,
  useCustomGetOne,
  useGetSelefDetailData,
  useAccount,
} from '../../../../hooks';
import { BucketData } from '../../../../dataProvider';
import { getParentPath } from '../../../../utils';

const BucketRentalCreate: React.FC = () => {
  const getAccount = useAccount();
  const useId = () => {
    const { bucketId } = useParams<{ bucketId: string }>();
    return bucketId;
  };
  const getSelefDetailData = useGetSelefDetailData();
  // const redirectTo = useRedirect();

  const resource = useResource('bucketShares');
  const bucketsResource = useResource('buckets');
  const { pathname } = useLocation();
  const basePath = getParentPath(pathname);
  const createProps = { resource, basePath };
  const bucketId = useId();
  const getBucket = useCustomGetOne(bucketsResource, bucketId);
  const { data } = getBucket;

  const [apiState, setApiState] = useState<{
    fetched: boolean;
    loading: boolean;
    data?: {
      fetchedBucketId: string;
      isOwnData: boolean;
      corporationId: string;
    };
  }>({ fetched: false, loading: true });

  useEffect(() => {
    if (apiState.fetched && apiState.data?.fetchedBucketId === bucketId) return;

    const api = async () => {
      // accounts
      const {
        data: { corporationId },
      } = await getAccount();

      // 自社のバケットであるか判定
      const filter = { corporationId };
      const isOwnBucket = await getSelefDetailData('buckets', filter, bucketId);
      return {
        data: {
          apiResult: {
            isOwnData: !!isOwnBucket,
            fetchedBucketId: bucketId,
            corporationId,
          },
        },
      };
    };
    api().then(({ data: { apiResult } }) => {
      setApiState({ fetched: true, loading: false, data: apiResult });
    });
  }, [bucketId, getAccount, getSelefDetailData, apiState]);

  if (!data || apiState.loading || !apiState.data) return <Loading />;
  // TODO: 閲覧不可なデータは現場一覧に遷移する機能を有効にする場合、isOwnDataを追加する
  const { corporationId } = apiState.data;
  // 一覧に存在しないバケットデータは表示させない
  // if (!isOwnData) redirectTo('/');

  const record = data as BucketData;
  const fromBukectId = parseInt(bucketId, 10);

  return (
    <Create {...createProps}>
      <CustomForm
        title="admin.pages.bucketRentalCreate"
        redirect={basePath}
        initialValues={{
          fromBucketId: fromBukectId,
          corporationId: '',
          name: record.name,
        }}
      >
        <BreadcrumbsFiled
          breadcrumbs={['resources.buckets.name', record && record.name]}
          label="admin.pages.bucketRentalCreate"
        />
        <CustomerAutoCompleteInput
          label="admin.label.bucketRental.corporation"
          resource={bucketsResource}
          source="corporationId"
          validate={[required()]}
          fromCorporationId={corporationId}
        />
        <TextField resource={resource} source="name" record={record} />
        <TextField
          label="admin.label.bucketRental.bucketType"
          resource={bucketsResource}
          source="bucketType"
          record={record}
        />
        <TextField
          label="admin.label.bucketRental.bk3File"
          resource={bucketsResource}
          source="bk3File.title"
          record={record}
        />
      </CustomForm>
    </Create>
  );
};

BucketRentalCreate.displayName = 'BucketRentalCreate';
export default BucketRentalCreate;
