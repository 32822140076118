import React, { useCallback, useEffect, useState } from 'react';
import {
  useDataProvider,
  useNotify,
  useRedirect,
  useUpdate,
} from 'react-admin';
import { CustomSaveButton, DialogOKOnly } from '../../..';
import { GnssSettingData } from '../../../../dataProvider';
import { SaveIcon } from '../../../../assets';

const SaveButton: React.FC = (props: any) => {
  const resource = 'retrofits';
  const [open, setOpen] = useState(false);
  const { form, basePath, record } = props;
  const notify = useNotify();
  const redirectTo = useRedirect();
  const [update] = useUpdate(resource, record.id, undefined, record);
  const recordGnssSettingId = record.gnssSettingId || '';

  const handleHide = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const [isStatusSending, setIsStatusSending] = useState(false);
  const dataProvider = useDataProvider();

  useEffect(() => {
    const gnssSettingId = form.getFieldState('gnssSettingId')?.value;
    if (!gnssSettingId) return;
    const fn = async () => {
      const { data } = await dataProvider.getOne('gnssSettings', {
        id: gnssSettingId,
      });
      if (data) {
        const gnssData = data as GnssSettingData;
        if (gnssData.status === 'Sending') setIsStatusSending(true);
      }
    };
    fn();
  }, [dataProvider, resource, form]);

  const handleClick = useCallback(
    values => {
      const valueGnssSettingId = form.getFieldState('gnssSettingId')?.value;
      const gnsssettingIdChanged = recordGnssSettingId !== valueGnssSettingId;
      const GnssSettingIdHasValue = valueGnssSettingId !== undefined;

      // エラーダイアログ出力の条件
      // 画面で選択されたGnssSettingIdが未設定ではない
      // かつレトロフィットの GnssSettingsId 現在値と画面で選択された GnssSettingsId が一致しない
      // かつ選択された GnssSettingId のステータスが「送信中」である
      if (gnsssettingIdChanged && isStatusSending && GnssSettingIdHasValue) {
        // 選択した建機に紐づくGnss設定のステータスが 「Sending」であるためエラー
        setOpen(true);
        return;
      }

      update(
        {
          payload: {
            data: {
              ...values,
            },
          },
        },
        {
          onSuccess: () => {
            notify('admin.message.success', 'info');
            redirectTo(basePath);
          },
          onFailure: (error: Error) => {
            notify(`Error: ${error.message}`, 'warning');
          },
        },
      );
    },
    [
      update,
      recordGnssSettingId,
      isStatusSending,
      basePath,
      notify,
      redirectTo,
      form,
    ],
  );

  return (
    <>
      <DialogOKOnly
        open={open}
        onClose={handleHide}
        title=""
        message={'admin.dialog.retrofitEdit.unableSettingGnessInfo.message'}
      />
      <CustomSaveButton {...props} onSave={handleClick}>
        <SaveIcon />
      </CustomSaveButton>
    </>
  );
};

SaveButton.displayName = 'SaveButton';
export default SaveButton;
