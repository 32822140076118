import React, { useState, useCallback } from 'react';
import { DialogOKOnly } from '..';

const InfoDialogButton: React.FC<{
  title: string;
  message?: string;
  messages?: string[];
  button: React.ReactElement<any>;
}> = ({ title, message, messages, button }) => {
  const [open, setOpen] = useState(false);
  const handleShow = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const handleHide = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      {React.cloneElement(button, { onClick: handleShow })}
      <DialogOKOnly
        open={open}
        onClose={handleHide}
        title={title}
        message={message}
        messages={messages}
      />
    </>
  );
};

InfoDialogButton.displayName = 'InfoDialogButton';
export default InfoDialogButton;
