import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDataProvider, required, useRedirect } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import {
  CustomForm,
  Loading,
  BreadcrumbsFiled,
  CustomEdit,
  TextInput,
  PropsConsumer,
} from '../..';
import { findRecordById, LonLat } from '../../../utils';
import { GeofenceMapInput, SaveButton } from './view';
import {
  useGeofenceProps,
  useConvertMapCoordinate,
  useMapController,
  useMapPrimitiveType,
} from './hooks';
import { getGeometriesArray } from './utils';
import { GeofenceData } from '../../../dataProvider';
import { useResult } from '../../../hooks';
import { existEditFeature } from './map/interaction/utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& > div .ra-input-mapInput > div > label': {
        display: 'none',
      },
    },
  }),
);

const GeofenceEdit: FC = () => {
  const classes = useStyles();
  const {
    props,
    loading,
    sites,
    unitLength,
    geofences,
    isOwnData,
    mapBackgroundColor,
  } = useGeofenceProps();
  const geofenceId = props.id || '';
  const convertMapCoordinate = useConvertMapCoordinate();
  const result = useResult();
  const { mapController } = useMapController();
  const handleChangeType = useMapPrimitiveType(mapController);
  const dataProvider = useDataProvider();
  const redirectTo = useRedirect();
  const { siteId } = useParams<{ siteId: string }>();

  const depsGeofences = JSON.stringify(geofences);

  // saveButtonInvalidがtrueの時、保存ボタンは非活性状態
  const [saveButtonInvalid, setSaveButtonInvalid] = useState(true);
  const [mapModified, setMapModified] = useState(false);
  const handleModify = useCallback(() => {
    const validation = mapController.getDisableSaveButton();
    setSaveButtonInvalid(validation);
    setMapModified(true);
  }, [mapController]);

  useEffect(() => {
    if (!depsGeofences || depsGeofences === '[]' || !isOwnData) return;
    const api = async (targetGeofenceId: number): Promise<GeofenceData> => {
      const rawGeofences: GeofenceData[] = JSON.parse(depsGeofences);
      const lonLatId = rawGeofences
        .filter(geofence => geofence.points !== undefined)
        .map(
          geofence =>
            ({
              id: geofence.id,
              coordinates: geofence.points?.map(lonLats => ({
                lon: lonLats[0],
                lat: lonLats[1],
              })),
            } as { id: number; coordinates: LonLat[] }),
        );

      // ジオフェンスの描画処理に必要なため先行してTypeだけ設定
      const geofenceInfo = findRecordById<GeofenceData>(
        rawGeofences,
        targetGeofenceId,
      );
      mapController.setSiteUnitLength(unitLength);
      mapController.setSelectedType(geofenceInfo.type);
      // ジオメトリ配列に変換
      const { geometries } = getGeometriesArray(
        lonLatId,
        rawGeofences,
        targetGeofenceId,
      );
      // setGeometriesの前に全ジオフェンスオブジェクトのtypeのdictionaryを作成
      geometries.forEach(it => {
        mapController.getGeofenceTypeList().set(it.id, it.type);
      });
      // マップにジオフェンス情報を反映
      mapController.setGeometries(geometries);

      // ジオフェンス情報が存在する場合は、boudary を自動調整
      const geofenceInformation = geometries.filter(
        ({ id }) => id === Number(geofenceId),
      );
      const selectedGeofence = geofenceInformation[0];
      const geofenceRadius = selectedGeofence.radius;
      const centerPoint = selectedGeofence.coordinates[0];
      // ジオフェンスタイプがCircleの場合、setBoundaryに渡す座標を調整する
      const boundary: LonLat[] =
        selectedGeofence.type === 'Circle' && geofenceRadius
          ? [
              {
                lon: centerPoint.lon + geofenceRadius, // northEastLon
                lat: centerPoint.lat + geofenceRadius, // northEastLat
              },
              {
                lon: centerPoint.lon - geofenceRadius, // northWestLon
                lat: centerPoint.lat + geofenceRadius, // northWestLat
              },
              {
                lon: centerPoint.lon - geofenceRadius, // southWestLon
                lat: centerPoint.lat - geofenceRadius, // southWestLat
              },
              {
                lon: centerPoint.lon + geofenceRadius, // southEastLon
                lat: centerPoint.lat - geofenceRadius, // southEastLat
              },
            ]
          : selectedGeofence.coordinates;

      if (boundary) {
        mapController.setBoundary(boundary);
        mapController.navigateToHome(0);
      }

      return findRecordById(rawGeofences, targetGeofenceId);
    };
    api(Number(geofenceId)).then(geofenceData => {
      // 別現場のgeofenceIdを設定した場合はデータが取得できないため、後続処理を実施しない
      if (!geofenceData) return;
      // 各種初期値の設定
      const {
        type,
        dimension,
        rgbRed,
        rgbGreen,
        rgbBlue,
        valid,
        transparency,
        alertType,
        elevation,
        height,
        radius,
        thickness,
        trigger,
        id: featureId,
      } = geofenceData;
      handleChangeType(type);
      mapController.setSelectedType(type);
      mapController.setSelectedDimension(dimension);
      mapController.setSelectedColor({
        r: rgbRed,
        g: rgbGreen,
        b: rgbBlue,
        a: 1,
      });
      mapController.setSelectedValid(valid);
      mapController.setSelectedTransparency(transparency);
      mapController.setSelectedAlertype(alertType);
      mapController.setSettingElevation(elevation);
      mapController.setSettingHeight(height);
      mapController.setSettingRadius(radius);
      mapController.setSettingThickness(thickness);
      mapController.setSettingTrigger(trigger);
      mapController.setModifyMode();
      mapController.setSelectFeature(featureId);
      // Map上の入力コンポーネントをrender
      mapController.renderInputComponent(mapController.getMapProps());
      // typeのコンポーネントを最新化
      mapController.renderTypeControl(
        mapController.getMapProps(),
        existEditFeature(featureId, mapController.getMapProps().sourceVector),
      );
      // 入力欄の未入力チェック
      const validation = mapController.getDisableSaveButton();
      setSaveButtonInvalid(validation);
    });
  }, [
    depsGeofences,
    convertMapCoordinate,
    mapController,
    geofenceId,
    handleChangeType,
    isOwnData,
    unitLength,
  ]);

  if (loading || !sites) return <Loading />;

  // 一覧に存在しないgeofenceのデータは表示させない
  if (!isOwnData) redirectTo('/');

  const {
    record: { name: siteName },
  } = sites;

  const handleSave = async () => {
    if (!mapController) {
      return Promise.reject(new Error(`Invalid mapController`));
    }
    const [geometry] = mapController
      .getGeometries()
      .filter(({ id }) => id === Number(geofenceId));
    if (!geometry) {
      return Promise.reject(new Error(`Invalid geometry: ${geofenceId}`));
    }
    return {
      points: geometry.coordinates.map(ll => [ll.lon, ll.lat]),
      type: geometry.type,
      radius: geometry.radius,
    };
  };

  const handleSubmit = async (
    data: GeofenceData,
    previousData?: GeofenceData,
  ) => {
    if (!previousData) return;
    try {
      await dataProvider.update('geofences', {
        id: data.id,
        data,
        previousData,
      });
      result.success(props.basePath);
    } catch (e) {
      result.failure(e);
    }
  };

  return (
    <CustomEdit {...props}>
      <CustomForm
        className={classes.root}
        title="admin.pages.geofenceEdit"
        saveButton={
          <PropsConsumer>
            {formProps => {
              const { pristine, invalid, form, record } = formProps;
              const disabled = !mapModified
                ? pristine || invalid
                : !mapModified || invalid;
              // 入力欄に空欄がある場合、または登録内容に変更がない場合は保存ボタンは非活性
              const saveButtonDisabled = !!(disabled || saveButtonInvalid);
              return (
                <SaveButton
                  mapController={mapController}
                  onClick={handleSave}
                  onSubmit={handleSubmit}
                  disabled={saveButtonDisabled}
                  form={form}
                  record={record}
                />
              );
            }}
          </PropsConsumer>
        }
      >
        <BreadcrumbsFiled
          breadcrumbs={[
            'resources.sites.name',
            '',
            siteName,
            'resources.geofences.name',
          ]}
          label="admin.breadcrumbs.geofenceEdit"
        />
        <TextInput resource="geofences" source="name" validate={[required()]} />
        <GeofenceMapInput
          source="mapInput"
          siteId={siteId}
          mapController={mapController}
          mapBackgroundColor={mapBackgroundColor}
          visible={true}
          onModify={handleModify}
        />
      </CustomForm>
    </CustomEdit>
  );
};

GeofenceEdit.displayName = 'GeofenceEdit';
export default GeofenceEdit;
