import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { useResource } from '../../../../../hooks';

const useAlternateRegisted = () => {
  const dataProvider = useDataProvider();
  const resource = useResource('retrofitAlternateRegists');

  return useCallback(
    async (retrofitId: number, corporationId: string): Promise<boolean> => {
      const { data: retrofitAlternateRegistData } = await dataProvider.getList(
        resource,
        {
          pagination: {
            page: 1,
            perPage: 1,
          },
          sort: { field: 'id', order: 'ASC' },
          filter: {
            retrofitId,
            fromCorporationId: corporationId,
            isApproved: false,
          },
        },
      );

      return retrofitAlternateRegistData.length > 0;
    },
    [dataProvider, resource],
  );
};

export default useAlternateRegisted;
