import React, { FC, useCallback, useState, useEffect } from 'react';
import {
  Record,
  required,
  usePermissions,
  useDataProvider,
  FormDataConsumer,
  useRedirect,
} from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import {
  useResource,
  useSite,
  useNameValidation,
  useFileNameValidation,
  useGetSelefDetailData,
  useGetUserConfigure,
} from '../../../../../hooks';
import {
  Edit,
  CustomForm,
  BreadcrumbsFiled,
  SelectArrayInput,
  CustomDeleteButton,
  Loading,
  CustomDateField,
  TaskMap,
  TextField,
  TextInput,
  SelectInput,
  DialogOKOnly,
  DoneButton,
  ErrorInfoLayout,
  ErrorInfoField,
  IconField,
  TaskMapModel,
  PropsConsumer,
} from '../../../..';
import { styles } from '../../../../../theme';
import {
  TaskData,
  ProjectsData,
  RetrofitByTaskData,
  UnitLength,
} from '../../../../../dataProvider';
import { getParentParentPath } from '../../../../../utils';
import { SaveIcon, DeleteIcon } from '../../../../../assets';
import { useRetrofitByTasks, useSiteConfigure } from './hooks';
import { CancelButton, SaveButton } from './views';
import { projectToLayerData, LayerParams } from './utils';

const useStyles = makeStyles(() =>
  createStyles({
    description: {
      ...styles.multilineText,
    },
    root: {
      '& .map': {
        display: 'flex', // 注意: デフォルトの inline-flex だと width 100% が効かない
      },
    },
    icon: {
      '& > div > div': {
        marginLeft: -12,
      },
    },
  }),
);

const DeleteButton: FC<{ basePath?: string }> = ({ basePath, ...rest }) => {
  const props = { ...rest, basePath: getParentParentPath(basePath || '') };
  return (
    <CustomDeleteButton
      {...props}
      messageTargetItem="admin.dialog.taskEdit.deleteConfirm.messageTargetItem"
    >
      <DeleteIcon />
    </CustomDeleteButton>
  );
};

const UnavailableButton: FC<{
  messages: string[];
}> = ({ messages }) => {
  const [open, setOpen] = useState(false);
  const handleShow = useCallback(() => {
    setOpen(true);
  }, []);
  const handleHide = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <DoneButton onClick={handleShow} label="ra.action.save">
        <SaveIcon />
      </DoneButton>
      <DialogOKOnly
        open={open}
        onClose={handleHide}
        title=""
        messages={messages}
      />
    </>
  );
};

// 通常、親から流れてくるリソースを同名の props で上書きできない為、
// それを実現するために、別のリソースを適応したい場合に使うTextFeld
const CustomTextField: FC<{
  basePath?: string;
  addLabel?: boolean;
  label?: string;
  realResource: string;
  realSource: string;
  realRecord: Record;
}> = ({ realResource, realSource, realRecord, basePath }) => {
  const props = {
    basePath,
    resource: realResource,
    source: realSource,
    record: realRecord,
  } as any;
  return <TextField {...props} />;
};
CustomTextField.defaultProps = {
  addLabel: true,
  label: '',
};

const TaskRecordConsumer: FC<
  Partial<{
    record: TaskData;
    children: (params: { record: TaskData }) => React.ReactNode;
  }>
> = ({ children, ...rest }) => {
  const { record } = rest;
  if (!record) throw new Error('Invalid record');
  return <>{children ? children({ record, ...rest }) : null}</>;
};

const TaskEdit: FC = () => {
  const resource = useResource('tasks');
  const classes = useStyles();
  const {
    siteId,
    projectId,
    id: taskId,
  } = useParams<{
    siteId: string;
    projectId: string;
    id: string;
  }>();
  const { props, getSite } = useSite('tasks');
  const { basePath } = props;
  const { permissions } = usePermissions();
  const getRetrofitByTasks = useRetrofitByTasks();
  const getSiteConfigure = useSiteConfigure();
  const dataProvider = useDataProvider();
  const getSelefDetailData = useGetSelefDetailData();
  const redirectTo = useRedirect();

  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    taskId: string | undefined;
    data?: {
      siteName: string;
      project: ProjectsData;
      retrofitByTasks: RetrofitByTaskData[];
      unit: UnitLength;
      layerParams: LayerParams;
      isOwnData: boolean;
    };
  }>({ loading: true, fetched: false, taskId });

  const nameValidation = useNameValidation(
    'tasks',
    'admin.validation.duplicatedTaskName',
    { projectVersionId: apiState.data?.project?.latestProjectVersionId || -1 },
    [Number(taskId)],
  );
  const fileNameValidation = useFileNameValidation();

  const [mapModel, setMapModel] = useState<TaskMapModel>();
  const handleBindModel = useCallback(model => setMapModel(model), []);

  const {
    data: { userConfigure },
  } = useGetUserConfigure();
  const mapBackgroundColor = userConfigure
    ? userConfigure.mapBackgroundColor
    : '#ffffff'; // userConfigureデータがなければ地図背景色を白色で表示

  const handleSubmit = useCallback(
    async (record, prevRecord) => {
      await dataProvider.update(resource, {
        id: record.id,
        data: record,
        previousData: prevRecord,
      });
    },
    [resource, dataProvider],
  );

  const [mapModified, setMapModified] = useState(false);
  const handleModify = useCallback(() => {
    setMapModified(true);
  }, []);

  useEffect(() => {
    if (apiState.fetched && apiState.taskId === taskId) return;
    setApiState({ loading: true, fetched: true, taskId });

    const api = async () => {
      // sites
      const { data: site } = await getSite();

      // retrofitByTasks
      const {
        data: { retrofitByTasks },
      } = await getRetrofitByTasks({ siteId, taskId: Number(taskId) });

      const { name: siteName, unitLength } = site;

      // projects
      const { data: project } = await dataProvider.getOne<ProjectsData>(
        'projects',
        {
          id: projectId,
        },
      );

      // projectVersionIdを取得
      const { latestProjectVersionId } = project;

      // プロジェクトと現場に紐づいたタスクであるか判定
      const filter = { siteId, projectVersionId: latestProjectVersionId };
      const task = await getSelefDetailData(resource, filter, taskId);

      // siteConfigures
      const { data: siteConfigures } = await getSiteConfigure({ siteId });
      const [siteConfigure] = siteConfigures;
      const unit = siteConfigure?.unitLength || unitLength;

      // init layer
      const layerParams = projectToLayerData(project.projectSourceLayers);

      return {
        siteName,
        project,
        retrofitByTasks,
        unit,
        layerParams,
        isOwnData: !!task,
      };
    };
    api().then(result => {
      setApiState({
        loading: false,
        fetched: true,
        taskId,
        data: result,
      });
    });
  }, [
    siteId,
    projectId,
    taskId,
    dataProvider,
    apiState.fetched,
    apiState.taskId,
    getSite,
    getRetrofitByTasks,
    getSiteConfigure,
    getSelefDetailData,
    resource,
  ]);

  if (apiState.loading || !apiState.data) return <Loading />;

  const { isOwnData } = apiState.data;
  // 一覧に存在しないtaskのデータは表示させない
  if (!isOwnData) redirectTo('/');

  const {
    siteName,
    project: projectData,
    retrofitByTasks,
    unit,
    layerParams: { layerMap, layerChoices },
  } = apiState.data;
  const { name: projectName, latestProjectVersionId } = projectData;
  const { token } = permissions;
  return (
    <Edit {...props}>
      <TaskRecordConsumer>
        {({ record: taskData, ...rest }) => (
          <CustomForm
            {...rest}
            className={classes.root}
            record={taskData}
            resource={resource}
            title="admin.pages.taskEdit"
            redirect={false}
            cancelButton={<CancelButton basePath={basePath} />}
            deleteButton={<DeleteButton />}
            saveButton={
              projectData.status === 'Converted' &&
              (taskData.status === 'Sending' ||
                taskData.status === 'Sent' ||
                taskData.status === 'FailToSend') ? (
                <PropsConsumer>
                  {formProps => {
                    const { pristine, invalid } = formProps;
                    const disabled = !mapModified
                      ? pristine || invalid
                      : !mapModified || invalid;
                    return (
                      <SaveButton
                        {...formProps}
                        disabled={disabled}
                        latestProjectVersionId={latestProjectVersionId}
                        getCordinates={() => mapModel?.getCoordinates() || []}
                        getIntersect={() => mapModel?.checkIntersect() || false}
                        getSameValue={() => mapModel?.checkSameValue() || false}
                        getAreaZero={(coordinates: number[][]) =>
                          mapModel?.checkAreaZero(coordinates) || false
                        }
                        getCordinateModified={() =>
                          mapModel?.isModified() || false
                        }
                        onSubmit={handleSubmit}
                      />
                    );
                  }}
                </PropsConsumer>
              ) : (
                <UnavailableButton
                  messages={[
                    'admin.dialog.taskList.unableToUpdate.message1',
                    'admin.dialog.taskList.unableToUpdate.message2',
                    'admin.dialog.taskList.unableToUpdate.message3',
                  ]}
                />
              )
            }
          >
            <BreadcrumbsFiled
              breadcrumbs={[
                'resources.sites.name',
                '',
                siteName,
                'resources.projects.name',
                projectName,
              ]}
              label="admin.breadcrumbs.taskEdit"
            />
            {taskData.status === 'Error' ? (
              <ErrorInfoLayout title="admin.dialog.projectConversionStatus.title">
                <CustomTextField
                  realRecord={projectData}
                  realResource="projects"
                  realSource="version"
                  label="resources.projects.fields.version"
                />
                <CustomDateField resource={resource} source="lastUpdated" />
                <IconField
                  resource={resource}
                  source="status"
                  className={classes.icon}
                />
                <ErrorInfoField resource={resource} source="errorInfo" />
              </ErrorInfoLayout>
            ) : null}
            <TextInput
              resource={resource}
              source="name"
              validate={[required(), fileNameValidation, nameValidation]}
            />
            <SelectInput
              resource={resource}
              source="projectSourceLayerId"
              choices={layerChoices}
              optionText="name"
              validate={[required()]}
              resettable={false}
            />
            <FormDataConsumer>
              {({ formData: { projectSourceLayerId } }) => {
                const {
                  rectangle: { coordinates },
                } = taskData;
                const layerId = layerMap[projectSourceLayerId];
                return (
                  <TaskMap
                    onBindModel={handleBindModel}
                    coordinates={coordinates}
                    projectsData={projectData}
                    layerId={layerId}
                    isEditable={true}
                    token={token}
                    unit={unit}
                    projectVersionId={latestProjectVersionId}
                    onModify={handleModify}
                    mapBackgroundColor={mapBackgroundColor}
                  />
                );
              }}
            </FormDataConsumer>
            <CustomDateField resource={resource} source="expiredDate" />
            <TextInput
              resource={resource}
              source="description"
              multiline
              rows={3}
              className={classes.description}
            />
            <SelectArrayInput
              resource={resource}
              source="retrofitIds"
              choices={retrofitByTasks}
              optionText="machineInfoMachineName"
            />
          </CustomForm>
        )}
      </TaskRecordConsumer>
    </Edit>
  );
};

TaskEdit.displayName = 'TaskEdit';
export default TaskEdit;
