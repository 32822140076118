import React, { FC, useState, useCallback } from 'react';
import { useLoading, useNotify, useRedirect } from 'react-admin';
import { CustomSaveButton, DialogOKOnly, Coordinates } from '../../../../..';
import { SaveIcon } from '../../../../../../assets';
import { TaskData } from '../../../../../../dataProvider';
import {
  getParentParentPath,
  math,
  apiErrorHandler,
} from '../../../../../../utils';

const localCoordinatesToNumbers = (
  coordinates: { e: number; n: number }[],
): number[][][] => {
  const arrayPair = coordinates.map(({ e, n }) => [e, n]);
  return arrayPair.length > 0 ? [arrayPair] : [];
};

const toRoundUp = (coordinates: number[][]): number[][] => {
  if (!coordinates) return [];
  const decimalDigits = 3;
  return coordinates.map(array => {
    return array.map(coordinate => math.roundUp(coordinate, decimalDigits));
  });
};

interface Props {
  basePath: string;
  record: TaskData;
  disabled: boolean;
  latestProjectVersionId: number; // 座標変換APIで必要
  getCordinates: () => Coordinates[];
  getIntersect: () => boolean;
  getSameValue: () => boolean;
  getAreaZero: (coordinates: number[][]) => boolean;
  getCordinateModified: () => boolean;
  onSubmit: (
    record: TaskData,
    prevRecord: TaskData | undefined,
  ) => Promise<void>;
}

const SaveButton: FC<Partial<Props>> = ({
  disabled,
  latestProjectVersionId,
  getCordinates = () => [],
  getIntersect = () => true,
  getSameValue = () => true,
  getAreaZero = () => true,
  getCordinateModified = () => true,
  onSubmit,
  ...rest
}) => {
  const { basePath, record } = rest;
  if (!basePath) throw new Error('Invalid basePath');
  if (!onSubmit) throw new Error('Invalid onSubmit');
  if (!latestProjectVersionId)
    throw new Error('Invalid latestProjectVersionId');

  const loading = useLoading();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    message?: string;
  }>({ open: false });

  const handleSave = useCallback(
    async values => {
      const lonLat = getCordinates();
      if (lonLat.length <= 0) {
        setDialogState({
          open: true,
          message: 'admin.dialog.taskMap.noPolygonMessage',
        });
        return;
      }
      if (getIntersect()) {
        setDialogState({
          open: true,
          message: 'admin.dialog.taskMap.crossingMessage',
        });
        return;
      }
      if (getSameValue()) {
        setDialogState({
          open: true,
          message: 'admin.dialog.taskMap.sameValueMessage',
        });
        return;
      }

      let newRecord = { ...values };

      const cordinateModified = getCordinateModified();
      if (cordinateModified) {
        const localCoordinates = lonLat.map(({ lat, lon }) => ({
          n: lat,
          e: lon,
        }));
        // ローカル座標 { e, n }[] から 二次元配列 [ [e,n], [e,n] ] に変換
        const coordinates = localCoordinatesToNumbers(localCoordinates);
        const [checkCoordinates] = coordinates;

        if (getAreaZero(toRoundUp(checkCoordinates))) {
          setDialogState({
            open: true,
            message: 'admin.dialog.taskMap.areaZeroMessage',
          });
          return;
        }

        newRecord = {
          ...values,
          rectangle: {
            type: 'Polygon',
            coordinates,
          },
        };
      }

      try {
        await onSubmit(newRecord, record);
        notify('admin.message.success', 'info');
        redirectTo(getParentParentPath(basePath));
      } catch (error) {
        notify(apiErrorHandler(error), 'warning');
      }
    },
    [
      getCordinates,
      getIntersect,
      getSameValue,
      getAreaZero,
      getCordinateModified,
      onSubmit,
      basePath,
      record,
      notify,
      redirectTo,
    ],
  );

  return (
    <>
      <DialogOKOnly
        open={dialogState.open}
        onClose={() => setDialogState({ open: false })}
        title=""
        message={dialogState?.message}
      />
      <CustomSaveButton
        {...rest}
        disabled={loading || disabled}
        onSave={handleSave}
      >
        <SaveIcon />
      </CustomSaveButton>
    </>
  );
};

SaveButton.displayName = 'SaveButton';
export default SaveButton;
