import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Button } from '../../../..';
import { mapStyle } from '../../../../../theme';
import { MapGeoValid, MapGeoInvalid } from '../../../../../assets';

const onUseStyle = makeStyles(() =>
  createStyles({
    root: {
      ...mapStyle.button,
      backgroundColor: '#000344',
    },
  }),
);

const offUseStyle = makeStyles(() =>
  createStyles({
    root: {
      ...mapStyle.button,
    },
  }),
);

const ValidEditButton: React.FC<{
  onClick?: () => void;
  defaultValid?: boolean;
}> = ({ onClick, defaultValid = true }) => {
  const [valid, setValid] = useState<boolean>(defaultValid);
  const onStyle = onUseStyle();
  const offStyle = offUseStyle();
  return (
    <Button
      onClick={() => {
        if (onClick) onClick();
        setValid(!valid);
      }}
      data-testid={'admin.actions.mapValid'}
      className={valid ? onStyle.root : offStyle.root}
    >
      {valid ? <MapGeoValid /> : <MapGeoInvalid />}
    </Button>
  );
};

ValidEditButton.displayName = 'ValidEditButton';
export default ValidEditButton;
