import React from 'react';
import { MapProps, crearteOverlayControl } from '../../../../../hooks';
import { MapDeleteButton } from '../../../..';

const addDeleteControl = ({ map }: MapProps, onClick: () => void) => {
  map.addControl(
    crearteOverlayControl('map_del', <MapDeleteButton onClick={onClick} />, {
      right: 13,
      top: 350,
    }),
  );
};

export default addDeleteControl;
