import { useNotify, useRedirect } from 'react-admin';
import { apiErrorHandler } from '../utils/error';

const useResult = () => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  return {
    success: (redirect: string) => {
      notify('admin.message.success', 'info');
      redirectTo(redirect);
    },
    failure: (error: unknown) => {
      notify(apiErrorHandler(error), 'warning');
    },
  };
};

export default useResult;
