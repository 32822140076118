import React, { useCallback, useRef, useEffect, useState } from 'react';
import { makeStyles, createStyles, Dialog } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {
  ArrayInput as RaArrayInput,
  useTranslate,
  useLoading,
} from 'react-admin';
import {
  Button,
  DoneButton,
  NormalButton,
  CustomForm,
  MapDialogDeleteButton,
  CustomNumberInput,
  SimpleFormIterator,
} from '../../..';
import { useUnitLength } from '../../../../hooks';
import { LonLat } from '../../../../utils';
import { AddIcon, ReturnIcon, SaveIcon } from '../../../../assets';
import { styles } from '../../../../theme';
import { GeofenceType, UnitLength } from '../../../../dataProvider';

const RoundUpDigits = 3; // 小数第四位で四捨五入し小数第三位で扱う

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& li': {
        border: 'none',
      },
      '& section': {
        display: 'flex',
        '& label': {
          width: 30,
          minWidth: 30,
        },
        '& > div': {
          marginLeft: 4,
          marginRight: 4,
          minWidth: 260,
          '& div.MuiInputBase-root': {
            '& > input': {
              width: 120,
              minWidth: 120,
            },
          },
        },
      },
    },
    header: {
      fontSize: 18,
      margin: 0,
      padding: 24,
    },
    error: {
      fontSize: 12,
      color: '#f44336',
    },
    content: {
      overflowX: 'hidden',
      fontSize: 18,
    },
    footer: {
      margin: 0,
      padding: 24,
      minHeight: 36, // ボタン分の高さ
      boxSizing: 'content-box', // paddingをheightの高さとして扱わない
    },
    arrayInput: {
      marginTop: 0,
      marginBottom: 0,
      '& > label': {
        // ArrayInput の Label をCSSで非表示にする (TextInput のように addLabel が用意されていない為)
        display: 'none',
      },
    },
    radiusInput: {
      '& > div': {
        marginLeft: 4,
        marginRight: 4,
        minWidth: 260,
        left: 4,
        '& div.MuiInputBase-root': {
          right: 106,
          '& > input': {
            width: 120,
            minWidth: 120,
          },
        },
      },
    },
  }),
);

const useAddButtonStyles = makeStyles(() =>
  createStyles({
    button: {
      ...styles.buttonIteratorAdd,
    },
  }),
);

const useIteratorAddButton = (disabled: boolean) => {
  const classes = useAddButtonStyles();
  return (
    <Button
      onClick={e => e.preventDefault()}
      classes={classes}
      disabled={disabled}
    >
      <AddIcon />
    </Button>
  );
};

const useIteratorDeleteButton = (disabled: boolean) => {
  return (
    <MapDialogDeleteButton
      onClick={e => e.preventDefault()}
      disabled={disabled}
    />
  );
};

const useStyleFormHeader = makeStyles(() =>
  createStyles({
    header: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      padding: 24,
    },
  }),
);

interface Coordinate {
  lon: number;
  lat: number;
}

interface CoordinateInput {
  lon?: number | string;
  lat?: number | string;
}

export const useNumberCoordinates = () => {
  const { feetToUnitLength } = useUnitLength();
  return (
    coordinates: CoordinateInput[],
    unitLength: UnitLength,
  ): Coordinate[] => {
    return coordinates.map(({ lon, lat }: CoordinateInput) => {
      const newLon = feetToUnitLength(Number(lon), unitLength);
      const newLat = feetToUnitLength(Number(lat), unitLength);

      return {
        lon: newLon,
        lat: newLat,
      } as Coordinate;
    });
  };
};

const dialogMinWidth = 674;
const dialogMinHeight = 382;

const GeofenceInputDialogForm: React.FC<
  {
    record: any;
    saveButton: React.ReactElement<any> | boolean;
    cancelButton: React.ReactElement<any> | boolean;
    onValueChange: (value: any) => void;
    geofenceType: GeofenceType;
    unit: UnitLength;
  } & React.ReactNode
> = ({
  children,
  record,
  saveButton,
  onValueChange,
  cancelButton,
  geofenceType,
  unit,
}) => {
  const classes = useStyleFormHeader();
  const ref = useRef<HTMLDivElement>(null);
  const { feetToUnitLength } = useUnitLength();

  const toNumberCoordinates = useNumberCoordinates();

  useEffect(() => {
    const dialogElement = ref?.current?.parentElement?.parentElement;
    if (!dialogElement) return;
    dialogElement.style.minWidth = `${dialogMinWidth}px`;
    dialogElement.style.minHeight = `${dialogMinHeight}px`;
  }, [ref]);

  /**
   * フォームバリデーション
   *
   * @param value form の入力値
   *
   * @return form の型に沿ったオブジェクト
   *
   * エラーメッセージは Field に対応する Value に文字列を設定する
   *  (例)
   *   一番目のデータの lon の InputField にバリデーションエラーを表示する
   *
   *   return { coordinates: [{ lon: 'error' }] };
   */
  const formValidation = (value: any) => {
    const { coordinates } = value as {
      coordinates: CoordinateInput[];
    };
    const { radius } = value;
    const raRequired = 'ra.validation.required';
    const validation = coordinates.map(it => {
      // 追加ボタン押下時は (array) => [undefined] が設定される
      if (it === undefined) {
        return { lon: raRequired, lat: raRequired };
      }

      const fieldCheck = (fieldValue: any, fieldName: string) => {
        // 未入力時は undefined、削除時は null が設定される
        if (fieldValue === undefined || fieldValue === null) {
          return { [fieldName]: raRequired };
        }
        return undefined;
      };
      const lonError = fieldCheck(it.lon, 'lon');
      const latError = fieldCheck(it.lat, 'lat');

      return { ...lonError, ...latError };
    });

    // 経度/緯度の必須チェックバリデーション
    const lonLatError =
      validation.filter(it => {
        return JSON.stringify(it) !== '{}'; // エラーがあるかどうか、`{}` 比較している
      }).length > 0;
    if (lonLatError) {
      return { coordinates: validation };
    }

    // 経度/緯度の3点チェックバリデーション
    const minPointsError = coordinates.length < 3; // 矩形を描くのに3点必須である
    if (geofenceType === 'Polygon' && minPointsError) {
      // エラーとして検出するためにダミー値を返す（画面上にはでない、対応する Field が存在しない為）
      return { coordinates: ['error'] };
    }

    // 半径の入力チェック
    if (
      (geofenceType === 'Circle' && !radius) ||
      (geofenceType === 'Circle' && radius <= 0)
    ) {
      // TODO: 返す値が適切ではないため、値を見直す
      return { coordinates: ['error'] };
    }
    const newCoordinates = toNumberCoordinates(coordinates, unit);
    // ジオフェンスタイプがCircleの時、ダイアログに表示される数値をM単位に変換してから登録する
    const convertedRadius = radius
      ? feetToUnitLength(Number(radius), unit)
      : undefined;
    const changedValue = {
      newCoordinates,
      convertedRadius,
    };
    // バリデーションを通過したら、親コンポーネントに最新の値を通知する
    onValueChange(changedValue);
    return {};
  };

  return (
    <div ref={ref}>
      <CustomForm
        validate={formValidation}
        saveButton={saveButton}
        cancelButton={cancelButton}
        record={record}
        classes={classes}
      >
        {children}
      </CustomForm>
    </div>
  );
};

export const useDefaultValues = () => {
  const { meterToUnitLengthRoundUp } = useUnitLength();
  return (lonLat: LonLat[], unitLength: UnitLength): LonLat[] =>
    lonLat.map(({ lon, lat }) => ({
      lon: meterToUnitLengthRoundUp(lon, unitLength, RoundUpDigits),
      lat: meterToUnitLengthRoundUp(lat, unitLength, RoundUpDigits),
    }));
};

const GeofenceInputDialog: React.FC<{
  open: boolean;
  lonLat: LonLat[];
  geofenceType: GeofenceType;
  unit: UnitLength;
  radius?: number;
  onClose: (lonLat: LonLat[] | undefined, radius?: number) => void;
  onIntersectCheck: (lonLat: LonLat[]) => boolean;
  onSameValueCheck: (lonLat: LonLat[]) => boolean;
  onAreaZeroCheck: (lonLat: LonLat[]) => boolean;
}> = ({
  open,
  lonLat,
  geofenceType,
  unit,
  radius,
  onClose,
  onIntersectCheck,
  onSameValueCheck,
  onAreaZeroCheck,
}) => {
  const classes = useStyles();
  const loading = useLoading();
  const translate = useTranslate();
  const [saveCoordinates, setSaveCoordinates] = useState<string>(''); // NOTE: Array で扱うと、何故かset時に無限ループが起きる
  const [saveRadius, setRadius] = useState<number | undefined>(0);
  const errorMessageRef = useRef<HTMLSpanElement>(null);
  const getDefaultValue = useDefaultValues();
  const { meterToUnitLengthRoundUp } = useUnitLength();
  const defaultValues = getDefaultValue(lonLat, unit);

  // 初期表示の入力欄表示数を制御
  // ジオフェンスタイプ:Polygonの座標を初期値に設定
  let emptyValues = [
    { lon: undefined, lat: undefined },
    { lon: undefined, lat: undefined },
    { lon: undefined, lat: undefined },
  ];
  if (geofenceType === 'Circle') {
    emptyValues = [{ lon: undefined, lat: undefined }];
  } else if (geofenceType === 'Line' || geofenceType === 'Wall') {
    emptyValues = [
      { lon: undefined, lat: undefined },
      { lon: undefined, lat: undefined },
    ];
  }

  // 座標入力欄の追加・削除ボタンの表示を制御
  // Polygonの場合のみ、disableAddButtonとdisableRemoveButtonはfalseとなる
  const disableAddButton = !!(
    geofenceType === 'Circle' ||
    geofenceType === 'Line' ||
    geofenceType === 'Wall'
  );
  const disableRemoveButton = !!(
    geofenceType === 'Circle' ||
    geofenceType === 'Line' ||
    geofenceType === 'Wall'
  );

  const record = {
    coordinates: defaultValues.length <= 0 ? emptyValues : defaultValues,
    radius: radius
      ? meterToUnitLengthRoundUp(radius, unit, RoundUpDigits)
      : undefined,
  };

  const handleValueChange = useCallback(
    (newValue: any) => {
      const { newCoordinates, convertedRadius } = newValue;
      const newCoordinatesToString = JSON.stringify(newCoordinates);
      setSaveCoordinates(newCoordinatesToString);
      setRadius(convertedRadius);
    },
    [setSaveCoordinates, setRadius],
  );

  const handleSave = useCallback(async () => {
    const results: LonLat[] = JSON.parse(saveCoordinates);
    // 入力ダイアログ確定前のポリゴン交差チェック
    if (onIntersectCheck(results)) {
      if (errorMessageRef && errorMessageRef.current) {
        const message = 'admin.message.geofence.crossingMessage';
        errorMessageRef.current.innerText = translate(message);
      }
      return;
    }
    // 入力ダイアログ確定前の同一座標チェック
    if (onSameValueCheck(results)) {
      if (errorMessageRef && errorMessageRef.current) {
        const message = 'admin.message.geofence.sameValueMessage';
        errorMessageRef.current.innerText = translate(message);
      }
      return;
    }
    // 入力ダイアログ確定前の面積ゼロチェック
    // 面積チェックはローカル座標で行う → 'results' 変数
    if (geofenceType === 'Polygon' && onAreaZeroCheck(results)) {
      if (errorMessageRef && errorMessageRef.current) {
        const message = 'admin.message.geofence.areaZeroMessage';
        errorMessageRef.current.innerText = translate(message);
      }
      return;
    }
    onClose(results, saveRadius);
  }, [
    onClose,
    onIntersectCheck,
    onSameValueCheck,
    onAreaZeroCheck,
    translate,
    saveRadius,
    errorMessageRef,
    saveCoordinates,
    geofenceType,
  ]);

  const { toDisplayUnitLength } = useUnitLength();

  const addButton = useIteratorAddButton(loading);
  const deleteButton = useIteratorDeleteButton(loading);
  const coordinateLabel = translate('admin.label.tasks.coordinate', {
    unit: toDisplayUnitLength(unit),
  });

  return (
    <>
      <Dialog open={open} className={classes.root}>
        {open ? (
          <>
            <MuiDialogTitle className={classes.header}>
              {coordinateLabel}
            </MuiDialogTitle>
            <MuiDialogContent className={classes.content}>
              <span className={classes.error} ref={errorMessageRef}></span>
              <GeofenceInputDialogForm
                record={record}
                saveButton={
                  <DoneButton onClick={handleSave} disabled={loading}>
                    <SaveIcon />
                  </DoneButton>
                }
                cancelButton={
                  <NormalButton
                    onClick={() => onClose(undefined)}
                    disabled={loading}
                  >
                    <ReturnIcon />
                  </NormalButton>
                }
                onValueChange={handleValueChange}
                geofenceType={geofenceType}
                unit={unit}
              >
                {geofenceType === 'Circle' ? (
                  <>
                    <RaArrayInput
                      className={classes.arrayInput}
                      source="coordinates"
                      defaultValue={record}
                    >
                      <SimpleFormIterator
                        addButton={addButton}
                        removeButton={deleteButton}
                        disableAdd={disableAddButton}
                        disableRemove={disableRemoveButton}
                      >
                        <CustomNumberInput
                          source="lon"
                          label="admin.label.geofenceDialog.coordinateX"
                          disabled={loading}
                        />
                        <CustomNumberInput
                          source="lat"
                          label="admin.label.geofenceDialog.coordinateY"
                          disabled={loading}
                        />
                      </SimpleFormIterator>
                    </RaArrayInput>
                    <div className={classes.radiusInput}>
                      <CustomNumberInput
                        source="radius"
                        label="admin.label.geofenceDialog.radius"
                        disabled={loading}
                      />
                    </div>
                  </>
                ) : (
                  <RaArrayInput
                    className={classes.arrayInput}
                    source="coordinates"
                    defaultValue={record}
                  >
                    <SimpleFormIterator
                      addButton={addButton}
                      removeButton={deleteButton}
                      disableAdd={disableAddButton}
                      disableRemove={disableRemoveButton}
                    >
                      <CustomNumberInput
                        source="lon"
                        label="admin.label.geofenceDialog.coordinateX"
                        disabled={loading}
                      />
                      <CustomNumberInput
                        source="lat"
                        label="admin.label.geofenceDialog.coordinateY"
                        disabled={loading}
                      />
                    </SimpleFormIterator>
                  </RaArrayInput>
                )}
              </GeofenceInputDialogForm>
            </MuiDialogContent>
            <MuiDialogActions className={classes.footer}></MuiDialogActions>
          </>
        ) : (
          false
        )}
      </Dialog>
    </>
  );
};

GeofenceInputDialog.displayName = 'GeofenceInputDialog';
export default GeofenceInputDialog;
