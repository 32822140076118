import React, { FC, useState } from 'react';
import {
  useTranslate,
  useNotify,
  useLoading,
  useRedirect,
  useDataProvider,
} from 'react-admin';
import { DoneButton, DialogOKOnlyWithUrl, LendCorporationData } from '../../..';
import { SaveIcon } from '../../../../assets';
import { RetrofitData } from '../../../../dataProvider';
import { apiErrorHandler, config } from '../../../../utils';
import { useCreateRetrofitAlternateRegists } from '../hooks';
import { useAccount } from '../../../../hooks';

export const serialNumberPrefix = 'serialNumber_';
export const serialNumberErrorPrefix = 'serialNumberError_';

const useRetrofits = () => {
  const dataProvider = useDataProvider();
  return async (serials: string[]): Promise<RetrofitData[]> => {
    const { data } = await dataProvider.getList<RetrofitData>('retrofits', {
      pagination: {
        page: 1,
        perPage: 100, // ただし、シリアル番号 1回での取得を100にする場合は、コントローラ登録で一回でチェックするシリアル番号を件数を絞り込みすることも合わせて検討する
      },
      sort: { field: 'id', order: 'ASC' },
      filter: {
        basicInfoSerialNumber: serials,
      },
    });
    return data;
  };
};

interface Props {
  disabled: boolean;
  form: any;
  customer: LendCorporationData | null;
  checkEarthbrainCorporationId: (retrofits: RetrofitData[]) => Promise<boolean>;
  checkSiteAttachment: (retrofitIds: number[]) => Promise<boolean>;
  checkLending: (retrofitIds: number[]) => Promise<boolean>;
  checkPendingTransfer: (retrofitIds: number[]) => Promise<boolean>;
  checkCorporation: (retrofits: RetrofitData[]) => Promise<boolean>;
  checkUnusedNtipDB: (retrofits: RetrofitData[]) => Promise<boolean>;
  checkDoubleRegistration: (
    retrofits: RetrofitData[],
    retrofitIds: number[],
    corporationId: string,
  ) => Promise<boolean>;
}

const CreatePhotoSaveButton: FC<Partial<Props>> = ({
  disabled,
  form,
  customer,
  checkEarthbrainCorporationId = async () => Promise.resolve(true),
  checkSiteAttachment = async () => Promise.resolve(true),
  checkLending = async () => {
    return true;
  },
  checkPendingTransfer = async () => Promise.resolve(true),
  checkCorporation = async () => Promise.resolve(true),
  checkDoubleRegistration = async () => Promise.resolve(false),
  checkUnusedNtipDB = async () => Promise.resolve(true),
}) => {
  const translate = useTranslate();
  const notify = useNotify();
  const createRetrofitAlternateRegists = useCreateRetrofitAlternateRegists();
  const getAccount = useAccount();
  const loading = useLoading();
  const getRetrofits = useRetrofits();
  const redirectTo = useRedirect();
  const toCorporationId = customer ? customer.id : 'unknown';
  const toCorporationName = customer ? customer.name : 'unknown';
  const basePath = '/retrofitAlternateRegists';
  const scSupportRequestUrl = config.url.scSupportRequest; // エラーダイアログ用URL

  const [errorDialogInfo, setErrorDialogInfo] = useState<{
    open: boolean;
    messages: string[];
    hideUrl?: boolean;
    subMessages?: string[];
  }>({ open: false, messages: [], hideUrl: false, subMessages: [] });

  const handleSave = () => {
    const fields: string[] = form.getRegisteredFields() || [];
    const toMailAddress = form.getFieldState('toMailAddress').value;
    const hideNofityError = 'hideNofityError';
    const serialNumbers = fields
      .filter(it => it.indexOf(serialNumberPrefix) === 0)
      .map(it => form.getFieldState(it).value);
    const errorSerialNumbers = fields
      .filter(it => it.indexOf(serialNumberErrorPrefix) === 0)
      .map(it => form.getFieldState(it).value);

    if (errorSerialNumbers.length > 0) {
      setErrorDialogInfo({
        open: true,
        messages: [
          translate('admin.dialog.retrofitNamePlateFiles.error.serial'),
        ],
        hideUrl: true,
      });
      return;
    }

    const api = async (): Promise<boolean> => {
      const retrofits = await getRetrofits(serialNumbers);

      // 自身の企業情報を取得
      const {
        data: {
          corporationId: fromCorporationId,
          corporationName: fromCorporationName,
          email: fromApplicantId,
          name: fromApplicantName,
        },
      } = await getAccount();

      // 存在しないシリアル番号のチェック
      const validSerials = retrofits.map(it => it.basicInfoSerialNumber);
      const invalidSerials = serialNumbers.filter(
        (it: string) => !validSerials.includes(it),
      );
      if (invalidSerials.length > 0) {
        setErrorDialogInfo({
          open: true,
          messages: [
            translate(
              'admin.dialog.retrofitAlternateRegists.serialNumberExistError.message1',
            ),
            translate(
              'admin.dialog.retrofitAlternateRegists.serialNumberExistError.message2',
            ),
          ],
          subMessages: [
            translate(
              'admin.dialog.retrofitAlternateRegists.serialnumberCheck.message',
            ),
            invalidSerials.join(' , '),
          ],
        });
        throw Error(hideNofityError);
      }

      const retrofitIds = retrofits.map(({ id }) => id);
      // 代理申請承認待ちチェック
      if (
        await checkDoubleRegistration(retrofits, retrofitIds, fromCorporationId)
      ) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // すでに代理申請先企業に紐づいているかチェック ※ メールアドレスのみ入力した場合は判定不可
      const retrofitCorporationId = retrofits.map(it => it.corporationId);
      const findRetrofitIds = retrofitCorporationId.includes(toCorporationId);
      if (findRetrofitIds) {
        // エラーダイアログを表示
        setErrorDialogInfo({
          open: true,
          messages: [
            translate(
              'admin.dialog.retrofitAlternateRegists.corporationIdCheck.message1',
            ),
            translate(
              'admin.dialog.retrofitAlternateRegists.corporationIdCheck.message2',
            ),
          ],
          subMessages: [
            translate(
              'admin.dialog.retrofitAlternateRegists.serialnumberCheck.message',
            ),
            validSerials.join(' , '),
          ],
        });
        return false;
      }
      // EARTHBRAIN企業IDチェック
      if (!(await checkEarthbrainCorporationId(retrofits))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // 利用中企業の制限チェック
      if (!(await checkCorporation(retrofits))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // Ntrip払い出しで未使用NtripDBの合計数が足りるかチェック
      if (!(await checkUnusedNtipDB(retrofits))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // レトロフィット貸与中チェック
      if (!(await checkLending(retrofitIds))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // 譲渡承認待ちチェック
      if (!(await checkPendingTransfer(retrofitIds))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // 現場の紐づきをチェック
      if (!(await checkSiteAttachment(retrofitIds))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // 登録処理
      await createRetrofitAlternateRegists({
        data: retrofits,
        fromCorporationId,
        fromCorporationName,
        toCorporationId,
        toCorporationName,
        toMailAddress,
        fromApplicantId,
        fromApplicantName,
      });
      return true;
    };

    api()
      .then(result => {
        if (result) {
          notify('admin.message.success', 'info');
          redirectTo(basePath);
        }
      })
      .catch(error => {
        const message = apiErrorHandler(error);
        if (message !== hideNofityError) {
          // 想定外エラーはダイアログ表示ではなくnotify表示
          notify(message, 'warning');
        }
      });
  };

  return (
    <>
      <DoneButton
        label="admin.actions.save"
        onClick={handleSave}
        disabled={disabled || loading}
      >
        <SaveIcon />
      </DoneButton>
      <DialogOKOnlyWithUrl
        open={errorDialogInfo.open}
        onClose={() => {
          setErrorDialogInfo({
            ...errorDialogInfo,
            open: false,
          });
        }}
        title=""
        nonTranslateMessage={true}
        messages={errorDialogInfo.messages}
        url={errorDialogInfo.hideUrl ? '' : scSupportRequestUrl} // 銘鈑抽出エラー以外はURLを表示する
        subMessages={errorDialogInfo.subMessages}
      />
    </>
  );
};

CreatePhotoSaveButton.displayName = 'CreatePhotoSaveButton';
export default CreatePhotoSaveButton;
