import React, { FC, useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { PdfFileIcon } from '../../../../assets';
import { TermsConditionDocumentData } from '../../../../dataProvider';
import { Typography } from '../../..';
import { useGetTermsConditions } from '../hooks';

const padding = 38;
const space = 14;

const getBrowserLanguage = (): string => {
  return window.navigator.languages[0].slice(0, 2);
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingTop: padding,
      paddingBottom: padding - space,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: space,
      '& > svg': {
        width: 38,
        marginRight: 8,
      },
      '& > p': {
        fontSize: 14,
        fontWeight: 'bold',
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  }),
);

interface Props {
  termsConditionId: number;
  onConfirmed?: () => void;
}

const TermsCondition: FC<Props> = ({ termsConditionId, onConfirmed }) => {
  const classes = useStyles();
  const getTermsConditions = useGetTermsConditions();
  const [data, setData] = useState<TermsConditionDocumentData[]>([]);
  const [confirmed, setConfirmed] = useState({});

  useEffect(() => {
    const language = getBrowserLanguage().toUpperCase();
    getTermsConditions({ termsConditionId, language }).then(
      ({ data: termsConditions }) => setData(termsConditions),
    );
  }, [termsConditionId, getTermsConditions]);

  const handleClick = (src: string) => {
    window.open(src, '_blank');
    const newConfirmed = { ...confirmed, ...{ [src]: src } };
    if (onConfirmed && Object.keys(newConfirmed).length === data.length) {
      onConfirmed();
      setConfirmed({});
    } else {
      setConfirmed(newConfirmed);
    }
  };

  return (
    <div className={classes.root}>
      {data.map((item, index) => (
        <div className={classes.container} key={index}>
          <PdfFileIcon />
          <Typography onClick={() => handleClick(item.termsFile.src)}>
            {item.title}
          </Typography>
        </div>
      ))}
    </div>
  );
};

TermsCondition.displayName = 'TermsCondition';
export default TermsCondition;
