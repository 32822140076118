import { DataProvider, GetListParams, GetListResult } from 'react-admin';
import { Point } from '..';

const getCoordinate = async (
  dataProvider: DataProvider,
  params: GetListParams,
): Promise<GetListResult> => {
  const { data: coordinates } = await dataProvider.getList<Point>(
    'coordinates',
    params,
  );
  const data = coordinates.map((point: Point) => ({
    id: point.id,
    point,
  }));
  return {
    data,
    total: data.length,
  };
};

export default getCoordinate;
