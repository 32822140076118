import { CreateParams, CreateResult } from 'react-admin';
import { AsbuiltDownloadFileData } from '..';

const getAsbuiltDownloadFilesFake = async (
  params: CreateParams,
): Promise<CreateResult> => {
  if (!params.data) {
    console.log('getAsbuiltDownloadFilesFake param error');
    return { data: { id: -1 } };
  }
  const { data: abuiltDownloadFiles } = params;
  // Fake なので Filter の中身を加工して返す
  const data: AsbuiltDownloadFileData = {
    id: `fake_${abuiltDownloadFiles.asbuiltFileId}_${abuiltDownloadFiles.decimalDelimiter}_${abuiltDownloadFiles.separatorDelimiter}`,
    asbuiltFileId: abuiltDownloadFiles.asbuiltFileId,
    decimalDelimiter: abuiltDownloadFiles.decimalDelimiter,
    separatorDelimiter: abuiltDownloadFiles.separatorDelimiter,
    url: `fake_downloadFile_${abuiltDownloadFiles.asbuiltFileId}`,
  };
  return {
    data,
  };
};

export default getAsbuiltDownloadFilesFake;
