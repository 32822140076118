import React from 'react';
import { MapProps, crearteOverlayControl } from '../../../../../hooks';
import { MapZoomButton } from '../../../..';
import define from '../../../../organisms/taskmap/define'; // TODO: 共通化の課題

const addZoomControl = ({ map, view }: MapProps) => {
  const zoom = view.getZoom() || 0;
  const { zoomDuration, changeZoomLevel } = define;
  let currentZoom = zoom;
  map.addControl(
    crearteOverlayControl(
      'map_zoom',
      <MapZoomButton
        onClickZoomIn={() => {
          const mapZoom = Math.round(view.getZoom() || 0);
          currentZoom = mapZoom + changeZoomLevel;
          view.animate({
            duration: zoomDuration,
            zoom: currentZoom,
          });
        }}
        onClickZoomOut={() => {
          const mapZoom = Math.round(view.getZoom() || 0);
          currentZoom = mapZoom - changeZoomLevel;
          if (currentZoom < 0) {
            currentZoom = 0;
          }
          view.animate({
            duration: zoomDuration,
            zoom: currentZoom,
          });
        }}
      />,
      { left: 11, top: 12 },
    ),
  );
};

export default addZoomControl;
