import React from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles, createStyles, Dialog } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { ReactElementLike } from 'prop-types';
import { Button } from '..';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../theme/define';
import { colors } from '../../theme';
import { OkIcon } from '../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      fontSize: 18,
      margin: 0,
      padding: 24,
    },
    content: {
      padding: 0,
    },
    footer: {
      margin: 0,
      padding: 24,
    },
    button: {
      background: colors.button.save,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.saveHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.saveDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
      '& span': {
        padding: 0,
      },
    },
  }),
);

interface Props {
  open: boolean;
  onClose: () => void;
  title?: string;
  okLabel?: string;
  children?: ReactElementLike;
  testId?: string;
}

const CustomDialog: React.FC<Props> = ({
  open,
  onClose,
  title,
  okLabel,
  children,
  testId,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const dialogTitle = title ? translate(title) : null;
  return (
    <>
      <Dialog open={open} onClose={onClose} data-testid={testId}>
        {open ? (
          <>
            <MuiDialogTitle className={classes.header}>
              <span>{dialogTitle}</span>
            </MuiDialogTitle>
            <MuiDialogContent className={classes.content}>
              {children}
            </MuiDialogContent>
            <MuiDialogActions className={classes.footer}>
              <Button
                className={classes.button}
                data-testid={okLabel}
                onClick={onClose}
                label=""
              >
                <OkIcon />
              </Button>
            </MuiDialogActions>
          </>
        ) : null}
      </Dialog>
    </>
  );
};

CustomDialog.displayName = 'CustomDialog';
CustomDialog.defaultProps = {
  title: '',
  okLabel: '',
};
export default CustomDialog;
