import { verify, decode } from 'jsonwebtoken';
import JwksClient from 'jwks-rsa';
import { config } from '../../utils';

interface Header {
  typ: string;
  alg: string;
  kid: string;
}

interface Payload {
  iss: string;
  sub: string;
  aud: string;
  exp: number;
  iat: number;
  authTime: number;
  nonce: string;
  jti: string;
  sid: string;
  atHash: string;
}

interface Jwt {
  header: Header;
  payload: Payload;
  signature: string;
}

const { issuerUri, clientId, apiUri } = config.auth.landlog;
const getUri = () => {
  if (!apiUri.startsWith('/')) return apiUri;
  // JwksClientはルートからのパスを指定した呼び出しはできないのでフルのURLを作成する
  const { protocol, host } = window.location;
  return `${protocol}//${host}${apiUri}`;
};
const publicKeyUri = `${getUri()}/connect/jwks`;

const getKey = (header: Header) => {
  const client = JwksClient({ strictSsl: false, jwksUri: publicKeyUri });

  return new Promise<string>((resolve, reject) => {
    client.getSigningKey(header.kid, (error, key) => {
      if (error) reject(error);
      else {
        const signingKey = key.getPublicKey();
        if (!signingKey) reject();
        else resolve(signingKey);
      }
    });
  });
};

export default async (token: string) => {
  const { header } = decode(token, { complete: true }) as Jwt;
  const key = await getKey(header);

  return new Promise<void>((resolve, reject) =>
    verify(token, key, { issuer: issuerUri, audience: clientId }, error => {
      if (error) reject(error);
      else resolve();
    }),
  );
};
