import React, { FC, ReactText, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { required, useTranslate } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  BreadcrumbsFiled,
  Loading,
  Create,
  CustomForm,
  LinkButton,
  TextInput,
  SelectInput,
  ReferenceInput,
  CustomNumberInput,
} from '../../../..';
import {
  useCreateInSite,
  useCustomGetOne,
  useUIntegerValidation,
  useGetUnitLength,
  useUnitLength,
  useGetSelefDetailData,
} from '../../../../../hooks';
import { styles } from '../../../../../theme';
import { ReturnIcon } from '../../../../../assets';
import { CompactionLayerData } from '../../../../../dataProvider';

const useStyles = makeStyles(() =>
  createStyles({
    description: {
      ...styles.multilineText,
    },
  }),
);

const CancelButton: FC<{ basePath?: string }> = ({ basePath }) => {
  return (
    <LinkButton path={basePath} label="ra.action.cancel">
      <ReturnIcon />
    </LinkButton>
  );
};

const CompactionLayerCreate: FC = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const resource = 'compactionLayers';
  const { siteId, projectId, compactionWorkAreaId } = useParams<{
    siteId: string;
    projectId: string;
    compactionWorkAreaId: string;
  }>();
  const { props, site } = useCreateInSite(resource);
  const integerValidation = useUIntegerValidation();
  const getSelefDetailData = useGetSelefDetailData();
  // const redirectTo = useRedirect();
  const { data: projectData } = useCustomGetOne(
    'projects',
    projectId as ReactText,
  );
  const { data: compactionWorkAreaData } = useCustomGetOne(
    'compactionWorkAreas',
    compactionWorkAreaId as ReactText,
  );

  const { toDisplayUnitLength, feetToUnitLength } = useUnitLength();
  const {
    loading: unitLengthLoading,
    data: { unitLength },
  } = useGetUnitLength({ siteId });

  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: {
      fetchedprojectId: string;
      fetchedCompactionWorkAreaId: string;
      isOwnData: boolean;
    };
  }>({ loading: true, fetched: false });

  useEffect(() => {
    if (
      apiState.fetched &&
      apiState.data?.fetchedprojectId === projectId &&
      apiState.data?.fetchedCompactionWorkAreaId === compactionWorkAreaId
    )
      return;
    setApiState({ loading: true, fetched: true });

    const api = async () => {
      // 現場,プロジェクトに紐づいた施工領域であるか判定
      const filter = { siteId, projectId, enable: true };

      const isOwnCompactionWorkAreas = await getSelefDetailData(
        'compactionWorkAreas',
        filter,
        compactionWorkAreaId,
      );

      return {
        fetchedprojectId: projectId,
        fetchedCompactionWorkAreaId: compactionWorkAreaId,
        isOwnData: !!isOwnCompactionWorkAreas,
      };
    };
    api().then(result => {
      setApiState({
        loading: false,
        fetched: true,
        data: result,
      });
    });
  }, [
    siteId,
    apiState.fetched,
    apiState.data,
    projectId,
    compactionWorkAreaId,
    getSelefDetailData,
  ]);

  if (apiState.loading || !apiState.data) return <Loading />;
  // TODO: 閲覧不可なデータは現場一覧に遷移する機能を有効にする場合、コメントアウトを外す
  // const { isOwnData } = apiState.data;
  // 施工領域が自社のものでない場合、転圧レイヤ作成画面は表示させない
  // if (!isOwnData) redirectTo('/');

  if (!projectData || !site || !compactionWorkAreaData || unitLengthLoading) {
    return <Loading />;
  }

  const { basePath } = props as { basePath: string };
  const { name: siteName } = site;
  const { name: projectName } = projectData;
  const { name: compactionWorkAreaName } = compactionWorkAreaData;

  const thicknessLabel = translate('admin.label.compactionLayers.thickness', {
    unit: toDisplayUnitLength(unitLength),
  });

  const roundUpDigits = 3; // 小数第四位で四捨五入し小数第三位で扱う

  // 保存用の単位変換
  const handleTransform = (record: CompactionLayerData) => ({
    ...record,
    thickness:
      // ft 系単位の場合は ft -> m へ変換する
      feetToUnitLength(record.thickness, unitLength),
  });

  return (
    <Create {...props} transform={handleTransform}>
      <CustomForm
        redirect={basePath}
        title="admin.pages.compactionLayerCreate"
        resource={resource}
        cancelButton={<CancelButton basePath={basePath} />}
        initialValues={{
          compactionWorkAreaId: Number(compactionWorkAreaId),
          inUse: false,
          enable: true,
        }}
      >
        <BreadcrumbsFiled
          breadcrumbs={[
            'resources.sites.name',
            '',
            siteName,
            'resources.projects.name',
            projectName,
            '',
            compactionWorkAreaName,
          ]}
          label="admin.breadcrumbs.compactionLayerCreate"
        />
        <TextInput resource={resource} source="name" validate={[required()]} />
        <CustomNumberInput
          resource={resource}
          source="thickness"
          label={thicknessLabel}
          validate={[required()]}
          limitDecimal={roundUpDigits}
        />
        <CustomNumberInput
          resource={resource}
          source="passCount"
          validate={[required(), integerValidation]}
        />
        <ReferenceInput
          resource={resource}
          source="compactionMaterialId"
          reference="compactionMaterials"
          link=""
          sort={{ field: 'name', order: 'ASC' }}
          filter={{ siteId }}
        >
          <SelectInput resource={resource} source="compactionMaterialId" />
        </ReferenceInput>
        <TextInput
          resource={resource}
          source="description"
          multiline
          rows={3}
          className={classes.description}
        />
      </CustomForm>
    </Create>
  );
};

CompactionLayerCreate.displayName = 'CompactionLayerCreate';
export default CompactionLayerCreate;
