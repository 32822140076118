import { useCallback } from 'react';
import { useGetListAllPage } from '.';
import { RetrofitData } from '../dataProvider';

interface InParams {
  retrofitIds: number[];
}

interface OutParams {
  data: {
    isAttachedToSite: boolean;
    attachedRetrofitIds: number[];
  };
}

const useCheckSiteAttachment = () => {
  const getListAllPage = useGetListAllPage();

  return useCallback(
    async ({ retrofitIds }: InParams): Promise<OutParams> => {
      // レトロフィットを登録された分全件取得
      const retrofits = await getListAllPage({
        resource: 'retrofits',
        filter: { id: retrofitIds },
        sort: {
          field: 'id',
          order: 'DESC',
        },
      });

      // 現場に紐づいている建機を抽出
      const attachedRetrofits = retrofits.data.filter(
        retrofit => retrofit.siteId !== undefined && retrofit.siteId !== '',
      ) as RetrofitData[];

      return {
        data: {
          isAttachedToSite: attachedRetrofits.length > 0,
          attachedRetrofitIds: attachedRetrofits.map(({ id }) => id),
        },
      };
    },
    [getListAllPage],
  );
};

export default useCheckSiteAttachment;
