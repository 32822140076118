import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CRUD_DELETE,
  required,
  useDelete,
  useNotify,
  useRedirect,
} from 'react-admin';
import { ReactElementLike } from 'prop-types';
import {
  Edit,
  CustomForm,
  BreadcrumbsFiled,
  TextInput,
  FunctionField,
  Typography,
  LinkButton,
  Loading,
  DialogOKOnly,
  CustomSaveButton,
  LoadingView,
  Button,
  DialogOKCancel,
  RetrofitAutoCompleteInput,
} from '../..';
import {
  useResource,
  usePageProps,
  useGnssSettingProvider,
  useAccount,
  useGetSelefDetailData,
} from '../../../hooks';
import { GnssOwner, ReturnIcon, SaveIcon, DeleteIcon } from '../../../assets';
import gnssSettingUtils from './gnssSettingUtils';
import { GnssSettingData } from '../../../dataProvider';
import { colors } from '../../../theme';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../../theme/define';
import { apiErrorHandler } from '../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    deleteButton: {
      background: colors.button.delete,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        background: colors.button.deleteHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.deleteDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
  }),
);

const SaveButton: React.FC = (props: any) => {
  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const { form, basePath, record } = props;
  const notify = useNotify();
  const redirectTo = useRedirect();
  const gnssSettingProvider = useGnssSettingProvider(basePath, record);

  const handleShow = useCallback(
    (message: string) => {
      setDialogMessage(message);
      setOpen(true);
    },
    [setOpen, setDialogMessage],
  );
  const handleHide = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleResult = {
    onSuccess: () => {
      notify('admin.message.success', 'info');
      redirectTo(basePath);
    },
    onFailure: (error: Error) => {
      notify(`Error: ${error.message}`, 'warning');
    },
  };

  const handleClick = useCallback(
    values => {
      const retrofitId = form.getFieldState('retrofitId')?.value;
      if (!retrofitId) {
        // 対象建機が未設定の場合、そのままupdate処理を実施
        gnssSettingProvider.update(values, handleResult);
        return;
      }
      // 対象建機が設定されている場合は、選択した建機に別のGnss設定が紐づけられていないかを確認
      gnssSettingProvider.getRecordByRetrofitId(
        retrofitId,
        (gnssSetting: GnssSettingData | undefined) => {
          if (gnssSetting) {
            const { status } = gnssSetting;
            if (status === 'Sending') {
              // 選択した建機に紐づくGnss設定のステータスが 「Sending」であるためエラー
              handleShow(
                'admin.dialog.gnssSettings.unableSettingGnessInfo.message',
              );
              return;
            }
          }
          gnssSettingProvider.update(values, handleResult);
        },
      );
    },
    [form, handleShow, gnssSettingProvider, handleResult],
  );

  return (
    <>
      <DialogOKOnly
        open={open}
        onClose={handleHide}
        title=""
        message={dialogMessage}
      />
      <CustomSaveButton {...props} onSave={handleClick}>
        <SaveIcon />
      </CustomSaveButton>
    </>
  );
};

const DeleteButton: React.FC<
  Partial<{
    record: GnssSettingData;
    basePath: string;
    disabled: boolean;
  }>
> = ({ record, basePath, disabled }) => {
  const { id, retrofitId } = record || { id: '', retrofitId: undefined };
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const resource = 'gnssSettings';
  const notify = useNotify();
  const redirectTo = useRedirect();

  const [deleteOne] = useDelete(resource, id, record, {
    action: CRUD_DELETE,
    onSuccess: () => {
      notify('ra.notification.deleted', 'info', { smart_count: 1 });
      redirectTo(basePath || '');
    },
    onFailure: error => {
      notify(apiErrorHandler(error), 'warning');
      setOpen(false);
    },
    undoable: false,
  });

  const handleShow = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleHide = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const dialogMsgResource = retrofitId
    ? 'admin.dialog.gnssSettings.confirmDeleteWithMachine.message'
    : 'admin.dialog.gnssSettings.confirmDelete.message';

  return (
    <>
      <Button
        className={classes.deleteButton}
        onClick={handleShow}
        label=""
        data-testid="ra.action.delete"
        disabled={disabled}
      >
        <DeleteIcon />
      </Button>
      <DialogOKCancel
        open={open}
        onClose={handleHide}
        onOKClick={deleteOne}
        title="admin.dialog.retrofitRentalEdit.unapprovedDelete.title"
        message={dialogMsgResource}
        isDelete={true}
      />
    </>
  );
};

const CancelButton: React.FC<{ basePath?: string }> = props => {
  const { basePath = 'gnssSettings' } = props;
  return (
    <LinkButton path={basePath} label="ra.action.cancel">
      <ReturnIcon />
    </LinkButton>
  );
};

const WithRecord: React.FC<{
  record?: GnssSettingData;
  children?: (record: GnssSettingData) => ReactElementLike;
}> = ({ children, ...rest }) => {
  const { record } = rest;
  return (
    <>
      {children && record ? (
        React.cloneElement(children(record), { ...rest })
      ) : (
        <></>
      )}
    </>
  );
};

interface ApiResult {
  isOwnData: boolean;
  corporationId: string;
  corporationName: string;
}

const GnssSettingOwnerEdit: React.FC<{}> = () => {
  const resource = useResource('gnssSettings');
  const props = usePageProps(resource);
  const getAccount = useAccount();
  const getSelefDetailData = useGetSelefDetailData();
  const redirectTo = useRedirect();
  const { basePath } = props;
  const redirect = '/gnssSettings';
  const { id: gnssSettingId } = useParams<{ id: string }>();
  const [status, setStatus] = useState('');

  const [apiState, setApiState] = useState<{
    fetched: boolean;
    loading: boolean;
    gnssSettingId: string | undefined;
    data?: ApiResult;
  }>({
    fetched: false,
    loading: true,
    gnssSettingId: undefined,
    data: undefined,
  });

  useEffect(() => {
    if (apiState.fetched && apiState.gnssSettingId === gnssSettingId) return;
    setApiState({ fetched: true, loading: true, gnssSettingId });

    const api = async (): Promise<{
      data: {
        apiResult: ApiResult;
      };
    }> => {
      // account
      const {
        data: { corporationId, corporationName },
      } = await getAccount();

      const filter = { ownerCorporationId: corporationId };
      const gnssSetting = await getSelefDetailData(
        resource,
        filter,
        gnssSettingId,
      );
      return {
        data: {
          apiResult: {
            isOwnData: !!gnssSetting,
            corporationId,
            corporationName,
          },
        },
      };
    };
    api().then(({ data: { apiResult } }) => {
      setApiState({
        fetched: true,
        loading: false,
        data: apiResult,
        gnssSettingId,
      });
    });
  }, [
    getAccount,
    basePath,
    apiState.fetched,
    apiState.gnssSettingId,
    getSelefDetailData,
    gnssSettingId,
    resource,
  ]);

  if (apiState.loading || !apiState.data) return <Loading />;

  const { isOwnData, corporationId, corporationName } = apiState.data;
  if (!isOwnData) redirectTo('/');

  const isSending = status === 'Sending';
  return (
    <LoadingView>
      <Edit {...props}>
        <CustomForm
          icon={<GnssOwner />}
          title="admin.pages.gnssSettingOwnerEdit"
          resource="gnssSettings"
          redirect={redirect}
          saveButton={<SaveButton />}
          cancelButton={<CancelButton basePath={basePath} />}
          deleteButton={<DeleteButton disabled={isSending} />}
          initialValues={
            {
              ownerCorporationId: corporationId,
              ownerCorporationName: corporationName,
              registCorporationId: corporationId,
              registCorporationName: corporationName,
            } as any
          }
        >
          <BreadcrumbsFiled
            breadcrumbs={['resources.gnssSettings.name']}
            label="ra.action.edit"
          />
          <FunctionField
            resource={resource}
            source="ownerCorporationId"
            render={() => {
              return <Typography>{corporationId}</Typography>;
            }}
          />
          <FunctionField
            resource={resource}
            source="ownerCorporationName"
            label="admin.label.gnssSettingsEdit.ownerCorporationName"
            render={() => {
              return <Typography>{corporationName}</Typography>;
            }}
          />

          <TextInput
            resource={resource}
            source="name"
            validate={[required()]}
            disabled={isSending}
          />
          <TextInput
            resource={resource}
            source="url"
            validate={[required()]}
            disabled={isSending}
          />
          <TextInput
            resource={resource}
            source="portnum"
            validate={[required(), gnssSettingUtils.halfWidthAlphanumericValid]}
            disabled={isSending}
          />
          <TextInput
            resource={resource}
            source="userId"
            validate={[required()]}
            disabled={isSending}
          />
          <TextInput
            resource={resource}
            source="password"
            validate={[required()]}
            disabled={isSending}
          />
          <WithRecord>
            {(record: GnssSettingData) => {
              setStatus(record.status || '');
              return (
                <RetrofitAutoCompleteInput
                  source="retrofitId"
                  filter={{
                    ownerCorporationId: corporationId,
                  }}
                  label="resources.gnssSettings.fields.retrofitId"
                />
              );
            }}
          </WithRecord>
        </CustomForm>
      </Edit>
    </LoadingView>
  );
};

GnssSettingOwnerEdit.displayName = 'gnssSettingOwnerEdit';
export default GnssSettingOwnerEdit;
