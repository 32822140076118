import { GetPointAvailabilityApiData } from '../../types';

const pointavailability: GetPointAvailabilityApiData[] = [
  {
    id: 'remotesession7788222xxxxx431',
    pointAvailabilityStatus: 1000,
    statusComment: '1000',
  },
  {
    id: 'remotesession7788222xxxxx432',
    pointAvailabilityStatus: 5005,
    statusComment: '5005',
  },
  {
    id: 'remotesession7788222xxxxx433',
    pointAvailabilityStatus: 1000,
    statusComment: '1000',
  },
];
export default pointavailability;
