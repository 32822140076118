import { SiteStatus, UnitLength } from '../dataProvider';

const toViewSiteStatus = (
  status: 'waiting' | 'working' | 'completed',
): SiteStatus => {
  switch (status) {
    case 'waiting':
      return 'Waiting';
    case 'working':
      return 'Working';
    default:
      return 'Completed';
  }
};

const toViewUnitLength = (unitLength: string): UnitLength => {
  switch (unitLength) {
    case 'ft':
      return 'Ft';
    case 'ftUS':
      return 'UsFt';
    default:
      return 'M';
  }
};

export { toViewSiteStatus, toViewUnitLength };
