import { FileField, FieldProps, InjectedFieldProps } from 'react-admin';

interface Props extends FieldProps {
  src?: string;
  // FIXME: source と関連付ける
  title?: string;
  target?: string;
  classes?: object;
}

FileField.displayName = 'FileField';
// FIXME: ResourceFieldFC 準拠にする
export default FileField as React.FC<Props & InjectedFieldProps>;
