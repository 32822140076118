import { AdminApiConverter } from '..';

const converter: AdminApiConverter<
  'compactionMaterials',
  'compactionMaterials'
> = {
  resource: 'compactionMaterials',
  defaultSort: { field: 'name', order: 'ASC' },
  filterToApi: filter => filter,
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(record => {
        if (!record.id) throw new Error("Can't get id from API");
        return {
          id: record.id,
          siteId: record.siteId || '',
          name: record.name || '',
        };
      }),
    ),
  recordToApi: record => ({
    name: record.name,
    siteId: record.siteId,
  }),
};

export default converter;
