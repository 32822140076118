const getFileExt = (fileName: string): string => {
  const i = fileName.lastIndexOf('.');
  if (i === -1) return '';
  return fileName.slice(i + 1).toLowerCase();
};

interface FilterType {
  [key: string]: number;
}

const validFileType = (files: string[], targetFiles: string[]) => {
  const filteredFileMap: FilterType = targetFiles.reduce<FilterType>(
    (p, target) => {
      const { length } = files.filter(file => getFileExt(file) === target);
      return { ...p, [target]: length }; // length : 添付されたチェック対象のファイル数
    },
    {},
  );
  let hasTarget = false;
  try {
    targetFiles.forEach((target: string) => {
      const length = filteredFileMap[target];
      if (hasTarget && length >= 1) throw new Error('two file types');
      if (length >= 2) throw new Error('file duplicate');
      hasTarget = length >= 1;
    });
  } catch {
    return false;
  }
  return true;
};

export default validFileType;
