import { BooleanField as RaBooleanField } from 'react-admin';
import { TypographyProps } from '@material-ui/core/Typography';
import { ResourceFieldFC } from '.';

interface Props {
  valueLabelTrue?: string;
  valueLabelFalse?: string;
}

const BooleanField = RaBooleanField as ResourceFieldFC<Props & TypographyProps>;
BooleanField.displayName = 'BooleanField';

export default BooleanField;
