import {
  SiteBucketCreate,
  SiteBucketEdit,
  SiteProjectCreate,
  SiteProjectEdit,
  SiteRetrofitCreate,
  SiteRetrofitEdit,
  SiteRetrofitRedirect,
  RetrofitRentalCreate,
  RetrofitTransferCreate,
  RetrofitRentalEdit,
  RetrofitTransferEdit,
  BucketRentalCreate,
  ExtensionarmShare,
  ApprovalList,
  ApprovalEdit,
  ApprovalTransferEdit,
  ApprovalAlternateRegistEdit,
  RetrofitErrorList,
  RetrofitAccuracieList,
  RetrofitKitInfoList,
  RetrofitConfigList,
  RetrofitCalibList,
  RetrofitCalibShow,
  RetrofitBasicSettingList,
  RetrofitToposurveyList,
  RetrofitShareFileList,
  SiteAsbuiltCreate,
  SiteExtensionarmCreate,
  RetrofitBulkActionCreate,
  RetrofitSendBack,
  RetrofitSendBackReturn,
  GnssSettingCreate,
  GnssSettingEdit,
  TaskCreate,
  TaskEdit,
  SiteMasterShow,
  CompactionWorkAreaCreate,
  CompactionWorkAreaShow,
  CompactionLayerCreate,
  CompactionLayerEdit,
  UserNoticeList,
  UserNoticeCreate,
  UserNoticeEdit,
  RetrofitManageTab,
  RetrofitManageAdminTab,
  RetrofitManageAdminBulkActionCreate,
  ShowTerms,
  GeofenceCreate,
  GeofenceEdit,
  RetrofitAlternateRegistTab,
  RetrofitAlternateRegistEdit,
  ShowDownloads,
  GeofenceAlertList,
  GeofenceAlertShow,
} from '../components';
import createCustomRoutes from './createCustomRoutes';

// FIXME: 何故か型エラーになるようになったため暫定対応any
const RetrofitInfo: any = (parent: string) => {
  return [
    // 建機 - 各種設定情報
    {
      path: `${parent}/:retrofitId/sharefiles`,
      component: RetrofitShareFileList,
    },
    {
      path: `${parent}/:retrofitId/config`,
      component: RetrofitConfigList,
    },
    {
      path: `${parent}/:retrofitId/kitinfo`,
      component: RetrofitKitInfoList,
    },
    {
      path: `${parent}/:retrofitId/accuracies`,
      component: RetrofitAccuracieList,
    },
    {
      path: `${parent}/:retrofitId/calibs`,
      component: RetrofitCalibList,
    },
    {
      path: `${parent}/:retrofitId/calibs/:id`,
      component: RetrofitCalibShow,
    },
    {
      path: `${parent}/:retrofitId/basic_settings`,
      component: RetrofitBasicSettingList,
    },
    {
      path: `${parent}/:retrofitId/errors`,
      component: RetrofitErrorList,
    },
    {
      path: `${parent}/:retrofitId/toposurveys`,
      component: RetrofitToposurveyList,
    },
  ];
};

export default () =>
  createCustomRoutes([
    // 現場 - 建機
    {
      path: '/sites/:siteId/show/retrofits',
      component: SiteRetrofitRedirect,
    },
    {
      path: '/sites/:siteId/show/retrofits/create',
      component: SiteRetrofitCreate,
    },
    {
      path: '/sites/:siteId/show/retrofits/:id',
      component: SiteRetrofitEdit,
    },
    // 現場 - 建機詳細 - 各種設定情報
    ...RetrofitInfo('/sites/:siteId/show/retrofits'),
    // 現場 - バケット
    {
      path: '/sites/:siteId/show/buckets/create',
      component: SiteBucketCreate,
    },
    {
      path: '/sites/:siteId/show/buckets/:id',
      component: SiteBucketEdit,
    },
    // 現場 - プロジェクト
    {
      path: '/sites/:siteId/show/projects/create',
      component: SiteProjectCreate,
    },
    {
      path: '/sites/:siteId/show/projects/:id',
      component: SiteProjectEdit,
    },
    // 現場 - プロジェクト - タスク
    {
      path: '/sites/:siteId/show/projects/:projectId/tasks/create',
      component: TaskCreate,
    },
    {
      path: '/sites/:siteId/show/projects/:projectId/tasks/:id',
      component: TaskEdit,
    },
    // 現場 - プロジェクト - 転圧
    {
      path: '/sites/:siteId/show/projects/:projectId/compactionWorkAreas/create',
      component: CompactionWorkAreaCreate,
    },
    {
      path: '/sites/:siteId/show/projects/:projectId/compactionWorkAreas/:id',
      component: CompactionWorkAreaShow,
    },
    // 現場 - プロジェクト - 転圧 - 転圧レイヤ
    {
      path: '/sites/:siteId/show/projects/:projectId/compactionWorkAreas/:compactionWorkAreaId/create',
      component: CompactionLayerCreate,
    },
    {
      path: '/sites/:siteId/show/projects/:projectId/compactionWorkAreas/:compactionWorkAreaId/:id',
      component: CompactionLayerEdit,
    },
    // 現場 - Asbuilt
    {
      path: '/sites/:siteId/show/asbuilts/create',
      component: SiteAsbuiltCreate,
    },
    // 現場 - エクステンションアーム
    {
      path: '/sites/:siteId/show/extensionarms/create',
      component: SiteExtensionarmCreate,
    },
    // 現場 - ジオフェンスアラート
    {
      path: '/sites/:siteId/show/geofences/alerts',
      component: GeofenceAlertList,
    },
    {
      path: '/sites/:siteId/show/geofences/alerts/:id',
      component: GeofenceAlertShow,
    },
    // 現場 - ジオフェンス
    {
      path: '/sites/:siteId/show/geofences/create',
      component: GeofenceCreate,
    },
    {
      path: '/sites/:siteId/show/geofences/:id',
      component: GeofenceEdit,
    },
    // 現場 - Master
    {
      path: '/sites/:siteId/show/master',
      component: SiteMasterShow,
    },
    {
      path: '/sites/:siteId/show/master/compactionMaterials',
      component: SiteMasterShow,
    },
    {
      path: '/sites/:siteId/show/master/weathers',
      component: SiteMasterShow,
    },
    {
      path: '/sites/:siteId/show/master/operators',
      component: SiteMasterShow,
    },
    // 建機
    {
      path: '/retrofits/:retrofitId/1/create',
      component: RetrofitRentalCreate,
    },
    {
      path: '/retrofits/:retrofitId/1/:id',
      component: RetrofitRentalEdit,
    },
    {
      path: '/retrofits/:retrofitId/transfer/create',
      component: RetrofitTransferCreate,
    },
    {
      path: '/retrofits/:retrofitId/transfer/:id',
      component: RetrofitTransferEdit,
    },
    // 建機詳細 - 各種設定情報
    ...RetrofitInfo('/retrofits'),
    // バケット
    {
      path: '/buckets/:bucketId/create',
      component: BucketRentalCreate,
    },
    // バケット - エクステンションアーム
    {
      path: '/extensionarms/:id/create',
      component: ExtensionarmShare,
    },
    // 承認待ち建機 - 貸与
    {
      path: '/approvals',
      component: ApprovalList,
    },
    {
      path: '/approvals/assignments/:id',
      component: ApprovalEdit,
    },
    {
      path: '/approvals/transfers/:id',
      component: ApprovalTransferEdit,
    },
    {
      path: '/approvals/:id',
      component: ApprovalAlternateRegistEdit,
    },
    // レトロフィット一括登録
    {
      path: '/retrofitBulkActions/create',
      component: RetrofitBulkActionCreate,
    },
    // センドバック
    {
      path: '/retrofitBasicInfos/:retrofitId/sendBack/create',
      component: RetrofitSendBack,
    },
    // センドバック（返却）
    {
      path: '/retrofitBasicInfos/:retrofitId/sendBack/:id',
      component: RetrofitSendBackReturn,
    },
    // 利用規約表示
    {
      path: '/terms',
      component: ShowTerms,
    },
    // ダウンロード
    {
      path: '/downloads',
      component: ShowDownloads,
    },
    // Ntrip Setting登録
    {
      path: '/gnssSettings/create',
      component: GnssSettingCreate,
    },
    // Ntrip Setting更新
    {
      path: '/gnssSettings/:id',
      component: GnssSettingEdit,
    },
    // お知らせ一覧
    {
      path: '/userNotices',
      component: UserNoticeList,
    },
    // お知らせ作成
    {
      path: '/userNotices/create',
      component: UserNoticeCreate,
    },
    // お知らせ更新
    {
      path: '/userNotices/:id',
      component: UserNoticeEdit,
    },
    // Retrofit管理（タブ）
    {
      path: '/controllerRegistration',
      component: RetrofitManageTab,
    },
    // Retrofit管理（一般:手入力）
    {
      path: '/controllerRegistration/text',
      component: RetrofitManageTab,
    },
    // Retrofit管理（一般:銘板取込）
    {
      path: '/controllerRegistration/photo',
      component: RetrofitManageTab,
    },
    // Retrofit管理（管理者:タブ）
    {
      path: '/controllerRegistrationAdmin',
      component: RetrofitManageAdminTab,
    },
    // Retrofit管理（管理者:手入力）
    {
      path: '/controllerRegistrationAdmin/text',
      component: RetrofitManageAdminTab,
    },
    // Retrofit管理（管理者:銘板取込）
    {
      path: '/controllerRegistrationAdmin/photo',
      component: RetrofitManageAdminTab,
    },
    // Retrofit管理（管理者:一括登録）
    {
      path: '/controllerRegistrationAdmin/bulkAction',
      component: RetrofitManageAdminTab,
    },
    // Retrofit管理（管理者:一括登録）
    {
      path: '/controllerRegistrationAdmin/bulkAction/create',
      component: RetrofitManageAdminBulkActionCreate,
    },
    // 販売管理（利用中企業変更機能）代理申請（登録：タブ）
    {
      path: '/retrofitAlternateRegists/create',
      component: RetrofitAlternateRegistTab,
    },
    // 販売管理（利用中企業変更機能）代理申請（登録：手入力）
    {
      path: '/retrofitAlternateRegists/text/create',
      component: RetrofitAlternateRegistTab,
    },
    // 販売管理（利用中企業変更機能）代理申請（登録：銘板取込）
    {
      path: '/retrofitAlternateRegists/photo/create',
      component: RetrofitAlternateRegistTab,
    },
    // 販売管理（利用中企業変更機能）代理申請（更新）
    {
      path: '/retrofitAlternateRegists/:id',
      component: RetrofitAlternateRegistEdit,
    },
  ]);
