import { createStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useCallback, useState } from 'react';
import { Identifier } from 'react-admin';

import { OpenlayerTooltip } from '../..';
import { TooltipDirection } from '../../molecules/OpenlayerTooltip';

import { AnyObject } from '../../../utils';
import { useSiteRetrofitMap } from '../../../hooks';
import { MachineInfo } from '../../../hooks/useSiteRetrofitMap';

type Position = AnyObject & {
  longitude: number;
  latitude: number;
};

interface Props {
  defaultView?: { zoom?: number } & Partial<MachineInfo>;
  positions: MachineInfo[];
  iconSelector?: (data: AnyObject) => any;
  classes?: object;
  sitePosition: Position;
}

const elementHide = (element: any) => {
  const el = element;
  el.style.display = 'none';
  el.style.opacity = '0';
};

const useStyle = makeStyles(() =>
  createStyles({
    root: { width: '100%', height: '200px', position: 'relative' },
  }),
);

const LocationMap: React.FC<Props> = ({
  defaultView,
  positions,
  iconSelector,
  classes: classOverride,
  sitePosition,
}) => {
  const [direction, setDirection] = useState<TooltipDirection>('top');
  const [top, setTop] = useState<Identifier>(0);
  const [left, setLeft] = useState<Identifier>(0);
  const olmap = useSiteRetrofitMap(
    positions,
    defaultView,
    iconSelector,
    sitePosition,
  );
  const tooltip = document.getElementById('tooltip');

  const displayFeatureInfo = useCallback(
    (pixel: number[]) => {
      const mapElement = document.getElementById('map');
      const mapHeight = mapElement?.clientHeight;
      const mapWidth = mapElement?.clientWidth;
      if (!(tooltip && mapHeight && mapWidth)) return;

      const feature = olmap.forEachFeatureAtPixel(pixel, (data: any) => {
        return data;
      });
      if (feature) {
        // 建機アイコンとツールチップの余白
        const TOOLTIP_MARGIN = 20;
        // ツールチップを表示させるが、透明度を0で表示
        tooltip.style.display = 'block';
        // ツールチップに建機名を挿入
        tooltip.innerHTML = feature.get('name');
        // マップの上下の境界値を取得
        const mapHeightHalf = mapHeight / 2;
        // カーソルのY軸を取得
        const pointerPixelY = pixel[1];
        // ツールチップの高さを取得
        const tooltipHeight = tooltip.clientHeight;

        // 建機の位置がマップの半分より上か下かで表示位置を切替
        if (pointerPixelY < mapHeightHalf) {
          setDirection('top');
          setTop(`${pointerPixelY + TOOLTIP_MARGIN}px`);
        } else {
          setDirection('bottom');
          setTop(`${pointerPixelY - tooltipHeight - TOOLTIP_MARGIN}px`);
        }

        // ツールチップの横幅を取得
        const tooltipWidth = tooltip.clientWidth;
        // ツールチップの横幅をの半分を取得
        const tooltipWidthHalf = tooltipWidth / 2;
        // カーソルのX軸を取得
        const pointerPixelX = pixel[0];
        // 上下にツールチップが出るときのポジション(デフォルト)
        setLeft(`${pointerPixelX - tooltipWidthHalf}px`);

        // ツールチップの高さの半分を取得
        const tooltipHeightHalf = tooltipHeight / 2;
        // 左端のアイコンのツールチップがマップからはみ出るときは右にツールチップを表示する
        if (pointerPixelX < tooltipWidthHalf) {
          setDirection('left');
          setTop(`${pointerPixelY - tooltipHeightHalf}px`);
          setLeft(`${pointerPixelX + TOOLTIP_MARGIN}px`);
        }

        // 右端のアイコンのツールチップがマップからはみ出るときは左にツールチップを表示する
        const pointerDiffX = mapWidth - pointerPixelX;
        if (pointerDiffX < tooltipWidthHalf) {
          setDirection('right');
          setTop(`${pointerPixelY - tooltipHeightHalf}px`);
          setLeft(`${pointerPixelX - (tooltipWidth + TOOLTIP_MARGIN)}px`);
        }

        // ツールチップを表示
        tooltip.style.opacity = '1';
      } else {
        elementHide(tooltip);
      }
    },
    [tooltip, olmap, setDirection],
  );

  useEffect(() => {
    olmap.setTarget('map');
    olmap.on('pointermove', (evt: any) => {
      if (evt.dragging && tooltip) {
        elementHide(tooltip);
        return;
      }
      displayFeatureInfo(olmap.getEventPixel(evt.originalEvent));
    });
    return () => olmap.setTarget('');
  }, [olmap, displayFeatureInfo, tooltip]);

  const classes = useStyle({ classes: classOverride });
  return (
    <div id="map" className={classes.root}>
      <OpenlayerTooltip direction={direction} top={top} left={left} />
    </div>
  );
};

LocationMap.displayName = 'LocationMap';

export default LocationMap;
