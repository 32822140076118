import React, { FC } from 'react';
import RetrofitManage from './RetrofitManage';
import { UpdateDataAdmin } from './hooks';

interface Props {
  updateData: UpdateDataAdmin;
  corporationName: string;
}

const RetrofitManageAdmin: FC<Partial<Props>> = ({
  updateData,
  corporationName,
  ...rest
}) => {
  return (
    <RetrofitManage
      corporationName={corporationName}
      updateData={updateData}
      {...rest}
    />
  );
};

RetrofitManageAdmin.displayName = 'RetrofitManageAdmin';
export default RetrofitManageAdmin;
