import React, { useCallback, useEffect, useState } from 'react';
import {
  CustomList,
  Filter,
  SiteDatagrid,
  SiteCardgrid,
  SiteCardIcon,
  SiteListIcon,
  SiteSearchInput,
  SiteCreateButton,
} from '../..';
import { useAccount, useGetSupportMode, useGetUserData } from '../../../hooks';

const ToggleCardButton = ({ toggleCard }: { toggleCard: () => void }) => (
  <SiteCardIcon onClick={() => toggleCard()} />
);

const ToggleListButton = ({ toggleCard }: { toggleCard: () => void }) => (
  <SiteListIcon onClick={() => toggleCard()} />
);

const SiteListFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

const SiteList: React.FC = props => {
  const { enable: supportMode } = useGetSupportMode();
  const getAccount = useAccount();
  const getUserData = useGetUserData();
  const [isCard, setIsCard] = useState(false);
  const [isGAEventSending, setIsGAEventSending] = useState(false);

  useEffect(() => {
    // サポートモード使用中はユーザー情報(Landlog API)を取得しない
    if (!supportMode) {
      if (!isGAEventSending) {
        setIsGAEventSending(true);
        const fn = async () => {
          const {
            data: { email, corporationId: userCorporationId },
          } = await getAccount();
          const userData = await getUserData(userCorporationId, email);
          const { id } = userData[0];
          return {
            id,
            userCorporationId,
          };
        };
        fn().then(result => {
          // GoogleAnalytics イベント送信
          window.gtag('event', 'sc_open', {
            event: 'sc_open',
            sc_corporation_id: result.userCorporationId,
            sc_user_id: result.id,
          });
        });
      }
    }
  }, [supportMode, isGAEventSending, getAccount, getUserData]);

  const toggleCard = useCallback(() => {
    setIsCard(!isCard);
  }, [isCard]);

  return (
    <CustomList
      {...props}
      title="admin.pages.siteList"
      resource="sites"
      subActions={
        isCard ? (
          <ToggleListButton toggleCard={toggleCard} />
        ) : (
          <ToggleCardButton toggleCard={toggleCard} />
        )
      }
      bulkActionButtons={false}
      actions={<SiteCreateButton />}
      filters={<SiteListFilter />}
    >
      {isCard ? <SiteCardgrid /> : <SiteDatagrid />}
    </CustomList>
  );
};

SiteList.displayName = 'SiteList';

export default SiteList;
