// for tr
// トルコ語
import raMessage from 'ra-language-turkish';
import en from './en';

const { ra } = raMessage;
const message: typeof en = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: '',
        toggle_hidden: '',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: 'Bulunamadı',
      page_rows_per_page: 'Sayfa başına satır sayısı:',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'TAMAM',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
      search: 'Aramak',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: '%{name} değerini silmek istiyor musunuz?',
    },
  },
  admin: {
    title: {
      normal: 'Smart Construction Pilot',
      split1: 'Smart Construction',
      split2: 'Pilot',
    },
    copyright: 'Telif hakkı © EARTHBRAIN Ltd. Tüm hakları saklıdır.',
    approval: 'Onay Bekleniyor',
    usermenu: {
      userNotice: 'Bildirim',
      terms: 'Kullanım Şartları',
      manual: 'Manuel',
      downloads: 'İndir',
    },
    breadcrumbs: {
      details: 'Ayrıntılar',
      retrofitEdit: 'Ayrıntılar',
      taskCreate: 'Görev Oluştur',
      taskEdit: 'Görev Ayrıntıları',
      geofenceCreate: 'Oluştur',
      geofenceEdit: 'Düzenle',
      geofenceAlertList: 'Coğrafi Çit Uyarısı',
      geofenceAlertShow: 'Ayrıntılar',
      compactionWorkAreaCreate: 'Sıkıştırılmış Çalışma Alanları Ekle',
      compactionWorkAreaShow: 'Sıkıştırılmış Çalışma Alanları',
      compactionWorkAreaEdit: 'Sıkıştırılmış Çalışma Alanlarını Düzenle',
      compactionLayerCreate: 'Sıkıştırma Katmanları Oluştur',
      compactionLayerEdit: 'Sıkıştırma Katmanlarını Düzenle',
      supportMode: 'Destek Modu',
      retrofitManage: 'Kontrolör Kaydı',
      retrofitManageAdmin: 'Kontrolör Kaydı(Yönetici)',
      retrofitNamePlateFiles: 'Kontrolör Kaydı',
      retrofitAlternateRegistCreate: 'Kontrolör Proxy Kaydı',
      retrofitAlternateRegistEdit: 'Ayrıntılar',
      retrofitManageAdminBulkActionCreate: 'Kayıt',
      terms: 'Kullanım Şartları',
      downloads: 'İndir',
    },
    pages: {
      siteList: 'Şantiye Listesi',
      siteRetrofitCreate: 'Makineyi Şantiyeye Ekle',
      siteRetrofitEdit: '',
      siteBucketCreate: 'Kovayı Şantiyeye Ekle',
      siteBucketEdit: 'Kova Ayrıntıları',
      siteProjectEdit: 'Proje Ayrıntıları',
      siteProjectCreate: 'Proje Dosyası Kaydı',
      siteExtensionarmCreate: 'Uzatılmış Arm Şantiyeye Ekle',
      taskCreate: 'Görev Oluştur',
      taskEdit: 'Görev Ayrıntıları',
      geofenceAlertList: 'Coğrafi Çit Uyarısı',
      geofenceAlertShow: 'Coğrafi Çit Uyarı Ayrıntıları',
      geofenceCreate: 'Coğrafi Çit Oluştur',
      geofenceEdit: 'Coğrafi Çiti düzenle',
      compactionWorkAreaCreate: 'Sıkıştırılmış Çalışma Alanları Ekle',
      compactionLayerCreate: 'Sıkıştırma Katmanları Oluştur',
      compactionLayerEdit: 'Sıkıştırma Katmanlarını Düzenle',
      siteFileEdit: 'Ayrıntılar',
      siteFileCreate: 'Şantiyeye Dosya Ekle',
      retrofitList: 'Makine Listesi',
      retrofitEdit: '',
      retrofitRentalCreate: 'Atama Kaydı',
      retrofitTransferCreate: 'Aktarım Kaydı',
      retrofitSendBack: 'Kaydı Geri Gönder',
      bucketList: 'Kova Listesi',
      bucketEdit: 'Kova Ayrıntıları',
      bucketCreate: 'Kova Kaydı',
      bucketRentalCreate: 'Kova Paylaşımı',
      extensionarmList: 'Uzatma Arm Listesi',
      extensionarmEdit: 'Uzatma Arm Ayrıntıları',
      extensionarmShare: 'Uzatma Arm Paylaşımı',
      retrofitBulkActionList: 'Toplu Kayıt Listesi',
      retrofitBulkActionCreate: 'Toplu Kayıt',
      retrofitBasicInfoList: 'Kontrolör Listesi',
      retrofitBasicInfoCreate: 'Kontrolör Kaydı',
      retrofitBasicInfoEdit: 'Kontrolör Güncellemesi',
      gnssSettingList: 'Ntrip Ayar Listesi',
      gnssSettingCreate: 'Ntrip Ayarı Oluştur',
      gnssSettingAdminCreate: 'Ntrip Ayarı Oluştur',
      gnssSettingOwnerCreate: 'Ntrip Ayarı Oluştur',
      gnssSettingEdit: 'Ntrip Ayarını Düzenle',
      gnssSettingAdminEdit: 'Ntrip Ayarını Düzenle',
      gnssSettingOwnerEdit: 'Ntrip Ayarını Düzenle',
      approvalList: 'Makine Listesi',
      retrofitRentalEdit: 'Atama Ayrıntıları',
      retrofitTransferEdit: 'Aktarım Ayrıntıları',
      asbuiltsCreate: 'İnşaat Sonucunu Al',
      asbuiltsShow: 'Uygulama Geçmişi',
      retrofitConfigList: 'Konfigürasyon Bilgileri',
      retrofitKitInfoList: 'Kontrolör Bilgileri',
      retrofitAccuraciesList: 'Doğruluk Kontrolü Sonucu',
      retrofitAccuraciesShow: 'Ayrıntılar',
      retrofitCalibaList: 'Kalibrasyon Bilgileri',
      retrofitCalibInfo: 'Makine Kalibrasyon Bilgileri Ayrıntıları',
      retrofitBasicSettingList: 'Temel Ayar Bilgileri',
      retrofitErrorList: 'Hata Bilgileri',
      retrofitErrorShow: 'Ayrıntılar',
      retrofitTopographicSurveyList: 'Topografik İnceleme Listesi',
      retrofitTopographicSurveyShow: 'Topografik inceleme Ayrıntıları',
      retrofitShareFileList: 'Uygulama Günlüğü',
      retrofitCorporationLicenseList: 'Lisans Listesi',
      retrofitCorporationLicenseEdit: 'Lisans Ayrıntıları',
      CompactionLayerList: 'Sıkıştırma Katmanları',
      userNoticeList: 'Bildirim Listesi',
      userNoticeCreate: 'Bildirim Ekle',
      userNoticeEdit: 'Güncelleme Bildirimi',
      userNoticeDrawer: 'Bildirim',
      retrofitAlternateRegists: 'Kontrolör Proxy Kaydı Listesi',
      firmwareList: 'Donanım Yazılımı Listesi',
      firmwareShow: 'Donanım Yazılımı Ayrıntıları',
      firmwareCreate: 'Donanım Yazılımı Ekle',
      retrofitManage: 'Metin',
      retrofitNamePlateFiles: 'Fotoğraf',
      retrofitManageAdminBulkAction: 'Toplu Kayıt',
      retrofitManageAdminBulkActionCreate: 'Toplu Kayıt',
      retrofitAlternateRegistCreate: 'Kontrolör Proxy Kaydı',
      retrofitAlternateRegistEdit: 'Kontrolör Proxy Kaydı Ayrıntıları',
      retrofitAlternateRegistCreateText: 'Metin',
      retrofitAlternateRegistCreatePhoto: 'Fotoğraf',
      ntripList: 'Ntrip Bilgileri',
      ntripEdit: 'Ntrip Yönetimi',
    },
    label: {
      login: 'Oturum Aç',
      selected: 'Seçili (%{length} durum)',
      listNoResults: 'Veri bulunamadı',
      maintenance: {
        title: 'Şu anda bakım yapılıyor.',
        message: `Bakımdan dolayı geçici olarak askıya alınmış hizmetlerimiz var.
      Bu rahatsızlıktan dolayı özür dileriz ancak lütfen biraz daha bekleyin.`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, ln3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder:
          'Yüklenecek dosyaları (%{fileTypes}) sürükleyip bırakın veya seçin.',
        maxFileNum:
          'Tek seferde kaydedilebilecek maksimum kontrolör sayısı beştir.',
      },
      nosetting: 'Ayar Yok',
      siteRetrofitList: {
        remoteSupport: 'Uzaktan Destek',
      },
      retrofitEdit: {
        breadcrumbs: 'Ayrıntılar',
        belongsDetails: '',
        ntripSettingInfo: 'Ntrip Ayar Bilgileri',
        machineryDetails: 'Makine',
        retrofitDetails: 'Kontrolör',
        basicInfo: 'Temel Bilgiler',
        controller: 'Kontrolör',
        reciverMain: 'GNSS Ana Alıcı',
        reciverSub: 'GNSS Yedek Alıcı',
        retrofitstatus: 'Kontrolör Durumu',
        license: 'Lisans',
        licenseId: 'Lisans Kimliği',
        expiredDate: 'Son Kullanma Tarihi ',
      },
      retrofitRental: {
        rentalDetails: 'Atama Ayrıntıları',
        rentalInfo: 'Atama Ayrıntıları',
        lending: 'Atama',
        return: 'Geri dön',
        lendingDestination: 'Atanan Şirket',
        lendingCompany: 'Şirket Adı',
        lendingEmail: 'E-posta',
        companyName: 'Şirket Adı',
      },
      retorfitTransfer: {
        tab: 'Aktarım',
        transferInfo: 'Aktarım Ayrıntıları',
        transferButton: 'Aktarım',
        transferEditButton: 'Aktarımı Düzenle',
      },
      siteConfigureEdit: {
        onButton: 'AÇIK',
        offButton: 'KAPALI',
      },
      approvals: {
        rentalTab: 'Atama',
        transferTab: 'Aktarım',
      },
      buckets: {
        bucketTab: 'Kova',
        extensionarmTab: 'Uzatma Arm',
        extensionarmFile: 'Uzatma Arm Dosyası',
        shareTo: 'Şununla Paylaş',
      },
      bucketRental: {
        corporation: 'Şununla Paylaş',
        bucketType: 'Tür',
        bk3File: 'BK3 Dosyası',
      },
      retrofitBasic: {
        basicInfoTab: 'Temel Bilgiler',
        bulkActionTab: 'Toplu Kayıt',
        ntripTab: 'Ntrip Yönetimi',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'Geri Gönder',
        initialize: 'Sıfırlama',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: 'Toplu Güncelleme',
        retrofitDistributor: 'Distribütör',
        retrofitRegion: 'Bölge',
        corporationId: 'Şirket Kimliği',
        corporationName: 'Şirket Adı',
      },
      retrofitBasicInfoEdit: {
        corporationId: 'Şirket Kimliği Kullanımda',
        corporationName: 'Şirket Kullanımda',
      },
      gnssSettingsEdit: {
        ownerCorporationName: 'Şirket Adı',
      },
      terms: {
        title: 'Kullanım Şartları',
        message:
          'Kullanım Şartlarını gözden geçirmek için lütfen aşağıdaki bağlantıya tıklayın.',
        confirmMessage1:
          'Smart Construction Pilot’ı kullanmak için aşağıdaki belgeleri kabul etmeniz gerekir.',
        confirmMessage2:
          'Kabul etmezseniz Smart Construction Pilot’ı kullanamazsınız.',
        select: 'Lütfen bölgenizi seçin.',
      },
      downloads: {
        title: 'Proje Dosyası Dönüştürücü',
        message:
          'Bu, tasarım dosyalarından bir proje dosyası oluşturan bağımsız bir uygulamadır.',
        button: 'İndir',
      },
      retrofitBasicInfoCreate: {
        uuid: 'Otomatik Numaralandırma',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'İndir',
        resultFileDownload: 'İndir',
      },
      tasks: {
        coordinate: 'Koordinatlar (birim:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'Blok Boyutu (%{unit})',
      },
      compactionLayers: {
        thickness: 'Kalınlık (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: 'Ayrıntılar',
        rentalDetail: 'Atama Ayrıntıları',
      },
      pendingTransfers: {
        breadcrumbs: 'Ayrıntılar',
        transferDetail: 'Aktarım Ayrıntıları',
      },
      pendingAlternateRegists: {
        breadcrumbs: 'Ayrıntılar',
        alternateRegistDetail: 'Kontrolör Proxy Kaydı Ayrıntıları',
      },
      supportMode: {
        retrofit: 'Makine',
        optional: 'İsteğe bağlı',
      },
      retrofitList: {
        assignment: 'Atama',
        license: 'Lisans',
      },
      userNotices: {
        languageDetails: 'Bildirim İçeriği',
        language: 'Dil',
        languageEn: 'İngilizce',
        languageJa: 'Japonca',
        languageFr: 'Fransızca',
        languageDe: 'Almanca',
        languageEs: 'İspanyolca',
        Information: 'Bildirim',
        Maintenance: 'Bakım',
        emergency: 'Acil durum',
        important: 'Önemli',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'Kontrolör Kaydı',
        corporationName: 'Şirket Adı',
        basicInfoSerialNumber: 'Seri Numarası',
        confirmInput: 'Seri Numarasını Yeniden Girin',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'Seri Numarası',
        status: 'Durum',
        imageFile: 'Görüntü',
        message1:
          'Lütfen kontrolör seri numarasının veya QR kodunun net ve bulanık olmayan bir resmini çekin.',
        message2: 'Görüntülerin boyutu %{mbyte} MB’den az olmalıdır.',
        errormessage:
          'Tek seferde kaydedilebilecek maksimum kontrolör sayısı beştir.',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: 'Geçerli',
          disable: 'Geçersiz',
          unLicense: 'Atanmamış',
        },
        machine: 'Makine',
        before: 'Önce',
        after: 'Sonra',
      },
      geofences: {
        project: 'Proje',
        projectSourceLayer: 'Katman',
        rgb: 'Renk(RGB)',
        select: 'Seçim coğrafi çiti',
        coordinates: 'Koordinatlar',
        coordinatesLabel: 'Koordinatlar (N,E)',
        unitLength: 'Birim:%{unit}',
      },
      geofenceDialog: {
        coordinate: 'Koordinatlar (birim:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: 'Yarıçap',
      },
      retrofitAlternateRegists: {
        menuTitle: 'Kontrolör Proxy Kaydı',
        corporationName: 'Şirket Adı',
        email: 'E-posta',
        basicInfoSerialNumber: 'Seri Numarası',
        confirmInput: 'Seri Numarasını Yeniden Girin',
      },
      ntripList: {
        unusedTotal: 'Kullanılmayan Toplam',
      },
      calibrationDetail: {
        breadcrumbs: 'Ayrıntılar',
        machineInfo: 'Makine Bilgileri',
        machineGeometryInfo: 'Makine Geometrisi Bilgileri',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'IMU Montaj Bilgileri',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'Ana GNSS Montaj Bilgileri',
        bodyCenterToGnssSub: 'Yedek GNSS Montaj Bilgileri',
        extendedModel: 'Genişletilmiş Model',
        measuredValues: 'Kalibrasyon Bilgileri',
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'Veri dönüştürme hatası',
        system_localization_quantity_error: `Lokalizasyon bilgileri içeren birden fazla dosya kaydedilemez.
      Lütfen lokalizasyon bilgilerini içeren yalnızca bir adet dosya kaydedin.`,
        system_detect_0byte_file_error: `Bir iletişim hatası nedeniyle sunucuya dosya kaydı başarısız oldu.
      Lütfen dosyayı tekrar kaydedin.`,
        system_batch_error: 'Sistem Hatası (P001)',
        system_parameter_error: 'Sistem Hatası (P002)',
        system_parameterfile_notfound: 'Sistem Hatası (P003)',
        system_exception: 'Sistem Hatası (P004)',
        system_logfile_error: 'Sistem Hatası (P005)',
        system_parameterfile_parameter_error: 'Sistem Hatası (P006)',
        desing_localization_error: `Mevcut lokalizasyon bilgisi yok.
      Lütfen aşağıdaki dosyalardan birini kaydedin:

      ・GC3 dosyası
      ・Kontrol noktalarını içeren TP3 dosyası
      ・CAL dosyası`,
        desing_no_support_dxf_error:
          'Sürüm AutoCAD2000’den daha eski olduğu için DXF dosyaları okunamadı.',
        desing_tile_error: 'Proje dosyası oluşturulamadı.',
        desing_outline_error: 'Dış sınır çizgisi oluşturulamadı.',
        desing_area_length_error:
          'Proje alanı çok büyük olduğu için işlem iptal edildi. Lütfen tasarım verilerinin ve CAD verilerinin ölçeğinin doğru olup olmadığını kontrol edin.',
        desing_layer_name_duplication_error: `Yinelenen katman adlarına sahip proje verileri kaydedilemez.
      Lütfen tasarım katmanı adını değiştirin veya aynı tasarım katmanı adına sahip yalnızca bir adet dosya olacak şekilde kaydedin.`,
        targetFiles: '[Dosya Adı]',
        targetLayers: '[Katman]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: 'Yok',
        system_batch_error: 'Sistem Hatası (T001)',
        system_parameter_error: 'Sistem Hatası (T002)',
        system_parameterfile_notfound: 'Sistem Hatası (T003)',
        system_exception: 'Sistem Hatası (T004)',
        system_logfile_error: 'Sistem Hatası (T005)',
        system_basefile_notfound: 'Sistem Hatası (T006)',
        system_prjfile_multi: 'Sistem Hatası (T007)',
        system_basefile_read_error: 'Sistem Hatası (T008)',
        system_target_layer_notfound: 'Sistem Hatası (T009)',
        system_target_layer_duplication: 'Aynı katman adı zaten mevcut.',
        system_taskarea_error: 'Geçersiz görev alanı.',
        system_taskarea_design_notfound:
          'Görev alanında proje dosyası için veri yok (TIN, hat çalışması, noktalar).',
        system_tile_error: 'Proje dosyası oluşturulamadı.',
        system_outline_error: 'Dış sınır çizgisi oluşturulamadı',
        system_parameterfile_error: 'Sistem Hatası',
      },
      retrofitError: {
        1000: 'Kontrolördan gelen UDP verileri algılanmadı',
        2000: 'Gövde IMU Algılanmadı',
        2001: 'Gövde IMU Donanım hatası',
        2002: 'Gövde IMU Algoritma hatası',
        2003: 'Gövde IMU Veri hatası',
        2004: 'Gövde IMU Sıfırlama hatası',
        2100: 'Bom IMU Algılanmadı',
        2101: 'Bom IMU Donanım hatası',
        2102: 'Bom IMU Algoritma hatası',
        2103: 'Bom IMU Veri hatası',
        2104: 'Bom IMU Sıfırlama hatası',
        2200: 'Kol IMU Algılanmadı',
        2201: 'Kol IMU Donanım hatası',
        2202: 'Kol IMU Algoritma hatası',
        2203: 'Kol IMU Veri hatası',
        2204: 'Kol IMU Sıfırlama hatası',
        2300: 'Kova IMU Algılanmadı',
        2301: 'Kova IMU Donanım hatası',
        2302: 'Kova IMU Algoritma hatası',
        2303: 'Kova IMU Veri hatası',
        2304: 'Kova IMU Sıfırlama hatası',
        2400: 'Tilt Kovası IMU Algılanmadı',
        2401: 'Tilt Kovası IMU Donanım hatası',
        2402: 'Tilt Kovası IMU Algoritma hatası',
        2403: 'Tilt Kovası IMU Veri hatası',
        2404: 'Tilt Kovası IMU Sıfırlama hatası',
        2500: '2. Bom IMU Algılanmadı',
        2501: '2. Bom IMU Donanım hatası',
        2502: '2. Bom IMU Algoritma hatası',
        2503: '2. Bom IMU Veri hatası',
        2504: '2. Bom IMU Sıfırlama hatası',
        2600: 'Kule Dönüş Sensörü Algılanmadı',
        2601: 'Kule Dönüş Sensör Sistemi hatası',
        2602: 'Kule Dönüş Sensörü Donanım hatası',
        2700: 'Ofset Sensörü Algılanmadı',
        2701: 'Ofset Sensör Sistemi hatası',
        2702: 'Ofset Sensörü Donanım hatası',
        3000: 'Baş tarafı basınç sensörü algılanmadı',
        3100: 'Alt taraf basınç sensörü algılanmadı',
        4000: 'GNSS anteni algılanmadı',
        5000: 'Düzeltme Bilgileri algılanmadı',
        5001: 'Radyo alıcı sinyali algılanmadı',
      },
    },
    dialog: {
      menu: {
        confirmManualLink:
          'Başka bir web sitesine yönlendirileceksiniz. Tamam mı?',
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'Kova',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message: 'Şirket Kimliği ve Şirket Adı gereklidir.',
        },
        save: {
          message1: 'Şirket Kimliği/Adı değiştirilemez',
          message2: 'başka bir Şirkete atanan makineleri içeriyor.',
          message3: '',
          message4: 'Lütfen aşağıdaki seri numaralarını kontrol edin.',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'Kontrolör',
        },
        undeletable: {
          message: 'Kontrolör kullanımda ve silinemez.',
        },
      },
      Ntrip: {
        save: {
          stop: 'Bu Ntrip Ayarını durdurmak istiyor musunuz?',
          delete: 'Ntrip Ayarını silmek istiyor musunuz?',
        },
      },
      siteRetrofitList: {
        title: 'Ek Bilgiler',
        message: 'İnşaat süresi sona ermiştir.',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: 'Ortak Ayar',
          siteSetting: 'Şantiye Ayarları',
        },
        mapBackgroundColor: 'Harita Arka Plan Rengi',
        message:
          'Coğrafi çit temas algılama/uyarı bildirim işlevi, kullanıldığı ortama ve koşullara bağlı olarak düzgün çalışmayabilir. İşlevi aşırı güvenerek kullanmayın ve işlev ile kullanım koşullarını anladığınızdan emin olun.',
      },
      geofenceList: {
        confirmDelete: {
          message: 'Bu coğrafi çiti silmek istiyor musunuz?',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1:
            'Makine bir şantiyeye bağlıdır ve atanamaz. Lütfen makinenin şantiyeyle bağlantısını kesin.',
          message2: 'Lütfen makinenin şantiyeyle bağlantısını kesin.',
        },
        sameOwner: {
          message: 'Şirketiniz makinenin sahibi olduğu için makine atanamıyor.',
        },
        unableTransfer: {
          message: 'Makine aktarım onayı bekliyor ve atamayı kaydedemiyor.',
        },
        unableAlternateRegist: {
          message:
            'Makine Kontrolör Proxy Kayıt Onayı bekliyor ve atama için kaydedilemiyor.',
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          'Makine bir şantiyeye bağlı ve aktarılamıyor. Lütfen makinenin şantiyeyle bağlantısını kesin.',
        messageRentaled: 'Makine atanmış ve aktarılamıyor.',
        messageLicense: 'Makinenin lisansı var ve aktarılamıyor.',
        pendingApprovals: {
          message1: 'Aktarma talebi alındı. Lütfen onayı bekleyin.',
          message2:
            'Herhangi bir sorunuz varsa lütfen aşağıdaki URL’den bizimle iletişime geçin.',
        },
      },
      retrofitSendBacks: {
        submit:
          'Kontrolör geri göndermek ve ayarlarını hedef kontrolör almak istiyor musunuz?',
        deleteNtrip: 'Geri verilen kontrolör sıfırlamak istiyor musunuz?',
        unableRegion:
          'Bu kontrolör yurt dışına gönderilmiştir. Bu kontrolör doğruysa, lütfen “Bölge” seçeneğini boş olarak sıfırlayın.',
        unableCorporation: {
          message1: 'Kontrolör geri gönderilemiyor.',
          message2:
            'Bunun bir hata olduğuna inanıyorsanız lütfen satıcınızla iletişim kurun.',
        },
        retrofitRentaled: 'Kontrolör atanmış ve geri gönderilemiyor.',
        tiedToSite: 'Makine bir şantiyeye bağlı ve geri gönderilemiyor.',
      },
      retrofitAddToSiteAction: {
        title: 'Ek Bilgiler',
        message:
          'Onay bekleyen bir makine var, bu nedenle şantiyeye bağlanamıyor.',
      },
      retrofitAddToSiteActionTransfer: {
        title: 'Ek Bilgiler',
        message: 'Makine aktarılıyor ve şantiyeye bağlanamıyor.',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message: 'Seçilen Ntrip Ayarı başka bir makineye gönderiliyor.',
        },
        unableTransferInfo: {
          message: 'Aktarmak için lütfen aşağıdaki ayarları iptal edin.',
          reasonSiteId: 'Şantiye',
          reasonRentaled: 'Atama',
          reasonLicense: 'Lisans',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            'Atanan şirket şu anda makineyi başka bir şirkete atıyor. Başka bir şirkete atama başlangıç tarihi %{date} şeklindedir.',
          message2: 'Lütfen %{date} tarihinden sonra bir bitiş tarihi seçin.',
        },
        confirmTieUpJobsite: {
          message:
            'Makine, atanan şirkette bir şantiyeye bağlıdır. Bu atamayı sonlandırmak istiyor musunuz?',
        },
        confirmDoubleRental: {
          message:
            'Atanan şirket şu anda makineyi başka bir şirkete atıyor. \nAtanan şirket şu anda makineyi başka bir şirkete atıyor. Bir bitiş tarihi ayarlamak, başka bir şirkete atamayı da sonlandırır. Bitiş tarihini ayarlamak istiyor musunuz?',
        },
        unapprovedSave: {
          message: 'Bu atama onaylanmamış ve kaydedilemez.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Bu atamayı silmek istiyor musunuz?',
        },
        approvedDelete: {
          title: '',
          message: 'Bu atama onaylanmış ve silinemez.',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message: 'Makine şu anda bir atamada ve aktarılamaz.',
        },
        approvedSave: {
          message: 'Makinenin lisansı var ve aktarılamıyor.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Bu aktarımı silmek istiyor musunuz?',
        },
        approvedDelete: {
          title: '',
          message: 'Bu aktarım onaylanmış ve silinemez.',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'Uzatma Arm',
        },
      },
      projectFileUpload: {
        message:
          'Birden fazla kontrol noktası dosyası(.gc3/.cal) kaydedilemez.',
      },
      projectEdit: {
        confirm: {
          message:
            'Projeyi güncelledikten sonra mevcut görevler silinecektir. Güncellemek istiyor musunuz?',
        },
      },
      projectConversionStatus: {
        title: 'Dönüştürme Durumu',
        button: 'TAMAM',
      },
      taskConversionStatus: {
        title: 'Dönüştürme Durumu',
        button: 'TAMAM',
      },
      taskList: {
        undeletable: {
          message: 'Seçilen görev, kullanımda olduğu için silinemiyor.',
        },
        unableToCreate: {
          message: 'Projede hiçbir tasarım verisi kayıtlı değil.',
        },
        unableToDelete: {
          message1:
            'Projenin durumu ve görevin durumu aşağıdaki gibi olduğu için görev silinemez.',
          message2: 'Proje: Dönüştürülüyor',
          message3: 'Görev   : Hata',
        },
        unableToUpdate: {
          message1:
            'Projenin durumu ve görevin durumu aşağıdaki gibi olmadığı için görev düzenlenemez.',
          message2: 'Proje: [Dönüştürülmüş]',
          message3: 'Görev   : [Aktarılıyor][Aktarıldı][Aktarım Başarısız]',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'Görev',
        },
        unableToDelete: {
          message: 'Seçilen görev, kullanımda olduğu için silinemiyor.',
        },
      },
      taskMap: {
        crossingMessage: 'Görev alanı kesişiyor.',
        sameValueMessage: 'Aynı koordinatlar girilmiş.',
        areaZeroMessage: 'Görev alanı için alan yok.',
        noPolygonMessage: 'Görev, henüz kaydedilmediği için kaydedilemiyor.',
      },
      compactionMaterials: {
        add: {
          title: 'Bir Malzeme Ekle',
        },
        update: {
          title: 'Bir Malzeme Güncelle',
        },
      },
      weathers: {
        add: {
          title: 'Bir Hava Durumu Ekle',
        },
        update: {
          title: 'Bir Hava Durumu Güncelle',
        },
      },
      operators: {
        add: {
          title: 'Operatör Ekle',
        },
        update: {
          title: 'Operatörü Güncelle',
        },
      },
      compactionWorkAreas: {
        update: {
          message: 'Sıkıştırılmış Çalışma Alanlarını Düzenle',
        },
        undeletable: {
          message:
            'İnşaat alanına dahil edilen sıkıştırma katmanı kullanımdadır ve silinemez',
        },
      },
      compactionLayers: {
        undeletable: {
          message: 'Sıkıştırma katmanı kullanımdadır ve silinemez',
        },
        deleteConfirm: {
          messageTargetItem: 'Sıkıştırma Katmanı',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message: 'Seçilen makineye başka bir Ntrip Ayarı gönderiliyor.',
        },
        confirmDelete: {
          message: 'Ntrip Ayarını silmek istiyor musunuz?',
        },
        confirmDeleteWithMachine: {
          message:
            'Makine şu anda bağlantılıdır. Ntrip Ayarını silmek istiyor musunuz?',
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'Bildirim',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: 'Lisansları aşağıdaki makineye ayarlayın.',
        },
        changeDisable: {
          message:
            'Lütfen aşağıdaki inşaat makinesinin lisans kimliğini geçerli olarak değiştirin.',
        },
        changeTemporary: {
          message:
            'Lisansları aşağıdaki makineye ayarlayın. Halihazırda ayarlı geçici lisans geçersiz kılınacaktır.',
        },
        errorAdd: {
          message:
            'Tüm lisanslar zaten ayarlandığı için makinenin lisansı değiştirilemez.',
        },
        errorSameType: {
          message:
            'Aynı tip lisans zaten ayarlandığı için makinenin lisansı değiştirilemez.',
        },
        errorUnlimited: {
          message:
            'Makine zaten Sınırsız lisans ile ayarlamış, diğer lisanslar ayarlanamaz.',
        },
        addAll: {
          message:
            'Aşağıdaki makineye zaten lisanslar ayarlanmış. Tüm lisansları ayarlamak istiyor musunuz?',
        },
        compare: {
          message: 'Aşağıdaki makine için lisansları değiştirin.',
        },
        warning: {
          message:
            '%{retrofit}, %{id} lisansına sahiptir. Lisansın bağlantısını kesmek istiyor musunuz?',
        },
        unableTransfer: {
          message: 'Makine, lisans bağlanamayacak şekilde aktarılıyor.',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial: 'Sistem Hatası. Görüntüyü analiz edemeyen veriler var.',
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1: 'Kayıt talebi alındı. Lütfen onaylanana kadar bekleyin.',
          message2:
            'Herhangi bir sorunuz varsa lütfen aşağıdaki URL’den bizimle iletişime geçin.',
        },
        corporationIdCheck: {
          message1:
            'Kontrolör zaten kayıtlı. Lütfen makinelerde arama yaparak kontrol edin.',
          message2:
            'Hedef kontrolör listede bulamazsanız, lütfen aşağıdaki URL’den bizimle iletişim kurun.',
        },
        earthbrainIdCheck: {
          message1: 'Bu rahatsızlıktan dolayı özür dileriz,',
          message2:
            'ancak lütfen aşağıdaki bilgilerle, aşağıdaki URL’den bizimle iletişim kurun.',
          message3: '-Kontrolör seri numarası',
          message4: '-Kaydedilecek makinenin adı (isteğe bağlı)',
          message5: '-Kaydedilecek şirket kimliği',
        },
        invalidSerialNumber: {
          message1:
            'Kontrolör halihazırda başka bir şirket tarafından kullanılıyor.',
          message2: 'Bunu başka sebeplerden dolayı değiştirmek isterseniz, ',
          message3:
            'lütfen aşağıdaki bilgilerle, aşağıdaki URL’den bizimle iletişim kurun.',
          message4: '-Kontrolör seri numarası',
          message5: '-Kaydedilecek makinenin adı (isteğe bağlı)',
          message6: '-Kaydedilecek şirket kimliği',
          message7: '-Değişiklik sebebi',
        },
        lendingRetrofits: {
          message1: 'Kontrolör atanmış.',
          message2:
            'Atama şirketindeki atama sona erdikten sonra lütfen yeniden başvurun.',
          message3:
            'Herhangi bir sorunuz varsa lütfen aşağıdaki URL’den bizimle iletişime geçin.',
        },
        siteAttached: {
          message1: 'Kontrolör bir şantiyeye bağlı.',
          message2:
            'Lütfen makinenin şantiyeyle bağlantısını kestikten sonra yeniden başvurun.',
          message3:
            'Herhangi bir sorunuz varsa lütfen aşağıdaki URL’den bizimle iletişime geçin.',
        },
        serialNumberExistError: {
          message1:
            'Bu mevcut olmayan bir kullanmakta seri numarasıdır. Lütfen girdiğiniz kontrolör seri numarasının doğru olduğundan emin olun.',
          message2:
            'Sorun devam ederse lütfen aşağıdaki URL’den bizimle iletişim kurun.',
        },
        ntripShortage: {
          message1: 'Ntrip kullanıcı kimliğinizi ve şifrenizi oluşturamadık.',
          message2: 'Lütfen aşağıdaki URL’den bizimle iletişim kurun.',
        },
        serialnumberCheck: {
          message:
            'Lütfen aşağıdaki seri numaralarına sahip ürünleri kontrol edin',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: 'retrofitAlternatifKayıtlar',
        },
        saveValidation: {
          message: 'E-posta gerekir.',
        },
        pendingApprovals: {
          message1: 'Kayıt talebi alındı. Lütfen onaylanana kadar bekleyin.',
          message2:
            'Herhangi bir sorunuz varsa lütfen aşağıdaki URL’den bizimle iletişime geçin.',
        },
        corporationIdCheck: {
          message1:
            'Kontrolör zaten kayıtlı. Lütfen makinelerde arama yaparak kontrol edin.',
          message2:
            'Hedef kontrolör listede bulamazsanız, lütfen aşağıdaki URL’den bizimle iletişim kurun.',
        },
        earthbrainIdCheck: {
          message1: 'Bu rahatsızlıktan dolayı özür dileriz,',
          message2:
            'ancak lütfen aşağıdaki bilgilerle, aşağıdaki URL’den bizimle iletişim kurun.',
          message3: '-Kontrolör seri numarası',
          message4: '-Kaydedilecek makinenin adı (isteğe bağlı)',
          message5: '-Kaydedilecek şirket kimliği',
        },
        invalidSerialNumber: {
          message1:
            'Kontrolör halihazırda başka bir şirket tarafından kullanılıyor.',
          message2: 'Bunu başka sebeplerden dolayı değiştirmek isterseniz, ',
          message3:
            'lütfen aşağıdaki bilgilerle, aşağıdaki URL’den bizimle iletişim kurun.',
          message4: '-Kontrolör seri numarası',
          message5: '-Kaydedilecek makinenin adı (isteğe bağlı)',
          message6: '-Kaydedilecek şirket kimliği',
          message7: '-Değişiklik sebebi',
        },
        lendingRetrofits: {
          message1: 'Kontrolör atanmış.',
          message2:
            'Atama şirketindeki atama sona erdikten sonra lütfen yeniden başvurun.',
          message3:
            'Herhangi bir sorunuz varsa lütfen aşağıdaki URL’den bizimle iletişime geçin.',
        },
        siteAttached: {
          message1: 'Kontrolör bir şantiyeye bağlı.',
          message2:
            'Lütfen makinenin şantiyeyle bağlantısını kestikten sonra yeniden başvurun.',
          message3:
            'Herhangi bir sorunuz varsa lütfen aşağıdaki URL’den bizimle iletişime geçin.',
        },
        serialNumberExistError: {
          message1:
            'Bu mevcut olmayan bir kullanmakta seri numarasıdır. Lütfen girdiğiniz kontrolör seri numarasının doğru olduğundan emin olun.',
          message2:
            'Sorun devam ederse lütfen aşağıdaki URL’den bizimle iletişim kurun.',
        },
        ntripShortage: {
          message1: 'Ntrip kullanıcı kimliğinizi ve şifrenizi oluşturamadık.',
          message2: 'Lütfen aşağıdaki URL’den bizimle iletişim kurun.',
        },
        serialnumberCheck: {
          message:
            'Lütfen aşağıdaki seri numaralarına sahip ürünleri kontrol edin',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1: 'Bu kontrolör zaten şirketinize ait.',
          message2: 'Lütfen başvuru sahibi şirketle iletişim kurun.',
        },
        userCorporation: {
          message1:
            'Bu kontrolör zaten ödünç verdiğiniz için bu talebi onaylayamazsınız.',
          message2: 'Lütfen başvuru sahibi şirketle iletişim kurun.',
        },
      },
      licensePurchaseNotice: {
        button: 'TAMAM',
        message1: `Şantiyede lisanssız olarak kullanılan bir makine var.
      Şantiyede kullanım için bir lisans gerekir.
      Bir lisans satın almadıysanız, lütfen Smart Construction Marketplace’den bir lisans satın alın.`,
        message2: `
      Başka bir şirketten kiralıyorsanız lütfen kiraladığınız kaynaktan makine için bir lisans talep edin.

      Herhangi bir sorunuz varsa lütfen aşağıdaki destek sitesini kontrol edin.`,
      },
      siteBuriedStructureEdit: {
        title: 'Buried Structure',
      },
    },
    tootip: {
      retrofitShareFileList: 'Uygulama Günlüğü',
      retrofitConfigList: 'Konfigürasyon Bilgileri',
      retrofitKitInfoList: 'Kontrolör Bilgileri',
      retrofitAccuraciesList: 'Doğruluk Kontrolü Sonucu',
      retrofitCalibaList: 'Kalibrasyon Bilgileri',
      retrofitBasicSettingList: 'Temel Ayar',
      retrofitErrorList: 'Hata Ayrıntıları',
      retrofitTopographicSurveyList: 'Topografik İnceleme',
    },
    actions: {
      ok: 'TAMAM',
      add: 'Ekle',
      create: 'Kayıt',
      yes: 'Evet',
      no: 'Hayır',
      confirmation: 'Onayla',
      save: 'Kaydet',
      cancel: 'İptal Et',
      required: 'Gerekli',
      addMachineToSite: 'Makineyi Şantiyeye Ekle',
      addBucketToSite: 'Ekle',
      deleteFromSite: 'Şantiye ile Bağlantıyı Kes',
      removeLicense: 'Makineyle Bağlantıyı Kes',
      lendMachine: 'Atama',
      transferMachine: 'Aktarım',
      remoteSupport: 'Uzaktan Destek',
      lendBucket: 'Paylaş',
      approval: 'Onay',
      connect: 'Bağlan',
      creationInstructions: 'İnşaat Sonucunu Al',
      csvDownload: 'İndir',
      retrofitBundleCreate: 'Toplu Kayıt',
      termsAccept: 'Kabul Et',
      termsDeny: 'Reddet',
      bulkRegistration: 'Toplu Güncelleme',
      bulkRegistrationOK: 'TAMAM',
      compactionItems: 'Sıkıştırma Öğeleri',
      addLanguage: 'Dil Ekle',
    },
    message: {
      error: 'Bir hata meydana geldi',
      confirm: 'Tamam mı?',
      fileUpload: 'Dosyaları buraya sürükleyip bırakın.',
      success: 'Kayıt tamamlandı',
      approved: 'Onay tamamlandı',
      deleteConfirmTitle: '',
      deleteConfirmContent: '%{title} öğesini silmek istiyor musunuz?',
      bulkDeleteConfirmContent: '%{title} öğesini silmek istiyor musunuz?',
      disconnectFromSite: 'Şantiye ile bağlantıyı kesmek istiyor musunuz?',
      saveConfirmContent: 'Onayla',
      removeLicense: 'Makine ile bağlantıyı kesmek istiyor musunuz?',
      task: {
        crossingMessage: 'Görev alanı kesişiyor.',
        sameValueMessage: 'Aynı koordinatlar girilmiş.',
        areaZeroMessage: 'Görev alanı için alan yok.',
        invalidPolygonMessage: 'Aynı koordinatlar girilmiş.',
      },
      geofence: {
        crossingMessage: 'Coğrafi çit alanı kesişiyor.',
        sameValueMessage: 'Aynı koordinatlar girilmiş.',
        areaZeroMessage: 'Coğrafi çit alanı için alan yok.',
      },
      licenseError:
        'Smart Construction Pilot(Web Uygulaması) için lisansınız yok.',
      retrofitsManage: {
        serialNumberMatchError: 'Seri Numarası eşleşmiyor.',
        serialNumberInputError: 'Seri Numarası yok.',
        invalidAccess:
          'Bu sayfayı görüntüleme yetkiniz yok, üst sayfaya geri dönün.',
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'Seri Numarası eşleşmiyor.',
        serialNumberInputError: 'Seri Numarası yok.',
        retrofitRentaled:
          'Kontrolör atamadayken şirket bilgileri güncellenemez.',
        invalidCorporation:
          'Şirketiniz distribütör olmadığı için kullanmakta proxy kaydını yapamazsınız.',
      },
    },
    validation: {
      startDate: 'Başlangıç Tarihi',
      endDate: 'Bitiş Tarihi',
      invalidDateFormat: 'Tarih formatı geçersiz.',
      invalidDateTimeFormat: 'Tarih ve saat formatı geçersiz.',
      duplicateProject: 'Proje adı yineleniyor.',
      incorrectBeforeDate:
        '%{target}, %{before} tarihinden önceki bir tarihe ayarlanmalıdır.',
      incorrectAfterDate:
        '%{target}, %{after} tarihinden sonraki bir tarihe ayarlanmalıdır.',
      incorrectPlanStartDate:
        'Planlanan başlangıç tarihi planlanan bitiş tarihinden önce olmalıdır.',
      incorrectPlanEndDate:
        'Planlanan bitiş tarihi, planlanan başlangıç tarihinden sonraki bir tarih olmalıdır.',
      incorrectActualStartDate:
        'Başlangıç tarihi bitiş tarihinden önceki bir tarihe ayarlanmalıdır.',
      incorrectActualEndDate:
        'Bitiş tarihi, başlangıç tarihinden sonraki bir tarihe ayarlanmalıdır.',
      withActualStartDate: 'Lütfen başlangıç tarihini de girin.',
      inputAvailableDate: 'Girilebilecek tarih %{date} alanından girilebilir.',
      registrationRequired:
        'Lütfen atanan şirketi veya e-posta adresini seçin.',
      bothInput: 'Sadece atanan şirketi veya e-posta adresini girebilirsiniz.',
      supportMode: 'Lütfen bir Şirket Kimliği veya Şantiye Kimliği girin.',
      invalidBkFile: 'Bu, bir BK3 dosyası değildir.',
      invalidPdfFile: 'Bu bir PDF dosyası değildir.',
      duplicatedBucketName: 'Kova adı yineleniyor.',
      duplicatedTaskName: 'Görev adı yineleniyor.',
      duplicateGeofenceName: 'Coğrafi çit adı yineleniyor.',
      duplicatedCompactionWorkAreaName:
        'Yinelenen sıkıştırma çalışma alanı adı',
      duplicatedCompactionLayerName: 'Yinelenen sıkıştırma katmanı adı',
      duplicated: {
        compactionMaterials: {
          message: 'Yinelenen malzeme adı',
        },
        weathers: {
          message: 'Yinelenen hava durumu adı',
        },
        operators: {
          message: 'Yinelenen operatör adı',
        },
      },
      containInvalidString:
        'Geçersiz karakterler içeriyor. (%{msg} girilmesine izin verilmez)',
      validHalfWidthAlphanumeric:
        'Sadece yarı genişlikte alfanümerik karakterlere izin verilir.',
      validNumberFormat: 'Bir sayı değil.',
      validIntegerFormat: 'Bir tamsayı değil.',
      validIntegerRangeFormat: '0 ila 2147483647 arasında girişe izin verilir.',
      validDuplicateLanguage: 'Aynı dil seçilir.',
      validBeforeDate:
        '%{target}, %{before} tarihinden önceki bir tarihe ve saate ayarlanmalıdır.',
      validAfterDate:
        '%{target}, %{after} tarihinden sonraki bir tarihe ve saate ayarlanmalıdır.',
    },
  },
  resources: {
    sites: {
      name: 'Şantiye',
      fields: {
        name: 'Şantiye',
        corporationName: 'Şirket Adı',
        status: 'Durum',
        workPeriodStartDate: 'Başlangıç Tarihi',
        workPeriodEndDate: 'Bitiş Tarihi',
      },
      status: {
        completed: 'Tamamlandı',
        waiting: 'İnşaat Başlamadan Önce',
        working: 'Yapım Altında',
      },
    },
    retrofits: {
      name: 'Makine',
      fields: {
        information: 'Makine Bilgileri',
        rental: 'Atama',
        sendBack: 'Geri Gönder',
        corporationId: 'Şirket Kullanımda',
        siteId: 'Şantiye',
        uuid: 'UUID',
        isError: 'Durum',
        isOnline: 'Çevrimiçi',
        lastOnlineTime: 'Son Bağlantı',
        basicInfoManufacturer: 'Üretici',
        basicInfoModel: 'Model',
        basicInfoProductNumber: 'Ürün No.',
        basicInfoSerialNumber: 'Seri No.',
        controllerManufacturer: 'Üretici',
        controllerModel: 'Model',
        controllerFirmwareVersion: 'Donanım Yazılımı Sür.',
        gnssReceiverMainManufacturer: 'Üretici',
        gnssReceiverMainModel: 'Model',
        gnssReceiverMainFirmwareVersion: 'Donanım Yazılımı Sür.',
        gnssReceiverSubManufacturer: 'Üretici',
        gnssReceiverSubModel: 'Model',
        gnssReceiverSubFirmwareVersion: 'Donanım Yazılımı Sür.',
        machineInfoCompanyName: 'Markalar',
        machineInfoMachineType: 'Makine Tipi',
        machineInfoMachineName: 'Makine Adı',
        machineInfoMachineId: 'Makine Kimliği',
        rentalStatus: 'Atama',
        lastUpdated: 'Son Güncelleme',
      },
      isError: {
        true: 'Hata',
        false: 'Normal',
      },
      isOnline: {
        true: 'Çevrimiçi',
        false: 'Çevrimdışı',
      },
      machineInfoMachineType: {
        excavator: 'Ekskavatör',
        roadroller: 'Yol Silindiri',
        dozer: 'Dozer',
        rigiddump: 'Kaya Kamyonu',
        wheelloader: 'Lastikli Yükleyici',
        grader: 'Greyder',
        automobile: 'Otomobil',
      },
      licenseMachineType: {
        excavator: 'Ekskavatör',
        roadroller: 'Yol Silindiri',
        dozer: 'Dozer',
        rigiddump: 'Kaya Kamyonu',
        wheelloader: 'Lastikli Yükleyici',
        grader: 'Greyder',
        automobile: 'Otomobil',
        excavator_license: 'Ekskavatör : %{licenseGrantInfo}',
        roadroller_license: 'Yol Silindiri : %{licenseGrantInfo}',
        dozer_license: 'Dozer : %{licenseGrantInfo}',
        rigiddump_license: 'Kaya Kamyonu : %{licenseGrantInfo}',
        wheelloader_license: 'Lastikli Yükleyici : %{licenseGrantInfo}',
        grader_license: 'Greyder : %{licenseGrantInfo}',
        automobile_license: 'Otomobil : %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: 'Aktarılıyor',
        sent: 'Aktarıldı',
        failtosend: 'Aktarım başarısız',
      },
      rentalStatus: {
        approved: 'Onaylandı',
        unapproved: 'Onaylanmadı',
        returned: 'İade edildi',
      },
    },
    retrofitRentals: {
      name: 'Atama',
      fields: {
        id: 'Kimlik',
        retrofitId: 'Makine Kimliği',
        fromCorporationId: 'Atama Şirketi Kimliği',
        fromCorporationName: 'Atama Şirketi',
        toCorporationId: 'Atanan Şirket',
        toCorporationName: 'Şirket Kullanımda',
        retrofitRentalId: 'Atama Kimliği',
        isApproved: 'Durum',
        isReturned: 'İade edildi',
        planStartDate: 'Planlanan Başlangıç Tarihi',
        planEndDate: 'Planlanan Bitiş Tarihi',
        actualStartDate: 'Başlangıç Tarihi',
        actualEndDate: 'Bitiş Tarihi',
        rentalUsers: 'E-posta',
        email: 'E-posta',
        rentalStatus: 'Durum',
      },
      isApproved: {
        true: 'Onaylandı',
        false: 'Onaylanmadı',
      },
      rentalStatus: {
        approved: 'Onaylandı',
        unapproved: 'Onaylanmadı',
        returned: 'İade edildi',
      },
    },
    pendingRentals: {
      name: 'Onay Bekleniyor',
      fields: {
        fromCorporationName: 'Atama Şirketi',
        planStartDate: 'Planlanan Başlangıç Tarihi',
        planEndDate: 'Planlanan Bitiş Tarihi',
      },
    },
    pendingTransfers: {
      name: 'Onay Bekleniyor',
      fields: {
        fromCorporationName: 'Şirket Aktarılıyor',
        planDate: 'Planlanan Aktarım Tarihi',
        lastUpdated: 'Son Güncelleme',
      },
    },
    pendingAlternateRegists: {
      name: 'Onay Bekleniyor',
      fields: {
        fromCorporationName: 'Mevcut Sahibi',
        lastUpdated: 'Son Güncelleme',
      },
    },
    assignments: {
      name: 'Aktarım',
      fields: {
        id: 'Kimlik',
        retrofitId: 'Makine Kimliği',
        fromCorporationId: 'Şirket Kimliği Aktarılıyor',
        fromCorporationName: 'Şirket Aktarılıyor',
        toCorporationId: 'Aktarılan Şirket',
        toCorporationName: 'Şirket',
        isApproved: 'Durum',
        planDate: 'Planlanan Aktarım Tarihi',
        actualDate: 'Aktarım Tarihi',
        transferUsers: 'E-posta',
        email: 'E-posta',
        lastUpdated: 'Son Güncelleme',
      },
      isApproved: {
        true: 'Onaylandı',
        false: 'Onaylanmadı',
      },
    },
    buckets: {
      name: 'Kova',
      fields: {
        companyName: 'Şirket Adı',
        siteId: 'Şantiye',
        name: 'Adı',
        corporationId: 'Şirket Kullanımda',
        bucketType: 'Tür',
        bk3File: 'BK3 Dosyası',
        'bk3File.title': 'BK3 Dosyası',
        gltfFile: 'Kova Dosyası',
        uploadedBy: 'Kayıt Eden',
        updatedBy: 'Güncelleyen',
        fileStatus: 'Durum',
        lastUpdated: 'Son Güncelleme',
        uploadBk: 'BK3’ü yükle',
      },
      bucketType: {
        slope: 'Eğim',
        standard: 'Standart',
        tilt: 'Tilt',
        tiltrotator: 'Eğimli Döndürücü',
      },
      uploadedBy: {
        admin: 'Yönetici',
        tablet: 'Tablet',
      },
      fileStatus: {
        converting: 'Dönüştürülüyor',
        completed: 'Tamamlandı',
        error: 'Hata',
      },
    },
    projects: {
      name: 'Proje',
      fields: {
        name: 'Proje Adı',
        coordinate: 'Koordinat Sistemi',
        version: 'Versiyon',
        status: 'Durum',
        lastUpdated: 'Son Güncelleme',
        regionId: 'Bölge',
        projectionId: 'Projeksiyon',
        geoidId: 'Geoid',
        datumId: 'Referans Düzlemi',
        file: 'Dosyalar',
        retrofitId: 'Hedef Makine',
      },
      status: {
        converted: 'Tamamlandı',
        waitforconvert: 'Dönüştürülüyor',
        error: 'Hata',
      },
    },
    extensionarms: {
      name: 'Uzatma Arm',
      fields: {
        extensionarmType: 'Tür',
        name: 'Adı',
        corporationId: 'Şirket Kullanımda',
        corporationName: 'Şirket Kullanımda',
        siteId: 'Şantiye',
        siteName: 'Şantiye',
        lastUpdated: 'Son Güncelleme',
        extensionarmFile: 'Uzatma Arm Dosyası',
      },
      extensionarmType: {
        basic: 'Uzatma Arm',
        a: 'A Tipi',
        b: 'B Tipi',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: 'Şununla Paylaş',
        name: 'Adı',
        extensionarmType: 'Tür',
        extensionarmFile: {
          title: 'Uzatma Arm Dosyası',
        },
      },
    },
    corporations: {
      name: 'Şirket',
      fields: {
        name: 'Şirket Kullanımda',
      },
    },
    retrofitShareFiles: {
      name: 'Uygulama Günlüğü',
      fields: {
        name: 'Dosya Adı',
        fileTime: 'Alınma Tarihi ve Saati',
        url: 'İndir',
      },
    },
    retrofitConfigs: {
      name: 'Konfigürasyon Bilgileri',
      fields: {
        timeStamp: 'Alınma Tarihi ve Saati',
        url: 'İndir',
      },
    },
    retrofitKitInfos: {
      name: 'Kontrolör Bilgileri',
      fields: {
        timeStamp: 'Alınma Tarihi ve Saati',
        url: 'İndir',
      },
    },
    retrofitAccuracies: {
      name: 'Doğruluk Kontrolü Sonucu',
      fields: {
        checkDateTime: 'Onay Tarihi ve Saati',
        surveyPoint: 'Ölçülen Nokta',
        positionN: 'Ölçülen Değer : N',
        positionE: 'E',
        positionZ: 'Z',
        machineOffsetN: 'Ofset Değeri : N',
        machineOffsetE: 'E',
        machineOffsetZ: 'Z',
        referencePointName: 'Referans Noktası Adı',
        referencePointN: 'Referans Noktası : N',
        referencePointE: 'E',
        referencePointZ: 'Z',
        unitLength: 'Uzunluk Birimi',
      },
    },
    retrofitCalibs: {
      name: 'Kalibrasyon Bilgileri',
      fields: {
        timeStamp: 'Alınma Tarihi ve Saati',
        url: 'İndir',
        detailInfo: {
          createDateTime: 'Tarih ve saat oluştur',
          updateDateTime: 'Güncelleme tarihi ve saati',
          machineInfo: {
            companyName: 'Markalar',
            machineType: 'Makine Tipi',
            machineName: 'Makine Adı',
            machineId: 'Makine Kimliği',
            drumConfiguration: 'Tambur Konfigürasyonu',
            guidanceMode: 'Kılavuz Modu',
          },
          machineGeometryInfo: {
            boomLength: 'Bom Uzunluğu',
            secondBoomLength: '2. Bom Uzunluğu',
            armLength: 'Kol Uzunluğu',
            armTopPinToBucketSideLinkPinLength:
              'Kol Üstü ile Kova Yan Bağlantısı Mesafesi',
            boomTopPinToBucketSideLinkPinLength:
              'Bom Üstü ve Kova Yan Bağlantısı Arasındaki Mesafe',
            bucketLink1Length:
              'Kova Yan Tarafı İle Kova Silindiri Arasındaki Mesafe',
            bucketLink2Length:
              'Kova Silindiri İle Kova Bağlantısı Arasındaki Mesafe',
            bodyCenterToBoomFootPin: {
              x: 'Gövde Merkezine Göre Bom Ayak Pimi X',
              y: 'Gövde Merkezine Göre Bom Ayak Pimi Y',
              z: 'Gövde Merkezine Göre Bom Ayak Pimi Z',
            },
            drumWidth: 'Tambur Genişliği',
            length: 'Uzunluk',
            shoeInterval: 'Pabuç Aralığı',
            bladeWidthForDozer: 'Dozer için Bıçak Genişliği',
            distanceFromBodyToBlade: 'Gövdeden Bıçağa Olan Mesafe',
            frontToMainGnssAntenna: 'Önden Ana Gnss Antenine',
            frontToSubGnssAntenna: 'Önden Yedek Gnss Antenine',
            centerToMainGnssAntenna: 'Merkezden Ana Gnss Antenine',
            centerToSubGnssAntenna: 'Merkezden Yedek Gnss Antenine',
            groundToMainGnssAntenna: 'Zeminden Ana Gnss Antenine',
            groundToSubGnssAntenna: 'Zeminden Yedek Gnss Antenine',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'Bom IMU’nun Ofset Açısı',
            secondBoomImuOffsetAngle: '2. Bom IMU’nun Ofset Açısı',
            armImuOffsetAngle: 'Kol IMU’nun Ofset Açısı',
            bucketImuOffsetAngle: 'Kova IMU’nun Ofset Açısı',
            bodyImuOffsetRollAngle: 'Gövde IMU’nun Ofset Yuvarlanma Açısı ',
            bodyImuOffsetPitchAngle: 'Gövde IMU’nun Ofset Hatve Açısı',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: 'Gövde Merkezine Göre Ana GNSS',
                y: 'Gövde Merkezine Göre Ana GNSS Y',
                z: 'Gövde Merkezine Göre Ana GNSS Z',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: 'Gövde Merkezine Göre Yedek GNSS X',
                y: 'Gövde Merkezine Göre Yedek GNSS Y',
                z: 'Gövde Merkezine Göre Yedek GNSS Z',
              },
            },
          },
          extendedModel: {
            swingBoom: 'Kule Dönüş Bomu Modeli',
            twoPieceBoom: '2 Parçalı Bom Modeli',
            minimalSwingRadius: 'Ofset Bom Modeli',
          },
          measuredValues: {
            otherLength: {
              machineHeight: 'Makine Yüksekliği',
            },
            bodyImu1: {
              roll: 'Gövde IMU1 Yuvarlanma',
              pitch: 'Gövde IMU1 Hatve',
              yaw: 'Gövde IMU1 Yalpa',
            },
            bodyImu2: {
              roll: 'Gövde IMU2 Yuvarlanma',
              pitch: 'Gövde IMU2 Hatve',
              yaw: 'Gövde IMU2 Yalpa',
            },
            frontLeft: {
              x: 'Ön Sol X',
              y: 'Ön Sol Y',
              z: 'Ön Sol Z',
            },
            frontRight: {
              x: 'Ön Sağ X',
              y: 'Ön Sağ Y',
              z: 'Ön Sağ Z',
            },
            frontGroundingEnd: {
              x: 'Ön Topraklama Ucu X',
              y: 'Ön Topraklama Ucu Y',
              z: 'Ön Topraklama Ucu Z',
            },
            rearGroundingEnd: {
              x: 'Arka Topraklama Ucu X',
              y: 'Arka Topraklama Ucu Y',
              z: 'Arka Topraklama Ucu Z',
            },
            mainGnssAntenna: {
              x: 'Ana Gnss Anteni X',
              y: 'Ana Gnss Anteni Y',
              z: 'Ana Gnss Anteni Z',
            },
            subGnssAntenna: {
              x: 'Yedek Gnss Anteni X',
              y: 'Yedek Gnss Anteni Y',
              z: 'Yedek Gnss Anteni Z',
            },
            prismPoleOffset: 'Prizma Jalon Ofseti',
            antennaElectricalCenterHeight: 'Anten Elektrik Merkezi Yüksekliği',
          },
        },
      },
    },
    retrofitBasicSettings: {
      name: 'Temel Ayar',
      fields: {
        timeStamp: 'Alınma Tarihi ve Saati',
        url: 'İndir',
      },
    },
    retrofitErrors: {
      name: 'Hata Bilgileri',
      fields: {
        notifiedTime: 'Meydana Geliş Tarihi ve Saati',
        errorCodes: 'Hata Kodu : Ayrıntılar',
      },
    },
    retrofitToposurveys: {
      name: 'Topografik İnceleme Listesi',
      fields: {
        surveyDate: 'İnceleme Tarihi ve Saati',
        projectName: 'Proje Adı',
        layerName: 'Katman',
        pointName: 'Nokta Adı',
        surveyPoint: 'Ölçülen Nokta',
        n: 'Ölçülen Değer : N',
        e: 'E',
        z: 'Z',
        unitLength: 'Uzunluk Birimi',
      },
    },
    asbuilts: {
      name: 'İnşaat Sonuçları',
      fields: {
        execStartDate: 'Uygulama Başlangıcı',
        execEndDate: 'Uygulama Bitişi',
        startPeriod: 'Alım Süresi Başlangıcı',
        endPeriod: 'Alım Süresi Bitişi',
        execStatus: 'Durum',
        url: '',
        csvType: 'Veri Biçimi',
        createUser: 'Kullanıcı oluştur',
      },
      execStatus: {
        complete: 'Tamamlandı',
        inprogress: 'İşleniyor',
        error: 'Hata',
        completewithoutdata: 'Tamamlandı(Veri Yok)',
      },
    },
    retrofitBasicInfos: {
      name: 'Temel Bilgiler',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: 'Distribütör',
        retrofitRegionId: 'Bölge',
        corporationId: 'Şirket Kimliği',
        corporationName: 'Şirket Adı',
        basicInfoManufacturer: 'Üretici',
        basicInfoModel: 'Model',
        basicInfoProductNumber: 'Ürün No.',
        basicInfoSerialNumber: 'Seri No.',
        lastUpdated: 'Son Güncelleme',
        description: 'Açıklama',
        ownerId: 'Şirket Kimliği',
        ownerName: 'Şirket Adı',
      },
      tag: {
        retrofitBasicInfosDetail: 'Temel Bilgiler',
        sendBack: 'Geri Gönder',
      },
      sendBack: {
        fields: {
          site: 'Şantiye',
          corporation: 'Şirket Kullanımda',
          ntripSettingInfo: 'Ntrip Kimliği',
          toRetrofit: 'Alternatif Kontrolör',
          notificationEmailAddress: 'E-posta',
        },
      },
    },
    retrofitBulkActions: {
      name: 'Toplu Kayıt',
      fields: {
        uploadFileName: 'Dosya Adı',
        execStartDate: 'Uygulama Başlangıç Tarihi ve Saati',
        execEndDate: 'Uygulama Bitiş Tarihi ve Saati',
        execStatus: 'Durum',
        uploadFile: 'Kayıtlı Dosya',
        resultFile: 'Kayıt Sonuçları',
      },
      execStatus: {
        complete: 'Tamamlandı',
        inprogress: 'İşleniyor',
        error: 'Hata',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: 'YöneticiŞirketTopluİşlem',
      fields: {
        uploadFileName: 'Dosya Adı',
        execStartDate: 'Uygulama Başlangıç Tarihi ve Saati',
        execEndDate: 'Uygulama Bitiş Tarihi ve Saati',
        execStatus: 'Durum',
        uploadFile: 'Kayıtlı Dosya',
        resultFile: 'Kayıt Sonuçları',
      },
      execStatus: {
        complete: 'Tamamlandı',
        inprogress: 'İşleniyor',
        error: 'Hata',
      },
    },
    tasks: {
      name: 'Görev',
      fields: {
        name: 'Adı',
        status: 'Durum',
        description: 'Açıklama',
        projectSourceLayerId: 'Katman',
        retrofitIds: 'Hedef Makine',
        expiredDate: 'Son Kullanma Tarihi ',
        createDate: 'Son Kullanma Tarihi Verileri',
        rectangle: 'Desteklenen Aralık',
        lastUpdated: 'Son Güncelleme',
      },
      status: {
        error: 'Hata',
        converting: 'Dönüştürülüyor',
        sending: 'Aktarılıyor',
        sent: 'Aktarıldı',
        failtosend: 'Aktarım başarısız',
      },
    },
    compactionMaterials: {
      name: 'Malzemeler',
      fields: {
        name: 'Adı',
      },
    },
    weathers: {
      name: 'Hava Koşulları',
      fields: {
        name: 'Adı',
      },
    },
    operators: {
      name: 'Operatörler',
      fields: {
        name: 'Adı',
      },
    },
    compactionWorkAreas: {
      name: 'Sıkıştırılmış Çalışma Alanları',
      fields: {
        name: 'İş Alanları Adı',
        blockSize: 'Blok Boyutu',
        description: 'Açıklama',
      },
    },
    compactionLayers: {
      name: 'Sıkıştırma Katmanları',
      fields: {
        name: 'Adı',
        thickness: 'Kalınlık',
        passCount: 'Geçiş Sayısı',
        compactionMaterialId: 'Malzeme',
        inUse: 'Durum',
        description: 'Açıklama',
      },
      inUse: {
        true: 'Kullanımda',
        false: 'kullanılmamış',
      },
    },
    gnssSettings: {
      name: 'Ntrip Ayarı',
      fields: {
        registCorporationName: 'Kayıt Eden',
        ownerCorporationId: 'Şirket Kimliği',
        ownerCorporationName: 'Şirket Adı',
        name: 'Adı',
        url: 'Sunucu URL’si',
        portnum: 'Port',
        userId: 'Kimlik',
        password: 'Şifre',
        retrofitId: 'Hedef Makine',
        status: 'Durum',
        updateDate: 'Son Güncelleme',
      },
      status: {
        waitforsend: 'Dönüştürülüyor',
        sending: 'Aktarılıyor',
        sent: 'Aktarıldı',
        failtosend: 'Aktarım başarısız',
      },
    },
    supportMode: {
      name: 'Destek Modu',
      fields: {
        enabled: 'Destek Modunu Etkinleştir',
        corporationId: 'Şirket Kimliği',
        corporationName: 'Şirket Adı',
        siteId: 'Şantiye Kimliği',
        siteName: 'Şantiye',
        latitude: 'Enlem',
        longitude: 'Boylam',
        workPeriodStartDate: 'Gerçek İnşaat Süresi (Başlangıç tarihi)',
        workPeriodEndDate: 'Gerçek İnşaat Süresi (Bitiş tarihi)',
        photoUrl: 'Şantiye Resmi URL’si',
        description: 'Açıklama',
      },
    },
    userNotices: {
      name: 'Bildirim',
      noticeType: {
        emergencyimportant: 'Acil Durum/Önemli',
        emergency: 'Acil durum',
        important: 'Önemli',
      },
      fields: {
        noticeType: 'Önem',
        noticeTypes: 'Önem',
        noticeCategory: 'Bildirim Türü',
        title: 'Başlık',
        content: 'İçindekiler',
        publicationStartDate: 'Başlangıç Tarihi ve Saati(UTC)',
        publicationEndDate: 'Bitiş Tarihi ve Saati(UTC)',
        file: 'Eklentiler',
        language: 'Dil',
        emergency: 'Acil durum',
        important: 'Önemli',
      },
    },
    firmwares: {
      name: 'Donanım Yazılımı',
      fields: {
        version: 'Versiyon',
        compatibilityLevel: 'Uyumluluk Seviyesi',
        firmwareFile: 'Donanım Yazılımı Dosyası',
        firmwareFileCheckSum: 'Donanım Yazılımı Kontrolü Toplamı',
        firstInitializationFile: 'Sıfırlama Dosyası 1',
        firstInitializationFileCheckSum: 'Sıfırlama Dosyası 1 Kontrol Toplamı',
        secondInitializationFile: 'Sıfırlama Dosyası 2',
        secondInitializationFileCheckSum: 'Sıfırlama Dosyası 2 Kontrol Toplamı',
      },
    },
    bucketShares: {
      name: 'Kova Paylaşımı',
      fields: {
        fromBucketId: 'Kova Kimliğinden',
        corporationId: 'Şirket Kimliği',
        name: 'Adı',
      },
    },
    extensionarmShare: {
      name: 'Uzatma Arm Paylaşımı',
      fields: {
        name: 'Adı',
      },
    },
    retrofitCorporationLicenses: {
      name: 'Lisans',
      fields: {
        licenseId: 'Lisans Kimliği',
        licenseType: 'Tür',
        issuedAt: 'Düzenlenme Tarihi',
        expiresAt: 'Son Kullanma Tarihi ',
        retrofitId: 'Makine',
        comment: 'Açıklama',
        licenseStatus: 'Durum',
        licensePermission: 'Lisans',
        productType: 'Ürün Adı',
      },
      licenseStatus: {
        unlicense: 'Atanmamış',
        disable: 'Geçersiz',
        enable: 'Geçerli',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: 'Şirket Adı',
        namePlates: 'Fotoğraf',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: 'Uzunluk Birimi',
      },
    },
    geofences: {
      name: 'Coğrafi Çit',
      fields: {
        type: 'Tür',
        name: 'Adı',
        dimension: 'Boyut',
        trigger: 'Tetikleyici',
        valid: 'Geçerli',
        alertType: 'Uyarı Türü',
        startDate: 'Başlangıç Tarihi',
        endDate: 'Bitiş Tarihi',
        transparency: 'Şeffaf',
        elevation: 'Yükseklik',
        height: 'Yükseklik',
        radius: 'Yarıçap',
        lastUpdated: 'Son Güncelleme',
        points: 'Desteklenen Aralık',
        thickness: 'Kalınlık',
      },
      type: {
        line: 'Çizgi',
        polygon: 'Poligon',
        circle: 'Daire',
        point: 'Nokta',
        wall: 'Duvar',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: 'Geçerli',
        false: 'Geçersiz',
      },
      alertType: {
        attention: 'Dikkat',
        notice: 'Bildirim',
        warning: 'Uyarı',
        caution: 'Dikkat',
        danger: 'Tehlike',
      },
      transparency: {
        true: 'Şeffaf',
        false: 'Opak',
      },
    },
    geofenceAlerts: {
      name: 'Uyarılar',
      fields: {
        timeStamp: 'Algılama Tarihi ve Saati',
        geofenceName: 'Coğrafi Çit Adı',
        alertType: 'Uyarı Türü',
        collisionPoint: 'Çakışma Noktası',
        retrofit: {
          machineInfoMachineName: 'Makine',
        },
      },
      alertType: {
        attention: 'Dikkat',
        notice: 'Bildirim',
        warning: 'Uyarı',
        caution: 'Dikkat',
        danger: 'Tehlike',
      },
    },
    ntrips: {
      name: 'Ntrip Yönetimi',
      fields: {
        name: 'Kullanıcı Kimliği',
        ntripPass: 'Şifre',
        serial: 'Seri No.',
        customerCorporationId: 'Şirket Kimliği',
        customerCorporationName: 'Şirket Adı',
        ntrip: 'Durum',
        numberOfExchanges: 'Değişim Sayısı',
        id: 'Kimlik',
        licenceId: 'Lisans Kimliği',
        dateOfRegistration: 'Kayıt Tarihi',
        billingToCustomers: 'Müşterilere Faturalama',
        paymentToKomatsu: 'Komatsu’ya Ödeme',
        applicantId: 'Uygulama Kimliği',
        applicant: 'Başvuru Sahibi',
        requestCorporationId: 'Talep Şirketi Kimliği',
        requestCorporationName: 'Talep Şirketi Adı',
        isUsed: 'Kullanılır',
      },
      ntrip: {
        publish: 'Ödendi',
        stop: 'Durdur',
        delete: 'Silindi',
      },
    },
    retrofitAlternateRegists: {
      name: 'Kontrolör Proxy Kaydı',
      fields: {
        id: 'Kimlik',
        retrofitId: 'retrofitKimliği',
        fromCorporationId: 'ŞirketKimliğinden',
        fromCorporationName: 'Kontrolör Proxy Kaydı',
        toCorporationId: 'Kullanıcı',
        toCorporationName: 'Şirket Adı',
        isApproved: 'Durum',
        createDate: 'Başvuru Tarihi',
        actualDate: 'Onaylanma Tarihi',
        transferUsers: 'E-posta',
        email: 'E-posta',
        lastUpdated: 'Son Güncelleme',
      },
      isApproved: {
        true: 'Onaylandı',
        false: 'Onaylanmadı',
      },
    },
    pendingApprovals: {
      name: 'Onay Bekleniyor',
      fields: {
        approvalType: 'Tür',
        requestedBy: 'Başvuru Sahibi Şirket',
        lastUpdated: 'Son Güncelleme',
      },
      approvalType: {
        assignment: 'Atama',
        transfer: 'Aktarım',
        agent: 'Kontrolör Proxy Kaydı',
      },
    },
    retrofitSendbacks: {
      name: 'Geri Gönder',
      fields: {
        fromRetrofitId: 'Retrofitten',
        toRetrofitId: 'Alternatif Kontrolör',
        notificationEmailAddress: 'E-posta',
        siteId: 'Şantiye',
        corporationId: 'Şirket Kimliği',
        corporationName: 'Şirket Kullanımda',
        ntripId: 'Ntrip Bilgileri',
        isComplete: 'Geri Gönderme Tamamlandı',
      },
    },
    siteBuriedStructures: {
      fields: {
        epsgCode: 'Epsg',
      },
    },
  },
};

export default message;
