import { RetrofitRentalData } from '../../types';

const retrofitRentals: RetrofitRentalData[] = [
  {
    id: 1,
    retrofitId: 16,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: '株式会社クオリカ建築　テスト事業部',
    toCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    toCorporationName: 'クオリカ',
    retrofitRentalId: undefined,
    isApproved: true,
    isReturned: true,
    rentalStatus: 'Returned',
    planStartDate: '2020-02-01T00:00:00.000Z',
    planEndDate: '2020-02-17T00:00:00.000Z',
    actualStartDate: '2020-02-01T00:00:00.000Z',
    actualEndDate: '2020-02-12T00:00:00.000Z',
    email: 'kazuhiro_tsurumi@qualica.co.jp',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 2,
    retrofitId: 17,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: '株式会社クオリカ建築　テスト事業部',
    toCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    toCorporationName: 'クオリカ',
    retrofitRentalId: undefined,
    isApproved: true,
    isReturned: true,
    rentalStatus: 'Returned',
    planStartDate: '2020-02-01T00:00:00.000Z',
    planEndDate: '2020-02-17T00:00:00.000Z',
    actualStartDate: '2020-02-01T00:00:00.000Z',
    actualEndDate: '2020-02-12T00:00:00.000Z',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 3,
    retrofitId: 16,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: '株式会社クオリカ建築　テスト事業部',
    toCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    toCorporationName: 'クオリカ',
    retrofitRentalId: undefined,
    isApproved: true,
    isReturned: false,
    rentalStatus: 'Approved',
    planStartDate: '2020-02-13T00:00:00.000Z',
    planEndDate: '2020-03-31T00:00:00.000Z',
    actualStartDate: '2020-02-13T00:00:00.000Z',
    actualEndDate: '',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 4,
    retrofitId: 17,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: '株式会社クオリカ建築　テスト事業部',
    toCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    toCorporationName: 'クオリカ',
    retrofitRentalId: undefined,
    isApproved: true,
    isReturned: false,
    rentalStatus: 'Approved',
    planStartDate: '2020-02-13T00:00:00.000Z',
    planEndDate: '2020-03-31T00:00:00.000Z',
    actualStartDate: '2020-02-13T00:00:00.000Z',
    actualEndDate: '',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 5,
    retrofitId: 18,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: '株式会社クオリカ建築　テスト事業部',
    toCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    toCorporationName: 'クオリカ',
    retrofitRentalId: undefined,
    isApproved: false,
    isReturned: false,
    rentalStatus: 'Unapproved',
    planStartDate: '2020-03-01T00:00:00.000Z',
    planEndDate: '2020-03-31T00:00:00.000Z',
    actualStartDate: '',
    actualEndDate: '',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 6,
    retrofitId: 19,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: '株式会社クオリカ建築　テスト事業部',
    toCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    toCorporationName: 'クオリカ',
    retrofitRentalId: undefined,
    isApproved: false,
    isReturned: false,
    rentalStatus: 'Unapproved',
    planStartDate: '2020-03-01T00:00:00.000Z',
    planEndDate: '2020-03-31T00:00:00.000Z',
    actualStartDate: '',
    actualEndDate: '',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 7,
    retrofitId: 20,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: '株式会社クオリカ建築　テスト事業部',
    toCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    toCorporationName: 'クオリカ',
    retrofitRentalId: undefined,
    isApproved: true,
    isReturned: true,
    rentalStatus: 'Returned',
    planStartDate: '2020-01-01T00:00:00.000Z',
    planEndDate: '2020-01-31T00:00:00.000Z',
    actualStartDate: '2020-01-01T00:00:00.000Z',
    actualEndDate: '2020-01-31T00:00:00.000Z',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 8,
    retrofitId: 21,
    fromCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    fromCorporationName: 'クオリカ',
    toCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    toCorporationName: '株式会社クオリカ建築　テスト事業部',
    retrofitRentalId: undefined,
    isApproved: true,
    isReturned: true,
    rentalStatus: 'Returned',
    planStartDate: '2020-01-01T00:00:00.000Z',
    planEndDate: '2020-01-31T00:00:00.000Z',
    actualStartDate: '2020-01-01T00:00:00.000Z',
    actualEndDate: '2020-01-31T00:00:00.000Z',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 9,
    retrofitId: 22,
    fromCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    fromCorporationName: 'クオリカ',
    toCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    toCorporationName: '株式会社クオリカ建築　テスト事業部',
    retrofitRentalId: undefined,
    isApproved: true,
    isReturned: true,
    rentalStatus: 'Returned',
    planStartDate: '2020-01-01T00:00:00.000Z',
    planEndDate: '2020-01-31T00:00:00.000Z',
    actualStartDate: '2020-01-01T00:00:00.000Z',
    actualEndDate: '2020-01-31T00:00:00.000Z',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 10,
    retrofitId: 23,
    fromCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    fromCorporationName: 'クオリカ',
    toCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    toCorporationName: '株式会社クオリカ建築　テスト事業部',
    retrofitRentalId: undefined,
    isApproved: false,
    isReturned: false,
    rentalStatus: 'Unapproved',
    planStartDate: '2020-01-01T00:00:00.000Z',
    planEndDate: '2020-01-31T00:00:00.000Z',
    actualStartDate: '2020-01-01T00:00:00.000Z',
    actualEndDate: '2020-01-31T00:00:00.000Z',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 11,
    retrofitId: 24,
    fromCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    fromCorporationName: 'クオリカ',
    toCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    toCorporationName: '株式会社クオリカ建築　テスト事業部',
    retrofitRentalId: undefined,
    isApproved: true,
    isReturned: true,
    rentalStatus: 'Returned',
    planStartDate: '2020-01-01T00:00:00.000Z',
    planEndDate: '2020-01-31T00:00:00.000Z',
    actualStartDate: '2020-01-01T00:00:00.000Z',
    actualEndDate: '2020-01-31T00:00:00.000Z',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 12,
    retrofitId: 25,
    fromCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    fromCorporationName: 'クオリカ',
    toCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    toCorporationName: '株式会社クオリカ建築　テスト事業部',
    retrofitRentalId: undefined,
    isApproved: true,
    isReturned: true,
    rentalStatus: 'Returned',
    planStartDate: '2020-01-01T00:00:00.000Z',
    planEndDate: '2020-01-31T00:00:00.000Z',
    actualStartDate: '2020-01-01T00:00:00.000Z',
    actualEndDate: '2020-01-31T00:00:00.000Z',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 13,
    retrofitId: 26,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: '株式会社クオリカ建築　テスト事業部',
    toCorporationId: '0da5298e-0081-08af-c9dd-3c8ae99fa742',
    toCorporationName: 'クオリカその２',
    retrofitRentalId: undefined,
    isApproved: false,
    isReturned: false,
    rentalStatus: 'Unapproved',
    planStartDate: '2020-03-01T00:00:00.000Z',
    planEndDate: '2020-03-31T00:00:00.000Z',
    actualStartDate: '',
    actualEndDate: '',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 14,
    retrofitId: 27,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: '株式会社クオリカ建築　テスト事業部',
    toCorporationId: '0da5298e-0081-08af-c9dd-3c8ae99fa742',
    toCorporationName: 'クオリカその２',
    retrofitRentalId: undefined,
    isApproved: false,
    isReturned: false,
    rentalStatus: 'Unapproved',
    planStartDate: '2020-03-01T00:00:00.000Z',
    planEndDate: '2020-03-31T00:00:00.000Z',
    actualStartDate: '',
    actualEndDate: '',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 15,
    retrofitId: 28,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: '株式会社クオリカ建築　テスト事業部',
    toCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    toCorporationName: 'クオリカ',
    retrofitRentalId: undefined,
    isApproved: true,
    isReturned: false,
    rentalStatus: 'Approved',
    planStartDate: '2020-03-01T00:00:00.000Z',
    planEndDate: '2020-03-31T00:00:00.000Z',
    actualStartDate: '2020-03-01T00:00:00.000Z',
    actualEndDate: '',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 16,
    retrofitId: 29,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: '株式会社クオリカ建築　テスト事業部',
    toCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    toCorporationName: 'クオリカ',
    retrofitRentalId: undefined,
    isApproved: true,
    isReturned: false,
    rentalStatus: 'Approved',
    planStartDate: '2020-03-01T00:00:00.000Z',
    planEndDate: '2020-03-31T00:00:00.000Z',
    actualStartDate: '2020-03-01T00:00:00.000Z',
    actualEndDate: '',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 17,
    retrofitId: 28,
    fromCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    fromCorporationName: 'クオリカ',
    toCorporationId: '0da5298e-0081-08af-c9dd-3c8ae99fa742',
    toCorporationName: 'クオリカその２',
    retrofitRentalId: 15,
    isApproved: true,
    isReturned: false,
    rentalStatus: 'Approved',
    planStartDate: '2020-03-01T00:00:00.000Z',
    planEndDate: '2020-03-31T00:00:00.000Z',
    actualStartDate: '2020-03-01T00:00:00.000Z',
    actualEndDate: '',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 18,
    retrofitId: 29,
    fromCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    fromCorporationName: 'クオリカ',
    toCorporationId: '0da5298e-0081-08af-c9dd-3c8ae99fa742',
    toCorporationName: 'クオリカその２',
    retrofitRentalId: 16,
    isApproved: true,
    isReturned: false,
    rentalStatus: 'Approved',
    planStartDate: '2020-03-01T00:00:00.000Z',
    planEndDate: '2020-03-31T00:00:00.000Z',
    actualStartDate: '2020-03-01T00:00:00.000Z',
    actualEndDate: '',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 19,
    retrofitId: 3,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: '株式会社クオリカ建築　テスト事業部',
    toCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    toCorporationName: 'LANDLOG',
    retrofitRentalId: undefined,
    isApproved: false,
    isReturned: false,
    rentalStatus: 'Unapproved',
    planStartDate: '2020-03-01T00:00:00.000Z',
    planEndDate: '2020-03-31T00:00:00.000Z',
    actualStartDate: '2020-03-01T00:00:00.000Z',
    actualEndDate: '',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 20,
    retrofitId: 2,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: '株式会社クオリカ建築　テスト事業部',
    toCorporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    toCorporationName: 'LANDLOG',
    retrofitRentalId: undefined,
    isApproved: true,
    isReturned: false,
    rentalStatus: 'Approved',
    planStartDate: '2020-03-01T00:00:00.000Z',
    planEndDate: '2020-03-31T00:00:00.000Z',
    actualStartDate: '2020-03-01T00:00:00.000Z',
    actualEndDate: '',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 21,
    retrofitId: 28,
    fromCorporationId: '0da5298e-0081-08af-c9dd-3c8ae99fa742',
    fromCorporationName: 'クオリカその２',
    toCorporationId: '0da5298e-0081-08af-c9dd-3c8ae99fa743',
    toCorporationName: 'クオリカその3',
    retrofitRentalId: 17,
    isApproved: true,
    isReturned: false,
    rentalStatus: 'Approved',
    planStartDate: '2020-03-01T00:00:00.000Z',
    planEndDate: '2020-03-31T00:00:00.000Z',
    actualStartDate: '2020-03-02T00:00:00.000Z',
    actualEndDate: '',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 22,
    retrofitId: 28,
    fromCorporationId: '0da5298e-0081-08af-c9dd-3c8ae99fa743',
    fromCorporationName: 'クオリカその3',
    toCorporationId: '0da5298e-0081-08af-c9dd-3c8ae99fa744',
    toCorporationName: 'クオリカその4',
    retrofitRentalId: 21,
    isApproved: true,
    isReturned: true,
    rentalStatus: 'Approved',
    planStartDate: '2020-03-01T00:00:00.000Z',
    planEndDate: '2020-03-31T00:00:00.000Z',
    actualStartDate: '2020-03-03T00:00:00.000Z',
    actualEndDate: '2020-03-12T00:00:00.000Z',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 23,
    retrofitId: 28,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: 'クオリカその4',
    toCorporationId: '0da5298e-0081-08af-c9dd-3c8ae99fa745',
    toCorporationName: 'クオリカその5',
    retrofitRentalId: 22,
    isApproved: true,
    isReturned: false,
    rentalStatus: 'Approved',
    planStartDate: '2020-03-01T00:00:00.000Z',
    planEndDate: '2020-03-31T00:00:00.000Z',
    actualStartDate: '2020-03-04T00:00:00.000Z',
    actualEndDate: '2020-03-11T00:00:00.000Z',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 24,
    retrofitId: 145,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: '株式会社クオリカ建築　テスト事業部',
    toCorporationId: '0da5298e-0081-08af-c9dd-3c8ae99fa745',
    toCorporationName: 'クオリカその5',
    retrofitRentalId: 22,
    isApproved: true,
    isReturned: false,
    rentalStatus: 'Approved',
    planStartDate: '2020-03-01T00:00:00.000Z',
    planEndDate: '2020-03-31T00:00:00.000Z',
    actualStartDate: '2020-03-04T00:00:00.000Z',
    actualEndDate: '2020-03-11T00:00:00.000Z',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
  {
    id: 25,
    retrofitId: 145,
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    fromCorporationName: '株式会社クオリカ建築　テスト事業部',
    toCorporationId: '0da5298e-0081-08af-c9dd-3c8ae99fa745',
    toCorporationName: 'クオリカその5',
    retrofitRentalId: 22,
    isApproved: true,
    isReturned: false,
    rentalStatus: 'Approved',
    planStartDate: '2020-03-01T00:00:00.000Z',
    planEndDate: '2020-03-31T00:00:00.000Z',
    actualStartDate: '2020-03-04T00:00:00.000Z',
    actualEndDate: '2020-03-11T00:00:00.000Z',
    email: '',
    lastUpdated: '2020-02-12T00:00:00.000Z',
  },
];

export default retrofitRentals;
