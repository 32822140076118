import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  FileField,
  LinkButton,
} from '../..';
import { ReturnIcon } from '../../../assets';

const Actions: React.FC<{
  basePath?: string;
}> = () => {
  return (
    <LinkButton path={`/firmwares/`} label="Back">
      <ReturnIcon />
    </LinkButton>
  );
};

const FirmwareShow: React.FC = props => {
  return (
    <Show actions={<Actions />} {...props}>
      <SimpleShowLayout>
        <TextField resource="firmwares" source="id" />
        <TextField resource="firmwares" source="version" />
        <FileField
          title="firmwareFile.title"
          resource="firmwares"
          source="firmwareFile.src"
        />
        <TextField resource="firmwares" source="firmwareFileCheckSum" />
        <FileField
          title="firstInitializationFile.title"
          resource="firmwares"
          source="firstInitializationFile.src"
        />
        <TextField
          resource="firmwares"
          source="firstInitializationFileCheckSum"
        />
        <FileField
          title="secondInitializationFile.title"
          resource="firmwares"
          source="secondInitializationFile.src"
        />
        <TextField
          resource="firmwares"
          source="secondInitializationFileCheckSum"
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default FirmwareShow;
