import React, { useState } from 'react';
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { useTranslate, useUpdate } from 'react-admin';
import { Button } from '../../..';
import {
  ArrowDown,
  ArrowUp,
  Download,
  EmergencySmall,
  File,
  ImportantSmall,
  MailClose,
  MailOpen,
} from '../../../../assets';
import { UserNoticeStatusData } from '../../../../dataProvider';

const useStyles = makeStyles(() =>
  createStyles({
    file: {
      marginTop: 5,
      marginBottom: 5,
      paddingLeft: 8,
      '& > svg': {
        verticalAlign: 'middle',
      },
    },
    fileLink: {
      minHeight: 25,
    },
    wrap: {
      whiteSpace: 'pre-wrap',
    },
    downloadButton: {
      minWidth: 5,
      minHeight: 32,
      fontSize: '0.9em',
      textDecoration: 'underline',
      backgroundColor: 'transparent',
      '& span': {
        padding: 0,
      },
    },
  }),
);

const UserNoticeListCell: React.FC<{
  record: UserNoticeStatusData;
}> = ({ record }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [update] = useUpdate(
    'userNoticeStatus',
    record.id,
    {
      ...record,
      read: true,
    },
    record,
  );

  const {
    id,
    title,
    content,
    read: recRead,
    file,
    noticeType,
    noticeCategory,
    publicationStartDate,
  } = record;
  const [read, setRead] = useState(recRead);
  const downloadList = [];
  const fileList = [];
  if (file && file[0] && file[0]?.src) {
    for (let i = 0; i < file.length; i += 1) {
      downloadList.push(
        <div className={classes.file} key={`${id}_file_${i}`}>
          <Download />
          <span className={classes.fileLink}>
            <Button
              className={classes.downloadButton}
              href={file[i].src}
              label={file[i].title}
              download
            />
          </span>
        </div>,
      );
    }
    fileList.push(<File key={id} />);
  }

  const noticeTypeList = [];
  if (noticeType === 'EmergencyImportant' || noticeType === 'Emergency') {
    noticeTypeList.push(<EmergencySmall key={id} />);
  } else if (noticeType === 'Important') {
    noticeTypeList.push(<ImportantSmall key={id} />);
  }
  const showStartDate = new Date(publicationStartDate).toLocaleDateString();
  const translate = useTranslate();

  const res = 'admin.label.userNotices';
  const type = noticeCategory;
  const trans = translate(`${res}.${type}`);
  // 翻訳できなければ、もとの内容をそのまま設定する
  const showNoticeCategory = trans.startsWith(res) ? type : trans;

  return (
    <Box
      display="flex"
      borderColor="#F5F5FF33"
      borderRadius="solid"
      borderTop={1}
      {...{ bgcolor: open ? '#37373C' : undefined }}
    >
      <Box width="75%" display="flex" flexDirection="column">
        <Box m={1} display="flex" alignItems="center">
          {noticeTypeList}
          <Box ml={noticeTypeList.length ? 1 : 3} />
          <Typography variant="subtitle2">{showNoticeCategory}</Typography>
        </Box>
        <Box ml={1}>
          <Typography noWrap={!open} variant="h6">
            {title}
          </Typography>
        </Box>
        <Box ml={1} mt={1} mb={1}>
          <Typography
            className={open ? classes.wrap : undefined}
            noWrap={!open}
            variant="body2"
          >
            {content}
          </Typography>
        </Box>

        {open ? <Box>{downloadList}</Box> : null}
      </Box>
      <Box width="25%" display="flex" flexDirection="column">
        <Box
          mr={1}
          mt={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box>{fileList}</Box>
          <Box ml={1}>{read ? <MailOpen /> : <MailClose />}</Box>
        </Box>
        <Box mr={1} mt={1} display="flex" justifyContent="flex-end">
          <Typography noWrap variant="caption">
            {showStartDate}
          </Typography>
        </Box>
        <Box flexGrow={1} display="flex" alignItems="flex-end">
          <Button
            fullWidth
            endIcon={open ? <ArrowUp /> : <ArrowDown />}
            onClick={() => {
              setOpen(!open);
              if (!open && !read) {
                // 開いたときに更新処理を実施
                update();
                setRead(true);
              }
            }}
          >
            <Box width="100%" display="flex" justifyContent="flex-end">
              <Typography noWrap variant="caption">
                {open
                  ? translate('admin.label.userNoticeDrawer.close')
                  : translate('admin.label.userNoticeDrawer.more')}
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

UserNoticeListCell.displayName = 'UserNoticeListCell';
export default UserNoticeListCell;
