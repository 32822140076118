import React, { useCallback, useEffect, useState } from 'react';
import { Identifier, useDataProvider } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CustomList,
  Datagrid,
  TextField,
  IconField,
  Filter,
  SiteSearchInput,
  CustomDateField,
  Loading,
  DialogOKOnly,
  Button,
} from '../..';
import {
  useResource,
  useJumpTo,
  useAccount,
  useGetSelefDetailData,
} from '../../../hooks';
import { AddIcon } from '../../../assets';
import { useAlternateRegisted, useTransferred } from './transfers/hooks';
import { colors } from '../../../theme';
import { buttonWidth, buttonHeight } from '../../../theme/define';
import { config } from '../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 200,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 120,
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 120,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 139,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 139,
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 55,
            textAlign: 'center',
          },
        },
      },
    },
  }),
);

// GotoRegistrationButtonと同じスタイル
const useButtonStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: colors.button.cancel,
      color: colors.text.primaryLight, // テキスト表示するので必要
      fontSize: 14, // テキスト表示するので必要
      textTransform: 'none', // テキスト表示するので必要
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
      },
      // テキスト表示するので必要
      '& span': {
        padding: 0,
      },
    },
  }),
);

const CreateRentalButton: React.FC<{
  retrofitId: number;
  name: string;
  tabId?: Identifier;
}> = ({ retrofitId, name, tabId }) => {
  const { gotoInnerListRegistration } = useJumpTo();
  const getAccount = useAccount();
  const getTransferred = useTransferred();
  const getAlternateRegisted = useAlternateRegisted();
  const classes = useButtonStyles();

  const [dialogState, setDialogState] = useState<{
    open: boolean;
    message?: string;
  }>({ open: false });

  const handleCreateRentalClick = useCallback(() => {
    const api = async () => {
      const {
        data: { corporationId },
      } = await getAccount();
      const isTransferred = await getTransferred(retrofitId);
      const isAlternateRegisted = await getAlternateRegisted(
        retrofitId,
        corporationId,
      );
      return { isTransferred, isAlternateRegisted };
    };
    api().then(result => {
      // 建機が譲渡中の場合
      if (result.isTransferred) {
        setDialogState({
          open: true,
          message: 'admin.dialog.retrofitRentalCreate.unableTransfer.message',
        });
        return;
      }
      // 建機が代理申請中の場合
      if (result.isAlternateRegisted) {
        setDialogState({
          open: true,
          message:
            'admin.dialog.retrofitRentalCreate.unableAlternateRegist.message',
        });
        return;
      }
      gotoInnerListRegistration(name, retrofitId, tabId);
    });
  }, [
    getAccount,
    getTransferred,
    getAlternateRegisted,
    gotoInnerListRegistration,
    retrofitId,
    name,
    tabId,
  ]);

  // REACT_APP_FEATURE_TRANSFERが削除されたら関数ごと削除、ButtonのonClickイベント内の分岐を削除する
  const handleNoneCheckClick = useCallback(() => {
    gotoInnerListRegistration(name, retrofitId, tabId);
  }, [gotoInnerListRegistration, retrofitId, name, tabId]);

  const label = 'admin.actions.lendMachine';

  return (
    <>
      <DialogOKOnly
        open={dialogState.open}
        onClose={() => setDialogState({ open: false })}
        title=""
        message={dialogState?.message}
      />
      <Button
        className={classes.root}
        label={label}
        data-testid={label}
        onClick={
          config.feature.transfer
            ? handleCreateRentalClick
            : handleNoneCheckClick
        }
      >
        <AddIcon />
      </Button>
    </>
  );
};

interface Props {
  id: number;
  resource: string;
  corporationid?: string;
}

const ListFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

const RetrofitRentalList: React.FC<Partial<Props>> = props => {
  const {
    id: retrofitId = 0,
    resource: retrofitResource = '',
    corporationid: corporationId = '',
  } = props;
  const resource = useResource('retrofitRentals');
  const tabIndex = 1; // 1: レンタルタブ
  const { gotoInnerListEditOrShow: gotoLendingEditOrShow } = useJumpTo();
  const dataProvider = useDataProvider();
  const getAccount = useAccount();
  const getSelefDetailData = useGetSelefDetailData();
  // const redirectTo = useRedirect();

  const [fetching, setFetching] = useState(true);
  const [isLendMachineVisible, setLendMachineVisible] = useState(false);

  const classes = useStyles();
  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: {
      fetchedRetrofitId: number;
      isOwnData: boolean;
    };
  }>({ loading: true, fetched: false });

  useEffect(() => {
    if (apiState.fetched) return;
    setApiState({ loading: true, fetched: true });

    const api = async () => {
      // 自社のretrofitsか判定
      const filter = {
        ownerCorporationId: corporationId,
        adminCorporationId: corporationId,
      };

      const isOwnRetrofits = await getSelefDetailData(
        'retrofits',
        filter,
        retrofitId,
      );

      return {
        fetchedRetrofitId: retrofitId,
        isOwnData: !!isOwnRetrofits,
      };
    };
    api().then(result => {
      setApiState({
        loading: false,
        fetched: true,
        data: result,
      });
    });
  }, [
    getAccount,
    corporationId,
    apiState.fetched,
    apiState.data,
    retrofitId,
    getSelefDetailData,
  ]);

  useEffect(() => {
    if (!corporationId) return;
    setFetching(true);
    // 貸出ボタンの表示条件
    // - レトロフィットレンタルを以下の条件で、抽出し、１件も存在しないこと
    //  ＜抽出条件＞
    // レトロフィットレンタル（retrofitRentals.retrofitId ）=  retrofitId（建機一覧で該当明細選択時のレトロフィットID）
    // レトロフィットレンタル（retrofitRentals.FromCorporationsId ）=  acounts.corporationId（ログインユーザの企業ID）
    // レトロフィットレンタル.未返却(IsReturned = FALSE ）
    dataProvider
      .getList(resource, {
        filter: {
          retrofitId,
          fromCorporationId: corporationId,
          isReturned: false, // 貸している状態
        },
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
      })
      .then(result => {
        if (result === undefined) return;
        const { data } = result;
        if (data === undefined) return;
        setLendMachineVisible(data.length <= 0); // 1件も存在しない場合、貸出ボタンを表示する
      })
      .finally(() => {
        setFetching(false);
      });
  }, [dataProvider, retrofitId, corporationId, resource]);

  if (corporationId === '' || fetching || apiState.loading || !apiState.data)
    return <Loading />;

  // TODO: 閲覧不可なデータは現場一覧に遷移する機能を有効にする場合、コメントアウトを外す
  // const { isOwnData } = apiState.data;
  // if (!isOwnData) redirectTo('/');

  return (
    <CustomList
      breadcrumbs={['resources.retrofits.name']}
      {...props}
      resource={resource}
      controllerResource={resource}
      actions={
        isLendMachineVisible ? (
          <CreateRentalButton
            retrofitId={retrofitId}
            tabId={tabIndex}
            name={retrofitResource}
          />
        ) : (
          <></>
        )
      }
      // ＜抽出条件＞
      // レトロフィットレンタル（retrofitRentals.retrofitId ）=  retrofit.id（建機一覧で該当明細選択時のレトロフィットID）
      // レトロフィットレンタル.From企業ID = acounts.corporationId（ログインユーザの企業ID）
      filter={{
        retrofitId,
        fromCorporationId: corporationId,
      }}
      filters={<ListFilter />}
      bulkActionButtons={false}
    >
      <Datagrid
        rowClick={(rentalId: string) => {
          gotoLendingEditOrShow(
            retrofitResource,
            retrofitId,
            rentalId,
            tabIndex,
          );
        }}
        className={classes.root}
      >
        <TextField
          resource={resource}
          source="toCorporationName"
          label="admin.label.retrofitRental.companyName"
        />
        <CustomDateField resource={resource} source="planStartDate" />
        <CustomDateField resource={resource} source="planEndDate" />
        <CustomDateField resource={resource} source="actualStartDate" />
        <CustomDateField resource={resource} source="actualEndDate" />
        <IconField
          resource={resource}
          source="rentalStatus"
          positionLastColumn={true}
        />
      </Datagrid>
    </CustomList>
  );
};

RetrofitRentalList.displayName = 'RetrofitRentalList';
export default RetrofitRentalList;
