import React from 'react';
import { Create, TextInput, FileInput, FileField } from '../../bases';
import { CustomForm } from '../..';

const FirmwareCreate: React.FC = props => {
  return (
    <Create {...props}>
      <CustomForm>
        <TextInput resource="firmwares" source="version" />
        <TextInput resource="firmwares" source="compatibilityLevel" />
        <FileInput resource="firmwares" source="firmwareFile" accept=".bin">
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput
          resource="firmwares"
          source="firstInitializationFile"
          accept=".gfw"
        >
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput
          resource="firmwares"
          source="secondInitializationFile"
          accept=".gfw"
        >
          <FileField source="src" title="title" />
        </FileInput>
      </CustomForm>
    </Create>
  );
};

export default FirmwareCreate;
