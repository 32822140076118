import { DataProvider } from 'react-admin';
import { getRestDataProvider } from '..';
import getGeofenceCoordinate from './getGeofenceCoordinate';

export default (): DataProvider => {
  return {
    getList: (_, params): any => {
      return getGeofenceCoordinate(getRestDataProvider(), params);
    },
    getOne: () => {
      throw new Error('Not supported for coordinates');
    },
    getMany: () => {
      throw new Error('Not supported for coordinates');
    },
    getManyReference: () => {
      throw new Error('Not supported for coordinates');
    },
    update: () => {
      throw new Error('Not supported for coordinates');
    },
    updateMany: () => {
      throw new Error('Not supported for coordinates');
    },
    create: (): any => {
      throw new Error('Not supported for coordinates');
    },
    delete: () => {
      throw new Error('Not supported for coordinates');
    },
    deleteMany: () => {
      throw new Error('Not supported for coordinates');
    },
  };
};
