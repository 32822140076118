import React, { FC, useState } from 'react';
import {
  useTranslate,
  useNotify,
  useLoading,
  useDataProvider,
} from 'react-admin';
import { DoneButton, DialogOKOnlyWithUrl } from '../../..';
import { SaveIcon } from '../../../../assets';
import { RetrofitData } from '../../../../dataProvider';
import { useUpdateRetrofitMany, UpdateData } from '../hooks';
import { apiErrorHandler, config } from '../../../../utils';

export const serialNumberPrefix = 'serialNumber_';
export const serialNumberErrorPrefix = 'serialNumberError_';

interface Props {
  saveButtonDisabled: boolean; // 保存ボタン活性化状態の独自機能
  disabled: boolean; // 保存ボタン活性化状態のreact-Admin標準機能
  form: any;
  updateData: UpdateData;
  checkAlreadyRegistered: (retrofits: RetrofitData[]) => Promise<boolean>;
  checkEarthbrainCorporationId: (retrofits: RetrofitData[]) => Promise<boolean>;
  checkSiteAttachment: (retrofitIds: number[]) => Promise<boolean>;
  checkLending: (retrofitIds: number[]) => Promise<boolean>;
  checkPendingTransfer: (retrofitIds: number[]) => Promise<boolean>;
  checkPendingAlternateRegistration: (
    retrofitIds: number[],
  ) => Promise<boolean>;
  checkCorporation: (retrofits: RetrofitData[]) => Promise<boolean>;
  checkNtripUnusedTotal: (retrofits: RetrofitData[]) => Promise<boolean>;
  handleDisableness: () => void;
}
const useRetrofits = () => {
  const dataProvider = useDataProvider();
  return async (serials: string[]): Promise<RetrofitData[]> => {
    const { data } = await dataProvider.getList<RetrofitData>('retrofits', {
      pagination: {
        page: 1,
        perPage: 100, // ただし、シリアル番号 1回での取得を100にする場合は、コントローラ登録で一回でチェックするシリアル番号を件数を絞り込みすることも合わせて検討する
      },
      sort: { field: 'id', order: 'ASC' },
      filter: {
        basicInfoSerialNumber: serials,
      },
    });
    return data;
  };
};

const RetrofitNamePlateFileSaveButton: FC<Partial<Props>> = ({
  saveButtonDisabled,
  disabled,
  form,
  updateData,
  checkAlreadyRegistered = async () => Promise.resolve(true),
  checkEarthbrainCorporationId = async () => Promise.resolve(true),
  checkSiteAttachment = async () => Promise.resolve(true),
  checkLending = async () => {
    return true;
  },
  checkPendingTransfer = async () => Promise.resolve(true),
  checkPendingAlternateRegistration = async () => Promise.resolve(true),
  checkCorporation = async () => Promise.resolve(true),
  checkNtripUnusedTotal = async () => Promise.resolve(true),
  handleDisableness = () => false,
}) => {
  if (!updateData) throw new Error('Invalid updateData');

  const scSupportRequestUrl = config.url.scSupportRequest; // エラーダイアログ用URL
  const translate = useTranslate();
  const notify = useNotify();
  const loading = useLoading();
  const getRetrofits = useRetrofits();
  const updateRetrofitMany = useUpdateRetrofitMany();

  const [serialErrorDialog, setSerialErrorDialog] = useState<{
    open: boolean;
    messages?: string[];
    hideUrl?: boolean;
    subMessages?: string[];
  }>({ open: false, messages: [], hideUrl: false });

  const handleSave = () => {
    const fields: string[] = form.getRegisteredFields() || [];
    const hideNofityError = 'hideNofityError';
    const serialNumbers = fields
      .filter(it => it.indexOf(serialNumberPrefix) === 0)
      .map(it => form.getFieldState(it).value);
    const errorSerialNumbers = fields
      .filter(it => it.indexOf(serialNumberErrorPrefix) === 0)
      .map(it => form.getFieldState(it).value);

    if (errorSerialNumbers.length > 0) {
      setSerialErrorDialog({
        open: true,
        messages: [
          translate('admin.dialog.retrofitNamePlateFiles.error.serial'),
        ],
        hideUrl: true,
      });
      return;
    }

    const api = async (): Promise<boolean> => {
      const retrofits = await getRetrofits(serialNumbers);

      // 存在しないシリアル番号のチェック
      const validSerials = retrofits.map(it => it.basicInfoSerialNumber);
      const invalidSerials = serialNumbers.filter(
        (it: string) => !validSerials.includes(it),
      );
      if (invalidSerials.length > 0) {
        setSerialErrorDialog({
          open: true,
          messages: [
            translate(
              'admin.dialog.retrofitManageTab.serialNumberExistError.message1',
            ),
            translate(
              'admin.dialog.retrofitManageTab.serialNumberExistError.message2',
            ),
          ],
          subMessages: [
            translate(
              'admin.dialog.retrofitManageTab.serialnumberCheck.message',
            ),
            invalidSerials.join(' , '),
          ],
        });
        throw Error(hideNofityError);
      }

      const retrofitIds = retrofits.map(({ id }) => id);
      // ログイン中企業とレトロフィットの登録先企業チェック
      if (!(await checkAlreadyRegistered(retrofits))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // EARTHBRAIN企業IDチェック
      if (!(await checkEarthbrainCorporationId(retrofits))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // 利用中企業の制限チェック
      if (!(await checkCorporation(retrofits))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // Ntrip払い出しで未使用NtripDBの合計数が足りるかチェック
      if (!(await checkNtripUnusedTotal(retrofits))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // レトロフィット貸与中チェック
      if (!(await checkLending(retrofitIds))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // 譲渡申請中チェック
      if (!(await checkPendingTransfer(retrofitIds))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      //  代理申請中チェック
      if (!(await checkPendingAlternateRegistration(retrofitIds))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }
      // 現場の紐づき状態をチェック
      if (!(await checkSiteAttachment(retrofitIds))) {
        return false; // 処理中断（上位コンポーネント側でエラーダイアログを出す）
      }

      // レトロフィットの更新
      await updateRetrofitMany(retrofits, updateData);
      return true;
    };

    api()
      .then(result => {
        if (result) {
          notify('admin.message.success', 'info');
          handleDisableness();
        }
      })
      .catch(error => {
        const message = apiErrorHandler(error);
        if (message !== hideNofityError) {
          // 想定外エラーはダイアログ表示ではなくnotify表示
          notify(message, 'warning');
        }
      });
  };
  return (
    <>
      <DoneButton
        label="admin.actions.save"
        onClick={handleSave}
        disabled={disabled || saveButtonDisabled || loading}
      >
        <SaveIcon />
      </DoneButton>
      <DialogOKOnlyWithUrl
        open={serialErrorDialog.open}
        onClose={() => {
          setSerialErrorDialog({
            ...serialErrorDialog,
            open: false,
          });
        }}
        title=""
        nonTranslateMessage={true}
        messages={serialErrorDialog.messages}
        url={serialErrorDialog.hideUrl ? '' : scSupportRequestUrl} // 銘鈑抽出エラー以外はURLを表示する
        subMessages={serialErrorDialog.subMessages}
      />
    </>
  );
};

RetrofitNamePlateFileSaveButton.displayName = 'RetrofitNamePlateFileSaveButton';
export default RetrofitNamePlateFileSaveButton;
