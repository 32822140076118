import { AdminApiConverter } from '..';

const converter: AdminApiConverter<'extensionarmShares', 'extensionarmShares'> =
  {
    resource: 'extensionarmShares',
    defaultSort: { field: 'fromExtensionarmId', order: 'DESC' },
    filterToApi: filter => filter,
    sortFieldToApi: () => 'id',
    recordsFromApi: async records =>
      records.map(record => ({
        id: record.fromExtensionarmId,
        fromExtensionarmId: record.fromExtensionarmId,
        corporationId: record.corporationId,
        name: record.name,
      })),
    recordToApi: record => ({
      fromExtensionarmId: record.fromExtensionarmId,
      name: record.name,
      corporationId: record.corporationId,
    }),
  };

export default converter;
