import { useGetList } from 'react-admin';
import { ExtensionarmData } from '../../../../dataProvider';
import { objectToList } from '../../../../utils';

const useGetSiteExtensionarms = (siteId: string) => {
  const { data: keyValueData } = useGetList<ExtensionarmData>(
    'extensionarms',
    {
      page: 1,
      perPage: 1,
    },
    { field: 'updateDate', order: 'DESC' },
    {
      siteId,
    },
  );
  const data = objectToList(keyValueData);
  return data.length > 0;
};

export default useGetSiteExtensionarms;
