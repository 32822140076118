import { CustomerData } from '../../types';

const customers: CustomerData[] = [
  {
    id: '84cee5a0-fca3-4b09-a48c-820818b91e4d',
    name: 'テスト履歴企業B',
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
  },
  {
    id: '54d393e9-9314-4b2d-9f24-4640f3f70431',
    name: 'テスト履歴企業C',
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
  },
  {
    id: 'c8a1e90f-6fe6-47c8-8ab4-cf5cd328dbea',
    name: 'テスト履歴企業A',
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
  },
  {
    id: '820254f0-5098-49d7-b70c-6e47fa99ec28',
    name: 'テスト履歴企業D',
    fromCorporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
  },
  {
    id: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    name: 'クオリカ',
    fromCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
  },
];
export default customers;
