import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import { Tooltip } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { FunctionField, IconField } from '..';
import { ExtensionarmIcon } from '../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    svgIcon: {
      textAlign: 'center',
      paddingLeft: '10px',
    },
  }),
);

interface Props {
  addLabel: boolean;
  resource: string;
  source: string;
  record: any;
}

const ExtensionarmTypeField: FC<Partial<Props>> = props => {
  const { record } = props;
  if (!record) throw Error('Invalid record');
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <FunctionField
      resource="extensionarms"
      source="extensionarmType"
      render={extensionarmsRecord => {
        if (
          extensionarmsRecord?.extensionarmType === 'A' ||
          extensionarmsRecord?.extensionarmType === 'B'
        ) {
          return (
            <IconField
              resource="extensionarms"
              source="extensionarmType"
              record={extensionarmsRecord}
            />
          );
        }
        return (
          // AB以外、nullの場合は、固定アイコンを表示
          <Tooltip
            className={classes.svgIcon}
            title={translate('resources.extensionarms.extensionarmType.basic')}
          >
            <div className="icon-root">
              <ExtensionarmIcon />
            </div>
          </Tooltip>
        );
      }}
    />
  );
};

ExtensionarmTypeField.defaultProps = {
  addLabel: true,
};

ExtensionarmTypeField.displayName = 'ExtensionarmTypeField';
export default ExtensionarmTypeField;
