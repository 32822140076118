import { useDataProvider } from 'react-admin';
import { RetrofitCorporationLicenseData } from '../../../../dataProvider';

const useLicenseUpdate = (
  form: any,
  record: RetrofitCorporationLicenseData,
) => {
  const { id } = record;
  const dataProvider = useDataProvider();
  return async () => {
    const { values } = form.getState() as {
      values: RetrofitCorporationLicenseData;
    };
    const { retrofitId } = values;
    const data = !retrofitId
      ? { ...values, retrofitId: undefined } // RetrofitAutoCompleteInput でクリアボタン押下時は Field 毎消えるので明示的にクリアが必要
      : { ...values, retrofitId: Number(retrofitId) }; // RetrofitAutoCompleteInput で変更後は String型 になる為、キャストが必要
    return dataProvider.update('retrofitCorporationLicenses', {
      id,
      data,
      previousData: record,
    });
  };
};

export default useLicenseUpdate;
