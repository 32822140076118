import { useCallback } from 'react';
import { RetrofitData } from '../../../../dataProvider/types';

interface InParams {
  ownerIds: (string | undefined)[];
  ownerCorporationIds: string[];
  retrofits: RetrofitData[];
}

interface OutParams {
  data: {
    allIncluded: boolean;
    serialNumbers: string[];
  };
}

// 登録するレトロフィットがレトロフィット利用中企業登録可能企業に含まれることをチェック
const useIncludesOwnerCorporationId = () => {
  return useCallback(
    async ({
      ownerIds,
      ownerCorporationIds,
      retrofits,
    }: InParams): Promise<OutParams> => {
      if (ownerIds.length <= 0)
        return {
          data: {
            allIncluded: false,
            serialNumbers: [],
          },
        };

      const allIncluded = ownerIds
        .map(
          (ownerId: string | undefined) =>
            ownerId !== undefined && ownerCorporationIds.includes(ownerId),
        )
        .every((included: boolean) => included); // 全て要素が true の場合、戻り値として true を返す
      const serialNumbers = retrofits
        .filter(
          ({ ownerId }) =>
            ownerId !== undefined && !ownerCorporationIds.includes(ownerId),
        )
        .map(({ basicInfoSerialNumber }) => basicInfoSerialNumber);
      return {
        data: {
          allIncluded,
          serialNumbers,
        },
      };
    },
    [],
  );
};

export default useIncludesOwnerCorporationId;
