import React, { FC } from 'react';
import RetrofitManage from './RetrofitManage';
import { UpdateDataRegular } from './hooks';

interface Props {
  updateData: UpdateDataRegular;
}

const RetrofitManageRegular: FC<Partial<Props>> = ({ updateData, ...rest }) => {
  if (!updateData) throw new Error('Invalid updateData');
  const { corporationName } = updateData;
  return (
    <RetrofitManage
      corporationName={corporationName}
      updateData={updateData}
      {...rest}
    />
  );
};

RetrofitManageRegular.displayName = 'RetrofitManageRegular';
export default RetrofitManageRegular;
