import { useDataProvider } from 'react-admin';

interface InParams {
  ids: number[]; // retrofitCorporationLicenses.id
}

const useRemoveLicense = () => {
  const dataProvider = useDataProvider();
  return async ({ ids }: InParams): Promise<void> => {
    await dataProvider.updateMany('retrofitCorporationLicenses', {
      ids,
      data: {
        retrofitId: null,
      },
    });
  };
};

export default useRemoveLicense;
