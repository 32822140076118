import React from 'react';
import { useRedirect, useTranslate } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import {
  SimpleShowLayout,
  RetrofitBulkActionList,
  RetrofitBasicInfoList,
  TabData,
  TabContent,
  Divider,
  NtripList,
} from '../..';
import { styles } from '../../../theme';
import { useCustomLocation } from '../../../hooks';
import { trimOneString, config } from '../../../utils';

const useCreateRetrofitBasicInfoShowTabs = (props: any): TabData[] => {
  const translate = useTranslate();
  const tabData: TabData[] = [];
  tabData.push({
    name: 'retrofitBasicInfos',
    tab: (
      <MuiTab
        label={translate('admin.label.retrofitBasic.basicInfoTab')}
        key="tab_retrofitBasicInfos"
        value="retrofitBasicInfos"
      />
    ),
    content: <RetrofitBasicInfoList {...props} />,
    order: 1,
  });

  tabData.push({
    name: 'retrofitBulkActions',
    tab: (
      <MuiTab
        label={translate('admin.label.retrofitBasic.bulkActionTab')}
        key="tab_retrofitBulkActions"
        value="retrofitBulkActions"
      />
    ),
    content: <RetrofitBulkActionList {...props} />,
    order: 2,
  });

  if (config.feature.ntrips) {
    tabData.push({
      name: 'ntrips',
      tab: (
        <MuiTab
          label={translate('admin.label.retrofitBasic.ntripTab')}
          key="tab_ntrips"
          value="ntrips"
        />
      ),
      content: <NtripList {...props} />,
      order: 3,
    });
  }

  return tabData.sort((lhs, rhs) => (lhs.order > rhs.order ? 1 : -1));
};

const useStyles = makeStyles(() =>
  createStyles({
    simpleShowLayout: {
      ...styles.zeroPaddingHorizontal,
    },
  }),
);

const RetrofitBasicInfoTab: React.FC = props => {
  const { getBasePath } = useCustomLocation('');
  const basePath = getBasePath();
  const classes = useStyles();
  const tabs = useCreateRetrofitBasicInfoShowTabs(props);
  const redirectTo = useRedirect();
  const currentTab = trimOneString(basePath);
  const handleChange = (_event: any, newValue: string) => {
    redirectTo(`/${newValue}`);
  };
  return (
    <SimpleShowLayout className={classes.simpleShowLayout} {...props}>
      <>
        <MuiTabs value={currentTab} onChange={handleChange}>
          {tabs.map(({ tab }) => tab)};
        </MuiTabs>
        <Divider />
        {tabs.map(({ name, content }) => {
          return (
            <TabContent name={name} key={name} value={currentTab}>
              {content}
            </TabContent>
          );
        })}
      </>
    </SimpleShowLayout>
  );
};

RetrofitBasicInfoTab.displayName = 'RetrofitBasicInfoTab';
export default RetrofitBasicInfoTab;
