import { AdminApiConverter, ApiFilter } from '..';
import { RetrofitShareFileData } from '../../types';

const converter: AdminApiConverter<'retrofitShareFiles', 'retrofitShareFiles'> =
  {
    resource: 'retrofitShareFiles',
    defaultSort: { field: 'id', order: 'DESC' },
    filterToApi: filter => {
      const apiFilter: ApiFilter<'retrofitShareFiles'> = {};
      if ('id' in filter) apiFilter.id = filter.id;
      if ('retrofitId' in filter) apiFilter.retrofitId = filter.retrofitId;
      return apiFilter;
    },
    sortFieldToApi: field => {
      return field;
    },
    recordsFromApi: records =>
      Promise.resolve(
        records.map((record): RetrofitShareFileData => {
          return {
            id: record.id || '',
            retrofitId: record.retrofitId,
            shareFile: {
              id: record.shareFile?.id || '',
              name: record.shareFile?.name || '',
              description: record.shareFile?.description || '',
              blobFileStatus: record.shareFile?.blobFileStatus || 'Created',
              fileTime: record.shareFile?.fileTime || '',
              url: record.shareFile?.url || '',
            },
            createUser: record.createUser,
            createDate: record.createDate,
            updateUser: record.updateUser,
            updateDate: record.updateDate,
          };
        }),
      ),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    recordToApi: record => ({}),
  };
export default converter;
