import React, { FC, useEffect, useState } from 'react';
import { Loading, useDataProvider, useRedirect, useVersion } from 'react-admin';
import {
  usePageProps,
  useAccount,
  useGetSelefDetailData,
} from '../../../hooks';
import { AlternateRegistEdit, AlternateRegistShow } from './views';
import { RetrofitAlternateRegistData } from '../../../dataProvider/types';

const RetrofitAlternateRegistEdit: FC = () => {
  const props = usePageProps('retrofitAlternateRegists');
  const dataProvider = useDataProvider();
  const version = useVersion();
  const getAccount = useAccount();
  const redirectTo = useRedirect();
  const getSelefDetailData = useGetSelefDetailData();
  const { id: retrofitAlternateRegistId, basePath, resource } = props;

  const [apiState, setApiState] = useState<{
    loading: boolean;
    version: number;
    retrofitAlternateRegistId: string | undefined;
    data?: {
      id: number | undefined;
      retrofitAlternateRegistData: RetrofitAlternateRegistData | undefined;
      isEditMode: boolean;
      isOwnData: boolean | undefined;
    };
  }>({ loading: true, version: -1, retrofitAlternateRegistId: undefined });

  useEffect(() => {
    if (
      apiState.version === version &&
      apiState.retrofitAlternateRegistId === retrofitAlternateRegistId
    )
      return;
    setApiState({ loading: true, version, retrofitAlternateRegistId });

    const api = async () => {
      // retrofitAlternateRegistData
      const { data: retrofitAlternateRegistData } =
        await dataProvider.getOne<RetrofitAlternateRegistData>(
          'retrofitAlternateRegists',
          {
            id: Number(retrofitAlternateRegistId),
          },
        );
      const { id, isApproved } = retrofitAlternateRegistData;

      // 自社のretrofitAlternateRegistDataか判定
      const {
        data: { corporationId },
      } = await getAccount();
      const filter = {
        fromCorporationId: corporationId,
      };
      const selfData = await getSelefDetailData(
        resource,
        filter,
        Number(retrofitAlternateRegistId),
      );

      return {
        id,
        retrofitAlternateRegistData,
        isEditMode: !isApproved,
        isOwnData: !!selfData,
      };
    };

    api().then(data => {
      setApiState({
        loading: false,
        version,
        retrofitAlternateRegistId,
        data,
      });
    });
  }, [
    apiState,
    version,
    dataProvider,
    retrofitAlternateRegistId,
    basePath,
    resource,
    redirectTo,
    getAccount,
    getSelefDetailData,
  ]);

  if (apiState.loading || !apiState.data) return <Loading />;

  const { retrofitAlternateRegistData, isEditMode, isOwnData } = apiState.data;
  if (!isOwnData) redirectTo('/');

  return !isEditMode ? (
    <AlternateRegistShow
      props={props}
      retrofitAlternateRegistData={retrofitAlternateRegistData}
    />
  ) : (
    <AlternateRegistEdit
      props={props}
      retrofitAlternateRegistData={retrofitAlternateRegistData}
    />
  );
};

RetrofitAlternateRegistEdit.displayName = 'RetrofitAlternateRegistEdit';
export default RetrofitAlternateRegistEdit;
