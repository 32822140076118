import React from 'react';
import { FieldProps, InjectedFieldProps, useTranslate } from 'react-admin';
import { createStyles, makeStyles } from '@material-ui/core';
import { ResourceProps, ResourceName, Typography } from '../..';
import useTranslateMultiLine from '../../../hooks/useTranslateMultiLine';

const useStyles = makeStyles(() =>
  createStyles({
    errormessage: {
      whiteSpaceCollapse: 'preserve-breaks',
    },
  }),
);

interface ResourceFC<P = {}> extends React.FC {
  <T extends ResourceName>(
    props: React.PropsWithChildren<P & ResourceProps<T>>,
    context?: any,
  ): React.ReactElement | null;
}

type Props = Partial<FieldProps & InjectedFieldProps>;

const ErrorInfoField: React.FC<Props> = ({ ...props }) => {
  const { resource, record, source } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const translateMultiLine = useTranslateMultiLine();
  const errorFileNames = record?.resultInfo?.errorFileNames;
  const errorLayerNames = record?.resultInfo?.errorLayerNames;
  if (!resource || !source) return <></>;
  const values = record ? record[`${source}`] : [];
  const messages = values.map(
    (value: string) =>
      `admin.errorInfo.${resource}.${value.toLocaleLowerCase()}`,
  );

  const getErrorFiles = (errorFiles: string[]) => {
    return errorFiles.map((errorFile: string) => (
      <Typography key={errorFile}>{errorFile}</Typography>
    ));
  };

  return (
    <>
      {messages.map((message: string) => {
        return (
          <>
            {/** エラーメッセージ */}
            <div key={message} className={classes.errormessage}>
              <Typography>{translateMultiLine(message)}</Typography>
            </div>
            {/** [対象ファイル]*/}
            {errorFileNames && errorFileNames.length > 0 ? (
              <>
                <br></br>
                <Typography>
                  {translate('admin.errorInfo.projects.targetFiles')}
                </Typography>
                {getErrorFiles(errorFileNames)}
              </>
            ) : (
              <></>
            )}
            {/** 対象レイヤー */}
            {errorLayerNames && errorLayerNames.length > 0 ? (
              <>
                <br></br>
                <Typography>
                  {translate('admin.errorInfo.projects.targetLayers')}
                </Typography>
                {getErrorFiles(errorLayerNames)}
              </>
            ) : (
              <></>
            )}
          </>
        );
      })}
    </>
  );
};

ErrorInfoField.displayName = 'ErrorInfoField';
ErrorInfoField.defaultProps = {
  addLabel: true,
  label: '',
};
export default ErrorInfoField as ResourceFC<Props>;
