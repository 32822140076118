import { UpdateResult } from 'react-admin';
import { callApiPointAvailability, RemotePointAvailability } from '.';

export default async (remoteSessionId: string): Promise<UpdateResult> => {
  // 戻り値に、getOneと同様の型であるUpdateResultを使用する
  const pointAvailability = await callApiPointAvailability(remoteSessionId);
  const data: RemotePointAvailability = {
    id: remoteSessionId,
    pointAvailabilityStatus: pointAvailability.point_availability_status,
    statusComment: pointAvailability.status_comment,
  };
  return { data };
};
