import { AuthProvider } from 'react-admin';
import {
  fakeAuthProvider,
  landlogAuthProvider,
  Permissions,
  Token,
  UserInfo,
} from '.';
import { getDataProvider, UserPermissionsData } from '../dataProvider';
import { config, countryCodeStore, lastDialogOpenedTimeStore } from '../utils';
import getAccount from './getAccount';
import getLandlogapps from './getLandlogapps';
import getLicenses from './getLicenses';

const getLandlogPermission = async () => {
  if (config.auth.useFake) {
    const token = '';
    const userInfo: UserInfo = {
      email: 'test_user@example.com',
      familyName: '',
      givenName: '',
      emailVerified: true,
      name: 'レトロフィット太郎',
      sub: 'xxx',
    };
    return { token, userInfo };
  }
  const permissions: {
    token: Token;
    userInfo: UserInfo;
  } = await landlogAuthProvider.getPermissions(null);
  return { token: permissions.token.idToken, userInfo: permissions.userInfo };
};

let userRoles: UserPermissionsData['userRoles'];
const getPermissions = async (): Promise<Permissions> => {
  // ID Token
  const { token, userInfo } = await getLandlogPermission();

  // API経由でユーザー権限を取得
  if (!userRoles) {
    const dataProvider = getDataProvider();
    const { data: userPermissionData } = await dataProvider.getList(
      'userPermissions',
      {
        filter: { email: userInfo.email },
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'ASC' },
      },
    );
    if (userPermissionData && userPermissionData.length !== 0) {
      userRoles = userPermissionData[0].userRoles;
    } else {
      userRoles = [];
    }
  }
  return { token, userRoles, userInfo };
};

const getIdentity = async () => {
  const account = await getAccount();
  return {
    ...account,
    id: account.email,
    fullName: account.name,
    avatar: account.picture,
  };
};

const validateLicense = async () => {
  const { items } = await getLicenses();
  const apps = await getLandlogapps();
  // 必要なライセンスを購入しているかチェック
  if (
    items.some(item =>
      apps.some(
        app => app.itemId === item.itemId && app.planId === item.planId,
      ),
    )
  ) {
    return true;
  }
  return false;
};

export default (): AuthProvider => {
  const authProvider = config.auth.useFake
    ? fakeAuthProvider
    : landlogAuthProvider;
  const login = async (params: unknown) => {
    await authProvider.login(params);
    if (!(await validateLicense()))
      throw new Error('admin.message.licenseError');

    countryCodeStore.clear();
    lastDialogOpenedTimeStore.clear();
  };
  const checkAuth = async (params: unknown) => {
    await authProvider.checkAuth(params);
    if (!(await validateLicense()))
      throw new Error('admin.message.licenseError');
  };
  return {
    ...authProvider,
    login,
    checkAuth,
    getPermissions,
    getIdentity,
  };
};
