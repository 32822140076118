import { AdminApiConverter } from '..';
import { CompactionWorkAreaData } from '../../types';

const converter: AdminApiConverter<
  'compactionWorkAreas',
  'compactionWorkAreas'
> = {
  resource: 'compactionWorkAreas',
  defaultSort: { field: 'name', order: 'ASC' },
  filterToApi: filter => filter,
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(
        (record): CompactionWorkAreaData => ({
          id: record.id || 0,
          projectId: record.projectId,
          name: record.name || '',
          description: record.description,
          blockSize: record.blockSize,
          enable: record.enable,
        }),
      ),
    ),
  recordToApi: record => ({
    projectId: record.projectId,
    name: record.name,
    description: record?.description,
    blockSize: record.blockSize,
    enable: record.enable,
  }),
};

export default converter;
