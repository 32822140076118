const KEY_LAST_DIALOG_OPENED_TIME = 'last_dialog_opened_time';

const lastDialogOpenedTimeStore = {
  get: () => {
    return localStorage.getItem(KEY_LAST_DIALOG_OPENED_TIME);
  },
  set: () => {
    const now = new Date().toISOString();
    localStorage.setItem(KEY_LAST_DIALOG_OPENED_TIME, now);
  },
  clear: () => {
    localStorage.setItem(KEY_LAST_DIALOG_OPENED_TIME, '');
  },
};

export default lastDialogOpenedTimeStore;
