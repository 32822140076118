import { AdminApiConverter } from '..';
import { LicenseAgreementData } from '../../types';

const converter: AdminApiConverter<'licenseAgreements', 'licenseAgreements'> = {
  resource: 'licenseAgreements',
  defaultSort: { field: 'email', order: 'DESC' },
  filterToApi: filter => filter,
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map((record): LicenseAgreementData => {
        return {
          id: record.id,
          email: record.email,
          agreement: record.agreement,
          termsConditionId: record.termsConditionId,
        };
      }),
    ),
  recordToApi: record => {
    return {
      email: record.email,
      agreement: record.agreement,
      termsConditionId: record.termsConditionId,
    };
  },
};

export default converter;
