import React, { FC } from 'react';
import {
  DateField,
  Show,
  FunctionField,
  SimpleShowLayout,
  useTranslate,
  TextField,
} from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import { useResource } from '../../../../hooks';
import {
  BreadcrumbsFiled,
  CustomChildBox,
  CustomForm,
  CustomParentBox,
} from '../../../molecules';
import { IconFieldPaddingZeroStyle, Typography } from '../../../atoms';

const useStyles = makeStyles(() =>
  createStyles({
    titleFirst: {
      padding: '12px 0px',
    },
    title: {
      marginTop: '12px',
      padding: '12px 0px',
      borderTop: '1px solid #F5F5FF33', // 各項目表題の上部に白線追加
    },
    form: {
      '& .MuiFormControl-root': {
        marginTop: 8,
        marginBottom: 10,
        '& .MuiFormLabel-root': {
          width: 290,
        },
      },
      '& .MuiCardContent-root': {
        width: 490,
        padding: 0,
      },
      ...IconFieldPaddingZeroStyle,
    },
    machineName: {
      fontSize: 18,
      fontWeight: 'bold',
      paddingTop: 16,
    },
    dateTime: {
      paddingLeft: 90,
    },
    machineType: {
      '& .MuiTypography-root': {
        paddingLeft: 95,
        fontSize: 16,
      },
    },
    textField: {
      '& .MuiTypography-root': {
        paddingLeft: 95,
        fontSize: 16,
      },
    },
    textNumberField: {
      '& .MuiTypography-root': {
        fontSize: 16,
      },
      '& div > div': {
        textAlign: 'right',
        paddingRight: '24px',
        width: '50%',
      },
    },
    extendedModel: {
      alignItems: 'baseline',
      '& > p': {
        fontSize: 12,
      },
      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        '& > div': {
          paddingRight: '33%',
        },
      },
    },
    space: {
      marginBottom: 8,
    },
    flexCenter: {
      justifyContent: 'center',
      width: '100%',
      '& > div': {
        // TopPagination の margin を消す
        margin: 0,
      },
    },
    calibtitle: {
      padding: '4px',
      backgroundColor: '#31323A',
      width: '950px',
    },
  }),
);

const RetrofitFlexCalibShow: FC<{
  props: any;
  breadcrumbs: string[];
}> = ({ props, breadcrumbs }) => {
  const resource = useResource('retrofitCalibs');
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Show {...props}>
      <CustomForm
        title="admin.pages.retrofitCalibInfo"
        redirect={false}
        saveButton={false}
        deleteButton={false}
        className={classes.form}
      >
        <BreadcrumbsFiled
          breadcrumbs={breadcrumbs}
          label="admin.label.calibrationDetail.breadcrumbs"
        />

        {/* 作成日時・更新日時 */}
        <DateField
          className={classes.dateTime}
          resource={resource}
          source="detailInfo.createDateTime"
          showTime
        />
        <DateField
          className={classes.dateTime}
          resource={resource}
          source="detailInfo.updateDateTime"
          showTime
        />

        {/* マシン情報 */}
        <Typography className={classes.title}>
          {translate('admin.label.calibrationDetail.machineInfo')}
        </Typography>
        <CustomParentBox>
          <SimpleShowLayout className={classes.space}>
            <FunctionField
              className={classes.machineType}
              resource={resource}
              source="detailInfo.machineInfo.machineType"
              render={(record: any) => {
                const res = 'resources.retrofits.machineInfoMachineType'; // 建機情報のためretrofitsリソース使用
                const type =
                  record?.detailInfo?.machineInfo?.machineType.toLowerCase();
                const trans = translate(`${res}.${type}`);
                // 翻訳できなければ、もとの内容をそのまま返す
                if (trans.startsWith(res)) return type;
                return trans;
              }}
            />
            <TextField
              className={classes.textField}
              resource={resource}
              source="detailInfo.machineInfo.machineId"
            />
            <TextField
              className={classes.textField}
              resource={resource}
              source="detailInfo.machineInfo.machineName"
            />
            <TextField
              className={classes.textField}
              resource={resource}
              source="detailInfo.machineInfo.companyName"
            />
            <TextField
              className={classes.textField}
              resource={resource}
              source="detailInfo.machineInfo.drumConfiguration"
            />
            <TextField
              className={classes.textField}
              resource={resource}
              source="detailInfo.machineInfo.guidanceMode"
            />
          </SimpleShowLayout>
        </CustomParentBox>

        {/* マシンジオメトリ情報 */}
        <Typography className={classes.title} />
        <CustomParentBox>
          <CustomChildBox
            title={translate(
              'admin.label.calibrationDetail.machineGeometryInfo',
            )}
          >
            <SimpleShowLayout className={classes.space}>
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.machineGeometryInfo.drumWidth"
              />
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.machineGeometryInfo.length"
              />
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.machineGeometryInfo.shoeInterval"
              />
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.machineGeometryInfo.bladeWidthForDozer"
              />
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.machineGeometryInfo.distanceFromBodyToBlade"
              />
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.machineGeometryInfo.frontToMainGnssAntenna"
              />
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.machineGeometryInfo.frontToSubGnssAntenna"
              />
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.machineGeometryInfo.centerToMainGnssAntenna"
              />
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.machineGeometryInfo.centerToSubGnssAntenna"
              />
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.machineGeometryInfo.groundToMainGnssAntenna"
              />
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.machineGeometryInfo.groundToSubGnssAntenna"
              />
            </SimpleShowLayout>
          </CustomChildBox>

          {/* IMUマウント情報 */}
          <CustomChildBox
            title={translate('admin.label.calibrationDetail.imuMountInfo')}
          >
            <SimpleShowLayout className={classes.space}>
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.imuMountInfo.bodyImuOffsetRollAngle"
              />
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.imuMountInfo.bodyImuOffsetPitchAngle"
              />
            </SimpleShowLayout>
          </CustomChildBox>
        </CustomParentBox>

        {/* メイン・サブGNSSマウント情報 */}
        <CustomParentBox>
          <CustomChildBox
            title={translate(
              'admin.label.calibrationDetail.bodyCenterToGnssMain',
            )}
          >
            <SimpleShowLayout className={classes.space}>
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.gnssMountInfo.main.bodyCenterToGnss.x"
              />
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.gnssMountInfo.main.bodyCenterToGnss.y"
              />
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.gnssMountInfo.main.bodyCenterToGnss.z"
              />
            </SimpleShowLayout>
          </CustomChildBox>
          <CustomChildBox
            title={translate(
              'admin.label.calibrationDetail.bodyCenterToGnssSub',
            )}
          >
            <SimpleShowLayout className={classes.space}>
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.gnssMountInfo.sub.bodyCenterToGnss.x"
              />
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.gnssMountInfo.sub.bodyCenterToGnss.y"
              />
              <TextField
                className={classes.textNumberField}
                resource={resource}
                source="detailInfo.gnssMountInfo.sub.bodyCenterToGnss.z"
              />
            </SimpleShowLayout>
          </CustomChildBox>
        </CustomParentBox>

        {/* キャリブレーション情報 */}
        <Typography className={classes.calibtitle}>
          {translate('admin.label.calibrationDetail.measuredValues')}
        </Typography>
        <CustomParentBox>
          <SimpleShowLayout className={classes.space}>
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.bodyImu1.roll"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.bodyImu1.pitch"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.bodyImu1.yaw"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.bodyImu2.roll"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.bodyImu2.pitch"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.bodyImu2.yaw"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.frontLeft.x"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.frontLeft.y"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.frontLeft.z"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.frontRight.x"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.frontRight.y"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.frontRight.z"
            />
          </SimpleShowLayout>
          <SimpleShowLayout className={classes.space}>
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.frontGroundingEnd.x"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.frontGroundingEnd.y"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.frontGroundingEnd.z"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.rearGroundingEnd.x"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.rearGroundingEnd.y"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.rearGroundingEnd.z"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.mainGnssAntenna.x"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.mainGnssAntenna.y"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.mainGnssAntenna.z"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.subGnssAntenna.x"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.subGnssAntenna.y"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.subGnssAntenna.z"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.prismPoleOffset"
            />
            <TextField
              className={classes.textNumberField}
              resource={resource}
              source="detailInfo.measuredValues.antennaElectricalCenterHeight"
            />
          </SimpleShowLayout>
        </CustomParentBox>
      </CustomForm>
    </Show>
  );
};

RetrofitFlexCalibShow.displayName = 'RetrofitFlexCalibShow';
export default RetrofitFlexCalibShow;
