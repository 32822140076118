import { TextInput } from 'react-admin';
import { InputProps as RaInputProps } from 'ra-core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { ResourceFC } from '..';

export type TextInputProps = RaInputProps<TextFieldProps> &
  Omit<TextFieldProps, 'label' | 'helperText'>;

const { defaultProps } = TextInput;
TextInput.defaultProps = {
  ...defaultProps,
  autoComplete: 'off',
};
TextInput.displayName = 'TextInput';
export default TextInput as ResourceFC<TextInputProps>;
