import React from 'react';
import {
  Badge as MuiBadge,
  BadgeProps as MuiBadgeProps,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    badge: {
      backgroundColor: '#FF2154', // NOTE: 今後、他の色を出し分ける必要がある場合は要修正
    },
  }),
);

const DotBadge: React.FC<MuiBadgeProps> = props => {
  const classes = useStyles();
  return (
    <MuiBadge variant="dot" classes={{ badge: classes.badge }} {...props} />
  );
};

DotBadge.displayName = 'DotBadge';
export default DotBadge;
