import { DatagridBody as RaDatagridBody } from 'react-admin';
import { TableBodyProps as MuiProps } from '@material-ui/core/TableBody';
import {
  ReactElementLike,
  ReactComponentLike,
  ReactNodeLike,
} from 'prop-types';
import { AnyObject } from '../../../utils/types';

interface RaProps {
  basePath: string;
  classes: AnyObject;
  className: string;
  children: ReactNodeLike;
  data: AnyObject;
  expand: ReactElementLike | ReactComponentLike;
  hasBulkActions: boolean;
  hover: boolean;
  ids: any[];
  onToggleItem: Function;
  resource: string;
  row: ReactElementLike;
  rowClick: string | Function;
  rowStyle: Function;
  selectedIds: any[];
  styles: AnyObject;
  version: number;
}

const DatagridBody = RaDatagridBody as ForgivingComponentType<
  MuiProps & RaProps
>;
DatagridBody.displayName = 'DatagridBody';

export default DatagridBody;
