/**
 * 設定されたDate変数に1日足す（undefinedの場合はそのまま返却）
 **/
const addOneDay = (date: Date | undefined): Date | undefined => {
  if (!date) return undefined;
  const current = new Date(date);
  current.setDate(current.getDate() + 1);
  return current;
};

export default addOneDay;
