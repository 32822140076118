import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useRedirect } from 'react-admin';
import {
  BreadcrumbsFiled,
  CustomForm,
  CustomDateField,
  DeleteFromSiteButton,
  Edit,
  Loading,
  TextField,
  LoadingView,
  BucketStateView,
  FunctionField,
  IconFileField,
  IconField,
  IconFieldPaddingZeroStyle,
} from '../../..';
import {
  useAccount,
  useEditInSite,
  useGetSelefDetailData,
} from '../../../../hooks';
import { getParentPath } from '../../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      ...IconFieldPaddingZeroStyle,
    },
  }),
);

export const SiteBucketEdit: React.FC = () => {
  const classes = useStyles();
  const { props, site } = useEditInSite('buckets');
  const { id: bucketId, basePath, resource } = props;
  const getAccount = useAccount();
  const getSelefDetailData = useGetSelefDetailData();
  const redirectTo = useRedirect();

  const [status, setStatus] = useState('');
  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    bucketId: string | undefined;
    data?: { isOwnData?: boolean };
  }>({ loading: true, fetched: false, bucketId, data: undefined });

  useEffect(() => {
    if (apiState.fetched && apiState.bucketId === bucketId) return;
    setApiState({ loading: true, fetched: true, bucketId });

    // urlからsiteIdを取得する
    const url = getParentPath(basePath).split('/');
    const siteId = {
      siteId: url[2],
    };

    const api = async () => {
      const bucket = await getSelefDetailData(resource, siteId, bucketId);
      return { isOwnData: !!bucket };
    };
    api().then(data => {
      setApiState({
        loading: false,
        fetched: true,
        bucketId,
        data,
      });
    });
  }, [
    getSelefDetailData,
    bucketId,
    getAccount,
    apiState.fetched,
    apiState.bucketId,
    redirectTo,
    resource,
    basePath,
  ]);

  const onStateHandle = useCallback(
    newStatus => {
      setStatus(newStatus);
    },
    [setStatus],
  );

  if (!site) return <Loading />;
  const { name: siteName } = site;

  if (!siteName) return <Loading />;
  if (apiState.loading) return <Loading />;
  const isOwnData = apiState.data?.isOwnData;
  if (!isOwnData) redirectTo('/');

  return (
    <LoadingView isForceBlocking={status === 'Converting'}>
      <Edit {...props}>
        <CustomForm
          title="admin.pages.siteBucketEdit"
          redirect={false}
          deleteButton={
            <DeleteFromSiteButton disabled={status === 'Converting'} />
          }
          saveButton={false}
          cancelButton={false}
          className={classes.form}
        >
          <BucketStateView onStateChange={onStateHandle} />
          <BreadcrumbsFiled
            breadcrumbs={[
              'resources.sites.name',
              '',
              siteName,
              'resources.buckets.name',
            ]}
            label="admin.breadcrumbs.details"
          />
          {/* NOTE: BucketEdit とほぼ同じなので共通化してもよいのでは */}
          <TextField resource={resource} source="name" />
          <TextField resource={resource} source="bucketType" />
          <FunctionField
            resource={resource}
            source="bk3File"
            render={record => (
              <IconFileField record={record} source="bk3File" />
            )}
          />
          <FunctionField
            resource={resource}
            source="gltfFile"
            render={record => (
              <IconFileField record={record} source="gltfFile" />
            )}
          />
          <TextField resource={resource} source="uploadedBy" />
          <IconField resource={resource} source="fileStatus" />
          <CustomDateField resource={resource} source="lastUpdated" showTime />
          <TextField resource={resource} source="updatedBy" />
        </CustomForm>
      </Edit>
    </LoadingView>
  );
};
SiteBucketEdit.displayName = 'SiteBucketEdit';

export default SiteBucketEdit;
