import React, { FC, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CustomForm,
  CustomList,
  Datagrid,
  CustomDateField,
  FunctionField,
  IconField,
  TextField,
  ToggleAddSiteButton,
  CreateButton,
  SiteProjectExpand,
  MultiLineCustomDialog,
  ErrorInfoField,
  IconButton,
  ListDownloadButton,
  Loading,
} from '../../..';
import { useResource, useGetUnitLength } from '../../../../hooks';
import { ProjectsData } from '../../../../dataProvider';
import { styles } from '../../../../theme';
import { AddIcon } from '../../../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .IconButton': {
        minWidth: 50,
        minHeight: 50,
      },
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 16,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 16,
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 'auto',
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 80,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 80,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 112,
          },
          '& .MuiTableCell-root:nth-child(7)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 50,
          },
        },
      },
    },
    dialogForm: {
      '& .icon-root': {
        position: 'absolute',
        left: 10,
        top: 76,
      },
    },
  }),
);

interface Props {
  siteId: string;
}

const SiteProjectList: FC<Partial<Props>> = props => {
  const location = useLocation();
  const resource = useResource('projects');
  const classes = useStyles();
  const [projectRecord, setProjectRecord] = useState<ProjectsData | undefined>(
    undefined,
  );
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const handleStatusIconClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, record) => {
      e.stopPropagation();
      setOpen(true);
      setProjectRecord(record);
    },
    [setOpen, setProjectRecord],
  );

  const handleValidButton = useCallback(record => {
    const { status } = record as ProjectsData;
    return status === 'Error';
  }, []);

  const {
    loading,
    data: { unitLength },
  } = useGetUnitLength({ siteId: props.siteId });
  if (loading) return <Loading />;

  return (
    <>
      <MultiLineCustomDialog
        open={open}
        onClose={handleClose}
        title="admin.dialog.projectConversionStatus.title"
        okLabel="admin.dialog.projectConversionStatus.button"
        testId="projects_info_dialog"
      >
        <div style={styles.multiLineErrorInfo}>
          <CustomForm
            resource={resource}
            record={projectRecord}
            deleteButton={false}
            cancelButton={false}
            saveButton={false}
            className={classes.dialogForm}
          >
            <IconField resource={resource} source="status" addLabel={false} />
            <ErrorInfoField resource={resource} source="errorInfo" />
          </CustomForm>
        </div>
      </MultiLineCustomDialog>
      <FunctionField
        {...props}
        resource="sites"
        addLabel={false}
        render={record =>
          record ? (
            <CustomList
              breadcrumbs={['resources.sites.name']}
              resource={resource}
              controllerResource={`${resource}_${record.id}`}
              basePath={location.pathname}
              location={location}
              filter={{ siteId: record.id }}
              actions={
                <ToggleAddSiteButton {...props} label="ra.action.create">
                  <CreateButton>
                    <AddIcon />
                  </CreateButton>
                </ToggleAddSiteButton>
              }
              hasCreate
            >
              <Datagrid
                rowClick="edit"
                className={classes.root}
                expand={<SiteProjectExpand unitLength={unitLength} />}
              >
                <TextField resource={resource} source="name" />
                <TextField resource={resource} source="version" />
                <IconField
                  resource={resource}
                  source="status"
                  button={<IconButton onClick={handleStatusIconClick} />}
                  validButton={handleValidButton}
                />
                <CustomDateField resource={resource} source="lastUpdated" />
                <FunctionField
                  resource={resource}
                  source="projectZipFile"
                  sortable={false}
                  label=""
                  render={siteProjectRecord => {
                    const data = siteProjectRecord as ProjectsData;
                    const url = data.projectZipFile?.src || '';
                    return (
                      <ListDownloadButton
                        url={url}
                        label="projectZipDownload"
                        smallicon={true}
                      />
                    );
                  }}
                />
              </Datagrid>
            </CustomList>
          ) : null
        }
      />
    </>
  );
};

SiteProjectList.displayName = 'SiteProjectList';
export default SiteProjectList;
