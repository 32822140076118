import { ProjectionData } from '../../types';

const projections: ProjectionData[] = [
  {
    id: 1,
    name: 'UTM zone 1N',
    regionId: 1,
  },
  {
    id: 2,
    name: 'UTM zone 2N',
    regionId: 1,
  },
  {
    id: 3,
    name: 'UTM zone 3N',
    regionId: 1,
  },
  {
    id: 4,
    name: 'UTM zone 4N',
    regionId: 1,
  },
  {
    id: 5,
    name: 'UTM zone 5N',
    regionId: 1,
  },
  {
    id: 6,
    name: 'UTM zone 6N',
    regionId: 1,
  },
  {
    id: 7,
    name: 'UTM zone 7N',
    regionId: 1,
  },
  {
    id: 8,
    name: 'UTM zone 8N',
    regionId: 1,
  },
  {
    id: 9,
    name: 'UTM zone 9N',
    regionId: 1,
  },
  {
    id: 10,
    name: 'UTM zone 10N',
    regionId: 1,
  },
  {
    id: 11,
    name: 'UTM zone 11N',
    regionId: 1,
  },
  {
    id: 12,
    name: 'UTM zone 12N',
    regionId: 1,
  },
  {
    id: 13,
    name: 'UTM zone 13N',
    regionId: 1,
  },
  {
    id: 14,
    name: 'UTM zone 14N',
    regionId: 1,
  },
  {
    id: 15,
    name: 'UTM zone 15N',
    regionId: 1,
  },
  {
    id: 16,
    name: 'UTM zone 16N',
    regionId: 1,
  },
  {
    id: 17,
    name: 'UTM zone 17N',
    regionId: 1,
  },
  {
    id: 18,
    name: 'UTM zone 18N',
    regionId: 1,
  },
  {
    id: 19,
    name: 'UTM zone 19N',
    regionId: 1,
  },
  {
    id: 20,
    name: 'UTM zone 20N',
    regionId: 1,
  },
  {
    id: 21,
    name: 'UTM zone 21N',
    regionId: 1,
  },
  {
    id: 22,
    name: 'UTM zone 22N',
    regionId: 1,
  },
  {
    id: 23,
    name: 'UTM zone 23N',
    regionId: 1,
  },
  {
    id: 24,
    name: 'UTM zone 24N',
    regionId: 1,
  },
  {
    id: 25,
    name: 'UTM zone 25N',
    regionId: 1,
  },
  {
    id: 26,
    name: 'UTM zone 26N',
    regionId: 1,
  },
  {
    id: 27,
    name: 'UTM zone 27N',
    regionId: 1,
  },
  {
    id: 28,
    name: 'UTM zone 28N',
    regionId: 1,
  },
  {
    id: 29,
    name: 'UTM zone 29N',
    regionId: 1,
  },
  {
    id: 30,
    name: 'UTM zone 30N',
    regionId: 1,
  },
  {
    id: 31,
    name: 'UTM zone 31N',
    regionId: 1,
  },
  {
    id: 32,
    name: 'UTM zone 32N',
    regionId: 1,
  },
  {
    id: 33,
    name: 'UTM zone 33N',
    regionId: 1,
  },
  {
    id: 34,
    name: 'UTM zone 34N',
    regionId: 1,
  },
  {
    id: 35,
    name: 'UTM zone 35N',
    regionId: 1,
  },
  {
    id: 36,
    name: 'UTM zone 36N',
    regionId: 1,
  },
  {
    id: 37,
    name: 'UTM zone 37N',
    regionId: 1,
  },
  {
    id: 38,
    name: 'UTM zone 38N',
    regionId: 1,
  },
  {
    id: 39,
    name: 'UTM zone 39N',
    regionId: 1,
  },
  {
    id: 40,
    name: 'UTM zone 40N',
    regionId: 1,
  },
  {
    id: 41,
    name: 'UTM zone 41N',
    regionId: 1,
  },
  {
    id: 42,
    name: 'UTM zone 42N',
    regionId: 1,
  },
  {
    id: 43,
    name: 'UTM zone 43N',
    regionId: 1,
  },
  {
    id: 44,
    name: 'UTM zone 44N',
    regionId: 1,
  },
  {
    id: 45,
    name: 'UTM zone 45N',
    regionId: 1,
  },
  {
    id: 46,
    name: 'UTM zone 46N',
    regionId: 1,
  },
  {
    id: 47,
    name: 'UTM zone 47N',
    regionId: 1,
  },
  {
    id: 48,
    name: 'UTM zone 48N',
    regionId: 1,
  },
  {
    id: 49,
    name: 'UTM zone 49N',
    regionId: 1,
  },
  {
    id: 50,
    name: 'UTM zone 50N',
    regionId: 1,
  },
  {
    id: 51,
    name: 'UTM zone 51N',
    regionId: 1,
  },
  {
    id: 52,
    name: 'UTM zone 52N',
    regionId: 1,
  },
  {
    id: 53,
    name: 'UTM zone 53N',
    regionId: 1,
  },
  {
    id: 54,
    name: 'UTM zone 54N',
    regionId: 1,
  },
  {
    id: 55,
    name: 'UTM zone 55N',
    regionId: 1,
  },
  {
    id: 56,
    name: 'UTM zone 56N',
    regionId: 1,
  },
  {
    id: 57,
    name: 'UTM zone 57N',
    regionId: 1,
  },
  {
    id: 58,
    name: 'UTM zone 58N',
    regionId: 1,
  },
  {
    id: 59,
    name: 'UTM zone 59N',
    regionId: 1,
  },
  {
    id: 60,
    name: 'UTM zone 60N',
    regionId: 1,
  },
  {
    id: 61,
    name: 'UTM zone 1S',
    regionId: 1,
  },
  {
    id: 62,
    name: 'UTM zone 2S',
    regionId: 1,
  },
  {
    id: 63,
    name: 'UTM zone 3S',
    regionId: 1,
  },
  {
    id: 64,
    name: 'UTM zone 4S',
    regionId: 1,
  },
  {
    id: 65,
    name: 'UTM zone 5S',
    regionId: 1,
  },
  {
    id: 66,
    name: 'UTM zone 6S',
    regionId: 1,
  },
  {
    id: 67,
    name: 'UTM zone 7S',
    regionId: 1,
  },
  {
    id: 68,
    name: 'UTM zone 8S',
    regionId: 1,
  },
  {
    id: 69,
    name: 'UTM zone 9S',
    regionId: 1,
  },
  {
    id: 70,
    name: 'UTM zone 10S',
    regionId: 1,
  },
  {
    id: 71,
    name: 'UTM zone 11S',
    regionId: 1,
  },
  {
    id: 72,
    name: 'UTM zone 12S',
    regionId: 1,
  },
  {
    id: 73,
    name: 'UTM zone 13S',
    regionId: 1,
  },
  {
    id: 74,
    name: 'UTM zone 14S',
    regionId: 1,
  },
  {
    id: 75,
    name: 'UTM zone 15S',
    regionId: 1,
  },
  {
    id: 76,
    name: 'UTM zone 16S',
    regionId: 1,
  },
  {
    id: 77,
    name: 'UTM zone 17S',
    regionId: 1,
  },
  {
    id: 78,
    name: 'UTM zone 18S',
    regionId: 1,
  },
  {
    id: 79,
    name: 'UTM zone 19S',
    regionId: 1,
  },
  {
    id: 80,
    name: 'UTM zone 20S',
    regionId: 1,
  },
  {
    id: 81,
    name: 'UTM zone 21S',
    regionId: 1,
  },
  {
    id: 82,
    name: 'UTM zone 22S',
    regionId: 1,
  },
  {
    id: 83,
    name: 'UTM zone 23S',
    regionId: 1,
  },
  {
    id: 84,
    name: 'UTM zone 24S',
    regionId: 1,
  },
  {
    id: 85,
    name: 'UTM zone 25S',
    regionId: 1,
  },
  {
    id: 86,
    name: 'UTM zone 26S',
    regionId: 1,
  },
  {
    id: 87,
    name: 'UTM zone 27S',
    regionId: 1,
  },
  {
    id: 88,
    name: 'UTM zone 28S',
    regionId: 1,
  },
  {
    id: 89,
    name: 'UTM zone 29S',
    regionId: 1,
  },
  {
    id: 90,
    name: 'UTM zone 30S',
    regionId: 1,
  },
  {
    id: 91,
    name: 'UTM zone 31S',
    regionId: 1,
  },
  {
    id: 92,
    name: 'UTM zone 32S',
    regionId: 1,
  },
  {
    id: 93,
    name: 'UTM zone 33S',
    regionId: 1,
  },
  {
    id: 94,
    name: 'UTM zone 34S',
    regionId: 1,
  },
  {
    id: 95,
    name: 'UTM zone 35S',
    regionId: 1,
  },
  {
    id: 96,
    name: 'UTM zone 36S',
    regionId: 1,
  },
  {
    id: 97,
    name: 'UTM zone 37S',
    regionId: 1,
  },
  {
    id: 98,
    name: 'UTM zone 38S',
    regionId: 1,
  },
  {
    id: 99,
    name: 'UTM zone 39S',
    regionId: 1,
  },
  {
    id: 100,
    name: 'UTM zone 40S',
    regionId: 1,
  },
  {
    id: 101,
    name: 'UTM zone 41S',
    regionId: 1,
  },
  {
    id: 102,
    name: 'UTM zone 42S',
    regionId: 1,
  },
  {
    id: 103,
    name: 'UTM zone 43S',
    regionId: 1,
  },
  {
    id: 104,
    name: 'UTM zone 44S',
    regionId: 1,
  },
  {
    id: 105,
    name: 'UTM zone 45S',
    regionId: 1,
  },
  {
    id: 106,
    name: 'UTM zone 46S',
    regionId: 1,
  },
  {
    id: 107,
    name: 'UTM zone 47S',
    regionId: 1,
  },
  {
    id: 108,
    name: 'UTM zone 48S',
    regionId: 1,
  },
  {
    id: 109,
    name: 'UTM zone 49S',
    regionId: 1,
  },
  {
    id: 110,
    name: 'UTM zone 50S',
    regionId: 1,
  },
  {
    id: 111,
    name: 'UTM zone 51S',
    regionId: 1,
  },
  {
    id: 112,
    name: 'UTM zone 52S',
    regionId: 1,
  },
  {
    id: 113,
    name: 'UTM zone 53S',
    regionId: 1,
  },
  {
    id: 114,
    name: 'UTM zone 54S',
    regionId: 1,
  },
  {
    id: 115,
    name: 'UTM zone 55S',
    regionId: 1,
  },
  {
    id: 116,
    name: 'UTM zone 56S',
    regionId: 1,
  },
  {
    id: 117,
    name: 'UTM zone 57S',
    regionId: 1,
  },
  {
    id: 118,
    name: 'UTM zone 58S',
    regionId: 1,
  },
  {
    id: 119,
    name: 'UTM zone 59S',
    regionId: 1,
  },
  {
    id: 120,
    name: 'UTM zone 60S',
    regionId: 1,
  },
  {
    id: 121,
    name: 'UTM zone 28N',
    regionId: 1,
  },
  {
    id: 122,
    name: 'UTM zone 29N',
    regionId: 1,
  },
  {
    id: 123,
    name: 'UTM zone 30N',
    regionId: 1,
  },
  {
    id: 124,
    name: 'UTM zone 31N',
    regionId: 1,
  },
  {
    id: 125,
    name: 'UTM zone 32N',
    regionId: 1,
  },
  {
    id: 126,
    name: 'UTM zone 33N',
    regionId: 1,
  },
  {
    id: 127,
    name: 'UTM zone 34N',
    regionId: 1,
  },
  {
    id: 128,
    name: 'UTM zone 35N',
    regionId: 1,
  },
  {
    id: 129,
    name: 'UTM zone 36N',
    regionId: 1,
  },
  {
    id: 130,
    name: 'UTM zone 37N',
    regionId: 1,
  },
  {
    id: 131,
    name: 'NHN UTM Zone 31',
    regionId: 2,
  },
  {
    id: 132,
    name: 'NHN UTM Zone 32',
    regionId: 2,
  },
  {
    id: 133,
    name: 'NHN UTM Zone 33',
    regionId: 2,
  },
  {
    id: 134,
    name: 'Gauss Krueger Zone 2',
    regionId: 2,
  },
  {
    id: 135,
    name: 'Gauss Krueger Zone 3',
    regionId: 2,
  },
  {
    id: 136,
    name: 'Gauss Krueger Zone 4',
    regionId: 2,
  },
  {
    id: 137,
    name: 'Gauss Krueger Zone 5',
    regionId: 2,
  },
  {
    id: 138,
    name: 'Gauss Krueger Zone 2',
    regionId: 2,
  },
  {
    id: 139,
    name: 'Gauss Krueger Zone 3',
    regionId: 2,
  },
  {
    id: 140,
    name: 'Gauss Krueger Zone 4',
    regionId: 2,
  },
  {
    id: 141,
    name: 'Gauss Krueger Zone 5',
    regionId: 2,
  },
  {
    id: 142,
    name: 'Lambert-93',
    regionId: 3,
  },
  {
    id: 143,
    name: 'Lambert Conformal Conic 42',
    regionId: 3,
  },
  {
    id: 144,
    name: 'Lambert Conformal Conic 43',
    regionId: 3,
  },
  {
    id: 145,
    name: 'Lambert Conformal Conic 44',
    regionId: 3,
  },
  {
    id: 146,
    name: 'Lambert Conformal Conic 45',
    regionId: 3,
  },
  {
    id: 147,
    name: 'Lambert Conformal Conic 46',
    regionId: 3,
  },
  {
    id: 148,
    name: 'Lambert Conformal Conic 47',
    regionId: 3,
  },
  {
    id: 149,
    name: 'Lambert Conformal Conic 48',
    regionId: 3,
  },
  {
    id: 150,
    name: 'Lambert Conformal Conic 49',
    regionId: 3,
  },
  {
    id: 151,
    name: 'Lambert Conformal Conic 50',
    regionId: 3,
  },
  {
    id: 152,
    name: 'Lambert zone I',
    regionId: 3,
  },
  {
    id: 153,
    name: 'Lambert zone II',
    regionId: 3,
  },
  {
    id: 154,
    name: 'Lambert zone II etendu',
    regionId: 3,
  },
  {
    id: 155,
    name: 'Lambert zone III',
    regionId: 3,
  },
  {
    id: 156,
    name: 'Lambert zone IV',
    regionId: 3,
  },
  {
    id: 157,
    name: 'Lambert zone I',
    regionId: 3,
  },
  {
    id: 158,
    name: 'Lambert zone II',
    regionId: 3,
  },
  {
    id: 159,
    name: 'Lambert zone II etendu',
    regionId: 3,
  },
  {
    id: 160,
    name: 'Lambert zone III',
    regionId: 3,
  },
  {
    id: 161,
    name: 'Lambert zone IV',
    regionId: 3,
  },
  {
    id: 162,
    name: 'Lambert Carto I',
    regionId: 3,
  },
  {
    id: 163,
    name: 'Lambert Carto II',
    regionId: 3,
  },
  {
    id: 164,
    name: 'Lambert Carto II etendu',
    regionId: 3,
  },
  {
    id: 165,
    name: 'Lambert Carto III',
    regionId: 3,
  },
  {
    id: 166,
    name: 'Lambert Carto IV',
    regionId: 3,
  },
  {
    id: 167,
    name: 'OSTN02',
    regionId: 4,
  },
  {
    id: 168,
    name: 'OSTN15',
    regionId: 4,
  },
  {
    id: 169,
    name: 'OSGB36',
    regionId: 4,
  },
  {
    id: 170,
    name: 'DKTM1',
    regionId: 5,
  },
  {
    id: 171,
    name: 'DKTM2',
    regionId: 5,
  },
  {
    id: 172,
    name: 'DKTM3',
    regionId: 5,
  },
  {
    id: 173,
    name: 'DKTM4',
    regionId: 5,
  },
  {
    id: 174,
    name: 'Kp2000 Jutland',
    regionId: 5,
  },
  {
    id: 175,
    name: 'Kp2000 Zealand',
    regionId: 5,
  },
  {
    id: 176,
    name: 'Kp2000 Bornholm',
    regionId: 5,
  },
  {
    id: 177,
    name: 'Belgian Lambert 72',
    regionId: 6,
  },
  {
    id: 178,
    name: 'Belgian Lambert 2008',
    regionId: 6,
  },
  {
    id: 179,
    name: 'Albany Grid 1994 (ALB94)',
    regionId: 7,
  },
  {
    id: 180,
    name: 'Barrow Island and Onslow Grid 1994 (BIO94)',
    regionId: 7,
  },
  {
    id: 181,
    name: 'Broome Grid 1994 (BRO94)',
    regionId: 7,
  },
  {
    id: 182,
    name: 'Busselton Coastal Grid 1994 (BCG94)',
    regionId: 7,
  },
  {
    id: 183,
    name: 'Carnarvon Grid 1994 (CARN94)',
    regionId: 7,
  },
  {
    id: 184,
    name: 'Christmas Island Grid 1994 (CIG94)',
    regionId: 7,
  },
  {
    id: 185,
    name: 'Cocos (Keeling) Islands Grid 1994 (CKIG94)',
    regionId: 7,
  },
  {
    id: 186,
    name: 'Collie Grid 1994 (COL94)',
    regionId: 7,
  },
  {
    id: 187,
    name: 'Esperance Grid 1994 (ESP94)',
    regionId: 7,
  },
  {
    id: 188,
    name: 'Exmouth Grid 1994 (EXM94)',
    regionId: 7,
  },
  {
    id: 189,
    name: 'Geraldton Coastal Grid 1994 (GCG94)',
    regionId: 7,
  },
  {
    id: 190,
    name: 'Goldfields Grid 1994 (GOLD94)',
    regionId: 7,
  },
  {
    id: 191,
    name: 'Jurien Coastal Grid 1994 (JCG94)',
    regionId: 7,
  },
  {
    id: 192,
    name: 'Karratha Grid 1994 (KAR94)',
    regionId: 7,
  },
  {
    id: 193,
    name: 'Kununurra Grid 1994 (KUN94)',
    regionId: 7,
  },
  {
    id: 194,
    name: 'Lancelin Coastal Grid 1994 (LCG94)',
    regionId: 7,
  },
  {
    id: 195,
    name: 'Margaret River Coastal Grid 1994 (MRCG94)',
    regionId: 7,
  },
  {
    id: 196,
    name: 'Perth Coastal Grid 1984 (PCG)',
    regionId: 7,
  },
  {
    id: 197,
    name: 'Perth Coastal Grid 1994 (PCG94)',
    regionId: 7,
  },
  {
    id: 198,
    name: 'Port Hedland Grid 1994 (PHG94)',
    regionId: 7,
  },
  {
    id: 199,
    name: 'Kalbarri Grid 1994 (KALB94)',
    regionId: 7,
  },
  {
    id: 200,
    name: 'Aglime94',
    regionId: 7,
  },
  {
    id: 201,
    name: 'Hug94',
    regionId: 7,
  },
  {
    id: 202,
    name: 'Newman94 (NWM94)',
    regionId: 7,
  },
  {
    id: 203,
    name: 'ShawRiver94',
    regionId: 7,
  },
  {
    id: 204,
    name: 'TomPrice94',
    regionId: 7,
  },
  {
    id: 205,
    name: 'Bulla94',
    regionId: 7,
  },
  {
    id: 206,
    name: 'Pindarts94',
    regionId: 7,
  },
  {
    id: 207,
    name: 'Nullagine94 (NULL94)',
    regionId: 7,
  },
  {
    id: 208,
    name: 'Muchea94 (MUCH94)',
    regionId: 7,
  },
  {
    id: 209,
    name: 'Ravensthorpe94 (RAVENS94)',
    regionId: 7,
  },
  {
    id: 210,
    name: 'EXMXT94',
    regionId: 7,
  },
  {
    id: 211,
    name: 'Coorow94',
    regionId: 7,
  },
  {
    id: 212,
    name: 'Giralia94',
    regionId: 7,
  },
  {
    id: 213,
    name: 'Nanu94',
    regionId: 7,
  },
  {
    id: 214,
    name: 'Cranbk94',
    regionId: 7,
  },
  {
    id: 215,
    name: 'Morley94',
    regionId: 7,
  },
  {
    id: 216,
    name: 'H9BRIVER94',
    regionId: 7,
  },
  {
    id: 217,
    name: 'Kojon94',
    regionId: 7,
  },
  {
    id: 218,
    name: 'Eastalb94',
    regionId: 7,
  },
  {
    id: 219,
    name: 'Narrogin94',
    regionId: 7,
  },
  {
    id: 220,
    name: 'Brdgetwn94',
    regionId: 7,
  },
  {
    id: 221,
    name: 'Brookton94',
    regionId: 7,
  },
  {
    id: 222,
    name: 'H1BANWIL94',
    regionId: 7,
  },
  {
    id: 223,
    name: 'Ajana94',
    regionId: 7,
  },
  {
    id: 224,
    name: 'Meeka94',
    regionId: 7,
  },
  {
    id: 225,
    name: 'Derby94',
    regionId: 7,
  },
  {
    id: 226,
    name: 'Boondi94',
    regionId: 7,
  },
  {
    id: 227,
    name: 'Gold94',
    regionId: 7,
  },
  {
    id: 228,
    name: 'Marvel94',
    regionId: 7,
  },
  {
    id: 229,
    name: 'Marble94',
    regionId: 7,
  },
  {
    id: 230,
    name: 'Wubin94',
    regionId: 7,
  },
  {
    id: 231,
    name: 'Wyndham94',
    regionId: 7,
  },
  {
    id: 232,
    name: 'Whaleback (WB94)',
    regionId: 7,
  },
  {
    id: 233,
    name: 'Eastern Ridge (ER94)',
    regionId: 7,
  },
  {
    id: 234,
    name: 'Central Project Grid (CPG94)',
    regionId: 7,
  },
  {
    id: 235,
    name: 'OB18 (OB18PG)',
    regionId: 7,
  },
  {
    id: 236,
    name: 'Jimblebar (JIM94)',
    regionId: 7,
  },
  {
    id: 237,
    name: 'Yarrie (YAR94)',
    regionId: 7,
  },
  {
    id: 238,
    name: 'Yandi (YAN94)',
    regionId: 7,
  },
  {
    id: 239,
    name: 'Hbi94',
    regionId: 7,
  },
  {
    id: 240,
    name: 'Nelson Point (NP94)',
    regionId: 7,
  },
  {
    id: 241,
    name: 'Wheelarra (WHL94)',
    regionId: 7,
  },
  {
    id: 242,
    name: 'Brockman94',
    regionId: 7,
  },
  {
    id: 243,
    name: 'Cullinia94',
    regionId: 7,
  },
  {
    id: 244,
    name: 'West Angelas94 (WA94PG)',
    regionId: 7,
  },
  {
    id: 245,
    name: 'Ellenbrook_Rev2',
    regionId: 7,
  },
  {
    id: 246,
    name: 'GDA94 / MGA Zone 49',
    regionId: 7,
  },
  {
    id: 247,
    name: 'GDA94 / MGA Zone 50',
    regionId: 7,
  },
  {
    id: 248,
    name: 'GDA94 / MGA Zone 51',
    regionId: 7,
  },
  {
    id: 249,
    name: 'GDA94 / MGA Zone 52',
    regionId: 7,
  },
  {
    id: 250,
    name: 'GDA94 / MGA Zone 53',
    regionId: 7,
  },
  {
    id: 251,
    name: 'GDA94 / MGA Zone 54',
    regionId: 7,
  },
  {
    id: 252,
    name: 'GDA94 / MGA Zone 55',
    regionId: 7,
  },
  {
    id: 253,
    name: 'GDA94 / MGA Zone 56',
    regionId: 7,
  },
  {
    id: 254,
    name: 'NZTM2000',
    regionId: 8,
  },
  {
    id: 255,
    name: 'EDENTM2000',
    regionId: 8,
  },
  {
    id: 256,
    name: 'PLENTM2000',
    regionId: 8,
  },
  {
    id: 257,
    name: 'POVETM2000',
    regionId: 8,
  },
  {
    id: 258,
    name: 'HAWKTM2000',
    regionId: 8,
  },
  {
    id: 259,
    name: 'TARATM2000',
    regionId: 8,
  },
  {
    id: 260,
    name: 'TUHITM2000',
    regionId: 8,
  },
  {
    id: 261,
    name: 'WANGTM2000',
    regionId: 8,
  },
  {
    id: 262,
    name: 'WAIRTM2000',
    regionId: 8,
  },
  {
    id: 263,
    name: 'WELLTM2000',
    regionId: 8,
  },
  {
    id: 264,
    name: 'COLLTM2000',
    regionId: 8,
  },
  {
    id: 265,
    name: 'NELSTM2000',
    regionId: 8,
  },
  {
    id: 266,
    name: 'KARATM2000',
    regionId: 8,
  },
  {
    id: 267,
    name: 'BULLTM2000',
    regionId: 8,
  },
  {
    id: 268,
    name: 'GREYTM2000',
    regionId: 8,
  },
  {
    id: 269,
    name: 'AMURTM2000',
    regionId: 8,
  },
  {
    id: 270,
    name: 'MARLTM2000',
    regionId: 8,
  },
  {
    id: 271,
    name: 'HOKITM2000',
    regionId: 8,
  },
  {
    id: 272,
    name: 'OKARTM2000',
    regionId: 8,
  },
  {
    id: 273,
    name: 'JACKTM2000',
    regionId: 8,
  },
  {
    id: 274,
    name: 'PLEATM2000',
    regionId: 8,
  },
  {
    id: 275,
    name: 'GAWLTM2000',
    regionId: 8,
  },
  {
    id: 276,
    name: 'TIMATM2000',
    regionId: 8,
  },
  {
    id: 277,
    name: 'LINDTM2000',
    regionId: 8,
  },
  {
    id: 278,
    name: 'NICHTM2000',
    regionId: 8,
  },
  {
    id: 279,
    name: 'YORKTM2000',
    regionId: 8,
  },
  {
    id: 280,
    name: 'OBSETM2000',
    regionId: 8,
  },
  {
    id: 281,
    name: 'TAIETM2000',
    regionId: 8,
  },
  {
    id: 282,
    name: 'BLUFTM2000',
    regionId: 8,
  },
  {
    id: 283,
    name: 'TokyoDatum CS 1',
    regionId: 9,
  },
  {
    id: 284,
    name: 'TokyoDatum CS 2',
    regionId: 9,
  },
  {
    id: 285,
    name: 'TokyoDatum CS 3',
    regionId: 9,
  },
  {
    id: 286,
    name: 'TokyoDatum CS 4',
    regionId: 9,
  },
  {
    id: 287,
    name: 'TokyoDatum CS 5',
    regionId: 9,
  },
  {
    id: 288,
    name: 'TokyoDatum CS 6',
    regionId: 9,
  },
  {
    id: 289,
    name: 'TokyoDatum CS 7',
    regionId: 9,
  },
  {
    id: 290,
    name: 'TokyoDatum CS 8',
    regionId: 9,
  },
  {
    id: 291,
    name: 'TokyoDatum CS 9',
    regionId: 9,
  },
  {
    id: 292,
    name: 'TokyoDatum CS 10',
    regionId: 9,
  },
  {
    id: 293,
    name: 'TokyoDatum CS 11',
    regionId: 9,
  },
  {
    id: 294,
    name: 'TokyoDatum CS 12',
    regionId: 9,
  },
  {
    id: 295,
    name: 'TokyoDatum CS 13',
    regionId: 9,
  },
  {
    id: 296,
    name: 'TokyoDatum CS 14',
    regionId: 9,
  },
  {
    id: 297,
    name: 'TokyoDatum CS 15',
    regionId: 9,
  },
  {
    id: 298,
    name: 'TokyoDatum CS 16',
    regionId: 9,
  },
  {
    id: 299,
    name: 'TokyoDatum CS 17',
    regionId: 9,
  },
  {
    id: 300,
    name: 'TokyoDatum CS 18',
    regionId: 9,
  },
  {
    id: 301,
    name: 'TokyoDatum CS 19',
    regionId: 9,
  },
  {
    id: 302,
    name: 'JGD2011 CS 1',
    regionId: 9,
  },
  {
    id: 303,
    name: 'JGD2011 CS 2',
    regionId: 9,
  },
  {
    id: 304,
    name: 'JGD2011 CS 3',
    regionId: 9,
  },
  {
    id: 305,
    name: 'JGD2011 CS 4',
    regionId: 9,
  },
  {
    id: 306,
    name: 'JGD2011 CS 5',
    regionId: 9,
  },
  {
    id: 307,
    name: 'JGD2011 CS 6',
    regionId: 9,
  },
  {
    id: 308,
    name: 'JGD2011 CS 7',
    regionId: 9,
  },
  {
    id: 309,
    name: 'JGD2011 CS 8',
    regionId: 9,
  },
  {
    id: 310,
    name: 'JGD2011 CS 9',
    regionId: 9,
  },
  {
    id: 311,
    name: 'JGD2011 CS 10',
    regionId: 9,
  },
  {
    id: 312,
    name: 'JGD2011 CS 11',
    regionId: 9,
  },
  {
    id: 313,
    name: 'JGD2011 CS 12',
    regionId: 9,
  },
  {
    id: 314,
    name: 'JGD2011 CS 13',
    regionId: 9,
  },
  {
    id: 315,
    name: 'JGD2011 CS 14',
    regionId: 9,
  },
  {
    id: 316,
    name: 'JGD2011 CS 15',
    regionId: 9,
  },
  {
    id: 317,
    name: 'JGD2011 CS 16',
    regionId: 9,
  },
  {
    id: 318,
    name: 'JGD2011 CS 17',
    regionId: 9,
  },
  {
    id: 319,
    name: 'JGD2011 CS 18',
    regionId: 9,
  },
  {
    id: 320,
    name: 'JGD2011 CS 19',
    regionId: 9,
  },
  {
    id: 321,
    name: 'プロジェクション１',
    regionId: 10,
  },
  {
    id: 322,
    name: 'プロジェクション２',
    regionId: 10,
  },
];
export default projections;
