const KEY_REDIRECT_AFTER_LOGIN = 'redirect_after_login';

const redirectAfterLoginStore = {
  init: () => {
    const rawUrl = window.location.href;
    const path = rawUrl.replace(window.location.origin, '');
    const [, target] = path.split('#');
    if (target !== '/login') {
      localStorage.setItem(KEY_REDIRECT_AFTER_LOGIN, target);
    }
  },
  get: (): string => {
    return localStorage.getItem(KEY_REDIRECT_AFTER_LOGIN) || '';
  },
  clear: () => {
    localStorage.setItem(KEY_REDIRECT_AFTER_LOGIN, '');
  },
};

export default redirectAfterLoginStore;
