import { AccountData } from '../../types';

const accounts: AccountData[] = [
  {
    id: 1,
    email: 'test_user@example.com',
    name: 'レトロフィット太郎',
    picture: '',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
  },
];
export default accounts;

/*
 * アカウントを変更する場合は、以下の内容をコメントから外して利用する。
 * 基本1件のみ有効とすること
  {
    id: 1,
    email: 'yukio_aki@qualica.co.jp',
    name: '阿木由紀夫',
    picture: '',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築テスト事業部',
  },
  {
    id: 1,
    email: 'tsurumi_kazuhiro@qualica.co.jp',
    name: '鶴見和弘',
    picture: '',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    corporationName: 'クオリカ',
  },
  {
    id: 1,
    email: 'yuuichi_tamashiro@qualica.co.jp',
    name: '玉城勇一',
    picture: '',
    corporationId: 'ef12300e-822d-4e8e-b820-2864288d0051',
    corporationName: 'Komatsu Ltd.',
  },
  {
    id: 1,
    email: 'shingo_nakashima@qualica.co.jp',
    name: '中島慎吾',
    picture: '',
    corporationId: '0da5298e-0081-08af-c9dd-3c8ae99fa742',
    corporationName: 'クオリカその２',
  },
  {
    id: 1,
    email: 'yukio_aki@qualica.co.jp',
    name: '阿木由紀夫',
    picture: '',
    corporationId: '8715c009-aada-9c22-20e3-a63305b46db9',
    corporationName: '会社名５０６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０',
  },
  {
    id: 1,
    email: 'test_user@example.com',
    name: 'レトロフィット太郎',
    picture: '',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築テスト事業部',
  },
 */
