import { ProjectBoundaryData } from '../dataProvider';

export default (
  projectBoundary: ProjectBoundaryData,
): { lon: number; lat: number }[] => {
  const {
    northEastLon, // 右上
    northEastLat, // 右上
    northWestLon, // 左上
    northWestLat, // 左上
    southWestLon, // 左下
    southWestLat, // 左下
    southEastLon, // 右下
    southEastLat, // 右下
  } = projectBoundary;
  return [
    {
      lon: northEastLon,
      lat: northEastLat,
    },
    {
      lon: northWestLon,
      lat: northWestLat,
    },
    {
      lon: southWestLon,
      lat: southWestLat,
    },
    {
      lon: southEastLon,
      lat: southEastLat,
    },
  ];
};
