import {
  DataProvider,
  GetOneParams,
  GetOneResult,
  Identifier,
} from 'react-admin';
import {
  landlogAccountGetOne,
  landlogCorporationsGetList,
  landlogCorporationsGetMany,
  landlogCorporationsGetOne,
  landlogLicenseGetOne,
  landlogSitesGetList,
  landlogSitesGetMany,
  landlogSitesGetOne,
  landlogSitesGetOneAllowedError,
  landlogUsersGetList,
  landlogUsersGetOne,
} from '..';

interface Record {
  id: Identifier;
  [key: string]: any;
}

type GetOneAllowedErrorDataProvider<T> = T & {
  getOneAllowedError: <RecordType extends Record = Record>(
    resource: string,
    params: GetOneParams,
  ) => Promise<GetOneResult<RecordType>>;
};

export type CustomDataProvider = GetOneAllowedErrorDataProvider<DataProvider>;

const landlogDataProvider: CustomDataProvider = {
  getList: async (resource, params): Promise<any> => {
    switch (resource) {
      case 'sites':
        return landlogSitesGetList(params);
      case 'corporations':
        return landlogCorporationsGetList(params);
      case 'users':
        return landlogUsersGetList(params);
      default:
        return Promise.reject(
          new Error(`${resource} is not supported for getList`),
        );
    }
  },
  getOne: (resource, params): Promise<any> => {
    switch (resource) {
      case 'sites':
        return landlogSitesGetOne(params);
      case 'corporations':
        return landlogCorporationsGetOne(params);
      case 'users':
        return landlogUsersGetOne(params);
      case 'accounts':
        return landlogAccountGetOne();
      case 'licenses':
        return landlogLicenseGetOne();
      default:
        return Promise.reject(new Error('not supported'));
    }
  },
  getOneAllowedError: (resource: any, params: GetOneParams): Promise<any> => {
    if (resource === 'sites') {
      return landlogSitesGetOneAllowedError(params);
    }
    return Promise.reject(new Error('not supported'));
  },
  getMany: (resource, params): Promise<any> => {
    switch (resource) {
      case 'sites':
        return landlogSitesGetMany(params);
      case 'corporations':
        return landlogCorporationsGetMany(params);
      default:
        return Promise.reject(new Error('not supported'));
    }
  },
  getManyReference: () => Promise.reject(new Error('not supported')),
  update: () => Promise.reject(new Error('not supported')),
  updateMany: () => Promise.reject(new Error('not supported')),
  create: () => Promise.reject(new Error('not supported')),
  delete: () => Promise.reject(new Error('not supported')),
  deleteMany: () => Promise.reject(new Error('not supported')),
};

export default landlogDataProvider;
