import { AdminApiConverter, ApiFilter } from '..';
import { RetrofitCorporationLicenseData } from '../../types';

const converter: AdminApiConverter<
  'retrofitCorporationLicenses',
  'retrofitCorporationLicenses'
> = {
  resource: 'retrofitCorporationLicenses',
  defaultSort: { field: 'id', order: 'ASC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'retrofitCorporationLicenses'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('corporationId' in filter)
      apiFilter.corporationId = filter.corporationId;
    if ('retrofitLicenseId' in filter)
      apiFilter.retrofitLicenseId = filter.retrofitLicenseId;
    if ('licenseId' in filter) apiFilter.licenseId = filter.licenseId;
    if ('licenseType' in filter) apiFilter.licenseType = filter.licenseType;
    if ('licenseStatus' in filter)
      apiFilter.licenseStatus = filter.licenseStatus;
    if ('licensePermission' in filter)
      apiFilter.licensePermission = filter.licensePermission;
    if ('enable' in filter) apiFilter.enable = filter.enable;
    if ('issuedAt' in filter) apiFilter.issuedAt = filter.issuedAt;
    if ('expiresAt' in filter) apiFilter.expiresAt = filter.expiresAt;
    if ('retrofitId' in filter) apiFilter.retrofitId = filter.retrofitId;
    return apiFilter;
  },
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map((record): RetrofitCorporationLicenseData => {
        return {
          id: record.id || 0,
          corporationId: record.corporationId || '',
          retrofitLicenseId: record.retrofitLicenseId,
          licenseId: record.licenseId || '',
          licenseType: record.licenseType || '',
          licenseStatus: record.licenseStatus,
          licensePermission: record.licensePermission,
          isLicenseAttached: record.isLicenseAttached,
          enable: record.enable,
          issuedAt: record.issuedAt,
          expiresAt: record.expiresAt,
          retrofitId:
            record.retrofitId === null ? undefined : record.retrofitId,
          comment: record.comment,
        };
      }),
    ),
  recordToApi: record => ({
    retrofitId: record.retrofitId,
    comment: record.comment,
  }),
  embed: ['retrofit'],
};

export default converter;
