import {
  AdminApiConverter,
  ApiFilter,
  getRestDataProvider,
  File as FileApi,
} from '..';

const getFile = async (url: string) => {
  const response = await fetch(url);
  return response.text();
};

const converter: AdminApiConverter<'firmwares', 'firmwares'> = {
  resource: 'firmwares',
  defaultSort: { field: 'id', order: 'ASC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'firmwares'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('version' in filter) apiFilter.version = filter.version;
    if ('compatibilityLevel' in filter)
      apiFilter.compatibilityLevel = filter.compatibilityLevel;
    if ('firmwareFileCheckSum' in filter)
      apiFilter.firmwareFileCheckSum = filter.firmwareFileCheckSum;
    if ('firstInitializationFileCheckSum' in filter)
      apiFilter.firstInitializationFileCheckSum =
        filter.firstInitializationFileCheckSum;
    if ('secondInitializationFileCheckSum' in filter)
      apiFilter.secondInitializationFileCheckSum =
        filter.secondInitializationFileCheckSum;
    return apiFilter;
  },
  sortFieldToApi: field => {
    if (field === 'version') return 'version';
    if (field === 'compatibilityLevel') return 'compatibilityLevel';
    return field;
  },
  prepareParam: async record => {
    const paramRecord = record;
    const { firmwareFile, firstInitializationFile, secondInitializationFile } =
      paramRecord;
    if (firmwareFile) {
      const { id, rawFile, title, src: url } = firmwareFile;
      if (id) return paramRecord;
      if (rawFile || url) {
        const dataProvider = getRestDataProvider();
        const data: FileApi = {
          blobFileType: 'RetrofitFirmware',
          name: title,
          blobFileStatus: 'Created',
        };
        const result = await dataProvider.create('files', { data });
        const apiData = result.data as FileApi;
        if (rawFile && result.data.url) {
          await fetch(result.data.url, {
            method: 'PUT',
            body: rawFile,
            headers: { 'Content-Type': 'application/octet-stream' },
          });
        }
        await dataProvider.update('files', {
          id: apiData.id || '',
          data,
          previousData: { ...data, id: apiData.id || '' },
        });
        paramRecord.firmwareFile = {
          ...firmwareFile,
          id: apiData.id || '',
          src: apiData.url || '',
          rawFile: rawFile || new File([await getFile(url)], title),
        };
      }
    }
    if (firstInitializationFile) {
      const { id, rawFile, title, src: url } = firstInitializationFile;
      if (id) return paramRecord;
      if (rawFile || url) {
        const dataProvider = getRestDataProvider();
        const data: FileApi = {
          blobFileType: 'RetrofitFirmwareInitialization',
          name: title,
          blobFileStatus: 'Created',
        };
        const result = await dataProvider.create('files', { data });
        const apiData = result.data as FileApi;
        if (rawFile && result.data.url) {
          await fetch(result.data.url, {
            method: 'PUT',
            body: rawFile,
            headers: { 'Content-Type': 'application/octet-stream' },
          });
        }
        await dataProvider.update('files', {
          id: apiData.id || '',
          data,
          previousData: { ...data, id: apiData.id || '' },
        });
        await dataProvider.update('files', {
          id: apiData.id || '',
          data,
          previousData: { ...data, id: apiData.id || '' },
        });
        paramRecord.firstInitializationFile = {
          ...firstInitializationFile,
          id: apiData.id || '',
          src: apiData.url || '',
          rawFile: rawFile || new File([await getFile(url)], title),
        };
      }
    }
    if (secondInitializationFile) {
      const { id, rawFile, title, src: url } = secondInitializationFile;
      if (id) return paramRecord;
      if (rawFile || url) {
        const dataProvider = getRestDataProvider();
        const data: FileApi = {
          blobFileType: 'RetrofitFirmwareInitialization',
          name: title,
        };
        const result = await dataProvider.create('files', { data });
        const apiData = result.data as FileApi;
        if (rawFile && result.data.url) {
          await fetch(result.data.url, {
            method: 'PUT',
            body: rawFile,
            headers: { 'Content-Type': 'application/octet-stream' },
          });
        }
        await dataProvider.update('files', {
          id: apiData.id || '',
          data,
          previousData: { ...data, id: apiData.id || '' },
        });
        paramRecord.secondInitializationFile = {
          ...secondInitializationFile,
          id: apiData.id || '',
          src: apiData.url || '',
          rawFile: rawFile || new File([await getFile(url)], title),
        };
      }
    }

    return paramRecord;
  },
  recordsFromApi: async records => {
    const dataProvider = getRestDataProvider();
    // ここの実装方法についていい方法を後で聞く
    const firmwareFile = { url: '', name: '' };
    const firstInitializationFile = { url: '', name: '' };
    const secondInitializationFile = { url: '', name: '' };

    if (records.length === 1) {
      const firmwareFileGetOne = (
        await dataProvider.getOne('files', {
          id: records[0].firmwareFileId || '',
        })
      ).data;
      firmwareFile.name = firmwareFileGetOne.name;
      firmwareFile.url = firmwareFileGetOne.url;

      const firstInitializationFileGetOne = (
        await dataProvider.getOne('files', {
          id: records[0].firstInitializationFileId || '',
        })
      ).data;
      firstInitializationFile.name = firstInitializationFileGetOne.name;
      firstInitializationFile.url = firstInitializationFileGetOne.url;

      const secondInitializationFileGetOne = (
        await dataProvider.getOne('files', {
          id: records[0].secondInitializationFileId || '',
        })
      ).data;
      secondInitializationFile.name = secondInitializationFileGetOne.name;
      secondInitializationFile.url = secondInitializationFileGetOne.url;
    }

    return Promise.resolve(
      records.map(record => ({
        id: record.id || 0,
        version: record.version || '',
        compatibilityLevel: record.compatibilityLevel,
        firmwareFile: {
          id: record.firmwareFileId || '',
          src: firmwareFile?.url,
          title: firmwareFile?.name,
        },
        firmwareFileCheckSum: record.firmwareFileCheckSum || '',
        firstInitializationFile: {
          id: record.firstInitializationFileId || '',
          src: firstInitializationFile?.url || '',
          title: firstInitializationFile?.name || '',
        },
        firstInitializationFileCheckSum:
          record.firstInitializationFileCheckSum || '',
        secondInitializationFile: {
          id: record.secondInitializationFileId || '',
          src: secondInitializationFile?.url || '',
          title: secondInitializationFile?.name || '',
        },
        secondInitializationFileCheckSum:
          record.secondInitializationFileCheckSum || '',
      })),
    );
  },
  recordToApi: record => ({
    version: record.version,
    compatibilityLevel: record.compatibilityLevel,
    firmwareFileId: record.firmwareFile.id || '', // TODO: 20210121,swagger.json取込エラー回避用暫定対策
    firmwareFileCheckSum: record.firmwareFileCheckSum,
    firstInitializationFileId: record.firstInitializationFile.id || '', // TODO: 20210121,swagger.json取込エラー回避用暫定対策
    firstInitializationFileCheckSum: record.firstInitializationFileCheckSum,
    secondInitializationFileId: record.secondInitializationFile?.id,
    secondInitializationFileCheckSum: record.secondInitializationFileCheckSum,
  }),
};

export default converter;
