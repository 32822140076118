import React from 'react';
import { useTranslate } from 'react-admin';
import { NtripData } from '../../../../dataProvider';
import { Typography } from '../../../atoms';

const NtripStatusField: React.FC<{
  record?: NtripData;
  label?: string; // 內部で使わなくても <FormInput> 側で利用される
  addLabel?: boolean; // 內部で使わなくても <FormInput> 側で利用される
}> = ({ record }) => {
  const translate = useTranslate();
  const base = 'resources.ntrips.ntrip';
  const ntripStatus = record?.ntrip || '';

  // Record毎のステータスによって出力する文字を変更する
  let ntripType;
  if (ntripStatus === '払出済') {
    ntripType = translate(`${base}.publish`);
  } else if (ntripStatus === '停止') {
    ntripType = translate(`${base}.stop`);
  } else if (ntripStatus === '削除(=返却済)') {
    ntripType = translate(`${base}.delete`);
  } else {
    ntripType = '';
  }

  return <Typography>{ntripType}</Typography>;
};

NtripStatusField.defaultProps = {
  addLabel: true, // Form 配下でラベルを出す為に設定
};

NtripStatusField.displayName = 'NtripStatusField';
export default NtripStatusField;
