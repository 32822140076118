import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  downloadCSV,
  useTranslate,
  useDataProvider,
  NumberField,
} from 'react-admin';
import jsonExport from 'jsonexport';
import {
  RetrofitData,
  RetrofitToposurveysData,
  UnitLength,
} from '../../../../dataProvider';
import {
  useAccount,
  useGetSelefDetailData,
  useGetUnitLengthCallback,
  useResource,
} from '../../../../hooks';
import {
  CustomList,
  Filter,
  SiteSearchInput,
  CustomDateField,
  TextField,
  Datagrid,
  Loading,
} from '../../..';
import { ColumnFilters } from './views';
import { useGetRetrofitsSiteId, useGetSiteName } from './hooks';
import { getRetrofitInfoBreadcrumbs } from './util';
import { convertNumberLocale, getCsvDelimiter } from '../../../../utils';

const ListFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      '& > div:nth-child(2)': {
        overflow: 'auto',
        height: 'calc(100vh - 320px)',
      },
    },
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
            // ヘッダ固定用スタイル
            position: 'sticky',
            top: 0,
            backgroundColor: '#29292f', // colors.tsのbackgroundと同じ色を設定すること
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 112,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 160,
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(7)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 100,
          },
          '& .MuiTableCell-root:nth-child(8)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 100,
          },
        },
      },
    },
  }),
);

const useExporter = (resource: string, retrofitId: number) => {
  const base = `resources.${resource}.fields`;
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  return async (record: RetrofitToposurveysData[]) => {
    const result = await dataProvider.getOne<RetrofitData>('retrofits', {
      id: retrofitId,
    });
    if (!result) return;
    const { data: retrofitData } = result;
    const { basicInfoSerialNumber } = retrofitData;

    // 変数内のすべてのフィールドがCSV出力されてしまうため、不要なフィールドをomit
    // csv出力不要かつ以降の処理で未使用であるものはlintエラーになるため、lintエラー回避コメント文を追記
    const postsForExport = record.map(data => {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        id,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        retrofitId: _,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        userCorporationId,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        unitLength,
        n,
        e,
        z,
        ...postForExport
      } = data; // omit
      // convert
      postForExport.surveyDate = new Date(data.surveyDate).toLocaleString();
      // convert - 小数値変換
      postForExport.csvPositionN = convertNumberLocale(n);
      postForExport.csvPositionE = convertNumberLocale(e);
      postForExport.csvPositionZ = convertNumberLocale(z);
      return postForExport;
    });
    jsonExport(
      postsForExport,
      {
        forceTextDelimiter: true,
        rowDelimiter: getCsvDelimiter(),
        headers: [
          'surveyDate',
          'projectName',
          'layerName',
          'pointName',
          'surveyPoint',
          'csvPositionN',
          'csvPositionE',
          'csvPositionZ',
        ], // order fields in the export
        rename: [
          translate(`${base}.surveyDate`),
          translate(`${base}.projectName`),
          translate(`${base}.layerName`),
          translate(`${base}.pointName`),
          translate(`${base}.surveyPoint`),
          translate(`${base}.n`),
          translate(`${base}.e`),
          translate(`${base}.z`),
        ], // rename column name
      },
      (_, rawCsv: string) => {
        const BOM = '\uFEFF';
        const csv = `${BOM}${rawCsv}`; // UTF8でBOM付とするため、CSVの先頭にバイトオーダマークを付与
        downloadCSV(csv, `TopographicSurvey_${basicInfoSerialNumber}`);
      },
    );
  };
};

interface ApiResult {
  isOwnData: boolean;
  isAdminCorporation: boolean;
  siteAttachedData: boolean;
  fetchedRetrofitId: number;
  userCorporationId: string;
  breadcrumbs: string[];
  unitLength: UnitLength;
}

const ToposurveyList: React.FC<any | { siteId: string; retrofitId: string }> =
  ({ siteId, retrofitId }) => {
    const resourceToposurveys = useResource('retrofitToposurveys');
    const classes = useStyles();
    const getAccount = useAccount();
    const getSelefDetailData = useGetSelefDetailData();
    const getUnitLengthCallback = useGetUnitLengthCallback();
    const getSiteName = useGetSiteName();
    // const redirectTo = useRedirect();
    const { siteId: urlSiteId } = useParams<{ siteId: string }>();
    const exporter = useExporter('retrofitToposurveys', retrofitId);
    const [apiState, setApiState] = useState<{
      loading: boolean;
      fetched: boolean;
      data?: ApiResult;
    }>({
      loading: true,
      fetched: false,
      data: undefined,
    });

    useEffect(() => {
      // 既にDB取得済みでも、urlのretrofitIdが変わった場合は再度実施
      if (apiState.fetched && apiState.data?.fetchedRetrofitId === retrofitId) {
        return;
      }

      const api = async (): Promise<{
        data: {
          apiResult: ApiResult;
        };
      }> => {
        // accounts
        const {
          data: { corporationId },
        } = await getAccount();

        // retrofits
        const listFileter = {
          ownerCorporationId: corporationId,
          adminCorporationId: corporationId,
        };
        // 建機一覧から閲覧可能なレトロフィットであるか判定
        const retrofit = await getSelefDetailData(
          'retrofits',
          listFileter,
          retrofitId,
        );

        // 指定した建機をログインユーザーが利用中の場合、現場単位を取得
        const isRetrofitUser = corporationId === retrofit?.corporationId;
        const { unitLength } = await getUnitLengthCallback({
          siteId,
          isRetrofitUser,
        });
        // ログインユーザーはretrofitの管理者であるか判定
        const isAdminCorporation = retrofit?.adminCorporations
          ? retrofit.adminCorporations.some(
              (it: any) => it.corporationId === corporationId,
            )
          : false;

        // 現場に紐づくレトロフィットであるか判定
        const siteAttachedListFileter = {
          siteId: urlSiteId || '',
        };
        const siteAttachedRetrofit = await getSelefDetailData(
          'retrofits',
          siteAttachedListFileter,
          retrofitId,
        );

        // site ※現場建機詳細から呼ばれた場合に、パンくずリストで必要
        let siteName;
        if (urlSiteId) {
          const result = await getSiteName({ siteId: urlSiteId });
          siteName = result.data.siteName;
        }

        // DBの取得結果を使うため、パンくずリストはここで作成
        const breadcrumbs = getRetrofitInfoBreadcrumbs(
          siteAttachedRetrofit?.machineInfoMachineName
            ? siteAttachedRetrofit?.machineInfoMachineName
            : retrofit?.machineInfoMachineName || '',
          siteName,
        );

        return {
          data: {
            apiResult: {
              isOwnData: !!retrofit,
              isAdminCorporation,
              siteAttachedData: !!siteAttachedRetrofit,
              fetchedRetrofitId: retrofitId,
              userCorporationId: corporationId,
              breadcrumbs,
              unitLength,
            },
          },
        };
      };
      api().then(({ data: { apiResult } }) => {
        setApiState({
          loading: false,
          fetched: true,
          data: apiResult,
        });
      });
    }, [
      getSelefDetailData,
      getAccount,
      getSiteName,
      getUnitLengthCallback,
      retrofitId,
      siteId,
      urlSiteId,
      apiState,
    ]);

    if (apiState.loading || !apiState.data) return <Loading />;

    // TODO: 閲覧不可なデータは現場一覧に遷移する機能を有効にする場合、コメントアウトを外す
    const { userCorporationId, breadcrumbs, isAdminCorporation, unitLength } =
      apiState.data;
    // 建機一覧に存在しないretrofitの地形計測結果は表示させない
    // または現場詳細画面から遷移する場合、対象現場に紐づかないretrofitの地形計測結果は表示させない
    // if (urlSiteId ? !siteAttachedData : !isOwnData) redirectTo('/');

    const filter = isAdminCorporation
      ? { retrofitId }
      : { retrofitId, userCorporationId };

    return (
      <CustomList
        className={classes.table}
        breadcrumbs={breadcrumbs}
        resource={resourceToposurveys}
        title="admin.pages.retrofitTopographicSurveyList"
        bulkActionButtons={false}
        filter={filter}
        filterDefaultValues={{ unitLength }}
        filters={<ListFilter />}
        useScrollPagination={true}
        exporter={exporter}
        columnfilters={
          <ColumnFilters
            resource={resourceToposurveys}
            unitLength={unitLength}
          />
        }
      >
        <Datagrid className={classes.root}>
          <CustomDateField
            resource={resourceToposurveys}
            source="surveyDate"
            showTime
          />
          <TextField resource={resourceToposurveys} source="projectName" />
          <TextField resource={resourceToposurveys} source="layerName" />
          <TextField resource={resourceToposurveys} source="pointName" />
          <TextField resource={resourceToposurveys} source="surveyPoint" />
          <NumberField
            resource={resourceToposurveys}
            source="n"
            options={{ useGrouping: false }}
          />
          <NumberField
            resource={resourceToposurveys}
            source="e"
            options={{ useGrouping: false }}
          />
          <NumberField
            resource={resourceToposurveys}
            source="z"
            options={{ useGrouping: false }}
          />
        </Datagrid>
      </CustomList>
    );
  };

const RetrofitToposurveyList: React.FC = () => {
  const getSiteId = useGetRetrofitsSiteId();
  const [siteId, setSiteId] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const { retrofitId: strRetrofitId } = useParams<{ retrofitId: string }>();
  const retrofitId = Number(strRetrofitId);
  // retrofit情報からSIteIdを取得
  useEffect(() => {
    getSiteId({ retrofitId }).then(({ data }) => {
      setSiteId(data.siteId);
      setIsLoading(false);
    });
  }, [getSiteId, retrofitId]);
  if (isLoading) return <Loading />;

  return <ToposurveyList siteId={siteId} retrofitId={retrofitId} />;
};

RetrofitToposurveyList.displayName = 'RetrofitToposurveyList';
export default RetrofitToposurveyList;
