/* eslint-disable no-nested-ternary */
import { AdminApiConverter, ApiFilter } from '..';
import { convertFillDate } from '../../../utils/dateUtils';

const minFillTime = {
  hour: 0,
  min: 0,
  sec: 0,
};

const maxFillTime = {
  hour: 23,
  min: 59,
  sec: 59,
};

const converter: AdminApiConverter<'retrofitRentals', 'rentals'> = {
  resource: 'rentals',
  defaultSort: { field: 'planStartDate', order: 'DESC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'rentals'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('retrofitId' in filter) apiFilter.retrofitId = filter.retrofitId;
    if ('fromCorporationId' in filter)
      apiFilter.fromCorporationId = filter.fromCorporationId;
    if ('fromCorporationName' in filter)
      apiFilter.fromCorporationName = filter.fromCorporationName;
    if ('toCorporationId' in filter)
      apiFilter.toCorporationId = filter.toCorporationId;
    if ('toCorporationName' in filter)
      apiFilter.toCorporationName = filter.toCorporationName;
    if ('retrofitRentalId' in filter)
      apiFilter.retrofitRentalId = filter.retrofitRentalId;
    if ('isApproved' in filter) apiFilter.isApproved = filter.isApproved;
    if ('isReturned' in filter) apiFilter.isReturned = filter.isReturned;
    if ('planStartDate' in filter)
      apiFilter.planStartDate = filter.planStartDate;
    if ('planEndDate' in filter) apiFilter.planEndDate = filter.planEndDate;
    if ('actualStartDate' in filter)
      apiFilter.actualStartDate = filter.actualStartDate;
    if ('actualEndDate' in filter)
      apiFilter.actualEndDate = filter.actualEndDate;
    if ('email' in filter) {
      const emails = [filter.email];
      if (emails.length > 0 && typeof emails[0] !== 'undefined') {
        apiFilter.rentalUsers = emails.map((record: any) => record);
      }
    }
    return apiFilter;
  },
  sortFieldToApi: field => {
    if (field === 'rentalStatus') return 'isReturned';
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(record => {
        return {
          id: record.id || 0,
          retrofitId: record.retrofitId,
          fromCorporationId: record.fromCorporationId || '',
          fromCorporationName: record.fromCorporationName || '',
          toCorporationId: record.toCorporationId || '',
          toCorporationName: record.toCorporationName || '',
          retrofitRentalId: record.retrofitRentalId,
          isApproved: record.isApproved,
          isReturned: record.isReturned,
          rentalStatus: record.isReturned
            ? 'Returned'
            : record.isApproved
            ? 'Approved'
            : 'Unapproved',
          planStartDate:
            convertFillDate(record.planStartDate, minFillTime) || '',
          planEndDate: convertFillDate(record.planEndDate, maxFillTime) || '',
          actualStartDate:
            convertFillDate(record.actualStartDate, minFillTime) || '',
          actualEndDate:
            convertFillDate(record.actualEndDate, maxFillTime) || '',
          email: record.rentalUsers?.map(email => ({ email }))[0]?.email || '',
          lastUpdated: record.updateDate || '',
        };
      }),
    ),
  recordToApi: record => ({
    retrofitId: record.retrofitId,
    fromCorporationId: record.fromCorporationId,
    fromCorporationName: record.fromCorporationName,
    toCorporationId: record.toCorporationId,
    toCorporationName: record.toCorporationName,
    retrofitRentalId: record.retrofitRentalId,
    isApproved: record.isApproved,
    isReturned: record.isReturned,
    planStartDate: record.planStartDate
      ? convertFillDate(record.planStartDate, minFillTime)
      : '',
    planEndDate: record.planEndDate
      ? convertFillDate(record.planEndDate, maxFillTime)
      : '',
    actualStartDate: record.actualStartDate
      ? convertFillDate(record.actualStartDate, minFillTime)
      : '',
    actualEndDate: record.actualEndDate
      ? convertFillDate(record.actualEndDate, maxFillTime)
      : '',
    rentalUsers: [record.email],
  }),
};

export default converter;
