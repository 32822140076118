import React, { useRef, useCallback } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles, createStyles, Dialog } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Button, Typography } from '../..';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../../theme/define';
import { colors } from '../../../theme';
import { OkIcon } from '../../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      fontSize: 18,
      margin: 0,
      padding: 24,
    },
    content: {
      fontSize: 18,
      width: 600,
      height: 260 - (80 + 84), // 80: header, 84: footer
      '& p': {
        fontSize: 18,
        fontWeight: 'bold',
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    footer: {
      margin: 0,
      padding: 24,
    },
    button: {
      background: colors.button.save,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.saveHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.saveDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
      '& span': {
        padding: 0,
      },
    },
  }),
);

const DialogOKOnlyWithUrl: React.FC<{
  open: boolean;
  onClose: () => void;
  title: string;
  messages?: string[]; // 配列指定があれば複数行表示が優先される
  subMessages?: string[];
  nonTranslateMessage?: boolean; // 画面側でTranslateして設定する場合はtrue
  okLabel?: string;
  url?: string;
}> = ({
  open,
  onClose,
  title,
  messages = [],
  subMessages = [],
  nonTranslateMessage = false,
  okLabel,
  url = '',
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const contentRef = useRef<HTMLDivElement>(null);
  const onEnter = useCallback(() => {
    if (
      !contentRef ||
      !contentRef.current ||
      !contentRef.current.parentElement
    ) {
      return;
    }
    const heightFactor = 20; // リサイズ時の調整値（同値だとスクロールバーが出る為、少し大きくする）

    // JSS で定義している content.height より、
    // MuiDialogContent 內部の div 領域の方が大きい場合は、div の Height で MuiDialogContent をリサイズする
    const messageDiv = contentRef.current;
    const messageParentDiv = contentRef.current.parentElement;
    if (messageDiv.clientHeight > messageParentDiv.clientHeight) {
      const newHeight = messageDiv.clientHeight + heightFactor;
      messageParentDiv.style.height = `${newHeight}px`;
    }
  }, [contentRef]);
  const handleClick = (src: string) => {
    window.open(src, '_blank');
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onEnter={onEnter}
      data-testid={'ok_only_dialog'}
    >
      <MuiDialogTitle className={classes.header}>
        {title !== '' ? translate(title) : title}
      </MuiDialogTitle>
      <MuiDialogContent className={classes.content}>
        <div ref={contentRef}>
          {
            // eslint-disable-next-line no-nested-ternary
            messages.length > 0 ? (
              messages.map((text, i) => (
                <div key={i}>
                  {nonTranslateMessage ? text : translate(text)}
                </div>
              ))
            ) : (
              <></>
            )
          }
          {url ? (
            <Typography onClick={() => handleClick(url)}>{url}</Typography>
          ) : (
            <></>
          )}
          <br />
          {subMessages.length > 0 ? (
            subMessages.map((text, i) => (
              <div key={i}>{nonTranslateMessage ? text : translate(text)}</div>
            ))
          ) : (
            <></>
          )}
        </div>
      </MuiDialogContent>
      <MuiDialogActions className={classes.footer}>
        <Button
          className={classes.button}
          onClick={onClose}
          data-testid={okLabel}
        >
          <OkIcon />
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};

DialogOKOnlyWithUrl.defaultProps = {
  okLabel: 'admin.actions.ok',
};
DialogOKOnlyWithUrl.displayName = 'DialogOKOnlyWithUrl';
export default DialogOKOnlyWithUrl;
