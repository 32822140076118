import React, { useEffect, useState } from 'react';
import { ChipField, useTranslate } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import {
  CustomList,
  Datagrid,
  CustomDateField,
  FunctionField,
  Loading,
} from '../../..';
import {
  useAccount,
  useGetSelefDetailData,
  useResource,
} from '../../../../hooks';
import { useGetSiteName } from './hooks';
import { getRetrofitInfoBreadcrumbs } from './util';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      '& > div:nth-child(2)': {
        overflowY: 'auto',
        overflowX: 'hidden',
        height: 'calc(100vh - 320px)',
      },
    },
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
            // ヘッダ固定用スタイル
            position: 'sticky',
            top: 0,
            backgroundColor: '#29292f', // colors.tsのbackgroundと同じ色を設定すること
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 112,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 786,
          },
        },
      },
    },
    errorcode: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
  }),
);

interface ApiResult {
  isOwnData: boolean;
  siteAttachedData: boolean;
  fetchedRetrofitId: number;
  breadcrumbs: string[];
}

const RetrofitErrorList: React.FC = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const resourceErrors = useResource('retrofitErrors');
  const { retrofitId: strRetrofitId, siteId } =
    useParams<{ retrofitId: string; siteId: string }>();
  const retrofitId = Number(strRetrofitId);
  const getAccount = useAccount();
  const getSelefDetailData = useGetSelefDetailData();
  const getSiteName = useGetSiteName();
  // const redirectTo = useRedirect();

  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: ApiResult;
  }>({
    loading: true,
    fetched: false,
    data: undefined,
  });

  useEffect(() => {
    // 既にDB取得済みでも、urlのretrofitIdが変わった場合は再度実施
    if (apiState.fetched && apiState.data?.fetchedRetrofitId === retrofitId) {
      return;
    }

    const api = async (): Promise<{
      data: {
        apiResult: ApiResult;
      };
    }> => {
      // accounts
      const {
        data: { corporationId },
      } = await getAccount();

      // retrofits
      const listFileter = {
        ownerCorporationId: corporationId,
        adminCorporationId: corporationId,
      };
      // 建機一覧から閲覧可能なレトロフィットであるか判定
      const retrofit = await getSelefDetailData(
        'retrofits',
        listFileter,
        retrofitId,
      );

      // 現場に紐づくレトロフィットであるか判定
      const siteAttachedListFileter = {
        siteId: siteId || '',
      };
      const siteAttachedRetrofit = await getSelefDetailData(
        'retrofits',
        siteAttachedListFileter,
        retrofitId,
      );

      // site ※現場建機詳細から呼ばれた場合に、パンくずリストで必要
      let siteName;
      if (siteId) {
        const result = await getSiteName({ siteId });
        siteName = result.data.siteName;
      }

      // DBの取得結果を使うため、パンくずリストはここで作成
      const breadcrumbs = getRetrofitInfoBreadcrumbs(
        siteAttachedRetrofit?.machineInfoMachineName
          ? siteAttachedRetrofit?.machineInfoMachineName
          : retrofit?.machineInfoMachineName || '',
        siteName,
      );

      return {
        data: {
          apiResult: {
            isOwnData: !!retrofit,
            siteAttachedData: !!siteAttachedRetrofit,
            fetchedRetrofitId: retrofitId,
            breadcrumbs,
          },
        },
      };
    };
    api().then(({ data: { apiResult } }) => {
      setApiState({ loading: false, fetched: true, data: apiResult });
    });
  }, [
    getSelefDetailData,
    getAccount,
    getSiteName,
    retrofitId,
    siteId,
    apiState,
  ]);

  if (apiState.loading || !apiState.data) return <Loading />;

  // TODO: 閲覧不可なデータは現場一覧に遷移する機能を有効にする場合、コメントアウトを外す
  const { breadcrumbs } = apiState.data;
  // 建機一覧に存在しないretrofitのエラー詳細情報は表示させない
  // または現場詳細画面から遷移する場合、対象現場に紐づかないretrofitのエラー詳細情報は表示させない
  // if (siteId ? !siteAttachedData : !isOwnData) redirectTo('/');

  return (
    <CustomList
      className={classes.table}
      breadcrumbs={breadcrumbs}
      resource={resourceErrors}
      title="admin.pages.retrofitErrorList"
      bulkActionButtons={false}
      filter={{ retrofitId }}
      useScrollPagination={true}
    >
      <Datagrid className={classes.root}>
        <CustomDateField
          resource={resourceErrors}
          source="notifiedTime"
          showTime
        />
        <FunctionField
          resource={resourceErrors}
          source="errorCodes"
          sortable={false}
          render={record => {
            if (!record) return null;
            const { errorCodes } = record;
            if (!errorCodes) return null;
            return (
              <div className={classes.errorcode}>
                {errorCodes.map(value => {
                  const msg = translate(
                    `admin.errorInfo.retrofitError.${value}`,
                  );
                  const name = `${value} : ${msg}`;
                  return (
                    <div key={value} className={classes.chip}>
                      <ChipField source="name" record={{ name }} />
                    </div>
                  );
                })}
              </div>
            );
          }}
        />
      </Datagrid>
    </CustomList>
  );
};

RetrofitErrorList.displayName = 'RetrofitErrorList';
export default RetrofitErrorList;
