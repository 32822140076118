import { AdminApiConverter, ApiFilter } from '..';
import { UserPermissionsData } from '../../types';

const converter: AdminApiConverter<'userPermissions', 'userPermissions'> = {
  resource: 'userPermissions',
  defaultSort: { field: 'id', order: 'ASC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'userPermissions'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('email' in filter) apiFilter.email = filter.email;
    if ('userRoles' in filter) apiFilter.roles = filter.userRoles;

    return apiFilter;
  },
  sortFieldToApi: field => {
    if (field === 'userRoles') return 'roles';
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map(
        (record): UserPermissionsData => ({
          id: record.id || 0,
          email: record.email || '',
          userRoles: record.roles || [],
        }),
      ),
    ),
  recordToApi: record => ({
    email: record.email,
    roles: record.userRoles,
  }),
};

export default converter;
