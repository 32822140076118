import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { DatagridRow } from '../bases';
import { isBlank } from '../../utils';

type RetrofitAddToSiteDatagridRowProps = React.ComponentProps<
  typeof DatagridRow
>;

const RetrofitAddToSiteDatagridRow: React.FC<RetrofitAddToSiteDatagridRowProps> =
  ({
    record,
    resource,
    id,
    onToggleItem,
    children,
    selected,
    basePath,
    classes = {},
  }) => {
    return (
      <TableRow
        key={id}
        style={{
          opacity: record && isBlank(record.siteId) ? 1 : 0.5,
        }}
      >
        {record && isBlank(record.siteId) ? (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              className={`select-item ${classes.checkbox}`}
              checked={selected}
              onClick={(e: any) => onToggleItem && onToggleItem(id, e)}
            />
          </TableCell>
        ) : (
          <td />
        )}
        {React.Children.map(children, field => {
          if (React.isValidElement(field)) {
            return (
              <TableCell key={id}>
                {React.cloneElement(field, {
                  record,
                  basePath,
                  resource,
                })}
              </TableCell>
            );
          }
          if (typeof field === 'string') {
            return field;
          }
          return null;
        })}
      </TableRow>
    );
  };

RetrofitAddToSiteDatagridRow.displayName = 'RetrofitAddToSiteDatagridRow';

export default RetrofitAddToSiteDatagridRow;
