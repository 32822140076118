import { RetrofitErrorData } from '../../types';

const retrofitErrors: RetrofitErrorData[] = [
  {
    id: '6_2020-02-01T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-02T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100, 2300, 2400, 3000, 4000, 5000],
  },
  {
    id: '6_2020-02-03T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-04T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-05T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-06T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-07T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-08T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-09T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-10T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-11T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-12T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-13T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-14T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-15T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-16T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-17T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-18T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-19T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '6_2020-02-20T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '7_2020-02-01T08:00:02.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:02.000Z',
    errorCodes: [2200, 2300],
  },
  {
    id: '8_2020-02-01T08:00:03.000Z',
    retrofitId: 8,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:03.000Z',
    errorCodes: [2400, 2401, 2402, 2403, 2404, 2500, 3000, 4000, 5000],
  },
  {
    id: '9_2020-02-01T08:00:04.000Z',
    retrofitId: 9,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:04.000Z',
    errorCodes: [1000],
  },
  {
    id: '10_2020-02-01T08:00:05.000Z',
    retrofitId: 10,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:05.000Z',
    errorCodes: [2000, 2001, 2002, 2003, 2004],
  },
  {
    id: '6_2020-01-31T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-11-31T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '7_2020-01-31T08:00:02.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-11-31T08:00:02.000Z',
    errorCodes: [2200, 2300],
  },
  {
    id: '8_2020-01-31T08:00:03.000Z',
    retrofitId: 8,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-11-31T08:00:03.000Z',
    errorCodes: [2400, 2401, 2402, 2403, 2404, 2500, 3000, 4000, 5000],
  },
  {
    id: '9_2020-01-31T08:00:04.000Z',
    retrofitId: 9,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-11-31T08:00:04.000Z',
    errorCodes: [1000],
  },
  {
    id: '10_2020-01-31T08:00:05.000Z',
    retrofitId: 10,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-11-31T08:00:05.000Z',
    errorCodes: [2000, 2001, 2002, 2003, 2004],
  },
  {
    id: '6_2020-01-30T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-11-30T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '7_2020-01-30T08:00:02.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-11-30T08:00:02.000Z',
    errorCodes: [2200, 2300],
  },
  {
    id: '8_2020-01-30T08:00:03.000Z',
    retrofitId: 8,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-11-30T08:00:03.000Z',
    errorCodes: [2400, 2401, 2402, 2403, 2404, 2500, 3000, 4000, 5000],
  },
  {
    id: '9_2020-01-30T08:00:04.000Z',
    retrofitId: 9,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-11-30T08:00:04.000Z',
    errorCodes: [1000],
  },
  {
    id: '10_2020-01-30T08:00:05.000Z',
    retrofitId: 10,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-11-30T08:00:05.000Z',
    errorCodes: [2000, 2001, 2002, 2003, 2004],
  },
  {
    id: '6_2020-01-29T08:00:01.000Z',
    retrofitId: 6,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-11-29T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '7_2020-01-29T08:00:02.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-11-29T08:00:02.000Z',
    errorCodes: [2200, 2300],
  },
  {
    id: '8_2020-01-29T08:00:03.000Z',
    retrofitId: 8,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-11-29T08:00:03.000Z',
    errorCodes: [2400, 2401, 2402, 2403, 2404, 2500, 3000, 4000, 5000],
  },
  {
    id: '9_2020-01-29T08:00:04.000Z',
    retrofitId: 9,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-11-29T08:00:04.000Z',
    errorCodes: [1000],
  },
  {
    id: '10_2020-01-29T08:00:05.000Z',
    retrofitId: 10,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-11-29T08:00:05.000Z',
    errorCodes: [2000, 2001, 2002, 2003, 2004],
  },
  {
    id: '11_2020-03-06T01:06:01.000Z',
    retrofitId: 11,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-03-06T01:06:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '12_2020-03-06T01:07:01.000Z',
    retrofitId: 12,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-03-06T01:07:01.000Z',
    errorCodes: [2200, 2300],
  },
  {
    id: '13_2020-03-06T01:08:01.000Z',
    retrofitId: 13,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-03-06T01:08:01.000Z',
    errorCodes: [2400, 2401, 2402, 2403, 2404, 2500, 3000, 4000, 5000],
  },
  {
    id: '14_2020-03-06T01:09:01.000Z',
    retrofitId: 14,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-03-06T01:09:01.000Z',
    errorCodes: [1000],
  },
  {
    id: '15_2020-03-06T01:10:01.000Z',
    retrofitId: 15,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-03-06T01:10:01.000Z',
    errorCodes: [2000, 2001, 2002, 2003, 2004],
  },
  {
    id: '16_2020-02-01T08:00:01.000Z',
    retrofitId: 16,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '17_2020-02-01T08:00:02.000Z',
    retrofitId: 17,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:02.000Z',
    errorCodes: [2200, 2300],
  },
  {
    id: '18_2020-02-01T08:00:03.000Z',
    retrofitId: 18,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:03.000Z',
    errorCodes: [2400, 2401, 2402, 2403, 2404, 2500, 3000, 4000, 5000],
  },
  {
    id: '19_2020-02-01T08:00:04.000Z',
    retrofitId: 19,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:04.000Z',
    errorCodes: [1000],
  },
  {
    id: '20_2020-02-01T08:00:05.000Z',
    retrofitId: 20,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:05.000Z',
    errorCodes: [2000, 2001, 2002, 2003, 2004],
  },
  {
    id: '21_2020-02-01T08:00:01.000Z',
    retrofitId: 21,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '22_2020-02-01T08:00:02.000Z',
    retrofitId: 22,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:02.000Z',
    errorCodes: [2200, 2300],
  },
  {
    id: '23_2020-02-01T08:00:03.000Z',
    retrofitId: 23,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:03.000Z',
    errorCodes: [2400, 2401, 2402, 2403, 2404, 2500, 3000, 4000, 5000],
  },
  {
    id: '24_2020-02-01T08:00:04.000Z',
    retrofitId: 24,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:04.000Z',
    errorCodes: [1000],
  },
  {
    id: '25_2020-02-01T08:00:05.000Z',
    retrofitId: 25,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:05.000Z',
    errorCodes: [2000, 2001, 2002, 2003, 2004],
  },
  {
    id: '26_2020-02-01T08:00:01.000Z',
    retrofitId: 26,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:01.000Z',
    errorCodes: [2000, 2100],
  },
  {
    id: '27_2020-02-01T08:00:02.000Z',
    retrofitId: 27,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:02.000Z',
    errorCodes: [2200, 2300],
  },
  {
    id: '28_2020-02-01T08:00:03.000Z',
    retrofitId: 28,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:03.000Z',
    errorCodes: [2400, 2401, 2402, 2403, 2404, 2500, 3000, 4000, 5000],
  },
  {
    id: '29_2020-02-01T08:00:04.000Z',
    retrofitId: 29,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:04.000Z',
    errorCodes: [1000],
  },
  {
    id: '30_2020-02-01T08:00:05.000Z',
    retrofitId: 30,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-01T08:00:05.000Z',
    errorCodes: [2000, 2001, 2002, 2003, 2004],
  },
  {
    id: '7_2020-02-02T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-02T00:00:01.000Z',
    errorCodes: [1000],
  },
  {
    id: '7_2020-02-03T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-03T00:00:01.000Z',
    errorCodes: [2000, 2001, 2002, 2003, 2004],
  },
  {
    id: '7_2020-02-04T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-04T00:00:01.000Z',
    errorCodes: [2100, 2101, 2102, 2103, 2104],
  },
  {
    id: '7_2020-02-05T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-05T00:00:01.000Z',
    errorCodes: [2200, 2201, 2202, 2203, 2204],
  },
  {
    id: '7_2020-02-06T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-06T00:00:01.000Z',
    errorCodes: [2300, 2301, 2302, 2303, 2304],
  },
  {
    id: '7_2020-02-07T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-07T00:00:01.000Z',
    errorCodes: [2400, 2401, 2402, 2403, 2404],
  },
  {
    id: '7_2020-02-08T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-08T00:00:01.000Z',
    errorCodes: [2500, 2501, 2502, 2503, 2504],
  },
  {
    id: '7_2020-02-09T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-09T00:00:01.000Z',
    errorCodes: [3000],
  },
  {
    id: '7_2020-02-10T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-10T00:00:01.000Z',
    errorCodes: [3100],
  },
  {
    id: '7_2020-02-11T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-11T00:00:01.000Z',
    errorCodes: [4000],
  },
  {
    id: '7_2020-02-12T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-12T00:00:01.000Z',
    errorCodes: [5000],
  },
  {
    id: '7_2020-02-13T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-13T00:00:01.000Z',
    errorCodes: [5001],
  },
  {
    id: '7_2020-02-14T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-14T00:00:01.000Z',
    errorCodes: [1000],
  },
  {
    id: '7_2020-02-15T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-15T00:00:01.000Z',
    errorCodes: [2000, 2001, 2002, 2003, 2004],
  },
  {
    id: '7_2020-02-16T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-16T00:00:01.000Z',
    errorCodes: [2100, 2101, 2102, 2103, 2104],
  },
  {
    id: '7_2020-02-17T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-17T00:00:01.000Z',
    errorCodes: [2200, 2201, 2202, 2203, 2204],
  },
  {
    id: '7_2020-02-18T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-18T00:00:01.000Z',
    errorCodes: [2300, 2301, 2302, 2303, 2304],
  },
  {
    id: '7_2020-02-19T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-19T00:00:01.000Z',
    errorCodes: [2400, 2401, 2402, 2403, 2404],
  },
  {
    id: '7_2020-02-20T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-20T00:00:01.000Z',
    errorCodes: [2500, 2501, 2502, 2503, 2504],
  },
  {
    id: '7_2020-02-21T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-21T00:00:01.000Z',
    errorCodes: [3000],
  },
  {
    id: '7_2020-02-22T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-22T00:00:01.000Z',
    errorCodes: [3100],
  },
  {
    id: '7_2020-02-23T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-23T00:00:01.000Z',
    errorCodes: [4000],
  },
  {
    id: '7_2020-02-24T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-24T00:00:01.000Z',
    errorCodes: [5000],
  },
  {
    id: '7_2020-02-25T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-25T00:00:01.000Z',
    errorCodes: [5001],
  },
  {
    id: '7_2020-02-26T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-26T00:00:01.000Z',
    errorCodes: [1000],
  },
  {
    id: '7_2020-02-27T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-27T00:00:01.000Z',
    errorCodes: [2000, 2001, 2002, 2003, 2004],
  },
  {
    id: '7_2020-02-28T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-28T00:00:01.000Z',
    errorCodes: [2100, 2101, 2102, 2103, 2104],
  },
  {
    id: '7_2020-02-29T00:00:01.000Z',
    retrofitId: 7,
    userCorporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    notifiedTime: '2020-12-29T00:00:01.000Z',
    errorCodes: [2200, 2201, 2202, 2203, 2204],
  },
];

export default retrofitErrors;
