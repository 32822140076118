import React from 'react';
import { Button, makeStyles, createStyles } from '@material-ui/core';
import { ReactAdminComponentPropsWithId } from 'react-admin';
import { colors } from '../../theme';
import { AddIcon } from '../../assets';
import { config } from '../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 160,
      color: colors.text.primaryLight,
      backgroundColor: colors.button.cancel,
      fontWeight: 'normal',
      boxShadow: '0px 0px 4px #00000040',
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
    },
  }),
);

type SiteCreateButtonProps = React.PropsWithChildren<
  Partial<
    ReactAdminComponentPropsWithId & {
      classes: object;
      label: string;
    }
  >
>;

const SiteCreateButton: React.FC<SiteCreateButtonProps> = (
  props: SiteCreateButtonProps,
) => {
  const { classes: classOverride } = props;
  const classes = useStyles({ classes: classOverride });
  const siteCreateUrl = config.url.siteManage;

  return (
    <Button
      className={classes.root}
      onClick={(e: any) => {
        e.stopPropagation();
        // 現場作成画面URLの設定
        window.open(`${siteCreateUrl}`, '_blank');
      }}
    >
      <AddIcon />
    </Button>
  );
};

SiteCreateButton.displayName = 'SiteCreateButton';
export default SiteCreateButton;
