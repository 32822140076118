import React from 'react';
import { MapProps, crearteOverlayControl } from '../../../../../hooks';
import { MapHomeButton } from '../../../..';

const addHomeControl = ({ map }: MapProps, onClick: () => void) => {
  map.addControl(
    crearteOverlayControl('map_home', <MapHomeButton onClick={onClick} />, {
      left: 11,
      top: 165,
    }),
  );
};

export default addHomeControl;
