import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Button } from '../../../..';
import { mapStyle } from '../../../../../theme';
import { Dimension, GeofenceType } from '../../../../../dataProvider';
import { MapGeo2d, MapGeo3d } from '../../../../../assets';

const onUseStyle = makeStyles(() =>
  createStyles({
    root: {
      ...mapStyle.button,
      backgroundColor: '#000344',
    },
  }),
);

const offUseStyle = makeStyles(() =>
  createStyles({
    root: {
      ...mapStyle.button,
    },
  }),
);

const DimensionEditButton: React.FC<{
  settingType: GeofenceType | undefined;
  defaultDimension?: Dimension;
  onClick?: () => void;
}> = ({ settingType, defaultDimension = 'ThreeDimension', onClick }) => {
  const [dimension, setDimension] = useState<Dimension>(defaultDimension);
  const onStyle = onUseStyle();
  const offStyle = offUseStyle();
  const isEnable = settingType === 'Polygon' || settingType === 'Circle';
  useEffect(() => {
    if (!isEnable) setDimension('ThreeDimension');
  }, [isEnable]);

  return (
    <Button
      onClick={() => {
        if (!isEnable) return;
        if (onClick) onClick();
        setDimension(
          dimension === 'TwoDimension' ? 'ThreeDimension' : 'TwoDimension',
        );
      }}
      className={isEnable ? onStyle.root : offStyle.root}
      data-testid={'admin.actions.mapDimension'}
    >
      {dimension === 'TwoDimension' ? <MapGeo2d /> : <MapGeo3d />}
    </Button>
  );
};

DimensionEditButton.displayName = 'DimensionEditButton';
export default DimensionEditButton;
