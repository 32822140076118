import { useDataProvider, DataProviderProxy } from 'react-admin';

// バリデーションの実処理
const nameValidationAsync = async (
  name: string,
  dataProvider: DataProviderProxy,
  resource: string,
  errorMessage: string,
  inFilter: { [key: string]: string | number },
  ignoreIds: string[] | number[],
) => {
  // 入力値の 名称 をフィルタに加える
  const filter = inFilter;
  filter.name = name;
  // 対象リソースに対し、任意のフィルター項目 と 名称 で検索し、データがあれば、エラーを返す
  const result = await dataProvider.getList(resource, {
    filter,
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'id', order: 'ASC' },
  });
  if (!result) return null;

  // 特定のIDを取得結果から除外する処理（例: 編集対象のデータは、バリデーションの対象外にしたい時に利用する)
  const ignoredData = result.data.filter(item => {
    const { id } = item;
    return !ignoreIds.some(value => value === id);
  });
  return ignoredData.length > 0 ? errorMessage : null;
};

// NOTE:
//   InputField の validate で 非同期メソッドを動かす為に、
//   本メソッドでラップしないとビルドエラーになる。
//
//   また、バリデーション処理をキャッシュ化しないと、SAVEボタン押下時に意図しない回数呼ばれる問題が発生する。
//   (final-formの問題)
//     => https://github.com/final-form/final-form/issues/37#issuecomment-417715696
const nameValidationMemoize = (
  validationFn: Function,
  dataProvider: DataProviderProxy,
  resource: string,
  errorMessage: string,
  filter: { [key: string]: string | number },
  ignoreIds: string[] | number[],
) => {
  let lastArg: string;
  let lastResult: string | undefined;
  return (arg: string) => {
    if (lastArg !== arg) {
      lastArg = arg;
      lastResult = validationFn(
        arg,
        dataProvider,
        resource,
        errorMessage,
        filter,
        ignoreIds,
      );
    }
    return lastResult;
  };
};

/**
 * 名称バリデーション
 * @param resource リソース文字列
 * @param errorMessage バリデーションエラー時の文言
 * @param filter フィルタオブジェクト
 * @param ignoreIds フィルタ結果から除外するID
 *
 * @example
 *
 * const buckedId = 1;
 * const { nameValidation } = useNameValidation(
 *   'buckets',
 *   'resources.xxxxx.xxxxx',
 *   { corporationId }, // 企業IDで絞る,
 *   [buckedId] // バケットID 1 はバリデーションの対象外
 * );
 *
 * [JSX]
 *   <TextInput
 *     ...
 *     validate={nameValidation}
 *     />
 */
const useNameValidation = (
  resource: string,
  errorMessage: string,
  filter?: { [key: string]: string | number },
  ignoreIds?: string[] | number[],
) => {
  const dataProvider = useDataProvider();
  return nameValidationMemoize(
    nameValidationAsync,
    dataProvider,
    resource,
    errorMessage,
    filter || {},
    ignoreIds || [],
  );
};

export default useNameValidation;
