import { Token } from '.';

const SOTRE_KEY = 'token';
export default {
  get: () => {
    const token = localStorage.getItem(SOTRE_KEY);
    if (!token) return null;
    return JSON.parse(token) as Token;
  },

  set: (token: Token) => {
    localStorage.setItem(SOTRE_KEY, JSON.stringify(token));
  },

  remove: () => {
    localStorage.removeItem(SOTRE_KEY);
  },
};
