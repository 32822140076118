import React, { FC } from 'react';
import { FunctionField } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import { SimpleForm } from 'ra-ui-materialui';
import {
  CustomList,
  Datagrid,
  TextField,
  CustomDateField,
  ListDownloadButton,
  LinkButton,
  IconField,
  FinalFormConsumer,
} from '../..';
import { AddIcon } from '../../../assets';
import { GeofenceData } from '../../../dataProvider';
import { GeofenceListAside } from './view';

const Actions: FC<{
  basePath?: string;
}> = ({ basePath }) => (
  <LinkButton
    path={`${basePath}/create`}
    label="admin.actions.creationInstructions"
  >
    <AddIcon />
  </LinkButton>
);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 16,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 'auto',
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 80,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 80,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 80,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '10px',
            paddingLeft: '10px',
            width: 100,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(7)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 112,
          },
          '& .MuiTableCell-root:nth-child(8)': {
            paddingRight: '32px',
            paddingLeft: '15px',
            width: 44,
          },
        },
      },
    },
  }),
);

const GeofenceList: FC = props => {
  const classes = useStyles();
  return (
    <SimpleForm toolbar={<></>}>
      <FinalFormConsumer>
        {({ form }) => (
          <CustomList
            className={classes.root}
            resource="geofences"
            {...props}
            actions={<Actions {...props} />}
            aside={<GeofenceListAside {...props} form={form} />}
          >
            <Datagrid rowClick="edit">
              <TextField resource="geofences" source="name" />
              <IconField resource="geofences" source="type" />
              <IconField resource="geofences" source="dimension" />
              <IconField resource="geofences" source="valid" />
              <IconField resource="geofences" source="alertType" />
              <CustomDateField
                resource="geofences"
                source="lastUpdated"
                showTime
              />
              <FunctionField
                resource="geofences"
                source="geofenceFile"
                sortable={false}
                label=""
                render={(record: GeofenceData) => {
                  const url = record.geofenceFile?.src || '';
                  return (
                    <ListDownloadButton
                      url={url}
                      label="geofencesDownload"
                      smallicon={true}
                    />
                  );
                }}
              />
            </Datagrid>
          </CustomList>
        )}
      </FinalFormConsumer>
    </SimpleForm>
  );
};

GeofenceList.displayName = 'GeofenceList';
export default GeofenceList;
