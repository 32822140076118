import React, { useEffect, useState } from 'react';
import { SelectInput } from '../../..';
import { GnssSettingData, RetrofitData } from '../../../../dataProvider';
import { useGetListAllPage } from '../../../../hooks';

interface GnssReferenceInputProps {
  record?: RetrofitData;
  userCorporationId?: string;
  rented?: boolean;
  unapproved?: boolean;
  selectNtripInfoDisable?: boolean;
  adminDisabled?: boolean;
}

const GnssReferenceInput: React.FC<GnssReferenceInputProps> = ({
  record,
  userCorporationId = '',
  rented = false,
  unapproved = false,
  selectNtripInfoDisable = false,
  adminDisabled = false,
}) => {
  const resource = 'retrofits';
  const getListAllPage = useGetListAllPage();
  const [fetched, setFetched] = useState(false);
  const [gnssSetting, setGnssSetings] = useState<GnssSettingData[]>([]);

  useEffect(() => {
    if (fetched || !record) return; // NOTE: 再取得防止
    setFetched(true);

    // 本番環境のREACT_APP_FEATURE_RETROFITLICENSEがtrueに切り替わる事情でfilter条件を変更
    // 変更前はライセンスに紐づかない建機の場合のみ、filter条件は以下の通りであった
    // eslint-disable-next-line no-irregular-whitespace
    // id = 建機に紐づくgnssSettingId　かつ、ownerCorporationId = userCorporationId
    const filter = { ownerCorporationId: userCorporationId };

    getListAllPage({
      resource: 'gnssSettings',
      filter,
      sort: {
        field: 'name',
        order: 'ASC',
      },
    }).then(result => {
      const gnssSettingData = result.data as GnssSettingData[];
      setGnssSetings(gnssSettingData);
    });
  }, [fetched, record, userCorporationId, getListAllPage]);

  if (!record) return <></>;
  const { corporationId } = record;
  const unMatchCorporation = corporationId !== userCorporationId;
  const disabled =
    unapproved ||
    (rented && unMatchCorporation) ||
    selectNtripInfoDisable ||
    adminDisabled;

  return (
    <div>
      <SelectInput
        resource={resource}
        source="gnssSettingId"
        optionText="ntripInfo"
        label="admin.label.retrofitEdit.ntripSettingInfo"
        choices={gnssSetting.map(({ id, name, ntripInfo }) => ({
          id,
          name,
          ntripInfo,
        }))}
        disabled={disabled}
        style={{ boxSizing: 'border-box' }}
      />

      <div style={{ height: 8 }} />
    </div>
  );
};

GnssReferenceInput.displayName = 'GnssReferenceInput';
export default GnssReferenceInput;
