import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { MapTransparentButton } from '..';
import { mapStyle } from '../../theme';
import { MapMinus, MapPlus } from '../../assets/images';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      ...mapStyle.buttonWrapVertical,
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

const MapZoomButton: React.FC<{
  onClickZoomIn: () => void;
  onClickZoomOut: () => void;
}> = ({ onClickZoomIn, onClickZoomOut }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MapTransparentButton onClick={onClickZoomIn}>
        <MapPlus />
      </MapTransparentButton>
      <MapTransparentButton onClick={onClickZoomOut}>
        <MapMinus />
      </MapTransparentButton>
    </div>
  );
};

MapZoomButton.displayName = 'MapZoomButton';
export default MapZoomButton;
