import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { AssignmentData } from '../../../../dataProvider';

interface InParams {
  retrofitId: number;
  userCorporationId: string;
  retrofitCorporationId: string;
}

interface OutParams {
  data: {
    unapproved: boolean;
    editableId?: number;
    transferdData?: AssignmentData;
  };
}

const useGetTransferInfo = () => {
  const dataProvider = useDataProvider();
  return useCallback(
    async ({
      retrofitId,
      userCorporationId,
      retrofitCorporationId,
    }: InParams): Promise<OutParams> => {
      const pagination = { page: 1, perPage: 100 };
      const sort = { field: 'id', order: 'DESC' };

      const { data: unapprovedTransferData } =
        await dataProvider.getList<AssignmentData>('assignments', {
          filter: {
            retrofitId, // 対象建機
            fromCorporationId: userCorporationId, // ログインユーザの企業ID
            isApproved: false, // 未承認のデータを抽出
          },
          pagination,
          sort,
        });

      const { data: rentedTransferData } =
        await dataProvider.getList<AssignmentData>('assignments', {
          filter: {
            retrofitId, // 対象建機
            toCorporationId: retrofitCorporationId,
            isApproved: true, // 譲渡済みのデータを抽出
          },
          pagination,
          sort,
        });

      return {
        data: {
          unapproved: unapprovedTransferData.length > 0, // 譲渡未承認のデータが1件でも存在するか
          editableId:
            unapprovedTransferData.length > 0
              ? unapprovedTransferData[0].id
              : undefined,
          transferdData:
            rentedTransferData.length > 0 ? rentedTransferData[0] : undefined, // 貸与中のデータが1件でも存在するか
        },
      };
    },
    [dataProvider],
  );
};

export default useGetTransferInfo;
