import { Button as RaButton } from 'react-admin';
import { ButtonProps } from '@material-ui/core/Button';
import { IconButtonProps } from '@material-ui/core/IconButton';
import { ReactElementLike } from 'prop-types';

type MuiButtonProps = ButtonProps & IconButtonProps;

export type MuiProps = MuiButtonProps;

export interface RaProps {
  alignIcon: string;
  children: ReactElementLike;
  classes: object;
  className: string;
  color: string;
  disabled: boolean;
  label: string;
  size: 'small' | 'medium' | 'large';
}

const Button = RaButton as ForgivingComponentType<MuiProps & RaProps>;
Button.displayName = 'Button';

export default Button;
