import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { Typography } from '../..';
import { ExclamationMark } from '../../../assets';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 22,
      '& > div': {
        textAlign: 'center',
      },
      '& .MuiTypography-root': {
        fontSize: 20,
      },
    },
    space: {
      marginTop: 12,
    },
  }),
);

const PaginationLimit: FC = () => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <div className={classes.root}>
      <div>
        <ExclamationMark />
        <div className={classes.space} />
        <Typography>{translate('admin.label.listNoResults')}</Typography>
      </div>
    </div>
  );
};

PaginationLimit.displayName = 'PaginationLimit';
export default PaginationLimit;
