import React, { ChangeEvent, useState } from 'react';
import { TextInput as RaTextInput } from 'react-admin';

const RetrofitTextInput: React.FC<{
  source: string;
  label?: string; // 內部で使わなくても <FormInput> 側で利用される
  addLabel?: boolean; // 內部で使わなくても <FormInput> 側で利用される
  validate?: any | any[];
  checkSaveButtonDisables?: (value: string) => void;
}> = ({ source, label, validate, checkSaveButtonDisables }) => {
  const [defaultValue, setDefaultValue] = useState('Retro-');
  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    if (checkSaveButtonDisables) checkSaveButtonDisables(value);
    if (!value) {
      setDefaultValue('');
    } else {
      setDefaultValue('Retro-');
    }
  };

  // 渡ってきたsourceから必要な文字列だけを抽出
  const sourceStr = source.substring(source.indexOf('.'));
  const checkSource = sourceStr.replace('.', '');
  return checkSource === 'basicInfoSerialNumber' ? (
    <RaTextInput
      source={source}
      label={label}
      defaultValue={defaultValue}
      validate={validate}
      onChange={handleChange}
    />
  ) : (
    <RaTextInput
      inputProps={{
        // ペーストを無効化する
        onPaste: (e: any) => e.preventDefault(),
      }}
      source={source}
      label={label}
      defaultValue={defaultValue}
      validate={validate}
      onChange={handleChange}
    />
  );
};

RetrofitTextInput.displayName = 'RetrofitTextInput';
export default RetrofitTextInput;
