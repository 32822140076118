import React, { FC, useEffect, useState, cloneElement } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import { useRedirect, useTranslate, useNotify } from 'react-admin';
import {
  SimpleShowLayout,
  TabData,
  TabContent,
  Divider,
  Loading,
  BreadcrumbsFiled,
} from '../..';
import { styles } from '../../../theme';
import {
  RetrofitManageAdmin,
  RetrofitManageAdminBulkActionList,
  RetrofitNamePlateFile,
} from '.';
import { useCustomLocation, useJumpTo } from '../../../hooks';
import {
  UpdateData,
  UpdateDataAdmin,
  useAdminInitial,
  PermissionError,
} from './hooks';
import { config, apiErrorHandler } from '../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    simpleShowLayout: {
      ...styles.zeroPaddingHorizontal,
    },
    breadcrumbs: {
      marginBottom: 12,
    },
  }),
);

const useCreateRetrofitManageAdminTabs = (props: any): TabData[] => {
  const translate = useTranslate();
  const tabData: TabData[] = [];
  tabData.push({
    name: 'photo',
    tab: (
      <MuiTab
        label={translate('admin.pages.retrofitNamePlateFiles')}
        key="tab_photo"
        value="photo"
      />
    ),
    content: <RetrofitNamePlateFile {...props} />,
    order: 1,
  });

  tabData.push({
    name: 'text',
    tab: (
      <MuiTab
        label={translate('admin.pages.retrofitManage')}
        key="tab_text"
        value="text"
      />
    ),
    content: <RetrofitManageAdmin {...props} />,
    order: 2,
  });

  if (config.feature.adminBulkAction) {
    /* ↓ REACT_APP_FEATURE_ADMIN_BULK_ACTION */
    tabData.push({
      name: 'bulkAction',
      tab: (
        <MuiTab
          label={translate('admin.pages.retrofitManageAdminBulkAction')}
          key="tab_bulkAction"
          value="bulkAction"
        />
      ),
      content: <RetrofitManageAdminBulkActionList {...props} />,
      order: 3,
    });
    /* ↑ REACT_APP_FEATURE_ADMIN_BULK_ACTION */
  }

  return tabData.sort((lhs, rhs) => (lhs.order > rhs.order ? 1 : -1));
};

const RetrofitManageAdminTab: FC = props => {
  const uri = '/controllerRegistrationAdmin';
  const classes = useStyles();
  const { getValue, getBasePath } = useCustomLocation(uri);
  const redirectTo = useRedirect();
  const basePath = getBasePath();
  const handleChange = (_event: any, newValue: string) => {
    redirectTo(`${basePath}/${newValue}`);
  };

  const notify = useNotify();
  const { gotoList } = useJumpTo();

  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: {
      updateData: UpdateData;
      corporationName: string;
    };
  }>({ loading: true, fetched: false });
  const adminInitialApi = useAdminInitial();

  useEffect(() => {
    if (apiState.fetched) return;
    setApiState({ loading: true, fetched: true });
    // APIの呼び出し
    adminInitialApi()
      .then(({ data: { corporationId, corporationName } }) => {
        // 正常系
        const updateData: UpdateDataAdmin = {
          adminCorporations: [
            {
              corporationId,
              corporationName,
            },
          ],
        };
        setApiState({
          loading: false,
          fetched: true,
          data: {
            updateData,
            corporationName,
          },
        });
      })
      .catch(error => {
        // 異常系
        let message = '';
        let gotoTop = false;
        if (error instanceof PermissionError) {
          message = 'admin.message.retrofitsManage.invalidAccess';
          gotoTop = true;
        } else {
          message = apiErrorHandler(error);
        }
        notify(message);
        // 権限エラーのみTOP画面に戻る（その他のエラーは Loading 表示のままとなる）
        if (gotoTop) gotoList('sites');
      });
  }, [adminInitialApi, apiState.fetched, notify, gotoList]);

  const { loading, data } = apiState;

  const tabs = useCreateRetrofitManageAdminTabs(props);
  const [firstTab] = tabs;
  const currentTab = getValue() || firstTab.name;

  return (
    <SimpleShowLayout className={classes.simpleShowLayout} {...props}>
      <BreadcrumbsFiled
        className={classes.breadcrumbs}
        label="admin.breadcrumbs.retrofitManageAdmin"
      />
      <MuiTabs value={currentTab} onChange={handleChange}>
        {tabs.map(({ tab }) => tab)};
      </MuiTabs>
      <Divider />
      {tabs.map(({ name, content }) => {
        return (
          <TabContent name={name} key={name} value={currentTab}>
            {!loading && data ? (
              cloneElement(content, {
                updateData: data.updateData,
                corporationName: data.corporationName,
              })
            ) : (
              <Loading />
            )}
          </TabContent>
        );
      })}
    </SimpleShowLayout>
  );
};

RetrofitManageAdminTab.displayName = 'RetrofitManageAdminTab';
export default RetrofitManageAdminTab;
