import { RetrofitOwnerRegistCorporationData } from '../../types';

const retrofitOwnerRegistCorporations: RetrofitOwnerRegistCorporationData[] = [
  {
    id: 1,
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    corporationName: '株式会社クオリカ建築　テスト事業部',
  },
  {
    id: 2,
    corporationId: 'd087e444-ded2-de5e-087b-76f3ce864ae3',
    corporationName: 'LANDLOG',
  },
];

export default retrofitOwnerRegistCorporations;
