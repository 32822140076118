import { createStyles, makeStyles } from '@material-ui/core';
import { colors } from '../theme';

const useSpacerStyles = makeStyles(() =>
  createStyles({
    root: { marginLeft: 12 },
    button: {
      fontSize: 14,
      backgroundColor: colors.button.cancel,
      minWidth: 160,
      minHeight: 36,
      textTransform: 'none',
      paddingLeft: 0,
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
    },
  }),
);

export default useSpacerStyles;
