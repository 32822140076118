import { ReferenceManyField as RaReferenceManyField } from 'react-admin';
import { ReferenceFieldFC } from '.';

interface Props {
  filter?: object;
  perPage?: number;
  sort?: {
    field: string;
    order: string;
  };
}

const ReferenceManyField = RaReferenceManyField as ReferenceFieldFC<Props>;
ReferenceManyField.displayName = 'ReferenceManyField';

export default ReferenceManyField;
