import { Feature } from 'ol';
import {
  AlertMapController,
  Event,
  EventType,
  BindMapParams,
  GeofenceTypeDictionary,
} from './types';
import { AlertMapProps, MapProps } from '../../../../../hooks';
import {
  notNull,
  LonLat,
  Geometry,
  geographicToMercator,
  mapViewFitByFeature,
  createFeatureFromGeometry,
  createGeometryFromFeature,
  createAlertFeature,
} from '../../../../../utils';
import {
  addHomeControl,
  addZoomControl,
  addProjectSourceLayer,
  addProjectSourceLayerLabelPoint,
  addProjectSourceLayerPointLabel,
  addProjectSourceLocationPoint,
  addProjectSourceLocationLabel,
  addProjectSourceLineWork,
  removeProjectSourceLayer,
  addLineworkButtonControl,
} from '../../../geofences/map/ui';
import {
  createInteractor,
  setupInteraction,
  Interactor,
  PrimitiveType,
} from '../../../geofences/map/interaction';
import define from '../../../../organisms/taskmap/define'; // TODO: 共通化の課題
import {
  GeofenceAlertsData,
  GeofenceAlertType,
  UnitLength,
} from '../../../../../dataProvider';

const fireEvent = (events: Event[], type: EventType, args?: any) => {
  events.forEach((event: Event) => {
    event(type, args);
  });
};

const AlertMapControllerImpl = (): AlertMapController => {
  let mapProps: AlertMapProps;
  let bindEnd = false;
  let defaultBoundary: LonLat[] = [];
  let selectedAlertType: GeofenceAlertType;
  let siteUnitLength: UnitLength;
  let showLinework = true;

  const geofenceTypeList: GeofenceTypeDictionary = new Map<number, string>();
  const events: Event[] = [];
  const interactor: Interactor = createInteractor();

  const onChangeShowLinework = (
    { map }: MapProps | AlertMapProps,
    visible: boolean,
  ) => {
    map
      .getLayers()
      .getArray()
      .filter(
        layer =>
          layer.getClassName() === 'mapserverLineworkLayer' ||
          layer.getClassName() === 'mapserverLabelPointkLayer' ||
          layer.getClassName() === 'mapserverPointLabelkLayer' ||
          layer.getClassName() === 'mapserverLocationPointLayer' ||
          layer.getClassName() === 'mapserverLocationLabelLayer',
      )
      .forEach(layer => {
        layer.setVisible(visible);
      });
    showLinework = visible;
  };

  const self = {
    bindMap: ({ mapProps: props, featureId }: BindMapParams) => {
      mapProps = props;
      // インタラクション (MAP上での操作機能) を初期化する
      const interactions = setupInteraction(props, featureId, {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onDrawEnd: () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onModifyEnd: () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onTranslateEnd: () => {},
        onSelectEnd: (features: Feature[]) => {
          let selecteId: number | undefined;
          if (features.length > 0) {
            const getId: string | number | undefined = features[0].getId();
            selecteId = getId ? Number(getId) : undefined;
          }
          fireEvent(events, 'select', { id: selecteId });
        },
      });
      // 実際にインタラクションを操作するモジュールへインタラクションをバインドする
      interactor.setup(interactions, props.map);
      // ホーム位置を表示する
      self.navigateToHome();
      // オーバーレイUIを初期化する
      // 既にbind済みで呼ばれた場合は新たにControlを設定しない
      if (!bindEnd) {
        interactor.changeState('select');
        addZoomControl(props);
        addHomeControl(props, () => self.navigateToHome());
        addLineworkButtonControl(props, onChangeShowLinework);
      }
      bindEnd = true;
    },
    isBindMapEnd: (): boolean => bindEnd,
    getGeofenceTypeList: () => geofenceTypeList,
    navigateToHome: (duration: number | undefined = define.homeDuration) => {
      if (defaultBoundary.length <= 0) return;
      const feature = createFeatureFromGeometry({
        type: 'Polygon',
        id: 0,
        name: 'view',
        coordinates: defaultBoundary,
      });
      if (feature) {
        mapViewFitByFeature(mapProps.map, feature, 0, duration);
      }
    },
    navigateToCoordinate: ({ lon, lat }: LonLat) => {
      mapProps.view.setCenter(geographicToMercator([lon, lat]));
    },
    getMapProps: (): AlertMapProps => mapProps,
    getGeometries: (): Geometry[] => {
      const features = mapProps.sourceVector.getFeatures();
      if (features.length <= 0) {
        return [];
      }
      // Feature から各 Geometry に変換
      return features
        .map(feature => {
          const id = feature.getId() as number;
          const dictionaryType = geofenceTypeList.get(id);
          const targetType = dictionaryType;
          if (!targetType) return null;
          return createGeometryFromFeature(feature, targetType);
        })
        .filter(notNull);
    },
    setGeometries: (geometries: Geometry[]) => {
      if (mapProps) mapProps.sourceVector.clear();
      if (geometries.length <= 0) return;
      // Geometry から各 Feature に変換
      const features = geometries
        .map(geometry => createFeatureFromGeometry(geometry))
        .filter(notNull);
      // 地図に反映
      features.forEach(feature => mapProps.sourceVector.addFeature(feature));

      fireEvent(events, 'loaded');
    },
    setAlert: (alert?: GeofenceAlertsData) => {
      if (!alert) return;
      mapProps.sourceAlertVector.addFeature(createAlertFeature(alert));
      fireEvent(events, 'loaded');
    },
    setSelectFeature: (featureId: number) => {
      const features = mapProps.sourceVector.getFeatures();
      if (features.length <= 0) return;
      const filteredFeature = features.filter(it => it.getId() === featureId);
      if (filteredFeature.length <= 0) return;
      interactor.selectFeature(filteredFeature[0]);
    },
    setBoundary: (boundary: LonLat[]) => {
      defaultBoundary = boundary;
    },
    setAddOnEventEnd: (event: Event) => {
      events.push(event);
    },
    setPrimitiveType: (type: PrimitiveType) => {
      interactor.setPrimitiveType(type);
    },
    getSelectedAlertType: (): GeofenceAlertType => {
      return selectedAlertType;
    },
    setSelectedAlertype: (alertType: GeofenceAlertType) => {
      selectedAlertType = alertType;
    },
    getSiteUnitLength: (): UnitLength => {
      return siteUnitLength;
    },
    setSiteUnitLength: (unitLength: UnitLength) => {
      siteUnitLength = unitLength;
    },
    setProjectSourceLayer: (id: number, versionId: number, token: string) => {
      addProjectSourceLayer(mapProps, id, versionId, token, true);
    },
    setProjectSourceLayerLabelPoint: (versionId: number, token: string) => {
      addProjectSourceLayerLabelPoint(mapProps, versionId, token, showLinework);
    },
    setProjectSourceLayerPointLabel: (versionId: number, token: string) => {
      addProjectSourceLayerPointLabel(mapProps, versionId, token, showLinework);
    },
    setProjectSourceLocationPoint: (versionId: number, token: string) => {
      addProjectSourceLocationPoint(mapProps, versionId, token, showLinework);
    },
    setProjectSourceLocationLabel: (versionId: number, token: string) => {
      addProjectSourceLocationLabel(mapProps, versionId, token, showLinework);
    },
    setProjectSourceLineWork: (
      id: number,
      versionId: number,
      token: string,
    ) => {
      addProjectSourceLineWork(mapProps, id, versionId, token, showLinework);
    },
    removeProjectSourceLayer: () => removeProjectSourceLayer(mapProps),
  };
  return self;
};

export default AlertMapControllerImpl;
