import React from 'react';
import { ReactAdminComponentProps } from 'react-admin';
import { ResourceFieldFC } from '../../bases';
import { RemoteSupportButton } from '..';
import { GetPanelStatusApiData } from '../../../dataProvider';

interface SytleProps {
  classes?: object;
}

type RemoteSupportFieldProps = React.PropsWithChildren<
  Partial<
    ReactAdminComponentProps & {
      record: GetPanelStatusApiData;
      resource: string;
      label: string;
    } & SytleProps
  >
>;

const FC: React.FC<RemoteSupportFieldProps> = (
  props: RemoteSupportFieldProps,
) => {
  const { record } = props;
  // recordがundefinedの場合は、null返却
  if (record === undefined) return null;

  if (record.remoteStatus === 'WaitingToStart') {
    return <RemoteSupportButton {...props} />;
  }
  return null;
};
const RemoteSupportField = FC as ResourceFieldFC<SytleProps>;
export default RemoteSupportField;
