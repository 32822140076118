import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  required,
  email as emailValidation,
  Loading,
  useCreate,
  useNotify,
  useRedirect,
  useDataProvider,
  useTranslate,
  FormDataConsumer,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import {
  CustomForm,
  BreadcrumbsFiled,
  TextField,
  Create,
  TextInput,
  CustomSaveButton,
  DatePickerInput,
  FunctionField,
  DoneButton,
  InfoDialogButton,
  RadioButtonGroupInput,
  CustomerAutoCompleteInput,
  LendCorporationData,
  DialogOKOnly,
} from '../../..';
import {
  useResource,
  useCreate as useCreateHook,
  useGetSelefDetailData,
  useAccount,
} from '../../../../hooks';
import { Record } from '../../../bases';
import {
  RetrofitRentalData,
  CustomerData,
} from '../../../../dataProvider/types';
import { dateStringToLocaleDate, apiErrorHandler } from '../../../../utils';
import { SaveIcon } from '../../../../assets';
import useTransferred from '../transfers/hooks/useTransferred';
import useAlternateRegisted from '../transfers/hooks/useAlternateRegisted';

const useStyles = makeStyles(() =>
  createStyles({
    lendingDestination: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
    },
    selectRadio: {
      gridRow: '1 / 3',
      gridColumn: '1 / 2',
    },
    selectToCorporation: {
      gridRow: '1 / 2',
      gridColumn: '2 / 3',
      '& .MuiFormLabel-root': {
        display: 'none',
      },
      '& .MuiInputBase-input': {
        width: 500,
      },
    },
    inputEmail: {
      gridRow: '2 / 3',
      gridColumn: '2 / 3',
    },
    emailHideLabel: {
      '& > .MuiFormLabel-root': {
        display: 'none', // 子コンポーネントのラベル領域を消すため
      },
    },
  }),
);

const SaveButton: React.FC<{
  basePath?: string;
  disabled?: boolean;
  siteId: string;
  retrofitId: number;
  customer: LendCorporationData | null; // 貸与先企業を選択した場合は設定される
}> = ({ basePath, disabled, siteId, retrofitId, customer, ...props }) => {
  const [create] = useCreate('retrofitRentals');
  const redirectTo = useRedirect();
  const getAccount = useAccount();
  const getTransferred = useTransferred();
  const getAlternateRegisted = useAlternateRegisted();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    message?: string;
  }>({ open: false });

  const toCorporationId = customer ? customer.id : 'unknown';
  const toCorporationName = customer ? customer.name : 'unknown';

  const handleSave = useCallback(
    (values, redirect) => {
      // 建機が貸与登録可能か判定
      const api = async () => {
        const {
          data: { corporationId },
        } = await getAccount();

        // 貸与先企業がログイン中企業チェック(企業名での登録時のみ)
        const { data: retrofitInfo } = await dataProvider.getOne('retrofits', {
          id: retrofitId,
        });
        const isSameOwnerId = toCorporationId === retrofitInfo.ownerId;
        const isTransferred = await getTransferred(retrofitId);
        const isAlternateRegisted = await getAlternateRegisted(
          retrofitId,
          corporationId,
        );
        return { isSameOwnerId, isTransferred, isAlternateRegisted };
      };
      api().then(result => {
        // 貸与先企業がレトロフィットの所有企業である場合
        if (result.isSameOwnerId) {
          setDialogState({
            open: true,
            message: 'admin.dialog.retrofitRentalCreate.sameOwner.message',
          });
          return;
        }
        // 建機が譲渡中の場合
        if (result.isTransferred) {
          setDialogState({
            open: true,
            message: 'admin.dialog.retrofitRentalCreate.unableTransfer.message',
          });
          return;
        }
        // 建機が代理申請中の場合
        if (result.isAlternateRegisted) {
          setDialogState({
            open: true,
            message:
              'admin.dialog.retrofitRentalCreate.unableAlternateRegist.message',
          });
          return;
        }
        create(
          {
            payload: {
              data: {
                ...values,
                toCorporationId,
                toCorporationName,
              },
            },
          },
          {
            onSuccess: ({ data: newRecord }: any) => {
              notify('admin.message.success', 'info');
              redirectTo(redirect, basePath, newRecord.id, newRecord);
            },
            onFailure: (error: Error) => {
              notify(apiErrorHandler(error), 'warning');
            },
          },
        );
      });
    },
    [
      dataProvider,
      getAccount,
      getTransferred,
      getAlternateRegisted,
      retrofitId,
      create,
      notify,
      redirectTo,
      basePath,
      toCorporationId,
      toCorporationName,
    ],
  );
  return siteId ? (
    <InfoDialogButton
      title=""
      messages={[
        'admin.dialog.retrofitRentalCreate.unableTieUpJobSite.message1',
        'admin.dialog.retrofitRentalCreate.unableTieUpJobSite.message2',
      ]}
      button={
        <DoneButton disabled={disabled} label="ra.action.save">
          <SaveIcon />
        </DoneButton>
      }
    />
  ) : (
    <>
      <DialogOKOnly
        open={dialogState.open}
        onClose={() => setDialogState({ open: false })}
        title=""
        message={dialogState?.message}
      />
      <CustomSaveButton {...props} disabled={disabled} onSave={handleSave}>
        <SaveIcon />
      </CustomSaveButton>
    </>
  );
};

const RetrofitRentalCreate: React.FC = () => {
  const classes = useStyles();
  const resource = useResource('retrofitRentals');
  const retrofits = useResource('retrofits');
  const dataProvider = useDataProvider();
  const { retrofitId: strRetrofitId } = useParams<{ retrofitId: string }>();
  const retrofitId = Number(strRetrofitId);
  const { props, data } = useCreateHook(resource, {
    resource: retrofits,
    key: 'retrofitId',
  });
  const getAccount = useAccount();
  const translate = useTranslate();
  const getSelefDetailData = useGetSelefDetailData();
  // const redirectTo = useRedirect();

  const [customer, setCustomer] = useState<LendCorporationData | null>(null);

  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: {
      fetchedRetrofitId: number;
      isOwnData: boolean;
      corporationId: string;
      corporationName: string;
      retrofitRentalId: number | undefined;
    };
  }>({ loading: true, fetched: false });

  useEffect(() => {
    if (
      !data ||
      (apiState.fetched && apiState.data?.fetchedRetrofitId === retrofitId)
    )
      return;

    const api = async () => {
      // accounts
      const {
        data: { corporationId, corporationName },
      } = await getAccount();

      // 自社のretrofitsか判定
      const filter = {
        ownerCorporationId: corporationId,
        adminCorporationId: corporationId,
      };

      const isOwnRetrofits = await getSelefDetailData(
        'retrofits',
        filter,
        retrofitId,
      );

      const { data: rentalData } = await dataProvider.getList(resource, {
        pagination: {
          page: 1,
          perPage: 1, // 1件しか取る必要がない
        },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          retrofitId: data.id,
          toCorporationId: corporationId,
          isReturned: false,
        },
      });
      let parentId;
      if (rentalData.length > 0) {
        parentId = Number(rentalData[0].id);
      }

      return {
        fetchedRetrofitId: retrofitId,
        isOwnData: !!isOwnRetrofits,
        corporationId,
        corporationName,
        retrofitRentalId: parentId,
      };
    };
    api().then(result => {
      setApiState({
        loading: false,
        fetched: true,
        data: result,
      });
    });
  }, [
    data,
    getAccount,
    dataProvider,
    resource,
    apiState.data,
    apiState.fetched,
    getSelefDetailData,
    retrofitId,
  ]);

  if (!data || apiState.loading || !apiState.data) {
    return <Loading />;
  }
  // TODO: 閲覧不可なデータは現場一覧に遷移する機能を有効にする場合、isOwnDataを追加する
  const { corporationId, corporationName, retrofitRentalId } = apiState.data;
  // if (!isOwnData) redirectTo('/');

  const [record] = [data as Record<typeof retrofits>];

  // TODO: 共通化したい
  const dateValidation = (unusedValue: any, allValues: RetrofitRentalData) => {
    const availableDate = '2020/01/01';
    if (!allValues.planStartDate) {
      return null;
    }
    if (
      dateStringToLocaleDate(allValues.planStartDate) <
      dateStringToLocaleDate(availableDate)
    ) {
      return {
        message: 'admin.validation.inputAvailableDate',
        args: {
          date: new Date(availableDate).toLocaleDateString(),
        },
      };
    }
    if (!allValues.planEndDate) {
      return null;
    }

    if (
      dateStringToLocaleDate(allValues.planStartDate) <=
      dateStringToLocaleDate(allValues.planEndDate)
    ) {
      return null;
    }
    return 'admin.validation.incorrectPlanStartDate';
  };
  const toLendingValidation = (
    _: any,
    { email, toCorporationId, lendingDestination }: any,
    { name }: any,
  ) => {
    const isRequired = required();
    const isEmail = emailValidation();
    // メールアドレスに全角文字がないかチェック
    const isHalfWidth = (value: any, message = 'ra.validation.email') =>
      typeof value === 'string' &&
      // eslint-disable-next-line no-control-regex
      /([^\x01-\x7E\uFF65-\uFF9F]|[ｦ-ﾟ])+/.test(value)
        ? message
        : undefined;

    switch (name) {
      case 'toCorporationId': {
        if (lendingDestination !== 'lendingCompany') return null;
        return isRequired(toCorporationId, null);
      }

      case 'email': {
        if (lendingDestination !== 'lendingEmail') return null;
        const error = isRequired(email, null);
        if (error) return error;
        const errorEmail = isEmail(email, null);
        if (errorEmail) return errorEmail;
        return isHalfWidth(email);
      }

      default:
        return null;
    }
  };
  return (
    <Create {...props}>
      <CustomForm
        title="admin.pages.retrofitRentalCreate"
        deleteButton={false}
        saveButton={
          <SaveButton
            siteId={record.siteId === undefined ? '' : record.siteId}
            retrofitId={record.id}
            customer={customer}
          />
        }
        initialValues={
          {
            retrofitId: record.id,
            fromCorporationId: corporationId,
            fromCorporationName: corporationName,
            email: '',
            toCorporationId: '',
            toCorporationName: '',
            retrofitRentalId,
            isApproved: false,
            isReturned: false,
            actualStartDate: null,
            actualEndDate: null,
            rentalUsers: [],
            lendingDestination: 'lendingCompany', // ラジオボタン初期値
          } as any
        }
      >
        <BreadcrumbsFiled
          breadcrumbs={[
            'resources.retrofits.name',
            'admin.breadcrumbs.retrofitEdit',
            'resources.retrofits.fields.rental',
          ]}
          label="admin.pages.retrofitRentalCreate"
        />

        <FunctionField
          resource={retrofits}
          label="resources.retrofits.fields.machineInfoMachineType"
          record={record}
          render={() => {
            const res = 'resources.retrofits.machineInfoMachineType';
            const type = record?.machineInfoMachineType.toLowerCase();
            const trans = translate(`${res}.${type}`);
            // 翻訳できなければ、もとの内容をそのまま返す
            if (trans.startsWith(res)) return type;
            return trans;
          }}
        />
        <TextField
          resource={retrofits}
          record={record}
          source="machineInfoMachineId"
          label="resources.retrofits.fields.machineInfoMachineId"
        />
        <TextField
          resource={retrofits}
          record={record}
          source="machineInfoMachineName"
          label="resources.retrofits.fields.machineInfoMachineName"
        />
        <TextField
          resource={retrofits}
          record={record}
          source="machineInfoCompanyName"
          label="resources.retrofits.fields.machineInfoCompanyName"
        />

        <Box className={classes.lendingDestination}>
          <Box className={classes.selectRadio}>
            <RadioButtonGroupInput
              {...props}
              resource={resource}
              source="lendingDestination"
              label="admin.label.retrofitRental.lendingDestination"
              row={false}
              validate={[required()]}
              choices={[
                {
                  id: 'lendingCompany',
                  name: translate('admin.label.retrofitRental.lendingCompany'),
                },
                {
                  id: 'lendingEmail',
                  name: translate('admin.label.retrofitRental.lendingEmail'),
                },
              ]}
            />
          </Box>
          <Box className={classes.selectToCorporation}>
            <FormDataConsumer>
              {({ formData }) => {
                let corporationEnabled = false;
                if (formData.lendingDestination === 'lendingCompany') {
                  corporationEnabled = true;
                  const workFormData = formData;
                  workFormData.email = '';
                }
                return (
                  <CustomerAutoCompleteInput
                    label={<></>}
                    resource="retrofitRentals"
                    source="toCorporationId"
                    validate={[toLendingValidation]}
                    disabled={!corporationEnabled}
                    fromCorporationId={corporationId}
                    defaultValue={customer}
                    onNotifyChange={(value: CustomerData) => setCustomer(value)}
                  />
                );
              }}
            </FormDataConsumer>
          </Box>
          <Box className={classes.inputEmail}>
            <FormDataConsumer>
              {({ formData }) => {
                let emailEnabled = false;
                if (formData.lendingDestination === 'lendingEmail') {
                  emailEnabled = true;
                  const workFormData = formData;
                  workFormData.toCorporationId = '';
                  setCustomer(null);
                }
                return (
                  <TextInput
                    label=""
                    data-testid="email"
                    resource="retrofitRentals"
                    source="email"
                    validate={[toLendingValidation]}
                    disabled={!emailEnabled}
                    className={classes.emailHideLabel}
                  />
                );
              }}
            </FormDataConsumer>
          </Box>
        </Box>

        <DatePickerInput
          resource="retrofitRentals"
          source="planStartDate"
          validate={[dateValidation]}
        />
        <DatePickerInput
          resource="retrofitRentals"
          source="planEndDate"
          validate={[]}
        />
      </CustomForm>
    </Create>
  );
};

RetrofitRentalCreate.displayName = 'RetrofitRentalCreate';
export default RetrofitRentalCreate;
