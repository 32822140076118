import React, { FC, useCallback } from 'react';
import { useDataProvider, useNotify, useRedirect } from 'react-admin';
import DoneButton from './DoneButton';
import { Record } from '../..';
import { SaveIcon } from '../../../assets';
import { apiErrorHandler } from '../../../utils/error';

interface Props {
  resource: string;
  record?: Record<any>;
  disabled?: boolean;
  redirect?: string;
  form?: any;
  transform?: (record: Record<any>) => Record<any>;
}

const UpdateButton: FC<Props> = ({
  resource,
  record,
  disabled,
  form,
  redirect = '',
  transform,
}) => {
  if (!record) throw Error('Invalid record');
  if (!form) throw Error('Invalid form');

  const notify = useNotify();
  const redirectTo = useRedirect();
  const dataProvider = useDataProvider();

  const handleClick = useCallback(() => {
    // 変更差分を取得
    const modifiedFields = form
      .getRegisteredFields()
      .map((name: string) => form.getFieldState(name))
      .filter((field: any) => field.modified)
      .map((field: any) => ({
        [field.name]: field.value,
      })) as { [key: string]: any }[];
    // 前回値のレコードとマージ
    let data = modifiedFields.reduce((pre, cur) => ({ ...pre, ...cur }), {
      ...record,
    });
    if (transform) {
      data = transform(data);
    }
    // 対象リソースの更新処理
    dataProvider
      .update(resource, {
        id: record.id,
        data,
        previousData: record,
      })
      .then(() => {
        notify('admin.message.success', 'info');
        redirectTo(redirect);
      })
      .catch(error => {
        notify(apiErrorHandler(error), 'warning');
      });
  }, [
    dataProvider,
    notify,
    redirectTo,
    record,
    form,
    resource,
    redirect,
    transform,
  ]);

  return (
    <DoneButton
      disabled={disabled}
      onClick={handleClick}
      label="ra.action.save"
    >
      <SaveIcon />
    </DoneButton>
  );
};

UpdateButton.displayName = 'UpdateButton';
export default UpdateButton;
