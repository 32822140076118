import { TaskData } from '../../types';

const tasks: TaskData[] = [
  {
    id: 1,
    name: 'initial_polygon',
    description:
      'aaaaaaaaaaaaaabbbbbbbbbbbbbccccccccccccddddddddddddddddeeeeeeeeeeeffffffffffgggggggg',
    corporationId: 'test',
    siteId: 'test',
    projectId: 1,
    projectVersionId: 11,
    projectSourceLayerId: 2,
    rectangle: {
      type: 'Polygon',
      coordinates: [
        [
          [14008.076160401486, 3560.2744140580185],
          [14008.076160401486, 3559.9138531678564],
          [14008.99065118321, 3559.913853167858],
          [14008.9594369758, 3560.2736575820863],
          [14008.076160401486, 3560.2744140580185],
        ],
      ],
    },
    status: 'Sending',
    retrofitIds: [3],
    expiredDate: '2020-08-15T00:00:00.000Z',
    errorInfo: ['SYSTEM_BATCH_ERROR'],
    lastUpdated: '2020-09-16T01:01:01.000Z',
  },
  {
    id: 1000,
    name: '一直線',
    description: '',
    corporationId: 'test',
    siteId: 'test',
    projectId: 1,
    projectVersionId: 11,
    projectSourceLayerId: 2,
    rectangle: {
      type: 'Polygon',
      coordinates: [
        [
          [14008, 3560],
          [14008, 3559],
          [14008, 3557],
          [14008, 3560],
        ],
      ],
    },
    status: 'Sending',
    retrofitIds: [],
    expiredDate: '2020-08-15T00:00:00.000Z',
    errorInfo: [],
    lastUpdated: '2020-09-16T01:01:01.000Z',
  },
  {
    id: 11,
    name: 'converting',
    description: 'aaaa',
    corporationId: 'test',
    siteId: 'test',
    projectId: 1,
    projectVersionId: 11,
    projectSourceLayerId: 0,
    rectangle: {
      type: 'Polygon',
      coordinates: [],
    },
    status: 'Converting',
    retrofitIds: [],
    expiredDate: '2020-08-15T00:00:00.000Z',
    errorInfo: [],
    lastUpdated: '2020-09-16T01:01:01.000Z',
  },
  {
    id: 12,
    name: 'Sending',
    description: 'aaaa',
    corporationId: 'test',
    siteId: 'test',
    projectId: 1,
    projectVersionId: 11,
    projectSourceLayerId: 0,
    rectangle: {
      type: 'Polygon',
      coordinates: [],
    },
    status: 'Sending',
    retrofitIds: [],
    expiredDate: '2020-08-15T00:00:00.000Z',
    errorInfo: [],
    lastUpdated: '2020-09-16T01:01:01.000Z',
  },
  {
    id: 13,
    name: 'Sent',
    description: 'ttt_zipdl',
    corporationId: 'test',
    siteId: 'test',
    projectId: 1,
    projectVersionId: 11,
    projectSourceLayerId: 0,
    rectangle: {
      type: 'Polygon',
      coordinates: [],
    },
    status: 'Sent',
    retrofitIds: [],
    expiredDate: '2020-08-15T00:00:00.000Z',
    errorInfo: [],
    projectZipFileId: '99a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    projectZipFile: {
      src: 'http//www.3dmg.test/ttt.zip',
      title: 'testtaskfile.zip',
    },
    lastUpdated: '2020-09-16T01:01:01.000Z',
  },
  {
    id: 14,
    name: 'FailToSend',
    description: 'aaaa',
    corporationId: 'test',
    siteId: 'test',
    projectId: 1,
    projectVersionId: 11,
    projectSourceLayerId: 0,
    rectangle: {
      type: 'Polygon',
      coordinates: [
        [
          [22418.174160890452, -44045.064866550514],
          [22419.17975857745, -44445.07422502696],
          [23247.790690684807, -44442.95274836731],
          [23218.46849575469, -44043.85622428898],
          [22418.174160890452, -44045.064866550514],
        ],
      ],
    },
    status: 'FailToSend',
    retrofitIds: [],
    expiredDate: '2020-08-15T00:00:00.000Z',
    errorInfo: [],
    lastUpdated: '2020-09-16T01:01:01.000Z',
  },
  {
    id: 15,
    name: 'Error',
    description: 'Deleteable',
    corporationId: 'test',
    siteId: 'test',
    projectId: 1,
    projectVersionId: 11,
    projectSourceLayerId: 0,
    rectangle: {
      type: 'Polygon',
      coordinates: [],
    },
    status: 'Error',
    retrofitIds: [],
    expiredDate: '2020-08-15T00:00:00.000Z',
    errorInfo: ['SYSTEM_PARAMETER_ERROR'],
    lastUpdated: '2020-09-16T01:01:01.000Z',
  },
  {
    id: 2,
    name: 'タスク1.2-1',
    description: 'hoge',
    corporationId: 'test',
    siteId: 'test',
    projectId: 12,
    projectVersionId: 21,
    projectSourceLayerId: 0,
    rectangle: {
      type: 'Polygon',
      coordinates: [],
    },
    status: 'Sent',
    retrofitIds: [],
    expiredDate: '2020-08-12T00:00:00.000Z',
    errorInfo: [],
    lastUpdated: '2020-09-16T01:01:01.000Z',
  },
  {
    id: 3,
    name: 'タスク1.2-2',
    description: 'huga',
    corporationId: 'test',
    siteId: 'test',
    projectId: 12,
    projectVersionId: 22,
    projectSourceLayerId: 0,
    rectangle: {
      type: 'Polygon',
      coordinates: [],
    },
    status: 'FailToSend',
    retrofitIds: [],
    expiredDate: '2020-08-13T00:00:00.000Z',
    errorInfo: [],
    lastUpdated: '2020-09-16T01:01:01.000Z',
  },
  {
    id: 10,
    name: '品川駅周辺タスク01',
    description:
      'aaaaaaaaaaaaaabbbbbbbbbbbbbccccccccccccddddddddddddddddeeeeeeeeeeeffffffffffgggggggg',
    corporationId: 'test',
    siteId: 'test',
    projectId: 10,
    projectVersionId: 20,
    projectSourceLayerId: 0,
    rectangle: {
      type: 'Polygon',
      coordinates: [
        [
          [22418.174160890452, -44045.064866550514],
          [22419.17975857745, -44445.07422502696],
          [23247.790690684807, -44442.95274836731],
          [22418.174160890452, -44045.064866550514],
        ],
      ],
    },
    status: 'Error',
    retrofitIds: [],
    expiredDate: '2020-08-15T00:00:00.000Z',
    errorInfo: [],
    lastUpdated: '2020-09-16T01:01:01.000Z',
  },
  {
    id: 100,
    name: 'TASK',
    description: 'hoge',
    corporationId: 'test',
    siteId: 'test',
    projectId: 13,
    projectVersionId: 23,
    projectSourceLayerId: 0,
    rectangle: {
      type: 'Polygon',
      coordinates: [],
    },
    status: 'Error',
    retrofitIds: [],
    expiredDate: '2020-08-12T00:00:00.000Z',
    errorInfo: [
      'SYSTEM_BATCH_ERROR',
      'SYSTEM_PARAMETER_ERROR',
      'SYSTEM_PARAMETERFILE_NOTFOUND',
      'SYSTEM_EXCEPTION',
      'SYSTEM_LOGFILE_ERROR',
      'SYSTEM_BASEFILE_NOTFOUND',
      'SYSTEM_PRJFILE_MULTI',
      'SYSTEM_BASEFILE_READ_ERROR',
      'SYSTEM_TARGET_LAYER_NOTFOUND',
      'SYSTEM_TARGET_LAYER_DUPLICATION',
      'SYSTEM_TASKAREA_ERROR',
      'SYSTEM_TASKAREA_DESIGN_NOTFOUND',
      'SYSTEM_TILE_ERROR',
      'SYSTEM_OUTLINE_ERROR',
    ],
    lastUpdated: '2020-09-16T01:01:01.000Z',
  },
  {
    id: 1001,
    name: 'Error',
    description: 'Deleteable',
    corporationId: 'test',
    siteId: 'test',
    projectId: 11,
    projectVersionId: 21,
    projectSourceLayerId: 0,
    rectangle: {
      type: 'Polygon',
      coordinates: [],
    },
    status: 'Error',
    retrofitIds: [],
    expiredDate: '2020-08-15T00:00:00.000Z',
    errorInfo: ['SYSTEM_PARAMETERFILE_NOTFOUND'],
    lastUpdated: '2020-09-16T01:01:01.000Z',
  },
];
export default tasks;
