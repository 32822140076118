import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Identifier,
  useTranslate,
  required,
  useDataProvider,
  email as emailValidation,
} from 'react-admin';
import { Dialog, makeStyles, createStyles, Box } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {
  NormalButton,
  CustomForm,
  TextInput,
  Divider,
  FunctionField,
  FinalFormConsumer,
  PropsConsumer,
  DialogOKOnly,
  CustomerAutoCompleteInput,
  LendCorporationData,
} from '../../..';
import { ReturnIcon } from '../../../../assets';
import BulkRegistrationSaveButton from './BulkRegistrationSaveButton';
import { useGetAccount, useResource } from '../../../../hooks';
import { CorporationData, CustomerData } from '../../../../dataProvider/types';

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      '& .MuiBox-root': {
        height: 54,
      },
      '& .selectCount': {
        fontSize: 18,
        marginTop: 12,
      },
      '& .serialNumber': {
        fontSize: 14,
      },
      '& .dividerTop': {
        marginTop: 16,
      },
    },
    checkBox: {
      marginTop: 14,
      marginRight: 14,
    },
    indent: {
      width: 38,
    },
  }),
);

const SelectedCounter: FC<{ count: number }> = ({ count }) => {
  const translate = useTranslate();
  return (
    <div className="selectCount">
      {translate('admin.label.selected', {
        length: count,
      })}
    </div>
  );
};

interface Props {
  open: boolean;
  onClose: () => void;
  ids: Identifier[];
  serialNumbers: string[];
  lendingSerialNumberText: string;
}

export interface FormState {
  disableEmail: boolean;
  disableCorporation: boolean;
  checkCount: number;
}

export const initialFormState: FormState = {
  disableEmail: true,
  disableCorporation: true,
  checkCount: 0,
};

const BulkRegistrationDialog: FC<Props> = ({
  open,
  onClose,
  ids,
  serialNumbers,
  lendingSerialNumberText,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const corporations = useResource('corporations');
  const [customer, setCustomer] = useState<LendCorporationData | null>(null);
  const resource = useResource('retrofitAlternateRegists');
  const dataProvider = useDataProvider();
  const { getAccount } = useGetAccount();
  const accountData = getAccount();
  const accountDataCorporationId = accountData?.corporationId || '';
  const [fetched, setFetched] = useState(false);
  const [corporationId, setCorporationId] = useState('');
  const [disabled, setDisabled] = useState(true);
  const dialogWidth = 650;
  const dialogHight = 400;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (accountDataCorporationId === '' || fetched) return;
    dataProvider
      .getOne(corporations, { id: accountDataCorporationId })
      .then((result: any) => {
        if (!result) return;
        const corporationData = result.data as CorporationData;
        setCorporationId(corporationData?.id);
        setFetched(true);
      });
  }, [dataProvider, corporations, accountDataCorporationId, fetched]);

  const handleDialogEnter = useCallback(() => {
    const dialogElement = ref?.current?.parentElement;
    if (!dialogElement) return;
    dialogElement.style.minWidth = `${dialogWidth}px`;
    dialogElement.style.minHeight = `${dialogHight}px`;
  }, [ref]);

  // メールアドレスに全角文字がないかチェック
  const halfWidthValidation =
    (message = 'ra.validation.email') =>
    (value: any) =>
      typeof value === 'string' &&
      // eslint-disable-next-line no-control-regex
      /([^\x01-\x7E\uFF65-\uFF9F]|[ｦ-ﾟ])+/.test(value)
        ? message
        : undefined;

  // メールアドレス欄が空の場合は保存ボタンを非活性化する

  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    const checkEmail =
      // eslint-disable-next-line no-useless-escape
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value,
      );

    // eslint-disable-next-line no-control-regex
    const checkHalfWidth = /([^\x01-\x7E\uFF65-\uFF9F]|[ｦ-ﾟ])+/.test(value);

    if (!value || checkEmail || checkHalfWidth) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  return (
    <>
      <DialogOKOnly
        open={errorDialogOpen}
        onClose={() => setErrorDialogOpen(false)}
        title=""
        nonTranslateMessage={true}
        messages={[
          translate('admin.dialog.retrofitBasicInfoList.save.message1'),
          translate('admin.dialog.retrofitBasicInfoList.save.message2'),
          translate('admin.dialog.retrofitBasicInfoList.save.message3'),
          translate('admin.dialog.retrofitBasicInfoList.save.message4'),
          lendingSerialNumberText,
        ]}
      />
      <Dialog
        open={open}
        onClose={onClose}
        data-testid="bulk_registration_dialog"
        onEnter={handleDialogEnter}
      >
        {open ? (
          <div ref={ref}>
            <MuiDialogTitle>
              {translate(
                'admin.label.retrofitBasicInfos.bulkRegistrationTitle',
              )}
            </MuiDialogTitle>
            <MuiDialogContent className={classes.dialog}>
              <CustomForm
                bottomToolbar={true}
                cancelButton={
                  <NormalButton label="admin.actions.cancel" onClick={onClose}>
                    <ReturnIcon />
                  </NormalButton>
                }
                saveButton={
                  <PropsConsumer>
                    {({ form }) => (
                      <BulkRegistrationSaveButton
                        ids={ids}
                        form={form}
                        customer={customer}
                        disabled={disabled}
                      />
                    )}
                  </PropsConsumer>
                }
              >
                <FinalFormConsumer>
                  {() => (
                    <>
                      {/* 企業名 */}
                      <Box display="flex">
                        <CustomerAutoCompleteInput
                          data-testid="toCorporationName"
                          label="resources.retrofitAlternateRegists.fields.toCorporationName"
                          resource={resource}
                          source="toCorporationId"
                          fromCorporationId={corporationId}
                          onNotifyChange={(value: CustomerData) =>
                            setCustomer(value)
                          }
                        />
                      </Box>
                      {/* Email */}
                      <Box display="flex">
                        <TextInput
                          data-testid="email"
                          label="resources.retrofitAlternateRegists.fields.email"
                          resource={resource}
                          source="toMailAddress"
                          validate={[
                            required(),
                            emailValidation(),
                            halfWidthValidation(),
                          ]}
                          onChange={handleChange}
                        />
                      </Box>
                    </>
                  )}
                </FinalFormConsumer>
                <Divider className="dividerTop" />
                <Divider />
                <SelectedCounter count={ids.length} />
                {serialNumbers.map((serialNumber: string, index: number) => {
                  const labelResources =
                    'resources.retrofits.fields.basicInfoSerialNumber';
                  return (
                    <FunctionField
                      key={index}
                      label={index === 0 ? labelResources : ' '}
                      record={{} as any} // NOTE: レコードは使用しないけど、Renderするのに何かしら値を設定しないといけない
                      render={() => {
                        return (
                          <div className="serialNumber">{serialNumber}</div>
                        );
                      }}
                    />
                  );
                })}
              </CustomForm>
            </MuiDialogContent>
          </div>
        ) : (
          false
        )}
      </Dialog>
    </>
  );
};

BulkRegistrationDialog.displayName = 'BulkRegistrationDialog';
export default BulkRegistrationDialog;
