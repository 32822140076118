import { LicenseAgreementData } from '../../types';

const data: LicenseAgreementData[] = [
  {
    id: 1,
    email: 'test_user@example.com',
    agreement: false,
    termsConditionId: 1,
  },
];
export default data;
