import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, TextField, createStyles, makeStyles } from '@material-ui/core';
import { FieldTitle, InputHelperText, InputProps, useInput } from 'react-admin';
import { throttle } from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import { getDataProvider, SiteData } from '../../dataProvider';

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      '& > div.MuiFilledInput-root': {
        paddingTop: 'unset',
        paddingLeft: 'unset',
      },
    },
    textFieldWithValidate: {
      '& > div.MuiFilledInput-root': {
        paddingTop: 'unset',
        paddingLeft: 'unset',
        width: 1000,
      },
    },
  }),
);

interface Props {
  handleChange?: (value: SiteData, form: any) => void; // multiple モード時は呼ばれないので注意（未サポート）
  form?: any;
  filter?: { [key: string]: string | number };
  handleOption?: (text: string) => void;
  disabled: boolean;
  keepValidateSpace?: boolean;
}

const SiteAutoCompleteInput: FC<InputProps<Props>> = ({
  filter: argFilter = {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleOption = () => {},
  ...props
}) => {
  const {
    input: { onChange, multiple, value: siteId, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const {
    disabled,
    helperText,
    label,
    margin = 'dense',
    resource,
    source,
    variant = 'filled',
    handleChange,
    form,
    keepValidateSpace = false,
  } = props;
  const classes = useStyles();
  const [autocompleteValue, setAutocompleteValue] = useState<
    SiteData | SiteData[] | null
  >(multiple ? [] : null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<SiteData[]>([]);
  const jsonArgFilter = JSON.stringify(argFilter); // useMemo の deps に渡す為、文字列にしている
  const dataProvider = getDataProvider();

  const fetch = useCallback(
    throttle(async (search: string) => {
      const filter: { [key: string]: string | number } =
        search !== ''
          ? {
              ...JSON.parse(jsonArgFilter),
              q: search,
            }
          : {
              ...JSON.parse(jsonArgFilter),
            };

      const result = await dataProvider.getList<SiteData>('sites', {
        filter,
        pagination: { page: 1, perPage: 9 },
        sort: { field: 'id', order: 'ASC' },
      });

      if (!result) return;
      setOptions(result.data);
    }, 1_000),
    [jsonArgFilter],
  );

  useEffect(() => {
    fetch(inputValue);
  }, [inputValue, fetch]);

  const jsonAutocompleteValue = JSON.stringify(autocompleteValue); // useEffect の deps に渡す為、文字列にしている

  // 現場取得API
  const fetchSite = useMemo(() => {
    return async (id: string): Promise<SiteData | undefined> => {
      if (id) {
        const result = await dataProvider.getOneAllowedError<SiteData>(
          'sites',
          {
            id,
          },
        );

        if (!result) throw Error(`No Results: 'sites' id=${id}`);
        const { data } = result;

        return data;
      }
      return undefined;
    };
  }, [dataProvider]);

  useEffect(() => {
    const site = JSON.parse(jsonAutocompleteValue) as SiteData;
    if (site && site.id === siteId) {
      return; // 既に取得済み
    }
    fetchSite(siteId)
      .then(data => {
        if (data) {
          setAutocompleteValue({
            id: data.id,
            name: data.name,
            corporationId: data.corporationId,
            corporationName: data.corporationName,
            latitude: data.latitude,
            longitude: data.longitude,
            workPeriodStartDate: data.workPeriodStartDate,
            workPeriodEndDate: data.workPeriodEndDate,
            photoUrl: data.photoUrl,
            unitLength: data.unitLength,
            status: data.status,
          });
        }
      })
      .catch(e => {
        console.error(e);
      });
  }, [siteId, jsonAutocompleteValue, fetchSite]);

  // validationメッセージの表示が必要な場合は右端に表示領域を確保
  const textFieldClass = keepValidateSpace
    ? classes.textFieldWithValidate
    : classes.textField;

  return (
    <Autocomplete
      multiple={multiple}
      {...rest}
      getOptionLabel={record => {
        const { name } = record;
        const text = `${name} `;
        handleOption(name);
        return text;
      }}
      data-testid="siteAutoCompleteInput"
      getOptionSelected={(option, value) => option.id === value.id}
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={autocompleteValue}
      disabled={disabled}
      onChange={(_, newValue: SiteData | SiteData[] | null) => {
        if (!Array.isArray(newValue) && !newValue) {
          handleOption('');
          setAutocompleteValue(null);
          setOptions([]);
          onChange(undefined);
          return;
        }
        if (Array.isArray(newValue) && newValue.length === 0) {
          setAutocompleteValue([]);
          setOptions([]);
          onChange([]);
          return;
        }
        if (!Array.isArray(newValue)) {
          setOptions(newValue ? [newValue, ...options] : options);
          if (newValue) {
            onChange(String(newValue.id));
            setAutocompleteValue(newValue);
          }
          if (handleChange && newValue && form) {
            handleChange(newValue, form);
          }
        }
        if (Array.isArray(newValue)) {
          setOptions(newValue);
          onChange(newValue.map(v => String(v.id)));
        }
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField
          className={textFieldClass}
          {...params}
          label={
            label !== '' &&
            label !== false && (
              <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />
            )
          }
          error={!!(touched && error)}
          helperText={
            <InputHelperText
              touched={touched as boolean}
              error={error}
              helperText={helperText}
            />
          }
          disabled={disabled}
          variant={variant}
          margin={margin}
        />
      )}
      renderOption={({ name }) => <Box> {name}</Box>}
    />
  );
};

export default SiteAutoCompleteInput;
