import React, { FC, useCallback, useState } from 'react';
import { ArrayInput, Labeled, required } from 'react-admin';
import { createStyles, makeStyles } from '@material-ui/core';
import {
  CustomForm,
  Divider,
  Typography,
  Button,
  SimpleFormIterator,
} from '../..';
import { styles } from '../../../theme';
import { useConfirmArrayValidation } from '../../../hooks';
import { inputPasswordWidth } from '../../../theme/define';
import { RetrofitSsidImage, AddIcon, DeleteIcon } from '../../../assets';
import { UpdateData } from './hooks';
import { RetrofitManageSaveButton as SaveButton } from './views';
import RetrofitTextInput from '../../organisms/RetrofitTextInput';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 14,
    },
    divider: {
      marginTop: 8,
      marginBottom: 12,
      backgroundColor: 'white',
    },
    description: {
      ...styles.multilineText,
    },
    corporationId: {
      ...styles.longWidthText,
    },
    icon: {
      marginTop: 24,
    },
    arrayInput: {
      marginTop: 0,
      marginBottom: 0,
      '& > label': {
        // ArrayInput の Label をCSSで非表示にする (TextInput のように addLabel が用意されていない為)
        display: 'none',
      },
      '& > ul': {
        width: '100%',
      },
      '& > ul > li > p': {
        display: 'none',
      },
    },
    confirm: {
      '& .MuiInputBase-input': {
        minWidth: inputPasswordWidth,
      },
    },
  }),
);

const useIteratorAddButtonStyle = makeStyles(() =>
  createStyles({
    button: {
      ...styles.buttonIteratorAdd,
      marginLeft: 148,
    },
  }),
);

const useIteratorAddButton = (): JSX.Element => {
  const classes = useIteratorAddButtonStyle();
  return (
    <Button classes={classes} onClick={e => e.preventDefault()}>
      <AddIcon />
    </Button>
  );
};

const useIteratorDeleteButtonStyle = makeStyles(() =>
  createStyles({
    button: {
      ...styles.buttonIteratorDelete,
    },
  }),
);

const useIteratorDeleteButton = (): JSX.Element => {
  const classes = useIteratorDeleteButtonStyle();
  return (
    <Button classes={classes} onClick={e => e.preventDefault()}>
      <DeleteIcon />
    </Button>
  );
};

const RetrofitSsidFiled: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.icon}>
      <RetrofitSsidImage />
    </div>
  );
};

interface Props {
  corporationName: string;
  updateData: UpdateData;
}

const RetrofitManage: FC<Partial<Props>> = ({
  corporationName,
  updateData,
  ...props
}) => {
  if (!updateData) throw new Error('Invalid updateData');
  const classes = useStyles();
  const addButton = useIteratorAddButton();
  const deleteButton = useIteratorDeleteButton();
  const [formCheck, setFormCheck] = useState(true);
  const [eventCheck, setEventCheck] = useState(true);

  const confirmArrayValidation = useConfirmArrayValidation(
    'retrofitsSerial',
    'basicInfoSerialNumber',
    'admin.message.retrofitsManage.serialNumberMatchError',
  );

  const [disableAddSerial, setDisableAddSerial] = useState(false);
  const formValidation = (value: any) => {
    // 言語の追加制限制御
    const formData = value;
    if (formData && formData.retrofitsSerial) {
      setDisableAddSerial(formData.retrofitsSerial?.length >= 5);
    }

    const basicInfoSerials = formData.retrofitsSerial?.map((it: any) =>
      it?.basicInfoSerialNumber ? it.basicInfoSerialNumber : undefined,
    );
    const confirmSerials = formData.retrofitsSerial?.map((it: any) =>
      it?.confirmInput ? it.confirmInput : undefined,
    );

    // 入力欄が1つでも初期状態であれば保存ボタン非活性
    const isValueDefault =
      basicInfoSerials?.some(
        (it: string) => it === undefined || it === 'Retro-',
      ) ||
      confirmSerials?.some((it: string) => it === undefined || it === 'Retro-');

    // 入力欄と確認用入力欄が完全一致しない場合は保存ボタンを非活性
    const isUnMatched =
      JSON.stringify(basicInfoSerials) !== JSON.stringify(confirmSerials);

    // 保存ボタンの活性状態制御
    if (
      isValueDefault ||
      isUnMatched ||
      formData.retrofitsSerial?.length === 0
    ) {
      setFormCheck(true);
    } else {
      setFormCheck(false);
    }

    return {};
  };

  // 登録処理完了後、保存ボタンを非活性状態に変更する
  const handleDisableness = useCallback(() => {
    setEventCheck(true);
  }, []);

  // 入力アクションがあれば、登録後に非活性となった保存ボタンを活性状態に戻す
  const checkSaveButtonDisables = useCallback(() => {
    setEventCheck(false);
  }, []);

  const saveButtonDisabled = formCheck || eventCheck;
  const newProps = {
    ...props,
    updateData,
    saveButtonDisabled,
    handleDisableness,
  };

  return (
    <CustomForm
      className={classes.root}
      resource="retrofits"
      redirect={false}
      saveButton={<SaveButton {...newProps} />}
      deleteButton={false}
      cancelButton={false}
      title=""
      validate={formValidation}
      {...props}
    >
      <Labeled label="admin.label.retrofitsManage.corporationName">
        <Typography>{corporationName}</Typography>
      </Labeled>
      <Divider className={classes.divider} />
      <RetrofitSsidFiled />
      <ArrayInput
        source="retrofitsSerial"
        label=""
        className={classes.arrayInput}
        defaultValue={[
          { basicInfoSerialNumber: 'Retro-', confirmInput: 'Retro-' },
        ]}
      >
        <SimpleFormIterator
          disableAdd={disableAddSerial}
          addButton={addButton}
          removeButton={deleteButton}
        >
          <RetrofitTextInput
            source="basicInfoSerialNumber"
            label="admin.label.retrofitsManage.basicInfoSerialNumber"
            validate={required()}
            checkSaveButtonDisables={checkSaveButtonDisables}
          />
          <RetrofitTextInput
            source="confirmInput"
            label="admin.label.retrofitsManage.confirmInput"
            validate={[required(), confirmArrayValidation]}
            checkSaveButtonDisables={checkSaveButtonDisables}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </CustomForm>
  );
};

RetrofitManage.displayName = 'RetrofitManage';
export default RetrofitManage;
