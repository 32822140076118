import { useDataProvider } from 'react-admin';
import { RetrofitByTaskData } from '../../../../../../dataProvider';

interface InParams {
  siteId: string;
  taskId?: number;
}

interface OutParams {
  data: {
    retrofitByTasks: RetrofitByTaskData[];
  };
}

const useRetrofitByTasks = () => {
  const dataProvider = useDataProvider();
  return async ({ siteId, taskId }: InParams): Promise<OutParams> => {
    const { data: retrofitByTasks } =
      await dataProvider.getList<RetrofitByTaskData>('retrofitByTasks', {
        pagination: {
          page: 1,
          perPage: 100,
        },
        sort: { field: 'machineInfoMachineName', order: 'ASC' },
        filter: { siteId, taskId },
      });
    return {
      data: {
        retrofitByTasks,
      },
    };
  };
};

export default useRetrofitByTasks;
