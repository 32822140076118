import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';

/**
 * 一覧と同一条件のFilterにて詳細情報を取得する
 *
 * @example
 *
 * const { getSelefDetailData } = useGetSelefDetailData();
 *
 * // 建機詳細の情報を取得する
 * const corporationData = getSelefDetailData('retrofits',{ ownerCorporationId : 'corpid' });
 */
const useGetSelefDetailData = () => {
  const dataProvider = useDataProvider();

  return useCallback(
    async (resorce: string, filter: any, id: string | number): Promise<any> => {
      const { data } = await dataProvider.getList(resorce, {
        pagination: {
          page: 1,
          perPage: 1,
        },
        sort: { field: 'id', order: 'DESC' },
        filter: { ...filter, id },
      });

      if (data.length > 0) {
        return data[0];
      }

      return undefined;
    },
    [dataProvider],
  );
};

export default useGetSelefDetailData;
