import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useGetMany, TextField as RaTextField } from 'react-admin';
import { RetrofitData, SiteData } from '../../dataProvider';
import { Typography } from '../atoms';

const SiteNameField: React.FC<{
  record?: RetrofitData;
  label?: string; // 內部で使わなくても <FormInput> 側で利用される
  addLabel?: boolean; // 內部で使わなくても <FormInput> 側で利用される
}> = ({ record }) => {
  const retrofitSiteId = record?.siteId || '';
  const { data, loading } = useGetMany('sites', [retrofitSiteId]);
  if (loading) return <LinearProgress />;
  if (!data || data.length === 0 || data[0] === undefined)
    return <Typography>{retrofitSiteId}</Typography>;

  const siteData = data[0] as SiteData;
  const name = siteData?.name;
  return <RaTextField record={{ name }} source="name" />;
};

SiteNameField.defaultProps = {
  addLabel: true, // Form 配下でラベルを出す為に設定
};

SiteNameField.displayName = 'SiteNameField';
export default SiteNameField;
