import { useState } from 'react';
import { MapController, getMapController } from '../map';

interface OutParams {
  mapController: MapController;
}

const useMapController = (): OutParams => {
  const [mapController] = useState<MapController>(getMapController);

  return {
    mapController,
  };
};

export default useMapController;
