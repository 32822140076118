import { UnitLength } from '../../dataProvider';
import { metreToFeet, feetToMetre, math } from '../../utils';

const defaultRoundUpDigits = 2; // 小数第三位で四捨五入し小数第二位で扱う

export const displayFt = 'ft';
export const displayUsFt = 'Usft';
export const displayM = 'm';

const useUnitLength = () => {
  const self = {
    toDisplayUnitLength: (unit: UnitLength): string => {
      switch (unit) {
        case 'Ft':
          return displayFt;
        case 'UsFt':
          return displayUsFt;
        case 'M':
          return displayM;
        default:
          console.error(`Illegal unit: ${unit}`);
          return unit;
      }
    },
    /* m To ft */
    meterToUnitLength: (meter: number, unitLength: UnitLength): number => {
      if (unitLength === 'M') return meter; // 単位変換なし
      const isUsFt = unitLength === 'UsFt';
      return metreToFeet(isUsFt, meter);
    },
    /* m To ft (四捨五入）*/
    meterToUnitLengthRoundUp: (
      meter: number,
      unitLength: UnitLength,
      roundUpDigits?: number,
    ): number => {
      return math.roundUp(
        self.meterToUnitLength(meter, unitLength),
        roundUpDigits || defaultRoundUpDigits,
      );
    },
    /* ft To m */
    feetToUnitLength: (feet: number, unitLength: UnitLength): number => {
      if (unitLength === 'M') return feet; // 単位変換なし
      const isUsFt = unitLength === 'UsFt';
      return feetToMetre(isUsFt, feet);
    },
    /* ft To m (四捨五入）*/
    feetToUnitLengthRoundUp: (
      feet: number,
      unitLength: UnitLength,
      roundUpDigits?: number,
    ): number => {
      return math.roundUp(
        self.feetToUnitLength(feet, unitLength),
        roundUpDigits || defaultRoundUpDigits,
      );
    },
  };
  return self;
};

export default useUnitLength;
